// Global
import { sendGTMEvent } from '@next/third-parties/google';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { useI18n } from 'next-localization';
import { useEffect, useState } from 'react';
import { tailwindVariants } from './BackToTopTailwind';

// Lib
import { ComponentProps } from 'lib/component-props';
import { DartComponents } from 'lib/templates/Feature.Dart.model';

// Local
import Button from 'helpers/Button/Button';

export type BackToTopProps = ComponentProps & DartComponents.RenderingParameters.BackToTop;

const BackToTop = (props: BackToTopProps) => {
  const { enableRTL } = props?.params || {};
  const { componentName, dataSource } = props?.rendering || {};

  const i18n = useI18n();

  const [showBackToTopButton, setShowBackToTopButton] = useState(false);

  const alignment = enableRTL === '1' ? 'left' : 'right';
  const backToTopMsg = i18n.t('BacktoTop');

  const { base, button, label } = tailwindVariants({
    alignment,
    showBackToTopButton,
  });

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 100) {
        setShowBackToTopButton(true);
      } else {
        setShowBackToTopButton(false);
      }
    });

    // For Storybook only, force the button to always be shown.
    if (process.env.IS_STORYBOOK) setShowBackToTopButton(true);
  }, []);

  const handleOnClick = (e?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    sendGTMEvent({
      event: 'link',
      type: 'floating button',
      'gtm.element.dataset.gtmLinkUrl': '',
      'gtm.element.dataset.gtmLinkName': 'Back to top',
      'gtm.element.dataset.gtmDatasourceId': dataSource,
      'gtm.element.dataset.gtmComponentName': componentName,
    });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div
      className={base()}
      data-component="helpers/general/backtotop"
      onClick={(e) => handleOnClick(e)}
    >
      <Button
        childClass={button()}
        href="#back-to-top"
        iconLeft="expand_less"
        srOnlyText={backToTopMsg}
        tag="a"
        type="filled"
      />
      <Text className={label()} encode={false} field={{ value: backToTopMsg }} tag="span" />
    </div>
  );
};

export default BackToTop;
