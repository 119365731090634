// Global
import { useSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useEffect, useState } from 'react';

// Lib
import { ComponentProps } from 'lib/component-props';
import { DartComponents } from 'lib/templates/Feature.Dart.model';
import { BaseFields } from 'lib/templates/Foundation.Dart.model';

// Local
import CtaBorderAnimation from 'helpers/CTA/CtaBorderAnimation';
import GoogleMaterialSymbol from 'helpers/GoogleMaterialSymbol/GoogleMaterialSymbol';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import { NameDefault } from './HeaderDesktop';
import { tailwindVariants } from './HeaderMobileTailwind';
import { useTheme } from 'lib/context/ThemeContext';
import { useRouter } from 'next/router';

export type Countrylist = DartComponents.Navigation.RegionJson &
  NameDefault &
  DartComponents.Navigation.CountryJson;

export type FeaturedLinks = BaseFields.CtaJson;

export type HeaderMobileProps = ComponentProps &
  DartComponents.HeaderJson & {
    staticProps?: {
      header?: DartComponents.HeaderJson;
    };
  };

export type MainNavigations = DartComponents.Navigation.SecondaryNavigationJson &
  DartComponents.Navigation.PrimaryHeaderNavigationJson &
  BaseFields.FeaturedJson;

export type SecondaryNavigations = DartComponents.Navigation.SecondaryNavigationJson;

const HeaderMobile = (props: HeaderMobileProps): JSX.Element => {
  const {
    enableSearch,
    logoImage,
    mainNavigations,
    regions,
    searchPlaceholderText,
    title,
    logoUrl,
  } = props?.staticProps?.header || {};
  const { componentName, dataSource } = props?.rendering || {};
  const { themeName } = useTheme();

  const [matchingCountry, setMatchingCountry] = useState<Countrylist | null>();
  const [openCountryWrapper, setOpenCountryWrapper] = useState<boolean>(false);
  const [openL1Panels, setOpenL1Panels] = useState<number[]>([]);
  const [openL2Panels, setOpenL2Panels] = useState<Record<number, number[]>>({});
  const [toggleMenu, setToggleMenu] = useState(false);
  const router = useRouter();

  const [pathname, setPathname] = useState(`${router?.locale}/${router?.asPath}`);

  const { sitecoreContext } = useSitecoreContext();
  type SearchSettings = {
    previewSource: string;
    source: string;
    searchPageLink: string;
  };

  const searchSettings = sitecoreContext?.searchDetails as SearchSettings;
  const languageLength = (regions?.targetItems?.length as number) > 1;

  const {
    base,
    headerWrapper,
    mobileHeaderLeft,
    logoWrapper,
    logo,
    mobileHeaderRight,
    searchWrapper,
    searchForm,
    searchFormInput,
    searchFormButton,
    searchFormButtonIcon,
    hamburgerWrapper,
    toggleMenuWrapper,
    toggleMenuCheckbox,
    hamburgerLinesWrapper,
    hamburgerLine1,
    hamburgerLine2,
    hamburgerLine3,
    mobileNavigation,
    navigation,
    navigationInner,
    primaryNavigation,
    primaryNavigationListitemWrapper,
    primaryNavigationListitem,
    primaryNavigationListItemIconActive,
    secondaryNavigationWrapper,
    secondaryNavigation,
    secondaryNavigationListItemTitleLinkWrapper,
    secondaryNavigationListItemTitleLinkActive,
    secondaryNavigationListItemTitleLink,
    secondaryNavigationListItemLinkIconActive,
    secondaryNavigationListItemLinkIcon,
    secondaryNavigationItemsWrapper,
    secondaryNavigationItem,
    secondaryNavigationItemLink,
    featuredContentWrapper,
    featuredContentTitleWrapper,
    featuredContentTitle,
    featuredContentImageWrapper,
    featuredContentImage,
    featuredContentLinksWrapper,
    featuredContentLinks,
    featuredContentLinkItem,
    countryWrapper,
    countryWrapperInner,
    currentCountry,
    currentCountryInner,
    flagIconWrapper,
    logoPlaceholder,
    countryInformation,
    currentCountryName,
    currentCountryLanguage,
    countryDropDownIcon,
    countryListWrapper,
    countryListInnerWrapper,
    countryListTop,
    countryListHeading,
    countryListBottom,
    countryListCols,
    countryListCol,
    countryName,
    countryList,
    countryItemLinkWrapper,
    countryItemLink,
    countryListItemLinkleft,
    countryListItemLinkleftInfo,
    countryListItemLinkleftInfoTitle,
    countryListItemLinkleftInfoSubTitle,
    countryListRight,
    countrySelection,
    countryListRightIcon,
  } = tailwindVariants({
    toggleMobileMenu: toggleMenu,
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    brand: themeName as string,
    languageLength,
  });

  useEffect(() => {
    router && setPathname(`/${router.locale}${router.asPath}`);
  }, [router]);

  useEffect(() => {
    if (!regions) return;

    const foundCountry = regions?.targetItems
      .flatMap((region: Countrylist) => {
        return region?.countries?.targetItems;
      })
      .find(
        (country?: Countrylist) =>
          country?.languageCode?.jsonValue?.value === sitecoreContext.language
      );
    setMatchingCountry(foundCountry);
  }, [regions, sitecoreContext]);

  useEffect(() => {
    if (toggleMenu) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    const autoExpand = mainNavigations?.targetItems?.[0]?.navAutoExpand?.jsonValue?.value;

    if (toggleMenu && autoExpand) {
      setOpenL1Panels([0]); // always open first panel if autoExpand is true
    } else {
      setOpenL1Panels([]); // close all
    }
  }, [toggleMenu, mainNavigations]);

  const showCountryWrapper = () => {
    setOpenCountryWrapper(!openCountryWrapper);
  };

  const expandL1Navigation = (index: number) => {
    if (openL1Panels?.includes(index as never)) {
      setOpenL1Panels(openL1Panels.filter((x) => x !== index));
    } else {
      setOpenL1Panels([...(openL1Panels as never), index as never]);
    }
  };

  const expandL2Navigation = (index: number, innerIndex: number) => {
    setOpenL2Panels((prevOpenL2Panels) => {
      const currentL1Panels = prevOpenL2Panels[index] || [];

      return {
        ...prevOpenL2Panels,
        [index]: Array.isArray(currentL1Panels)
          ? currentL1Panels.includes(innerIndex)
            ? currentL1Panels.filter((y) => y !== innerIndex)
            : [...currentL1Panels, innerIndex]
          : [innerIndex],
      };
    });
  };

  const toggleHamburger = (state?: boolean) => {
    setOpenL1Panels([]);
    setOpenL2Panels([]);
    setToggleMenu(state !== undefined ? state : !toggleMenu);
  };

  return (
    <div className={base()} data-component="authorable/landmarks/header">
      <div className={headerWrapper()}>
        <div className={mobileHeaderLeft()}>
          <div className={logoWrapper()}>
            <div className={logo()}>
              {pathname.replace(/#.*/, '') !== '/' ? (
                <LinkWrapper
                  aria-label="Link to homepage"
                  field={logoUrl?.jsonValue}
                  gtmEvent={{
                    event: 'navigation_click',
                    'gtm.element.dataset.gtmDatasourceId': dataSource,
                    'gtm.element.dataset.gtmComponentName': componentName,
                  }}
                  onClick={() => toggleHamburger(false)}
                  title="Link to homepage"
                >
                  <ImageWrapper field={logoImage?.jsonValue} />
                </LinkWrapper>
              ) : (
                <ImageWrapper field={logoImage?.jsonValue} />
              )}
            </div>
          </div>
        </div>
        <div className={mobileHeaderRight()}>
          {enableSearch?.jsonValue?.value && (
            <div className={searchWrapper()}>
              <form action={searchSettings?.searchPageLink} className={searchForm()} role="search">
                <label htmlFor="searchboxh" className="hidden">
                  {searchPlaceholderText?.jsonValue?.value}
                </label>
                <input
                  autoComplete="off"
                  className={searchFormInput()}
                  id="searchboxh"
                  name="q"
                  placeholder={searchPlaceholderText?.jsonValue?.value}
                  required={false}
                  type="text"
                />
                <button aria-label="Search" className={searchFormButton()} type="submit">
                  <GoogleMaterialSymbol
                    className={searchFormButtonIcon()}
                    icon="search"
                    variant="outlined"
                  />
                </button>
              </form>
            </div>
          )}
          <div className={hamburgerWrapper()}>
            <div className={toggleMenuWrapper()}>
              <input
                aria-label="Main Menu"
                className={toggleMenuCheckbox()}
                checked={toggleMenu}
                onChange={() => toggleHamburger()}
                type="checkbox"
              />
              <div className={hamburgerLinesWrapper()}>
                <span className={hamburgerLine1()}></span>
                <span className={hamburgerLine2()}></span>
                <span className={hamburgerLine3()}></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {toggleMenu && (
        <>
          <div className={mobileNavigation()}>
            <nav className={navigation()}>
              <div className={navigationInner()}>
                <ul className={primaryNavigation()}>
                  {mainNavigations?.targetItems?.map((ListItem: MainNavigations, index: number) => {
                    const {
                      primaryNavigationListItemLink,
                      primaryNavigationListItemLinkActive,
                      primaryNavigationListItemIcon,
                    } = tailwindVariants({
                      isActive:
                        (ListItem?.navigationLink?.jsonValue?.value?.href &&
                          ListItem?.navigationLink?.jsonValue?.value?.href === pathname) ||
                        false,
                    });

                    return (
                      <li key={index} className={primaryNavigationListitemWrapper()}>
                        <div
                          className={primaryNavigationListitem()}
                          onClick={() => expandL1Navigation(index)}
                        >
                          {ListItem?.navigationLink?.jsonValue?.value?.href ? (
                            <LinkWrapper
                              className={`${
                                openL1Panels.includes(index as never)
                                  ? primaryNavigationListItemLinkActive()
                                  : primaryNavigationListItemLink()
                              }`}
                              field={ListItem?.navigationLink?.jsonValue}
                              gtmEvent={{
                                event: 'navigation_click',
                                type: 'header',
                                'gtm.element.dataset.gtmLinkName':
                                  ListItem?.navigationLink?.jsonValue?.value.text,
                                'gtm.element.dataset.gtmLinkUrl':
                                  ListItem?.navigationLink?.jsonValue?.value?.href,
                                'gtm.element.dataset.gtmDatasourceId': dataSource,
                                'gtm.element.dataset.gtmComponentName': componentName,
                              }}
                              onClick={() => toggleHamburger()}
                            />
                          ) : (
                            <Text
                              aria-label={ListItem?.navigationTitle?.jsonValue?.value}
                              className={`${
                                openL1Panels.includes(index as never)
                                  ? primaryNavigationListItemLinkActive()
                                  : primaryNavigationListItemLink()
                              }`}
                              encode={false}
                              field={ListItem?.navigationTitle?.jsonValue}
                              tag="span"
                            />
                          )}
                          {ListItem?.secondaryNavigations?.targetItems?.length !== 0 && (
                            <GoogleMaterialSymbol
                              className={`${
                                openL1Panels.includes(index as never)
                                  ? primaryNavigationListItemIconActive()
                                  : primaryNavigationListItemIcon()
                              }`}
                              icon={
                                openL1Panels.includes(index as never)
                                  ? 'expand_less'
                                  : 'expand_more'
                              }
                            />
                          )}
                        </div>
                        {openL1Panels?.includes(index as never) && (
                          <div className={secondaryNavigationWrapper()}>
                            <ul className={secondaryNavigation()}>
                              {ListItem?.secondaryNavigations?.targetItems?.map(
                                (ListIteminner: SecondaryNavigations, innerindex: number) => {
                                  return (
                                    <li key={innerindex}>
                                      {(openL2Panels[index] || []).includes(innerindex) ? (
                                        <>
                                          <div
                                            className={secondaryNavigationListItemTitleLinkWrapper()}
                                            onClick={() => expandL2Navigation(index, innerindex)}
                                          >
                                            <LinkWrapper
                                              className={secondaryNavigationListItemTitleLinkActive()}
                                              field={ListIteminner?.navigationLink?.jsonValue}
                                              gtmEvent={{
                                                event: 'navigation_click',
                                                type: 'header',
                                                'gtm.element.dataset.gtmLinkName':
                                                  ListIteminner?.navigationLink?.jsonValue?.value
                                                    .text,
                                                'gtm.element.dataset.gtmLinkUrl':
                                                  ListIteminner?.navigationLink?.jsonValue?.value
                                                    ?.href,
                                                'gtm.element.dataset.gtmDatasourceId': dataSource,
                                                'gtm.element.dataset.gtmComponentName':
                                                  componentName,
                                              }}
                                              key={index}
                                              onClick={() => toggleHamburger()}
                                            />
                                            {ListIteminner?.navigations?.targetItems?.length &&
                                            ListIteminner?.navigations?.targetItems?.length !==
                                              0 ? (
                                              <GoogleMaterialSymbol
                                                className={secondaryNavigationListItemLinkIconActive()}
                                                icon={
                                                  (openL2Panels[index] || []).includes(innerindex)
                                                    ? 'remove'
                                                    : 'add'
                                                }
                                              />
                                            ) : (
                                              ''
                                            )}
                                          </div>
                                          <ul className={secondaryNavigationItemsWrapper()}>
                                            {ListIteminner?.navigations?.targetItems?.map(
                                              (
                                                SecondaryItems: SecondaryNavigations,
                                                index: number
                                              ) => {
                                                return (
                                                  SecondaryItems?.navigationLink?.jsonValue?.value
                                                    ?.href && (
                                                    <li
                                                      className={secondaryNavigationItem()}
                                                      key={index}
                                                    >
                                                      <LinkWrapper
                                                        className={secondaryNavigationItemLink()}
                                                        field={
                                                          SecondaryItems?.navigationLink?.jsonValue
                                                        }
                                                        gtmEvent={{
                                                          event: 'navigation_click',
                                                          type: 'header',
                                                          'gtm.element.dataset.gtmLinkName':
                                                            SecondaryItems?.navigationLink
                                                              ?.jsonValue?.value.text,
                                                          'gtm.element.dataset.gtmLinkUrl':
                                                            SecondaryItems?.navigationLink
                                                              ?.jsonValue?.value?.href,
                                                          'gtm.element.dataset.gtmDatasourceId':
                                                            dataSource,
                                                          'gtm.element.dataset.gtmComponentName':
                                                            componentName,
                                                        }}
                                                        onClick={() => toggleHamburger()}
                                                      />
                                                    </li>
                                                  )
                                                );
                                              }
                                            )}
                                          </ul>
                                        </>
                                      ) : (
                                        <div
                                          className={secondaryNavigationListItemTitleLinkWrapper()}
                                          onClick={() => expandL2Navigation(index, innerindex)}
                                        >
                                          <LinkWrapper
                                            className={secondaryNavigationListItemTitleLink()}
                                            field={ListIteminner?.navigationLink?.jsonValue}
                                            gtmEvent={{
                                              event: 'navigation_click',
                                              type: 'header',
                                              'gtm.element.dataset.gtmLinkName':
                                                ListIteminner?.navigationLink?.jsonValue?.value
                                                  .text,
                                              'gtm.element.dataset.gtmLinkUrl':
                                                ListIteminner?.navigationLink?.jsonValue?.value
                                                  ?.href,
                                              'gtm.element.dataset.gtmDatasourceId': dataSource,
                                              'gtm.element.dataset.gtmComponentName': componentName,
                                            }}
                                            key={index}
                                            onClick={() => toggleHamburger()}
                                          />
                                          {ListIteminner?.navigations?.targetItems?.length &&
                                          ListIteminner?.navigations?.targetItems?.length !== 0 ? (
                                            <GoogleMaterialSymbol
                                              className={secondaryNavigationListItemLinkIcon()}
                                              icon={
                                                (openL2Panels[index] || []).includes(innerindex)
                                                  ? 'remove'
                                                  : 'add'
                                              }
                                            />
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      )}
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                            {ListItem?.featuredImage?.jsonValue?.value?.src && (
                              <div className={featuredContentWrapper()}>
                                <div className={featuredContentTitleWrapper()}>
                                  <Text
                                    className={featuredContentTitle()}
                                    encode={false}
                                    field={ListItem?.featuredTitle?.jsonValue}
                                    tag="p"
                                  />
                                </div>
                                <div className={featuredContentImageWrapper()}>
                                  <ImageWrapper
                                    className={featuredContentImage()}
                                    field={ListItem?.featuredImage?.jsonValue}
                                  />
                                </div>
                                <div className={featuredContentLinksWrapper()}>
                                  <ul className={featuredContentLinks()}>
                                    {ListItem?.featuredLinks?.targetItems?.map(
                                      (featuredLinkItem: FeaturedLinks, index: number) => {
                                        return (
                                          <React.Fragment key={index}>
                                            {featuredLinkItem?.ctaLink?.jsonValue?.value?.href && (
                                              <li className={featuredContentLinkItem()}>
                                                {featuredLinkItem?.ctaLink && (
                                                  <CtaBorderAnimation
                                                    fields={featuredLinkItem?.ctaLink?.jsonValue}
                                                  />
                                                )}
                                              </li>
                                            )}
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                  </ul>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </nav>
          </div>
          {matchingCountry?.languageName?.jsonValue?.value && (
            <div className={countryWrapper()}>
              {languageLength ? (
                <button
                  aria-label={`Current Country and Language - ${
                    matchingCountry?.name?.jsonValue?.value
                      ? matchingCountry?.name?.jsonValue?.value
                      : matchingCountry?.nameDefault?.jsonValue?.value
                  } ${matchingCountry?.languageName?.jsonValue?.value}`}
                  className={countryWrapperInner()}
                  onClick={showCountryWrapper}
                >
                  <div className={currentCountry()}>
                    <div className={currentCountryInner()}>
                      <div className={flagIconWrapper()}>
                        {matchingCountry?.flagIcon?.jsonValue?.value?.src ? (
                          <ImageWrapper field={matchingCountry?.flagIcon?.jsonValue} />
                        ) : (
                          <div className={logoPlaceholder()}></div>
                        )}
                      </div>
                      <div className={countryInformation()}>
                        <Text
                          className={currentCountryName()}
                          encode={false}
                          field={
                            matchingCountry?.name?.jsonValue
                              ? matchingCountry?.name?.jsonValue
                              : matchingCountry?.nameDefault?.jsonValue
                          }
                          tag="div"
                        />
                        <Text
                          className={currentCountryLanguage()}
                          encode={false}
                          field={matchingCountry?.languageName?.jsonValue}
                          tag="div"
                        />
                      </div>
                    </div>
                  </div>
                  <GoogleMaterialSymbol
                    className={countryDropDownIcon()}
                    icon={openCountryWrapper ? 'arrow_drop_up' : 'arrow_drop_down'}
                    variant="outlined"
                  />
                </button>
              ) : (
                <div className={countryWrapperInner()}>
                  <div className={flagIconWrapper()}>
                    {matchingCountry?.flagIcon?.jsonValue?.value?.src ? (
                      <ImageWrapper field={matchingCountry?.flagIcon?.jsonValue} />
                    ) : (
                      <div className={logoPlaceholder()}></div>
                    )}
                  </div>
                  <div className={currentCountry()}>
                    <div className={countryInformation()}>
                      <div className={currentCountryName()}>
                        {matchingCountry?.name?.jsonValue?.value
                          ? matchingCountry?.name?.jsonValue?.value
                          : matchingCountry?.nameDefault?.jsonValue?.value}
                      </div>
                      <div className={currentCountryLanguage()}>
                        {matchingCountry?.languageName?.jsonValue?.value}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {openCountryWrapper && (
                <div className={countryListWrapper()}>
                  <div className={countryListInnerWrapper()}>
                    <div className={countryListTop()}>
                      <Text
                        className={countryListHeading()}
                        encode={false}
                        field={{ value: title?.jsonValue?.value }}
                        tag="div"
                      />
                    </div>
                    <div className={countryListBottom()}>
                      {regions?.targetItems?.map((country: Countrylist, index: number) => {
                        return (
                          <>
                            {country?.countries?.targetItems?.length !== 0 &&
                              (country?.name?.jsonValue || country?.nameDefault?.jsonValue) && (
                                <div className={countryListCols()} key={index}>
                                  <div className={countryListCol()}>
                                    <Text
                                      className={countryName()}
                                      encode={false}
                                      field={
                                        country?.name?.jsonValue
                                          ? country?.name?.jsonValue
                                          : country?.nameDefault?.jsonValue
                                      }
                                      tag="p"
                                    />
                                  </div>
                                  <div className={countryList()}>
                                    {country?.countries?.targetItems?.map(
                                      (countryItem: Countrylist, index: number) => {
                                        const matchingCountryItemName = matchingCountry?.name
                                          ?.jsonValue
                                          ? matchingCountry?.name?.jsonValue
                                          : matchingCountry?.nameDefault?.jsonValue;
                                        const countryItemName = countryItem?.name?.jsonValue
                                          ? countryItem?.name?.jsonValue
                                          : countryItem?.nameDefault?.jsonValue;
                                        const isMatchingCountry =
                                          matchingCountryItemName?.value ===
                                            countryItemName?.value &&
                                          matchingCountry?.languageName?.jsonValue?.value ===
                                            countryItem?.languageName?.jsonValue?.value;

                                        // use interanl links to conrol languge switching. external links just redirect to sites we haven't built yet.
                                        const linkField = countryItem?.link?.jsonValue;
                                        if (linkField?.value?.linktype == 'internal') {
                                          linkField.value.href = '';
                                        }

                                        return (
                                          <>
                                            {countryItem?.link?.jsonValue?.value?.text && (
                                              <div
                                                className={countryItemLinkWrapper({
                                                  showCountryBg: isMatchingCountry,
                                                })}
                                                key={index}
                                              >
                                                <LinkWrapper
                                                  aria-label={`Set Country and Language to ${countryItemName?.value} ${countryItem?.languageName?.jsonValue?.value}`}
                                                  className={countryItemLink()}
                                                  field={countryItem?.link?.jsonValue}
                                                  key={index}
                                                  showLinkTextWithChildrenPresent={false}
                                                  target={
                                                    countryItem?.link?.jsonValue?.value?.target
                                                  }
                                                  locale={countryItem?.languageCode?.jsonValue?.value?.toLocaleLowerCase()}
                                                >
                                                  <div className={countryListItemLinkleft()}>
                                                    {countryItem?.flagIcon?.jsonValue?.value
                                                      ?.src && (
                                                      <ImageWrapper
                                                        field={countryItem?.flagIcon?.jsonValue}
                                                      />
                                                    )}
                                                    <div className={countryListItemLinkleftInfo()}>
                                                      <Text
                                                        className={countryListItemLinkleftInfoTitle()}
                                                        encode={false}
                                                        field={countryItemName}
                                                        tag="p"
                                                      />
                                                      <Text
                                                        className={countryListItemLinkleftInfoSubTitle()}
                                                        encode={false}
                                                        field={countryItem?.languageName?.jsonValue}
                                                        tag="span"
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className={countryListRight()}>
                                                    <div className={countrySelection()}>
                                                      <>
                                                        <GoogleMaterialSymbol
                                                          className={`${
                                                            isMatchingCountry
                                                              ? 'fill-color-system-success-400'
                                                              : countryListRightIcon()
                                                          } `}
                                                          icon="done"
                                                        />
                                                      </>
                                                    </div>
                                                  </div>
                                                </LinkWrapper>
                                              </div>
                                            )}
                                          </>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              )}
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default HeaderMobile;
