// Global
import { tv } from 'tailwind-variants';

export const carouselControlsTailwindVariants = tv({
  slots: {
    paginationWrapper: [
      'align-start',
      'flex',
      'flex-col',
      'gap-spacing-spacing-3',
      'justify-between',
    ],
  },
});

export const navigationButtonTailwindVariants = tv({
  slots: {
    navigationButton: ['disabled:opacity-50'],
    navigationButtonIcon: [],
  },
  variants: {
    variant: {
      brand: {
        navigationButton: [
          'disabled:border-components-button-color-outline-brand-disabled-stroke',
          'disabled:pointer-events-none',
          'border-components-button-color-outline-brand-default-stroke',
          'hover:border-components-button-color-outline-brand-hover-stroke',
          'hover:bg-components-button-color-outline-brand-hover-bg',
          'active:bg-components-button-color-outline-brand-focus-bg',
          'active:border-components-button-color-outline-brand-focus-stroke',
          'focus:ring-components-button-color-outline-brand-focus-outline',
          '[&_svg]:disabled:fill-components-button-color-outline-brand-disabled-icon',
        ],
        navigationButtonIcon: [
          'fill-components-button-color-outline-brand-default-icon',
          'group-hover:fill-components-button-color-outline-brand-hover-icon',
          'group-active:fill-components-button-color-outline-brand-focus-icon',
        ],
      },
      white: {
        navigationButton: [
          'disabled:border-components-button-color-outline-white-disabled-stroke',
          'disabled:pointer-events-none',
          'border-components-button-color-outline-white-default-stroke',
          'hover:border-components-button-color-outline-white-hover-stroke',
          'hover:bg-components-button-color-outline-white-hover-bg',
          'active:bg-components-button-color-outline-white-focus-bg',
          'active:border-components-button-color-outline-white-focus-stroke',
          'focus:ring-components-button-color-outline-white-focus-outline',
          '[&_svg]:disabled:fill-components-button-color-outline-white-disabled-icon',
        ],
        navigationButtonIcon: [
          'fill-components-button-color-outline-white-default-icon',
          'group-hover:fill-components-button-color-outline-white-hover-icon',
          'group-active:fill-components-button-color-outline-white-focus-icon',
        ],
      },
    },
  },
});

export const progressBarTailwindVariants = tv({
  slots: {
    carouselProgressBar: [
      'progress-bar',
      'h-[4px]',
      'w-spacing-spacing-12',
      'rounded-components-pagination-scrollbar-radius',
    ],
    carouselProgress: ['h-[4px]', 'rounded-components-pagination-scrollbar-radius'],
    page: [],
  },
  variants: {
    variant: {
      color: {
        carouselProgressBar: ['bg-components-pagination-on-color-bg'],
        carouselProgress: ['bg-components-pagination-on-color-accent-scroll'],
        navigationButton: [],
        page: [
          '[&_button]:from-components-pagination-on-color-accent-scroll',
          '[&_button]:via-components-pagination-on-color-accent-scroll',
          '[&_button]:to-components-pagination-on-color-bg',
        ],
      },
      white: {
        carouselProgressBar: ['bg-components-pagination-on-white-bg'],
        carouselProgress: ['bg-components-pagination-on-white-accent-scroll'],
        navigationButton: [],
        page: [
          '[&_button]:from-components-pagination-on-white-accent-scroll',
          '[&_button]:via-components-pagination-on-white-accent-scroll',
          '[&_button]:to-components-pagination-on-white-bg',
          'rounded-components-pagination-scrollbar-radius',
        ],
      },
    },
  },
});
