// Global
import { useI18n } from 'next-localization';
import React, { useEffect } from 'react';

// Local
import Button from 'helpers/Button/Button';
import { useRealPathName } from 'lib/utils/use-real-pathname';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

interface _Window {
  swnDataLayer?: {
    product_eans?: string;
  };
}

interface ProductEanProps {
  eans?: string;
  className?: string;
  btnType?: string;
  btnColor?: string;
}

const ProductEan: React.FC<ProductEanProps> = ({ eans, btnType, btnColor }) => {
  const i18n = useI18n();
  const mikMakLabel = i18n.t('MikMakLabel');
  const pathname = useRealPathName();
  const { sitecoreContext } = useSitecoreContext();

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const anchor = document.querySelector('.swn-tag-wtb-btn');
    const dataEans = anchor?.getAttribute('data-mm-ids');

    if (dataEans !== null && dataEans !== undefined) {
      const swnDataLayer = ((window as _Window).swnDataLayer =
        (window as _Window).swnDataLayer || {});
      swnDataLayer.product_eans = dataEans || '';
    }
  }, [eans]);

  return (
    <Button
      attributes={{
        'data-mm-ids': String(eans),
        'data-mm-wtbid': String(sitecoreContext?.integrationMikMak?.mikMakPdpWidgetID),
      }}
      childClass="block my-[20px] swn-tag-wtb-btn"
      color={(btnColor as string) || 'brand'}
      label={mikMakLabel}
      title={mikMakLabel}
      type={(btnType as string) || 'filled'}
      gtmEvent={{
        event: 'cta_click',
        type: 'primary',
        'gtm.element.dataset.gtmComponentName': 'MikMak CTA Button',
        'gtm.element.dataset.gtmLinkName': mikMakLabel,
        'gtm.element.dataset.gtmLinkUrl': pathname,
        'gtm.element.dataset.gtmDatasourceId': eans,
      }}
    />
  );
};

export default ProductEan;
