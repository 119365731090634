// Global
import { LinkField, Text, TextField } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useEffect, useState } from 'react';

// Lib
import { ComponentProps } from 'lib/component-props';
import { CardComponents } from 'lib/templates/Feature.Dart.model';
import { ItemEx } from 'lib/templates/_.Sitecore.Override';

// Local
import Button from 'helpers/Button/Button';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import fallback from 'lib/fallback/fallback';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import SVG from 'helpers/SVG/SVG';
import { useTheme } from 'lib/context/ThemeContext';
import { tailwindVariantsBrandCard } from './BrandCardTailwind';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';

export type BrandCard = ItemEx & CardComponents.BrandCard.BrandCardItem & NumOfCards;

interface NumOfCards {
  numOfCards: number | undefined;
}

export type BrandCardProps = ComponentProps & CardComponents.BrandCard.BrandCardsList;

export type BrandCategoryType = ItemEx &
  CardComponents.BrandCard.BrandCardCategoryItem &
  NumOfCards;

type Grid = '5column' | '6column' | undefined;

// Add fallback component variant color
const fallbackComponentVariantColor = fallback?.componentVariants?.value;
const fallbackComponentVariantType = fallback?.componentVariants?.type;

const BrandSingleCard = (props: BrandCard): JSX.Element => {
  const { brandImage, brandLink } = props?.fields || {};

  const { cardImageWrapper } = tailwindVariantsBrandCard({});

  return (
    <>
      {brandImage?.value?.src && (
        <div className={cardImageWrapper()}>
          <LinkWrapper field={brandLink} suppressLinkText ignoreEE>
            <ImageWrapper field={brandImage} layout="intrinsic" />
          </LinkWrapper>
        </div>
      )}
    </>
  );
};

const BrandCard = (props: BrandCardProps): JSX.Element => {
  const { componentName, dataSource } = props?.rendering || {};

  const {
    brandCategories,
    allBrandsText,
    description,
    primaryCTA,
    title,
    primaryCTAColor,
    primaryCTAType,
  } = props?.fields || {};

  const [showAllBrands, setShowAllBrands] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [brandsKey, setBrandsKey] = useState(0); // re-render of "All Brands"
  const [categoryKey, setCategoryKey] = useState(0); // re-render of "Selected Category"

  const { themeName } = useTheme();

  // Handles clicking the "Show All Brands" button
  const handleAllBrandsClick = () => {
    setShowAllBrands(true);
    setSelectedCategory(null);
    setBrandsKey((prevKey) => prevKey + 1); // Increment brandsKey to trigger re-render
  };

  // Handles clicking a specific category
  const handleCategoryClick = (categoryTitle: string) => {
    setSelectedCategory(categoryTitle);
    setShowAllBrands(false);
    setCategoryKey((prevKey) => prevKey + 1); // Increment categoryKey to trigger re-render
  };

  const handleTitleLinkClick = () => {
    setLoading(true);
  };

  useEffect(() => {
    if (!allBrandsText?.value && !selectedCategory && brandCategories?.length) {
      const firstCategory = (brandCategories[0]?.fields?.title as TextField | undefined)?.value;
      if (firstCategory) {
        handleCategoryClick(firstCategory as string);
      }
    }
  }, [allBrandsText?.value, selectedCategory, brandCategories]);

  if (!props.fields) return <></>;

  const {
    componentBG,
    base,
    contentWrapper,
    titleAndDescriptionWrapperOuter,
    titleAndDescriptionWrapperInner,
    titleText,
    descriptionText,
    brandContainer,
    brandContainerWrapper,
    brandContainerLeftWrapper,
    brandContainerRightWrapper,
    brandTitle,
    brandCardTitle,
    brandCardDescription,
    archiveSection,
    textLink,
    titleArchives,
    descriptionArchives,
    archiveCTA,
    gradientMobile,
    loaderWrapper,
    searchLoaderAnimation,
  } = tailwindVariantsBrandCard({
    grid: props?.params?.grid as Grid,
  });

  // Unique id for component
  const id = props?.params?.RenderingIdentifier;

  const activeClass =
    'bg-components-button-group-color-button-bg-active hover:!bg-components-button-group-color-button-bg-active !text-components-button-group-color-button-fg-active';

  const getButtonClass = (isActive: boolean) =>
    `${brandTitle()} ${isActive ? activeClass : '[&>a]:block'}`;

  const showAllBrandsSection = showAllBrands;
  const showSelectedCategorySection = !showAllBrands && selectedCategory;

  return (
    <div className={componentBG()} id={id ? id : undefined} tabIndex={id ? -1 : 1}>
      <div className={base()} data-component="authorable/general/brandcard" data-testid="brandcard">
        <div className={contentWrapper()}>
          {title?.value && (
            <div className={titleAndDescriptionWrapperOuter()}>
              <div className={titleAndDescriptionWrapperInner()}>
                {title?.value && <Text className={titleText()} encode={false} field={title} />}
                {description?.value && (
                  <Text className={descriptionText()} encode={false} field={description} />
                )}
              </div>
              {primaryCTA?.value?.href && (
                <Button
                  href={primaryCTA?.value?.href}
                  label={primaryCTA?.value?.text}
                  tag="a"
                  color={primaryCTAColor?.value || fallbackComponentVariantColor}
                  type={primaryCTAType?.value || fallbackComponentVariantType}
                  target={primaryCTA?.value?.target}
                  size="compressed"
                  gtmEvent={{
                    event: 'cta_click',
                    type: 'primary',
                    'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                    'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                    'gtm.element.dataset.gtmDatasourceId': dataSource,
                    'gtm.element.dataset.gtmComponentName': componentName,
                  }}
                />
              )}
            </div>
          )}

          <div className={brandContainer()}>
            <div className={brandContainerLeftWrapper()}>
              {allBrandsText?.value && (
                <div className={getButtonClass(showAllBrands)} onClick={handleAllBrandsClick}>
                  <Text encode={false} field={allBrandsText} />
                </div>
              )}

              {brandCategories?.map((item: BrandCategoryType, key) => {
                const titleValue = item?.fields?.title?.value || '';
                const titleLink = item?.fields?.tabLink?.value?.href || '';

                const isActive = !allBrandsText?.value
                  ? selectedCategory === titleValue || (!selectedCategory && key === 0)
                  : selectedCategory === titleValue;

                return (
                  <>
                    {titleLink ? (
                      <div
                        key={key}
                        className={getButtonClass(isActive)}
                        onClick={() => {
                          setLoading(true);
                          handleCategoryClick(titleValue);
                          handleTitleLinkClick();
                        }}
                      >
                        <LinkWrapper
                          field={item?.fields?.tabLink?.value?.href as LinkField | undefined}
                          suppressLinkText
                          ignoreEE
                        >
                          <Text encode={false} field={item.fields.title as TextField} />
                        </LinkWrapper>
                      </div>
                    ) : (
                      <div
                        key={key}
                        className={getButtonClass(isActive)}
                        onClick={() => handleCategoryClick(titleValue)}
                      >
                        {item?.fields?.title && (
                          <Text
                            encode={false}
                            className={textLink()}
                            field={item.fields.title as TextField}
                          />
                        )}
                      </div>
                    )}
                  </>
                );
              })}

              <div
                className={gradientMobile()}
                style={{
                  background:
                    'linear-gradient(90deg, rgba(255, 255, 255, 0.00) -4.17%, #FFF 104.17%)',
                }}
              ></div>
            </div>

            {/* Left Content Section */}
            {showAllBrandsSection && (
              <div className={brandContainerRightWrapper()} key={brandsKey}>
                {brandCategories?.flatMap((item: BrandCategoryType) =>
                  item.fields?.brands?.map((brandItem: BrandCard, key) => (
                    <BrandSingleCard key={key} {...brandItem} />
                  ))
                )}
              </div>
            )}

            {/* Brand Card Right Section */}
            {showSelectedCategorySection ? (
              !loading ? (
                <div className={brandContainerWrapper()}>
                  {brandCategories?.some(
                    (item: BrandCategoryType) =>
                      item.fields?.title?.value === selectedCategory &&
                      item.fields?.brandCardTitle?.value?.trim() !== ''
                  ) && (
                    <>
                      {brandCategories
                        ?.filter(
                          (item: BrandCategoryType) =>
                            item.fields?.title?.value === selectedCategory
                        )
                        ?.flatMap(
                          (item: BrandCategoryType) =>
                            item.fields?.brandCardTitle?.value?.trim() !== '' && (
                              <Text
                                data-testid="contentblock"
                                className={brandCardTitle()}
                                field={item.fields?.brandCardTitle}
                                tag="h5"
                                editable
                              />
                            )
                        )}
                      {brandCategories
                        ?.filter(
                          (item: BrandCategoryType) =>
                            item.fields?.title?.value === selectedCategory
                        )
                        ?.flatMap(
                          (item: BrandCategoryType) =>
                            item.fields?.brandCardDescription?.value?.trim() !== '' && (
                              <RichTextA11yWrapper
                                data-testid="contentblock"
                                className={brandCardDescription()}
                                field={item.fields?.brandCardDescription}
                                editable
                              />
                            )
                        )}
                    </>
                  )}

                  {/* Brand Cards Tile */}
                  <div className={brandContainerRightWrapper()} key={categoryKey}>
                    {brandCategories
                      ?.filter(
                        (item: BrandCategoryType) => item.fields?.title?.value === selectedCategory
                      )
                      ?.flatMap((item: BrandCategoryType) =>
                        item.fields?.brands?.map((brandItem: BrandCard, key) => (
                          <BrandSingleCard key={key} {...brandItem} />
                        ))
                      )}
                  </div>
                  {/* Archive Section */}
                  <div className={archiveSection()}>
                    {brandCategories
                      ?.filter(
                        (item: BrandCategoryType) => item.fields?.title?.value === selectedCategory
                      )
                      ?.flatMap(
                        (item: BrandCategoryType) =>
                          item.fields?.archivesHeadline && (
                            <Text
                              encode={false}
                              tag="h2"
                              className={titleArchives()}
                              field={item.fields?.archivesHeadline as TextField | undefined}
                            />
                          )
                      )}
                    {brandCategories
                      ?.filter(
                        (item: BrandCategoryType) => item.fields?.title?.value === selectedCategory
                      )
                      ?.flatMap(
                        (item: BrandCategoryType) =>
                          item.fields?.archivesDescription && (
                            <RichTextA11yWrapper
                              data-testid="contentblock"
                              className={descriptionArchives()}
                              field={item.fields?.archivesDescription}
                              editable
                            />
                          )
                      )}
                    <div className={archiveCTA()}>
                      {brandCategories
                        ?.filter(
                          (item: BrandCategoryType) =>
                            item.fields?.title?.value === selectedCategory
                        )
                        ?.flatMap(
                          (item: BrandCategoryType) =>
                            item.fields?.archivesCTA?.value?.text && (
                              <Button
                                href={item.fields?.archivesCTA?.value?.href}
                                label={item.fields?.archivesCTA?.value?.text}
                                tag="a"
                                type={fallbackComponentVariantType}
                                target={item.fields?.archivesCTA?.value?.target}
                                gtmEvent={{
                                  event: 'cta_click',
                                  type: 'primary',
                                  'gtm.element.dataset.gtmLinkUrl':
                                    item.fields?.archivesCTA?.value?.href,
                                  'gtm.element.dataset.gtmLinkName':
                                    item.fields?.archivesCTA?.value?.text,
                                  'gtm.element.dataset.gtmDatasourceId': dataSource,
                                  'gtm.element.dataset.gtmComponentName': componentName,
                                }}
                              />
                            )
                        )}
                    </div>
                  </div>
                </div>
              ) : themeName === 'PIDS' ? (
                <div className={loaderWrapper()}>
                  <div className={searchLoaderAnimation()} />
                </div>
              ) : null
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const FilterTabSingleCard = (props: BrandCard): JSX.Element => {
  const { brandImage, brandLink, brandName, brandDescription } = props?.fields || {};

  const { cardWrapper, filterTabContent, imageTab, cardTitleText, cardDescriptionText } =
    tailwindVariantsBrandCard({});
  return (
    <div className={cardWrapper()}>
      {brandImage?.value?.src && brandLink?.value?.href && (
        <LinkWrapper field={brandLink} suppressLinkText ignoreEE>
          <ImageWrapper className={imageTab()} field={brandImage} layout="intrinsic" />
          <div className={filterTabContent()}>
            <div className={cardTitleText()}>
              {brandName?.value && <Text tag="h2" encode={false} field={brandName} />}
            </div>

            <div className={cardDescriptionText()}>
              {brandDescription?.value && <Text encode={false} field={brandDescription} />}
            </div>
          </div>
        </LinkWrapper>
      )}
    </div>
  );
};

export const FilterTab = (props: BrandCardProps): JSX.Element => {
  const { brandCategories, allBrandsText } = props?.fields || {};

  const [showAllBrands, setShowAllBrands] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [brandsKey, setBrandsKey] = useState(0); // re-render of "All Brands"
  const [categoryKey, setCategoryKey] = useState(0); // re-render of "Selected Category"

  const { themeName } = useTheme();

  // Handles clicking the "Show All Brands" button
  const handleAllBrandsClick = () => {
    setShowAllBrands(true);
    setSelectedCategory(null);
    setBrandsKey((prevKey) => prevKey + 1); // Increment brandsKey to trigger re-render
  };

  // Handles clicking a specific category
  const handleCategoryClick = (categoryTitle: string) => {
    setSelectedCategory(categoryTitle);
    setShowAllBrands(false);
    setCategoryKey((prevKey) => prevKey + 1); // Increment categoryKey to trigger re-render
  };

  if (!props.fields) return <></>;

  const {
    filterTabcomponentBG,
    base,
    filterTabcontentWrapper,
    filterTabButtons,
    filterTabBrandTitle,
    filterTabContainer,
    filterTabContainerWrapper,
    svgClassLarge,
    svgClassExtraLarge,
    gradientMobile,
  } = tailwindVariantsBrandCard({});

  // Unique id for component
  const id = props?.params?.RenderingIdentifier;

  return (
    <div className={filterTabcomponentBG()} id={id ? id : undefined}>
      <div className={base()} data-component="authorable/general/filterTab" data-testid="filterTab">
        <div className={filterTabcontentWrapper()}>
          <div className={filterTabButtons()}>
            <div
              className={`${filterTabBrandTitle()} ${
                showAllBrands &&
                'bg-components-button-group-color-button-bg-active hover:!bg-components-button-group-color-button-bg-active !text-components-button-group-color-button-fg-active'
              }`}
              onClick={handleAllBrandsClick}
            >
              {allBrandsText?.value && <Text encode={false} field={allBrandsText} />}
            </div>
            {brandCategories?.map((item: BrandCategoryType, key) => (
              <div
                key={key}
                className={`${filterTabBrandTitle()} ${
                  selectedCategory === item?.fields?.title?.value &&
                  'bg-components-button-group-color-button-bg-active hover:!bg-components-button-group-color-button-bg-active !text-components-button-group-color-button-fg-active'
                }`}
                onClick={() => handleCategoryClick(item?.fields?.title?.value || '')}
              >
                <Text encode={false} field={item?.fields?.title as TextField | undefined} />
              </div>
            ))}
            <div
              className={gradientMobile()}
              style={{
                background:
                  'linear-gradient(90deg, rgba(255, 255, 255, 0.00) -4.17%, #F0F2F7 104.17%)',
              }}
            ></div>
          </div>
          <SVG className={svgClassLarge()} svg={`rule-lines/Breakpoint=Large,Brand=${themeName}`} />
          <SVG
            className={svgClassExtraLarge()}
            svg={`rule-lines/Breakpoint=ExtraLarge,Brand=${themeName}`}
          />
          <div className={filterTabContainer()}>
            {/* Brands Display */}
            {showAllBrands ? (
              <div className={filterTabContainerWrapper()} key={brandsKey}>
                {/* This key will re-render "Show All Brands" */}
                {brandCategories?.flatMap((item: BrandCategoryType) =>
                  item.fields?.brands?.map((brandItem: BrandCard, key) => (
                    <FilterTabSingleCard
                      key={key}
                      {...brandItem}
                      numOfCards={brandCategories?.length}
                    />
                  ))
                )}
              </div>
            ) : selectedCategory ? (
              <div className={filterTabContainerWrapper()} key={categoryKey}>
                {/* This key will re-render "Selected Category" */}
                {brandCategories
                  ?.filter(
                    (item: BrandCategoryType) => item.fields?.title?.value === selectedCategory
                  )
                  ?.flatMap((item: BrandCategoryType) =>
                    item.fields?.brands?.map((brandItem: BrandCard, key) => (
                      <FilterTabSingleCard
                        key={key}
                        {...brandItem}
                        numOfCards={brandCategories?.length}
                      />
                    ))
                  )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export const WithLinkText = (props: BrandCardProps): JSX.Element => {
  const { componentName, dataSource } = props?.rendering || {};

  const {
    brandCategories,
    allBrandsText,
    description,
    primaryCTA,
    title,
    primaryCTAColor,
    primaryCTAType,
  } = props?.fields || {};

  const [showAllBrands, setShowAllBrands] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [brandsKey, setBrandsKey] = useState(0); // re-render of "All Brands"
  const [categoryKey, setCategoryKey] = useState(0); // re-render of "Selected Category"

  // Handles clicking the "Show All Brands" button
  const handleAllBrandsClick = () => {
    setShowAllBrands(true);
    setSelectedCategory(null);
    setBrandsKey((prevKey) => prevKey + 1); // Increment brandsKey to trigger re-render
  };

  // Handles clicking a specific category
  const handleCategoryClick = (categoryTitle: string) => {
    setSelectedCategory(categoryTitle);
    setShowAllBrands(false);
    setCategoryKey((prevKey) => prevKey + 1); // Increment categoryKey to trigger re-render
  };

  if (!props.fields) return <></>;

  const {
    componentBG,
    base,
    contentWrapper,
    titleAndDescriptionWrapperOuter,
    titleAndDescriptionWrapperInner,
    titleText,
    descriptionText,
    brandContainer,
    brandContainerLeftWrapper,
    brandContainerRightWrapper,
    brandTitle,
    gradientMobile,
  } = tailwindVariantsBrandCard({
    grid: props?.params?.grid as Grid,
  });

  // Unique id for component
  const id = props?.params?.RenderingIdentifier;

  return (
    <div className={componentBG()} id={id ? id : undefined} tabIndex={id ? -1 : 1}>
      <div className={base()} data-component="authorable/general/brandcard" data-testid="brandcard">
        <div className={contentWrapper()}>
          {title?.value && (
            <div className={titleAndDescriptionWrapperOuter()}>
              <div className={titleAndDescriptionWrapperInner()}>
                {title?.value && <Text className={titleText()} encode={false} field={title} />}
                {description?.value && (
                  <Text className={descriptionText()} encode={false} field={description} />
                )}
              </div>
              {primaryCTA?.value?.href && (
                <Button
                  href={primaryCTA?.value?.href}
                  label={primaryCTA?.value?.text}
                  tag="a"
                  color={primaryCTAColor?.value || fallbackComponentVariantColor}
                  type={primaryCTAType?.value || fallbackComponentVariantType}
                  target={primaryCTA?.value?.target}
                  size="compressed"
                  gtmEvent={{
                    event: 'cta_click',
                    type: 'primary',
                    'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                    'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                    'gtm.element.dataset.gtmDatasourceId': dataSource,
                    'gtm.element.dataset.gtmComponentName': componentName,
                  }}
                />
              )}
            </div>
          )}
          <div className={brandContainer()}>
            <div className={brandContainerLeftWrapper()}>
              <div
                className={`${brandTitle()} ${
                  showAllBrands &&
                  'bg-components-button-group-color-button-bg-active hover:!bg-components-button-group-color-button-bg-active !text-components-button-group-color-button-fg-active'
                }`}
                onClick={handleAllBrandsClick}
              >
                {allBrandsText?.value && <Text encode={false} field={allBrandsText} />}
              </div>
              {brandCategories?.map((item: BrandCategoryType, key) => (
                <div
                  key={key}
                  className={`${brandTitle()} ${
                    selectedCategory === item?.fields?.title?.value &&
                    'bg-components-button-group-color-button-bg-active hover:!bg-components-button-group-color-button-bg-active !text-components-button-group-color-button-fg-active'
                  }`}
                  onClick={() => handleCategoryClick(item?.fields?.title?.value || '')}
                >
                  {item?.fields?.title && (
                    <Text encode={false} field={item?.fields?.title as TextField | undefined} />
                  )}
                </div>
              ))}
              <div
                className={gradientMobile()}
                style={{
                  background:
                    'linear-gradient(90deg, rgba(255, 255, 255, 0.00) -4.17%, #FFF 104.17%)',
                }}
              ></div>
            </div>
            {showAllBrands ? (
              <div className={brandContainerRightWrapper()} key={brandsKey}>
                {/* This key used to re-render "Show All Brands" */}
                {brandCategories?.flatMap((item: BrandCategoryType) =>
                  item.fields?.brands?.map((brandItem: BrandCard, key) => (
                    <BrandSingleCard key={key} {...brandItem} />
                  ))
                )}
              </div>
            ) : selectedCategory ? (
              <div className={brandContainerRightWrapper()} key={categoryKey}>
                {/* This key used to re-render "Selected Category" */}
                {brandCategories
                  ?.filter(
                    (item: BrandCategoryType) => item.fields?.title?.value === selectedCategory
                  )
                  ?.flatMap((item: BrandCategoryType) =>
                    item.fields?.brands?.map((brandItem: BrandCard, key) => (
                      <BrandSingleCard key={key} {...brandItem} />
                    ))
                  )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandCard;
