// Global
import { createContext, useContext } from 'react';

//Local
import jssConfig from 'src/temp/config';

export const ALL_THEMES = [
  'Vanilla',
  'Ziploc',
  'Off',
  'Autan',
  'Corporate',
  'AutanDefense',
  'OffDefense',
  'Glade',
  'Raid',
  'Baygon',
  'Duck',
  'Pyrel',
  'MrMuscle',
  'Pledge',
  'Drano',
  'Lysoform',
  'PIDS',
] as const;

const [_VANILLA, ...OTHER_THEMES] = ALL_THEMES;

export const ALL_TAILWIND_THEMES = ['DART', ...OTHER_THEMES] as const;

export type ThemeName = (typeof ALL_THEMES)[number];
export type TailwindThemeName = (typeof ALL_TAILWIND_THEMES)[number];

export type ThemeFile = {
  [key in ThemeName]: undefined;
};

export const ThemeContext = !jssConfig.fallbackAppName
  ? createContext<ThemeName>('Vanilla')
  : createContext<ThemeName>(jssConfig.fallbackAppName as ThemeName);

export type Theme = {
  /** When used for anything other than Tailwind theme variables, use this. */
  themeName: ThemeName;
  themeData?: Record<string, unknown>;
  /** When using to reference Tailwind theme variables, use this.  "Vanilla" is changed to "DART" */
  tailwindThemeName: TailwindThemeName;
};

export const useTheme = (themeFile?: ThemeFile): Theme => {
  const themeName = useContext(ThemeContext);
  const themeData = themeFile ? themeFile[themeName] : undefined;
  const tailwindThemeName = themeName === 'Vanilla' ? 'DART' : themeName;
  return { themeName, themeData, tailwindThemeName };
};
