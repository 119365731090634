module.exports = {
  "brands": {
    "Autan": {
      "colors": {
        "components-accessibility-bar-color-bg": "#183d20",
        "components-accessibility-bar-color-text": "#ffffff",
        "components-accordion-color-bg": "#ffffff",
        "components-accordion-color-title": "#191b1c",
        "components-accordion-color-body": "#27282b",
        "components-accordion-color-section-section-bg": "#ffffff",
        "components-accordion-color-section-section-title": "#191b1c",
        "components-accordion-color-section-section-body": "#27282b",
        "components-accordion-color-item-item-bg": "#ffffff",
        "components-accordion-color-item-divider": "#e8e8ea",
        "components-accordion-color-item-icon-icon-default": "#d7252d",
        "components-accordion-color-item-icon-icon-hover": "#ffffff",
        "components-accordion-color-item-icon-icon-pressed": "#ffffff",
        "components-accordion-color-item-icon-icon-active": "#ffffff",
        "components-accordion-color-item-title-item-default": "#d7252d",
        "components-accordion-color-item-title-item-hover": "#ffffff",
        "components-accordion-color-item-title-item-pressed": "#ffffff",
        "components-accordion-color-item-title-item-active": "#ffffff",
        "components-accordion-color-content-bg": "#ffffff",
        "components-accordion-color-content-title": "#191b1c",
        "components-accordion-color-content-body": "#27282b",
        "components-article-card-color-bg": "#fcfcfd",
        "components-article-card-color-border": "#e8e8ea",
        "components-article-card-color-content-bg": "#fcfcfd",
        "components-article-card-color-title": "#191b1c",
        "components-article-card-color-subtitle": "#27282b",
        "components-article-card-color-copy": "#27282b",
        "components-article-card-color-description": "#27282b",
        "components-article-card-listing-color-default-bg": "#ffffff",
        "components-article-card-listing-color-default-title": "#191b1c",
        "components-article-card-listing-color-default-copy": "#27282b",
        "components-article-hero-color-content-bg": "#ffffff",
        "components-article-hero-color-bg": "#ffffff",
        "components-article-hero-color-headline": "#191b1c",
        "components-article-hero-color-body": "#27282b",
        "components-article-hero-color-detail-text": "#27282b",
        "components-article-hero-color-divider": "#a2a5ab",
        "components-article-hero-color-icon": "#d7252d",
        "components-article-hero-color-play-icon": "#ffffff",
        "components-article-hero-color-selected-stroke": "#0053b4",
        "components-banner-color-bg-default": "#fdf4f5",
        "components-banner-color-icon-default": "#d7252d",
        "components-banner-color-title-default": "#6c1317",
        "components-banner-color-body-default": "#373432",
        "components-banner-color-icon-close-default": "#191b1c",
        "components-banner-color-bg-alert": "#b32b30",
        "components-banner-color-icon-alert": "#ffffff",
        "components-banner-color-title-alert": "#ffffff",
        "components-banner-color-body-alert": "#ffffff",
        "components-banner-color-icon-close-alert": "#ffffff",
        "components-breadcrumb-color-bg": "#ffffff",
        "components-breadcrumb-color-breadcrumb-link-default": "#72757e",
        "components-breadcrumb-color-breadcrumb-link-hover": "#4e5056",
        "components-breadcrumb-color-breadcrumb-link-active": "#27282b",
        "components-breadcrumb-color-icon-divider": "#a2a5ab",
        "components-bug-finder-prompt": "#ffffff",
        "components-button-color-filled-brand-default-bg": "#191b1c",
        "components-button-color-filled-brand-default-text": "#ffffff",
        "components-button-color-filled-brand-default-stroke": "#191b1c",
        "components-button-color-filled-brand-default-icon": "#ffffff",
        "components-button-color-filled-brand-hover-bg": "#27282b",
        "components-button-color-filled-brand-hover-text": "#ffffff",
        "components-button-color-filled-brand-hover-stroke": "#27282b",
        "components-button-color-filled-brand-hover-icon": "#ffffff",
        "components-button-color-filled-brand-pressed-bg": "#27282b",
        "components-button-color-filled-brand-pressed-text": "#ffffff",
        "components-button-color-filled-brand-pressed-stroke": "#27282b",
        "components-button-color-filled-brand-pressed-icon": "#ffffff",
        "components-button-color-filled-brand-disabled-bg": "rgba(25, 27, 28, 0.38)",
        "components-button-color-filled-brand-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-disabled-stroke": "rgba(25, 27, 28, 0.38)",
        "components-button-color-filled-brand-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-focus-bg": "#191b1c",
        "components-button-color-filled-brand-focus-text": "#ffffff",
        "components-button-color-filled-brand-focus-stroke": "#191b1c",
        "components-button-color-filled-brand-focus-icon": "#ffffff",
        "components-button-color-filled-brand-focus-outline": "#286efa",
        "components-button-color-filled-tonal-default-bg": "#fdf4f5",
        "components-button-color-filled-tonal-default-text": "#bd2128",
        "components-button-color-filled-tonal-default-stroke": "#fdf4f5",
        "components-button-color-filled-tonal-default-icon": "#bd2128",
        "components-button-color-filled-tonal-hover-bg": "#bd2128",
        "components-button-color-filled-tonal-hover-text": "#fdf4f5",
        "components-button-color-filled-tonal-hover-stroke": "#bd2128",
        "components-button-color-filled-tonal-hover-icon": "#fdf4f5",
        "components-button-color-filled-tonal-pressed-bg": "#bd2128",
        "components-button-color-filled-tonal-pressed-text": "#fdf4f5",
        "components-button-color-filled-tonal-pressed-stroke": "#bd2128",
        "components-button-color-filled-tonal-pressed-icon": "#fdf4f5",
        "components-button-color-filled-tonal-disabled-bg": "rgba(255, 241, 234, 0.38)",
        "components-button-color-filled-tonal-disabled-text": "rgba(179, 57, 0, 0.38)",
        "components-button-color-filled-tonal-disabled-stroke": "rgba(255, 241, 234, 0.38)",
        "components-button-color-filled-tonal-disabled-icon": "rgba(179, 57, 0, 0.38)",
        "components-button-color-filled-tonal-focus-bg": "#fdf4f5",
        "components-button-color-filled-tonal-focus-text": "#bd2128",
        "components-button-color-filled-tonal-focus-stroke": "#fdf4f5",
        "components-button-color-filled-tonal-focus-icon": "#bd2128",
        "components-button-color-filled-tonal-focus-outline": "#1c4cae",
        "components-button-color-filled-white-default-bg": "#ffffff",
        "components-button-color-filled-white-default-text": "#191b1c",
        "components-button-color-filled-white-default-stroke": "#ffffff",
        "components-button-color-filled-white-default-icon": "#d7252d",
        "components-button-color-filled-white-hover-bg": "#fdf4f5",
        "components-button-color-filled-white-hover-text": "#191b1c",
        "components-button-color-filled-white-hover-stroke": "#fdf4f5",
        "components-button-color-filled-white-hover-icon": "#bd2128",
        "components-button-color-filled-white-pressed-bg": "#fdf4f5",
        "components-button-color-filled-white-pressed-text": "#191b1c",
        "components-button-color-filled-white-pressed-stroke": "#fdf4f5",
        "components-button-color-filled-white-pressed-icon": "#bd2128",
        "components-button-color-filled-white-disabled-bg": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-text": "rgba(25, 27, 28, 0.38)",
        "components-button-color-filled-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-icon": "rgba(254, 80, 0, 0.38)",
        "components-button-color-filled-white-focus-bg": "#ffffff",
        "components-button-color-filled-white-focus-text": "#191b1c",
        "components-button-color-filled-white-focus-stroke": "#ffffff",
        "components-button-color-filled-white-focus-icon": "#d7252d",
        "components-button-color-filled-white-focus-outline": "#1c4cae",
        "components-button-color-outline-brand-default-bg": "rgba(25, 27, 28, 0.00)",
        "components-button-color-outline-brand-default-text": "#191b1c",
        "components-button-color-outline-brand-default-stroke": "#191b1c",
        "components-button-color-outline-brand-default-icon": "#191b1c",
        "components-button-color-outline-brand-hover-bg": "#27282b",
        "components-button-color-outline-brand-hover-text": "#ffffff",
        "components-button-color-outline-brand-hover-stroke": "#27282b",
        "components-button-color-outline-brand-hover-icon": "#ffffff",
        "components-button-color-outline-brand-pressed-bg": "#27282b",
        "components-button-color-outline-brand-pressed-text": "#ffffff",
        "components-button-color-outline-brand-pressed-stroke": "#27282b",
        "components-button-color-outline-brand-pressed-icon": "#ffffff",
        "components-button-color-outline-brand-disabled-bg": "rgba(25, 27, 28, 0.00)",
        "components-button-color-outline-brand-disabled-text": "rgba(25, 27, 28, 0.38)",
        "components-button-color-outline-brand-disabled-stroke": "rgba(25, 27, 28, 0.38)",
        "components-button-color-outline-brand-disabled-icon": "rgba(25, 27, 28, 0.38)",
        "components-button-color-outline-brand-focus-bg": "#ffffff",
        "components-button-color-outline-brand-focus-text": "#191b1c",
        "components-button-color-outline-brand-focus-stroke": "#191b1c",
        "components-button-color-outline-brand-focus-icon": "#191b1c",
        "components-button-color-outline-brand-focus-outline": "#286efa",
        "components-button-color-outline-tonal-default-bg": "#ffffff",
        "components-button-color-outline-tonal-default-text": "#d7252d",
        "components-button-color-outline-tonal-default-stroke": "#d7252d",
        "components-button-color-outline-tonal-default-icon": "#d7252d",
        "components-button-color-outline-tonal-hover-bg": "#d7252d",
        "components-button-color-outline-tonal-hover-text": "#ffffff",
        "components-button-color-outline-tonal-hover-stroke": "#d7252d",
        "components-button-color-outline-tonal-hover-icon": "#ffffff",
        "components-button-color-outline-tonal-pressed-bg": "#d7252d",
        "components-button-color-outline-tonal-pressed-text": "#ffffff",
        "components-button-color-outline-tonal-pressed-stroke": "#d7252d",
        "components-button-color-outline-tonal-pressed-icon": "#ffffff",
        "components-button-color-outline-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-tonal-disabled-text": "rgba(254, 80, 0, 0.38)",
        "components-button-color-outline-tonal-disabled-stroke": "rgba(254, 80, 0, 0.38)",
        "components-button-color-outline-tonal-disabled-icon": "rgba(254, 80, 0, 0.38)",
        "components-button-color-outline-tonal-focus-bg": "#ffffff",
        "components-button-color-outline-tonal-focus-text": "#d7252d",
        "components-button-color-outline-tonal-focus-stroke": "#d7252d",
        "components-button-color-outline-tonal-focus-icon": "#d7252d",
        "components-button-color-outline-tonal-focus-outline": "#286efa",
        "components-button-color-outline-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-default-text": "#ffffff",
        "components-button-color-outline-white-default-stroke": "#ffffff",
        "components-button-color-outline-white-default-icon": "#ffffff",
        "components-button-color-outline-white-hover-bg": "#ffffff",
        "components-button-color-outline-white-hover-text": "#191b1c",
        "components-button-color-outline-white-hover-stroke": "#ffffff",
        "components-button-color-outline-white-hover-icon": "#d7252d",
        "components-button-color-outline-white-pressed-bg": "#ffffff",
        "components-button-color-outline-white-pressed-text": "#191b1c",
        "components-button-color-outline-white-pressed-stroke": "#ffffff",
        "components-button-color-outline-white-pressed-icon": "#d7252d",
        "components-button-color-outline-white-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-focus-bg": "rgba(255, 255, 255, 0.01)",
        "components-button-color-outline-white-focus-text": "#ffffff",
        "components-button-color-outline-white-focus-stroke": "#ffffff",
        "components-button-color-outline-white-focus-icon": "#ffffff",
        "components-button-color-outline-white-focus-outline": "#286efa",
        "components-button-color-text-brand-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-default-text": "#191b1c",
        "components-button-color-text-brand-default-stroke": "#191b1c",
        "components-button-color-text-brand-default-icon": "#191b1c",
        "components-button-color-text-brand-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-hover-bg-icon-only": "#e8e8ea",
        "components-button-color-text-brand-hover-text": "#191b1c",
        "components-button-color-text-brand-hover-stroke": "#191b1c",
        "components-button-color-text-brand-hover-icon": "#191b1c",
        "components-button-color-text-brand-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-pressed-bg-icon-only": "#e8e8ea",
        "components-button-color-text-brand-pressed-text": "#191b1c",
        "components-button-color-text-brand-pressed-stroke": "#191b1c",
        "components-button-color-text-brand-pressed-icon": "#191b1c",
        "components-button-color-text-brand-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-disabled-text": "rgba(25, 27, 28, 0.38)",
        "components-button-color-text-brand-disabled-stroke": "rgba(25, 27, 28, 0.38)",
        "components-button-color-text-brand-disabled-icon": "rgba(25, 27, 28, 0.38)",
        "components-button-color-text-brand-focus-bg": "#d5e3ff",
        "components-button-color-text-brand-focus-text": "#191b1c",
        "components-button-color-text-brand-focus-stroke": "#191b1c",
        "components-button-color-text-brand-focus-icon": "#1c4cae",
        "components-button-color-text-brand-focus-outline": "#1c4cae",
        "components-button-color-text-tonal-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-default-text": "#d7252d",
        "components-button-color-text-tonal-default-stroke": "#d7252d",
        "components-button-color-text-tonal-default-icon": "#d7252d",
        "components-button-color-text-tonal-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-hover-bg-icon-only": "#fdf4f5",
        "components-button-color-text-tonal-hover-text": "#bd2128",
        "components-button-color-text-tonal-hover-stroke": "#d7252d",
        "components-button-color-text-tonal-hover-icon": "#bd2128",
        "components-button-color-text-tonal-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-pressed-bg-icon-only": "#fdf4f5",
        "components-button-color-text-tonal-pressed-text": "#bd2128",
        "components-button-color-text-tonal-pressed-stroke": "#d7252d",
        "components-button-color-text-tonal-pressed-icon": "#bd2128",
        "components-button-color-text-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-disabled-text": "rgba(254, 80, 0, 0.38)",
        "components-button-color-text-tonal-disabled-stroke": "rgba(254, 80, 0, 0.38)",
        "components-button-color-text-tonal-disabled-icon": "rgba(254, 80, 0, 0.38)",
        "components-button-color-text-tonal-focus-bg": "#d5e3ff",
        "components-button-color-text-tonal-focus-text": "#d7252d",
        "components-button-color-text-tonal-focus-stroke": "#d7252d",
        "components-button-color-text-tonal-focus-icon": "#1c4cae",
        "components-button-color-text-tonal-focus-outline": "#1c4cae",
        "components-button-color-text-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-default-text": "#ffffff",
        "components-button-color-text-white-default-stroke": "#ffffff",
        "components-button-color-text-white-default-icon": "#ffffff",
        "components-button-color-text-white-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-hover-bg-icon-only": "#27282b",
        "components-button-color-text-white-hover-text": "#ffffff",
        "components-button-color-text-white-hover-stroke": "#ffffff",
        "components-button-color-text-white-hover-icon": "#ffffff",
        "components-button-color-text-white-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-pressed-bg-icon-only": "#27282b",
        "components-button-color-text-white-pressed-text": "#ffffff",
        "components-button-color-text-white-pressed-stroke": "#ffffff",
        "components-button-color-text-white-pressed-icon": "#ffffff",
        "components-button-color-text-white-disabled-bg": "rgba(115, 119, 128, 0.00)",
        "components-button-color-text-white-disabled-text": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-stroke": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-icon": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-focus-bg": "#d5e3ff",
        "components-button-color-text-white-focus-text": "#ffffff",
        "components-button-color-text-white-focus-stroke": "#ffffff",
        "components-button-color-text-white-focus-icon": "#1c4cae",
        "components-button-color-text-white-focus-outline": "#1c4cae",
        "components-button-group-color-border": "#e8e8ea",
        "components-button-group-color-button-bg-default": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-button-fg-default": "#bd2128",
        "components-button-group-color-button-bg-hover": "#bd2128",
        "components-button-group-color-button-fg-hover": "#fdf4f5",
        "components-button-group-color-button-bg-active": "#fdf4f5",
        "components-button-group-color-button-fg-active": "#bd2128",
        "components-button-group-color-overlay-left-gradient-fill-1": "#ffffff",
        "components-button-group-color-overlay-left-gradient-fill-2": "rgba(255, 255, 255, 0.50)",
        "components-button-group-color-stroke-default-gradient-fill-1": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-2": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-3": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-4": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-1": "#bd2128",
        "components-button-group-color-stroke-hover-gradient-fill-2": "#bd2128",
        "components-button-group-color-stroke-hover-gradient-fill-3": "#bd2128",
        "components-button-group-color-stroke-hover-gradient-fill-4": "#bd2128",
        "components-button-group-color-stroke-active-gradient-fill-1": "#fdf4f5",
        "components-button-group-color-stroke-active-gradient-fill-2": "#fdf4f5",
        "components-button-group-color-stroke-active-gradient-fill-3": "#fdf4f5",
        "components-button-group-color-stroke-active-gradient-fill-4": "#fdf4f5",
        "components-category-card-color-bg": "#ffffff",
        "components-category-card-color-content-bg": "#ffffff",
        "components-category-card-color-category-bg": "#68d759",
        "components-category-card-color-category-bg-2": "#00ccff",
        "components-category-card-color-category-bg-3": "#47b560",
        "components-category-card-color-category-bg-4": "#d7252d",
        "components-category-card-color-category-bg-5": "#0053b4",
        "components-category-card-color-category-bg-6": "#a9e8a0",
        "components-category-card-color-title": "#191b1c",
        "components-category-card-color-copy": "#27282b",
        "components-category-card-color-stroke": "#e8e8ea",
        "components-category-card-color-stroke-hover": "#d7252d",
        "components-category-card-listing-color-bg": "#ffffff",
        "components-category-card-listing-color-title": "#191b1c",
        "components-category-card-listing-color-copy": "#27282b",
        "components-card-search-result-color-bg": "#ffffff",
        "components-card-search-result-color-label": "#27282b",
        "components-card-search-result-color-title": "#191b1c",
        "components-card-search-result-color-body": "#27282b",
        "components-card-search-result-color-divider-line": "#e8e8ea",
        "components-contact-us-promo-color-light-bg": "#fdf4f5",
        "components-contact-us-promo-color-light-title": "#191b1c",
        "components-contact-us-promo-color-light-body": "#27282b",
        "components-contact-us-promo-color-default-bg": "#ffffff",
        "components-contact-us-promo-color-default-title": "#191b1c",
        "components-contact-us-promo-color-default-body": "#27282b",
        "components-contact-us-promo-color-brand-bg": "#d7252d",
        "components-contact-us-promo-color-brand-title": "#ffffff",
        "components-contact-us-promo-color-brand-body": "#fcfcfd",
        "components-content-list-color-bg": "#ffffff",
        "components-content-list-color-title": "#191b1c",
        "components-content-list-color-body": "#27282b",
        "components-content-list-color-icon": "#d7252d",
        "components-cookies-colors-bg": "#ffffff",
        "components-cookies-colors-title": "#191b1c",
        "components-cookies-colors-body": "#27282b",
        "components-cookies-colors-icon": "#d7252d",
        "components-dropdown-bg": "#ffffff",
        "components-dropdown-border-default": "#72757e",
        "components-dropdown-border-hover": "#d7252d",
        "components-dropdown-border-focused": "#191b1c",
        "components-dropdown-border-destructive": "#b32b30",
        "components-dropdown-label-text-default": "#191b1c",
        "components-dropdown-item-text-default": "#191b1c",
        "components-dropdown-item-bg-default": "#ffffff",
        "components-dropdown-item-bg-hover": "#fdf4f5",
        "components-dropdown-input-field-default": "#72757e",
        "components-dropdown-input-field-focused": "#191b1c",
        "components-dropdown-input-label-focused": "#191b1c",
        "components-dropdown-input-field-destructive": "#b32b30",
        "components-external-link-card-bg": "#ffffff",
        "components-external-link-card-card-bg": "#ffffff",
        "components-external-link-card-card-title": "#191b1c",
        "components-external-link-card-card-subtitle": "#27282b",
        "components-external-link-card-card-eyebrow": "#4e5056",
        "components-fab-color-border-default": "#ffffff",
        "components-footer-brand-color-bg": "#191b1c",
        "components-footer-brand-color-logo": "#ffffff",
        "components-footer-brand-color-title-text": "#ffffff",
        "components-footer-brand-color-description-text": "#fcfcfd",
        "components-footer-brand-color-footer-menu-expanded": "#191b1c",
        "components-footer-brand-color-link-default": "#ffffff",
        "components-footer-brand-color-link-hover": "#fdf4f5",
        "components-footer-brand-color-link-pressed": "#e77c81",
        "components-footer-brand-color-category-link-default": "#ffffff",
        "components-footer-brand-color-category-link-hover": "#fcfcfd",
        "components-footer-brand-color-category-link-pressed": "#e8e8ea",
        "components-footer-brand-color-subcategory-link-default": "#fcfcfd",
        "components-footer-brand-color-subcategory-link-hover": "#fdf4f5",
        "components-footer-brand-color-subcategory-link-pressed": "#dd484f",
        "components-footer-brand-color-icon-icon-default": "#ffffff",
        "components-footer-brand-color-icon-icon-pressed": "#e8e8ea",
        "components-footer-brand-color-social-icon-default": "#ffffff",
        "components-footer-brand-color-social-icon-hover": "#e77c81",
        "components-footer-brand-color-social-icon-pressed": "#bd2128",
        "components-footer-brand-spacing-category-link-hover": "#000000",
        "components-footer-global-color-bg": "#ffffff",
        "components-footer-global-color-title-text": "#000000",
        "components-footer-global-color-disclaimer-text": "#000000",
        "components-footer-global-color-link-default": "#000000",
        "components-footer-global-color-link-hover": "#000000",
        "components-footer-global-color-link-pressed": "#000000",
        "components-generic-card-color-bg": "#fcfcfd",
        "components-generic-card-color-content-bg": "#fcfcfd",
        "components-generic-card-color-title": "#191b1c",
        "components-generic-card-color-subtitle": "#27282b",
        "components-generic-card-color-copy": "#27282b",
        "components-global-search-color-omnibox-list-bg": "#ffffff",
        "components-global-search-color-omnibox-divider": "#a2a5ab",
        "components-global-search-color-omnibox-item-bg-default": "#ffffff",
        "components-global-search-color-omnibox-item-bg-hover": "#fcfcfd",
        "components-global-search-color-omnibox-item-text-default": "#d7252d",
        "components-global-search-color-omnibox-item-text-hover": "#bd2128",
        "components-global-search-color-omnibox-label-text-default": "#191b1c",
        "components-global-search-color-omnibox-category-text-default": "#191b1c",
        "components-generic-card-listing-color-bg": "#ffffff",
        "components-generic-card-listing-color-title": "#191b1c",
        "components-generic-card-listing-color-copy": "#27282b",
        "components-header-color-bg": "#ffffff",
        "components-header-color-bottom-stroke": "#e8e8ea",
        "components-header-color-search-search-border-default": "#191b1c",
        "components-header-color-search-search-text": "#4e5056",
        "components-header-color-search-search-icon": "#d7252d",
        "components-header-color-search-search-bar": "#191b1c",
        "components-header-color-mobile-mobile-expanded-bg": "#ffffff",
        "components-header-color-mobile-pressed-bg": "#ffffff",
        "components-header-color-language-selector-language": "#4e5056",
        "components-header-color-language-selector-country": "#27282b",
        "components-header-color-language-selector-country-hover-bg": "#fcfcfd",
        "components-header-color-language-selector-megamenu-language": "#4e5056",
        "components-header-color-language-selector-megamenu-country": "#27282b",
        "components-header-color-language-selector-megamenu-country-hover": "#fcfcfd",
        "components-header-color-language-selector-bg": "#ffffff",
        "components-header-color-language-selector-stroke": "#72757e",
        "components-header-color-mega-menu-bg": "#fdf4f5",
        "components-header-color-mega-menu-featured-bg": "#d7252d",
        "components-header-color-mega-menu-featured-title": "#ffffff",
        "components-header-color-mega-menu-category-title": "#27282b",
        "components-header-color-mega-menu-bg-accent": "#ffffff",
        "components-header-color-icon-icon-default": "#191b1c",
        "components-header-color-icon-icon-hover": "#d7252d",
        "components-header-color-icon-icon-pressed": "#bd2128",
        "components-header-color-links-nav-main-link-default": "#191b1c",
        "components-header-color-links-nav-main-link-hover": "#d7252d",
        "components-header-color-links-nav-main-link-pressed": "#bd2128",
        "components-header-color-links-nav-main-link-active": "#971a20",
        "components-header-color-links-nav-main-active-bar": "#d7252d",
        "components-header-color-links-nav-main-bg-color": "#ffffff",
        "components-header-color-links-nav-category-link-category-default": "#27282b",
        "components-header-color-links-nav-category-link-category-hover": "#bd2128",
        "components-header-color-links-nav-category-link-category-pressed": "#bd2128",
        "components-header-color-links-nav-category-link-category-active": "#971a20",
        "components-header-color-links-nav-menu-link-link-default": "#191b1c",
        "components-header-color-links-nav-menu-link-link-hover": "#bd2128",
        "components-header-color-links-nav-menu-link-link-pressed": "#bd2128",
        "components-header-color-links-nav-menu-link-link-active": "#971a20",
        "components-header-color-links-nav-menu-link-bg": "#fdf4f5",
        "components-header-color-links-nav-subcategory-link-subcategory-default": "#27282b",
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": "#bd2128",
        "components-header-color-links-nav-subcategory-link-subcategory-active": "#971a20",
        "components-hero-homepage-color-bg": "#fcfcfd",
        "components-hero-homepage-color-container-bg": "#d7252d",
        "components-hero-homepage-color-eyebrow": "#72757e",
        "components-hero-homepage-color-header": "#d7252d",
        "components-hero-homepage-color-sub-header": "#27282b",
        "components-hero-homepage-color-body": "#27282b",
        "components-hero-homepage-color-play-pause": "#000000",
        "components-hero-internal-color-default-title": "#ffffff",
        "components-hero-internal-color-default-body": "#fcfcfd",
        "components-hero-internal-color-default-bg": "#d7252d",
        "components-hero-internal-color-accent-bg": "#d7252d",
        "components-hero-internal-color-with-image-bg": "#d7252d",
        "components-hero-internal-color-default-bg-2": "#d7252d",
        "components-hero-internal-color-with-image-title": "#ffffff",
        "components-hero-internal-color-with-image-body": "#fcfcfd",
        "components-heading-page-title-color-default-bg": "#ffffff",
        "components-heading-page-title-color-default-title": "#191b1c",
        "components-heading-page-title-color-default-body": "#27282b",
        "components-heading-page-title-color-light-bg": "#fdf4f5",
        "components-heading-page-title-color-light-title": "#191b1c",
        "components-heading-page-title-color-light-body": "#27282b",
        "components-heading-page-title-color-color-bg": "#191b1c",
        "components-heading-page-title-color-color-title": "#ffffff",
        "components-heading-page-title-color-color-body": "#fcfcfd",
        "components-hyperlink-color-dark-default": "#d7252d",
        "components-hyperlink-color-dark-hover": "#bd2128",
        "components-hyperlink-color-dark-visited": "#865ddf",
        "components-hyperlink-color-light-default": "#fdf4f5",
        "components-hyperlink-color-light-hover": "#dd484f",
        "components-hyperlink-color-light-visited": "#ab8fe9",
        "components-icon-default": "#72757e",
        "components-icon-focused": "#72757e",
        "components-icon-card-color-bg": "#ffffff",
        "components-icon-card-color-icon-bg": "#191b1c",
        "components-icon-card-color-icon": "#ffffff",
        "components-icon-card-color-title": "#191b1c",
        "components-icon-card-color-subtitle": "#4e5056",
        "components-icon-card-color-description": "#27282b",
        "components-icon-card-listing-color-bg": "#ffffff",
        "components-icon-card-listing-color-inner-bg": "#ffffff",
        "components-icon-card-listing-color-brand-bg": "#d7252d",
        "components-icon-card-listing-color-title": "#191b1c",
        "components-icon-card-listing-color-copy": "#27282b",
        "components-icon-card-listing-color-inner-legal-text": "#fcfcfd",
        "components-jumplink-color-bg-default": "#ffffff",
        "components-jumplink-color-link-text-default": "#4e5056",
        "components-jumplink-color-link-text-hover": "#971a20",
        "components-jumplink-color-link-border-hover": "#971a20",
        "components-jumplink-color-link-text-active": "#d7252d",
        "components-jumplink-color-link-border-active": "#d7252d",
        "components-jumplink-color-title-text": "#191b1c",
        "components-jumplink-color-description-text": "#27282b",
        "components-layout-bg-default": "#ffffff",
        "components-lightbox-color-overlay-bg": "rgba(255, 255, 255, 0.95)",
        "components-lightbox-color-title": "#191b1c",
        "components-lightbox-color-body": "#27282b",
        "components-label-color-on-color-label-label-default": "#ffffff",
        "components-label-color-on-color-label-label-hover": "#4e5056",
        "components-label-color-on-color-label-label-pressed": "#27282b",
        "components-label-color-on-color-background-bg-default": "#72757e",
        "components-label-color-on-color-background-bg-hover": "#ffffff",
        "components-label-color-on-color-background-bg-pressed": "#e8e8ea",
        "components-label-color-on-color-stroke-hover": "#ffffff",
        "components-label-color-on-color-stroke-pressed": "#e8e8ea",
        "components-label-color-on-color-stroke-default-gradient-fill-1": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-2": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-3": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-4": "#ffffff",
        "components-label-color-on-white-label-label-default": "#4e5056",
        "components-label-color-on-white-label-label-hover": "#ffffff",
        "components-label-color-on-white-label-label-pressed": "#ffffff",
        "components-label-color-on-white-background-bg-default": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-white-background-bg-hover": "#72757e",
        "components-label-color-on-white-background-bg-pressed": "#4e5056",
        "components-label-color-on-white-stroke-hover": "#72757e",
        "components-label-color-on-white-stroke-pressed": "#4e5056",
        "components-label-color-on-white-stroke-default-gradient-fill-1": "#72757e",
        "components-label-color-on-white-stroke-default-gradient-fill-2": "#72757e",
        "components-label-color-on-white-stroke-default-gradient-fill-3": "#72757e",
        "components-label-color-on-white-stroke-default-gradient-fill-4": "#72757e",
        "components-legal-disclaimer-color-default-bg": "#ffffff",
        "components-legal-disclaimer-color-default-title": "#191b1c",
        "components-legal-disclaimer-color-default-body": "#27282b",
        "components-legal-disclaimer-color-light-bg": "#fdf4f5",
        "components-legal-disclaimer-color-light-title": "#191b1c",
        "components-legal-disclaimer-color-light-body": "#27282b",
        "components-legal-disclaimer-color-brand-bg": "#191b1c",
        "components-legal-disclaimer-color-brand-title": "#ffffff",
        "components-legal-disclaimer-color-brand-body": "#fcfcfd",
        "components-media-video-thumbnail-overlay-bg": "rgba(25, 27, 28, 0.80)",
        "components-media-caption-color-bg": "#fcfcfd",
        "components-media-caption-color-text": "#27282b",
        "components-media-gallery-color-bg": "#ffffff",
        "components-media-gallery-color-title": "#191b1c",
        "components-media-gallery-color-body": "#27282b",
        "components-pagination-on-white-accent-default": "#27282b",
        "components-pagination-on-white-accent-scroll": "#d7252d",
        "components-pagination-on-white-disabled": "#a2a5ab",
        "components-pagination-on-white-bg": "#e8e8ea",
        "components-pagination-on-white-stroke": "#e8e8ea",
        "components-pagination-on-white-bg-active": "#191b1c",
        "components-pagination-on-white-bg-hover": "#e8e8ea",
        "components-pagination-on-white-text-default": "#72757e",
        "components-pagination-on-white-text-hover": "#27282b",
        "components-pagination-on-white-text-active": "#ffffff",
        "components-pagination-on-color-accent-default": "#ffffff",
        "components-pagination-on-color-accent-scroll": "#27282b",
        "components-pagination-on-color-disabled": "#6c1317",
        "components-pagination-on-color-bg": "#fcfcfd",
        "components-pagination-on-color-stroke": "rgba(255, 255, 255, 0.38)",
        "components-pagination-on-color-bg-active": "#ffffff",
        "components-pagination-on-color-bg-hover": "#27282b",
        "components-pagination-on-color-text-default": "#ffffff",
        "components-pagination-on-color-text-hover": "#ffffff",
        "components-pagination-on-color-text-active": "#191b1c",
        "components-pdf-link-card-color-bg": "#ffffff",
        "components-pdf-link-card-color-card-bg": "#ffffff",
        "components-pdf-link-card-color-card-title": "#191b1c",
        "components-pdf-link-card-color-card-subtitle": "#27282b",
        "components-pdf-link-card-color-card-body": "#27282b",
        "components-product-card-color-bg": "#fcfcfd",
        "components-product-card-color-content-bg": "#fcfcfd",
        "components-product-card-color-border": "#fcfcfd",
        "components-product-card-color-title": "#191b1c",
        "components-product-card-color-description": "#27282b",
        "components-product-card-color-star-rating": "#d7252d",
        "components-product-card-color-badge-bg": "#bd2128",
        "components-product-card-color-badge-text": "#ffffff",
        "components-product-card-listing-color-bg": "#ffffff",
        "components-product-card-listing-color-title": "#191b1c",
        "components-product-card-listing-color-copy": "#27282b",
        "components-product-card-listing-color-legal-text": "#fcfcfd",
        "components-product-information-color-bg": "#ffffff",
        "components-product-information-color-content-bg": "#ffffff",
        "components-product-information-color-headline": "#191b1c",
        "components-product-information-color-body": "#27282b",
        "components-product-information-color-detail-text": "#27282b",
        "components-product-information-color-divider": "#a2a5ab",
        "components-product-information-color-icon": "#d7252d",
        "components-product-information-color-play-icon": "#ffffff",
        "components-product-information-color-selected-stroke": "#0053b4",
        "components-product-results-list-with-search-color-bg": "#ffffff",
        "components-promo-color-default-bg": "#ffffff",
        "components-promo-color-default-image-bg": "#ffffff",
        "components-promo-color-default-content-bg": "#ffffff",
        "components-promo-color-default-title": "#191b1c",
        "components-promo-color-default-body": "#27282b",
        "components-promo-color-default-category": "#191b1c",
        "components-promo-color-light-bg": "#fdf4f5",
        "components-promo-color-light-image-bg": "#fdf4f5",
        "components-promo-color-light-content-bg": "#fdf4f5",
        "components-promo-color-light-title": "#191b1c",
        "components-promo-color-light-body": "#27282b",
        "components-promo-color-light-category": "#191b1c",
        "components-promo-color-brand-bg": "#d7252d",
        "components-promo-color-brand-image-bg": "#d7252d",
        "components-promo-color-brand-content-bg": "#d7252d",
        "components-promo-color-brand-title": "#ffffff",
        "components-promo-color-brand-body": "#fcfcfd",
        "components-promo-color-brand-category": "#fcfcfd",
        "components-pull-quote-color-bg": "#fdf4f5",
        "components-pull-quote-color-stroke": "#0053b4",
        "components-pull-quote-color-quote-bg": "#ffffff",
        "components-pull-quote-color-quote-title": "#191b1c",
        "components-pull-quote-color-quote-by-text": "#27282b",
        "components-pull-quote-color-quote-detail": "#72757e",
        "components-pull-quote-color-quote-highlight": "#d7252d",
        "components-promo-alternate-color-default-bg": "#ffffff",
        "components-promo-alternate-color-default-image-bg": "#ffffff",
        "components-promo-alternate-color-default-content-bg": "#ffffff",
        "components-promo-alternate-color-default-title": "#191b1c",
        "components-promo-alternate-color-default-body": "#27282b",
        "components-promo-alternate-color-default-category": "#191b1c",
        "components-promo-alternate-color-light-bg": "#fdf4f5",
        "components-promo-alternate-color-light-image-bg": "#fdf4f5",
        "components-promo-alternate-color-light-content-bg": "#fdf4f5",
        "components-promo-alternate-color-light-title": "#191b1c",
        "components-promo-alternate-color-light-body": "#27282b",
        "components-promo-alternate-color-light-category": "#191b1c",
        "components-promo-alternate-color-brand-bg": "#d7252d",
        "components-promo-alternate-color-brand-image-bg": "#d7252d",
        "components-promo-alternate-color-brand-content-bg": "#d7252d",
        "components-promo-alternate-color-brand-title": "#ffffff",
        "components-promo-alternate-color-brand-body": "#fcfcfd",
        "components-promo-alternate-color-brand-category": "#fcfcfd",
        "components-share-list-color-title": "#27282b",
        "components-share-list-color-bg": "#fcfcfd",
        "components-share-list-color-link-default": "#27282b",
        "components-share-list-color-link-hover": "#d7252d",
        "components-share-list-color-bg-link-hover": "#e8e8ea",
        "components-share-list-color-close-icon": "#27282b",
        "components-share-list-color-facebook-icon": "#3b5998",
        "components-share-list-color-pinterest-icon": "#e60023",
        "components-share-list-color-twitter-icon": "#000000",
        "components-share-list-color-basic-icon-default": "#27282b",
        "components-share-list-color-icon-hover": "#d7252d",
        "components-share-list-color-border": "#e8e8ea",
        "components-site-search-bg": "#ffffff",
        "components-site-search-results_text": "#27282b",
        "components-site-search-filter-filter-selected-bg": "#fdf4f5",
        "components-site-search-facet-group-label-bottom-line": "#e8e8ea",
        "components-site-search-facet-group-label-text": "#27282b",
        "components-site-search-facet-group-label-bg": "#ffffff",
        "components-site-search-facet-bg": "#ffffff",
        "components-site-search-facet-stroke": "#e8e8ea",
        "components-site-search-refine-bg": "#ffffff",
        "components-site-search-refine-right-divider": "#fdf4f5",
        "components-site-search-refine-title": "#191b1c",
        "components-site-search-hero-search-bg": "#d7252d",
        "components-site-search-hero-search-accent-bg": "#bd2128",
        "components-site-search-hero-search-title": "#ffffff",
        "components-stats-stat-text": "#d7252d",
        "components-stats-stat-text-on-light": "#d7252d",
        "components-stats-supporting-text": "#27282b",
        "components-stats-bg-default": "#ffffff",
        "components-stats-bg-inner": "#ffffff",
        "components-stats-bg-section-inner": "#d7252d",
        "components-stats-title": "#191b1c",
        "components-stats-copy": "#27282b",
        "components-supporting-text-default": "#4e5056",
        "components-supporting-text-destructive": "#b32b30",
        "components-tab-bg": "#ffffff",
        "components-tab-border-default": "#72757e",
        "components-tab-border-hover": "#d7252d",
        "components-tab-border-focused": "#191b1c",
        "components-tab-border-destructive": "#b32b30",
        "components-tab-label-text-default": "#191b1c",
        "components-tab-item-text-default": "#191b1c",
        "components-tab-item-bg-default": "#ffffff",
        "components-tab-item-bg-hover": "#fdf4f5",
        "components-tab-input-field-default": "#72757e",
        "components-tab-input-field-focused": "#191b1c",
        "components-tab-input-label-focused": "#191b1c",
        "components-tab-input-field-destructive": "#b32b30",
        "components-tab-bg-2": "#ffffff",
        "components-tag-color-bg": "#ffffff",
        "components-tag-color-text": "#191b1c",
        "components-tag-color-stroke": "#d7252d",
        "components-tag-color-bg-hover": "#d7252d",
        "components-tag-color-text-hover": "#ffffff",
        "components-tag-color-stroke-hover": "#d7252d",
        "components-text-field-bg": "#fcfcfd",
        "components-text-field-bg-focused": "#fdf4f5",
        "components-text-field-border-default": "#191b1c",
        "components-text-field-border-hover": "#d7252d",
        "components-text-field-border-focused": "#191b1c",
        "components-text-field-border-destructive": "#b32b30",
        "components-text-field-input-field-default": "#4e5056",
        "components-text-field-input-field-focused": "#191b1c",
        "components-text-field-input-label-focused": "#191b1c",
        "components-text-field-input-field-destructive": "#b32b30",
        "components-text-field-icon-default": "#72757e",
        "components-text-field-icon-destructive": "#b32b30",
        "components-text-field-supporting-text-default": "#4e5056",
        "components-text-field-supporting-text-destructive": "#b32b30",
        "components-tip-color-bg": "#fdf4f5",
        "components-tip-color-title": "#191b1c",
        "components-tip-color-body": "#27282b",
        "components-tip-color-stroke": "#e77c81",
        "components-video-color-bg": "#ffffff",
        "themes-general-surface-color-light-bg": "#ffffff",
        "themes-general-surface-color-light-title": "#191b1c",
        "themes-general-surface-color-light-body": "#27282b",
        "themes-general-surface-color-light-decor-line-break": "#e77c81",
        "themes-general-surface-color-light-rule-line": "#e77c81",
        "themes-general-surface-color-dark-bg": "#d7252d",
        "themes-general-surface-color-dark-title": "#ffffff",
        "themes-general-surface-color-dark-body": "#fcfcfd",
        "themes-general-surface-color-dark-decor-line-break": "#e77c81",
        "themes-general-card-color-dark-title": "#191b1c",
        "themes-general-card-color-dark-subtitle": "#27282b",
        "themes-general-card-color-dark-body": "#27282b",
        "themes-general-card-color-light-title": "#ffffff",
        "themes-general-card-color-light-subtitle": "#fcfcfd",
        "themes-general-card-color-light-body": "#fcfcfd",
        "themes-shadow-color": "#ffffff",
        "themes-shadow-share-list-color-fill": "rgba(0, 0, 0, 0.40)",
        "themes-shadow-header-menu-color-fill": "rgba(0, 0, 0, 0.20)",
        "themes-gradient-loader-gradient-fill-1": "#191b1c",
        "themes-gradient-loader-gradient-fill-2": "rgba(25, 27, 28, 0.00)",
        "themes-color-background-white": "#ffffff",
        "themes-color-background-light": "#fdf4f5",
        "themes-color-background-dark": "#27282b",
        "themes-color-background-black": "#191b1c",
        "themes-color-background-brand1": "#d7252d",
        "themes-color-background-brand2": "#0053b4",
        "themes-color-background-accent1": "#286636",
        "themes-color-background-accent2": "#00ccff",
        "themes-color-background-body": "#ffffff",
        "themes-color-stroke-white": "#ffffff",
        "themes-color-stroke-light": "#e8e8ea",
        "themes-color-stroke-dark": "#27282b",
        "themes-color-stroke-black": "#191b1c",
        "themes-color-stroke-brand1": "#d7252d",
        "themes-color-stroke-brand2": "#0053b4",
        "themes-color-stroke-accent1": "#286636",
        "themes-color-stroke-accent2": "#00ccff",
        "themes-color-text-white": "#ffffff",
        "themes-color-text-light": "#fcfcfd",
        "themes-color-text-dark": "#27282b",
        "themes-color-text-black": "#191b1c",
        "themes-color-text-brand1": "#d7252d",
        "themes-color-text-brand2": "#0053b4",
        "themes-color-text-accent1": "#183d20",
        "themes-color-text-accent2": "#003d4d",
        "colors-grayscale-100": "#fcfcfd",
        "colors-grayscale-200": "#e8e8ea",
        "colors-grayscale-300": "#a2a5ab",
        "colors-grayscale-400": "#72757e",
        "colors-grayscale-500": "#4e5056",
        "colors-grayscale-600": "#27282b",
        "colors-grayscale-700": "#191b1c",
        "colors-brand-1-100": "#fdf4f5",
        "colors-brand-1-200": "#e77c81",
        "colors-brand-1-300": "#dd484f",
        "colors-brand-1-400": "#d7252d",
        "colors-brand-1-500": "#bd2128",
        "colors-brand-1-600": "#971a20",
        "colors-brand-1-700": "#6c1317",
        "colors-brand-2-100": "#ebf1f9",
        "colors-brand-2-200": "#6698d2",
        "colors-brand-2-300": "#2e72c2",
        "colors-brand-2-400": "#0053b4",
        "colors-brand-2-500": "#004697",
        "colors-brand-2-600": "#00326c",
        "colors-brand-2-700": "#001936",
        "colors-accent-1-100": "#eaf7ed",
        "colors-accent-1-200": "#95d6a4",
        "colors-accent-1-300": "#47b560",
        "colors-accent-1-400": "#286636",
        "colors-accent-1-500": "#22562d",
        "colors-accent-1-600": "#183d20",
        "colors-accent-1-700": "#0e2513",
        "colors-accent-2-100": "#ebfbff",
        "colors-accent-2-200": "#66e0ff",
        "colors-accent-2-300": "#2ed5ff",
        "colors-accent-2-400": "#00ccff",
        "colors-accent-2-500": "#00abd6",
        "colors-accent-2-600": "#007a99",
        "colors-accent-2-700": "#003d4d",
        "colors-accent-3-100": "#eefaec",
        "colors-accent-3-200": "#a9e8a0",
        "colors-accent-3-300": "#68d759",
        "colors-accent-3-400": "#3aae2a",
        "colors-accent-3-500": "#319223",
        "colors-accent-3-600": "#236819",
        "colors-accent-3-700": "#153f0f",
        "colors-accent-4-400_storage": "#a35eb5",
        "colors-accessible-accent-1-100": "#f5f2fc",
        "colors-accessible-accent-1-200": "#cfbff2",
        "colors-accessible-accent-1-300": "#ab8fe9",
        "colors-accessible-accent-1-400": "#865ddf",
        "colors-accessible-accent-1-500": "#6d3ad8",
        "colors-accessible-accent-1-600": "#4c21a9",
        "colors-accessible-accent-1-700": "#341672",
        "colors-accessible-accent-2-100": "#e7f8e4",
        "colors-accessible-accent-2-200": "#7ddc6f",
        "colors-accessible-accent-2-300": "#3cb42c",
        "colors-accessible-accent-2-400": "#2d8721",
        "colors-accessible-accent-2-500": "#246d1a",
        "colors-accessible-accent-2-600": "#1b5214",
        "colors-accessible-accent-2-700": "#11340c",
        "colors-accessible-brand-2-100": "#fdf4f5",
        "colors-accessible-brand-2-200": "#e77c81",
        "colors-accessible-brand-2-300": "#dd484f",
        "colors-accessible-brand-2-400": "#d7252d",
        "colors-accessible-brand-2-500": "#bd2128",
        "colors-accessible-brand-2-600": "#971a20",
        "colors-accessible-brand-2-700": "#6c1317"
      },
      "spacing": {
        "components-accordion-spacing-large-padding-x": "5rem",
        "components-accordion-spacing-large-icon-margin-right": "2rem",
        "components-accordion-spacing-large-copy-margin-right": "2.5rem",
        "components-accordion-spacing-large-maxwidth": "59.625rem",
        "components-accordion-spacing-large-section-padding-x": "0rem",
        "components-accordion-spacing-large-section-padding-y": "2.5rem",
        "components-accordion-spacing-large-section-copy-margin-bottom": "2.5rem",
        "components-accordion-spacing-large-section-space-between": "4rem",
        "components-accordion-spacing-large-item-padding-x": "0rem",
        "components-accordion-spacing-large-item-padding-y": "1.5rem",
        "components-accordion-spacing-large-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-large-content-padding-x": "0rem",
        "components-accordion-spacing-large-content-padding-y": "2.5rem",
        "components-accordion-spacing-large-content-title-margin-bottom": "2.5rem",
        "components-accordion-spacing-large-content-content-space-between": "4rem",
        "components-accordion-spacing-large-content-content-margin-left": "1rem",
        "components-accordion-spacing-small-padding-top": "1rem",
        "components-accordion-spacing-small-padding-x": "1.5rem",
        "components-accordion-spacing-small-padding-y": "2.5rem",
        "components-accordion-spacing-small-icon-margin-right": "0.75rem",
        "components-accordion-spacing-small-title-margin-bottom": "0.5rem",
        "components-accordion-spacing-small-copy-margin-right": "2rem",
        "components-accordion-spacing-small-copy-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-padding-x": "0rem",
        "components-accordion-spacing-small-section-padding-y": "0rem",
        "components-accordion-spacing-small-section-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-space-between": "2.5rem",
        "components-accordion-spacing-small-item-padding-x": "0rem",
        "components-accordion-spacing-small-item-padding-y": "1rem",
        "components-accordion-spacing-small-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-small-content-padding-x": "0rem",
        "components-accordion-spacing-small-content-padding-y": "0.75rem",
        "components-accordion-spacing-small-content-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-content-content-space-between": "1rem",
        "components-accordion-spacing-small-content-content-margin-left": "1rem",
        "components-article-card-spacing-large-padding-image-x": "0rem",
        "components-article-card-spacing-large-padding-image-y": "0rem",
        "components-article-card-spacing-large-padding-content-x": "1.5rem",
        "components-article-card-spacing-large-padding-content-y": "1.5rem",
        "components-article-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-article-card-spacing-large-button-space-between": "1rem",
        "components-article-card-spacing-large-title-margin-bottom": "1rem",
        "components-article-card-spacing-large-list-content-gap": "1rem",
        "components-article-card-spacing-large-list-image-padding-x": "0rem",
        "components-article-card-spacing-large-list-image-padding-y": "0rem",
        "components-article-card-spacing-small-padding-image-x": "0rem",
        "components-article-card-spacing-small-padding-image-y": "0rem",
        "components-article-card-spacing-small-padding-content-x": "1.5rem",
        "components-article-card-spacing-small-padding-content-y": "1.5rem",
        "components-article-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-article-card-listing-spacing-small-padding-x": "1.5rem",
        "components-article-card-listing-spacing-small-padding-y": "2.5rem",
        "components-article-hero-spacing-large-padding-x": "0rem",
        "components-article-hero-spacing-large-padding-y": "0rem",
        "components-article-hero-spacing-large-title-margin-bottom": "2rem",
        "components-article-hero-spacing-large-label-share-margin-bottom": "2rem",
        "components-article-hero-spacing-large-author-name-margin-bottom": "0.75rem",
        "components-article-hero-spacing-large-author-thumbnail-margin-left": "1rem",
        "components-article-hero-spacing-large-details-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-space-between": "1rem",
        "components-article-hero-spacing-large-body-margin-bottom": "2rem",
        "components-article-hero-spacing-large-image-space-between": "1rem",
        "components-article-hero-spacing-large-image-margin-bottom": "1rem",
        "components-article-hero-spacing-large-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-stats-margin-bottom": "2.5rem",
        "components-article-hero-spacing-small-content-margin-top": "2.5rem",
        "components-article-hero-spacing-small-title-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-label-share-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-authorname-margin-bottom": "0.75rem",
        "components-article-hero-spacing-small-authorthumbnail-margin-left": "1rem",
        "components-article-hero-spacing-small-details-margin-bottom": "1rem",
        "components-article-hero-spacing-small-space-between": "0.75rem",
        "components-article-hero-spacing-small-body-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-image-space-between": "0.75rem",
        "components-article-hero-spacing-small-image-margin-bottom": "1rem",
        "components-article-hero-spacing-small-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-stats-margin-bottom": "2rem",
        "components-banner-spacing-large-content-padding-x": "1.5rem",
        "components-banner-spacing-large-content-space-between": "1rem",
        "components-banner-spacing-large-content-width": "69.375rem",
        "components-banner-spacing-icon-dimensions": "1.5rem",
        "components-banner-spacing-icon-margin-left": "0.5rem",
        "components-banner-spacing-small-space-between": "1rem",
        "components-banner-spacing-small-content-space-between": "1rem",
        "components-banner-spacing-small-title-margin-bottom": "0.75rem",
        "components-banner-spacing-small-content-margin-bottom": "1rem",
        "components-breadcrumb-spacing-large-space-between": "0.25rem",
        "components-breadcrumb-spacing-small-space-between": "0.25rem",
        "components-button-spacing-large-icon-large-x": "2.5rem",
        "components-button-spacing-large-icon-large-y": "2.5rem",
        "components-button-spacing-large-default-padding-y": "0.75rem",
        "components-button-spacing-large-default-padding-x": "1.5rem",
        "components-button-spacing-large-default-space-between": "0.25rem",
        "components-button-spacing-large-icon-left-padding-left": "1rem",
        "components-button-spacing-large-icon-right-padding-right": "1rem",
        "components-button-spacing-large-icon-only-padding-x": "0.75rem",
        "components-button-spacing-large-icon-only-padding-y": "0.75rem",
        "components-button-spacing-large-tertiary-content-height": "1.5rem",
        "components-button-spacing-compressed-icon-large-x": "2.5rem",
        "components-button-spacing-compressed-icon-large-y": "2.5rem",
        "components-button-spacing-compressed-default-padding-y": "0.5rem",
        "components-button-spacing-compressed-default-padding-x": "1rem",
        "components-button-spacing-compressed-default-space-between": "0.125rem",
        "components-button-spacing-compressed-icon-left-padding-left": "0.5rem",
        "components-button-spacing-compressed-icon-right-padding-right": "0.5rem",
        "components-button-spacing-compressed-icon-only-padding-x": "0.5rem",
        "components-button-group-spacing-padding-y": "0.125rem",
        "components-button-group-spacing-padding-x": "0.25rem",
        "components-button-group-spacing-space-between": "0.5rem",
        "components-category-card-spacing-large-padding-top": "0rem",
        "components-category-card-spacing-large-padding-x": "0rem",
        "components-category-card-spacing-large-padding-y": "0rem",
        "components-category-card-spacing-large-padding-image-x": "0rem",
        "components-category-card-spacing-large-padding-image-y": "0rem",
        "components-category-card-spacing-large-padding-content-x": "2rem",
        "components-category-card-spacing-large-padding-content-y": "2rem",
        "components-category-card-listing-spacing-large-padding-y": "5rem",
        "components-category-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-category-card-listing-spacing-large-card-margin-bottom": "0rem",
        "components-category-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-card-search-result-small-padding-top": "0rem",
        "components-card-search-result-small-padding-x": "0rem",
        "components-card-search-result-small-padding-y": "0rem",
        "components-card-search-result-small-padding-image-x": "0rem",
        "components-card-search-result-small-padding-image-y": "0rem",
        "components-card-search-result-small-padding-content-x": "1.5rem",
        "components-card-search-result-small-padding-content-y": "1.5rem",
        "components-card-search-result-spacing-large-padding-x": "0rem",
        "components-card-search-result-spacing-large-padding-y": "1.5rem",
        "components-card-search-result-spacing-large-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-image-margin-right": "1.5rem",
        "components-card-search-result-spacing-small-padding-x": "1.5rem",
        "components-card-search-result-spacing-small-padding-y": "1.5rem",
        "components-card-search-result-spacing-small-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-image-margin-right": "0.75rem",
        "components-contact-us-promo-spacing-large-padding-x": "5rem",
        "components-contact-us-promo-spacing-large-padding-y": "5rem",
        "components-contact-us-promo-spacing-large-content-margin-right": "5rem",
        "components-contact-us-promo-spacing-large-margin-y": "2.5rem",
        "components-contact-us-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-contact-us-promo-spacing-small-margin-y": "2.5rem",
        "components-content-list-list-item-container-padding-x": "0.75rem",
        "components-content-list-list-item-container-padding-y": "1rem",
        "components-content-list-list-item-content-space-between": "0.5rem",
        "components-content-list-list-item-content-default-padding-y": "0.25rem",
        "components-content-list-list-item-content-hyperlink-padding-y": "0rem",
        "components-content-list-spacing-large-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-large-item-title-margin-bottom": "1rem",
        "components-content-list-spacing-large-item-copy-margin-bottom": "2.5rem",
        "components-content-list-spacing-large-item-icon-copy-margin-top": "0rem",
        "components-content-list-spacing-large-item-margin-bottom": "2rem",
        "components-content-list-spacing-large-item-image-margin-bottom": "0.5rem",
        "components-content-list-spacing-large-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-y": "2.5rem",
        "components-content-list-spacing-small-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-small-item-title-margin-bottom": "0.5rem",
        "components-content-list-spacing-small-item-copy-margin-bottom": "1rem",
        "components-content-list-spacing-small-item-margin-bottom": "1.5rem",
        "components-content-list-spacing-small-header-title-margin-bottom": "1rem",
        "components-content-list-spacing-small-header-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-title-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-button-space-between": "1rem",
        "components-dropdown-item-padding-y": "0.75rem",
        "components-dropdown-item-padding-x": "0.75rem",
        "components-dropdown-item-spacing-x": "0.75rem",
        "components-dropdown-input-padding-y": "0.25rem",
        "components-dropdown-input-padding-x": "1rem",
        "components-footer-brand-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-large-column-margin-right": "4rem",
        "components-footer-brand-spacing-large-column-margin-bottom": "4rem",
        "components-footer-brand-spacing-large-link-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-padding-y": "2.5rem",
        "components-footer-brand-spacing-small-padding-x": "1rem",
        "components-footer-brand-spacing-small-logo-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-column-margin-right": "0.125rem",
        "components-footer-brand-spacing-small-column-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-small-link-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-title-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-text-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-footer-link-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-link-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-link-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-link-minheight": "3rem",
        "components-footer-brand-spacing-small-footer-category-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-category-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-category-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-category-minheight": "3rem",
        "components-footer-global-spacing-large-padding-y": "2.5rem",
        "components-footer-global-spacing-large-padding-x": "5rem",
        "components-footer-global-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-global-spacing-large-column-margin-right": "2rem",
        "components-footer-global-spacing-large-link-margin-bottom": "2rem",
        "components-footer-global-spacing-small-padding-y": "2.5rem",
        "components-footer-global-spacing-small-padding-x": "1rem",
        "components-footer-global-spacing-small-logo-margin-bottom": "1rem",
        "components-footer-global-spacing-small-column-margin-right": "1rem",
        "components-footer-global-spacing-small-link-margin-bottom": "1rem",
        "components-generic-card-spacing-large-padding-x": "0rem",
        "components-generic-card-spacing-large-padding-y": "0rem",
        "components-generic-card-spacing-large-padding-image-x": "0rem",
        "components-generic-card-spacing-large-padding-image-y": "0rem",
        "components-generic-card-spacing-large-padding-content-x": "2rem",
        "components-generic-card-spacing-large-padding-content-y": "2rem",
        "components-generic-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-generic-card-spacing-large-title-margin-bottom": "1rem",
        "components-generic-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-button-space-between": "1rem",
        "components-generic-card-spacing-small-padding-x": "0rem",
        "components-generic-card-spacing-small-padding-y": "0rem",
        "components-generic-card-spacing-small-padding-image-x": "0rem",
        "components-generic-card-spacing-small-padding-image-y": "0rem",
        "components-generic-card-spacing-small-padding-content-x": "1.5rem",
        "components-generic-card-spacing-small-padding-content-y": "1.5rem",
        "components-global-search-spacing-omnibox-padding-x": "0.125rem",
        "components-global-search-spacing-omnibox-list-padding-y": "0.5rem",
        "components-global-search-spacing-omnibox-label-padding": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-item-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-height": "3rem",
        "components-generic-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-generic-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-header-shadow-x": "0rem",
        "components-header-spacing-large-padding-y": "1rem",
        "components-header-spacing-large-padding-x": "1.5rem",
        "components-header-spacing-large-links-margin-right": "1.5rem",
        "components-header-spacing-large-icons-space-between": "1.5rem",
        "components-header-spacing-large-logo-margin-right": "2rem",
        "components-header-spacing-large-space-between-search": "0.5rem",
        "components-header-spacing-large-height": "5rem",
        "components-header-spacing-large-language-selector-padding-x": "1rem",
        "components-header-spacing-large-language-selector-padding-y": "0.5rem",
        "components-header-spacing-large-language-selector-space-between": "0.75rem",
        "components-header-spacing-large-language-selector-country-space-between": "0.5rem",
        "components-header-spacing-large-mega-menu-padding-y": "2.5rem",
        "components-header-spacing-large-mega-menu-padding-x": "2.5rem",
        "components-header-spacing-large-mega-menu-column-margin-right": "5rem",
        "components-header-spacing-large-mega-menu-category-link-margin-bottom": "1rem",
        "components-header-spacing-large-mega-menu-link-margin-bottom": "0.5rem",
        "components-header-spacing-large-mega-menu-featured-padding-x": "2rem",
        "components-header-spacing-large-mega-menu-featured-padding-y": "2rem",
        "components-header-spacing-large-mega-menu-featured-image-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-title-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-link-space-between": "1.5rem",
        "components-header-spacing-large-mega-menu-title-margin-bottom": "2.5rem",
        "components-header-spacing-large-dropdown-padding-y": "1.5rem",
        "components-header-spacing-large-dropdown-padding-x": "1.5rem",
        "components-header-spacing-large-dropdown-margin-bottom": "0.5rem",
        "components-header-spacing-small-language-selector-padding-x": "1rem",
        "components-header-spacing-small-language-selector-padding-y": "0.5rem",
        "components-header-spacing-small-language-selector-space-between": "0.5rem",
        "components-header-spacing-small-padding-left": "1rem",
        "components-header-spacing-small-padding-right": "1rem",
        "components-header-spacing-small-padding-top": "0.5rem",
        "components-header-spacing-small-padding-bottom": "0.5rem",
        "components-header-spacing-small-nav-main-minheight": "3rem",
        "components-header-spacing-small-nav-main-padding-y": "0.5rem",
        "components-header-spacing-small-nav-main-padding-left": "1.5rem",
        "components-header-spacing-small-nav-main-padding-right": "1rem",
        "components-header-spacing-small-nav-category-minheight": "3rem",
        "components-header-spacing-small-nav-category-padding-left": "1.5rem",
        "components-header-spacing-small-nav-category-padding-right": "0.75rem",
        "components-header-spacing-small-nav-category-padding-y": "0.75rem",
        "components-header-spacing-small-nav-category-space-between": "0rem",
        "components-header-spacing-small-nav-menu-link-minheight": "3rem",
        "components-header-spacing-small-nav-menu-link-padding-right": "1.5rem",
        "components-header-spacing-small-nav-menu-link-padding-left": "2.5rem",
        "components-header-spacing-small-nav-menu-link-padding-y": "0.75rem",
        "components-header-spacing-small-mega-menu-padding-y": "0rem",
        "components-header-spacing-small-mega-menu-padding-x": "0rem",
        "components-header-spacing-small-mega-menu-category-link-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-link-margin-bottom": "0rem",
        "components-header-spacing-small-mega-menu-featured-padding-x": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-padding-y": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-image-margin-bottom": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-title-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-featured-link-space-between": "0.75rem",
        "components-header-spacing-small-mega-menu-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-container-padding": "1rem",
        "components-hero-homepage-spacing-large-padding-x": "5rem",
        "components-hero-homepage-spacing-large-padding-y": "2.5rem",
        "components-hero-homepage-spacing-large-minheight": "34.3125rem",
        "components-hero-homepage-spacing-large-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-margin-bottom": "4rem",
        "components-hero-homepage-spacing-large-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-copy-margin-bottom": "2.5rem",
        "components-hero-homepage-spacing-large-cta-margin-right": "1rem",
        "components-hero-homepage-spacing-large-image-height": "34.3125rem",
        "components-hero-homepage-spacing-large-image-width": "45.75rem",
        "components-hero-homepage-spacing-small-padding-x": "0.5rem",
        "components-hero-homepage-spacing-small-padding-y": "1.5rem",
        "components-hero-homepage-spacing-small-minheight": "34.3125rem",
        "components-hero-homepage-spacing-small-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-copy-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-image-height": "17.5625rem",
        "components-hero-homepage-spacing-small-image-width": "45.75rem",
        "components-hero-internal-spacing-large-padding-y": "2.5rem",
        "components-hero-internal-spacing-large-padding-right": "10rem",
        "components-hero-internal-spacing-large-title-margin-bottom": "1.5rem",
        "components-hero-internal-spacing-large-button-padding-y": "2rem",
        "components-hero-internal-spacing-large-text-maxwidth": "59.625rem",
        "components-hero-internal-spacing-large-minheight": "18.5rem",
        "components-hero-internal-spacing-small-padding-x": "1.5rem",
        "components-hero-internal-spacing-small-padding-right": "0rem",
        "components-hero-internal-spacing-small-button-padding-y": "1.5rem",
        "components-hero-internal-spacing-small-text-maxwidth": "30rem",
        "components-hero-internal-spacing-small-minheight": "13.5rem",
        "components-icon-card-spacing-large-padding-x": "0rem",
        "components-icon-card-spacing-large-padding-y": "0rem",
        "components-icon-card-spacing-large-icon-margin": "1.5rem",
        "components-icon-card-spacing-large-margin-right": "1.5rem",
        "components-icon-card-spacing-large-title-margin-bottom": "1rem",
        "components-icon-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-icon-card-spacing-large-button-space-between": "0.5rem",
        "components-icon-card-spacing-large-top-space-between": "1rem",
        "components-icon-card-spacing-small-padding-x": "1.5rem",
        "components-icon-card-spacing-small-padding-y": "1.5rem",
        "components-icon-card-spacing-small-icon-margin": "1.5rem",
        "components-icon-card-spacing-small-margin-right": "1.5rem",
        "components-icon-card-spacing-small-title-margin-bottom": "1.5rem",
        "components-icon-card-dimensions-large-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-large-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-large-big-icon-height": "4rem",
        "components-icon-card-dimensions-large-big-icon-width": "4rem",
        "components-icon-card-dimensions-large-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-large-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-large-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-large-tiny-icon-width": "2.5rem",
        "components-icon-card-dimensions-small-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-small-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-small-big-icon-height": "3.5rem",
        "components-icon-card-dimensions-small-big-icon-width": "3.5rem",
        "components-icon-card-dimensions-small-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-small-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-small-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-small-tiny-icon-width": "2.5rem",
        "components-icon-card-listing-spacing-large-padding-inner-x": "4rem",
        "components-icon-card-listing-spacing-large-padding-inner-y": "4rem",
        "components-icon-card-listing-spacing-large-card-space-between": "4rem",
        "components-icon-card-listing-spacing-large-title-margin-bottom": "1.5rem",
        "components-icon-card-listing-spacing-large-card-margin-bottom": "4rem",
        "components-icon-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-x": "1.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-y": "2.5rem",
        "components-icon-card-listing-spacing-small-title-margin-bottom": "1rem",
        "components-icon-card-listing-spacing-small-button-margin-top": "1.5rem",
        "components-jumplink-link-padding-bottom": "0.5rem",
        "components-jumplink-spacing-small-title-area-margin-bottom": "1.5rem",
        "components-jumplink-spacing-small-link-container-padding-x": "0rem",
        "components-lightbox-spacing-large-carousel-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-carousel-space-between": "1.5rem",
        "components-lightbox-spacing-large-featured-image-height": "39.25rem",
        "components-lightbox-spacing-large-featured-image-width": "39.25rem",
        "components-lightbox-spacing-large-image-height": "17.0625rem",
        "components-lightbox-spacing-large-image-width": "17.0625rem",
        "components-lightbox-spacing-small-carousel-margin-bottom": "1rem",
        "components-lightbox-spacing-small-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-small-media-details-margin-top": "1rem",
        "components-lightbox-spacing-small-image-height": "20.4375rem",
        "components-lightbox-spacing-small-image-width": "20.4375rem",
        "components-legal-disclaimer-spacing-small-ruleline-width": "20.4375rem",
        "components-media-spacing-large-padding-y": "0.5rem",
        "components-media-spacing-large-padding-x": "0.5rem",
        "components-media-gallery-large-container-grid-gap-x": "1.5rem",
        "components-media-gallery-large-container-grid-gap-y": "1.5rem",
        "components-media-gallery-small-container-grid-gap-x": "0.5rem",
        "components-media-gallery-small-container-grid-gap-y": "0.5rem",
        "components-media-gallery-spacing-small-padding-x": "1.5rem",
        "components-media-gallery-spacing-small-padding-y": "2.5rem",
        "components-media-gallery-spacing-large-padding-x": "5rem",
        "components-media-gallery-spacing-large-padding-y": "5rem",
        "components-product-card-spacing-list-content-gap": "0rem",
        "components-product-card-spacing-large-padding-image-x": "0rem",
        "components-product-card-spacing-large-padding-image-y": "0rem",
        "components-product-card-spacing-large-padding-content-x": "1.5rem",
        "components-product-card-spacing-large-padding-content-y": "1.5rem",
        "components-product-card-spacing-large-category-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-title-margin-bottom": "1rem",
        "components-product-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-button-space-between": "1rem",
        "components-product-card-spacing-small-padding-image-x": "0rem",
        "components-product-card-spacing-small-padding-image-y": "0rem",
        "components-product-card-spacing-small-padding-content-x": "1.5rem",
        "components-product-card-spacing-small-padding-content-y": "1.5rem",
        "components-product-card-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-large-padding-x": "5rem",
        "components-product-information-spacing-large-padding-y": "1.5rem",
        "components-product-information-spacing-large-title-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-label-margin-bottom": "2.5rem",
        "components-product-information-spacing-large-ratings-margin-bottom": "1rem",
        "components-product-information-spacing-large-details-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-space-between": "1rem",
        "components-product-information-spacing-large-carousel-space-between": "5rem",
        "components-product-information-spacing-large-button-margin-y": "0.5rem",
        "components-product-information-spacing-large-body-margin-top": "1.5rem",
        "components-product-information-spacing-large-image-space-between": "1rem",
        "components-product-information-spacing-large-image-margin-bottom": "1rem",
        "components-product-information-spacing-small-padding-x": "1.5rem",
        "components-product-information-spacing-small-padding-y": "0.5rem",
        "components-product-information-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-small-label-margin-bottom": "1.5rem",
        "components-product-information-spacing-small-ratings-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-details-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-space-between": "0.75rem",
        "components-product-information-spacing-small-carousel-space-between": "2.5rem",
        "components-product-information-spacing-small-button-margin-y": "0.75rem",
        "components-product-information-spacing-small-body-margin-top": "1rem",
        "components-product-information-spacing-small-image-space-between": "0.75rem",
        "components-product-information-spacing-small-image-margin-bottom": "1rem",
        "components-product-results-list-with-search-spacing-large-padding-x": "5rem",
        "components-product-results-list-with-search-spacing-large-padding-y": "5rem",
        "components-product-results-list-with-search-spacing-large-ruleline-margin-top": "2.5rem",
        "components-product-results-list-with-search-spacing-large-filter-space-between": "1rem",
        "components-product-results-list-with-search-spacing-large-card-space-between": "1.5rem",
        "components-product-results-list-with-search-spacing-large-card-container-margin-bottom": "5rem",
        "components-product-results-list-with-search-spacing-small-padding-x": "1.5rem",
        "components-product-results-list-with-search-spacing-small-padding-y": "2.5rem",
        "components-product-results-list-with-search-spacing-small-ruleline-margin-top": "1rem",
        "components-product-results-list-with-search-spacing-small-filter-padding-x": "0.5rem",
        "components-product-results-list-with-search-spacing-small-card-space-between": "1rem",
        "components-product-results-list-with-search-spacing-small-card-margin-bottom": "1.5rem",
        "components-promo-spacing-large-padding-x": "4rem",
        "components-promo-spacing-large-padding-y": "0rem",
        "components-promo-spacing-large-content-space-between": "0rem",
        "components-promo-spacing-large-content-space-between-y": "0rem",
        "components-promo-spacing-large-padding-content-x": "7.5rem",
        "components-promo-spacing-large-padding-content-condensed-x": "7.5rem",
        "components-promo-spacing-large-padding-content-y": "7.5rem",
        "components-promo-spacing-large-padding-content-condensed-y": "7.5rem",
        "components-promo-spacing-large-padding-image-x": "0rem",
        "components-promo-spacing-large-padding-image-y": "0rem",
        "components-promo-spacing-large-padding-image-top": "1.5rem",
        "components-promo-spacing-large-title-margin-bottom": "2rem",
        "components-promo-spacing-large-margin-y": "2.5rem",
        "components-promo-spacing-large-buttons-space-between": "1rem",
        "components-promo-spacing-small-padding-x": "1rem",
        "components-promo-spacing-small-padding-y": "2.5rem",
        "components-promo-spacing-small-padding-top": "0rem",
        "components-promo-spacing-small-padding-content-x": "1.5rem",
        "components-promo-spacing-small-padding-content-y": "2.5rem",
        "components-promo-spacing-small-padding-image-x": "0rem",
        "components-promo-spacing-small-padding-image-y": "0rem",
        "components-promo-spacing-small-padding-image-top": "0rem",
        "components-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-promo-spacing-small-margin-y": "2.5rem",
        "components-promo-minheight": "30rem",
        "components-pull-quote-spacing-large-padding-quote-x": "4rem",
        "components-pull-quote-spacing-large-padding-quote-y": "4rem",
        "components-pull-quote-spacing-large-title-margin-bottom": "2rem",
        "components-pull-quote-spacing-large-quote-margin-bottom": "4rem",
        "components-pull-quote-spacing-large-quote-space-between": "1.5rem",
        "components-pull-quote-spacing-large-detail-space-between": "0.5rem",
        "components-pull-quote-spacing-large-quote-minheight": "25rem",
        "components-pull-quote-spacing-small-padding-quote-x": "1.5rem",
        "components-pull-quote-spacing-small-padding-quote-y": "2.5rem",
        "components-pull-quote-spacing-small-title-margin-bottom": "1.5rem",
        "components-pull-quote-spacing-small-quote-margin-bottom": "2.5rem",
        "components-pull-quote-spacing-small-quote-space-between": "1rem",
        "components-pull-quote-spacing-small-detail-space-between": "1rem",
        "components-pull-quote-spacing-small-quote-minheight": "15rem",
        "components-promo-alternate-minheight": "30rem",
        "components-promo-alternate-spacing-large-padding-x": "4rem",
        "components-promo-alternate-spacing-large-padding-y": "0rem",
        "components-promo-alternate-spacing-large-content-space-between": "0rem",
        "components-promo-alternate-spacing-large-padding-content-x": "7.5rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-x": "7.5rem",
        "components-promo-alternate-spacing-large-padding-content-y": "7.5rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-y": "7.5rem",
        "components-promo-alternate-spacing-large-padding-image-x": "0rem",
        "components-promo-alternate-spacing-large-padding-image-y": "0rem",
        "components-promo-alternate-spacing-large-padding-image-top": "1.5rem",
        "components-promo-alternate-spacing-large-title-margin-bottom": "2rem",
        "components-promo-alternate-spacing-small-padding-x": "1rem",
        "components-promo-alternate-spacing-small-padding-y": "2.5rem",
        "components-promo-alternate-spacing-small-padding-content-x": "1.5rem",
        "components-promo-alternate-spacing-small-padding-content-y": "2.5rem",
        "components-promo-alternate-spacing-small-padding-image-x": "0rem",
        "components-promo-alternate-spacing-small-padding-image-y": "0rem",
        "components-promo-alternate-spacing-small-padding-image-top": "0rem",
        "components-promo-alternate-spacing-small-title-margin-bottom": "1.5rem",
        "components-share-list-spacing-padding-x": "1rem",
        "components-share-list-spacing-padding-y": "1rem",
        "components-header-copy-margin-bottom": "1rem",
        "components-header-margin-bottom": "1.5rem",
        "components-item-margin-bottom": "1.5rem",
        "components-site-search-padding-x": "2.5rem",
        "components-site-search-padding-y": "5rem",
        "components-site-search-facet-label-space-between": "0.25rem",
        "components-site-search-refine-padding-x": "1.5rem",
        "components-site-search-refine-padding-y": "0rem",
        "components-site-search-hero-search-height": "18.5rem",
        "components-site-search-hero-search-large-padding-x": "5rem",
        "components-site-search-hero-search-large-padding-y": "5rem",
        "components-stats-spacing-stat-text-space-between": "0.5rem",
        "components-stats-spacing-large-inner-padding-x": "5rem",
        "components-stats-spacing-large-inner-padding-y": "5rem",
        "components-stats-spacing-small-inner-padding-x": "1.5rem",
        "components-stats-spacing-small-inner-padding-y": "1.5rem",
        "components-icon-card-listing-spacing-small-top-space-between": "1rem",
        "components-supporting-text-padding-x": "0rem",
        "components-supporting-text-padding-bottom": "0.5rem",
        "components-tab-item-padding-y": "0.75rem",
        "components-tab-item-padding-x": "0.75rem",
        "components-tab-item-spacing-x": "0.75rem",
        "components-tab-input-padding-y": "0.25rem",
        "components-tab-input-padding-x": "1rem",
        "components-tag-spacing-padding-x": "0.5rem",
        "components-tag-spacing-padding-y": "0.5rem",
        "components-tag-spacing-space-between": "0.5rem",
        "components-text-field-input-padding-y": "0.25rem",
        "components-text-field-input-padding-x": "1rem",
        "components-text-field-area-input-padding-y": "1rem",
        "components-text-field-area-input-padding-x": "1rem",
        "components-text-field-supporting-text-padding-x": "0rem",
        "components-text-field-supporting-text-padding-top": "0.5rem",
        "themes-general-surface-spacing-large-padding-x": "5rem",
        "themes-general-surface-spacing-large-padding-y": "5rem",
        "themes-general-surface-spacing-large-title-margin-bottom": "2rem",
        "themes-general-surface-spacing-large-body-margin-right": "4rem",
        "themes-general-surface-spacing-large-body-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-large-margin-bottom": "4rem",
        "themes-general-surface-spacing-large-cards-space-between": "1.5rem",
        "themes-general-surface-spacing-large-button-margin-top": "2rem",
        "themes-general-surface-spacing-small-padding-x": "1.5rem",
        "themes-general-surface-spacing-small-padding-y": "5rem",
        "themes-general-surface-spacing-small-title-margin-bottom": "1rem",
        "themes-general-surface-spacing-small-body-margin-right": "2.5rem",
        "themes-general-surface-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-surface-spacing-small-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-small-cards-space-between": "1rem",
        "themes-general-surface-spacing-small-button-margin-top": "1.5rem",
        "themes-general-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "themes-general-card-spacing-large-title-margin-bottom": "1rem",
        "themes-general-card-spacing-large-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-large-button-space-between": "1.5rem",
        "themes-general-card-spacing-small-eyebrow-margin-bottom": "1rem",
        "themes-general-card-spacing-small-title-margin-bottom": "1rem",
        "themes-general-card-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-small-button-space-between": "1.5rem",
        "themes-shadow-share-list-spacing-x": "0rem",
        "themes-shadow-share-list-spacing-y": "0.75rem",
        "themes-shadow-share-list-spacing-blur": "1rem",
        "themes-shadow-share-list-spacing-spread": "-0.5rem",
        "themes-shadow-header-menu-spacing-x": "0rem",
        "themes-shadow-header-menu-spacing-y": "0.75rem",
        "themes-shadow-header-menu-spacing-blur": "1rem",
        "themes-shadow-header-menu-spacing-spread": "-0.5rem",
        "themes-typography-rte-large-h1-textsize": "3rem",
        "themes-typography-rte-large-h2-textsize": "2.5rem",
        "themes-typography-rte-large-h3-textsize": "2.25rem",
        "themes-typography-rte-large-h4-textsize": "2rem",
        "themes-typography-rte-large-h5-textsize": "1.5rem",
        "themes-typography-rte-large-h6-textsize": "1.25rem",
        "themes-typography-rte-medium-h1-textsize": "2.5rem",
        "themes-typography-rte-medium-h2-textsize": "2.25rem",
        "themes-typography-rte-medium-h3-textsize": "1.75rem",
        "themes-typography-rte-medium-h4-textsize": "1.5rem",
        "themes-typography-rte-medium-h5-textsize": "1.25rem",
        "themes-typography-rte-medium-h6-textsize": "1.125rem",
        "themes-typography-rte-small-h1-textsize": "2.5rem",
        "themes-typography-rte-small-h2-textsize": "2rem",
        "themes-typography-rte-small-h3-textsize": "1.75rem",
        "themes-typography-rte-small-h4-textsize": "1.5rem",
        "themes-typography-rte-small-h5-textsize": "1.125rem",
        "themes-typography-rte-small-h6-textsize": "1rem",
        "spacing-margin-small-1": "0.125rem",
        "spacing-margin-small-2": "0.25rem",
        "spacing-margin-small-3": "0.5rem",
        "spacing-margin-small-4": "0.75rem",
        "spacing-margin-small-5": "1rem",
        "spacing-margin-small-6": "1.5rem",
        "spacing-margin-small-7": "2.5rem",
        "spacing-margin-medium-2": "0.75rem",
        "spacing-margin-medium-4": "1.5rem",
        "spacing-margin-medium-5": "2rem",
        "spacing-margin-large-1": "0.5rem",
        "spacing-margin-large-2": "1rem",
        "spacing-margin-large-3": "1.5rem",
        "spacing-margin-large-4": "2rem",
        "spacing-margin-large-5": "2.5rem",
        "spacing-margin-large-6": "4rem",
        "spacing-margin-large-7": "5rem",
        "spacing-space-between-small-1": "0.125rem",
        "spacing-space-between-small-2": "0.25rem",
        "spacing-space-between-small-3": "0.5rem",
        "spacing-space-between-small-4": "0.75rem",
        "spacing-space-between-small-5": "1rem",
        "spacing-space-between-small-6": "1.5rem",
        "spacing-space-between-small-7": "2.5rem",
        "spacing-space-between-medium-1": "0.125rem",
        "spacing-space-between-medium-2": "0.25rem",
        "spacing-space-between-medium-3": "0.5rem",
        "spacing-space-between-medium-4": "0.75rem",
        "spacing-space-between-medium-5": "1rem",
        "spacing-space-between-large-1": "0.25rem",
        "spacing-space-between-large-2": "0.5rem",
        "spacing-space-between-large-3": "1rem",
        "spacing-space-between-large-4": "1.5rem",
        "spacing-space-between-large-5": "1.5rem",
        "spacing-space-between-large-6": "2.5rem",
        "spacing-space-between-large-7": "4rem",
        "spacing-padding-small-1": "0.125rem",
        "spacing-padding-small-2": "0.25rem",
        "spacing-padding-small-3": "0.5rem",
        "spacing-padding-small-4": "0.75rem",
        "spacing-padding-small-5": "1rem",
        "spacing-padding-small-6": "1.5rem",
        "spacing-padding-small-7": "2.5rem",
        "spacing-padding-medium-1": "0.25rem",
        "spacing-padding-medium-2": "0.75rem",
        "spacing-padding-medium-3": "1rem",
        "spacing-padding-medium-4": "1.5rem",
        "spacing-padding-medium-5": "2rem",
        "spacing-padding-medium-6": "3rem",
        "spacing-padding-medium-7": "4rem",
        "spacing-padding-large-1": "0.5rem",
        "spacing-padding-large-2": "1rem",
        "spacing-padding-large-3": "1.5rem",
        "spacing-padding-large-4": "2rem",
        "spacing-padding-large-5": "2.5rem",
        "spacing-padding-large-6": "4rem",
        "spacing-padding-large-7": "5rem"
      },
      "string": {
        "components-accordion-section-button": "var(--brand)",
        "components-article-card-listing-button-theme": "var(--brand)",
        "components-article-hero-pagination-buttons": "var(--brand)",
        "components-banner-banner-button": "var(--brand)",
        "components-category-card-listing-button-theme": "var(--brand)",
        "components-contact-us-promo-hierarchy-button-theme-1": "var(--filled)",
        "components-contact-us-promo-hierarchy-button-theme-2": "var(--outlined)",
        "components-contact-us-promo-color-button-theme": "var(--brand)",
        "components-contact-us-promo-color-brand-button-theme": "var(--white)",
        "components-generic-card-button-theme": "var(--brand)",
        "components-global-search-loader": "var(--default)",
        "components-generic-card-listing-decor-top": "var(--off-2)",
        "components-generic-card-listing-decor-bottom": "var(--off-4)",
        "components-hero-homepage-pagination-buttons": "var(--brand)",
        "components-hero-homepage-pattern": "var(--off)",
        "components-hero-homepage-button-1": "var(--brand)",
        "components-hero-homepage-button-2": "var(--brand)",
        "components-hero-homepage-image-ratio": "var(--5:4)",
        "components-hero-internal-theme-button-one-type": "var(--filled)",
        "components-hero-internal-theme-button-two-type": "var(--outlined)",
        "components-hero-internal-theme-button-color": "var(--white)",
        "components-hero-internal-theme-button-color-with-image": "var(--white)",
        "components-lightbox-button-type": "var(--brand)",
        "components-pdf-link-card-hierarchy-button-theme-1": "var(--filled)",
        "components-pdf-link-card-hierarchy-button-theme-2": "var(--outlined)",
        "components-pdf-link-card-color-button-theme": "var(--brand)",
        "components-product-card-badge-align": "var(--center)",
        "components-product-card-listing-button-color": "var(--tonal)",
        "components-promo-theme-button-one-theme": "var(--outlined)",
        "components-promo-theme-button-two-theme": "var(--text)",
        "components-promo-color-default-button-theme": "var(--brand)",
        "components-promo-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-color-brand-button-theme": "var(--white)",
        "components-pull-quote-border-decor": "var(--off-1)",
        "components-promo-alternate-theme-button-one-theme": "var(--outlined)",
        "components-promo-alternate-theme-button-two-theme": "var(--text)",
        "components-promo-alternate-color-default-button-theme": "var(--brand)",
        "components-promo-alternate-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-alternate-color-brand-button-theme": "var(--white)",
        "components-share-list-button-color": "var(--brand)",
        "components-site-search-hero-search-hero-pattern": "var(--off)",
        "components-stats-decor-top": "var(--off-2)",
        "components-stats-decor-bottom": "var(--off-4)",
        "themes-brand-loader": "var(--default)",
        "themes-brand-variants": "var(--off)",
        "themes-tab-variant": "var(--default)",
        "themes-label-variants": "var(--default)",
        "themes-logo": "var(--autan)",
        "themes-category-card": "var(--off)",
        "themes-title-decor": "var(--off)",
        "themes-typography-heading-fontfamily": "var(--poppins)",
        "themes-typography-heading-large-xxlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-xlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-large-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-medium-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-small-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-xsmall-fontweight": "var(--bold)",
        "themes-typography-heading-large-xxsmall-fontweight": "var(--semibold)",
        "themes-typography-heading-medium-xxlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-xlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-large-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-medium-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-small-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-xsmall-fontweight": "var(--bold)",
        "themes-typography-heading-medium-xxsmall-fontweight": "var(--semibold)",
        "themes-typography-heading-small-xxlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-xlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-large-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-medium-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-small-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-xsmall-fontweight": "var(--bold)",
        "themes-typography-heading-small-xxsmall-fontweight": "var(--semibold)",
        "themes-typography-rte-fontfamily": "var(--poppins)",
        "themes-typography-rte-fontweight": "var(--extrabold)"
      },
      "borderRadius": {
        "components-accordion-spacing-large-item-item-radius": "0rem",
        "components-article-card-spacing-large-image-radius-tl": "0rem",
        "components-article-card-spacing-large-image-radius-tr": "0rem",
        "components-article-card-spacing-large-image-radius-bl": "0rem",
        "components-article-card-spacing-large-image-radius-br": "4rem",
        "components-article-card-spacing-large-list-image-radius-br": "4rem",
        "components-article-card-spacing-small-image-radius-br": "2.5rem",
        "components-article-card-spacing-small-list-image-radius-br": "1rem",
        "components-article-hero-spacing-large-content-radius": "1.5rem",
        "components-article-hero-spacing-small-content-radius": "1rem",
        "components-button-radius-large": "7.5rem",
        "components-button-group-spacing-border-radius": "7.5rem",
        "components-category-card-radius-border-width": "0.0625rem",
        "components-category-card-radius-border-radius": "7.5rem",
        "components-category-card-radius-border-radius-condensed": "0.5rem",
        "components-category-card-radius-media-radius": "0.75rem",
        "components-content-list-icon-radius": "7.5rem",
        "components-fab-spacing-border-radius": "7.5rem",
        "components-header-spacing-large-search-border-radius-top": "7.5rem",
        "components-header-spacing-large-search-border-radius-bottom": "7.5rem",
        "components-header-spacing-large-language-selector-radius": "0.25rem",
        "components-header-spacing-large-dropdown-radius": "0rem",
        "components-hero-homepage-spacing-large-container-radius": "1rem",
        "components-hero-internal-spacing-large-icon-radius": "7.5rem",
        "components-hero-internal-spacing-small-icon-radius": "7.5rem",
        "components-lightbox-spacing-large-overlay-radius": "1rem",
        "components-pagination-scrollbar-radius": "62.5rem",
        "components-product-card-spacing-image-radius-tl": "0rem",
        "components-product-card-spacing-image-radius-tr": "0rem",
        "components-product-card-spacing-image-radius-bl": "0rem",
        "components-product-card-spacing-image-radius-br": "0rem",
        "components-product-information-spacing-large-content-radius": "1.5rem",
        "components-product-information-spacing-small-content-radius": "1rem",
        "components-promo-spacing-large-content-radius": "0rem",
        "components-promo-spacing-large-content-radius-tl": "0rem",
        "components-promo-spacing-large-content-radius-tr": "0rem",
        "components-promo-spacing-large-content-radius-bl": "0rem",
        "components-promo-spacing-large-content-radius-br": "0rem",
        "components-promo-spacing-large-image-radius-tl": "12.5rem",
        "components-promo-spacing-large-image-radius-tr": "0rem",
        "components-promo-spacing-large-image-radius-bl": "0rem",
        "components-promo-spacing-large-image-radius-br": "12.5rem",
        "components-promo-spacing-small-content-radius": "5rem",
        "components-promo-spacing-small-image-radius-tl": "5rem",
        "components-promo-spacing-small-image-radius-tr": "0rem",
        "components-promo-spacing-small-image-radius-bl": "0rem",
        "components-promo-spacing-small-image-radius-br": "5rem",
        "components-promo-alternate-spacing-large-content-radius": "12.5rem",
        "components-promo-alternate-spacing-large-image-radius-tl": "12.5rem",
        "components-promo-alternate-spacing-large-image-radius-tr": "0rem",
        "components-promo-alternate-spacing-large-image-radius-bl": "0rem",
        "components-promo-alternate-spacing-large-image-radius-br": "12.5rem",
        "components-promo-alternate-spacing-small-content-radius": "5rem",
        "components-promo-alternate-spacing-small-image-radius-tl": "5rem",
        "components-promo-alternate-spacing-small-image-radius-tr": "0rem",
        "components-promo-alternate-spacing-small-image-radius-bl": "0rem",
        "components-promo-alternate-spacing-small-image-radius-br": "5rem",
        "components-share-list-spacing-radius": "0.5rem",
        "components-share-list-spacing-link-radius": "0.5rem",
        "components-site-search-filter-radius": "0.5rem",
        "components-site-search-facet-radius": "0rem",
        "components-site-search-refine-radius": "0rem",
        "components-tag-spacing-radius": "0.25rem",
        "components-tip-spacing-radius": "0.25rem",
        "themes-spacing-radius-pagination-bar": "62.5rem",
        "themes-spacing-radius-large-button": "7.5rem",
        "themes-spacing-radius-large-button-icon": "7.5rem",
        "themes-spacing-radius-large-image": "1rem",
        "themes-spacing-radius-large-image-hero": "1rem",
        "themes-spacing-radius-large-image-card": "0.75rem",
        "themes-spacing-radius-large-card": "1rem",
        "themes-spacing-radius-large-icon": "7.5rem",
        "themes-spacing-radius-large-label": "0.25rem",
        "themes-spacing-radius-large-tag": "0.25rem",
        "themes-spacing-radius-large-container-tip": "0.25rem",
        "themes-spacing-radius-large-badge": "0rem",
        "themes-spacing-radius-large-item": "0.5rem",
        "themes-spacing-radius-large-form-field-input": "7.5rem",
        "themes-spacing-radius-large-form-area-input": "0.25rem",
        "themes-spacing-radius-large-general": "1.5rem",
        "themes-spacing-radius-small-button": "7.5rem",
        "themes-spacing-radius-small-button-icon": "7.5rem",
        "themes-spacing-radius-small-image": "0.75rem",
        "themes-spacing-radius-small-image-card": "0.75rem",
        "themes-spacing-radius-small-card": "0.75rem",
        "themes-spacing-radius-small-icon": "7.5rem",
        "themes-spacing-radius-small-label": "0.125rem",
        "themes-spacing-radius-small-tag": "0.25rem",
        "themes-spacing-radius-small-badge": "0rem",
        "themes-spacing-radius-small-item": "0rem",
        "themes-spacing-radius-small-form-field-input": "0.5rem",
        "themes-spacing-radius-small-general": "1rem"
      },
      "borderWidth": {
        "3": "3px",
        "components-article-card-spacing-large-border-width": "0.0625rem",
        "components-generic-card-color-border-width": "0.0625rem",
        "components-header-spacing-large-search-border-width-top": "0.125rem",
        "components-header-spacing-large-search-border-width-right": "0.125rem",
        "components-header-spacing-large-search-border-width-bottom": "0.125rem",
        "components-header-spacing-large-search-border-width-left": "0.125rem",
        "components-product-card-spacing-border-width": "0rem"
      },
      "maxHeight": {},
      "maxWidth": {
        "components-article-hero-spacing-large-header-max-width": "66.375rem",
        "components-content-list-spacing-large-item-image-max-width": "33.75rem",
        "components-content-list-spacing-small-item-image-max-width": "33.75rem"
      },
      "minHeight": {
        "components-dropdown-item-min-height": "3rem",
        "components-icon-min-height": "3rem",
        "components-tab-item-min-height": "3rem",
        "components-text-field-input-min-height": "3rem",
        "components-text-field-icon-min-height": "3rem"
      },
      "minWidth": {
        "components-content-list-spacing-small-item-image-min-width": "18.75rem",
        "components-icon-min-width": "3rem",
        "components-text-field-icon-min-width": "3rem"
      },
      "opacity": {
        "components-accessibility-bar-color-bg": 1,
        "components-accessibility-bar-color-text": 1,
        "components-accordion-color-bg": 1,
        "components-accordion-color-title": 1,
        "components-accordion-color-body": 1,
        "components-accordion-color-section-section-bg": 1,
        "components-accordion-color-section-section-title": 1,
        "components-accordion-color-section-section-body": 1,
        "components-accordion-color-item-item-bg": 1,
        "components-accordion-color-item-divider": 1,
        "components-accordion-color-item-icon-icon-default": 1,
        "components-accordion-color-item-icon-icon-hover": 1,
        "components-accordion-color-item-icon-icon-pressed": 1,
        "components-accordion-color-item-icon-icon-active": 1,
        "components-accordion-color-item-title-item-default": 1,
        "components-accordion-color-item-title-item-hover": 1,
        "components-accordion-color-item-title-item-pressed": 1,
        "components-accordion-color-item-title-item-active": 1,
        "components-accordion-color-content-bg": 1,
        "components-accordion-color-content-title": 1,
        "components-accordion-color-content-body": 1,
        "components-article-card-color-bg": 1,
        "components-article-card-color-border": 1,
        "components-article-card-color-content-bg": 1,
        "components-article-card-color-title": 1,
        "components-article-card-color-subtitle": 1,
        "components-article-card-color-copy": 1,
        "components-article-card-color-description": 1,
        "components-article-card-listing-color-default-bg": 1,
        "components-article-card-listing-color-default-title": 1,
        "components-article-card-listing-color-default-copy": 1,
        "components-article-hero-color-content-bg": 1,
        "components-article-hero-color-bg": 1,
        "components-article-hero-color-headline": 1,
        "components-article-hero-color-body": 1,
        "components-article-hero-color-detail-text": 1,
        "components-article-hero-color-divider": 1,
        "components-article-hero-color-icon": 1,
        "components-article-hero-color-play-icon": 1,
        "components-article-hero-color-selected-stroke": 1,
        "components-banner-color-bg-default": 1,
        "components-banner-color-icon-default": 1,
        "components-banner-color-title-default": 1,
        "components-banner-color-body-default": 1,
        "components-banner-color-icon-close-default": 1,
        "components-banner-color-bg-alert": 1,
        "components-banner-color-icon-alert": 1,
        "components-banner-color-title-alert": 1,
        "components-banner-color-body-alert": 1,
        "components-banner-color-icon-close-alert": 1,
        "components-breadcrumb-color-bg": 1,
        "components-breadcrumb-color-breadcrumb-link-default": 1,
        "components-breadcrumb-color-breadcrumb-link-hover": 1,
        "components-breadcrumb-color-breadcrumb-link-active": 1,
        "components-breadcrumb-color-icon-divider": 1,
        "components-bug-finder-prompt": 1,
        "components-button-color-filled-brand-default-bg": 1,
        "components-button-color-filled-brand-default-text": 1,
        "components-button-color-filled-brand-default-stroke": 1,
        "components-button-color-filled-brand-default-icon": 1,
        "components-button-color-filled-brand-hover-bg": 1,
        "components-button-color-filled-brand-hover-text": 1,
        "components-button-color-filled-brand-hover-stroke": 1,
        "components-button-color-filled-brand-hover-icon": 1,
        "components-button-color-filled-brand-pressed-bg": 1,
        "components-button-color-filled-brand-pressed-text": 1,
        "components-button-color-filled-brand-pressed-stroke": 1,
        "components-button-color-filled-brand-pressed-icon": 1,
        "components-button-color-filled-brand-disabled-bg": null,
        "components-button-color-filled-brand-disabled-text": 0.02,
        "components-button-color-filled-brand-disabled-stroke": null,
        "components-button-color-filled-brand-disabled-icon": 0.02,
        "components-button-color-filled-brand-focus-bg": 1,
        "components-button-color-filled-brand-focus-text": 1,
        "components-button-color-filled-brand-focus-stroke": 1,
        "components-button-color-filled-brand-focus-icon": 1,
        "components-button-color-filled-brand-focus-outline": 1,
        "components-button-color-filled-tonal-default-bg": 1,
        "components-button-color-filled-tonal-default-text": 1,
        "components-button-color-filled-tonal-default-stroke": 1,
        "components-button-color-filled-tonal-default-icon": 1,
        "components-button-color-filled-tonal-hover-bg": 1,
        "components-button-color-filled-tonal-hover-text": 1,
        "components-button-color-filled-tonal-hover-stroke": 1,
        "components-button-color-filled-tonal-hover-icon": 1,
        "components-button-color-filled-tonal-pressed-bg": 1,
        "components-button-color-filled-tonal-pressed-text": 1,
        "components-button-color-filled-tonal-pressed-stroke": 1,
        "components-button-color-filled-tonal-pressed-icon": 1,
        "components-button-color-filled-tonal-disabled-bg": 0.02,
        "components-button-color-filled-tonal-disabled-text": 0.04,
        "components-button-color-filled-tonal-disabled-stroke": 0.02,
        "components-button-color-filled-tonal-disabled-icon": 0.04,
        "components-button-color-filled-tonal-focus-bg": 1,
        "components-button-color-filled-tonal-focus-text": 1,
        "components-button-color-filled-tonal-focus-stroke": 1,
        "components-button-color-filled-tonal-focus-icon": 1,
        "components-button-color-filled-tonal-focus-outline": 1,
        "components-button-color-filled-white-default-bg": 1,
        "components-button-color-filled-white-default-text": 1,
        "components-button-color-filled-white-default-stroke": 1,
        "components-button-color-filled-white-default-icon": 1,
        "components-button-color-filled-white-hover-bg": 1,
        "components-button-color-filled-white-hover-text": 1,
        "components-button-color-filled-white-hover-stroke": 1,
        "components-button-color-filled-white-hover-icon": 1,
        "components-button-color-filled-white-pressed-bg": 1,
        "components-button-color-filled-white-pressed-text": 1,
        "components-button-color-filled-white-pressed-stroke": 1,
        "components-button-color-filled-white-pressed-icon": 1,
        "components-button-color-filled-white-disabled-bg": 0.02,
        "components-button-color-filled-white-disabled-text": null,
        "components-button-color-filled-white-disabled-stroke": 0.02,
        "components-button-color-filled-white-disabled-icon": 0.02,
        "components-button-color-filled-white-focus-bg": 1,
        "components-button-color-filled-white-focus-text": 1,
        "components-button-color-filled-white-focus-stroke": 1,
        "components-button-color-filled-white-focus-icon": 1,
        "components-button-color-filled-white-focus-outline": 1,
        "components-button-color-outline-brand-default-bg": null,
        "components-button-color-outline-brand-default-text": 1,
        "components-button-color-outline-brand-default-stroke": 1,
        "components-button-color-outline-brand-default-icon": 1,
        "components-button-color-outline-brand-hover-bg": 1,
        "components-button-color-outline-brand-hover-text": 1,
        "components-button-color-outline-brand-hover-stroke": 1,
        "components-button-color-outline-brand-hover-icon": 1,
        "components-button-color-outline-brand-pressed-bg": 1,
        "components-button-color-outline-brand-pressed-text": 1,
        "components-button-color-outline-brand-pressed-stroke": 1,
        "components-button-color-outline-brand-pressed-icon": 1,
        "components-button-color-outline-brand-disabled-bg": null,
        "components-button-color-outline-brand-disabled-text": null,
        "components-button-color-outline-brand-disabled-stroke": null,
        "components-button-color-outline-brand-disabled-icon": null,
        "components-button-color-outline-brand-focus-bg": 1,
        "components-button-color-outline-brand-focus-text": 1,
        "components-button-color-outline-brand-focus-stroke": 1,
        "components-button-color-outline-brand-focus-icon": 1,
        "components-button-color-outline-brand-focus-outline": 1,
        "components-button-color-outline-tonal-default-bg": 1,
        "components-button-color-outline-tonal-default-text": 1,
        "components-button-color-outline-tonal-default-stroke": 1,
        "components-button-color-outline-tonal-default-icon": 1,
        "components-button-color-outline-tonal-hover-bg": 1,
        "components-button-color-outline-tonal-hover-text": 1,
        "components-button-color-outline-tonal-hover-stroke": 1,
        "components-button-color-outline-tonal-hover-icon": 1,
        "components-button-color-outline-tonal-pressed-bg": 1,
        "components-button-color-outline-tonal-pressed-text": 1,
        "components-button-color-outline-tonal-pressed-stroke": 1,
        "components-button-color-outline-tonal-pressed-icon": 1,
        "components-button-color-outline-tonal-disabled-bg": 0.02,
        "components-button-color-outline-tonal-disabled-text": 0.02,
        "components-button-color-outline-tonal-disabled-stroke": 0.02,
        "components-button-color-outline-tonal-disabled-icon": 0.02,
        "components-button-color-outline-tonal-focus-bg": 1,
        "components-button-color-outline-tonal-focus-text": 1,
        "components-button-color-outline-tonal-focus-stroke": 1,
        "components-button-color-outline-tonal-focus-icon": 1,
        "components-button-color-outline-tonal-focus-outline": 1,
        "components-button-color-outline-white-default-bg": 0.02,
        "components-button-color-outline-white-default-text": 1,
        "components-button-color-outline-white-default-stroke": 1,
        "components-button-color-outline-white-default-icon": 1,
        "components-button-color-outline-white-hover-bg": 1,
        "components-button-color-outline-white-hover-text": 1,
        "components-button-color-outline-white-hover-stroke": 1,
        "components-button-color-outline-white-hover-icon": 1,
        "components-button-color-outline-white-pressed-bg": 1,
        "components-button-color-outline-white-pressed-text": 1,
        "components-button-color-outline-white-pressed-stroke": 1,
        "components-button-color-outline-white-pressed-icon": 1,
        "components-button-color-outline-white-disabled-bg": 0.02,
        "components-button-color-outline-white-disabled-text": 0.02,
        "components-button-color-outline-white-disabled-stroke": 0.02,
        "components-button-color-outline-white-disabled-icon": 0.02,
        "components-button-color-outline-white-focus-bg": 0.02,
        "components-button-color-outline-white-focus-text": 1,
        "components-button-color-outline-white-focus-stroke": 1,
        "components-button-color-outline-white-focus-icon": 1,
        "components-button-color-outline-white-focus-outline": 1,
        "components-button-color-text-brand-default-bg": 0.02,
        "components-button-color-text-brand-default-text": 1,
        "components-button-color-text-brand-default-stroke": 1,
        "components-button-color-text-brand-default-icon": 1,
        "components-button-color-text-brand-hover-bg": 0.02,
        "components-button-color-text-brand-hover-bg-icon-only": 1,
        "components-button-color-text-brand-hover-text": 1,
        "components-button-color-text-brand-hover-stroke": 1,
        "components-button-color-text-brand-hover-icon": 1,
        "components-button-color-text-brand-pressed-bg": 0.02,
        "components-button-color-text-brand-pressed-bg-icon-only": 1,
        "components-button-color-text-brand-pressed-text": 1,
        "components-button-color-text-brand-pressed-stroke": 1,
        "components-button-color-text-brand-pressed-icon": 1,
        "components-button-color-text-brand-disabled-bg": 0.02,
        "components-button-color-text-brand-disabled-text": null,
        "components-button-color-text-brand-disabled-stroke": null,
        "components-button-color-text-brand-disabled-icon": null,
        "components-button-color-text-brand-focus-bg": 1,
        "components-button-color-text-brand-focus-text": 1,
        "components-button-color-text-brand-focus-stroke": 1,
        "components-button-color-text-brand-focus-icon": 1,
        "components-button-color-text-brand-focus-outline": 1,
        "components-button-color-text-tonal-default-bg": 0.02,
        "components-button-color-text-tonal-default-text": 1,
        "components-button-color-text-tonal-default-stroke": 1,
        "components-button-color-text-tonal-default-icon": 1,
        "components-button-color-text-tonal-hover-bg": 0.02,
        "components-button-color-text-tonal-hover-bg-icon-only": 1,
        "components-button-color-text-tonal-hover-text": 1,
        "components-button-color-text-tonal-hover-stroke": 1,
        "components-button-color-text-tonal-hover-icon": 1,
        "components-button-color-text-tonal-pressed-bg": 0.02,
        "components-button-color-text-tonal-pressed-bg-icon-only": 1,
        "components-button-color-text-tonal-pressed-text": 1,
        "components-button-color-text-tonal-pressed-stroke": 1,
        "components-button-color-text-tonal-pressed-icon": 1,
        "components-button-color-text-tonal-disabled-bg": 0.02,
        "components-button-color-text-tonal-disabled-text": 0.02,
        "components-button-color-text-tonal-disabled-stroke": 0.02,
        "components-button-color-text-tonal-disabled-icon": 0.02,
        "components-button-color-text-tonal-focus-bg": 1,
        "components-button-color-text-tonal-focus-text": 1,
        "components-button-color-text-tonal-focus-stroke": 1,
        "components-button-color-text-tonal-focus-icon": 1,
        "components-button-color-text-tonal-focus-outline": 1,
        "components-button-color-text-white-default-bg": 0.02,
        "components-button-color-text-white-default-text": 1,
        "components-button-color-text-white-default-stroke": 1,
        "components-button-color-text-white-default-icon": 1,
        "components-button-color-text-white-hover-bg": 0.02,
        "components-button-color-text-white-hover-bg-icon-only": 1,
        "components-button-color-text-white-hover-text": 1,
        "components-button-color-text-white-hover-stroke": 1,
        "components-button-color-text-white-hover-icon": 1,
        "components-button-color-text-white-pressed-bg": 0.02,
        "components-button-color-text-white-pressed-bg-icon-only": 1,
        "components-button-color-text-white-pressed-text": 1,
        "components-button-color-text-white-pressed-stroke": 1,
        "components-button-color-text-white-pressed-icon": 1,
        "components-button-color-text-white-disabled-bg": 0.02,
        "components-button-color-text-white-disabled-text": 0.01,
        "components-button-color-text-white-disabled-stroke": 0.01,
        "components-button-color-text-white-disabled-icon": 0.01,
        "components-button-color-text-white-focus-bg": 1,
        "components-button-color-text-white-focus-text": 1,
        "components-button-color-text-white-focus-stroke": 1,
        "components-button-color-text-white-focus-icon": 1,
        "components-button-color-text-white-focus-outline": 1,
        "components-button-group-color-border": 1,
        "components-button-group-color-button-bg-default": 0.02,
        "components-button-group-color-button-fg-default": 1,
        "components-button-group-color-button-bg-hover": 1,
        "components-button-group-color-button-fg-hover": 1,
        "components-button-group-color-button-bg-active": 1,
        "components-button-group-color-button-fg-active": 1,
        "components-button-group-color-overlay-left-gradient-fill-1": 1,
        "components-button-group-color-overlay-left-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-1": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-3": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-4": 0.02,
        "components-button-group-color-stroke-hover-gradient-fill-1": 1,
        "components-button-group-color-stroke-hover-gradient-fill-2": 1,
        "components-button-group-color-stroke-hover-gradient-fill-3": 1,
        "components-button-group-color-stroke-hover-gradient-fill-4": 1,
        "components-button-group-color-stroke-active-gradient-fill-1": 1,
        "components-button-group-color-stroke-active-gradient-fill-2": 1,
        "components-button-group-color-stroke-active-gradient-fill-3": 1,
        "components-button-group-color-stroke-active-gradient-fill-4": 1,
        "components-category-card-color-bg": 1,
        "components-category-card-color-content-bg": 1,
        "components-category-card-color-category-bg": 1,
        "components-category-card-color-category-bg-2": 1,
        "components-category-card-color-category-bg-3": 1,
        "components-category-card-color-category-bg-4": 1,
        "components-category-card-color-category-bg-5": 1,
        "components-category-card-color-category-bg-6": 1,
        "components-category-card-color-title": 1,
        "components-category-card-color-copy": 1,
        "components-category-card-color-stroke": 1,
        "components-category-card-color-stroke-hover": 1,
        "components-category-card-listing-color-bg": 1,
        "components-category-card-listing-color-title": 1,
        "components-category-card-listing-color-copy": 1,
        "components-card-search-result-color-bg": 1,
        "components-card-search-result-color-label": 1,
        "components-card-search-result-color-title": 1,
        "components-card-search-result-color-body": 1,
        "components-card-search-result-color-divider-line": 1,
        "components-contact-us-promo-color-light-bg": 1,
        "components-contact-us-promo-color-light-title": 1,
        "components-contact-us-promo-color-light-body": 1,
        "components-contact-us-promo-color-default-bg": 1,
        "components-contact-us-promo-color-default-title": 1,
        "components-contact-us-promo-color-default-body": 1,
        "components-contact-us-promo-color-brand-bg": 1,
        "components-contact-us-promo-color-brand-title": 1,
        "components-contact-us-promo-color-brand-body": 1,
        "components-content-list-color-bg": 1,
        "components-content-list-color-title": 1,
        "components-content-list-color-body": 1,
        "components-content-list-color-icon": 1,
        "components-cookies-colors-bg": 1,
        "components-cookies-colors-title": 1,
        "components-cookies-colors-body": 1,
        "components-cookies-colors-icon": 1,
        "components-dropdown-bg": 1,
        "components-dropdown-border-default": 1,
        "components-dropdown-border-hover": 1,
        "components-dropdown-border-focused": 1,
        "components-dropdown-border-destructive": 1,
        "components-dropdown-label-text-default": 1,
        "components-dropdown-item-text-default": 1,
        "components-dropdown-item-bg-default": 1,
        "components-dropdown-item-bg-hover": 1,
        "components-dropdown-input-field-default": 1,
        "components-dropdown-input-field-focused": 1,
        "components-dropdown-input-label-focused": 1,
        "components-dropdown-input-field-destructive": 1,
        "components-external-link-card-bg": 1,
        "components-external-link-card-card-bg": 1,
        "components-external-link-card-card-title": 1,
        "components-external-link-card-card-subtitle": 1,
        "components-external-link-card-card-eyebrow": 1,
        "components-fab-color-border-default": 1,
        "components-footer-brand-color-bg": 1,
        "components-footer-brand-color-logo": 1,
        "components-footer-brand-color-title-text": 1,
        "components-footer-brand-color-description-text": 1,
        "components-footer-brand-color-footer-menu-expanded": 1,
        "components-footer-brand-color-link-default": 1,
        "components-footer-brand-color-link-hover": 1,
        "components-footer-brand-color-link-pressed": 1,
        "components-footer-brand-color-category-link-default": 1,
        "components-footer-brand-color-category-link-hover": 1,
        "components-footer-brand-color-category-link-pressed": 1,
        "components-footer-brand-color-subcategory-link-default": 1,
        "components-footer-brand-color-subcategory-link-hover": 1,
        "components-footer-brand-color-subcategory-link-pressed": 1,
        "components-footer-brand-color-icon-icon-default": 1,
        "components-footer-brand-color-icon-icon-pressed": 1,
        "components-footer-brand-color-social-icon-default": 1,
        "components-footer-brand-color-social-icon-hover": 1,
        "components-footer-brand-color-social-icon-pressed": 1,
        "components-footer-brand-spacing-category-link-hover": 1,
        "components-footer-global-color-bg": 1,
        "components-footer-global-color-title-text": 1,
        "components-footer-global-color-disclaimer-text": 1,
        "components-footer-global-color-link-default": 1,
        "components-footer-global-color-link-hover": 1,
        "components-footer-global-color-link-pressed": 1,
        "components-generic-card-color-bg": 1,
        "components-generic-card-color-content-bg": 1,
        "components-generic-card-color-title": 1,
        "components-generic-card-color-subtitle": 1,
        "components-generic-card-color-copy": 1,
        "components-global-search-color-omnibox-list-bg": 1,
        "components-global-search-color-omnibox-divider": 1,
        "components-global-search-color-omnibox-item-bg-default": 1,
        "components-global-search-color-omnibox-item-bg-hover": 1,
        "components-global-search-color-omnibox-item-text-default": 1,
        "components-global-search-color-omnibox-item-text-hover": 1,
        "components-global-search-color-omnibox-label-text-default": 1,
        "components-global-search-color-omnibox-category-text-default": 1,
        "components-generic-card-listing-color-bg": 1,
        "components-generic-card-listing-color-title": 1,
        "components-generic-card-listing-color-copy": 1,
        "components-header-color-bg": 1,
        "components-header-color-bottom-stroke": 1,
        "components-header-color-search-search-border-default": 1,
        "components-header-color-search-search-text": 1,
        "components-header-color-search-search-icon": 1,
        "components-header-color-search-search-bar": 1,
        "components-header-color-mobile-mobile-expanded-bg": 1,
        "components-header-color-mobile-pressed-bg": 1,
        "components-header-color-language-selector-language": 1,
        "components-header-color-language-selector-country": 1,
        "components-header-color-language-selector-country-hover-bg": 1,
        "components-header-color-language-selector-megamenu-language": 1,
        "components-header-color-language-selector-megamenu-country": 1,
        "components-header-color-language-selector-megamenu-country-hover": 1,
        "components-header-color-language-selector-bg": 1,
        "components-header-color-language-selector-stroke": 1,
        "components-header-color-mega-menu-bg": 1,
        "components-header-color-mega-menu-featured-bg": 1,
        "components-header-color-mega-menu-featured-title": 1,
        "components-header-color-mega-menu-category-title": 1,
        "components-header-color-mega-menu-bg-accent": 1,
        "components-header-color-icon-icon-default": 1,
        "components-header-color-icon-icon-hover": 1,
        "components-header-color-icon-icon-pressed": 1,
        "components-header-color-links-nav-main-link-default": 1,
        "components-header-color-links-nav-main-link-hover": 1,
        "components-header-color-links-nav-main-link-pressed": 1,
        "components-header-color-links-nav-main-link-active": 1,
        "components-header-color-links-nav-main-active-bar": 1,
        "components-header-color-links-nav-main-bg-color": 1,
        "components-header-color-links-nav-category-link-category-default": 1,
        "components-header-color-links-nav-category-link-category-hover": 1,
        "components-header-color-links-nav-category-link-category-pressed": 1,
        "components-header-color-links-nav-category-link-category-active": 1,
        "components-header-color-links-nav-menu-link-link-default": 1,
        "components-header-color-links-nav-menu-link-link-hover": 1,
        "components-header-color-links-nav-menu-link-link-pressed": 1,
        "components-header-color-links-nav-menu-link-link-active": 1,
        "components-header-color-links-nav-menu-link-bg": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-default": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-active": 1,
        "components-hero-homepage-color-bg": 1,
        "components-hero-homepage-color-container-bg": 1,
        "components-hero-homepage-color-eyebrow": 1,
        "components-hero-homepage-color-header": 1,
        "components-hero-homepage-color-sub-header": 1,
        "components-hero-homepage-color-body": 1,
        "components-hero-homepage-color-play-pause": 1,
        "components-hero-internal-color-default-title": 1,
        "components-hero-internal-color-default-body": 1,
        "components-hero-internal-color-default-bg": 1,
        "components-hero-internal-color-accent-bg": 1,
        "components-hero-internal-color-with-image-bg": 1,
        "components-hero-internal-color-default-bg-2": 1,
        "components-hero-internal-color-with-image-title": 1,
        "components-hero-internal-color-with-image-body": 1,
        "components-heading-page-title-color-default-bg": 1,
        "components-heading-page-title-color-default-title": 1,
        "components-heading-page-title-color-default-body": 1,
        "components-heading-page-title-color-light-bg": 1,
        "components-heading-page-title-color-light-title": 1,
        "components-heading-page-title-color-light-body": 1,
        "components-heading-page-title-color-color-bg": 1,
        "components-heading-page-title-color-color-title": 1,
        "components-heading-page-title-color-color-body": 1,
        "components-hyperlink-color-dark-default": 1,
        "components-hyperlink-color-dark-hover": 1,
        "components-hyperlink-color-dark-visited": 1,
        "components-hyperlink-color-light-default": 1,
        "components-hyperlink-color-light-hover": 1,
        "components-hyperlink-color-light-visited": 1,
        "components-icon-default": 1,
        "components-icon-focused": 1,
        "components-icon-card-color-bg": 1,
        "components-icon-card-color-icon-bg": 1,
        "components-icon-card-color-icon": 1,
        "components-icon-card-color-title": 1,
        "components-icon-card-color-subtitle": 1,
        "components-icon-card-color-description": 1,
        "components-icon-card-listing-color-bg": 1,
        "components-icon-card-listing-color-inner-bg": 1,
        "components-icon-card-listing-color-brand-bg": 1,
        "components-icon-card-listing-color-title": 1,
        "components-icon-card-listing-color-copy": 1,
        "components-icon-card-listing-color-inner-legal-text": 1,
        "components-jumplink-color-bg-default": 1,
        "components-jumplink-color-link-text-default": 1,
        "components-jumplink-color-link-text-hover": 1,
        "components-jumplink-color-link-border-hover": 1,
        "components-jumplink-color-link-text-active": 1,
        "components-jumplink-color-link-border-active": 1,
        "components-jumplink-color-title-text": 1,
        "components-jumplink-color-description-text": 1,
        "components-layout-bg-default": 1,
        "components-lightbox-color-overlay-bg": 0.02,
        "components-lightbox-color-title": 1,
        "components-lightbox-color-body": 1,
        "components-label-color-on-color-label-label-default": 1,
        "components-label-color-on-color-label-label-hover": 1,
        "components-label-color-on-color-label-label-pressed": 1,
        "components-label-color-on-color-background-bg-default": 1,
        "components-label-color-on-color-background-bg-hover": 1,
        "components-label-color-on-color-background-bg-pressed": 1,
        "components-label-color-on-color-stroke-hover": 1,
        "components-label-color-on-color-stroke-pressed": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-4": 1,
        "components-label-color-on-white-label-label-default": 1,
        "components-label-color-on-white-label-label-hover": 1,
        "components-label-color-on-white-label-label-pressed": 1,
        "components-label-color-on-white-background-bg-default": 0.02,
        "components-label-color-on-white-background-bg-hover": 1,
        "components-label-color-on-white-background-bg-pressed": 1,
        "components-label-color-on-white-stroke-hover": 1,
        "components-label-color-on-white-stroke-pressed": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-4": 1,
        "components-legal-disclaimer-color-default-bg": 1,
        "components-legal-disclaimer-color-default-title": 1,
        "components-legal-disclaimer-color-default-body": 1,
        "components-legal-disclaimer-color-light-bg": 1,
        "components-legal-disclaimer-color-light-title": 1,
        "components-legal-disclaimer-color-light-body": 1,
        "components-legal-disclaimer-color-brand-bg": 1,
        "components-legal-disclaimer-color-brand-title": 1,
        "components-legal-disclaimer-color-brand-body": 1,
        "components-media-video-thumbnail-overlay-bg": null,
        "components-media-caption-color-bg": 1,
        "components-media-caption-color-text": 1,
        "components-media-gallery-color-bg": 1,
        "components-media-gallery-color-title": 1,
        "components-media-gallery-color-body": 1,
        "components-pagination-on-white-accent-default": 1,
        "components-pagination-on-white-accent-scroll": 1,
        "components-pagination-on-white-disabled": 1,
        "components-pagination-on-white-bg": 1,
        "components-pagination-on-white-stroke": 1,
        "components-pagination-on-white-bg-active": 1,
        "components-pagination-on-white-bg-hover": 1,
        "components-pagination-on-white-text-default": 1,
        "components-pagination-on-white-text-hover": 1,
        "components-pagination-on-white-text-active": 1,
        "components-pagination-on-color-accent-default": 1,
        "components-pagination-on-color-accent-scroll": 1,
        "components-pagination-on-color-disabled": 1,
        "components-pagination-on-color-bg": 1,
        "components-pagination-on-color-stroke": 0.02,
        "components-pagination-on-color-bg-active": 1,
        "components-pagination-on-color-bg-hover": 1,
        "components-pagination-on-color-text-default": 1,
        "components-pagination-on-color-text-hover": 1,
        "components-pagination-on-color-text-active": 1,
        "components-pdf-link-card-color-bg": 1,
        "components-pdf-link-card-color-card-bg": 1,
        "components-pdf-link-card-color-card-title": 1,
        "components-pdf-link-card-color-card-subtitle": 1,
        "components-pdf-link-card-color-card-body": 1,
        "components-product-card-color-bg": 1,
        "components-product-card-color-content-bg": 1,
        "components-product-card-color-border": 1,
        "components-product-card-color-title": 1,
        "components-product-card-color-description": 1,
        "components-product-card-color-star-rating": 1,
        "components-product-card-color-badge-bg": 1,
        "components-product-card-color-badge-text": 1,
        "components-product-card-listing-color-bg": 1,
        "components-product-card-listing-color-title": 1,
        "components-product-card-listing-color-copy": 1,
        "components-product-card-listing-color-legal-text": 1,
        "components-product-information-color-bg": 1,
        "components-product-information-color-content-bg": 1,
        "components-product-information-color-headline": 1,
        "components-product-information-color-body": 1,
        "components-product-information-color-detail-text": 1,
        "components-product-information-color-divider": 1,
        "components-product-information-color-icon": 1,
        "components-product-information-color-play-icon": 1,
        "components-product-information-color-selected-stroke": 1,
        "components-product-results-list-with-search-color-bg": 1,
        "components-promo-color-default-bg": 1,
        "components-promo-color-default-image-bg": 1,
        "components-promo-color-default-content-bg": 1,
        "components-promo-color-default-title": 1,
        "components-promo-color-default-body": 1,
        "components-promo-color-default-category": 1,
        "components-promo-color-light-bg": 1,
        "components-promo-color-light-image-bg": 1,
        "components-promo-color-light-content-bg": 1,
        "components-promo-color-light-title": 1,
        "components-promo-color-light-body": 1,
        "components-promo-color-light-category": 1,
        "components-promo-color-brand-bg": 1,
        "components-promo-color-brand-image-bg": 1,
        "components-promo-color-brand-content-bg": 1,
        "components-promo-color-brand-title": 1,
        "components-promo-color-brand-body": 1,
        "components-promo-color-brand-category": 1,
        "components-pull-quote-color-bg": 1,
        "components-pull-quote-color-stroke": 1,
        "components-pull-quote-color-quote-bg": 1,
        "components-pull-quote-color-quote-title": 1,
        "components-pull-quote-color-quote-by-text": 1,
        "components-pull-quote-color-quote-detail": 1,
        "components-pull-quote-color-quote-highlight": 1,
        "components-promo-alternate-color-default-bg": 1,
        "components-promo-alternate-color-default-image-bg": 1,
        "components-promo-alternate-color-default-content-bg": 1,
        "components-promo-alternate-color-default-title": 1,
        "components-promo-alternate-color-default-body": 1,
        "components-promo-alternate-color-default-category": 1,
        "components-promo-alternate-color-light-bg": 1,
        "components-promo-alternate-color-light-image-bg": 1,
        "components-promo-alternate-color-light-content-bg": 1,
        "components-promo-alternate-color-light-title": 1,
        "components-promo-alternate-color-light-body": 1,
        "components-promo-alternate-color-light-category": 1,
        "components-promo-alternate-color-brand-bg": 1,
        "components-promo-alternate-color-brand-image-bg": 1,
        "components-promo-alternate-color-brand-content-bg": 1,
        "components-promo-alternate-color-brand-title": 1,
        "components-promo-alternate-color-brand-body": 1,
        "components-promo-alternate-color-brand-category": 1,
        "components-share-list-color-title": 1,
        "components-share-list-color-bg": 1,
        "components-share-list-color-link-default": 1,
        "components-share-list-color-link-hover": 1,
        "components-share-list-color-bg-link-hover": 1,
        "components-share-list-color-close-icon": 1,
        "components-share-list-color-facebook-icon": 1,
        "components-share-list-color-pinterest-icon": 1,
        "components-share-list-color-twitter-icon": 1,
        "components-share-list-color-basic-icon-default": 1,
        "components-share-list-color-icon-hover": 1,
        "components-share-list-color-border": 1,
        "components-site-search-bg": 1,
        "components-site-search-results_text": 1,
        "components-site-search-filter-filter-selected-bg": 1,
        "components-site-search-facet-group-label-bottom-line": 1,
        "components-site-search-facet-group-label-text": 1,
        "components-site-search-facet-group-label-bg": 1,
        "components-site-search-facet-bg": 1,
        "components-site-search-facet-stroke": 1,
        "components-site-search-refine-bg": 1,
        "components-site-search-refine-right-divider": 1,
        "components-site-search-refine-title": 1,
        "components-site-search-hero-search-bg": 1,
        "components-site-search-hero-search-accent-bg": 1,
        "components-site-search-hero-search-title": 1,
        "components-stats-stat-text": 1,
        "components-stats-stat-text-on-light": 1,
        "components-stats-supporting-text": 1,
        "components-stats-bg-default": 1,
        "components-stats-bg-inner": 1,
        "components-stats-bg-section-inner": 1,
        "components-stats-title": 1,
        "components-stats-copy": 1,
        "components-supporting-text-default": 1,
        "components-supporting-text-destructive": 1,
        "components-tab-bg": 1,
        "components-tab-border-default": 1,
        "components-tab-border-hover": 1,
        "components-tab-border-focused": 1,
        "components-tab-border-destructive": 1,
        "components-tab-label-text-default": 1,
        "components-tab-item-text-default": 1,
        "components-tab-item-bg-default": 1,
        "components-tab-item-bg-hover": 1,
        "components-tab-input-field-default": 1,
        "components-tab-input-field-focused": 1,
        "components-tab-input-label-focused": 1,
        "components-tab-input-field-destructive": 1,
        "components-tab-bg-2": 1,
        "components-tag-color-bg": 1,
        "components-tag-color-text": 1,
        "components-tag-color-stroke": 1,
        "components-tag-color-bg-hover": 1,
        "components-tag-color-text-hover": 1,
        "components-tag-color-stroke-hover": 1,
        "components-text-field-bg": 1,
        "components-text-field-bg-focused": 1,
        "components-text-field-border-default": 1,
        "components-text-field-border-hover": 1,
        "components-text-field-border-focused": 1,
        "components-text-field-border-destructive": 1,
        "components-text-field-input-field-default": 1,
        "components-text-field-input-field-focused": 1,
        "components-text-field-input-label-focused": 1,
        "components-text-field-input-field-destructive": 1,
        "components-text-field-icon-default": 1,
        "components-text-field-icon-destructive": 1,
        "components-text-field-supporting-text-default": 1,
        "components-text-field-supporting-text-destructive": 1,
        "components-tip-color-bg": 1,
        "components-tip-color-title": 1,
        "components-tip-color-body": 1,
        "components-tip-color-stroke": 1,
        "components-video-color-bg": 1,
        "themes-general-surface-color-light-bg": 1,
        "themes-general-surface-color-light-title": 1,
        "themes-general-surface-color-light-body": 1,
        "themes-general-surface-color-light-decor-line-break": 1,
        "themes-general-surface-color-light-rule-line": 1,
        "themes-general-surface-color-dark-bg": 1,
        "themes-general-surface-color-dark-title": 1,
        "themes-general-surface-color-dark-body": 1,
        "themes-general-surface-color-dark-decor-line-break": 1,
        "themes-general-card-color-dark-title": 1,
        "themes-general-card-color-dark-subtitle": 1,
        "themes-general-card-color-dark-body": 1,
        "themes-general-card-color-light-title": 1,
        "themes-general-card-color-light-subtitle": 1,
        "themes-general-card-color-light-body": 1,
        "themes-shadow-color": 1,
        "themes-shadow-share-list-color-fill": 0,
        "themes-shadow-header-menu-color-fill": 0,
        "themes-gradient-loader-gradient-fill-1": 1,
        "themes-gradient-loader-gradient-fill-2": null,
        "themes-color-background-white": 1,
        "themes-color-background-light": 1,
        "themes-color-background-dark": 1,
        "themes-color-background-black": 1,
        "themes-color-background-brand1": 1,
        "themes-color-background-brand2": 1,
        "themes-color-background-accent1": 1,
        "themes-color-background-accent2": 1,
        "themes-color-background-body": 1,
        "themes-color-stroke-white": 1,
        "themes-color-stroke-light": 1,
        "themes-color-stroke-dark": 1,
        "themes-color-stroke-black": 1,
        "themes-color-stroke-brand1": 1,
        "themes-color-stroke-brand2": 1,
        "themes-color-stroke-accent1": 1,
        "themes-color-stroke-accent2": 1,
        "themes-color-text-white": 1,
        "themes-color-text-light": 1,
        "themes-color-text-dark": 1,
        "themes-color-text-black": 1,
        "themes-color-text-brand1": 1,
        "themes-color-text-brand2": 1,
        "themes-color-text-accent1": 1,
        "themes-color-text-accent2": 1,
        "colors-grayscale-100": 1,
        "colors-grayscale-200": 1,
        "colors-grayscale-300": 1,
        "colors-grayscale-400": 1,
        "colors-grayscale-500": 1,
        "colors-grayscale-600": 1,
        "colors-grayscale-700": 1,
        "colors-brand-1-100": 1,
        "colors-brand-1-200": 1,
        "colors-brand-1-300": 1,
        "colors-brand-1-400": 1,
        "colors-brand-1-500": 1,
        "colors-brand-1-600": 1,
        "colors-brand-1-700": 1,
        "colors-brand-2-100": 1,
        "colors-brand-2-200": 1,
        "colors-brand-2-300": 1,
        "colors-brand-2-400": 1,
        "colors-brand-2-500": 1,
        "colors-brand-2-600": 1,
        "colors-brand-2-700": 1,
        "colors-accent-1-100": 1,
        "colors-accent-1-200": 1,
        "colors-accent-1-300": 1,
        "colors-accent-1-400": 1,
        "colors-accent-1-500": 1,
        "colors-accent-1-600": 1,
        "colors-accent-1-700": 1,
        "colors-accent-2-100": 1,
        "colors-accent-2-200": 1,
        "colors-accent-2-300": 1,
        "colors-accent-2-400": 1,
        "colors-accent-2-500": 1,
        "colors-accent-2-600": 1,
        "colors-accent-2-700": 1,
        "colors-accent-3-100": 1,
        "colors-accent-3-200": 1,
        "colors-accent-3-300": 1,
        "colors-accent-3-400": 1,
        "colors-accent-3-500": 1,
        "colors-accent-3-600": 1,
        "colors-accent-3-700": 1,
        "colors-accent-4-400_storage": 1,
        "colors-accessible-accent-1-100": 1,
        "colors-accessible-accent-1-200": 1,
        "colors-accessible-accent-1-300": 1,
        "colors-accessible-accent-1-400": 1,
        "colors-accessible-accent-1-500": 1,
        "colors-accessible-accent-1-600": 1,
        "colors-accessible-accent-1-700": 1,
        "colors-accessible-accent-2-100": 1,
        "colors-accessible-accent-2-200": 1,
        "colors-accessible-accent-2-300": 1,
        "colors-accessible-accent-2-400": 1,
        "colors-accessible-accent-2-500": 1,
        "colors-accessible-accent-2-600": 1,
        "colors-accessible-accent-2-700": 1,
        "colors-accessible-brand-2-100": 1,
        "colors-accessible-brand-2-200": 1,
        "colors-accessible-brand-2-300": 1,
        "colors-accessible-brand-2-400": 1,
        "colors-accessible-brand-2-500": 1,
        "colors-accessible-brand-2-600": 1,
        "colors-accessible-brand-2-700": 1
      }
    },
    "AutanDefense": {
      "colors": {
        "components-accessibility-bar-color-bg": "#00326c",
        "components-accessibility-bar-color-text": "#ffffff",
        "components-accordion-color-bg": "#ffffff",
        "components-accordion-color-title": "#191b1c",
        "components-accordion-color-body": "#27282b",
        "components-accordion-color-section-section-bg": "#ffffff",
        "components-accordion-color-section-section-title": "#191b1c",
        "components-accordion-color-section-section-body": "#27282b",
        "components-accordion-color-item-item-bg": "#ffffff",
        "components-accordion-color-item-divider": "#e8e8ea",
        "components-accordion-color-item-icon-icon-default": "#004697",
        "components-accordion-color-item-icon-icon-hover": "#ffffff",
        "components-accordion-color-item-icon-icon-pressed": "#ffffff",
        "components-accordion-color-item-icon-icon-active": "#ffffff",
        "components-accordion-color-item-title-item-default": "#004697",
        "components-accordion-color-item-title-item-hover": "#ffffff",
        "components-accordion-color-item-title-item-pressed": "#ffffff",
        "components-accordion-color-item-title-item-active": "#ffffff",
        "components-accordion-color-content-bg": "#ffffff",
        "components-accordion-color-content-title": "#191b1c",
        "components-accordion-color-content-body": "#27282b",
        "components-article-card-color-bg": "#fcfcfd",
        "components-article-card-color-border": "#e8e8ea",
        "components-article-card-color-content-bg": "#fcfcfd",
        "components-article-card-color-title": "#191b1c",
        "components-article-card-color-subtitle": "#27282b",
        "components-article-card-color-copy": "#27282b",
        "components-article-card-color-description": "#27282b",
        "components-article-card-listing-color-default-bg": "#ebf1f9",
        "components-article-card-listing-color-default-title": "#191b1c",
        "components-article-card-listing-color-default-copy": "#27282b",
        "components-article-hero-color-content-bg": "#ffffff",
        "components-article-hero-color-bg": "#ffffff",
        "components-article-hero-color-headline": "#191b1c",
        "components-article-hero-color-body": "#27282b",
        "components-article-hero-color-detail-text": "#27282b",
        "components-article-hero-color-divider": "#a2a5ab",
        "components-article-hero-color-icon": "#0053b4",
        "components-article-hero-color-play-icon": "#ffffff",
        "components-article-hero-color-selected-stroke": "#0053b4",
        "components-banner-color-bg-default": "#ebf1f9",
        "components-banner-color-icon-default": "#0053b4",
        "components-banner-color-title-default": "#001936",
        "components-banner-color-body-default": "#373432",
        "components-banner-color-icon-close-default": "#191b1c",
        "components-banner-color-bg-alert": "#b32b30",
        "components-banner-color-icon-alert": "#ffffff",
        "components-banner-color-title-alert": "#ffffff",
        "components-banner-color-body-alert": "#ffffff",
        "components-banner-color-icon-close-alert": "#ffffff",
        "components-breadcrumb-color-bg": "#ffffff",
        "components-breadcrumb-color-breadcrumb-link-default": "#72757e",
        "components-breadcrumb-color-breadcrumb-link-hover": "#4e5056",
        "components-breadcrumb-color-breadcrumb-link-active": "#27282b",
        "components-breadcrumb-color-icon-divider": "#a2a5ab",
        "components-bug-finder-prompt": "#ffffff",
        "components-button-color-filled-brand-default-bg": "#0053b4",
        "components-button-color-filled-brand-default-text": "#ffffff",
        "components-button-color-filled-brand-default-stroke": "#0053b4",
        "components-button-color-filled-brand-default-icon": "#ffffff",
        "components-button-color-filled-brand-hover-bg": "#004697",
        "components-button-color-filled-brand-hover-text": "#ffffff",
        "components-button-color-filled-brand-hover-stroke": "#004697",
        "components-button-color-filled-brand-hover-icon": "#ffffff",
        "components-button-color-filled-brand-pressed-bg": "#00326c",
        "components-button-color-filled-brand-pressed-text": "#ffffff",
        "components-button-color-filled-brand-pressed-stroke": "#00326c",
        "components-button-color-filled-brand-pressed-icon": "#ffffff",
        "components-button-color-filled-brand-disabled-bg": "rgba(0, 83, 180, 0.38)",
        "components-button-color-filled-brand-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-disabled-stroke": "rgba(0, 83, 180, 0.38)",
        "components-button-color-filled-brand-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-focus-bg": "#0053b4",
        "components-button-color-filled-brand-focus-text": "#ffffff",
        "components-button-color-filled-brand-focus-stroke": "#0053b4",
        "components-button-color-filled-brand-focus-icon": "#ffffff",
        "components-button-color-filled-brand-focus-outline": "#286efa",
        "components-button-color-filled-tonal-default-bg": "#ebf1f9",
        "components-button-color-filled-tonal-default-text": "#004697",
        "components-button-color-filled-tonal-default-stroke": "#ebf1f9",
        "components-button-color-filled-tonal-default-icon": "#004697",
        "components-button-color-filled-tonal-hover-bg": "#6698d2",
        "components-button-color-filled-tonal-hover-text": "#00326c",
        "components-button-color-filled-tonal-hover-stroke": "#6698d2",
        "components-button-color-filled-tonal-hover-icon": "#00326c",
        "components-button-color-filled-tonal-pressed-bg": "#6698d2",
        "components-button-color-filled-tonal-pressed-text": "#00326c",
        "components-button-color-filled-tonal-pressed-stroke": "#6698d2",
        "components-button-color-filled-tonal-pressed-icon": "#00326c",
        "components-button-color-filled-tonal-disabled-bg": "rgba(235, 241, 249, 0.38)",
        "components-button-color-filled-tonal-disabled-text": "rgba(0, 70, 151, 0.38)",
        "components-button-color-filled-tonal-disabled-stroke": "rgba(235, 241, 249, 0.38)",
        "components-button-color-filled-tonal-disabled-icon": "rgba(0, 70, 151, 0.38)",
        "components-button-color-filled-tonal-focus-bg": "#ebf1f9",
        "components-button-color-filled-tonal-focus-text": "#004697",
        "components-button-color-filled-tonal-focus-stroke": "#ebf1f9",
        "components-button-color-filled-tonal-focus-icon": "#004697",
        "components-button-color-filled-tonal-focus-outline": "#1c4cae",
        "components-button-color-filled-white-default-bg": "#ffffff",
        "components-button-color-filled-white-default-text": "#191b1c",
        "components-button-color-filled-white-default-stroke": "#ffffff",
        "components-button-color-filled-white-default-icon": "#0053b4",
        "components-button-color-filled-white-hover-bg": "#ebf1f9",
        "components-button-color-filled-white-hover-text": "#191b1c",
        "components-button-color-filled-white-hover-stroke": "#ebf1f9",
        "components-button-color-filled-white-hover-icon": "#004697",
        "components-button-color-filled-white-pressed-bg": "#ebf1f9",
        "components-button-color-filled-white-pressed-text": "#191b1c",
        "components-button-color-filled-white-pressed-stroke": "#ebf1f9",
        "components-button-color-filled-white-pressed-icon": "#004697",
        "components-button-color-filled-white-disabled-bg": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-text": "rgba(25, 27, 28, 0.38)",
        "components-button-color-filled-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-icon": "rgba(0, 83, 180, 0.38)",
        "components-button-color-filled-white-focus-bg": "#ffffff",
        "components-button-color-filled-white-focus-text": "#191b1c",
        "components-button-color-filled-white-focus-stroke": "#ffffff",
        "components-button-color-filled-white-focus-icon": "#0053b4",
        "components-button-color-filled-white-focus-outline": "#1c4cae",
        "components-button-color-outline-brand-default-bg": "rgba(0, 83, 180, 0.00)",
        "components-button-color-outline-brand-default-text": "#0053b4",
        "components-button-color-outline-brand-default-stroke": "#0053b4",
        "components-button-color-outline-brand-default-icon": "#0053b4",
        "components-button-color-outline-brand-hover-bg": "#004697",
        "components-button-color-outline-brand-hover-text": "#ffffff",
        "components-button-color-outline-brand-hover-stroke": "#004697",
        "components-button-color-outline-brand-hover-icon": "#ffffff",
        "components-button-color-outline-brand-pressed-bg": "#00326c",
        "components-button-color-outline-brand-pressed-text": "#ffffff",
        "components-button-color-outline-brand-pressed-stroke": "#00326c",
        "components-button-color-outline-brand-pressed-icon": "#ffffff",
        "components-button-color-outline-brand-disabled-bg": "rgba(0, 83, 180, 0.00)",
        "components-button-color-outline-brand-disabled-text": "rgba(0, 83, 180, 0.38)",
        "components-button-color-outline-brand-disabled-stroke": "rgba(0, 83, 180, 0.38)",
        "components-button-color-outline-brand-disabled-icon": "rgba(0, 83, 180, 0.38)",
        "components-button-color-outline-brand-focus-bg": "#ffffff",
        "components-button-color-outline-brand-focus-text": "#0053b4",
        "components-button-color-outline-brand-focus-stroke": "#0053b4",
        "components-button-color-outline-brand-focus-icon": "#0053b4",
        "components-button-color-outline-brand-focus-outline": "#286efa",
        "components-button-color-outline-tonal-default-bg": "#ffffff",
        "components-button-color-outline-tonal-default-text": "#72757e",
        "components-button-color-outline-tonal-default-stroke": "#72757e",
        "components-button-color-outline-tonal-default-icon": "#72757e",
        "components-button-color-outline-tonal-hover-bg": "#ffffff",
        "components-button-color-outline-tonal-hover-text": "#4e5056",
        "components-button-color-outline-tonal-hover-stroke": "#4e5056",
        "components-button-color-outline-tonal-hover-icon": "#4e5056",
        "components-button-color-outline-tonal-pressed-bg": "#ffffff",
        "components-button-color-outline-tonal-pressed-text": "#4e5056",
        "components-button-color-outline-tonal-pressed-stroke": "#4e5056",
        "components-button-color-outline-tonal-pressed-icon": "#4e5056",
        "components-button-color-outline-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-tonal-disabled-text": "rgba(114, 117, 126, 0.38)",
        "components-button-color-outline-tonal-disabled-stroke": "rgba(114, 117, 126, 0.38)",
        "components-button-color-outline-tonal-disabled-icon": "rgba(114, 117, 126, 0.38)",
        "components-button-color-outline-tonal-focus-bg": "#ffffff",
        "components-button-color-outline-tonal-focus-text": "#72757e",
        "components-button-color-outline-tonal-focus-stroke": "#72757e",
        "components-button-color-outline-tonal-focus-icon": "#72757e",
        "components-button-color-outline-tonal-focus-outline": "#286efa",
        "components-button-color-outline-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-default-text": "#ffffff",
        "components-button-color-outline-white-default-stroke": "#ffffff",
        "components-button-color-outline-white-default-icon": "#ffffff",
        "components-button-color-outline-white-hover-bg": "#ffffff",
        "components-button-color-outline-white-hover-text": "#191b1c",
        "components-button-color-outline-white-hover-stroke": "#ffffff",
        "components-button-color-outline-white-hover-icon": "#0053b4",
        "components-button-color-outline-white-pressed-bg": "#ffffff",
        "components-button-color-outline-white-pressed-text": "#191b1c",
        "components-button-color-outline-white-pressed-stroke": "#ffffff",
        "components-button-color-outline-white-pressed-icon": "#0053b4",
        "components-button-color-outline-white-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-focus-bg": "rgba(255, 255, 255, 0.01)",
        "components-button-color-outline-white-focus-text": "#ffffff",
        "components-button-color-outline-white-focus-stroke": "#ffffff",
        "components-button-color-outline-white-focus-icon": "#ffffff",
        "components-button-color-outline-white-focus-outline": "#286efa",
        "components-button-color-text-brand-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-default-text": "#0053b4",
        "components-button-color-text-brand-default-stroke": "#0053b4",
        "components-button-color-text-brand-default-icon": "#0053b4",
        "components-button-color-text-brand-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-hover-bg-icon-only": "#e8e8ea",
        "components-button-color-text-brand-hover-text": "#004697",
        "components-button-color-text-brand-hover-stroke": "#004697",
        "components-button-color-text-brand-hover-icon": "#004697",
        "components-button-color-text-brand-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-pressed-bg-icon-only": "#e8e8ea",
        "components-button-color-text-brand-pressed-text": "#00326c",
        "components-button-color-text-brand-pressed-stroke": "#00326c",
        "components-button-color-text-brand-pressed-icon": "#00326c",
        "components-button-color-text-brand-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-disabled-text": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-brand-disabled-stroke": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-brand-disabled-icon": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-brand-focus-bg": "#d5e3ff",
        "components-button-color-text-brand-focus-text": "#004697",
        "components-button-color-text-brand-focus-stroke": "#004697",
        "components-button-color-text-brand-focus-icon": "#1c4cae",
        "components-button-color-text-brand-focus-outline": "#1c4cae",
        "components-button-color-text-tonal-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-default-text": "#0053b4",
        "components-button-color-text-tonal-default-stroke": "#0053b4",
        "components-button-color-text-tonal-default-icon": "#0053b4",
        "components-button-color-text-tonal-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-hover-bg-icon-only": "#ebf1f9",
        "components-button-color-text-tonal-hover-text": "#004697",
        "components-button-color-text-tonal-hover-stroke": "#0053b4",
        "components-button-color-text-tonal-hover-icon": "#004697",
        "components-button-color-text-tonal-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-pressed-bg-icon-only": "#ebf1f9",
        "components-button-color-text-tonal-pressed-text": "#004697",
        "components-button-color-text-tonal-pressed-stroke": "#0053b4",
        "components-button-color-text-tonal-pressed-icon": "#004697",
        "components-button-color-text-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-disabled-text": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-tonal-disabled-stroke": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-tonal-disabled-icon": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-tonal-focus-bg": "#d5e3ff",
        "components-button-color-text-tonal-focus-text": "#004697",
        "components-button-color-text-tonal-focus-stroke": "#004697",
        "components-button-color-text-tonal-focus-icon": "#1c4cae",
        "components-button-color-text-tonal-focus-outline": "#1c4cae",
        "components-button-color-text-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-default-text": "#ffffff",
        "components-button-color-text-white-default-stroke": "#ffffff",
        "components-button-color-text-white-default-icon": "#ffffff",
        "components-button-color-text-white-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-hover-bg-icon-only": "#0053b4",
        "components-button-color-text-white-hover-text": "#ffffff",
        "components-button-color-text-white-hover-stroke": "#ffffff",
        "components-button-color-text-white-hover-icon": "#ffffff",
        "components-button-color-text-white-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-pressed-bg-icon-only": "#0053b4",
        "components-button-color-text-white-pressed-text": "#ffffff",
        "components-button-color-text-white-pressed-stroke": "#ffffff",
        "components-button-color-text-white-pressed-icon": "#ffffff",
        "components-button-color-text-white-disabled-bg": "rgba(115, 119, 128, 0.00)",
        "components-button-color-text-white-disabled-text": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-stroke": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-icon": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-focus-bg": "#d5e3ff",
        "components-button-color-text-white-focus-text": "#ffffff",
        "components-button-color-text-white-focus-stroke": "#ffffff",
        "components-button-color-text-white-focus-icon": "#1c4cae",
        "components-button-color-text-white-focus-outline": "#1c4cae",
        "components-button-group-color-border": "#e8e8ea",
        "components-button-group-color-button-bg-default": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-button-fg-default": "#004697",
        "components-button-group-color-button-bg-hover": "#2e72c2",
        "components-button-group-color-button-fg-hover": "#ffffff",
        "components-button-group-color-button-bg-active": "#004697",
        "components-button-group-color-button-fg-active": "#ffffff",
        "components-button-group-color-overlay-left-gradient-fill-1": "#ebf1f9",
        "components-button-group-color-overlay-left-gradient-fill-2": "rgba(235, 241, 249, 0.50)",
        "components-button-group-color-stroke-default-gradient-fill-1": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-2": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-3": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-4": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-1": "#2e72c2",
        "components-button-group-color-stroke-hover-gradient-fill-2": "#2e72c2",
        "components-button-group-color-stroke-hover-gradient-fill-3": "#2e72c2",
        "components-button-group-color-stroke-hover-gradient-fill-4": "#2e72c2",
        "components-button-group-color-stroke-active-gradient-fill-1": "#004697",
        "components-button-group-color-stroke-active-gradient-fill-2": "#004697",
        "components-button-group-color-stroke-active-gradient-fill-3": "#004697",
        "components-button-group-color-stroke-active-gradient-fill-4": "#004697",
        "components-category-card-color-bg": "#ffffff",
        "components-category-card-color-content-bg": "#ffffff",
        "components-category-card-color-category-bg": "#ebf1f9",
        "components-category-card-color-category-bg-2": "#ebf1f9",
        "components-category-card-color-category-bg-3": "#ebf1f9",
        "components-category-card-color-category-bg-4": "#ebf1f9",
        "components-category-card-color-category-bg-5": "#ebf1f9",
        "components-category-card-color-category-bg-6": "#ebf1f9",
        "components-category-card-color-title": "#191b1c",
        "components-category-card-color-copy": "#27282b",
        "components-category-card-color-stroke": "#e8e8ea",
        "components-category-card-color-stroke-hover": "#0053b4",
        "components-category-card-listing-color-bg": "#ffffff",
        "components-category-card-listing-color-title": "#191b1c",
        "components-category-card-listing-color-copy": "#27282b",
        "components-card-search-result-color-bg": "#ffffff",
        "components-card-search-result-color-label": "#27282b",
        "components-card-search-result-color-title": "#191b1c",
        "components-card-search-result-color-body": "#27282b",
        "components-card-search-result-color-divider-line": "#e8e8ea",
        "components-contact-us-promo-color-light-bg": "#ebf1f9",
        "components-contact-us-promo-color-light-title": "#191b1c",
        "components-contact-us-promo-color-light-body": "#27282b",
        "components-contact-us-promo-color-default-bg": "#ffffff",
        "components-contact-us-promo-color-default-title": "#191b1c",
        "components-contact-us-promo-color-default-body": "#27282b",
        "components-contact-us-promo-color-brand-bg": "#004697",
        "components-contact-us-promo-color-brand-title": "#ffffff",
        "components-contact-us-promo-color-brand-body": "#fcfcfd",
        "components-content-list-color-bg": "#ffffff",
        "components-content-list-color-title": "#191b1c",
        "components-content-list-color-body": "#27282b",
        "components-content-list-color-icon": "#dd484f",
        "components-cookies-colors-bg": "#ffffff",
        "components-cookies-colors-title": "#191b1c",
        "components-cookies-colors-body": "#27282b",
        "components-cookies-colors-icon": "#004697",
        "components-dropdown-bg": "#ffffff",
        "components-dropdown-border-default": "#72757e",
        "components-dropdown-border-hover": "#0053b4",
        "components-dropdown-border-focused": "#191b1c",
        "components-dropdown-border-destructive": "#b32b30",
        "components-dropdown-label-text-default": "#191b1c",
        "components-dropdown-item-text-default": "#191b1c",
        "components-dropdown-item-bg-default": "#ffffff",
        "components-dropdown-item-bg-hover": "#ebf1f9",
        "components-dropdown-input-field-default": "#72757e",
        "components-dropdown-input-field-focused": "#191b1c",
        "components-dropdown-input-label-focused": "#191b1c",
        "components-dropdown-input-field-destructive": "#b32b30",
        "components-external-link-card-bg": "#ffffff",
        "components-external-link-card-card-bg": "#ffffff",
        "components-external-link-card-card-title": "#191b1c",
        "components-external-link-card-card-subtitle": "#27282b",
        "components-external-link-card-card-eyebrow": "#4e5056",
        "components-fab-color-border-default": "#ffffff",
        "components-footer-brand-color-bg": "#191b1c",
        "components-footer-brand-color-logo": "#ffffff",
        "components-footer-brand-color-title-text": "#ffffff",
        "components-footer-brand-color-description-text": "#fcfcfd",
        "components-footer-brand-color-footer-menu-expanded": "#191b1c",
        "components-footer-brand-color-link-default": "#ffffff",
        "components-footer-brand-color-link-hover": "#ebf1f9",
        "components-footer-brand-color-link-pressed": "#6698d2",
        "components-footer-brand-color-category-link-default": "#ffffff",
        "components-footer-brand-color-category-link-hover": "#fcfcfd",
        "components-footer-brand-color-category-link-pressed": "#e8e8ea",
        "components-footer-brand-color-subcategory-link-default": "#fcfcfd",
        "components-footer-brand-color-subcategory-link-hover": "#ebf1f9",
        "components-footer-brand-color-subcategory-link-pressed": "#2e72c2",
        "components-footer-brand-color-icon-icon-default": "#ffffff",
        "components-footer-brand-color-icon-icon-pressed": "#e8e8ea",
        "components-footer-brand-color-social-icon-default": "#ffffff",
        "components-footer-brand-color-social-icon-hover": "#6698d2",
        "components-footer-brand-color-social-icon-pressed": "#004697",
        "components-footer-brand-spacing-category-link-hover": "#000000",
        "components-footer-global-color-bg": "#ffffff",
        "components-footer-global-color-title-text": "#000000",
        "components-footer-global-color-disclaimer-text": "#000000",
        "components-footer-global-color-link-default": "#000000",
        "components-footer-global-color-link-hover": "#000000",
        "components-footer-global-color-link-pressed": "#000000",
        "components-generic-card-color-bg": "#fcfcfd",
        "components-generic-card-color-content-bg": "#fcfcfd",
        "components-generic-card-color-title": "#191b1c",
        "components-generic-card-color-subtitle": "#27282b",
        "components-generic-card-color-copy": "#27282b",
        "components-global-search-color-omnibox-list-bg": "#ffffff",
        "components-global-search-color-omnibox-divider": "#a2a5ab",
        "components-global-search-color-omnibox-item-bg-default": "#ffffff",
        "components-global-search-color-omnibox-item-bg-hover": "#fcfcfd",
        "components-global-search-color-omnibox-item-text-default": "#0053b4",
        "components-global-search-color-omnibox-item-text-hover": "#004697",
        "components-global-search-color-omnibox-label-text-default": "#191b1c",
        "components-global-search-color-omnibox-category-text-default": "#191b1c",
        "components-generic-card-listing-color-bg": "#ffffff",
        "components-generic-card-listing-color-title": "#191b1c",
        "components-generic-card-listing-color-copy": "#27282b",
        "components-header-color-bg": "#ffffff",
        "components-header-color-bottom-stroke": "#e8e8ea",
        "components-header-color-search-search-border-default": "#191b1c",
        "components-header-color-search-search-text": "#4e5056",
        "components-header-color-search-search-icon": "#0053b4",
        "components-header-color-search-search-bar": "#191b1c",
        "components-header-color-mobile-mobile-expanded-bg": "#ffffff",
        "components-header-color-mobile-pressed-bg": "#ffffff",
        "components-header-color-language-selector-language": "#4e5056",
        "components-header-color-language-selector-country": "#191b1c",
        "components-header-color-language-selector-country-hover-bg": "#fcfcfd",
        "components-header-color-language-selector-megamenu-language": "#4e5056",
        "components-header-color-language-selector-megamenu-country": "#191b1c",
        "components-header-color-language-selector-megamenu-country-hover": "#fcfcfd",
        "components-header-color-language-selector-bg": "#ffffff",
        "components-header-color-language-selector-stroke": "#72757e",
        "components-header-color-mega-menu-bg": "#ebf1f9",
        "components-header-color-mega-menu-featured-bg": "#004697",
        "components-header-color-mega-menu-featured-title": "#ffffff",
        "components-header-color-mega-menu-category-title": "#27282b",
        "components-header-color-mega-menu-bg-accent": "#ffffff",
        "components-header-color-icon-icon-default": "#191b1c",
        "components-header-color-icon-icon-hover": "#004697",
        "components-header-color-icon-icon-pressed": "#00326c",
        "components-header-color-links-nav-main-link-default": "#191b1c",
        "components-header-color-links-nav-main-link-hover": "#0053b4",
        "components-header-color-links-nav-main-link-pressed": "#004697",
        "components-header-color-links-nav-main-link-active": "#00326c",
        "components-header-color-links-nav-main-active-bar": "#0053b4",
        "components-header-color-links-nav-main-bg-color": "#ffffff",
        "components-header-color-links-nav-category-link-category-default": "#27282b",
        "components-header-color-links-nav-category-link-category-hover": "#004697",
        "components-header-color-links-nav-category-link-category-pressed": "#004697",
        "components-header-color-links-nav-category-link-category-active": "#00326c",
        "components-header-color-links-nav-menu-link-link-default": "#191b1c",
        "components-header-color-links-nav-menu-link-link-hover": "#004697",
        "components-header-color-links-nav-menu-link-link-pressed": "#004697",
        "components-header-color-links-nav-menu-link-link-active": "#00326c",
        "components-header-color-links-nav-menu-link-bg": "#ebf1f9",
        "components-header-color-links-nav-subcategory-link-subcategory-default": "#27282b",
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": "#004697",
        "components-header-color-links-nav-subcategory-link-subcategory-active": "#00326c",
        "components-hero-homepage-color-bg": "#fcfcfd",
        "components-hero-homepage-color-container-bg": "#8cc6e7",
        "components-hero-homepage-color-eyebrow": "#72757e",
        "components-hero-homepage-color-header": "#00326c",
        "components-hero-homepage-color-sub-header": "#27282b",
        "components-hero-homepage-color-body": "#27282b",
        "components-hero-homepage-color-play-pause": "#000000",
        "components-hero-internal-color-default-title": "#ffffff",
        "components-hero-internal-color-default-body": "#fcfcfd",
        "components-hero-internal-color-default-bg": "#004697",
        "components-hero-internal-color-accent-bg": "#00326c",
        "components-hero-internal-color-with-image-bg": "#004697",
        "components-hero-internal-color-default-bg-2": "#004697",
        "components-hero-internal-color-with-image-title": "#ffffff",
        "components-hero-internal-color-with-image-body": "#fcfcfd",
        "components-heading-page-title-color-default-bg": "#ffffff",
        "components-heading-page-title-color-default-title": "#191b1c",
        "components-heading-page-title-color-default-body": "#27282b",
        "components-heading-page-title-color-light-bg": "#ebf1f9",
        "components-heading-page-title-color-light-title": "#191b1c",
        "components-heading-page-title-color-light-body": "#27282b",
        "components-heading-page-title-color-color-bg": "#191b1c",
        "components-heading-page-title-color-color-title": "#ffffff",
        "components-heading-page-title-color-color-body": "#fcfcfd",
        "components-hyperlink-color-dark-default": "#0053b4",
        "components-hyperlink-color-dark-hover": "#004697",
        "components-hyperlink-color-dark-visited": "#865ddf",
        "components-hyperlink-color-light-default": "#ebf1f9",
        "components-hyperlink-color-light-hover": "#2e72c2",
        "components-hyperlink-color-light-visited": "#b89fec",
        "components-icon-default": "#72757e",
        "components-icon-focused": "#72757e",
        "components-icon-card-color-bg": "#ffffff",
        "components-icon-card-color-icon-bg": "#191b1c",
        "components-icon-card-color-icon": "#ffffff",
        "components-icon-card-color-title": "#191b1c",
        "components-icon-card-color-subtitle": "#4e5056",
        "components-icon-card-color-description": "#27282b",
        "components-icon-card-listing-color-bg": "#ffffff",
        "components-icon-card-listing-color-inner-bg": "#ffffff",
        "components-icon-card-listing-color-brand-bg": "#004697",
        "components-icon-card-listing-color-title": "#191b1c",
        "components-icon-card-listing-color-copy": "#27282b",
        "components-icon-card-listing-color-inner-legal-text": "#fcfcfd",
        "components-jumplink-color-bg-default": "#ffffff",
        "components-jumplink-color-link-text-default": "#4e5056",
        "components-jumplink-color-link-text-hover": "#00326c",
        "components-jumplink-color-link-border-hover": "#00326c",
        "components-jumplink-color-link-text-active": "#0053b4",
        "components-jumplink-color-link-border-active": "#0053b4",
        "components-jumplink-color-title-text": "#191b1c",
        "components-jumplink-color-description-text": "#27282b",
        "components-layout-bg-default": "#ffffff",
        "components-lightbox-color-overlay-bg": "rgba(255, 255, 255, 0.95)",
        "components-lightbox-color-title": "#191b1c",
        "components-lightbox-color-body": "#27282b",
        "components-label-color-on-color-label-label-default": "#ffffff",
        "components-label-color-on-color-label-label-hover": "#4e5056",
        "components-label-color-on-color-label-label-pressed": "#27282b",
        "components-label-color-on-color-background-bg-default": "#72757e",
        "components-label-color-on-color-background-bg-hover": "#ffffff",
        "components-label-color-on-color-background-bg-pressed": "#e8e8ea",
        "components-label-color-on-color-stroke-hover": "#ffffff",
        "components-label-color-on-color-stroke-pressed": "#e8e8ea",
        "components-label-color-on-color-stroke-default-gradient-fill-1": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-2": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-3": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-4": "#ffffff",
        "components-label-color-on-white-label-label-default": "#4e5056",
        "components-label-color-on-white-label-label-hover": "#ffffff",
        "components-label-color-on-white-label-label-pressed": "#ffffff",
        "components-label-color-on-white-background-bg-default": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-white-background-bg-hover": "#72757e",
        "components-label-color-on-white-background-bg-pressed": "#4e5056",
        "components-label-color-on-white-stroke-hover": "#72757e",
        "components-label-color-on-white-stroke-pressed": "#4e5056",
        "components-label-color-on-white-stroke-default-gradient-fill-1": "#72757e",
        "components-label-color-on-white-stroke-default-gradient-fill-2": "#72757e",
        "components-label-color-on-white-stroke-default-gradient-fill-3": "#72757e",
        "components-label-color-on-white-stroke-default-gradient-fill-4": "#72757e",
        "components-legal-disclaimer-color-default-bg": "#ffffff",
        "components-legal-disclaimer-color-default-title": "#191b1c",
        "components-legal-disclaimer-color-default-body": "#27282b",
        "components-legal-disclaimer-color-light-bg": "#ebf1f9",
        "components-legal-disclaimer-color-light-title": "#191b1c",
        "components-legal-disclaimer-color-light-body": "#27282b",
        "components-legal-disclaimer-color-brand-bg": "#191b1c",
        "components-legal-disclaimer-color-brand-title": "#ffffff",
        "components-legal-disclaimer-color-brand-body": "#fcfcfd",
        "components-media-video-thumbnail-overlay-bg": "rgba(25, 27, 28, 0.80)",
        "components-media-caption-color-bg": "#fcfcfd",
        "components-media-caption-color-text": "#27282b",
        "components-media-gallery-color-bg": "#ffffff",
        "components-media-gallery-color-title": "#191b1c",
        "components-media-gallery-color-body": "#27282b",
        "components-pagination-on-white-accent-default": "#27282b",
        "components-pagination-on-white-accent-scroll": "#bd2128",
        "components-pagination-on-white-disabled": "#a2a5ab",
        "components-pagination-on-white-bg": "#fdf4f5",
        "components-pagination-on-white-stroke": "#e8e8ea",
        "components-pagination-on-white-bg-active": "#0053b4",
        "components-pagination-on-white-bg-hover": "#e8e8ea",
        "components-pagination-on-white-text-default": "#72757e",
        "components-pagination-on-white-text-hover": "#27282b",
        "components-pagination-on-white-text-active": "#ffffff",
        "components-pagination-on-color-accent-default": "#ffffff",
        "components-pagination-on-color-accent-scroll": "#27282b",
        "components-pagination-on-color-disabled": "#001936",
        "components-pagination-on-color-bg": "#e8e8ea",
        "components-pagination-on-color-stroke": "rgba(255, 255, 255, 0.38)",
        "components-pagination-on-color-bg-active": "#ffffff",
        "components-pagination-on-color-bg-hover": "#27282b",
        "components-pagination-on-color-text-default": "#ffffff",
        "components-pagination-on-color-text-hover": "#ffffff",
        "components-pagination-on-color-text-active": "#191b1c",
        "components-pdf-link-card-color-bg": "#ffffff",
        "components-pdf-link-card-color-card-bg": "#ffffff",
        "components-pdf-link-card-color-card-title": "#191b1c",
        "components-pdf-link-card-color-card-subtitle": "#27282b",
        "components-pdf-link-card-color-card-body": "#27282b",
        "components-product-card-color-bg": "#fcfcfd",
        "components-product-card-color-content-bg": "#fcfcfd",
        "components-product-card-color-border": "#e8e8ea",
        "components-product-card-color-title": "#191b1c",
        "components-product-card-color-description": "#27282b",
        "components-product-card-color-star-rating": "#d7252d",
        "components-product-card-color-badge-bg": "#d7252d",
        "components-product-card-color-badge-text": "#ffffff",
        "components-product-card-listing-color-bg": "#ffffff",
        "components-product-card-listing-color-title": "#191b1c",
        "components-product-card-listing-color-copy": "#27282b",
        "components-product-card-listing-color-legal-text": "#27282b",
        "components-product-information-color-bg": "#ffffff",
        "components-product-information-color-content-bg": "#ffffff",
        "components-product-information-color-headline": "#191b1c",
        "components-product-information-color-body": "#27282b",
        "components-product-information-color-detail-text": "#27282b",
        "components-product-information-color-divider": "#a2a5ab",
        "components-product-information-color-icon": "#004697",
        "components-product-information-color-play-icon": "#ffffff",
        "components-product-information-color-selected-stroke": "#0053b4",
        "components-product-results-list-with-search-color-bg": "#ffffff",
        "components-promo-color-default-bg": "#ffffff",
        "components-promo-color-default-image-bg": "#ffffff",
        "components-promo-color-default-content-bg": "#ffffff",
        "components-promo-color-default-title": "#191b1c",
        "components-promo-color-default-body": "#27282b",
        "components-promo-color-default-category": "#191b1c",
        "components-promo-color-light-bg": "#ebf1f9",
        "components-promo-color-light-image-bg": "#ebf1f9",
        "components-promo-color-light-content-bg": "#ebf1f9",
        "components-promo-color-light-title": "#191b1c",
        "components-promo-color-light-body": "#27282b",
        "components-promo-color-light-category": "#191b1c",
        "components-promo-color-brand-bg": "#0053b4",
        "components-promo-color-brand-image-bg": "#0053b4",
        "components-promo-color-brand-content-bg": "#0053b4",
        "components-promo-color-brand-title": "#ffffff",
        "components-promo-color-brand-body": "#fcfcfd",
        "components-promo-color-brand-category": "#fcfcfd",
        "components-pull-quote-color-bg": "#fcfcfd",
        "components-pull-quote-color-stroke": "#0053b4",
        "components-pull-quote-color-quote-bg": "#ffffff",
        "components-pull-quote-color-quote-title": "#191b1c",
        "components-pull-quote-color-quote-by-text": "#27282b",
        "components-pull-quote-color-quote-detail": "#72757e",
        "components-pull-quote-color-quote-highlight": "#d7252d",
        "components-promo-alternate-color-default-bg": "#ffffff",
        "components-promo-alternate-color-default-image-bg": "#ffffff",
        "components-promo-alternate-color-default-content-bg": "#ffffff",
        "components-promo-alternate-color-default-title": "#191b1c",
        "components-promo-alternate-color-default-body": "#27282b",
        "components-promo-alternate-color-default-category": "#191b1c",
        "components-promo-alternate-color-light-bg": "#ebf1f9",
        "components-promo-alternate-color-light-image-bg": "#ebf1f9",
        "components-promo-alternate-color-light-content-bg": "#ebf1f9",
        "components-promo-alternate-color-light-title": "#191b1c",
        "components-promo-alternate-color-light-body": "#27282b",
        "components-promo-alternate-color-light-category": "#191b1c",
        "components-promo-alternate-color-brand-bg": "#0053b4",
        "components-promo-alternate-color-brand-image-bg": "#0053b4",
        "components-promo-alternate-color-brand-content-bg": "#0053b4",
        "components-promo-alternate-color-brand-title": "#ffffff",
        "components-promo-alternate-color-brand-body": "#fcfcfd",
        "components-promo-alternate-color-brand-category": "#fcfcfd",
        "components-share-list-color-title": "#27282b",
        "components-share-list-color-bg": "#fcfcfd",
        "components-share-list-color-link-default": "#27282b",
        "components-share-list-color-link-hover": "#0053b4",
        "components-share-list-color-bg-link-hover": "#e8e8ea",
        "components-share-list-color-close-icon": "#27282b",
        "components-share-list-color-facebook-icon": "#3b5998",
        "components-share-list-color-pinterest-icon": "#e60023",
        "components-share-list-color-twitter-icon": "#000000",
        "components-share-list-color-basic-icon-default": "#27282b",
        "components-share-list-color-icon-hover": "#004697",
        "components-share-list-color-border": "#e8e8ea",
        "components-site-search-bg": "#ffffff",
        "components-site-search-results_text": "#27282b",
        "components-site-search-filter-filter-selected-bg": "#ebf1f9",
        "components-site-search-facet-group-label-bottom-line": "#e8e8ea",
        "components-site-search-facet-group-label-text": "#27282b",
        "components-site-search-facet-group-label-bg": "#ffffff",
        "components-site-search-facet-bg": "#ffffff",
        "components-site-search-facet-stroke": "#e8e8ea",
        "components-site-search-refine-bg": "#ffffff",
        "components-site-search-refine-right-divider": "#ebf1f9",
        "components-site-search-refine-title": "#191b1c",
        "components-site-search-hero-search-bg": "#004697",
        "components-site-search-hero-search-accent-bg": "#00326c",
        "components-site-search-hero-search-title": "#ffffff",
        "components-stats-stat-text": "#004697",
        "components-stats-stat-text-on-light": "#004697",
        "components-stats-supporting-text": "#27282b",
        "components-stats-bg-default": "#ffffff",
        "components-stats-bg-inner": "#ffffff",
        "components-stats-bg-section-inner": "#004697",
        "components-stats-title": "#191b1c",
        "components-stats-copy": "#27282b",
        "components-supporting-text-default": "#4e5056",
        "components-supporting-text-destructive": "#b32b30",
        "components-tab-bg": "#ffffff",
        "components-tab-border-default": "#72757e",
        "components-tab-border-hover": "#0053b4",
        "components-tab-border-focused": "#191b1c",
        "components-tab-border-destructive": "#b32b30",
        "components-tab-label-text-default": "#191b1c",
        "components-tab-item-text-default": "#191b1c",
        "components-tab-item-bg-default": "#ffffff",
        "components-tab-item-bg-hover": "#ebf1f9",
        "components-tab-input-field-default": "#72757e",
        "components-tab-input-field-focused": "#191b1c",
        "components-tab-input-label-focused": "#191b1c",
        "components-tab-input-field-destructive": "#b32b30",
        "components-tab-bg-2": "#ffffff",
        "components-tag-color-bg": "#ffffff",
        "components-tag-color-text": "#0053b4",
        "components-tag-color-stroke": "#0053b4",
        "components-tag-color-bg-hover": "#004697",
        "components-tag-color-text-hover": "#ffffff",
        "components-tag-color-stroke-hover": "#004697",
        "components-text-field-bg": "#fcfcfd",
        "components-text-field-bg-focused": "#ebf1f9",
        "components-text-field-border-default": "#191b1c",
        "components-text-field-border-hover": "#0053b4",
        "components-text-field-border-focused": "#191b1c",
        "components-text-field-border-destructive": "#b32b30",
        "components-text-field-input-field-default": "#4e5056",
        "components-text-field-input-field-focused": "#191b1c",
        "components-text-field-input-label-focused": "#191b1c",
        "components-text-field-input-field-destructive": "#b32b30",
        "components-text-field-icon-default": "#72757e",
        "components-text-field-icon-destructive": "#b32b30",
        "components-text-field-supporting-text-default": "#4e5056",
        "components-text-field-supporting-text-destructive": "#b32b30",
        "components-tip-color-bg": "#ebf1f9",
        "components-tip-color-title": "#191b1c",
        "components-tip-color-body": "#27282b",
        "components-tip-color-stroke": "#6698d2",
        "components-video-color-bg": "#ffffff",
        "themes-general-surface-color-light-bg": "#ffffff",
        "themes-general-surface-color-light-title": "#191b1c",
        "themes-general-surface-color-light-body": "#27282b",
        "themes-general-surface-color-light-decor-line-break": "#6698d2",
        "themes-general-surface-color-light-rule-line": "#6698d2",
        "themes-general-surface-color-dark-bg": "#0053b4",
        "themes-general-surface-color-dark-title": "#ffffff",
        "themes-general-surface-color-dark-body": "#fcfcfd",
        "themes-general-surface-color-dark-decor-line-break": "#6698d2",
        "themes-general-card-color-dark-title": "#191b1c",
        "themes-general-card-color-dark-subtitle": "#27282b",
        "themes-general-card-color-dark-body": "#27282b",
        "themes-general-card-color-light-title": "#ffffff",
        "themes-general-card-color-light-subtitle": "#fcfcfd",
        "themes-general-card-color-light-body": "#fcfcfd",
        "themes-shadow-color": "#ffffff",
        "themes-shadow-share-list-color-fill": "rgba(0, 0, 0, 0.40)",
        "themes-shadow-header-menu-color-fill": "rgba(0, 0, 0, 0.20)",
        "themes-gradient-loader-gradient-fill-1": "#0053b4",
        "themes-gradient-loader-gradient-fill-2": "rgba(0, 83, 180, 0.00)",
        "themes-color-background-white": "#ffffff",
        "themes-color-background-light": "#ebf1f9",
        "themes-color-background-dark": "#27282b",
        "themes-color-background-black": "#191b1c",
        "themes-color-background-brand1": "#004697",
        "themes-color-background-brand2": "#d7252d",
        "themes-color-background-accent1": "#286636",
        "themes-color-background-accent2": "#9976e4",
        "themes-color-background-body": "#ffffff",
        "themes-color-stroke-white": "#ffffff",
        "themes-color-stroke-light": "#e8e8ea",
        "themes-color-stroke-dark": "#27282b",
        "themes-color-stroke-black": "#191b1c",
        "themes-color-stroke-brand1": "#0053b4",
        "themes-color-stroke-brand2": "#d7252d",
        "themes-color-stroke-accent1": "#286636",
        "themes-color-stroke-accent2": "#9976e4",
        "themes-color-text-white": "#ffffff",
        "themes-color-text-light": "#fcfcfd",
        "themes-color-text-dark": "#27282b",
        "themes-color-text-black": "#191b1c",
        "themes-color-text-brand1": "#0053b4",
        "themes-color-text-brand2": "#d7252d",
        "themes-color-text-accent1": "#183d20",
        "themes-color-text-accent2": "#2f1568",
        "colors-grayscale-100": "#fcfcfd",
        "colors-grayscale-200": "#e8e8ea",
        "colors-grayscale-300": "#a2a5ab",
        "colors-grayscale-400": "#72757e",
        "colors-grayscale-500": "#4e5056",
        "colors-grayscale-600": "#27282b",
        "colors-grayscale-700": "#191b1c",
        "colors-brand-1-100": "#ebf1f9",
        "colors-brand-1-200": "#6698d2",
        "colors-brand-1-300": "#2e72c2",
        "colors-brand-1-400": "#0053b4",
        "colors-brand-1-500": "#004697",
        "colors-brand-1-600": "#00326c",
        "colors-brand-1-700": "#001936",
        "colors-brand-2-100": "#fdf4f5",
        "colors-brand-2-200": "#e77c81",
        "colors-brand-2-300": "#dd484f",
        "colors-brand-2-400": "#d7252d",
        "colors-brand-2-500": "#bd2128",
        "colors-brand-2-600": "#971a20",
        "colors-brand-2-700": "#6c1317",
        "colors-accent-1-100": "#eaf7ed",
        "colors-accent-1-200": "#95d6a4",
        "colors-accent-1-300": "#47b560",
        "colors-accent-1-400": "#286636",
        "colors-accent-1-500": "#22562d",
        "colors-accent-1-600": "#183d20",
        "colors-accent-1-700": "#0e2513",
        "colors-accent-2-100": "#f7f4fd",
        "colors-accent-2-200": "#d6c8f4",
        "colors-accent-2-300": "#b89fec",
        "colors-accent-2-400": "#9976e4",
        "colors-accent-2-500": "#7748db",
        "colors-accent-2-600": "#4920a2",
        "colors-accent-2-700": "#2f1568",
        "colors-accent-3-100": "#eefaec",
        "colors-accent-3-200": "#a9e8a0",
        "colors-accent-3-300": "#68d759",
        "colors-accent-3-400": "#3aae2a",
        "colors-accent-3-500": "#319223",
        "colors-accent-3-600": "#236819",
        "colors-accent-3-700": "#153f0f",
        "colors-accent-4-400_storage": "#00aed6",
        "colors-accessible-accent-1-100": "#f5f2fc",
        "colors-accessible-accent-1-200": "#cfbff2",
        "colors-accessible-accent-1-300": "#ab8fe9",
        "colors-accessible-accent-1-400": "#865ddf",
        "colors-accessible-accent-1-500": "#6d3ad8",
        "colors-accessible-accent-1-600": "#4c21a9",
        "colors-accessible-accent-1-700": "#341672",
        "colors-accessible-accent-2-100": "#e7f8e4",
        "colors-accessible-accent-2-200": "#7ddc6f",
        "colors-accessible-accent-2-300": "#3cb42c",
        "colors-accessible-accent-2-400": "#2d8721",
        "colors-accessible-accent-2-500": "#246d1a",
        "colors-accessible-accent-2-600": "#1b5214",
        "colors-accessible-accent-2-700": "#11340c",
        "colors-accessible-brand-2-100": "#fdf4f5",
        "colors-accessible-brand-2-200": "#e77c81",
        "colors-accessible-brand-2-300": "#dd484f",
        "colors-accessible-brand-2-400": "#d7252d",
        "colors-accessible-brand-2-500": "#bd2128",
        "colors-accessible-brand-2-600": "#971a20",
        "colors-accessible-brand-2-700": "#6c1317"
      },
      "spacing": {
        "components-accordion-spacing-large-padding-x": "5rem",
        "components-accordion-spacing-large-icon-margin-right": "2rem",
        "components-accordion-spacing-large-copy-margin-right": "2.5rem",
        "components-accordion-spacing-large-maxwidth": "59.625rem",
        "components-accordion-spacing-large-section-padding-x": "0rem",
        "components-accordion-spacing-large-section-padding-y": "2.5rem",
        "components-accordion-spacing-large-section-copy-margin-bottom": "2.5rem",
        "components-accordion-spacing-large-section-space-between": "4rem",
        "components-accordion-spacing-large-item-padding-x": "0rem",
        "components-accordion-spacing-large-item-padding-y": "1.5rem",
        "components-accordion-spacing-large-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-large-content-padding-x": "0rem",
        "components-accordion-spacing-large-content-padding-y": "2.5rem",
        "components-accordion-spacing-large-content-title-margin-bottom": "2.5rem",
        "components-accordion-spacing-large-content-content-space-between": "4rem",
        "components-accordion-spacing-large-content-content-margin-left": "1rem",
        "components-accordion-spacing-small-padding-top": "1rem",
        "components-accordion-spacing-small-padding-x": "1.5rem",
        "components-accordion-spacing-small-padding-y": "2.5rem",
        "components-accordion-spacing-small-icon-margin-right": "0.75rem",
        "components-accordion-spacing-small-title-margin-bottom": "0.5rem",
        "components-accordion-spacing-small-copy-margin-right": "2rem",
        "components-accordion-spacing-small-copy-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-padding-x": "0rem",
        "components-accordion-spacing-small-section-padding-y": "0rem",
        "components-accordion-spacing-small-section-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-space-between": "2.5rem",
        "components-accordion-spacing-small-item-padding-x": "0rem",
        "components-accordion-spacing-small-item-padding-y": "1rem",
        "components-accordion-spacing-small-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-small-content-padding-x": "0rem",
        "components-accordion-spacing-small-content-padding-y": "0.75rem",
        "components-accordion-spacing-small-content-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-content-content-space-between": "1rem",
        "components-accordion-spacing-small-content-content-margin-left": "1rem",
        "components-article-card-spacing-large-padding-image-x": "0rem",
        "components-article-card-spacing-large-padding-image-y": "0rem",
        "components-article-card-spacing-large-padding-content-x": "1.5rem",
        "components-article-card-spacing-large-padding-content-y": "1.5rem",
        "components-article-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-article-card-spacing-large-button-space-between": "1rem",
        "components-article-card-spacing-large-title-margin-bottom": "1rem",
        "components-article-card-spacing-large-list-content-gap": "1rem",
        "components-article-card-spacing-large-list-image-padding-x": "0rem",
        "components-article-card-spacing-large-list-image-padding-y": "0rem",
        "components-article-card-spacing-small-padding-image-x": "0rem",
        "components-article-card-spacing-small-padding-image-y": "0rem",
        "components-article-card-spacing-small-padding-content-x": "1.5rem",
        "components-article-card-spacing-small-padding-content-y": "1.5rem",
        "components-article-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-article-card-listing-spacing-small-padding-x": "1.5rem",
        "components-article-card-listing-spacing-small-padding-y": "2.5rem",
        "components-article-hero-spacing-large-padding-x": "0rem",
        "components-article-hero-spacing-large-padding-y": "0rem",
        "components-article-hero-spacing-large-title-margin-bottom": "2rem",
        "components-article-hero-spacing-large-label-share-margin-bottom": "2rem",
        "components-article-hero-spacing-large-author-name-margin-bottom": "0.75rem",
        "components-article-hero-spacing-large-author-thumbnail-margin-left": "1rem",
        "components-article-hero-spacing-large-details-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-space-between": "1rem",
        "components-article-hero-spacing-large-body-margin-bottom": "2rem",
        "components-article-hero-spacing-large-image-space-between": "1rem",
        "components-article-hero-spacing-large-image-margin-bottom": "1rem",
        "components-article-hero-spacing-large-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-stats-margin-bottom": "2.5rem",
        "components-article-hero-spacing-small-content-margin-top": "2.5rem",
        "components-article-hero-spacing-small-title-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-label-share-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-authorname-margin-bottom": "0.75rem",
        "components-article-hero-spacing-small-authorthumbnail-margin-left": "1rem",
        "components-article-hero-spacing-small-details-margin-bottom": "1rem",
        "components-article-hero-spacing-small-space-between": "0.75rem",
        "components-article-hero-spacing-small-body-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-image-space-between": "0.75rem",
        "components-article-hero-spacing-small-image-margin-bottom": "1rem",
        "components-article-hero-spacing-small-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-stats-margin-bottom": "2rem",
        "components-banner-spacing-large-content-padding-x": "1.5rem",
        "components-banner-spacing-large-content-space-between": "1rem",
        "components-banner-spacing-large-content-width": "69.375rem",
        "components-banner-spacing-icon-dimensions": "1.5rem",
        "components-banner-spacing-icon-margin-left": "0.5rem",
        "components-banner-spacing-small-space-between": "1rem",
        "components-banner-spacing-small-content-space-between": "1rem",
        "components-banner-spacing-small-title-margin-bottom": "0.75rem",
        "components-banner-spacing-small-content-margin-bottom": "1rem",
        "components-breadcrumb-spacing-large-space-between": "0.25rem",
        "components-breadcrumb-spacing-small-space-between": "0.25rem",
        "components-button-spacing-large-icon-large-x": "2.5rem",
        "components-button-spacing-large-icon-large-y": "2.5rem",
        "components-button-spacing-large-default-padding-y": "0.75rem",
        "components-button-spacing-large-default-padding-x": "1.5rem",
        "components-button-spacing-large-default-space-between": "0.25rem",
        "components-button-spacing-large-icon-left-padding-left": "1rem",
        "components-button-spacing-large-icon-right-padding-right": "1rem",
        "components-button-spacing-large-icon-only-padding-x": "0.75rem",
        "components-button-spacing-large-icon-only-padding-y": "0.75rem",
        "components-button-spacing-large-tertiary-content-height": "1.5rem",
        "components-button-spacing-compressed-icon-large-x": "2.5rem",
        "components-button-spacing-compressed-icon-large-y": "2.5rem",
        "components-button-spacing-compressed-default-padding-y": "0.5rem",
        "components-button-spacing-compressed-default-padding-x": "1rem",
        "components-button-spacing-compressed-default-space-between": "0.125rem",
        "components-button-spacing-compressed-icon-left-padding-left": "0.5rem",
        "components-button-spacing-compressed-icon-right-padding-right": "0.5rem",
        "components-button-spacing-compressed-icon-only-padding-x": "0.5rem",
        "components-button-group-spacing-padding-y": "0.125rem",
        "components-button-group-spacing-padding-x": "0.25rem",
        "components-button-group-spacing-space-between": "0.5rem",
        "components-category-card-spacing-large-padding-top": "0rem",
        "components-category-card-spacing-large-padding-x": "0rem",
        "components-category-card-spacing-large-padding-y": "0rem",
        "components-category-card-spacing-large-padding-image-x": "0rem",
        "components-category-card-spacing-large-padding-image-y": "0rem",
        "components-category-card-spacing-large-padding-content-x": "2rem",
        "components-category-card-spacing-large-padding-content-y": "2rem",
        "components-category-card-listing-spacing-large-padding-y": "5rem",
        "components-category-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-category-card-listing-spacing-large-card-margin-bottom": "0rem",
        "components-category-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-card-search-result-small-padding-top": "0rem",
        "components-card-search-result-small-padding-x": "0rem",
        "components-card-search-result-small-padding-y": "0rem",
        "components-card-search-result-small-padding-image-x": "0rem",
        "components-card-search-result-small-padding-image-y": "0rem",
        "components-card-search-result-small-padding-content-x": "1.5rem",
        "components-card-search-result-small-padding-content-y": "1.5rem",
        "components-card-search-result-spacing-large-padding-x": "0rem",
        "components-card-search-result-spacing-large-padding-y": "1.5rem",
        "components-card-search-result-spacing-large-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-image-margin-right": "1.5rem",
        "components-card-search-result-spacing-small-padding-x": "1.5rem",
        "components-card-search-result-spacing-small-padding-y": "1.5rem",
        "components-card-search-result-spacing-small-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-image-margin-right": "0.75rem",
        "components-contact-us-promo-spacing-large-padding-x": "5rem",
        "components-contact-us-promo-spacing-large-padding-y": "5rem",
        "components-contact-us-promo-spacing-large-content-margin-right": "5rem",
        "components-contact-us-promo-spacing-large-margin-y": "2.5rem",
        "components-contact-us-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-contact-us-promo-spacing-small-margin-y": "2.5rem",
        "components-content-list-list-item-container-padding-x": "0.75rem",
        "components-content-list-list-item-container-padding-y": "1rem",
        "components-content-list-list-item-content-space-between": "0.5rem",
        "components-content-list-list-item-content-default-padding-y": "0.25rem",
        "components-content-list-list-item-content-hyperlink-padding-y": "0rem",
        "components-content-list-spacing-large-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-large-item-title-margin-bottom": "1rem",
        "components-content-list-spacing-large-item-copy-margin-bottom": "2.5rem",
        "components-content-list-spacing-large-item-icon-copy-margin-top": "0rem",
        "components-content-list-spacing-large-item-margin-bottom": "2rem",
        "components-content-list-spacing-large-item-image-margin-bottom": "0.5rem",
        "components-content-list-spacing-large-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-y": "2.5rem",
        "components-content-list-spacing-small-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-small-item-title-margin-bottom": "0.5rem",
        "components-content-list-spacing-small-item-copy-margin-bottom": "1rem",
        "components-content-list-spacing-small-item-margin-bottom": "1.5rem",
        "components-content-list-spacing-small-header-title-margin-bottom": "1rem",
        "components-content-list-spacing-small-header-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-title-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-button-space-between": "1rem",
        "components-dropdown-item-padding-y": "0.75rem",
        "components-dropdown-item-padding-x": "0.75rem",
        "components-dropdown-item-spacing-x": "0.75rem",
        "components-dropdown-input-padding-y": "0.25rem",
        "components-dropdown-input-padding-x": "1rem",
        "components-footer-brand-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-large-column-margin-right": "4rem",
        "components-footer-brand-spacing-large-column-margin-bottom": "4rem",
        "components-footer-brand-spacing-large-link-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-padding-y": "2.5rem",
        "components-footer-brand-spacing-small-padding-x": "1rem",
        "components-footer-brand-spacing-small-logo-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-column-margin-right": "0.125rem",
        "components-footer-brand-spacing-small-column-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-small-link-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-title-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-text-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-footer-link-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-link-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-link-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-link-minheight": "3rem",
        "components-footer-brand-spacing-small-footer-category-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-category-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-category-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-category-minheight": "3rem",
        "components-footer-global-spacing-large-padding-y": "2.5rem",
        "components-footer-global-spacing-large-padding-x": "5rem",
        "components-footer-global-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-global-spacing-large-column-margin-right": "2rem",
        "components-footer-global-spacing-large-link-margin-bottom": "2rem",
        "components-footer-global-spacing-small-padding-y": "2.5rem",
        "components-footer-global-spacing-small-padding-x": "1rem",
        "components-footer-global-spacing-small-logo-margin-bottom": "1rem",
        "components-footer-global-spacing-small-column-margin-right": "1rem",
        "components-footer-global-spacing-small-link-margin-bottom": "1rem",
        "components-generic-card-spacing-large-padding-x": "0rem",
        "components-generic-card-spacing-large-padding-y": "0rem",
        "components-generic-card-spacing-large-padding-image-x": "0rem",
        "components-generic-card-spacing-large-padding-image-y": "0rem",
        "components-generic-card-spacing-large-padding-content-x": "2rem",
        "components-generic-card-spacing-large-padding-content-y": "2rem",
        "components-generic-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-generic-card-spacing-large-title-margin-bottom": "1rem",
        "components-generic-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-button-space-between": "1rem",
        "components-generic-card-spacing-small-padding-x": "0rem",
        "components-generic-card-spacing-small-padding-y": "0rem",
        "components-generic-card-spacing-small-padding-image-x": "0rem",
        "components-generic-card-spacing-small-padding-image-y": "0rem",
        "components-generic-card-spacing-small-padding-content-x": "1.5rem",
        "components-generic-card-spacing-small-padding-content-y": "1.5rem",
        "components-global-search-spacing-omnibox-padding-x": "0.125rem",
        "components-global-search-spacing-omnibox-list-padding-y": "0.5rem",
        "components-global-search-spacing-omnibox-label-padding": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-item-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-height": "3rem",
        "components-generic-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-generic-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-header-shadow-x": "0rem",
        "components-header-spacing-large-padding-y": "1rem",
        "components-header-spacing-large-padding-x": "1.5rem",
        "components-header-spacing-large-links-margin-right": "1.5rem",
        "components-header-spacing-large-icons-space-between": "1.5rem",
        "components-header-spacing-large-logo-margin-right": "2rem",
        "components-header-spacing-large-space-between-search": "0.5rem",
        "components-header-spacing-large-height": "6rem",
        "components-header-spacing-large-language-selector-padding-x": "1rem",
        "components-header-spacing-large-language-selector-padding-y": "0.5rem",
        "components-header-spacing-large-language-selector-space-between": "0.75rem",
        "components-header-spacing-large-language-selector-country-space-between": "0.5rem",
        "components-header-spacing-large-mega-menu-padding-y": "2.5rem",
        "components-header-spacing-large-mega-menu-padding-x": "2.5rem",
        "components-header-spacing-large-mega-menu-column-margin-right": "5rem",
        "components-header-spacing-large-mega-menu-category-link-margin-bottom": "1rem",
        "components-header-spacing-large-mega-menu-link-margin-bottom": "0.5rem",
        "components-header-spacing-large-mega-menu-featured-padding-x": "2rem",
        "components-header-spacing-large-mega-menu-featured-padding-y": "2rem",
        "components-header-spacing-large-mega-menu-featured-image-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-title-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-link-space-between": "1.5rem",
        "components-header-spacing-large-mega-menu-title-margin-bottom": "2.5rem",
        "components-header-spacing-large-dropdown-padding-y": "1.5rem",
        "components-header-spacing-large-dropdown-padding-x": "1.5rem",
        "components-header-spacing-large-dropdown-margin-bottom": "0.5rem",
        "components-header-spacing-small-language-selector-padding-x": "1rem",
        "components-header-spacing-small-language-selector-padding-y": "0.5rem",
        "components-header-spacing-small-language-selector-space-between": "0.5rem",
        "components-header-spacing-small-padding-left": "1rem",
        "components-header-spacing-small-padding-right": "1rem",
        "components-header-spacing-small-padding-top": "0.5rem",
        "components-header-spacing-small-padding-bottom": "0.5rem",
        "components-header-spacing-small-nav-main-minheight": "3rem",
        "components-header-spacing-small-nav-main-padding-y": "0.5rem",
        "components-header-spacing-small-nav-main-padding-left": "1.5rem",
        "components-header-spacing-small-nav-main-padding-right": "1rem",
        "components-header-spacing-small-nav-category-minheight": "3rem",
        "components-header-spacing-small-nav-category-padding-left": "1.5rem",
        "components-header-spacing-small-nav-category-padding-right": "0.75rem",
        "components-header-spacing-small-nav-category-padding-y": "0.75rem",
        "components-header-spacing-small-nav-category-space-between": "0rem",
        "components-header-spacing-small-nav-menu-link-minheight": "3rem",
        "components-header-spacing-small-nav-menu-link-padding-right": "1.5rem",
        "components-header-spacing-small-nav-menu-link-padding-left": "2.5rem",
        "components-header-spacing-small-nav-menu-link-padding-y": "0.75rem",
        "components-header-spacing-small-mega-menu-padding-y": "0rem",
        "components-header-spacing-small-mega-menu-padding-x": "0rem",
        "components-header-spacing-small-mega-menu-category-link-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-link-margin-bottom": "0rem",
        "components-header-spacing-small-mega-menu-featured-padding-x": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-padding-y": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-image-margin-bottom": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-title-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-featured-link-space-between": "0.75rem",
        "components-header-spacing-small-mega-menu-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-container-padding": "1rem",
        "components-hero-homepage-spacing-large-padding-x": "5rem",
        "components-hero-homepage-spacing-large-padding-y": "2.5rem",
        "components-hero-homepage-spacing-large-minheight": "34.3125rem",
        "components-hero-homepage-spacing-large-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-margin-bottom": "4rem",
        "components-hero-homepage-spacing-large-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-copy-margin-bottom": "2.5rem",
        "components-hero-homepage-spacing-large-cta-margin-right": "1rem",
        "components-hero-homepage-spacing-large-image-height": "34.3125rem",
        "components-hero-homepage-spacing-large-image-width": "45.75rem",
        "components-hero-homepage-spacing-small-padding-x": "0.5rem",
        "components-hero-homepage-spacing-small-padding-y": "1.5rem",
        "components-hero-homepage-spacing-small-minheight": "34.3125rem",
        "components-hero-homepage-spacing-small-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-copy-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-image-height": "17.5625rem",
        "components-hero-homepage-spacing-small-image-width": "45.75rem",
        "components-hero-internal-spacing-large-padding-y": "2.5rem",
        "components-hero-internal-spacing-large-padding-right": "10rem",
        "components-hero-internal-spacing-large-title-margin-bottom": "1.5rem",
        "components-hero-internal-spacing-large-button-padding-y": "2rem",
        "components-hero-internal-spacing-large-text-maxwidth": "59.625rem",
        "components-hero-internal-spacing-large-minheight": "18.5rem",
        "components-hero-internal-spacing-small-padding-x": "1.5rem",
        "components-hero-internal-spacing-small-padding-right": "0rem",
        "components-hero-internal-spacing-small-button-padding-y": "1.5rem",
        "components-hero-internal-spacing-small-text-maxwidth": "30rem",
        "components-hero-internal-spacing-small-minheight": "13.5rem",
        "components-icon-card-spacing-large-padding-x": "0rem",
        "components-icon-card-spacing-large-padding-y": "0rem",
        "components-icon-card-spacing-large-icon-margin": "1.5rem",
        "components-icon-card-spacing-large-margin-right": "1.5rem",
        "components-icon-card-spacing-large-title-margin-bottom": "1rem",
        "components-icon-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-icon-card-spacing-large-button-space-between": "0.5rem",
        "components-icon-card-spacing-large-top-space-between": "1rem",
        "components-icon-card-spacing-small-padding-x": "1.5rem",
        "components-icon-card-spacing-small-padding-y": "1.5rem",
        "components-icon-card-spacing-small-icon-margin": "1.5rem",
        "components-icon-card-spacing-small-margin-right": "1.5rem",
        "components-icon-card-spacing-small-title-margin-bottom": "1.5rem",
        "components-icon-card-dimensions-large-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-large-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-large-big-icon-height": "4rem",
        "components-icon-card-dimensions-large-big-icon-width": "4rem",
        "components-icon-card-dimensions-large-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-large-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-large-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-large-tiny-icon-width": "2.5rem",
        "components-icon-card-dimensions-small-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-small-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-small-big-icon-height": "3.5rem",
        "components-icon-card-dimensions-small-big-icon-width": "3.5rem",
        "components-icon-card-dimensions-small-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-small-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-small-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-small-tiny-icon-width": "2.5rem",
        "components-icon-card-listing-spacing-large-padding-inner-x": "4rem",
        "components-icon-card-listing-spacing-large-padding-inner-y": "4rem",
        "components-icon-card-listing-spacing-large-card-space-between": "4rem",
        "components-icon-card-listing-spacing-large-title-margin-bottom": "1.5rem",
        "components-icon-card-listing-spacing-large-card-margin-bottom": "4rem",
        "components-icon-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-x": "1.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-y": "2.5rem",
        "components-icon-card-listing-spacing-small-title-margin-bottom": "1rem",
        "components-icon-card-listing-spacing-small-button-margin-top": "1.5rem",
        "components-jumplink-link-padding-bottom": "0.5rem",
        "components-jumplink-spacing-small-title-area-margin-bottom": "1.5rem",
        "components-jumplink-spacing-small-link-container-padding-x": "0rem",
        "components-lightbox-spacing-large-carousel-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-carousel-space-between": "1.5rem",
        "components-lightbox-spacing-large-featured-image-height": "39.25rem",
        "components-lightbox-spacing-large-featured-image-width": "39.25rem",
        "components-lightbox-spacing-large-image-height": "17.0625rem",
        "components-lightbox-spacing-large-image-width": "17.0625rem",
        "components-lightbox-spacing-small-carousel-margin-bottom": "1rem",
        "components-lightbox-spacing-small-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-small-media-details-margin-top": "1rem",
        "components-lightbox-spacing-small-image-height": "20.4375rem",
        "components-lightbox-spacing-small-image-width": "20.4375rem",
        "components-legal-disclaimer-spacing-small-ruleline-width": "20.4375rem",
        "components-media-spacing-large-padding-y": "0.5rem",
        "components-media-spacing-large-padding-x": "0.5rem",
        "components-media-gallery-large-container-grid-gap-x": "1.5rem",
        "components-media-gallery-large-container-grid-gap-y": "1.5rem",
        "components-media-gallery-small-container-grid-gap-x": "0.5rem",
        "components-media-gallery-small-container-grid-gap-y": "0.5rem",
        "components-media-gallery-spacing-small-padding-x": "1.5rem",
        "components-media-gallery-spacing-small-padding-y": "2.5rem",
        "components-media-gallery-spacing-large-padding-x": "5rem",
        "components-media-gallery-spacing-large-padding-y": "5rem",
        "components-product-card-spacing-list-content-gap": "0rem",
        "components-product-card-spacing-large-padding-image-x": "0rem",
        "components-product-card-spacing-large-padding-image-y": "0rem",
        "components-product-card-spacing-large-padding-content-x": "1.5rem",
        "components-product-card-spacing-large-padding-content-y": "1.5rem",
        "components-product-card-spacing-large-category-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-title-margin-bottom": "1rem",
        "components-product-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-button-space-between": "1rem",
        "components-product-card-spacing-small-padding-image-x": "0rem",
        "components-product-card-spacing-small-padding-image-y": "0rem",
        "components-product-card-spacing-small-padding-content-x": "1.5rem",
        "components-product-card-spacing-small-padding-content-y": "1.5rem",
        "components-product-card-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-large-padding-x": "5rem",
        "components-product-information-spacing-large-padding-y": "1.5rem",
        "components-product-information-spacing-large-title-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-label-margin-bottom": "2.5rem",
        "components-product-information-spacing-large-ratings-margin-bottom": "1rem",
        "components-product-information-spacing-large-details-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-space-between": "1rem",
        "components-product-information-spacing-large-carousel-space-between": "5rem",
        "components-product-information-spacing-large-button-margin-y": "0.5rem",
        "components-product-information-spacing-large-body-margin-top": "1.5rem",
        "components-product-information-spacing-large-image-space-between": "1rem",
        "components-product-information-spacing-large-image-margin-bottom": "1rem",
        "components-product-information-spacing-small-padding-x": "1.5rem",
        "components-product-information-spacing-small-padding-y": "0.5rem",
        "components-product-information-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-small-label-margin-bottom": "1.5rem",
        "components-product-information-spacing-small-ratings-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-details-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-space-between": "0.75rem",
        "components-product-information-spacing-small-carousel-space-between": "2.5rem",
        "components-product-information-spacing-small-button-margin-y": "0.75rem",
        "components-product-information-spacing-small-body-margin-top": "1rem",
        "components-product-information-spacing-small-image-space-between": "0.75rem",
        "components-product-information-spacing-small-image-margin-bottom": "1rem",
        "components-product-results-list-with-search-spacing-large-padding-x": "5rem",
        "components-product-results-list-with-search-spacing-large-padding-y": "5rem",
        "components-product-results-list-with-search-spacing-large-ruleline-margin-top": "2.5rem",
        "components-product-results-list-with-search-spacing-large-filter-space-between": "1rem",
        "components-product-results-list-with-search-spacing-large-card-space-between": "1.5rem",
        "components-product-results-list-with-search-spacing-large-card-container-margin-bottom": "5rem",
        "components-product-results-list-with-search-spacing-small-padding-x": "1.5rem",
        "components-product-results-list-with-search-spacing-small-padding-y": "2.5rem",
        "components-product-results-list-with-search-spacing-small-ruleline-margin-top": "0.75rem",
        "components-product-results-list-with-search-spacing-small-filter-padding-x": "0.5rem",
        "components-product-results-list-with-search-spacing-small-card-space-between": "1rem",
        "components-product-results-list-with-search-spacing-small-card-margin-bottom": "1.5rem",
        "components-promo-spacing-large-padding-x": "0rem",
        "components-promo-spacing-large-padding-y": "0rem",
        "components-promo-spacing-large-content-space-between": "0rem",
        "components-promo-spacing-large-content-space-between-y": "0rem",
        "components-promo-spacing-large-padding-content-x": "5rem",
        "components-promo-spacing-large-padding-content-condensed-x": "5rem",
        "components-promo-spacing-large-padding-content-y": "5rem",
        "components-promo-spacing-large-padding-content-condensed-y": "5rem",
        "components-promo-spacing-large-padding-image-x": "0rem",
        "components-promo-spacing-large-padding-image-y": "0rem",
        "components-promo-spacing-large-padding-image-top": "5rem",
        "components-promo-spacing-large-title-margin-bottom": "2rem",
        "components-promo-spacing-large-margin-y": "2rem",
        "components-promo-spacing-large-buttons-space-between": "1.5rem",
        "components-promo-spacing-small-padding-x": "0rem",
        "components-promo-spacing-small-padding-y": "0rem",
        "components-promo-spacing-small-padding-top": "0rem",
        "components-promo-spacing-small-padding-content-x": "2.5rem",
        "components-promo-spacing-small-padding-content-y": "2.5rem",
        "components-promo-spacing-small-padding-image-x": "0rem",
        "components-promo-spacing-small-padding-image-y": "0rem",
        "components-promo-spacing-small-padding-image-top": "0rem",
        "components-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-promo-spacing-small-margin-y": "2.5rem",
        "components-promo-minheight": "30rem",
        "components-pull-quote-spacing-large-padding-quote-x": "4rem",
        "components-pull-quote-spacing-large-padding-quote-y": "4rem",
        "components-pull-quote-spacing-large-title-margin-bottom": "2rem",
        "components-pull-quote-spacing-large-quote-margin-bottom": "4rem",
        "components-pull-quote-spacing-large-quote-space-between": "1.5rem",
        "components-pull-quote-spacing-large-detail-space-between": "0.5rem",
        "components-pull-quote-spacing-large-quote-minheight": "25rem",
        "components-pull-quote-spacing-small-padding-quote-x": "1.5rem",
        "components-pull-quote-spacing-small-padding-quote-y": "2.5rem",
        "components-pull-quote-spacing-small-title-margin-bottom": "1.5rem",
        "components-pull-quote-spacing-small-quote-margin-bottom": "2.5rem",
        "components-pull-quote-spacing-small-quote-space-between": "1rem",
        "components-pull-quote-spacing-small-detail-space-between": "1rem",
        "components-pull-quote-spacing-small-quote-minheight": "15rem",
        "components-promo-alternate-minheight": "30rem",
        "components-promo-alternate-spacing-large-padding-x": "0rem",
        "components-promo-alternate-spacing-large-padding-y": "0rem",
        "components-promo-alternate-spacing-large-content-space-between": "0rem",
        "components-promo-alternate-spacing-large-padding-content-x": "5rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-x": "5rem",
        "components-promo-alternate-spacing-large-padding-content-y": "5rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-y": "5rem",
        "components-promo-alternate-spacing-large-padding-image-x": "0rem",
        "components-promo-alternate-spacing-large-padding-image-y": "0rem",
        "components-promo-alternate-spacing-large-padding-image-top": "5rem",
        "components-promo-alternate-spacing-large-title-margin-bottom": "2rem",
        "components-promo-alternate-spacing-small-padding-x": "0rem",
        "components-promo-alternate-spacing-small-padding-y": "0rem",
        "components-promo-alternate-spacing-small-padding-content-x": "2.5rem",
        "components-promo-alternate-spacing-small-padding-content-y": "2.5rem",
        "components-promo-alternate-spacing-small-padding-image-x": "0rem",
        "components-promo-alternate-spacing-small-padding-image-y": "0rem",
        "components-promo-alternate-spacing-small-padding-image-top": "0rem",
        "components-promo-alternate-spacing-small-title-margin-bottom": "1.5rem",
        "components-share-list-spacing-padding-x": "1rem",
        "components-share-list-spacing-padding-y": "1rem",
        "components-header-copy-margin-bottom": "1rem",
        "components-header-margin-bottom": "1.5rem",
        "components-item-margin-bottom": "1.5rem",
        "components-site-search-padding-x": "2.5rem",
        "components-site-search-padding-y": "5rem",
        "components-site-search-facet-label-space-between": "0.25rem",
        "components-site-search-refine-padding-x": "1.5rem",
        "components-site-search-refine-padding-y": "0rem",
        "components-site-search-hero-search-height": "18.5rem",
        "components-site-search-hero-search-large-padding-x": "5rem",
        "components-site-search-hero-search-large-padding-y": "5rem",
        "components-stats-spacing-stat-text-space-between": "0.5rem",
        "components-stats-spacing-large-inner-padding-x": "5rem",
        "components-stats-spacing-large-inner-padding-y": "5rem",
        "components-stats-spacing-small-inner-padding-x": "1.5rem",
        "components-stats-spacing-small-inner-padding-y": "1.5rem",
        "components-icon-card-listing-spacing-small-top-space-between": "1rem",
        "components-supporting-text-padding-x": "0rem",
        "components-supporting-text-padding-bottom": "0.5rem",
        "components-tab-item-padding-y": "0.75rem",
        "components-tab-item-padding-x": "0.75rem",
        "components-tab-item-spacing-x": "0.75rem",
        "components-tab-input-padding-y": "0.25rem",
        "components-tab-input-padding-x": "1rem",
        "components-tag-spacing-padding-x": "0.5rem",
        "components-tag-spacing-padding-y": "0.5rem",
        "components-tag-spacing-space-between": "0.5rem",
        "components-text-field-input-padding-y": "0.25rem",
        "components-text-field-input-padding-x": "1rem",
        "components-text-field-area-input-padding-y": "1rem",
        "components-text-field-area-input-padding-x": "1rem",
        "components-text-field-supporting-text-padding-x": "0rem",
        "components-text-field-supporting-text-padding-top": "0.5rem",
        "themes-general-surface-spacing-large-padding-x": "5rem",
        "themes-general-surface-spacing-large-padding-y": "5rem",
        "themes-general-surface-spacing-large-title-margin-bottom": "2rem",
        "themes-general-surface-spacing-large-body-margin-right": "4rem",
        "themes-general-surface-spacing-large-body-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-large-margin-bottom": "4rem",
        "themes-general-surface-spacing-large-cards-space-between": "1.5rem",
        "themes-general-surface-spacing-large-button-margin-top": "2rem",
        "themes-general-surface-spacing-small-padding-x": "1.5rem",
        "themes-general-surface-spacing-small-padding-y": "2.5rem",
        "themes-general-surface-spacing-small-title-margin-bottom": "1rem",
        "themes-general-surface-spacing-small-body-margin-right": "2.5rem",
        "themes-general-surface-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-surface-spacing-small-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-small-cards-space-between": "1.5rem",
        "themes-general-surface-spacing-small-button-margin-top": "1.5rem",
        "themes-general-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "themes-general-card-spacing-large-title-margin-bottom": "1rem",
        "themes-general-card-spacing-large-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-large-button-space-between": "1.5rem",
        "themes-general-card-spacing-small-eyebrow-margin-bottom": "1rem",
        "themes-general-card-spacing-small-title-margin-bottom": "1rem",
        "themes-general-card-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-small-button-space-between": "1.5rem",
        "themes-shadow-share-list-spacing-x": "0rem",
        "themes-shadow-share-list-spacing-y": "0.75rem",
        "themes-shadow-share-list-spacing-blur": "1rem",
        "themes-shadow-share-list-spacing-spread": "-0.5rem",
        "themes-shadow-header-menu-spacing-x": "0rem",
        "themes-shadow-header-menu-spacing-y": "0.75rem",
        "themes-shadow-header-menu-spacing-blur": "1rem",
        "themes-shadow-header-menu-spacing-spread": "-0.5rem",
        "themes-typography-rte-large-h1-textsize": "3rem",
        "themes-typography-rte-large-h2-textsize": "2.5rem",
        "themes-typography-rte-large-h3-textsize": "2.25rem",
        "themes-typography-rte-large-h4-textsize": "2rem",
        "themes-typography-rte-large-h5-textsize": "1.5rem",
        "themes-typography-rte-large-h6-textsize": "1.25rem",
        "themes-typography-rte-medium-h1-textsize": "2.5rem",
        "themes-typography-rte-medium-h2-textsize": "2.25rem",
        "themes-typography-rte-medium-h3-textsize": "1.75rem",
        "themes-typography-rte-medium-h4-textsize": "1.5rem",
        "themes-typography-rte-medium-h5-textsize": "1.25rem",
        "themes-typography-rte-medium-h6-textsize": "1.125rem",
        "themes-typography-rte-small-h1-textsize": "2.5rem",
        "themes-typography-rte-small-h2-textsize": "2rem",
        "themes-typography-rte-small-h3-textsize": "1.75rem",
        "themes-typography-rte-small-h4-textsize": "1.5rem",
        "themes-typography-rte-small-h5-textsize": "1.125rem",
        "themes-typography-rte-small-h6-textsize": "1rem",
        "spacing-margin-small-1": "0.125rem",
        "spacing-margin-small-2": "0.25rem",
        "spacing-margin-small-3": "0.5rem",
        "spacing-margin-small-4": "0.75rem",
        "spacing-margin-small-5": "1rem",
        "spacing-margin-small-6": "1.5rem",
        "spacing-margin-small-7": "2.5rem",
        "spacing-margin-medium-2": "0.75rem",
        "spacing-margin-medium-4": "1.5rem",
        "spacing-margin-medium-5": "2rem",
        "spacing-margin-large-1": "0.5rem",
        "spacing-margin-large-2": "1rem",
        "spacing-margin-large-3": "1.5rem",
        "spacing-margin-large-4": "2rem",
        "spacing-margin-large-5": "2.5rem",
        "spacing-margin-large-6": "4rem",
        "spacing-margin-large-7": "5rem",
        "spacing-space-between-small-1": "0.125rem",
        "spacing-space-between-small-2": "0.25rem",
        "spacing-space-between-small-3": "0.5rem",
        "spacing-space-between-small-4": "0.75rem",
        "spacing-space-between-small-5": "1rem",
        "spacing-space-between-small-6": "1.5rem",
        "spacing-space-between-small-7": "2.5rem",
        "spacing-space-between-medium-1": "0.125rem",
        "spacing-space-between-medium-2": "0.25rem",
        "spacing-space-between-medium-3": "0.5rem",
        "spacing-space-between-medium-4": "0.75rem",
        "spacing-space-between-medium-5": "1rem",
        "spacing-space-between-large-1": "0.25rem",
        "spacing-space-between-large-2": "0.5rem",
        "spacing-space-between-large-3": "1rem",
        "spacing-space-between-large-4": "1.5rem",
        "spacing-space-between-large-5": "1.5rem",
        "spacing-space-between-large-6": "2.5rem",
        "spacing-space-between-large-7": "4rem",
        "spacing-padding-small-1": "0.125rem",
        "spacing-padding-small-2": "0.25rem",
        "spacing-padding-small-3": "0.5rem",
        "spacing-padding-small-4": "0.75rem",
        "spacing-padding-small-5": "1rem",
        "spacing-padding-small-6": "1.5rem",
        "spacing-padding-small-7": "2.5rem",
        "spacing-padding-medium-1": "0.25rem",
        "spacing-padding-medium-2": "0.75rem",
        "spacing-padding-medium-3": "1rem",
        "spacing-padding-medium-4": "1.5rem",
        "spacing-padding-medium-5": "2rem",
        "spacing-padding-medium-6": "3rem",
        "spacing-padding-medium-7": "4rem",
        "spacing-padding-large-1": "0.5rem",
        "spacing-padding-large-2": "1rem",
        "spacing-padding-large-3": "1.5rem",
        "spacing-padding-large-4": "2rem",
        "spacing-padding-large-5": "2.5rem",
        "spacing-padding-large-6": "4rem",
        "spacing-padding-large-7": "5rem"
      },
      "string": {
        "components-accordion-section-button": "var(--brand)",
        "components-article-card-listing-button-theme": "var(--brand)",
        "components-article-hero-pagination-buttons": "var(--brand)",
        "components-banner-banner-button": "var(--brand)",
        "components-category-card-listing-button-theme": "var(--brand)",
        "components-contact-us-promo-hierarchy-button-theme-1": "var(--filled)",
        "components-contact-us-promo-hierarchy-button-theme-2": "var(--outlined)",
        "components-contact-us-promo-color-button-theme": "var(--brand)",
        "components-contact-us-promo-color-brand-button-theme": "var(--white)",
        "components-generic-card-button-theme": "var(--brand)",
        "components-global-search-loader": "var(--default)",
        "components-generic-card-listing-decor-top": "var(--defense-2)",
        "components-generic-card-listing-decor-bottom": "var(--defense-4)",
        "components-hero-homepage-pagination-buttons": "var(--tonal)",
        "components-hero-homepage-pattern": "var(--autan-d)",
        "components-hero-homepage-button-1": "var(--brand)",
        "components-hero-homepage-button-2": "var(--brand)",
        "components-hero-homepage-image-ratio": "var(--5:4)",
        "components-hero-internal-theme-button-one-type": "var(--filled)",
        "components-hero-internal-theme-button-two-type": "var(--text)",
        "components-hero-internal-theme-button-color": "var(--white)",
        "components-hero-internal-theme-button-color-with-image": "var(--white)",
        "components-lightbox-button-type": "var(--brand)",
        "components-pdf-link-card-hierarchy-button-theme-1": "var(--filled)",
        "components-pdf-link-card-hierarchy-button-theme-2": "var(--outlined)",
        "components-pdf-link-card-color-button-theme": "var(--brand)",
        "components-product-card-badge-align": "var(--center)",
        "components-product-card-listing-button-color": "var(--tonal)",
        "components-promo-theme-button-one-theme": "var(--filled)",
        "components-promo-theme-button-two-theme": "var(--outlined)",
        "components-promo-color-default-button-theme": "var(--brand)",
        "components-promo-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-color-brand-button-theme": "var(--white)",
        "components-pull-quote-border-decor": "var(--off-1)",
        "components-promo-alternate-theme-button-one-theme": "var(--filled)",
        "components-promo-alternate-theme-button-two-theme": "var(--outlined)",
        "components-promo-alternate-color-default-button-theme": "var(--brand)",
        "components-promo-alternate-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-alternate-color-brand-button-theme": "var(--white)",
        "components-share-list-button-color": "var(--brand)",
        "components-site-search-hero-search-hero-pattern": "var(--defense)",
        "components-stats-decor-top": "var(--defense-2)",
        "components-stats-decor-bottom": "var(--defense-4)",
        "themes-brand-loader": "var(--default)",
        "themes-brand-variants": "var(--off)",
        "themes-tab-variant": "var(--default)",
        "themes-label-variants": "var(--default)",
        "themes-logo": "var(--autandefense)",
        "themes-category-card": "var(--off)",
        "themes-title-decor": "var(--off)",
        "themes-typography-heading-fontfamily": "var(--poppins)",
        "themes-typography-heading-large-xxlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-xlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-large-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-medium-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-small-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-xsmall-fontweight": "var(--bold)",
        "themes-typography-heading-large-xxsmall-fontweight": "var(--semibold)",
        "themes-typography-heading-medium-xxlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-xlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-large-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-medium-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-small-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-xsmall-fontweight": "var(--bold)",
        "themes-typography-heading-medium-xxsmall-fontweight": "var(--semibold)",
        "themes-typography-heading-small-xxlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-xlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-large-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-medium-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-small-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-xsmall-fontweight": "var(--bold)",
        "themes-typography-heading-small-xxsmall-fontweight": "var(--semibold)",
        "themes-typography-rte-fontfamily": "var(--poppins)",
        "themes-typography-rte-fontweight": "var(--extrabold)"
      },
      "borderRadius": {
        "components-accordion-spacing-large-item-item-radius": "0rem",
        "components-article-card-spacing-large-image-radius-tl": "0rem",
        "components-article-card-spacing-large-image-radius-tr": "0rem",
        "components-article-card-spacing-large-image-radius-bl": "0rem",
        "components-article-card-spacing-large-image-radius-br": "0rem",
        "components-article-card-spacing-large-list-image-radius-br": "0rem",
        "components-article-card-spacing-small-image-radius-br": "0rem",
        "components-article-card-spacing-small-list-image-radius-br": "0rem",
        "components-article-hero-spacing-large-content-radius": "1.5rem",
        "components-article-hero-spacing-small-content-radius": "1rem",
        "components-button-radius-large": "0.5rem",
        "components-button-group-spacing-border-radius": "0.5rem",
        "components-category-card-radius-border-width": "0.0625rem",
        "components-category-card-radius-border-radius": "7.5rem",
        "components-category-card-radius-border-radius-condensed": "0.5rem",
        "components-category-card-radius-media-radius": "0.75rem",
        "components-content-list-icon-radius": "7.5rem",
        "components-fab-spacing-border-radius": "0.5rem",
        "components-header-spacing-large-search-border-radius-top": "0.25rem",
        "components-header-spacing-large-search-border-radius-bottom": "0.25rem",
        "components-header-spacing-large-language-selector-radius": "0.25rem",
        "components-header-spacing-large-dropdown-radius": "0rem",
        "components-hero-homepage-spacing-large-container-radius": "1rem",
        "components-hero-internal-spacing-large-icon-radius": "7.5rem",
        "components-hero-internal-spacing-small-icon-radius": "7.5rem",
        "components-lightbox-spacing-large-overlay-radius": "1rem",
        "components-pagination-scrollbar-radius": "62.5rem",
        "components-product-card-spacing-image-radius-tl": "0rem",
        "components-product-card-spacing-image-radius-tr": "0rem",
        "components-product-card-spacing-image-radius-bl": "0rem",
        "components-product-card-spacing-image-radius-br": "0rem",
        "components-product-information-spacing-large-content-radius": "1.5rem",
        "components-product-information-spacing-small-content-radius": "1rem",
        "components-promo-spacing-large-content-radius": "0rem",
        "components-promo-spacing-large-content-radius-tl": "0rem",
        "components-promo-spacing-large-content-radius-tr": "0rem",
        "components-promo-spacing-large-content-radius-bl": "0rem",
        "components-promo-spacing-large-content-radius-br": "0rem",
        "components-promo-spacing-large-image-radius-tl": "0rem",
        "components-promo-spacing-large-image-radius-tr": "0rem",
        "components-promo-spacing-large-image-radius-bl": "0rem",
        "components-promo-spacing-large-image-radius-br": "0rem",
        "components-promo-spacing-small-content-radius": "0rem",
        "components-promo-spacing-small-image-radius-tl": "0rem",
        "components-promo-spacing-small-image-radius-tr": "0rem",
        "components-promo-spacing-small-image-radius-bl": "0rem",
        "components-promo-spacing-small-image-radius-br": "0rem",
        "components-promo-alternate-spacing-large-content-radius": "0rem",
        "components-promo-alternate-spacing-large-image-radius-tl": "0rem",
        "components-promo-alternate-spacing-large-image-radius-tr": "0rem",
        "components-promo-alternate-spacing-large-image-radius-bl": "0rem",
        "components-promo-alternate-spacing-large-image-radius-br": "0rem",
        "components-promo-alternate-spacing-small-content-radius": "0rem",
        "components-promo-alternate-spacing-small-image-radius-tl": "0rem",
        "components-promo-alternate-spacing-small-image-radius-tr": "0rem",
        "components-promo-alternate-spacing-small-image-radius-bl": "0rem",
        "components-promo-alternate-spacing-small-image-radius-br": "0rem",
        "components-share-list-spacing-radius": "0.5rem",
        "components-share-list-spacing-link-radius": "0.5rem",
        "components-site-search-filter-radius": "0.5rem",
        "components-site-search-facet-radius": "0rem",
        "components-site-search-refine-radius": "0rem",
        "components-tag-spacing-radius": "0.25rem",
        "components-tip-spacing-radius": "0.25rem",
        "themes-spacing-radius-pagination-bar": "62.5rem",
        "themes-spacing-radius-large-button": "0.5rem",
        "themes-spacing-radius-large-button-icon": "0.5rem",
        "themes-spacing-radius-large-image": "1rem",
        "themes-spacing-radius-large-image-hero": "1rem",
        "themes-spacing-radius-large-image-card": "0.75rem",
        "themes-spacing-radius-large-card": "1rem",
        "themes-spacing-radius-large-icon": "7.5rem",
        "themes-spacing-radius-large-label": "0.25rem",
        "themes-spacing-radius-large-tag": "0.25rem",
        "themes-spacing-radius-large-container-tip": "0.25rem",
        "themes-spacing-radius-large-badge": "0rem",
        "themes-spacing-radius-large-item": "0.5rem",
        "themes-spacing-radius-large-form-field-input": "0.25rem",
        "themes-spacing-radius-large-form-area-input": "0.25rem",
        "themes-spacing-radius-large-general": "1.5rem",
        "themes-spacing-radius-small-button": "0.5rem",
        "themes-spacing-radius-small-button-icon": "0.5rem",
        "themes-spacing-radius-small-image": "0.75rem",
        "themes-spacing-radius-small-image-card": "0.75rem",
        "themes-spacing-radius-small-card": "0.75rem",
        "themes-spacing-radius-small-icon": "7.5rem",
        "themes-spacing-radius-small-label": "0.125rem",
        "themes-spacing-radius-small-tag": "0.25rem",
        "themes-spacing-radius-small-badge": "0rem",
        "themes-spacing-radius-small-item": "0rem",
        "themes-spacing-radius-small-form-field-input": "0.25rem",
        "themes-spacing-radius-small-general": "1rem"
      },
      "borderWidth": {
        "3": "3px",
        "components-article-card-spacing-large-border-width": "0.0625rem",
        "components-generic-card-color-border-width": "0.0625rem",
        "components-header-spacing-large-search-border-width-top": "0.125rem",
        "components-header-spacing-large-search-border-width-right": "0.125rem",
        "components-header-spacing-large-search-border-width-bottom": "0.125rem",
        "components-header-spacing-large-search-border-width-left": "0.125rem",
        "components-product-card-spacing-border-width": "0.0625rem"
      },
      "maxHeight": {},
      "maxWidth": {
        "components-article-hero-spacing-large-header-max-width": "66.375rem",
        "components-content-list-spacing-large-item-image-max-width": "33.75rem",
        "components-content-list-spacing-small-item-image-max-width": "33.75rem"
      },
      "minHeight": {
        "components-dropdown-item-min-height": "3rem",
        "components-icon-min-height": "3rem",
        "components-tab-item-min-height": "3rem",
        "components-text-field-input-min-height": "3rem",
        "components-text-field-icon-min-height": "3rem"
      },
      "minWidth": {
        "components-content-list-spacing-small-item-image-min-width": "18.75rem",
        "components-icon-min-width": "3rem",
        "components-text-field-icon-min-width": "3rem"
      },
      "opacity": {
        "components-accessibility-bar-color-bg": 1,
        "components-accessibility-bar-color-text": 1,
        "components-accordion-color-bg": 1,
        "components-accordion-color-title": 1,
        "components-accordion-color-body": 1,
        "components-accordion-color-section-section-bg": 1,
        "components-accordion-color-section-section-title": 1,
        "components-accordion-color-section-section-body": 1,
        "components-accordion-color-item-item-bg": 1,
        "components-accordion-color-item-divider": 1,
        "components-accordion-color-item-icon-icon-default": 1,
        "components-accordion-color-item-icon-icon-hover": 1,
        "components-accordion-color-item-icon-icon-pressed": 1,
        "components-accordion-color-item-icon-icon-active": 1,
        "components-accordion-color-item-title-item-default": 1,
        "components-accordion-color-item-title-item-hover": 1,
        "components-accordion-color-item-title-item-pressed": 1,
        "components-accordion-color-item-title-item-active": 1,
        "components-accordion-color-content-bg": 1,
        "components-accordion-color-content-title": 1,
        "components-accordion-color-content-body": 1,
        "components-article-card-color-bg": 1,
        "components-article-card-color-border": 1,
        "components-article-card-color-content-bg": 1,
        "components-article-card-color-title": 1,
        "components-article-card-color-subtitle": 1,
        "components-article-card-color-copy": 1,
        "components-article-card-color-description": 1,
        "components-article-card-listing-color-default-bg": 1,
        "components-article-card-listing-color-default-title": 1,
        "components-article-card-listing-color-default-copy": 1,
        "components-article-hero-color-content-bg": 1,
        "components-article-hero-color-bg": 1,
        "components-article-hero-color-headline": 1,
        "components-article-hero-color-body": 1,
        "components-article-hero-color-detail-text": 1,
        "components-article-hero-color-divider": 1,
        "components-article-hero-color-icon": 1,
        "components-article-hero-color-play-icon": 1,
        "components-article-hero-color-selected-stroke": 1,
        "components-banner-color-bg-default": 1,
        "components-banner-color-icon-default": 1,
        "components-banner-color-title-default": 1,
        "components-banner-color-body-default": 1,
        "components-banner-color-icon-close-default": 1,
        "components-banner-color-bg-alert": 1,
        "components-banner-color-icon-alert": 1,
        "components-banner-color-title-alert": 1,
        "components-banner-color-body-alert": 1,
        "components-banner-color-icon-close-alert": 1,
        "components-breadcrumb-color-bg": 1,
        "components-breadcrumb-color-breadcrumb-link-default": 1,
        "components-breadcrumb-color-breadcrumb-link-hover": 1,
        "components-breadcrumb-color-breadcrumb-link-active": 1,
        "components-breadcrumb-color-icon-divider": 1,
        "components-bug-finder-prompt": 1,
        "components-button-color-filled-brand-default-bg": 1,
        "components-button-color-filled-brand-default-text": 1,
        "components-button-color-filled-brand-default-stroke": 1,
        "components-button-color-filled-brand-default-icon": 1,
        "components-button-color-filled-brand-hover-bg": 1,
        "components-button-color-filled-brand-hover-text": 1,
        "components-button-color-filled-brand-hover-stroke": 1,
        "components-button-color-filled-brand-hover-icon": 1,
        "components-button-color-filled-brand-pressed-bg": 1,
        "components-button-color-filled-brand-pressed-text": 1,
        "components-button-color-filled-brand-pressed-stroke": 1,
        "components-button-color-filled-brand-pressed-icon": 1,
        "components-button-color-filled-brand-disabled-bg": 0.03,
        "components-button-color-filled-brand-disabled-text": 0.02,
        "components-button-color-filled-brand-disabled-stroke": 0.03,
        "components-button-color-filled-brand-disabled-icon": 0.02,
        "components-button-color-filled-brand-focus-bg": 1,
        "components-button-color-filled-brand-focus-text": 1,
        "components-button-color-filled-brand-focus-stroke": 1,
        "components-button-color-filled-brand-focus-icon": 1,
        "components-button-color-filled-brand-focus-outline": 1,
        "components-button-color-filled-tonal-default-bg": 1,
        "components-button-color-filled-tonal-default-text": 1,
        "components-button-color-filled-tonal-default-stroke": 1,
        "components-button-color-filled-tonal-default-icon": 1,
        "components-button-color-filled-tonal-hover-bg": 1,
        "components-button-color-filled-tonal-hover-text": 1,
        "components-button-color-filled-tonal-hover-stroke": 1,
        "components-button-color-filled-tonal-hover-icon": 1,
        "components-button-color-filled-tonal-pressed-bg": 1,
        "components-button-color-filled-tonal-pressed-text": 1,
        "components-button-color-filled-tonal-pressed-stroke": 1,
        "components-button-color-filled-tonal-pressed-icon": 1,
        "components-button-color-filled-tonal-disabled-bg": 0.02,
        "components-button-color-filled-tonal-disabled-text": 0.03,
        "components-button-color-filled-tonal-disabled-stroke": 0.02,
        "components-button-color-filled-tonal-disabled-icon": 0.03,
        "components-button-color-filled-tonal-focus-bg": 1,
        "components-button-color-filled-tonal-focus-text": 1,
        "components-button-color-filled-tonal-focus-stroke": 1,
        "components-button-color-filled-tonal-focus-icon": 1,
        "components-button-color-filled-tonal-focus-outline": 1,
        "components-button-color-filled-white-default-bg": 1,
        "components-button-color-filled-white-default-text": 1,
        "components-button-color-filled-white-default-stroke": 1,
        "components-button-color-filled-white-default-icon": 1,
        "components-button-color-filled-white-hover-bg": 1,
        "components-button-color-filled-white-hover-text": 1,
        "components-button-color-filled-white-hover-stroke": 1,
        "components-button-color-filled-white-hover-icon": 1,
        "components-button-color-filled-white-pressed-bg": 1,
        "components-button-color-filled-white-pressed-text": 1,
        "components-button-color-filled-white-pressed-stroke": 1,
        "components-button-color-filled-white-pressed-icon": 1,
        "components-button-color-filled-white-disabled-bg": 0.02,
        "components-button-color-filled-white-disabled-text": null,
        "components-button-color-filled-white-disabled-stroke": 0.02,
        "components-button-color-filled-white-disabled-icon": 0.03,
        "components-button-color-filled-white-focus-bg": 1,
        "components-button-color-filled-white-focus-text": 1,
        "components-button-color-filled-white-focus-stroke": 1,
        "components-button-color-filled-white-focus-icon": 1,
        "components-button-color-filled-white-focus-outline": 1,
        "components-button-color-outline-brand-default-bg": 0.03,
        "components-button-color-outline-brand-default-text": 1,
        "components-button-color-outline-brand-default-stroke": 1,
        "components-button-color-outline-brand-default-icon": 1,
        "components-button-color-outline-brand-hover-bg": 1,
        "components-button-color-outline-brand-hover-text": 1,
        "components-button-color-outline-brand-hover-stroke": 1,
        "components-button-color-outline-brand-hover-icon": 1,
        "components-button-color-outline-brand-pressed-bg": 1,
        "components-button-color-outline-brand-pressed-text": 1,
        "components-button-color-outline-brand-pressed-stroke": 1,
        "components-button-color-outline-brand-pressed-icon": 1,
        "components-button-color-outline-brand-disabled-bg": 0.03,
        "components-button-color-outline-brand-disabled-text": 0.03,
        "components-button-color-outline-brand-disabled-stroke": 0.03,
        "components-button-color-outline-brand-disabled-icon": 0.03,
        "components-button-color-outline-brand-focus-bg": 1,
        "components-button-color-outline-brand-focus-text": 1,
        "components-button-color-outline-brand-focus-stroke": 1,
        "components-button-color-outline-brand-focus-icon": 1,
        "components-button-color-outline-brand-focus-outline": 1,
        "components-button-color-outline-tonal-default-bg": 1,
        "components-button-color-outline-tonal-default-text": 1,
        "components-button-color-outline-tonal-default-stroke": 1,
        "components-button-color-outline-tonal-default-icon": 1,
        "components-button-color-outline-tonal-hover-bg": 1,
        "components-button-color-outline-tonal-hover-text": 1,
        "components-button-color-outline-tonal-hover-stroke": 1,
        "components-button-color-outline-tonal-hover-icon": 1,
        "components-button-color-outline-tonal-pressed-bg": 1,
        "components-button-color-outline-tonal-pressed-text": 1,
        "components-button-color-outline-tonal-pressed-stroke": 1,
        "components-button-color-outline-tonal-pressed-icon": 1,
        "components-button-color-outline-tonal-disabled-bg": 0.02,
        "components-button-color-outline-tonal-disabled-text": 0.02,
        "components-button-color-outline-tonal-disabled-stroke": 0.02,
        "components-button-color-outline-tonal-disabled-icon": 0.02,
        "components-button-color-outline-tonal-focus-bg": 1,
        "components-button-color-outline-tonal-focus-text": 1,
        "components-button-color-outline-tonal-focus-stroke": 1,
        "components-button-color-outline-tonal-focus-icon": 1,
        "components-button-color-outline-tonal-focus-outline": 1,
        "components-button-color-outline-white-default-bg": 0.02,
        "components-button-color-outline-white-default-text": 1,
        "components-button-color-outline-white-default-stroke": 1,
        "components-button-color-outline-white-default-icon": 1,
        "components-button-color-outline-white-hover-bg": 1,
        "components-button-color-outline-white-hover-text": 1,
        "components-button-color-outline-white-hover-stroke": 1,
        "components-button-color-outline-white-hover-icon": 1,
        "components-button-color-outline-white-pressed-bg": 1,
        "components-button-color-outline-white-pressed-text": 1,
        "components-button-color-outline-white-pressed-stroke": 1,
        "components-button-color-outline-white-pressed-icon": 1,
        "components-button-color-outline-white-disabled-bg": 0.02,
        "components-button-color-outline-white-disabled-text": 0.02,
        "components-button-color-outline-white-disabled-stroke": 0.02,
        "components-button-color-outline-white-disabled-icon": 0.02,
        "components-button-color-outline-white-focus-bg": 0.02,
        "components-button-color-outline-white-focus-text": 1,
        "components-button-color-outline-white-focus-stroke": 1,
        "components-button-color-outline-white-focus-icon": 1,
        "components-button-color-outline-white-focus-outline": 1,
        "components-button-color-text-brand-default-bg": 0.02,
        "components-button-color-text-brand-default-text": 1,
        "components-button-color-text-brand-default-stroke": 1,
        "components-button-color-text-brand-default-icon": 1,
        "components-button-color-text-brand-hover-bg": 0.02,
        "components-button-color-text-brand-hover-bg-icon-only": 1,
        "components-button-color-text-brand-hover-text": 1,
        "components-button-color-text-brand-hover-stroke": 1,
        "components-button-color-text-brand-hover-icon": 1,
        "components-button-color-text-brand-pressed-bg": 0.02,
        "components-button-color-text-brand-pressed-bg-icon-only": 1,
        "components-button-color-text-brand-pressed-text": 1,
        "components-button-color-text-brand-pressed-stroke": 1,
        "components-button-color-text-brand-pressed-icon": 1,
        "components-button-color-text-brand-disabled-bg": 0.02,
        "components-button-color-text-brand-disabled-text": 0.03,
        "components-button-color-text-brand-disabled-stroke": 0.03,
        "components-button-color-text-brand-disabled-icon": 0.03,
        "components-button-color-text-brand-focus-bg": 1,
        "components-button-color-text-brand-focus-text": 1,
        "components-button-color-text-brand-focus-stroke": 1,
        "components-button-color-text-brand-focus-icon": 1,
        "components-button-color-text-brand-focus-outline": 1,
        "components-button-color-text-tonal-default-bg": 0.02,
        "components-button-color-text-tonal-default-text": 1,
        "components-button-color-text-tonal-default-stroke": 1,
        "components-button-color-text-tonal-default-icon": 1,
        "components-button-color-text-tonal-hover-bg": 0.02,
        "components-button-color-text-tonal-hover-bg-icon-only": 1,
        "components-button-color-text-tonal-hover-text": 1,
        "components-button-color-text-tonal-hover-stroke": 1,
        "components-button-color-text-tonal-hover-icon": 1,
        "components-button-color-text-tonal-pressed-bg": 0.02,
        "components-button-color-text-tonal-pressed-bg-icon-only": 1,
        "components-button-color-text-tonal-pressed-text": 1,
        "components-button-color-text-tonal-pressed-stroke": 1,
        "components-button-color-text-tonal-pressed-icon": 1,
        "components-button-color-text-tonal-disabled-bg": 0.02,
        "components-button-color-text-tonal-disabled-text": 0.03,
        "components-button-color-text-tonal-disabled-stroke": 0.03,
        "components-button-color-text-tonal-disabled-icon": 0.03,
        "components-button-color-text-tonal-focus-bg": 1,
        "components-button-color-text-tonal-focus-text": 1,
        "components-button-color-text-tonal-focus-stroke": 1,
        "components-button-color-text-tonal-focus-icon": 1,
        "components-button-color-text-tonal-focus-outline": 1,
        "components-button-color-text-white-default-bg": 0.02,
        "components-button-color-text-white-default-text": 1,
        "components-button-color-text-white-default-stroke": 1,
        "components-button-color-text-white-default-icon": 1,
        "components-button-color-text-white-hover-bg": 0.02,
        "components-button-color-text-white-hover-bg-icon-only": 1,
        "components-button-color-text-white-hover-text": 1,
        "components-button-color-text-white-hover-stroke": 1,
        "components-button-color-text-white-hover-icon": 1,
        "components-button-color-text-white-pressed-bg": 0.02,
        "components-button-color-text-white-pressed-bg-icon-only": 1,
        "components-button-color-text-white-pressed-text": 1,
        "components-button-color-text-white-pressed-stroke": 1,
        "components-button-color-text-white-pressed-icon": 1,
        "components-button-color-text-white-disabled-bg": 0.02,
        "components-button-color-text-white-disabled-text": 0.01,
        "components-button-color-text-white-disabled-stroke": 0.01,
        "components-button-color-text-white-disabled-icon": 0.01,
        "components-button-color-text-white-focus-bg": 1,
        "components-button-color-text-white-focus-text": 1,
        "components-button-color-text-white-focus-stroke": 1,
        "components-button-color-text-white-focus-icon": 1,
        "components-button-color-text-white-focus-outline": 1,
        "components-button-group-color-border": 1,
        "components-button-group-color-button-bg-default": 0.02,
        "components-button-group-color-button-fg-default": 1,
        "components-button-group-color-button-bg-hover": 1,
        "components-button-group-color-button-fg-hover": 1,
        "components-button-group-color-button-bg-active": 1,
        "components-button-group-color-button-fg-active": 1,
        "components-button-group-color-overlay-left-gradient-fill-1": 1,
        "components-button-group-color-overlay-left-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-1": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-3": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-4": 0.02,
        "components-button-group-color-stroke-hover-gradient-fill-1": 1,
        "components-button-group-color-stroke-hover-gradient-fill-2": 1,
        "components-button-group-color-stroke-hover-gradient-fill-3": 1,
        "components-button-group-color-stroke-hover-gradient-fill-4": 1,
        "components-button-group-color-stroke-active-gradient-fill-1": 1,
        "components-button-group-color-stroke-active-gradient-fill-2": 1,
        "components-button-group-color-stroke-active-gradient-fill-3": 1,
        "components-button-group-color-stroke-active-gradient-fill-4": 1,
        "components-category-card-color-bg": 1,
        "components-category-card-color-content-bg": 1,
        "components-category-card-color-category-bg": 1,
        "components-category-card-color-category-bg-2": 1,
        "components-category-card-color-category-bg-3": 1,
        "components-category-card-color-category-bg-4": 1,
        "components-category-card-color-category-bg-5": 1,
        "components-category-card-color-category-bg-6": 1,
        "components-category-card-color-title": 1,
        "components-category-card-color-copy": 1,
        "components-category-card-color-stroke": 1,
        "components-category-card-color-stroke-hover": 1,
        "components-category-card-listing-color-bg": 1,
        "components-category-card-listing-color-title": 1,
        "components-category-card-listing-color-copy": 1,
        "components-card-search-result-color-bg": 1,
        "components-card-search-result-color-label": 1,
        "components-card-search-result-color-title": 1,
        "components-card-search-result-color-body": 1,
        "components-card-search-result-color-divider-line": 1,
        "components-contact-us-promo-color-light-bg": 1,
        "components-contact-us-promo-color-light-title": 1,
        "components-contact-us-promo-color-light-body": 1,
        "components-contact-us-promo-color-default-bg": 1,
        "components-contact-us-promo-color-default-title": 1,
        "components-contact-us-promo-color-default-body": 1,
        "components-contact-us-promo-color-brand-bg": 1,
        "components-contact-us-promo-color-brand-title": 1,
        "components-contact-us-promo-color-brand-body": 1,
        "components-content-list-color-bg": 1,
        "components-content-list-color-title": 1,
        "components-content-list-color-body": 1,
        "components-content-list-color-icon": 1,
        "components-cookies-colors-bg": 1,
        "components-cookies-colors-title": 1,
        "components-cookies-colors-body": 1,
        "components-cookies-colors-icon": 1,
        "components-dropdown-bg": 1,
        "components-dropdown-border-default": 1,
        "components-dropdown-border-hover": 1,
        "components-dropdown-border-focused": 1,
        "components-dropdown-border-destructive": 1,
        "components-dropdown-label-text-default": 1,
        "components-dropdown-item-text-default": 1,
        "components-dropdown-item-bg-default": 1,
        "components-dropdown-item-bg-hover": 1,
        "components-dropdown-input-field-default": 1,
        "components-dropdown-input-field-focused": 1,
        "components-dropdown-input-label-focused": 1,
        "components-dropdown-input-field-destructive": 1,
        "components-external-link-card-bg": 1,
        "components-external-link-card-card-bg": 1,
        "components-external-link-card-card-title": 1,
        "components-external-link-card-card-subtitle": 1,
        "components-external-link-card-card-eyebrow": 1,
        "components-fab-color-border-default": 1,
        "components-footer-brand-color-bg": 1,
        "components-footer-brand-color-logo": 1,
        "components-footer-brand-color-title-text": 1,
        "components-footer-brand-color-description-text": 1,
        "components-footer-brand-color-footer-menu-expanded": 1,
        "components-footer-brand-color-link-default": 1,
        "components-footer-brand-color-link-hover": 1,
        "components-footer-brand-color-link-pressed": 1,
        "components-footer-brand-color-category-link-default": 1,
        "components-footer-brand-color-category-link-hover": 1,
        "components-footer-brand-color-category-link-pressed": 1,
        "components-footer-brand-color-subcategory-link-default": 1,
        "components-footer-brand-color-subcategory-link-hover": 1,
        "components-footer-brand-color-subcategory-link-pressed": 1,
        "components-footer-brand-color-icon-icon-default": 1,
        "components-footer-brand-color-icon-icon-pressed": 1,
        "components-footer-brand-color-social-icon-default": 1,
        "components-footer-brand-color-social-icon-hover": 1,
        "components-footer-brand-color-social-icon-pressed": 1,
        "components-footer-brand-spacing-category-link-hover": 1,
        "components-footer-global-color-bg": 1,
        "components-footer-global-color-title-text": 1,
        "components-footer-global-color-disclaimer-text": 1,
        "components-footer-global-color-link-default": 1,
        "components-footer-global-color-link-hover": 1,
        "components-footer-global-color-link-pressed": 1,
        "components-generic-card-color-bg": 1,
        "components-generic-card-color-content-bg": 1,
        "components-generic-card-color-title": 1,
        "components-generic-card-color-subtitle": 1,
        "components-generic-card-color-copy": 1,
        "components-global-search-color-omnibox-list-bg": 1,
        "components-global-search-color-omnibox-divider": 1,
        "components-global-search-color-omnibox-item-bg-default": 1,
        "components-global-search-color-omnibox-item-bg-hover": 1,
        "components-global-search-color-omnibox-item-text-default": 1,
        "components-global-search-color-omnibox-item-text-hover": 1,
        "components-global-search-color-omnibox-label-text-default": 1,
        "components-global-search-color-omnibox-category-text-default": 1,
        "components-generic-card-listing-color-bg": 1,
        "components-generic-card-listing-color-title": 1,
        "components-generic-card-listing-color-copy": 1,
        "components-header-color-bg": 1,
        "components-header-color-bottom-stroke": 1,
        "components-header-color-search-search-border-default": 1,
        "components-header-color-search-search-text": 1,
        "components-header-color-search-search-icon": 1,
        "components-header-color-search-search-bar": 1,
        "components-header-color-mobile-mobile-expanded-bg": 1,
        "components-header-color-mobile-pressed-bg": 1,
        "components-header-color-language-selector-language": 1,
        "components-header-color-language-selector-country": 1,
        "components-header-color-language-selector-country-hover-bg": 1,
        "components-header-color-language-selector-megamenu-language": 1,
        "components-header-color-language-selector-megamenu-country": 1,
        "components-header-color-language-selector-megamenu-country-hover": 1,
        "components-header-color-language-selector-bg": 1,
        "components-header-color-language-selector-stroke": 1,
        "components-header-color-mega-menu-bg": 1,
        "components-header-color-mega-menu-featured-bg": 1,
        "components-header-color-mega-menu-featured-title": 1,
        "components-header-color-mega-menu-category-title": 1,
        "components-header-color-mega-menu-bg-accent": 1,
        "components-header-color-icon-icon-default": 1,
        "components-header-color-icon-icon-hover": 1,
        "components-header-color-icon-icon-pressed": 1,
        "components-header-color-links-nav-main-link-default": 1,
        "components-header-color-links-nav-main-link-hover": 1,
        "components-header-color-links-nav-main-link-pressed": 1,
        "components-header-color-links-nav-main-link-active": 1,
        "components-header-color-links-nav-main-active-bar": 1,
        "components-header-color-links-nav-main-bg-color": 1,
        "components-header-color-links-nav-category-link-category-default": 1,
        "components-header-color-links-nav-category-link-category-hover": 1,
        "components-header-color-links-nav-category-link-category-pressed": 1,
        "components-header-color-links-nav-category-link-category-active": 1,
        "components-header-color-links-nav-menu-link-link-default": 1,
        "components-header-color-links-nav-menu-link-link-hover": 1,
        "components-header-color-links-nav-menu-link-link-pressed": 1,
        "components-header-color-links-nav-menu-link-link-active": 1,
        "components-header-color-links-nav-menu-link-bg": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-default": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-active": 1,
        "components-hero-homepage-color-bg": 1,
        "components-hero-homepage-color-container-bg": 1,
        "components-hero-homepage-color-eyebrow": 1,
        "components-hero-homepage-color-header": 1,
        "components-hero-homepage-color-sub-header": 1,
        "components-hero-homepage-color-body": 1,
        "components-hero-homepage-color-play-pause": 1,
        "components-hero-internal-color-default-title": 1,
        "components-hero-internal-color-default-body": 1,
        "components-hero-internal-color-default-bg": 1,
        "components-hero-internal-color-accent-bg": 1,
        "components-hero-internal-color-with-image-bg": 1,
        "components-hero-internal-color-default-bg-2": 1,
        "components-hero-internal-color-with-image-title": 1,
        "components-hero-internal-color-with-image-body": 1,
        "components-heading-page-title-color-default-bg": 1,
        "components-heading-page-title-color-default-title": 1,
        "components-heading-page-title-color-default-body": 1,
        "components-heading-page-title-color-light-bg": 1,
        "components-heading-page-title-color-light-title": 1,
        "components-heading-page-title-color-light-body": 1,
        "components-heading-page-title-color-color-bg": 1,
        "components-heading-page-title-color-color-title": 1,
        "components-heading-page-title-color-color-body": 1,
        "components-hyperlink-color-dark-default": 1,
        "components-hyperlink-color-dark-hover": 1,
        "components-hyperlink-color-dark-visited": 1,
        "components-hyperlink-color-light-default": 1,
        "components-hyperlink-color-light-hover": 1,
        "components-hyperlink-color-light-visited": 1,
        "components-icon-default": 1,
        "components-icon-focused": 1,
        "components-icon-card-color-bg": 1,
        "components-icon-card-color-icon-bg": 1,
        "components-icon-card-color-icon": 1,
        "components-icon-card-color-title": 1,
        "components-icon-card-color-subtitle": 1,
        "components-icon-card-color-description": 1,
        "components-icon-card-listing-color-bg": 1,
        "components-icon-card-listing-color-inner-bg": 1,
        "components-icon-card-listing-color-brand-bg": 1,
        "components-icon-card-listing-color-title": 1,
        "components-icon-card-listing-color-copy": 1,
        "components-icon-card-listing-color-inner-legal-text": 1,
        "components-jumplink-color-bg-default": 1,
        "components-jumplink-color-link-text-default": 1,
        "components-jumplink-color-link-text-hover": 1,
        "components-jumplink-color-link-border-hover": 1,
        "components-jumplink-color-link-text-active": 1,
        "components-jumplink-color-link-border-active": 1,
        "components-jumplink-color-title-text": 1,
        "components-jumplink-color-description-text": 1,
        "components-layout-bg-default": 1,
        "components-lightbox-color-overlay-bg": 0.02,
        "components-lightbox-color-title": 1,
        "components-lightbox-color-body": 1,
        "components-label-color-on-color-label-label-default": 1,
        "components-label-color-on-color-label-label-hover": 1,
        "components-label-color-on-color-label-label-pressed": 1,
        "components-label-color-on-color-background-bg-default": 1,
        "components-label-color-on-color-background-bg-hover": 1,
        "components-label-color-on-color-background-bg-pressed": 1,
        "components-label-color-on-color-stroke-hover": 1,
        "components-label-color-on-color-stroke-pressed": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-4": 1,
        "components-label-color-on-white-label-label-default": 1,
        "components-label-color-on-white-label-label-hover": 1,
        "components-label-color-on-white-label-label-pressed": 1,
        "components-label-color-on-white-background-bg-default": 0.02,
        "components-label-color-on-white-background-bg-hover": 1,
        "components-label-color-on-white-background-bg-pressed": 1,
        "components-label-color-on-white-stroke-hover": 1,
        "components-label-color-on-white-stroke-pressed": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-4": 1,
        "components-legal-disclaimer-color-default-bg": 1,
        "components-legal-disclaimer-color-default-title": 1,
        "components-legal-disclaimer-color-default-body": 1,
        "components-legal-disclaimer-color-light-bg": 1,
        "components-legal-disclaimer-color-light-title": 1,
        "components-legal-disclaimer-color-light-body": 1,
        "components-legal-disclaimer-color-brand-bg": 1,
        "components-legal-disclaimer-color-brand-title": 1,
        "components-legal-disclaimer-color-brand-body": 1,
        "components-media-video-thumbnail-overlay-bg": null,
        "components-media-caption-color-bg": 1,
        "components-media-caption-color-text": 1,
        "components-media-gallery-color-bg": 1,
        "components-media-gallery-color-title": 1,
        "components-media-gallery-color-body": 1,
        "components-pagination-on-white-accent-default": 1,
        "components-pagination-on-white-accent-scroll": 1,
        "components-pagination-on-white-disabled": 1,
        "components-pagination-on-white-bg": 1,
        "components-pagination-on-white-stroke": 1,
        "components-pagination-on-white-bg-active": 1,
        "components-pagination-on-white-bg-hover": 1,
        "components-pagination-on-white-text-default": 1,
        "components-pagination-on-white-text-hover": 1,
        "components-pagination-on-white-text-active": 1,
        "components-pagination-on-color-accent-default": 1,
        "components-pagination-on-color-accent-scroll": 1,
        "components-pagination-on-color-disabled": 1,
        "components-pagination-on-color-bg": 1,
        "components-pagination-on-color-stroke": 0.02,
        "components-pagination-on-color-bg-active": 1,
        "components-pagination-on-color-bg-hover": 1,
        "components-pagination-on-color-text-default": 1,
        "components-pagination-on-color-text-hover": 1,
        "components-pagination-on-color-text-active": 1,
        "components-pdf-link-card-color-bg": 1,
        "components-pdf-link-card-color-card-bg": 1,
        "components-pdf-link-card-color-card-title": 1,
        "components-pdf-link-card-color-card-subtitle": 1,
        "components-pdf-link-card-color-card-body": 1,
        "components-product-card-color-bg": 1,
        "components-product-card-color-content-bg": 1,
        "components-product-card-color-border": 1,
        "components-product-card-color-title": 1,
        "components-product-card-color-description": 1,
        "components-product-card-color-star-rating": 1,
        "components-product-card-color-badge-bg": 1,
        "components-product-card-color-badge-text": 1,
        "components-product-card-listing-color-bg": 1,
        "components-product-card-listing-color-title": 1,
        "components-product-card-listing-color-copy": 1,
        "components-product-card-listing-color-legal-text": 1,
        "components-product-information-color-bg": 1,
        "components-product-information-color-content-bg": 1,
        "components-product-information-color-headline": 1,
        "components-product-information-color-body": 1,
        "components-product-information-color-detail-text": 1,
        "components-product-information-color-divider": 1,
        "components-product-information-color-icon": 1,
        "components-product-information-color-play-icon": 1,
        "components-product-information-color-selected-stroke": 1,
        "components-product-results-list-with-search-color-bg": 1,
        "components-promo-color-default-bg": 1,
        "components-promo-color-default-image-bg": 1,
        "components-promo-color-default-content-bg": 1,
        "components-promo-color-default-title": 1,
        "components-promo-color-default-body": 1,
        "components-promo-color-default-category": 1,
        "components-promo-color-light-bg": 1,
        "components-promo-color-light-image-bg": 1,
        "components-promo-color-light-content-bg": 1,
        "components-promo-color-light-title": 1,
        "components-promo-color-light-body": 1,
        "components-promo-color-light-category": 1,
        "components-promo-color-brand-bg": 1,
        "components-promo-color-brand-image-bg": 1,
        "components-promo-color-brand-content-bg": 1,
        "components-promo-color-brand-title": 1,
        "components-promo-color-brand-body": 1,
        "components-promo-color-brand-category": 1,
        "components-pull-quote-color-bg": 1,
        "components-pull-quote-color-stroke": 1,
        "components-pull-quote-color-quote-bg": 1,
        "components-pull-quote-color-quote-title": 1,
        "components-pull-quote-color-quote-by-text": 1,
        "components-pull-quote-color-quote-detail": 1,
        "components-pull-quote-color-quote-highlight": 1,
        "components-promo-alternate-color-default-bg": 1,
        "components-promo-alternate-color-default-image-bg": 1,
        "components-promo-alternate-color-default-content-bg": 1,
        "components-promo-alternate-color-default-title": 1,
        "components-promo-alternate-color-default-body": 1,
        "components-promo-alternate-color-default-category": 1,
        "components-promo-alternate-color-light-bg": 1,
        "components-promo-alternate-color-light-image-bg": 1,
        "components-promo-alternate-color-light-content-bg": 1,
        "components-promo-alternate-color-light-title": 1,
        "components-promo-alternate-color-light-body": 1,
        "components-promo-alternate-color-light-category": 1,
        "components-promo-alternate-color-brand-bg": 1,
        "components-promo-alternate-color-brand-image-bg": 1,
        "components-promo-alternate-color-brand-content-bg": 1,
        "components-promo-alternate-color-brand-title": 1,
        "components-promo-alternate-color-brand-body": 1,
        "components-promo-alternate-color-brand-category": 1,
        "components-share-list-color-title": 1,
        "components-share-list-color-bg": 1,
        "components-share-list-color-link-default": 1,
        "components-share-list-color-link-hover": 1,
        "components-share-list-color-bg-link-hover": 1,
        "components-share-list-color-close-icon": 1,
        "components-share-list-color-facebook-icon": 1,
        "components-share-list-color-pinterest-icon": 1,
        "components-share-list-color-twitter-icon": 1,
        "components-share-list-color-basic-icon-default": 1,
        "components-share-list-color-icon-hover": 1,
        "components-share-list-color-border": 1,
        "components-site-search-bg": 1,
        "components-site-search-results_text": 1,
        "components-site-search-filter-filter-selected-bg": 1,
        "components-site-search-facet-group-label-bottom-line": 1,
        "components-site-search-facet-group-label-text": 1,
        "components-site-search-facet-group-label-bg": 1,
        "components-site-search-facet-bg": 1,
        "components-site-search-facet-stroke": 1,
        "components-site-search-refine-bg": 1,
        "components-site-search-refine-right-divider": 1,
        "components-site-search-refine-title": 1,
        "components-site-search-hero-search-bg": 1,
        "components-site-search-hero-search-accent-bg": 1,
        "components-site-search-hero-search-title": 1,
        "components-stats-stat-text": 1,
        "components-stats-stat-text-on-light": 1,
        "components-stats-supporting-text": 1,
        "components-stats-bg-default": 1,
        "components-stats-bg-inner": 1,
        "components-stats-bg-section-inner": 1,
        "components-stats-title": 1,
        "components-stats-copy": 1,
        "components-supporting-text-default": 1,
        "components-supporting-text-destructive": 1,
        "components-tab-bg": 1,
        "components-tab-border-default": 1,
        "components-tab-border-hover": 1,
        "components-tab-border-focused": 1,
        "components-tab-border-destructive": 1,
        "components-tab-label-text-default": 1,
        "components-tab-item-text-default": 1,
        "components-tab-item-bg-default": 1,
        "components-tab-item-bg-hover": 1,
        "components-tab-input-field-default": 1,
        "components-tab-input-field-focused": 1,
        "components-tab-input-label-focused": 1,
        "components-tab-input-field-destructive": 1,
        "components-tab-bg-2": 1,
        "components-tag-color-bg": 1,
        "components-tag-color-text": 1,
        "components-tag-color-stroke": 1,
        "components-tag-color-bg-hover": 1,
        "components-tag-color-text-hover": 1,
        "components-tag-color-stroke-hover": 1,
        "components-text-field-bg": 1,
        "components-text-field-bg-focused": 1,
        "components-text-field-border-default": 1,
        "components-text-field-border-hover": 1,
        "components-text-field-border-focused": 1,
        "components-text-field-border-destructive": 1,
        "components-text-field-input-field-default": 1,
        "components-text-field-input-field-focused": 1,
        "components-text-field-input-label-focused": 1,
        "components-text-field-input-field-destructive": 1,
        "components-text-field-icon-default": 1,
        "components-text-field-icon-destructive": 1,
        "components-text-field-supporting-text-default": 1,
        "components-text-field-supporting-text-destructive": 1,
        "components-tip-color-bg": 1,
        "components-tip-color-title": 1,
        "components-tip-color-body": 1,
        "components-tip-color-stroke": 1,
        "components-video-color-bg": 1,
        "themes-general-surface-color-light-bg": 1,
        "themes-general-surface-color-light-title": 1,
        "themes-general-surface-color-light-body": 1,
        "themes-general-surface-color-light-decor-line-break": 1,
        "themes-general-surface-color-light-rule-line": 1,
        "themes-general-surface-color-dark-bg": 1,
        "themes-general-surface-color-dark-title": 1,
        "themes-general-surface-color-dark-body": 1,
        "themes-general-surface-color-dark-decor-line-break": 1,
        "themes-general-card-color-dark-title": 1,
        "themes-general-card-color-dark-subtitle": 1,
        "themes-general-card-color-dark-body": 1,
        "themes-general-card-color-light-title": 1,
        "themes-general-card-color-light-subtitle": 1,
        "themes-general-card-color-light-body": 1,
        "themes-shadow-color": 1,
        "themes-shadow-share-list-color-fill": 0,
        "themes-shadow-header-menu-color-fill": 0,
        "themes-gradient-loader-gradient-fill-1": 1,
        "themes-gradient-loader-gradient-fill-2": 0.03,
        "themes-color-background-white": 1,
        "themes-color-background-light": 1,
        "themes-color-background-dark": 1,
        "themes-color-background-black": 1,
        "themes-color-background-brand1": 1,
        "themes-color-background-brand2": 1,
        "themes-color-background-accent1": 1,
        "themes-color-background-accent2": 1,
        "themes-color-background-body": 1,
        "themes-color-stroke-white": 1,
        "themes-color-stroke-light": 1,
        "themes-color-stroke-dark": 1,
        "themes-color-stroke-black": 1,
        "themes-color-stroke-brand1": 1,
        "themes-color-stroke-brand2": 1,
        "themes-color-stroke-accent1": 1,
        "themes-color-stroke-accent2": 1,
        "themes-color-text-white": 1,
        "themes-color-text-light": 1,
        "themes-color-text-dark": 1,
        "themes-color-text-black": 1,
        "themes-color-text-brand1": 1,
        "themes-color-text-brand2": 1,
        "themes-color-text-accent1": 1,
        "themes-color-text-accent2": 1,
        "colors-grayscale-100": 1,
        "colors-grayscale-200": 1,
        "colors-grayscale-300": 1,
        "colors-grayscale-400": 1,
        "colors-grayscale-500": 1,
        "colors-grayscale-600": 1,
        "colors-grayscale-700": 1,
        "colors-brand-1-100": 1,
        "colors-brand-1-200": 1,
        "colors-brand-1-300": 1,
        "colors-brand-1-400": 1,
        "colors-brand-1-500": 1,
        "colors-brand-1-600": 1,
        "colors-brand-1-700": 1,
        "colors-brand-2-100": 1,
        "colors-brand-2-200": 1,
        "colors-brand-2-300": 1,
        "colors-brand-2-400": 1,
        "colors-brand-2-500": 1,
        "colors-brand-2-600": 1,
        "colors-brand-2-700": 1,
        "colors-accent-1-100": 1,
        "colors-accent-1-200": 1,
        "colors-accent-1-300": 1,
        "colors-accent-1-400": 1,
        "colors-accent-1-500": 1,
        "colors-accent-1-600": 1,
        "colors-accent-1-700": 1,
        "colors-accent-2-100": 1,
        "colors-accent-2-200": 1,
        "colors-accent-2-300": 1,
        "colors-accent-2-400": 1,
        "colors-accent-2-500": 1,
        "colors-accent-2-600": 1,
        "colors-accent-2-700": 1,
        "colors-accent-3-100": 1,
        "colors-accent-3-200": 1,
        "colors-accent-3-300": 1,
        "colors-accent-3-400": 1,
        "colors-accent-3-500": 1,
        "colors-accent-3-600": 1,
        "colors-accent-3-700": 1,
        "colors-accent-4-400_storage": 1,
        "colors-accessible-accent-1-100": 1,
        "colors-accessible-accent-1-200": 1,
        "colors-accessible-accent-1-300": 1,
        "colors-accessible-accent-1-400": 1,
        "colors-accessible-accent-1-500": 1,
        "colors-accessible-accent-1-600": 1,
        "colors-accessible-accent-1-700": 1,
        "colors-accessible-accent-2-100": 1,
        "colors-accessible-accent-2-200": 1,
        "colors-accessible-accent-2-300": 1,
        "colors-accessible-accent-2-400": 1,
        "colors-accessible-accent-2-500": 1,
        "colors-accessible-accent-2-600": 1,
        "colors-accessible-accent-2-700": 1,
        "colors-accessible-brand-2-100": 1,
        "colors-accessible-brand-2-200": 1,
        "colors-accessible-brand-2-300": 1,
        "colors-accessible-brand-2-400": 1,
        "colors-accessible-brand-2-500": 1,
        "colors-accessible-brand-2-600": 1,
        "colors-accessible-brand-2-700": 1
      }
    },
    "Baygon": {
      "colors": {
        "components-accessibility-bar-color-bg": "#e8e8ea",
        "components-accessibility-bar-color-text": "#191b1c",
        "components-accordion-color-bg": "#ffffff",
        "components-accordion-color-title": "#191b1c",
        "components-accordion-color-body": "#27282b",
        "components-accordion-color-section-section-bg": "#ffffff",
        "components-accordion-color-section-section-title": "#191b1c",
        "components-accordion-color-section-section-body": "#27282b",
        "components-accordion-color-item-item-bg": "#ffffff",
        "components-accordion-color-item-divider": "#e8e8ea",
        "components-accordion-color-item-icon-icon-default": "#e1251b",
        "components-accordion-color-item-icon-icon-hover": "#ffffff",
        "components-accordion-color-item-icon-icon-pressed": "#ffffff",
        "components-accordion-color-item-icon-icon-active": "#ffffff",
        "components-accordion-color-item-title-item-default": "#191b1c",
        "components-accordion-color-item-title-item-hover": "#ffffff",
        "components-accordion-color-item-title-item-pressed": "#ffffff",
        "components-accordion-color-item-title-item-active": "#ffffff",
        "components-accordion-color-content-bg": "#ffffff",
        "components-accordion-color-content-title": "#191b1c",
        "components-accordion-color-content-body": "#27282b",
        "components-article-card-color-bg": "#ffffff",
        "components-article-card-color-border": "#e8e8ea",
        "components-article-card-color-content-bg": "#ffffff",
        "components-article-card-color-title": "#191b1c",
        "components-article-card-color-subtitle": "#27282b",
        "components-article-card-color-copy": "#27282b",
        "components-article-card-color-description": "#27282b",
        "components-article-card-listing-color-default-bg": "#ffffff",
        "components-article-card-listing-color-default-title": "#191b1c",
        "components-article-card-listing-color-default-copy": "#27282b",
        "components-article-hero-color-content-bg": "#ffffff",
        "components-article-hero-color-bg": "#ffffff",
        "components-article-hero-color-headline": "#191b1c",
        "components-article-hero-color-body": "#27282b",
        "components-article-hero-color-detail-text": "#27282b",
        "components-article-hero-color-divider": "#a2a5ab",
        "components-article-hero-color-icon": "#e1251b",
        "components-article-hero-color-play-icon": "#ffffff",
        "components-article-hero-color-selected-stroke": "#72757e",
        "components-banner-color-bg-default": "#008533",
        "components-banner-color-icon-default": "#ffffff",
        "components-banner-color-title-default": "#ffffff",
        "components-banner-color-body-default": "#ffffff",
        "components-banner-color-icon-close-default": "#ffffff",
        "components-banner-color-bg-alert": "#b32b30",
        "components-banner-color-icon-alert": "#ffffff",
        "components-banner-color-title-alert": "#ffffff",
        "components-banner-color-body-alert": "#ffffff",
        "components-banner-color-icon-close-alert": "#ffffff",
        "components-breadcrumb-color-bg": "#ffffff",
        "components-breadcrumb-color-breadcrumb-link-default": "#72757e",
        "components-breadcrumb-color-breadcrumb-link-hover": "#4e5056",
        "components-breadcrumb-color-breadcrumb-link-active": "#27282b",
        "components-breadcrumb-color-icon-divider": "#72757e",
        "components-bug-finder-prompt": "#ffffff",
        "components-button-color-filled-brand-default-bg": "#191b1c",
        "components-button-color-filled-brand-default-text": "#ffffff",
        "components-button-color-filled-brand-default-stroke": "#191b1c",
        "components-button-color-filled-brand-default-icon": "#ffffff",
        "components-button-color-filled-brand-hover-bg": "#000000",
        "components-button-color-filled-brand-hover-text": "#ffffff",
        "components-button-color-filled-brand-hover-stroke": "#000000",
        "components-button-color-filled-brand-hover-icon": "#ffffff",
        "components-button-color-filled-brand-pressed-bg": "#000000",
        "components-button-color-filled-brand-pressed-text": "#ffffff",
        "components-button-color-filled-brand-pressed-stroke": "#000000",
        "components-button-color-filled-brand-pressed-icon": "#ffffff",
        "components-button-color-filled-brand-disabled-bg": "rgba(25, 27, 28, 0.38)",
        "components-button-color-filled-brand-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-disabled-stroke": "rgba(39, 40, 43, 0.38)",
        "components-button-color-filled-brand-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-focus-bg": "#191b1c",
        "components-button-color-filled-brand-focus-text": "#ffffff",
        "components-button-color-filled-brand-focus-stroke": "#191b1c",
        "components-button-color-filled-brand-focus-icon": "#ffffff",
        "components-button-color-filled-brand-focus-outline": "#286efa",
        "components-button-color-filled-tonal-default-bg": "#e1251b",
        "components-button-color-filled-tonal-default-text": "#ffffff",
        "components-button-color-filled-tonal-default-stroke": "#e1251b",
        "components-button-color-filled-tonal-default-icon": "#ffffff",
        "components-button-color-filled-tonal-hover-bg": "#b41e16",
        "components-button-color-filled-tonal-hover-text": "#ffffff",
        "components-button-color-filled-tonal-hover-stroke": "#b41e16",
        "components-button-color-filled-tonal-hover-icon": "#ffffff",
        "components-button-color-filled-tonal-pressed-bg": "#b41e16",
        "components-button-color-filled-tonal-pressed-text": "#ffffff",
        "components-button-color-filled-tonal-pressed-stroke": "#b41e16",
        "components-button-color-filled-tonal-pressed-icon": "#ffffff",
        "components-button-color-filled-tonal-disabled-bg": "rgba(225, 37, 27, 0.38)",
        "components-button-color-filled-tonal-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-tonal-disabled-stroke": "rgba(225, 37, 27, 0.38)",
        "components-button-color-filled-tonal-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-tonal-focus-bg": "#e1251b",
        "components-button-color-filled-tonal-focus-text": "#ffffff",
        "components-button-color-filled-tonal-focus-stroke": "#e1251b",
        "components-button-color-filled-tonal-focus-icon": "#ffffff",
        "components-button-color-filled-tonal-focus-outline": "#1c4cae",
        "components-button-color-filled-white-default-bg": "#ffffff",
        "components-button-color-filled-white-default-text": "#191b1c",
        "components-button-color-filled-white-default-stroke": "#ffffff",
        "components-button-color-filled-white-default-icon": "#e1251b",
        "components-button-color-filled-white-hover-bg": "#fcfcfd",
        "components-button-color-filled-white-hover-text": "#191b1c",
        "components-button-color-filled-white-hover-stroke": "#fcfcfd",
        "components-button-color-filled-white-hover-icon": "#e1251b",
        "components-button-color-filled-white-pressed-bg": "#e8e8ea",
        "components-button-color-filled-white-pressed-text": "#191b1c",
        "components-button-color-filled-white-pressed-stroke": "#e8e8ea",
        "components-button-color-filled-white-pressed-icon": "#e1251b",
        "components-button-color-filled-white-disabled-bg": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-text": "rgba(25, 27, 28, 0.38)",
        "components-button-color-filled-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-icon": "rgba(225, 37, 27, 0.38)",
        "components-button-color-filled-white-focus-bg": "#ffffff",
        "components-button-color-filled-white-focus-text": "#191b1c",
        "components-button-color-filled-white-focus-stroke": "#ffffff",
        "components-button-color-filled-white-focus-icon": "#e1251b",
        "components-button-color-filled-white-focus-outline": "#1c4cae",
        "components-button-color-outline-brand-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-brand-default-text": "#191b1c",
        "components-button-color-outline-brand-default-stroke": "#191b1c",
        "components-button-color-outline-brand-default-icon": "#191b1c",
        "components-button-color-outline-brand-hover-bg": "#000000",
        "components-button-color-outline-brand-hover-text": "#ffffff",
        "components-button-color-outline-brand-hover-stroke": "#000000",
        "components-button-color-outline-brand-hover-icon": "#ffffff",
        "components-button-color-outline-brand-pressed-bg": "#000000",
        "components-button-color-outline-brand-pressed-text": "#ffffff",
        "components-button-color-outline-brand-pressed-stroke": "#000000",
        "components-button-color-outline-brand-pressed-icon": "#ffffff",
        "components-button-color-outline-brand-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-brand-disabled-text": "rgba(25, 27, 28, 0.38)",
        "components-button-color-outline-brand-disabled-stroke": "rgba(25, 27, 28, 0.38)",
        "components-button-color-outline-brand-disabled-icon": "rgba(25, 27, 28, 0.38)",
        "components-button-color-outline-brand-focus-bg": "#ffffff",
        "components-button-color-outline-brand-focus-text": "#191b1c",
        "components-button-color-outline-brand-focus-stroke": "#191b1c",
        "components-button-color-outline-brand-focus-icon": "#191b1c",
        "components-button-color-outline-brand-focus-outline": "#286efa",
        "components-button-color-outline-tonal-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-tonal-default-text": "#191b1c",
        "components-button-color-outline-tonal-default-stroke": "#e1251b",
        "components-button-color-outline-tonal-default-icon": "#191b1c",
        "components-button-color-outline-tonal-hover-bg": "#b41e16",
        "components-button-color-outline-tonal-hover-text": "#ffffff",
        "components-button-color-outline-tonal-hover-stroke": "#b41e16",
        "components-button-color-outline-tonal-hover-icon": "#ffffff",
        "components-button-color-outline-tonal-pressed-bg": "#b41e16",
        "components-button-color-outline-tonal-pressed-text": "#ffffff",
        "components-button-color-outline-tonal-pressed-stroke": "#b41e16",
        "components-button-color-outline-tonal-pressed-icon": "#ffffff",
        "components-button-color-outline-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-tonal-disabled-text": "rgba(25, 27, 28, 0.38)",
        "components-button-color-outline-tonal-disabled-stroke": "rgba(225, 37, 27, 0.38)",
        "components-button-color-outline-tonal-disabled-icon": "rgba(25, 27, 28, 0.38)",
        "components-button-color-outline-tonal-focus-bg": "rgba(255, 255, 255, 0.01)",
        "components-button-color-outline-tonal-focus-text": "#191b1c",
        "components-button-color-outline-tonal-focus-stroke": "#e1251b",
        "components-button-color-outline-tonal-focus-icon": "#191b1c",
        "components-button-color-outline-tonal-focus-outline": "#286efa",
        "components-button-color-outline-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-default-text": "#ffffff",
        "components-button-color-outline-white-default-stroke": "#ffffff",
        "components-button-color-outline-white-default-icon": "#ffffff",
        "components-button-color-outline-white-hover-bg": "#ffffff",
        "components-button-color-outline-white-hover-text": "#191b1c",
        "components-button-color-outline-white-hover-stroke": "#ffffff",
        "components-button-color-outline-white-hover-icon": "#e1251b",
        "components-button-color-outline-white-pressed-bg": "#ffffff",
        "components-button-color-outline-white-pressed-text": "#191b1c",
        "components-button-color-outline-white-pressed-stroke": "#ffffff",
        "components-button-color-outline-white-pressed-icon": "#e1251b",
        "components-button-color-outline-white-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-focus-bg": "rgba(255, 255, 255, 0.01)",
        "components-button-color-outline-white-focus-text": "#ffffff",
        "components-button-color-outline-white-focus-stroke": "#ffffff",
        "components-button-color-outline-white-focus-icon": "#ffffff",
        "components-button-color-outline-white-focus-outline": "#286efa",
        "components-button-color-text-brand-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-default-text": "#27282b",
        "components-button-color-text-brand-default-stroke": "#27282b",
        "components-button-color-text-brand-default-icon": "#27282b",
        "components-button-color-text-brand-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-hover-bg-icon-only": "#e8e8ea",
        "components-button-color-text-brand-hover-text": "#191b1c",
        "components-button-color-text-brand-hover-stroke": "#27282b",
        "components-button-color-text-brand-hover-icon": "#191b1c",
        "components-button-color-text-brand-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-pressed-bg-icon-only": "#a2a5ab",
        "components-button-color-text-brand-pressed-text": "#000000",
        "components-button-color-text-brand-pressed-stroke": "#000000",
        "components-button-color-text-brand-pressed-icon": "#000000",
        "components-button-color-text-brand-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-disabled-text": "rgba(39, 40, 43, 0.38)",
        "components-button-color-text-brand-disabled-stroke": "rgba(39, 40, 43, 0.38)",
        "components-button-color-text-brand-disabled-icon": "rgba(39, 40, 43, 0.38)",
        "components-button-color-text-brand-focus-bg": "#d5e3ff",
        "components-button-color-text-brand-focus-text": "#191b1c",
        "components-button-color-text-brand-focus-stroke": "#27282b",
        "components-button-color-text-brand-focus-icon": "#1c4cae",
        "components-button-color-text-brand-focus-outline": "#1c4cae",
        "components-button-color-text-tonal-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-default-text": "#191b1c",
        "components-button-color-text-tonal-default-stroke": "#191b1c",
        "components-button-color-text-tonal-default-icon": "#e1251b",
        "components-button-color-text-tonal-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-hover-bg-icon-only": "#fcfcfd",
        "components-button-color-text-tonal-hover-text": "#b41e16",
        "components-button-color-text-tonal-hover-stroke": "#191b1c",
        "components-button-color-text-tonal-hover-icon": "#b41e16",
        "components-button-color-text-tonal-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-pressed-bg-icon-only": "#e8e8ea",
        "components-button-color-text-tonal-pressed-text": "#b41e16",
        "components-button-color-text-tonal-pressed-stroke": "#191b1c",
        "components-button-color-text-tonal-pressed-icon": "#b41e16",
        "components-button-color-text-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-disabled-text": "rgba(225, 37, 27, 0.38)",
        "components-button-color-text-tonal-disabled-stroke": "rgba(225, 37, 27, 0.38)",
        "components-button-color-text-tonal-disabled-icon": "rgba(225, 37, 27, 0.38)",
        "components-button-color-text-tonal-focus-bg": "#d5e3ff",
        "components-button-color-text-tonal-focus-text": "#1c4cae",
        "components-button-color-text-tonal-focus-stroke": "#e1251b",
        "components-button-color-text-tonal-focus-icon": "#1c4cae",
        "components-button-color-text-tonal-focus-outline": "#1c4cae",
        "components-button-color-text-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-default-text": "#ffffff",
        "components-button-color-text-white-default-stroke": "#ffffff",
        "components-button-color-text-white-default-icon": "#ffffff",
        "components-button-color-text-white-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-hover-bg-icon-only": "#4e5056",
        "components-button-color-text-white-hover-text": "#ffffff",
        "components-button-color-text-white-hover-stroke": "#ffffff",
        "components-button-color-text-white-hover-icon": "#ffffff",
        "components-button-color-text-white-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-pressed-bg-icon-only": "#4e5056",
        "components-button-color-text-white-pressed-text": "#ffffff",
        "components-button-color-text-white-pressed-stroke": "#ffffff",
        "components-button-color-text-white-pressed-icon": "#ffffff",
        "components-button-color-text-white-disabled-bg": "rgba(115, 119, 128, 0.00)",
        "components-button-color-text-white-disabled-text": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-stroke": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-icon": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-focus-bg": "#d5e3ff",
        "components-button-color-text-white-focus-text": "#ffffff",
        "components-button-color-text-white-focus-stroke": "#ffffff",
        "components-button-color-text-white-focus-icon": "#1c4cae",
        "components-button-color-text-white-focus-outline": "#1c4cae",
        "components-button-group-color-border": "#e8e8ea",
        "components-button-group-color-button-bg-default": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-button-fg-default": "#191b1c",
        "components-button-group-color-button-bg-hover": "rgba(25, 27, 28, 0.38)",
        "components-button-group-color-button-fg-hover": "#191b1c",
        "components-button-group-color-button-bg-active": "#191b1c",
        "components-button-group-color-button-fg-active": "#ffffff",
        "components-button-group-color-overlay-left-gradient-fill-1": "#ffffff",
        "components-button-group-color-overlay-left-gradient-fill-2": "rgba(255, 255, 255, 0.50)",
        "components-button-group-color-stroke-default-gradient-fill-1": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-2": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-3": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-4": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-1": "rgba(25, 27, 28, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-2": "rgba(25, 27, 28, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-3": "rgba(25, 27, 28, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-4": "rgba(25, 27, 28, 0.00)",
        "components-button-group-color-stroke-active-gradient-fill-1": "#191b1c",
        "components-button-group-color-stroke-active-gradient-fill-2": "#191b1c",
        "components-button-group-color-stroke-active-gradient-fill-3": "#191b1c",
        "components-button-group-color-stroke-active-gradient-fill-4": "#191b1c",
        "components-category-card-color-bg": "#f4f4f5",
        "components-category-card-color-content-bg": "#ffffff",
        "components-category-card-color-category-bg": "#ffffff",
        "components-category-card-color-category-bg-2": "#000000",
        "components-category-card-color-category-bg-3": "#27282b",
        "components-category-card-color-category-bg-4": "#72757e",
        "components-category-card-color-category-bg-5": "#4e5056",
        "components-category-card-color-category-bg-6": "#72757e",
        "components-category-card-color-title": "#191b1c",
        "components-category-card-color-copy": "#27282b",
        "components-category-card-color-stroke": "#e8e8ea",
        "components-category-card-color-stroke-hover": "#191b1c",
        "components-category-card-listing-color-bg": "#ffffff",
        "components-category-card-listing-color-title": "#191b1c",
        "components-category-card-listing-color-copy": "#27282b",
        "components-card-search-result-color-bg": "#ffffff",
        "components-card-search-result-color-label": "#27282b",
        "components-card-search-result-color-title": "#191b1c",
        "components-card-search-result-color-body": "#27282b",
        "components-card-search-result-color-divider-line": "#e8e8ea",
        "components-contact-us-promo-color-light-bg": "#f4f4f5",
        "components-contact-us-promo-color-light-title": "#191b1c",
        "components-contact-us-promo-color-light-body": "#27282b",
        "components-contact-us-promo-color-default-bg": "#ffffff",
        "components-contact-us-promo-color-default-title": "#191b1c",
        "components-contact-us-promo-color-default-body": "#27282b",
        "components-contact-us-promo-color-brand-bg": "#008533",
        "components-contact-us-promo-color-brand-title": "#ffffff",
        "components-contact-us-promo-color-brand-body": "#fcfcfd",
        "components-content-list-color-bg": "#ffffff",
        "components-content-list-color-title": "#191b1c",
        "components-content-list-color-body": "#27282b",
        "components-content-list-color-icon": "#008533",
        "components-cookies-colors-bg": "#ffffff",
        "components-cookies-colors-title": "#191b1c",
        "components-cookies-colors-body": "#27282b",
        "components-cookies-colors-icon": "#e1251b",
        "components-dropdown-bg": "#ffffff",
        "components-dropdown-border-default": "#72757e",
        "components-dropdown-border-hover": "#4e5056",
        "components-dropdown-border-focused": "#191b1c",
        "components-dropdown-border-destructive": "#b32b30",
        "components-dropdown-label-text-default": "#191b1c",
        "components-dropdown-item-text-default": "#191b1c",
        "components-dropdown-item-bg-default": "#ffffff",
        "components-dropdown-item-bg-hover": "#e8e8ea",
        "components-dropdown-input-field-default": "#72757e",
        "components-dropdown-input-field-focused": "#191b1c",
        "components-dropdown-input-label-focused": "#191b1c",
        "components-dropdown-input-field-destructive": "#b32b30",
        "components-external-link-card-bg": "#f4f4f5",
        "components-external-link-card-card-bg": "#ffffff",
        "components-external-link-card-card-title": "#27282b",
        "components-external-link-card-card-subtitle": "#27282b",
        "components-external-link-card-card-eyebrow": "#4e5056",
        "components-fab-color-border-default": "#ffffff",
        "components-footer-brand-color-bg": "#000000",
        "components-footer-brand-color-logo": "#ffffff",
        "components-footer-brand-color-title-text": "#ffffff",
        "components-footer-brand-color-description-text": "#ffffff",
        "components-footer-brand-color-footer-menu-expanded": "#000000",
        "components-footer-brand-color-link-default": "#ffffff",
        "components-footer-brand-color-link-hover": "#e8e8ea",
        "components-footer-brand-color-link-pressed": "#e8e8ea",
        "components-footer-brand-color-category-link-default": "#ffffff",
        "components-footer-brand-color-category-link-hover": "#e8e8ea",
        "components-footer-brand-color-category-link-pressed": "#e8e8ea",
        "components-footer-brand-color-subcategory-link-default": "#ffffff",
        "components-footer-brand-color-subcategory-link-hover": "#e8e8ea",
        "components-footer-brand-color-subcategory-link-pressed": "#e8e8ea",
        "components-footer-brand-color-icon-icon-default": "#ffffff",
        "components-footer-brand-color-icon-icon-pressed": "#e8e8ea",
        "components-footer-brand-color-social-icon-default": "#ffffff",
        "components-footer-brand-color-social-icon-hover": "#e8e8ea",
        "components-footer-brand-color-social-icon-pressed": "#e8e8ea",
        "components-footer-brand-spacing-category-link-hover": "#000000",
        "components-footer-global-color-bg": "#ffffff",
        "components-footer-global-color-title-text": "#000000",
        "components-footer-global-color-disclaimer-text": "#000000",
        "components-footer-global-color-link-default": "#000000",
        "components-footer-global-color-link-hover": "#000000",
        "components-footer-global-color-link-pressed": "#000000",
        "components-generic-card-color-bg": "#fcfcfd",
        "components-generic-card-color-content-bg": "#fcfcfd",
        "components-generic-card-color-title": "#191b1c",
        "components-generic-card-color-subtitle": "#27282b",
        "components-generic-card-color-copy": "#27282b",
        "components-global-search-color-omnibox-list-bg": "#ffffff",
        "components-global-search-color-omnibox-divider": "#a2a5ab",
        "components-global-search-color-omnibox-item-bg-default": "#ffffff",
        "components-global-search-color-omnibox-item-bg-hover": "#fcfcfd",
        "components-global-search-color-omnibox-item-text-default": "#008533",
        "components-global-search-color-omnibox-item-text-hover": "#006a29",
        "components-global-search-color-omnibox-label-text-default": "#4e5056",
        "components-global-search-color-omnibox-category-text-default": "#4e5056",
        "components-generic-card-listing-color-bg": "#ffffff",
        "components-generic-card-listing-color-title": "#191b1c",
        "components-generic-card-listing-color-copy": "#27282b",
        "components-header-color-bg": "#ffffff",
        "components-header-color-bottom-stroke": "#e8e8ea",
        "components-header-color-search-search-border-default": "#191b1c",
        "components-header-color-search-search-text": "#4e5056",
        "components-header-color-search-search-icon": "#4e5056",
        "components-header-color-search-search-bar": "#191b1c",
        "components-header-color-mobile-mobile-expanded-bg": "#ffffff",
        "components-header-color-mobile-pressed-bg": "#e8e8ea",
        "components-header-color-language-selector-language": "#4e5056",
        "components-header-color-language-selector-country": "#191b1c",
        "components-header-color-language-selector-country-hover-bg": "#e8e8ea",
        "components-header-color-language-selector-megamenu-language": "#4e5056",
        "components-header-color-language-selector-megamenu-country": "#191b1c",
        "components-header-color-language-selector-megamenu-country-hover": "#e8e8ea",
        "components-header-color-language-selector-bg": "#ffffff",
        "components-header-color-language-selector-stroke": "#72757e",
        "components-header-color-mega-menu-bg": "#ffffff",
        "components-header-color-mega-menu-featured-bg": "#4e5056",
        "components-header-color-mega-menu-featured-title": "#ffffff",
        "components-header-color-mega-menu-category-title": "#27282b",
        "components-header-color-mega-menu-bg-accent": "#ffffff",
        "components-header-color-icon-icon-default": "#008533",
        "components-header-color-icon-icon-hover": "#006a29",
        "components-header-color-icon-icon-pressed": "#000000",
        "components-header-color-links-nav-main-link-default": "#008533",
        "components-header-color-links-nav-main-link-hover": "#006a29",
        "components-header-color-links-nav-main-link-pressed": "#004c1d",
        "components-header-color-links-nav-main-link-active": "#000000",
        "components-header-color-links-nav-main-active-bar": "#e1251b",
        "components-header-color-links-nav-main-bg-color": "#ffffff",
        "components-header-color-links-nav-category-link-category-default": "#008533",
        "components-header-color-links-nav-category-link-category-hover": "#006a29",
        "components-header-color-links-nav-category-link-category-pressed": "#004c1d",
        "components-header-color-links-nav-category-link-category-active": "#000000",
        "components-header-color-links-nav-menu-link-link-default": "#008533",
        "components-header-color-links-nav-menu-link-link-hover": "#006a29",
        "components-header-color-links-nav-menu-link-link-pressed": "#004c1d",
        "components-header-color-links-nav-menu-link-link-active": "#000000",
        "components-header-color-links-nav-menu-link-bg": "#ffffff",
        "components-header-color-links-nav-subcategory-link-subcategory-default": "#008533",
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": "#004c1d",
        "components-header-color-links-nav-subcategory-link-subcategory-active": "#000000",
        "components-hero-homepage-color-bg": "#ffffff",
        "components-hero-homepage-color-container-bg": "#4e5056",
        "components-hero-homepage-color-eyebrow": "#72757e",
        "components-hero-homepage-color-header": "#e1251b",
        "components-hero-homepage-color-sub-header": "#27282b",
        "components-hero-homepage-color-body": "#27282b",
        "components-hero-homepage-color-play-pause": "#000000",
        "components-hero-internal-color-default-title": "#ffffff",
        "components-hero-internal-color-default-body": "#fcfcfd",
        "components-hero-internal-color-default-bg": "#e1251b",
        "components-hero-internal-color-accent-bg": "#e1251b",
        "components-hero-internal-color-with-image-bg": "#ffffff",
        "components-hero-internal-color-default-bg-2": "#e1251b",
        "components-hero-internal-color-with-image-title": "#e1251b",
        "components-hero-internal-color-with-image-body": "#27282b",
        "components-heading-page-title-color-default-bg": "#ffffff",
        "components-heading-page-title-color-default-title": "#e1251b",
        "components-heading-page-title-color-default-body": "#27282b",
        "components-heading-page-title-color-light-bg": "#f4f4f5",
        "components-heading-page-title-color-light-title": "#191b1c",
        "components-heading-page-title-color-light-body": "#27282b",
        "components-heading-page-title-color-color-bg": "#008533",
        "components-heading-page-title-color-color-title": "#ffffff",
        "components-heading-page-title-color-color-body": "#fcfcfd",
        "components-hyperlink-color-dark-default": "#008533",
        "components-hyperlink-color-dark-hover": "#006a29",
        "components-hyperlink-color-dark-visited": "#4e5056",
        "components-hyperlink-color-light-default": "#7bc3fe",
        "components-hyperlink-color-light-hover": "#0a8aed",
        "components-hyperlink-color-light-visited": "#f0f7ff",
        "components-icon-default": "#72757e",
        "components-icon-focused": "#72757e",
        "components-icon-card-color-bg": "#ffffff",
        "components-icon-card-color-icon-bg": "#e1251b",
        "components-icon-card-color-icon": "#191b1c",
        "components-icon-card-color-title": "#191b1c",
        "components-icon-card-color-subtitle": "#27282b",
        "components-icon-card-color-description": "#27282b",
        "components-icon-card-listing-color-bg": "#ffffff",
        "components-icon-card-listing-color-inner-bg": "#ffffff",
        "components-icon-card-listing-color-brand-bg": "#e1251b",
        "components-icon-card-listing-color-title": "#191b1c",
        "components-icon-card-listing-color-copy": "#27282b",
        "components-icon-card-listing-color-inner-legal-text": "#fcfcfd",
        "components-jumplink-color-bg-default": "#ffffff",
        "components-jumplink-color-link-text-default": "#4e5056",
        "components-jumplink-color-link-text-hover": "#7a140f",
        "components-jumplink-color-link-border-hover": "#7a140f",
        "components-jumplink-color-link-text-active": "#000000",
        "components-jumplink-color-link-border-active": "#e1251b",
        "components-jumplink-color-title-text": "#191b1c",
        "components-jumplink-color-description-text": "#27282b",
        "components-layout-bg-default": "#ffffff",
        "components-lightbox-color-overlay-bg": "rgba(255, 255, 255, 0.95)",
        "components-lightbox-color-title": "#191b1c",
        "components-lightbox-color-body": "#27282b",
        "components-label-color-on-color-label-label-default": "#191b1c",
        "components-label-color-on-color-label-label-hover": "#4e5056",
        "components-label-color-on-color-label-label-pressed": "#27282b",
        "components-label-color-on-color-background-bg-default": "rgba(114, 117, 126, 0.50)",
        "components-label-color-on-color-background-bg-hover": "#ffffff",
        "components-label-color-on-color-background-bg-pressed": "#72757e",
        "components-label-color-on-color-stroke-hover": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-color-stroke-pressed": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-color-stroke-default-gradient-fill-1": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-color-stroke-default-gradient-fill-2": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-color-stroke-default-gradient-fill-3": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-color-stroke-default-gradient-fill-4": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-white-label-label-default": "#27282b",
        "components-label-color-on-white-label-label-hover": "#fcfcfd",
        "components-label-color-on-white-label-label-pressed": "#fcfcfd",
        "components-label-color-on-white-background-bg-default": "#d3d3d5",
        "components-label-color-on-white-background-bg-hover": "#4e5056",
        "components-label-color-on-white-background-bg-pressed": "#4e5056",
        "components-label-color-on-white-stroke-hover": "#4e5056",
        "components-label-color-on-white-stroke-pressed": "#4e5056",
        "components-label-color-on-white-stroke-default-gradient-fill-1": "rgba(211, 211, 213, 0.00)",
        "components-label-color-on-white-stroke-default-gradient-fill-2": "rgba(211, 211, 213, 0.00)",
        "components-label-color-on-white-stroke-default-gradient-fill-3": "rgba(211, 211, 213, 0.00)",
        "components-label-color-on-white-stroke-default-gradient-fill-4": "rgba(211, 211, 213, 0.00)",
        "components-legal-disclaimer-color-default-bg": "#ffffff",
        "components-legal-disclaimer-color-default-title": "#191b1c",
        "components-legal-disclaimer-color-default-body": "#27282b",
        "components-legal-disclaimer-color-light-bg": "#f4f4f5",
        "components-legal-disclaimer-color-light-title": "#191b1c",
        "components-legal-disclaimer-color-light-body": "#27282b",
        "components-legal-disclaimer-color-brand-bg": "#191b1c",
        "components-legal-disclaimer-color-brand-title": "#ffffff",
        "components-legal-disclaimer-color-brand-body": "#fcfcfd",
        "components-media-video-thumbnail-overlay-bg": "rgba(25, 27, 28, 0.80)",
        "components-media-caption-color-bg": "#fcfcfd",
        "components-media-caption-color-text": "#27282b",
        "components-media-gallery-color-bg": "#f4f4f5",
        "components-media-gallery-color-title": "#191b1c",
        "components-media-gallery-color-body": "#27282b",
        "components-pagination-on-white-accent-default": "#27282b",
        "components-pagination-on-white-accent-scroll": "#27282b",
        "components-pagination-on-white-disabled": "#a2a5ab",
        "components-pagination-on-white-bg": "#e8e8ea",
        "components-pagination-on-white-stroke": "#e8e8ea",
        "components-pagination-on-white-bg-active": "#191b1c",
        "components-pagination-on-white-bg-hover": "#e8e8ea",
        "components-pagination-on-white-text-default": "#72757e",
        "components-pagination-on-white-text-hover": "#27282b",
        "components-pagination-on-white-text-active": "#ffffff",
        "components-pagination-on-color-accent-default": "#ffffff",
        "components-pagination-on-color-accent-scroll": "#e8e8ea",
        "components-pagination-on-color-disabled": "#5a0f0b",
        "components-pagination-on-color-bg": "rgba(39, 40, 43, 0.80)",
        "components-pagination-on-color-stroke": "rgba(255, 255, 255, 0.38)",
        "components-pagination-on-color-bg-active": "#ffffff",
        "components-pagination-on-color-bg-hover": "#27282b",
        "components-pagination-on-color-text-default": "#ffffff",
        "components-pagination-on-color-text-hover": "#ffffff",
        "components-pagination-on-color-text-active": "#191b1c",
        "components-pdf-link-card-color-bg": "#ffffff",
        "components-pdf-link-card-color-card-bg": "#ffffff",
        "components-pdf-link-card-color-card-title": "#191b1c",
        "components-pdf-link-card-color-card-subtitle": "#27282b",
        "components-pdf-link-card-color-card-body": "#27282b",
        "components-product-card-color-bg": "#ffffff",
        "components-product-card-color-content-bg": "#ffffff",
        "components-product-card-color-border": "#e8e8ea",
        "components-product-card-color-title": "#191b1c",
        "components-product-card-color-description": "#27282b",
        "components-product-card-color-star-rating": "#008533",
        "components-product-card-color-badge-bg": "#e1251b",
        "components-product-card-color-badge-text": "#ffffff",
        "components-product-card-listing-color-bg": "#008533",
        "components-product-card-listing-color-title": "#ffffff",
        "components-product-card-listing-color-copy": "#fcfcfd",
        "components-product-card-listing-color-legal-text": "#fcfcfd",
        "components-product-information-color-bg": "#ffffff",
        "components-product-information-color-content-bg": "#ffffff",
        "components-product-information-color-headline": "#191b1c",
        "components-product-information-color-body": "#27282b",
        "components-product-information-color-detail-text": "#27282b",
        "components-product-information-color-divider": "#a2a5ab",
        "components-product-information-color-icon": "#e1251b",
        "components-product-information-color-play-icon": "#ffffff",
        "components-product-information-color-selected-stroke": "#008533",
        "components-product-results-list-with-search-color-bg": "#ffffff",
        "components-promo-color-default-bg": "#ffffff",
        "components-promo-color-default-image-bg": "#ffffff",
        "components-promo-color-default-content-bg": "#ffffff",
        "components-promo-color-default-title": "#191b1c",
        "components-promo-color-default-body": "#27282b",
        "components-promo-color-default-category": "#191b1c",
        "components-promo-color-light-bg": "#f4f4f5",
        "components-promo-color-light-image-bg": "#f4f4f5",
        "components-promo-color-light-content-bg": "#f4f4f5",
        "components-promo-color-light-title": "#191b1c",
        "components-promo-color-light-body": "#27282b",
        "components-promo-color-light-category": "#191b1c",
        "components-promo-color-brand-bg": "#e1251b",
        "components-promo-color-brand-image-bg": "#e1251b",
        "components-promo-color-brand-content-bg": "#e1251b",
        "components-promo-color-brand-title": "#ffffff",
        "components-promo-color-brand-body": "#fcfcfd",
        "components-promo-color-brand-category": "#ffffff",
        "components-pull-quote-color-bg": "#ffffff",
        "components-pull-quote-color-stroke": "#e1251b",
        "components-pull-quote-color-quote-bg": "#ffffff",
        "components-pull-quote-color-quote-title": "#191b1c",
        "components-pull-quote-color-quote-by-text": "#191b1c",
        "components-pull-quote-color-quote-detail": "#006a29",
        "components-pull-quote-color-quote-highlight": "#e1251b",
        "components-promo-alternate-color-default-bg": "#ffffff",
        "components-promo-alternate-color-default-image-bg": "#ffffff",
        "components-promo-alternate-color-default-content-bg": "#ffffff",
        "components-promo-alternate-color-default-title": "#191b1c",
        "components-promo-alternate-color-default-body": "#27282b",
        "components-promo-alternate-color-default-category": "#191b1c",
        "components-promo-alternate-color-light-bg": "#f4f4f5",
        "components-promo-alternate-color-light-image-bg": "#f4f4f5",
        "components-promo-alternate-color-light-content-bg": "#f4f4f5",
        "components-promo-alternate-color-light-title": "#191b1c",
        "components-promo-alternate-color-light-body": "#27282b",
        "components-promo-alternate-color-light-category": "#191b1c",
        "components-promo-alternate-color-brand-bg": "#e1251b",
        "components-promo-alternate-color-brand-image-bg": "#e1251b",
        "components-promo-alternate-color-brand-content-bg": "#e1251b",
        "components-promo-alternate-color-brand-title": "#ffffff",
        "components-promo-alternate-color-brand-body": "#fcfcfd",
        "components-promo-alternate-color-brand-category": "#ffffff",
        "components-share-list-color-title": "#ffffff",
        "components-share-list-color-bg": "#27282b",
        "components-share-list-color-link-default": "#fcfcfd",
        "components-share-list-color-link-hover": "#e1251b",
        "components-share-list-color-bg-link-hover": "#191b1c",
        "components-share-list-color-close-icon": "#ffffff",
        "components-share-list-color-facebook-icon": "#3b5998",
        "components-share-list-color-pinterest-icon": "#e60023",
        "components-share-list-color-twitter-icon": "#ffffff",
        "components-share-list-color-basic-icon-default": "#ffffff",
        "components-share-list-color-icon-hover": "#e1251b",
        "components-share-list-color-border": "#191b1c",
        "components-site-search-bg": "#ffffff",
        "components-site-search-results_text": "#27282b",
        "components-site-search-filter-filter-selected-bg": "#fce9e8",
        "components-site-search-facet-group-label-bottom-line": "#e8e8ea",
        "components-site-search-facet-group-label-text": "#27282b",
        "components-site-search-facet-group-label-bg": "#ffffff",
        "components-site-search-facet-bg": "#ffffff",
        "components-site-search-facet-stroke": "#e8e8ea",
        "components-site-search-refine-bg": "#ffffff",
        "components-site-search-refine-right-divider": "#f4f4f5",
        "components-site-search-refine-title": "#191b1c",
        "components-site-search-hero-search-bg": "#e1251b",
        "components-site-search-hero-search-accent-bg": "#191b1c",
        "components-site-search-hero-search-title": "#ffffff",
        "components-stats-stat-text": "#e1251b",
        "components-stats-stat-text-on-light": "#e1251b",
        "components-stats-supporting-text": "#27282b",
        "components-stats-bg-default": "#ffffff",
        "components-stats-bg-inner": "#ffffff",
        "components-stats-bg-section-inner": "#e1251b",
        "components-stats-title": "#191b1c",
        "components-stats-copy": "#27282b",
        "components-supporting-text-default": "#4e5056",
        "components-supporting-text-destructive": "#b32b30",
        "components-tab-bg": "#ffffff",
        "components-tab-border-default": "#72757e",
        "components-tab-border-hover": "#e1251b",
        "components-tab-border-focused": "#191b1c",
        "components-tab-border-destructive": "#b32b30",
        "components-tab-label-text-default": "#191b1c",
        "components-tab-item-text-default": "#191b1c",
        "components-tab-item-bg-default": "#ffffff",
        "components-tab-item-bg-hover": "#fce9e8",
        "components-tab-input-field-default": "#72757e",
        "components-tab-input-field-focused": "#191b1c",
        "components-tab-input-label-focused": "#191b1c",
        "components-tab-input-field-destructive": "#b32b30",
        "components-tab-bg-2": "#f4f4f5",
        "components-tag-color-bg": "#ffffff",
        "components-tag-color-text": "#4e5056",
        "components-tag-color-stroke": "#72757e",
        "components-tag-color-bg-hover": "#4e5056",
        "components-tag-color-text-hover": "#ffffff",
        "components-tag-color-stroke-hover": "#4e5056",
        "components-text-field-bg": "#ffffff",
        "components-text-field-bg-focused": "#fcfcfd",
        "components-text-field-border-default": "#191b1c",
        "components-text-field-border-hover": "#72757e",
        "components-text-field-border-focused": "#191b1c",
        "components-text-field-border-destructive": "#b32b30",
        "components-text-field-input-field-default": "#4e5056",
        "components-text-field-input-field-focused": "#191b1c",
        "components-text-field-input-label-focused": "#191b1c",
        "components-text-field-input-field-destructive": "#b32b30",
        "components-text-field-icon-default": "#4e5056",
        "components-text-field-icon-destructive": "#b32b30",
        "components-text-field-supporting-text-default": "#4e5056",
        "components-text-field-supporting-text-destructive": "#b32b30",
        "components-tip-color-bg": "#fcfcfd",
        "components-tip-color-title": "#191b1c",
        "components-tip-color-body": "#27282b",
        "components-tip-color-stroke": "#e8e8ea",
        "components-video-color-bg": "#ffffff",
        "themes-general-surface-color-light-bg": "#ffffff",
        "themes-general-surface-color-light-title": "#191b1c",
        "themes-general-surface-color-light-body": "#27282b",
        "themes-general-surface-color-light-decor-line-break": "#72757e",
        "themes-general-surface-color-light-rule-line": "#a2a5ab",
        "themes-general-surface-color-dark-bg": "#e1251b",
        "themes-general-surface-color-dark-title": "#ffffff",
        "themes-general-surface-color-dark-body": "#fcfcfd",
        "themes-general-surface-color-dark-decor-line-break": "#72757e",
        "themes-general-card-color-dark-title": "#191b1c",
        "themes-general-card-color-dark-subtitle": "#27282b",
        "themes-general-card-color-dark-body": "#27282b",
        "themes-general-card-color-light-title": "#ffffff",
        "themes-general-card-color-light-subtitle": "#fcfcfd",
        "themes-general-card-color-light-body": "#fcfcfd",
        "themes-shadow-color": "#ffffff",
        "themes-shadow-share-list-color-fill": "rgba(0, 0, 0, 0.40)",
        "themes-shadow-header-menu-color-fill": "rgba(0, 0, 0, 0.20)",
        "themes-gradient-loader-gradient-fill-1": "#191b1c",
        "themes-gradient-loader-gradient-fill-2": "rgba(25, 27, 28, 0.00)",
        "themes-color-background-white": "#ffffff",
        "themes-color-background-light": "#f4f4f5",
        "themes-color-background-dark": "#27282b",
        "themes-color-background-black": "#191b1c",
        "themes-color-background-brand1": "#e1251b",
        "themes-color-background-brand2": "#72757e",
        "themes-color-background-accent1": "#005eb5",
        "themes-color-background-accent2": "#008533",
        "themes-color-background-body": "#ffffff",
        "themes-color-stroke-white": "#ffffff",
        "themes-color-stroke-light": "#e8e8ea",
        "themes-color-stroke-dark": "#27282b",
        "themes-color-stroke-black": "#191b1c",
        "themes-color-stroke-brand1": "#e1251b",
        "themes-color-stroke-brand2": "#72757e",
        "themes-color-stroke-accent1": "#005eb5",
        "themes-color-stroke-accent2": "#008533",
        "themes-color-text-white": "#ffffff",
        "themes-color-text-light": "#fcfcfd",
        "themes-color-text-dark": "#27282b",
        "themes-color-text-black": "#191b1c",
        "themes-color-text-brand1": "#e1251b",
        "themes-color-text-brand2": "#4e5056",
        "themes-color-text-accent1": "#0a3d70",
        "themes-color-text-accent2": "#00260f",
        "colors-grayscale-100": "#fcfcfd",
        "colors-grayscale-200": "#e8e8ea",
        "colors-grayscale-300": "#a2a5ab",
        "colors-grayscale-400": "#72757e",
        "colors-grayscale-500": "#4e5056",
        "colors-grayscale-600": "#27282b",
        "colors-grayscale-700": "#191b1c",
        "colors-brand-1-100": "#fce9e8",
        "colors-brand-1-200": "#f7bdba",
        "colors-brand-1-300": "#e94f47",
        "colors-brand-1-400": "#e1251b",
        "colors-brand-1-500": "#b41e16",
        "colors-brand-1-600": "#7a140f",
        "colors-brand-1-700": "#5a0f0b",
        "colors-brand-2-100": "#fcfcfd",
        "colors-brand-2-200": "#e8e8ea",
        "colors-brand-2-300": "#a2a5ab",
        "colors-brand-2-400": "#72757e",
        "colors-brand-2-500": "#4e5056",
        "colors-brand-2-600": "#27282b",
        "colors-brand-2-700": "#191b1c",
        "colors-accent-1-100": "#f0f7ff",
        "colors-accent-1-200": "#7bc3fe",
        "colors-accent-1-300": "#0a8aed",
        "colors-accent-1-400": "#005eb5",
        "colors-accent-1-500": "#004b9b",
        "colors-accent-1-600": "#0a3d70",
        "colors-accent-1-700": "#07274a",
        "colors-accent-2-100": "#dbffe9",
        "colors-accent-2-200": "#a9eac2",
        "colors-accent-2-300": "#59cf86",
        "colors-accent-2-400": "#008533",
        "colors-accent-2-500": "#006a29",
        "colors-accent-2-600": "#004c1d",
        "colors-accent-2-700": "#00260f",
        "colors-accent-3-100": "#fcfcfd",
        "colors-accent-3-200": "#e8e8ea",
        "colors-accent-3-300": "#a2a5ab",
        "colors-accent-3-400": "#72757e",
        "colors-accent-3-500": "#4e5056",
        "colors-accent-3-600": "#27282b",
        "colors-accent-3-700": "#191b1c",
        "colors-accent-4-400_storage": "#000000",
        "colors-accessible-accent-1-100": "#f0f7ff",
        "colors-accessible-accent-1-200": "#7bc3fe",
        "colors-accessible-accent-1-300": "#0a8aed",
        "colors-accessible-accent-1-400": "#005eb5",
        "colors-accessible-accent-1-500": "#004b9b",
        "colors-accessible-accent-1-600": "#0a3d70",
        "colors-accessible-accent-1-700": "#07274a",
        "colors-accessible-accent-2-100": "#dbffe9",
        "colors-accessible-accent-2-200": "#a9eac2",
        "colors-accessible-accent-2-300": "#59cf86",
        "colors-accessible-accent-2-400": "#008533",
        "colors-accessible-accent-2-500": "#006a29",
        "colors-accessible-accent-2-600": "#004c1d",
        "colors-accessible-accent-2-700": "#00260f",
        "colors-accessible-brand-2-100": "#fcfcfd",
        "colors-accessible-brand-2-200": "#e8e8ea",
        "colors-accessible-brand-2-300": "#a2a5ab",
        "colors-accessible-brand-2-400": "#72757e",
        "colors-accessible-brand-2-500": "#4e5056",
        "colors-accessible-brand-2-600": "#27282b",
        "colors-accessible-brand-2-700": "#191b1c"
      },
      "spacing": {
        "components-accordion-spacing-large-padding-x": "5rem",
        "components-accordion-spacing-large-icon-margin-right": "2rem",
        "components-accordion-spacing-large-copy-margin-right": "2.5rem",
        "components-accordion-spacing-large-maxwidth": "59.625rem",
        "components-accordion-spacing-large-section-padding-x": "0rem",
        "components-accordion-spacing-large-section-padding-y": "2.5rem",
        "components-accordion-spacing-large-section-copy-margin-bottom": "2.5rem",
        "components-accordion-spacing-large-section-space-between": "4rem",
        "components-accordion-spacing-large-item-padding-x": "0rem",
        "components-accordion-spacing-large-item-padding-y": "1.5rem",
        "components-accordion-spacing-large-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-large-content-padding-x": "0rem",
        "components-accordion-spacing-large-content-padding-y": "1.5rem",
        "components-accordion-spacing-large-content-title-margin-bottom": "2rem",
        "components-accordion-spacing-large-content-content-space-between": "1.5rem",
        "components-accordion-spacing-large-content-content-margin-left": "1rem",
        "components-accordion-spacing-small-padding-top": "1rem",
        "components-accordion-spacing-small-padding-x": "1.5rem",
        "components-accordion-spacing-small-padding-y": "2.5rem",
        "components-accordion-spacing-small-icon-margin-right": "0.75rem",
        "components-accordion-spacing-small-title-margin-bottom": "0.5rem",
        "components-accordion-spacing-small-copy-margin-right": "2rem",
        "components-accordion-spacing-small-copy-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-padding-x": "0rem",
        "components-accordion-spacing-small-section-padding-y": "0rem",
        "components-accordion-spacing-small-section-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-space-between": "2.5rem",
        "components-accordion-spacing-small-item-padding-x": "0rem",
        "components-accordion-spacing-small-item-padding-y": "1rem",
        "components-accordion-spacing-small-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-small-content-padding-x": "0rem",
        "components-accordion-spacing-small-content-padding-y": "0.75rem",
        "components-accordion-spacing-small-content-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-content-content-space-between": "1rem",
        "components-accordion-spacing-small-content-content-margin-left": "1rem",
        "components-article-card-spacing-large-padding-image-x": "0rem",
        "components-article-card-spacing-large-padding-image-y": "0rem",
        "components-article-card-spacing-large-padding-content-x": "0rem",
        "components-article-card-spacing-large-padding-content-y": "1.5rem",
        "components-article-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-article-card-spacing-large-button-space-between": "1rem",
        "components-article-card-spacing-large-title-margin-bottom": "1rem",
        "components-article-card-spacing-large-list-content-gap": "1.5rem",
        "components-article-card-spacing-large-list-image-padding-x": "0rem",
        "components-article-card-spacing-large-list-image-padding-y": "0rem",
        "components-article-card-spacing-small-padding-image-x": "0rem",
        "components-article-card-spacing-small-padding-image-y": "0rem",
        "components-article-card-spacing-small-padding-content-x": "1.5rem",
        "components-article-card-spacing-small-padding-content-y": "1.5rem",
        "components-article-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-article-card-listing-spacing-small-padding-x": "1.5rem",
        "components-article-card-listing-spacing-small-padding-y": "2.5rem",
        "components-article-hero-spacing-large-padding-x": "0rem",
        "components-article-hero-spacing-large-padding-y": "0rem",
        "components-article-hero-spacing-large-title-margin-bottom": "2rem",
        "components-article-hero-spacing-large-label-share-margin-bottom": "2rem",
        "components-article-hero-spacing-large-author-name-margin-bottom": "0.75rem",
        "components-article-hero-spacing-large-author-thumbnail-margin-left": "1rem",
        "components-article-hero-spacing-large-details-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-space-between": "1rem",
        "components-article-hero-spacing-large-body-margin-bottom": "2rem",
        "components-article-hero-spacing-large-image-space-between": "1rem",
        "components-article-hero-spacing-large-image-margin-bottom": "1rem",
        "components-article-hero-spacing-large-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-stats-margin-bottom": "2.5rem",
        "components-article-hero-spacing-small-content-margin-top": "2.5rem",
        "components-article-hero-spacing-small-title-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-label-share-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-authorname-margin-bottom": "0.75rem",
        "components-article-hero-spacing-small-authorthumbnail-margin-left": "1rem",
        "components-article-hero-spacing-small-details-margin-bottom": "1rem",
        "components-article-hero-spacing-small-space-between": "0.75rem",
        "components-article-hero-spacing-small-body-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-image-space-between": "0.75rem",
        "components-article-hero-spacing-small-image-margin-bottom": "1rem",
        "components-article-hero-spacing-small-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-stats-margin-bottom": "2rem",
        "components-banner-spacing-large-content-padding-x": "1.5rem",
        "components-banner-spacing-large-content-space-between": "1rem",
        "components-banner-spacing-large-content-width": "69.375rem",
        "components-banner-spacing-icon-dimensions": "1.5rem",
        "components-banner-spacing-icon-margin-left": "0.5rem",
        "components-banner-spacing-small-space-between": "1rem",
        "components-banner-spacing-small-content-space-between": "1rem",
        "components-banner-spacing-small-title-margin-bottom": "0.75rem",
        "components-banner-spacing-small-content-margin-bottom": "1rem",
        "components-breadcrumb-spacing-large-space-between": "0.25rem",
        "components-breadcrumb-spacing-small-space-between": "0.25rem",
        "components-button-spacing-large-icon-large-x": "2.5rem",
        "components-button-spacing-large-icon-large-y": "2.5rem",
        "components-button-spacing-large-default-padding-y": "0.75rem",
        "components-button-spacing-large-default-padding-x": "1.5rem",
        "components-button-spacing-large-default-space-between": "0.25rem",
        "components-button-spacing-large-icon-left-padding-left": "1rem",
        "components-button-spacing-large-icon-right-padding-right": "1rem",
        "components-button-spacing-large-icon-only-padding-x": "0.75rem",
        "components-button-spacing-large-icon-only-padding-y": "0.75rem",
        "components-button-spacing-large-tertiary-content-height": "1.5rem",
        "components-button-spacing-compressed-icon-large-x": "2.5rem",
        "components-button-spacing-compressed-icon-large-y": "2.5rem",
        "components-button-spacing-compressed-default-padding-y": "0.5rem",
        "components-button-spacing-compressed-default-padding-x": "1rem",
        "components-button-spacing-compressed-default-space-between": "0.125rem",
        "components-button-spacing-compressed-icon-left-padding-left": "0.5rem",
        "components-button-spacing-compressed-icon-right-padding-right": "0.5rem",
        "components-button-spacing-compressed-icon-only-padding-x": "0.5rem",
        "components-button-group-spacing-padding-y": "0.125rem",
        "components-button-group-spacing-padding-x": "0.25rem",
        "components-button-group-spacing-space-between": "0.5rem",
        "components-category-card-spacing-large-padding-top": "0rem",
        "components-category-card-spacing-large-padding-x": "0rem",
        "components-category-card-spacing-large-padding-y": "0rem",
        "components-category-card-spacing-large-padding-image-x": "0rem",
        "components-category-card-spacing-large-padding-image-y": "0rem",
        "components-category-card-spacing-large-padding-content-x": "2rem",
        "components-category-card-spacing-large-padding-content-y": "2rem",
        "components-category-card-listing-spacing-large-padding-y": "5rem",
        "components-category-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-category-card-listing-spacing-large-card-margin-bottom": "0rem",
        "components-category-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-card-search-result-small-padding-top": "0rem",
        "components-card-search-result-small-padding-x": "0rem",
        "components-card-search-result-small-padding-y": "0rem",
        "components-card-search-result-small-padding-image-x": "0rem",
        "components-card-search-result-small-padding-image-y": "0rem",
        "components-card-search-result-small-padding-content-x": "1.5rem",
        "components-card-search-result-small-padding-content-y": "1.5rem",
        "components-card-search-result-spacing-large-padding-x": "0rem",
        "components-card-search-result-spacing-large-padding-y": "1.5rem",
        "components-card-search-result-spacing-large-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-image-margin-right": "1.5rem",
        "components-card-search-result-spacing-small-padding-x": "1.5rem",
        "components-card-search-result-spacing-small-padding-y": "1.5rem",
        "components-card-search-result-spacing-small-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-image-margin-right": "0.75rem",
        "components-contact-us-promo-spacing-large-padding-x": "5rem",
        "components-contact-us-promo-spacing-large-padding-y": "5rem",
        "components-contact-us-promo-spacing-large-content-margin-right": "5rem",
        "components-contact-us-promo-spacing-large-margin-y": "2.5rem",
        "components-contact-us-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-contact-us-promo-spacing-small-margin-y": "2.5rem",
        "components-content-list-list-item-container-padding-x": "0.75rem",
        "components-content-list-list-item-container-padding-y": "1rem",
        "components-content-list-list-item-content-space-between": "0.5rem",
        "components-content-list-list-item-content-default-padding-y": "0.25rem",
        "components-content-list-list-item-content-hyperlink-padding-y": "0rem",
        "components-content-list-spacing-large-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-large-item-title-margin-bottom": "1rem",
        "components-content-list-spacing-large-item-copy-margin-bottom": "2.5rem",
        "components-content-list-spacing-large-item-icon-copy-margin-top": "0rem",
        "components-content-list-spacing-large-item-margin-bottom": "2rem",
        "components-content-list-spacing-large-item-image-margin-bottom": "0.5rem",
        "components-content-list-spacing-large-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-y": "2.5rem",
        "components-content-list-spacing-small-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-small-item-title-margin-bottom": "0.5rem",
        "components-content-list-spacing-small-item-copy-margin-bottom": "1rem",
        "components-content-list-spacing-small-item-margin-bottom": "1.5rem",
        "components-content-list-spacing-small-header-title-margin-bottom": "1rem",
        "components-content-list-spacing-small-header-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-title-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-button-space-between": "1rem",
        "components-dropdown-item-padding-y": "0.75rem",
        "components-dropdown-item-padding-x": "0.75rem",
        "components-dropdown-item-spacing-x": "0.75rem",
        "components-dropdown-input-padding-y": "0.25rem",
        "components-dropdown-input-padding-x": "1rem",
        "components-footer-brand-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-large-column-margin-right": "4rem",
        "components-footer-brand-spacing-large-column-margin-bottom": "4rem",
        "components-footer-brand-spacing-large-link-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-padding-y": "2.5rem",
        "components-footer-brand-spacing-small-padding-x": "1rem",
        "components-footer-brand-spacing-small-logo-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-column-margin-right": "0.125rem",
        "components-footer-brand-spacing-small-column-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-small-link-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-title-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-text-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-footer-link-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-link-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-link-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-link-minheight": "3rem",
        "components-footer-brand-spacing-small-footer-category-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-category-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-category-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-category-minheight": "3rem",
        "components-footer-global-spacing-large-padding-y": "2.5rem",
        "components-footer-global-spacing-large-padding-x": "5rem",
        "components-footer-global-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-global-spacing-large-column-margin-right": "2rem",
        "components-footer-global-spacing-large-link-margin-bottom": "2rem",
        "components-footer-global-spacing-small-padding-y": "2.5rem",
        "components-footer-global-spacing-small-padding-x": "1rem",
        "components-footer-global-spacing-small-logo-margin-bottom": "1rem",
        "components-footer-global-spacing-small-column-margin-right": "1rem",
        "components-footer-global-spacing-small-link-margin-bottom": "1rem",
        "components-generic-card-spacing-large-padding-x": "0rem",
        "components-generic-card-spacing-large-padding-y": "0rem",
        "components-generic-card-spacing-large-padding-image-x": "0rem",
        "components-generic-card-spacing-large-padding-image-y": "0rem",
        "components-generic-card-spacing-large-padding-content-x": "1.5rem",
        "components-generic-card-spacing-large-padding-content-y": "1.5rem",
        "components-generic-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-generic-card-spacing-large-title-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-button-space-between": "1rem",
        "components-generic-card-spacing-small-padding-x": "0rem",
        "components-generic-card-spacing-small-padding-y": "0rem",
        "components-generic-card-spacing-small-padding-image-x": "0rem",
        "components-generic-card-spacing-small-padding-image-y": "0rem",
        "components-generic-card-spacing-small-padding-content-x": "1.5rem",
        "components-generic-card-spacing-small-padding-content-y": "1.5rem",
        "components-global-search-spacing-omnibox-padding-x": "0.125rem",
        "components-global-search-spacing-omnibox-list-padding-y": "0.5rem",
        "components-global-search-spacing-omnibox-label-padding": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-item-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-height": "3rem",
        "components-generic-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-generic-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-header-shadow-x": "0rem",
        "components-header-spacing-large-padding-y": "1rem",
        "components-header-spacing-large-padding-x": "1.5rem",
        "components-header-spacing-large-links-margin-right": "1.5rem",
        "components-header-spacing-large-icons-space-between": "1.5rem",
        "components-header-spacing-large-logo-margin-right": "2rem",
        "components-header-spacing-large-space-between-search": "0.5rem",
        "components-header-spacing-large-height": "5rem",
        "components-header-spacing-large-language-selector-padding-x": "1rem",
        "components-header-spacing-large-language-selector-padding-y": "0.5rem",
        "components-header-spacing-large-language-selector-space-between": "0.75rem",
        "components-header-spacing-large-language-selector-country-space-between": "0.5rem",
        "components-header-spacing-large-mega-menu-padding-y": "2.5rem",
        "components-header-spacing-large-mega-menu-padding-x": "2.5rem",
        "components-header-spacing-large-mega-menu-column-margin-right": "2.5rem",
        "components-header-spacing-large-mega-menu-category-link-margin-bottom": "1rem",
        "components-header-spacing-large-mega-menu-link-margin-bottom": "0.5rem",
        "components-header-spacing-large-mega-menu-featured-padding-x": "2rem",
        "components-header-spacing-large-mega-menu-featured-padding-y": "2rem",
        "components-header-spacing-large-mega-menu-featured-image-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-title-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-link-space-between": "1.5rem",
        "components-header-spacing-large-mega-menu-title-margin-bottom": "2.5rem",
        "components-header-spacing-large-dropdown-padding-y": "1.5rem",
        "components-header-spacing-large-dropdown-padding-x": "1.5rem",
        "components-header-spacing-large-dropdown-margin-bottom": "0.5rem",
        "components-header-spacing-small-language-selector-padding-x": "1rem",
        "components-header-spacing-small-language-selector-padding-y": "0.5rem",
        "components-header-spacing-small-language-selector-space-between": "0.5rem",
        "components-header-spacing-small-padding-left": "1rem",
        "components-header-spacing-small-padding-right": "1rem",
        "components-header-spacing-small-padding-top": "0.5rem",
        "components-header-spacing-small-padding-bottom": "0.5rem",
        "components-header-spacing-small-nav-main-minheight": "3rem",
        "components-header-spacing-small-nav-main-padding-y": "0.5rem",
        "components-header-spacing-small-nav-main-padding-left": "1.5rem",
        "components-header-spacing-small-nav-main-padding-right": "1rem",
        "components-header-spacing-small-nav-category-minheight": "3rem",
        "components-header-spacing-small-nav-category-padding-left": "1.5rem",
        "components-header-spacing-small-nav-category-padding-right": "0.75rem",
        "components-header-spacing-small-nav-category-padding-y": "0.75rem",
        "components-header-spacing-small-nav-category-space-between": "0rem",
        "components-header-spacing-small-nav-menu-link-minheight": "3rem",
        "components-header-spacing-small-nav-menu-link-padding-right": "1.5rem",
        "components-header-spacing-small-nav-menu-link-padding-left": "2.5rem",
        "components-header-spacing-small-nav-menu-link-padding-y": "0.75rem",
        "components-header-spacing-small-mega-menu-padding-y": "0rem",
        "components-header-spacing-small-mega-menu-padding-x": "0rem",
        "components-header-spacing-small-mega-menu-category-link-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-link-margin-bottom": "0rem",
        "components-header-spacing-small-mega-menu-featured-padding-x": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-padding-y": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-image-margin-bottom": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-title-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-featured-link-space-between": "0.75rem",
        "components-header-spacing-small-mega-menu-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-container-padding": "1rem",
        "components-hero-homepage-spacing-large-padding-x": "4rem",
        "components-hero-homepage-spacing-large-padding-y": "2.5rem",
        "components-hero-homepage-spacing-large-minheight": "34.3125rem",
        "components-hero-homepage-spacing-large-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-margin-bottom": "2rem",
        "components-hero-homepage-spacing-large-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-copy-margin-bottom": "2.5rem",
        "components-hero-homepage-spacing-large-cta-margin-right": "1rem",
        "components-hero-homepage-spacing-large-image-height": "34.3125rem",
        "components-hero-homepage-spacing-large-image-width": "45.75rem",
        "components-hero-homepage-spacing-small-padding-x": "0rem",
        "components-hero-homepage-spacing-small-padding-y": "1.5rem",
        "components-hero-homepage-spacing-small-minheight": "34.3125rem",
        "components-hero-homepage-spacing-small-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-copy-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-image-height": "17.5625rem",
        "components-hero-homepage-spacing-small-image-width": "45.75rem",
        "components-hero-internal-spacing-large-padding-y": "2.5rem",
        "components-hero-internal-spacing-large-padding-right": "10rem",
        "components-hero-internal-spacing-large-title-margin-bottom": "1.5rem",
        "components-hero-internal-spacing-large-button-padding-y": "2rem",
        "components-hero-internal-spacing-large-text-maxwidth": "59.625rem",
        "components-hero-internal-spacing-large-minheight": "18.5rem",
        "components-hero-internal-spacing-small-padding-x": "1.5rem",
        "components-hero-internal-spacing-small-padding-right": "0rem",
        "components-hero-internal-spacing-small-button-padding-y": "1.5rem",
        "components-hero-internal-spacing-small-text-maxwidth": "30rem",
        "components-hero-internal-spacing-small-minheight": "13.5rem",
        "components-icon-card-spacing-large-padding-x": "0rem",
        "components-icon-card-spacing-large-padding-y": "0rem",
        "components-icon-card-spacing-large-icon-margin": "1.5rem",
        "components-icon-card-spacing-large-margin-right": "1.5rem",
        "components-icon-card-spacing-large-title-margin-bottom": "1rem",
        "components-icon-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-icon-card-spacing-large-button-space-between": "1.5rem",
        "components-icon-card-spacing-large-top-space-between": "1rem",
        "components-icon-card-spacing-small-padding-x": "1.5rem",
        "components-icon-card-spacing-small-padding-y": "1.5rem",
        "components-icon-card-spacing-small-icon-margin": "1.5rem",
        "components-icon-card-spacing-small-margin-right": "1.5rem",
        "components-icon-card-spacing-small-title-margin-bottom": "1.5rem",
        "components-icon-card-dimensions-large-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-large-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-large-big-icon-height": "4rem",
        "components-icon-card-dimensions-large-big-icon-width": "4rem",
        "components-icon-card-dimensions-large-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-large-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-large-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-large-tiny-icon-width": "2.5rem",
        "components-icon-card-dimensions-small-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-small-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-small-big-icon-height": "3.5rem",
        "components-icon-card-dimensions-small-big-icon-width": "3.5rem",
        "components-icon-card-dimensions-small-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-small-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-small-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-small-tiny-icon-width": "2.5rem",
        "components-icon-card-listing-spacing-large-padding-inner-x": "4rem",
        "components-icon-card-listing-spacing-large-padding-inner-y": "4rem",
        "components-icon-card-listing-spacing-large-card-space-between": "4rem",
        "components-icon-card-listing-spacing-large-title-margin-bottom": "1.5rem",
        "components-icon-card-listing-spacing-large-card-margin-bottom": "4rem",
        "components-icon-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-x": "1.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-y": "2.5rem",
        "components-icon-card-listing-spacing-small-title-margin-bottom": "1rem",
        "components-icon-card-listing-spacing-small-button-margin-top": "1.5rem",
        "components-jumplink-link-padding-bottom": "0.5rem",
        "components-jumplink-spacing-small-title-area-margin-bottom": "1.5rem",
        "components-jumplink-spacing-small-link-container-padding-x": "0rem",
        "components-lightbox-spacing-large-carousel-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-carousel-space-between": "1.5rem",
        "components-lightbox-spacing-large-featured-image-height": "39.25rem",
        "components-lightbox-spacing-large-featured-image-width": "39.25rem",
        "components-lightbox-spacing-large-image-height": "17.0625rem",
        "components-lightbox-spacing-large-image-width": "17.0625rem",
        "components-lightbox-spacing-small-carousel-margin-bottom": "1rem",
        "components-lightbox-spacing-small-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-small-media-details-margin-top": "1rem",
        "components-lightbox-spacing-small-image-height": "20.4375rem",
        "components-lightbox-spacing-small-image-width": "20.4375rem",
        "components-legal-disclaimer-spacing-small-ruleline-width": "20.4375rem",
        "components-media-spacing-large-padding-y": "0.5rem",
        "components-media-spacing-large-padding-x": "0.75rem",
        "components-media-gallery-large-container-grid-gap-x": "1.5rem",
        "components-media-gallery-large-container-grid-gap-y": "1.5rem",
        "components-media-gallery-small-container-grid-gap-x": "0.5rem",
        "components-media-gallery-small-container-grid-gap-y": "0.5rem",
        "components-media-gallery-spacing-small-padding-x": "1.5rem",
        "components-media-gallery-spacing-small-padding-y": "2.5rem",
        "components-media-gallery-spacing-large-padding-x": "5rem",
        "components-media-gallery-spacing-large-padding-y": "5rem",
        "components-product-card-spacing-list-content-gap": "0rem",
        "components-product-card-spacing-large-padding-image-x": "0rem",
        "components-product-card-spacing-large-padding-image-y": "0rem",
        "components-product-card-spacing-large-padding-content-x": "1.5rem",
        "components-product-card-spacing-large-padding-content-y": "1.5rem",
        "components-product-card-spacing-large-category-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-title-margin-bottom": "1rem",
        "components-product-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-button-space-between": "1rem",
        "components-product-card-spacing-small-padding-image-x": "0rem",
        "components-product-card-spacing-small-padding-image-y": "0rem",
        "components-product-card-spacing-small-padding-content-x": "1.5rem",
        "components-product-card-spacing-small-padding-content-y": "1.5rem",
        "components-product-card-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-large-padding-x": "5rem",
        "components-product-information-spacing-large-padding-y": "2.5rem",
        "components-product-information-spacing-large-title-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-label-margin-bottom": "2.5rem",
        "components-product-information-spacing-large-ratings-margin-bottom": "1rem",
        "components-product-information-spacing-large-details-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-space-between": "1rem",
        "components-product-information-spacing-large-carousel-space-between": "5rem",
        "components-product-information-spacing-large-button-margin-y": "0.5rem",
        "components-product-information-spacing-large-body-margin-top": "1.5rem",
        "components-product-information-spacing-large-image-space-between": "1rem",
        "components-product-information-spacing-large-image-margin-bottom": "1rem",
        "components-product-information-spacing-small-padding-x": "1.5rem",
        "components-product-information-spacing-small-padding-y": "1.5rem",
        "components-product-information-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-small-label-margin-bottom": "1.5rem",
        "components-product-information-spacing-small-ratings-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-details-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-space-between": "0.75rem",
        "components-product-information-spacing-small-carousel-space-between": "2.5rem",
        "components-product-information-spacing-small-button-margin-y": "0.75rem",
        "components-product-information-spacing-small-body-margin-top": "1rem",
        "components-product-information-spacing-small-image-space-between": "0.75rem",
        "components-product-information-spacing-small-image-margin-bottom": "1rem",
        "components-product-results-list-with-search-spacing-large-padding-x": "5rem",
        "components-product-results-list-with-search-spacing-large-padding-y": "5rem",
        "components-product-results-list-with-search-spacing-large-ruleline-margin-top": "2.5rem",
        "components-product-results-list-with-search-spacing-large-filter-space-between": "1rem",
        "components-product-results-list-with-search-spacing-large-card-space-between": "1.5rem",
        "components-product-results-list-with-search-spacing-large-card-container-margin-bottom": "5rem",
        "components-product-results-list-with-search-spacing-small-padding-x": "1.5rem",
        "components-product-results-list-with-search-spacing-small-padding-y": "2.5rem",
        "components-product-results-list-with-search-spacing-small-ruleline-margin-top": "1rem",
        "components-product-results-list-with-search-spacing-small-filter-padding-x": "0.5rem",
        "components-product-results-list-with-search-spacing-small-card-space-between": "1rem",
        "components-product-results-list-with-search-spacing-small-card-margin-bottom": "1.5rem",
        "components-promo-spacing-large-padding-x": "5rem",
        "components-promo-spacing-large-padding-y": "5rem",
        "components-promo-spacing-large-content-space-between": "5rem",
        "components-promo-spacing-large-content-space-between-y": "5rem",
        "components-promo-spacing-large-padding-content-x": "0rem",
        "components-promo-spacing-large-padding-content-condensed-x": "0rem",
        "components-promo-spacing-large-padding-content-y": "0rem",
        "components-promo-spacing-large-padding-content-condensed-y": "0rem",
        "components-promo-spacing-large-padding-image-x": "0rem",
        "components-promo-spacing-large-padding-image-y": "0rem",
        "components-promo-spacing-large-padding-image-top": "5rem",
        "components-promo-spacing-large-title-margin-bottom": "2rem",
        "components-promo-spacing-large-margin-y": "2rem",
        "components-promo-spacing-large-buttons-space-between": "1rem",
        "components-promo-spacing-small-padding-x": "0rem",
        "components-promo-spacing-small-padding-y": "0rem",
        "components-promo-spacing-small-padding-top": "0rem",
        "components-promo-spacing-small-padding-content-x": "1.5rem",
        "components-promo-spacing-small-padding-content-y": "2.5rem",
        "components-promo-spacing-small-padding-image-x": "1.5rem",
        "components-promo-spacing-small-padding-image-y": "0rem",
        "components-promo-spacing-small-padding-image-top": "1.5rem",
        "components-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-promo-spacing-small-margin-y": "2.5rem",
        "components-promo-minheight": "30rem",
        "components-pull-quote-spacing-large-padding-quote-x": "4rem",
        "components-pull-quote-spacing-large-padding-quote-y": "4rem",
        "components-pull-quote-spacing-large-title-margin-bottom": "2rem",
        "components-pull-quote-spacing-large-quote-margin-bottom": "4rem",
        "components-pull-quote-spacing-large-quote-space-between": "1.5rem",
        "components-pull-quote-spacing-large-detail-space-between": "0.5rem",
        "components-pull-quote-spacing-large-quote-minheight": "25rem",
        "components-pull-quote-spacing-small-padding-quote-x": "1.5rem",
        "components-pull-quote-spacing-small-padding-quote-y": "2.5rem",
        "components-pull-quote-spacing-small-title-margin-bottom": "1.5rem",
        "components-pull-quote-spacing-small-quote-margin-bottom": "2.5rem",
        "components-pull-quote-spacing-small-quote-space-between": "1rem",
        "components-pull-quote-spacing-small-detail-space-between": "1rem",
        "components-pull-quote-spacing-small-quote-minheight": "15rem",
        "components-promo-alternate-minheight": "30rem",
        "components-promo-alternate-spacing-large-padding-x": "5rem",
        "components-promo-alternate-spacing-large-padding-y": "5rem",
        "components-promo-alternate-spacing-large-content-space-between": "5rem",
        "components-promo-alternate-spacing-large-padding-content-x": "0rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-x": "0rem",
        "components-promo-alternate-spacing-large-padding-content-y": "0rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-y": "0rem",
        "components-promo-alternate-spacing-large-padding-image-x": "0rem",
        "components-promo-alternate-spacing-large-padding-image-y": "0rem",
        "components-promo-alternate-spacing-large-padding-image-top": "5rem",
        "components-promo-alternate-spacing-large-title-margin-bottom": "2rem",
        "components-promo-alternate-spacing-small-padding-x": "0rem",
        "components-promo-alternate-spacing-small-padding-y": "0rem",
        "components-promo-alternate-spacing-small-padding-content-x": "1.5rem",
        "components-promo-alternate-spacing-small-padding-content-y": "2.5rem",
        "components-promo-alternate-spacing-small-padding-image-x": "1.5rem",
        "components-promo-alternate-spacing-small-padding-image-y": "0rem",
        "components-promo-alternate-spacing-small-padding-image-top": "1.5rem",
        "components-promo-alternate-spacing-small-title-margin-bottom": "1.5rem",
        "components-share-list-spacing-padding-x": "1rem",
        "components-share-list-spacing-padding-y": "1rem",
        "components-header-copy-margin-bottom": "1rem",
        "components-header-margin-bottom": "1.5rem",
        "components-item-margin-bottom": "1.5rem",
        "components-site-search-padding-x": "2.5rem",
        "components-site-search-padding-y": "5rem",
        "components-site-search-facet-label-space-between": "0.25rem",
        "components-site-search-refine-padding-x": "1.5rem",
        "components-site-search-refine-padding-y": "0rem",
        "components-site-search-hero-search-height": "18.5rem",
        "components-site-search-hero-search-large-padding-x": "5rem",
        "components-site-search-hero-search-large-padding-y": "5rem",
        "components-stats-spacing-stat-text-space-between": "0.5rem",
        "components-stats-spacing-large-inner-padding-x": "5rem",
        "components-stats-spacing-large-inner-padding-y": "5rem",
        "components-stats-spacing-small-inner-padding-x": "1.5rem",
        "components-stats-spacing-small-inner-padding-y": "1.5rem",
        "components-icon-card-listing-spacing-small-top-space-between": "1rem",
        "components-supporting-text-padding-x": "0rem",
        "components-supporting-text-padding-bottom": "0.5rem",
        "components-tab-item-padding-y": "0.75rem",
        "components-tab-item-padding-x": "0.75rem",
        "components-tab-item-spacing-x": "0.75rem",
        "components-tab-input-padding-y": "0.25rem",
        "components-tab-input-padding-x": "1rem",
        "components-tag-spacing-padding-x": "0.5rem",
        "components-tag-spacing-padding-y": "0.5rem",
        "components-tag-spacing-space-between": "0.5rem",
        "components-text-field-input-padding-y": "0.25rem",
        "components-text-field-input-padding-x": "1rem",
        "components-text-field-area-input-padding-y": "1rem",
        "components-text-field-area-input-padding-x": "1rem",
        "components-text-field-supporting-text-padding-x": "0rem",
        "components-text-field-supporting-text-padding-top": "0.5rem",
        "themes-general-surface-spacing-large-padding-x": "5rem",
        "themes-general-surface-spacing-large-padding-y": "5rem",
        "themes-general-surface-spacing-large-title-margin-bottom": "2rem",
        "themes-general-surface-spacing-large-body-margin-right": "4rem",
        "themes-general-surface-spacing-large-body-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-large-margin-bottom": "4rem",
        "themes-general-surface-spacing-large-cards-space-between": "1.5rem",
        "themes-general-surface-spacing-large-button-margin-top": "2.5rem",
        "themes-general-surface-spacing-small-padding-x": "1.5rem",
        "themes-general-surface-spacing-small-padding-y": "2.5rem",
        "themes-general-surface-spacing-small-title-margin-bottom": "1rem",
        "themes-general-surface-spacing-small-body-margin-right": "2.5rem",
        "themes-general-surface-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-surface-spacing-small-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-small-cards-space-between": "1rem",
        "themes-general-surface-spacing-small-button-margin-top": "1.5rem",
        "themes-general-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "themes-general-card-spacing-large-title-margin-bottom": "1.5rem",
        "themes-general-card-spacing-large-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-large-button-space-between": "1.5rem",
        "themes-general-card-spacing-small-eyebrow-margin-bottom": "1rem",
        "themes-general-card-spacing-small-title-margin-bottom": "1.5rem",
        "themes-general-card-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-small-button-space-between": "1.5rem",
        "themes-shadow-share-list-spacing-x": "0rem",
        "themes-shadow-share-list-spacing-y": "0.75rem",
        "themes-shadow-share-list-spacing-blur": "1rem",
        "themes-shadow-share-list-spacing-spread": "-0.5rem",
        "themes-shadow-header-menu-spacing-x": "0rem",
        "themes-shadow-header-menu-spacing-y": "0.75rem",
        "themes-shadow-header-menu-spacing-blur": "1rem",
        "themes-shadow-header-menu-spacing-spread": "-0.5rem",
        "themes-typography-rte-large-h1-textsize": "3rem",
        "themes-typography-rte-large-h2-textsize": "2.5rem",
        "themes-typography-rte-large-h3-textsize": "2.25rem",
        "themes-typography-rte-large-h4-textsize": "2rem",
        "themes-typography-rte-large-h5-textsize": "1.5rem",
        "themes-typography-rte-large-h6-textsize": "1.25rem",
        "themes-typography-rte-medium-h1-textsize": "2.5rem",
        "themes-typography-rte-medium-h2-textsize": "2.25rem",
        "themes-typography-rte-medium-h3-textsize": "1.75rem",
        "themes-typography-rte-medium-h4-textsize": "1.5rem",
        "themes-typography-rte-medium-h5-textsize": "1.25rem",
        "themes-typography-rte-medium-h6-textsize": "1.125rem",
        "themes-typography-rte-small-h1-textsize": "2.5rem",
        "themes-typography-rte-small-h2-textsize": "2rem",
        "themes-typography-rte-small-h3-textsize": "1.75rem",
        "themes-typography-rte-small-h4-textsize": "1.5rem",
        "themes-typography-rte-small-h5-textsize": "1.125rem",
        "themes-typography-rte-small-h6-textsize": "1rem",
        "spacing-margin-small-1": "0.125rem",
        "spacing-margin-small-2": "0.25rem",
        "spacing-margin-small-3": "0.5rem",
        "spacing-margin-small-4": "0.75rem",
        "spacing-margin-small-5": "1rem",
        "spacing-margin-small-6": "1.5rem",
        "spacing-margin-small-7": "2.5rem",
        "spacing-margin-medium-2": "0.75rem",
        "spacing-margin-medium-4": "1.5rem",
        "spacing-margin-medium-5": "2rem",
        "spacing-margin-large-1": "0.5rem",
        "spacing-margin-large-2": "1rem",
        "spacing-margin-large-3": "1.5rem",
        "spacing-margin-large-4": "2rem",
        "spacing-margin-large-5": "2.5rem",
        "spacing-margin-large-6": "4rem",
        "spacing-margin-large-7": "5rem",
        "spacing-space-between-small-1": "0.125rem",
        "spacing-space-between-small-2": "0.25rem",
        "spacing-space-between-small-3": "0.5rem",
        "spacing-space-between-small-4": "0.75rem",
        "spacing-space-between-small-5": "1rem",
        "spacing-space-between-small-6": "1.5rem",
        "spacing-space-between-small-7": "2.5rem",
        "spacing-space-between-medium-1": "0.125rem",
        "spacing-space-between-medium-2": "0.25rem",
        "spacing-space-between-medium-3": "0.5rem",
        "spacing-space-between-medium-4": "0.75rem",
        "spacing-space-between-medium-5": "1rem",
        "spacing-space-between-large-1": "0.25rem",
        "spacing-space-between-large-2": "0.5rem",
        "spacing-space-between-large-3": "1rem",
        "spacing-space-between-large-4": "1.5rem",
        "spacing-space-between-large-5": "1.5rem",
        "spacing-space-between-large-6": "2.5rem",
        "spacing-space-between-large-7": "4rem",
        "spacing-padding-small-1": "0.125rem",
        "spacing-padding-small-2": "0.25rem",
        "spacing-padding-small-3": "0.5rem",
        "spacing-padding-small-4": "0.75rem",
        "spacing-padding-small-5": "1rem",
        "spacing-padding-small-6": "1.5rem",
        "spacing-padding-small-7": "2.5rem",
        "spacing-padding-medium-1": "0.25rem",
        "spacing-padding-medium-2": "0.75rem",
        "spacing-padding-medium-3": "1rem",
        "spacing-padding-medium-4": "1.5rem",
        "spacing-padding-medium-5": "2rem",
        "spacing-padding-medium-6": "3rem",
        "spacing-padding-medium-7": "4rem",
        "spacing-padding-large-1": "0.5rem",
        "spacing-padding-large-2": "1rem",
        "spacing-padding-large-3": "1.5rem",
        "spacing-padding-large-4": "2rem",
        "spacing-padding-large-5": "2.5rem",
        "spacing-padding-large-6": "4rem",
        "spacing-padding-large-7": "5rem"
      },
      "string": {
        "components-accordion-section-button": "var(--brand)",
        "components-article-card-listing-button-theme": "var(--tonal)",
        "components-article-hero-pagination-buttons": "var(--brand)",
        "components-banner-banner-button": "var(--white)",
        "components-category-card-listing-button-theme": "var(--tonal)",
        "components-contact-us-promo-hierarchy-button-theme-1": "var(--filled)",
        "components-contact-us-promo-hierarchy-button-theme-2": "var(--outlined)",
        "components-contact-us-promo-color-button-theme": "var(--brand)",
        "components-contact-us-promo-color-brand-button-theme": "var(--white)",
        "components-generic-card-button-theme": "var(--brand)",
        "components-global-search-loader": "var(--default)",
        "components-generic-card-listing-decor-top": "var(--raid-1)",
        "components-generic-card-listing-decor-bottom": "var(--raid-2)",
        "components-hero-homepage-pagination-buttons": "var(--tonal)",
        "components-hero-homepage-pattern": "var(--none)",
        "components-hero-homepage-button-1": "var(--tonal)",
        "components-hero-homepage-button-2": "var(--tonal)",
        "components-hero-homepage-image-ratio": "var(--5:4)",
        "components-hero-internal-theme-button-one-type": "var(--filled)",
        "components-hero-internal-theme-button-two-type": "var(--outlined)",
        "components-hero-internal-theme-button-color": "var(--white)",
        "components-hero-internal-theme-button-color-with-image": "var(--tonal)",
        "components-lightbox-button-type": "var(--brand)",
        "components-pdf-link-card-hierarchy-button-theme-1": "var(--filled)",
        "components-pdf-link-card-hierarchy-button-theme-2": "var(--outlined)",
        "components-pdf-link-card-color-button-theme": "var(--brand)",
        "components-product-card-badge-align": "var(--left)",
        "components-product-card-listing-button-color": "var(--white)",
        "components-promo-theme-button-one-theme": "var(--filled)",
        "components-promo-theme-button-two-theme": "var(--outlined)",
        "components-promo-color-default-button-theme": "var(--brand)",
        "components-promo-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-color-brand-button-theme": "var(--white)",
        "components-pull-quote-border-decor": "var(--glade)",
        "components-promo-alternate-theme-button-one-theme": "var(--filled)",
        "components-promo-alternate-theme-button-two-theme": "var(--outlined)",
        "components-promo-alternate-color-default-button-theme": "var(--brand)",
        "components-promo-alternate-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-alternate-color-brand-button-theme": "var(--white)",
        "components-share-list-button-color": "var(--brand)",
        "components-site-search-hero-search-hero-pattern": "var(--baygon)",
        "components-stats-decor-top": "var(--raid-1)",
        "components-stats-decor-bottom": "var(--raid-2)",
        "themes-brand-loader": "var(--default)",
        "themes-brand-variants": "var(--baygon)",
        "themes-tab-variant": "var(--default)",
        "themes-label-variants": "var(--default)",
        "themes-logo": "var(--baygon)",
        "themes-category-card": "var(--baygon)",
        "themes-title-decor": "var(--raid)",
        "themes-typography-heading-fontfamily": "var(--avenir)",
        "themes-typography-heading-large-xxlarge-fontweight": "var(--black)",
        "themes-typography-heading-large-xlarge-fontweight": "var(--black)",
        "themes-typography-heading-large-large-fontweight": "var(--black)",
        "themes-typography-heading-large-medium-fontweight": "var(--black)",
        "themes-typography-heading-large-small-fontweight": "var(--black)",
        "themes-typography-heading-large-xsmall-fontweight": "var(--black)",
        "themes-typography-heading-large-xxsmall-fontweight": "var(--black)",
        "themes-typography-heading-medium-xxlarge-fontweight": "var(--black)",
        "themes-typography-heading-medium-xlarge-fontweight": "var(--black)",
        "themes-typography-heading-medium-large-fontweight": "var(--black)",
        "themes-typography-heading-medium-medium-fontweight": "var(--black)",
        "themes-typography-heading-medium-small-fontweight": "var(--black)",
        "themes-typography-heading-medium-xsmall-fontweight": "var(--black)",
        "themes-typography-heading-medium-xxsmall-fontweight": "var(--black)",
        "themes-typography-heading-small-xxlarge-fontweight": "var(--black)",
        "themes-typography-heading-small-xlarge-fontweight": "var(--black)",
        "themes-typography-heading-small-large-fontweight": "var(--black)",
        "themes-typography-heading-small-medium-fontweight": "var(--black)",
        "themes-typography-heading-small-small-fontweight": "var(--black)",
        "themes-typography-heading-small-xsmall-fontweight": "var(--black)",
        "themes-typography-heading-small-xxsmall-fontweight": "var(--black)",
        "themes-typography-rte-fontfamily": "var(--avenir)",
        "themes-typography-rte-fontweight": "var(--black)"
      },
      "borderRadius": {
        "components-accordion-spacing-large-item-item-radius": "0rem",
        "components-article-card-spacing-large-image-radius-tl": "1rem",
        "components-article-card-spacing-large-image-radius-tr": "1rem",
        "components-article-card-spacing-large-image-radius-bl": "1rem",
        "components-article-card-spacing-large-image-radius-br": "1rem",
        "components-article-card-spacing-large-list-image-radius-br": "1rem",
        "components-article-card-spacing-small-image-radius-br": "1rem",
        "components-article-card-spacing-small-list-image-radius-br": "0rem",
        "components-article-hero-spacing-large-content-radius": "1.5rem",
        "components-article-hero-spacing-small-content-radius": "1rem",
        "components-button-radius-large": "0.5rem",
        "components-button-group-spacing-border-radius": "0.5rem",
        "components-category-card-radius-border-width": "0.125rem",
        "components-category-card-radius-border-radius": "7.5rem",
        "components-category-card-radius-border-radius-condensed": "0.5rem",
        "components-category-card-radius-media-radius": "0rem",
        "components-content-list-icon-radius": "7.5rem",
        "components-fab-spacing-border-radius": "0.5rem",
        "components-header-spacing-large-search-border-radius-top": "0.25rem",
        "components-header-spacing-large-search-border-radius-bottom": "0.25rem",
        "components-header-spacing-large-language-selector-radius": "0.25rem",
        "components-header-spacing-large-dropdown-radius": "0rem",
        "components-hero-homepage-spacing-large-container-radius": "1rem",
        "components-hero-internal-spacing-large-icon-radius": "7.5rem",
        "components-hero-internal-spacing-small-icon-radius": "7.5rem",
        "components-lightbox-spacing-large-overlay-radius": "1rem",
        "components-pagination-scrollbar-radius": "62.5rem",
        "components-product-card-spacing-image-radius-tl": "0rem",
        "components-product-card-spacing-image-radius-tr": "0rem",
        "components-product-card-spacing-image-radius-bl": "0rem",
        "components-product-card-spacing-image-radius-br": "0rem",
        "components-product-information-spacing-large-content-radius": "1.5rem",
        "components-product-information-spacing-small-content-radius": "1rem",
        "components-promo-spacing-large-content-radius": "0rem",
        "components-promo-spacing-large-content-radius-tl": "1rem",
        "components-promo-spacing-large-content-radius-tr": "1rem",
        "components-promo-spacing-large-content-radius-bl": "1rem",
        "components-promo-spacing-large-content-radius-br": "1rem",
        "components-promo-spacing-large-image-radius-tl": "1rem",
        "components-promo-spacing-large-image-radius-tr": "1rem",
        "components-promo-spacing-large-image-radius-bl": "1rem",
        "components-promo-spacing-large-image-radius-br": "1rem",
        "components-promo-spacing-small-content-radius": "0rem",
        "components-promo-spacing-small-image-radius-tl": "0.75rem",
        "components-promo-spacing-small-image-radius-tr": "0.75rem",
        "components-promo-spacing-small-image-radius-bl": "0.75rem",
        "components-promo-spacing-small-image-radius-br": "0.75rem",
        "components-promo-alternate-spacing-large-content-radius": "0rem",
        "components-promo-alternate-spacing-large-image-radius-tl": "1rem",
        "components-promo-alternate-spacing-large-image-radius-tr": "1rem",
        "components-promo-alternate-spacing-large-image-radius-bl": "1rem",
        "components-promo-alternate-spacing-large-image-radius-br": "1rem",
        "components-promo-alternate-spacing-small-content-radius": "0rem",
        "components-promo-alternate-spacing-small-image-radius-tl": "0.75rem",
        "components-promo-alternate-spacing-small-image-radius-tr": "0.75rem",
        "components-promo-alternate-spacing-small-image-radius-bl": "0.75rem",
        "components-promo-alternate-spacing-small-image-radius-br": "0.75rem",
        "components-share-list-spacing-radius": "0.5rem",
        "components-share-list-spacing-link-radius": "0.5rem",
        "components-site-search-filter-radius": "0.5rem",
        "components-site-search-facet-radius": "0rem",
        "components-site-search-refine-radius": "0rem",
        "components-tag-spacing-radius": "7.5rem",
        "components-tip-spacing-radius": "0.5rem",
        "themes-spacing-radius-pagination-bar": "62.5rem",
        "themes-spacing-radius-large-button": "0.5rem",
        "themes-spacing-radius-large-button-icon": "0.5rem",
        "themes-spacing-radius-large-image": "1rem",
        "themes-spacing-radius-large-image-hero": "0rem",
        "themes-spacing-radius-large-image-card": "0.75rem",
        "themes-spacing-radius-large-card": "1rem",
        "themes-spacing-radius-large-icon": "7.5rem",
        "themes-spacing-radius-large-label": "0.25rem",
        "themes-spacing-radius-large-tag": "7.5rem",
        "themes-spacing-radius-large-container-tip": "0.5rem",
        "themes-spacing-radius-large-badge": "0.25rem",
        "themes-spacing-radius-large-item": "0.5rem",
        "themes-spacing-radius-large-form-field-input": "0.25rem",
        "themes-spacing-radius-large-form-area-input": "0.25rem",
        "themes-spacing-radius-large-general": "1.5rem",
        "themes-spacing-radius-small-button": "7.5rem",
        "themes-spacing-radius-small-button-icon": "7.5rem",
        "themes-spacing-radius-small-image": "0.75rem",
        "themes-spacing-radius-small-image-card": "0.75rem",
        "themes-spacing-radius-small-card": "1rem",
        "themes-spacing-radius-small-icon": "7.5rem",
        "themes-spacing-radius-small-label": "0.125rem",
        "themes-spacing-radius-small-tag": "0.25rem",
        "themes-spacing-radius-small-badge": "0rem",
        "themes-spacing-radius-small-item": "0rem",
        "themes-spacing-radius-small-form-field-input": "0.5rem",
        "themes-spacing-radius-small-general": "1rem"
      },
      "borderWidth": {
        "3": "3px",
        "components-article-card-spacing-large-border-width": "0rem",
        "components-generic-card-color-border-width": "0.125rem",
        "components-header-spacing-large-search-border-width-top": "0.125rem",
        "components-header-spacing-large-search-border-width-right": "0.125rem",
        "components-header-spacing-large-search-border-width-bottom": "0.125rem",
        "components-header-spacing-large-search-border-width-left": "0.125rem",
        "components-product-card-spacing-border-width": "0.125rem"
      },
      "maxHeight": {},
      "maxWidth": {
        "components-article-hero-spacing-large-header-max-width": "66.375rem",
        "components-content-list-spacing-large-item-image-max-width": "33.75rem",
        "components-content-list-spacing-small-item-image-max-width": "33.75rem"
      },
      "minHeight": {
        "components-dropdown-item-min-height": "3rem",
        "components-icon-min-height": "3rem",
        "components-tab-item-min-height": "3rem",
        "components-text-field-input-min-height": "3rem",
        "components-text-field-icon-min-height": "3rem"
      },
      "minWidth": {
        "components-content-list-spacing-small-item-image-min-width": "18.75rem",
        "components-icon-min-width": "3rem",
        "components-text-field-icon-min-width": "3rem"
      },
      "opacity": {
        "components-accessibility-bar-color-bg": 1,
        "components-accessibility-bar-color-text": 1,
        "components-accordion-color-bg": 1,
        "components-accordion-color-title": 1,
        "components-accordion-color-body": 1,
        "components-accordion-color-section-section-bg": 1,
        "components-accordion-color-section-section-title": 1,
        "components-accordion-color-section-section-body": 1,
        "components-accordion-color-item-item-bg": 1,
        "components-accordion-color-item-divider": 1,
        "components-accordion-color-item-icon-icon-default": 1,
        "components-accordion-color-item-icon-icon-hover": 1,
        "components-accordion-color-item-icon-icon-pressed": 1,
        "components-accordion-color-item-icon-icon-active": 1,
        "components-accordion-color-item-title-item-default": 1,
        "components-accordion-color-item-title-item-hover": 1,
        "components-accordion-color-item-title-item-pressed": 1,
        "components-accordion-color-item-title-item-active": 1,
        "components-accordion-color-content-bg": 1,
        "components-accordion-color-content-title": 1,
        "components-accordion-color-content-body": 1,
        "components-article-card-color-bg": 1,
        "components-article-card-color-border": 1,
        "components-article-card-color-content-bg": 1,
        "components-article-card-color-title": 1,
        "components-article-card-color-subtitle": 1,
        "components-article-card-color-copy": 1,
        "components-article-card-color-description": 1,
        "components-article-card-listing-color-default-bg": 1,
        "components-article-card-listing-color-default-title": 1,
        "components-article-card-listing-color-default-copy": 1,
        "components-article-hero-color-content-bg": 1,
        "components-article-hero-color-bg": 1,
        "components-article-hero-color-headline": 1,
        "components-article-hero-color-body": 1,
        "components-article-hero-color-detail-text": 1,
        "components-article-hero-color-divider": 1,
        "components-article-hero-color-icon": 1,
        "components-article-hero-color-play-icon": 1,
        "components-article-hero-color-selected-stroke": 1,
        "components-banner-color-bg-default": 1,
        "components-banner-color-icon-default": 1,
        "components-banner-color-title-default": 1,
        "components-banner-color-body-default": 1,
        "components-banner-color-icon-close-default": 1,
        "components-banner-color-bg-alert": 1,
        "components-banner-color-icon-alert": 1,
        "components-banner-color-title-alert": 1,
        "components-banner-color-body-alert": 1,
        "components-banner-color-icon-close-alert": 1,
        "components-breadcrumb-color-bg": 1,
        "components-breadcrumb-color-breadcrumb-link-default": 1,
        "components-breadcrumb-color-breadcrumb-link-hover": 1,
        "components-breadcrumb-color-breadcrumb-link-active": 1,
        "components-breadcrumb-color-icon-divider": 1,
        "components-bug-finder-prompt": 1,
        "components-button-color-filled-brand-default-bg": 1,
        "components-button-color-filled-brand-default-text": 1,
        "components-button-color-filled-brand-default-stroke": 1,
        "components-button-color-filled-brand-default-icon": 1,
        "components-button-color-filled-brand-hover-bg": 1,
        "components-button-color-filled-brand-hover-text": 1,
        "components-button-color-filled-brand-hover-stroke": 1,
        "components-button-color-filled-brand-hover-icon": 1,
        "components-button-color-filled-brand-pressed-bg": 1,
        "components-button-color-filled-brand-pressed-text": 1,
        "components-button-color-filled-brand-pressed-stroke": 1,
        "components-button-color-filled-brand-pressed-icon": 1,
        "components-button-color-filled-brand-disabled-bg": null,
        "components-button-color-filled-brand-disabled-text": 0.02,
        "components-button-color-filled-brand-disabled-stroke": null,
        "components-button-color-filled-brand-disabled-icon": 0.02,
        "components-button-color-filled-brand-focus-bg": 1,
        "components-button-color-filled-brand-focus-text": 1,
        "components-button-color-filled-brand-focus-stroke": 1,
        "components-button-color-filled-brand-focus-icon": 1,
        "components-button-color-filled-brand-focus-outline": 1,
        "components-button-color-filled-tonal-default-bg": 1,
        "components-button-color-filled-tonal-default-text": 1,
        "components-button-color-filled-tonal-default-stroke": 1,
        "components-button-color-filled-tonal-default-icon": 1,
        "components-button-color-filled-tonal-hover-bg": 1,
        "components-button-color-filled-tonal-hover-text": 1,
        "components-button-color-filled-tonal-hover-stroke": 1,
        "components-button-color-filled-tonal-hover-icon": 1,
        "components-button-color-filled-tonal-pressed-bg": 1,
        "components-button-color-filled-tonal-pressed-text": 1,
        "components-button-color-filled-tonal-pressed-stroke": 1,
        "components-button-color-filled-tonal-pressed-icon": 1,
        "components-button-color-filled-tonal-disabled-bg": 0.02,
        "components-button-color-filled-tonal-disabled-text": 0.02,
        "components-button-color-filled-tonal-disabled-stroke": 0.02,
        "components-button-color-filled-tonal-disabled-icon": 0.02,
        "components-button-color-filled-tonal-focus-bg": 1,
        "components-button-color-filled-tonal-focus-text": 1,
        "components-button-color-filled-tonal-focus-stroke": 1,
        "components-button-color-filled-tonal-focus-icon": 1,
        "components-button-color-filled-tonal-focus-outline": 1,
        "components-button-color-filled-white-default-bg": 1,
        "components-button-color-filled-white-default-text": 1,
        "components-button-color-filled-white-default-stroke": 1,
        "components-button-color-filled-white-default-icon": 1,
        "components-button-color-filled-white-hover-bg": 1,
        "components-button-color-filled-white-hover-text": 1,
        "components-button-color-filled-white-hover-stroke": 1,
        "components-button-color-filled-white-hover-icon": 1,
        "components-button-color-filled-white-pressed-bg": 1,
        "components-button-color-filled-white-pressed-text": 1,
        "components-button-color-filled-white-pressed-stroke": 1,
        "components-button-color-filled-white-pressed-icon": 1,
        "components-button-color-filled-white-disabled-bg": 0.02,
        "components-button-color-filled-white-disabled-text": null,
        "components-button-color-filled-white-disabled-stroke": 0.02,
        "components-button-color-filled-white-disabled-icon": 0.02,
        "components-button-color-filled-white-focus-bg": 1,
        "components-button-color-filled-white-focus-text": 1,
        "components-button-color-filled-white-focus-stroke": 1,
        "components-button-color-filled-white-focus-icon": 1,
        "components-button-color-filled-white-focus-outline": 1,
        "components-button-color-outline-brand-default-bg": 0.02,
        "components-button-color-outline-brand-default-text": 1,
        "components-button-color-outline-brand-default-stroke": 1,
        "components-button-color-outline-brand-default-icon": 1,
        "components-button-color-outline-brand-hover-bg": 1,
        "components-button-color-outline-brand-hover-text": 1,
        "components-button-color-outline-brand-hover-stroke": 1,
        "components-button-color-outline-brand-hover-icon": 1,
        "components-button-color-outline-brand-pressed-bg": 1,
        "components-button-color-outline-brand-pressed-text": 1,
        "components-button-color-outline-brand-pressed-stroke": 1,
        "components-button-color-outline-brand-pressed-icon": 1,
        "components-button-color-outline-brand-disabled-bg": 0.02,
        "components-button-color-outline-brand-disabled-text": null,
        "components-button-color-outline-brand-disabled-stroke": null,
        "components-button-color-outline-brand-disabled-icon": null,
        "components-button-color-outline-brand-focus-bg": 1,
        "components-button-color-outline-brand-focus-text": 1,
        "components-button-color-outline-brand-focus-stroke": 1,
        "components-button-color-outline-brand-focus-icon": 1,
        "components-button-color-outline-brand-focus-outline": 1,
        "components-button-color-outline-tonal-default-bg": 0.02,
        "components-button-color-outline-tonal-default-text": 1,
        "components-button-color-outline-tonal-default-stroke": 1,
        "components-button-color-outline-tonal-default-icon": 1,
        "components-button-color-outline-tonal-hover-bg": 1,
        "components-button-color-outline-tonal-hover-text": 1,
        "components-button-color-outline-tonal-hover-stroke": 1,
        "components-button-color-outline-tonal-hover-icon": 1,
        "components-button-color-outline-tonal-pressed-bg": 1,
        "components-button-color-outline-tonal-pressed-text": 1,
        "components-button-color-outline-tonal-pressed-stroke": 1,
        "components-button-color-outline-tonal-pressed-icon": 1,
        "components-button-color-outline-tonal-disabled-bg": 0.02,
        "components-button-color-outline-tonal-disabled-text": null,
        "components-button-color-outline-tonal-disabled-stroke": 0.02,
        "components-button-color-outline-tonal-disabled-icon": null,
        "components-button-color-outline-tonal-focus-bg": 0.02,
        "components-button-color-outline-tonal-focus-text": 1,
        "components-button-color-outline-tonal-focus-stroke": 1,
        "components-button-color-outline-tonal-focus-icon": 1,
        "components-button-color-outline-tonal-focus-outline": 1,
        "components-button-color-outline-white-default-bg": 0.02,
        "components-button-color-outline-white-default-text": 1,
        "components-button-color-outline-white-default-stroke": 1,
        "components-button-color-outline-white-default-icon": 1,
        "components-button-color-outline-white-hover-bg": 1,
        "components-button-color-outline-white-hover-text": 1,
        "components-button-color-outline-white-hover-stroke": 1,
        "components-button-color-outline-white-hover-icon": 1,
        "components-button-color-outline-white-pressed-bg": 1,
        "components-button-color-outline-white-pressed-text": 1,
        "components-button-color-outline-white-pressed-stroke": 1,
        "components-button-color-outline-white-pressed-icon": 1,
        "components-button-color-outline-white-disabled-bg": 0.02,
        "components-button-color-outline-white-disabled-text": 0.02,
        "components-button-color-outline-white-disabled-stroke": 0.02,
        "components-button-color-outline-white-disabled-icon": 0.02,
        "components-button-color-outline-white-focus-bg": 0.02,
        "components-button-color-outline-white-focus-text": 1,
        "components-button-color-outline-white-focus-stroke": 1,
        "components-button-color-outline-white-focus-icon": 1,
        "components-button-color-outline-white-focus-outline": 1,
        "components-button-color-text-brand-default-bg": 0.02,
        "components-button-color-text-brand-default-text": 1,
        "components-button-color-text-brand-default-stroke": 1,
        "components-button-color-text-brand-default-icon": 1,
        "components-button-color-text-brand-hover-bg": 0.02,
        "components-button-color-text-brand-hover-bg-icon-only": 1,
        "components-button-color-text-brand-hover-text": 1,
        "components-button-color-text-brand-hover-stroke": 1,
        "components-button-color-text-brand-hover-icon": 1,
        "components-button-color-text-brand-pressed-bg": 0.02,
        "components-button-color-text-brand-pressed-bg-icon-only": 1,
        "components-button-color-text-brand-pressed-text": 1,
        "components-button-color-text-brand-pressed-stroke": 1,
        "components-button-color-text-brand-pressed-icon": 1,
        "components-button-color-text-brand-disabled-bg": 0.02,
        "components-button-color-text-brand-disabled-text": null,
        "components-button-color-text-brand-disabled-stroke": null,
        "components-button-color-text-brand-disabled-icon": null,
        "components-button-color-text-brand-focus-bg": 1,
        "components-button-color-text-brand-focus-text": 1,
        "components-button-color-text-brand-focus-stroke": 1,
        "components-button-color-text-brand-focus-icon": 1,
        "components-button-color-text-brand-focus-outline": 1,
        "components-button-color-text-tonal-default-bg": 0.02,
        "components-button-color-text-tonal-default-text": 1,
        "components-button-color-text-tonal-default-stroke": 1,
        "components-button-color-text-tonal-default-icon": 1,
        "components-button-color-text-tonal-hover-bg": 0.02,
        "components-button-color-text-tonal-hover-bg-icon-only": 1,
        "components-button-color-text-tonal-hover-text": 1,
        "components-button-color-text-tonal-hover-stroke": 1,
        "components-button-color-text-tonal-hover-icon": 1,
        "components-button-color-text-tonal-pressed-bg": 0.02,
        "components-button-color-text-tonal-pressed-bg-icon-only": 1,
        "components-button-color-text-tonal-pressed-text": 1,
        "components-button-color-text-tonal-pressed-stroke": 1,
        "components-button-color-text-tonal-pressed-icon": 1,
        "components-button-color-text-tonal-disabled-bg": 0.02,
        "components-button-color-text-tonal-disabled-text": 0.02,
        "components-button-color-text-tonal-disabled-stroke": 0.02,
        "components-button-color-text-tonal-disabled-icon": 0.02,
        "components-button-color-text-tonal-focus-bg": 1,
        "components-button-color-text-tonal-focus-text": 1,
        "components-button-color-text-tonal-focus-stroke": 1,
        "components-button-color-text-tonal-focus-icon": 1,
        "components-button-color-text-tonal-focus-outline": 1,
        "components-button-color-text-white-default-bg": 0.02,
        "components-button-color-text-white-default-text": 1,
        "components-button-color-text-white-default-stroke": 1,
        "components-button-color-text-white-default-icon": 1,
        "components-button-color-text-white-hover-bg": 0.02,
        "components-button-color-text-white-hover-bg-icon-only": 1,
        "components-button-color-text-white-hover-text": 1,
        "components-button-color-text-white-hover-stroke": 1,
        "components-button-color-text-white-hover-icon": 1,
        "components-button-color-text-white-pressed-bg": 0.02,
        "components-button-color-text-white-pressed-bg-icon-only": 1,
        "components-button-color-text-white-pressed-text": 1,
        "components-button-color-text-white-pressed-stroke": 1,
        "components-button-color-text-white-pressed-icon": 1,
        "components-button-color-text-white-disabled-bg": 0.02,
        "components-button-color-text-white-disabled-text": 0.01,
        "components-button-color-text-white-disabled-stroke": 0.01,
        "components-button-color-text-white-disabled-icon": 0.01,
        "components-button-color-text-white-focus-bg": 1,
        "components-button-color-text-white-focus-text": 1,
        "components-button-color-text-white-focus-stroke": 1,
        "components-button-color-text-white-focus-icon": 1,
        "components-button-color-text-white-focus-outline": 1,
        "components-button-group-color-border": 1,
        "components-button-group-color-button-bg-default": 0.02,
        "components-button-group-color-button-fg-default": 1,
        "components-button-group-color-button-bg-hover": null,
        "components-button-group-color-button-fg-hover": 1,
        "components-button-group-color-button-bg-active": 1,
        "components-button-group-color-button-fg-active": 1,
        "components-button-group-color-overlay-left-gradient-fill-1": 1,
        "components-button-group-color-overlay-left-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-1": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-3": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-4": 0.02,
        "components-button-group-color-stroke-hover-gradient-fill-1": null,
        "components-button-group-color-stroke-hover-gradient-fill-2": null,
        "components-button-group-color-stroke-hover-gradient-fill-3": null,
        "components-button-group-color-stroke-hover-gradient-fill-4": null,
        "components-button-group-color-stroke-active-gradient-fill-1": 1,
        "components-button-group-color-stroke-active-gradient-fill-2": 1,
        "components-button-group-color-stroke-active-gradient-fill-3": 1,
        "components-button-group-color-stroke-active-gradient-fill-4": 1,
        "components-category-card-color-bg": 1,
        "components-category-card-color-content-bg": 1,
        "components-category-card-color-category-bg": 1,
        "components-category-card-color-category-bg-2": 1,
        "components-category-card-color-category-bg-3": 1,
        "components-category-card-color-category-bg-4": 1,
        "components-category-card-color-category-bg-5": 1,
        "components-category-card-color-category-bg-6": 1,
        "components-category-card-color-title": 1,
        "components-category-card-color-copy": 1,
        "components-category-card-color-stroke": 1,
        "components-category-card-color-stroke-hover": 1,
        "components-category-card-listing-color-bg": 1,
        "components-category-card-listing-color-title": 1,
        "components-category-card-listing-color-copy": 1,
        "components-card-search-result-color-bg": 1,
        "components-card-search-result-color-label": 1,
        "components-card-search-result-color-title": 1,
        "components-card-search-result-color-body": 1,
        "components-card-search-result-color-divider-line": 1,
        "components-contact-us-promo-color-light-bg": 1,
        "components-contact-us-promo-color-light-title": 1,
        "components-contact-us-promo-color-light-body": 1,
        "components-contact-us-promo-color-default-bg": 1,
        "components-contact-us-promo-color-default-title": 1,
        "components-contact-us-promo-color-default-body": 1,
        "components-contact-us-promo-color-brand-bg": 1,
        "components-contact-us-promo-color-brand-title": 1,
        "components-contact-us-promo-color-brand-body": 1,
        "components-content-list-color-bg": 1,
        "components-content-list-color-title": 1,
        "components-content-list-color-body": 1,
        "components-content-list-color-icon": 1,
        "components-cookies-colors-bg": 1,
        "components-cookies-colors-title": 1,
        "components-cookies-colors-body": 1,
        "components-cookies-colors-icon": 1,
        "components-dropdown-bg": 1,
        "components-dropdown-border-default": 1,
        "components-dropdown-border-hover": 1,
        "components-dropdown-border-focused": 1,
        "components-dropdown-border-destructive": 1,
        "components-dropdown-label-text-default": 1,
        "components-dropdown-item-text-default": 1,
        "components-dropdown-item-bg-default": 1,
        "components-dropdown-item-bg-hover": 1,
        "components-dropdown-input-field-default": 1,
        "components-dropdown-input-field-focused": 1,
        "components-dropdown-input-label-focused": 1,
        "components-dropdown-input-field-destructive": 1,
        "components-external-link-card-bg": 1,
        "components-external-link-card-card-bg": 1,
        "components-external-link-card-card-title": 1,
        "components-external-link-card-card-subtitle": 1,
        "components-external-link-card-card-eyebrow": 1,
        "components-fab-color-border-default": 1,
        "components-footer-brand-color-bg": 1,
        "components-footer-brand-color-logo": 1,
        "components-footer-brand-color-title-text": 1,
        "components-footer-brand-color-description-text": 1,
        "components-footer-brand-color-footer-menu-expanded": 1,
        "components-footer-brand-color-link-default": 1,
        "components-footer-brand-color-link-hover": 1,
        "components-footer-brand-color-link-pressed": 1,
        "components-footer-brand-color-category-link-default": 1,
        "components-footer-brand-color-category-link-hover": 1,
        "components-footer-brand-color-category-link-pressed": 1,
        "components-footer-brand-color-subcategory-link-default": 1,
        "components-footer-brand-color-subcategory-link-hover": 1,
        "components-footer-brand-color-subcategory-link-pressed": 1,
        "components-footer-brand-color-icon-icon-default": 1,
        "components-footer-brand-color-icon-icon-pressed": 1,
        "components-footer-brand-color-social-icon-default": 1,
        "components-footer-brand-color-social-icon-hover": 1,
        "components-footer-brand-color-social-icon-pressed": 1,
        "components-footer-brand-spacing-category-link-hover": 1,
        "components-footer-global-color-bg": 1,
        "components-footer-global-color-title-text": 1,
        "components-footer-global-color-disclaimer-text": 1,
        "components-footer-global-color-link-default": 1,
        "components-footer-global-color-link-hover": 1,
        "components-footer-global-color-link-pressed": 1,
        "components-generic-card-color-bg": 1,
        "components-generic-card-color-content-bg": 1,
        "components-generic-card-color-title": 1,
        "components-generic-card-color-subtitle": 1,
        "components-generic-card-color-copy": 1,
        "components-global-search-color-omnibox-list-bg": 1,
        "components-global-search-color-omnibox-divider": 1,
        "components-global-search-color-omnibox-item-bg-default": 1,
        "components-global-search-color-omnibox-item-bg-hover": 1,
        "components-global-search-color-omnibox-item-text-default": 1,
        "components-global-search-color-omnibox-item-text-hover": 1,
        "components-global-search-color-omnibox-label-text-default": 1,
        "components-global-search-color-omnibox-category-text-default": 1,
        "components-generic-card-listing-color-bg": 1,
        "components-generic-card-listing-color-title": 1,
        "components-generic-card-listing-color-copy": 1,
        "components-header-color-bg": 1,
        "components-header-color-bottom-stroke": 1,
        "components-header-color-search-search-border-default": 1,
        "components-header-color-search-search-text": 1,
        "components-header-color-search-search-icon": 1,
        "components-header-color-search-search-bar": 1,
        "components-header-color-mobile-mobile-expanded-bg": 1,
        "components-header-color-mobile-pressed-bg": 1,
        "components-header-color-language-selector-language": 1,
        "components-header-color-language-selector-country": 1,
        "components-header-color-language-selector-country-hover-bg": 1,
        "components-header-color-language-selector-megamenu-language": 1,
        "components-header-color-language-selector-megamenu-country": 1,
        "components-header-color-language-selector-megamenu-country-hover": 1,
        "components-header-color-language-selector-bg": 1,
        "components-header-color-language-selector-stroke": 1,
        "components-header-color-mega-menu-bg": 1,
        "components-header-color-mega-menu-featured-bg": 1,
        "components-header-color-mega-menu-featured-title": 1,
        "components-header-color-mega-menu-category-title": 1,
        "components-header-color-mega-menu-bg-accent": 1,
        "components-header-color-icon-icon-default": 1,
        "components-header-color-icon-icon-hover": 1,
        "components-header-color-icon-icon-pressed": 1,
        "components-header-color-links-nav-main-link-default": 1,
        "components-header-color-links-nav-main-link-hover": 1,
        "components-header-color-links-nav-main-link-pressed": 1,
        "components-header-color-links-nav-main-link-active": 1,
        "components-header-color-links-nav-main-active-bar": 1,
        "components-header-color-links-nav-main-bg-color": 1,
        "components-header-color-links-nav-category-link-category-default": 1,
        "components-header-color-links-nav-category-link-category-hover": 1,
        "components-header-color-links-nav-category-link-category-pressed": 1,
        "components-header-color-links-nav-category-link-category-active": 1,
        "components-header-color-links-nav-menu-link-link-default": 1,
        "components-header-color-links-nav-menu-link-link-hover": 1,
        "components-header-color-links-nav-menu-link-link-pressed": 1,
        "components-header-color-links-nav-menu-link-link-active": 1,
        "components-header-color-links-nav-menu-link-bg": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-default": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-active": 1,
        "components-hero-homepage-color-bg": 1,
        "components-hero-homepage-color-container-bg": 1,
        "components-hero-homepage-color-eyebrow": 1,
        "components-hero-homepage-color-header": 1,
        "components-hero-homepage-color-sub-header": 1,
        "components-hero-homepage-color-body": 1,
        "components-hero-homepage-color-play-pause": 1,
        "components-hero-internal-color-default-title": 1,
        "components-hero-internal-color-default-body": 1,
        "components-hero-internal-color-default-bg": 1,
        "components-hero-internal-color-accent-bg": 1,
        "components-hero-internal-color-with-image-bg": 1,
        "components-hero-internal-color-default-bg-2": 1,
        "components-hero-internal-color-with-image-title": 1,
        "components-hero-internal-color-with-image-body": 1,
        "components-heading-page-title-color-default-bg": 1,
        "components-heading-page-title-color-default-title": 1,
        "components-heading-page-title-color-default-body": 1,
        "components-heading-page-title-color-light-bg": 1,
        "components-heading-page-title-color-light-title": 1,
        "components-heading-page-title-color-light-body": 1,
        "components-heading-page-title-color-color-bg": 1,
        "components-heading-page-title-color-color-title": 1,
        "components-heading-page-title-color-color-body": 1,
        "components-hyperlink-color-dark-default": 1,
        "components-hyperlink-color-dark-hover": 1,
        "components-hyperlink-color-dark-visited": 1,
        "components-hyperlink-color-light-default": 1,
        "components-hyperlink-color-light-hover": 1,
        "components-hyperlink-color-light-visited": 1,
        "components-icon-default": 1,
        "components-icon-focused": 1,
        "components-icon-card-color-bg": 1,
        "components-icon-card-color-icon-bg": 1,
        "components-icon-card-color-icon": 1,
        "components-icon-card-color-title": 1,
        "components-icon-card-color-subtitle": 1,
        "components-icon-card-color-description": 1,
        "components-icon-card-listing-color-bg": 1,
        "components-icon-card-listing-color-inner-bg": 1,
        "components-icon-card-listing-color-brand-bg": 1,
        "components-icon-card-listing-color-title": 1,
        "components-icon-card-listing-color-copy": 1,
        "components-icon-card-listing-color-inner-legal-text": 1,
        "components-jumplink-color-bg-default": 1,
        "components-jumplink-color-link-text-default": 1,
        "components-jumplink-color-link-text-hover": 1,
        "components-jumplink-color-link-border-hover": 1,
        "components-jumplink-color-link-text-active": 1,
        "components-jumplink-color-link-border-active": 1,
        "components-jumplink-color-title-text": 1,
        "components-jumplink-color-description-text": 1,
        "components-layout-bg-default": 1,
        "components-lightbox-color-overlay-bg": 0.02,
        "components-lightbox-color-title": 1,
        "components-lightbox-color-body": 1,
        "components-label-color-on-color-label-label-default": 1,
        "components-label-color-on-color-label-label-hover": 1,
        "components-label-color-on-color-label-label-pressed": 1,
        "components-label-color-on-color-background-bg-default": 0.02,
        "components-label-color-on-color-background-bg-hover": 1,
        "components-label-color-on-color-background-bg-pressed": 1,
        "components-label-color-on-color-stroke-hover": 0.02,
        "components-label-color-on-color-stroke-pressed": 0.02,
        "components-label-color-on-color-stroke-default-gradient-fill-1": 0.02,
        "components-label-color-on-color-stroke-default-gradient-fill-2": 0.02,
        "components-label-color-on-color-stroke-default-gradient-fill-3": 0.02,
        "components-label-color-on-color-stroke-default-gradient-fill-4": 0.02,
        "components-label-color-on-white-label-label-default": 1,
        "components-label-color-on-white-label-label-hover": 1,
        "components-label-color-on-white-label-label-pressed": 1,
        "components-label-color-on-white-background-bg-default": 1,
        "components-label-color-on-white-background-bg-hover": 1,
        "components-label-color-on-white-background-bg-pressed": 1,
        "components-label-color-on-white-stroke-hover": 1,
        "components-label-color-on-white-stroke-pressed": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-1": 0,
        "components-label-color-on-white-stroke-default-gradient-fill-2": 0,
        "components-label-color-on-white-stroke-default-gradient-fill-3": 0,
        "components-label-color-on-white-stroke-default-gradient-fill-4": 0,
        "components-legal-disclaimer-color-default-bg": 1,
        "components-legal-disclaimer-color-default-title": 1,
        "components-legal-disclaimer-color-default-body": 1,
        "components-legal-disclaimer-color-light-bg": 1,
        "components-legal-disclaimer-color-light-title": 1,
        "components-legal-disclaimer-color-light-body": 1,
        "components-legal-disclaimer-color-brand-bg": 1,
        "components-legal-disclaimer-color-brand-title": 1,
        "components-legal-disclaimer-color-brand-body": 1,
        "components-media-video-thumbnail-overlay-bg": null,
        "components-media-caption-color-bg": 1,
        "components-media-caption-color-text": 1,
        "components-media-gallery-color-bg": 1,
        "components-media-gallery-color-title": 1,
        "components-media-gallery-color-body": 1,
        "components-pagination-on-white-accent-default": 1,
        "components-pagination-on-white-accent-scroll": 1,
        "components-pagination-on-white-disabled": 1,
        "components-pagination-on-white-bg": 1,
        "components-pagination-on-white-stroke": 1,
        "components-pagination-on-white-bg-active": 1,
        "components-pagination-on-white-bg-hover": 1,
        "components-pagination-on-white-text-default": 1,
        "components-pagination-on-white-text-hover": 1,
        "components-pagination-on-white-text-active": 1,
        "components-pagination-on-color-accent-default": 1,
        "components-pagination-on-color-accent-scroll": 1,
        "components-pagination-on-color-disabled": 1,
        "components-pagination-on-color-bg": null,
        "components-pagination-on-color-stroke": 0.02,
        "components-pagination-on-color-bg-active": 1,
        "components-pagination-on-color-bg-hover": 1,
        "components-pagination-on-color-text-default": 1,
        "components-pagination-on-color-text-hover": 1,
        "components-pagination-on-color-text-active": 1,
        "components-pdf-link-card-color-bg": 1,
        "components-pdf-link-card-color-card-bg": 1,
        "components-pdf-link-card-color-card-title": 1,
        "components-pdf-link-card-color-card-subtitle": 1,
        "components-pdf-link-card-color-card-body": 1,
        "components-product-card-color-bg": 1,
        "components-product-card-color-content-bg": 1,
        "components-product-card-color-border": 1,
        "components-product-card-color-title": 1,
        "components-product-card-color-description": 1,
        "components-product-card-color-star-rating": 1,
        "components-product-card-color-badge-bg": 1,
        "components-product-card-color-badge-text": 1,
        "components-product-card-listing-color-bg": 1,
        "components-product-card-listing-color-title": 1,
        "components-product-card-listing-color-copy": 1,
        "components-product-card-listing-color-legal-text": 1,
        "components-product-information-color-bg": 1,
        "components-product-information-color-content-bg": 1,
        "components-product-information-color-headline": 1,
        "components-product-information-color-body": 1,
        "components-product-information-color-detail-text": 1,
        "components-product-information-color-divider": 1,
        "components-product-information-color-icon": 1,
        "components-product-information-color-play-icon": 1,
        "components-product-information-color-selected-stroke": 1,
        "components-product-results-list-with-search-color-bg": 1,
        "components-promo-color-default-bg": 1,
        "components-promo-color-default-image-bg": 1,
        "components-promo-color-default-content-bg": 1,
        "components-promo-color-default-title": 1,
        "components-promo-color-default-body": 1,
        "components-promo-color-default-category": 1,
        "components-promo-color-light-bg": 1,
        "components-promo-color-light-image-bg": 1,
        "components-promo-color-light-content-bg": 1,
        "components-promo-color-light-title": 1,
        "components-promo-color-light-body": 1,
        "components-promo-color-light-category": 1,
        "components-promo-color-brand-bg": 1,
        "components-promo-color-brand-image-bg": 1,
        "components-promo-color-brand-content-bg": 1,
        "components-promo-color-brand-title": 1,
        "components-promo-color-brand-body": 1,
        "components-promo-color-brand-category": 1,
        "components-pull-quote-color-bg": 1,
        "components-pull-quote-color-stroke": 1,
        "components-pull-quote-color-quote-bg": 1,
        "components-pull-quote-color-quote-title": 1,
        "components-pull-quote-color-quote-by-text": 1,
        "components-pull-quote-color-quote-detail": 1,
        "components-pull-quote-color-quote-highlight": 1,
        "components-promo-alternate-color-default-bg": 1,
        "components-promo-alternate-color-default-image-bg": 1,
        "components-promo-alternate-color-default-content-bg": 1,
        "components-promo-alternate-color-default-title": 1,
        "components-promo-alternate-color-default-body": 1,
        "components-promo-alternate-color-default-category": 1,
        "components-promo-alternate-color-light-bg": 1,
        "components-promo-alternate-color-light-image-bg": 1,
        "components-promo-alternate-color-light-content-bg": 1,
        "components-promo-alternate-color-light-title": 1,
        "components-promo-alternate-color-light-body": 1,
        "components-promo-alternate-color-light-category": 1,
        "components-promo-alternate-color-brand-bg": 1,
        "components-promo-alternate-color-brand-image-bg": 1,
        "components-promo-alternate-color-brand-content-bg": 1,
        "components-promo-alternate-color-brand-title": 1,
        "components-promo-alternate-color-brand-body": 1,
        "components-promo-alternate-color-brand-category": 1,
        "components-share-list-color-title": 1,
        "components-share-list-color-bg": 1,
        "components-share-list-color-link-default": 1,
        "components-share-list-color-link-hover": 1,
        "components-share-list-color-bg-link-hover": 1,
        "components-share-list-color-close-icon": 1,
        "components-share-list-color-facebook-icon": 1,
        "components-share-list-color-pinterest-icon": 1,
        "components-share-list-color-twitter-icon": 1,
        "components-share-list-color-basic-icon-default": 1,
        "components-share-list-color-icon-hover": 1,
        "components-share-list-color-border": 1,
        "components-site-search-bg": 1,
        "components-site-search-results_text": 1,
        "components-site-search-filter-filter-selected-bg": 1,
        "components-site-search-facet-group-label-bottom-line": 1,
        "components-site-search-facet-group-label-text": 1,
        "components-site-search-facet-group-label-bg": 1,
        "components-site-search-facet-bg": 1,
        "components-site-search-facet-stroke": 1,
        "components-site-search-refine-bg": 1,
        "components-site-search-refine-right-divider": 1,
        "components-site-search-refine-title": 1,
        "components-site-search-hero-search-bg": 1,
        "components-site-search-hero-search-accent-bg": 1,
        "components-site-search-hero-search-title": 1,
        "components-stats-stat-text": 1,
        "components-stats-stat-text-on-light": 1,
        "components-stats-supporting-text": 1,
        "components-stats-bg-default": 1,
        "components-stats-bg-inner": 1,
        "components-stats-bg-section-inner": 1,
        "components-stats-title": 1,
        "components-stats-copy": 1,
        "components-supporting-text-default": 1,
        "components-supporting-text-destructive": 1,
        "components-tab-bg": 1,
        "components-tab-border-default": 1,
        "components-tab-border-hover": 1,
        "components-tab-border-focused": 1,
        "components-tab-border-destructive": 1,
        "components-tab-label-text-default": 1,
        "components-tab-item-text-default": 1,
        "components-tab-item-bg-default": 1,
        "components-tab-item-bg-hover": 1,
        "components-tab-input-field-default": 1,
        "components-tab-input-field-focused": 1,
        "components-tab-input-label-focused": 1,
        "components-tab-input-field-destructive": 1,
        "components-tab-bg-2": 1,
        "components-tag-color-bg": 1,
        "components-tag-color-text": 1,
        "components-tag-color-stroke": 1,
        "components-tag-color-bg-hover": 1,
        "components-tag-color-text-hover": 1,
        "components-tag-color-stroke-hover": 1,
        "components-text-field-bg": 1,
        "components-text-field-bg-focused": 1,
        "components-text-field-border-default": 1,
        "components-text-field-border-hover": 1,
        "components-text-field-border-focused": 1,
        "components-text-field-border-destructive": 1,
        "components-text-field-input-field-default": 1,
        "components-text-field-input-field-focused": 1,
        "components-text-field-input-label-focused": 1,
        "components-text-field-input-field-destructive": 1,
        "components-text-field-icon-default": 1,
        "components-text-field-icon-destructive": 1,
        "components-text-field-supporting-text-default": 1,
        "components-text-field-supporting-text-destructive": 1,
        "components-tip-color-bg": 1,
        "components-tip-color-title": 1,
        "components-tip-color-body": 1,
        "components-tip-color-stroke": 1,
        "components-video-color-bg": 1,
        "themes-general-surface-color-light-bg": 1,
        "themes-general-surface-color-light-title": 1,
        "themes-general-surface-color-light-body": 1,
        "themes-general-surface-color-light-decor-line-break": 1,
        "themes-general-surface-color-light-rule-line": 1,
        "themes-general-surface-color-dark-bg": 1,
        "themes-general-surface-color-dark-title": 1,
        "themes-general-surface-color-dark-body": 1,
        "themes-general-surface-color-dark-decor-line-break": 1,
        "themes-general-card-color-dark-title": 1,
        "themes-general-card-color-dark-subtitle": 1,
        "themes-general-card-color-dark-body": 1,
        "themes-general-card-color-light-title": 1,
        "themes-general-card-color-light-subtitle": 1,
        "themes-general-card-color-light-body": 1,
        "themes-shadow-color": 1,
        "themes-shadow-share-list-color-fill": 0,
        "themes-shadow-header-menu-color-fill": 0,
        "themes-gradient-loader-gradient-fill-1": 1,
        "themes-gradient-loader-gradient-fill-2": null,
        "themes-color-background-white": 1,
        "themes-color-background-light": 1,
        "themes-color-background-dark": 1,
        "themes-color-background-black": 1,
        "themes-color-background-brand1": 1,
        "themes-color-background-brand2": 1,
        "themes-color-background-accent1": 1,
        "themes-color-background-accent2": 1,
        "themes-color-background-body": 1,
        "themes-color-stroke-white": 1,
        "themes-color-stroke-light": 1,
        "themes-color-stroke-dark": 1,
        "themes-color-stroke-black": 1,
        "themes-color-stroke-brand1": 1,
        "themes-color-stroke-brand2": 1,
        "themes-color-stroke-accent1": 1,
        "themes-color-stroke-accent2": 1,
        "themes-color-text-white": 1,
        "themes-color-text-light": 1,
        "themes-color-text-dark": 1,
        "themes-color-text-black": 1,
        "themes-color-text-brand1": 1,
        "themes-color-text-brand2": 1,
        "themes-color-text-accent1": 1,
        "themes-color-text-accent2": 1,
        "colors-grayscale-100": 1,
        "colors-grayscale-200": 1,
        "colors-grayscale-300": 1,
        "colors-grayscale-400": 1,
        "colors-grayscale-500": 1,
        "colors-grayscale-600": 1,
        "colors-grayscale-700": 1,
        "colors-brand-1-100": 1,
        "colors-brand-1-200": 1,
        "colors-brand-1-300": 1,
        "colors-brand-1-400": 1,
        "colors-brand-1-500": 1,
        "colors-brand-1-600": 1,
        "colors-brand-1-700": 1,
        "colors-brand-2-100": 1,
        "colors-brand-2-200": 1,
        "colors-brand-2-300": 1,
        "colors-brand-2-400": 1,
        "colors-brand-2-500": 1,
        "colors-brand-2-600": 1,
        "colors-brand-2-700": 1,
        "colors-accent-1-100": 1,
        "colors-accent-1-200": 1,
        "colors-accent-1-300": 1,
        "colors-accent-1-400": 1,
        "colors-accent-1-500": 1,
        "colors-accent-1-600": 1,
        "colors-accent-1-700": 1,
        "colors-accent-2-100": 1,
        "colors-accent-2-200": 1,
        "colors-accent-2-300": 1,
        "colors-accent-2-400": 1,
        "colors-accent-2-500": 1,
        "colors-accent-2-600": 1,
        "colors-accent-2-700": 1,
        "colors-accent-3-100": 1,
        "colors-accent-3-200": 1,
        "colors-accent-3-300": 1,
        "colors-accent-3-400": 1,
        "colors-accent-3-500": 1,
        "colors-accent-3-600": 1,
        "colors-accent-3-700": 1,
        "colors-accent-4-400_storage": 1,
        "colors-accessible-accent-1-100": 1,
        "colors-accessible-accent-1-200": 1,
        "colors-accessible-accent-1-300": 1,
        "colors-accessible-accent-1-400": 1,
        "colors-accessible-accent-1-500": 1,
        "colors-accessible-accent-1-600": 1,
        "colors-accessible-accent-1-700": 1,
        "colors-accessible-accent-2-100": 1,
        "colors-accessible-accent-2-200": 1,
        "colors-accessible-accent-2-300": 1,
        "colors-accessible-accent-2-400": 1,
        "colors-accessible-accent-2-500": 1,
        "colors-accessible-accent-2-600": 1,
        "colors-accessible-accent-2-700": 1,
        "colors-accessible-brand-2-100": 1,
        "colors-accessible-brand-2-200": 1,
        "colors-accessible-brand-2-300": 1,
        "colors-accessible-brand-2-400": 1,
        "colors-accessible-brand-2-500": 1,
        "colors-accessible-brand-2-600": 1,
        "colors-accessible-brand-2-700": 1
      }
    },
    "Corporate": {
      "colors": {
        "components-accessibility-bar-color-bg": "#a82220",
        "components-accessibility-bar-color-text": "#ffffff",
        "components-accordion-color-bg": "#ffffff",
        "components-accordion-color-title": "#000000",
        "components-accordion-color-body": "#121111",
        "components-accordion-color-section-section-bg": "#ffffff",
        "components-accordion-color-section-section-title": "#000000",
        "components-accordion-color-section-section-body": "#121111",
        "components-accordion-color-item-item-bg": "#ffffff",
        "components-accordion-color-item-divider": "#eae8e8",
        "components-accordion-color-item-icon-icon-default": "#0b40a3",
        "components-accordion-color-item-icon-icon-hover": "#ffffff",
        "components-accordion-color-item-icon-icon-pressed": "#ffffff",
        "components-accordion-color-item-icon-icon-active": "#ffffff",
        "components-accordion-color-item-title-item-default": "#0b40a3",
        "components-accordion-color-item-title-item-hover": "#ffffff",
        "components-accordion-color-item-title-item-pressed": "#ffffff",
        "components-accordion-color-item-title-item-active": "#ffffff",
        "components-accordion-color-content-bg": "#ffffff",
        "components-accordion-color-content-title": "#000000",
        "components-accordion-color-content-body": "#121111",
        "components-article-card-color-bg": "#f0f2f7",
        "components-article-card-color-border": "#f0f2f7",
        "components-article-card-color-content-bg": "#f0f2f7",
        "components-article-card-color-title": "#000000",
        "components-article-card-color-subtitle": "#121111",
        "components-article-card-color-copy": "#121111",
        "components-article-card-color-description": "#121111",
        "components-article-card-listing-color-default-bg": "#ffffff",
        "components-article-card-listing-color-default-title": "#000000",
        "components-article-card-listing-color-default-copy": "#121111",
        "components-article-hero-color-content-bg": "#ffffff",
        "components-article-hero-color-bg": "#ffffff",
        "components-article-hero-color-headline": "#000000",
        "components-article-hero-color-body": "#121111",
        "components-article-hero-color-detail-text": "#121111",
        "components-article-hero-color-divider": "#aba8a6",
        "components-article-hero-color-icon": "#d92c29",
        "components-article-hero-color-play-icon": "#ffffff",
        "components-article-hero-color-selected-stroke": "#d92c29",
        "components-banner-color-bg-default": "#f0f2f7",
        "components-banner-color-icon-default": "#d92c29",
        "components-banner-color-title-default": "#121111",
        "components-banner-color-body-default": "#373432",
        "components-banner-color-icon-close-default": "#121111",
        "components-banner-color-bg-alert": "#b32b30",
        "components-banner-color-icon-alert": "#ffffff",
        "components-banner-color-title-alert": "#ffffff",
        "components-banner-color-body-alert": "#ffffff",
        "components-banner-color-icon-close-alert": "#ffffff",
        "components-breadcrumb-color-bg": "#ffffff",
        "components-breadcrumb-color-breadcrumb-link-default": "#0b40a3",
        "components-breadcrumb-color-breadcrumb-link-hover": "#09327e",
        "components-breadcrumb-color-breadcrumb-link-active": "#121111",
        "components-breadcrumb-color-icon-divider": "#5b5654",
        "components-bug-finder-prompt": "#ffffff",
        "components-button-color-filled-brand-default-bg": "#000000",
        "components-button-color-filled-brand-default-text": "#ffffff",
        "components-button-color-filled-brand-default-stroke": "#000000",
        "components-button-color-filled-brand-default-icon": "#ffffff",
        "components-button-color-filled-brand-hover-bg": "#c22725",
        "components-button-color-filled-brand-hover-text": "#ffffff",
        "components-button-color-filled-brand-hover-stroke": "#c22725",
        "components-button-color-filled-brand-hover-icon": "#ffffff",
        "components-button-color-filled-brand-pressed-bg": "#a82220",
        "components-button-color-filled-brand-pressed-text": "#ffffff",
        "components-button-color-filled-brand-pressed-stroke": "#a82220",
        "components-button-color-filled-brand-pressed-icon": "#ffffff",
        "components-button-color-filled-brand-disabled-bg": "rgba(0, 0, 0, 0.38)",
        "components-button-color-filled-brand-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-disabled-stroke": "rgba(0, 0, 0, 0.38)",
        "components-button-color-filled-brand-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-focus-bg": "#000000",
        "components-button-color-filled-brand-focus-text": "#ffffff",
        "components-button-color-filled-brand-focus-stroke": "#000000",
        "components-button-color-filled-brand-focus-icon": "#ffffff",
        "components-button-color-filled-brand-focus-outline": "#286efa",
        "components-button-color-filled-tonal-default-bg": "#f0f2f7",
        "components-button-color-filled-tonal-default-text": "#0b40a3",
        "components-button-color-filled-tonal-default-stroke": "#0b40a3",
        "components-button-color-filled-tonal-default-icon": "#0b40a3",
        "components-button-color-filled-tonal-hover-bg": "#c6cadf",
        "components-button-color-filled-tonal-hover-text": "#072867",
        "components-button-color-filled-tonal-hover-stroke": "#072867",
        "components-button-color-filled-tonal-hover-icon": "#072867",
        "components-button-color-filled-tonal-pressed-bg": "#c6cadf",
        "components-button-color-filled-tonal-pressed-text": "#072867",
        "components-button-color-filled-tonal-pressed-stroke": "#072867",
        "components-button-color-filled-tonal-pressed-icon": "#072867",
        "components-button-color-filled-tonal-disabled-bg": "rgba(228, 230, 239, 0.38)",
        "components-button-color-filled-tonal-disabled-text": "rgba(7, 40, 103, 0.38)",
        "components-button-color-filled-tonal-disabled-stroke": "rgba(228, 230, 239, 0.38)",
        "components-button-color-filled-tonal-disabled-icon": "rgba(7, 40, 103, 0.38)",
        "components-button-color-filled-tonal-focus-bg": "#f0f2f7",
        "components-button-color-filled-tonal-focus-text": "#0b40a3",
        "components-button-color-filled-tonal-focus-stroke": "#0b40a3",
        "components-button-color-filled-tonal-focus-icon": "#0b40a3",
        "components-button-color-filled-tonal-focus-outline": "#1c4cae",
        "components-button-color-filled-white-default-bg": "#ffffff",
        "components-button-color-filled-white-default-text": "#000000",
        "components-button-color-filled-white-default-stroke": "#ffffff",
        "components-button-color-filled-white-default-icon": "#000000",
        "components-button-color-filled-white-hover-bg": "#eae8e8",
        "components-button-color-filled-white-hover-text": "#000000",
        "components-button-color-filled-white-hover-stroke": "#c6cadf",
        "components-button-color-filled-white-hover-icon": "#000000",
        "components-button-color-filled-white-pressed-bg": "#aba8a6",
        "components-button-color-filled-white-pressed-text": "#000000",
        "components-button-color-filled-white-pressed-stroke": "#c6cadf",
        "components-button-color-filled-white-pressed-icon": "#000000",
        "components-button-color-filled-white-disabled-bg": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-text": "rgba(0, 0, 0, 0.38)",
        "components-button-color-filled-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-icon": "rgba(0, 0, 0, 0.38)",
        "components-button-color-filled-white-focus-bg": "#ffffff",
        "components-button-color-filled-white-focus-text": "#000000",
        "components-button-color-filled-white-focus-stroke": "#ffffff",
        "components-button-color-filled-white-focus-icon": "#000000",
        "components-button-color-filled-white-focus-outline": "#1c4cae",
        "components-button-color-outline-brand-default-bg": "rgba(0, 83, 180, 0.00)",
        "components-button-color-outline-brand-default-text": "#0b40a3",
        "components-button-color-outline-brand-default-stroke": "#0b40a3",
        "components-button-color-outline-brand-default-icon": "#0b40a3",
        "components-button-color-outline-brand-hover-bg": "#c22725",
        "components-button-color-outline-brand-hover-text": "#ffffff",
        "components-button-color-outline-brand-hover-stroke": "#c22725",
        "components-button-color-outline-brand-hover-icon": "#ffffff",
        "components-button-color-outline-brand-pressed-bg": "#a82220",
        "components-button-color-outline-brand-pressed-text": "#ffffff",
        "components-button-color-outline-brand-pressed-stroke": "#a82220",
        "components-button-color-outline-brand-pressed-icon": "#ffffff",
        "components-button-color-outline-brand-disabled-bg": "rgba(0, 83, 180, 0.00)",
        "components-button-color-outline-brand-disabled-text": "rgba(0, 83, 180, 0.38)",
        "components-button-color-outline-brand-disabled-stroke": "rgba(0, 83, 180, 0.38)",
        "components-button-color-outline-brand-disabled-icon": "rgba(0, 83, 180, 0.38)",
        "components-button-color-outline-brand-focus-bg": "#ffffff",
        "components-button-color-outline-brand-focus-text": "#0b40a3",
        "components-button-color-outline-brand-focus-stroke": "#0b40a3",
        "components-button-color-outline-brand-focus-icon": "#0b40a3",
        "components-button-color-outline-brand-focus-outline": "#286efa",
        "components-button-color-outline-tonal-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-tonal-default-text": "#09327e",
        "components-button-color-outline-tonal-default-stroke": "#c6cadf",
        "components-button-color-outline-tonal-default-icon": "#09327e",
        "components-button-color-outline-tonal-hover-bg": "#c6cadf",
        "components-button-color-outline-tonal-hover-text": "#072867",
        "components-button-color-outline-tonal-hover-stroke": "#09327e",
        "components-button-color-outline-tonal-hover-icon": "#072867",
        "components-button-color-outline-tonal-pressed-bg": "#09327e",
        "components-button-color-outline-tonal-pressed-text": "#ffffff",
        "components-button-color-outline-tonal-pressed-stroke": "#09327e",
        "components-button-color-outline-tonal-pressed-icon": "#ffffff",
        "components-button-color-outline-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-tonal-disabled-text": "rgba(7, 40, 103, 0.38)",
        "components-button-color-outline-tonal-disabled-stroke": "rgba(7, 40, 103, 0.38)",
        "components-button-color-outline-tonal-disabled-icon": "rgba(0, 83, 180, 0.38)",
        "components-button-color-outline-tonal-focus-bg": "#ffffff",
        "components-button-color-outline-tonal-focus-text": "#09327e",
        "components-button-color-outline-tonal-focus-stroke": "#c6cadf",
        "components-button-color-outline-tonal-focus-icon": "#09327e",
        "components-button-color-outline-tonal-focus-outline": "#286efa",
        "components-button-color-outline-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-default-text": "#ffffff",
        "components-button-color-outline-white-default-stroke": "#ffffff",
        "components-button-color-outline-white-default-icon": "#ffffff",
        "components-button-color-outline-white-hover-bg": "#ffffff",
        "components-button-color-outline-white-hover-text": "#000000",
        "components-button-color-outline-white-hover-stroke": "#ffffff",
        "components-button-color-outline-white-hover-icon": "#000000",
        "components-button-color-outline-white-pressed-bg": "#eae8e8",
        "components-button-color-outline-white-pressed-text": "#000000",
        "components-button-color-outline-white-pressed-stroke": "#eae8e8",
        "components-button-color-outline-white-pressed-icon": "#000000",
        "components-button-color-outline-white-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-focus-bg": "rgba(255, 255, 255, 0.01)",
        "components-button-color-outline-white-focus-text": "#ffffff",
        "components-button-color-outline-white-focus-stroke": "#ffffff",
        "components-button-color-outline-white-focus-icon": "#ffffff",
        "components-button-color-outline-white-focus-outline": "#286efa",
        "components-button-color-text-brand-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-default-text": "#0b40a3",
        "components-button-color-text-brand-default-stroke": "#0b40a3",
        "components-button-color-text-brand-default-icon": "#0b40a3",
        "components-button-color-text-brand-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-hover-bg-icon-only": "#f8e5e5",
        "components-button-color-text-brand-hover-text": "#000000",
        "components-button-color-text-brand-hover-stroke": "#d92c29",
        "components-button-color-text-brand-hover-icon": "#000000",
        "components-button-color-text-brand-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-pressed-bg-icon-only": "#f1c7c7",
        "components-button-color-text-brand-pressed-text": "#000000",
        "components-button-color-text-brand-pressed-stroke": "#000000",
        "components-button-color-text-brand-pressed-icon": "#000000",
        "components-button-color-text-brand-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-disabled-text": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-brand-disabled-stroke": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-brand-disabled-icon": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-brand-focus-bg": "#d5e3ff",
        "components-button-color-text-brand-focus-text": "#09327e",
        "components-button-color-text-brand-focus-stroke": "#09327e",
        "components-button-color-text-brand-focus-icon": "#1c4cae",
        "components-button-color-text-brand-focus-outline": "#1c4cae",
        "components-button-color-text-tonal-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-default-text": "#0b40a3",
        "components-button-color-text-tonal-default-stroke": "#0b40a3",
        "components-button-color-text-tonal-default-icon": "#0b40a3",
        "components-button-color-text-tonal-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-hover-bg-icon-only": "#e4e6ef",
        "components-button-color-text-tonal-hover-text": "#09327e",
        "components-button-color-text-tonal-hover-stroke": "#0b40a3",
        "components-button-color-text-tonal-hover-icon": "#09327e",
        "components-button-color-text-tonal-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-pressed-bg-icon-only": "#c6cadf",
        "components-button-color-text-tonal-pressed-text": "#072867",
        "components-button-color-text-tonal-pressed-stroke": "#09327e",
        "components-button-color-text-tonal-pressed-icon": "#072867",
        "components-button-color-text-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-disabled-text": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-tonal-disabled-stroke": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-tonal-disabled-icon": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-tonal-focus-bg": "#d5e3ff",
        "components-button-color-text-tonal-focus-text": "#09327e",
        "components-button-color-text-tonal-focus-stroke": "#09327e",
        "components-button-color-text-tonal-focus-icon": "#1c4cae",
        "components-button-color-text-tonal-focus-outline": "#1c4cae",
        "components-button-color-text-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-default-text": "#ffffff",
        "components-button-color-text-white-default-stroke": "#ffffff",
        "components-button-color-text-white-default-icon": "#ffffff",
        "components-button-color-text-white-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-hover-bg-icon-only": "#72757e",
        "components-button-color-text-white-hover-text": "#ffffff",
        "components-button-color-text-white-hover-stroke": "#ffffff",
        "components-button-color-text-white-hover-icon": "#ffffff",
        "components-button-color-text-white-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-pressed-bg-icon-only": "#5b5654",
        "components-button-color-text-white-pressed-text": "#ffffff",
        "components-button-color-text-white-pressed-stroke": "#ffffff",
        "components-button-color-text-white-pressed-icon": "#ffffff",
        "components-button-color-text-white-disabled-bg": "rgba(115, 119, 128, 0.00)",
        "components-button-color-text-white-disabled-text": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-stroke": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-icon": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-focus-bg": "#d5e3ff",
        "components-button-color-text-white-focus-text": "#ffffff",
        "components-button-color-text-white-focus-stroke": "#ffffff",
        "components-button-color-text-white-focus-icon": "#1c4cae",
        "components-button-color-text-white-focus-outline": "#1c4cae",
        "components-button-group-color-border": "#eae8e8",
        "components-button-group-color-button-bg-default": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-button-fg-default": "#0b40a3",
        "components-button-group-color-button-bg-hover": "#c6cadf",
        "components-button-group-color-button-fg-hover": "#072867",
        "components-button-group-color-button-bg-active": "#000000",
        "components-button-group-color-button-fg-active": "#ffffff",
        "components-button-group-color-overlay-left-gradient-fill-1": "#ffffff",
        "components-button-group-color-overlay-left-gradient-fill-2": "rgba(255, 255, 255, 0.50)",
        "components-button-group-color-stroke-default-gradient-fill-1": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-2": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-3": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-4": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-1": "#c6cadf",
        "components-button-group-color-stroke-hover-gradient-fill-2": "#c6cadf",
        "components-button-group-color-stroke-hover-gradient-fill-3": "#c6cadf",
        "components-button-group-color-stroke-hover-gradient-fill-4": "#c6cadf",
        "components-button-group-color-stroke-active-gradient-fill-1": "#000000",
        "components-button-group-color-stroke-active-gradient-fill-2": "#000000",
        "components-button-group-color-stroke-active-gradient-fill-3": "#000000",
        "components-button-group-color-stroke-active-gradient-fill-4": "#000000",
        "components-category-card-color-bg": "#f0f2f7",
        "components-category-card-color-content-bg": "#ffffff",
        "components-category-card-color-category-bg": "#f0f2f7",
        "components-category-card-color-category-bg-2": "#f0f2f7",
        "components-category-card-color-category-bg-3": "#f0f2f7",
        "components-category-card-color-category-bg-4": "#f0f2f7",
        "components-category-card-color-category-bg-5": "#f0f2f7",
        "components-category-card-color-category-bg-6": "#f0f2f7",
        "components-category-card-color-title": "#000000",
        "components-category-card-color-copy": "#121111",
        "components-category-card-color-stroke": "#f0f2f7",
        "components-category-card-color-stroke-hover": "#d92c29",
        "components-category-card-listing-color-bg": "#ffffff",
        "components-category-card-listing-color-title": "#000000",
        "components-category-card-listing-color-copy": "#121111",
        "components-card-search-result-color-bg": "#ffffff",
        "components-card-search-result-color-label": "#121111",
        "components-card-search-result-color-title": "#000000",
        "components-card-search-result-color-body": "#121111",
        "components-card-search-result-color-divider-line": "#f0f2f7",
        "components-contact-us-promo-color-light-bg": "#c22725",
        "components-contact-us-promo-color-light-title": "#ffffff",
        "components-contact-us-promo-color-light-body": "#f0f2f7",
        "components-contact-us-promo-color-default-bg": "#f0f2f7",
        "components-contact-us-promo-color-default-title": "#000000",
        "components-contact-us-promo-color-default-body": "#121111",
        "components-contact-us-promo-color-brand-bg": "#000000",
        "components-contact-us-promo-color-brand-title": "#ffffff",
        "components-contact-us-promo-color-brand-body": "#f0f2f7",
        "components-content-list-color-bg": "#ffffff",
        "components-content-list-color-title": "#000000",
        "components-content-list-color-body": "#121111",
        "components-content-list-color-icon": "#c22725",
        "components-cookies-colors-bg": "#ffffff",
        "components-cookies-colors-title": "#000000",
        "components-cookies-colors-body": "#121111",
        "components-cookies-colors-icon": "#c22725",
        "components-dropdown-bg": "#ffffff",
        "components-dropdown-border-default": "#72757e",
        "components-dropdown-border-hover": "#d92c29",
        "components-dropdown-border-focused": "#000000",
        "components-dropdown-border-destructive": "#b32b30",
        "components-dropdown-label-text-default": "#121111",
        "components-dropdown-item-text-default": "#121111",
        "components-dropdown-item-bg-default": "#ffffff",
        "components-dropdown-item-bg-hover": "#e4e6ef",
        "components-dropdown-input-field-default": "#72757e",
        "components-dropdown-input-field-focused": "#121111",
        "components-dropdown-input-label-focused": "#000000",
        "components-dropdown-input-field-destructive": "#b32b30",
        "components-external-link-card-bg": "#f0f2f7",
        "components-external-link-card-card-bg": "#ffffff",
        "components-external-link-card-card-title": "#000000",
        "components-external-link-card-card-subtitle": "#121111",
        "components-external-link-card-card-eyebrow": "#5b5654",
        "components-fab-color-border-default": "#ffffff",
        "components-footer-brand-color-bg": "#000000",
        "components-footer-brand-color-logo": "#ffffff",
        "components-footer-brand-color-title-text": "#ffffff",
        "components-footer-brand-color-description-text": "#f0f2f7",
        "components-footer-brand-color-footer-menu-expanded": "#000000",
        "components-footer-brand-color-link-default": "#ffffff",
        "components-footer-brand-color-link-hover": "#fbf2f2",
        "components-footer-brand-color-link-pressed": "#f8e5e5",
        "components-footer-brand-color-category-link-default": "#ffffff",
        "components-footer-brand-color-category-link-hover": "#f0f2f7",
        "components-footer-brand-color-category-link-pressed": "#eae8e8",
        "components-footer-brand-color-subcategory-link-default": "#f0f2f7",
        "components-footer-brand-color-subcategory-link-hover": "#fbf2f2",
        "components-footer-brand-color-subcategory-link-pressed": "#f1c7c7",
        "components-footer-brand-color-icon-icon-default": "#ffffff",
        "components-footer-brand-color-icon-icon-pressed": "#eae8e8",
        "components-footer-brand-color-social-icon-default": "#ffffff",
        "components-footer-brand-color-social-icon-hover": "#f8e5e5",
        "components-footer-brand-color-social-icon-pressed": "#c22725",
        "components-footer-brand-spacing-category-link-hover": "#000000",
        "components-footer-global-color-bg": "#ffffff",
        "components-footer-global-color-title-text": "#000000",
        "components-footer-global-color-disclaimer-text": "#000000",
        "components-footer-global-color-link-default": "#000000",
        "components-footer-global-color-link-hover": "#000000",
        "components-footer-global-color-link-pressed": "#000000",
        "components-generic-card-color-bg": "#000000",
        "components-generic-card-color-content-bg": "#000000",
        "components-generic-card-color-title": "#ffffff",
        "components-generic-card-color-subtitle": "#f0f2f7",
        "components-generic-card-color-copy": "#f0f2f7",
        "components-global-search-color-omnibox-list-bg": "#ffffff",
        "components-global-search-color-omnibox-divider": "#aba8a6",
        "components-global-search-color-omnibox-item-bg-default": "#ffffff",
        "components-global-search-color-omnibox-item-bg-hover": "#f0f2f7",
        "components-global-search-color-omnibox-item-text-default": "#0b40a3",
        "components-global-search-color-omnibox-item-text-hover": "#09327e",
        "components-global-search-color-omnibox-label-text-default": "#121111",
        "components-global-search-color-omnibox-category-text-default": "#121111",
        "components-generic-card-listing-color-bg": "#ffffff",
        "components-generic-card-listing-color-title": "#000000",
        "components-generic-card-listing-color-copy": "#121111",
        "components-header-color-bg": "#ffffff",
        "components-header-color-bottom-stroke": "#f0f2f7",
        "components-header-color-search-search-border-default": "#121111",
        "components-header-color-search-search-text": "#5b5654",
        "components-header-color-search-search-icon": "#d92c29",
        "components-header-color-search-search-bar": "#121111",
        "components-header-color-mobile-mobile-expanded-bg": "#ffffff",
        "components-header-color-mobile-pressed-bg": "#ffffff",
        "components-header-color-language-selector-language": "#5b5654",
        "components-header-color-language-selector-country": "#000000",
        "components-header-color-language-selector-country-hover-bg": "#f0f2f7",
        "components-header-color-language-selector-megamenu-language": "#5b5654",
        "components-header-color-language-selector-megamenu-country": "#000000",
        "components-header-color-language-selector-megamenu-country-hover": "#f0f2f7",
        "components-header-color-language-selector-bg": "#ffffff",
        "components-header-color-language-selector-stroke": "#72757e",
        "components-header-color-mega-menu-bg": "#f0f2f7",
        "components-header-color-mega-menu-featured-bg": "#000000",
        "components-header-color-mega-menu-featured-title": "#ffffff",
        "components-header-color-mega-menu-category-title": "#121111",
        "components-header-color-mega-menu-bg-accent": "#ffffff",
        "components-header-color-icon-icon-default": "#000000",
        "components-header-color-icon-icon-hover": "#c22725",
        "components-header-color-icon-icon-pressed": "#a82220",
        "components-header-color-links-nav-main-link-default": "#000000",
        "components-header-color-links-nav-main-link-hover": "#d92c29",
        "components-header-color-links-nav-main-link-pressed": "#c22725",
        "components-header-color-links-nav-main-link-active": "#a82220",
        "components-header-color-links-nav-main-active-bar": "#d92c29",
        "components-header-color-links-nav-main-bg-color": "#ffffff",
        "components-header-color-links-nav-category-link-category-default": "#121111",
        "components-header-color-links-nav-category-link-category-hover": "#c22725",
        "components-header-color-links-nav-category-link-category-pressed": "#c22725",
        "components-header-color-links-nav-category-link-category-active": "#a82220",
        "components-header-color-links-nav-menu-link-link-default": "#000000",
        "components-header-color-links-nav-menu-link-link-hover": "#c22725",
        "components-header-color-links-nav-menu-link-link-pressed": "#c22725",
        "components-header-color-links-nav-menu-link-link-active": "#a82220",
        "components-header-color-links-nav-menu-link-bg": "#f0f2f7",
        "components-header-color-links-nav-subcategory-link-subcategory-default": "#121111",
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": "#c22725",
        "components-header-color-links-nav-subcategory-link-subcategory-active": "#a82220",
        "components-hero-homepage-color-bg": "#ffffff",
        "components-hero-homepage-color-container-bg": "#ffffff",
        "components-hero-homepage-color-eyebrow": "#72757e",
        "components-hero-homepage-color-header": "#000000",
        "components-hero-homepage-color-sub-header": "#121111",
        "components-hero-homepage-color-body": "#121111",
        "components-hero-homepage-color-play-pause": "#000000",
        "components-hero-internal-color-default-title": "#ffffff",
        "components-hero-internal-color-default-body": "#f0f2f7",
        "components-hero-internal-color-default-bg": "#c22725",
        "components-hero-internal-color-accent-bg": "#c22725",
        "components-hero-internal-color-with-image-bg": "#c22725",
        "components-hero-internal-color-default-bg-2": "#c22725",
        "components-hero-internal-color-with-image-title": "#ffffff",
        "components-hero-internal-color-with-image-body": "#f0f2f7",
        "components-heading-page-title-color-default-bg": "#ffffff",
        "components-heading-page-title-color-default-title": "#000000",
        "components-heading-page-title-color-default-body": "#121111",
        "components-heading-page-title-color-light-bg": "#f0f2f7",
        "components-heading-page-title-color-light-title": "#000000",
        "components-heading-page-title-color-light-body": "#121111",
        "components-heading-page-title-color-color-bg": "#000000",
        "components-heading-page-title-color-color-title": "#ffffff",
        "components-heading-page-title-color-color-body": "#f0f2f7",
        "components-hyperlink-color-dark-default": "#d92c29",
        "components-hyperlink-color-dark-hover": "#c22725",
        "components-hyperlink-color-dark-visited": "#865ddf",
        "components-hyperlink-color-light-default": "#fbf2f2",
        "components-hyperlink-color-light-hover": "#f1c7c7",
        "components-hyperlink-color-light-visited": "#ffedc8",
        "components-icon-default": "#72757e",
        "components-icon-focused": "#72757e",
        "components-icon-card-color-bg": "#ffffff",
        "components-icon-card-color-icon-bg": "#fece2f",
        "components-icon-card-color-icon": "#ffffff",
        "components-icon-card-color-title": "#000000",
        "components-icon-card-color-subtitle": "#5b5654",
        "components-icon-card-color-description": "#121111",
        "components-icon-card-listing-color-bg": "#ffffff",
        "components-icon-card-listing-color-inner-bg": "#ffffff",
        "components-icon-card-listing-color-brand-bg": "#f0f2f7",
        "components-icon-card-listing-color-title": "#000000",
        "components-icon-card-listing-color-copy": "#121111",
        "components-icon-card-listing-color-inner-legal-text": "#121111",
        "components-jumplink-color-bg-default": "#ffffff",
        "components-jumplink-color-link-text-default": "#5b5654",
        "components-jumplink-color-link-text-hover": "#a82220",
        "components-jumplink-color-link-border-hover": "#a82220",
        "components-jumplink-color-link-text-active": "#c22725",
        "components-jumplink-color-link-border-active": "#c22725",
        "components-jumplink-color-title-text": "#000000",
        "components-jumplink-color-description-text": "#121111",
        "components-layout-bg-default": "#ffffff",
        "components-lightbox-color-overlay-bg": "rgba(255, 255, 255, 0.95)",
        "components-lightbox-color-title": "#000000",
        "components-lightbox-color-body": "#121111",
        "components-label-color-on-color-label-label-default": "#ffffff",
        "components-label-color-on-color-label-label-hover": "#5b5654",
        "components-label-color-on-color-label-label-pressed": "#373432",
        "components-label-color-on-color-background-bg-default": "rgba(114, 117, 126, 0.00)",
        "components-label-color-on-color-background-bg-hover": "#ffffff",
        "components-label-color-on-color-background-bg-pressed": "#eae8e8",
        "components-label-color-on-color-stroke-hover": "#ffffff",
        "components-label-color-on-color-stroke-pressed": "#eae8e8",
        "components-label-color-on-color-stroke-default-gradient-fill-1": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-2": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-3": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-4": "#ffffff",
        "components-label-color-on-white-label-label-default": "#5b5654",
        "components-label-color-on-white-label-label-hover": "#ffffff",
        "components-label-color-on-white-label-label-pressed": "#ffffff",
        "components-label-color-on-white-background-bg-default": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-white-background-bg-hover": "#72757e",
        "components-label-color-on-white-background-bg-pressed": "#5b5654",
        "components-label-color-on-white-stroke-hover": "#72757e",
        "components-label-color-on-white-stroke-pressed": "#5b5654",
        "components-label-color-on-white-stroke-default-gradient-fill-1": "#72757e",
        "components-label-color-on-white-stroke-default-gradient-fill-2": "#72757e",
        "components-label-color-on-white-stroke-default-gradient-fill-3": "#72757e",
        "components-label-color-on-white-stroke-default-gradient-fill-4": "#72757e",
        "components-legal-disclaimer-color-default-bg": "#ffffff",
        "components-legal-disclaimer-color-default-title": "#000000",
        "components-legal-disclaimer-color-default-body": "#121111",
        "components-legal-disclaimer-color-light-bg": "#f0f2f7",
        "components-legal-disclaimer-color-light-title": "#000000",
        "components-legal-disclaimer-color-light-body": "#121111",
        "components-legal-disclaimer-color-brand-bg": "#000000",
        "components-legal-disclaimer-color-brand-title": "#ffffff",
        "components-legal-disclaimer-color-brand-body": "#f0f2f7",
        "components-media-video-thumbnail-overlay-bg": "rgba(25, 27, 28, 0.80)",
        "components-media-caption-color-bg": "#f0f2f7",
        "components-media-caption-color-text": "#121111",
        "components-media-gallery-color-bg": "#ffffff",
        "components-media-gallery-color-title": "#000000",
        "components-media-gallery-color-body": "#121111",
        "components-pagination-on-white-accent-default": "#121111",
        "components-pagination-on-white-accent-scroll": "#0b40a3",
        "components-pagination-on-white-disabled": "#aba8a6",
        "components-pagination-on-white-bg": "rgba(171, 168, 166, 0.50)",
        "components-pagination-on-white-stroke": "#eae8e8",
        "components-pagination-on-white-bg-active": "#000000",
        "components-pagination-on-white-bg-hover": "#c6cadf",
        "components-pagination-on-white-text-default": "#72757e",
        "components-pagination-on-white-text-hover": "#121111",
        "components-pagination-on-white-text-active": "#ffffff",
        "components-pagination-on-color-accent-default": "#000000",
        "components-pagination-on-color-accent-scroll": "#ffffff",
        "components-pagination-on-color-disabled": "#991f1d",
        "components-pagination-on-color-bg": "#000000",
        "components-pagination-on-color-stroke": "rgba(255, 255, 255, 0.38)",
        "components-pagination-on-color-bg-active": "#ffffff",
        "components-pagination-on-color-bg-hover": "#c6cadf",
        "components-pagination-on-color-text-default": "#ffffff",
        "components-pagination-on-color-text-hover": "#000000",
        "components-pagination-on-color-text-active": "#000000",
        "components-pdf-link-card-color-bg": "#ffffff",
        "components-pdf-link-card-color-card-bg": "#ffffff",
        "components-pdf-link-card-color-card-title": "#000000",
        "components-pdf-link-card-color-card-subtitle": "#121111",
        "components-pdf-link-card-color-card-body": "#121111",
        "components-product-card-color-bg": "#ffffff",
        "components-product-card-color-content-bg": "#ffffff",
        "components-product-card-color-border": "rgba(255, 255, 255, 0.00)",
        "components-product-card-color-title": "#000000",
        "components-product-card-color-description": "#121111",
        "components-product-card-color-star-rating": "#c22725",
        "components-product-card-color-badge-bg": "#128437",
        "components-product-card-color-badge-text": "#ffffff",
        "components-product-card-listing-color-bg": "#f0f2f7",
        "components-product-card-listing-color-title": "#000000",
        "components-product-card-listing-color-copy": "#121111",
        "components-product-card-listing-color-legal-text": "#121111",
        "components-product-information-color-bg": "#f0f2f7",
        "components-product-information-color-content-bg": "#f0f2f7",
        "components-product-information-color-headline": "#000000",
        "components-product-information-color-body": "#121111",
        "components-product-information-color-detail-text": "#121111",
        "components-product-information-color-divider": "#aba8a6",
        "components-product-information-color-icon": "#c22725",
        "components-product-information-color-play-icon": "#ffffff",
        "components-product-information-color-selected-stroke": "#d92c29",
        "components-product-results-list-with-search-color-bg": "#f0f2f7",
        "components-promo-color-default-bg": "#f0f2f7",
        "components-promo-color-default-image-bg": "#ffffff",
        "components-promo-color-default-content-bg": "#ffffff",
        "components-promo-color-default-title": "#000000",
        "components-promo-color-default-body": "#121111",
        "components-promo-color-default-category": "#000000",
        "components-promo-color-light-bg": "#f0f2f7",
        "components-promo-color-light-image-bg": "#c22725",
        "components-promo-color-light-content-bg": "#c22725",
        "components-promo-color-light-title": "#ffffff",
        "components-promo-color-light-body": "#f0f2f7",
        "components-promo-color-light-category": "#ffffff",
        "components-promo-color-brand-bg": "#f0f2f7",
        "components-promo-color-brand-image-bg": "#000000",
        "components-promo-color-brand-content-bg": "#000000",
        "components-promo-color-brand-title": "#ffffff",
        "components-promo-color-brand-body": "#f0f2f7",
        "components-promo-color-brand-category": "#ffffff",
        "components-pull-quote-color-bg": "#fece2f",
        "components-pull-quote-color-stroke": "#fece2f",
        "components-pull-quote-color-quote-bg": "#fece2f",
        "components-pull-quote-color-quote-title": "#000000",
        "components-pull-quote-color-quote-by-text": "#121111",
        "components-pull-quote-color-quote-detail": "#121111",
        "components-pull-quote-color-quote-highlight": "#ffffff",
        "components-promo-alternate-color-default-bg": "#ffffff",
        "components-promo-alternate-color-default-image-bg": "#ffffff",
        "components-promo-alternate-color-default-content-bg": "#ffffff",
        "components-promo-alternate-color-default-title": "#000000",
        "components-promo-alternate-color-default-body": "#121111",
        "components-promo-alternate-color-default-category": "#000000",
        "components-promo-alternate-color-light-bg": "#c22725",
        "components-promo-alternate-color-light-image-bg": "#c22725",
        "components-promo-alternate-color-light-content-bg": "#c22725",
        "components-promo-alternate-color-light-title": "#ffffff",
        "components-promo-alternate-color-light-body": "#f0f2f7",
        "components-promo-alternate-color-light-category": "#ffffff",
        "components-promo-alternate-color-brand-bg": "#000000",
        "components-promo-alternate-color-brand-image-bg": "#000000",
        "components-promo-alternate-color-brand-content-bg": "#000000",
        "components-promo-alternate-color-brand-title": "#ffffff",
        "components-promo-alternate-color-brand-body": "#f0f2f7",
        "components-promo-alternate-color-brand-category": "#ffffff",
        "components-share-list-color-title": "#121111",
        "components-share-list-color-bg": "#f0f2f7",
        "components-share-list-color-link-default": "#121111",
        "components-share-list-color-link-hover": "#c22725",
        "components-share-list-color-bg-link-hover": "#eae8e8",
        "components-share-list-color-close-icon": "#191b1c",
        "components-share-list-color-facebook-icon": "#3b5998",
        "components-share-list-color-pinterest-icon": "#e60023",
        "components-share-list-color-twitter-icon": "#000000",
        "components-share-list-color-basic-icon-default": "#191b1c",
        "components-share-list-color-icon-hover": "#c22725",
        "components-share-list-color-border": "#eae8e8",
        "components-site-search-bg": "#ffffff",
        "components-site-search-results_text": "#121111",
        "components-site-search-filter-filter-selected-bg": "#fbf2f2",
        "components-site-search-facet-group-label-bottom-line": "#f0f2f7",
        "components-site-search-facet-group-label-text": "#121111",
        "components-site-search-facet-group-label-bg": "#ffffff",
        "components-site-search-facet-bg": "#ffffff",
        "components-site-search-facet-stroke": "#f0f2f7",
        "components-site-search-refine-bg": "#ffffff",
        "components-site-search-refine-right-divider": "#f0f2f7",
        "components-site-search-refine-title": "#000000",
        "components-site-search-hero-search-bg": "#c22725",
        "components-site-search-hero-search-accent-bg": "#c22725",
        "components-site-search-hero-search-title": "#ffffff",
        "components-stats-stat-text": "#c22725",
        "components-stats-stat-text-on-light": "#c22725",
        "components-stats-supporting-text": "#121111",
        "components-stats-bg-default": "#ffffff",
        "components-stats-bg-inner": "#ffffff",
        "components-stats-bg-section-inner": "#0b40a3",
        "components-stats-title": "#000000",
        "components-stats-copy": "#121111",
        "components-supporting-text-default": "#5b5654",
        "components-supporting-text-destructive": "#b32b30",
        "components-tab-bg": "#ffffff",
        "components-tab-border-default": "#72757e",
        "components-tab-border-hover": "#d92c29",
        "components-tab-border-focused": "#000000",
        "components-tab-border-destructive": "#b32b30",
        "components-tab-label-text-default": "#121111",
        "components-tab-item-text-default": "#121111",
        "components-tab-item-bg-default": "#ffffff",
        "components-tab-item-bg-hover": "#e4e6ef",
        "components-tab-input-field-default": "#72757e",
        "components-tab-input-field-focused": "#121111",
        "components-tab-input-label-focused": "#000000",
        "components-tab-input-field-destructive": "#b32b30",
        "components-tab-bg-2": "#ffffff",
        "components-tag-color-bg": "#ffffff",
        "components-tag-color-text": "#0b40a3",
        "components-tag-color-stroke": "#0b40a3",
        "components-tag-color-bg-hover": "#c22725",
        "components-tag-color-text-hover": "#ffffff",
        "components-tag-color-stroke-hover": "#c22725",
        "components-text-field-bg": "#ffffff",
        "components-text-field-bg-focused": "#f0f2f7",
        "components-text-field-border-default": "#121111",
        "components-text-field-border-hover": "#0b40a3",
        "components-text-field-border-focused": "#000000",
        "components-text-field-border-destructive": "#b32b30",
        "components-text-field-input-field-default": "#5b5654",
        "components-text-field-input-field-focused": "#121111",
        "components-text-field-input-label-focused": "#000000",
        "components-text-field-input-field-destructive": "#b32b30",
        "components-text-field-icon-default": "#72757e",
        "components-text-field-icon-destructive": "#b32b30",
        "components-text-field-supporting-text-default": "#5b5654",
        "components-text-field-supporting-text-destructive": "#b32b30",
        "components-tip-color-bg": "#f0f2f7",
        "components-tip-color-title": "#000000",
        "components-tip-color-body": "#121111",
        "components-tip-color-stroke": "#0b40a3",
        "components-video-color-bg": "#ffffff",
        "themes-general-surface-color-light-bg": "#ffffff",
        "themes-general-surface-color-light-title": "#000000",
        "themes-general-surface-color-light-body": "#121111",
        "themes-general-surface-color-light-decor-line-break": "#eae8e8",
        "themes-general-surface-color-light-rule-line": "#eae8e8",
        "themes-general-surface-color-dark-bg": "#000000",
        "themes-general-surface-color-dark-title": "#ffffff",
        "themes-general-surface-color-dark-body": "#f0f2f7",
        "themes-general-surface-color-dark-decor-line-break": "#eae8e8",
        "themes-general-card-color-dark-title": "#000000",
        "themes-general-card-color-dark-subtitle": "#121111",
        "themes-general-card-color-dark-body": "#121111",
        "themes-general-card-color-light-title": "#ffffff",
        "themes-general-card-color-light-subtitle": "#f0f2f7",
        "themes-general-card-color-light-body": "#f0f2f7",
        "themes-shadow-color": "#ffffff",
        "themes-shadow-share-list-color-fill": "rgba(0, 0, 0, 0.40)",
        "themes-shadow-header-menu-color-fill": "rgba(0, 0, 0, 0.20)",
        "themes-gradient-loader-gradient-fill-1": "#d92c29",
        "themes-gradient-loader-gradient-fill-2": "rgba(217, 44, 41, 0.00)",
        "themes-color-background-white": "#ffffff",
        "themes-color-background-light": "#f0f2f7",
        "themes-color-background-dark": "#191b1c",
        "themes-color-background-black": "#000000",
        "themes-color-background-brand1": "#c22725",
        "themes-color-background-brand2": "#0b40a3",
        "themes-color-background-accent1": "#128437",
        "themes-color-background-accent2": "#fece2f",
        "themes-color-background-body": "#ffffff",
        "themes-color-stroke-white": "#ffffff",
        "themes-color-stroke-light": "#f0f2f7",
        "themes-color-stroke-dark": "#373432",
        "themes-color-stroke-black": "#000000",
        "themes-color-stroke-brand1": "#d92c29",
        "themes-color-stroke-brand2": "#0b40a3",
        "themes-color-stroke-accent1": "#14943d",
        "themes-color-stroke-accent2": "#fece2f",
        "themes-color-text-white": "#ffffff",
        "themes-color-text-light": "#f0f2f7",
        "themes-color-text-dark": "#121111",
        "themes-color-text-black": "#000000",
        "themes-color-text-brand1": "#c22725",
        "themes-color-text-brand2": "#0b40a3",
        "themes-color-text-accent1": "#0d5e27",
        "themes-color-text-accent2": "#c5a024",
        "colors-grayscale-100": "#f0f2f7",
        "colors-grayscale-200": "#eae8e8",
        "colors-grayscale-300": "#aba8a6",
        "colors-grayscale-400": "#72757e",
        "colors-grayscale-500": "#5b5654",
        "colors-grayscale-600": "#373432",
        "colors-grayscale-700": "#121111",
        "colors-brand-1-100": "#fbf2f2",
        "colors-brand-1-200": "#f8e5e5",
        "colors-brand-1-300": "#f1c7c7",
        "colors-brand-1-400": "#d92c29",
        "colors-brand-1-500": "#c22725",
        "colors-brand-1-600": "#a82220",
        "colors-brand-1-700": "#991f1d",
        "colors-brand-2-100": "#f2f3f7",
        "colors-brand-2-200": "#e4e6ef",
        "colors-brand-2-300": "#c6cadf",
        "colors-brand-2-400": "#0b40a3",
        "colors-brand-2-500": "#09327e",
        "colors-brand-2-600": "#072867",
        "colors-brand-2-700": "#051d49",
        "colors-accent-1-100": "#f2f6f3",
        "colors-accent-1-200": "#e4ede6",
        "colors-accent-1-300": "#a2c6a8",
        "colors-accent-1-400": "#14943d",
        "colors-accent-1-500": "#128437",
        "colors-accent-1-600": "#0d5e27",
        "colors-accent-1-700": "#09421b",
        "colors-accent-2-100": "#fffbf2",
        "colors-accent-2-200": "#fff6e5",
        "colors-accent-2-300": "#ffedc8",
        "colors-accent-2-400": "#fece2f",
        "colors-accent-2-500": "#e3b82a",
        "colors-accent-2-600": "#c5a024",
        "colors-accent-2-700": "#c5a024",
        "colors-accent-3-100": "#eefaec",
        "colors-accent-3-200": "#a9e8a0",
        "colors-accent-3-300": "#68d759",
        "colors-accent-3-400": "#3aae2a",
        "colors-accent-3-500": "#319223",
        "colors-accent-3-600": "#236819",
        "colors-accent-3-700": "#153f0f",
        "colors-accent-4-400_storage": "#00aed6",
        "colors-accessible-accent-1-100": "#f5f2fc",
        "colors-accessible-accent-1-200": "#cfbff2",
        "colors-accessible-accent-1-300": "#ab8fe9",
        "colors-accessible-accent-1-400": "#865ddf",
        "colors-accessible-accent-1-500": "#6d3ad8",
        "colors-accessible-accent-1-600": "#4c21a9",
        "colors-accessible-accent-1-700": "#341672",
        "colors-accessible-accent-2-100": "#e7f8e4",
        "colors-accessible-accent-2-200": "#7ddc6f",
        "colors-accessible-accent-2-300": "#3cb42c",
        "colors-accessible-accent-2-400": "#2d8721",
        "colors-accessible-accent-2-500": "#246d1a",
        "colors-accessible-accent-2-600": "#1b5214",
        "colors-accessible-accent-2-700": "#11340c",
        "colors-accessible-brand-2-100": "#f2f3f7",
        "colors-accessible-brand-2-200": "#e4e6ef",
        "colors-accessible-brand-2-300": "#c6cadf",
        "colors-accessible-brand-2-400": "#0b40a3",
        "colors-accessible-brand-2-500": "#09327e",
        "colors-accessible-brand-2-600": "#072867",
        "colors-accessible-brand-2-700": "#051d49"
      },
      "spacing": {
        "components-accordion-spacing-large-padding-x": "5rem",
        "components-accordion-spacing-large-icon-margin-right": "2rem",
        "components-accordion-spacing-large-copy-margin-right": "2.5rem",
        "components-accordion-spacing-large-maxwidth": "59.625rem",
        "components-accordion-spacing-large-section-padding-x": "0rem",
        "components-accordion-spacing-large-section-padding-y": "2.5rem",
        "components-accordion-spacing-large-section-copy-margin-bottom": "2.5rem",
        "components-accordion-spacing-large-section-space-between": "4rem",
        "components-accordion-spacing-large-item-padding-x": "0rem",
        "components-accordion-spacing-large-item-padding-y": "1.5rem",
        "components-accordion-spacing-large-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-large-content-padding-x": "0rem",
        "components-accordion-spacing-large-content-padding-y": "2.5rem",
        "components-accordion-spacing-large-content-title-margin-bottom": "2.5rem",
        "components-accordion-spacing-large-content-content-space-between": "4rem",
        "components-accordion-spacing-large-content-content-margin-left": "1rem",
        "components-accordion-spacing-small-padding-top": "1rem",
        "components-accordion-spacing-small-padding-x": "1.5rem",
        "components-accordion-spacing-small-padding-y": "2.5rem",
        "components-accordion-spacing-small-icon-margin-right": "0.75rem",
        "components-accordion-spacing-small-title-margin-bottom": "0.5rem",
        "components-accordion-spacing-small-copy-margin-right": "2rem",
        "components-accordion-spacing-small-copy-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-padding-x": "0rem",
        "components-accordion-spacing-small-section-padding-y": "0rem",
        "components-accordion-spacing-small-section-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-space-between": "2.5rem",
        "components-accordion-spacing-small-item-padding-x": "0rem",
        "components-accordion-spacing-small-item-padding-y": "1rem",
        "components-accordion-spacing-small-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-small-content-padding-x": "0rem",
        "components-accordion-spacing-small-content-padding-y": "0.75rem",
        "components-accordion-spacing-small-content-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-content-content-space-between": "1rem",
        "components-accordion-spacing-small-content-content-margin-left": "1rem",
        "components-article-card-spacing-large-padding-image-x": "0rem",
        "components-article-card-spacing-large-padding-image-y": "0rem",
        "components-article-card-spacing-large-padding-content-x": "1.5rem",
        "components-article-card-spacing-large-padding-content-y": "1.5rem",
        "components-article-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-article-card-spacing-large-button-space-between": "1rem",
        "components-article-card-spacing-large-title-margin-bottom": "1rem",
        "components-article-card-spacing-large-list-content-gap": "1rem",
        "components-article-card-spacing-large-list-image-padding-x": "1rem",
        "components-article-card-spacing-large-list-image-padding-y": "1rem",
        "components-article-card-spacing-small-padding-image-x": "0rem",
        "components-article-card-spacing-small-padding-image-y": "0rem",
        "components-article-card-spacing-small-padding-content-x": "1.5rem",
        "components-article-card-spacing-small-padding-content-y": "1.5rem",
        "components-article-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-article-card-listing-spacing-small-padding-x": "1.5rem",
        "components-article-card-listing-spacing-small-padding-y": "2.5rem",
        "components-article-hero-spacing-large-padding-x": "0rem",
        "components-article-hero-spacing-large-padding-y": "0rem",
        "components-article-hero-spacing-large-title-margin-bottom": "2rem",
        "components-article-hero-spacing-large-label-share-margin-bottom": "2rem",
        "components-article-hero-spacing-large-author-name-margin-bottom": "0.75rem",
        "components-article-hero-spacing-large-author-thumbnail-margin-left": "1rem",
        "components-article-hero-spacing-large-details-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-space-between": "1rem",
        "components-article-hero-spacing-large-body-margin-bottom": "2rem",
        "components-article-hero-spacing-large-image-space-between": "1rem",
        "components-article-hero-spacing-large-image-margin-bottom": "1rem",
        "components-article-hero-spacing-large-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-stats-margin-bottom": "2.5rem",
        "components-article-hero-spacing-small-content-margin-top": "2.5rem",
        "components-article-hero-spacing-small-title-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-label-share-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-authorname-margin-bottom": "0.75rem",
        "components-article-hero-spacing-small-authorthumbnail-margin-left": "1rem",
        "components-article-hero-spacing-small-details-margin-bottom": "1rem",
        "components-article-hero-spacing-small-space-between": "0.75rem",
        "components-article-hero-spacing-small-body-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-image-space-between": "0.75rem",
        "components-article-hero-spacing-small-image-margin-bottom": "1rem",
        "components-article-hero-spacing-small-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-stats-margin-bottom": "2rem",
        "components-banner-spacing-large-content-padding-x": "1.5rem",
        "components-banner-spacing-large-content-space-between": "1rem",
        "components-banner-spacing-large-content-width": "69.375rem",
        "components-banner-spacing-icon-dimensions": "1.5rem",
        "components-banner-spacing-icon-margin-left": "0.5rem",
        "components-banner-spacing-small-space-between": "1rem",
        "components-banner-spacing-small-content-space-between": "1rem",
        "components-banner-spacing-small-title-margin-bottom": "0.75rem",
        "components-banner-spacing-small-content-margin-bottom": "1rem",
        "components-breadcrumb-spacing-large-space-between": "0.25rem",
        "components-breadcrumb-spacing-small-space-between": "0.25rem",
        "components-button-spacing-large-icon-large-x": "2.5rem",
        "components-button-spacing-large-icon-large-y": "2.5rem",
        "components-button-spacing-large-default-padding-y": "0.75rem",
        "components-button-spacing-large-default-padding-x": "1.5rem",
        "components-button-spacing-large-default-space-between": "0.25rem",
        "components-button-spacing-large-icon-left-padding-left": "1rem",
        "components-button-spacing-large-icon-right-padding-right": "1rem",
        "components-button-spacing-large-icon-only-padding-x": "0.75rem",
        "components-button-spacing-large-icon-only-padding-y": "0.75rem",
        "components-button-spacing-large-tertiary-content-height": "1.5rem",
        "components-button-spacing-compressed-icon-large-x": "2.5rem",
        "components-button-spacing-compressed-icon-large-y": "2.5rem",
        "components-button-spacing-compressed-default-padding-y": "0.5rem",
        "components-button-spacing-compressed-default-padding-x": "1rem",
        "components-button-spacing-compressed-default-space-between": "0.125rem",
        "components-button-spacing-compressed-icon-left-padding-left": "0.5rem",
        "components-button-spacing-compressed-icon-right-padding-right": "0.5rem",
        "components-button-spacing-compressed-icon-only-padding-x": "0.5rem",
        "components-button-group-spacing-padding-y": "0.125rem",
        "components-button-group-spacing-padding-x": "0.25rem",
        "components-button-group-spacing-space-between": "0.5rem",
        "components-category-card-spacing-large-padding-top": "0rem",
        "components-category-card-spacing-large-padding-x": "0rem",
        "components-category-card-spacing-large-padding-y": "0rem",
        "components-category-card-spacing-large-padding-image-x": "0rem",
        "components-category-card-spacing-large-padding-image-y": "0rem",
        "components-category-card-spacing-large-padding-content-x": "2rem",
        "components-category-card-spacing-large-padding-content-y": "2rem",
        "components-category-card-listing-spacing-large-padding-y": "5rem",
        "components-category-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-category-card-listing-spacing-large-card-margin-bottom": "0rem",
        "components-category-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-card-search-result-small-padding-top": "0rem",
        "components-card-search-result-small-padding-x": "0rem",
        "components-card-search-result-small-padding-y": "0rem",
        "components-card-search-result-small-padding-image-x": "0rem",
        "components-card-search-result-small-padding-image-y": "0rem",
        "components-card-search-result-small-padding-content-x": "1.5rem",
        "components-card-search-result-small-padding-content-y": "1.5rem",
        "components-card-search-result-spacing-large-padding-x": "0rem",
        "components-card-search-result-spacing-large-padding-y": "1.5rem",
        "components-card-search-result-spacing-large-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-image-margin-right": "1.5rem",
        "components-card-search-result-spacing-small-padding-x": "1.5rem",
        "components-card-search-result-spacing-small-padding-y": "1.5rem",
        "components-card-search-result-spacing-small-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-image-margin-right": "0.75rem",
        "components-contact-us-promo-spacing-large-padding-x": "5rem",
        "components-contact-us-promo-spacing-large-padding-y": "5rem",
        "components-contact-us-promo-spacing-large-content-margin-right": "5rem",
        "components-contact-us-promo-spacing-large-margin-y": "2.5rem",
        "components-contact-us-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-contact-us-promo-spacing-small-margin-y": "2.5rem",
        "components-content-list-list-item-container-padding-x": "0.75rem",
        "components-content-list-list-item-container-padding-y": "1rem",
        "components-content-list-list-item-content-space-between": "0.5rem",
        "components-content-list-list-item-content-default-padding-y": "0.25rem",
        "components-content-list-list-item-content-hyperlink-padding-y": "0rem",
        "components-content-list-spacing-large-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-large-item-title-margin-bottom": "1rem",
        "components-content-list-spacing-large-item-copy-margin-bottom": "2.5rem",
        "components-content-list-spacing-large-item-icon-copy-margin-top": "0rem",
        "components-content-list-spacing-large-item-margin-bottom": "2rem",
        "components-content-list-spacing-large-item-image-margin-bottom": "0.5rem",
        "components-content-list-spacing-large-padding-x": "2.5rem",
        "components-content-list-spacing-small-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-y": "2.5rem",
        "components-content-list-spacing-small-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-small-item-title-margin-bottom": "0.5rem",
        "components-content-list-spacing-small-item-copy-margin-bottom": "1rem",
        "components-content-list-spacing-small-item-margin-bottom": "2rem",
        "components-content-list-spacing-small-header-title-margin-bottom": "2rem",
        "components-content-list-spacing-small-header-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-title-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-button-space-between": "1rem",
        "components-dropdown-item-padding-y": "0.75rem",
        "components-dropdown-item-padding-x": "0.75rem",
        "components-dropdown-item-spacing-x": "0.75rem",
        "components-dropdown-input-padding-y": "0.25rem",
        "components-dropdown-input-padding-x": "1rem",
        "components-footer-brand-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-large-column-margin-right": "2rem",
        "components-footer-brand-spacing-large-column-margin-bottom": "4rem",
        "components-footer-brand-spacing-large-link-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-padding-y": "2.5rem",
        "components-footer-brand-spacing-small-padding-x": "1rem",
        "components-footer-brand-spacing-small-logo-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-column-margin-right": "0.125rem",
        "components-footer-brand-spacing-small-column-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-small-link-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-title-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-text-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-footer-link-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-link-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-link-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-link-minheight": "3rem",
        "components-footer-brand-spacing-small-footer-category-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-category-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-category-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-category-minheight": "3rem",
        "components-footer-global-spacing-large-padding-y": "2.5rem",
        "components-footer-global-spacing-large-padding-x": "5rem",
        "components-footer-global-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-global-spacing-large-column-margin-right": "2rem",
        "components-footer-global-spacing-large-link-margin-bottom": "2rem",
        "components-footer-global-spacing-small-padding-y": "2.5rem",
        "components-footer-global-spacing-small-padding-x": "1rem",
        "components-footer-global-spacing-small-logo-margin-bottom": "1rem",
        "components-footer-global-spacing-small-column-margin-right": "1rem",
        "components-footer-global-spacing-small-link-margin-bottom": "1rem",
        "components-generic-card-spacing-large-padding-x": "0rem",
        "components-generic-card-spacing-large-padding-y": "0rem",
        "components-generic-card-spacing-large-padding-image-x": "0rem",
        "components-generic-card-spacing-large-padding-image-y": "0rem",
        "components-generic-card-spacing-large-padding-content-x": "2rem",
        "components-generic-card-spacing-large-padding-content-y": "2rem",
        "components-generic-card-spacing-large-eyebrow-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-title-margin-bottom": "1rem",
        "components-generic-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-button-space-between": "1rem",
        "components-generic-card-spacing-small-padding-x": "0rem",
        "components-generic-card-spacing-small-padding-y": "0rem",
        "components-generic-card-spacing-small-padding-image-x": "0rem",
        "components-generic-card-spacing-small-padding-image-y": "0rem",
        "components-generic-card-spacing-small-padding-content-x": "1.5rem",
        "components-generic-card-spacing-small-padding-content-y": "1.5rem",
        "components-global-search-spacing-omnibox-padding-x": "0.125rem",
        "components-global-search-spacing-omnibox-list-padding-y": "0.5rem",
        "components-global-search-spacing-omnibox-label-padding": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-item-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-height": "3rem",
        "components-generic-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-generic-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-header-shadow-x": "0rem",
        "components-header-spacing-large-padding-y": "1.5rem",
        "components-header-spacing-large-padding-x": "2rem",
        "components-header-spacing-large-links-margin-right": "1.5rem",
        "components-header-spacing-large-icons-space-between": "1.5rem",
        "components-header-spacing-large-logo-margin-right": "2rem",
        "components-header-spacing-large-space-between-search": "0.5rem",
        "components-header-spacing-large-height": "6.625rem",
        "components-header-spacing-large-language-selector-padding-x": "1rem",
        "components-header-spacing-large-language-selector-padding-y": "0.5rem",
        "components-header-spacing-large-language-selector-space-between": "0.75rem",
        "components-header-spacing-large-language-selector-country-space-between": "0.5rem",
        "components-header-spacing-large-mega-menu-padding-y": "2.5rem",
        "components-header-spacing-large-mega-menu-padding-x": "2.5rem",
        "components-header-spacing-large-mega-menu-column-margin-right": "2rem",
        "components-header-spacing-large-mega-menu-category-link-margin-bottom": "1rem",
        "components-header-spacing-large-mega-menu-link-margin-bottom": "0.5rem",
        "components-header-spacing-large-mega-menu-featured-padding-x": "2rem",
        "components-header-spacing-large-mega-menu-featured-padding-y": "2rem",
        "components-header-spacing-large-mega-menu-featured-image-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-title-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-link-space-between": "1.5rem",
        "components-header-spacing-large-mega-menu-title-margin-bottom": "2.5rem",
        "components-header-spacing-large-dropdown-padding-y": "1.5rem",
        "components-header-spacing-large-dropdown-padding-x": "1.5rem",
        "components-header-spacing-large-dropdown-margin-bottom": "0.5rem",
        "components-header-spacing-small-language-selector-padding-x": "1rem",
        "components-header-spacing-small-language-selector-padding-y": "0.5rem",
        "components-header-spacing-small-language-selector-space-between": "0.5rem",
        "components-header-spacing-small-padding-left": "1rem",
        "components-header-spacing-small-padding-right": "1rem",
        "components-header-spacing-small-padding-top": "0.5rem",
        "components-header-spacing-small-padding-bottom": "0.5rem",
        "components-header-spacing-small-nav-main-minheight": "3rem",
        "components-header-spacing-small-nav-main-padding-y": "0.5rem",
        "components-header-spacing-small-nav-main-padding-left": "1.5rem",
        "components-header-spacing-small-nav-main-padding-right": "1rem",
        "components-header-spacing-small-nav-category-minheight": "3rem",
        "components-header-spacing-small-nav-category-padding-left": "1.5rem",
        "components-header-spacing-small-nav-category-padding-right": "0.75rem",
        "components-header-spacing-small-nav-category-padding-y": "0.75rem",
        "components-header-spacing-small-nav-category-space-between": "0rem",
        "components-header-spacing-small-nav-menu-link-minheight": "3rem",
        "components-header-spacing-small-nav-menu-link-padding-right": "1.5rem",
        "components-header-spacing-small-nav-menu-link-padding-left": "2.5rem",
        "components-header-spacing-small-nav-menu-link-padding-y": "0.75rem",
        "components-header-spacing-small-mega-menu-padding-y": "0rem",
        "components-header-spacing-small-mega-menu-padding-x": "0rem",
        "components-header-spacing-small-mega-menu-category-link-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-link-margin-bottom": "0rem",
        "components-header-spacing-small-mega-menu-featured-padding-x": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-padding-y": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-image-margin-bottom": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-title-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-featured-link-space-between": "0.75rem",
        "components-header-spacing-small-mega-menu-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-container-padding": "1rem",
        "components-hero-homepage-spacing-large-padding-x": "5rem",
        "components-hero-homepage-spacing-large-padding-y": "2.5rem",
        "components-hero-homepage-spacing-large-minheight": "34.3125rem",
        "components-hero-homepage-spacing-large-content-title-margin-bottom": "2rem",
        "components-hero-homepage-spacing-large-content-margin-bottom": "2rem",
        "components-hero-homepage-spacing-large-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-copy-margin-bottom": "2.5rem",
        "components-hero-homepage-spacing-large-cta-margin-right": "1rem",
        "components-hero-homepage-spacing-large-image-height": "34.3125rem",
        "components-hero-homepage-spacing-large-image-width": "45.75rem",
        "components-hero-homepage-spacing-small-padding-x": "0.5rem",
        "components-hero-homepage-spacing-small-padding-y": "1.5rem",
        "components-hero-homepage-spacing-small-minheight": "34.3125rem",
        "components-hero-homepage-spacing-small-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-copy-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-image-height": "17.5625rem",
        "components-hero-homepage-spacing-small-image-width": "45.75rem",
        "components-hero-internal-spacing-large-padding-y": "5rem",
        "components-hero-internal-spacing-large-padding-right": "5rem",
        "components-hero-internal-spacing-large-title-margin-bottom": "1.5rem",
        "components-hero-internal-spacing-large-button-padding-y": "2rem",
        "components-hero-internal-spacing-large-text-maxwidth": "59.625rem",
        "components-hero-internal-spacing-large-minheight": "18.5rem",
        "components-hero-internal-spacing-small-padding-x": "1.5rem",
        "components-hero-internal-spacing-small-padding-right": "0rem",
        "components-hero-internal-spacing-small-button-padding-y": "1.5rem",
        "components-hero-internal-spacing-small-text-maxwidth": "30rem",
        "components-hero-internal-spacing-small-minheight": "20rem",
        "components-icon-card-spacing-large-padding-x": "0rem",
        "components-icon-card-spacing-large-padding-y": "0rem",
        "components-icon-card-spacing-large-icon-margin": "1.5rem",
        "components-icon-card-spacing-large-margin-right": "1.5rem",
        "components-icon-card-spacing-large-title-margin-bottom": "1rem",
        "components-icon-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-icon-card-spacing-large-button-space-between": "1.5rem",
        "components-icon-card-spacing-large-top-space-between": "1rem",
        "components-icon-card-spacing-small-padding-x": "1.5rem",
        "components-icon-card-spacing-small-padding-y": "1.5rem",
        "components-icon-card-spacing-small-icon-margin": "1.5rem",
        "components-icon-card-spacing-small-margin-right": "1.5rem",
        "components-icon-card-spacing-small-title-margin-bottom": "1.5rem",
        "components-icon-card-dimensions-large-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-large-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-large-big-icon-height": "5rem",
        "components-icon-card-dimensions-large-big-icon-width": "5rem",
        "components-icon-card-dimensions-large-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-large-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-large-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-large-tiny-icon-width": "2.5rem",
        "components-icon-card-dimensions-small-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-small-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-small-big-icon-height": "3.5rem",
        "components-icon-card-dimensions-small-big-icon-width": "3.5rem",
        "components-icon-card-dimensions-small-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-small-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-small-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-small-tiny-icon-width": "2.5rem",
        "components-icon-card-listing-spacing-large-padding-inner-x": "4rem",
        "components-icon-card-listing-spacing-large-padding-inner-y": "4rem",
        "components-icon-card-listing-spacing-large-card-space-between": "4rem",
        "components-icon-card-listing-spacing-large-title-margin-bottom": "1.5rem",
        "components-icon-card-listing-spacing-large-card-margin-bottom": "4rem",
        "components-icon-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-x": "1.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-y": "2.5rem",
        "components-icon-card-listing-spacing-small-title-margin-bottom": "1rem",
        "components-icon-card-listing-spacing-small-button-margin-top": "1.5rem",
        "components-jumplink-link-padding-bottom": "0.5rem",
        "components-jumplink-spacing-small-title-area-margin-bottom": "1.5rem",
        "components-jumplink-spacing-small-link-container-padding-x": "0rem",
        "components-lightbox-spacing-large-carousel-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-carousel-space-between": "1.5rem",
        "components-lightbox-spacing-large-featured-image-height": "39.25rem",
        "components-lightbox-spacing-large-featured-image-width": "39.25rem",
        "components-lightbox-spacing-large-image-height": "17.0625rem",
        "components-lightbox-spacing-large-image-width": "17.0625rem",
        "components-lightbox-spacing-small-carousel-margin-bottom": "1rem",
        "components-lightbox-spacing-small-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-small-media-details-margin-top": "1rem",
        "components-lightbox-spacing-small-image-height": "20.4375rem",
        "components-lightbox-spacing-small-image-width": "20.4375rem",
        "components-legal-disclaimer-spacing-small-ruleline-width": "20.4375rem",
        "components-media-spacing-large-padding-y": "0.5rem",
        "components-media-spacing-large-padding-x": "0.5rem",
        "components-media-gallery-large-container-grid-gap-x": "1.5rem",
        "components-media-gallery-large-container-grid-gap-y": "1.5rem",
        "components-media-gallery-small-container-grid-gap-x": "0.5rem",
        "components-media-gallery-small-container-grid-gap-y": "0.5rem",
        "components-media-gallery-spacing-small-padding-x": "1.5rem",
        "components-media-gallery-spacing-small-padding-y": "2.5rem",
        "components-media-gallery-spacing-large-padding-x": "5rem",
        "components-media-gallery-spacing-large-padding-y": "5rem",
        "components-product-card-spacing-list-content-gap": "0rem",
        "components-product-card-spacing-large-padding-image-x": "0rem",
        "components-product-card-spacing-large-padding-image-y": "0rem",
        "components-product-card-spacing-large-padding-content-x": "1.5rem",
        "components-product-card-spacing-large-padding-content-y": "1.5rem",
        "components-product-card-spacing-large-category-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-title-margin-bottom": "1rem",
        "components-product-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-button-space-between": "1rem",
        "components-product-card-spacing-small-padding-image-x": "0rem",
        "components-product-card-spacing-small-padding-image-y": "0rem",
        "components-product-card-spacing-small-padding-content-x": "1.5rem",
        "components-product-card-spacing-small-padding-content-y": "1.5rem",
        "components-product-card-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-large-padding-x": "5rem",
        "components-product-information-spacing-large-padding-y": "1.5rem",
        "components-product-information-spacing-large-title-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-label-margin-bottom": "2.5rem",
        "components-product-information-spacing-large-ratings-margin-bottom": "1rem",
        "components-product-information-spacing-large-details-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-space-between": "1rem",
        "components-product-information-spacing-large-carousel-space-between": "5rem",
        "components-product-information-spacing-large-button-margin-y": "0.5rem",
        "components-product-information-spacing-large-body-margin-top": "1.5rem",
        "components-product-information-spacing-large-image-space-between": "1rem",
        "components-product-information-spacing-large-image-margin-bottom": "1rem",
        "components-product-information-spacing-small-padding-x": "1.5rem",
        "components-product-information-spacing-small-padding-y": "0.5rem",
        "components-product-information-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-small-label-margin-bottom": "1.5rem",
        "components-product-information-spacing-small-ratings-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-details-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-space-between": "0.75rem",
        "components-product-information-spacing-small-carousel-space-between": "2.5rem",
        "components-product-information-spacing-small-button-margin-y": "0.75rem",
        "components-product-information-spacing-small-body-margin-top": "1rem",
        "components-product-information-spacing-small-image-space-between": "0.75rem",
        "components-product-information-spacing-small-image-margin-bottom": "1rem",
        "components-product-results-list-with-search-spacing-large-padding-x": "5rem",
        "components-product-results-list-with-search-spacing-large-padding-y": "5rem",
        "components-product-results-list-with-search-spacing-large-ruleline-margin-top": "2.5rem",
        "components-product-results-list-with-search-spacing-large-filter-space-between": "1rem",
        "components-product-results-list-with-search-spacing-large-card-space-between": "1.5rem",
        "components-product-results-list-with-search-spacing-large-card-container-margin-bottom": "5rem",
        "components-product-results-list-with-search-spacing-small-padding-x": "1.5rem",
        "components-product-results-list-with-search-spacing-small-padding-y": "2.5rem",
        "components-product-results-list-with-search-spacing-small-ruleline-margin-top": "1rem",
        "components-product-results-list-with-search-spacing-small-filter-padding-x": "0.5rem",
        "components-product-results-list-with-search-spacing-small-card-space-between": "1rem",
        "components-product-results-list-with-search-spacing-small-card-margin-bottom": "1.5rem",
        "components-promo-spacing-large-padding-x": "2rem",
        "components-promo-spacing-large-padding-y": "2rem",
        "components-promo-spacing-large-content-space-between": "-5rem",
        "components-promo-spacing-large-content-space-between-y": "-5rem",
        "components-promo-spacing-large-padding-content-x": "10rem",
        "components-promo-spacing-large-padding-content-condensed-x": "7.5rem",
        "components-promo-spacing-large-padding-content-y": "10rem",
        "components-promo-spacing-large-padding-content-condensed-y": "7.5rem",
        "components-promo-spacing-large-padding-image-x": "0rem",
        "components-promo-spacing-large-padding-image-y": "0rem",
        "components-promo-spacing-large-padding-image-top": "0.5rem",
        "components-promo-spacing-large-title-margin-bottom": "2rem",
        "components-promo-spacing-large-margin-y": "2rem",
        "components-promo-spacing-large-buttons-space-between": "1rem",
        "components-promo-spacing-small-padding-x": "0rem",
        "components-promo-spacing-small-padding-y": "0rem",
        "components-promo-spacing-small-padding-top": "0rem",
        "components-promo-spacing-small-padding-content-x": "2.5rem",
        "components-promo-spacing-small-padding-content-y": "1.5rem",
        "components-promo-spacing-small-padding-image-x": "1rem",
        "components-promo-spacing-small-padding-image-y": "0rem",
        "components-promo-spacing-small-padding-image-top": "1rem",
        "components-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-promo-spacing-small-margin-y": "1.5rem",
        "components-promo-minheight": "30rem",
        "components-pull-quote-spacing-large-padding-quote-x": "4rem",
        "components-pull-quote-spacing-large-padding-quote-y": "4rem",
        "components-pull-quote-spacing-large-title-margin-bottom": "2rem",
        "components-pull-quote-spacing-large-quote-margin-bottom": "4rem",
        "components-pull-quote-spacing-large-quote-space-between": "1.5rem",
        "components-pull-quote-spacing-large-detail-space-between": "0.5rem",
        "components-pull-quote-spacing-large-quote-minheight": "25rem",
        "components-pull-quote-spacing-small-padding-quote-x": "1.5rem",
        "components-pull-quote-spacing-small-padding-quote-y": "2.5rem",
        "components-pull-quote-spacing-small-title-margin-bottom": "1.5rem",
        "components-pull-quote-spacing-small-quote-margin-bottom": "2.5rem",
        "components-pull-quote-spacing-small-quote-space-between": "1rem",
        "components-pull-quote-spacing-small-detail-space-between": "1rem",
        "components-pull-quote-spacing-small-quote-minheight": "15rem",
        "components-promo-alternate-minheight": "30rem",
        "components-promo-alternate-spacing-large-padding-x": "5rem",
        "components-promo-alternate-spacing-large-padding-y": "5rem",
        "components-promo-alternate-spacing-large-content-space-between": "0rem",
        "components-promo-alternate-spacing-large-padding-content-x": "3rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-x": "3rem",
        "components-promo-alternate-spacing-large-padding-content-y": "3rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-y": "3rem",
        "components-promo-alternate-spacing-large-padding-image-x": "2rem",
        "components-promo-alternate-spacing-large-padding-image-y": "2rem",
        "components-promo-alternate-spacing-large-padding-image-top": "0.5rem",
        "components-promo-alternate-spacing-large-title-margin-bottom": "2rem",
        "components-promo-alternate-spacing-small-padding-x": "0rem",
        "components-promo-alternate-spacing-small-padding-y": "0rem",
        "components-promo-alternate-spacing-small-padding-content-x": "2.5rem",
        "components-promo-alternate-spacing-small-padding-content-y": "1.5rem",
        "components-promo-alternate-spacing-small-padding-image-x": "1rem",
        "components-promo-alternate-spacing-small-padding-image-y": "0rem",
        "components-promo-alternate-spacing-small-padding-image-top": "1rem",
        "components-promo-alternate-spacing-small-title-margin-bottom": "1.5rem",
        "components-share-list-spacing-padding-x": "1rem",
        "components-share-list-spacing-padding-y": "1rem",
        "components-header-copy-margin-bottom": "1rem",
        "components-header-margin-bottom": "1.5rem",
        "components-item-margin-bottom": "1.5rem",
        "components-site-search-padding-x": "2.5rem",
        "components-site-search-padding-y": "5rem",
        "components-site-search-facet-label-space-between": "0.25rem",
        "components-site-search-refine-padding-x": "1.5rem",
        "components-site-search-refine-padding-y": "0rem",
        "components-site-search-hero-search-height": "22.5rem",
        "components-site-search-hero-search-large-padding-x": "5rem",
        "components-site-search-hero-search-large-padding-y": "5rem",
        "components-stats-spacing-stat-text-space-between": "0.5rem",
        "components-stats-spacing-large-inner-padding-x": "5rem",
        "components-stats-spacing-large-inner-padding-y": "5rem",
        "components-stats-spacing-small-inner-padding-x": "1.5rem",
        "components-stats-spacing-small-inner-padding-y": "1.5rem",
        "components-icon-card-listing-spacing-small-top-space-between": "1rem",
        "components-supporting-text-padding-x": "0rem",
        "components-supporting-text-padding-bottom": "0.5rem",
        "components-tab-item-padding-y": "0.75rem",
        "components-tab-item-padding-x": "0.75rem",
        "components-tab-item-spacing-x": "0.75rem",
        "components-tab-input-padding-y": "0.25rem",
        "components-tab-input-padding-x": "1rem",
        "components-tag-spacing-padding-x": "0.5rem",
        "components-tag-spacing-padding-y": "0.5rem",
        "components-tag-spacing-space-between": "0.5rem",
        "components-text-field-input-padding-y": "0.25rem",
        "components-text-field-input-padding-x": "1rem",
        "components-text-field-area-input-padding-y": "1rem",
        "components-text-field-area-input-padding-x": "1rem",
        "components-text-field-supporting-text-padding-x": "0rem",
        "components-text-field-supporting-text-padding-top": "0.5rem",
        "themes-general-surface-spacing-large-padding-x": "5rem",
        "themes-general-surface-spacing-large-padding-y": "5rem",
        "themes-general-surface-spacing-large-title-margin-bottom": "2rem",
        "themes-general-surface-spacing-large-body-margin-right": "4rem",
        "themes-general-surface-spacing-large-body-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-large-margin-bottom": "4rem",
        "themes-general-surface-spacing-large-cards-space-between": "1.5rem",
        "themes-general-surface-spacing-large-button-margin-top": "2.5rem",
        "themes-general-surface-spacing-small-padding-x": "1.5rem",
        "themes-general-surface-spacing-small-padding-y": "2.5rem",
        "themes-general-surface-spacing-small-title-margin-bottom": "1.5rem",
        "themes-general-surface-spacing-small-body-margin-right": "2.5rem",
        "themes-general-surface-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-surface-spacing-small-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-small-cards-space-between": "1rem",
        "themes-general-surface-spacing-small-button-margin-top": "1.5rem",
        "themes-general-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "themes-general-card-spacing-large-title-margin-bottom": "1rem",
        "themes-general-card-spacing-large-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-large-button-space-between": "1.5rem",
        "themes-general-card-spacing-small-eyebrow-margin-bottom": "1rem",
        "themes-general-card-spacing-small-title-margin-bottom": "1rem",
        "themes-general-card-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-small-button-space-between": "1.5rem",
        "themes-shadow-share-list-spacing-x": "0rem",
        "themes-shadow-share-list-spacing-y": "0.75rem",
        "themes-shadow-share-list-spacing-blur": "1rem",
        "themes-shadow-share-list-spacing-spread": "-0.5rem",
        "themes-shadow-header-menu-spacing-x": "0rem",
        "themes-shadow-header-menu-spacing-y": "0.75rem",
        "themes-shadow-header-menu-spacing-blur": "1rem",
        "themes-shadow-header-menu-spacing-spread": "-0.5rem",
        "themes-typography-rte-large-h1-textsize": "3rem",
        "themes-typography-rte-large-h2-textsize": "2.5rem",
        "themes-typography-rte-large-h3-textsize": "2.25rem",
        "themes-typography-rte-large-h4-textsize": "2rem",
        "themes-typography-rte-large-h5-textsize": "1.5rem",
        "themes-typography-rte-large-h6-textsize": "1.25rem",
        "themes-typography-rte-medium-h1-textsize": "2.5rem",
        "themes-typography-rte-medium-h2-textsize": "2.25rem",
        "themes-typography-rte-medium-h3-textsize": "1.75rem",
        "themes-typography-rte-medium-h4-textsize": "1.5rem",
        "themes-typography-rte-medium-h5-textsize": "1.25rem",
        "themes-typography-rte-medium-h6-textsize": "1.125rem",
        "themes-typography-rte-small-h1-textsize": "2.5rem",
        "themes-typography-rte-small-h2-textsize": "2rem",
        "themes-typography-rte-small-h3-textsize": "1.75rem",
        "themes-typography-rte-small-h4-textsize": "1.5rem",
        "themes-typography-rte-small-h5-textsize": "1.125rem",
        "themes-typography-rte-small-h6-textsize": "1rem",
        "spacing-margin-small-1": "0.125rem",
        "spacing-margin-small-2": "0.25rem",
        "spacing-margin-small-3": "0.5rem",
        "spacing-margin-small-4": "0.75rem",
        "spacing-margin-small-5": "1rem",
        "spacing-margin-small-6": "1.5rem",
        "spacing-margin-small-7": "2.5rem",
        "spacing-margin-medium-2": "0.75rem",
        "spacing-margin-medium-4": "1.5rem",
        "spacing-margin-medium-5": "2rem",
        "spacing-margin-large-1": "0.5rem",
        "spacing-margin-large-2": "1rem",
        "spacing-margin-large-3": "1.5rem",
        "spacing-margin-large-4": "2rem",
        "spacing-margin-large-5": "2.5rem",
        "spacing-margin-large-6": "4rem",
        "spacing-margin-large-7": "5rem",
        "spacing-space-between-small-1": "0.125rem",
        "spacing-space-between-small-2": "0.25rem",
        "spacing-space-between-small-3": "0.5rem",
        "spacing-space-between-small-4": "0.75rem",
        "spacing-space-between-small-5": "1rem",
        "spacing-space-between-small-6": "1.5rem",
        "spacing-space-between-small-7": "2.5rem",
        "spacing-space-between-medium-1": "0.125rem",
        "spacing-space-between-medium-2": "0.25rem",
        "spacing-space-between-medium-3": "0.5rem",
        "spacing-space-between-medium-4": "0.75rem",
        "spacing-space-between-medium-5": "1rem",
        "spacing-space-between-large-1": "0.25rem",
        "spacing-space-between-large-2": "0.5rem",
        "spacing-space-between-large-3": "1rem",
        "spacing-space-between-large-4": "1.5rem",
        "spacing-space-between-large-5": "1.5rem",
        "spacing-space-between-large-6": "2.5rem",
        "spacing-space-between-large-7": "4rem",
        "spacing-padding-small-1": "0.125rem",
        "spacing-padding-small-2": "0.25rem",
        "spacing-padding-small-3": "0.5rem",
        "spacing-padding-small-4": "0.75rem",
        "spacing-padding-small-5": "1rem",
        "spacing-padding-small-6": "1.5rem",
        "spacing-padding-small-7": "2.5rem",
        "spacing-padding-medium-1": "0.25rem",
        "spacing-padding-medium-2": "0.75rem",
        "spacing-padding-medium-3": "1rem",
        "spacing-padding-medium-4": "1.5rem",
        "spacing-padding-medium-5": "2rem",
        "spacing-padding-medium-6": "3rem",
        "spacing-padding-medium-7": "4rem",
        "spacing-padding-large-1": "0.5rem",
        "spacing-padding-large-2": "1rem",
        "spacing-padding-large-3": "1.5rem",
        "spacing-padding-large-4": "2rem",
        "spacing-padding-large-5": "2.5rem",
        "spacing-padding-large-6": "4rem",
        "spacing-padding-large-7": "5rem"
      },
      "string": {
        "components-accordion-section-button": "var(--brand)",
        "components-article-card-listing-button-theme": "var(--brand)",
        "components-article-hero-pagination-buttons": "var(--tonal)",
        "components-banner-banner-button": "var(--brand)",
        "components-category-card-listing-button-theme": "var(--brand)",
        "components-contact-us-promo-hierarchy-button-theme-1": "var(--filled)",
        "components-contact-us-promo-hierarchy-button-theme-2": "var(--outlined)",
        "components-contact-us-promo-color-button-theme": "var(--tonal)",
        "components-contact-us-promo-color-brand-button-theme": "var(--white)",
        "components-generic-card-button-theme": "var(--white)",
        "components-global-search-loader": "var(--default)",
        "components-generic-card-listing-decor-top": "var(--corporate-1)",
        "components-generic-card-listing-decor-bottom": "var(--corporate-2)",
        "components-hero-homepage-pagination-buttons": "var(--tonal)",
        "components-hero-homepage-pattern": "var(--none)",
        "components-hero-homepage-button-1": "var(--brand)",
        "components-hero-homepage-button-2": "var(--tonal)",
        "components-hero-homepage-image-ratio": "var(--5:4)",
        "components-hero-internal-theme-button-one-type": "var(--filled)",
        "components-hero-internal-theme-button-two-type": "var(--outlined)",
        "components-hero-internal-theme-button-color": "var(--white)",
        "components-hero-internal-theme-button-color-with-image": "var(--white)",
        "components-lightbox-button-type": "var(--brand)",
        "components-pdf-link-card-hierarchy-button-theme-1": "var(--filled)",
        "components-pdf-link-card-hierarchy-button-theme-2": "var(--outlined)",
        "components-pdf-link-card-color-button-theme": "var(--tonal)",
        "components-product-card-badge-align": "var(--center)",
        "components-product-card-listing-button-color": "var(--brand)",
        "components-promo-theme-button-one-theme": "var(--outlined)",
        "components-promo-theme-button-two-theme": "var(--text)",
        "components-promo-color-default-button-theme": "var(--brand)",
        "components-promo-color-light-brand-light-button-theme": "var(--white)",
        "components-promo-color-brand-button-theme": "var(--white)",
        "components-pull-quote-border-decor": "var(--corporate)",
        "components-promo-alternate-theme-button-one-theme": "var(--outlined)",
        "components-promo-alternate-theme-button-two-theme": "var(--text)",
        "components-promo-alternate-color-default-button-theme": "var(--brand)",
        "components-promo-alternate-color-light-brand-light-button-theme": "var(--white)",
        "components-promo-alternate-color-brand-button-theme": "var(--white)",
        "components-share-list-button-color": "var(--brand)",
        "components-site-search-hero-search-hero-pattern": "var(--scj)",
        "components-stats-decor-top": "var(--corporate-1)",
        "components-stats-decor-bottom": "var(--corporate-2)",
        "themes-brand-loader": "var(--default)",
        "themes-brand-variants": "var(--corporate)",
        "themes-tab-variant": "var(--default)",
        "themes-label-variants": "var(--default)",
        "themes-logo": "var(--corporate)",
        "themes-category-card": "var(--corporate)",
        "themes-title-decor": "var(--corporate)",
        "themes-typography-heading-fontfamily": "var(--gotham-ssm)",
        "themes-typography-heading-large-xxlarge-fontweight": "var(--bold)",
        "themes-typography-heading-large-xlarge-fontweight": "var(--bold)",
        "themes-typography-heading-large-large-fontweight": "var(--bold)",
        "themes-typography-heading-large-medium-fontweight": "var(--bold)",
        "themes-typography-heading-large-small-fontweight": "var(--bold)",
        "themes-typography-heading-large-xsmall-fontweight": "var(--bold)",
        "themes-typography-heading-large-xxsmall-fontweight": "var(--bold)",
        "themes-typography-heading-medium-xxlarge-fontweight": "var(--bold)",
        "themes-typography-heading-medium-xlarge-fontweight": "var(--bold)",
        "themes-typography-heading-medium-large-fontweight": "var(--bold)",
        "themes-typography-heading-medium-medium-fontweight": "var(--bold)",
        "themes-typography-heading-medium-small-fontweight": "var(--bold)",
        "themes-typography-heading-medium-xsmall-fontweight": "var(--bold)",
        "themes-typography-heading-medium-xxsmall-fontweight": "var(--bold)",
        "themes-typography-heading-small-xxlarge-fontweight": "var(--bold)",
        "themes-typography-heading-small-xlarge-fontweight": "var(--bold)",
        "themes-typography-heading-small-large-fontweight": "var(--bold)",
        "themes-typography-heading-small-medium-fontweight": "var(--bold)",
        "themes-typography-heading-small-small-fontweight": "var(--bold)",
        "themes-typography-heading-small-xsmall-fontweight": "var(--bold)",
        "themes-typography-heading-small-xxsmall-fontweight": "var(--semibold)",
        "themes-typography-rte-fontfamily": "var(--gotham-ssm)",
        "themes-typography-rte-fontweight": "var(--bold)"
      },
      "borderRadius": {
        "components-accordion-spacing-large-item-item-radius": "0rem",
        "components-article-card-spacing-large-image-radius-tl": "0rem",
        "components-article-card-spacing-large-image-radius-tr": "0rem",
        "components-article-card-spacing-large-image-radius-bl": "0rem",
        "components-article-card-spacing-large-image-radius-br": "0rem",
        "components-article-card-spacing-large-list-image-radius-br": "0rem",
        "components-article-card-spacing-small-image-radius-br": "0rem",
        "components-article-card-spacing-small-list-image-radius-br": "0rem",
        "components-article-hero-spacing-large-content-radius": "1.5rem",
        "components-article-hero-spacing-small-content-radius": "1rem",
        "components-button-radius-large": "7.5rem",
        "components-button-group-spacing-border-radius": "7.5rem",
        "components-category-card-radius-border-width": "0.0625rem",
        "components-category-card-radius-border-radius": "7.5rem",
        "components-category-card-radius-border-radius-condensed": "0.5rem",
        "components-category-card-radius-media-radius": "0.75rem",
        "components-content-list-icon-radius": "7.5rem",
        "components-fab-spacing-border-radius": "7.5rem",
        "components-header-spacing-large-search-border-radius-top": "7.5rem",
        "components-header-spacing-large-search-border-radius-bottom": "7.5rem",
        "components-header-spacing-large-language-selector-radius": "0.5rem",
        "components-header-spacing-large-dropdown-radius": "0.5rem",
        "components-hero-homepage-spacing-large-container-radius": "1rem",
        "components-hero-internal-spacing-large-icon-radius": "7.5rem",
        "components-hero-internal-spacing-small-icon-radius": "7.5rem",
        "components-lightbox-spacing-large-overlay-radius": "1rem",
        "components-pagination-scrollbar-radius": "62.5rem",
        "components-product-card-spacing-image-radius-tl": "0rem",
        "components-product-card-spacing-image-radius-tr": "0rem",
        "components-product-card-spacing-image-radius-bl": "0rem",
        "components-product-card-spacing-image-radius-br": "0rem",
        "components-product-information-spacing-large-content-radius": "1.5rem",
        "components-product-information-spacing-small-content-radius": "1rem",
        "components-promo-spacing-large-content-radius": "1rem",
        "components-promo-spacing-large-content-radius-tl": "1rem",
        "components-promo-spacing-large-content-radius-tr": "1rem",
        "components-promo-spacing-large-content-radius-bl": "1rem",
        "components-promo-spacing-large-content-radius-br": "1rem",
        "components-promo-spacing-large-image-radius-tl": "1rem",
        "components-promo-spacing-large-image-radius-tr": "1rem",
        "components-promo-spacing-large-image-radius-bl": "1rem",
        "components-promo-spacing-large-image-radius-br": "1rem",
        "components-promo-spacing-small-content-radius": "0rem",
        "components-promo-spacing-small-image-radius-tl": "0.75rem",
        "components-promo-spacing-small-image-radius-tr": "0.75rem",
        "components-promo-spacing-small-image-radius-bl": "0.75rem",
        "components-promo-spacing-small-image-radius-br": "0.75rem",
        "components-promo-alternate-spacing-large-content-radius": "0rem",
        "components-promo-alternate-spacing-large-image-radius-tl": "1rem",
        "components-promo-alternate-spacing-large-image-radius-tr": "1rem",
        "components-promo-alternate-spacing-large-image-radius-bl": "1rem",
        "components-promo-alternate-spacing-large-image-radius-br": "1rem",
        "components-promo-alternate-spacing-small-content-radius": "0rem",
        "components-promo-alternate-spacing-small-image-radius-tl": "0.75rem",
        "components-promo-alternate-spacing-small-image-radius-tr": "0.75rem",
        "components-promo-alternate-spacing-small-image-radius-bl": "0.75rem",
        "components-promo-alternate-spacing-small-image-radius-br": "0.75rem",
        "components-share-list-spacing-radius": "0.5rem",
        "components-share-list-spacing-link-radius": "0.5rem",
        "components-site-search-filter-radius": "0.5rem",
        "components-site-search-facet-radius": "0rem",
        "components-site-search-refine-radius": "0rem",
        "components-tag-spacing-radius": "0.25rem",
        "components-tip-spacing-radius": "0.25rem",
        "themes-spacing-radius-pagination-bar": "62.5rem",
        "themes-spacing-radius-large-button": "7.5rem",
        "themes-spacing-radius-large-button-icon": "7.5rem",
        "themes-spacing-radius-large-image": "1rem",
        "themes-spacing-radius-large-image-hero": "1rem",
        "themes-spacing-radius-large-image-card": "0.75rem",
        "themes-spacing-radius-large-card": "1rem",
        "themes-spacing-radius-large-icon": "7.5rem",
        "themes-spacing-radius-large-label": "0.25rem",
        "themes-spacing-radius-large-tag": "0.25rem",
        "themes-spacing-radius-large-container-tip": "0.25rem",
        "themes-spacing-radius-large-badge": "0rem",
        "themes-spacing-radius-large-item": "0.5rem",
        "themes-spacing-radius-large-form-field-input": "7.5rem",
        "themes-spacing-radius-large-form-area-input": "7.5rem",
        "themes-spacing-radius-large-general": "1.5rem",
        "themes-spacing-radius-small-button": "7.5rem",
        "themes-spacing-radius-small-button-icon": "7.5rem",
        "themes-spacing-radius-small-image": "0.75rem",
        "themes-spacing-radius-small-image-card": "0.75rem",
        "themes-spacing-radius-small-card": "0.75rem",
        "themes-spacing-radius-small-icon": "7.5rem",
        "themes-spacing-radius-small-label": "0.125rem",
        "themes-spacing-radius-small-tag": "0.25rem",
        "themes-spacing-radius-small-badge": "0rem",
        "themes-spacing-radius-small-item": "0rem",
        "themes-spacing-radius-small-form-field-input": "7.5rem",
        "themes-spacing-radius-small-general": "1rem"
      },
      "borderWidth": {
        "3": "3px",
        "components-article-card-spacing-large-border-width": "0rem",
        "components-generic-card-color-border-width": "0rem",
        "components-header-spacing-large-search-border-width-top": "0.125rem",
        "components-header-spacing-large-search-border-width-right": "0.125rem",
        "components-header-spacing-large-search-border-width-bottom": "0.125rem",
        "components-header-spacing-large-search-border-width-left": "0.125rem",
        "components-product-card-spacing-border-width": "0.0625rem"
      },
      "maxHeight": {},
      "maxWidth": {
        "components-article-hero-spacing-large-header-max-width": "66.375rem",
        "components-content-list-spacing-large-item-image-max-width": "33.75rem",
        "components-content-list-spacing-small-item-image-max-width": "33.75rem"
      },
      "minHeight": {
        "components-dropdown-item-min-height": "3rem",
        "components-icon-min-height": "3rem",
        "components-tab-item-min-height": "3rem",
        "components-text-field-input-min-height": "3rem",
        "components-text-field-icon-min-height": "3rem"
      },
      "minWidth": {
        "components-content-list-spacing-small-item-image-min-width": "18.75rem",
        "components-icon-min-width": "3rem",
        "components-text-field-icon-min-width": "3rem"
      },
      "opacity": {
        "components-accessibility-bar-color-bg": 1,
        "components-accessibility-bar-color-text": 1,
        "components-accordion-color-bg": 1,
        "components-accordion-color-title": 1,
        "components-accordion-color-body": 1,
        "components-accordion-color-section-section-bg": 1,
        "components-accordion-color-section-section-title": 1,
        "components-accordion-color-section-section-body": 1,
        "components-accordion-color-item-item-bg": 1,
        "components-accordion-color-item-divider": 1,
        "components-accordion-color-item-icon-icon-default": 1,
        "components-accordion-color-item-icon-icon-hover": 1,
        "components-accordion-color-item-icon-icon-pressed": 1,
        "components-accordion-color-item-icon-icon-active": 1,
        "components-accordion-color-item-title-item-default": 1,
        "components-accordion-color-item-title-item-hover": 1,
        "components-accordion-color-item-title-item-pressed": 1,
        "components-accordion-color-item-title-item-active": 1,
        "components-accordion-color-content-bg": 1,
        "components-accordion-color-content-title": 1,
        "components-accordion-color-content-body": 1,
        "components-article-card-color-bg": 1,
        "components-article-card-color-border": 1,
        "components-article-card-color-content-bg": 1,
        "components-article-card-color-title": 1,
        "components-article-card-color-subtitle": 1,
        "components-article-card-color-copy": 1,
        "components-article-card-color-description": 1,
        "components-article-card-listing-color-default-bg": 1,
        "components-article-card-listing-color-default-title": 1,
        "components-article-card-listing-color-default-copy": 1,
        "components-article-hero-color-content-bg": 1,
        "components-article-hero-color-bg": 1,
        "components-article-hero-color-headline": 1,
        "components-article-hero-color-body": 1,
        "components-article-hero-color-detail-text": 1,
        "components-article-hero-color-divider": 1,
        "components-article-hero-color-icon": 1,
        "components-article-hero-color-play-icon": 1,
        "components-article-hero-color-selected-stroke": 1,
        "components-banner-color-bg-default": 1,
        "components-banner-color-icon-default": 1,
        "components-banner-color-title-default": 1,
        "components-banner-color-body-default": 1,
        "components-banner-color-icon-close-default": 1,
        "components-banner-color-bg-alert": 1,
        "components-banner-color-icon-alert": 1,
        "components-banner-color-title-alert": 1,
        "components-banner-color-body-alert": 1,
        "components-banner-color-icon-close-alert": 1,
        "components-breadcrumb-color-bg": 1,
        "components-breadcrumb-color-breadcrumb-link-default": 1,
        "components-breadcrumb-color-breadcrumb-link-hover": 1,
        "components-breadcrumb-color-breadcrumb-link-active": 1,
        "components-breadcrumb-color-icon-divider": 1,
        "components-bug-finder-prompt": 1,
        "components-button-color-filled-brand-default-bg": 1,
        "components-button-color-filled-brand-default-text": 1,
        "components-button-color-filled-brand-default-stroke": 1,
        "components-button-color-filled-brand-default-icon": 1,
        "components-button-color-filled-brand-hover-bg": 1,
        "components-button-color-filled-brand-hover-text": 1,
        "components-button-color-filled-brand-hover-stroke": 1,
        "components-button-color-filled-brand-hover-icon": 1,
        "components-button-color-filled-brand-pressed-bg": 1,
        "components-button-color-filled-brand-pressed-text": 1,
        "components-button-color-filled-brand-pressed-stroke": 1,
        "components-button-color-filled-brand-pressed-icon": 1,
        "components-button-color-filled-brand-disabled-bg": 0,
        "components-button-color-filled-brand-disabled-text": 0.02,
        "components-button-color-filled-brand-disabled-stroke": 0,
        "components-button-color-filled-brand-disabled-icon": 0.02,
        "components-button-color-filled-brand-focus-bg": 1,
        "components-button-color-filled-brand-focus-text": 1,
        "components-button-color-filled-brand-focus-stroke": 1,
        "components-button-color-filled-brand-focus-icon": 1,
        "components-button-color-filled-brand-focus-outline": 1,
        "components-button-color-filled-tonal-default-bg": 1,
        "components-button-color-filled-tonal-default-text": 1,
        "components-button-color-filled-tonal-default-stroke": 1,
        "components-button-color-filled-tonal-default-icon": 1,
        "components-button-color-filled-tonal-hover-bg": 1,
        "components-button-color-filled-tonal-hover-text": 1,
        "components-button-color-filled-tonal-hover-stroke": 1,
        "components-button-color-filled-tonal-hover-icon": 1,
        "components-button-color-filled-tonal-pressed-bg": 1,
        "components-button-color-filled-tonal-pressed-text": 1,
        "components-button-color-filled-tonal-pressed-stroke": 1,
        "components-button-color-filled-tonal-pressed-icon": 1,
        "components-button-color-filled-tonal-disabled-bg": 0.03,
        "components-button-color-filled-tonal-disabled-text": 0.02,
        "components-button-color-filled-tonal-disabled-stroke": 0.03,
        "components-button-color-filled-tonal-disabled-icon": 0.02,
        "components-button-color-filled-tonal-focus-bg": 1,
        "components-button-color-filled-tonal-focus-text": 1,
        "components-button-color-filled-tonal-focus-stroke": 1,
        "components-button-color-filled-tonal-focus-icon": 1,
        "components-button-color-filled-tonal-focus-outline": 1,
        "components-button-color-filled-white-default-bg": 1,
        "components-button-color-filled-white-default-text": 1,
        "components-button-color-filled-white-default-stroke": 1,
        "components-button-color-filled-white-default-icon": 1,
        "components-button-color-filled-white-hover-bg": 1,
        "components-button-color-filled-white-hover-text": 1,
        "components-button-color-filled-white-hover-stroke": 1,
        "components-button-color-filled-white-hover-icon": 1,
        "components-button-color-filled-white-pressed-bg": 1,
        "components-button-color-filled-white-pressed-text": 1,
        "components-button-color-filled-white-pressed-stroke": 1,
        "components-button-color-filled-white-pressed-icon": 1,
        "components-button-color-filled-white-disabled-bg": 0.02,
        "components-button-color-filled-white-disabled-text": 0,
        "components-button-color-filled-white-disabled-stroke": 0.02,
        "components-button-color-filled-white-disabled-icon": 0,
        "components-button-color-filled-white-focus-bg": 1,
        "components-button-color-filled-white-focus-text": 1,
        "components-button-color-filled-white-focus-stroke": 1,
        "components-button-color-filled-white-focus-icon": 1,
        "components-button-color-filled-white-focus-outline": 1,
        "components-button-color-outline-brand-default-bg": 0.03,
        "components-button-color-outline-brand-default-text": 1,
        "components-button-color-outline-brand-default-stroke": 1,
        "components-button-color-outline-brand-default-icon": 1,
        "components-button-color-outline-brand-hover-bg": 1,
        "components-button-color-outline-brand-hover-text": 1,
        "components-button-color-outline-brand-hover-stroke": 1,
        "components-button-color-outline-brand-hover-icon": 1,
        "components-button-color-outline-brand-pressed-bg": 1,
        "components-button-color-outline-brand-pressed-text": 1,
        "components-button-color-outline-brand-pressed-stroke": 1,
        "components-button-color-outline-brand-pressed-icon": 1,
        "components-button-color-outline-brand-disabled-bg": 0.03,
        "components-button-color-outline-brand-disabled-text": 0.03,
        "components-button-color-outline-brand-disabled-stroke": 0.03,
        "components-button-color-outline-brand-disabled-icon": 0.03,
        "components-button-color-outline-brand-focus-bg": 1,
        "components-button-color-outline-brand-focus-text": 1,
        "components-button-color-outline-brand-focus-stroke": 1,
        "components-button-color-outline-brand-focus-icon": 1,
        "components-button-color-outline-brand-focus-outline": 1,
        "components-button-color-outline-tonal-default-bg": 0.02,
        "components-button-color-outline-tonal-default-text": 1,
        "components-button-color-outline-tonal-default-stroke": 1,
        "components-button-color-outline-tonal-default-icon": 1,
        "components-button-color-outline-tonal-hover-bg": 1,
        "components-button-color-outline-tonal-hover-text": 1,
        "components-button-color-outline-tonal-hover-stroke": 1,
        "components-button-color-outline-tonal-hover-icon": 1,
        "components-button-color-outline-tonal-pressed-bg": 1,
        "components-button-color-outline-tonal-pressed-text": 1,
        "components-button-color-outline-tonal-pressed-stroke": 1,
        "components-button-color-outline-tonal-pressed-icon": 1,
        "components-button-color-outline-tonal-disabled-bg": 0.02,
        "components-button-color-outline-tonal-disabled-text": 0.02,
        "components-button-color-outline-tonal-disabled-stroke": 0.02,
        "components-button-color-outline-tonal-disabled-icon": 0.03,
        "components-button-color-outline-tonal-focus-bg": 1,
        "components-button-color-outline-tonal-focus-text": 1,
        "components-button-color-outline-tonal-focus-stroke": 1,
        "components-button-color-outline-tonal-focus-icon": 1,
        "components-button-color-outline-tonal-focus-outline": 1,
        "components-button-color-outline-white-default-bg": 0.02,
        "components-button-color-outline-white-default-text": 1,
        "components-button-color-outline-white-default-stroke": 1,
        "components-button-color-outline-white-default-icon": 1,
        "components-button-color-outline-white-hover-bg": 1,
        "components-button-color-outline-white-hover-text": 1,
        "components-button-color-outline-white-hover-stroke": 1,
        "components-button-color-outline-white-hover-icon": 1,
        "components-button-color-outline-white-pressed-bg": 1,
        "components-button-color-outline-white-pressed-text": 1,
        "components-button-color-outline-white-pressed-stroke": 1,
        "components-button-color-outline-white-pressed-icon": 1,
        "components-button-color-outline-white-disabled-bg": 0.02,
        "components-button-color-outline-white-disabled-text": 0.02,
        "components-button-color-outline-white-disabled-stroke": 0.02,
        "components-button-color-outline-white-disabled-icon": 0.02,
        "components-button-color-outline-white-focus-bg": 0.02,
        "components-button-color-outline-white-focus-text": 1,
        "components-button-color-outline-white-focus-stroke": 1,
        "components-button-color-outline-white-focus-icon": 1,
        "components-button-color-outline-white-focus-outline": 1,
        "components-button-color-text-brand-default-bg": 0.02,
        "components-button-color-text-brand-default-text": 1,
        "components-button-color-text-brand-default-stroke": 1,
        "components-button-color-text-brand-default-icon": 1,
        "components-button-color-text-brand-hover-bg": 0.02,
        "components-button-color-text-brand-hover-bg-icon-only": 1,
        "components-button-color-text-brand-hover-text": 1,
        "components-button-color-text-brand-hover-stroke": 1,
        "components-button-color-text-brand-hover-icon": 1,
        "components-button-color-text-brand-pressed-bg": 0.02,
        "components-button-color-text-brand-pressed-bg-icon-only": 1,
        "components-button-color-text-brand-pressed-text": 1,
        "components-button-color-text-brand-pressed-stroke": 1,
        "components-button-color-text-brand-pressed-icon": 1,
        "components-button-color-text-brand-disabled-bg": 0.02,
        "components-button-color-text-brand-disabled-text": 0.03,
        "components-button-color-text-brand-disabled-stroke": 0.03,
        "components-button-color-text-brand-disabled-icon": 0.03,
        "components-button-color-text-brand-focus-bg": 1,
        "components-button-color-text-brand-focus-text": 1,
        "components-button-color-text-brand-focus-stroke": 1,
        "components-button-color-text-brand-focus-icon": 1,
        "components-button-color-text-brand-focus-outline": 1,
        "components-button-color-text-tonal-default-bg": 0.02,
        "components-button-color-text-tonal-default-text": 1,
        "components-button-color-text-tonal-default-stroke": 1,
        "components-button-color-text-tonal-default-icon": 1,
        "components-button-color-text-tonal-hover-bg": 0.02,
        "components-button-color-text-tonal-hover-bg-icon-only": 1,
        "components-button-color-text-tonal-hover-text": 1,
        "components-button-color-text-tonal-hover-stroke": 1,
        "components-button-color-text-tonal-hover-icon": 1,
        "components-button-color-text-tonal-pressed-bg": 0.02,
        "components-button-color-text-tonal-pressed-bg-icon-only": 1,
        "components-button-color-text-tonal-pressed-text": 1,
        "components-button-color-text-tonal-pressed-stroke": 1,
        "components-button-color-text-tonal-pressed-icon": 1,
        "components-button-color-text-tonal-disabled-bg": 0.02,
        "components-button-color-text-tonal-disabled-text": 0.03,
        "components-button-color-text-tonal-disabled-stroke": 0.03,
        "components-button-color-text-tonal-disabled-icon": 0.03,
        "components-button-color-text-tonal-focus-bg": 1,
        "components-button-color-text-tonal-focus-text": 1,
        "components-button-color-text-tonal-focus-stroke": 1,
        "components-button-color-text-tonal-focus-icon": 1,
        "components-button-color-text-tonal-focus-outline": 1,
        "components-button-color-text-white-default-bg": 0.02,
        "components-button-color-text-white-default-text": 1,
        "components-button-color-text-white-default-stroke": 1,
        "components-button-color-text-white-default-icon": 1,
        "components-button-color-text-white-hover-bg": 0.02,
        "components-button-color-text-white-hover-bg-icon-only": 1,
        "components-button-color-text-white-hover-text": 1,
        "components-button-color-text-white-hover-stroke": 1,
        "components-button-color-text-white-hover-icon": 1,
        "components-button-color-text-white-pressed-bg": 0.02,
        "components-button-color-text-white-pressed-bg-icon-only": 1,
        "components-button-color-text-white-pressed-text": 1,
        "components-button-color-text-white-pressed-stroke": 1,
        "components-button-color-text-white-pressed-icon": 1,
        "components-button-color-text-white-disabled-bg": 0.02,
        "components-button-color-text-white-disabled-text": 0.01,
        "components-button-color-text-white-disabled-stroke": 0.01,
        "components-button-color-text-white-disabled-icon": 0.01,
        "components-button-color-text-white-focus-bg": 1,
        "components-button-color-text-white-focus-text": 1,
        "components-button-color-text-white-focus-stroke": 1,
        "components-button-color-text-white-focus-icon": 1,
        "components-button-color-text-white-focus-outline": 1,
        "components-button-group-color-border": 1,
        "components-button-group-color-button-bg-default": 0.02,
        "components-button-group-color-button-fg-default": 1,
        "components-button-group-color-button-bg-hover": 1,
        "components-button-group-color-button-fg-hover": 1,
        "components-button-group-color-button-bg-active": 1,
        "components-button-group-color-button-fg-active": 1,
        "components-button-group-color-overlay-left-gradient-fill-1": 1,
        "components-button-group-color-overlay-left-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-1": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-3": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-4": 0.02,
        "components-button-group-color-stroke-hover-gradient-fill-1": 1,
        "components-button-group-color-stroke-hover-gradient-fill-2": 1,
        "components-button-group-color-stroke-hover-gradient-fill-3": 1,
        "components-button-group-color-stroke-hover-gradient-fill-4": 1,
        "components-button-group-color-stroke-active-gradient-fill-1": 1,
        "components-button-group-color-stroke-active-gradient-fill-2": 1,
        "components-button-group-color-stroke-active-gradient-fill-3": 1,
        "components-button-group-color-stroke-active-gradient-fill-4": 1,
        "components-category-card-color-bg": 1,
        "components-category-card-color-content-bg": 1,
        "components-category-card-color-category-bg": 1,
        "components-category-card-color-category-bg-2": 1,
        "components-category-card-color-category-bg-3": 1,
        "components-category-card-color-category-bg-4": 1,
        "components-category-card-color-category-bg-5": 1,
        "components-category-card-color-category-bg-6": 1,
        "components-category-card-color-title": 1,
        "components-category-card-color-copy": 1,
        "components-category-card-color-stroke": 1,
        "components-category-card-color-stroke-hover": 1,
        "components-category-card-listing-color-bg": 1,
        "components-category-card-listing-color-title": 1,
        "components-category-card-listing-color-copy": 1,
        "components-card-search-result-color-bg": 1,
        "components-card-search-result-color-label": 1,
        "components-card-search-result-color-title": 1,
        "components-card-search-result-color-body": 1,
        "components-card-search-result-color-divider-line": 1,
        "components-contact-us-promo-color-light-bg": 1,
        "components-contact-us-promo-color-light-title": 1,
        "components-contact-us-promo-color-light-body": 1,
        "components-contact-us-promo-color-default-bg": 1,
        "components-contact-us-promo-color-default-title": 1,
        "components-contact-us-promo-color-default-body": 1,
        "components-contact-us-promo-color-brand-bg": 1,
        "components-contact-us-promo-color-brand-title": 1,
        "components-contact-us-promo-color-brand-body": 1,
        "components-content-list-color-bg": 1,
        "components-content-list-color-title": 1,
        "components-content-list-color-body": 1,
        "components-content-list-color-icon": 1,
        "components-cookies-colors-bg": 1,
        "components-cookies-colors-title": 1,
        "components-cookies-colors-body": 1,
        "components-cookies-colors-icon": 1,
        "components-dropdown-bg": 1,
        "components-dropdown-border-default": 1,
        "components-dropdown-border-hover": 1,
        "components-dropdown-border-focused": 1,
        "components-dropdown-border-destructive": 1,
        "components-dropdown-label-text-default": 1,
        "components-dropdown-item-text-default": 1,
        "components-dropdown-item-bg-default": 1,
        "components-dropdown-item-bg-hover": 1,
        "components-dropdown-input-field-default": 1,
        "components-dropdown-input-field-focused": 1,
        "components-dropdown-input-label-focused": 1,
        "components-dropdown-input-field-destructive": 1,
        "components-external-link-card-bg": 1,
        "components-external-link-card-card-bg": 1,
        "components-external-link-card-card-title": 1,
        "components-external-link-card-card-subtitle": 1,
        "components-external-link-card-card-eyebrow": 1,
        "components-fab-color-border-default": 1,
        "components-footer-brand-color-bg": 1,
        "components-footer-brand-color-logo": 1,
        "components-footer-brand-color-title-text": 1,
        "components-footer-brand-color-description-text": 1,
        "components-footer-brand-color-footer-menu-expanded": 1,
        "components-footer-brand-color-link-default": 1,
        "components-footer-brand-color-link-hover": 1,
        "components-footer-brand-color-link-pressed": 1,
        "components-footer-brand-color-category-link-default": 1,
        "components-footer-brand-color-category-link-hover": 1,
        "components-footer-brand-color-category-link-pressed": 1,
        "components-footer-brand-color-subcategory-link-default": 1,
        "components-footer-brand-color-subcategory-link-hover": 1,
        "components-footer-brand-color-subcategory-link-pressed": 1,
        "components-footer-brand-color-icon-icon-default": 1,
        "components-footer-brand-color-icon-icon-pressed": 1,
        "components-footer-brand-color-social-icon-default": 1,
        "components-footer-brand-color-social-icon-hover": 1,
        "components-footer-brand-color-social-icon-pressed": 1,
        "components-footer-brand-spacing-category-link-hover": 1,
        "components-footer-global-color-bg": 1,
        "components-footer-global-color-title-text": 1,
        "components-footer-global-color-disclaimer-text": 1,
        "components-footer-global-color-link-default": 1,
        "components-footer-global-color-link-hover": 1,
        "components-footer-global-color-link-pressed": 1,
        "components-generic-card-color-bg": 1,
        "components-generic-card-color-content-bg": 1,
        "components-generic-card-color-title": 1,
        "components-generic-card-color-subtitle": 1,
        "components-generic-card-color-copy": 1,
        "components-global-search-color-omnibox-list-bg": 1,
        "components-global-search-color-omnibox-divider": 1,
        "components-global-search-color-omnibox-item-bg-default": 1,
        "components-global-search-color-omnibox-item-bg-hover": 1,
        "components-global-search-color-omnibox-item-text-default": 1,
        "components-global-search-color-omnibox-item-text-hover": 1,
        "components-global-search-color-omnibox-label-text-default": 1,
        "components-global-search-color-omnibox-category-text-default": 1,
        "components-generic-card-listing-color-bg": 1,
        "components-generic-card-listing-color-title": 1,
        "components-generic-card-listing-color-copy": 1,
        "components-header-color-bg": 1,
        "components-header-color-bottom-stroke": 1,
        "components-header-color-search-search-border-default": 1,
        "components-header-color-search-search-text": 1,
        "components-header-color-search-search-icon": 1,
        "components-header-color-search-search-bar": 1,
        "components-header-color-mobile-mobile-expanded-bg": 1,
        "components-header-color-mobile-pressed-bg": 1,
        "components-header-color-language-selector-language": 1,
        "components-header-color-language-selector-country": 1,
        "components-header-color-language-selector-country-hover-bg": 1,
        "components-header-color-language-selector-megamenu-language": 1,
        "components-header-color-language-selector-megamenu-country": 1,
        "components-header-color-language-selector-megamenu-country-hover": 1,
        "components-header-color-language-selector-bg": 1,
        "components-header-color-language-selector-stroke": 1,
        "components-header-color-mega-menu-bg": 1,
        "components-header-color-mega-menu-featured-bg": 1,
        "components-header-color-mega-menu-featured-title": 1,
        "components-header-color-mega-menu-category-title": 1,
        "components-header-color-mega-menu-bg-accent": 1,
        "components-header-color-icon-icon-default": 1,
        "components-header-color-icon-icon-hover": 1,
        "components-header-color-icon-icon-pressed": 1,
        "components-header-color-links-nav-main-link-default": 1,
        "components-header-color-links-nav-main-link-hover": 1,
        "components-header-color-links-nav-main-link-pressed": 1,
        "components-header-color-links-nav-main-link-active": 1,
        "components-header-color-links-nav-main-active-bar": 1,
        "components-header-color-links-nav-main-bg-color": 1,
        "components-header-color-links-nav-category-link-category-default": 1,
        "components-header-color-links-nav-category-link-category-hover": 1,
        "components-header-color-links-nav-category-link-category-pressed": 1,
        "components-header-color-links-nav-category-link-category-active": 1,
        "components-header-color-links-nav-menu-link-link-default": 1,
        "components-header-color-links-nav-menu-link-link-hover": 1,
        "components-header-color-links-nav-menu-link-link-pressed": 1,
        "components-header-color-links-nav-menu-link-link-active": 1,
        "components-header-color-links-nav-menu-link-bg": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-default": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-active": 1,
        "components-hero-homepage-color-bg": 1,
        "components-hero-homepage-color-container-bg": 1,
        "components-hero-homepage-color-eyebrow": 1,
        "components-hero-homepage-color-header": 1,
        "components-hero-homepage-color-sub-header": 1,
        "components-hero-homepage-color-body": 1,
        "components-hero-homepage-color-play-pause": 1,
        "components-hero-internal-color-default-title": 1,
        "components-hero-internal-color-default-body": 1,
        "components-hero-internal-color-default-bg": 1,
        "components-hero-internal-color-accent-bg": 1,
        "components-hero-internal-color-with-image-bg": 1,
        "components-hero-internal-color-default-bg-2": 1,
        "components-hero-internal-color-with-image-title": 1,
        "components-hero-internal-color-with-image-body": 1,
        "components-heading-page-title-color-default-bg": 1,
        "components-heading-page-title-color-default-title": 1,
        "components-heading-page-title-color-default-body": 1,
        "components-heading-page-title-color-light-bg": 1,
        "components-heading-page-title-color-light-title": 1,
        "components-heading-page-title-color-light-body": 1,
        "components-heading-page-title-color-color-bg": 1,
        "components-heading-page-title-color-color-title": 1,
        "components-heading-page-title-color-color-body": 1,
        "components-hyperlink-color-dark-default": 1,
        "components-hyperlink-color-dark-hover": 1,
        "components-hyperlink-color-dark-visited": 1,
        "components-hyperlink-color-light-default": 1,
        "components-hyperlink-color-light-hover": 1,
        "components-hyperlink-color-light-visited": 1,
        "components-icon-default": 1,
        "components-icon-focused": 1,
        "components-icon-card-color-bg": 1,
        "components-icon-card-color-icon-bg": 1,
        "components-icon-card-color-icon": 1,
        "components-icon-card-color-title": 1,
        "components-icon-card-color-subtitle": 1,
        "components-icon-card-color-description": 1,
        "components-icon-card-listing-color-bg": 1,
        "components-icon-card-listing-color-inner-bg": 1,
        "components-icon-card-listing-color-brand-bg": 1,
        "components-icon-card-listing-color-title": 1,
        "components-icon-card-listing-color-copy": 1,
        "components-icon-card-listing-color-inner-legal-text": 1,
        "components-jumplink-color-bg-default": 1,
        "components-jumplink-color-link-text-default": 1,
        "components-jumplink-color-link-text-hover": 1,
        "components-jumplink-color-link-border-hover": 1,
        "components-jumplink-color-link-text-active": 1,
        "components-jumplink-color-link-border-active": 1,
        "components-jumplink-color-title-text": 1,
        "components-jumplink-color-description-text": 1,
        "components-layout-bg-default": 1,
        "components-lightbox-color-overlay-bg": 0.02,
        "components-lightbox-color-title": 1,
        "components-lightbox-color-body": 1,
        "components-label-color-on-color-label-label-default": 1,
        "components-label-color-on-color-label-label-hover": 1,
        "components-label-color-on-color-label-label-pressed": 1,
        "components-label-color-on-color-background-bg-default": 0.02,
        "components-label-color-on-color-background-bg-hover": 1,
        "components-label-color-on-color-background-bg-pressed": 1,
        "components-label-color-on-color-stroke-hover": 1,
        "components-label-color-on-color-stroke-pressed": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-4": 1,
        "components-label-color-on-white-label-label-default": 1,
        "components-label-color-on-white-label-label-hover": 1,
        "components-label-color-on-white-label-label-pressed": 1,
        "components-label-color-on-white-background-bg-default": 0.02,
        "components-label-color-on-white-background-bg-hover": 1,
        "components-label-color-on-white-background-bg-pressed": 1,
        "components-label-color-on-white-stroke-hover": 1,
        "components-label-color-on-white-stroke-pressed": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-4": 1,
        "components-legal-disclaimer-color-default-bg": 1,
        "components-legal-disclaimer-color-default-title": 1,
        "components-legal-disclaimer-color-default-body": 1,
        "components-legal-disclaimer-color-light-bg": 1,
        "components-legal-disclaimer-color-light-title": 1,
        "components-legal-disclaimer-color-light-body": 1,
        "components-legal-disclaimer-color-brand-bg": 1,
        "components-legal-disclaimer-color-brand-title": 1,
        "components-legal-disclaimer-color-brand-body": 1,
        "components-media-video-thumbnail-overlay-bg": null,
        "components-media-caption-color-bg": 1,
        "components-media-caption-color-text": 1,
        "components-media-gallery-color-bg": 1,
        "components-media-gallery-color-title": 1,
        "components-media-gallery-color-body": 1,
        "components-pagination-on-white-accent-default": 1,
        "components-pagination-on-white-accent-scroll": 1,
        "components-pagination-on-white-disabled": 1,
        "components-pagination-on-white-bg": 0,
        "components-pagination-on-white-stroke": 1,
        "components-pagination-on-white-bg-active": 1,
        "components-pagination-on-white-bg-hover": 1,
        "components-pagination-on-white-text-default": 1,
        "components-pagination-on-white-text-hover": 1,
        "components-pagination-on-white-text-active": 1,
        "components-pagination-on-color-accent-default": 1,
        "components-pagination-on-color-accent-scroll": 1,
        "components-pagination-on-color-disabled": 1,
        "components-pagination-on-color-bg": 1,
        "components-pagination-on-color-stroke": 0.02,
        "components-pagination-on-color-bg-active": 1,
        "components-pagination-on-color-bg-hover": 1,
        "components-pagination-on-color-text-default": 1,
        "components-pagination-on-color-text-hover": 1,
        "components-pagination-on-color-text-active": 1,
        "components-pdf-link-card-color-bg": 1,
        "components-pdf-link-card-color-card-bg": 1,
        "components-pdf-link-card-color-card-title": 1,
        "components-pdf-link-card-color-card-subtitle": 1,
        "components-pdf-link-card-color-card-body": 1,
        "components-product-card-color-bg": 1,
        "components-product-card-color-content-bg": 1,
        "components-product-card-color-border": 0.02,
        "components-product-card-color-title": 1,
        "components-product-card-color-description": 1,
        "components-product-card-color-star-rating": 1,
        "components-product-card-color-badge-bg": 1,
        "components-product-card-color-badge-text": 1,
        "components-product-card-listing-color-bg": 1,
        "components-product-card-listing-color-title": 1,
        "components-product-card-listing-color-copy": 1,
        "components-product-card-listing-color-legal-text": 1,
        "components-product-information-color-bg": 1,
        "components-product-information-color-content-bg": 1,
        "components-product-information-color-headline": 1,
        "components-product-information-color-body": 1,
        "components-product-information-color-detail-text": 1,
        "components-product-information-color-divider": 1,
        "components-product-information-color-icon": 1,
        "components-product-information-color-play-icon": 1,
        "components-product-information-color-selected-stroke": 1,
        "components-product-results-list-with-search-color-bg": 1,
        "components-promo-color-default-bg": 1,
        "components-promo-color-default-image-bg": 1,
        "components-promo-color-default-content-bg": 1,
        "components-promo-color-default-title": 1,
        "components-promo-color-default-body": 1,
        "components-promo-color-default-category": 1,
        "components-promo-color-light-bg": 1,
        "components-promo-color-light-image-bg": 1,
        "components-promo-color-light-content-bg": 1,
        "components-promo-color-light-title": 1,
        "components-promo-color-light-body": 1,
        "components-promo-color-light-category": 1,
        "components-promo-color-brand-bg": 1,
        "components-promo-color-brand-image-bg": 1,
        "components-promo-color-brand-content-bg": 1,
        "components-promo-color-brand-title": 1,
        "components-promo-color-brand-body": 1,
        "components-promo-color-brand-category": 1,
        "components-pull-quote-color-bg": 1,
        "components-pull-quote-color-stroke": 1,
        "components-pull-quote-color-quote-bg": 1,
        "components-pull-quote-color-quote-title": 1,
        "components-pull-quote-color-quote-by-text": 1,
        "components-pull-quote-color-quote-detail": 1,
        "components-pull-quote-color-quote-highlight": 1,
        "components-promo-alternate-color-default-bg": 1,
        "components-promo-alternate-color-default-image-bg": 1,
        "components-promo-alternate-color-default-content-bg": 1,
        "components-promo-alternate-color-default-title": 1,
        "components-promo-alternate-color-default-body": 1,
        "components-promo-alternate-color-default-category": 1,
        "components-promo-alternate-color-light-bg": 1,
        "components-promo-alternate-color-light-image-bg": 1,
        "components-promo-alternate-color-light-content-bg": 1,
        "components-promo-alternate-color-light-title": 1,
        "components-promo-alternate-color-light-body": 1,
        "components-promo-alternate-color-light-category": 1,
        "components-promo-alternate-color-brand-bg": 1,
        "components-promo-alternate-color-brand-image-bg": 1,
        "components-promo-alternate-color-brand-content-bg": 1,
        "components-promo-alternate-color-brand-title": 1,
        "components-promo-alternate-color-brand-body": 1,
        "components-promo-alternate-color-brand-category": 1,
        "components-share-list-color-title": 1,
        "components-share-list-color-bg": 1,
        "components-share-list-color-link-default": 1,
        "components-share-list-color-link-hover": 1,
        "components-share-list-color-bg-link-hover": 1,
        "components-share-list-color-close-icon": 1,
        "components-share-list-color-facebook-icon": 1,
        "components-share-list-color-pinterest-icon": 1,
        "components-share-list-color-twitter-icon": 1,
        "components-share-list-color-basic-icon-default": 1,
        "components-share-list-color-icon-hover": 1,
        "components-share-list-color-border": 1,
        "components-site-search-bg": 1,
        "components-site-search-results_text": 1,
        "components-site-search-filter-filter-selected-bg": 1,
        "components-site-search-facet-group-label-bottom-line": 1,
        "components-site-search-facet-group-label-text": 1,
        "components-site-search-facet-group-label-bg": 1,
        "components-site-search-facet-bg": 1,
        "components-site-search-facet-stroke": 1,
        "components-site-search-refine-bg": 1,
        "components-site-search-refine-right-divider": 1,
        "components-site-search-refine-title": 1,
        "components-site-search-hero-search-bg": 1,
        "components-site-search-hero-search-accent-bg": 1,
        "components-site-search-hero-search-title": 1,
        "components-stats-stat-text": 1,
        "components-stats-stat-text-on-light": 1,
        "components-stats-supporting-text": 1,
        "components-stats-bg-default": 1,
        "components-stats-bg-inner": 1,
        "components-stats-bg-section-inner": 1,
        "components-stats-title": 1,
        "components-stats-copy": 1,
        "components-supporting-text-default": 1,
        "components-supporting-text-destructive": 1,
        "components-tab-bg": 1,
        "components-tab-border-default": 1,
        "components-tab-border-hover": 1,
        "components-tab-border-focused": 1,
        "components-tab-border-destructive": 1,
        "components-tab-label-text-default": 1,
        "components-tab-item-text-default": 1,
        "components-tab-item-bg-default": 1,
        "components-tab-item-bg-hover": 1,
        "components-tab-input-field-default": 1,
        "components-tab-input-field-focused": 1,
        "components-tab-input-label-focused": 1,
        "components-tab-input-field-destructive": 1,
        "components-tab-bg-2": 1,
        "components-tag-color-bg": 1,
        "components-tag-color-text": 1,
        "components-tag-color-stroke": 1,
        "components-tag-color-bg-hover": 1,
        "components-tag-color-text-hover": 1,
        "components-tag-color-stroke-hover": 1,
        "components-text-field-bg": 1,
        "components-text-field-bg-focused": 1,
        "components-text-field-border-default": 1,
        "components-text-field-border-hover": 1,
        "components-text-field-border-focused": 1,
        "components-text-field-border-destructive": 1,
        "components-text-field-input-field-default": 1,
        "components-text-field-input-field-focused": 1,
        "components-text-field-input-label-focused": 1,
        "components-text-field-input-field-destructive": 1,
        "components-text-field-icon-default": 1,
        "components-text-field-icon-destructive": 1,
        "components-text-field-supporting-text-default": 1,
        "components-text-field-supporting-text-destructive": 1,
        "components-tip-color-bg": 1,
        "components-tip-color-title": 1,
        "components-tip-color-body": 1,
        "components-tip-color-stroke": 1,
        "components-video-color-bg": 1,
        "themes-general-surface-color-light-bg": 1,
        "themes-general-surface-color-light-title": 1,
        "themes-general-surface-color-light-body": 1,
        "themes-general-surface-color-light-decor-line-break": 1,
        "themes-general-surface-color-light-rule-line": 1,
        "themes-general-surface-color-dark-bg": 1,
        "themes-general-surface-color-dark-title": 1,
        "themes-general-surface-color-dark-body": 1,
        "themes-general-surface-color-dark-decor-line-break": 1,
        "themes-general-card-color-dark-title": 1,
        "themes-general-card-color-dark-subtitle": 1,
        "themes-general-card-color-dark-body": 1,
        "themes-general-card-color-light-title": 1,
        "themes-general-card-color-light-subtitle": 1,
        "themes-general-card-color-light-body": 1,
        "themes-shadow-color": 1,
        "themes-shadow-share-list-color-fill": 0,
        "themes-shadow-header-menu-color-fill": 0,
        "themes-gradient-loader-gradient-fill-1": 1,
        "themes-gradient-loader-gradient-fill-2": 0.03,
        "themes-color-background-white": 1,
        "themes-color-background-light": 1,
        "themes-color-background-dark": 1,
        "themes-color-background-black": 1,
        "themes-color-background-brand1": 1,
        "themes-color-background-brand2": 1,
        "themes-color-background-accent1": 1,
        "themes-color-background-accent2": 1,
        "themes-color-background-body": 1,
        "themes-color-stroke-white": 1,
        "themes-color-stroke-light": 1,
        "themes-color-stroke-dark": 1,
        "themes-color-stroke-black": 1,
        "themes-color-stroke-brand1": 1,
        "themes-color-stroke-brand2": 1,
        "themes-color-stroke-accent1": 1,
        "themes-color-stroke-accent2": 1,
        "themes-color-text-white": 1,
        "themes-color-text-light": 1,
        "themes-color-text-dark": 1,
        "themes-color-text-black": 1,
        "themes-color-text-brand1": 1,
        "themes-color-text-brand2": 1,
        "themes-color-text-accent1": 1,
        "themes-color-text-accent2": 1,
        "colors-grayscale-100": 1,
        "colors-grayscale-200": 1,
        "colors-grayscale-300": 1,
        "colors-grayscale-400": 1,
        "colors-grayscale-500": 1,
        "colors-grayscale-600": 1,
        "colors-grayscale-700": 1,
        "colors-brand-1-100": 1,
        "colors-brand-1-200": 1,
        "colors-brand-1-300": 1,
        "colors-brand-1-400": 1,
        "colors-brand-1-500": 1,
        "colors-brand-1-600": 1,
        "colors-brand-1-700": 1,
        "colors-brand-2-100": 1,
        "colors-brand-2-200": 1,
        "colors-brand-2-300": 1,
        "colors-brand-2-400": 1,
        "colors-brand-2-500": 1,
        "colors-brand-2-600": 1,
        "colors-brand-2-700": 1,
        "colors-accent-1-100": 1,
        "colors-accent-1-200": 1,
        "colors-accent-1-300": 1,
        "colors-accent-1-400": 1,
        "colors-accent-1-500": 1,
        "colors-accent-1-600": 1,
        "colors-accent-1-700": 1,
        "colors-accent-2-100": 1,
        "colors-accent-2-200": 1,
        "colors-accent-2-300": 1,
        "colors-accent-2-400": 1,
        "colors-accent-2-500": 1,
        "colors-accent-2-600": 1,
        "colors-accent-2-700": 1,
        "colors-accent-3-100": 1,
        "colors-accent-3-200": 1,
        "colors-accent-3-300": 1,
        "colors-accent-3-400": 1,
        "colors-accent-3-500": 1,
        "colors-accent-3-600": 1,
        "colors-accent-3-700": 1,
        "colors-accent-4-400_storage": 1,
        "colors-accessible-accent-1-100": 1,
        "colors-accessible-accent-1-200": 1,
        "colors-accessible-accent-1-300": 1,
        "colors-accessible-accent-1-400": 1,
        "colors-accessible-accent-1-500": 1,
        "colors-accessible-accent-1-600": 1,
        "colors-accessible-accent-1-700": 1,
        "colors-accessible-accent-2-100": 1,
        "colors-accessible-accent-2-200": 1,
        "colors-accessible-accent-2-300": 1,
        "colors-accessible-accent-2-400": 1,
        "colors-accessible-accent-2-500": 1,
        "colors-accessible-accent-2-600": 1,
        "colors-accessible-accent-2-700": 1,
        "colors-accessible-brand-2-100": 1,
        "colors-accessible-brand-2-200": 1,
        "colors-accessible-brand-2-300": 1,
        "colors-accessible-brand-2-400": 1,
        "colors-accessible-brand-2-500": 1,
        "colors-accessible-brand-2-600": 1,
        "colors-accessible-brand-2-700": 1
      }
    },
    "DART": {
      "colors": {
        "components-accessibility-bar-color-bg": "#191b1c",
        "components-accessibility-bar-color-text": "#fcfcfd",
        "components-accordion-color-bg": "#000000",
        "components-accordion-color-title": "#fcfcfd",
        "components-accordion-color-body": "#e8e8ea",
        "components-accordion-color-section-section-bg": "#27282b",
        "components-accordion-color-section-section-title": "#fcfcfd",
        "components-accordion-color-section-section-body": "#e8e8ea",
        "components-accordion-color-item-item-bg": "#191b1c",
        "components-accordion-color-item-divider": "#191b1c",
        "components-accordion-color-item-icon-icon-default": "#d2ff1a",
        "components-accordion-color-item-icon-icon-hover": "#ffffff",
        "components-accordion-color-item-icon-icon-pressed": "#ffffff",
        "components-accordion-color-item-icon-icon-active": "#ffffff",
        "components-accordion-color-item-title-item-default": "#e8e8ea",
        "components-accordion-color-item-title-item-hover": "#ffffff",
        "components-accordion-color-item-title-item-pressed": "#ffffff",
        "components-accordion-color-item-title-item-active": "#ffffff",
        "components-accordion-color-content-bg": "#000000",
        "components-accordion-color-content-title": "#fcfcfd",
        "components-accordion-color-content-body": "#e8e8ea",
        "components-article-card-color-bg": "#27282b",
        "components-article-card-color-border": "#27282b",
        "components-article-card-color-content-bg": "#27282b",
        "components-article-card-color-title": "#fcfcfd",
        "components-article-card-color-subtitle": "#e8e8ea",
        "components-article-card-color-copy": "#e8e8ea",
        "components-article-card-color-description": "#e8e8ea",
        "components-article-card-listing-color-default-bg": "#191b1c",
        "components-article-card-listing-color-default-title": "#fcfcfd",
        "components-article-card-listing-color-default-copy": "#e8e8ea",
        "components-article-hero-color-content-bg": "#191b1c",
        "components-article-hero-color-bg": "#191b1c",
        "components-article-hero-color-headline": "#fcfcfd",
        "components-article-hero-color-body": "#e8e8ea",
        "components-article-hero-color-detail-text": "#e8e8ea",
        "components-article-hero-color-divider": "#4e5056",
        "components-article-hero-color-icon": "#d2ff1a",
        "components-article-hero-color-play-icon": "#d2ff1a",
        "components-article-hero-color-selected-stroke": "#d2ff1a",
        "components-banner-color-bg-default": "#37118e",
        "components-banner-color-icon-default": "#a47efc",
        "components-banner-color-title-default": "#efe9fe",
        "components-banner-color-body-default": "#efe9fe",
        "components-banner-color-icon-close-default": "#fcfcfd",
        "components-banner-color-bg-alert": "#b32b30",
        "components-banner-color-icon-alert": "#ffffff",
        "components-banner-color-title-alert": "#fcfcfd",
        "components-banner-color-body-alert": "#fcfcfd",
        "components-banner-color-icon-close-alert": "#fcfcfd",
        "components-breadcrumb-color-bg": "#191b1c",
        "components-breadcrumb-color-breadcrumb-link-default": "#d2ff1a",
        "components-breadcrumb-color-breadcrumb-link-hover": "#f1ffb2",
        "components-breadcrumb-color-breadcrumb-link-active": "#e8e8ea",
        "components-breadcrumb-color-icon-divider": "#4e5056",
        "components-bug-finder-prompt": "#ffffff",
        "components-button-color-filled-brand-default-bg": "#d2ff1a",
        "components-button-color-filled-brand-default-text": "#000000",
        "components-button-color-filled-brand-default-stroke": "#d2ff1a",
        "components-button-color-filled-brand-default-icon": "#000000",
        "components-button-color-filled-brand-hover-bg": "#f1ffb2",
        "components-button-color-filled-brand-hover-text": "#000000",
        "components-button-color-filled-brand-hover-stroke": "#f1ffb2",
        "components-button-color-filled-brand-hover-icon": "#000000",
        "components-button-color-filled-brand-pressed-bg": "#e1ff66",
        "components-button-color-filled-brand-pressed-text": "#000000",
        "components-button-color-filled-brand-pressed-stroke": "#e1ff66",
        "components-button-color-filled-brand-pressed-icon": "#000000",
        "components-button-color-filled-brand-disabled-bg": "#6a6a6c",
        "components-button-color-filled-brand-disabled-text": "#a2a5ab",
        "components-button-color-filled-brand-disabled-stroke": "#e8e8ea",
        "components-button-color-filled-brand-disabled-icon": "#a2a5ab",
        "components-button-color-filled-brand-focus-bg": "#d2ff1a",
        "components-button-color-filled-brand-focus-text": "#000000",
        "components-button-color-filled-brand-focus-stroke": "#d2ff1a",
        "components-button-color-filled-brand-focus-icon": "#000000",
        "components-button-color-filled-brand-focus-outline": "#286efa",
        "components-button-color-filled-tonal-default-bg": "#f1e7f7",
        "components-button-color-filled-tonal-default-text": "#5f098a",
        "components-button-color-filled-tonal-default-stroke": "#f1e7f7",
        "components-button-color-filled-tonal-default-icon": "#5f098a",
        "components-button-color-filled-tonal-hover-bg": "#e4ceef",
        "components-button-color-filled-tonal-hover-text": "#470768",
        "components-button-color-filled-tonal-hover-stroke": "#e4ceef",
        "components-button-color-filled-tonal-hover-icon": "#470768",
        "components-button-color-filled-tonal-pressed-bg": "#ad6dce",
        "components-button-color-filled-tonal-pressed-text": "#300445",
        "components-button-color-filled-tonal-pressed-stroke": "#ad6dce",
        "components-button-color-filled-tonal-pressed-icon": "#300445",
        "components-button-color-filled-tonal-disabled-bg": "#e8e8ea",
        "components-button-color-filled-tonal-disabled-text": "#a2a5ab",
        "components-button-color-filled-tonal-disabled-stroke": "#e8e8ea",
        "components-button-color-filled-tonal-disabled-icon": "#a2a5ab",
        "components-button-color-filled-tonal-focus-bg": "#f1e7f7",
        "components-button-color-filled-tonal-focus-text": "#5f098a",
        "components-button-color-filled-tonal-focus-stroke": "#f1e7f7",
        "components-button-color-filled-tonal-focus-icon": "#5f098a",
        "components-button-color-filled-tonal-focus-outline": "#1c4cae",
        "components-button-color-filled-white-default-bg": "#fcfcfd",
        "components-button-color-filled-white-default-text": "#000000",
        "components-button-color-filled-white-default-stroke": "#fcfcfd",
        "components-button-color-filled-white-default-icon": "#000000",
        "components-button-color-filled-white-hover-bg": "#e8e8ea",
        "components-button-color-filled-white-hover-text": "#000000",
        "components-button-color-filled-white-hover-stroke": "#e8e8ea",
        "components-button-color-filled-white-hover-icon": "#000000",
        "components-button-color-filled-white-pressed-bg": "#4e5056",
        "components-button-color-filled-white-pressed-text": "#fcfcfd",
        "components-button-color-filled-white-pressed-stroke": "#4e5056",
        "components-button-color-filled-white-pressed-icon": "#fcfcfd",
        "components-button-color-filled-white-disabled-bg": "#e8e8ea",
        "components-button-color-filled-white-disabled-text": "#a2a5ab",
        "components-button-color-filled-white-disabled-stroke": "#e8e8ea",
        "components-button-color-filled-white-disabled-icon": "#a2a5ab",
        "components-button-color-filled-white-focus-bg": "#fcfcfd",
        "components-button-color-filled-white-focus-text": "#000000",
        "components-button-color-filled-white-focus-stroke": "#fcfcfd",
        "components-button-color-filled-white-focus-icon": "#000000",
        "components-button-color-filled-white-focus-outline": "#1c4cae",
        "components-button-color-outline-brand-default-bg": "rgba(0, 0, 0, 0.00)",
        "components-button-color-outline-brand-default-text": "#d2ff1a",
        "components-button-color-outline-brand-default-stroke": "#d2ff1a",
        "components-button-color-outline-brand-default-icon": "#d2ff1a",
        "components-button-color-outline-brand-hover-bg": "#f1ffb2",
        "components-button-color-outline-brand-hover-text": "#000000",
        "components-button-color-outline-brand-hover-stroke": "#f1ffb2",
        "components-button-color-outline-brand-hover-icon": "#000000",
        "components-button-color-outline-brand-pressed-bg": "#e1ff66",
        "components-button-color-outline-brand-pressed-text": "#000000",
        "components-button-color-outline-brand-pressed-stroke": "#e1ff66",
        "components-button-color-outline-brand-pressed-icon": "#000000",
        "components-button-color-outline-brand-disabled-bg": "rgba(115, 119, 128, 0.00)",
        "components-button-color-outline-brand-disabled-text": "#72757e",
        "components-button-color-outline-brand-disabled-stroke": "#72757e",
        "components-button-color-outline-brand-disabled-icon": "#72757e",
        "components-button-color-outline-brand-focus-bg": "#000000",
        "components-button-color-outline-brand-focus-text": "#d2ff1a",
        "components-button-color-outline-brand-focus-stroke": "#d2ff1a",
        "components-button-color-outline-brand-focus-icon": "#d2ff1a",
        "components-button-color-outline-brand-focus-outline": "#286efa",
        "components-button-color-outline-tonal-default-bg": "rgba(0, 0, 0, 0.00)",
        "components-button-color-outline-tonal-default-text": "#e4ceef",
        "components-button-color-outline-tonal-default-stroke": "#e4ceef",
        "components-button-color-outline-tonal-default-icon": "#e4ceef",
        "components-button-color-outline-tonal-hover-bg": "#e4ceef",
        "components-button-color-outline-tonal-hover-text": "#470768",
        "components-button-color-outline-tonal-hover-stroke": "#e4ceef",
        "components-button-color-outline-tonal-hover-icon": "#470768",
        "components-button-color-outline-tonal-pressed-bg": "#ad6dce",
        "components-button-color-outline-tonal-pressed-text": "#300445",
        "components-button-color-outline-tonal-pressed-stroke": "#ad6dce",
        "components-button-color-outline-tonal-pressed-icon": "#300445",
        "components-button-color-outline-tonal-disabled-bg": "rgba(115, 119, 128, 0.00)",
        "components-button-color-outline-tonal-disabled-text": "#72757e",
        "components-button-color-outline-tonal-disabled-stroke": "#72757e",
        "components-button-color-outline-tonal-disabled-icon": "#72757e",
        "components-button-color-outline-tonal-focus-bg": "#000000",
        "components-button-color-outline-tonal-focus-text": "#e4ceef",
        "components-button-color-outline-tonal-focus-stroke": "#e4ceef",
        "components-button-color-outline-tonal-focus-icon": "#e4ceef",
        "components-button-color-outline-tonal-focus-outline": "#80aaff",
        "components-button-color-outline-white-default-bg": "rgba(39, 40, 43, 0.00)",
        "components-button-color-outline-white-default-text": "#fcfcfd",
        "components-button-color-outline-white-default-stroke": "#fcfcfd",
        "components-button-color-outline-white-default-icon": "#fcfcfd",
        "components-button-color-outline-white-hover-bg": "#e8e8ea",
        "components-button-color-outline-white-hover-text": "#000000",
        "components-button-color-outline-white-hover-stroke": "#e8e8ea",
        "components-button-color-outline-white-hover-icon": "#000000",
        "components-button-color-outline-white-pressed-bg": "#4e5056",
        "components-button-color-outline-white-pressed-text": "#fcfcfd",
        "components-button-color-outline-white-pressed-stroke": "#4e5056",
        "components-button-color-outline-white-pressed-icon": "#fcfcfd",
        "components-button-color-outline-white-disabled-bg": "rgba(115, 119, 128, 0.00)",
        "components-button-color-outline-white-disabled-text": "#72757e",
        "components-button-color-outline-white-disabled-stroke": "#72757e",
        "components-button-color-outline-white-disabled-icon": "#72757e",
        "components-button-color-outline-white-focus-bg": "#191b1c",
        "components-button-color-outline-white-focus-text": "#fcfcfd",
        "components-button-color-outline-white-focus-stroke": "#fcfcfd",
        "components-button-color-outline-white-focus-icon": "#fcfcfd",
        "components-button-color-outline-white-focus-outline": "#1c4cae",
        "components-button-color-text-brand-default-bg": "rgba(43, 51, 8, 0.00)",
        "components-button-color-text-brand-default-text": "#d2ff1a",
        "components-button-color-text-brand-default-stroke": "#d2ff1a",
        "components-button-color-text-brand-default-icon": "#d2ff1a",
        "components-button-color-text-brand-hover-bg": "rgba(43, 51, 8, 0.00)",
        "components-button-color-text-brand-hover-bg-icon-only": "#5d6e11",
        "components-button-color-text-brand-hover-text": "#f1ffb2",
        "components-button-color-text-brand-hover-stroke": "#f1ffb2",
        "components-button-color-text-brand-hover-icon": "#f1ffb2",
        "components-button-color-text-brand-pressed-bg": "rgba(43, 51, 8, 0.00)",
        "components-button-color-text-brand-pressed-bg-icon-only": "#2b3308",
        "components-button-color-text-brand-pressed-text": "#e1ff66",
        "components-button-color-text-brand-pressed-stroke": "#e1ff66",
        "components-button-color-text-brand-pressed-icon": "#e1ff66",
        "components-button-color-text-brand-disabled-bg": "rgba(43, 51, 8, 0.00)",
        "components-button-color-text-brand-disabled-text": "#72757e",
        "components-button-color-text-brand-disabled-stroke": "#72757e",
        "components-button-color-text-brand-disabled-icon": "#72757e",
        "components-button-color-text-brand-focus-bg": "rgba(43, 51, 8, 0.00)",
        "components-button-color-text-brand-focus-text": "#d2ff1a",
        "components-button-color-text-brand-focus-stroke": "#d2ff1a",
        "components-button-color-text-brand-focus-icon": "#80aaff",
        "components-button-color-text-brand-focus-outline": "#80aaff",
        "components-button-color-text-tonal-default-bg": "rgba(5, 23, 57, 0.00)",
        "components-button-color-text-tonal-default-text": "#fcfcfd",
        "components-button-color-text-tonal-default-stroke": "#770bad",
        "components-button-color-text-tonal-default-icon": "#770bad",
        "components-button-color-text-tonal-hover-bg": "rgba(5, 23, 57, 0.00)",
        "components-button-color-text-tonal-hover-bg-icon-only": "#2b3308",
        "components-button-color-text-tonal-hover-text": "#fcfcfd",
        "components-button-color-text-tonal-hover-stroke": "#770bad",
        "components-button-color-text-tonal-hover-icon": "#770bad",
        "components-button-color-text-tonal-pressed-bg": "rgba(5, 23, 57, 0.00)",
        "components-button-color-text-tonal-pressed-bg-icon-only": "#2b3308",
        "components-button-color-text-tonal-pressed-text": "#fcfcfd",
        "components-button-color-text-tonal-pressed-stroke": "#770bad",
        "components-button-color-text-tonal-pressed-icon": "#770bad",
        "components-button-color-text-tonal-disabled-bg": "rgba(5, 23, 57, 0.00)",
        "components-button-color-text-tonal-disabled-text": "#72757e",
        "components-button-color-text-tonal-disabled-stroke": "#72757e",
        "components-button-color-text-tonal-disabled-icon": "#72757e",
        "components-button-color-text-tonal-focus-bg": "rgba(5, 23, 57, 0.00)",
        "components-button-color-text-tonal-focus-text": "#fcfcfd",
        "components-button-color-text-tonal-focus-stroke": "#770bad",
        "components-button-color-text-tonal-focus-icon": "#80aaff",
        "components-button-color-text-tonal-focus-outline": "#80aaff",
        "components-button-color-text-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-default-text": "#fcfcfd",
        "components-button-color-text-white-default-stroke": "#fcfcfd",
        "components-button-color-text-white-default-icon": "#fcfcfd",
        "components-button-color-text-white-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-hover-bg-icon-only": "#2b3308",
        "components-button-color-text-white-hover-text": "#fcfcfd",
        "components-button-color-text-white-hover-stroke": "#fcfcfd",
        "components-button-color-text-white-hover-icon": "#fcfcfd",
        "components-button-color-text-white-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-pressed-bg-icon-only": "#2b3308",
        "components-button-color-text-white-pressed-text": "#fcfcfd",
        "components-button-color-text-white-pressed-stroke": "#fcfcfd",
        "components-button-color-text-white-pressed-icon": "#fcfcfd",
        "components-button-color-text-white-disabled-bg": "rgba(115, 119, 128, 0.00)",
        "components-button-color-text-white-disabled-text": "#72757e",
        "components-button-color-text-white-disabled-stroke": "#72757e",
        "components-button-color-text-white-disabled-icon": "#72757e",
        "components-button-color-text-white-focus-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-focus-text": "#fcfcfd",
        "components-button-color-text-white-focus-stroke": "#fcfcfd",
        "components-button-color-text-white-focus-icon": "#80aaff",
        "components-button-color-text-white-focus-outline": "#80aaff",
        "components-button-group-color-border": "#4e5056",
        "components-button-group-color-button-bg-default": "rgba(0, 0, 0, 0.00)",
        "components-button-group-color-button-fg-default": "#d2ff1a",
        "components-button-group-color-button-bg-hover": "#2b3308",
        "components-button-group-color-button-fg-hover": "#e1ff66",
        "components-button-group-color-button-bg-active": "#f8ffdb",
        "components-button-group-color-button-fg-active": "#2b3308",
        "components-button-group-color-overlay-left-gradient-fill-1": "#191b1c",
        "components-button-group-color-overlay-left-gradient-fill-2": "rgba(25, 27, 28, 0.50)",
        "components-button-group-color-stroke-default-gradient-fill-1": "rgba(0, 0, 0, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-2": "rgba(0, 0, 0, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-3": "rgba(0, 0, 0, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-4": "rgba(0, 0, 0, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-1": "#2b3308",
        "components-button-group-color-stroke-hover-gradient-fill-2": "#2b3308",
        "components-button-group-color-stroke-hover-gradient-fill-3": "#2b3308",
        "components-button-group-color-stroke-hover-gradient-fill-4": "#2b3308",
        "components-button-group-color-stroke-active-gradient-fill-1": "#f8ffdb",
        "components-button-group-color-stroke-active-gradient-fill-2": "#f8ffdb",
        "components-button-group-color-stroke-active-gradient-fill-3": "#f8ffdb",
        "components-button-group-color-stroke-active-gradient-fill-4": "#f8ffdb",
        "components-category-card-color-bg": "#27282b",
        "components-category-card-color-content-bg": "#191b1c",
        "components-category-card-color-category-bg": "#191b1c",
        "components-category-card-color-category-bg-2": "#191b1c",
        "components-category-card-color-category-bg-3": "#191b1c",
        "components-category-card-color-category-bg-4": "#191b1c",
        "components-category-card-color-category-bg-5": "#191b1c",
        "components-category-card-color-category-bg-6": "#191b1c",
        "components-category-card-color-title": "#fcfcfd",
        "components-category-card-color-copy": "#e8e8ea",
        "components-category-card-color-stroke": "#4e5056",
        "components-category-card-color-stroke-hover": "#d2ff1a",
        "components-category-card-listing-color-bg": "#191b1c",
        "components-category-card-listing-color-title": "#fcfcfd",
        "components-category-card-listing-color-copy": "#e8e8ea",
        "components-card-search-result-color-bg": "#27282b",
        "components-card-search-result-color-label": "#e8e8ea",
        "components-card-search-result-color-title": "#fcfcfd",
        "components-card-search-result-color-body": "#e8e8ea",
        "components-card-search-result-color-divider-line": "#4e5056",
        "components-contact-us-promo-color-light-bg": "#27282b",
        "components-contact-us-promo-color-light-title": "#fcfcfd",
        "components-contact-us-promo-color-light-body": "#e8e8ea",
        "components-contact-us-promo-color-default-bg": "#191b1c",
        "components-contact-us-promo-color-default-title": "#fcfcfd",
        "components-contact-us-promo-color-default-body": "#e8e8ea",
        "components-contact-us-promo-color-brand-bg": "#470768",
        "components-contact-us-promo-color-brand-title": "#fcfcfd",
        "components-contact-us-promo-color-brand-body": "#e8e8ea",
        "components-content-list-color-bg": "#27282b",
        "components-content-list-color-title": "#fcfcfd",
        "components-content-list-color-body": "#e8e8ea",
        "components-content-list-color-icon": "#77d2a3",
        "components-cookies-colors-bg": "#191b1c",
        "components-cookies-colors-title": "#fcfcfd",
        "components-cookies-colors-body": "#e8e8ea",
        "components-cookies-colors-icon": "#fcfcfd",
        "components-dropdown-bg": "#000000",
        "components-dropdown-border-default": "#4e5056",
        "components-dropdown-border-hover": "#a2a5ab",
        "components-dropdown-border-focused": "#d2ff1a",
        "components-dropdown-border-destructive": "#ff3d45",
        "components-dropdown-label-text-default": "#fcfcfd",
        "components-dropdown-item-text-default": "#fcfcfd",
        "components-dropdown-item-bg-default": "#000000",
        "components-dropdown-item-bg-hover": "#27282b",
        "components-dropdown-input-field-default": "#a2a5ab",
        "components-dropdown-input-field-focused": "#fcfcfd",
        "components-dropdown-input-label-focused": "#d2ff1a",
        "components-dropdown-input-field-destructive": "#ff3d45",
        "components-external-link-card-bg": "#191b1c",
        "components-external-link-card-card-bg": "#191b1c",
        "components-external-link-card-card-title": "#fcfcfd",
        "components-external-link-card-card-subtitle": "#e8e8ea",
        "components-external-link-card-card-eyebrow": "#e8e8ea",
        "components-fab-color-border-default": "#000000",
        "components-footer-brand-color-bg": "#27282b",
        "components-footer-brand-color-logo": "#fcfcfd",
        "components-footer-brand-color-title-text": "#fcfcfd",
        "components-footer-brand-color-description-text": "#fcfcfd",
        "components-footer-brand-color-footer-menu-expanded": "#27282b",
        "components-footer-brand-color-link-default": "#d2ff1a",
        "components-footer-brand-color-link-hover": "#fcfcfd",
        "components-footer-brand-color-link-pressed": "#5d6e11",
        "components-footer-brand-color-category-link-default": "#d2ff1a",
        "components-footer-brand-color-category-link-hover": "#fcfcfd",
        "components-footer-brand-color-category-link-pressed": "#809919",
        "components-footer-brand-color-subcategory-link-default": "#d2ff1a",
        "components-footer-brand-color-subcategory-link-hover": "#fcfcfd",
        "components-footer-brand-color-subcategory-link-pressed": "#809919",
        "components-footer-brand-color-icon-icon-default": "#d2ff1a",
        "components-footer-brand-color-icon-icon-pressed": "#5d6e11",
        "components-footer-brand-color-social-icon-default": "#d2ff1a",
        "components-footer-brand-color-social-icon-hover": "#fcfcfd",
        "components-footer-brand-color-social-icon-pressed": "#809919",
        "components-footer-brand-spacing-category-link-hover": "#000000",
        "components-footer-global-color-bg": "#fcfcfd",
        "components-footer-global-color-title-text": "#000000",
        "components-footer-global-color-disclaimer-text": "#000000",
        "components-footer-global-color-link-default": "#000000",
        "components-footer-global-color-link-hover": "#000000",
        "components-footer-global-color-link-pressed": "#000000",
        "components-generic-card-color-bg": "#27282b",
        "components-generic-card-color-content-bg": "#27282b",
        "components-generic-card-color-title": "#fcfcfd",
        "components-generic-card-color-subtitle": "#e8e8ea",
        "components-generic-card-color-copy": "#e8e8ea",
        "components-global-search-color-omnibox-list-bg": "#191b1c",
        "components-global-search-color-omnibox-divider": "#4e5056",
        "components-global-search-color-omnibox-item-bg-default": "#191b1c",
        "components-global-search-color-omnibox-item-bg-hover": "#27282b",
        "components-global-search-color-omnibox-item-text-default": "#d2ff1a",
        "components-global-search-color-omnibox-item-text-hover": "#d2ff1a",
        "components-global-search-color-omnibox-label-text-default": "#e8e8ea",
        "components-global-search-color-omnibox-category-text-default": "#e8e8ea",
        "components-generic-card-listing-color-bg": "#191b1c",
        "components-generic-card-listing-color-title": "#fcfcfd",
        "components-generic-card-listing-color-copy": "#e8e8ea",
        "components-header-color-bg": "#191b1c",
        "components-header-color-bottom-stroke": "#27282b",
        "components-header-color-search-search-border-default": "#4e5056",
        "components-header-color-search-search-text": "#d2ff1a",
        "components-header-color-search-search-icon": "#d2ff1a",
        "components-header-color-search-search-bar": "#d2ff1a",
        "components-header-color-mobile-mobile-expanded-bg": "#27282b",
        "components-header-color-mobile-pressed-bg": "#191b1c",
        "components-header-color-language-selector-language": "#e8e8ea",
        "components-header-color-language-selector-country": "#fcfcfd",
        "components-header-color-language-selector-country-hover-bg": "#27282b",
        "components-header-color-language-selector-megamenu-language": "#e8e8ea",
        "components-header-color-language-selector-megamenu-country": "#fcfcfd",
        "components-header-color-language-selector-megamenu-country-hover": "#27282b",
        "components-header-color-language-selector-bg": "#191b1c",
        "components-header-color-language-selector-stroke": "#72757e",
        "components-header-color-mega-menu-bg": "#191b1c",
        "components-header-color-mega-menu-featured-bg": "#191b1c",
        "components-header-color-mega-menu-featured-title": "#fcfcfd",
        "components-header-color-mega-menu-category-title": "#fcfcfd",
        "components-header-color-mega-menu-bg-accent": "#191b1c",
        "components-header-color-icon-icon-default": "#d2ff1a",
        "components-header-color-icon-icon-hover": "#809919",
        "components-header-color-icon-icon-pressed": "#5d6e11",
        "components-header-color-links-nav-main-link-default": "#d2ff1a",
        "components-header-color-links-nav-main-link-hover": "#f1ffb2",
        "components-header-color-links-nav-main-link-pressed": "#5d6e11",
        "components-header-color-links-nav-main-link-active": "#d2ff1a",
        "components-header-color-links-nav-main-active-bar": "#d2ff1a",
        "components-header-color-links-nav-main-bg-color": "#f8ffdb",
        "components-header-color-links-nav-category-link-category-default": "#d2ff1a",
        "components-header-color-links-nav-category-link-category-hover": "#f1ffb2",
        "components-header-color-links-nav-category-link-category-pressed": "#f8ffdb",
        "components-header-color-links-nav-category-link-category-active": "#d2ff1a",
        "components-header-color-links-nav-menu-link-link-default": "#fcfcfd",
        "components-header-color-links-nav-menu-link-link-hover": "#d2ff1a",
        "components-header-color-links-nav-menu-link-link-pressed": "#fcfcfd",
        "components-header-color-links-nav-menu-link-link-active": "#191b1c",
        "components-header-color-links-nav-menu-link-bg": "#191b1c",
        "components-header-color-links-nav-subcategory-link-subcategory-default": "#d2ff1a",
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": "#f8ffdb",
        "components-header-color-links-nav-subcategory-link-subcategory-active": "#d2ff1a",
        "components-hero-homepage-color-bg": "#191b1c",
        "components-hero-homepage-color-container-bg": "#191b1c",
        "components-hero-homepage-color-eyebrow": "#fcfcfd",
        "components-hero-homepage-color-header": "#fcfcfd",
        "components-hero-homepage-color-sub-header": "#e8e8ea",
        "components-hero-homepage-color-body": "#e8e8ea",
        "components-hero-homepage-color-play-pause": "#ffffff",
        "components-hero-internal-color-default-title": "#fcfcfd",
        "components-hero-internal-color-default-body": "#e8e8ea",
        "components-hero-internal-color-default-bg": "#470768",
        "components-hero-internal-color-accent-bg": "#770bad",
        "components-hero-internal-color-with-image-bg": "#191b1c",
        "components-hero-internal-color-default-bg-2": "#470768",
        "components-hero-internal-color-with-image-title": "#fcfcfd",
        "components-hero-internal-color-with-image-body": "#e8e8ea",
        "components-heading-page-title-color-default-bg": "#191b1c",
        "components-heading-page-title-color-default-title": "#fcfcfd",
        "components-heading-page-title-color-default-body": "#e8e8ea",
        "components-heading-page-title-color-light-bg": "#27282b",
        "components-heading-page-title-color-light-title": "#fcfcfd",
        "components-heading-page-title-color-light-body": "#e8e8ea",
        "components-heading-page-title-color-color-bg": "#770bad",
        "components-heading-page-title-color-color-title": "#e8e8ea",
        "components-heading-page-title-color-color-body": "#fcfcfd",
        "components-hyperlink-color-dark-default": "#e1ff66",
        "components-hyperlink-color-dark-hover": "#d2ff1a",
        "components-hyperlink-color-dark-visited": "#5d6e11",
        "components-hyperlink-color-light-default": "#e4ceef",
        "components-hyperlink-color-light-hover": "#ad6dce",
        "components-hyperlink-color-light-visited": "#770bad",
        "components-icon-default": "#a2a5ab",
        "components-icon-focused": "#a2a5ab",
        "components-icon-card-color-bg": "#fcfcfd",
        "components-icon-card-color-icon-bg": "#191b1c",
        "components-icon-card-color-icon": "#fcfcfd",
        "components-icon-card-color-title": "#fcfcfd",
        "components-icon-card-color-subtitle": "#e8e8ea",
        "components-icon-card-color-description": "#e8e8ea",
        "components-icon-card-listing-color-bg": "#27282b",
        "components-icon-card-listing-color-inner-bg": "#27282b",
        "components-icon-card-listing-color-brand-bg": "#191b1c",
        "components-icon-card-listing-color-title": "#fcfcfd",
        "components-icon-card-listing-color-copy": "#e8e8ea",
        "components-icon-card-listing-color-inner-legal-text": "#e8e8ea",
        "components-jumplink-color-bg-default": "#191b1c",
        "components-jumplink-color-link-text-default": "#e8e8ea",
        "components-jumplink-color-link-text-hover": "#f1ffb2",
        "components-jumplink-color-link-border-hover": "#f1ffb2",
        "components-jumplink-color-link-text-active": "#d2ff1a",
        "components-jumplink-color-link-border-active": "#d2ff1a",
        "components-jumplink-color-title-text": "#fcfcfd",
        "components-jumplink-color-description-text": "#e8e8ea",
        "components-layout-bg-default": "#191b1c",
        "components-lightbox-color-overlay-bg": "rgba(25, 27, 28, 0.95)",
        "components-lightbox-color-title": "#fcfcfd",
        "components-lightbox-color-body": "#e8e8ea",
        "components-label-color-on-color-label-label-default": "#d2bffd",
        "components-label-color-on-color-label-label-hover": "#1b0947",
        "components-label-color-on-color-label-label-pressed": "#d2bffd",
        "components-label-color-on-color-background-bg-default": "rgba(210, 191, 253, 0.00)",
        "components-label-color-on-color-background-bg-hover": "#d2bffd",
        "components-label-color-on-color-background-bg-pressed": "#1b0947",
        "components-label-color-on-color-stroke-hover": "#d2bffd",
        "components-label-color-on-color-stroke-pressed": "#d2bffd",
        "components-label-color-on-color-stroke-default-gradient-fill-1": "#d2bffd",
        "components-label-color-on-color-stroke-default-gradient-fill-2": "#d2bffd",
        "components-label-color-on-color-stroke-default-gradient-fill-3": "#d2bffd",
        "components-label-color-on-color-stroke-default-gradient-fill-4": "#d2bffd",
        "components-label-color-on-white-label-label-default": "#d2ff1a",
        "components-label-color-on-white-label-label-hover": "#2b3308",
        "components-label-color-on-white-label-label-pressed": "#d2ff1a",
        "components-label-color-on-white-background-bg-default": "#191b1c",
        "components-label-color-on-white-background-bg-hover": "#d2ff1a",
        "components-label-color-on-white-background-bg-pressed": "#2b3308",
        "components-label-color-on-white-stroke-hover": "#d2ff1a",
        "components-label-color-on-white-stroke-pressed": "#d2ff1a",
        "components-label-color-on-white-stroke-default-gradient-fill-1": "#d2ff1a",
        "components-label-color-on-white-stroke-default-gradient-fill-2": "#d2ff1a",
        "components-label-color-on-white-stroke-default-gradient-fill-3": "#d2ff1a",
        "components-label-color-on-white-stroke-default-gradient-fill-4": "#d2ff1a",
        "components-legal-disclaimer-color-default-bg": "#191b1c",
        "components-legal-disclaimer-color-default-title": "#fcfcfd",
        "components-legal-disclaimer-color-default-body": "#e8e8ea",
        "components-legal-disclaimer-color-light-bg": "#27282b",
        "components-legal-disclaimer-color-light-title": "#fcfcfd",
        "components-legal-disclaimer-color-light-body": "#e8e8ea",
        "components-legal-disclaimer-color-brand-bg": "#470768",
        "components-legal-disclaimer-color-brand-title": "#fcfcfd",
        "components-legal-disclaimer-color-brand-body": "#e8e8ea",
        "components-media-video-thumbnail-overlay-bg": "rgba(25, 27, 28, 0.80)",
        "components-media-caption-color-bg": "#000000",
        "components-media-caption-color-text": "#e8e8ea",
        "components-media-gallery-color-bg": "#191b1c",
        "components-media-gallery-color-title": "#fcfcfd",
        "components-media-gallery-color-body": "#e8e8ea",
        "components-pagination-on-white-accent-default": "#d2ff1a",
        "components-pagination-on-white-accent-scroll": "#d2ff1a",
        "components-pagination-on-white-disabled": "#4e5056",
        "components-pagination-on-white-bg": "#2b3308",
        "components-pagination-on-white-stroke": "#4e5056",
        "components-pagination-on-white-bg-active": "#d2ff1a",
        "components-pagination-on-white-bg-hover": "#27282b",
        "components-pagination-on-white-text-default": "#e8e8ea",
        "components-pagination-on-white-text-hover": "#e8e8ea",
        "components-pagination-on-white-text-active": "#191b1c",
        "components-pagination-on-color-accent-default": "#d2ff1a",
        "components-pagination-on-color-accent-scroll": "#d2ff1a",
        "components-pagination-on-color-disabled": "#5f098a",
        "components-pagination-on-color-bg": "#300445",
        "components-pagination-on-color-stroke": "#ad6dce",
        "components-pagination-on-color-bg-active": "#fcfcfd",
        "components-pagination-on-color-bg-hover": "#d2ff1a",
        "components-pagination-on-color-text-default": "#fcfcfd",
        "components-pagination-on-color-text-hover": "#000000",
        "components-pagination-on-color-text-active": "#000000",
        "components-pdf-link-card-color-bg": "#191b1c",
        "components-pdf-link-card-color-card-bg": "#191b1c",
        "components-pdf-link-card-color-card-title": "#fcfcfd",
        "components-pdf-link-card-color-card-subtitle": "#e8e8ea",
        "components-pdf-link-card-color-card-body": "#e8e8ea",
        "components-product-card-color-bg": "#27282b",
        "components-product-card-color-content-bg": "#27282b",
        "components-product-card-color-border": "#27282b",
        "components-product-card-color-title": "#fcfcfd",
        "components-product-card-color-description": "#e8e8ea",
        "components-product-card-color-star-rating": "#d93927",
        "components-product-card-color-badge-bg": "#d93927",
        "components-product-card-color-badge-text": "#ffffff",
        "components-product-card-listing-color-bg": "#191b1c",
        "components-product-card-listing-color-title": "#fcfcfd",
        "components-product-card-listing-color-copy": "#e8e8ea",
        "components-product-card-listing-color-legal-text": "#e8e8ea",
        "components-product-information-color-bg": "#27282b",
        "components-product-information-color-content-bg": "#27282b",
        "components-product-information-color-headline": "#fcfcfd",
        "components-product-information-color-body": "#e8e8ea",
        "components-product-information-color-detail-text": "#e8e8ea",
        "components-product-information-color-divider": "#4e5056",
        "components-product-information-color-icon": "#d2ff1a",
        "components-product-information-color-play-icon": "#d2ff1a",
        "components-product-information-color-selected-stroke": "#d2ff1a",
        "components-product-results-list-with-search-color-bg": "#191b1c",
        "components-promo-color-default-bg": "#191b1c",
        "components-promo-color-default-image-bg": "#191b1c",
        "components-promo-color-default-content-bg": "#191b1c",
        "components-promo-color-default-title": "#fcfcfd",
        "components-promo-color-default-body": "#e8e8ea",
        "components-promo-color-default-category": "#e8e8ea",
        "components-promo-color-light-bg": "#27282b",
        "components-promo-color-light-image-bg": "#27282b",
        "components-promo-color-light-content-bg": "#27282b",
        "components-promo-color-light-title": "#fcfcfd",
        "components-promo-color-light-body": "#e8e8ea",
        "components-promo-color-light-category": "#fcfcfd",
        "components-promo-color-brand-bg": "#470768",
        "components-promo-color-brand-image-bg": "#470768",
        "components-promo-color-brand-content-bg": "#470768",
        "components-promo-color-brand-title": "#fcfcfd",
        "components-promo-color-brand-body": "#e8e8ea",
        "components-promo-color-brand-category": "#e8e8ea",
        "components-pull-quote-color-bg": "#191b1c",
        "components-pull-quote-color-stroke": "#fcfcfd",
        "components-pull-quote-color-quote-bg": "#fcfcfd",
        "components-pull-quote-color-quote-title": "#000000",
        "components-pull-quote-color-quote-by-text": "#27282b",
        "components-pull-quote-color-quote-detail": "#72757e",
        "components-pull-quote-color-quote-highlight": "#770bad",
        "components-promo-alternate-color-default-bg": "#191b1c",
        "components-promo-alternate-color-default-image-bg": "#191b1c",
        "components-promo-alternate-color-default-content-bg": "#191b1c",
        "components-promo-alternate-color-default-title": "#fcfcfd",
        "components-promo-alternate-color-default-body": "#e8e8ea",
        "components-promo-alternate-color-default-category": "#e8e8ea",
        "components-promo-alternate-color-light-bg": "#27282b",
        "components-promo-alternate-color-light-image-bg": "#27282b",
        "components-promo-alternate-color-light-content-bg": "#27282b",
        "components-promo-alternate-color-light-title": "#fcfcfd",
        "components-promo-alternate-color-light-body": "#e8e8ea",
        "components-promo-alternate-color-light-category": "#fcfcfd",
        "components-promo-alternate-color-brand-bg": "#470768",
        "components-promo-alternate-color-brand-image-bg": "#470768",
        "components-promo-alternate-color-brand-content-bg": "#470768",
        "components-promo-alternate-color-brand-title": "#fcfcfd",
        "components-promo-alternate-color-brand-body": "#e8e8ea",
        "components-promo-alternate-color-brand-category": "#e8e8ea",
        "components-share-list-color-title": "#fcfcfd",
        "components-share-list-color-bg": "#191b1c",
        "components-share-list-color-link-default": "#d2ff1a",
        "components-share-list-color-link-hover": "#000000",
        "components-share-list-color-bg-link-hover": "#d2ff1a",
        "components-share-list-color-close-icon": "#fcfcfd",
        "components-share-list-color-facebook-icon": "#3b5998",
        "components-share-list-color-pinterest-icon": "#e60023",
        "components-share-list-color-twitter-icon": "#ffffff",
        "components-share-list-color-basic-icon-default": "#d2ff1a",
        "components-share-list-color-icon-hover": "#191b1c",
        "components-share-list-color-border": "#191b1c",
        "components-site-search-bg": "#191b1c",
        "components-site-search-results_text": "#fcfcfd",
        "components-site-search-filter-filter-selected-bg": "#e8e8ea",
        "components-site-search-facet-group-label-bottom-line": "#4e5056",
        "components-site-search-facet-group-label-text": "#e8e8ea",
        "components-site-search-facet-group-label-bg": "#191b1c",
        "components-site-search-facet-bg": "#191b1c",
        "components-site-search-facet-stroke": "#4e5056",
        "components-site-search-refine-bg": "#191b1c",
        "components-site-search-refine-right-divider": "#27282b",
        "components-site-search-refine-title": "#fcfcfd",
        "components-site-search-hero-search-bg": "#470768",
        "components-site-search-hero-search-accent-bg": "#5f098a",
        "components-site-search-hero-search-title": "#fcfcfd",
        "components-stats-stat-text": "#a47efc",
        "components-stats-stat-text-on-light": "#a47efc",
        "components-stats-supporting-text": "#e8e8ea",
        "components-stats-bg-default": "#27282b",
        "components-stats-bg-inner": "#27282b",
        "components-stats-bg-section-inner": "#191b1c",
        "components-stats-title": "#fcfcfd",
        "components-stats-copy": "#e8e8ea",
        "components-supporting-text-default": "#e8e8ea",
        "components-supporting-text-destructive": "#ff3d45",
        "components-tab-bg": "#191b1c",
        "components-tab-border-default": "#4e5056",
        "components-tab-border-hover": "#a2a5ab",
        "components-tab-border-focused": "#d2ff1a",
        "components-tab-border-destructive": "#ff3d45",
        "components-tab-label-text-default": "#fcfcfd",
        "components-tab-item-text-default": "#fcfcfd",
        "components-tab-item-bg-default": "#191b1c",
        "components-tab-item-bg-hover": "#27282b",
        "components-tab-input-field-default": "#a2a5ab",
        "components-tab-input-field-focused": "#fcfcfd",
        "components-tab-input-label-focused": "#d2ff1a",
        "components-tab-input-field-destructive": "#ff3d45",
        "components-tab-bg-2": "#191b1c",
        "components-tag-color-bg": "#191b1c",
        "components-tag-color-text": "#d2ff1a",
        "components-tag-color-stroke": "#d2ff1a",
        "components-tag-color-bg-hover": "#2b3308",
        "components-tag-color-text-hover": "#fcfcfd",
        "components-tag-color-stroke-hover": "#fcfcfd",
        "components-text-field-bg": "#191b1c",
        "components-text-field-bg-focused": "#27282b",
        "components-text-field-border-default": "#4e5056",
        "components-text-field-border-hover": "#a2a5ab",
        "components-text-field-border-focused": "#d2ff1a",
        "components-text-field-border-destructive": "#ff3d45",
        "components-text-field-input-field-default": "#e8e8ea",
        "components-text-field-input-field-focused": "#fcfcfd",
        "components-text-field-input-label-focused": "#d2ff1a",
        "components-text-field-input-field-destructive": "#ff8b8f",
        "components-text-field-icon-default": "#e8e8ea",
        "components-text-field-icon-destructive": "#ff3d45",
        "components-text-field-supporting-text-default": "#e8e8ea",
        "components-text-field-supporting-text-destructive": "#ff3d45",
        "components-tip-color-bg": "#191b1c",
        "components-tip-color-title": "#77d2a3",
        "components-tip-color-body": "#effaf4",
        "components-tip-color-stroke": "#77d2a3",
        "components-video-color-bg": "#191b1c",
        "themes-general-surface-color-light-bg": "#27282b",
        "themes-general-surface-color-light-title": "#fcfcfd",
        "themes-general-surface-color-light-body": "#e8e8ea",
        "themes-general-surface-color-light-decor-line-break": "#770bad",
        "themes-general-surface-color-light-rule-line": "#770bad",
        "themes-general-surface-color-dark-bg": "#191b1c",
        "themes-general-surface-color-dark-title": "#fcfcfd",
        "themes-general-surface-color-dark-body": "#e8e8ea",
        "themes-general-surface-color-dark-decor-line-break": "#770bad",
        "themes-general-card-color-dark-title": "#000000",
        "themes-general-card-color-dark-subtitle": "#27282b",
        "themes-general-card-color-dark-body": "#27282b",
        "themes-general-card-color-light-title": "#fcfcfd",
        "themes-general-card-color-light-subtitle": "#e8e8ea",
        "themes-general-card-color-light-body": "#e8e8ea",
        "themes-shadow-color": "#ffffff",
        "themes-shadow-share-list-color-fill": "rgba(0, 0, 0, 0.00)",
        "themes-shadow-header-menu-color-fill": "rgba(0, 0, 0, 0.20)",
        "themes-gradient-loader-gradient-fill-1": "#d2ff1a",
        "themes-gradient-loader-gradient-fill-2": "rgba(210, 255, 26, 0.00)",
        "themes-color-background-white": "#fcfcfd",
        "themes-color-background-light": "#e8e8ea",
        "themes-color-background-dark": "#27282b",
        "themes-color-background-black": "#191b1c",
        "themes-color-background-brand1": "#d2ff1a",
        "themes-color-background-brand2": "#770bad",
        "themes-color-background-accent1": "#d93927",
        "themes-color-background-accent2": "#77d2a3",
        "themes-color-background-body": "#191b1c",
        "themes-color-stroke-white": "#fcfcfd",
        "themes-color-stroke-light": "#e8e8ea",
        "themes-color-stroke-dark": "#27282b",
        "themes-color-stroke-black": "#000000",
        "themes-color-stroke-brand1": "#d2ff1a",
        "themes-color-stroke-brand2": "#770bad",
        "themes-color-stroke-accent1": "#d93927",
        "themes-color-stroke-accent2": "#97281c",
        "themes-color-text-white": "#fcfcfd",
        "themes-color-text-light": "#e8e8ea",
        "themes-color-text-dark": "#27282b",
        "themes-color-text-black": "#000000",
        "themes-color-text-brand1": "#d2ff1a",
        "themes-color-text-brand2": "#770bad",
        "themes-color-text-accent1": "#d93927",
        "themes-color-text-accent2": "#183426",
        "colors-grayscale-100": "#fcfcfd",
        "colors-grayscale-200": "#e8e8ea",
        "colors-grayscale-300": "#a2a5ab",
        "colors-grayscale-400": "#72757e",
        "colors-grayscale-500": "#4e5056",
        "colors-grayscale-600": "#27282b",
        "colors-grayscale-700": "#191b1c",
        "colors-brand-1-100": "#f8ffdb",
        "colors-brand-1-200": "#f1ffb2",
        "colors-brand-1-300": "#e1ff66",
        "colors-brand-1-400": "#d2ff1a",
        "colors-brand-1-500": "#809919",
        "colors-brand-1-600": "#5d6e11",
        "colors-brand-1-700": "#2b3308",
        "colors-brand-2-100": "#f1e7f7",
        "colors-brand-2-200": "#e4ceef",
        "colors-brand-2-300": "#ad6dce",
        "colors-brand-2-400": "#770bad",
        "colors-brand-2-500": "#5f098a",
        "colors-brand-2-600": "#470768",
        "colors-brand-2-700": "#300445",
        "colors-accent-1-100": "#fbebe9",
        "colors-accent-1-200": "#f1b3ac",
        "colors-accent-1-300": "#e67b70",
        "colors-accent-1-400": "#d93927",
        "colors-accent-1-500": "#ad2e1f",
        "colors-accent-1-600": "#97281c",
        "colors-accent-1-700": "#571710",
        "colors-accent-2-100": "#effaf4",
        "colors-accent-2-200": "#d2f0e0",
        "colors-accent-2-300": "#a4e1c2",
        "colors-accent-2-400": "#77d2a3",
        "colors-accent-2-500": "#52ad7f",
        "colors-accent-2-600": "#377455",
        "colors-accent-2-700": "#183426",
        "colors-accent-3-100": "#efe9fe",
        "colors-accent-3-200": "#d2bffd",
        "colors-accent-3-300": "#a47efc",
        "colors-accent-3-400": "#773efa",
        "colors-accent-3-500": "#521ad6",
        "colors-accent-3-600": "#37118e",
        "colors-accent-3-700": "#1b0947",
        "colors-accent-4-400_storage": "#25dab9",
        "colors-accessible-accent-1-100": "#fbebe9",
        "colors-accessible-accent-1-200": "#f1b3ac",
        "colors-accessible-accent-1-300": "#e67b70",
        "colors-accessible-accent-1-400": "#d93927",
        "colors-accessible-accent-1-500": "#ad2e1f",
        "colors-accessible-accent-1-600": "#97281c",
        "colors-accessible-accent-1-700": "#571710",
        "colors-accessible-accent-2-100": "#effaf4",
        "colors-accessible-accent-2-200": "#d2f0e0",
        "colors-accessible-accent-2-300": "#a4e1c2",
        "colors-accessible-accent-2-400": "#77d2a3",
        "colors-accessible-accent-2-500": "#52ad7f",
        "colors-accessible-accent-2-600": "#377455",
        "colors-accessible-accent-2-700": "#183426",
        "colors-accessible-brand-2-100": "#f1e7f7",
        "colors-accessible-brand-2-200": "#e4ceef",
        "colors-accessible-brand-2-300": "#ad6dce",
        "colors-accessible-brand-2-400": "#770bad",
        "colors-accessible-brand-2-500": "#5f098a",
        "colors-accessible-brand-2-600": "#470768",
        "colors-accessible-brand-2-700": "#300445"
      },
      "spacing": {
        "components-accordion-spacing-large-padding-x": "5rem",
        "components-accordion-spacing-large-icon-margin-right": "2rem",
        "components-accordion-spacing-large-copy-margin-right": "2.5rem",
        "components-accordion-spacing-large-maxwidth": "90rem",
        "components-accordion-spacing-large-section-padding-x": "2.5rem",
        "components-accordion-spacing-large-section-padding-y": "2.5rem",
        "components-accordion-spacing-large-section-copy-margin-bottom": "2.5rem",
        "components-accordion-spacing-large-section-space-between": "2.5rem",
        "components-accordion-spacing-large-item-padding-x": "2rem",
        "components-accordion-spacing-large-item-padding-y": "2rem",
        "components-accordion-spacing-large-item-item-space-between": "1.5rem",
        "components-accordion-spacing-large-content-padding-x": "2.5rem",
        "components-accordion-spacing-large-content-padding-y": "2.5rem",
        "components-accordion-spacing-large-content-title-margin-bottom": "2.5rem",
        "components-accordion-spacing-large-content-content-space-between": "2.5rem",
        "components-accordion-spacing-large-content-content-margin-left": "2.5rem",
        "components-accordion-spacing-small-padding-top": "1rem",
        "components-accordion-spacing-small-padding-x": "1rem",
        "components-accordion-spacing-small-padding-y": "2.5rem",
        "components-accordion-spacing-small-icon-margin-right": "0.75rem",
        "components-accordion-spacing-small-title-margin-bottom": "0.75rem",
        "components-accordion-spacing-small-copy-margin-right": "1rem",
        "components-accordion-spacing-small-copy-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-padding-x": "1rem",
        "components-accordion-spacing-small-section-padding-y": "1.5rem",
        "components-accordion-spacing-small-section-title-margin-bottom": "1.5rem",
        "components-accordion-spacing-small-section-space-between": "1.5rem",
        "components-accordion-spacing-small-item-padding-x": "1.5rem",
        "components-accordion-spacing-small-item-padding-y": "0.75rem",
        "components-accordion-spacing-small-item-item-space-between": "0.75rem",
        "components-accordion-spacing-small-content-padding-x": "1.5rem",
        "components-accordion-spacing-small-content-padding-y": "1.5rem",
        "components-accordion-spacing-small-content-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-content-content-space-between": "1.5rem",
        "components-accordion-spacing-small-content-content-margin-left": "1rem",
        "components-article-card-spacing-large-padding-image-x": "1rem",
        "components-article-card-spacing-large-padding-image-y": "1rem",
        "components-article-card-spacing-large-padding-content-x": "1.5rem",
        "components-article-card-spacing-large-padding-content-y": "1.5rem",
        "components-article-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-article-card-spacing-large-button-space-between": "1rem",
        "components-article-card-spacing-large-title-margin-bottom": "1rem",
        "components-article-card-spacing-large-list-content-gap": "0rem",
        "components-article-card-spacing-large-list-image-padding-x": "1rem",
        "components-article-card-spacing-large-list-image-padding-y": "1rem",
        "components-article-card-spacing-small-padding-image-x": "1rem",
        "components-article-card-spacing-small-padding-image-y": "1rem",
        "components-article-card-spacing-small-padding-content-x": "1.5rem",
        "components-article-card-spacing-small-padding-content-y": "1.5rem",
        "components-article-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-article-card-listing-spacing-small-padding-x": "1.5rem",
        "components-article-card-listing-spacing-small-padding-y": "2.5rem",
        "components-article-hero-spacing-large-padding-x": "0rem",
        "components-article-hero-spacing-large-padding-y": "0rem",
        "components-article-hero-spacing-large-title-margin-bottom": "2rem",
        "components-article-hero-spacing-large-label-share-margin-bottom": "2rem",
        "components-article-hero-spacing-large-author-name-margin-bottom": "0.75rem",
        "components-article-hero-spacing-large-author-thumbnail-margin-left": "1rem",
        "components-article-hero-spacing-large-details-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-space-between": "1rem",
        "components-article-hero-spacing-large-body-margin-bottom": "2rem",
        "components-article-hero-spacing-large-image-space-between": "0.5rem",
        "components-article-hero-spacing-large-image-margin-bottom": "0.5rem",
        "components-article-hero-spacing-large-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-stats-margin-bottom": "2.5rem",
        "components-article-hero-spacing-small-content-margin-top": "2.5rem",
        "components-article-hero-spacing-small-title-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-label-share-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-authorname-margin-bottom": "0.75rem",
        "components-article-hero-spacing-small-authorthumbnail-margin-left": "1rem",
        "components-article-hero-spacing-small-details-margin-bottom": "1rem",
        "components-article-hero-spacing-small-space-between": "0.75rem",
        "components-article-hero-spacing-small-body-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-image-space-between": "0.75rem",
        "components-article-hero-spacing-small-image-margin-bottom": "1rem",
        "components-article-hero-spacing-small-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-stats-margin-bottom": "2rem",
        "components-banner-spacing-large-content-padding-x": "1.5rem",
        "components-banner-spacing-large-content-space-between": "1rem",
        "components-banner-spacing-large-content-width": "69.375rem",
        "components-banner-spacing-icon-dimensions": "1.5rem",
        "components-banner-spacing-icon-margin-left": "0.5rem",
        "components-banner-spacing-small-space-between": "1rem",
        "components-banner-spacing-small-content-space-between": "1rem",
        "components-banner-spacing-small-title-margin-bottom": "0.75rem",
        "components-banner-spacing-small-content-margin-bottom": "1rem",
        "components-breadcrumb-spacing-large-space-between": "0.25rem",
        "components-breadcrumb-spacing-small-space-between": "0.25rem",
        "components-button-spacing-large-icon-large-x": "2.5rem",
        "components-button-spacing-large-icon-large-y": "2.5rem",
        "components-button-spacing-large-default-padding-y": "0.75rem",
        "components-button-spacing-large-default-padding-x": "1.5rem",
        "components-button-spacing-large-default-space-between": "0.25rem",
        "components-button-spacing-large-icon-left-padding-left": "1rem",
        "components-button-spacing-large-icon-right-padding-right": "1rem",
        "components-button-spacing-large-icon-only-padding-x": "0.75rem",
        "components-button-spacing-large-icon-only-padding-y": "0.75rem",
        "components-button-spacing-large-tertiary-content-height": "0.25rem",
        "components-button-spacing-compressed-icon-large-x": "2.5rem",
        "components-button-spacing-compressed-icon-large-y": "2.5rem",
        "components-button-spacing-compressed-default-padding-y": "0.5rem",
        "components-button-spacing-compressed-default-padding-x": "1rem",
        "components-button-spacing-compressed-default-space-between": "0.125rem",
        "components-button-spacing-compressed-icon-left-padding-left": "0.5rem",
        "components-button-spacing-compressed-icon-right-padding-right": "0.5rem",
        "components-button-spacing-compressed-icon-only-padding-x": "0.5rem",
        "components-button-group-spacing-padding-y": "0.125rem",
        "components-button-group-spacing-padding-x": "0.25rem",
        "components-button-group-spacing-space-between": "0.5rem",
        "components-category-card-spacing-large-padding-top": "0rem",
        "components-category-card-spacing-large-padding-x": "0rem",
        "components-category-card-spacing-large-padding-y": "0rem",
        "components-category-card-spacing-large-padding-image-x": "0rem",
        "components-category-card-spacing-large-padding-image-y": "0rem",
        "components-category-card-spacing-large-padding-content-x": "2rem",
        "components-category-card-spacing-large-padding-content-y": "2rem",
        "components-category-card-listing-spacing-large-padding-y": "5rem",
        "components-category-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-category-card-listing-spacing-large-card-margin-bottom": "0rem",
        "components-category-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-card-search-result-small-padding-top": "0rem",
        "components-card-search-result-small-padding-x": "0rem",
        "components-card-search-result-small-padding-y": "0rem",
        "components-card-search-result-small-padding-image-x": "0rem",
        "components-card-search-result-small-padding-image-y": "0rem",
        "components-card-search-result-small-padding-content-x": "1.5rem",
        "components-card-search-result-small-padding-content-y": "1.5rem",
        "components-card-search-result-spacing-large-padding-x": "0rem",
        "components-card-search-result-spacing-large-padding-y": "1.5rem",
        "components-card-search-result-spacing-large-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-image-margin-right": "1.5rem",
        "components-card-search-result-spacing-small-padding-x": "1.5rem",
        "components-card-search-result-spacing-small-padding-y": "1.5rem",
        "components-card-search-result-spacing-small-eyebrow-margin-bottom": "1rem",
        "components-card-search-result-spacing-small-title-margin-bottom": "2rem",
        "components-card-search-result-spacing-small-image-margin-right": "0.75rem",
        "components-contact-us-promo-spacing-large-padding-x": "5rem",
        "components-contact-us-promo-spacing-large-padding-y": "5rem",
        "components-contact-us-promo-spacing-large-content-margin-right": "4rem",
        "components-contact-us-promo-spacing-large-margin-y": "2rem",
        "components-contact-us-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-contact-us-promo-spacing-small-margin-y": "1.5rem",
        "components-content-list-list-item-container-padding-x": "0.75rem",
        "components-content-list-list-item-container-padding-y": "1rem",
        "components-content-list-list-item-content-space-between": "0.5rem",
        "components-content-list-list-item-content-default-padding-y": "0.25rem",
        "components-content-list-list-item-content-hyperlink-padding-y": "0rem",
        "components-content-list-spacing-large-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-large-item-title-margin-bottom": "1rem",
        "components-content-list-spacing-large-item-copy-margin-bottom": "2.5rem",
        "components-content-list-spacing-large-item-icon-copy-margin-top": "0rem",
        "components-content-list-spacing-large-item-margin-bottom": "2rem",
        "components-content-list-spacing-large-item-image-margin-bottom": "0.5rem",
        "components-content-list-spacing-large-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-y": "2.5rem",
        "components-content-list-spacing-small-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-small-item-title-margin-bottom": "0.5rem",
        "components-content-list-spacing-small-item-copy-margin-bottom": "1rem",
        "components-content-list-spacing-small-item-margin-bottom": "1.5rem",
        "components-content-list-spacing-small-header-title-margin-bottom": "1rem",
        "components-content-list-spacing-small-header-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-title-margin-bottom": "2.5rem",
        "components-cookies-spacing-small-button-space-between": "0.75rem",
        "components-dropdown-item-padding-y": "0.75rem",
        "components-dropdown-item-padding-x": "0.75rem",
        "components-dropdown-item-spacing-x": "0.75rem",
        "components-dropdown-input-padding-y": "0.25rem",
        "components-dropdown-input-padding-x": "1rem",
        "components-footer-brand-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-large-column-margin-right": "4rem",
        "components-footer-brand-spacing-large-column-margin-bottom": "4rem",
        "components-footer-brand-spacing-large-link-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-padding-y": "2.5rem",
        "components-footer-brand-spacing-small-padding-x": "1.5rem",
        "components-footer-brand-spacing-small-logo-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-column-margin-right": "0.125rem",
        "components-footer-brand-spacing-small-column-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-link-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-title-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-text-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-footer-link-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-link-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-link-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-link-minheight": "3rem",
        "components-footer-brand-spacing-small-footer-category-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-category-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-category-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-category-minheight": "3rem",
        "components-footer-global-spacing-large-padding-y": "2.5rem",
        "components-footer-global-spacing-large-padding-x": "5rem",
        "components-footer-global-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-global-spacing-large-column-margin-right": "2rem",
        "components-footer-global-spacing-large-link-margin-bottom": "2rem",
        "components-footer-global-spacing-small-padding-y": "2.5rem",
        "components-footer-global-spacing-small-padding-x": "1.5rem",
        "components-footer-global-spacing-small-logo-margin-bottom": "1rem",
        "components-footer-global-spacing-small-column-margin-right": "1rem",
        "components-footer-global-spacing-small-link-margin-bottom": "1rem",
        "components-generic-card-spacing-large-padding-x": "0rem",
        "components-generic-card-spacing-large-padding-y": "0rem",
        "components-generic-card-spacing-large-padding-image-x": "0rem",
        "components-generic-card-spacing-large-padding-image-y": "0rem",
        "components-generic-card-spacing-large-padding-content-x": "2rem",
        "components-generic-card-spacing-large-padding-content-y": "2rem",
        "components-generic-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-generic-card-spacing-large-title-margin-bottom": "1rem",
        "components-generic-card-spacing-large-copy-margin-bottom": "2rem",
        "components-generic-card-spacing-large-button-space-between": "1.5rem",
        "components-generic-card-spacing-small-padding-x": "0rem",
        "components-generic-card-spacing-small-padding-y": "0rem",
        "components-generic-card-spacing-small-padding-image-x": "0rem",
        "components-generic-card-spacing-small-padding-image-y": "0rem",
        "components-generic-card-spacing-small-padding-content-x": "1.5rem",
        "components-generic-card-spacing-small-padding-content-y": "1.5rem",
        "components-global-search-spacing-omnibox-padding-x": "0.125rem",
        "components-global-search-spacing-omnibox-list-padding-y": "0.5rem",
        "components-global-search-spacing-omnibox-label-padding": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-item-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-height": "3rem",
        "components-generic-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-generic-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-header-shadow-x": "0rem",
        "components-header-spacing-large-padding-y": "1rem",
        "components-header-spacing-large-padding-x": "1.5rem",
        "components-header-spacing-large-links-margin-right": "1.5rem",
        "components-header-spacing-large-icons-space-between": "1.5rem",
        "components-header-spacing-large-logo-margin-right": "2rem",
        "components-header-spacing-large-space-between-search": "0.5rem",
        "components-header-spacing-large-height": "5rem",
        "components-header-spacing-large-language-selector-padding-x": "1rem",
        "components-header-spacing-large-language-selector-padding-y": "0.5rem",
        "components-header-spacing-large-language-selector-space-between": "0.75rem",
        "components-header-spacing-large-language-selector-country-space-between": "0.5rem",
        "components-header-spacing-large-mega-menu-padding-y": "2.5rem",
        "components-header-spacing-large-mega-menu-padding-x": "2.5rem",
        "components-header-spacing-large-mega-menu-column-margin-right": "5rem",
        "components-header-spacing-large-mega-menu-category-link-margin-bottom": "1rem",
        "components-header-spacing-large-mega-menu-link-margin-bottom": "0.5rem",
        "components-header-spacing-large-mega-menu-featured-padding-x": "2rem",
        "components-header-spacing-large-mega-menu-featured-padding-y": "2rem",
        "components-header-spacing-large-mega-menu-featured-image-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-title-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-link-space-between": "1.5rem",
        "components-header-spacing-large-mega-menu-title-margin-bottom": "2.5rem",
        "components-header-spacing-large-dropdown-padding-y": "2rem",
        "components-header-spacing-large-dropdown-padding-x": "2rem",
        "components-header-spacing-large-dropdown-margin-bottom": "0.5rem",
        "components-header-spacing-small-language-selector-padding-x": "1rem",
        "components-header-spacing-small-language-selector-padding-y": "0.5rem",
        "components-header-spacing-small-language-selector-space-between": "0.75rem",
        "components-header-spacing-small-padding-left": "1rem",
        "components-header-spacing-small-padding-right": "1rem",
        "components-header-spacing-small-padding-top": "0.5rem",
        "components-header-spacing-small-padding-bottom": "0.5rem",
        "components-header-spacing-small-nav-main-minheight": "3rem",
        "components-header-spacing-small-nav-main-padding-y": "0.5rem",
        "components-header-spacing-small-nav-main-padding-left": "1.5rem",
        "components-header-spacing-small-nav-main-padding-right": "0.75rem",
        "components-header-spacing-small-nav-category-minheight": "3rem",
        "components-header-spacing-small-nav-category-padding-left": "1.5rem",
        "components-header-spacing-small-nav-category-padding-right": "0.75rem",
        "components-header-spacing-small-nav-category-padding-y": "0.5rem",
        "components-header-spacing-small-nav-category-space-between": "0rem",
        "components-header-spacing-small-nav-menu-link-minheight": "3rem",
        "components-header-spacing-small-nav-menu-link-padding-right": "1.5rem",
        "components-header-spacing-small-nav-menu-link-padding-left": "2.5rem",
        "components-header-spacing-small-nav-menu-link-padding-y": "0.5rem",
        "components-header-spacing-small-mega-menu-padding-y": "0rem",
        "components-header-spacing-small-mega-menu-padding-x": "0rem",
        "components-header-spacing-small-mega-menu-category-link-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-link-margin-bottom": "0rem",
        "components-header-spacing-small-mega-menu-featured-padding-x": "1rem",
        "components-header-spacing-small-mega-menu-featured-padding-y": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-image-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-featured-title-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-featured-link-space-between": "0.75rem",
        "components-header-spacing-small-mega-menu-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-container-padding": "0rem",
        "components-hero-homepage-spacing-large-padding-x": "5rem",
        "components-hero-homepage-spacing-large-padding-y": "2.5rem",
        "components-hero-homepage-spacing-large-minheight": "34.3125rem",
        "components-hero-homepage-spacing-large-content-title-margin-bottom": "2.5rem",
        "components-hero-homepage-spacing-large-content-margin-bottom": "2.5rem",
        "components-hero-homepage-spacing-large-content-subtitle-margin-bottom": "2rem",
        "components-hero-homepage-spacing-large-content-copy-margin-bottom": "2rem",
        "components-hero-homepage-spacing-large-cta-margin-right": "1rem",
        "components-hero-homepage-spacing-large-image-height": "34.3125rem",
        "components-hero-homepage-spacing-large-image-width": "45.75rem",
        "components-hero-homepage-spacing-small-padding-x": "0.5rem",
        "components-hero-homepage-spacing-small-padding-y": "0.75rem",
        "components-hero-homepage-spacing-small-minheight": "34.3125rem",
        "components-hero-homepage-spacing-small-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-copy-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-image-height": "0rem",
        "components-hero-homepage-spacing-small-image-width": "45.75rem",
        "components-hero-internal-spacing-large-padding-y": "2.5rem",
        "components-hero-internal-spacing-large-padding-right": "5rem",
        "components-hero-internal-spacing-large-title-margin-bottom": "1.5rem",
        "components-hero-internal-spacing-large-button-padding-y": "2rem",
        "components-hero-internal-spacing-large-text-maxwidth": "90rem",
        "components-hero-internal-spacing-large-minheight": "18.5rem",
        "components-hero-internal-spacing-small-padding-x": "1.5rem",
        "components-hero-internal-spacing-small-padding-right": "0rem",
        "components-hero-internal-spacing-small-button-padding-y": "1.5rem",
        "components-hero-internal-spacing-small-text-maxwidth": "90rem",
        "components-hero-internal-spacing-small-minheight": "18.5rem",
        "components-icon-card-spacing-large-padding-x": "0rem",
        "components-icon-card-spacing-large-padding-y": "0rem",
        "components-icon-card-spacing-large-icon-margin": "1.5rem",
        "components-icon-card-spacing-large-margin-right": "1.5rem",
        "components-icon-card-spacing-large-title-margin-bottom": "1rem",
        "components-icon-card-spacing-large-copy-margin-bottom": "2rem",
        "components-icon-card-spacing-large-button-space-between": "1.5rem",
        "components-icon-card-spacing-large-top-space-between": "1rem",
        "components-icon-card-spacing-small-padding-x": "1.5rem",
        "components-icon-card-spacing-small-padding-y": "1.5rem",
        "components-icon-card-spacing-small-icon-margin": "1rem",
        "components-icon-card-spacing-small-margin-right": "1rem",
        "components-icon-card-spacing-small-title-margin-bottom": "1.5rem",
        "components-icon-card-dimensions-large-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-large-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-large-big-icon-height": "4rem",
        "components-icon-card-dimensions-large-big-icon-width": "4rem",
        "components-icon-card-dimensions-large-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-large-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-large-tiny-icon-height": "3rem",
        "components-icon-card-dimensions-large-tiny-icon-width": "3rem",
        "components-icon-card-dimensions-small-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-small-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-small-big-icon-height": "4rem",
        "components-icon-card-dimensions-small-big-icon-width": "4rem",
        "components-icon-card-dimensions-small-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-small-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-small-tiny-icon-height": "3rem",
        "components-icon-card-dimensions-small-tiny-icon-width": "3rem",
        "components-icon-card-listing-spacing-large-padding-inner-x": "5rem",
        "components-icon-card-listing-spacing-large-padding-inner-y": "5rem",
        "components-icon-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-icon-card-listing-spacing-large-title-margin-bottom": "1.5rem",
        "components-icon-card-listing-spacing-large-card-margin-bottom": "4rem",
        "components-icon-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-x": "1.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-y": "1.5rem",
        "components-icon-card-listing-spacing-small-title-margin-bottom": "1rem",
        "components-icon-card-listing-spacing-small-button-margin-top": "1.5rem",
        "components-jumplink-link-padding-bottom": "0.5rem",
        "components-jumplink-spacing-small-title-area-margin-bottom": "1.5rem",
        "components-jumplink-spacing-small-link-container-padding-x": "0rem",
        "components-lightbox-spacing-large-carousel-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-carousel-space-between": "1.5rem",
        "components-lightbox-spacing-large-featured-image-height": "39.25rem",
        "components-lightbox-spacing-large-featured-image-width": "39.25rem",
        "components-lightbox-spacing-large-image-height": "17.0625rem",
        "components-lightbox-spacing-large-image-width": "17.0625rem",
        "components-lightbox-spacing-small-carousel-margin-bottom": "1rem",
        "components-lightbox-spacing-small-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-small-media-details-margin-top": "1rem",
        "components-lightbox-spacing-small-image-height": "20.4375rem",
        "components-lightbox-spacing-small-image-width": "20.4375rem",
        "components-legal-disclaimer-spacing-small-ruleline-width": "18.4375rem",
        "components-media-spacing-large-padding-y": "0.5rem",
        "components-media-spacing-large-padding-x": "0.5rem",
        "components-media-gallery-large-container-grid-gap-x": "1.5rem",
        "components-media-gallery-large-container-grid-gap-y": "1.5rem",
        "components-media-gallery-small-container-grid-gap-x": "0.5rem",
        "components-media-gallery-small-container-grid-gap-y": "0.5rem",
        "components-media-gallery-spacing-small-padding-x": "1.5rem",
        "components-media-gallery-spacing-small-padding-y": "2.5rem",
        "components-media-gallery-spacing-large-padding-x": "5rem",
        "components-media-gallery-spacing-large-padding-y": "5rem",
        "components-product-card-spacing-list-content-gap": "0rem",
        "components-product-card-spacing-large-padding-image-x": "0rem",
        "components-product-card-spacing-large-padding-image-y": "0rem",
        "components-product-card-spacing-large-padding-content-x": "1.5rem",
        "components-product-card-spacing-large-padding-content-y": "1.5rem",
        "components-product-card-spacing-large-category-margin-bottom": "2rem",
        "components-product-card-spacing-large-title-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-copy-margin-bottom": "2rem",
        "components-product-card-spacing-large-button-space-between": "1.5rem",
        "components-product-card-spacing-small-padding-image-x": "0rem",
        "components-product-card-spacing-small-padding-image-y": "0rem",
        "components-product-card-spacing-small-padding-content-x": "1.5rem",
        "components-product-card-spacing-small-padding-content-y": "1.5rem",
        "components-product-card-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-large-padding-x": "5rem",
        "components-product-information-spacing-large-padding-y": "2.5rem",
        "components-product-information-spacing-large-title-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-label-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-ratings-margin-bottom": "0.5rem",
        "components-product-information-spacing-large-details-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-space-between": "1rem",
        "components-product-information-spacing-large-carousel-space-between": "5rem",
        "components-product-information-spacing-large-button-margin-y": "0.5rem",
        "components-product-information-spacing-large-body-margin-top": "2rem",
        "components-product-information-spacing-large-image-space-between": "0.5rem",
        "components-product-information-spacing-large-image-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-padding-x": "1.5rem",
        "components-product-information-spacing-small-padding-y": "2.5rem",
        "components-product-information-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-small-label-margin-bottom": "1.5rem",
        "components-product-information-spacing-small-ratings-margin-bottom": "1rem",
        "components-product-information-spacing-small-details-margin-bottom": "1rem",
        "components-product-information-spacing-small-space-between": "0.75rem",
        "components-product-information-spacing-small-carousel-space-between": "2.5rem",
        "components-product-information-spacing-small-button-margin-y": "1rem",
        "components-product-information-spacing-small-body-margin-top": "1.5rem",
        "components-product-information-spacing-small-image-space-between": "0.75rem",
        "components-product-information-spacing-small-image-margin-bottom": "1rem",
        "components-product-results-list-with-search-spacing-large-padding-x": "5rem",
        "components-product-results-list-with-search-spacing-large-padding-y": "5rem",
        "components-product-results-list-with-search-spacing-large-ruleline-margin-top": "2.5rem",
        "components-product-results-list-with-search-spacing-large-filter-space-between": "1rem",
        "components-product-results-list-with-search-spacing-large-card-space-between": "1.5rem",
        "components-product-results-list-with-search-spacing-large-card-container-margin-bottom": "5rem",
        "components-product-results-list-with-search-spacing-small-padding-x": "1.5rem",
        "components-product-results-list-with-search-spacing-small-padding-y": "2.5rem",
        "components-product-results-list-with-search-spacing-small-ruleline-margin-top": "1rem",
        "components-product-results-list-with-search-spacing-small-filter-padding-x": "0.5rem",
        "components-product-results-list-with-search-spacing-small-card-space-between": "1.5rem",
        "components-product-results-list-with-search-spacing-small-card-margin-bottom": "1.5rem",
        "components-promo-spacing-large-padding-x": "0rem",
        "components-promo-spacing-large-padding-y": "0rem",
        "components-promo-spacing-large-content-space-between": "0rem",
        "components-promo-spacing-large-content-space-between-y": "0rem",
        "components-promo-spacing-large-padding-content-x": "5rem",
        "components-promo-spacing-large-padding-content-condensed-x": "5rem",
        "components-promo-spacing-large-padding-content-y": "5rem",
        "components-promo-spacing-large-padding-content-condensed-y": "5rem",
        "components-promo-spacing-large-padding-image-x": "0rem",
        "components-promo-spacing-large-padding-image-y": "0rem",
        "components-promo-spacing-large-padding-image-top": "5rem",
        "components-promo-spacing-large-title-margin-bottom": "2rem",
        "components-promo-spacing-large-margin-y": "2rem",
        "components-promo-spacing-large-buttons-space-between": "1.5rem",
        "components-promo-spacing-small-padding-x": "0rem",
        "components-promo-spacing-small-padding-y": "0rem",
        "components-promo-spacing-small-padding-top": "0rem",
        "components-promo-spacing-small-padding-content-x": "2.5rem",
        "components-promo-spacing-small-padding-content-y": "2.5rem",
        "components-promo-spacing-small-padding-image-x": "0rem",
        "components-promo-spacing-small-padding-image-y": "0rem",
        "components-promo-spacing-small-padding-image-top": "0rem",
        "components-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-promo-spacing-small-margin-y": "1.5rem",
        "components-promo-minheight": "30rem",
        "components-pull-quote-spacing-large-padding-quote-x": "5rem",
        "components-pull-quote-spacing-large-padding-quote-y": "5rem",
        "components-pull-quote-spacing-large-title-margin-bottom": "2rem",
        "components-pull-quote-spacing-large-quote-margin-bottom": "4rem",
        "components-pull-quote-spacing-large-quote-space-between": "1.5rem",
        "components-pull-quote-spacing-large-detail-space-between": "0.5rem",
        "components-pull-quote-spacing-large-quote-minheight": "25rem",
        "components-pull-quote-spacing-small-padding-quote-x": "1.5rem",
        "components-pull-quote-spacing-small-padding-quote-y": "1.5rem",
        "components-pull-quote-spacing-small-title-margin-bottom": "1.5rem",
        "components-pull-quote-spacing-small-quote-margin-bottom": "1.5rem",
        "components-pull-quote-spacing-small-quote-space-between": "1rem",
        "components-pull-quote-spacing-small-detail-space-between": "1rem",
        "components-pull-quote-spacing-small-quote-minheight": "15rem",
        "components-promo-alternate-minheight": "30rem",
        "components-promo-alternate-spacing-large-padding-x": "0rem",
        "components-promo-alternate-spacing-large-padding-y": "0rem",
        "components-promo-alternate-spacing-large-content-space-between": "0rem",
        "components-promo-alternate-spacing-large-padding-content-x": "5rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-x": "2.5rem",
        "components-promo-alternate-spacing-large-padding-content-y": "5rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-y": "2.5rem",
        "components-promo-alternate-spacing-large-padding-image-x": "0rem",
        "components-promo-alternate-spacing-large-padding-image-y": "0rem",
        "components-promo-alternate-spacing-large-padding-image-top": "5rem",
        "components-promo-alternate-spacing-large-title-margin-bottom": "2rem",
        "components-promo-alternate-spacing-small-padding-x": "0rem",
        "components-promo-alternate-spacing-small-padding-y": "0rem",
        "components-promo-alternate-spacing-small-padding-content-x": "2.5rem",
        "components-promo-alternate-spacing-small-padding-content-y": "2.5rem",
        "components-promo-alternate-spacing-small-padding-image-x": "0rem",
        "components-promo-alternate-spacing-small-padding-image-y": "0rem",
        "components-promo-alternate-spacing-small-padding-image-top": "1.5rem",
        "components-promo-alternate-spacing-small-title-margin-bottom": "1.5rem",
        "components-share-list-spacing-padding-x": "0.5rem",
        "components-share-list-spacing-padding-y": "0.5rem",
        "components-header-copy-margin-bottom": "1rem",
        "components-header-margin-bottom": "1.5rem",
        "components-item-margin-bottom": "1.5rem",
        "components-site-search-padding-x": "2.5rem",
        "components-site-search-padding-y": "5rem",
        "components-site-search-facet-label-space-between": "0rem",
        "components-site-search-refine-padding-x": "1.5rem",
        "components-site-search-refine-padding-y": "1.5rem",
        "components-site-search-hero-search-height": "18.5rem",
        "components-site-search-hero-search-large-padding-x": "5rem",
        "components-site-search-hero-search-large-padding-y": "5rem",
        "components-stats-spacing-stat-text-space-between": "0.5rem",
        "components-stats-spacing-large-inner-padding-x": "5rem",
        "components-stats-spacing-large-inner-padding-y": "5rem",
        "components-stats-spacing-small-inner-padding-x": "1.5rem",
        "components-stats-spacing-small-inner-padding-y": "1.5rem",
        "components-icon-card-listing-spacing-small-top-space-between": "1rem",
        "components-supporting-text-padding-x": "0rem",
        "components-supporting-text-padding-bottom": "0.5rem",
        "components-tab-item-padding-y": "0.75rem",
        "components-tab-item-padding-x": "0.75rem",
        "components-tab-item-spacing-x": "0.75rem",
        "components-tab-input-padding-y": "0.25rem",
        "components-tab-input-padding-x": "1rem",
        "components-tag-spacing-padding-x": "0.5rem",
        "components-tag-spacing-padding-y": "0.5rem",
        "components-tag-spacing-space-between": "0.5rem",
        "components-text-field-input-padding-y": "0.25rem",
        "components-text-field-input-padding-x": "1rem",
        "components-text-field-area-input-padding-y": "1rem",
        "components-text-field-area-input-padding-x": "1rem",
        "components-text-field-supporting-text-padding-x": "0rem",
        "components-text-field-supporting-text-padding-top": "0.5rem",
        "themes-general-surface-spacing-large-padding-x": "5rem",
        "themes-general-surface-spacing-large-padding-y": "5rem",
        "themes-general-surface-spacing-large-title-margin-bottom": "2rem",
        "themes-general-surface-spacing-large-body-margin-right": "4rem",
        "themes-general-surface-spacing-large-body-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-large-margin-bottom": "5rem",
        "themes-general-surface-spacing-large-cards-space-between": "1.5rem",
        "themes-general-surface-spacing-large-button-margin-top": "2.5rem",
        "themes-general-surface-spacing-small-padding-x": "1.5rem",
        "themes-general-surface-spacing-small-padding-y": "2.5rem",
        "themes-general-surface-spacing-small-title-margin-bottom": "1.5rem",
        "themes-general-surface-spacing-small-body-margin-right": "2.5rem",
        "themes-general-surface-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-surface-spacing-small-margin-bottom": "1.5rem",
        "themes-general-surface-spacing-small-cards-space-between": "1rem",
        "themes-general-surface-spacing-small-button-margin-top": "1.5rem",
        "themes-general-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "themes-general-card-spacing-large-title-margin-bottom": "1rem",
        "themes-general-card-spacing-large-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-large-button-space-between": "1.5rem",
        "themes-general-card-spacing-small-eyebrow-margin-bottom": "1.5rem",
        "themes-general-card-spacing-small-title-margin-bottom": "1rem",
        "themes-general-card-spacing-small-body-margin-bottom": "2rem",
        "themes-general-card-spacing-small-button-space-between": "1.5rem",
        "themes-shadow-share-list-spacing-x": "0rem",
        "themes-shadow-share-list-spacing-y": "0rem",
        "themes-shadow-share-list-spacing-blur": "0rem",
        "themes-shadow-share-list-spacing-spread": "0rem",
        "themes-shadow-header-menu-spacing-x": "0rem",
        "themes-shadow-header-menu-spacing-y": "0.75rem",
        "themes-shadow-header-menu-spacing-blur": "1rem",
        "themes-shadow-header-menu-spacing-spread": "-0.5rem",
        "themes-typography-rte-large-h1-textsize": "3rem",
        "themes-typography-rte-large-h2-textsize": "2.5rem",
        "themes-typography-rte-large-h3-textsize": "2.25rem",
        "themes-typography-rte-large-h4-textsize": "2rem",
        "themes-typography-rte-large-h5-textsize": "1.5rem",
        "themes-typography-rte-large-h6-textsize": "1.25rem",
        "themes-typography-rte-medium-h1-textsize": "2.5rem",
        "themes-typography-rte-medium-h2-textsize": "2.25rem",
        "themes-typography-rte-medium-h3-textsize": "1.75rem",
        "themes-typography-rte-medium-h4-textsize": "1.5rem",
        "themes-typography-rte-medium-h5-textsize": "1.25rem",
        "themes-typography-rte-medium-h6-textsize": "1.125rem",
        "themes-typography-rte-small-h1-textsize": "2.5rem",
        "themes-typography-rte-small-h2-textsize": "2rem",
        "themes-typography-rte-small-h3-textsize": "1.75rem",
        "themes-typography-rte-small-h4-textsize": "1.5rem",
        "themes-typography-rte-small-h5-textsize": "1.125rem",
        "themes-typography-rte-small-h6-textsize": "1rem",
        "spacing-margin-small-1": "0.125rem",
        "spacing-margin-small-2": "0.25rem",
        "spacing-margin-small-3": "0.5rem",
        "spacing-margin-small-4": "0.75rem",
        "spacing-margin-small-5": "1rem",
        "spacing-margin-small-6": "1.5rem",
        "spacing-margin-small-7": "2.5rem",
        "spacing-margin-medium-2": "0.75rem",
        "spacing-margin-medium-4": "1.5rem",
        "spacing-margin-medium-5": "2rem",
        "spacing-margin-large-1": "0.5rem",
        "spacing-margin-large-2": "1rem",
        "spacing-margin-large-3": "1.5rem",
        "spacing-margin-large-4": "2rem",
        "spacing-margin-large-5": "2.5rem",
        "spacing-margin-large-6": "4rem",
        "spacing-margin-large-7": "5rem",
        "spacing-space-between-small-1": "0.125rem",
        "spacing-space-between-small-2": "0.25rem",
        "spacing-space-between-small-3": "0.5rem",
        "spacing-space-between-small-4": "0.75rem",
        "spacing-space-between-small-5": "1rem",
        "spacing-space-between-small-6": "1.5rem",
        "spacing-space-between-small-7": "2.5rem",
        "spacing-space-between-medium-1": "0.125rem",
        "spacing-space-between-medium-2": "0.25rem",
        "spacing-space-between-medium-3": "0.5rem",
        "spacing-space-between-medium-4": "0.75rem",
        "spacing-space-between-medium-5": "1rem",
        "spacing-space-between-large-1": "0.25rem",
        "spacing-space-between-large-2": "0.5rem",
        "spacing-space-between-large-3": "1rem",
        "spacing-space-between-large-4": "1.5rem",
        "spacing-space-between-large-5": "1.5rem",
        "spacing-space-between-large-6": "2.5rem",
        "spacing-space-between-large-7": "4rem",
        "spacing-padding-small-1": "0.125rem",
        "spacing-padding-small-2": "0.25rem",
        "spacing-padding-small-3": "0.5rem",
        "spacing-padding-small-4": "0.75rem",
        "spacing-padding-small-5": "1rem",
        "spacing-padding-small-6": "1.5rem",
        "spacing-padding-small-7": "2.5rem",
        "spacing-padding-medium-1": "0.25rem",
        "spacing-padding-medium-2": "0.75rem",
        "spacing-padding-medium-3": "1rem",
        "spacing-padding-medium-4": "1.5rem",
        "spacing-padding-medium-5": "2rem",
        "spacing-padding-medium-6": "3rem",
        "spacing-padding-medium-7": "4rem",
        "spacing-padding-large-1": "0.5rem",
        "spacing-padding-large-2": "1rem",
        "spacing-padding-large-3": "1.5rem",
        "spacing-padding-large-4": "2rem",
        "spacing-padding-large-5": "2.5rem",
        "spacing-padding-large-6": "4rem",
        "spacing-padding-large-7": "5rem"
      },
      "string": {
        "components-accordion-section-button": "var(--brand)",
        "components-article-card-listing-button-theme": "var(--brand)",
        "components-article-hero-pagination-buttons": "var(--brand)",
        "components-banner-banner-button": "var(--tonal)",
        "components-category-card-listing-button-theme": "var(--brand)",
        "components-contact-us-promo-hierarchy-button-theme-1": "var(--filled)",
        "components-contact-us-promo-hierarchy-button-theme-2": "var(--outlined)",
        "components-contact-us-promo-color-button-theme": "var(--brand)",
        "components-contact-us-promo-color-brand-button-theme": "var(--white)",
        "components-generic-card-button-theme": "var(--brand)",
        "components-global-search-loader": "var(--default)",
        "components-generic-card-listing-decor-top": "var(--default)",
        "components-generic-card-listing-decor-bottom": "var(--default)",
        "components-hero-homepage-pagination-buttons": "var(--white)",
        "components-hero-homepage-pattern": "var(--default)",
        "components-hero-homepage-button-1": "var(--white)",
        "components-hero-homepage-button-2": "var(--white)",
        "components-hero-homepage-image-ratio": "var(--4:3)",
        "components-hero-internal-theme-button-one-type": "var(--outlined)",
        "components-hero-internal-theme-button-two-type": "var(--text)",
        "components-hero-internal-theme-button-color": "var(--white)",
        "components-hero-internal-theme-button-color-with-image": "var(--white)",
        "components-lightbox-button-type": "var(--white)",
        "components-pdf-link-card-hierarchy-button-theme-1": "var(--filled)",
        "components-pdf-link-card-hierarchy-button-theme-2": "var(--outlined)",
        "components-pdf-link-card-color-button-theme": "var(--brand)",
        "components-product-card-badge-align": "var(--center)",
        "components-product-card-listing-button-color": "var(--brand)",
        "components-promo-theme-button-one-theme": "var(--outlined)",
        "components-promo-theme-button-two-theme": "var(--text)",
        "components-promo-color-default-button-theme": "var(--brand)",
        "components-promo-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-color-brand-button-theme": "var(--white)",
        "components-pull-quote-border-decor": "var(--default)",
        "components-promo-alternate-theme-button-one-theme": "var(--outlined)",
        "components-promo-alternate-theme-button-two-theme": "var(--text)",
        "components-promo-alternate-color-default-button-theme": "var(--brand)",
        "components-promo-alternate-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-alternate-color-brand-button-theme": "var(--white)",
        "components-share-list-button-color": "var(--brand)",
        "components-site-search-hero-search-hero-pattern": "var(--default)",
        "components-stats-decor-top": "var(--default)",
        "components-stats-decor-bottom": "var(--default)",
        "themes-brand-loader": "var(--default)",
        "themes-brand-variants": "var(--default)",
        "themes-tab-variant": "var(--default)",
        "themes-label-variants": "var(--default)",
        "themes-logo": "var(--default)",
        "themes-category-card": "var(--default)",
        "themes-title-decor": "var(--default)",
        "themes-typography-heading-fontfamily": "var(--museo-sans)",
        "themes-typography-heading-large-xxlarge-fontweight": "var(--900)",
        "themes-typography-heading-large-xlarge-fontweight": "var(--900)",
        "themes-typography-heading-large-large-fontweight": "var(--900)",
        "themes-typography-heading-large-medium-fontweight": "var(--700)",
        "themes-typography-heading-large-small-fontweight": "var(--700)",
        "themes-typography-heading-large-xsmall-fontweight": "var(--700)",
        "themes-typography-heading-large-xxsmall-fontweight": "var(--700)",
        "themes-typography-heading-medium-xxlarge-fontweight": "var(--900)",
        "themes-typography-heading-medium-xlarge-fontweight": "var(--900)",
        "themes-typography-heading-medium-large-fontweight": "var(--900)",
        "themes-typography-heading-medium-medium-fontweight": "var(--700)",
        "themes-typography-heading-medium-small-fontweight": "var(--700)",
        "themes-typography-heading-medium-xsmall-fontweight": "var(--700)",
        "themes-typography-heading-medium-xxsmall-fontweight": "var(--400)",
        "themes-typography-heading-small-xxlarge-fontweight": "var(--900)",
        "themes-typography-heading-small-xlarge-fontweight": "var(--900)",
        "themes-typography-heading-small-large-fontweight": "var(--900)",
        "themes-typography-heading-small-medium-fontweight": "var(--700)",
        "themes-typography-heading-small-small-fontweight": "var(--700)",
        "themes-typography-heading-small-xsmall-fontweight": "var(--700)",
        "themes-typography-heading-small-xxsmall-fontweight": "var(--700)",
        "themes-typography-rte-fontfamily": "var(--museo-sans)",
        "themes-typography-rte-fontweight": "var(--900)"
      },
      "borderRadius": {
        "components-accordion-spacing-large-item-item-radius": "0.5rem",
        "components-article-card-spacing-large-image-radius-tl": "0rem",
        "components-article-card-spacing-large-image-radius-tr": "0rem",
        "components-article-card-spacing-large-image-radius-bl": "0rem",
        "components-article-card-spacing-large-image-radius-br": "0rem",
        "components-article-card-spacing-large-list-image-radius-br": "0rem",
        "components-article-card-spacing-small-image-radius-br": "0rem",
        "components-article-card-spacing-small-list-image-radius-br": "0rem",
        "components-article-hero-spacing-large-content-radius": "0rem",
        "components-article-hero-spacing-small-content-radius": "0rem",
        "components-button-radius-large": "0rem",
        "components-button-group-spacing-border-radius": "0rem",
        "components-category-card-radius-border-width": "0.0625rem",
        "components-category-card-radius-border-radius": "0rem",
        "components-category-card-radius-border-radius-condensed": "0rem",
        "components-category-card-radius-media-radius": "0rem",
        "components-content-list-icon-radius": "0rem",
        "components-fab-spacing-border-radius": "0rem",
        "components-header-spacing-large-search-border-radius-top": "0rem",
        "components-header-spacing-large-search-border-radius-bottom": "0rem",
        "components-header-spacing-large-language-selector-radius": "0.25rem",
        "components-header-spacing-large-dropdown-radius": "0rem",
        "components-hero-homepage-spacing-large-container-radius": "0rem",
        "components-hero-internal-spacing-large-icon-radius": "0rem",
        "components-hero-internal-spacing-small-icon-radius": "0rem",
        "components-lightbox-spacing-large-overlay-radius": "0rem",
        "components-pagination-scrollbar-radius": "62.5rem",
        "components-product-card-spacing-image-radius-tl": "0rem",
        "components-product-card-spacing-image-radius-tr": "0rem",
        "components-product-card-spacing-image-radius-bl": "0rem",
        "components-product-card-spacing-image-radius-br": "0rem",
        "components-product-information-spacing-large-content-radius": "0rem",
        "components-product-information-spacing-small-content-radius": "0rem",
        "components-promo-spacing-large-content-radius": "0rem",
        "components-promo-spacing-large-content-radius-tl": "0rem",
        "components-promo-spacing-large-content-radius-tr": "0rem",
        "components-promo-spacing-large-content-radius-bl": "0rem",
        "components-promo-spacing-large-content-radius-br": "0rem",
        "components-promo-spacing-large-image-radius-tl": "0rem",
        "components-promo-spacing-large-image-radius-tr": "0rem",
        "components-promo-spacing-large-image-radius-bl": "0rem",
        "components-promo-spacing-large-image-radius-br": "0rem",
        "components-promo-spacing-small-content-radius": "0rem",
        "components-promo-spacing-small-image-radius-tl": "0rem",
        "components-promo-spacing-small-image-radius-tr": "0rem",
        "components-promo-spacing-small-image-radius-bl": "0rem",
        "components-promo-spacing-small-image-radius-br": "0rem",
        "components-promo-alternate-spacing-large-content-radius": "0rem",
        "components-promo-alternate-spacing-large-image-radius-tl": "0rem",
        "components-promo-alternate-spacing-large-image-radius-tr": "0rem",
        "components-promo-alternate-spacing-large-image-radius-bl": "0rem",
        "components-promo-alternate-spacing-large-image-radius-br": "0rem",
        "components-promo-alternate-spacing-small-content-radius": "0rem",
        "components-promo-alternate-spacing-small-image-radius-tl": "0.75rem",
        "components-promo-alternate-spacing-small-image-radius-tr": "0.75rem",
        "components-promo-alternate-spacing-small-image-radius-bl": "0.75rem",
        "components-promo-alternate-spacing-small-image-radius-br": "0.75rem",
        "components-share-list-spacing-radius": "0rem",
        "components-share-list-spacing-link-radius": "0rem",
        "components-site-search-filter-radius": "0rem",
        "components-site-search-facet-radius": "0rem",
        "components-site-search-refine-radius": "0rem",
        "components-tag-spacing-radius": "0rem",
        "components-tip-spacing-radius": "0rem",
        "themes-spacing-radius-pagination-bar": "62.5rem",
        "themes-spacing-radius-large-button": "0rem",
        "themes-spacing-radius-large-button-icon": "0rem",
        "themes-spacing-radius-large-image": "0rem",
        "themes-spacing-radius-large-image-hero": "0rem",
        "themes-spacing-radius-large-image-card": "0rem",
        "themes-spacing-radius-large-card": "0rem",
        "themes-spacing-radius-large-icon": "0rem",
        "themes-spacing-radius-large-label": "0rem",
        "themes-spacing-radius-large-tag": "0rem",
        "themes-spacing-radius-large-container-tip": "0rem",
        "themes-spacing-radius-large-badge": "0rem",
        "themes-spacing-radius-large-item": "0rem",
        "themes-spacing-radius-large-form-field-input": "0rem",
        "themes-spacing-radius-large-form-area-input": "0rem",
        "themes-spacing-radius-large-general": "0rem",
        "themes-spacing-radius-small-button": "0rem",
        "themes-spacing-radius-small-button-icon": "0rem",
        "themes-spacing-radius-small-image": "0rem",
        "themes-spacing-radius-small-image-card": "0rem",
        "themes-spacing-radius-small-card": "0rem",
        "themes-spacing-radius-small-icon": "0rem",
        "themes-spacing-radius-small-label": "0rem",
        "themes-spacing-radius-small-tag": "0rem",
        "themes-spacing-radius-small-badge": "0rem",
        "themes-spacing-radius-small-item": "0.25rem",
        "themes-spacing-radius-small-form-field-input": "0rem",
        "themes-spacing-radius-small-general": "0rem"
      },
      "borderWidth": {
        "3": "3px",
        "components-article-card-spacing-large-border-width": "0.0625rem",
        "components-generic-card-color-border-width": "0.0625rem",
        "components-header-spacing-large-search-border-width-top": "0.125rem",
        "components-header-spacing-large-search-border-width-right": "0.125rem",
        "components-header-spacing-large-search-border-width-bottom": "0.125rem",
        "components-header-spacing-large-search-border-width-left": "0.125rem",
        "components-product-card-spacing-border-width": "0.0625rem"
      },
      "maxHeight": {},
      "maxWidth": {
        "components-article-hero-spacing-large-header-max-width": "66.375rem",
        "components-content-list-spacing-large-item-image-max-width": "33.75rem",
        "components-content-list-spacing-small-item-image-max-width": "33.75rem"
      },
      "minHeight": {
        "components-dropdown-item-min-height": "3rem",
        "components-icon-min-height": "3rem",
        "components-tab-item-min-height": "3rem",
        "components-text-field-input-min-height": "3rem",
        "components-text-field-icon-min-height": "3rem"
      },
      "minWidth": {
        "components-content-list-spacing-small-item-image-min-width": "18.75rem",
        "components-icon-min-width": "3rem",
        "components-text-field-icon-min-width": "3rem"
      },
      "opacity": {
        "components-accessibility-bar-color-bg": 1,
        "components-accessibility-bar-color-text": 1,
        "components-accordion-color-bg": 1,
        "components-accordion-color-title": 1,
        "components-accordion-color-body": 1,
        "components-accordion-color-section-section-bg": 1,
        "components-accordion-color-section-section-title": 1,
        "components-accordion-color-section-section-body": 1,
        "components-accordion-color-item-item-bg": 1,
        "components-accordion-color-item-divider": 1,
        "components-accordion-color-item-icon-icon-default": 1,
        "components-accordion-color-item-icon-icon-hover": 1,
        "components-accordion-color-item-icon-icon-pressed": 1,
        "components-accordion-color-item-icon-icon-active": 1,
        "components-accordion-color-item-title-item-default": 1,
        "components-accordion-color-item-title-item-hover": 1,
        "components-accordion-color-item-title-item-pressed": 1,
        "components-accordion-color-item-title-item-active": 1,
        "components-accordion-color-content-bg": 1,
        "components-accordion-color-content-title": 1,
        "components-accordion-color-content-body": 1,
        "components-article-card-color-bg": 1,
        "components-article-card-color-border": 1,
        "components-article-card-color-content-bg": 1,
        "components-article-card-color-title": 1,
        "components-article-card-color-subtitle": 1,
        "components-article-card-color-copy": 1,
        "components-article-card-color-description": 1,
        "components-article-card-listing-color-default-bg": 1,
        "components-article-card-listing-color-default-title": 1,
        "components-article-card-listing-color-default-copy": 1,
        "components-article-hero-color-content-bg": 1,
        "components-article-hero-color-bg": 1,
        "components-article-hero-color-headline": 1,
        "components-article-hero-color-body": 1,
        "components-article-hero-color-detail-text": 1,
        "components-article-hero-color-divider": 1,
        "components-article-hero-color-icon": 1,
        "components-article-hero-color-play-icon": 1,
        "components-article-hero-color-selected-stroke": 1,
        "components-banner-color-bg-default": 1,
        "components-banner-color-icon-default": 1,
        "components-banner-color-title-default": 1,
        "components-banner-color-body-default": 1,
        "components-banner-color-icon-close-default": 1,
        "components-banner-color-bg-alert": 1,
        "components-banner-color-icon-alert": 1,
        "components-banner-color-title-alert": 1,
        "components-banner-color-body-alert": 1,
        "components-banner-color-icon-close-alert": 1,
        "components-breadcrumb-color-bg": 1,
        "components-breadcrumb-color-breadcrumb-link-default": 1,
        "components-breadcrumb-color-breadcrumb-link-hover": 1,
        "components-breadcrumb-color-breadcrumb-link-active": 1,
        "components-breadcrumb-color-icon-divider": 1,
        "components-bug-finder-prompt": 1,
        "components-button-color-filled-brand-default-bg": 1,
        "components-button-color-filled-brand-default-text": 1,
        "components-button-color-filled-brand-default-stroke": 1,
        "components-button-color-filled-brand-default-icon": 1,
        "components-button-color-filled-brand-hover-bg": 1,
        "components-button-color-filled-brand-hover-text": 1,
        "components-button-color-filled-brand-hover-stroke": 1,
        "components-button-color-filled-brand-hover-icon": 1,
        "components-button-color-filled-brand-pressed-bg": 1,
        "components-button-color-filled-brand-pressed-text": 1,
        "components-button-color-filled-brand-pressed-stroke": 1,
        "components-button-color-filled-brand-pressed-icon": 1,
        "components-button-color-filled-brand-disabled-bg": 1,
        "components-button-color-filled-brand-disabled-text": 1,
        "components-button-color-filled-brand-disabled-stroke": 1,
        "components-button-color-filled-brand-disabled-icon": 1,
        "components-button-color-filled-brand-focus-bg": 1,
        "components-button-color-filled-brand-focus-text": 1,
        "components-button-color-filled-brand-focus-stroke": 1,
        "components-button-color-filled-brand-focus-icon": 1,
        "components-button-color-filled-brand-focus-outline": 1,
        "components-button-color-filled-tonal-default-bg": 1,
        "components-button-color-filled-tonal-default-text": 1,
        "components-button-color-filled-tonal-default-stroke": 1,
        "components-button-color-filled-tonal-default-icon": 1,
        "components-button-color-filled-tonal-hover-bg": 1,
        "components-button-color-filled-tonal-hover-text": 1,
        "components-button-color-filled-tonal-hover-stroke": 1,
        "components-button-color-filled-tonal-hover-icon": 1,
        "components-button-color-filled-tonal-pressed-bg": 1,
        "components-button-color-filled-tonal-pressed-text": 1,
        "components-button-color-filled-tonal-pressed-stroke": 1,
        "components-button-color-filled-tonal-pressed-icon": 1,
        "components-button-color-filled-tonal-disabled-bg": 1,
        "components-button-color-filled-tonal-disabled-text": 1,
        "components-button-color-filled-tonal-disabled-stroke": 1,
        "components-button-color-filled-tonal-disabled-icon": 1,
        "components-button-color-filled-tonal-focus-bg": 1,
        "components-button-color-filled-tonal-focus-text": 1,
        "components-button-color-filled-tonal-focus-stroke": 1,
        "components-button-color-filled-tonal-focus-icon": 1,
        "components-button-color-filled-tonal-focus-outline": 1,
        "components-button-color-filled-white-default-bg": 1,
        "components-button-color-filled-white-default-text": 1,
        "components-button-color-filled-white-default-stroke": 1,
        "components-button-color-filled-white-default-icon": 1,
        "components-button-color-filled-white-hover-bg": 1,
        "components-button-color-filled-white-hover-text": 1,
        "components-button-color-filled-white-hover-stroke": 1,
        "components-button-color-filled-white-hover-icon": 1,
        "components-button-color-filled-white-pressed-bg": 1,
        "components-button-color-filled-white-pressed-text": 1,
        "components-button-color-filled-white-pressed-stroke": 1,
        "components-button-color-filled-white-pressed-icon": 1,
        "components-button-color-filled-white-disabled-bg": 1,
        "components-button-color-filled-white-disabled-text": 1,
        "components-button-color-filled-white-disabled-stroke": 1,
        "components-button-color-filled-white-disabled-icon": 1,
        "components-button-color-filled-white-focus-bg": 1,
        "components-button-color-filled-white-focus-text": 1,
        "components-button-color-filled-white-focus-stroke": 1,
        "components-button-color-filled-white-focus-icon": 1,
        "components-button-color-filled-white-focus-outline": 1,
        "components-button-color-outline-brand-default-bg": 0,
        "components-button-color-outline-brand-default-text": 1,
        "components-button-color-outline-brand-default-stroke": 1,
        "components-button-color-outline-brand-default-icon": 1,
        "components-button-color-outline-brand-hover-bg": 1,
        "components-button-color-outline-brand-hover-text": 1,
        "components-button-color-outline-brand-hover-stroke": 1,
        "components-button-color-outline-brand-hover-icon": 1,
        "components-button-color-outline-brand-pressed-bg": 1,
        "components-button-color-outline-brand-pressed-text": 1,
        "components-button-color-outline-brand-pressed-stroke": 1,
        "components-button-color-outline-brand-pressed-icon": 1,
        "components-button-color-outline-brand-disabled-bg": 0.02,
        "components-button-color-outline-brand-disabled-text": 1,
        "components-button-color-outline-brand-disabled-stroke": 1,
        "components-button-color-outline-brand-disabled-icon": 1,
        "components-button-color-outline-brand-focus-bg": 1,
        "components-button-color-outline-brand-focus-text": 1,
        "components-button-color-outline-brand-focus-stroke": 1,
        "components-button-color-outline-brand-focus-icon": 1,
        "components-button-color-outline-brand-focus-outline": 1,
        "components-button-color-outline-tonal-default-bg": 0,
        "components-button-color-outline-tonal-default-text": 1,
        "components-button-color-outline-tonal-default-stroke": 1,
        "components-button-color-outline-tonal-default-icon": 1,
        "components-button-color-outline-tonal-hover-bg": 1,
        "components-button-color-outline-tonal-hover-text": 1,
        "components-button-color-outline-tonal-hover-stroke": 1,
        "components-button-color-outline-tonal-hover-icon": 1,
        "components-button-color-outline-tonal-pressed-bg": 1,
        "components-button-color-outline-tonal-pressed-text": 1,
        "components-button-color-outline-tonal-pressed-stroke": 1,
        "components-button-color-outline-tonal-pressed-icon": 1,
        "components-button-color-outline-tonal-disabled-bg": 0.02,
        "components-button-color-outline-tonal-disabled-text": 1,
        "components-button-color-outline-tonal-disabled-stroke": 1,
        "components-button-color-outline-tonal-disabled-icon": 1,
        "components-button-color-outline-tonal-focus-bg": 1,
        "components-button-color-outline-tonal-focus-text": 1,
        "components-button-color-outline-tonal-focus-stroke": 1,
        "components-button-color-outline-tonal-focus-icon": 1,
        "components-button-color-outline-tonal-focus-outline": 1,
        "components-button-color-outline-white-default-bg": null,
        "components-button-color-outline-white-default-text": 1,
        "components-button-color-outline-white-default-stroke": 1,
        "components-button-color-outline-white-default-icon": 1,
        "components-button-color-outline-white-hover-bg": 1,
        "components-button-color-outline-white-hover-text": 1,
        "components-button-color-outline-white-hover-stroke": 1,
        "components-button-color-outline-white-hover-icon": 1,
        "components-button-color-outline-white-pressed-bg": 1,
        "components-button-color-outline-white-pressed-text": 1,
        "components-button-color-outline-white-pressed-stroke": 1,
        "components-button-color-outline-white-pressed-icon": 1,
        "components-button-color-outline-white-disabled-bg": 0.02,
        "components-button-color-outline-white-disabled-text": 1,
        "components-button-color-outline-white-disabled-stroke": 1,
        "components-button-color-outline-white-disabled-icon": 1,
        "components-button-color-outline-white-focus-bg": 1,
        "components-button-color-outline-white-focus-text": 1,
        "components-button-color-outline-white-focus-stroke": 1,
        "components-button-color-outline-white-focus-icon": 1,
        "components-button-color-outline-white-focus-outline": 1,
        "components-button-color-text-brand-default-bg": null,
        "components-button-color-text-brand-default-text": 1,
        "components-button-color-text-brand-default-stroke": 1,
        "components-button-color-text-brand-default-icon": 1,
        "components-button-color-text-brand-hover-bg": null,
        "components-button-color-text-brand-hover-bg-icon-only": 1,
        "components-button-color-text-brand-hover-text": 1,
        "components-button-color-text-brand-hover-stroke": 1,
        "components-button-color-text-brand-hover-icon": 1,
        "components-button-color-text-brand-pressed-bg": null,
        "components-button-color-text-brand-pressed-bg-icon-only": 1,
        "components-button-color-text-brand-pressed-text": 1,
        "components-button-color-text-brand-pressed-stroke": 1,
        "components-button-color-text-brand-pressed-icon": 1,
        "components-button-color-text-brand-disabled-bg": null,
        "components-button-color-text-brand-disabled-text": 1,
        "components-button-color-text-brand-disabled-stroke": 1,
        "components-button-color-text-brand-disabled-icon": 1,
        "components-button-color-text-brand-focus-bg": null,
        "components-button-color-text-brand-focus-text": 1,
        "components-button-color-text-brand-focus-stroke": 1,
        "components-button-color-text-brand-focus-icon": 1,
        "components-button-color-text-brand-focus-outline": 1,
        "components-button-color-text-tonal-default-bg": 0.01,
        "components-button-color-text-tonal-default-text": 1,
        "components-button-color-text-tonal-default-stroke": 1,
        "components-button-color-text-tonal-default-icon": 1,
        "components-button-color-text-tonal-hover-bg": 0.01,
        "components-button-color-text-tonal-hover-bg-icon-only": 1,
        "components-button-color-text-tonal-hover-text": 1,
        "components-button-color-text-tonal-hover-stroke": 1,
        "components-button-color-text-tonal-hover-icon": 1,
        "components-button-color-text-tonal-pressed-bg": 0.01,
        "components-button-color-text-tonal-pressed-bg-icon-only": 1,
        "components-button-color-text-tonal-pressed-text": 1,
        "components-button-color-text-tonal-pressed-stroke": 1,
        "components-button-color-text-tonal-pressed-icon": 1,
        "components-button-color-text-tonal-disabled-bg": 0.01,
        "components-button-color-text-tonal-disabled-text": 1,
        "components-button-color-text-tonal-disabled-stroke": 1,
        "components-button-color-text-tonal-disabled-icon": 1,
        "components-button-color-text-tonal-focus-bg": 0.01,
        "components-button-color-text-tonal-focus-text": 1,
        "components-button-color-text-tonal-focus-stroke": 1,
        "components-button-color-text-tonal-focus-icon": 1,
        "components-button-color-text-tonal-focus-outline": 1,
        "components-button-color-text-white-default-bg": 0.02,
        "components-button-color-text-white-default-text": 1,
        "components-button-color-text-white-default-stroke": 1,
        "components-button-color-text-white-default-icon": 1,
        "components-button-color-text-white-hover-bg": 0.02,
        "components-button-color-text-white-hover-bg-icon-only": 1,
        "components-button-color-text-white-hover-text": 1,
        "components-button-color-text-white-hover-stroke": 1,
        "components-button-color-text-white-hover-icon": 1,
        "components-button-color-text-white-pressed-bg": 0.02,
        "components-button-color-text-white-pressed-bg-icon-only": 1,
        "components-button-color-text-white-pressed-text": 1,
        "components-button-color-text-white-pressed-stroke": 1,
        "components-button-color-text-white-pressed-icon": 1,
        "components-button-color-text-white-disabled-bg": 0.02,
        "components-button-color-text-white-disabled-text": 1,
        "components-button-color-text-white-disabled-stroke": 1,
        "components-button-color-text-white-disabled-icon": 1,
        "components-button-color-text-white-focus-bg": 0.02,
        "components-button-color-text-white-focus-text": 1,
        "components-button-color-text-white-focus-stroke": 1,
        "components-button-color-text-white-focus-icon": 1,
        "components-button-color-text-white-focus-outline": 1,
        "components-button-group-color-border": 1,
        "components-button-group-color-button-bg-default": 0,
        "components-button-group-color-button-fg-default": 1,
        "components-button-group-color-button-bg-hover": 1,
        "components-button-group-color-button-fg-hover": 1,
        "components-button-group-color-button-bg-active": 1,
        "components-button-group-color-button-fg-active": 1,
        "components-button-group-color-overlay-left-gradient-fill-1": 1,
        "components-button-group-color-overlay-left-gradient-fill-2": null,
        "components-button-group-color-stroke-default-gradient-fill-1": 0,
        "components-button-group-color-stroke-default-gradient-fill-2": 0,
        "components-button-group-color-stroke-default-gradient-fill-3": 0,
        "components-button-group-color-stroke-default-gradient-fill-4": 0,
        "components-button-group-color-stroke-hover-gradient-fill-1": 1,
        "components-button-group-color-stroke-hover-gradient-fill-2": 1,
        "components-button-group-color-stroke-hover-gradient-fill-3": 1,
        "components-button-group-color-stroke-hover-gradient-fill-4": 1,
        "components-button-group-color-stroke-active-gradient-fill-1": 1,
        "components-button-group-color-stroke-active-gradient-fill-2": 1,
        "components-button-group-color-stroke-active-gradient-fill-3": 1,
        "components-button-group-color-stroke-active-gradient-fill-4": 1,
        "components-category-card-color-bg": 1,
        "components-category-card-color-content-bg": 1,
        "components-category-card-color-category-bg": 1,
        "components-category-card-color-category-bg-2": 1,
        "components-category-card-color-category-bg-3": 1,
        "components-category-card-color-category-bg-4": 1,
        "components-category-card-color-category-bg-5": 1,
        "components-category-card-color-category-bg-6": 1,
        "components-category-card-color-title": 1,
        "components-category-card-color-copy": 1,
        "components-category-card-color-stroke": 1,
        "components-category-card-color-stroke-hover": 1,
        "components-category-card-listing-color-bg": 1,
        "components-category-card-listing-color-title": 1,
        "components-category-card-listing-color-copy": 1,
        "components-card-search-result-color-bg": 1,
        "components-card-search-result-color-label": 1,
        "components-card-search-result-color-title": 1,
        "components-card-search-result-color-body": 1,
        "components-card-search-result-color-divider-line": 1,
        "components-contact-us-promo-color-light-bg": 1,
        "components-contact-us-promo-color-light-title": 1,
        "components-contact-us-promo-color-light-body": 1,
        "components-contact-us-promo-color-default-bg": 1,
        "components-contact-us-promo-color-default-title": 1,
        "components-contact-us-promo-color-default-body": 1,
        "components-contact-us-promo-color-brand-bg": 1,
        "components-contact-us-promo-color-brand-title": 1,
        "components-contact-us-promo-color-brand-body": 1,
        "components-content-list-color-bg": 1,
        "components-content-list-color-title": 1,
        "components-content-list-color-body": 1,
        "components-content-list-color-icon": 1,
        "components-cookies-colors-bg": 1,
        "components-cookies-colors-title": 1,
        "components-cookies-colors-body": 1,
        "components-cookies-colors-icon": 1,
        "components-dropdown-bg": 1,
        "components-dropdown-border-default": 1,
        "components-dropdown-border-hover": 1,
        "components-dropdown-border-focused": 1,
        "components-dropdown-border-destructive": 1,
        "components-dropdown-label-text-default": 1,
        "components-dropdown-item-text-default": 1,
        "components-dropdown-item-bg-default": 1,
        "components-dropdown-item-bg-hover": 1,
        "components-dropdown-input-field-default": 1,
        "components-dropdown-input-field-focused": 1,
        "components-dropdown-input-label-focused": 1,
        "components-dropdown-input-field-destructive": 1,
        "components-external-link-card-bg": 1,
        "components-external-link-card-card-bg": 1,
        "components-external-link-card-card-title": 1,
        "components-external-link-card-card-subtitle": 1,
        "components-external-link-card-card-eyebrow": 1,
        "components-fab-color-border-default": 1,
        "components-footer-brand-color-bg": 1,
        "components-footer-brand-color-logo": 1,
        "components-footer-brand-color-title-text": 1,
        "components-footer-brand-color-description-text": 1,
        "components-footer-brand-color-footer-menu-expanded": 1,
        "components-footer-brand-color-link-default": 1,
        "components-footer-brand-color-link-hover": 1,
        "components-footer-brand-color-link-pressed": 1,
        "components-footer-brand-color-category-link-default": 1,
        "components-footer-brand-color-category-link-hover": 1,
        "components-footer-brand-color-category-link-pressed": 1,
        "components-footer-brand-color-subcategory-link-default": 1,
        "components-footer-brand-color-subcategory-link-hover": 1,
        "components-footer-brand-color-subcategory-link-pressed": 1,
        "components-footer-brand-color-icon-icon-default": 1,
        "components-footer-brand-color-icon-icon-pressed": 1,
        "components-footer-brand-color-social-icon-default": 1,
        "components-footer-brand-color-social-icon-hover": 1,
        "components-footer-brand-color-social-icon-pressed": 1,
        "components-footer-brand-spacing-category-link-hover": 1,
        "components-footer-global-color-bg": 1,
        "components-footer-global-color-title-text": 1,
        "components-footer-global-color-disclaimer-text": 1,
        "components-footer-global-color-link-default": 1,
        "components-footer-global-color-link-hover": 1,
        "components-footer-global-color-link-pressed": 1,
        "components-generic-card-color-bg": 1,
        "components-generic-card-color-content-bg": 1,
        "components-generic-card-color-title": 1,
        "components-generic-card-color-subtitle": 1,
        "components-generic-card-color-copy": 1,
        "components-global-search-color-omnibox-list-bg": 1,
        "components-global-search-color-omnibox-divider": 1,
        "components-global-search-color-omnibox-item-bg-default": 1,
        "components-global-search-color-omnibox-item-bg-hover": 1,
        "components-global-search-color-omnibox-item-text-default": 1,
        "components-global-search-color-omnibox-item-text-hover": 1,
        "components-global-search-color-omnibox-label-text-default": 1,
        "components-global-search-color-omnibox-category-text-default": 1,
        "components-generic-card-listing-color-bg": 1,
        "components-generic-card-listing-color-title": 1,
        "components-generic-card-listing-color-copy": 1,
        "components-header-color-bg": 1,
        "components-header-color-bottom-stroke": 1,
        "components-header-color-search-search-border-default": 1,
        "components-header-color-search-search-text": 1,
        "components-header-color-search-search-icon": 1,
        "components-header-color-search-search-bar": 1,
        "components-header-color-mobile-mobile-expanded-bg": 1,
        "components-header-color-mobile-pressed-bg": 1,
        "components-header-color-language-selector-language": 1,
        "components-header-color-language-selector-country": 1,
        "components-header-color-language-selector-country-hover-bg": 1,
        "components-header-color-language-selector-megamenu-language": 1,
        "components-header-color-language-selector-megamenu-country": 1,
        "components-header-color-language-selector-megamenu-country-hover": 1,
        "components-header-color-language-selector-bg": 1,
        "components-header-color-language-selector-stroke": 1,
        "components-header-color-mega-menu-bg": 1,
        "components-header-color-mega-menu-featured-bg": 1,
        "components-header-color-mega-menu-featured-title": 1,
        "components-header-color-mega-menu-category-title": 1,
        "components-header-color-mega-menu-bg-accent": 1,
        "components-header-color-icon-icon-default": 1,
        "components-header-color-icon-icon-hover": 1,
        "components-header-color-icon-icon-pressed": 1,
        "components-header-color-links-nav-main-link-default": 1,
        "components-header-color-links-nav-main-link-hover": 1,
        "components-header-color-links-nav-main-link-pressed": 1,
        "components-header-color-links-nav-main-link-active": 1,
        "components-header-color-links-nav-main-active-bar": 1,
        "components-header-color-links-nav-main-bg-color": 1,
        "components-header-color-links-nav-category-link-category-default": 1,
        "components-header-color-links-nav-category-link-category-hover": 1,
        "components-header-color-links-nav-category-link-category-pressed": 1,
        "components-header-color-links-nav-category-link-category-active": 1,
        "components-header-color-links-nav-menu-link-link-default": 1,
        "components-header-color-links-nav-menu-link-link-hover": 1,
        "components-header-color-links-nav-menu-link-link-pressed": 1,
        "components-header-color-links-nav-menu-link-link-active": 1,
        "components-header-color-links-nav-menu-link-bg": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-default": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-active": 1,
        "components-hero-homepage-color-bg": 1,
        "components-hero-homepage-color-container-bg": 1,
        "components-hero-homepage-color-eyebrow": 1,
        "components-hero-homepage-color-header": 1,
        "components-hero-homepage-color-sub-header": 1,
        "components-hero-homepage-color-body": 1,
        "components-hero-homepage-color-play-pause": 1,
        "components-hero-internal-color-default-title": 1,
        "components-hero-internal-color-default-body": 1,
        "components-hero-internal-color-default-bg": 1,
        "components-hero-internal-color-accent-bg": 1,
        "components-hero-internal-color-with-image-bg": 1,
        "components-hero-internal-color-default-bg-2": 1,
        "components-hero-internal-color-with-image-title": 1,
        "components-hero-internal-color-with-image-body": 1,
        "components-heading-page-title-color-default-bg": 1,
        "components-heading-page-title-color-default-title": 1,
        "components-heading-page-title-color-default-body": 1,
        "components-heading-page-title-color-light-bg": 1,
        "components-heading-page-title-color-light-title": 1,
        "components-heading-page-title-color-light-body": 1,
        "components-heading-page-title-color-color-bg": 1,
        "components-heading-page-title-color-color-title": 1,
        "components-heading-page-title-color-color-body": 1,
        "components-hyperlink-color-dark-default": 1,
        "components-hyperlink-color-dark-hover": 1,
        "components-hyperlink-color-dark-visited": 1,
        "components-hyperlink-color-light-default": 1,
        "components-hyperlink-color-light-hover": 1,
        "components-hyperlink-color-light-visited": 1,
        "components-icon-default": 1,
        "components-icon-focused": 1,
        "components-icon-card-color-bg": 1,
        "components-icon-card-color-icon-bg": 1,
        "components-icon-card-color-icon": 1,
        "components-icon-card-color-title": 1,
        "components-icon-card-color-subtitle": 1,
        "components-icon-card-color-description": 1,
        "components-icon-card-listing-color-bg": 1,
        "components-icon-card-listing-color-inner-bg": 1,
        "components-icon-card-listing-color-brand-bg": 1,
        "components-icon-card-listing-color-title": 1,
        "components-icon-card-listing-color-copy": 1,
        "components-icon-card-listing-color-inner-legal-text": 1,
        "components-jumplink-color-bg-default": 1,
        "components-jumplink-color-link-text-default": 1,
        "components-jumplink-color-link-text-hover": 1,
        "components-jumplink-color-link-border-hover": 1,
        "components-jumplink-color-link-text-active": 1,
        "components-jumplink-color-link-border-active": 1,
        "components-jumplink-color-title-text": 1,
        "components-jumplink-color-description-text": 1,
        "components-layout-bg-default": 1,
        "components-lightbox-color-overlay-bg": null,
        "components-lightbox-color-title": 1,
        "components-lightbox-color-body": 1,
        "components-label-color-on-color-label-label-default": 1,
        "components-label-color-on-color-label-label-hover": 1,
        "components-label-color-on-color-label-label-pressed": 1,
        "components-label-color-on-color-background-bg-default": 0,
        "components-label-color-on-color-background-bg-hover": 1,
        "components-label-color-on-color-background-bg-pressed": 1,
        "components-label-color-on-color-stroke-hover": 1,
        "components-label-color-on-color-stroke-pressed": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-4": 1,
        "components-label-color-on-white-label-label-default": 1,
        "components-label-color-on-white-label-label-hover": 1,
        "components-label-color-on-white-label-label-pressed": 1,
        "components-label-color-on-white-background-bg-default": 1,
        "components-label-color-on-white-background-bg-hover": 1,
        "components-label-color-on-white-background-bg-pressed": 1,
        "components-label-color-on-white-stroke-hover": 1,
        "components-label-color-on-white-stroke-pressed": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-4": 1,
        "components-legal-disclaimer-color-default-bg": 1,
        "components-legal-disclaimer-color-default-title": 1,
        "components-legal-disclaimer-color-default-body": 1,
        "components-legal-disclaimer-color-light-bg": 1,
        "components-legal-disclaimer-color-light-title": 1,
        "components-legal-disclaimer-color-light-body": 1,
        "components-legal-disclaimer-color-brand-bg": 1,
        "components-legal-disclaimer-color-brand-title": 1,
        "components-legal-disclaimer-color-brand-body": 1,
        "components-media-video-thumbnail-overlay-bg": null,
        "components-media-caption-color-bg": 1,
        "components-media-caption-color-text": 1,
        "components-media-gallery-color-bg": 1,
        "components-media-gallery-color-title": 1,
        "components-media-gallery-color-body": 1,
        "components-pagination-on-white-accent-default": 1,
        "components-pagination-on-white-accent-scroll": 1,
        "components-pagination-on-white-disabled": 1,
        "components-pagination-on-white-bg": 1,
        "components-pagination-on-white-stroke": 1,
        "components-pagination-on-white-bg-active": 1,
        "components-pagination-on-white-bg-hover": 1,
        "components-pagination-on-white-text-default": 1,
        "components-pagination-on-white-text-hover": 1,
        "components-pagination-on-white-text-active": 1,
        "components-pagination-on-color-accent-default": 1,
        "components-pagination-on-color-accent-scroll": 1,
        "components-pagination-on-color-disabled": 1,
        "components-pagination-on-color-bg": 1,
        "components-pagination-on-color-stroke": 1,
        "components-pagination-on-color-bg-active": 1,
        "components-pagination-on-color-bg-hover": 1,
        "components-pagination-on-color-text-default": 1,
        "components-pagination-on-color-text-hover": 1,
        "components-pagination-on-color-text-active": 1,
        "components-pdf-link-card-color-bg": 1,
        "components-pdf-link-card-color-card-bg": 1,
        "components-pdf-link-card-color-card-title": 1,
        "components-pdf-link-card-color-card-subtitle": 1,
        "components-pdf-link-card-color-card-body": 1,
        "components-product-card-color-bg": 1,
        "components-product-card-color-content-bg": 1,
        "components-product-card-color-border": 1,
        "components-product-card-color-title": 1,
        "components-product-card-color-description": 1,
        "components-product-card-color-star-rating": 1,
        "components-product-card-color-badge-bg": 1,
        "components-product-card-color-badge-text": 1,
        "components-product-card-listing-color-bg": 1,
        "components-product-card-listing-color-title": 1,
        "components-product-card-listing-color-copy": 1,
        "components-product-card-listing-color-legal-text": 1,
        "components-product-information-color-bg": 1,
        "components-product-information-color-content-bg": 1,
        "components-product-information-color-headline": 1,
        "components-product-information-color-body": 1,
        "components-product-information-color-detail-text": 1,
        "components-product-information-color-divider": 1,
        "components-product-information-color-icon": 1,
        "components-product-information-color-play-icon": 1,
        "components-product-information-color-selected-stroke": 1,
        "components-product-results-list-with-search-color-bg": 1,
        "components-promo-color-default-bg": 1,
        "components-promo-color-default-image-bg": 1,
        "components-promo-color-default-content-bg": 1,
        "components-promo-color-default-title": 1,
        "components-promo-color-default-body": 1,
        "components-promo-color-default-category": 1,
        "components-promo-color-light-bg": 1,
        "components-promo-color-light-image-bg": 1,
        "components-promo-color-light-content-bg": 1,
        "components-promo-color-light-title": 1,
        "components-promo-color-light-body": 1,
        "components-promo-color-light-category": 1,
        "components-promo-color-brand-bg": 1,
        "components-promo-color-brand-image-bg": 1,
        "components-promo-color-brand-content-bg": 1,
        "components-promo-color-brand-title": 1,
        "components-promo-color-brand-body": 1,
        "components-promo-color-brand-category": 1,
        "components-pull-quote-color-bg": 1,
        "components-pull-quote-color-stroke": 1,
        "components-pull-quote-color-quote-bg": 1,
        "components-pull-quote-color-quote-title": 1,
        "components-pull-quote-color-quote-by-text": 1,
        "components-pull-quote-color-quote-detail": 1,
        "components-pull-quote-color-quote-highlight": 1,
        "components-promo-alternate-color-default-bg": 1,
        "components-promo-alternate-color-default-image-bg": 1,
        "components-promo-alternate-color-default-content-bg": 1,
        "components-promo-alternate-color-default-title": 1,
        "components-promo-alternate-color-default-body": 1,
        "components-promo-alternate-color-default-category": 1,
        "components-promo-alternate-color-light-bg": 1,
        "components-promo-alternate-color-light-image-bg": 1,
        "components-promo-alternate-color-light-content-bg": 1,
        "components-promo-alternate-color-light-title": 1,
        "components-promo-alternate-color-light-body": 1,
        "components-promo-alternate-color-light-category": 1,
        "components-promo-alternate-color-brand-bg": 1,
        "components-promo-alternate-color-brand-image-bg": 1,
        "components-promo-alternate-color-brand-content-bg": 1,
        "components-promo-alternate-color-brand-title": 1,
        "components-promo-alternate-color-brand-body": 1,
        "components-promo-alternate-color-brand-category": 1,
        "components-share-list-color-title": 1,
        "components-share-list-color-bg": 1,
        "components-share-list-color-link-default": 1,
        "components-share-list-color-link-hover": 1,
        "components-share-list-color-bg-link-hover": 1,
        "components-share-list-color-close-icon": 1,
        "components-share-list-color-facebook-icon": 1,
        "components-share-list-color-pinterest-icon": 1,
        "components-share-list-color-twitter-icon": 1,
        "components-share-list-color-basic-icon-default": 1,
        "components-share-list-color-icon-hover": 1,
        "components-share-list-color-border": 1,
        "components-site-search-bg": 1,
        "components-site-search-results_text": 1,
        "components-site-search-filter-filter-selected-bg": 1,
        "components-site-search-facet-group-label-bottom-line": 1,
        "components-site-search-facet-group-label-text": 1,
        "components-site-search-facet-group-label-bg": 1,
        "components-site-search-facet-bg": 1,
        "components-site-search-facet-stroke": 1,
        "components-site-search-refine-bg": 1,
        "components-site-search-refine-right-divider": 1,
        "components-site-search-refine-title": 1,
        "components-site-search-hero-search-bg": 1,
        "components-site-search-hero-search-accent-bg": 1,
        "components-site-search-hero-search-title": 1,
        "components-stats-stat-text": 1,
        "components-stats-stat-text-on-light": 1,
        "components-stats-supporting-text": 1,
        "components-stats-bg-default": 1,
        "components-stats-bg-inner": 1,
        "components-stats-bg-section-inner": 1,
        "components-stats-title": 1,
        "components-stats-copy": 1,
        "components-supporting-text-default": 1,
        "components-supporting-text-destructive": 1,
        "components-tab-bg": 1,
        "components-tab-border-default": 1,
        "components-tab-border-hover": 1,
        "components-tab-border-focused": 1,
        "components-tab-border-destructive": 1,
        "components-tab-label-text-default": 1,
        "components-tab-item-text-default": 1,
        "components-tab-item-bg-default": 1,
        "components-tab-item-bg-hover": 1,
        "components-tab-input-field-default": 1,
        "components-tab-input-field-focused": 1,
        "components-tab-input-label-focused": 1,
        "components-tab-input-field-destructive": 1,
        "components-tab-bg-2": 1,
        "components-tag-color-bg": 1,
        "components-tag-color-text": 1,
        "components-tag-color-stroke": 1,
        "components-tag-color-bg-hover": 1,
        "components-tag-color-text-hover": 1,
        "components-tag-color-stroke-hover": 1,
        "components-text-field-bg": 1,
        "components-text-field-bg-focused": 1,
        "components-text-field-border-default": 1,
        "components-text-field-border-hover": 1,
        "components-text-field-border-focused": 1,
        "components-text-field-border-destructive": 1,
        "components-text-field-input-field-default": 1,
        "components-text-field-input-field-focused": 1,
        "components-text-field-input-label-focused": 1,
        "components-text-field-input-field-destructive": 1,
        "components-text-field-icon-default": 1,
        "components-text-field-icon-destructive": 1,
        "components-text-field-supporting-text-default": 1,
        "components-text-field-supporting-text-destructive": 1,
        "components-tip-color-bg": 1,
        "components-tip-color-title": 1,
        "components-tip-color-body": 1,
        "components-tip-color-stroke": 1,
        "components-video-color-bg": 1,
        "themes-general-surface-color-light-bg": 1,
        "themes-general-surface-color-light-title": 1,
        "themes-general-surface-color-light-body": 1,
        "themes-general-surface-color-light-decor-line-break": 1,
        "themes-general-surface-color-light-rule-line": 1,
        "themes-general-surface-color-dark-bg": 1,
        "themes-general-surface-color-dark-title": 1,
        "themes-general-surface-color-dark-body": 1,
        "themes-general-surface-color-dark-decor-line-break": 1,
        "themes-general-card-color-dark-title": 1,
        "themes-general-card-color-dark-subtitle": 1,
        "themes-general-card-color-dark-body": 1,
        "themes-general-card-color-light-title": 1,
        "themes-general-card-color-light-subtitle": 1,
        "themes-general-card-color-light-body": 1,
        "themes-shadow-color": 1,
        "themes-shadow-share-list-color-fill": 0,
        "themes-shadow-header-menu-color-fill": 0,
        "themes-gradient-loader-gradient-fill-1": 1,
        "themes-gradient-loader-gradient-fill-2": 0,
        "themes-color-background-white": 1,
        "themes-color-background-light": 1,
        "themes-color-background-dark": 1,
        "themes-color-background-black": 1,
        "themes-color-background-brand1": 1,
        "themes-color-background-brand2": 1,
        "themes-color-background-accent1": 1,
        "themes-color-background-accent2": 1,
        "themes-color-background-body": 1,
        "themes-color-stroke-white": 1,
        "themes-color-stroke-light": 1,
        "themes-color-stroke-dark": 1,
        "themes-color-stroke-black": 1,
        "themes-color-stroke-brand1": 1,
        "themes-color-stroke-brand2": 1,
        "themes-color-stroke-accent1": 1,
        "themes-color-stroke-accent2": 1,
        "themes-color-text-white": 1,
        "themes-color-text-light": 1,
        "themes-color-text-dark": 1,
        "themes-color-text-black": 1,
        "themes-color-text-brand1": 1,
        "themes-color-text-brand2": 1,
        "themes-color-text-accent1": 1,
        "themes-color-text-accent2": 1,
        "colors-grayscale-100": 1,
        "colors-grayscale-200": 1,
        "colors-grayscale-300": 1,
        "colors-grayscale-400": 1,
        "colors-grayscale-500": 1,
        "colors-grayscale-600": 1,
        "colors-grayscale-700": 1,
        "colors-brand-1-100": 1,
        "colors-brand-1-200": 1,
        "colors-brand-1-300": 1,
        "colors-brand-1-400": 1,
        "colors-brand-1-500": 1,
        "colors-brand-1-600": 1,
        "colors-brand-1-700": 1,
        "colors-brand-2-100": 1,
        "colors-brand-2-200": 1,
        "colors-brand-2-300": 1,
        "colors-brand-2-400": 1,
        "colors-brand-2-500": 1,
        "colors-brand-2-600": 1,
        "colors-brand-2-700": 1,
        "colors-accent-1-100": 1,
        "colors-accent-1-200": 1,
        "colors-accent-1-300": 1,
        "colors-accent-1-400": 1,
        "colors-accent-1-500": 1,
        "colors-accent-1-600": 1,
        "colors-accent-1-700": 1,
        "colors-accent-2-100": 1,
        "colors-accent-2-200": 1,
        "colors-accent-2-300": 1,
        "colors-accent-2-400": 1,
        "colors-accent-2-500": 1,
        "colors-accent-2-600": 1,
        "colors-accent-2-700": 1,
        "colors-accent-3-100": 1,
        "colors-accent-3-200": 1,
        "colors-accent-3-300": 1,
        "colors-accent-3-400": 1,
        "colors-accent-3-500": 1,
        "colors-accent-3-600": 1,
        "colors-accent-3-700": 1,
        "colors-accent-4-400_storage": 1,
        "colors-accessible-accent-1-100": 1,
        "colors-accessible-accent-1-200": 1,
        "colors-accessible-accent-1-300": 1,
        "colors-accessible-accent-1-400": 1,
        "colors-accessible-accent-1-500": 1,
        "colors-accessible-accent-1-600": 1,
        "colors-accessible-accent-1-700": 1,
        "colors-accessible-accent-2-100": 1,
        "colors-accessible-accent-2-200": 1,
        "colors-accessible-accent-2-300": 1,
        "colors-accessible-accent-2-400": 1,
        "colors-accessible-accent-2-500": 1,
        "colors-accessible-accent-2-600": 1,
        "colors-accessible-accent-2-700": 1,
        "colors-accessible-brand-2-100": 1,
        "colors-accessible-brand-2-200": 1,
        "colors-accessible-brand-2-300": 1,
        "colors-accessible-brand-2-400": 1,
        "colors-accessible-brand-2-500": 1,
        "colors-accessible-brand-2-600": 1,
        "colors-accessible-brand-2-700": 1
      }
    },
    "Drano": {
      "colors": {
        "components-accessibility-bar-color-bg": "#003da5",
        "components-accessibility-bar-color-text": "#ffffff",
        "components-accordion-color-bg": "#ffffff",
        "components-accordion-color-title": "#191b1c",
        "components-accordion-color-body": "#27282b",
        "components-accordion-color-section-section-bg": "#ffffff",
        "components-accordion-color-section-section-title": "#191b1c",
        "components-accordion-color-section-section-body": "#27282b",
        "components-accordion-color-item-item-bg": "#ffffff",
        "components-accordion-color-item-divider": "#e8e8ea",
        "components-accordion-color-item-icon-icon-default": "#191b1c",
        "components-accordion-color-item-icon-icon-hover": "#ffffff",
        "components-accordion-color-item-icon-icon-pressed": "#ffffff",
        "components-accordion-color-item-icon-icon-active": "#ffffff",
        "components-accordion-color-item-title-item-default": "#191b1c",
        "components-accordion-color-item-title-item-hover": "#ffffff",
        "components-accordion-color-item-title-item-pressed": "#ffffff",
        "components-accordion-color-item-title-item-active": "#ffffff",
        "components-accordion-color-content-bg": "#ffffff",
        "components-accordion-color-content-title": "#191b1c",
        "components-accordion-color-content-body": "#27282b",
        "components-article-card-color-bg": "rgba(255, 255, 255, 0.00)",
        "components-article-card-color-border": "rgba(255, 255, 255, 0.00)",
        "components-article-card-color-content-bg": "rgba(255, 255, 255, 0.00)",
        "components-article-card-color-title": "#191b1c",
        "components-article-card-color-subtitle": "#27282b",
        "components-article-card-color-copy": "#27282b",
        "components-article-card-color-description": "#27282b",
        "components-article-card-listing-color-default-bg": "#f7f7f8",
        "components-article-card-listing-color-default-title": "#191b1c",
        "components-article-card-listing-color-default-copy": "#27282b",
        "components-article-hero-color-content-bg": "#f7f7f8",
        "components-article-hero-color-bg": "#f7f7f8",
        "components-article-hero-color-headline": "#191b1c",
        "components-article-hero-color-body": "#27282b",
        "components-article-hero-color-detail-text": "#27282b",
        "components-article-hero-color-divider": "#a2a5ab",
        "components-article-hero-color-icon": "#da291c",
        "components-article-hero-color-play-icon": "#ffffff",
        "components-article-hero-color-selected-stroke": "#003da5",
        "components-banner-color-bg-default": "#f7f7f8",
        "components-banner-color-icon-default": "#da291c",
        "components-banner-color-title-default": "#191b1c",
        "components-banner-color-body-default": "#191b1c",
        "components-banner-color-icon-close-default": "#191b1c",
        "components-banner-color-bg-alert": "#b32b30",
        "components-banner-color-icon-alert": "#ffffff",
        "components-banner-color-title-alert": "#ffffff",
        "components-banner-color-body-alert": "#ffffff",
        "components-banner-color-icon-close-alert": "#ffffff",
        "components-breadcrumb-color-bg": "#ffffff",
        "components-breadcrumb-color-breadcrumb-link-default": "#72757e",
        "components-breadcrumb-color-breadcrumb-link-hover": "#4e5056",
        "components-breadcrumb-color-breadcrumb-link-active": "#27282b",
        "components-breadcrumb-color-icon-divider": "#72757e",
        "components-bug-finder-prompt": "#ffffff",
        "components-button-color-filled-brand-default-bg": "#da291c",
        "components-button-color-filled-brand-default-text": "#ffffff",
        "components-button-color-filled-brand-default-stroke": "#da291c",
        "components-button-color-filled-brand-default-icon": "#ffffff",
        "components-button-color-filled-brand-hover-bg": "#a92016",
        "components-button-color-filled-brand-hover-text": "#ffffff",
        "components-button-color-filled-brand-hover-stroke": "#a92016",
        "components-button-color-filled-brand-hover-icon": "#ffffff",
        "components-button-color-filled-brand-pressed-bg": "#781710",
        "components-button-color-filled-brand-pressed-text": "#ffffff",
        "components-button-color-filled-brand-pressed-stroke": "#781710",
        "components-button-color-filled-brand-pressed-icon": "#ffffff",
        "components-button-color-filled-brand-disabled-bg": "rgba(218, 41, 28, 0.38)",
        "components-button-color-filled-brand-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-disabled-stroke": "rgba(218, 41, 28, 0.38)",
        "components-button-color-filled-brand-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-focus-bg": "#da291c",
        "components-button-color-filled-brand-focus-text": "#ffffff",
        "components-button-color-filled-brand-focus-stroke": "#da291c",
        "components-button-color-filled-brand-focus-icon": "#ffffff",
        "components-button-color-filled-brand-focus-outline": "#286efa",
        "components-button-color-filled-tonal-default-bg": "#fadcdb",
        "components-button-color-filled-tonal-default-text": "#da291c",
        "components-button-color-filled-tonal-default-stroke": "#fadcdb",
        "components-button-color-filled-tonal-default-icon": "#da291c",
        "components-button-color-filled-tonal-hover-bg": "#f19d99",
        "components-button-color-filled-tonal-hover-text": "#a92016",
        "components-button-color-filled-tonal-hover-stroke": "#f19d99",
        "components-button-color-filled-tonal-hover-icon": "#a92016",
        "components-button-color-filled-tonal-pressed-bg": "#f19d99",
        "components-button-color-filled-tonal-pressed-text": "#a92016",
        "components-button-color-filled-tonal-pressed-stroke": "#f19d99",
        "components-button-color-filled-tonal-pressed-icon": "#a92016",
        "components-button-color-filled-tonal-disabled-bg": "rgba(250, 220, 219, 0.38)",
        "components-button-color-filled-tonal-disabled-text": "rgba(218, 41, 28, 0.38)",
        "components-button-color-filled-tonal-disabled-stroke": "rgba(250, 220, 219, 0.38)",
        "components-button-color-filled-tonal-disabled-icon": "rgba(218, 41, 28, 0.38)",
        "components-button-color-filled-tonal-focus-bg": "#fadcdb",
        "components-button-color-filled-tonal-focus-text": "#da291c",
        "components-button-color-filled-tonal-focus-stroke": "#fadcdb",
        "components-button-color-filled-tonal-focus-icon": "#da291c",
        "components-button-color-filled-tonal-focus-outline": "#1c4cae",
        "components-button-color-filled-white-default-bg": "#ffffff",
        "components-button-color-filled-white-default-text": "#da291c",
        "components-button-color-filled-white-default-stroke": "#ffffff",
        "components-button-color-filled-white-default-icon": "#da291c",
        "components-button-color-filled-white-hover-bg": "#f7f7f8",
        "components-button-color-filled-white-hover-text": "#a92016",
        "components-button-color-filled-white-hover-stroke": "#f7f7f8",
        "components-button-color-filled-white-hover-icon": "#a92016",
        "components-button-color-filled-white-pressed-bg": "#f7f7f8",
        "components-button-color-filled-white-pressed-text": "#a92016",
        "components-button-color-filled-white-pressed-stroke": "#f7f7f8",
        "components-button-color-filled-white-pressed-icon": "#a92016",
        "components-button-color-filled-white-disabled-bg": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-text": "rgba(218, 41, 28, 0.38)",
        "components-button-color-filled-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-icon": "rgba(218, 41, 28, 0.38)",
        "components-button-color-filled-white-focus-bg": "#ffffff",
        "components-button-color-filled-white-focus-text": "#da291c",
        "components-button-color-filled-white-focus-stroke": "#ffffff",
        "components-button-color-filled-white-focus-icon": "#da291c",
        "components-button-color-filled-white-focus-outline": "#1c4cae",
        "components-button-color-outline-brand-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-brand-default-text": "#da291c",
        "components-button-color-outline-brand-default-stroke": "#da291c",
        "components-button-color-outline-brand-default-icon": "#da291c",
        "components-button-color-outline-brand-hover-bg": "#a92016",
        "components-button-color-outline-brand-hover-text": "#ffffff",
        "components-button-color-outline-brand-hover-stroke": "#a92016",
        "components-button-color-outline-brand-hover-icon": "#ffffff",
        "components-button-color-outline-brand-pressed-bg": "#781710",
        "components-button-color-outline-brand-pressed-text": "#ffffff",
        "components-button-color-outline-brand-pressed-stroke": "#781710",
        "components-button-color-outline-brand-pressed-icon": "#ffffff",
        "components-button-color-outline-brand-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-brand-disabled-text": "rgba(218, 41, 28, 0.38)",
        "components-button-color-outline-brand-disabled-stroke": "rgba(218, 41, 28, 0.38)",
        "components-button-color-outline-brand-disabled-icon": "rgba(218, 41, 28, 0.38)",
        "components-button-color-outline-brand-focus-bg": "#ffffff",
        "components-button-color-outline-brand-focus-text": "#da291c",
        "components-button-color-outline-brand-focus-stroke": "#da291c",
        "components-button-color-outline-brand-focus-icon": "#da291c",
        "components-button-color-outline-brand-focus-outline": "#286efa",
        "components-button-color-outline-tonal-default-bg": "rgba(25, 27, 28, 0.00)",
        "components-button-color-outline-tonal-default-text": "#da291c",
        "components-button-color-outline-tonal-default-stroke": "#f19d99",
        "components-button-color-outline-tonal-default-icon": "#da291c",
        "components-button-color-outline-tonal-hover-bg": "#f19d99",
        "components-button-color-outline-tonal-hover-text": "#a92016",
        "components-button-color-outline-tonal-hover-stroke": "#f19d99",
        "components-button-color-outline-tonal-hover-icon": "#a92016",
        "components-button-color-outline-tonal-pressed-bg": "#f19d99",
        "components-button-color-outline-tonal-pressed-text": "#a92016",
        "components-button-color-outline-tonal-pressed-stroke": "#f19d99",
        "components-button-color-outline-tonal-pressed-icon": "#a92016",
        "components-button-color-outline-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-tonal-disabled-text": "rgba(218, 41, 28, 0.38)",
        "components-button-color-outline-tonal-disabled-stroke": "rgba(241, 157, 153, 0.38)",
        "components-button-color-outline-tonal-disabled-icon": "rgba(218, 41, 28, 0.38)",
        "components-button-color-outline-tonal-focus-bg": "rgba(25, 27, 28, 0.01)",
        "components-button-color-outline-tonal-focus-text": "#da291c",
        "components-button-color-outline-tonal-focus-stroke": "#f19d99",
        "components-button-color-outline-tonal-focus-icon": "#da291c",
        "components-button-color-outline-tonal-focus-outline": "#286efa",
        "components-button-color-outline-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-default-text": "#ffffff",
        "components-button-color-outline-white-default-stroke": "#ffffff",
        "components-button-color-outline-white-default-icon": "#ffffff",
        "components-button-color-outline-white-hover-bg": "#f7f7f8",
        "components-button-color-outline-white-hover-text": "#a92016",
        "components-button-color-outline-white-hover-stroke": "#f7f7f8",
        "components-button-color-outline-white-hover-icon": "#a92016",
        "components-button-color-outline-white-pressed-bg": "#f7f7f8",
        "components-button-color-outline-white-pressed-text": "#a92016",
        "components-button-color-outline-white-pressed-stroke": "#f7f7f8",
        "components-button-color-outline-white-pressed-icon": "#a92016",
        "components-button-color-outline-white-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-focus-bg": "rgba(255, 255, 255, 0.01)",
        "components-button-color-outline-white-focus-text": "#ffffff",
        "components-button-color-outline-white-focus-stroke": "#ffffff",
        "components-button-color-outline-white-focus-icon": "#ffffff",
        "components-button-color-outline-white-focus-outline": "#286efa",
        "components-button-color-text-brand-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-default-text": "#da291c",
        "components-button-color-text-brand-default-stroke": "#da291c",
        "components-button-color-text-brand-default-icon": "#da291c",
        "components-button-color-text-brand-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-hover-bg-icon-only": "#fadcdb",
        "components-button-color-text-brand-hover-text": "#a92016",
        "components-button-color-text-brand-hover-stroke": "#781710",
        "components-button-color-text-brand-hover-icon": "#a92016",
        "components-button-color-text-brand-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-pressed-bg-icon-only": "#a2a5ab",
        "components-button-color-text-brand-pressed-text": "#781710",
        "components-button-color-text-brand-pressed-stroke": "#781710",
        "components-button-color-text-brand-pressed-icon": "#781710",
        "components-button-color-text-brand-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-disabled-text": "rgba(218, 41, 28, 0.38)",
        "components-button-color-text-brand-disabled-stroke": "rgba(218, 41, 28, 0.38)",
        "components-button-color-text-brand-disabled-icon": "rgba(218, 41, 28, 0.38)",
        "components-button-color-text-brand-focus-bg": "#e8e8ea",
        "components-button-color-text-brand-focus-text": "#a92016",
        "components-button-color-text-brand-focus-stroke": "#a92016",
        "components-button-color-text-brand-focus-icon": "#a92016",
        "components-button-color-text-brand-focus-outline": "#a92016",
        "components-button-color-text-tonal-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-default-text": "#da291c",
        "components-button-color-text-tonal-default-stroke": "#da291c",
        "components-button-color-text-tonal-default-icon": "#da291c",
        "components-button-color-text-tonal-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-hover-bg-icon-only": "#f19d99",
        "components-button-color-text-tonal-hover-text": "#a92016",
        "components-button-color-text-tonal-hover-stroke": "#a92016",
        "components-button-color-text-tonal-hover-icon": "#a92016",
        "components-button-color-text-tonal-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-pressed-bg-icon-only": "#f19d99",
        "components-button-color-text-tonal-pressed-text": "#781710",
        "components-button-color-text-tonal-pressed-stroke": "#781710",
        "components-button-color-text-tonal-pressed-icon": "#781710",
        "components-button-color-text-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-disabled-text": "rgba(218, 41, 28, 0.38)",
        "components-button-color-text-tonal-disabled-stroke": "rgba(218, 41, 28, 0.38)",
        "components-button-color-text-tonal-disabled-icon": "rgba(218, 41, 28, 0.38)",
        "components-button-color-text-tonal-focus-bg": "#f19d99",
        "components-button-color-text-tonal-focus-text": "#a92016",
        "components-button-color-text-tonal-focus-stroke": "#fadcdb",
        "components-button-color-text-tonal-focus-icon": "#a92016",
        "components-button-color-text-tonal-focus-outline": "#1c4cae",
        "components-button-color-text-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-default-text": "#ffffff",
        "components-button-color-text-white-default-stroke": "#ffffff",
        "components-button-color-text-white-default-icon": "#ffffff",
        "components-button-color-text-white-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-hover-bg-icon-only": "#4e5056",
        "components-button-color-text-white-hover-text": "#ffffff",
        "components-button-color-text-white-hover-stroke": "#ffffff",
        "components-button-color-text-white-hover-icon": "#ffffff",
        "components-button-color-text-white-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-pressed-bg-icon-only": "#4e5056",
        "components-button-color-text-white-pressed-text": "#ffffff",
        "components-button-color-text-white-pressed-stroke": "#ffffff",
        "components-button-color-text-white-pressed-icon": "#ffffff",
        "components-button-color-text-white-disabled-bg": "rgba(115, 119, 128, 0.00)",
        "components-button-color-text-white-disabled-text": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-stroke": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-icon": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-focus-bg": "#d5e3ff",
        "components-button-color-text-white-focus-text": "#ffffff",
        "components-button-color-text-white-focus-stroke": "#ffffff",
        "components-button-color-text-white-focus-icon": "#1c4cae",
        "components-button-color-text-white-focus-outline": "#1c4cae",
        "components-button-group-color-border": "#e8e8ea",
        "components-button-group-color-button-bg-default": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-button-fg-default": "#da291c",
        "components-button-group-color-button-bg-hover": "rgba(218, 41, 28, 0.38)",
        "components-button-group-color-button-fg-hover": "#a92016",
        "components-button-group-color-button-bg-active": "#da291c",
        "components-button-group-color-button-fg-active": "#ffffff",
        "components-button-group-color-overlay-left-gradient-fill-1": "#ffffff",
        "components-button-group-color-overlay-left-gradient-fill-2": "rgba(255, 255, 255, 0.50)",
        "components-button-group-color-stroke-default-gradient-fill-1": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-2": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-3": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-4": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-1": "rgba(25, 27, 28, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-2": "rgba(25, 27, 28, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-3": "rgba(25, 27, 28, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-4": "rgba(25, 27, 28, 0.00)",
        "components-button-group-color-stroke-active-gradient-fill-1": "#da291c",
        "components-button-group-color-stroke-active-gradient-fill-2": "#da291c",
        "components-button-group-color-stroke-active-gradient-fill-3": "#da291c",
        "components-button-group-color-stroke-active-gradient-fill-4": "#da291c",
        "components-category-card-color-bg": "#ffffff",
        "components-category-card-color-content-bg": "#ffffff",
        "components-category-card-color-category-bg": "#fedd00",
        "components-category-card-color-category-bg-2": "#4f9fa6",
        "components-category-card-color-category-bg-3": "#003da5",
        "components-category-card-color-category-bg-4": "#fedd00",
        "components-category-card-color-category-bg-5": "#4f9fa6",
        "components-category-card-color-category-bg-6": "#003da5",
        "components-category-card-color-title": "#191b1c",
        "components-category-card-color-copy": "#27282b",
        "components-category-card-color-stroke": "#e8e8ea",
        "components-category-card-color-stroke-hover": "#191b1c",
        "components-category-card-listing-color-bg": "#f7f7f8",
        "components-category-card-listing-color-title": "#191b1c",
        "components-category-card-listing-color-copy": "#27282b",
        "components-card-search-result-color-bg": "#ffffff",
        "components-card-search-result-color-label": "#27282b",
        "components-card-search-result-color-title": "#191b1c",
        "components-card-search-result-color-body": "#27282b",
        "components-card-search-result-color-divider-line": "#e8e8ea",
        "components-contact-us-promo-color-light-bg": "#ffffff",
        "components-contact-us-promo-color-light-title": "#191b1c",
        "components-contact-us-promo-color-light-body": "#27282b",
        "components-contact-us-promo-color-default-bg": "#f7f7f8",
        "components-contact-us-promo-color-default-title": "#191b1c",
        "components-contact-us-promo-color-default-body": "#27282b",
        "components-contact-us-promo-color-brand-bg": "#003da5",
        "components-contact-us-promo-color-brand-title": "#ffffff",
        "components-contact-us-promo-color-brand-body": "#f7f7f8",
        "components-content-list-color-bg": "#ffffff",
        "components-content-list-color-title": "#191b1c",
        "components-content-list-color-body": "#27282b",
        "components-content-list-color-icon": "#4e5056",
        "components-cookies-colors-bg": "#f7f7f8",
        "components-cookies-colors-title": "#191b1c",
        "components-cookies-colors-body": "#27282b",
        "components-cookies-colors-icon": "#da291c",
        "components-dropdown-bg": "#ffffff",
        "components-dropdown-border-default": "#72757e",
        "components-dropdown-border-hover": "#da291c",
        "components-dropdown-border-focused": "#191b1c",
        "components-dropdown-border-destructive": "#b32b30",
        "components-dropdown-label-text-default": "#191b1c",
        "components-dropdown-item-text-default": "#191b1c",
        "components-dropdown-item-bg-default": "#ffffff",
        "components-dropdown-item-bg-hover": "#d5e3ff",
        "components-dropdown-input-field-default": "#72757e",
        "components-dropdown-input-field-focused": "#191b1c",
        "components-dropdown-input-label-focused": "#191b1c",
        "components-dropdown-input-field-destructive": "#b32b30",
        "components-external-link-card-bg": "#f7f7f8",
        "components-external-link-card-card-bg": "#ffffff",
        "components-external-link-card-card-title": "#191b1c",
        "components-external-link-card-card-subtitle": "#27282b",
        "components-external-link-card-card-eyebrow": "#4e5056",
        "components-fab-color-border-default": "#ffffff",
        "components-footer-brand-color-bg": "#000000",
        "components-footer-brand-color-logo": "#ffffff",
        "components-footer-brand-color-title-text": "#ffffff",
        "components-footer-brand-color-description-text": "#f7f7f8",
        "components-footer-brand-color-footer-menu-expanded": "#000000",
        "components-footer-brand-color-link-default": "#ffffff",
        "components-footer-brand-color-link-hover": "#e8e8ea",
        "components-footer-brand-color-link-pressed": "#da291c",
        "components-footer-brand-color-category-link-default": "#ffffff",
        "components-footer-brand-color-category-link-hover": "#e8e8ea",
        "components-footer-brand-color-category-link-pressed": "#da291c",
        "components-footer-brand-color-subcategory-link-default": "#ffffff",
        "components-footer-brand-color-subcategory-link-hover": "#e8e8ea",
        "components-footer-brand-color-subcategory-link-pressed": "#da291c",
        "components-footer-brand-color-icon-icon-default": "#ffffff",
        "components-footer-brand-color-icon-icon-pressed": "#da291c",
        "components-footer-brand-color-social-icon-default": "#ffffff",
        "components-footer-brand-color-social-icon-hover": "#e8e8ea",
        "components-footer-brand-color-social-icon-pressed": "#da291c",
        "components-footer-brand-spacing-category-link-hover": "#000000",
        "components-footer-global-color-bg": "#ffffff",
        "components-footer-global-color-title-text": "#000000",
        "components-footer-global-color-disclaimer-text": "#000000",
        "components-footer-global-color-link-default": "#000000",
        "components-footer-global-color-link-hover": "#000000",
        "components-footer-global-color-link-pressed": "#000000",
        "components-generic-card-color-bg": "#ffffff",
        "components-generic-card-color-content-bg": "#ffffff",
        "components-generic-card-color-title": "#191b1c",
        "components-generic-card-color-subtitle": "#27282b",
        "components-generic-card-color-copy": "#27282b",
        "components-global-search-color-omnibox-list-bg": "#ffffff",
        "components-global-search-color-omnibox-divider": "#a2a5ab",
        "components-global-search-color-omnibox-item-bg-default": "#ffffff",
        "components-global-search-color-omnibox-item-bg-hover": "#d5e3ff",
        "components-global-search-color-omnibox-item-text-default": "#191b1c",
        "components-global-search-color-omnibox-item-text-hover": "#112d67",
        "components-global-search-color-omnibox-label-text-default": "#4e5056",
        "components-global-search-color-omnibox-category-text-default": "#4e5056",
        "components-generic-card-listing-color-bg": "#f7f7f8",
        "components-generic-card-listing-color-title": "#191b1c",
        "components-generic-card-listing-color-copy": "#27282b",
        "components-header-color-bg": "#ffffff",
        "components-header-color-bottom-stroke": "#e8e8ea",
        "components-header-color-search-search-border-default": "#191b1c",
        "components-header-color-search-search-text": "#4e5056",
        "components-header-color-search-search-icon": "#4e5056",
        "components-header-color-search-search-bar": "#191b1c",
        "components-header-color-mobile-mobile-expanded-bg": "#ffffff",
        "components-header-color-mobile-pressed-bg": "#e8e8ea",
        "components-header-color-language-selector-language": "#4e5056",
        "components-header-color-language-selector-country": "#191b1c",
        "components-header-color-language-selector-country-hover-bg": "#e8e8ea",
        "components-header-color-language-selector-megamenu-language": "#4e5056",
        "components-header-color-language-selector-megamenu-country": "#191b1c",
        "components-header-color-language-selector-megamenu-country-hover": "#e8e8ea",
        "components-header-color-language-selector-bg": "#ffffff",
        "components-header-color-language-selector-stroke": "#72757e",
        "components-header-color-mega-menu-bg": "#ffffff",
        "components-header-color-mega-menu-featured-bg": "#003da5",
        "components-header-color-mega-menu-featured-title": "#ffffff",
        "components-header-color-mega-menu-category-title": "#27282b",
        "components-header-color-mega-menu-bg-accent": "#ffffff",
        "components-header-color-icon-icon-default": "#da291c",
        "components-header-color-icon-icon-hover": "#781710",
        "components-header-color-icon-icon-pressed": "#000000",
        "components-header-color-links-nav-main-link-default": "#da291c",
        "components-header-color-links-nav-main-link-hover": "#781710",
        "components-header-color-links-nav-main-link-pressed": "#000000",
        "components-header-color-links-nav-main-link-active": "#000000",
        "components-header-color-links-nav-main-active-bar": "#da291c",
        "components-header-color-links-nav-main-bg-color": "#ffffff",
        "components-header-color-links-nav-category-link-category-default": "#4e5056",
        "components-header-color-links-nav-category-link-category-hover": "#191b1c",
        "components-header-color-links-nav-category-link-category-pressed": "#000000",
        "components-header-color-links-nav-category-link-category-active": "#000000",
        "components-header-color-links-nav-menu-link-link-default": "#4e5056",
        "components-header-color-links-nav-menu-link-link-hover": "#191b1c",
        "components-header-color-links-nav-menu-link-link-pressed": "#000000",
        "components-header-color-links-nav-menu-link-link-active": "#000000",
        "components-header-color-links-nav-menu-link-bg": "#ffffff",
        "components-header-color-links-nav-subcategory-link-subcategory-default": "#4e5056",
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": "#000000",
        "components-header-color-links-nav-subcategory-link-subcategory-active": "#000000",
        "components-hero-homepage-color-bg": "#ffffff",
        "components-hero-homepage-color-container-bg": "#f7f7f8",
        "components-hero-homepage-color-eyebrow": "#4e5056",
        "components-hero-homepage-color-header": "#191b1c",
        "components-hero-homepage-color-sub-header": "#27282b",
        "components-hero-homepage-color-body": "#27282b",
        "components-hero-homepage-color-play-pause": "#000000",
        "components-hero-internal-color-default-title": "#191b1c",
        "components-hero-internal-color-default-body": "#27282b",
        "components-hero-internal-color-default-bg": "#ffffff",
        "components-hero-internal-color-accent-bg": "#f7f7f8",
        "components-hero-internal-color-with-image-bg": "#ffffff",
        "components-hero-internal-color-default-bg-2": "#191b1c",
        "components-hero-internal-color-with-image-title": "#191b1c",
        "components-hero-internal-color-with-image-body": "#27282b",
        "components-heading-page-title-color-default-bg": "#ffffff",
        "components-heading-page-title-color-default-title": "#191b1c",
        "components-heading-page-title-color-default-body": "#27282b",
        "components-heading-page-title-color-light-bg": "#f7f7f8",
        "components-heading-page-title-color-light-title": "#191b1c",
        "components-heading-page-title-color-light-body": "#27282b",
        "components-heading-page-title-color-color-bg": "#003da5",
        "components-heading-page-title-color-color-title": "#ffffff",
        "components-heading-page-title-color-color-body": "#f7f7f8",
        "components-hyperlink-color-dark-default": "#003da5",
        "components-hyperlink-color-dark-hover": "#00286e",
        "components-hyperlink-color-dark-visited": "#2c3d00",
        "components-hyperlink-color-light-default": "#e6efff",
        "components-hyperlink-color-light-hover": "#7babff",
        "components-hyperlink-color-light-visited": "#dbff8c",
        "components-icon-default": "#72757e",
        "components-icon-focused": "#72757e",
        "components-icon-card-color-bg": "#ffffff",
        "components-icon-card-color-icon-bg": "#da291c",
        "components-icon-card-color-icon": "#191b1c",
        "components-icon-card-color-title": "#191b1c",
        "components-icon-card-color-subtitle": "#27282b",
        "components-icon-card-color-description": "#27282b",
        "components-icon-card-listing-color-bg": "#ffffff",
        "components-icon-card-listing-color-inner-bg": "#ffffff",
        "components-icon-card-listing-color-brand-bg": "#f7f7f8",
        "components-icon-card-listing-color-title": "#191b1c",
        "components-icon-card-listing-color-copy": "#27282b",
        "components-icon-card-listing-color-inner-legal-text": "#f7f7f8",
        "components-jumplink-color-bg-default": "#ffffff",
        "components-jumplink-color-link-text-default": "#4e5056",
        "components-jumplink-color-link-text-hover": "#27282b",
        "components-jumplink-color-link-border-hover": "#27282b",
        "components-jumplink-color-link-text-active": "#000000",
        "components-jumplink-color-link-border-active": "#da291c",
        "components-jumplink-color-title-text": "#191b1c",
        "components-jumplink-color-description-text": "#27282b",
        "components-layout-bg-default": "#ffffff",
        "components-lightbox-color-overlay-bg": "rgba(255, 255, 255, 0.95)",
        "components-lightbox-color-title": "#191b1c",
        "components-lightbox-color-body": "#27282b",
        "components-label-color-on-color-label-label-default": "#ffffff",
        "components-label-color-on-color-label-label-hover": "#4e5056",
        "components-label-color-on-color-label-label-pressed": "#27282b",
        "components-label-color-on-color-background-bg-default": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-color-background-bg-hover": "#ffffff",
        "components-label-color-on-color-background-bg-pressed": "#e8e8ea",
        "components-label-color-on-color-stroke-hover": "#ffffff",
        "components-label-color-on-color-stroke-pressed": "#e8e8ea",
        "components-label-color-on-color-stroke-default-gradient-fill-1": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-2": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-3": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-4": "#ffffff",
        "components-label-color-on-white-label-label-default": "#191b1c",
        "components-label-color-on-white-label-label-hover": "#ffffff",
        "components-label-color-on-white-label-label-pressed": "#ffffff",
        "components-label-color-on-white-background-bg-default": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-white-background-bg-hover": "#191b1c",
        "components-label-color-on-white-background-bg-pressed": "#000000",
        "components-label-color-on-white-stroke-hover": "#191b1c",
        "components-label-color-on-white-stroke-pressed": "#000000",
        "components-label-color-on-white-stroke-default-gradient-fill-1": "#191b1c",
        "components-label-color-on-white-stroke-default-gradient-fill-2": "#191b1c",
        "components-label-color-on-white-stroke-default-gradient-fill-3": "#191b1c",
        "components-label-color-on-white-stroke-default-gradient-fill-4": "#191b1c",
        "components-legal-disclaimer-color-default-bg": "#ffffff",
        "components-legal-disclaimer-color-default-title": "#191b1c",
        "components-legal-disclaimer-color-default-body": "#27282b",
        "components-legal-disclaimer-color-light-bg": "#f7f7f8",
        "components-legal-disclaimer-color-light-title": "#191b1c",
        "components-legal-disclaimer-color-light-body": "#27282b",
        "components-legal-disclaimer-color-brand-bg": "#003da5",
        "components-legal-disclaimer-color-brand-title": "#ffffff",
        "components-legal-disclaimer-color-brand-body": "#f7f7f8",
        "components-media-video-thumbnail-overlay-bg": "rgba(25, 27, 28, 0.80)",
        "components-media-caption-color-bg": "#f7f7f8",
        "components-media-caption-color-text": "#27282b",
        "components-media-gallery-color-bg": "#ffffff",
        "components-media-gallery-color-title": "#191b1c",
        "components-media-gallery-color-body": "#27282b",
        "components-pagination-on-white-accent-default": "#a92016",
        "components-pagination-on-white-accent-scroll": "#a92016",
        "components-pagination-on-white-disabled": "#a2a5ab",
        "components-pagination-on-white-bg": "rgba(25, 27, 28, 0.25)",
        "components-pagination-on-white-stroke": "#e8e8ea",
        "components-pagination-on-white-bg-active": "#da291c",
        "components-pagination-on-white-bg-hover": "#e8e8ea",
        "components-pagination-on-white-text-default": "#72757e",
        "components-pagination-on-white-text-hover": "#27282b",
        "components-pagination-on-white-text-active": "#ffffff",
        "components-pagination-on-color-accent-default": "#e8e8ea",
        "components-pagination-on-color-accent-scroll": "#e8e8ea",
        "components-pagination-on-color-disabled": "#480e0a",
        "components-pagination-on-color-bg": "rgba(25, 27, 28, 0.75)",
        "components-pagination-on-color-stroke": "#e8e8ea",
        "components-pagination-on-color-bg-active": "#ffffff",
        "components-pagination-on-color-bg-hover": "#27282b",
        "components-pagination-on-color-text-default": "#ffffff",
        "components-pagination-on-color-text-hover": "#ffffff",
        "components-pagination-on-color-text-active": "#191b1c",
        "components-pdf-link-card-color-bg": "#f7f7f8",
        "components-pdf-link-card-color-card-bg": "#ffffff",
        "components-pdf-link-card-color-card-title": "#191b1c",
        "components-pdf-link-card-color-card-subtitle": "#27282b",
        "components-pdf-link-card-color-card-body": "#27282b",
        "components-product-card-color-bg": "#ffffff",
        "components-product-card-color-content-bg": "#ffffff",
        "components-product-card-color-border": "rgba(255, 255, 255, 0.00)",
        "components-product-card-color-title": "#191b1c",
        "components-product-card-color-description": "#27282b",
        "components-product-card-color-star-rating": "#da291c",
        "components-product-card-color-badge-bg": "#fedd00",
        "components-product-card-color-badge-text": "#191b1c",
        "components-product-card-listing-color-bg": "#ffffff",
        "components-product-card-listing-color-title": "#191b1c",
        "components-product-card-listing-color-copy": "#27282b",
        "components-product-card-listing-color-legal-text": "#27282b",
        "components-product-information-color-bg": "#ffffff",
        "components-product-information-color-content-bg": "#ffffff",
        "components-product-information-color-headline": "#191b1c",
        "components-product-information-color-body": "#27282b",
        "components-product-information-color-detail-text": "#27282b",
        "components-product-information-color-divider": "#a2a5ab",
        "components-product-information-color-icon": "#da291c",
        "components-product-information-color-play-icon": "#ffffff",
        "components-product-information-color-selected-stroke": "#003da5",
        "components-product-results-list-with-search-color-bg": "#ffffff",
        "components-promo-color-default-bg": "#f7f7f8",
        "components-promo-color-default-image-bg": "rgba(255, 255, 255, 0.00)",
        "components-promo-color-default-content-bg": "#ffffff",
        "components-promo-color-default-title": "#191b1c",
        "components-promo-color-default-body": "#27282b",
        "components-promo-color-default-category": "#191b1c",
        "components-promo-color-light-bg": "#ffffff",
        "components-promo-color-light-image-bg": "rgba(255, 255, 255, 0.00)",
        "components-promo-color-light-content-bg": "#f7f7f8",
        "components-promo-color-light-title": "#191b1c",
        "components-promo-color-light-body": "#27282b",
        "components-promo-color-light-category": "#191b1c",
        "components-promo-color-brand-bg": "#ffffff",
        "components-promo-color-brand-image-bg": "rgba(255, 255, 255, 0.00)",
        "components-promo-color-brand-content-bg": "#003da5",
        "components-promo-color-brand-title": "#ffffff",
        "components-promo-color-brand-body": "#f7f7f8",
        "components-promo-color-brand-category": "#ffffff",
        "components-pull-quote-color-bg": "#f7f7f8",
        "components-pull-quote-color-stroke": "#003da5",
        "components-pull-quote-color-quote-bg": "#ffffff",
        "components-pull-quote-color-quote-title": "#191b1c",
        "components-pull-quote-color-quote-by-text": "#27282b",
        "components-pull-quote-color-quote-detail": "#4e5056",
        "components-pull-quote-color-quote-highlight": "#003da5",
        "components-promo-alternate-color-default-bg": "#f7f7f8",
        "components-promo-alternate-color-default-image-bg": "#f7f7f8",
        "components-promo-alternate-color-default-content-bg": "#f7f7f8",
        "components-promo-alternate-color-default-title": "#191b1c",
        "components-promo-alternate-color-default-body": "#27282b",
        "components-promo-alternate-color-default-category": "#191b1c",
        "components-promo-alternate-color-light-bg": "#ffffff",
        "components-promo-alternate-color-light-image-bg": "#ffffff",
        "components-promo-alternate-color-light-content-bg": "#ffffff",
        "components-promo-alternate-color-light-title": "#191b1c",
        "components-promo-alternate-color-light-body": "#27282b",
        "components-promo-alternate-color-light-category": "#191b1c",
        "components-promo-alternate-color-brand-bg": "#a92016",
        "components-promo-alternate-color-brand-image-bg": "#a92016",
        "components-promo-alternate-color-brand-content-bg": "#a92016",
        "components-promo-alternate-color-brand-title": "#ffffff",
        "components-promo-alternate-color-brand-body": "#f7f7f8",
        "components-promo-alternate-color-brand-category": "#ffffff",
        "components-share-list-color-title": "#191b1c",
        "components-share-list-color-bg": "#ffffff",
        "components-share-list-color-link-default": "#191b1c",
        "components-share-list-color-link-hover": "#da291c",
        "components-share-list-color-bg-link-hover": "#e8e8ea",
        "components-share-list-color-close-icon": "#191b1c",
        "components-share-list-color-facebook-icon": "#3b5998",
        "components-share-list-color-pinterest-icon": "#e60023",
        "components-share-list-color-twitter-icon": "#000000",
        "components-share-list-color-basic-icon-default": "#191b1c",
        "components-share-list-color-icon-hover": "#da291c",
        "components-share-list-color-border": "#e8e8ea",
        "components-site-search-bg": "#ffffff",
        "components-site-search-results_text": "#27282b",
        "components-site-search-filter-filter-selected-bg": "#fadcdb",
        "components-site-search-facet-group-label-bottom-line": "#e8e8ea",
        "components-site-search-facet-group-label-text": "#27282b",
        "components-site-search-facet-group-label-bg": "#ffffff",
        "components-site-search-facet-bg": "#ffffff",
        "components-site-search-facet-stroke": "#e8e8ea",
        "components-site-search-refine-bg": "#ffffff",
        "components-site-search-refine-right-divider": "#f7f7f8",
        "components-site-search-refine-title": "#191b1c",
        "components-site-search-hero-search-bg": "#ffffff",
        "components-site-search-hero-search-accent-bg": "#f7f7f8",
        "components-site-search-hero-search-title": "#191b1c",
        "components-stats-stat-text": "#da291c",
        "components-stats-stat-text-on-light": "#191b1c",
        "components-stats-supporting-text": "#27282b",
        "components-stats-bg-default": "#ffffff",
        "components-stats-bg-inner": "#ffffff",
        "components-stats-bg-section-inner": "#f7f7f8",
        "components-stats-title": "#191b1c",
        "components-stats-copy": "#27282b",
        "components-supporting-text-default": "#4e5056",
        "components-supporting-text-destructive": "#b32b30",
        "components-tab-bg": "#ffffff",
        "components-tab-border-default": "#72757e",
        "components-tab-border-hover": "#da291c",
        "components-tab-border-focused": "#191b1c",
        "components-tab-border-destructive": "#b32b30",
        "components-tab-label-text-default": "#191b1c",
        "components-tab-item-text-default": "#191b1c",
        "components-tab-item-bg-default": "#ffffff",
        "components-tab-item-bg-hover": "#fadcdb",
        "components-tab-input-field-default": "#72757e",
        "components-tab-input-field-focused": "#191b1c",
        "components-tab-input-label-focused": "#191b1c",
        "components-tab-input-field-destructive": "#b32b30",
        "components-tab-bg-2": "#ffffff",
        "components-tag-color-bg": "rgba(114, 117, 126, 0.25)",
        "components-tag-color-text": "#191b1c",
        "components-tag-color-stroke": "rgba(114, 117, 126, 0.25)",
        "components-tag-color-bg-hover": "#72757e",
        "components-tag-color-text-hover": "#191b1c",
        "components-tag-color-stroke-hover": "#72757e",
        "components-text-field-bg": "#ffffff",
        "components-text-field-bg-focused": "#e6efff",
        "components-text-field-border-default": "#191b1c",
        "components-text-field-border-hover": "#00286e",
        "components-text-field-border-focused": "#003da5",
        "components-text-field-border-destructive": "#b32b30",
        "components-text-field-input-field-default": "#4e5056",
        "components-text-field-input-field-focused": "#191b1c",
        "components-text-field-input-label-focused": "#191b1c",
        "components-text-field-input-field-destructive": "#b32b30",
        "components-text-field-icon-default": "#4e5056",
        "components-text-field-icon-destructive": "#b32b30",
        "components-text-field-supporting-text-default": "#4e5056",
        "components-text-field-supporting-text-destructive": "#b32b30",
        "components-tip-color-bg": "#f7f7f8",
        "components-tip-color-title": "#191b1c",
        "components-tip-color-body": "#27282b",
        "components-tip-color-stroke": "#e8e8ea",
        "components-video-color-bg": "#ffffff",
        "themes-general-surface-color-light-bg": "#f7f7f8",
        "themes-general-surface-color-light-title": "#191b1c",
        "themes-general-surface-color-light-body": "#27282b",
        "themes-general-surface-color-light-decor-line-break": "rgba(25, 27, 28, 0.25)",
        "themes-general-surface-color-light-rule-line": "rgba(25, 27, 28, 0.25)",
        "themes-general-surface-color-dark-bg": "#191b1c",
        "themes-general-surface-color-dark-title": "#ffffff",
        "themes-general-surface-color-dark-body": "#f7f7f8",
        "themes-general-surface-color-dark-decor-line-break": "rgba(25, 27, 28, 0.25)",
        "themes-general-card-color-dark-title": "#191b1c",
        "themes-general-card-color-dark-subtitle": "#27282b",
        "themes-general-card-color-dark-body": "#27282b",
        "themes-general-card-color-light-title": "#ffffff",
        "themes-general-card-color-light-subtitle": "#f7f7f8",
        "themes-general-card-color-light-body": "#f7f7f8",
        "themes-shadow-color": "#ffffff",
        "themes-shadow-share-list-color-fill": "rgba(0, 0, 0, 0.40)",
        "themes-shadow-header-menu-color-fill": "rgba(0, 0, 0, 0.20)",
        "themes-gradient-loader-gradient-fill-1": "#003da5",
        "themes-gradient-loader-gradient-fill-2": "rgba(0, 61, 165, 0.00)",
        "themes-color-background-white": "#ffffff",
        "themes-color-background-light": "#f7f7f8",
        "themes-color-background-dark": "#27282b",
        "themes-color-background-black": "#191b1c",
        "themes-color-background-brand1": "#da291c",
        "themes-color-background-brand2": "#fedd00",
        "themes-color-background-accent1": "#003da5",
        "themes-color-background-accent2": "#97d700",
        "themes-color-background-body": "#ffffff",
        "themes-color-stroke-white": "#ffffff",
        "themes-color-stroke-light": "#e8e8ea",
        "themes-color-stroke-dark": "#27282b",
        "themes-color-stroke-black": "#191b1c",
        "themes-color-stroke-brand1": "#da291c",
        "themes-color-stroke-brand2": "#fedd00",
        "themes-color-stroke-accent1": "#003da5",
        "themes-color-stroke-accent2": "#97d700",
        "themes-color-text-white": "#ffffff",
        "themes-color-text-light": "#f7f7f8",
        "themes-color-text-dark": "#27282b",
        "themes-color-text-black": "#191b1c",
        "themes-color-text-brand1": "#da291c",
        "themes-color-text-brand2": "#d1a400",
        "themes-color-text-accent1": "#00286e",
        "themes-color-text-accent2": "#2c3d00",
        "colors-grayscale-100": "#f7f7f8",
        "colors-grayscale-200": "#e8e8ea",
        "colors-grayscale-300": "#a2a5ab",
        "colors-grayscale-400": "#72757e",
        "colors-grayscale-500": "#4e5056",
        "colors-grayscale-600": "#27282b",
        "colors-grayscale-700": "#191b1c",
        "colors-brand-1-100": "#fadcdb",
        "colors-brand-1-200": "#f19d99",
        "colors-brand-1-300": "#e96057",
        "colors-brand-1-400": "#da291c",
        "colors-brand-1-500": "#a92016",
        "colors-brand-1-600": "#781710",
        "colors-brand-1-700": "#480e0a",
        "colors-brand-2-100": "#feffe7",
        "colors-brand-2-200": "#fdff86",
        "colors-brand-2-300": "#ffec0d",
        "colors-brand-2-400": "#fedd00",
        "colors-brand-2-500": "#d1a400",
        "colors-brand-2-600": "#895b0a",
        "colors-brand-2-700": "#442704",
        "colors-accent-1-100": "#e6efff",
        "colors-accent-1-200": "#7babff",
        "colors-accent-1-300": "#1168ff",
        "colors-accent-1-400": "#003da5",
        "colors-accent-1-500": "#003389",
        "colors-accent-1-600": "#00286e",
        "colors-accent-1-700": "#001e52",
        "colors-accent-2-100": "#f7ffe6",
        "colors-accent-2-200": "#dbff8c",
        "colors-accent-2-300": "#c0ff32",
        "colors-accent-2-400": "#97d700",
        "colors-accent-2-500": "#74a400",
        "colors-accent-2-600": "#507000",
        "colors-accent-2-700": "#2c3d00",
        "colors-accent-3-100": "#eaf4f5",
        "colors-accent-3-200": "#b5d8dc",
        "colors-accent-3-300": "#80bdc3",
        "colors-accent-3-400": "#4f9fa6",
        "colors-accent-3-500": "#3e7c80",
        "colors-accent-3-600": "#2d595b",
        "colors-accent-3-700": "#1b3536",
        "colors-accent-4-400_storage": "#000000",
        "colors-accessible-accent-1-100": "#e6efff",
        "colors-accessible-accent-1-200": "#7babff",
        "colors-accessible-accent-1-300": "#1168ff",
        "colors-accessible-accent-1-400": "#003da5",
        "colors-accessible-accent-1-500": "#003389",
        "colors-accessible-accent-1-600": "#00286e",
        "colors-accessible-accent-1-700": "#001e52",
        "colors-accessible-accent-2-100": "#f7ffe6",
        "colors-accessible-accent-2-200": "#dbff8c",
        "colors-accessible-accent-2-300": "#c0ff32",
        "colors-accessible-accent-2-400": "#97d700",
        "colors-accessible-accent-2-500": "#74a400",
        "colors-accessible-accent-2-600": "#507000",
        "colors-accessible-accent-2-700": "#2c3d00",
        "colors-accessible-brand-2-100": "#feffe7",
        "colors-accessible-brand-2-200": "#fdff86",
        "colors-accessible-brand-2-300": "#ffec0d",
        "colors-accessible-brand-2-400": "#fedd00",
        "colors-accessible-brand-2-500": "#d1a400",
        "colors-accessible-brand-2-600": "#895b0a",
        "colors-accessible-brand-2-700": "#442704"
      },
      "spacing": {
        "components-accordion-spacing-large-padding-x": "5rem",
        "components-accordion-spacing-large-icon-margin-right": "2rem",
        "components-accordion-spacing-large-copy-margin-right": "2.5rem",
        "components-accordion-spacing-large-maxwidth": "64rem",
        "components-accordion-spacing-large-section-padding-x": "0rem",
        "components-accordion-spacing-large-section-padding-y": "2.5rem",
        "components-accordion-spacing-large-section-copy-margin-bottom": "2.5rem",
        "components-accordion-spacing-large-section-space-between": "0rem",
        "components-accordion-spacing-large-item-padding-x": "0rem",
        "components-accordion-spacing-large-item-padding-y": "1.5rem",
        "components-accordion-spacing-large-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-large-content-padding-x": "0rem",
        "components-accordion-spacing-large-content-padding-y": "1.5rem",
        "components-accordion-spacing-large-content-title-margin-bottom": "2rem",
        "components-accordion-spacing-large-content-content-space-between": "0.25rem",
        "components-accordion-spacing-large-content-content-margin-left": "1rem",
        "components-accordion-spacing-small-padding-top": "1rem",
        "components-accordion-spacing-small-padding-x": "1.5rem",
        "components-accordion-spacing-small-padding-y": "2.5rem",
        "components-accordion-spacing-small-icon-margin-right": "0.75rem",
        "components-accordion-spacing-small-title-margin-bottom": "0.5rem",
        "components-accordion-spacing-small-copy-margin-right": "2rem",
        "components-accordion-spacing-small-copy-margin-bottom": "1.5rem",
        "components-accordion-spacing-small-section-padding-x": "0rem",
        "components-accordion-spacing-small-section-padding-y": "0rem",
        "components-accordion-spacing-small-section-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-space-between": "2.5rem",
        "components-accordion-spacing-small-item-padding-x": "0rem",
        "components-accordion-spacing-small-item-padding-y": "1rem",
        "components-accordion-spacing-small-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-small-content-padding-x": "0rem",
        "components-accordion-spacing-small-content-padding-y": "0.75rem",
        "components-accordion-spacing-small-content-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-content-content-space-between": "1rem",
        "components-accordion-spacing-small-content-content-margin-left": "1rem",
        "components-article-card-spacing-large-padding-image-x": "0rem",
        "components-article-card-spacing-large-padding-image-y": "0rem",
        "components-article-card-spacing-large-padding-content-x": "0rem",
        "components-article-card-spacing-large-padding-content-y": "1.5rem",
        "components-article-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-article-card-spacing-large-button-space-between": "1rem",
        "components-article-card-spacing-large-title-margin-bottom": "1rem",
        "components-article-card-spacing-large-list-content-gap": "1rem",
        "components-article-card-spacing-large-list-image-padding-x": "0rem",
        "components-article-card-spacing-large-list-image-padding-y": "0rem",
        "components-article-card-spacing-small-padding-image-x": "0rem",
        "components-article-card-spacing-small-padding-image-y": "0rem",
        "components-article-card-spacing-small-padding-content-x": "0rem",
        "components-article-card-spacing-small-padding-content-y": "0rem",
        "components-article-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-article-card-listing-spacing-small-padding-x": "1.5rem",
        "components-article-card-listing-spacing-small-padding-y": "2.5rem",
        "components-article-hero-spacing-large-padding-x": "0rem",
        "components-article-hero-spacing-large-padding-y": "0rem",
        "components-article-hero-spacing-large-title-margin-bottom": "2rem",
        "components-article-hero-spacing-large-label-share-margin-bottom": "2rem",
        "components-article-hero-spacing-large-author-name-margin-bottom": "0.75rem",
        "components-article-hero-spacing-large-author-thumbnail-margin-left": "1rem",
        "components-article-hero-spacing-large-details-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-space-between": "1rem",
        "components-article-hero-spacing-large-body-margin-bottom": "2rem",
        "components-article-hero-spacing-large-image-space-between": "1rem",
        "components-article-hero-spacing-large-image-margin-bottom": "1rem",
        "components-article-hero-spacing-large-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-stats-margin-bottom": "2.5rem",
        "components-article-hero-spacing-small-content-margin-top": "2.5rem",
        "components-article-hero-spacing-small-title-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-label-share-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-authorname-margin-bottom": "0.75rem",
        "components-article-hero-spacing-small-authorthumbnail-margin-left": "1rem",
        "components-article-hero-spacing-small-details-margin-bottom": "1rem",
        "components-article-hero-spacing-small-space-between": "0.75rem",
        "components-article-hero-spacing-small-body-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-image-space-between": "0.75rem",
        "components-article-hero-spacing-small-image-margin-bottom": "1rem",
        "components-article-hero-spacing-small-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-stats-margin-bottom": "2rem",
        "components-banner-spacing-large-content-padding-x": "1.5rem",
        "components-banner-spacing-large-content-space-between": "1rem",
        "components-banner-spacing-large-content-width": "69.375rem",
        "components-banner-spacing-icon-dimensions": "1.5rem",
        "components-banner-spacing-icon-margin-left": "0.5rem",
        "components-banner-spacing-small-space-between": "1rem",
        "components-banner-spacing-small-content-space-between": "1rem",
        "components-banner-spacing-small-title-margin-bottom": "0.75rem",
        "components-banner-spacing-small-content-margin-bottom": "1rem",
        "components-breadcrumb-spacing-large-space-between": "0.25rem",
        "components-breadcrumb-spacing-small-space-between": "0.25rem",
        "components-button-spacing-large-icon-large-x": "2.5rem",
        "components-button-spacing-large-icon-large-y": "2.5rem",
        "components-button-spacing-large-default-padding-y": "0.75rem",
        "components-button-spacing-large-default-padding-x": "1.5rem",
        "components-button-spacing-large-default-space-between": "0.25rem",
        "components-button-spacing-large-icon-left-padding-left": "1rem",
        "components-button-spacing-large-icon-right-padding-right": "1rem",
        "components-button-spacing-large-icon-only-padding-x": "0.75rem",
        "components-button-spacing-large-icon-only-padding-y": "0.75rem",
        "components-button-spacing-large-tertiary-content-height": "1.5rem",
        "components-button-spacing-compressed-icon-large-x": "2.5rem",
        "components-button-spacing-compressed-icon-large-y": "2.5rem",
        "components-button-spacing-compressed-default-padding-y": "0.5rem",
        "components-button-spacing-compressed-default-padding-x": "1rem",
        "components-button-spacing-compressed-default-space-between": "0.125rem",
        "components-button-spacing-compressed-icon-left-padding-left": "0.5rem",
        "components-button-spacing-compressed-icon-right-padding-right": "0.5rem",
        "components-button-spacing-compressed-icon-only-padding-x": "0.5rem",
        "components-button-group-spacing-padding-y": "0.125rem",
        "components-button-group-spacing-padding-x": "0.25rem",
        "components-button-group-spacing-space-between": "0.5rem",
        "components-category-card-spacing-large-padding-top": "0rem",
        "components-category-card-spacing-large-padding-x": "0rem",
        "components-category-card-spacing-large-padding-y": "0rem",
        "components-category-card-spacing-large-padding-image-x": "0rem",
        "components-category-card-spacing-large-padding-image-y": "0rem",
        "components-category-card-spacing-large-padding-content-x": "2rem",
        "components-category-card-spacing-large-padding-content-y": "2rem",
        "components-category-card-listing-spacing-large-padding-y": "5rem",
        "components-category-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-category-card-listing-spacing-large-card-margin-bottom": "0rem",
        "components-category-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-card-search-result-small-padding-top": "0rem",
        "components-card-search-result-small-padding-x": "0rem",
        "components-card-search-result-small-padding-y": "0rem",
        "components-card-search-result-small-padding-image-x": "0rem",
        "components-card-search-result-small-padding-image-y": "0rem",
        "components-card-search-result-small-padding-content-x": "1.5rem",
        "components-card-search-result-small-padding-content-y": "1.5rem",
        "components-card-search-result-spacing-large-padding-x": "0rem",
        "components-card-search-result-spacing-large-padding-y": "1.5rem",
        "components-card-search-result-spacing-large-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-image-margin-right": "1.5rem",
        "components-card-search-result-spacing-small-padding-x": "1.5rem",
        "components-card-search-result-spacing-small-padding-y": "1.5rem",
        "components-card-search-result-spacing-small-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-image-margin-right": "0.75rem",
        "components-contact-us-promo-spacing-large-padding-x": "5rem",
        "components-contact-us-promo-spacing-large-padding-y": "5rem",
        "components-contact-us-promo-spacing-large-content-margin-right": "5rem",
        "components-contact-us-promo-spacing-large-margin-y": "2.5rem",
        "components-contact-us-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-contact-us-promo-spacing-small-margin-y": "2.5rem",
        "components-content-list-list-item-container-padding-x": "0.75rem",
        "components-content-list-list-item-container-padding-y": "1rem",
        "components-content-list-list-item-content-space-between": "0.5rem",
        "components-content-list-list-item-content-default-padding-y": "0.25rem",
        "components-content-list-list-item-content-hyperlink-padding-y": "0rem",
        "components-content-list-spacing-large-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-large-item-title-margin-bottom": "1rem",
        "components-content-list-spacing-large-item-copy-margin-bottom": "2.5rem",
        "components-content-list-spacing-large-item-icon-copy-margin-top": "0rem",
        "components-content-list-spacing-large-item-margin-bottom": "2rem",
        "components-content-list-spacing-large-item-image-margin-bottom": "0.5rem",
        "components-content-list-spacing-large-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-y": "2.5rem",
        "components-content-list-spacing-small-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-small-item-title-margin-bottom": "0.5rem",
        "components-content-list-spacing-small-item-copy-margin-bottom": "1rem",
        "components-content-list-spacing-small-item-margin-bottom": "1.5rem",
        "components-content-list-spacing-small-header-title-margin-bottom": "1rem",
        "components-content-list-spacing-small-header-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-title-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-button-space-between": "1rem",
        "components-dropdown-item-padding-y": "0.75rem",
        "components-dropdown-item-padding-x": "0.75rem",
        "components-dropdown-item-spacing-x": "0.75rem",
        "components-dropdown-input-padding-y": "0.25rem",
        "components-dropdown-input-padding-x": "1rem",
        "components-footer-brand-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-large-column-margin-right": "4rem",
        "components-footer-brand-spacing-large-column-margin-bottom": "4rem",
        "components-footer-brand-spacing-large-link-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-padding-y": "2.5rem",
        "components-footer-brand-spacing-small-padding-x": "1rem",
        "components-footer-brand-spacing-small-logo-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-column-margin-right": "0.125rem",
        "components-footer-brand-spacing-small-column-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-small-link-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-title-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-text-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-footer-link-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-link-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-link-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-link-minheight": "3rem",
        "components-footer-brand-spacing-small-footer-category-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-category-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-category-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-category-minheight": "3rem",
        "components-footer-global-spacing-large-padding-y": "2.5rem",
        "components-footer-global-spacing-large-padding-x": "5rem",
        "components-footer-global-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-global-spacing-large-column-margin-right": "2rem",
        "components-footer-global-spacing-large-link-margin-bottom": "2rem",
        "components-footer-global-spacing-small-padding-y": "2.5rem",
        "components-footer-global-spacing-small-padding-x": "1rem",
        "components-footer-global-spacing-small-logo-margin-bottom": "1rem",
        "components-footer-global-spacing-small-column-margin-right": "1rem",
        "components-footer-global-spacing-small-link-margin-bottom": "1rem",
        "components-generic-card-spacing-large-padding-x": "0rem",
        "components-generic-card-spacing-large-padding-y": "0rem",
        "components-generic-card-spacing-large-padding-image-x": "0rem",
        "components-generic-card-spacing-large-padding-image-y": "0rem",
        "components-generic-card-spacing-large-padding-content-x": "2rem",
        "components-generic-card-spacing-large-padding-content-y": "2rem",
        "components-generic-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-generic-card-spacing-large-title-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-button-space-between": "1rem",
        "components-generic-card-spacing-small-padding-x": "0rem",
        "components-generic-card-spacing-small-padding-y": "0rem",
        "components-generic-card-spacing-small-padding-image-x": "0rem",
        "components-generic-card-spacing-small-padding-image-y": "0rem",
        "components-generic-card-spacing-small-padding-content-x": "1.5rem",
        "components-generic-card-spacing-small-padding-content-y": "1.5rem",
        "components-global-search-spacing-omnibox-padding-x": "0.125rem",
        "components-global-search-spacing-omnibox-list-padding-y": "0.5rem",
        "components-global-search-spacing-omnibox-label-padding": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-item-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-height": "3rem",
        "components-generic-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-generic-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-header-shadow-x": "0rem",
        "components-header-spacing-large-padding-y": "1rem",
        "components-header-spacing-large-padding-x": "1.5rem",
        "components-header-spacing-large-links-margin-right": "1.5rem",
        "components-header-spacing-large-icons-space-between": "1.5rem",
        "components-header-spacing-large-logo-margin-right": "2rem",
        "components-header-spacing-large-space-between-search": "0.5rem",
        "components-header-spacing-large-height": "5rem",
        "components-header-spacing-large-language-selector-padding-x": "1rem",
        "components-header-spacing-large-language-selector-padding-y": "0.5rem",
        "components-header-spacing-large-language-selector-space-between": "0.75rem",
        "components-header-spacing-large-language-selector-country-space-between": "0.5rem",
        "components-header-spacing-large-mega-menu-padding-y": "2.5rem",
        "components-header-spacing-large-mega-menu-padding-x": "2.5rem",
        "components-header-spacing-large-mega-menu-column-margin-right": "2.5rem",
        "components-header-spacing-large-mega-menu-category-link-margin-bottom": "1rem",
        "components-header-spacing-large-mega-menu-link-margin-bottom": "0.5rem",
        "components-header-spacing-large-mega-menu-featured-padding-x": "2rem",
        "components-header-spacing-large-mega-menu-featured-padding-y": "2rem",
        "components-header-spacing-large-mega-menu-featured-image-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-title-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-link-space-between": "1.5rem",
        "components-header-spacing-large-mega-menu-title-margin-bottom": "2.5rem",
        "components-header-spacing-large-dropdown-padding-y": "1.5rem",
        "components-header-spacing-large-dropdown-padding-x": "1.5rem",
        "components-header-spacing-large-dropdown-margin-bottom": "0.5rem",
        "components-header-spacing-small-language-selector-padding-x": "1rem",
        "components-header-spacing-small-language-selector-padding-y": "0.5rem",
        "components-header-spacing-small-language-selector-space-between": "0.5rem",
        "components-header-spacing-small-padding-left": "1rem",
        "components-header-spacing-small-padding-right": "1rem",
        "components-header-spacing-small-padding-top": "0.5rem",
        "components-header-spacing-small-padding-bottom": "0.5rem",
        "components-header-spacing-small-nav-main-minheight": "3rem",
        "components-header-spacing-small-nav-main-padding-y": "0.5rem",
        "components-header-spacing-small-nav-main-padding-left": "1.5rem",
        "components-header-spacing-small-nav-main-padding-right": "1rem",
        "components-header-spacing-small-nav-category-minheight": "3rem",
        "components-header-spacing-small-nav-category-padding-left": "1.5rem",
        "components-header-spacing-small-nav-category-padding-right": "0.75rem",
        "components-header-spacing-small-nav-category-padding-y": "0.75rem",
        "components-header-spacing-small-nav-category-space-between": "0rem",
        "components-header-spacing-small-nav-menu-link-minheight": "3rem",
        "components-header-spacing-small-nav-menu-link-padding-right": "1.5rem",
        "components-header-spacing-small-nav-menu-link-padding-left": "2.5rem",
        "components-header-spacing-small-nav-menu-link-padding-y": "0.75rem",
        "components-header-spacing-small-mega-menu-padding-y": "0rem",
        "components-header-spacing-small-mega-menu-padding-x": "0rem",
        "components-header-spacing-small-mega-menu-category-link-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-link-margin-bottom": "0rem",
        "components-header-spacing-small-mega-menu-featured-padding-x": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-padding-y": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-image-margin-bottom": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-title-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-featured-link-space-between": "0.75rem",
        "components-header-spacing-small-mega-menu-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-container-padding": "3rem",
        "components-hero-homepage-spacing-large-padding-x": "4rem",
        "components-hero-homepage-spacing-large-padding-y": "2.5rem",
        "components-hero-homepage-spacing-large-minheight": "34.3125rem",
        "components-hero-homepage-spacing-large-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-margin-bottom": "2rem",
        "components-hero-homepage-spacing-large-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-copy-margin-bottom": "2.5rem",
        "components-hero-homepage-spacing-large-cta-margin-right": "1rem",
        "components-hero-homepage-spacing-large-image-height": "34.3125rem",
        "components-hero-homepage-spacing-large-image-width": "45.75rem",
        "components-hero-homepage-spacing-small-padding-x": "0rem",
        "components-hero-homepage-spacing-small-padding-y": "1.5rem",
        "components-hero-homepage-spacing-small-minheight": "34.3125rem",
        "components-hero-homepage-spacing-small-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-copy-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-image-height": "17.5625rem",
        "components-hero-homepage-spacing-small-image-width": "45.75rem",
        "components-hero-internal-spacing-large-padding-y": "2.5rem",
        "components-hero-internal-spacing-large-padding-right": "10rem",
        "components-hero-internal-spacing-large-title-margin-bottom": "1.5rem",
        "components-hero-internal-spacing-large-button-padding-y": "2rem",
        "components-hero-internal-spacing-large-text-maxwidth": "59.625rem",
        "components-hero-internal-spacing-large-minheight": "18.5rem",
        "components-hero-internal-spacing-small-padding-x": "1.5rem",
        "components-hero-internal-spacing-small-padding-right": "0rem",
        "components-hero-internal-spacing-small-button-padding-y": "1.5rem",
        "components-hero-internal-spacing-small-text-maxwidth": "30rem",
        "components-hero-internal-spacing-small-minheight": "13.5rem",
        "components-icon-card-spacing-large-padding-x": "0rem",
        "components-icon-card-spacing-large-padding-y": "0rem",
        "components-icon-card-spacing-large-icon-margin": "1.5rem",
        "components-icon-card-spacing-large-margin-right": "1.5rem",
        "components-icon-card-spacing-large-title-margin-bottom": "1rem",
        "components-icon-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-icon-card-spacing-large-button-space-between": "1.5rem",
        "components-icon-card-spacing-large-top-space-between": "1rem",
        "components-icon-card-spacing-small-padding-x": "1.5rem",
        "components-icon-card-spacing-small-padding-y": "1.5rem",
        "components-icon-card-spacing-small-icon-margin": "1.5rem",
        "components-icon-card-spacing-small-margin-right": "1.5rem",
        "components-icon-card-spacing-small-title-margin-bottom": "1.5rem",
        "components-icon-card-dimensions-large-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-large-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-large-big-icon-height": "4rem",
        "components-icon-card-dimensions-large-big-icon-width": "4rem",
        "components-icon-card-dimensions-large-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-large-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-large-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-large-tiny-icon-width": "2.5rem",
        "components-icon-card-dimensions-small-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-small-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-small-big-icon-height": "3.5rem",
        "components-icon-card-dimensions-small-big-icon-width": "3.5rem",
        "components-icon-card-dimensions-small-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-small-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-small-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-small-tiny-icon-width": "2.5rem",
        "components-icon-card-listing-spacing-large-padding-inner-x": "4rem",
        "components-icon-card-listing-spacing-large-padding-inner-y": "4rem",
        "components-icon-card-listing-spacing-large-card-space-between": "4rem",
        "components-icon-card-listing-spacing-large-title-margin-bottom": "1.5rem",
        "components-icon-card-listing-spacing-large-card-margin-bottom": "4rem",
        "components-icon-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-x": "1.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-y": "2.5rem",
        "components-icon-card-listing-spacing-small-title-margin-bottom": "1rem",
        "components-icon-card-listing-spacing-small-button-margin-top": "1.5rem",
        "components-jumplink-link-padding-bottom": "0.5rem",
        "components-jumplink-spacing-small-title-area-margin-bottom": "1.5rem",
        "components-jumplink-spacing-small-link-container-padding-x": "0rem",
        "components-lightbox-spacing-large-carousel-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-carousel-space-between": "1.5rem",
        "components-lightbox-spacing-large-featured-image-height": "39.25rem",
        "components-lightbox-spacing-large-featured-image-width": "39.25rem",
        "components-lightbox-spacing-large-image-height": "17.0625rem",
        "components-lightbox-spacing-large-image-width": "17.0625rem",
        "components-lightbox-spacing-small-carousel-margin-bottom": "1rem",
        "components-lightbox-spacing-small-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-small-media-details-margin-top": "1rem",
        "components-lightbox-spacing-small-image-height": "20.4375rem",
        "components-lightbox-spacing-small-image-width": "20.4375rem",
        "components-legal-disclaimer-spacing-small-ruleline-width": "20.4375rem",
        "components-media-spacing-large-padding-y": "0.5rem",
        "components-media-spacing-large-padding-x": "0.75rem",
        "components-media-gallery-large-container-grid-gap-x": "1.5rem",
        "components-media-gallery-large-container-grid-gap-y": "1.5rem",
        "components-media-gallery-small-container-grid-gap-x": "0.5rem",
        "components-media-gallery-small-container-grid-gap-y": "0.5rem",
        "components-media-gallery-spacing-small-padding-x": "1.5rem",
        "components-media-gallery-spacing-small-padding-y": "2.5rem",
        "components-media-gallery-spacing-large-padding-x": "5rem",
        "components-media-gallery-spacing-large-padding-y": "5rem",
        "components-product-card-spacing-list-content-gap": "1rem",
        "components-product-card-spacing-large-padding-image-x": "0rem",
        "components-product-card-spacing-large-padding-image-y": "0rem",
        "components-product-card-spacing-large-padding-content-x": "0rem",
        "components-product-card-spacing-large-padding-content-y": "1.5rem",
        "components-product-card-spacing-large-category-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-title-margin-bottom": "1rem",
        "components-product-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-button-space-between": "1rem",
        "components-product-card-spacing-small-padding-image-x": "0rem",
        "components-product-card-spacing-small-padding-image-y": "0rem",
        "components-product-card-spacing-small-padding-content-x": "0rem",
        "components-product-card-spacing-small-padding-content-y": "1.5rem",
        "components-product-card-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-large-padding-x": "5rem",
        "components-product-information-spacing-large-padding-y": "2.5rem",
        "components-product-information-spacing-large-title-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-label-margin-bottom": "2.5rem",
        "components-product-information-spacing-large-ratings-margin-bottom": "1rem",
        "components-product-information-spacing-large-details-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-space-between": "1rem",
        "components-product-information-spacing-large-carousel-space-between": "5rem",
        "components-product-information-spacing-large-button-margin-y": "0.5rem",
        "components-product-information-spacing-large-body-margin-top": "1.5rem",
        "components-product-information-spacing-large-image-space-between": "1rem",
        "components-product-information-spacing-large-image-margin-bottom": "1rem",
        "components-product-information-spacing-small-padding-x": "1.5rem",
        "components-product-information-spacing-small-padding-y": "1.5rem",
        "components-product-information-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-small-label-margin-bottom": "1.5rem",
        "components-product-information-spacing-small-ratings-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-details-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-space-between": "0.75rem",
        "components-product-information-spacing-small-carousel-space-between": "2.5rem",
        "components-product-information-spacing-small-button-margin-y": "0.75rem",
        "components-product-information-spacing-small-body-margin-top": "1rem",
        "components-product-information-spacing-small-image-space-between": "0.75rem",
        "components-product-information-spacing-small-image-margin-bottom": "1rem",
        "components-product-results-list-with-search-spacing-large-padding-x": "5rem",
        "components-product-results-list-with-search-spacing-large-padding-y": "5rem",
        "components-product-results-list-with-search-spacing-large-ruleline-margin-top": "2.5rem",
        "components-product-results-list-with-search-spacing-large-filter-space-between": "1rem",
        "components-product-results-list-with-search-spacing-large-card-space-between": "1.5rem",
        "components-product-results-list-with-search-spacing-large-card-container-margin-bottom": "5rem",
        "components-product-results-list-with-search-spacing-small-padding-x": "1.5rem",
        "components-product-results-list-with-search-spacing-small-padding-y": "2.5rem",
        "components-product-results-list-with-search-spacing-small-ruleline-margin-top": "1rem",
        "components-product-results-list-with-search-spacing-small-filter-padding-x": "0.5rem",
        "components-product-results-list-with-search-spacing-small-card-space-between": "1rem",
        "components-product-results-list-with-search-spacing-small-card-margin-bottom": "1.5rem",
        "components-promo-spacing-large-padding-x": "5rem",
        "components-promo-spacing-large-padding-y": "5rem",
        "components-promo-spacing-large-content-space-between": "0rem",
        "components-promo-spacing-large-content-space-between-y": "3rem",
        "components-promo-spacing-large-padding-content-x": "4rem",
        "components-promo-spacing-large-padding-content-condensed-x": "0rem",
        "components-promo-spacing-large-padding-content-y": "4rem",
        "components-promo-spacing-large-padding-content-condensed-y": "0rem",
        "components-promo-spacing-large-padding-image-x": "0rem",
        "components-promo-spacing-large-padding-image-y": "0rem",
        "components-promo-spacing-large-padding-image-top": "5rem",
        "components-promo-spacing-large-title-margin-bottom": "2rem",
        "components-promo-spacing-large-margin-y": "2rem",
        "components-promo-spacing-large-buttons-space-between": "1rem",
        "components-promo-spacing-small-padding-x": "0rem",
        "components-promo-spacing-small-padding-y": "0rem",
        "components-promo-spacing-small-padding-top": "0rem",
        "components-promo-spacing-small-padding-content-x": "1.5rem",
        "components-promo-spacing-small-padding-content-y": "2.5rem",
        "components-promo-spacing-small-padding-image-x": "0rem",
        "components-promo-spacing-small-padding-image-y": "0rem",
        "components-promo-spacing-small-padding-image-top": "0rem",
        "components-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-promo-spacing-small-margin-y": "2.5rem",
        "components-promo-minheight": "30rem",
        "components-pull-quote-spacing-large-padding-quote-x": "4rem",
        "components-pull-quote-spacing-large-padding-quote-y": "4rem",
        "components-pull-quote-spacing-large-title-margin-bottom": "2rem",
        "components-pull-quote-spacing-large-quote-margin-bottom": "4rem",
        "components-pull-quote-spacing-large-quote-space-between": "1.5rem",
        "components-pull-quote-spacing-large-detail-space-between": "0.5rem",
        "components-pull-quote-spacing-large-quote-minheight": "25rem",
        "components-pull-quote-spacing-small-padding-quote-x": "1.5rem",
        "components-pull-quote-spacing-small-padding-quote-y": "2.5rem",
        "components-pull-quote-spacing-small-title-margin-bottom": "1.5rem",
        "components-pull-quote-spacing-small-quote-margin-bottom": "2.5rem",
        "components-pull-quote-spacing-small-quote-space-between": "1rem",
        "components-pull-quote-spacing-small-detail-space-between": "1rem",
        "components-pull-quote-spacing-small-quote-minheight": "15rem",
        "components-promo-alternate-minheight": "30rem",
        "components-promo-alternate-spacing-large-padding-x": "5rem",
        "components-promo-alternate-spacing-large-padding-y": "5rem",
        "components-promo-alternate-spacing-large-content-space-between": "5rem",
        "components-promo-alternate-spacing-large-padding-content-x": "0rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-x": "0rem",
        "components-promo-alternate-spacing-large-padding-content-y": "0rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-y": "0rem",
        "components-promo-alternate-spacing-large-padding-image-x": "0rem",
        "components-promo-alternate-spacing-large-padding-image-y": "0rem",
        "components-promo-alternate-spacing-large-padding-image-top": "5rem",
        "components-promo-alternate-spacing-large-title-margin-bottom": "2rem",
        "components-promo-alternate-spacing-small-padding-x": "0rem",
        "components-promo-alternate-spacing-small-padding-y": "0rem",
        "components-promo-alternate-spacing-small-padding-content-x": "1.5rem",
        "components-promo-alternate-spacing-small-padding-content-y": "2.5rem",
        "components-promo-alternate-spacing-small-padding-image-x": "0rem",
        "components-promo-alternate-spacing-small-padding-image-y": "0rem",
        "components-promo-alternate-spacing-small-padding-image-top": "0rem",
        "components-promo-alternate-spacing-small-title-margin-bottom": "1.5rem",
        "components-share-list-spacing-padding-x": "1rem",
        "components-share-list-spacing-padding-y": "1rem",
        "components-header-copy-margin-bottom": "1rem",
        "components-header-margin-bottom": "1.5rem",
        "components-item-margin-bottom": "1.5rem",
        "components-site-search-padding-x": "2.5rem",
        "components-site-search-padding-y": "5rem",
        "components-site-search-facet-label-space-between": "0.25rem",
        "components-site-search-refine-padding-x": "1.5rem",
        "components-site-search-refine-padding-y": "0rem",
        "components-site-search-hero-search-height": "18.5rem",
        "components-site-search-hero-search-large-padding-x": "5rem",
        "components-site-search-hero-search-large-padding-y": "5rem",
        "components-stats-spacing-stat-text-space-between": "0.5rem",
        "components-stats-spacing-large-inner-padding-x": "5rem",
        "components-stats-spacing-large-inner-padding-y": "5rem",
        "components-stats-spacing-small-inner-padding-x": "1.5rem",
        "components-stats-spacing-small-inner-padding-y": "1.5rem",
        "components-icon-card-listing-spacing-small-top-space-between": "1rem",
        "components-supporting-text-padding-x": "0rem",
        "components-supporting-text-padding-bottom": "0.5rem",
        "components-tab-item-padding-y": "0.75rem",
        "components-tab-item-padding-x": "0.75rem",
        "components-tab-item-spacing-x": "0.75rem",
        "components-tab-input-padding-y": "0.25rem",
        "components-tab-input-padding-x": "1rem",
        "components-tag-spacing-padding-x": "0.5rem",
        "components-tag-spacing-padding-y": "0.5rem",
        "components-tag-spacing-space-between": "0.5rem",
        "components-text-field-input-padding-y": "0.25rem",
        "components-text-field-input-padding-x": "1rem",
        "components-text-field-area-input-padding-y": "1rem",
        "components-text-field-area-input-padding-x": "1rem",
        "components-text-field-supporting-text-padding-x": "0rem",
        "components-text-field-supporting-text-padding-top": "0.5rem",
        "themes-general-surface-spacing-large-padding-x": "5rem",
        "themes-general-surface-spacing-large-padding-y": "5rem",
        "themes-general-surface-spacing-large-title-margin-bottom": "2rem",
        "themes-general-surface-spacing-large-body-margin-right": "4rem",
        "themes-general-surface-spacing-large-body-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-large-margin-bottom": "4rem",
        "themes-general-surface-spacing-large-cards-space-between": "1.5rem",
        "themes-general-surface-spacing-large-button-margin-top": "2.5rem",
        "themes-general-surface-spacing-small-padding-x": "1.5rem",
        "themes-general-surface-spacing-small-padding-y": "2.5rem",
        "themes-general-surface-spacing-small-title-margin-bottom": "1rem",
        "themes-general-surface-spacing-small-body-margin-right": "2.5rem",
        "themes-general-surface-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-surface-spacing-small-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-small-cards-space-between": "1rem",
        "themes-general-surface-spacing-small-button-margin-top": "1.5rem",
        "themes-general-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "themes-general-card-spacing-large-title-margin-bottom": "1.5rem",
        "themes-general-card-spacing-large-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-large-button-space-between": "1.5rem",
        "themes-general-card-spacing-small-eyebrow-margin-bottom": "1rem",
        "themes-general-card-spacing-small-title-margin-bottom": "1.5rem",
        "themes-general-card-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-small-button-space-between": "1.5rem",
        "themes-shadow-share-list-spacing-x": "0rem",
        "themes-shadow-share-list-spacing-y": "0.75rem",
        "themes-shadow-share-list-spacing-blur": "1rem",
        "themes-shadow-share-list-spacing-spread": "-0.5rem",
        "themes-shadow-header-menu-spacing-x": "0rem",
        "themes-shadow-header-menu-spacing-y": "0.75rem",
        "themes-shadow-header-menu-spacing-blur": "1rem",
        "themes-shadow-header-menu-spacing-spread": "-0.5rem",
        "themes-typography-rte-large-h1-textsize": "3rem",
        "themes-typography-rte-large-h2-textsize": "2.5rem",
        "themes-typography-rte-large-h3-textsize": "2.25rem",
        "themes-typography-rte-large-h4-textsize": "2rem",
        "themes-typography-rte-large-h5-textsize": "1.5rem",
        "themes-typography-rte-large-h6-textsize": "1.25rem",
        "themes-typography-rte-medium-h1-textsize": "2.5rem",
        "themes-typography-rte-medium-h2-textsize": "2.25rem",
        "themes-typography-rte-medium-h3-textsize": "1.75rem",
        "themes-typography-rte-medium-h4-textsize": "1.5rem",
        "themes-typography-rte-medium-h5-textsize": "1.25rem",
        "themes-typography-rte-medium-h6-textsize": "1.125rem",
        "themes-typography-rte-small-h1-textsize": "2.5rem",
        "themes-typography-rte-small-h2-textsize": "2rem",
        "themes-typography-rte-small-h3-textsize": "1.75rem",
        "themes-typography-rte-small-h4-textsize": "1.5rem",
        "themes-typography-rte-small-h5-textsize": "1.125rem",
        "themes-typography-rte-small-h6-textsize": "1rem",
        "spacing-margin-small-1": "0.125rem",
        "spacing-margin-small-2": "0.25rem",
        "spacing-margin-small-3": "0.5rem",
        "spacing-margin-small-4": "0.75rem",
        "spacing-margin-small-5": "1rem",
        "spacing-margin-small-6": "1.5rem",
        "spacing-margin-small-7": "2.5rem",
        "spacing-margin-medium-2": "0.75rem",
        "spacing-margin-medium-4": "1.5rem",
        "spacing-margin-medium-5": "2rem",
        "spacing-margin-large-1": "0.5rem",
        "spacing-margin-large-2": "1rem",
        "spacing-margin-large-3": "1.5rem",
        "spacing-margin-large-4": "2rem",
        "spacing-margin-large-5": "2.5rem",
        "spacing-margin-large-6": "4rem",
        "spacing-margin-large-7": "5rem",
        "spacing-space-between-small-1": "0.125rem",
        "spacing-space-between-small-2": "0.25rem",
        "spacing-space-between-small-3": "0.5rem",
        "spacing-space-between-small-4": "0.75rem",
        "spacing-space-between-small-5": "1rem",
        "spacing-space-between-small-6": "1.5rem",
        "spacing-space-between-small-7": "2.5rem",
        "spacing-space-between-medium-1": "0.125rem",
        "spacing-space-between-medium-2": "0.25rem",
        "spacing-space-between-medium-3": "0.5rem",
        "spacing-space-between-medium-4": "0.75rem",
        "spacing-space-between-medium-5": "1rem",
        "spacing-space-between-large-1": "0.25rem",
        "spacing-space-between-large-2": "0.5rem",
        "spacing-space-between-large-3": "1rem",
        "spacing-space-between-large-4": "1.5rem",
        "spacing-space-between-large-5": "1.5rem",
        "spacing-space-between-large-6": "2.5rem",
        "spacing-space-between-large-7": "4rem",
        "spacing-padding-small-1": "0.125rem",
        "spacing-padding-small-2": "0.25rem",
        "spacing-padding-small-3": "0.5rem",
        "spacing-padding-small-4": "0.75rem",
        "spacing-padding-small-5": "1rem",
        "spacing-padding-small-6": "1.5rem",
        "spacing-padding-small-7": "2.5rem",
        "spacing-padding-medium-1": "0.25rem",
        "spacing-padding-medium-2": "0.75rem",
        "spacing-padding-medium-3": "1rem",
        "spacing-padding-medium-4": "1.5rem",
        "spacing-padding-medium-5": "2rem",
        "spacing-padding-medium-6": "3rem",
        "spacing-padding-medium-7": "4rem",
        "spacing-padding-large-1": "0.5rem",
        "spacing-padding-large-2": "1rem",
        "spacing-padding-large-3": "1.5rem",
        "spacing-padding-large-4": "2rem",
        "spacing-padding-large-5": "2.5rem",
        "spacing-padding-large-6": "4rem",
        "spacing-padding-large-7": "5rem"
      },
      "string": {
        "components-accordion-section-button": "var(--brand)",
        "components-article-card-listing-button-theme": "var(--brand)",
        "components-article-hero-pagination-buttons": "var(--brand)",
        "components-banner-banner-button": "var(--white)",
        "components-category-card-listing-button-theme": "var(--brand)",
        "components-contact-us-promo-hierarchy-button-theme-1": "var(--filled)",
        "components-contact-us-promo-hierarchy-button-theme-2": "var(--outlined)",
        "components-contact-us-promo-color-button-theme": "var(--brand)",
        "components-contact-us-promo-color-brand-button-theme": "var(--white)",
        "components-generic-card-button-theme": "var(--brand)",
        "components-global-search-loader": "var(--default)",
        "components-generic-card-listing-decor-top": "var(--raid-1)",
        "components-generic-card-listing-decor-bottom": "var(--raid-2)",
        "components-hero-homepage-pagination-buttons": "var(--tonal)",
        "components-hero-homepage-pattern": "var(--drano)",
        "components-hero-homepage-button-1": "var(--brand)",
        "components-hero-homepage-button-2": "var(--brand)",
        "components-hero-homepage-image-ratio": "var(--5:4)",
        "components-hero-internal-theme-button-one-type": "var(--filled)",
        "components-hero-internal-theme-button-two-type": "var(--outlined)",
        "components-hero-internal-theme-button-color": "var(--brand)",
        "components-hero-internal-theme-button-color-with-image": "var(--brand)",
        "components-lightbox-button-type": "var(--brand)",
        "components-pdf-link-card-hierarchy-button-theme-1": "var(--filled)",
        "components-pdf-link-card-hierarchy-button-theme-2": "var(--outlined)",
        "components-pdf-link-card-color-button-theme": "var(--brand)",
        "components-product-card-badge-align": "var(--left)",
        "components-product-card-listing-button-color": "var(--brand)",
        "components-promo-theme-button-one-theme": "var(--filled)",
        "components-promo-theme-button-two-theme": "var(--outlined)",
        "components-promo-color-default-button-theme": "var(--brand)",
        "components-promo-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-color-brand-button-theme": "var(--white)",
        "components-pull-quote-border-decor": "var(--glade)",
        "components-promo-alternate-theme-button-one-theme": "var(--filled)",
        "components-promo-alternate-theme-button-two-theme": "var(--outlined)",
        "components-promo-alternate-color-default-button-theme": "var(--brand)",
        "components-promo-alternate-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-alternate-color-brand-button-theme": "var(--white)",
        "components-share-list-button-color": "var(--brand)",
        "components-site-search-hero-search-hero-pattern": "var(--drano)",
        "components-stats-decor-top": "var(--raid-1)",
        "components-stats-decor-bottom": "var(--raid-2)",
        "themes-brand-loader": "var(--default)",
        "themes-brand-variants": "var(--drano)",
        "themes-tab-variant": "var(--default)",
        "themes-label-variants": "var(--default)",
        "themes-logo": "var(--drano)",
        "themes-category-card": "var(--drano)",
        "themes-title-decor": "var(--raid)",
        "themes-typography-heading-fontfamily": "var(--futura-pt)",
        "themes-typography-heading-large-xxlarge-fontweight": "var(--bold)",
        "themes-typography-heading-large-xlarge-fontweight": "var(--bold)",
        "themes-typography-heading-large-large-fontweight": "var(--bold)",
        "themes-typography-heading-large-medium-fontweight": "var(--bold)",
        "themes-typography-heading-large-small-fontweight": "var(--bold)",
        "themes-typography-heading-large-xsmall-fontweight": "var(--bold)",
        "themes-typography-heading-large-xxsmall-fontweight": "var(--bold)",
        "themes-typography-heading-medium-xxlarge-fontweight": "var(--bold)",
        "themes-typography-heading-medium-xlarge-fontweight": "var(--bold)",
        "themes-typography-heading-medium-large-fontweight": "var(--bold)",
        "themes-typography-heading-medium-medium-fontweight": "var(--bold)",
        "themes-typography-heading-medium-small-fontweight": "var(--bold)",
        "themes-typography-heading-medium-xsmall-fontweight": "var(--bold)",
        "themes-typography-heading-medium-xxsmall-fontweight": "var(--bold)",
        "themes-typography-heading-small-xxlarge-fontweight": "var(--bold)",
        "themes-typography-heading-small-xlarge-fontweight": "var(--bold)",
        "themes-typography-heading-small-large-fontweight": "var(--bold)",
        "themes-typography-heading-small-medium-fontweight": "var(--bold)",
        "themes-typography-heading-small-small-fontweight": "var(--bold)",
        "themes-typography-heading-small-xsmall-fontweight": "var(--bold)",
        "themes-typography-heading-small-xxsmall-fontweight": "var(--black)",
        "themes-typography-rte-fontfamily": "var(--futura-pt)",
        "themes-typography-rte-fontweight": "var(--bold)"
      },
      "borderRadius": {
        "components-accordion-spacing-large-item-item-radius": "0rem",
        "components-article-card-spacing-large-image-radius-tl": "1rem",
        "components-article-card-spacing-large-image-radius-tr": "1rem",
        "components-article-card-spacing-large-image-radius-bl": "1rem",
        "components-article-card-spacing-large-image-radius-br": "1rem",
        "components-article-card-spacing-large-list-image-radius-br": "1rem",
        "components-article-card-spacing-small-image-radius-br": "1rem",
        "components-article-card-spacing-small-list-image-radius-br": "1rem",
        "components-article-hero-spacing-large-content-radius": "1rem",
        "components-article-hero-spacing-small-content-radius": "1rem",
        "components-button-radius-large": "7.5rem",
        "components-button-group-spacing-border-radius": "7.5rem",
        "components-category-card-radius-border-width": "0.0625rem",
        "components-category-card-radius-border-radius": "7.5rem",
        "components-category-card-radius-border-radius-condensed": "0.5rem",
        "components-category-card-radius-media-radius": "0rem",
        "components-content-list-icon-radius": "7.5rem",
        "components-fab-spacing-border-radius": "7.5rem",
        "components-header-spacing-large-search-border-radius-top": "7.5rem",
        "components-header-spacing-large-search-border-radius-bottom": "7.5rem",
        "components-header-spacing-large-language-selector-radius": "0.25rem",
        "components-header-spacing-large-dropdown-radius": "0.5rem",
        "components-hero-homepage-spacing-large-container-radius": "1rem",
        "components-hero-internal-spacing-large-icon-radius": "7.5rem",
        "components-hero-internal-spacing-small-icon-radius": "7.5rem",
        "components-lightbox-spacing-large-overlay-radius": "1rem",
        "components-pagination-scrollbar-radius": "62.5rem",
        "components-product-card-spacing-image-radius-tl": "1rem",
        "components-product-card-spacing-image-radius-tr": "1rem",
        "components-product-card-spacing-image-radius-bl": "1rem",
        "components-product-card-spacing-image-radius-br": "1rem",
        "components-product-information-spacing-large-content-radius": "1rem",
        "components-product-information-spacing-small-content-radius": "1rem",
        "components-promo-spacing-large-content-radius": "1rem",
        "components-promo-spacing-large-content-radius-tl": "0rem",
        "components-promo-spacing-large-content-radius-tr": "1rem",
        "components-promo-spacing-large-content-radius-bl": "0rem",
        "components-promo-spacing-large-content-radius-br": "1rem",
        "components-promo-spacing-large-image-radius-tl": "0rem",
        "components-promo-spacing-large-image-radius-tr": "1rem",
        "components-promo-spacing-large-image-radius-bl": "0rem",
        "components-promo-spacing-large-image-radius-br": "1rem",
        "components-promo-spacing-small-content-radius": "0rem",
        "components-promo-spacing-small-image-radius-tl": "0rem",
        "components-promo-spacing-small-image-radius-tr": "0rem",
        "components-promo-spacing-small-image-radius-bl": "0rem",
        "components-promo-spacing-small-image-radius-br": "0rem",
        "components-promo-alternate-spacing-large-content-radius": "0rem",
        "components-promo-alternate-spacing-large-image-radius-tl": "0rem",
        "components-promo-alternate-spacing-large-image-radius-tr": "0rem",
        "components-promo-alternate-spacing-large-image-radius-bl": "0rem",
        "components-promo-alternate-spacing-large-image-radius-br": "0rem",
        "components-promo-alternate-spacing-small-content-radius": "0rem",
        "components-promo-alternate-spacing-small-image-radius-tl": "0rem",
        "components-promo-alternate-spacing-small-image-radius-tr": "0rem",
        "components-promo-alternate-spacing-small-image-radius-bl": "0rem",
        "components-promo-alternate-spacing-small-image-radius-br": "0rem",
        "components-share-list-spacing-radius": "0.5rem",
        "components-share-list-spacing-link-radius": "0rem",
        "components-site-search-filter-radius": "0rem",
        "components-site-search-facet-radius": "0rem",
        "components-site-search-refine-radius": "0rem",
        "components-tag-spacing-radius": "7.5rem",
        "components-tip-spacing-radius": "1rem",
        "themes-spacing-radius-pagination-bar": "62.5rem",
        "themes-spacing-radius-large-button": "7.5rem",
        "themes-spacing-radius-large-button-icon": "7.5rem",
        "themes-spacing-radius-large-image": "1rem",
        "themes-spacing-radius-large-image-hero": "0rem",
        "themes-spacing-radius-large-image-card": "0.75rem",
        "themes-spacing-radius-large-card": "1rem",
        "themes-spacing-radius-large-icon": "7.5rem",
        "themes-spacing-radius-large-label": "7.5rem",
        "themes-spacing-radius-large-tag": "7.5rem",
        "themes-spacing-radius-large-container-tip": "1rem",
        "themes-spacing-radius-large-badge": "0rem",
        "themes-spacing-radius-large-item": "0rem",
        "themes-spacing-radius-large-form-field-input": "7.5rem",
        "themes-spacing-radius-large-form-area-input": "0.5rem",
        "themes-spacing-radius-large-general": "1rem",
        "themes-spacing-radius-small-button": "7.5rem",
        "themes-spacing-radius-small-button-icon": "7.5rem",
        "themes-spacing-radius-small-image": "0.75rem",
        "themes-spacing-radius-small-image-card": "0.75rem",
        "themes-spacing-radius-small-card": "0.75rem",
        "themes-spacing-radius-small-icon": "7.5rem",
        "themes-spacing-radius-small-label": "7.5rem",
        "themes-spacing-radius-small-tag": "7.5rem",
        "themes-spacing-radius-small-badge": "0rem",
        "themes-spacing-radius-small-item": "0rem",
        "themes-spacing-radius-small-form-field-input": "0rem",
        "themes-spacing-radius-small-general": "1rem"
      },
      "borderWidth": {
        "3": "3px",
        "components-article-card-spacing-large-border-width": "0rem",
        "components-generic-card-color-border-width": "0.0625rem",
        "components-header-spacing-large-search-border-width-top": "0.125rem",
        "components-header-spacing-large-search-border-width-right": "0.125rem",
        "components-header-spacing-large-search-border-width-bottom": "0.125rem",
        "components-header-spacing-large-search-border-width-left": "0.125rem",
        "components-product-card-spacing-border-width": "0.0625rem"
      },
      "maxHeight": {},
      "maxWidth": {
        "components-article-hero-spacing-large-header-max-width": "66.375rem",
        "components-content-list-spacing-large-item-image-max-width": "33.75rem",
        "components-content-list-spacing-small-item-image-max-width": "33.75rem"
      },
      "minHeight": {
        "components-dropdown-item-min-height": "3rem",
        "components-icon-min-height": "3rem",
        "components-tab-item-min-height": "3rem",
        "components-text-field-input-min-height": "3rem",
        "components-text-field-icon-min-height": "3rem"
      },
      "minWidth": {
        "components-content-list-spacing-small-item-image-min-width": "18.75rem",
        "components-icon-min-width": "3rem",
        "components-text-field-icon-min-width": "3rem"
      },
      "opacity": {
        "components-accessibility-bar-color-bg": 1,
        "components-accessibility-bar-color-text": 1,
        "components-accordion-color-bg": 1,
        "components-accordion-color-title": 1,
        "components-accordion-color-body": 1,
        "components-accordion-color-section-section-bg": 1,
        "components-accordion-color-section-section-title": 1,
        "components-accordion-color-section-section-body": 1,
        "components-accordion-color-item-item-bg": 1,
        "components-accordion-color-item-divider": 1,
        "components-accordion-color-item-icon-icon-default": 1,
        "components-accordion-color-item-icon-icon-hover": 1,
        "components-accordion-color-item-icon-icon-pressed": 1,
        "components-accordion-color-item-icon-icon-active": 1,
        "components-accordion-color-item-title-item-default": 1,
        "components-accordion-color-item-title-item-hover": 1,
        "components-accordion-color-item-title-item-pressed": 1,
        "components-accordion-color-item-title-item-active": 1,
        "components-accordion-color-content-bg": 1,
        "components-accordion-color-content-title": 1,
        "components-accordion-color-content-body": 1,
        "components-article-card-color-bg": 0.02,
        "components-article-card-color-border": 0.02,
        "components-article-card-color-content-bg": 0.02,
        "components-article-card-color-title": 1,
        "components-article-card-color-subtitle": 1,
        "components-article-card-color-copy": 1,
        "components-article-card-color-description": 1,
        "components-article-card-listing-color-default-bg": 1,
        "components-article-card-listing-color-default-title": 1,
        "components-article-card-listing-color-default-copy": 1,
        "components-article-hero-color-content-bg": 1,
        "components-article-hero-color-bg": 1,
        "components-article-hero-color-headline": 1,
        "components-article-hero-color-body": 1,
        "components-article-hero-color-detail-text": 1,
        "components-article-hero-color-divider": 1,
        "components-article-hero-color-icon": 1,
        "components-article-hero-color-play-icon": 1,
        "components-article-hero-color-selected-stroke": 1,
        "components-banner-color-bg-default": 1,
        "components-banner-color-icon-default": 1,
        "components-banner-color-title-default": 1,
        "components-banner-color-body-default": 1,
        "components-banner-color-icon-close-default": 1,
        "components-banner-color-bg-alert": 1,
        "components-banner-color-icon-alert": 1,
        "components-banner-color-title-alert": 1,
        "components-banner-color-body-alert": 1,
        "components-banner-color-icon-close-alert": 1,
        "components-breadcrumb-color-bg": 1,
        "components-breadcrumb-color-breadcrumb-link-default": 1,
        "components-breadcrumb-color-breadcrumb-link-hover": 1,
        "components-breadcrumb-color-breadcrumb-link-active": 1,
        "components-breadcrumb-color-icon-divider": 1,
        "components-bug-finder-prompt": 1,
        "components-button-color-filled-brand-default-bg": 1,
        "components-button-color-filled-brand-default-text": 1,
        "components-button-color-filled-brand-default-stroke": 1,
        "components-button-color-filled-brand-default-icon": 1,
        "components-button-color-filled-brand-hover-bg": 1,
        "components-button-color-filled-brand-hover-text": 1,
        "components-button-color-filled-brand-hover-stroke": 1,
        "components-button-color-filled-brand-hover-icon": 1,
        "components-button-color-filled-brand-pressed-bg": 1,
        "components-button-color-filled-brand-pressed-text": 1,
        "components-button-color-filled-brand-pressed-stroke": 1,
        "components-button-color-filled-brand-pressed-icon": 1,
        "components-button-color-filled-brand-disabled-bg": 0.03,
        "components-button-color-filled-brand-disabled-text": 0.02,
        "components-button-color-filled-brand-disabled-stroke": 0.03,
        "components-button-color-filled-brand-disabled-icon": 0.02,
        "components-button-color-filled-brand-focus-bg": 1,
        "components-button-color-filled-brand-focus-text": 1,
        "components-button-color-filled-brand-focus-stroke": 1,
        "components-button-color-filled-brand-focus-icon": 1,
        "components-button-color-filled-brand-focus-outline": 1,
        "components-button-color-filled-tonal-default-bg": 1,
        "components-button-color-filled-tonal-default-text": 1,
        "components-button-color-filled-tonal-default-stroke": 1,
        "components-button-color-filled-tonal-default-icon": 1,
        "components-button-color-filled-tonal-hover-bg": 1,
        "components-button-color-filled-tonal-hover-text": 1,
        "components-button-color-filled-tonal-hover-stroke": 1,
        "components-button-color-filled-tonal-hover-icon": 1,
        "components-button-color-filled-tonal-pressed-bg": 1,
        "components-button-color-filled-tonal-pressed-text": 1,
        "components-button-color-filled-tonal-pressed-stroke": 1,
        "components-button-color-filled-tonal-pressed-icon": 1,
        "components-button-color-filled-tonal-disabled-bg": 0,
        "components-button-color-filled-tonal-disabled-text": 0.03,
        "components-button-color-filled-tonal-disabled-stroke": 0,
        "components-button-color-filled-tonal-disabled-icon": 0.03,
        "components-button-color-filled-tonal-focus-bg": 1,
        "components-button-color-filled-tonal-focus-text": 1,
        "components-button-color-filled-tonal-focus-stroke": 1,
        "components-button-color-filled-tonal-focus-icon": 1,
        "components-button-color-filled-tonal-focus-outline": 1,
        "components-button-color-filled-white-default-bg": 1,
        "components-button-color-filled-white-default-text": 1,
        "components-button-color-filled-white-default-stroke": 1,
        "components-button-color-filled-white-default-icon": 1,
        "components-button-color-filled-white-hover-bg": 1,
        "components-button-color-filled-white-hover-text": 1,
        "components-button-color-filled-white-hover-stroke": 1,
        "components-button-color-filled-white-hover-icon": 1,
        "components-button-color-filled-white-pressed-bg": 1,
        "components-button-color-filled-white-pressed-text": 1,
        "components-button-color-filled-white-pressed-stroke": 1,
        "components-button-color-filled-white-pressed-icon": 1,
        "components-button-color-filled-white-disabled-bg": 0.02,
        "components-button-color-filled-white-disabled-text": 0.03,
        "components-button-color-filled-white-disabled-stroke": 0.02,
        "components-button-color-filled-white-disabled-icon": 0.03,
        "components-button-color-filled-white-focus-bg": 1,
        "components-button-color-filled-white-focus-text": 1,
        "components-button-color-filled-white-focus-stroke": 1,
        "components-button-color-filled-white-focus-icon": 1,
        "components-button-color-filled-white-focus-outline": 1,
        "components-button-color-outline-brand-default-bg": 0.02,
        "components-button-color-outline-brand-default-text": 1,
        "components-button-color-outline-brand-default-stroke": 1,
        "components-button-color-outline-brand-default-icon": 1,
        "components-button-color-outline-brand-hover-bg": 1,
        "components-button-color-outline-brand-hover-text": 1,
        "components-button-color-outline-brand-hover-stroke": 1,
        "components-button-color-outline-brand-hover-icon": 1,
        "components-button-color-outline-brand-pressed-bg": 1,
        "components-button-color-outline-brand-pressed-text": 1,
        "components-button-color-outline-brand-pressed-stroke": 1,
        "components-button-color-outline-brand-pressed-icon": 1,
        "components-button-color-outline-brand-disabled-bg": 0.02,
        "components-button-color-outline-brand-disabled-text": 0.03,
        "components-button-color-outline-brand-disabled-stroke": 0.03,
        "components-button-color-outline-brand-disabled-icon": 0.03,
        "components-button-color-outline-brand-focus-bg": 1,
        "components-button-color-outline-brand-focus-text": 1,
        "components-button-color-outline-brand-focus-stroke": 1,
        "components-button-color-outline-brand-focus-icon": 1,
        "components-button-color-outline-brand-focus-outline": 1,
        "components-button-color-outline-tonal-default-bg": null,
        "components-button-color-outline-tonal-default-text": 1,
        "components-button-color-outline-tonal-default-stroke": 1,
        "components-button-color-outline-tonal-default-icon": 1,
        "components-button-color-outline-tonal-hover-bg": 1,
        "components-button-color-outline-tonal-hover-text": 1,
        "components-button-color-outline-tonal-hover-stroke": 1,
        "components-button-color-outline-tonal-hover-icon": 1,
        "components-button-color-outline-tonal-pressed-bg": 1,
        "components-button-color-outline-tonal-pressed-text": 1,
        "components-button-color-outline-tonal-pressed-stroke": 1,
        "components-button-color-outline-tonal-pressed-icon": 1,
        "components-button-color-outline-tonal-disabled-bg": 0.02,
        "components-button-color-outline-tonal-disabled-text": 0.03,
        "components-button-color-outline-tonal-disabled-stroke": 0,
        "components-button-color-outline-tonal-disabled-icon": 0.03,
        "components-button-color-outline-tonal-focus-bg": null,
        "components-button-color-outline-tonal-focus-text": 1,
        "components-button-color-outline-tonal-focus-stroke": 1,
        "components-button-color-outline-tonal-focus-icon": 1,
        "components-button-color-outline-tonal-focus-outline": 1,
        "components-button-color-outline-white-default-bg": 0.02,
        "components-button-color-outline-white-default-text": 1,
        "components-button-color-outline-white-default-stroke": 1,
        "components-button-color-outline-white-default-icon": 1,
        "components-button-color-outline-white-hover-bg": 1,
        "components-button-color-outline-white-hover-text": 1,
        "components-button-color-outline-white-hover-stroke": 1,
        "components-button-color-outline-white-hover-icon": 1,
        "components-button-color-outline-white-pressed-bg": 1,
        "components-button-color-outline-white-pressed-text": 1,
        "components-button-color-outline-white-pressed-stroke": 1,
        "components-button-color-outline-white-pressed-icon": 1,
        "components-button-color-outline-white-disabled-bg": 0.02,
        "components-button-color-outline-white-disabled-text": 0.02,
        "components-button-color-outline-white-disabled-stroke": 0.02,
        "components-button-color-outline-white-disabled-icon": 0.02,
        "components-button-color-outline-white-focus-bg": 0.02,
        "components-button-color-outline-white-focus-text": 1,
        "components-button-color-outline-white-focus-stroke": 1,
        "components-button-color-outline-white-focus-icon": 1,
        "components-button-color-outline-white-focus-outline": 1,
        "components-button-color-text-brand-default-bg": 0.02,
        "components-button-color-text-brand-default-text": 1,
        "components-button-color-text-brand-default-stroke": 1,
        "components-button-color-text-brand-default-icon": 1,
        "components-button-color-text-brand-hover-bg": 0.02,
        "components-button-color-text-brand-hover-bg-icon-only": 1,
        "components-button-color-text-brand-hover-text": 1,
        "components-button-color-text-brand-hover-stroke": 1,
        "components-button-color-text-brand-hover-icon": 1,
        "components-button-color-text-brand-pressed-bg": 0.02,
        "components-button-color-text-brand-pressed-bg-icon-only": 1,
        "components-button-color-text-brand-pressed-text": 1,
        "components-button-color-text-brand-pressed-stroke": 1,
        "components-button-color-text-brand-pressed-icon": 1,
        "components-button-color-text-brand-disabled-bg": 0.02,
        "components-button-color-text-brand-disabled-text": 0.03,
        "components-button-color-text-brand-disabled-stroke": 0.03,
        "components-button-color-text-brand-disabled-icon": 0.03,
        "components-button-color-text-brand-focus-bg": 1,
        "components-button-color-text-brand-focus-text": 1,
        "components-button-color-text-brand-focus-stroke": 1,
        "components-button-color-text-brand-focus-icon": 1,
        "components-button-color-text-brand-focus-outline": 1,
        "components-button-color-text-tonal-default-bg": 0.02,
        "components-button-color-text-tonal-default-text": 1,
        "components-button-color-text-tonal-default-stroke": 1,
        "components-button-color-text-tonal-default-icon": 1,
        "components-button-color-text-tonal-hover-bg": 0.02,
        "components-button-color-text-tonal-hover-bg-icon-only": 1,
        "components-button-color-text-tonal-hover-text": 1,
        "components-button-color-text-tonal-hover-stroke": 1,
        "components-button-color-text-tonal-hover-icon": 1,
        "components-button-color-text-tonal-pressed-bg": 0.02,
        "components-button-color-text-tonal-pressed-bg-icon-only": 1,
        "components-button-color-text-tonal-pressed-text": 1,
        "components-button-color-text-tonal-pressed-stroke": 1,
        "components-button-color-text-tonal-pressed-icon": 1,
        "components-button-color-text-tonal-disabled-bg": 0.02,
        "components-button-color-text-tonal-disabled-text": 0.03,
        "components-button-color-text-tonal-disabled-stroke": 0.03,
        "components-button-color-text-tonal-disabled-icon": 0.03,
        "components-button-color-text-tonal-focus-bg": 1,
        "components-button-color-text-tonal-focus-text": 1,
        "components-button-color-text-tonal-focus-stroke": 1,
        "components-button-color-text-tonal-focus-icon": 1,
        "components-button-color-text-tonal-focus-outline": 1,
        "components-button-color-text-white-default-bg": 0.02,
        "components-button-color-text-white-default-text": 1,
        "components-button-color-text-white-default-stroke": 1,
        "components-button-color-text-white-default-icon": 1,
        "components-button-color-text-white-hover-bg": 0.02,
        "components-button-color-text-white-hover-bg-icon-only": 1,
        "components-button-color-text-white-hover-text": 1,
        "components-button-color-text-white-hover-stroke": 1,
        "components-button-color-text-white-hover-icon": 1,
        "components-button-color-text-white-pressed-bg": 0.02,
        "components-button-color-text-white-pressed-bg-icon-only": 1,
        "components-button-color-text-white-pressed-text": 1,
        "components-button-color-text-white-pressed-stroke": 1,
        "components-button-color-text-white-pressed-icon": 1,
        "components-button-color-text-white-disabled-bg": 0.02,
        "components-button-color-text-white-disabled-text": 0.01,
        "components-button-color-text-white-disabled-stroke": 0.01,
        "components-button-color-text-white-disabled-icon": 0.01,
        "components-button-color-text-white-focus-bg": 1,
        "components-button-color-text-white-focus-text": 1,
        "components-button-color-text-white-focus-stroke": 1,
        "components-button-color-text-white-focus-icon": 1,
        "components-button-color-text-white-focus-outline": 1,
        "components-button-group-color-border": 1,
        "components-button-group-color-button-bg-default": 0.02,
        "components-button-group-color-button-fg-default": 1,
        "components-button-group-color-button-bg-hover": 0.03,
        "components-button-group-color-button-fg-hover": 1,
        "components-button-group-color-button-bg-active": 1,
        "components-button-group-color-button-fg-active": 1,
        "components-button-group-color-overlay-left-gradient-fill-1": 1,
        "components-button-group-color-overlay-left-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-1": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-3": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-4": 0.02,
        "components-button-group-color-stroke-hover-gradient-fill-1": null,
        "components-button-group-color-stroke-hover-gradient-fill-2": null,
        "components-button-group-color-stroke-hover-gradient-fill-3": null,
        "components-button-group-color-stroke-hover-gradient-fill-4": null,
        "components-button-group-color-stroke-active-gradient-fill-1": 1,
        "components-button-group-color-stroke-active-gradient-fill-2": 1,
        "components-button-group-color-stroke-active-gradient-fill-3": 1,
        "components-button-group-color-stroke-active-gradient-fill-4": 1,
        "components-category-card-color-bg": 1,
        "components-category-card-color-content-bg": 1,
        "components-category-card-color-category-bg": 1,
        "components-category-card-color-category-bg-2": 1,
        "components-category-card-color-category-bg-3": 1,
        "components-category-card-color-category-bg-4": 1,
        "components-category-card-color-category-bg-5": 1,
        "components-category-card-color-category-bg-6": 1,
        "components-category-card-color-title": 1,
        "components-category-card-color-copy": 1,
        "components-category-card-color-stroke": 1,
        "components-category-card-color-stroke-hover": 1,
        "components-category-card-listing-color-bg": 1,
        "components-category-card-listing-color-title": 1,
        "components-category-card-listing-color-copy": 1,
        "components-card-search-result-color-bg": 1,
        "components-card-search-result-color-label": 1,
        "components-card-search-result-color-title": 1,
        "components-card-search-result-color-body": 1,
        "components-card-search-result-color-divider-line": 1,
        "components-contact-us-promo-color-light-bg": 1,
        "components-contact-us-promo-color-light-title": 1,
        "components-contact-us-promo-color-light-body": 1,
        "components-contact-us-promo-color-default-bg": 1,
        "components-contact-us-promo-color-default-title": 1,
        "components-contact-us-promo-color-default-body": 1,
        "components-contact-us-promo-color-brand-bg": 1,
        "components-contact-us-promo-color-brand-title": 1,
        "components-contact-us-promo-color-brand-body": 1,
        "components-content-list-color-bg": 1,
        "components-content-list-color-title": 1,
        "components-content-list-color-body": 1,
        "components-content-list-color-icon": 1,
        "components-cookies-colors-bg": 1,
        "components-cookies-colors-title": 1,
        "components-cookies-colors-body": 1,
        "components-cookies-colors-icon": 1,
        "components-dropdown-bg": 1,
        "components-dropdown-border-default": 1,
        "components-dropdown-border-hover": 1,
        "components-dropdown-border-focused": 1,
        "components-dropdown-border-destructive": 1,
        "components-dropdown-label-text-default": 1,
        "components-dropdown-item-text-default": 1,
        "components-dropdown-item-bg-default": 1,
        "components-dropdown-item-bg-hover": 1,
        "components-dropdown-input-field-default": 1,
        "components-dropdown-input-field-focused": 1,
        "components-dropdown-input-label-focused": 1,
        "components-dropdown-input-field-destructive": 1,
        "components-external-link-card-bg": 1,
        "components-external-link-card-card-bg": 1,
        "components-external-link-card-card-title": 1,
        "components-external-link-card-card-subtitle": 1,
        "components-external-link-card-card-eyebrow": 1,
        "components-fab-color-border-default": 1,
        "components-footer-brand-color-bg": 1,
        "components-footer-brand-color-logo": 1,
        "components-footer-brand-color-title-text": 1,
        "components-footer-brand-color-description-text": 1,
        "components-footer-brand-color-footer-menu-expanded": 1,
        "components-footer-brand-color-link-default": 1,
        "components-footer-brand-color-link-hover": 1,
        "components-footer-brand-color-link-pressed": 1,
        "components-footer-brand-color-category-link-default": 1,
        "components-footer-brand-color-category-link-hover": 1,
        "components-footer-brand-color-category-link-pressed": 1,
        "components-footer-brand-color-subcategory-link-default": 1,
        "components-footer-brand-color-subcategory-link-hover": 1,
        "components-footer-brand-color-subcategory-link-pressed": 1,
        "components-footer-brand-color-icon-icon-default": 1,
        "components-footer-brand-color-icon-icon-pressed": 1,
        "components-footer-brand-color-social-icon-default": 1,
        "components-footer-brand-color-social-icon-hover": 1,
        "components-footer-brand-color-social-icon-pressed": 1,
        "components-footer-brand-spacing-category-link-hover": 1,
        "components-footer-global-color-bg": 1,
        "components-footer-global-color-title-text": 1,
        "components-footer-global-color-disclaimer-text": 1,
        "components-footer-global-color-link-default": 1,
        "components-footer-global-color-link-hover": 1,
        "components-footer-global-color-link-pressed": 1,
        "components-generic-card-color-bg": 1,
        "components-generic-card-color-content-bg": 1,
        "components-generic-card-color-title": 1,
        "components-generic-card-color-subtitle": 1,
        "components-generic-card-color-copy": 1,
        "components-global-search-color-omnibox-list-bg": 1,
        "components-global-search-color-omnibox-divider": 1,
        "components-global-search-color-omnibox-item-bg-default": 1,
        "components-global-search-color-omnibox-item-bg-hover": 1,
        "components-global-search-color-omnibox-item-text-default": 1,
        "components-global-search-color-omnibox-item-text-hover": 1,
        "components-global-search-color-omnibox-label-text-default": 1,
        "components-global-search-color-omnibox-category-text-default": 1,
        "components-generic-card-listing-color-bg": 1,
        "components-generic-card-listing-color-title": 1,
        "components-generic-card-listing-color-copy": 1,
        "components-header-color-bg": 1,
        "components-header-color-bottom-stroke": 1,
        "components-header-color-search-search-border-default": 1,
        "components-header-color-search-search-text": 1,
        "components-header-color-search-search-icon": 1,
        "components-header-color-search-search-bar": 1,
        "components-header-color-mobile-mobile-expanded-bg": 1,
        "components-header-color-mobile-pressed-bg": 1,
        "components-header-color-language-selector-language": 1,
        "components-header-color-language-selector-country": 1,
        "components-header-color-language-selector-country-hover-bg": 1,
        "components-header-color-language-selector-megamenu-language": 1,
        "components-header-color-language-selector-megamenu-country": 1,
        "components-header-color-language-selector-megamenu-country-hover": 1,
        "components-header-color-language-selector-bg": 1,
        "components-header-color-language-selector-stroke": 1,
        "components-header-color-mega-menu-bg": 1,
        "components-header-color-mega-menu-featured-bg": 1,
        "components-header-color-mega-menu-featured-title": 1,
        "components-header-color-mega-menu-category-title": 1,
        "components-header-color-mega-menu-bg-accent": 1,
        "components-header-color-icon-icon-default": 1,
        "components-header-color-icon-icon-hover": 1,
        "components-header-color-icon-icon-pressed": 1,
        "components-header-color-links-nav-main-link-default": 1,
        "components-header-color-links-nav-main-link-hover": 1,
        "components-header-color-links-nav-main-link-pressed": 1,
        "components-header-color-links-nav-main-link-active": 1,
        "components-header-color-links-nav-main-active-bar": 1,
        "components-header-color-links-nav-main-bg-color": 1,
        "components-header-color-links-nav-category-link-category-default": 1,
        "components-header-color-links-nav-category-link-category-hover": 1,
        "components-header-color-links-nav-category-link-category-pressed": 1,
        "components-header-color-links-nav-category-link-category-active": 1,
        "components-header-color-links-nav-menu-link-link-default": 1,
        "components-header-color-links-nav-menu-link-link-hover": 1,
        "components-header-color-links-nav-menu-link-link-pressed": 1,
        "components-header-color-links-nav-menu-link-link-active": 1,
        "components-header-color-links-nav-menu-link-bg": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-default": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-active": 1,
        "components-hero-homepage-color-bg": 1,
        "components-hero-homepage-color-container-bg": 1,
        "components-hero-homepage-color-eyebrow": 1,
        "components-hero-homepage-color-header": 1,
        "components-hero-homepage-color-sub-header": 1,
        "components-hero-homepage-color-body": 1,
        "components-hero-homepage-color-play-pause": 1,
        "components-hero-internal-color-default-title": 1,
        "components-hero-internal-color-default-body": 1,
        "components-hero-internal-color-default-bg": 1,
        "components-hero-internal-color-accent-bg": 1,
        "components-hero-internal-color-with-image-bg": 1,
        "components-hero-internal-color-default-bg-2": 1,
        "components-hero-internal-color-with-image-title": 1,
        "components-hero-internal-color-with-image-body": 1,
        "components-heading-page-title-color-default-bg": 1,
        "components-heading-page-title-color-default-title": 1,
        "components-heading-page-title-color-default-body": 1,
        "components-heading-page-title-color-light-bg": 1,
        "components-heading-page-title-color-light-title": 1,
        "components-heading-page-title-color-light-body": 1,
        "components-heading-page-title-color-color-bg": 1,
        "components-heading-page-title-color-color-title": 1,
        "components-heading-page-title-color-color-body": 1,
        "components-hyperlink-color-dark-default": 1,
        "components-hyperlink-color-dark-hover": 1,
        "components-hyperlink-color-dark-visited": 1,
        "components-hyperlink-color-light-default": 1,
        "components-hyperlink-color-light-hover": 1,
        "components-hyperlink-color-light-visited": 1,
        "components-icon-default": 1,
        "components-icon-focused": 1,
        "components-icon-card-color-bg": 1,
        "components-icon-card-color-icon-bg": 1,
        "components-icon-card-color-icon": 1,
        "components-icon-card-color-title": 1,
        "components-icon-card-color-subtitle": 1,
        "components-icon-card-color-description": 1,
        "components-icon-card-listing-color-bg": 1,
        "components-icon-card-listing-color-inner-bg": 1,
        "components-icon-card-listing-color-brand-bg": 1,
        "components-icon-card-listing-color-title": 1,
        "components-icon-card-listing-color-copy": 1,
        "components-icon-card-listing-color-inner-legal-text": 1,
        "components-jumplink-color-bg-default": 1,
        "components-jumplink-color-link-text-default": 1,
        "components-jumplink-color-link-text-hover": 1,
        "components-jumplink-color-link-border-hover": 1,
        "components-jumplink-color-link-text-active": 1,
        "components-jumplink-color-link-border-active": 1,
        "components-jumplink-color-title-text": 1,
        "components-jumplink-color-description-text": 1,
        "components-layout-bg-default": 1,
        "components-lightbox-color-overlay-bg": 0.02,
        "components-lightbox-color-title": 1,
        "components-lightbox-color-body": 1,
        "components-label-color-on-color-label-label-default": 1,
        "components-label-color-on-color-label-label-hover": 1,
        "components-label-color-on-color-label-label-pressed": 1,
        "components-label-color-on-color-background-bg-default": 0.02,
        "components-label-color-on-color-background-bg-hover": 1,
        "components-label-color-on-color-background-bg-pressed": 1,
        "components-label-color-on-color-stroke-hover": 1,
        "components-label-color-on-color-stroke-pressed": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-4": 1,
        "components-label-color-on-white-label-label-default": 1,
        "components-label-color-on-white-label-label-hover": 1,
        "components-label-color-on-white-label-label-pressed": 1,
        "components-label-color-on-white-background-bg-default": 0.02,
        "components-label-color-on-white-background-bg-hover": 1,
        "components-label-color-on-white-background-bg-pressed": 1,
        "components-label-color-on-white-stroke-hover": 1,
        "components-label-color-on-white-stroke-pressed": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-4": 1,
        "components-legal-disclaimer-color-default-bg": 1,
        "components-legal-disclaimer-color-default-title": 1,
        "components-legal-disclaimer-color-default-body": 1,
        "components-legal-disclaimer-color-light-bg": 1,
        "components-legal-disclaimer-color-light-title": 1,
        "components-legal-disclaimer-color-light-body": 1,
        "components-legal-disclaimer-color-brand-bg": 1,
        "components-legal-disclaimer-color-brand-title": 1,
        "components-legal-disclaimer-color-brand-body": 1,
        "components-media-video-thumbnail-overlay-bg": null,
        "components-media-caption-color-bg": 1,
        "components-media-caption-color-text": 1,
        "components-media-gallery-color-bg": 1,
        "components-media-gallery-color-title": 1,
        "components-media-gallery-color-body": 1,
        "components-pagination-on-white-accent-default": 1,
        "components-pagination-on-white-accent-scroll": 1,
        "components-pagination-on-white-disabled": 1,
        "components-pagination-on-white-bg": null,
        "components-pagination-on-white-stroke": 1,
        "components-pagination-on-white-bg-active": 1,
        "components-pagination-on-white-bg-hover": 1,
        "components-pagination-on-white-text-default": 1,
        "components-pagination-on-white-text-hover": 1,
        "components-pagination-on-white-text-active": 1,
        "components-pagination-on-color-accent-default": 1,
        "components-pagination-on-color-accent-scroll": 1,
        "components-pagination-on-color-disabled": 1,
        "components-pagination-on-color-bg": null,
        "components-pagination-on-color-stroke": 1,
        "components-pagination-on-color-bg-active": 1,
        "components-pagination-on-color-bg-hover": 1,
        "components-pagination-on-color-text-default": 1,
        "components-pagination-on-color-text-hover": 1,
        "components-pagination-on-color-text-active": 1,
        "components-pdf-link-card-color-bg": 1,
        "components-pdf-link-card-color-card-bg": 1,
        "components-pdf-link-card-color-card-title": 1,
        "components-pdf-link-card-color-card-subtitle": 1,
        "components-pdf-link-card-color-card-body": 1,
        "components-product-card-color-bg": 1,
        "components-product-card-color-content-bg": 1,
        "components-product-card-color-border": 0.02,
        "components-product-card-color-title": 1,
        "components-product-card-color-description": 1,
        "components-product-card-color-star-rating": 1,
        "components-product-card-color-badge-bg": 1,
        "components-product-card-color-badge-text": 1,
        "components-product-card-listing-color-bg": 1,
        "components-product-card-listing-color-title": 1,
        "components-product-card-listing-color-copy": 1,
        "components-product-card-listing-color-legal-text": 1,
        "components-product-information-color-bg": 1,
        "components-product-information-color-content-bg": 1,
        "components-product-information-color-headline": 1,
        "components-product-information-color-body": 1,
        "components-product-information-color-detail-text": 1,
        "components-product-information-color-divider": 1,
        "components-product-information-color-icon": 1,
        "components-product-information-color-play-icon": 1,
        "components-product-information-color-selected-stroke": 1,
        "components-product-results-list-with-search-color-bg": 1,
        "components-promo-color-default-bg": 1,
        "components-promo-color-default-image-bg": 0.02,
        "components-promo-color-default-content-bg": 1,
        "components-promo-color-default-title": 1,
        "components-promo-color-default-body": 1,
        "components-promo-color-default-category": 1,
        "components-promo-color-light-bg": 1,
        "components-promo-color-light-image-bg": 0.02,
        "components-promo-color-light-content-bg": 1,
        "components-promo-color-light-title": 1,
        "components-promo-color-light-body": 1,
        "components-promo-color-light-category": 1,
        "components-promo-color-brand-bg": 1,
        "components-promo-color-brand-image-bg": 0.02,
        "components-promo-color-brand-content-bg": 1,
        "components-promo-color-brand-title": 1,
        "components-promo-color-brand-body": 1,
        "components-promo-color-brand-category": 1,
        "components-pull-quote-color-bg": 1,
        "components-pull-quote-color-stroke": 1,
        "components-pull-quote-color-quote-bg": 1,
        "components-pull-quote-color-quote-title": 1,
        "components-pull-quote-color-quote-by-text": 1,
        "components-pull-quote-color-quote-detail": 1,
        "components-pull-quote-color-quote-highlight": 1,
        "components-promo-alternate-color-default-bg": 1,
        "components-promo-alternate-color-default-image-bg": 1,
        "components-promo-alternate-color-default-content-bg": 1,
        "components-promo-alternate-color-default-title": 1,
        "components-promo-alternate-color-default-body": 1,
        "components-promo-alternate-color-default-category": 1,
        "components-promo-alternate-color-light-bg": 1,
        "components-promo-alternate-color-light-image-bg": 1,
        "components-promo-alternate-color-light-content-bg": 1,
        "components-promo-alternate-color-light-title": 1,
        "components-promo-alternate-color-light-body": 1,
        "components-promo-alternate-color-light-category": 1,
        "components-promo-alternate-color-brand-bg": 1,
        "components-promo-alternate-color-brand-image-bg": 1,
        "components-promo-alternate-color-brand-content-bg": 1,
        "components-promo-alternate-color-brand-title": 1,
        "components-promo-alternate-color-brand-body": 1,
        "components-promo-alternate-color-brand-category": 1,
        "components-share-list-color-title": 1,
        "components-share-list-color-bg": 1,
        "components-share-list-color-link-default": 1,
        "components-share-list-color-link-hover": 1,
        "components-share-list-color-bg-link-hover": 1,
        "components-share-list-color-close-icon": 1,
        "components-share-list-color-facebook-icon": 1,
        "components-share-list-color-pinterest-icon": 1,
        "components-share-list-color-twitter-icon": 1,
        "components-share-list-color-basic-icon-default": 1,
        "components-share-list-color-icon-hover": 1,
        "components-share-list-color-border": 1,
        "components-site-search-bg": 1,
        "components-site-search-results_text": 1,
        "components-site-search-filter-filter-selected-bg": 1,
        "components-site-search-facet-group-label-bottom-line": 1,
        "components-site-search-facet-group-label-text": 1,
        "components-site-search-facet-group-label-bg": 1,
        "components-site-search-facet-bg": 1,
        "components-site-search-facet-stroke": 1,
        "components-site-search-refine-bg": 1,
        "components-site-search-refine-right-divider": 1,
        "components-site-search-refine-title": 1,
        "components-site-search-hero-search-bg": 1,
        "components-site-search-hero-search-accent-bg": 1,
        "components-site-search-hero-search-title": 1,
        "components-stats-stat-text": 1,
        "components-stats-stat-text-on-light": 1,
        "components-stats-supporting-text": 1,
        "components-stats-bg-default": 1,
        "components-stats-bg-inner": 1,
        "components-stats-bg-section-inner": 1,
        "components-stats-title": 1,
        "components-stats-copy": 1,
        "components-supporting-text-default": 1,
        "components-supporting-text-destructive": 1,
        "components-tab-bg": 1,
        "components-tab-border-default": 1,
        "components-tab-border-hover": 1,
        "components-tab-border-focused": 1,
        "components-tab-border-destructive": 1,
        "components-tab-label-text-default": 1,
        "components-tab-item-text-default": 1,
        "components-tab-item-bg-default": 1,
        "components-tab-item-bg-hover": 1,
        "components-tab-input-field-default": 1,
        "components-tab-input-field-focused": 1,
        "components-tab-input-label-focused": 1,
        "components-tab-input-field-destructive": 1,
        "components-tab-bg-2": 1,
        "components-tag-color-bg": 0.02,
        "components-tag-color-text": 1,
        "components-tag-color-stroke": 0.02,
        "components-tag-color-bg-hover": 1,
        "components-tag-color-text-hover": 1,
        "components-tag-color-stroke-hover": 1,
        "components-text-field-bg": 1,
        "components-text-field-bg-focused": 1,
        "components-text-field-border-default": 1,
        "components-text-field-border-hover": 1,
        "components-text-field-border-focused": 1,
        "components-text-field-border-destructive": 1,
        "components-text-field-input-field-default": 1,
        "components-text-field-input-field-focused": 1,
        "components-text-field-input-label-focused": 1,
        "components-text-field-input-field-destructive": 1,
        "components-text-field-icon-default": 1,
        "components-text-field-icon-destructive": 1,
        "components-text-field-supporting-text-default": 1,
        "components-text-field-supporting-text-destructive": 1,
        "components-tip-color-bg": 1,
        "components-tip-color-title": 1,
        "components-tip-color-body": 1,
        "components-tip-color-stroke": 1,
        "components-video-color-bg": 1,
        "themes-general-surface-color-light-bg": 1,
        "themes-general-surface-color-light-title": 1,
        "themes-general-surface-color-light-body": 1,
        "themes-general-surface-color-light-decor-line-break": null,
        "themes-general-surface-color-light-rule-line": null,
        "themes-general-surface-color-dark-bg": 1,
        "themes-general-surface-color-dark-title": 1,
        "themes-general-surface-color-dark-body": 1,
        "themes-general-surface-color-dark-decor-line-break": null,
        "themes-general-card-color-dark-title": 1,
        "themes-general-card-color-dark-subtitle": 1,
        "themes-general-card-color-dark-body": 1,
        "themes-general-card-color-light-title": 1,
        "themes-general-card-color-light-subtitle": 1,
        "themes-general-card-color-light-body": 1,
        "themes-shadow-color": 1,
        "themes-shadow-share-list-color-fill": 0,
        "themes-shadow-header-menu-color-fill": 0,
        "themes-gradient-loader-gradient-fill-1": 1,
        "themes-gradient-loader-gradient-fill-2": 0.02,
        "themes-color-background-white": 1,
        "themes-color-background-light": 1,
        "themes-color-background-dark": 1,
        "themes-color-background-black": 1,
        "themes-color-background-brand1": 1,
        "themes-color-background-brand2": 1,
        "themes-color-background-accent1": 1,
        "themes-color-background-accent2": 1,
        "themes-color-background-body": 1,
        "themes-color-stroke-white": 1,
        "themes-color-stroke-light": 1,
        "themes-color-stroke-dark": 1,
        "themes-color-stroke-black": 1,
        "themes-color-stroke-brand1": 1,
        "themes-color-stroke-brand2": 1,
        "themes-color-stroke-accent1": 1,
        "themes-color-stroke-accent2": 1,
        "themes-color-text-white": 1,
        "themes-color-text-light": 1,
        "themes-color-text-dark": 1,
        "themes-color-text-black": 1,
        "themes-color-text-brand1": 1,
        "themes-color-text-brand2": 1,
        "themes-color-text-accent1": 1,
        "themes-color-text-accent2": 1,
        "colors-grayscale-100": 1,
        "colors-grayscale-200": 1,
        "colors-grayscale-300": 1,
        "colors-grayscale-400": 1,
        "colors-grayscale-500": 1,
        "colors-grayscale-600": 1,
        "colors-grayscale-700": 1,
        "colors-brand-1-100": 1,
        "colors-brand-1-200": 1,
        "colors-brand-1-300": 1,
        "colors-brand-1-400": 1,
        "colors-brand-1-500": 1,
        "colors-brand-1-600": 1,
        "colors-brand-1-700": 1,
        "colors-brand-2-100": 1,
        "colors-brand-2-200": 1,
        "colors-brand-2-300": 1,
        "colors-brand-2-400": 1,
        "colors-brand-2-500": 1,
        "colors-brand-2-600": 1,
        "colors-brand-2-700": 1,
        "colors-accent-1-100": 1,
        "colors-accent-1-200": 1,
        "colors-accent-1-300": 1,
        "colors-accent-1-400": 1,
        "colors-accent-1-500": 1,
        "colors-accent-1-600": 1,
        "colors-accent-1-700": 1,
        "colors-accent-2-100": 1,
        "colors-accent-2-200": 1,
        "colors-accent-2-300": 1,
        "colors-accent-2-400": 1,
        "colors-accent-2-500": 1,
        "colors-accent-2-600": 1,
        "colors-accent-2-700": 1,
        "colors-accent-3-100": 1,
        "colors-accent-3-200": 1,
        "colors-accent-3-300": 1,
        "colors-accent-3-400": 1,
        "colors-accent-3-500": 1,
        "colors-accent-3-600": 1,
        "colors-accent-3-700": 1,
        "colors-accent-4-400_storage": 1,
        "colors-accessible-accent-1-100": 1,
        "colors-accessible-accent-1-200": 1,
        "colors-accessible-accent-1-300": 1,
        "colors-accessible-accent-1-400": 1,
        "colors-accessible-accent-1-500": 1,
        "colors-accessible-accent-1-600": 1,
        "colors-accessible-accent-1-700": 1,
        "colors-accessible-accent-2-100": 1,
        "colors-accessible-accent-2-200": 1,
        "colors-accessible-accent-2-300": 1,
        "colors-accessible-accent-2-400": 1,
        "colors-accessible-accent-2-500": 1,
        "colors-accessible-accent-2-600": 1,
        "colors-accessible-accent-2-700": 1,
        "colors-accessible-brand-2-100": 1,
        "colors-accessible-brand-2-200": 1,
        "colors-accessible-brand-2-300": 1,
        "colors-accessible-brand-2-400": 1,
        "colors-accessible-brand-2-500": 1,
        "colors-accessible-brand-2-600": 1,
        "colors-accessible-brand-2-700": 1
      }
    },
    "Duck": {
      "colors": {
        "components-accessibility-bar-color-bg": "#003da5",
        "components-accessibility-bar-color-text": "#ffffff",
        "components-accordion-color-bg": "#f3f9fd",
        "components-accordion-color-title": "#191b1c",
        "components-accordion-color-body": "#27282b",
        "components-accordion-color-section-section-bg": "#ffffff",
        "components-accordion-color-section-section-title": "#191b1c",
        "components-accordion-color-section-section-body": "#27282b",
        "components-accordion-color-item-item-bg": "#ffffff",
        "components-accordion-color-item-divider": "#e8e8ea",
        "components-accordion-color-item-icon-icon-default": "#003da5",
        "components-accordion-color-item-icon-icon-hover": "#ffffff",
        "components-accordion-color-item-icon-icon-pressed": "#ffffff",
        "components-accordion-color-item-icon-icon-active": "#ffffff",
        "components-accordion-color-item-title-item-default": "#191b1c",
        "components-accordion-color-item-title-item-hover": "#ffffff",
        "components-accordion-color-item-title-item-pressed": "#ffffff",
        "components-accordion-color-item-title-item-active": "#ffffff",
        "components-accordion-color-content-bg": "#ffffff",
        "components-accordion-color-content-title": "#191b1c",
        "components-accordion-color-content-body": "#27282b",
        "components-article-card-color-bg": "#ffffff",
        "components-article-card-color-border": "#e6f2fa",
        "components-article-card-color-content-bg": "#ffffff",
        "components-article-card-color-title": "#191b1c",
        "components-article-card-color-subtitle": "#27282b",
        "components-article-card-color-copy": "#27282b",
        "components-article-card-color-description": "#27282b",
        "components-article-card-listing-color-default-bg": "#f3f9fd",
        "components-article-card-listing-color-default-title": "#191b1c",
        "components-article-card-listing-color-default-copy": "#27282b",
        "components-article-hero-color-content-bg": "#002768",
        "components-article-hero-color-bg": "#002768",
        "components-article-hero-color-headline": "#ffffff",
        "components-article-hero-color-body": "#fcfcfd",
        "components-article-hero-color-detail-text": "#ffffff",
        "components-article-hero-color-divider": "#a2a5ab",
        "components-article-hero-color-icon": "#003da5",
        "components-article-hero-color-play-icon": "#ffffff",
        "components-article-hero-color-selected-stroke": "#da291c",
        "components-banner-color-bg-default": "#003794",
        "components-banner-color-icon-default": "#f2a900",
        "components-banner-color-title-default": "#ffffff",
        "components-banner-color-body-default": "#fcfcfd",
        "components-banner-color-icon-close-default": "#ffffff",
        "components-banner-color-bg-alert": "#b32b30",
        "components-banner-color-icon-alert": "#ffffff",
        "components-banner-color-title-alert": "#ffffff",
        "components-banner-color-body-alert": "#ffffff",
        "components-banner-color-icon-close-alert": "#ffffff",
        "components-breadcrumb-color-bg": "#ffffff",
        "components-breadcrumb-color-breadcrumb-link-default": "#72757e",
        "components-breadcrumb-color-breadcrumb-link-hover": "#4e5056",
        "components-breadcrumb-color-breadcrumb-link-active": "#27282b",
        "components-breadcrumb-color-icon-divider": "#72757e",
        "components-bug-finder-prompt": "#ffffff",
        "components-button-color-filled-brand-default-bg": "#c32519",
        "components-button-color-filled-brand-default-text": "#ffffff",
        "components-button-color-filled-brand-default-stroke": "#c32519",
        "components-button-color-filled-brand-default-icon": "#ffffff",
        "components-button-color-filled-brand-hover-bg": "#da291c",
        "components-button-color-filled-brand-hover-text": "#ffffff",
        "components-button-color-filled-brand-hover-stroke": "#da291c",
        "components-button-color-filled-brand-hover-icon": "#ffffff",
        "components-button-color-filled-brand-pressed-bg": "#da291c",
        "components-button-color-filled-brand-pressed-text": "#ffffff",
        "components-button-color-filled-brand-pressed-stroke": "#da291c",
        "components-button-color-filled-brand-pressed-icon": "#ffffff",
        "components-button-color-filled-brand-disabled-bg": "rgba(195, 37, 25, 0.38)",
        "components-button-color-filled-brand-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-disabled-stroke": "rgba(195, 37, 25, 0.38)",
        "components-button-color-filled-brand-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-focus-bg": "#c32519",
        "components-button-color-filled-brand-focus-text": "#ffffff",
        "components-button-color-filled-brand-focus-stroke": "#c32519",
        "components-button-color-filled-brand-focus-icon": "#ffffff",
        "components-button-color-filled-brand-focus-outline": "#286efa",
        "components-button-color-filled-tonal-default-bg": "#e4e6f0",
        "components-button-color-filled-tonal-default-text": "#003794",
        "components-button-color-filled-tonal-default-stroke": "#e4e6f0",
        "components-button-color-filled-tonal-default-icon": "#003794",
        "components-button-color-filled-tonal-hover-bg": "#002f80",
        "components-button-color-filled-tonal-hover-text": "#ffffff",
        "components-button-color-filled-tonal-hover-stroke": "#002f80",
        "components-button-color-filled-tonal-hover-icon": "#ffffff",
        "components-button-color-filled-tonal-pressed-bg": "#002f80",
        "components-button-color-filled-tonal-pressed-text": "#ffffff",
        "components-button-color-filled-tonal-pressed-stroke": "#002f80",
        "components-button-color-filled-tonal-pressed-icon": "#ffffff",
        "components-button-color-filled-tonal-disabled-bg": "rgba(228, 230, 240, 0.40)",
        "components-button-color-filled-tonal-disabled-text": "rgba(0, 55, 148, 0.40)",
        "components-button-color-filled-tonal-disabled-stroke": "rgba(0, 55, 148, 0.40)",
        "components-button-color-filled-tonal-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-tonal-focus-bg": "#e4e6f0",
        "components-button-color-filled-tonal-focus-text": "#003794",
        "components-button-color-filled-tonal-focus-stroke": "#e4e6f0",
        "components-button-color-filled-tonal-focus-icon": "#003794",
        "components-button-color-filled-tonal-focus-outline": "#1c4cae",
        "components-button-color-filled-white-default-bg": "#ffffff",
        "components-button-color-filled-white-default-text": "#191b1c",
        "components-button-color-filled-white-default-stroke": "#ffffff",
        "components-button-color-filled-white-default-icon": "#c32519",
        "components-button-color-filled-white-hover-bg": "#fcfcfd",
        "components-button-color-filled-white-hover-text": "#191b1c",
        "components-button-color-filled-white-hover-stroke": "#fcfcfd",
        "components-button-color-filled-white-hover-icon": "#c32519",
        "components-button-color-filled-white-pressed-bg": "#e8e8ea",
        "components-button-color-filled-white-pressed-text": "#191b1c",
        "components-button-color-filled-white-pressed-stroke": "#e8e8ea",
        "components-button-color-filled-white-pressed-icon": "#c32519",
        "components-button-color-filled-white-disabled-bg": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-text": "rgba(25, 27, 28, 0.38)",
        "components-button-color-filled-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-icon": "rgba(195, 37, 25, 0.38)",
        "components-button-color-filled-white-focus-bg": "#ffffff",
        "components-button-color-filled-white-focus-text": "#191b1c",
        "components-button-color-filled-white-focus-stroke": "#ffffff",
        "components-button-color-filled-white-focus-icon": "#c32519",
        "components-button-color-filled-white-focus-outline": "#1c4cae",
        "components-button-color-outline-brand-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-brand-default-text": "#c32519",
        "components-button-color-outline-brand-default-stroke": "#da291c",
        "components-button-color-outline-brand-default-icon": "#c32519",
        "components-button-color-outline-brand-hover-bg": "#da291c",
        "components-button-color-outline-brand-hover-text": "#ffffff",
        "components-button-color-outline-brand-hover-stroke": "#da291c",
        "components-button-color-outline-brand-hover-icon": "#ffffff",
        "components-button-color-outline-brand-pressed-bg": "#da291c",
        "components-button-color-outline-brand-pressed-text": "#ffffff",
        "components-button-color-outline-brand-pressed-stroke": "#da291c",
        "components-button-color-outline-brand-pressed-icon": "#ffffff",
        "components-button-color-outline-brand-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-brand-disabled-text": "rgba(25, 27, 28, 0.38)",
        "components-button-color-outline-brand-disabled-stroke": "rgba(25, 27, 28, 0.38)",
        "components-button-color-outline-brand-disabled-icon": "rgba(25, 27, 28, 0.38)",
        "components-button-color-outline-brand-focus-bg": "#ffffff",
        "components-button-color-outline-brand-focus-text": "#c32519",
        "components-button-color-outline-brand-focus-stroke": "#da291c",
        "components-button-color-outline-brand-focus-icon": "#c32519",
        "components-button-color-outline-brand-focus-outline": "#286efa",
        "components-button-color-outline-tonal-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-tonal-default-text": "#002f80",
        "components-button-color-outline-tonal-default-stroke": "#a1a8ce",
        "components-button-color-outline-tonal-default-icon": "#002f80",
        "components-button-color-outline-tonal-hover-bg": "#002f80",
        "components-button-color-outline-tonal-hover-text": "#ffffff",
        "components-button-color-outline-tonal-hover-stroke": "#002f80",
        "components-button-color-outline-tonal-hover-icon": "#ffffff",
        "components-button-color-outline-tonal-pressed-bg": "#002f80",
        "components-button-color-outline-tonal-pressed-text": "#ffffff",
        "components-button-color-outline-tonal-pressed-stroke": "#002f80",
        "components-button-color-outline-tonal-pressed-icon": "#ffffff",
        "components-button-color-outline-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-tonal-disabled-text": "rgba(0, 61, 165, 0.38)",
        "components-button-color-outline-tonal-disabled-stroke": "rgba(0, 61, 165, 0.38)",
        "components-button-color-outline-tonal-disabled-icon": "rgba(0, 61, 165, 0.38)",
        "components-button-color-outline-tonal-focus-bg": "rgba(255, 255, 255, 0.01)",
        "components-button-color-outline-tonal-focus-text": "#002f80",
        "components-button-color-outline-tonal-focus-stroke": "#a1a8ce",
        "components-button-color-outline-tonal-focus-icon": "#002f80",
        "components-button-color-outline-tonal-focus-outline": "#286efa",
        "components-button-color-outline-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-default-text": "#ffffff",
        "components-button-color-outline-white-default-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-default-icon": "#ffffff",
        "components-button-color-outline-white-hover-bg": "#ffffff",
        "components-button-color-outline-white-hover-text": "#191b1c",
        "components-button-color-outline-white-hover-stroke": "#ffffff",
        "components-button-color-outline-white-hover-icon": "#c32519",
        "components-button-color-outline-white-pressed-bg": "#ffffff",
        "components-button-color-outline-white-pressed-text": "#191b1c",
        "components-button-color-outline-white-pressed-stroke": "#ffffff",
        "components-button-color-outline-white-pressed-icon": "#c32519",
        "components-button-color-outline-white-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-focus-bg": "rgba(255, 255, 255, 0.01)",
        "components-button-color-outline-white-focus-text": "#ffffff",
        "components-button-color-outline-white-focus-stroke": "#ffffff",
        "components-button-color-outline-white-focus-icon": "#ffffff",
        "components-button-color-outline-white-focus-outline": "#286efa",
        "components-button-color-text-brand-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-default-text": "#003794",
        "components-button-color-text-brand-default-stroke": "#002f80",
        "components-button-color-text-brand-default-icon": "#003794",
        "components-button-color-text-brand-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-hover-bg-icon-only": "#e8e8ea",
        "components-button-color-text-brand-hover-text": "#da291c",
        "components-button-color-text-brand-hover-stroke": "#da291c",
        "components-button-color-text-brand-hover-icon": "#da291c",
        "components-button-color-text-brand-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-pressed-bg-icon-only": "#a2a5ab",
        "components-button-color-text-brand-pressed-text": "#da291c",
        "components-button-color-text-brand-pressed-stroke": "#da291c",
        "components-button-color-text-brand-pressed-icon": "#da291c",
        "components-button-color-text-brand-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-disabled-text": "rgba(39, 40, 43, 0.38)",
        "components-button-color-text-brand-disabled-stroke": "rgba(39, 40, 43, 0.38)",
        "components-button-color-text-brand-disabled-icon": "rgba(39, 40, 43, 0.38)",
        "components-button-color-text-brand-focus-bg": "#d5e3ff",
        "components-button-color-text-brand-focus-text": "#c32519",
        "components-button-color-text-brand-focus-stroke": "#27282b",
        "components-button-color-text-brand-focus-icon": "#1c4cae",
        "components-button-color-text-brand-focus-outline": "#1c4cae",
        "components-button-color-text-tonal-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-default-text": "#002f80",
        "components-button-color-text-tonal-default-stroke": "#003794",
        "components-button-color-text-tonal-default-icon": "#002f80",
        "components-button-color-text-tonal-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-hover-bg-icon-only": "#fcfcfd",
        "components-button-color-text-tonal-hover-text": "#002768",
        "components-button-color-text-tonal-hover-stroke": "#003794",
        "components-button-color-text-tonal-hover-icon": "#002768",
        "components-button-color-text-tonal-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-pressed-bg-icon-only": "#e8e8ea",
        "components-button-color-text-tonal-pressed-text": "#002768",
        "components-button-color-text-tonal-pressed-stroke": "#003794",
        "components-button-color-text-tonal-pressed-icon": "#002768",
        "components-button-color-text-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-disabled-text": "rgba(65, 95, 45, 0.38)",
        "components-button-color-text-tonal-disabled-stroke": "rgba(65, 95, 45, 0.38)",
        "components-button-color-text-tonal-disabled-icon": "rgba(65, 95, 45, 0.38)",
        "components-button-color-text-tonal-focus-bg": "#d5e3ff",
        "components-button-color-text-tonal-focus-text": "#1c4cae",
        "components-button-color-text-tonal-focus-stroke": "#003794",
        "components-button-color-text-tonal-focus-icon": "#1c4cae",
        "components-button-color-text-tonal-focus-outline": "#1c4cae",
        "components-button-color-text-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-default-text": "#ffffff",
        "components-button-color-text-white-default-stroke": "#ffffff",
        "components-button-color-text-white-default-icon": "#ffffff",
        "components-button-color-text-white-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-hover-bg-icon-only": "#4e5056",
        "components-button-color-text-white-hover-text": "#ffffff",
        "components-button-color-text-white-hover-stroke": "#ffffff",
        "components-button-color-text-white-hover-icon": "#ffffff",
        "components-button-color-text-white-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-pressed-bg-icon-only": "#4e5056",
        "components-button-color-text-white-pressed-text": "#ffffff",
        "components-button-color-text-white-pressed-stroke": "#ffffff",
        "components-button-color-text-white-pressed-icon": "#ffffff",
        "components-button-color-text-white-disabled-bg": "rgba(115, 119, 128, 0.00)",
        "components-button-color-text-white-disabled-text": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-stroke": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-icon": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-focus-bg": "#d5e3ff",
        "components-button-color-text-white-focus-text": "#ffffff",
        "components-button-color-text-white-focus-stroke": "#ffffff",
        "components-button-color-text-white-focus-icon": "#1c4cae",
        "components-button-color-text-white-focus-outline": "#1c4cae",
        "components-button-group-color-border": "#e8e8ea",
        "components-button-group-color-button-bg-default": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-button-fg-default": "#191b1c",
        "components-button-group-color-button-bg-hover": "rgba(195, 37, 25, 0.38)",
        "components-button-group-color-button-fg-hover": "#191b1c",
        "components-button-group-color-button-bg-active": "#c32519",
        "components-button-group-color-button-fg-active": "#ffffff",
        "components-button-group-color-overlay-left-gradient-fill-1": "#f3f9fd",
        "components-button-group-color-overlay-left-gradient-fill-2": "rgba(243, 249, 253, 0.50)",
        "components-button-group-color-stroke-default-gradient-fill-1": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-2": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-3": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-4": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-1": "rgba(195, 37, 25, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-2": "rgba(195, 37, 25, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-3": "rgba(195, 37, 25, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-4": "rgba(195, 37, 25, 0.00)",
        "components-button-group-color-stroke-active-gradient-fill-1": "#c32519",
        "components-button-group-color-stroke-active-gradient-fill-2": "#c32519",
        "components-button-group-color-stroke-active-gradient-fill-3": "#c32519",
        "components-button-group-color-stroke-active-gradient-fill-4": "#c32519",
        "components-category-card-color-bg": "#ffffff",
        "components-category-card-color-content-bg": "#ffffff",
        "components-category-card-color-category-bg": "#ffffff",
        "components-category-card-color-category-bg-2": "#ffffff",
        "components-category-card-color-category-bg-3": "#ffffff",
        "components-category-card-color-category-bg-4": "#ffffff",
        "components-category-card-color-category-bg-5": "#ffffff",
        "components-category-card-color-category-bg-6": "#ffffff",
        "components-category-card-color-title": "#191b1c",
        "components-category-card-color-copy": "#27282b",
        "components-category-card-color-stroke": "#e6f2fa",
        "components-category-card-color-stroke-hover": "#002f80",
        "components-category-card-listing-color-bg": "#f3f9fd",
        "components-category-card-listing-color-title": "#191b1c",
        "components-category-card-listing-color-copy": "#27282b",
        "components-card-search-result-color-bg": "#ffffff",
        "components-card-search-result-color-label": "#27282b",
        "components-card-search-result-color-title": "#191b1c",
        "components-card-search-result-color-body": "#27282b",
        "components-card-search-result-color-divider-line": "#e8e8ea",
        "components-contact-us-promo-color-light-bg": "#f3f9fd",
        "components-contact-us-promo-color-light-title": "#191b1c",
        "components-contact-us-promo-color-light-body": "#27282b",
        "components-contact-us-promo-color-default-bg": "#ffffff",
        "components-contact-us-promo-color-default-title": "#191b1c",
        "components-contact-us-promo-color-default-body": "#27282b",
        "components-contact-us-promo-color-brand-bg": "#003da5",
        "components-contact-us-promo-color-brand-title": "#ffffff",
        "components-contact-us-promo-color-brand-body": "#fcfcfd",
        "components-content-list-color-bg": "#ffffff",
        "components-content-list-color-title": "#191b1c",
        "components-content-list-color-body": "#27282b",
        "components-content-list-color-icon": "#da291c",
        "components-cookies-colors-bg": "#f3f9fd",
        "components-cookies-colors-title": "#191b1c",
        "components-cookies-colors-body": "#27282b",
        "components-cookies-colors-icon": "#003da5",
        "components-dropdown-bg": "#ffffff",
        "components-dropdown-border-default": "#72757e",
        "components-dropdown-border-hover": "#003da5",
        "components-dropdown-border-focused": "#191b1c",
        "components-dropdown-border-destructive": "#b32b30",
        "components-dropdown-label-text-default": "#191b1c",
        "components-dropdown-item-text-default": "#191b1c",
        "components-dropdown-item-bg-default": "#ffffff",
        "components-dropdown-item-bg-hover": "#f2f3f7",
        "components-dropdown-input-field-default": "#72757e",
        "components-dropdown-input-field-focused": "#191b1c",
        "components-dropdown-input-label-focused": "#191b1c",
        "components-dropdown-input-field-destructive": "#b32b30",
        "components-external-link-card-bg": "#f3f9fd",
        "components-external-link-card-card-bg": "#ffffff",
        "components-external-link-card-card-title": "#27282b",
        "components-external-link-card-card-subtitle": "#27282b",
        "components-external-link-card-card-eyebrow": "#4e5056",
        "components-fab-color-border-default": "#ffffff",
        "components-footer-brand-color-bg": "#002768",
        "components-footer-brand-color-logo": "#ffffff",
        "components-footer-brand-color-title-text": "#ffffff",
        "components-footer-brand-color-description-text": "#ffffff",
        "components-footer-brand-color-footer-menu-expanded": "#002768",
        "components-footer-brand-color-link-default": "#ffffff",
        "components-footer-brand-color-link-hover": "#e8e8ea",
        "components-footer-brand-color-link-pressed": "#e8e8ea",
        "components-footer-brand-color-category-link-default": "#ffffff",
        "components-footer-brand-color-category-link-hover": "#e8e8ea",
        "components-footer-brand-color-category-link-pressed": "#e8e8ea",
        "components-footer-brand-color-subcategory-link-default": "#ffffff",
        "components-footer-brand-color-subcategory-link-hover": "#e8e8ea",
        "components-footer-brand-color-subcategory-link-pressed": "#e8e8ea",
        "components-footer-brand-color-icon-icon-default": "#ffffff",
        "components-footer-brand-color-icon-icon-pressed": "#e8e8ea",
        "components-footer-brand-color-social-icon-default": "#ffffff",
        "components-footer-brand-color-social-icon-hover": "#e8e8ea",
        "components-footer-brand-color-social-icon-pressed": "#e8e8ea",
        "components-footer-brand-spacing-category-link-hover": "#000000",
        "components-footer-global-color-bg": "#ffffff",
        "components-footer-global-color-title-text": "#000000",
        "components-footer-global-color-disclaimer-text": "#000000",
        "components-footer-global-color-link-default": "#000000",
        "components-footer-global-color-link-hover": "#000000",
        "components-footer-global-color-link-pressed": "#000000",
        "components-generic-card-color-bg": "#ffffff",
        "components-generic-card-color-content-bg": "#ffffff",
        "components-generic-card-color-title": "#191b1c",
        "components-generic-card-color-subtitle": "#27282b",
        "components-generic-card-color-copy": "#27282b",
        "components-global-search-color-omnibox-list-bg": "#ffffff",
        "components-global-search-color-omnibox-divider": "#a2a5ab",
        "components-global-search-color-omnibox-item-bg-default": "#ffffff",
        "components-global-search-color-omnibox-item-bg-hover": "#fcf2f2",
        "components-global-search-color-omnibox-item-text-default": "#da291c",
        "components-global-search-color-omnibox-item-text-hover": "#c32519",
        "components-global-search-color-omnibox-label-text-default": "#4e5056",
        "components-global-search-color-omnibox-category-text-default": "#4e5056",
        "components-generic-card-listing-color-bg": "#f3f9fd",
        "components-generic-card-listing-color-title": "#191b1c",
        "components-generic-card-listing-color-copy": "#27282b",
        "components-header-color-bg": "#ffffff",
        "components-header-color-bottom-stroke": "#e8e8ea",
        "components-header-color-search-search-border-default": "#191b1c",
        "components-header-color-search-search-text": "#4e5056",
        "components-header-color-search-search-icon": "#4e5056",
        "components-header-color-search-search-bar": "#191b1c",
        "components-header-color-mobile-mobile-expanded-bg": "#ffffff",
        "components-header-color-mobile-pressed-bg": "#e4e6f0",
        "components-header-color-language-selector-language": "#4e5056",
        "components-header-color-language-selector-country": "#191b1c",
        "components-header-color-language-selector-country-hover-bg": "#e8e8ea",
        "components-header-color-language-selector-megamenu-language": "#4e5056",
        "components-header-color-language-selector-megamenu-country": "#191b1c",
        "components-header-color-language-selector-megamenu-country-hover": "#e8e8ea",
        "components-header-color-language-selector-bg": "#ffffff",
        "components-header-color-language-selector-stroke": "#72757e",
        "components-header-color-mega-menu-bg": "#ffffff",
        "components-header-color-mega-menu-featured-bg": "#003da5",
        "components-header-color-mega-menu-featured-title": "#ffffff",
        "components-header-color-mega-menu-category-title": "#27282b",
        "components-header-color-mega-menu-bg-accent": "#ffffff",
        "components-header-color-icon-icon-default": "#4e5056",
        "components-header-color-icon-icon-hover": "#191b1c",
        "components-header-color-icon-icon-pressed": "#000000",
        "components-header-color-links-nav-main-link-default": "#4e5056",
        "components-header-color-links-nav-main-link-hover": "#191b1c",
        "components-header-color-links-nav-main-link-pressed": "#000000",
        "components-header-color-links-nav-main-link-active": "#000000",
        "components-header-color-links-nav-main-active-bar": "#003da5",
        "components-header-color-links-nav-main-bg-color": "#ffffff",
        "components-header-color-links-nav-category-link-category-default": "#4e5056",
        "components-header-color-links-nav-category-link-category-hover": "#191b1c",
        "components-header-color-links-nav-category-link-category-pressed": "#000000",
        "components-header-color-links-nav-category-link-category-active": "#000000",
        "components-header-color-links-nav-menu-link-link-default": "#4e5056",
        "components-header-color-links-nav-menu-link-link-hover": "#191b1c",
        "components-header-color-links-nav-menu-link-link-pressed": "#000000",
        "components-header-color-links-nav-menu-link-link-active": "#000000",
        "components-header-color-links-nav-menu-link-bg": "#ffffff",
        "components-header-color-links-nav-subcategory-link-subcategory-default": "#4e5056",
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": "#000000",
        "components-header-color-links-nav-subcategory-link-subcategory-active": "#000000",
        "components-hero-homepage-color-bg": "#003da5",
        "components-hero-homepage-color-container-bg": "#003da5",
        "components-hero-homepage-color-eyebrow": "#72757e",
        "components-hero-homepage-color-header": "#ffffff",
        "components-hero-homepage-color-sub-header": "#fcfcfd",
        "components-hero-homepage-color-body": "#fcfcfd",
        "components-hero-homepage-color-play-pause": "#ffffff",
        "components-hero-internal-color-default-title": "#ffffff",
        "components-hero-internal-color-default-body": "#fcfcfd",
        "components-hero-internal-color-default-bg": "#002f80",
        "components-hero-internal-color-accent-bg": "#003794",
        "components-hero-internal-color-with-image-bg": "#002f80",
        "components-hero-internal-color-default-bg-2": "#002f80",
        "components-hero-internal-color-with-image-title": "#ffffff",
        "components-hero-internal-color-with-image-body": "#fcfcfd",
        "components-heading-page-title-color-default-bg": "#ffffff",
        "components-heading-page-title-color-default-title": "#003794",
        "components-heading-page-title-color-default-body": "#27282b",
        "components-heading-page-title-color-light-bg": "#f3f9fd",
        "components-heading-page-title-color-light-title": "#003794",
        "components-heading-page-title-color-light-body": "#27282b",
        "components-heading-page-title-color-color-bg": "#003da5",
        "components-heading-page-title-color-color-title": "#ffffff",
        "components-heading-page-title-color-color-body": "#ffffff",
        "components-hyperlink-color-dark-default": "#da291c",
        "components-hyperlink-color-dark-hover": "#a92016",
        "components-hyperlink-color-dark-visited": "#41b6e6",
        "components-hyperlink-color-light-default": "#f8e5e4",
        "components-hyperlink-color-light-hover": "#f1c7c6",
        "components-hyperlink-color-light-visited": "#e6f2fa",
        "components-icon-default": "#72757e",
        "components-icon-focused": "#72757e",
        "components-icon-card-color-bg": "#ffffff",
        "components-icon-card-color-icon-bg": "#003da5",
        "components-icon-card-color-icon": "#191b1c",
        "components-icon-card-color-title": "#191b1c",
        "components-icon-card-color-subtitle": "#27282b",
        "components-icon-card-color-description": "#27282b",
        "components-icon-card-listing-color-bg": "#f3f9fd",
        "components-icon-card-listing-color-inner-bg": "#f3f9fd",
        "components-icon-card-listing-color-brand-bg": "#003da5",
        "components-icon-card-listing-color-title": "#191b1c",
        "components-icon-card-listing-color-copy": "#27282b",
        "components-icon-card-listing-color-inner-legal-text": "#ffffff",
        "components-jumplink-color-bg-default": "#ffffff",
        "components-jumplink-color-link-text-default": "#4e5056",
        "components-jumplink-color-link-text-hover": "#002f80",
        "components-jumplink-color-link-border-hover": "#002f80",
        "components-jumplink-color-link-text-active": "#000000",
        "components-jumplink-color-link-border-active": "#002f80",
        "components-jumplink-color-title-text": "#191b1c",
        "components-jumplink-color-description-text": "#27282b",
        "components-layout-bg-default": "#ffffff",
        "components-lightbox-color-overlay-bg": "rgba(255, 255, 255, 0.95)",
        "components-lightbox-color-title": "#191b1c",
        "components-lightbox-color-body": "#27282b",
        "components-label-color-on-color-label-label-default": "#ffffff",
        "components-label-color-on-color-label-label-hover": "#003794",
        "components-label-color-on-color-label-label-pressed": "#ffffff",
        "components-label-color-on-color-background-bg-default": "rgba(65, 182, 230, 0.00)",
        "components-label-color-on-color-background-bg-hover": "#ffffff",
        "components-label-color-on-color-background-bg-pressed": "#3aa3ce",
        "components-label-color-on-color-stroke-hover": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-color-stroke-pressed": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-color-stroke-default-gradient-fill-1": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-2": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-3": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-4": "#ffffff",
        "components-label-color-on-white-label-label-default": "#003794",
        "components-label-color-on-white-label-label-hover": "#ffffff",
        "components-label-color-on-white-label-label-pressed": "#ffffff",
        "components-label-color-on-white-background-bg-default": "rgba(65, 182, 230, 0.00)",
        "components-label-color-on-white-background-bg-hover": "#328db2",
        "components-label-color-on-white-background-bg-pressed": "#328db2",
        "components-label-color-on-white-stroke-hover": "#328db2",
        "components-label-color-on-white-stroke-pressed": "#328db2",
        "components-label-color-on-white-stroke-default-gradient-fill-1": "#002f80",
        "components-label-color-on-white-stroke-default-gradient-fill-2": "#002f80",
        "components-label-color-on-white-stroke-default-gradient-fill-3": "#002f80",
        "components-label-color-on-white-stroke-default-gradient-fill-4": "#002f80",
        "components-legal-disclaimer-color-default-bg": "#f3f9fd",
        "components-legal-disclaimer-color-default-title": "#191b1c",
        "components-legal-disclaimer-color-default-body": "#27282b",
        "components-legal-disclaimer-color-light-bg": "#f3f9fd",
        "components-legal-disclaimer-color-light-title": "#191b1c",
        "components-legal-disclaimer-color-light-body": "#27282b",
        "components-legal-disclaimer-color-brand-bg": "#191b1c",
        "components-legal-disclaimer-color-brand-title": "#ffffff",
        "components-legal-disclaimer-color-brand-body": "#fcfcfd",
        "components-media-video-thumbnail-overlay-bg": "rgba(0, 61, 165, 0.70)",
        "components-media-caption-color-bg": "#fcfcfd",
        "components-media-caption-color-text": "#27282b",
        "components-media-gallery-color-bg": "#ffffff",
        "components-media-gallery-color-title": "#191b1c",
        "components-media-gallery-color-body": "#27282b",
        "components-pagination-on-white-accent-default": "#002768",
        "components-pagination-on-white-accent-scroll": "#003da5",
        "components-pagination-on-white-disabled": "#a2a5ab",
        "components-pagination-on-white-bg": "#e6f2fa",
        "components-pagination-on-white-stroke": "#e8e8ea",
        "components-pagination-on-white-bg-active": "#c32519",
        "components-pagination-on-white-bg-hover": "#e8e8ea",
        "components-pagination-on-white-text-default": "#72757e",
        "components-pagination-on-white-text-hover": "#27282b",
        "components-pagination-on-white-text-active": "#ffffff",
        "components-pagination-on-color-accent-default": "#ffffff",
        "components-pagination-on-color-accent-scroll": "#f2f3f7",
        "components-pagination-on-color-disabled": "#002768",
        "components-pagination-on-color-bg": "rgba(255, 255, 255, 0.20)",
        "components-pagination-on-color-stroke": "rgba(255, 255, 255, 0.50)",
        "components-pagination-on-color-bg-active": "#ffffff",
        "components-pagination-on-color-bg-hover": "#27282b",
        "components-pagination-on-color-text-default": "#ffffff",
        "components-pagination-on-color-text-hover": "#ffffff",
        "components-pagination-on-color-text-active": "#191b1c",
        "components-pdf-link-card-color-bg": "#f3f9fd",
        "components-pdf-link-card-color-card-bg": "#ffffff",
        "components-pdf-link-card-color-card-title": "#191b1c",
        "components-pdf-link-card-color-card-subtitle": "#27282b",
        "components-pdf-link-card-color-card-body": "#27282b",
        "components-product-card-color-bg": "#ffffff",
        "components-product-card-color-content-bg": "#ffffff",
        "components-product-card-color-border": "#e6f2fa",
        "components-product-card-color-title": "#191b1c",
        "components-product-card-color-description": "#27282b",
        "components-product-card-color-star-rating": "#f2a900",
        "components-product-card-color-badge-bg": "#f2a900",
        "components-product-card-color-badge-text": "#002768",
        "components-product-card-listing-color-bg": "#003da5",
        "components-product-card-listing-color-title": "#ffffff",
        "components-product-card-listing-color-copy": "#fcfcfd",
        "components-product-card-listing-color-legal-text": "#fcfcfd",
        "components-product-information-color-bg": "#002768",
        "components-product-information-color-content-bg": "#002768",
        "components-product-information-color-headline": "#ffffff",
        "components-product-information-color-body": "#fcfcfd",
        "components-product-information-color-detail-text": "#ffffff",
        "components-product-information-color-divider": "#a2a5ab",
        "components-product-information-color-icon": "#ffffff",
        "components-product-information-color-play-icon": "#ffffff",
        "components-product-information-color-selected-stroke": "#da291c",
        "components-product-results-list-with-search-color-bg": "#f3f9fd",
        "components-promo-color-default-bg": "#ffffff",
        "components-promo-color-default-image-bg": "#ffffff",
        "components-promo-color-default-content-bg": "#ffffff",
        "components-promo-color-default-title": "#191b1c",
        "components-promo-color-default-body": "#27282b",
        "components-promo-color-default-category": "#191b1c",
        "components-promo-color-light-bg": "#f3f9fd",
        "components-promo-color-light-image-bg": "#f3f9fd",
        "components-promo-color-light-content-bg": "#f3f9fd",
        "components-promo-color-light-title": "#191b1c",
        "components-promo-color-light-body": "#27282b",
        "components-promo-color-light-category": "#191b1c",
        "components-promo-color-brand-bg": "#002f80",
        "components-promo-color-brand-image-bg": "#002f80",
        "components-promo-color-brand-content-bg": "#002f80",
        "components-promo-color-brand-title": "#ffffff",
        "components-promo-color-brand-body": "#fcfcfd",
        "components-promo-color-brand-category": "#ffffff",
        "components-pull-quote-color-bg": "#003da5",
        "components-pull-quote-color-stroke": "#002f80",
        "components-pull-quote-color-quote-bg": "#ffffff",
        "components-pull-quote-color-quote-title": "#191b1c",
        "components-pull-quote-color-quote-by-text": "#27282b",
        "components-pull-quote-color-quote-detail": "#003da5",
        "components-pull-quote-color-quote-highlight": "#003da5",
        "components-promo-alternate-color-default-bg": "#ffffff",
        "components-promo-alternate-color-default-image-bg": "#ffffff",
        "components-promo-alternate-color-default-content-bg": "#ffffff",
        "components-promo-alternate-color-default-title": "#191b1c",
        "components-promo-alternate-color-default-body": "#27282b",
        "components-promo-alternate-color-default-category": "#191b1c",
        "components-promo-alternate-color-light-bg": "#f3f9fd",
        "components-promo-alternate-color-light-image-bg": "#f3f9fd",
        "components-promo-alternate-color-light-content-bg": "#f3f9fd",
        "components-promo-alternate-color-light-title": "#191b1c",
        "components-promo-alternate-color-light-body": "#27282b",
        "components-promo-alternate-color-light-category": "#191b1c",
        "components-promo-alternate-color-brand-bg": "#002f80",
        "components-promo-alternate-color-brand-image-bg": "#002f80",
        "components-promo-alternate-color-brand-content-bg": "#002f80",
        "components-promo-alternate-color-brand-title": "#ffffff",
        "components-promo-alternate-color-brand-body": "#fcfcfd",
        "components-promo-alternate-color-brand-category": "#ffffff",
        "components-share-list-color-title": "#27282b",
        "components-share-list-color-bg": "#ffffff",
        "components-share-list-color-link-default": "#27282b",
        "components-share-list-color-link-hover": "#003794",
        "components-share-list-color-bg-link-hover": "#f3f9fd",
        "components-share-list-color-close-icon": "#191b1c",
        "components-share-list-color-facebook-icon": "#3b5998",
        "components-share-list-color-pinterest-icon": "#e60023",
        "components-share-list-color-twitter-icon": "#000000",
        "components-share-list-color-basic-icon-default": "#003da5",
        "components-share-list-color-icon-hover": "#003da5",
        "components-share-list-color-border": "#191b1c",
        "components-site-search-bg": "#ffffff",
        "components-site-search-results_text": "#27282b",
        "components-site-search-filter-filter-selected-bg": "#f2f3f7",
        "components-site-search-facet-group-label-bottom-line": "#e8e8ea",
        "components-site-search-facet-group-label-text": "#27282b",
        "components-site-search-facet-group-label-bg": "#ffffff",
        "components-site-search-facet-bg": "#ffffff",
        "components-site-search-facet-stroke": "#e8e8ea",
        "components-site-search-refine-bg": "#ffffff",
        "components-site-search-refine-right-divider": "#f3f9fd",
        "components-site-search-refine-title": "#191b1c",
        "components-site-search-hero-search-bg": "#002f80",
        "components-site-search-hero-search-accent-bg": "#003794",
        "components-site-search-hero-search-title": "#ffffff",
        "components-stats-stat-text": "#003794",
        "components-stats-stat-text-on-light": "#ffffff",
        "components-stats-supporting-text": "#27282b",
        "components-stats-bg-default": "#ffffff",
        "components-stats-bg-inner": "#ffffff",
        "components-stats-bg-section-inner": "#003da5",
        "components-stats-title": "#191b1c",
        "components-stats-copy": "#27282b",
        "components-supporting-text-default": "#4e5056",
        "components-supporting-text-destructive": "#b32b30",
        "components-tab-bg": "#ffffff",
        "components-tab-border-default": "#72757e",
        "components-tab-border-hover": "#003da5",
        "components-tab-border-focused": "#191b1c",
        "components-tab-border-destructive": "#b32b30",
        "components-tab-label-text-default": "#191b1c",
        "components-tab-item-text-default": "#191b1c",
        "components-tab-item-bg-default": "#ffffff",
        "components-tab-item-bg-hover": "#f2f3f7",
        "components-tab-input-field-default": "#72757e",
        "components-tab-input-field-focused": "#191b1c",
        "components-tab-input-label-focused": "#191b1c",
        "components-tab-input-field-destructive": "#b32b30",
        "components-tab-bg-2": "#ffffff",
        "components-tag-color-bg": "#ffffff",
        "components-tag-color-text": "#c32519",
        "components-tag-color-stroke": "#da291c",
        "components-tag-color-bg-hover": "#c32519",
        "components-tag-color-text-hover": "#ffffff",
        "components-tag-color-stroke-hover": "#c32519",
        "components-text-field-bg": "#ffffff",
        "components-text-field-bg-focused": "#f2f3f7",
        "components-text-field-border-default": "#191b1c",
        "components-text-field-border-hover": "#003da5",
        "components-text-field-border-focused": "#002f80",
        "components-text-field-border-destructive": "#b32b30",
        "components-text-field-input-field-default": "#4e5056",
        "components-text-field-input-field-focused": "#191b1c",
        "components-text-field-input-label-focused": "#191b1c",
        "components-text-field-input-field-destructive": "#b32b30",
        "components-text-field-icon-default": "#4e5056",
        "components-text-field-icon-destructive": "#b32b30",
        "components-text-field-supporting-text-default": "#4e5056",
        "components-text-field-supporting-text-destructive": "#b32b30",
        "components-tip-color-bg": "#f2f3f7",
        "components-tip-color-title": "#003794",
        "components-tip-color-body": "#191b1c",
        "components-tip-color-stroke": "#a1a8ce",
        "components-video-color-bg": "#ffffff",
        "themes-general-surface-color-light-bg": "#f3f9fd",
        "themes-general-surface-color-light-title": "#191b1c",
        "themes-general-surface-color-light-body": "#27282b",
        "themes-general-surface-color-light-decor-line-break": "#a2a5ab",
        "themes-general-surface-color-light-rule-line": "rgba(162, 165, 171, 0.40)",
        "themes-general-surface-color-dark-bg": "#003da5",
        "themes-general-surface-color-dark-title": "#ffffff",
        "themes-general-surface-color-dark-body": "#fcfcfd",
        "themes-general-surface-color-dark-decor-line-break": "#72757e",
        "themes-general-card-color-dark-title": "#191b1c",
        "themes-general-card-color-dark-subtitle": "#27282b",
        "themes-general-card-color-dark-body": "#27282b",
        "themes-general-card-color-light-title": "#ffffff",
        "themes-general-card-color-light-subtitle": "#fcfcfd",
        "themes-general-card-color-light-body": "#fcfcfd",
        "themes-shadow-color": "#ffffff",
        "themes-shadow-share-list-color-fill": "rgba(0, 0, 0, 0.40)",
        "themes-shadow-header-menu-color-fill": "rgba(0, 0, 0, 0.20)",
        "themes-gradient-loader-gradient-fill-1": "#003da5",
        "themes-gradient-loader-gradient-fill-2": "rgba(0, 61, 165, 0.00)",
        "themes-color-background-white": "#ffffff",
        "themes-color-background-light": "#f3f9fd",
        "themes-color-background-dark": "#27282b",
        "themes-color-background-black": "#191b1c",
        "themes-color-background-brand1": "#003da5",
        "themes-color-background-brand2": "#c32519",
        "themes-color-background-accent1": "#f2a900",
        "themes-color-background-accent2": "#41b6e6",
        "themes-color-background-body": "#ffffff",
        "themes-color-stroke-white": "#ffffff",
        "themes-color-stroke-light": "#e8e8ea",
        "themes-color-stroke-dark": "#27282b",
        "themes-color-stroke-black": "#191b1c",
        "themes-color-stroke-brand1": "#002f80",
        "themes-color-stroke-brand2": "#da291c",
        "themes-color-stroke-accent1": "#f2a900",
        "themes-color-stroke-accent2": "#41b6e6",
        "themes-color-text-white": "#ffffff",
        "themes-color-text-light": "#fcfcfd",
        "themes-color-text-dark": "#27282b",
        "themes-color-text-black": "#191b1c",
        "themes-color-text-brand1": "#003794",
        "themes-color-text-brand2": "#c32519",
        "themes-color-text-accent1": "#bb8300",
        "themes-color-text-accent2": "#1d5167",
        "colors-grayscale-100": "#fcfcfd",
        "colors-grayscale-200": "#e8e8ea",
        "colors-grayscale-300": "#a2a5ab",
        "colors-grayscale-400": "#72757e",
        "colors-grayscale-500": "#4e5056",
        "colors-grayscale-600": "#27282b",
        "colors-grayscale-700": "#191b1c",
        "colors-brand-1-100": "#f2f3f7",
        "colors-brand-1-200": "#e4e6f0",
        "colors-brand-1-300": "#a1a8ce",
        "colors-brand-1-400": "#003da5",
        "colors-brand-1-500": "#003794",
        "colors-brand-1-600": "#002f80",
        "colors-brand-1-700": "#002768",
        "colors-brand-2-100": "#fcf2f2",
        "colors-brand-2-200": "#f8e5e4",
        "colors-brand-2-300": "#f1c7c6",
        "colors-brand-2-400": "#da291c",
        "colors-brand-2-500": "#c32519",
        "colors-brand-2-600": "#a92016",
        "colors-brand-2-700": "#61120d",
        "colors-accent-1-100": "#fef8f2",
        "colors-accent-1-200": "#fcf0e4",
        "colors-accent-1-300": "#fae1c6",
        "colors-accent-1-400": "#f2a900",
        "colors-accent-1-500": "#d89700",
        "colors-accent-1-600": "#bb8300",
        "colors-accent-1-700": "#996b00",
        "colors-accent-2-100": "#f3f9fd",
        "colors-accent-2-200": "#e6f2fa",
        "colors-accent-2-300": "#a9d6f0",
        "colors-accent-2-400": "#41b6e6",
        "colors-accent-2-500": "#3aa3ce",
        "colors-accent-2-600": "#328db2",
        "colors-accent-2-700": "#1d5167",
        "colors-accent-3-100": "#fcfcfd",
        "colors-accent-3-200": "#e8e8ea",
        "colors-accent-3-300": "#a2a5ab",
        "colors-accent-3-400": "#72757e",
        "colors-accent-3-500": "#4e5056",
        "colors-accent-3-600": "#27282b",
        "colors-accent-3-700": "#191b1c",
        "colors-accent-4-400_storage": "#000000",
        "colors-accessible-accent-1-100": "#fef8f2",
        "colors-accessible-accent-1-200": "#fcf0e4",
        "colors-accessible-accent-1-300": "#fae1c6",
        "colors-accessible-accent-1-400": "#f2a900",
        "colors-accessible-accent-1-500": "#d89700",
        "colors-accessible-accent-1-600": "#bb8300",
        "colors-accessible-accent-1-700": "#996b00",
        "colors-accessible-accent-2-100": "#f3f9fd",
        "colors-accessible-accent-2-200": "#e6f2fa",
        "colors-accessible-accent-2-300": "#a9d6f0",
        "colors-accessible-accent-2-400": "#41b6e6",
        "colors-accessible-accent-2-500": "#3aa3ce",
        "colors-accessible-accent-2-600": "#328db2",
        "colors-accessible-accent-2-700": "#1d5167",
        "colors-accessible-brand-2-100": "#fcf2f2",
        "colors-accessible-brand-2-200": "#f8e5e4",
        "colors-accessible-brand-2-300": "#f1c7c6",
        "colors-accessible-brand-2-400": "#da291c",
        "colors-accessible-brand-2-500": "#c32519",
        "colors-accessible-brand-2-600": "#a92016",
        "colors-accessible-brand-2-700": "#61120d"
      },
      "spacing": {
        "components-accordion-spacing-large-padding-x": "5rem",
        "components-accordion-spacing-large-icon-margin-right": "2rem",
        "components-accordion-spacing-large-copy-margin-right": "2.5rem",
        "components-accordion-spacing-large-maxwidth": "64rem",
        "components-accordion-spacing-large-section-padding-x": "2.5rem",
        "components-accordion-spacing-large-section-padding-y": "2.5rem",
        "components-accordion-spacing-large-section-copy-margin-bottom": "2.5rem",
        "components-accordion-spacing-large-section-space-between": "4rem",
        "components-accordion-spacing-large-item-padding-x": "0rem",
        "components-accordion-spacing-large-item-padding-y": "1.5rem",
        "components-accordion-spacing-large-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-large-content-padding-x": "0rem",
        "components-accordion-spacing-large-content-padding-y": "1.5rem",
        "components-accordion-spacing-large-content-title-margin-bottom": "2rem",
        "components-accordion-spacing-large-content-content-space-between": "1.5rem",
        "components-accordion-spacing-large-content-content-margin-left": "1rem",
        "components-accordion-spacing-small-padding-top": "1rem",
        "components-accordion-spacing-small-padding-x": "1.5rem",
        "components-accordion-spacing-small-padding-y": "2.5rem",
        "components-accordion-spacing-small-icon-margin-right": "0.75rem",
        "components-accordion-spacing-small-title-margin-bottom": "0.5rem",
        "components-accordion-spacing-small-copy-margin-right": "2rem",
        "components-accordion-spacing-small-copy-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-padding-x": "1.5rem",
        "components-accordion-spacing-small-section-padding-y": "1.5rem",
        "components-accordion-spacing-small-section-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-space-between": "1rem",
        "components-accordion-spacing-small-item-padding-x": "0rem",
        "components-accordion-spacing-small-item-padding-y": "1rem",
        "components-accordion-spacing-small-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-small-content-padding-x": "1.5rem",
        "components-accordion-spacing-small-content-padding-y": "0.75rem",
        "components-accordion-spacing-small-content-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-content-content-space-between": "1rem",
        "components-accordion-spacing-small-content-content-margin-left": "1rem",
        "components-article-card-spacing-large-padding-image-x": "0rem",
        "components-article-card-spacing-large-padding-image-y": "0rem",
        "components-article-card-spacing-large-padding-content-x": "2rem",
        "components-article-card-spacing-large-padding-content-y": "2rem",
        "components-article-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-article-card-spacing-large-button-space-between": "1rem",
        "components-article-card-spacing-large-title-margin-bottom": "1rem",
        "components-article-card-spacing-large-list-content-gap": "1rem",
        "components-article-card-spacing-large-list-image-padding-x": "0rem",
        "components-article-card-spacing-large-list-image-padding-y": "0rem",
        "components-article-card-spacing-small-padding-image-x": "0rem",
        "components-article-card-spacing-small-padding-image-y": "0rem",
        "components-article-card-spacing-small-padding-content-x": "1.5rem",
        "components-article-card-spacing-small-padding-content-y": "1.5rem",
        "components-article-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-article-card-listing-spacing-small-padding-x": "1.5rem",
        "components-article-card-listing-spacing-small-padding-y": "2.5rem",
        "components-article-hero-spacing-large-padding-x": "0rem",
        "components-article-hero-spacing-large-padding-y": "0rem",
        "components-article-hero-spacing-large-title-margin-bottom": "2rem",
        "components-article-hero-spacing-large-label-share-margin-bottom": "2rem",
        "components-article-hero-spacing-large-author-name-margin-bottom": "0.75rem",
        "components-article-hero-spacing-large-author-thumbnail-margin-left": "1rem",
        "components-article-hero-spacing-large-details-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-space-between": "1rem",
        "components-article-hero-spacing-large-body-margin-bottom": "2rem",
        "components-article-hero-spacing-large-image-space-between": "1rem",
        "components-article-hero-spacing-large-image-margin-bottom": "1rem",
        "components-article-hero-spacing-large-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-stats-margin-bottom": "2.5rem",
        "components-article-hero-spacing-small-content-margin-top": "2.5rem",
        "components-article-hero-spacing-small-title-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-label-share-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-authorname-margin-bottom": "0.75rem",
        "components-article-hero-spacing-small-authorthumbnail-margin-left": "1rem",
        "components-article-hero-spacing-small-details-margin-bottom": "1rem",
        "components-article-hero-spacing-small-space-between": "0.75rem",
        "components-article-hero-spacing-small-body-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-image-space-between": "0.75rem",
        "components-article-hero-spacing-small-image-margin-bottom": "1rem",
        "components-article-hero-spacing-small-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-stats-margin-bottom": "2rem",
        "components-banner-spacing-large-content-padding-x": "1.5rem",
        "components-banner-spacing-large-content-space-between": "1rem",
        "components-banner-spacing-large-content-width": "69.375rem",
        "components-banner-spacing-icon-dimensions": "1.5rem",
        "components-banner-spacing-icon-margin-left": "0.5rem",
        "components-banner-spacing-small-space-between": "1rem",
        "components-banner-spacing-small-content-space-between": "1rem",
        "components-banner-spacing-small-title-margin-bottom": "0.75rem",
        "components-banner-spacing-small-content-margin-bottom": "1rem",
        "components-breadcrumb-spacing-large-space-between": "0.25rem",
        "components-breadcrumb-spacing-small-space-between": "0.25rem",
        "components-button-spacing-large-icon-large-x": "2.5rem",
        "components-button-spacing-large-icon-large-y": "2.5rem",
        "components-button-spacing-large-default-padding-y": "0.75rem",
        "components-button-spacing-large-default-padding-x": "1.5rem",
        "components-button-spacing-large-default-space-between": "0.25rem",
        "components-button-spacing-large-icon-left-padding-left": "1rem",
        "components-button-spacing-large-icon-right-padding-right": "1rem",
        "components-button-spacing-large-icon-only-padding-x": "0.75rem",
        "components-button-spacing-large-icon-only-padding-y": "0.75rem",
        "components-button-spacing-large-tertiary-content-height": "1.5rem",
        "components-button-spacing-compressed-icon-large-x": "2.5rem",
        "components-button-spacing-compressed-icon-large-y": "2.5rem",
        "components-button-spacing-compressed-default-padding-y": "0.5rem",
        "components-button-spacing-compressed-default-padding-x": "1rem",
        "components-button-spacing-compressed-default-space-between": "0.125rem",
        "components-button-spacing-compressed-icon-left-padding-left": "0.5rem",
        "components-button-spacing-compressed-icon-right-padding-right": "0.5rem",
        "components-button-spacing-compressed-icon-only-padding-x": "0.5rem",
        "components-button-group-spacing-padding-y": "0.125rem",
        "components-button-group-spacing-padding-x": "0.25rem",
        "components-button-group-spacing-space-between": "0.5rem",
        "components-category-card-spacing-large-padding-top": "0rem",
        "components-category-card-spacing-large-padding-x": "0rem",
        "components-category-card-spacing-large-padding-y": "0rem",
        "components-category-card-spacing-large-padding-image-x": "0rem",
        "components-category-card-spacing-large-padding-image-y": "0rem",
        "components-category-card-spacing-large-padding-content-x": "2rem",
        "components-category-card-spacing-large-padding-content-y": "2rem",
        "components-category-card-listing-spacing-large-padding-y": "5rem",
        "components-category-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-category-card-listing-spacing-large-card-margin-bottom": "0rem",
        "components-category-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-card-search-result-small-padding-top": "0rem",
        "components-card-search-result-small-padding-x": "0rem",
        "components-card-search-result-small-padding-y": "0rem",
        "components-card-search-result-small-padding-image-x": "0rem",
        "components-card-search-result-small-padding-image-y": "0rem",
        "components-card-search-result-small-padding-content-x": "1.5rem",
        "components-card-search-result-small-padding-content-y": "1.5rem",
        "components-card-search-result-spacing-large-padding-x": "0rem",
        "components-card-search-result-spacing-large-padding-y": "1.5rem",
        "components-card-search-result-spacing-large-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-image-margin-right": "1.5rem",
        "components-card-search-result-spacing-small-padding-x": "1.5rem",
        "components-card-search-result-spacing-small-padding-y": "1.5rem",
        "components-card-search-result-spacing-small-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-image-margin-right": "0.75rem",
        "components-contact-us-promo-spacing-large-padding-x": "5rem",
        "components-contact-us-promo-spacing-large-padding-y": "5rem",
        "components-contact-us-promo-spacing-large-content-margin-right": "5rem",
        "components-contact-us-promo-spacing-large-margin-y": "2.5rem",
        "components-contact-us-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-contact-us-promo-spacing-small-margin-y": "2.5rem",
        "components-content-list-list-item-container-padding-x": "0.75rem",
        "components-content-list-list-item-container-padding-y": "1rem",
        "components-content-list-list-item-content-space-between": "0.5rem",
        "components-content-list-list-item-content-default-padding-y": "0.25rem",
        "components-content-list-list-item-content-hyperlink-padding-y": "0rem",
        "components-content-list-spacing-large-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-large-item-title-margin-bottom": "1rem",
        "components-content-list-spacing-large-item-copy-margin-bottom": "2.5rem",
        "components-content-list-spacing-large-item-icon-copy-margin-top": "0rem",
        "components-content-list-spacing-large-item-margin-bottom": "2rem",
        "components-content-list-spacing-large-item-image-margin-bottom": "0.5rem",
        "components-content-list-spacing-large-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-y": "2.5rem",
        "components-content-list-spacing-small-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-small-item-title-margin-bottom": "0.5rem",
        "components-content-list-spacing-small-item-copy-margin-bottom": "1rem",
        "components-content-list-spacing-small-item-margin-bottom": "1.5rem",
        "components-content-list-spacing-small-header-title-margin-bottom": "1rem",
        "components-content-list-spacing-small-header-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-title-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-button-space-between": "1rem",
        "components-dropdown-item-padding-y": "0.75rem",
        "components-dropdown-item-padding-x": "0.75rem",
        "components-dropdown-item-spacing-x": "0.75rem",
        "components-dropdown-input-padding-y": "0.25rem",
        "components-dropdown-input-padding-x": "1rem",
        "components-footer-brand-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-large-column-margin-right": "4rem",
        "components-footer-brand-spacing-large-column-margin-bottom": "4rem",
        "components-footer-brand-spacing-large-link-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-padding-y": "2.5rem",
        "components-footer-brand-spacing-small-padding-x": "1rem",
        "components-footer-brand-spacing-small-logo-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-column-margin-right": "0.125rem",
        "components-footer-brand-spacing-small-column-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-small-link-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-title-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-text-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-footer-link-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-link-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-link-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-link-minheight": "3rem",
        "components-footer-brand-spacing-small-footer-category-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-category-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-category-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-category-minheight": "3rem",
        "components-footer-global-spacing-large-padding-y": "2.5rem",
        "components-footer-global-spacing-large-padding-x": "5rem",
        "components-footer-global-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-global-spacing-large-column-margin-right": "2rem",
        "components-footer-global-spacing-large-link-margin-bottom": "2rem",
        "components-footer-global-spacing-small-padding-y": "2.5rem",
        "components-footer-global-spacing-small-padding-x": "1rem",
        "components-footer-global-spacing-small-logo-margin-bottom": "1rem",
        "components-footer-global-spacing-small-column-margin-right": "1rem",
        "components-footer-global-spacing-small-link-margin-bottom": "1rem",
        "components-generic-card-spacing-large-padding-x": "0rem",
        "components-generic-card-spacing-large-padding-y": "0rem",
        "components-generic-card-spacing-large-padding-image-x": "0rem",
        "components-generic-card-spacing-large-padding-image-y": "0rem",
        "components-generic-card-spacing-large-padding-content-x": "2rem",
        "components-generic-card-spacing-large-padding-content-y": "2rem",
        "components-generic-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-generic-card-spacing-large-title-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-button-space-between": "1rem",
        "components-generic-card-spacing-small-padding-x": "0rem",
        "components-generic-card-spacing-small-padding-y": "0rem",
        "components-generic-card-spacing-small-padding-image-x": "0rem",
        "components-generic-card-spacing-small-padding-image-y": "0rem",
        "components-generic-card-spacing-small-padding-content-x": "1.5rem",
        "components-generic-card-spacing-small-padding-content-y": "1.5rem",
        "components-global-search-spacing-omnibox-padding-x": "0.125rem",
        "components-global-search-spacing-omnibox-list-padding-y": "0.5rem",
        "components-global-search-spacing-omnibox-label-padding": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-item-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-height": "3rem",
        "components-generic-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-generic-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-header-shadow-x": "0rem",
        "components-header-spacing-large-padding-y": "0.5rem",
        "components-header-spacing-large-padding-x": "1.5rem",
        "components-header-spacing-large-links-margin-right": "1.5rem",
        "components-header-spacing-large-icons-space-between": "1.5rem",
        "components-header-spacing-large-logo-margin-right": "2rem",
        "components-header-spacing-large-space-between-search": "0.5rem",
        "components-header-spacing-large-height": "4.5rem",
        "components-header-spacing-large-language-selector-padding-x": "1rem",
        "components-header-spacing-large-language-selector-padding-y": "0.5rem",
        "components-header-spacing-large-language-selector-space-between": "0.75rem",
        "components-header-spacing-large-language-selector-country-space-between": "0.5rem",
        "components-header-spacing-large-mega-menu-padding-y": "2.5rem",
        "components-header-spacing-large-mega-menu-padding-x": "2.5rem",
        "components-header-spacing-large-mega-menu-column-margin-right": "2.5rem",
        "components-header-spacing-large-mega-menu-category-link-margin-bottom": "1rem",
        "components-header-spacing-large-mega-menu-link-margin-bottom": "0.5rem",
        "components-header-spacing-large-mega-menu-featured-padding-x": "2rem",
        "components-header-spacing-large-mega-menu-featured-padding-y": "2rem",
        "components-header-spacing-large-mega-menu-featured-image-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-title-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-link-space-between": "1.5rem",
        "components-header-spacing-large-mega-menu-title-margin-bottom": "2.5rem",
        "components-header-spacing-large-dropdown-padding-y": "1.5rem",
        "components-header-spacing-large-dropdown-padding-x": "1.5rem",
        "components-header-spacing-large-dropdown-margin-bottom": "0.5rem",
        "components-header-spacing-small-language-selector-padding-x": "1rem",
        "components-header-spacing-small-language-selector-padding-y": "0.5rem",
        "components-header-spacing-small-language-selector-space-between": "0.5rem",
        "components-header-spacing-small-padding-left": "1rem",
        "components-header-spacing-small-padding-right": "1rem",
        "components-header-spacing-small-padding-top": "0.5rem",
        "components-header-spacing-small-padding-bottom": "0.5rem",
        "components-header-spacing-small-nav-main-minheight": "3rem",
        "components-header-spacing-small-nav-main-padding-y": "1rem",
        "components-header-spacing-small-nav-main-padding-left": "1.5rem",
        "components-header-spacing-small-nav-main-padding-right": "1rem",
        "components-header-spacing-small-nav-category-minheight": "3rem",
        "components-header-spacing-small-nav-category-padding-left": "1.5rem",
        "components-header-spacing-small-nav-category-padding-right": "0.75rem",
        "components-header-spacing-small-nav-category-padding-y": "1.5rem",
        "components-header-spacing-small-nav-category-space-between": "0rem",
        "components-header-spacing-small-nav-menu-link-minheight": "3rem",
        "components-header-spacing-small-nav-menu-link-padding-right": "1.5rem",
        "components-header-spacing-small-nav-menu-link-padding-left": "2.5rem",
        "components-header-spacing-small-nav-menu-link-padding-y": "0.75rem",
        "components-header-spacing-small-mega-menu-padding-y": "0rem",
        "components-header-spacing-small-mega-menu-padding-x": "0rem",
        "components-header-spacing-small-mega-menu-category-link-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-link-margin-bottom": "0rem",
        "components-header-spacing-small-mega-menu-featured-padding-x": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-padding-y": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-image-margin-bottom": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-title-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-featured-link-space-between": "0.75rem",
        "components-header-spacing-small-mega-menu-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-container-padding": "0rem",
        "components-hero-homepage-spacing-large-padding-x": "4rem",
        "components-hero-homepage-spacing-large-padding-y": "2.5rem",
        "components-hero-homepage-spacing-large-minheight": "34.3125rem",
        "components-hero-homepage-spacing-large-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-margin-bottom": "2rem",
        "components-hero-homepage-spacing-large-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-copy-margin-bottom": "2.5rem",
        "components-hero-homepage-spacing-large-cta-margin-right": "1rem",
        "components-hero-homepage-spacing-large-image-height": "34.3125rem",
        "components-hero-homepage-spacing-large-image-width": "45.75rem",
        "components-hero-homepage-spacing-small-padding-x": "0rem",
        "components-hero-homepage-spacing-small-padding-y": "1.5rem",
        "components-hero-homepage-spacing-small-minheight": "34.3125rem",
        "components-hero-homepage-spacing-small-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-copy-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-image-height": "17.5625rem",
        "components-hero-homepage-spacing-small-image-width": "45.75rem",
        "components-hero-internal-spacing-large-padding-y": "2.5rem",
        "components-hero-internal-spacing-large-padding-right": "10rem",
        "components-hero-internal-spacing-large-title-margin-bottom": "1.5rem",
        "components-hero-internal-spacing-large-button-padding-y": "2rem",
        "components-hero-internal-spacing-large-text-maxwidth": "59.625rem",
        "components-hero-internal-spacing-large-minheight": "18.5rem",
        "components-hero-internal-spacing-small-padding-x": "1.5rem",
        "components-hero-internal-spacing-small-padding-right": "0rem",
        "components-hero-internal-spacing-small-button-padding-y": "1.5rem",
        "components-hero-internal-spacing-small-text-maxwidth": "30rem",
        "components-hero-internal-spacing-small-minheight": "13.5rem",
        "components-icon-card-spacing-large-padding-x": "0rem",
        "components-icon-card-spacing-large-padding-y": "0rem",
        "components-icon-card-spacing-large-icon-margin": "1.5rem",
        "components-icon-card-spacing-large-margin-right": "1.5rem",
        "components-icon-card-spacing-large-title-margin-bottom": "1rem",
        "components-icon-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-icon-card-spacing-large-button-space-between": "1.5rem",
        "components-icon-card-spacing-large-top-space-between": "1rem",
        "components-icon-card-spacing-small-padding-x": "1.5rem",
        "components-icon-card-spacing-small-padding-y": "1.5rem",
        "components-icon-card-spacing-small-icon-margin": "1.5rem",
        "components-icon-card-spacing-small-margin-right": "1.5rem",
        "components-icon-card-spacing-small-title-margin-bottom": "1.5rem",
        "components-icon-card-dimensions-large-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-large-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-large-big-icon-height": "4rem",
        "components-icon-card-dimensions-large-big-icon-width": "4rem",
        "components-icon-card-dimensions-large-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-large-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-large-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-large-tiny-icon-width": "2.5rem",
        "components-icon-card-dimensions-small-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-small-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-small-big-icon-height": "3.5rem",
        "components-icon-card-dimensions-small-big-icon-width": "3.5rem",
        "components-icon-card-dimensions-small-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-small-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-small-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-small-tiny-icon-width": "2.5rem",
        "components-icon-card-listing-spacing-large-padding-inner-x": "4rem",
        "components-icon-card-listing-spacing-large-padding-inner-y": "4rem",
        "components-icon-card-listing-spacing-large-card-space-between": "4rem",
        "components-icon-card-listing-spacing-large-title-margin-bottom": "1.5rem",
        "components-icon-card-listing-spacing-large-card-margin-bottom": "4rem",
        "components-icon-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-x": "1.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-y": "2.5rem",
        "components-icon-card-listing-spacing-small-title-margin-bottom": "1rem",
        "components-icon-card-listing-spacing-small-button-margin-top": "1.5rem",
        "components-jumplink-link-padding-bottom": "0.5rem",
        "components-jumplink-spacing-small-title-area-margin-bottom": "1.5rem",
        "components-jumplink-spacing-small-link-container-padding-x": "0rem",
        "components-lightbox-spacing-large-carousel-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-carousel-space-between": "1.5rem",
        "components-lightbox-spacing-large-featured-image-height": "39.25rem",
        "components-lightbox-spacing-large-featured-image-width": "39.25rem",
        "components-lightbox-spacing-large-image-height": "17.0625rem",
        "components-lightbox-spacing-large-image-width": "17.0625rem",
        "components-lightbox-spacing-small-carousel-margin-bottom": "1rem",
        "components-lightbox-spacing-small-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-small-media-details-margin-top": "1rem",
        "components-lightbox-spacing-small-image-height": "20.4375rem",
        "components-lightbox-spacing-small-image-width": "20.4375rem",
        "components-legal-disclaimer-spacing-small-ruleline-width": "20.4375rem",
        "components-media-spacing-large-padding-y": "0.5rem",
        "components-media-spacing-large-padding-x": "0.75rem",
        "components-media-gallery-large-container-grid-gap-x": "1.5rem",
        "components-media-gallery-large-container-grid-gap-y": "1.5rem",
        "components-media-gallery-small-container-grid-gap-x": "0.5rem",
        "components-media-gallery-small-container-grid-gap-y": "0.5rem",
        "components-media-gallery-spacing-small-padding-x": "1.5rem",
        "components-media-gallery-spacing-small-padding-y": "2.5rem",
        "components-media-gallery-spacing-large-padding-x": "5rem",
        "components-media-gallery-spacing-large-padding-y": "5rem",
        "components-product-card-spacing-list-content-gap": "0rem",
        "components-product-card-spacing-large-padding-image-x": "0rem",
        "components-product-card-spacing-large-padding-image-y": "0rem",
        "components-product-card-spacing-large-padding-content-x": "1.5rem",
        "components-product-card-spacing-large-padding-content-y": "1.5rem",
        "components-product-card-spacing-large-category-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-title-margin-bottom": "1rem",
        "components-product-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-button-space-between": "1rem",
        "components-product-card-spacing-small-padding-image-x": "0rem",
        "components-product-card-spacing-small-padding-image-y": "0rem",
        "components-product-card-spacing-small-padding-content-x": "1.5rem",
        "components-product-card-spacing-small-padding-content-y": "1.5rem",
        "components-product-card-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-large-padding-x": "5rem",
        "components-product-information-spacing-large-padding-y": "2.5rem",
        "components-product-information-spacing-large-title-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-label-margin-bottom": "2.5rem",
        "components-product-information-spacing-large-ratings-margin-bottom": "1rem",
        "components-product-information-spacing-large-details-margin-bottom": "1rem",
        "components-product-information-spacing-large-space-between": "1rem",
        "components-product-information-spacing-large-carousel-space-between": "5rem",
        "components-product-information-spacing-large-button-margin-y": "0.5rem",
        "components-product-information-spacing-large-body-margin-top": "1.5rem",
        "components-product-information-spacing-large-image-space-between": "1rem",
        "components-product-information-spacing-large-image-margin-bottom": "1rem",
        "components-product-information-spacing-small-padding-x": "1.5rem",
        "components-product-information-spacing-small-padding-y": "1.5rem",
        "components-product-information-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-small-label-margin-bottom": "1.5rem",
        "components-product-information-spacing-small-ratings-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-details-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-space-between": "0.75rem",
        "components-product-information-spacing-small-carousel-space-between": "2.5rem",
        "components-product-information-spacing-small-button-margin-y": "0.75rem",
        "components-product-information-spacing-small-body-margin-top": "1rem",
        "components-product-information-spacing-small-image-space-between": "0.75rem",
        "components-product-information-spacing-small-image-margin-bottom": "1rem",
        "components-product-results-list-with-search-spacing-large-padding-x": "5rem",
        "components-product-results-list-with-search-spacing-large-padding-y": "5rem",
        "components-product-results-list-with-search-spacing-large-ruleline-margin-top": "2.5rem",
        "components-product-results-list-with-search-spacing-large-filter-space-between": "1rem",
        "components-product-results-list-with-search-spacing-large-card-space-between": "1.5rem",
        "components-product-results-list-with-search-spacing-large-card-container-margin-bottom": "5rem",
        "components-product-results-list-with-search-spacing-small-padding-x": "1.5rem",
        "components-product-results-list-with-search-spacing-small-padding-y": "2.5rem",
        "components-product-results-list-with-search-spacing-small-ruleline-margin-top": "1rem",
        "components-product-results-list-with-search-spacing-small-filter-padding-x": "0.5rem",
        "components-product-results-list-with-search-spacing-small-card-space-between": "1rem",
        "components-product-results-list-with-search-spacing-small-card-margin-bottom": "1.5rem",
        "components-promo-spacing-large-padding-x": "4rem",
        "components-promo-spacing-large-padding-y": "4rem",
        "components-promo-spacing-large-content-space-between": "3rem",
        "components-promo-spacing-large-content-space-between-y": "3rem",
        "components-promo-spacing-large-padding-content-x": "0rem",
        "components-promo-spacing-large-padding-content-condensed-x": "0rem",
        "components-promo-spacing-large-padding-content-y": "0rem",
        "components-promo-spacing-large-padding-content-condensed-y": "0rem",
        "components-promo-spacing-large-padding-image-x": "1.5rem",
        "components-promo-spacing-large-padding-image-y": "1.5rem",
        "components-promo-spacing-large-padding-image-top": "5rem",
        "components-promo-spacing-large-title-margin-bottom": "2rem",
        "components-promo-spacing-large-margin-y": "2rem",
        "components-promo-spacing-large-buttons-space-between": "1rem",
        "components-promo-spacing-small-padding-x": "0rem",
        "components-promo-spacing-small-padding-y": "0rem",
        "components-promo-spacing-small-padding-top": "0rem",
        "components-promo-spacing-small-padding-content-x": "1.5rem",
        "components-promo-spacing-small-padding-content-y": "2.5rem",
        "components-promo-spacing-small-padding-image-x": "1rem",
        "components-promo-spacing-small-padding-image-y": "0rem",
        "components-promo-spacing-small-padding-image-top": "1rem",
        "components-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-promo-spacing-small-margin-y": "2.5rem",
        "components-promo-minheight": "30rem",
        "components-pull-quote-spacing-large-padding-quote-x": "4rem",
        "components-pull-quote-spacing-large-padding-quote-y": "4rem",
        "components-pull-quote-spacing-large-title-margin-bottom": "2rem",
        "components-pull-quote-spacing-large-quote-margin-bottom": "4rem",
        "components-pull-quote-spacing-large-quote-space-between": "1.5rem",
        "components-pull-quote-spacing-large-detail-space-between": "0.5rem",
        "components-pull-quote-spacing-large-quote-minheight": "25rem",
        "components-pull-quote-spacing-small-padding-quote-x": "1.5rem",
        "components-pull-quote-spacing-small-padding-quote-y": "2.5rem",
        "components-pull-quote-spacing-small-title-margin-bottom": "1.5rem",
        "components-pull-quote-spacing-small-quote-margin-bottom": "2.5rem",
        "components-pull-quote-spacing-small-quote-space-between": "1rem",
        "components-pull-quote-spacing-small-detail-space-between": "1rem",
        "components-pull-quote-spacing-small-quote-minheight": "15rem",
        "components-promo-alternate-minheight": "30rem",
        "components-promo-alternate-spacing-large-padding-x": "4rem",
        "components-promo-alternate-spacing-large-padding-y": "4rem",
        "components-promo-alternate-spacing-large-content-space-between": "3rem",
        "components-promo-alternate-spacing-large-padding-content-x": "0rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-x": "0rem",
        "components-promo-alternate-spacing-large-padding-content-y": "0rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-y": "0rem",
        "components-promo-alternate-spacing-large-padding-image-x": "1.5rem",
        "components-promo-alternate-spacing-large-padding-image-y": "1.5rem",
        "components-promo-alternate-spacing-large-padding-image-top": "5rem",
        "components-promo-alternate-spacing-large-title-margin-bottom": "2rem",
        "components-promo-alternate-spacing-small-padding-x": "0rem",
        "components-promo-alternate-spacing-small-padding-y": "0rem",
        "components-promo-alternate-spacing-small-padding-content-x": "1.5rem",
        "components-promo-alternate-spacing-small-padding-content-y": "2.5rem",
        "components-promo-alternate-spacing-small-padding-image-x": "1rem",
        "components-promo-alternate-spacing-small-padding-image-y": "0rem",
        "components-promo-alternate-spacing-small-padding-image-top": "1rem",
        "components-promo-alternate-spacing-small-title-margin-bottom": "1.5rem",
        "components-share-list-spacing-padding-x": "1rem",
        "components-share-list-spacing-padding-y": "1rem",
        "components-header-copy-margin-bottom": "1rem",
        "components-header-margin-bottom": "1.5rem",
        "components-item-margin-bottom": "1.5rem",
        "components-site-search-padding-x": "2.5rem",
        "components-site-search-padding-y": "5rem",
        "components-site-search-facet-label-space-between": "0.25rem",
        "components-site-search-refine-padding-x": "1.5rem",
        "components-site-search-refine-padding-y": "0rem",
        "components-site-search-hero-search-height": "18.5rem",
        "components-site-search-hero-search-large-padding-x": "5rem",
        "components-site-search-hero-search-large-padding-y": "5rem",
        "components-stats-spacing-stat-text-space-between": "0.5rem",
        "components-stats-spacing-large-inner-padding-x": "5rem",
        "components-stats-spacing-large-inner-padding-y": "5rem",
        "components-stats-spacing-small-inner-padding-x": "1.5rem",
        "components-stats-spacing-small-inner-padding-y": "1.5rem",
        "components-icon-card-listing-spacing-small-top-space-between": "1rem",
        "components-supporting-text-padding-x": "0rem",
        "components-supporting-text-padding-bottom": "0.5rem",
        "components-tab-item-padding-y": "0.75rem",
        "components-tab-item-padding-x": "0.75rem",
        "components-tab-item-spacing-x": "0.75rem",
        "components-tab-input-padding-y": "0.25rem",
        "components-tab-input-padding-x": "1rem",
        "components-tag-spacing-padding-x": "0.5rem",
        "components-tag-spacing-padding-y": "0.5rem",
        "components-tag-spacing-space-between": "0.5rem",
        "components-text-field-input-padding-y": "0.25rem",
        "components-text-field-input-padding-x": "1rem",
        "components-text-field-area-input-padding-y": "1rem",
        "components-text-field-area-input-padding-x": "1rem",
        "components-text-field-supporting-text-padding-x": "0rem",
        "components-text-field-supporting-text-padding-top": "0.5rem",
        "themes-general-surface-spacing-large-padding-x": "4rem",
        "themes-general-surface-spacing-large-padding-y": "4rem",
        "themes-general-surface-spacing-large-title-margin-bottom": "2rem",
        "themes-general-surface-spacing-large-body-margin-right": "4rem",
        "themes-general-surface-spacing-large-body-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-large-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-large-cards-space-between": "1.5rem",
        "themes-general-surface-spacing-large-button-margin-top": "2.5rem",
        "themes-general-surface-spacing-small-padding-x": "1.5rem",
        "themes-general-surface-spacing-small-padding-y": "2.5rem",
        "themes-general-surface-spacing-small-title-margin-bottom": "1rem",
        "themes-general-surface-spacing-small-body-margin-right": "2.5rem",
        "themes-general-surface-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-surface-spacing-small-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-small-cards-space-between": "1rem",
        "themes-general-surface-spacing-small-button-margin-top": "1.5rem",
        "themes-general-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "themes-general-card-spacing-large-title-margin-bottom": "1rem",
        "themes-general-card-spacing-large-body-margin-bottom": "2rem",
        "themes-general-card-spacing-large-button-space-between": "1rem",
        "themes-general-card-spacing-small-eyebrow-margin-bottom": "1.5rem",
        "themes-general-card-spacing-small-title-margin-bottom": "1rem",
        "themes-general-card-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-small-button-space-between": "1rem",
        "themes-shadow-share-list-spacing-x": "0rem",
        "themes-shadow-share-list-spacing-y": "0.75rem",
        "themes-shadow-share-list-spacing-blur": "1rem",
        "themes-shadow-share-list-spacing-spread": "-0.5rem",
        "themes-shadow-header-menu-spacing-x": "0rem",
        "themes-shadow-header-menu-spacing-y": "0.75rem",
        "themes-shadow-header-menu-spacing-blur": "1rem",
        "themes-shadow-header-menu-spacing-spread": "-0.5rem",
        "themes-typography-rte-large-h1-textsize": "3rem",
        "themes-typography-rte-large-h2-textsize": "2.5rem",
        "themes-typography-rte-large-h3-textsize": "2.25rem",
        "themes-typography-rte-large-h4-textsize": "2rem",
        "themes-typography-rte-large-h5-textsize": "1.5rem",
        "themes-typography-rte-large-h6-textsize": "1.25rem",
        "themes-typography-rte-medium-h1-textsize": "2.5rem",
        "themes-typography-rte-medium-h2-textsize": "2.25rem",
        "themes-typography-rte-medium-h3-textsize": "1.75rem",
        "themes-typography-rte-medium-h4-textsize": "1.5rem",
        "themes-typography-rte-medium-h5-textsize": "1.25rem",
        "themes-typography-rte-medium-h6-textsize": "1.125rem",
        "themes-typography-rte-small-h1-textsize": "2.5rem",
        "themes-typography-rte-small-h2-textsize": "2rem",
        "themes-typography-rte-small-h3-textsize": "1.75rem",
        "themes-typography-rte-small-h4-textsize": "1.5rem",
        "themes-typography-rte-small-h5-textsize": "1.125rem",
        "themes-typography-rte-small-h6-textsize": "1rem",
        "spacing-margin-small-1": "0.125rem",
        "spacing-margin-small-2": "0.25rem",
        "spacing-margin-small-3": "0.5rem",
        "spacing-margin-small-4": "0.75rem",
        "spacing-margin-small-5": "1rem",
        "spacing-margin-small-6": "1.5rem",
        "spacing-margin-small-7": "2.5rem",
        "spacing-margin-medium-2": "0.75rem",
        "spacing-margin-medium-4": "1.5rem",
        "spacing-margin-medium-5": "2rem",
        "spacing-margin-large-1": "0.5rem",
        "spacing-margin-large-2": "1rem",
        "spacing-margin-large-3": "1.5rem",
        "spacing-margin-large-4": "2rem",
        "spacing-margin-large-5": "2.5rem",
        "spacing-margin-large-6": "4rem",
        "spacing-margin-large-7": "5rem",
        "spacing-space-between-small-1": "0.125rem",
        "spacing-space-between-small-2": "0.25rem",
        "spacing-space-between-small-3": "0.5rem",
        "spacing-space-between-small-4": "0.75rem",
        "spacing-space-between-small-5": "1rem",
        "spacing-space-between-small-6": "1.5rem",
        "spacing-space-between-small-7": "2.5rem",
        "spacing-space-between-medium-1": "0.125rem",
        "spacing-space-between-medium-2": "0.25rem",
        "spacing-space-between-medium-3": "0.5rem",
        "spacing-space-between-medium-4": "0.75rem",
        "spacing-space-between-medium-5": "1rem",
        "spacing-space-between-large-1": "0.25rem",
        "spacing-space-between-large-2": "0.5rem",
        "spacing-space-between-large-3": "1rem",
        "spacing-space-between-large-4": "1.5rem",
        "spacing-space-between-large-5": "1.5rem",
        "spacing-space-between-large-6": "2.5rem",
        "spacing-space-between-large-7": "4rem",
        "spacing-padding-small-1": "0.125rem",
        "spacing-padding-small-2": "0.25rem",
        "spacing-padding-small-3": "0.5rem",
        "spacing-padding-small-4": "0.75rem",
        "spacing-padding-small-5": "1rem",
        "spacing-padding-small-6": "1.5rem",
        "spacing-padding-small-7": "2.5rem",
        "spacing-padding-medium-1": "0.25rem",
        "spacing-padding-medium-2": "0.75rem",
        "spacing-padding-medium-3": "1rem",
        "spacing-padding-medium-4": "1.5rem",
        "spacing-padding-medium-5": "2rem",
        "spacing-padding-medium-6": "3rem",
        "spacing-padding-medium-7": "4rem",
        "spacing-padding-large-1": "0.5rem",
        "spacing-padding-large-2": "1rem",
        "spacing-padding-large-3": "1.5rem",
        "spacing-padding-large-4": "2rem",
        "spacing-padding-large-5": "2.5rem",
        "spacing-padding-large-6": "4rem",
        "spacing-padding-large-7": "5rem"
      },
      "string": {
        "components-accordion-section-button": "var(--brand)",
        "components-article-card-listing-button-theme": "var(--tonal)",
        "components-article-hero-pagination-buttons": "var(--white)",
        "components-banner-banner-button": "var(--white)",
        "components-category-card-listing-button-theme": "var(--tonal)",
        "components-contact-us-promo-hierarchy-button-theme-1": "var(--filled)",
        "components-contact-us-promo-hierarchy-button-theme-2": "var(--outlined)",
        "components-contact-us-promo-color-button-theme": "var(--brand)",
        "components-contact-us-promo-color-brand-button-theme": "var(--white)",
        "components-generic-card-button-theme": "var(--brand)",
        "components-global-search-loader": "var(--default)",
        "components-generic-card-listing-decor-top": "var(--duck-1)",
        "components-generic-card-listing-decor-bottom": "var(--duck-2)",
        "components-hero-homepage-pagination-buttons": "var(--tonal)",
        "components-hero-homepage-pattern": "var(--duck)",
        "components-hero-homepage-button-1": "var(--brand)",
        "components-hero-homepage-button-2": "var(--white)",
        "components-hero-homepage-image-ratio": "var(--4:3)",
        "components-hero-internal-theme-button-one-type": "var(--filled)",
        "components-hero-internal-theme-button-two-type": "var(--outlined)",
        "components-hero-internal-theme-button-color": "var(--tonal)",
        "components-hero-internal-theme-button-color-with-image": "var(--tonal)",
        "components-lightbox-button-type": "var(--brand)",
        "components-pdf-link-card-hierarchy-button-theme-1": "var(--filled)",
        "components-pdf-link-card-hierarchy-button-theme-2": "var(--outlined)",
        "components-pdf-link-card-color-button-theme": "var(--brand)",
        "components-product-card-badge-align": "var(--left)",
        "components-product-card-listing-button-color": "var(--white)",
        "components-promo-theme-button-one-theme": "var(--filled)",
        "components-promo-theme-button-two-theme": "var(--outlined)",
        "components-promo-color-default-button-theme": "var(--brand)",
        "components-promo-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-color-brand-button-theme": "var(--white)",
        "components-pull-quote-border-decor": "var(--duck)",
        "components-promo-alternate-theme-button-one-theme": "var(--filled)",
        "components-promo-alternate-theme-button-two-theme": "var(--outlined)",
        "components-promo-alternate-color-default-button-theme": "var(--brand)",
        "components-promo-alternate-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-alternate-color-brand-button-theme": "var(--white)",
        "components-share-list-button-color": "var(--white)",
        "components-site-search-hero-search-hero-pattern": "var(--duck)",
        "components-stats-decor-top": "var(--duck-1)",
        "components-stats-decor-bottom": "var(--duck-2)",
        "themes-brand-loader": "var(--default)",
        "themes-brand-variants": "var(--duck)",
        "themes-tab-variant": "var(--default)",
        "themes-label-variants": "var(--default)",
        "themes-logo": "var(--duck)",
        "themes-category-card": "var(--duck)",
        "themes-title-decor": "var(--off)",
        "themes-typography-heading-fontfamily": "var(--gotham-ssm)",
        "themes-typography-heading-large-xxlarge-fontweight": "var(--bold)",
        "themes-typography-heading-large-xlarge-fontweight": "var(--bold)",
        "themes-typography-heading-large-large-fontweight": "var(--bold)",
        "themes-typography-heading-large-medium-fontweight": "var(--bold)",
        "themes-typography-heading-large-small-fontweight": "var(--bold)",
        "themes-typography-heading-large-xsmall-fontweight": "var(--bold)",
        "themes-typography-heading-large-xxsmall-fontweight": "var(--bold)",
        "themes-typography-heading-medium-xxlarge-fontweight": "var(--bold)",
        "themes-typography-heading-medium-xlarge-fontweight": "var(--bold)",
        "themes-typography-heading-medium-large-fontweight": "var(--bold)",
        "themes-typography-heading-medium-medium-fontweight": "var(--bold)",
        "themes-typography-heading-medium-small-fontweight": "var(--bold)",
        "themes-typography-heading-medium-xsmall-fontweight": "var(--bold)",
        "themes-typography-heading-medium-xxsmall-fontweight": "var(--bold)",
        "themes-typography-heading-small-xxlarge-fontweight": "var(--boldblack)",
        "themes-typography-heading-small-xlarge-fontweight": "var(--bold)",
        "themes-typography-heading-small-large-fontweight": "var(--bold)",
        "themes-typography-heading-small-medium-fontweight": "var(--bold)",
        "themes-typography-heading-small-small-fontweight": "var(--bold)",
        "themes-typography-heading-small-xsmall-fontweight": "var(--bold)",
        "themes-typography-heading-small-xxsmall-fontweight": "var(--semibold)",
        "themes-typography-rte-fontfamily": "var(--gotham-ssm)",
        "themes-typography-rte-fontweight": "var(--bold)"
      },
      "borderRadius": {
        "components-accordion-spacing-large-item-item-radius": "0rem",
        "components-article-card-spacing-large-image-radius-tl": "1rem",
        "components-article-card-spacing-large-image-radius-tr": "1rem",
        "components-article-card-spacing-large-image-radius-bl": "0rem",
        "components-article-card-spacing-large-image-radius-br": "0rem",
        "components-article-card-spacing-large-list-image-radius-br": "0rem",
        "components-article-card-spacing-small-image-radius-br": "0rem",
        "components-article-card-spacing-small-list-image-radius-br": "0rem",
        "components-article-hero-spacing-large-content-radius": "1rem",
        "components-article-hero-spacing-small-content-radius": "0rem",
        "components-button-radius-large": "7.5rem",
        "components-button-group-spacing-border-radius": "7.5rem",
        "components-category-card-radius-border-width": "0.125rem",
        "components-category-card-radius-border-radius": "7.5rem",
        "components-category-card-radius-border-radius-condensed": "0.5rem",
        "components-category-card-radius-media-radius": "0rem",
        "components-content-list-icon-radius": "7.5rem",
        "components-fab-spacing-border-radius": "7.5rem",
        "components-header-spacing-large-search-border-radius-top": "0.25rem",
        "components-header-spacing-large-search-border-radius-bottom": "0.25rem",
        "components-header-spacing-large-language-selector-radius": "0.25rem",
        "components-header-spacing-large-dropdown-radius": "0rem",
        "components-hero-homepage-spacing-large-container-radius": "0rem",
        "components-hero-internal-spacing-large-icon-radius": "7.5rem",
        "components-hero-internal-spacing-small-icon-radius": "7.5rem",
        "components-lightbox-spacing-large-overlay-radius": "1rem",
        "components-pagination-scrollbar-radius": "62.5rem",
        "components-product-card-spacing-image-radius-tl": "0rem",
        "components-product-card-spacing-image-radius-tr": "0rem",
        "components-product-card-spacing-image-radius-bl": "0rem",
        "components-product-card-spacing-image-radius-br": "0rem",
        "components-product-information-spacing-large-content-radius": "1rem",
        "components-product-information-spacing-small-content-radius": "0rem",
        "components-promo-spacing-large-content-radius": "1rem",
        "components-promo-spacing-large-content-radius-tl": "1rem",
        "components-promo-spacing-large-content-radius-tr": "1rem",
        "components-promo-spacing-large-content-radius-bl": "1rem",
        "components-promo-spacing-large-content-radius-br": "1rem",
        "components-promo-spacing-large-image-radius-tl": "1rem",
        "components-promo-spacing-large-image-radius-tr": "1rem",
        "components-promo-spacing-large-image-radius-bl": "1rem",
        "components-promo-spacing-large-image-radius-br": "1rem",
        "components-promo-spacing-small-content-radius": "0.75rem",
        "components-promo-spacing-small-image-radius-tl": "0.75rem",
        "components-promo-spacing-small-image-radius-tr": "0.75rem",
        "components-promo-spacing-small-image-radius-bl": "0.75rem",
        "components-promo-spacing-small-image-radius-br": "0.75rem",
        "components-promo-alternate-spacing-large-content-radius": "1rem",
        "components-promo-alternate-spacing-large-image-radius-tl": "1rem",
        "components-promo-alternate-spacing-large-image-radius-tr": "1rem",
        "components-promo-alternate-spacing-large-image-radius-bl": "1rem",
        "components-promo-alternate-spacing-large-image-radius-br": "1rem",
        "components-promo-alternate-spacing-small-content-radius": "0.75rem",
        "components-promo-alternate-spacing-small-image-radius-tl": "0.75rem",
        "components-promo-alternate-spacing-small-image-radius-tr": "0.75rem",
        "components-promo-alternate-spacing-small-image-radius-bl": "0.75rem",
        "components-promo-alternate-spacing-small-image-radius-br": "0.75rem",
        "components-share-list-spacing-radius": "0.5rem",
        "components-share-list-spacing-link-radius": "0.5rem",
        "components-site-search-filter-radius": "0.5rem",
        "components-site-search-facet-radius": "0rem",
        "components-site-search-refine-radius": "0rem",
        "components-tag-spacing-radius": "7.5rem",
        "components-tip-spacing-radius": "0.5rem",
        "themes-spacing-radius-pagination-bar": "62.5rem",
        "themes-spacing-radius-large-button": "7.5rem",
        "themes-spacing-radius-large-button-icon": "7.5rem",
        "themes-spacing-radius-large-image": "1rem",
        "themes-spacing-radius-large-image-hero": "0rem",
        "themes-spacing-radius-large-image-card": "0.75rem",
        "themes-spacing-radius-large-card": "1rem",
        "themes-spacing-radius-large-icon": "7.5rem",
        "themes-spacing-radius-large-label": "7.5rem",
        "themes-spacing-radius-large-tag": "7.5rem",
        "themes-spacing-radius-large-container-tip": "0.5rem",
        "themes-spacing-radius-large-badge": "0rem",
        "themes-spacing-radius-large-item": "0.5rem",
        "themes-spacing-radius-large-form-field-input": "0.25rem",
        "themes-spacing-radius-large-form-area-input": "0.25rem",
        "themes-spacing-radius-large-general": "1rem",
        "themes-spacing-radius-small-button": "7.5rem",
        "themes-spacing-radius-small-button-icon": "7.5rem",
        "themes-spacing-radius-small-image": "0.75rem",
        "themes-spacing-radius-small-image-card": "0.75rem",
        "themes-spacing-radius-small-card": "0.75rem",
        "themes-spacing-radius-small-icon": "7.5rem",
        "themes-spacing-radius-small-label": "0.25rem",
        "themes-spacing-radius-small-tag": "7.5rem",
        "themes-spacing-radius-small-badge": "0rem",
        "themes-spacing-radius-small-item": "0rem",
        "themes-spacing-radius-small-form-field-input": "0rem",
        "themes-spacing-radius-small-general": "0rem"
      },
      "borderWidth": {
        "3": "3px",
        "components-article-card-spacing-large-border-width": "0.125rem",
        "components-generic-card-color-border-width": "0rem",
        "components-header-spacing-large-search-border-width-top": "0.125rem",
        "components-header-spacing-large-search-border-width-right": "0.125rem",
        "components-header-spacing-large-search-border-width-bottom": "0.125rem",
        "components-header-spacing-large-search-border-width-left": "0.125rem",
        "components-product-card-spacing-border-width": "0.125rem"
      },
      "maxHeight": {},
      "maxWidth": {
        "components-article-hero-spacing-large-header-max-width": "66.375rem",
        "components-content-list-spacing-large-item-image-max-width": "33.75rem",
        "components-content-list-spacing-small-item-image-max-width": "33.75rem"
      },
      "minHeight": {
        "components-dropdown-item-min-height": "3rem",
        "components-icon-min-height": "3rem",
        "components-tab-item-min-height": "3rem",
        "components-text-field-input-min-height": "3rem",
        "components-text-field-icon-min-height": "3rem"
      },
      "minWidth": {
        "components-content-list-spacing-small-item-image-min-width": "18.75rem",
        "components-icon-min-width": "3rem",
        "components-text-field-icon-min-width": "3rem"
      },
      "opacity": {
        "components-accessibility-bar-color-bg": 1,
        "components-accessibility-bar-color-text": 1,
        "components-accordion-color-bg": 1,
        "components-accordion-color-title": 1,
        "components-accordion-color-body": 1,
        "components-accordion-color-section-section-bg": 1,
        "components-accordion-color-section-section-title": 1,
        "components-accordion-color-section-section-body": 1,
        "components-accordion-color-item-item-bg": 1,
        "components-accordion-color-item-divider": 1,
        "components-accordion-color-item-icon-icon-default": 1,
        "components-accordion-color-item-icon-icon-hover": 1,
        "components-accordion-color-item-icon-icon-pressed": 1,
        "components-accordion-color-item-icon-icon-active": 1,
        "components-accordion-color-item-title-item-default": 1,
        "components-accordion-color-item-title-item-hover": 1,
        "components-accordion-color-item-title-item-pressed": 1,
        "components-accordion-color-item-title-item-active": 1,
        "components-accordion-color-content-bg": 1,
        "components-accordion-color-content-title": 1,
        "components-accordion-color-content-body": 1,
        "components-article-card-color-bg": 1,
        "components-article-card-color-border": 1,
        "components-article-card-color-content-bg": 1,
        "components-article-card-color-title": 1,
        "components-article-card-color-subtitle": 1,
        "components-article-card-color-copy": 1,
        "components-article-card-color-description": 1,
        "components-article-card-listing-color-default-bg": 1,
        "components-article-card-listing-color-default-title": 1,
        "components-article-card-listing-color-default-copy": 1,
        "components-article-hero-color-content-bg": 1,
        "components-article-hero-color-bg": 1,
        "components-article-hero-color-headline": 1,
        "components-article-hero-color-body": 1,
        "components-article-hero-color-detail-text": 1,
        "components-article-hero-color-divider": 1,
        "components-article-hero-color-icon": 1,
        "components-article-hero-color-play-icon": 1,
        "components-article-hero-color-selected-stroke": 1,
        "components-banner-color-bg-default": 1,
        "components-banner-color-icon-default": 1,
        "components-banner-color-title-default": 1,
        "components-banner-color-body-default": 1,
        "components-banner-color-icon-close-default": 1,
        "components-banner-color-bg-alert": 1,
        "components-banner-color-icon-alert": 1,
        "components-banner-color-title-alert": 1,
        "components-banner-color-body-alert": 1,
        "components-banner-color-icon-close-alert": 1,
        "components-breadcrumb-color-bg": 1,
        "components-breadcrumb-color-breadcrumb-link-default": 1,
        "components-breadcrumb-color-breadcrumb-link-hover": 1,
        "components-breadcrumb-color-breadcrumb-link-active": 1,
        "components-breadcrumb-color-icon-divider": 1,
        "components-bug-finder-prompt": 1,
        "components-button-color-filled-brand-default-bg": 1,
        "components-button-color-filled-brand-default-text": 1,
        "components-button-color-filled-brand-default-stroke": 1,
        "components-button-color-filled-brand-default-icon": 1,
        "components-button-color-filled-brand-hover-bg": 1,
        "components-button-color-filled-brand-hover-text": 1,
        "components-button-color-filled-brand-hover-stroke": 1,
        "components-button-color-filled-brand-hover-icon": 1,
        "components-button-color-filled-brand-pressed-bg": 1,
        "components-button-color-filled-brand-pressed-text": 1,
        "components-button-color-filled-brand-pressed-stroke": 1,
        "components-button-color-filled-brand-pressed-icon": 1,
        "components-button-color-filled-brand-disabled-bg": 0.02,
        "components-button-color-filled-brand-disabled-text": 0.02,
        "components-button-color-filled-brand-disabled-stroke": 0.02,
        "components-button-color-filled-brand-disabled-icon": 0.02,
        "components-button-color-filled-brand-focus-bg": 1,
        "components-button-color-filled-brand-focus-text": 1,
        "components-button-color-filled-brand-focus-stroke": 1,
        "components-button-color-filled-brand-focus-icon": 1,
        "components-button-color-filled-brand-focus-outline": 1,
        "components-button-color-filled-tonal-default-bg": 1,
        "components-button-color-filled-tonal-default-text": 1,
        "components-button-color-filled-tonal-default-stroke": 1,
        "components-button-color-filled-tonal-default-icon": 1,
        "components-button-color-filled-tonal-hover-bg": 1,
        "components-button-color-filled-tonal-hover-text": 1,
        "components-button-color-filled-tonal-hover-stroke": 1,
        "components-button-color-filled-tonal-hover-icon": 1,
        "components-button-color-filled-tonal-pressed-bg": 1,
        "components-button-color-filled-tonal-pressed-text": 1,
        "components-button-color-filled-tonal-pressed-stroke": 1,
        "components-button-color-filled-tonal-pressed-icon": 1,
        "components-button-color-filled-tonal-disabled-bg": 0.03,
        "components-button-color-filled-tonal-disabled-text": 0.02,
        "components-button-color-filled-tonal-disabled-stroke": 0.02,
        "components-button-color-filled-tonal-disabled-icon": 0.02,
        "components-button-color-filled-tonal-focus-bg": 1,
        "components-button-color-filled-tonal-focus-text": 1,
        "components-button-color-filled-tonal-focus-stroke": 1,
        "components-button-color-filled-tonal-focus-icon": 1,
        "components-button-color-filled-tonal-focus-outline": 1,
        "components-button-color-filled-white-default-bg": 1,
        "components-button-color-filled-white-default-text": 1,
        "components-button-color-filled-white-default-stroke": 1,
        "components-button-color-filled-white-default-icon": 1,
        "components-button-color-filled-white-hover-bg": 1,
        "components-button-color-filled-white-hover-text": 1,
        "components-button-color-filled-white-hover-stroke": 1,
        "components-button-color-filled-white-hover-icon": 1,
        "components-button-color-filled-white-pressed-bg": 1,
        "components-button-color-filled-white-pressed-text": 1,
        "components-button-color-filled-white-pressed-stroke": 1,
        "components-button-color-filled-white-pressed-icon": 1,
        "components-button-color-filled-white-disabled-bg": 0.02,
        "components-button-color-filled-white-disabled-text": null,
        "components-button-color-filled-white-disabled-stroke": 0.02,
        "components-button-color-filled-white-disabled-icon": 0.02,
        "components-button-color-filled-white-focus-bg": 1,
        "components-button-color-filled-white-focus-text": 1,
        "components-button-color-filled-white-focus-stroke": 1,
        "components-button-color-filled-white-focus-icon": 1,
        "components-button-color-filled-white-focus-outline": 1,
        "components-button-color-outline-brand-default-bg": 0.02,
        "components-button-color-outline-brand-default-text": 1,
        "components-button-color-outline-brand-default-stroke": 1,
        "components-button-color-outline-brand-default-icon": 1,
        "components-button-color-outline-brand-hover-bg": 1,
        "components-button-color-outline-brand-hover-text": 1,
        "components-button-color-outline-brand-hover-stroke": 1,
        "components-button-color-outline-brand-hover-icon": 1,
        "components-button-color-outline-brand-pressed-bg": 1,
        "components-button-color-outline-brand-pressed-text": 1,
        "components-button-color-outline-brand-pressed-stroke": 1,
        "components-button-color-outline-brand-pressed-icon": 1,
        "components-button-color-outline-brand-disabled-bg": 0.02,
        "components-button-color-outline-brand-disabled-text": null,
        "components-button-color-outline-brand-disabled-stroke": null,
        "components-button-color-outline-brand-disabled-icon": null,
        "components-button-color-outline-brand-focus-bg": 1,
        "components-button-color-outline-brand-focus-text": 1,
        "components-button-color-outline-brand-focus-stroke": 1,
        "components-button-color-outline-brand-focus-icon": 1,
        "components-button-color-outline-brand-focus-outline": 1,
        "components-button-color-outline-tonal-default-bg": 0.02,
        "components-button-color-outline-tonal-default-text": 1,
        "components-button-color-outline-tonal-default-stroke": 1,
        "components-button-color-outline-tonal-default-icon": 1,
        "components-button-color-outline-tonal-hover-bg": 1,
        "components-button-color-outline-tonal-hover-text": 1,
        "components-button-color-outline-tonal-hover-stroke": 1,
        "components-button-color-outline-tonal-hover-icon": 1,
        "components-button-color-outline-tonal-pressed-bg": 1,
        "components-button-color-outline-tonal-pressed-text": 1,
        "components-button-color-outline-tonal-pressed-stroke": 1,
        "components-button-color-outline-tonal-pressed-icon": 1,
        "components-button-color-outline-tonal-disabled-bg": 0.02,
        "components-button-color-outline-tonal-disabled-text": 0.02,
        "components-button-color-outline-tonal-disabled-stroke": 0.02,
        "components-button-color-outline-tonal-disabled-icon": 0.02,
        "components-button-color-outline-tonal-focus-bg": 0.02,
        "components-button-color-outline-tonal-focus-text": 1,
        "components-button-color-outline-tonal-focus-stroke": 1,
        "components-button-color-outline-tonal-focus-icon": 1,
        "components-button-color-outline-tonal-focus-outline": 1,
        "components-button-color-outline-white-default-bg": 0.02,
        "components-button-color-outline-white-default-text": 1,
        "components-button-color-outline-white-default-stroke": 0.02,
        "components-button-color-outline-white-default-icon": 1,
        "components-button-color-outline-white-hover-bg": 1,
        "components-button-color-outline-white-hover-text": 1,
        "components-button-color-outline-white-hover-stroke": 1,
        "components-button-color-outline-white-hover-icon": 1,
        "components-button-color-outline-white-pressed-bg": 1,
        "components-button-color-outline-white-pressed-text": 1,
        "components-button-color-outline-white-pressed-stroke": 1,
        "components-button-color-outline-white-pressed-icon": 1,
        "components-button-color-outline-white-disabled-bg": 0.02,
        "components-button-color-outline-white-disabled-text": 0.02,
        "components-button-color-outline-white-disabled-stroke": 0.02,
        "components-button-color-outline-white-disabled-icon": 0.02,
        "components-button-color-outline-white-focus-bg": 0.02,
        "components-button-color-outline-white-focus-text": 1,
        "components-button-color-outline-white-focus-stroke": 1,
        "components-button-color-outline-white-focus-icon": 1,
        "components-button-color-outline-white-focus-outline": 1,
        "components-button-color-text-brand-default-bg": 0.02,
        "components-button-color-text-brand-default-text": 1,
        "components-button-color-text-brand-default-stroke": 1,
        "components-button-color-text-brand-default-icon": 1,
        "components-button-color-text-brand-hover-bg": 0.02,
        "components-button-color-text-brand-hover-bg-icon-only": 1,
        "components-button-color-text-brand-hover-text": 1,
        "components-button-color-text-brand-hover-stroke": 1,
        "components-button-color-text-brand-hover-icon": 1,
        "components-button-color-text-brand-pressed-bg": 0.02,
        "components-button-color-text-brand-pressed-bg-icon-only": 1,
        "components-button-color-text-brand-pressed-text": 1,
        "components-button-color-text-brand-pressed-stroke": 1,
        "components-button-color-text-brand-pressed-icon": 1,
        "components-button-color-text-brand-disabled-bg": 0.02,
        "components-button-color-text-brand-disabled-text": null,
        "components-button-color-text-brand-disabled-stroke": null,
        "components-button-color-text-brand-disabled-icon": null,
        "components-button-color-text-brand-focus-bg": 1,
        "components-button-color-text-brand-focus-text": 1,
        "components-button-color-text-brand-focus-stroke": 1,
        "components-button-color-text-brand-focus-icon": 1,
        "components-button-color-text-brand-focus-outline": 1,
        "components-button-color-text-tonal-default-bg": 0.02,
        "components-button-color-text-tonal-default-text": 1,
        "components-button-color-text-tonal-default-stroke": 1,
        "components-button-color-text-tonal-default-icon": 1,
        "components-button-color-text-tonal-hover-bg": 0.02,
        "components-button-color-text-tonal-hover-bg-icon-only": 1,
        "components-button-color-text-tonal-hover-text": 1,
        "components-button-color-text-tonal-hover-stroke": 1,
        "components-button-color-text-tonal-hover-icon": 1,
        "components-button-color-text-tonal-pressed-bg": 0.02,
        "components-button-color-text-tonal-pressed-bg-icon-only": 1,
        "components-button-color-text-tonal-pressed-text": 1,
        "components-button-color-text-tonal-pressed-stroke": 1,
        "components-button-color-text-tonal-pressed-icon": 1,
        "components-button-color-text-tonal-disabled-bg": 0.02,
        "components-button-color-text-tonal-disabled-text": null,
        "components-button-color-text-tonal-disabled-stroke": null,
        "components-button-color-text-tonal-disabled-icon": null,
        "components-button-color-text-tonal-focus-bg": 1,
        "components-button-color-text-tonal-focus-text": 1,
        "components-button-color-text-tonal-focus-stroke": 1,
        "components-button-color-text-tonal-focus-icon": 1,
        "components-button-color-text-tonal-focus-outline": 1,
        "components-button-color-text-white-default-bg": 0.02,
        "components-button-color-text-white-default-text": 1,
        "components-button-color-text-white-default-stroke": 1,
        "components-button-color-text-white-default-icon": 1,
        "components-button-color-text-white-hover-bg": 0.02,
        "components-button-color-text-white-hover-bg-icon-only": 1,
        "components-button-color-text-white-hover-text": 1,
        "components-button-color-text-white-hover-stroke": 1,
        "components-button-color-text-white-hover-icon": 1,
        "components-button-color-text-white-pressed-bg": 0.02,
        "components-button-color-text-white-pressed-bg-icon-only": 1,
        "components-button-color-text-white-pressed-text": 1,
        "components-button-color-text-white-pressed-stroke": 1,
        "components-button-color-text-white-pressed-icon": 1,
        "components-button-color-text-white-disabled-bg": 0.02,
        "components-button-color-text-white-disabled-text": 0.01,
        "components-button-color-text-white-disabled-stroke": 0.01,
        "components-button-color-text-white-disabled-icon": 0.01,
        "components-button-color-text-white-focus-bg": 1,
        "components-button-color-text-white-focus-text": 1,
        "components-button-color-text-white-focus-stroke": 1,
        "components-button-color-text-white-focus-icon": 1,
        "components-button-color-text-white-focus-outline": 1,
        "components-button-group-color-border": 1,
        "components-button-group-color-button-bg-default": 0.02,
        "components-button-group-color-button-fg-default": 1,
        "components-button-group-color-button-bg-hover": 0.02,
        "components-button-group-color-button-fg-hover": 1,
        "components-button-group-color-button-bg-active": 1,
        "components-button-group-color-button-fg-active": 1,
        "components-button-group-color-overlay-left-gradient-fill-1": 1,
        "components-button-group-color-overlay-left-gradient-fill-2": 0.01,
        "components-button-group-color-stroke-default-gradient-fill-1": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-3": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-4": 0.02,
        "components-button-group-color-stroke-hover-gradient-fill-1": 0.02,
        "components-button-group-color-stroke-hover-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-hover-gradient-fill-3": 0.02,
        "components-button-group-color-stroke-hover-gradient-fill-4": 0.02,
        "components-button-group-color-stroke-active-gradient-fill-1": 1,
        "components-button-group-color-stroke-active-gradient-fill-2": 1,
        "components-button-group-color-stroke-active-gradient-fill-3": 1,
        "components-button-group-color-stroke-active-gradient-fill-4": 1,
        "components-category-card-color-bg": 1,
        "components-category-card-color-content-bg": 1,
        "components-category-card-color-category-bg": 1,
        "components-category-card-color-category-bg-2": 1,
        "components-category-card-color-category-bg-3": 1,
        "components-category-card-color-category-bg-4": 1,
        "components-category-card-color-category-bg-5": 1,
        "components-category-card-color-category-bg-6": 1,
        "components-category-card-color-title": 1,
        "components-category-card-color-copy": 1,
        "components-category-card-color-stroke": 1,
        "components-category-card-color-stroke-hover": 1,
        "components-category-card-listing-color-bg": 1,
        "components-category-card-listing-color-title": 1,
        "components-category-card-listing-color-copy": 1,
        "components-card-search-result-color-bg": 1,
        "components-card-search-result-color-label": 1,
        "components-card-search-result-color-title": 1,
        "components-card-search-result-color-body": 1,
        "components-card-search-result-color-divider-line": 1,
        "components-contact-us-promo-color-light-bg": 1,
        "components-contact-us-promo-color-light-title": 1,
        "components-contact-us-promo-color-light-body": 1,
        "components-contact-us-promo-color-default-bg": 1,
        "components-contact-us-promo-color-default-title": 1,
        "components-contact-us-promo-color-default-body": 1,
        "components-contact-us-promo-color-brand-bg": 1,
        "components-contact-us-promo-color-brand-title": 1,
        "components-contact-us-promo-color-brand-body": 1,
        "components-content-list-color-bg": 1,
        "components-content-list-color-title": 1,
        "components-content-list-color-body": 1,
        "components-content-list-color-icon": 1,
        "components-cookies-colors-bg": 1,
        "components-cookies-colors-title": 1,
        "components-cookies-colors-body": 1,
        "components-cookies-colors-icon": 1,
        "components-dropdown-bg": 1,
        "components-dropdown-border-default": 1,
        "components-dropdown-border-hover": 1,
        "components-dropdown-border-focused": 1,
        "components-dropdown-border-destructive": 1,
        "components-dropdown-label-text-default": 1,
        "components-dropdown-item-text-default": 1,
        "components-dropdown-item-bg-default": 1,
        "components-dropdown-item-bg-hover": 1,
        "components-dropdown-input-field-default": 1,
        "components-dropdown-input-field-focused": 1,
        "components-dropdown-input-label-focused": 1,
        "components-dropdown-input-field-destructive": 1,
        "components-external-link-card-bg": 1,
        "components-external-link-card-card-bg": 1,
        "components-external-link-card-card-title": 1,
        "components-external-link-card-card-subtitle": 1,
        "components-external-link-card-card-eyebrow": 1,
        "components-fab-color-border-default": 1,
        "components-footer-brand-color-bg": 1,
        "components-footer-brand-color-logo": 1,
        "components-footer-brand-color-title-text": 1,
        "components-footer-brand-color-description-text": 1,
        "components-footer-brand-color-footer-menu-expanded": 1,
        "components-footer-brand-color-link-default": 1,
        "components-footer-brand-color-link-hover": 1,
        "components-footer-brand-color-link-pressed": 1,
        "components-footer-brand-color-category-link-default": 1,
        "components-footer-brand-color-category-link-hover": 1,
        "components-footer-brand-color-category-link-pressed": 1,
        "components-footer-brand-color-subcategory-link-default": 1,
        "components-footer-brand-color-subcategory-link-hover": 1,
        "components-footer-brand-color-subcategory-link-pressed": 1,
        "components-footer-brand-color-icon-icon-default": 1,
        "components-footer-brand-color-icon-icon-pressed": 1,
        "components-footer-brand-color-social-icon-default": 1,
        "components-footer-brand-color-social-icon-hover": 1,
        "components-footer-brand-color-social-icon-pressed": 1,
        "components-footer-brand-spacing-category-link-hover": 1,
        "components-footer-global-color-bg": 1,
        "components-footer-global-color-title-text": 1,
        "components-footer-global-color-disclaimer-text": 1,
        "components-footer-global-color-link-default": 1,
        "components-footer-global-color-link-hover": 1,
        "components-footer-global-color-link-pressed": 1,
        "components-generic-card-color-bg": 1,
        "components-generic-card-color-content-bg": 1,
        "components-generic-card-color-title": 1,
        "components-generic-card-color-subtitle": 1,
        "components-generic-card-color-copy": 1,
        "components-global-search-color-omnibox-list-bg": 1,
        "components-global-search-color-omnibox-divider": 1,
        "components-global-search-color-omnibox-item-bg-default": 1,
        "components-global-search-color-omnibox-item-bg-hover": 1,
        "components-global-search-color-omnibox-item-text-default": 1,
        "components-global-search-color-omnibox-item-text-hover": 1,
        "components-global-search-color-omnibox-label-text-default": 1,
        "components-global-search-color-omnibox-category-text-default": 1,
        "components-generic-card-listing-color-bg": 1,
        "components-generic-card-listing-color-title": 1,
        "components-generic-card-listing-color-copy": 1,
        "components-header-color-bg": 1,
        "components-header-color-bottom-stroke": 1,
        "components-header-color-search-search-border-default": 1,
        "components-header-color-search-search-text": 1,
        "components-header-color-search-search-icon": 1,
        "components-header-color-search-search-bar": 1,
        "components-header-color-mobile-mobile-expanded-bg": 1,
        "components-header-color-mobile-pressed-bg": 1,
        "components-header-color-language-selector-language": 1,
        "components-header-color-language-selector-country": 1,
        "components-header-color-language-selector-country-hover-bg": 1,
        "components-header-color-language-selector-megamenu-language": 1,
        "components-header-color-language-selector-megamenu-country": 1,
        "components-header-color-language-selector-megamenu-country-hover": 1,
        "components-header-color-language-selector-bg": 1,
        "components-header-color-language-selector-stroke": 1,
        "components-header-color-mega-menu-bg": 1,
        "components-header-color-mega-menu-featured-bg": 1,
        "components-header-color-mega-menu-featured-title": 1,
        "components-header-color-mega-menu-category-title": 1,
        "components-header-color-mega-menu-bg-accent": 1,
        "components-header-color-icon-icon-default": 1,
        "components-header-color-icon-icon-hover": 1,
        "components-header-color-icon-icon-pressed": 1,
        "components-header-color-links-nav-main-link-default": 1,
        "components-header-color-links-nav-main-link-hover": 1,
        "components-header-color-links-nav-main-link-pressed": 1,
        "components-header-color-links-nav-main-link-active": 1,
        "components-header-color-links-nav-main-active-bar": 1,
        "components-header-color-links-nav-main-bg-color": 1,
        "components-header-color-links-nav-category-link-category-default": 1,
        "components-header-color-links-nav-category-link-category-hover": 1,
        "components-header-color-links-nav-category-link-category-pressed": 1,
        "components-header-color-links-nav-category-link-category-active": 1,
        "components-header-color-links-nav-menu-link-link-default": 1,
        "components-header-color-links-nav-menu-link-link-hover": 1,
        "components-header-color-links-nav-menu-link-link-pressed": 1,
        "components-header-color-links-nav-menu-link-link-active": 1,
        "components-header-color-links-nav-menu-link-bg": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-default": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-active": 1,
        "components-hero-homepage-color-bg": 1,
        "components-hero-homepage-color-container-bg": 1,
        "components-hero-homepage-color-eyebrow": 1,
        "components-hero-homepage-color-header": 1,
        "components-hero-homepage-color-sub-header": 1,
        "components-hero-homepage-color-body": 1,
        "components-hero-homepage-color-play-pause": 1,
        "components-hero-internal-color-default-title": 1,
        "components-hero-internal-color-default-body": 1,
        "components-hero-internal-color-default-bg": 1,
        "components-hero-internal-color-accent-bg": 1,
        "components-hero-internal-color-with-image-bg": 1,
        "components-hero-internal-color-default-bg-2": 1,
        "components-hero-internal-color-with-image-title": 1,
        "components-hero-internal-color-with-image-body": 1,
        "components-heading-page-title-color-default-bg": 1,
        "components-heading-page-title-color-default-title": 1,
        "components-heading-page-title-color-default-body": 1,
        "components-heading-page-title-color-light-bg": 1,
        "components-heading-page-title-color-light-title": 1,
        "components-heading-page-title-color-light-body": 1,
        "components-heading-page-title-color-color-bg": 1,
        "components-heading-page-title-color-color-title": 1,
        "components-heading-page-title-color-color-body": 1,
        "components-hyperlink-color-dark-default": 1,
        "components-hyperlink-color-dark-hover": 1,
        "components-hyperlink-color-dark-visited": 1,
        "components-hyperlink-color-light-default": 1,
        "components-hyperlink-color-light-hover": 1,
        "components-hyperlink-color-light-visited": 1,
        "components-icon-default": 1,
        "components-icon-focused": 1,
        "components-icon-card-color-bg": 1,
        "components-icon-card-color-icon-bg": 1,
        "components-icon-card-color-icon": 1,
        "components-icon-card-color-title": 1,
        "components-icon-card-color-subtitle": 1,
        "components-icon-card-color-description": 1,
        "components-icon-card-listing-color-bg": 1,
        "components-icon-card-listing-color-inner-bg": 1,
        "components-icon-card-listing-color-brand-bg": 1,
        "components-icon-card-listing-color-title": 1,
        "components-icon-card-listing-color-copy": 1,
        "components-icon-card-listing-color-inner-legal-text": 1,
        "components-jumplink-color-bg-default": 1,
        "components-jumplink-color-link-text-default": 1,
        "components-jumplink-color-link-text-hover": 1,
        "components-jumplink-color-link-border-hover": 1,
        "components-jumplink-color-link-text-active": 1,
        "components-jumplink-color-link-border-active": 1,
        "components-jumplink-color-title-text": 1,
        "components-jumplink-color-description-text": 1,
        "components-layout-bg-default": 1,
        "components-lightbox-color-overlay-bg": 0.02,
        "components-lightbox-color-title": 1,
        "components-lightbox-color-body": 1,
        "components-label-color-on-color-label-label-default": 1,
        "components-label-color-on-color-label-label-hover": 1,
        "components-label-color-on-color-label-label-pressed": 1,
        "components-label-color-on-color-background-bg-default": null,
        "components-label-color-on-color-background-bg-hover": 1,
        "components-label-color-on-color-background-bg-pressed": 1,
        "components-label-color-on-color-stroke-hover": 0.02,
        "components-label-color-on-color-stroke-pressed": 0.02,
        "components-label-color-on-color-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-4": 1,
        "components-label-color-on-white-label-label-default": 1,
        "components-label-color-on-white-label-label-hover": 1,
        "components-label-color-on-white-label-label-pressed": 1,
        "components-label-color-on-white-background-bg-default": null,
        "components-label-color-on-white-background-bg-hover": 1,
        "components-label-color-on-white-background-bg-pressed": 1,
        "components-label-color-on-white-stroke-hover": 1,
        "components-label-color-on-white-stroke-pressed": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-4": 1,
        "components-legal-disclaimer-color-default-bg": 1,
        "components-legal-disclaimer-color-default-title": 1,
        "components-legal-disclaimer-color-default-body": 1,
        "components-legal-disclaimer-color-light-bg": 1,
        "components-legal-disclaimer-color-light-title": 1,
        "components-legal-disclaimer-color-light-body": 1,
        "components-legal-disclaimer-color-brand-bg": 1,
        "components-legal-disclaimer-color-brand-title": 1,
        "components-legal-disclaimer-color-brand-body": 1,
        "components-media-video-thumbnail-overlay-bg": 0.02,
        "components-media-caption-color-bg": 1,
        "components-media-caption-color-text": 1,
        "components-media-gallery-color-bg": 1,
        "components-media-gallery-color-title": 1,
        "components-media-gallery-color-body": 1,
        "components-pagination-on-white-accent-default": 1,
        "components-pagination-on-white-accent-scroll": 1,
        "components-pagination-on-white-disabled": 1,
        "components-pagination-on-white-bg": 1,
        "components-pagination-on-white-stroke": 1,
        "components-pagination-on-white-bg-active": 1,
        "components-pagination-on-white-bg-hover": 1,
        "components-pagination-on-white-text-default": 1,
        "components-pagination-on-white-text-hover": 1,
        "components-pagination-on-white-text-active": 1,
        "components-pagination-on-color-accent-default": 1,
        "components-pagination-on-color-accent-scroll": 1,
        "components-pagination-on-color-disabled": 1,
        "components-pagination-on-color-bg": 0.02,
        "components-pagination-on-color-stroke": 0.02,
        "components-pagination-on-color-bg-active": 1,
        "components-pagination-on-color-bg-hover": 1,
        "components-pagination-on-color-text-default": 1,
        "components-pagination-on-color-text-hover": 1,
        "components-pagination-on-color-text-active": 1,
        "components-pdf-link-card-color-bg": 1,
        "components-pdf-link-card-color-card-bg": 1,
        "components-pdf-link-card-color-card-title": 1,
        "components-pdf-link-card-color-card-subtitle": 1,
        "components-pdf-link-card-color-card-body": 1,
        "components-product-card-color-bg": 1,
        "components-product-card-color-content-bg": 1,
        "components-product-card-color-border": 1,
        "components-product-card-color-title": 1,
        "components-product-card-color-description": 1,
        "components-product-card-color-star-rating": 1,
        "components-product-card-color-badge-bg": 1,
        "components-product-card-color-badge-text": 1,
        "components-product-card-listing-color-bg": 1,
        "components-product-card-listing-color-title": 1,
        "components-product-card-listing-color-copy": 1,
        "components-product-card-listing-color-legal-text": 1,
        "components-product-information-color-bg": 1,
        "components-product-information-color-content-bg": 1,
        "components-product-information-color-headline": 1,
        "components-product-information-color-body": 1,
        "components-product-information-color-detail-text": 1,
        "components-product-information-color-divider": 1,
        "components-product-information-color-icon": 1,
        "components-product-information-color-play-icon": 1,
        "components-product-information-color-selected-stroke": 1,
        "components-product-results-list-with-search-color-bg": 1,
        "components-promo-color-default-bg": 1,
        "components-promo-color-default-image-bg": 1,
        "components-promo-color-default-content-bg": 1,
        "components-promo-color-default-title": 1,
        "components-promo-color-default-body": 1,
        "components-promo-color-default-category": 1,
        "components-promo-color-light-bg": 1,
        "components-promo-color-light-image-bg": 1,
        "components-promo-color-light-content-bg": 1,
        "components-promo-color-light-title": 1,
        "components-promo-color-light-body": 1,
        "components-promo-color-light-category": 1,
        "components-promo-color-brand-bg": 1,
        "components-promo-color-brand-image-bg": 1,
        "components-promo-color-brand-content-bg": 1,
        "components-promo-color-brand-title": 1,
        "components-promo-color-brand-body": 1,
        "components-promo-color-brand-category": 1,
        "components-pull-quote-color-bg": 1,
        "components-pull-quote-color-stroke": 1,
        "components-pull-quote-color-quote-bg": 1,
        "components-pull-quote-color-quote-title": 1,
        "components-pull-quote-color-quote-by-text": 1,
        "components-pull-quote-color-quote-detail": 1,
        "components-pull-quote-color-quote-highlight": 1,
        "components-promo-alternate-color-default-bg": 1,
        "components-promo-alternate-color-default-image-bg": 1,
        "components-promo-alternate-color-default-content-bg": 1,
        "components-promo-alternate-color-default-title": 1,
        "components-promo-alternate-color-default-body": 1,
        "components-promo-alternate-color-default-category": 1,
        "components-promo-alternate-color-light-bg": 1,
        "components-promo-alternate-color-light-image-bg": 1,
        "components-promo-alternate-color-light-content-bg": 1,
        "components-promo-alternate-color-light-title": 1,
        "components-promo-alternate-color-light-body": 1,
        "components-promo-alternate-color-light-category": 1,
        "components-promo-alternate-color-brand-bg": 1,
        "components-promo-alternate-color-brand-image-bg": 1,
        "components-promo-alternate-color-brand-content-bg": 1,
        "components-promo-alternate-color-brand-title": 1,
        "components-promo-alternate-color-brand-body": 1,
        "components-promo-alternate-color-brand-category": 1,
        "components-share-list-color-title": 1,
        "components-share-list-color-bg": 1,
        "components-share-list-color-link-default": 1,
        "components-share-list-color-link-hover": 1,
        "components-share-list-color-bg-link-hover": 1,
        "components-share-list-color-close-icon": 1,
        "components-share-list-color-facebook-icon": 1,
        "components-share-list-color-pinterest-icon": 1,
        "components-share-list-color-twitter-icon": 1,
        "components-share-list-color-basic-icon-default": 1,
        "components-share-list-color-icon-hover": 1,
        "components-share-list-color-border": 1,
        "components-site-search-bg": 1,
        "components-site-search-results_text": 1,
        "components-site-search-filter-filter-selected-bg": 1,
        "components-site-search-facet-group-label-bottom-line": 1,
        "components-site-search-facet-group-label-text": 1,
        "components-site-search-facet-group-label-bg": 1,
        "components-site-search-facet-bg": 1,
        "components-site-search-facet-stroke": 1,
        "components-site-search-refine-bg": 1,
        "components-site-search-refine-right-divider": 1,
        "components-site-search-refine-title": 1,
        "components-site-search-hero-search-bg": 1,
        "components-site-search-hero-search-accent-bg": 1,
        "components-site-search-hero-search-title": 1,
        "components-stats-stat-text": 1,
        "components-stats-stat-text-on-light": 1,
        "components-stats-supporting-text": 1,
        "components-stats-bg-default": 1,
        "components-stats-bg-inner": 1,
        "components-stats-bg-section-inner": 1,
        "components-stats-title": 1,
        "components-stats-copy": 1,
        "components-supporting-text-default": 1,
        "components-supporting-text-destructive": 1,
        "components-tab-bg": 1,
        "components-tab-border-default": 1,
        "components-tab-border-hover": 1,
        "components-tab-border-focused": 1,
        "components-tab-border-destructive": 1,
        "components-tab-label-text-default": 1,
        "components-tab-item-text-default": 1,
        "components-tab-item-bg-default": 1,
        "components-tab-item-bg-hover": 1,
        "components-tab-input-field-default": 1,
        "components-tab-input-field-focused": 1,
        "components-tab-input-label-focused": 1,
        "components-tab-input-field-destructive": 1,
        "components-tab-bg-2": 1,
        "components-tag-color-bg": 1,
        "components-tag-color-text": 1,
        "components-tag-color-stroke": 1,
        "components-tag-color-bg-hover": 1,
        "components-tag-color-text-hover": 1,
        "components-tag-color-stroke-hover": 1,
        "components-text-field-bg": 1,
        "components-text-field-bg-focused": 1,
        "components-text-field-border-default": 1,
        "components-text-field-border-hover": 1,
        "components-text-field-border-focused": 1,
        "components-text-field-border-destructive": 1,
        "components-text-field-input-field-default": 1,
        "components-text-field-input-field-focused": 1,
        "components-text-field-input-label-focused": 1,
        "components-text-field-input-field-destructive": 1,
        "components-text-field-icon-default": 1,
        "components-text-field-icon-destructive": 1,
        "components-text-field-supporting-text-default": 1,
        "components-text-field-supporting-text-destructive": 1,
        "components-tip-color-bg": 1,
        "components-tip-color-title": 1,
        "components-tip-color-body": 1,
        "components-tip-color-stroke": 1,
        "components-video-color-bg": 1,
        "themes-general-surface-color-light-bg": 1,
        "themes-general-surface-color-light-title": 1,
        "themes-general-surface-color-light-body": 1,
        "themes-general-surface-color-light-decor-line-break": 1,
        "themes-general-surface-color-light-rule-line": 0.01,
        "themes-general-surface-color-dark-bg": 1,
        "themes-general-surface-color-dark-title": 1,
        "themes-general-surface-color-dark-body": 1,
        "themes-general-surface-color-dark-decor-line-break": 1,
        "themes-general-card-color-dark-title": 1,
        "themes-general-card-color-dark-subtitle": 1,
        "themes-general-card-color-dark-body": 1,
        "themes-general-card-color-light-title": 1,
        "themes-general-card-color-light-subtitle": 1,
        "themes-general-card-color-light-body": 1,
        "themes-shadow-color": 1,
        "themes-shadow-share-list-color-fill": 0,
        "themes-shadow-header-menu-color-fill": 0,
        "themes-gradient-loader-gradient-fill-1": 1,
        "themes-gradient-loader-gradient-fill-2": 0.02,
        "themes-color-background-white": 1,
        "themes-color-background-light": 1,
        "themes-color-background-dark": 1,
        "themes-color-background-black": 1,
        "themes-color-background-brand1": 1,
        "themes-color-background-brand2": 1,
        "themes-color-background-accent1": 1,
        "themes-color-background-accent2": 1,
        "themes-color-background-body": 1,
        "themes-color-stroke-white": 1,
        "themes-color-stroke-light": 1,
        "themes-color-stroke-dark": 1,
        "themes-color-stroke-black": 1,
        "themes-color-stroke-brand1": 1,
        "themes-color-stroke-brand2": 1,
        "themes-color-stroke-accent1": 1,
        "themes-color-stroke-accent2": 1,
        "themes-color-text-white": 1,
        "themes-color-text-light": 1,
        "themes-color-text-dark": 1,
        "themes-color-text-black": 1,
        "themes-color-text-brand1": 1,
        "themes-color-text-brand2": 1,
        "themes-color-text-accent1": 1,
        "themes-color-text-accent2": 1,
        "colors-grayscale-100": 1,
        "colors-grayscale-200": 1,
        "colors-grayscale-300": 1,
        "colors-grayscale-400": 1,
        "colors-grayscale-500": 1,
        "colors-grayscale-600": 1,
        "colors-grayscale-700": 1,
        "colors-brand-1-100": 1,
        "colors-brand-1-200": 1,
        "colors-brand-1-300": 1,
        "colors-brand-1-400": 1,
        "colors-brand-1-500": 1,
        "colors-brand-1-600": 1,
        "colors-brand-1-700": 1,
        "colors-brand-2-100": 1,
        "colors-brand-2-200": 1,
        "colors-brand-2-300": 1,
        "colors-brand-2-400": 1,
        "colors-brand-2-500": 1,
        "colors-brand-2-600": 1,
        "colors-brand-2-700": 1,
        "colors-accent-1-100": 1,
        "colors-accent-1-200": 1,
        "colors-accent-1-300": 1,
        "colors-accent-1-400": 1,
        "colors-accent-1-500": 1,
        "colors-accent-1-600": 1,
        "colors-accent-1-700": 1,
        "colors-accent-2-100": 1,
        "colors-accent-2-200": 1,
        "colors-accent-2-300": 1,
        "colors-accent-2-400": 1,
        "colors-accent-2-500": 1,
        "colors-accent-2-600": 1,
        "colors-accent-2-700": 1,
        "colors-accent-3-100": 1,
        "colors-accent-3-200": 1,
        "colors-accent-3-300": 1,
        "colors-accent-3-400": 1,
        "colors-accent-3-500": 1,
        "colors-accent-3-600": 1,
        "colors-accent-3-700": 1,
        "colors-accent-4-400_storage": 1,
        "colors-accessible-accent-1-100": 1,
        "colors-accessible-accent-1-200": 1,
        "colors-accessible-accent-1-300": 1,
        "colors-accessible-accent-1-400": 1,
        "colors-accessible-accent-1-500": 1,
        "colors-accessible-accent-1-600": 1,
        "colors-accessible-accent-1-700": 1,
        "colors-accessible-accent-2-100": 1,
        "colors-accessible-accent-2-200": 1,
        "colors-accessible-accent-2-300": 1,
        "colors-accessible-accent-2-400": 1,
        "colors-accessible-accent-2-500": 1,
        "colors-accessible-accent-2-600": 1,
        "colors-accessible-accent-2-700": 1,
        "colors-accessible-brand-2-100": 1,
        "colors-accessible-brand-2-200": 1,
        "colors-accessible-brand-2-300": 1,
        "colors-accessible-brand-2-400": 1,
        "colors-accessible-brand-2-500": 1,
        "colors-accessible-brand-2-600": 1,
        "colors-accessible-brand-2-700": 1
      }
    },
    "Exposis": {
      "colors": {
        "components-accessibility-bar-color-bg": "#f7cd46",
        "components-accessibility-bar-color-text": "#191b1c",
        "components-accordion-color-bg": "#ffffff",
        "components-accordion-color-title": "#191b1c",
        "components-accordion-color-body": "#27282b",
        "components-accordion-color-section-section-bg": "#e8e8ea",
        "components-accordion-color-section-section-title": "#191b1c",
        "components-accordion-color-section-section-body": "#27282b",
        "components-accordion-color-item-item-bg": "#e8e8ea",
        "components-accordion-color-item-divider": "#e8e8ea",
        "components-accordion-color-item-icon-icon-default": "#191b1c",
        "components-accordion-color-item-icon-icon-hover": "#ffffff",
        "components-accordion-color-item-icon-icon-pressed": "#ffffff",
        "components-accordion-color-item-icon-icon-active": "#ffffff",
        "components-accordion-color-item-title-item-default": "#191b1c",
        "components-accordion-color-item-title-item-hover": "#ffffff",
        "components-accordion-color-item-title-item-pressed": "#ffffff",
        "components-accordion-color-item-title-item-active": "#ffffff",
        "components-accordion-color-content-bg": "#e8e8ea",
        "components-accordion-color-content-title": "#191b1c",
        "components-accordion-color-content-body": "#27282b",
        "components-article-card-color-bg": "#ffffff",
        "components-article-card-color-border": "#a2a5ab",
        "components-article-card-color-content-bg": "#ffffff",
        "components-article-card-color-title": "#191b1c",
        "components-article-card-color-subtitle": "#27282b",
        "components-article-card-color-copy": "#27282b",
        "components-article-card-color-description": "#27282b",
        "components-article-card-listing-color-default-bg": "#e8e8ea",
        "components-article-card-listing-color-default-title": "#191b1c",
        "components-article-card-listing-color-default-copy": "#27282b",
        "components-article-hero-color-content-bg": "#e8e8ea",
        "components-article-hero-color-bg": "#e8e8ea",
        "components-article-hero-color-headline": "#191b1c",
        "components-article-hero-color-body": "#27282b",
        "components-article-hero-color-detail-text": "#27282b",
        "components-article-hero-color-divider": "#a2a5ab",
        "components-article-hero-color-icon": "#eb2035",
        "components-article-hero-color-play-icon": "#ffffff",
        "components-article-hero-color-selected-stroke": "#f7cd46",
        "components-banner-color-bg-default": "#3161b3",
        "components-banner-color-icon-default": "#ffffff",
        "components-banner-color-title-default": "#ffffff",
        "components-banner-color-body-default": "#ffffff",
        "components-banner-color-icon-close-default": "#ffffff",
        "components-banner-color-bg-alert": "#b32b30",
        "components-banner-color-icon-alert": "#ffffff",
        "components-banner-color-title-alert": "#ffffff",
        "components-banner-color-body-alert": "#ffffff",
        "components-banner-color-icon-close-alert": "#ffffff",
        "components-breadcrumb-color-bg": "#ffffff",
        "components-breadcrumb-color-breadcrumb-link-default": "#72757e",
        "components-breadcrumb-color-breadcrumb-link-hover": "#4e5056",
        "components-breadcrumb-color-breadcrumb-link-active": "#27282b",
        "components-breadcrumb-color-icon-divider": "#72757e",
        "components-bug-finder-prompt": "#ffffff",
        "components-button-color-filled-brand-default-bg": "#b6222e",
        "components-button-color-filled-brand-default-text": "#ffffff",
        "components-button-color-filled-brand-default-stroke": "#b6222e",
        "components-button-color-filled-brand-default-icon": "#ffffff",
        "components-button-color-filled-brand-hover-bg": "#000000",
        "components-button-color-filled-brand-hover-text": "#ffffff",
        "components-button-color-filled-brand-hover-stroke": "#000000",
        "components-button-color-filled-brand-hover-icon": "#ffffff",
        "components-button-color-filled-brand-pressed-bg": "#000000",
        "components-button-color-filled-brand-pressed-text": "#ffffff",
        "components-button-color-filled-brand-pressed-stroke": "#000000",
        "components-button-color-filled-brand-pressed-icon": "#ffffff",
        "components-button-color-filled-brand-disabled-bg": "rgba(25, 27, 28, 0.38)",
        "components-button-color-filled-brand-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-disabled-stroke": "rgba(39, 40, 43, 0.38)",
        "components-button-color-filled-brand-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-focus-bg": "#b6222e",
        "components-button-color-filled-brand-focus-text": "#ffffff",
        "components-button-color-filled-brand-focus-stroke": "#b6222e",
        "components-button-color-filled-brand-focus-icon": "#ffffff",
        "components-button-color-filled-brand-focus-outline": "#286efa",
        "components-button-color-filled-tonal-default-bg": "#9ac6e7",
        "components-button-color-filled-tonal-default-text": "#191b1c",
        "components-button-color-filled-tonal-default-stroke": "#9ac6e7",
        "components-button-color-filled-tonal-default-icon": "#191b1c",
        "components-button-color-filled-tonal-hover-bg": "#eb2035",
        "components-button-color-filled-tonal-hover-text": "#191b1c",
        "components-button-color-filled-tonal-hover-stroke": "#eb2035",
        "components-button-color-filled-tonal-hover-icon": "#191b1c",
        "components-button-color-filled-tonal-pressed-bg": "#eb2035",
        "components-button-color-filled-tonal-pressed-text": "#191b1c",
        "components-button-color-filled-tonal-pressed-stroke": "#eb2035",
        "components-button-color-filled-tonal-pressed-icon": "#191b1c",
        "components-button-color-filled-tonal-disabled-bg": "rgba(255, 236, 13, 0.38)",
        "components-button-color-filled-tonal-disabled-text": "rgba(25, 27, 28, 0.38)",
        "components-button-color-filled-tonal-disabled-stroke": "rgba(255, 236, 13, 0.38)",
        "components-button-color-filled-tonal-disabled-icon": "rgba(25, 27, 28, 0.38)",
        "components-button-color-filled-tonal-focus-bg": "#9ac6e7",
        "components-button-color-filled-tonal-focus-text": "#191b1c",
        "components-button-color-filled-tonal-focus-stroke": "#9ac6e7",
        "components-button-color-filled-tonal-focus-icon": "#191b1c",
        "components-button-color-filled-tonal-focus-outline": "#1c4cae",
        "components-button-color-filled-white-default-bg": "#ffffff",
        "components-button-color-filled-white-default-text": "#191b1c",
        "components-button-color-filled-white-default-stroke": "#ffffff",
        "components-button-color-filled-white-default-icon": "#191b1c",
        "components-button-color-filled-white-hover-bg": "#fcfcfd",
        "components-button-color-filled-white-hover-text": "#191b1c",
        "components-button-color-filled-white-hover-stroke": "#fcfcfd",
        "components-button-color-filled-white-hover-icon": "#191b1c",
        "components-button-color-filled-white-pressed-bg": "#e8e8ea",
        "components-button-color-filled-white-pressed-text": "#191b1c",
        "components-button-color-filled-white-pressed-stroke": "#e8e8ea",
        "components-button-color-filled-white-pressed-icon": "#191b1c",
        "components-button-color-filled-white-disabled-bg": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-text": "rgba(25, 27, 28, 0.38)",
        "components-button-color-filled-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-icon": "rgba(25, 27, 28, 0.38)",
        "components-button-color-filled-white-focus-bg": "#ffffff",
        "components-button-color-filled-white-focus-text": "#191b1c",
        "components-button-color-filled-white-focus-stroke": "#ffffff",
        "components-button-color-filled-white-focus-icon": "#191b1c",
        "components-button-color-filled-white-focus-outline": "#1c4cae",
        "components-button-color-outline-brand-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-brand-default-text": "#b6222e",
        "components-button-color-outline-brand-default-stroke": "#b6222e",
        "components-button-color-outline-brand-default-icon": "#b6222e",
        "components-button-color-outline-brand-hover-bg": "#000000",
        "components-button-color-outline-brand-hover-text": "#ffffff",
        "components-button-color-outline-brand-hover-stroke": "#000000",
        "components-button-color-outline-brand-hover-icon": "#ffffff",
        "components-button-color-outline-brand-pressed-bg": "#000000",
        "components-button-color-outline-brand-pressed-text": "#ffffff",
        "components-button-color-outline-brand-pressed-stroke": "#000000",
        "components-button-color-outline-brand-pressed-icon": "#ffffff",
        "components-button-color-outline-brand-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-brand-disabled-text": "rgba(25, 27, 28, 0.38)",
        "components-button-color-outline-brand-disabled-stroke": "rgba(25, 27, 28, 0.38)",
        "components-button-color-outline-brand-disabled-icon": "rgba(25, 27, 28, 0.38)",
        "components-button-color-outline-brand-focus-bg": "#ffffff",
        "components-button-color-outline-brand-focus-text": "#b6222e",
        "components-button-color-outline-brand-focus-stroke": "#b6222e",
        "components-button-color-outline-brand-focus-icon": "#b6222e",
        "components-button-color-outline-brand-focus-outline": "#286efa",
        "components-button-color-outline-tonal-default-bg": "rgba(25, 27, 28, 0.00)",
        "components-button-color-outline-tonal-default-text": "#ed9599",
        "components-button-color-outline-tonal-default-stroke": "#ed9599",
        "components-button-color-outline-tonal-default-icon": "#ed9599",
        "components-button-color-outline-tonal-hover-bg": "#eb2035",
        "components-button-color-outline-tonal-hover-text": "#191b1c",
        "components-button-color-outline-tonal-hover-stroke": "#eb2035",
        "components-button-color-outline-tonal-hover-icon": "#191b1c",
        "components-button-color-outline-tonal-pressed-bg": "#eb2035",
        "components-button-color-outline-tonal-pressed-text": "#191b1c",
        "components-button-color-outline-tonal-pressed-stroke": "#eb2035",
        "components-button-color-outline-tonal-pressed-icon": "#191b1c",
        "components-button-color-outline-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-tonal-disabled-text": "rgba(255, 236, 13, 0.38)",
        "components-button-color-outline-tonal-disabled-stroke": "rgba(255, 236, 13, 0.38)",
        "components-button-color-outline-tonal-disabled-icon": "rgba(255, 236, 13, 0.38)",
        "components-button-color-outline-tonal-focus-bg": "rgba(25, 27, 28, 0.01)",
        "components-button-color-outline-tonal-focus-text": "#ed9599",
        "components-button-color-outline-tonal-focus-stroke": "#ed9599",
        "components-button-color-outline-tonal-focus-icon": "#ed9599",
        "components-button-color-outline-tonal-focus-outline": "#286efa",
        "components-button-color-outline-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-default-text": "#ffffff",
        "components-button-color-outline-white-default-stroke": "#ffffff",
        "components-button-color-outline-white-default-icon": "#ffffff",
        "components-button-color-outline-white-hover-bg": "#ffffff",
        "components-button-color-outline-white-hover-text": "#191b1c",
        "components-button-color-outline-white-hover-stroke": "#ffffff",
        "components-button-color-outline-white-hover-icon": "#191b1c",
        "components-button-color-outline-white-pressed-bg": "#ffffff",
        "components-button-color-outline-white-pressed-text": "#191b1c",
        "components-button-color-outline-white-pressed-stroke": "#ffffff",
        "components-button-color-outline-white-pressed-icon": "#191b1c",
        "components-button-color-outline-white-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-focus-bg": "rgba(255, 255, 255, 0.01)",
        "components-button-color-outline-white-focus-text": "#ffffff",
        "components-button-color-outline-white-focus-stroke": "#ffffff",
        "components-button-color-outline-white-focus-icon": "#ffffff",
        "components-button-color-outline-white-focus-outline": "#286efa",
        "components-button-color-text-brand-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-default-text": "#27282b",
        "components-button-color-text-brand-default-stroke": "#27282b",
        "components-button-color-text-brand-default-icon": "#27282b",
        "components-button-color-text-brand-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-hover-bg-icon-only": "#e8e8ea",
        "components-button-color-text-brand-hover-text": "#191b1c",
        "components-button-color-text-brand-hover-stroke": "#27282b",
        "components-button-color-text-brand-hover-icon": "#191b1c",
        "components-button-color-text-brand-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-pressed-bg-icon-only": "#a2a5ab",
        "components-button-color-text-brand-pressed-text": "#000000",
        "components-button-color-text-brand-pressed-stroke": "#000000",
        "components-button-color-text-brand-pressed-icon": "#000000",
        "components-button-color-text-brand-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-disabled-text": "rgba(39, 40, 43, 0.38)",
        "components-button-color-text-brand-disabled-stroke": "rgba(39, 40, 43, 0.38)",
        "components-button-color-text-brand-disabled-icon": "rgba(39, 40, 43, 0.38)",
        "components-button-color-text-brand-focus-bg": "#d5e3ff",
        "components-button-color-text-brand-focus-text": "#191b1c",
        "components-button-color-text-brand-focus-stroke": "#27282b",
        "components-button-color-text-brand-focus-icon": "#1c4cae",
        "components-button-color-text-brand-focus-outline": "#1c4cae",
        "components-button-color-text-tonal-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-default-text": "#ed9599",
        "components-button-color-text-tonal-default-stroke": "#ed9599",
        "components-button-color-text-tonal-default-icon": "#ed9599",
        "components-button-color-text-tonal-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-hover-bg-icon-only": "#27282b",
        "components-button-color-text-tonal-hover-text": "#eb2035",
        "components-button-color-text-tonal-hover-stroke": "#eb2035",
        "components-button-color-text-tonal-hover-icon": "#eb2035",
        "components-button-color-text-tonal-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-pressed-bg-icon-only": "#27282b",
        "components-button-color-text-tonal-pressed-text": "#eb2035",
        "components-button-color-text-tonal-pressed-stroke": "#eb2035",
        "components-button-color-text-tonal-pressed-icon": "#eb2035",
        "components-button-color-text-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-disabled-text": "rgba(255, 236, 13, 0.38)",
        "components-button-color-text-tonal-disabled-stroke": "rgba(255, 236, 13, 0.38)",
        "components-button-color-text-tonal-disabled-icon": "rgba(255, 236, 13, 0.38)",
        "components-button-color-text-tonal-focus-bg": "#d5e3ff",
        "components-button-color-text-tonal-focus-text": "#1c4cae",
        "components-button-color-text-tonal-focus-stroke": "#ed9599",
        "components-button-color-text-tonal-focus-icon": "#1c4cae",
        "components-button-color-text-tonal-focus-outline": "#1c4cae",
        "components-button-color-text-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-default-text": "#ffffff",
        "components-button-color-text-white-default-stroke": "#ffffff",
        "components-button-color-text-white-default-icon": "#ffffff",
        "components-button-color-text-white-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-hover-bg-icon-only": "#4e5056",
        "components-button-color-text-white-hover-text": "#ffffff",
        "components-button-color-text-white-hover-stroke": "#ffffff",
        "components-button-color-text-white-hover-icon": "#ffffff",
        "components-button-color-text-white-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-pressed-bg-icon-only": "#4e5056",
        "components-button-color-text-white-pressed-text": "#ffffff",
        "components-button-color-text-white-pressed-stroke": "#ffffff",
        "components-button-color-text-white-pressed-icon": "#ffffff",
        "components-button-color-text-white-disabled-bg": "rgba(115, 119, 128, 0.00)",
        "components-button-color-text-white-disabled-text": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-stroke": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-icon": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-focus-bg": "#d5e3ff",
        "components-button-color-text-white-focus-text": "#ffffff",
        "components-button-color-text-white-focus-stroke": "#ffffff",
        "components-button-color-text-white-focus-icon": "#1c4cae",
        "components-button-color-text-white-focus-outline": "#1c4cae",
        "components-button-group-color-border": "#e8e8ea",
        "components-button-group-color-button-bg-default": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-button-fg-default": "#191b1c",
        "components-button-group-color-button-bg-hover": "rgba(25, 27, 28, 0.38)",
        "components-button-group-color-button-fg-hover": "#191b1c",
        "components-button-group-color-button-bg-active": "#b6222e",
        "components-button-group-color-button-fg-active": "#ffffff",
        "components-button-group-color-overlay-left-gradient-fill-1": "#e8e8ea",
        "components-button-group-color-overlay-left-gradient-fill-2": "rgba(232, 232, 234, 0.50)",
        "components-button-group-color-stroke-default-gradient-fill-1": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-2": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-3": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-4": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-1": "rgba(25, 27, 28, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-2": "rgba(25, 27, 28, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-3": "rgba(25, 27, 28, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-4": "rgba(25, 27, 28, 0.00)",
        "components-button-group-color-stroke-active-gradient-fill-1": "#b6222e",
        "components-button-group-color-stroke-active-gradient-fill-2": "#b6222e",
        "components-button-group-color-stroke-active-gradient-fill-3": "#b6222e",
        "components-button-group-color-stroke-active-gradient-fill-4": "#b6222e",
        "components-category-card-color-bg": "#ffffff",
        "components-category-card-color-content-bg": "#ffffff",
        "components-category-card-color-category-bg": "#eb2035",
        "components-category-card-color-category-bg-2": "#74aa50",
        "components-category-card-color-category-bg-3": "#27282b",
        "components-category-card-color-category-bg-4": "#72757e",
        "components-category-card-color-category-bg-5": "#e8be25",
        "components-category-card-color-category-bg-6": "#a3c3c2",
        "components-category-card-color-title": "#191b1c",
        "components-category-card-color-copy": "#27282b",
        "components-category-card-color-stroke": "#a2a5ab",
        "components-category-card-color-stroke-hover": "#191b1c",
        "components-category-card-listing-color-bg": "#ffffff",
        "components-category-card-listing-color-title": "#191b1c",
        "components-category-card-listing-color-copy": "#27282b",
        "components-card-search-result-color-bg": "#ffffff",
        "components-card-search-result-color-label": "#27282b",
        "components-card-search-result-color-title": "#191b1c",
        "components-card-search-result-color-body": "#27282b",
        "components-card-search-result-color-divider-line": "#a2a5ab",
        "components-contact-us-promo-color-light-bg": "#ffffff",
        "components-contact-us-promo-color-light-title": "#191b1c",
        "components-contact-us-promo-color-light-body": "#27282b",
        "components-contact-us-promo-color-default-bg": "#e8e8ea",
        "components-contact-us-promo-color-default-title": "#191b1c",
        "components-contact-us-promo-color-default-body": "#27282b",
        "components-contact-us-promo-color-brand-bg": "#f7cd46",
        "components-contact-us-promo-color-brand-title": "#191b1c",
        "components-contact-us-promo-color-brand-body": "#27282b",
        "components-content-list-color-bg": "#ffffff",
        "components-content-list-color-title": "#191b1c",
        "components-content-list-color-body": "#27282b",
        "components-content-list-color-icon": "#f7cd46",
        "components-cookies-colors-bg": "#e8e8ea",
        "components-cookies-colors-title": "#191b1c",
        "components-cookies-colors-body": "#27282b",
        "components-cookies-colors-icon": "#b6222e",
        "components-dropdown-bg": "#ffffff",
        "components-dropdown-border-default": "#72757e",
        "components-dropdown-border-hover": "#eb2035",
        "components-dropdown-border-focused": "#191b1c",
        "components-dropdown-border-destructive": "#b32b30",
        "components-dropdown-label-text-default": "#191b1c",
        "components-dropdown-item-text-default": "#191b1c",
        "components-dropdown-item-bg-default": "#ffffff",
        "components-dropdown-item-bg-hover": "#d5e3ff",
        "components-dropdown-input-field-default": "#72757e",
        "components-dropdown-input-field-focused": "#191b1c",
        "components-dropdown-input-label-focused": "#191b1c",
        "components-dropdown-input-field-destructive": "#b32b30",
        "components-external-link-card-bg": "#e8e8ea",
        "components-external-link-card-card-bg": "#ffffff",
        "components-external-link-card-card-title": "#191b1c",
        "components-external-link-card-card-subtitle": "#27282b",
        "components-external-link-card-card-eyebrow": "#4e5056",
        "components-fab-color-border-default": "#ffffff",
        "components-footer-brand-color-bg": "#000000",
        "components-footer-brand-color-logo": "#ffffff",
        "components-footer-brand-color-title-text": "#ffffff",
        "components-footer-brand-color-description-text": "#fcfcfd",
        "components-footer-brand-color-footer-menu-expanded": "#000000",
        "components-footer-brand-color-link-default": "#ffffff",
        "components-footer-brand-color-link-hover": "#eb2035",
        "components-footer-brand-color-link-pressed": "#eb2035",
        "components-footer-brand-color-category-link-default": "#ffffff",
        "components-footer-brand-color-category-link-hover": "#eb2035",
        "components-footer-brand-color-category-link-pressed": "#eb2035",
        "components-footer-brand-color-subcategory-link-default": "#fcfcfd",
        "components-footer-brand-color-subcategory-link-hover": "#eb2035",
        "components-footer-brand-color-subcategory-link-pressed": "#eb2035",
        "components-footer-brand-color-icon-icon-default": "#ffffff",
        "components-footer-brand-color-icon-icon-pressed": "#eb2035",
        "components-footer-brand-color-social-icon-default": "#ffffff",
        "components-footer-brand-color-social-icon-hover": "#eb2035",
        "components-footer-brand-color-social-icon-pressed": "#eb2035",
        "components-footer-brand-spacing-category-link-hover": "#000000",
        "components-footer-global-color-bg": "#ffffff",
        "components-footer-global-color-title-text": "#000000",
        "components-footer-global-color-disclaimer-text": "#000000",
        "components-footer-global-color-link-default": "#000000",
        "components-footer-global-color-link-hover": "#000000",
        "components-footer-global-color-link-pressed": "#000000",
        "components-generic-card-color-bg": "#ffffff",
        "components-generic-card-color-content-bg": "#ffffff",
        "components-generic-card-color-title": "#191b1c",
        "components-generic-card-color-subtitle": "#27282b",
        "components-generic-card-color-copy": "#27282b",
        "components-global-search-color-omnibox-list-bg": "#ffffff",
        "components-global-search-color-omnibox-divider": "#a2a5ab",
        "components-global-search-color-omnibox-item-bg-default": "#ffffff",
        "components-global-search-color-omnibox-item-bg-hover": "#fffefa",
        "components-global-search-color-omnibox-item-text-default": "#f7cd46",
        "components-global-search-color-omnibox-item-text-hover": "#e8be25",
        "components-global-search-color-omnibox-label-text-default": "#4e5056",
        "components-global-search-color-omnibox-category-text-default": "#4e5056",
        "components-generic-card-listing-color-bg": "#e8e8ea",
        "components-generic-card-listing-color-title": "#191b1c",
        "components-generic-card-listing-color-copy": "#27282b",
        "components-header-color-bg": "#ffffff",
        "components-header-color-bottom-stroke": "#a2a5ab",
        "components-header-color-search-search-border-default": "#191b1c",
        "components-header-color-search-search-text": "#4e5056",
        "components-header-color-search-search-icon": "#4e5056",
        "components-header-color-search-search-bar": "#191b1c",
        "components-header-color-mobile-mobile-expanded-bg": "#ffffff",
        "components-header-color-mobile-pressed-bg": "#e8e8ea",
        "components-header-color-language-selector-language": "#4e5056",
        "components-header-color-language-selector-country": "#191b1c",
        "components-header-color-language-selector-country-hover-bg": "#e8e8ea",
        "components-header-color-language-selector-megamenu-language": "#4e5056",
        "components-header-color-language-selector-megamenu-country": "#191b1c",
        "components-header-color-language-selector-megamenu-country-hover": "#e8e8ea",
        "components-header-color-language-selector-bg": "#ffffff",
        "components-header-color-language-selector-stroke": "#72757e",
        "components-header-color-mega-menu-bg": "#ffffff",
        "components-header-color-mega-menu-featured-bg": "#000000",
        "components-header-color-mega-menu-featured-title": "#eb2035",
        "components-header-color-mega-menu-category-title": "#27282b",
        "components-header-color-mega-menu-bg-accent": "#ffffff",
        "components-header-color-icon-icon-default": "#4e5056",
        "components-header-color-icon-icon-hover": "#000000",
        "components-header-color-icon-icon-pressed": "#000000",
        "components-header-color-links-nav-main-link-default": "#4e5056",
        "components-header-color-links-nav-main-link-hover": "#191b1c",
        "components-header-color-links-nav-main-link-pressed": "#000000",
        "components-header-color-links-nav-main-link-active": "#000000",
        "components-header-color-links-nav-main-active-bar": "#eb2035",
        "components-header-color-links-nav-main-bg-color": "#ffffff",
        "components-header-color-links-nav-category-link-category-default": "#4e5056",
        "components-header-color-links-nav-category-link-category-hover": "#191b1c",
        "components-header-color-links-nav-category-link-category-pressed": "#000000",
        "components-header-color-links-nav-category-link-category-active": "#000000",
        "components-header-color-links-nav-menu-link-link-default": "#4e5056",
        "components-header-color-links-nav-menu-link-link-hover": "#191b1c",
        "components-header-color-links-nav-menu-link-link-pressed": "#000000",
        "components-header-color-links-nav-menu-link-link-active": "#000000",
        "components-header-color-links-nav-menu-link-bg": "#ffffff",
        "components-header-color-links-nav-subcategory-link-subcategory-default": "#4e5056",
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": "#000000",
        "components-header-color-links-nav-subcategory-link-subcategory-active": "#000000",
        "components-hero-homepage-color-bg": "#000000",
        "components-hero-homepage-color-container-bg": "#000000",
        "components-hero-homepage-color-eyebrow": "#fcfcfd",
        "components-hero-homepage-color-header": "#eb2035",
        "components-hero-homepage-color-sub-header": "#fcfcfd",
        "components-hero-homepage-color-body": "#fcfcfd",
        "components-hero-homepage-color-play-pause": "#ffffff",
        "components-hero-internal-color-default-title": "#ffffff",
        "components-hero-internal-color-default-body": "#fcfcfd",
        "components-hero-internal-color-default-bg": "#191b1c",
        "components-hero-internal-color-accent-bg": "#191b1c",
        "components-hero-internal-color-with-image-bg": "#000000",
        "components-hero-internal-color-default-bg-2": "#191b1c",
        "components-hero-internal-color-with-image-title": "#eb2035",
        "components-hero-internal-color-with-image-body": "#fcfcfd",
        "components-heading-page-title-color-default-bg": "#000000",
        "components-heading-page-title-color-default-title": "#eb2035",
        "components-heading-page-title-color-default-body": "#fcfcfd",
        "components-heading-page-title-color-light-bg": "#ffffff",
        "components-heading-page-title-color-light-title": "#191b1c",
        "components-heading-page-title-color-light-body": "#27282b",
        "components-heading-page-title-color-color-bg": "#b6222e",
        "components-heading-page-title-color-color-title": "#191b1c",
        "components-heading-page-title-color-color-body": "#27282b",
        "components-hyperlink-color-dark-default": "#f7cd46",
        "components-hyperlink-color-dark-hover": "#bb9d21",
        "components-hyperlink-color-dark-visited": "#79a9d1",
        "components-hyperlink-color-light-default": "#fef4cc",
        "components-hyperlink-color-light-hover": "#fadb72",
        "components-hyperlink-color-light-visited": "#9ac6e7",
        "components-icon-default": "#72757e",
        "components-icon-focused": "#72757e",
        "components-icon-card-color-bg": "#ffffff",
        "components-icon-card-color-icon-bg": "#b6222e",
        "components-icon-card-color-icon": "#000000",
        "components-icon-card-color-title": "#191b1c",
        "components-icon-card-color-subtitle": "#27282b",
        "components-icon-card-color-description": "#27282b",
        "components-icon-card-listing-color-bg": "#e8e8ea",
        "components-icon-card-listing-color-inner-bg": "#ffffff",
        "components-icon-card-listing-color-brand-bg": "#b6222e",
        "components-icon-card-listing-color-title": "#191b1c",
        "components-icon-card-listing-color-copy": "#27282b",
        "components-icon-card-listing-color-inner-legal-text": "#fcfcfd",
        "components-jumplink-color-bg-default": "#ffffff",
        "components-jumplink-color-link-text-default": "#4e5056",
        "components-jumplink-color-link-text-hover": "#191b1c",
        "components-jumplink-color-link-border-hover": "#191b1c",
        "components-jumplink-color-link-text-active": "#000000",
        "components-jumplink-color-link-border-active": "#000000",
        "components-jumplink-color-title-text": "#191b1c",
        "components-jumplink-color-description-text": "#27282b",
        "components-layout-bg-default": "#ffffff",
        "components-lightbox-color-overlay-bg": "rgba(232, 232, 234, 0.95)",
        "components-lightbox-color-title": "#191b1c",
        "components-lightbox-color-body": "#27282b",
        "components-label-color-on-color-label-label-default": "#ffffff",
        "components-label-color-on-color-label-label-hover": "#4e5056",
        "components-label-color-on-color-label-label-pressed": "#27282b",
        "components-label-color-on-color-background-bg-default": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-color-background-bg-hover": "#ffffff",
        "components-label-color-on-color-background-bg-pressed": "#e8e8ea",
        "components-label-color-on-color-stroke-hover": "#ffffff",
        "components-label-color-on-color-stroke-pressed": "#e8e8ea",
        "components-label-color-on-color-stroke-default-gradient-fill-1": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-2": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-3": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-4": "#ffffff",
        "components-label-color-on-white-label-label-default": "#191b1c",
        "components-label-color-on-white-label-label-hover": "#ffffff",
        "components-label-color-on-white-label-label-pressed": "#ffffff",
        "components-label-color-on-white-background-bg-default": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-white-background-bg-hover": "#191b1c",
        "components-label-color-on-white-background-bg-pressed": "#000000",
        "components-label-color-on-white-stroke-hover": "#191b1c",
        "components-label-color-on-white-stroke-pressed": "#000000",
        "components-label-color-on-white-stroke-default-gradient-fill-1": "#191b1c",
        "components-label-color-on-white-stroke-default-gradient-fill-2": "#191b1c",
        "components-label-color-on-white-stroke-default-gradient-fill-3": "#191b1c",
        "components-label-color-on-white-stroke-default-gradient-fill-4": "#191b1c",
        "components-legal-disclaimer-color-default-bg": "#e8e8ea",
        "components-legal-disclaimer-color-default-title": "#191b1c",
        "components-legal-disclaimer-color-default-body": "#27282b",
        "components-legal-disclaimer-color-light-bg": "#e8e8ea",
        "components-legal-disclaimer-color-light-title": "#191b1c",
        "components-legal-disclaimer-color-light-body": "#27282b",
        "components-legal-disclaimer-color-brand-bg": "#000000",
        "components-legal-disclaimer-color-brand-title": "#ffffff",
        "components-legal-disclaimer-color-brand-body": "#fcfcfd",
        "components-media-video-thumbnail-overlay-bg": "rgba(25, 27, 28, 0.80)",
        "components-media-caption-color-bg": "#fcfcfd",
        "components-media-caption-color-text": "#27282b",
        "components-media-gallery-color-bg": "#e8e8ea",
        "components-media-gallery-color-title": "#191b1c",
        "components-media-gallery-color-body": "#27282b",
        "components-pagination-on-white-accent-default": "#27282b",
        "components-pagination-on-white-accent-scroll": "#b6222e",
        "components-pagination-on-white-disabled": "#a2a5ab",
        "components-pagination-on-white-bg": "#e8e8ea",
        "components-pagination-on-white-stroke": "#e8e8ea",
        "components-pagination-on-white-bg-active": "#b6222e",
        "components-pagination-on-white-bg-hover": "#e8e8ea",
        "components-pagination-on-white-text-default": "#72757e",
        "components-pagination-on-white-text-hover": "#27282b",
        "components-pagination-on-white-text-active": "#ffffff",
        "components-pagination-on-color-accent-default": "#ffffff",
        "components-pagination-on-color-accent-scroll": "#e8e8ea",
        "components-pagination-on-color-disabled": "#4e2324",
        "components-pagination-on-color-bg": "#ed9599",
        "components-pagination-on-color-stroke": "rgba(255, 255, 255, 0.38)",
        "components-pagination-on-color-bg-active": "#ffffff",
        "components-pagination-on-color-bg-hover": "#27282b",
        "components-pagination-on-color-text-default": "#ffffff",
        "components-pagination-on-color-text-hover": "#ffffff",
        "components-pagination-on-color-text-active": "#191b1c",
        "components-pdf-link-card-color-bg": "#e8e8ea",
        "components-pdf-link-card-color-card-bg": "#ffffff",
        "components-pdf-link-card-color-card-title": "#191b1c",
        "components-pdf-link-card-color-card-subtitle": "#27282b",
        "components-pdf-link-card-color-card-body": "#27282b",
        "components-product-card-color-bg": "#ffffff",
        "components-product-card-color-content-bg": "#ffffff",
        "components-product-card-color-border": "#e8e8ea",
        "components-product-card-color-title": "#191b1c",
        "components-product-card-color-description": "#27282b",
        "components-product-card-color-star-rating": "#3161b3",
        "components-product-card-color-badge-bg": "#f7cd46",
        "components-product-card-color-badge-text": "#191b1c",
        "components-product-card-listing-color-bg": "#e8e8ea",
        "components-product-card-listing-color-title": "#191b1c",
        "components-product-card-listing-color-copy": "#27282b",
        "components-product-card-listing-color-legal-text": "#27282b",
        "components-product-information-color-bg": "#ffffff",
        "components-product-information-color-content-bg": "#ffffff",
        "components-product-information-color-headline": "#191b1c",
        "components-product-information-color-body": "#27282b",
        "components-product-information-color-detail-text": "#27282b",
        "components-product-information-color-divider": "#a2a5ab",
        "components-product-information-color-icon": "#b6222e",
        "components-product-information-color-play-icon": "#ffffff",
        "components-product-information-color-selected-stroke": "#f7cd46",
        "components-product-results-list-with-search-color-bg": "#ffffff",
        "components-promo-color-default-bg": "#e8e8ea",
        "components-promo-color-default-image-bg": "#e8e8ea",
        "components-promo-color-default-content-bg": "#e8e8ea",
        "components-promo-color-default-title": "#191b1c",
        "components-promo-color-default-body": "#27282b",
        "components-promo-color-default-category": "#191b1c",
        "components-promo-color-light-bg": "#ffffff",
        "components-promo-color-light-image-bg": "#ffffff",
        "components-promo-color-light-content-bg": "#ffffff",
        "components-promo-color-light-title": "#191b1c",
        "components-promo-color-light-body": "#27282b",
        "components-promo-color-light-category": "#191b1c",
        "components-promo-color-brand-bg": "#000000",
        "components-promo-color-brand-image-bg": "#000000",
        "components-promo-color-brand-content-bg": "#000000",
        "components-promo-color-brand-title": "#ffffff",
        "components-promo-color-brand-body": "#fcfcfd",
        "components-promo-color-brand-category": "#ffffff",
        "components-pull-quote-color-bg": "#eb2035",
        "components-pull-quote-color-stroke": "#191b1c",
        "components-pull-quote-color-quote-bg": "#ffffff",
        "components-pull-quote-color-quote-title": "#191b1c",
        "components-pull-quote-color-quote-by-text": "#191b1c",
        "components-pull-quote-color-quote-detail": "#eb2035",
        "components-pull-quote-color-quote-highlight": "#b6222e",
        "components-promo-alternate-color-default-bg": "#e8e8ea",
        "components-promo-alternate-color-default-image-bg": "#e8e8ea",
        "components-promo-alternate-color-default-content-bg": "#e8e8ea",
        "components-promo-alternate-color-default-title": "#191b1c",
        "components-promo-alternate-color-default-body": "#27282b",
        "components-promo-alternate-color-default-category": "#191b1c",
        "components-promo-alternate-color-light-bg": "#ffffff",
        "components-promo-alternate-color-light-image-bg": "#ffffff",
        "components-promo-alternate-color-light-content-bg": "#ffffff",
        "components-promo-alternate-color-light-title": "#191b1c",
        "components-promo-alternate-color-light-body": "#27282b",
        "components-promo-alternate-color-light-category": "#191b1c",
        "components-promo-alternate-color-brand-bg": "#000000",
        "components-promo-alternate-color-brand-image-bg": "#000000",
        "components-promo-alternate-color-brand-content-bg": "#000000",
        "components-promo-alternate-color-brand-title": "#ffffff",
        "components-promo-alternate-color-brand-body": "#fcfcfd",
        "components-promo-alternate-color-brand-category": "#ffffff",
        "components-share-list-color-title": "#ffffff",
        "components-share-list-color-bg": "#27282b",
        "components-share-list-color-link-default": "#fcfcfd",
        "components-share-list-color-link-hover": "#eb2035",
        "components-share-list-color-bg-link-hover": "#191b1c",
        "components-share-list-color-close-icon": "#ffffff",
        "components-share-list-color-facebook-icon": "#3b5998",
        "components-share-list-color-pinterest-icon": "#e60023",
        "components-share-list-color-twitter-icon": "#ffffff",
        "components-share-list-color-basic-icon-default": "#ffffff",
        "components-share-list-color-icon-hover": "#b6222e",
        "components-share-list-color-border": "#191b1c",
        "components-site-search-bg": "#ffffff",
        "components-site-search-results_text": "#27282b",
        "components-site-search-filter-filter-selected-bg": "#fefbfb",
        "components-site-search-facet-group-label-bottom-line": "#a2a5ab",
        "components-site-search-facet-group-label-text": "#27282b",
        "components-site-search-facet-group-label-bg": "#ffffff",
        "components-site-search-facet-bg": "#ffffff",
        "components-site-search-facet-stroke": "#a2a5ab",
        "components-site-search-refine-bg": "#ffffff",
        "components-site-search-refine-right-divider": "#e8e8ea",
        "components-site-search-refine-title": "#191b1c",
        "components-site-search-hero-search-bg": "#191b1c",
        "components-site-search-hero-search-accent-bg": "#191b1c",
        "components-site-search-hero-search-title": "#eb2035",
        "components-stats-stat-text": "#eb2035",
        "components-stats-stat-text-on-light": "#191b1c",
        "components-stats-supporting-text": "#fcfcfd",
        "components-stats-bg-default": "#000000",
        "components-stats-bg-inner": "#000000",
        "components-stats-bg-section-inner": "#000000",
        "components-stats-title": "#ffffff",
        "components-stats-copy": "#fcfcfd",
        "components-supporting-text-default": "#4e5056",
        "components-supporting-text-destructive": "#b32b30",
        "components-tab-bg": "#ffffff",
        "components-tab-border-default": "#72757e",
        "components-tab-border-hover": "#eb2035",
        "components-tab-border-focused": "#191b1c",
        "components-tab-border-destructive": "#b32b30",
        "components-tab-label-text-default": "#191b1c",
        "components-tab-item-text-default": "#191b1c",
        "components-tab-item-bg-default": "#ffffff",
        "components-tab-item-bg-hover": "#fefbfb",
        "components-tab-input-field-default": "#72757e",
        "components-tab-input-field-focused": "#191b1c",
        "components-tab-input-label-focused": "#191b1c",
        "components-tab-input-field-destructive": "#b32b30",
        "components-tab-bg-2": "#e8e8ea",
        "components-tag-color-bg": "rgba(114, 117, 126, 0.25)",
        "components-tag-color-text": "#191b1c",
        "components-tag-color-stroke": "rgba(114, 117, 126, 0.25)",
        "components-tag-color-bg-hover": "#72757e",
        "components-tag-color-text-hover": "#191b1c",
        "components-tag-color-stroke-hover": "#72757e",
        "components-text-field-bg": "#ffffff",
        "components-text-field-bg-focused": "#fffefa",
        "components-text-field-border-default": "#191b1c",
        "components-text-field-border-hover": "#f7cd46",
        "components-text-field-border-focused": "#191b1c",
        "components-text-field-border-destructive": "#b32b30",
        "components-text-field-input-field-default": "#4e5056",
        "components-text-field-input-field-focused": "#191b1c",
        "components-text-field-input-label-focused": "#191b1c",
        "components-text-field-input-field-destructive": "#b32b30",
        "components-text-field-icon-default": "#4e5056",
        "components-text-field-icon-destructive": "#b32b30",
        "components-text-field-supporting-text-default": "#4e5056",
        "components-text-field-supporting-text-destructive": "#b32b30",
        "components-tip-color-bg": "#e8e8ea",
        "components-tip-color-title": "#191b1c",
        "components-tip-color-body": "#27282b",
        "components-tip-color-stroke": "#e8e8ea",
        "components-video-color-bg": "#ffffff",
        "themes-general-surface-color-light-bg": "#e8e8ea",
        "themes-general-surface-color-light-title": "#191b1c",
        "themes-general-surface-color-light-body": "#27282b",
        "themes-general-surface-color-light-decor-line-break": "#a2a5ab",
        "themes-general-surface-color-light-rule-line": "#a2a5ab",
        "themes-general-surface-color-dark-bg": "#000000",
        "themes-general-surface-color-dark-title": "#ffffff",
        "themes-general-surface-color-dark-body": "#fcfcfd",
        "themes-general-surface-color-dark-decor-line-break": "#a2a5ab",
        "themes-general-card-color-dark-title": "#191b1c",
        "themes-general-card-color-dark-subtitle": "#27282b",
        "themes-general-card-color-dark-body": "#27282b",
        "themes-general-card-color-light-title": "#ffffff",
        "themes-general-card-color-light-subtitle": "#fcfcfd",
        "themes-general-card-color-light-body": "#fcfcfd",
        "themes-shadow-color": "#ffffff",
        "themes-shadow-share-list-color-fill": "rgba(0, 0, 0, 0.40)",
        "themes-shadow-header-menu-color-fill": "rgba(0, 0, 0, 0.20)",
        "themes-gradient-loader-gradient-fill-1": "#191b1c",
        "themes-gradient-loader-gradient-fill-2": "rgba(25, 27, 28, 0.00)",
        "themes-color-background-white": "#ffffff",
        "themes-color-background-light": "#e8e8ea",
        "themes-color-background-dark": "#27282b",
        "themes-color-background-black": "#000000",
        "themes-color-background-brand1": "#b6222e",
        "themes-color-background-brand2": "#f7cd46",
        "themes-color-background-accent1": "#3161b3",
        "themes-color-background-accent2": "#eb2035",
        "themes-color-background-body": "#ffffff",
        "themes-color-stroke-white": "#ffffff",
        "themes-color-stroke-light": "#a2a5ab",
        "themes-color-stroke-dark": "#27282b",
        "themes-color-stroke-black": "#191b1c",
        "themes-color-stroke-brand1": "#eb2035",
        "themes-color-stroke-brand2": "#f7cd46",
        "themes-color-stroke-accent1": "#3161b3",
        "themes-color-stroke-accent2": "#79a9d1",
        "themes-color-text-white": "#ffffff",
        "themes-color-text-light": "#fcfcfd",
        "themes-color-text-dark": "#27282b",
        "themes-color-text-black": "#191b1c",
        "themes-color-text-brand1": "#eb2035",
        "themes-color-text-brand2": "#e8be25",
        "themes-color-text-accent1": "#2b3958",
        "themes-color-text-accent2": "#567ca0",
        "colors-grayscale-100": "#fcfcfd",
        "colors-grayscale-200": "#e8e8ea",
        "colors-grayscale-300": "#a2a5ab",
        "colors-grayscale-400": "#72757e",
        "colors-grayscale-500": "#4e5056",
        "colors-grayscale-600": "#27282b",
        "colors-grayscale-700": "#191b1c",
        "colors-brand-1-100": "#fefbfb",
        "colors-brand-1-200": "#f4cacb",
        "colors-brand-1-300": "#ed9599",
        "colors-brand-1-400": "#eb2035",
        "colors-brand-1-500": "#b6222e",
        "colors-brand-1-600": "#7e272b",
        "colors-brand-1-700": "#4e2324",
        "colors-brand-2-100": "#fffefa",
        "colors-brand-2-200": "#fef4cc",
        "colors-brand-2-300": "#fadb72",
        "colors-brand-2-400": "#f7cd46",
        "colors-brand-2-500": "#e8be25",
        "colors-brand-2-600": "#bb9d21",
        "colors-brand-2-700": "#5b5220",
        "colors-accent-1-100": "#e6ebf7",
        "colors-accent-1-200": "#b6c6e9",
        "colors-accent-1-300": "#86a2db",
        "colors-accent-1-400": "#3161b3",
        "colors-accent-1-500": "#314d83",
        "colors-accent-1-600": "#2b3958",
        "colors-accent-1-700": "#202533",
        "colors-accent-2-100": "#b2d0ed",
        "colors-accent-2-200": "#9ac6e7",
        "colors-accent-2-300": "#88b8dd",
        "colors-accent-2-400": "#79a9d1",
        "colors-accent-2-500": "#6b9ac3",
        "colors-accent-2-600": "#5e8bb4",
        "colors-accent-2-700": "#567ca0",
        "colors-accent-3-100": "#fcfdfd",
        "colors-accent-3-200": "#e6eeee",
        "colors-accent-3-300": "#b9d1d1",
        "colors-accent-3-400": "#a3c3c2",
        "colors-accent-3-500": "#8babaa",
        "colors-accent-3-600": "#8babaa",
        "colors-accent-3-700": "#759090",
        "colors-accent-4-400_storage": "#74aa50",
        "colors-accessible-accent-1-100": "#e6ebf7",
        "colors-accessible-accent-1-200": "#b6c6e9",
        "colors-accessible-accent-1-300": "#86a2db",
        "colors-accessible-accent-1-400": "#3161b3",
        "colors-accessible-accent-1-500": "#314d83",
        "colors-accessible-accent-1-600": "#2b3958",
        "colors-accessible-accent-1-700": "#202533",
        "colors-accessible-accent-2-100": "#b2d0ed",
        "colors-accessible-accent-2-200": "#9ac6e7",
        "colors-accessible-accent-2-300": "#88b8dd",
        "colors-accessible-accent-2-400": "#79a9d1",
        "colors-accessible-accent-2-500": "#6b9ac3",
        "colors-accessible-accent-2-600": "#5e8bb4",
        "colors-accessible-accent-2-700": "#567ca0",
        "colors-accessible-brand-2-100": "#fffefa",
        "colors-accessible-brand-2-200": "#fef4cc",
        "colors-accessible-brand-2-300": "#fadb72",
        "colors-accessible-brand-2-400": "#f7cd46",
        "colors-accessible-brand-2-500": "#e8be25",
        "colors-accessible-brand-2-600": "#bb9d21",
        "colors-accessible-brand-2-700": "#5b5220"
      },
      "spacing": {
        "components-accordion-spacing-large-padding-x": "5rem",
        "components-accordion-spacing-large-icon-margin-right": "2rem",
        "components-accordion-spacing-large-copy-margin-right": "2.5rem",
        "components-accordion-spacing-large-maxwidth": "59.625rem",
        "components-accordion-spacing-large-section-padding-x": "4rem",
        "components-accordion-spacing-large-section-padding-y": "2.5rem",
        "components-accordion-spacing-large-section-copy-margin-bottom": "2.5rem",
        "components-accordion-spacing-large-section-space-between": "0rem",
        "components-accordion-spacing-large-item-padding-x": "0rem",
        "components-accordion-spacing-large-item-padding-y": "1.5rem",
        "components-accordion-spacing-large-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-large-content-padding-x": "0rem",
        "components-accordion-spacing-large-content-padding-y": "1.5rem",
        "components-accordion-spacing-large-content-title-margin-bottom": "2rem",
        "components-accordion-spacing-large-content-content-space-between": "0.25rem",
        "components-accordion-spacing-large-content-content-margin-left": "1rem",
        "components-accordion-spacing-small-padding-top": "1rem",
        "components-accordion-spacing-small-padding-x": "1.5rem",
        "components-accordion-spacing-small-padding-y": "2.5rem",
        "components-accordion-spacing-small-icon-margin-right": "0.75rem",
        "components-accordion-spacing-small-title-margin-bottom": "0.5rem",
        "components-accordion-spacing-small-copy-margin-right": "2rem",
        "components-accordion-spacing-small-copy-margin-bottom": "1.5rem",
        "components-accordion-spacing-small-section-padding-x": "1.5rem",
        "components-accordion-spacing-small-section-padding-y": "2.5rem",
        "components-accordion-spacing-small-section-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-space-between": "2.5rem",
        "components-accordion-spacing-small-item-padding-x": "0rem",
        "components-accordion-spacing-small-item-padding-y": "1rem",
        "components-accordion-spacing-small-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-small-content-padding-x": "0rem",
        "components-accordion-spacing-small-content-padding-y": "0.75rem",
        "components-accordion-spacing-small-content-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-content-content-space-between": "1rem",
        "components-accordion-spacing-small-content-content-margin-left": "1rem",
        "components-article-card-spacing-large-padding-image-x": "1rem",
        "components-article-card-spacing-large-padding-image-y": "1rem",
        "components-article-card-spacing-large-padding-content-x": "1.5rem",
        "components-article-card-spacing-large-padding-content-y": "1.5rem",
        "components-article-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-article-card-spacing-large-button-space-between": "1rem",
        "components-article-card-spacing-large-title-margin-bottom": "1rem",
        "components-article-card-spacing-large-list-content-gap": "1rem",
        "components-article-card-spacing-large-list-image-padding-x": "1rem",
        "components-article-card-spacing-large-list-image-padding-y": "1rem",
        "components-article-card-spacing-small-padding-image-x": "1rem",
        "components-article-card-spacing-small-padding-image-y": "1rem",
        "components-article-card-spacing-small-padding-content-x": "1.5rem",
        "components-article-card-spacing-small-padding-content-y": "1.5rem",
        "components-article-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-article-card-listing-spacing-small-padding-x": "1.5rem",
        "components-article-card-listing-spacing-small-padding-y": "2.5rem",
        "components-article-hero-spacing-large-padding-x": "0rem",
        "components-article-hero-spacing-large-padding-y": "0rem",
        "components-article-hero-spacing-large-title-margin-bottom": "2rem",
        "components-article-hero-spacing-large-label-share-margin-bottom": "2rem",
        "components-article-hero-spacing-large-author-name-margin-bottom": "0.75rem",
        "components-article-hero-spacing-large-author-thumbnail-margin-left": "1rem",
        "components-article-hero-spacing-large-details-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-space-between": "1rem",
        "components-article-hero-spacing-large-body-margin-bottom": "2rem",
        "components-article-hero-spacing-large-image-space-between": "1rem",
        "components-article-hero-spacing-large-image-margin-bottom": "1rem",
        "components-article-hero-spacing-large-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-stats-margin-bottom": "2.5rem",
        "components-article-hero-spacing-small-content-margin-top": "2.5rem",
        "components-article-hero-spacing-small-title-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-label-share-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-authorname-margin-bottom": "0.75rem",
        "components-article-hero-spacing-small-authorthumbnail-margin-left": "1rem",
        "components-article-hero-spacing-small-details-margin-bottom": "1rem",
        "components-article-hero-spacing-small-space-between": "0.75rem",
        "components-article-hero-spacing-small-body-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-image-space-between": "0.75rem",
        "components-article-hero-spacing-small-image-margin-bottom": "1rem",
        "components-article-hero-spacing-small-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-stats-margin-bottom": "2rem",
        "components-banner-spacing-large-content-padding-x": "1.5rem",
        "components-banner-spacing-large-content-space-between": "1rem",
        "components-banner-spacing-large-content-width": "69.375rem",
        "components-banner-spacing-icon-dimensions": "1.5rem",
        "components-banner-spacing-icon-margin-left": "0.5rem",
        "components-banner-spacing-small-space-between": "1rem",
        "components-banner-spacing-small-content-space-between": "1rem",
        "components-banner-spacing-small-title-margin-bottom": "0.75rem",
        "components-banner-spacing-small-content-margin-bottom": "1rem",
        "components-breadcrumb-spacing-large-space-between": "0.25rem",
        "components-breadcrumb-spacing-small-space-between": "0.25rem",
        "components-button-spacing-large-icon-large-x": "2.5rem",
        "components-button-spacing-large-icon-large-y": "2.5rem",
        "components-button-spacing-large-default-padding-y": "0.75rem",
        "components-button-spacing-large-default-padding-x": "1.5rem",
        "components-button-spacing-large-default-space-between": "0.25rem",
        "components-button-spacing-large-icon-left-padding-left": "1rem",
        "components-button-spacing-large-icon-right-padding-right": "1rem",
        "components-button-spacing-large-icon-only-padding-x": "0.75rem",
        "components-button-spacing-large-icon-only-padding-y": "0.75rem",
        "components-button-spacing-large-tertiary-content-height": "1.5rem",
        "components-button-spacing-compressed-icon-large-x": "2.5rem",
        "components-button-spacing-compressed-icon-large-y": "2.5rem",
        "components-button-spacing-compressed-default-padding-y": "0.5rem",
        "components-button-spacing-compressed-default-padding-x": "1rem",
        "components-button-spacing-compressed-default-space-between": "0.125rem",
        "components-button-spacing-compressed-icon-left-padding-left": "0.5rem",
        "components-button-spacing-compressed-icon-right-padding-right": "0.5rem",
        "components-button-spacing-compressed-icon-only-padding-x": "0.5rem",
        "components-button-group-spacing-padding-y": "0.125rem",
        "components-button-group-spacing-padding-x": "0.25rem",
        "components-button-group-spacing-space-between": "0.5rem",
        "components-category-card-spacing-large-padding-top": "0rem",
        "components-category-card-spacing-large-padding-x": "0rem",
        "components-category-card-spacing-large-padding-y": "0rem",
        "components-category-card-spacing-large-padding-image-x": "0rem",
        "components-category-card-spacing-large-padding-image-y": "0rem",
        "components-category-card-spacing-large-padding-content-x": "2rem",
        "components-category-card-spacing-large-padding-content-y": "2rem",
        "components-category-card-listing-spacing-large-padding-y": "5rem",
        "components-category-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-category-card-listing-spacing-large-card-margin-bottom": "0rem",
        "components-category-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-card-search-result-small-padding-top": "0rem",
        "components-card-search-result-small-padding-x": "0rem",
        "components-card-search-result-small-padding-y": "0rem",
        "components-card-search-result-small-padding-image-x": "0rem",
        "components-card-search-result-small-padding-image-y": "0rem",
        "components-card-search-result-small-padding-content-x": "1.5rem",
        "components-card-search-result-small-padding-content-y": "1.5rem",
        "components-card-search-result-spacing-large-padding-x": "0rem",
        "components-card-search-result-spacing-large-padding-y": "1.5rem",
        "components-card-search-result-spacing-large-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-image-margin-right": "1.5rem",
        "components-card-search-result-spacing-small-padding-x": "1.5rem",
        "components-card-search-result-spacing-small-padding-y": "1.5rem",
        "components-card-search-result-spacing-small-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-image-margin-right": "0.75rem",
        "components-contact-us-promo-spacing-large-padding-x": "5rem",
        "components-contact-us-promo-spacing-large-padding-y": "5rem",
        "components-contact-us-promo-spacing-large-content-margin-right": "5rem",
        "components-contact-us-promo-spacing-large-margin-y": "2.5rem",
        "components-contact-us-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-contact-us-promo-spacing-small-margin-y": "2.5rem",
        "components-content-list-list-item-container-padding-x": "0.75rem",
        "components-content-list-list-item-container-padding-y": "1rem",
        "components-content-list-list-item-content-space-between": "0.5rem",
        "components-content-list-list-item-content-default-padding-y": "0.25rem",
        "components-content-list-list-item-content-hyperlink-padding-y": "0rem",
        "components-content-list-spacing-large-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-large-item-title-margin-bottom": "1rem",
        "components-content-list-spacing-large-item-copy-margin-bottom": "2.5rem",
        "components-content-list-spacing-large-item-icon-copy-margin-top": "0rem",
        "components-content-list-spacing-large-item-margin-bottom": "2rem",
        "components-content-list-spacing-large-item-image-margin-bottom": "0.5rem",
        "components-content-list-spacing-large-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-y": "2.5rem",
        "components-content-list-spacing-small-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-small-item-title-margin-bottom": "0.5rem",
        "components-content-list-spacing-small-item-copy-margin-bottom": "1rem",
        "components-content-list-spacing-small-item-margin-bottom": "1.5rem",
        "components-content-list-spacing-small-header-title-margin-bottom": "1rem",
        "components-content-list-spacing-small-header-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-title-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-button-space-between": "1rem",
        "components-dropdown-item-padding-y": "0.75rem",
        "components-dropdown-item-padding-x": "0.75rem",
        "components-dropdown-item-spacing-x": "0.75rem",
        "components-dropdown-input-padding-y": "0.25rem",
        "components-dropdown-input-padding-x": "1rem",
        "components-footer-brand-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-large-column-margin-right": "4rem",
        "components-footer-brand-spacing-large-column-margin-bottom": "4rem",
        "components-footer-brand-spacing-large-link-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-padding-y": "2.5rem",
        "components-footer-brand-spacing-small-padding-x": "1rem",
        "components-footer-brand-spacing-small-logo-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-column-margin-right": "0.125rem",
        "components-footer-brand-spacing-small-column-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-small-link-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-title-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-text-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-footer-link-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-link-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-link-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-link-minheight": "3rem",
        "components-footer-brand-spacing-small-footer-category-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-category-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-category-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-category-minheight": "3rem",
        "components-footer-global-spacing-large-padding-y": "2.5rem",
        "components-footer-global-spacing-large-padding-x": "5rem",
        "components-footer-global-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-global-spacing-large-column-margin-right": "2rem",
        "components-footer-global-spacing-large-link-margin-bottom": "2rem",
        "components-footer-global-spacing-small-padding-y": "2.5rem",
        "components-footer-global-spacing-small-padding-x": "1rem",
        "components-footer-global-spacing-small-logo-margin-bottom": "1rem",
        "components-footer-global-spacing-small-column-margin-right": "1rem",
        "components-footer-global-spacing-small-link-margin-bottom": "1rem",
        "components-generic-card-spacing-large-padding-x": "0rem",
        "components-generic-card-spacing-large-padding-y": "0rem",
        "components-generic-card-spacing-large-padding-image-x": "0rem",
        "components-generic-card-spacing-large-padding-image-y": "0rem",
        "components-generic-card-spacing-large-padding-content-x": "2rem",
        "components-generic-card-spacing-large-padding-content-y": "2rem",
        "components-generic-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-generic-card-spacing-large-title-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-button-space-between": "1rem",
        "components-generic-card-spacing-small-padding-x": "0rem",
        "components-generic-card-spacing-small-padding-y": "0rem",
        "components-generic-card-spacing-small-padding-image-x": "0rem",
        "components-generic-card-spacing-small-padding-image-y": "0rem",
        "components-generic-card-spacing-small-padding-content-x": "1.5rem",
        "components-generic-card-spacing-small-padding-content-y": "1.5rem",
        "components-global-search-spacing-omnibox-padding-x": "0.125rem",
        "components-global-search-spacing-omnibox-list-padding-y": "0.5rem",
        "components-global-search-spacing-omnibox-label-padding": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-item-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-height": "3rem",
        "components-generic-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-generic-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-header-shadow-x": "0rem",
        "components-header-spacing-large-padding-y": "1rem",
        "components-header-spacing-large-padding-x": "1.5rem",
        "components-header-spacing-large-links-margin-right": "1.5rem",
        "components-header-spacing-large-icons-space-between": "1.5rem",
        "components-header-spacing-large-logo-margin-right": "2rem",
        "components-header-spacing-large-space-between-search": "0.5rem",
        "components-header-spacing-large-height": "5rem",
        "components-header-spacing-large-language-selector-padding-x": "1rem",
        "components-header-spacing-large-language-selector-padding-y": "0.5rem",
        "components-header-spacing-large-language-selector-space-between": "0.75rem",
        "components-header-spacing-large-language-selector-country-space-between": "0.5rem",
        "components-header-spacing-large-mega-menu-padding-y": "2.5rem",
        "components-header-spacing-large-mega-menu-padding-x": "2.5rem",
        "components-header-spacing-large-mega-menu-column-margin-right": "2.5rem",
        "components-header-spacing-large-mega-menu-category-link-margin-bottom": "1rem",
        "components-header-spacing-large-mega-menu-link-margin-bottom": "0.5rem",
        "components-header-spacing-large-mega-menu-featured-padding-x": "2rem",
        "components-header-spacing-large-mega-menu-featured-padding-y": "2rem",
        "components-header-spacing-large-mega-menu-featured-image-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-title-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-link-space-between": "1.5rem",
        "components-header-spacing-large-mega-menu-title-margin-bottom": "2.5rem",
        "components-header-spacing-large-dropdown-padding-y": "1.5rem",
        "components-header-spacing-large-dropdown-padding-x": "1.5rem",
        "components-header-spacing-large-dropdown-margin-bottom": "0.5rem",
        "components-header-spacing-small-language-selector-padding-x": "1rem",
        "components-header-spacing-small-language-selector-padding-y": "0.5rem",
        "components-header-spacing-small-language-selector-space-between": "0.5rem",
        "components-header-spacing-small-padding-left": "1rem",
        "components-header-spacing-small-padding-right": "1rem",
        "components-header-spacing-small-padding-top": "0.5rem",
        "components-header-spacing-small-padding-bottom": "0.5rem",
        "components-header-spacing-small-nav-main-minheight": "3rem",
        "components-header-spacing-small-nav-main-padding-y": "0.5rem",
        "components-header-spacing-small-nav-main-padding-left": "1.5rem",
        "components-header-spacing-small-nav-main-padding-right": "1rem",
        "components-header-spacing-small-nav-category-minheight": "3rem",
        "components-header-spacing-small-nav-category-padding-left": "1.5rem",
        "components-header-spacing-small-nav-category-padding-right": "0.75rem",
        "components-header-spacing-small-nav-category-padding-y": "0.75rem",
        "components-header-spacing-small-nav-category-space-between": "0rem",
        "components-header-spacing-small-nav-menu-link-minheight": "3rem",
        "components-header-spacing-small-nav-menu-link-padding-right": "1.5rem",
        "components-header-spacing-small-nav-menu-link-padding-left": "2.5rem",
        "components-header-spacing-small-nav-menu-link-padding-y": "0.75rem",
        "components-header-spacing-small-mega-menu-padding-y": "0rem",
        "components-header-spacing-small-mega-menu-padding-x": "0rem",
        "components-header-spacing-small-mega-menu-category-link-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-link-margin-bottom": "0rem",
        "components-header-spacing-small-mega-menu-featured-padding-x": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-padding-y": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-image-margin-bottom": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-title-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-featured-link-space-between": "0.75rem",
        "components-header-spacing-small-mega-menu-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-container-padding": "0rem",
        "components-hero-homepage-spacing-large-padding-x": "5rem",
        "components-hero-homepage-spacing-large-padding-y": "2.5rem",
        "components-hero-homepage-spacing-large-minheight": "34.3125rem",
        "components-hero-homepage-spacing-large-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-margin-bottom": "2rem",
        "components-hero-homepage-spacing-large-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-copy-margin-bottom": "2.5rem",
        "components-hero-homepage-spacing-large-cta-margin-right": "1rem",
        "components-hero-homepage-spacing-large-image-height": "34.3125rem",
        "components-hero-homepage-spacing-large-image-width": "45.75rem",
        "components-hero-homepage-spacing-small-padding-x": "0rem",
        "components-hero-homepage-spacing-small-padding-y": "1.5rem",
        "components-hero-homepage-spacing-small-minheight": "34.3125rem",
        "components-hero-homepage-spacing-small-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-copy-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-image-height": "17.5625rem",
        "components-hero-homepage-spacing-small-image-width": "45.75rem",
        "components-hero-internal-spacing-large-padding-y": "2.5rem",
        "components-hero-internal-spacing-large-padding-right": "10rem",
        "components-hero-internal-spacing-large-title-margin-bottom": "1.5rem",
        "components-hero-internal-spacing-large-button-padding-y": "2rem",
        "components-hero-internal-spacing-large-text-maxwidth": "59.625rem",
        "components-hero-internal-spacing-large-minheight": "18.5rem",
        "components-hero-internal-spacing-small-padding-x": "1.5rem",
        "components-hero-internal-spacing-small-padding-right": "0rem",
        "components-hero-internal-spacing-small-button-padding-y": "1.5rem",
        "components-hero-internal-spacing-small-text-maxwidth": "30rem",
        "components-hero-internal-spacing-small-minheight": "13.5rem",
        "components-icon-card-spacing-large-padding-x": "0rem",
        "components-icon-card-spacing-large-padding-y": "0rem",
        "components-icon-card-spacing-large-icon-margin": "1.5rem",
        "components-icon-card-spacing-large-margin-right": "1.5rem",
        "components-icon-card-spacing-large-title-margin-bottom": "1rem",
        "components-icon-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-icon-card-spacing-large-button-space-between": "1.5rem",
        "components-icon-card-spacing-large-top-space-between": "1rem",
        "components-icon-card-spacing-small-padding-x": "1.5rem",
        "components-icon-card-spacing-small-padding-y": "1.5rem",
        "components-icon-card-spacing-small-icon-margin": "1.5rem",
        "components-icon-card-spacing-small-margin-right": "1.5rem",
        "components-icon-card-spacing-small-title-margin-bottom": "1.5rem",
        "components-icon-card-dimensions-large-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-large-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-large-big-icon-height": "4rem",
        "components-icon-card-dimensions-large-big-icon-width": "4rem",
        "components-icon-card-dimensions-large-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-large-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-large-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-large-tiny-icon-width": "2.5rem",
        "components-icon-card-dimensions-small-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-small-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-small-big-icon-height": "3.5rem",
        "components-icon-card-dimensions-small-big-icon-width": "3.5rem",
        "components-icon-card-dimensions-small-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-small-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-small-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-small-tiny-icon-width": "2.5rem",
        "components-icon-card-listing-spacing-large-padding-inner-x": "4rem",
        "components-icon-card-listing-spacing-large-padding-inner-y": "4rem",
        "components-icon-card-listing-spacing-large-card-space-between": "4rem",
        "components-icon-card-listing-spacing-large-title-margin-bottom": "1.5rem",
        "components-icon-card-listing-spacing-large-card-margin-bottom": "4rem",
        "components-icon-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-x": "1.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-y": "2.5rem",
        "components-icon-card-listing-spacing-small-title-margin-bottom": "1rem",
        "components-icon-card-listing-spacing-small-button-margin-top": "1.5rem",
        "components-jumplink-link-padding-bottom": "0.5rem",
        "components-jumplink-spacing-small-title-area-margin-bottom": "1.5rem",
        "components-jumplink-spacing-small-link-container-padding-x": "0rem",
        "components-lightbox-spacing-large-carousel-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-carousel-space-between": "1.5rem",
        "components-lightbox-spacing-large-featured-image-height": "39.25rem",
        "components-lightbox-spacing-large-featured-image-width": "39.25rem",
        "components-lightbox-spacing-large-image-height": "17.0625rem",
        "components-lightbox-spacing-large-image-width": "17.0625rem",
        "components-lightbox-spacing-small-carousel-margin-bottom": "1rem",
        "components-lightbox-spacing-small-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-small-media-details-margin-top": "1rem",
        "components-lightbox-spacing-small-image-height": "20.4375rem",
        "components-lightbox-spacing-small-image-width": "20.4375rem",
        "components-legal-disclaimer-spacing-small-ruleline-width": "20.4375rem",
        "components-media-spacing-large-padding-y": "0.5rem",
        "components-media-spacing-large-padding-x": "0.75rem",
        "components-media-gallery-large-container-grid-gap-x": "1.5rem",
        "components-media-gallery-large-container-grid-gap-y": "1.5rem",
        "components-media-gallery-small-container-grid-gap-x": "0.5rem",
        "components-media-gallery-small-container-grid-gap-y": "0.5rem",
        "components-media-gallery-spacing-small-padding-x": "1.5rem",
        "components-media-gallery-spacing-small-padding-y": "2.5rem",
        "components-media-gallery-spacing-large-padding-x": "5rem",
        "components-media-gallery-spacing-large-padding-y": "5rem",
        "components-product-card-spacing-list-content-gap": "0rem",
        "components-product-card-spacing-large-padding-image-x": "0rem",
        "components-product-card-spacing-large-padding-image-y": "0rem",
        "components-product-card-spacing-large-padding-content-x": "1.5rem",
        "components-product-card-spacing-large-padding-content-y": "1.5rem",
        "components-product-card-spacing-large-category-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-title-margin-bottom": "1rem",
        "components-product-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-button-space-between": "1rem",
        "components-product-card-spacing-small-padding-image-x": "0rem",
        "components-product-card-spacing-small-padding-image-y": "0rem",
        "components-product-card-spacing-small-padding-content-x": "1.5rem",
        "components-product-card-spacing-small-padding-content-y": "1.5rem",
        "components-product-card-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-large-padding-x": "5rem",
        "components-product-information-spacing-large-padding-y": "2.5rem",
        "components-product-information-spacing-large-title-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-label-margin-bottom": "2.5rem",
        "components-product-information-spacing-large-ratings-margin-bottom": "1rem",
        "components-product-information-spacing-large-details-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-space-between": "1rem",
        "components-product-information-spacing-large-carousel-space-between": "5rem",
        "components-product-information-spacing-large-button-margin-y": "0.5rem",
        "components-product-information-spacing-large-body-margin-top": "1.5rem",
        "components-product-information-spacing-large-image-space-between": "1rem",
        "components-product-information-spacing-large-image-margin-bottom": "1rem",
        "components-product-information-spacing-small-padding-x": "1.5rem",
        "components-product-information-spacing-small-padding-y": "1.5rem",
        "components-product-information-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-small-label-margin-bottom": "1.5rem",
        "components-product-information-spacing-small-ratings-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-details-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-space-between": "0.75rem",
        "components-product-information-spacing-small-carousel-space-between": "2.5rem",
        "components-product-information-spacing-small-button-margin-y": "0.75rem",
        "components-product-information-spacing-small-body-margin-top": "1rem",
        "components-product-information-spacing-small-image-space-between": "0.75rem",
        "components-product-information-spacing-small-image-margin-bottom": "1rem",
        "components-product-results-list-with-search-spacing-large-padding-x": "5rem",
        "components-product-results-list-with-search-spacing-large-padding-y": "5rem",
        "components-product-results-list-with-search-spacing-large-ruleline-margin-top": "2.5rem",
        "components-product-results-list-with-search-spacing-large-filter-space-between": "1rem",
        "components-product-results-list-with-search-spacing-large-card-space-between": "1.5rem",
        "components-product-results-list-with-search-spacing-large-card-container-margin-bottom": "5rem",
        "components-product-results-list-with-search-spacing-small-padding-x": "1.5rem",
        "components-product-results-list-with-search-spacing-small-padding-y": "2.5rem",
        "components-product-results-list-with-search-spacing-small-ruleline-margin-top": "1rem",
        "components-product-results-list-with-search-spacing-small-filter-padding-x": "0.5rem",
        "components-product-results-list-with-search-spacing-small-card-space-between": "1rem",
        "components-product-results-list-with-search-spacing-small-card-margin-bottom": "1.5rem",
        "components-promo-spacing-large-padding-x": "5rem",
        "components-promo-spacing-large-padding-y": "5rem",
        "components-promo-spacing-large-content-space-between": "5rem",
        "components-promo-spacing-large-content-space-between-y": "5rem",
        "components-promo-spacing-large-padding-content-x": "0rem",
        "components-promo-spacing-large-padding-content-condensed-x": "0rem",
        "components-promo-spacing-large-padding-content-y": "0rem",
        "components-promo-spacing-large-padding-content-condensed-y": "0rem",
        "components-promo-spacing-large-padding-image-x": "0rem",
        "components-promo-spacing-large-padding-image-y": "0rem",
        "components-promo-spacing-large-padding-image-top": "5rem",
        "components-promo-spacing-large-title-margin-bottom": "2rem",
        "components-promo-spacing-large-margin-y": "2rem",
        "components-promo-spacing-large-buttons-space-between": "1rem",
        "components-promo-spacing-small-padding-x": "0rem",
        "components-promo-spacing-small-padding-y": "0rem",
        "components-promo-spacing-small-padding-top": "0rem",
        "components-promo-spacing-small-padding-content-x": "1.5rem",
        "components-promo-spacing-small-padding-content-y": "2.5rem",
        "components-promo-spacing-small-padding-image-x": "0rem",
        "components-promo-spacing-small-padding-image-y": "0rem",
        "components-promo-spacing-small-padding-image-top": "0rem",
        "components-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-promo-spacing-small-margin-y": "2.5rem",
        "components-promo-minheight": "30rem",
        "components-pull-quote-spacing-large-padding-quote-x": "4rem",
        "components-pull-quote-spacing-large-padding-quote-y": "4rem",
        "components-pull-quote-spacing-large-title-margin-bottom": "2rem",
        "components-pull-quote-spacing-large-quote-margin-bottom": "4rem",
        "components-pull-quote-spacing-large-quote-space-between": "1.5rem",
        "components-pull-quote-spacing-large-detail-space-between": "0.5rem",
        "components-pull-quote-spacing-large-quote-minheight": "25rem",
        "components-pull-quote-spacing-small-padding-quote-x": "1.5rem",
        "components-pull-quote-spacing-small-padding-quote-y": "2.5rem",
        "components-pull-quote-spacing-small-title-margin-bottom": "1.5rem",
        "components-pull-quote-spacing-small-quote-margin-bottom": "2.5rem",
        "components-pull-quote-spacing-small-quote-space-between": "1rem",
        "components-pull-quote-spacing-small-detail-space-between": "1rem",
        "components-pull-quote-spacing-small-quote-minheight": "15rem",
        "components-promo-alternate-minheight": "30rem",
        "components-promo-alternate-spacing-large-padding-x": "5rem",
        "components-promo-alternate-spacing-large-padding-y": "5rem",
        "components-promo-alternate-spacing-large-content-space-between": "5rem",
        "components-promo-alternate-spacing-large-padding-content-x": "0rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-x": "0rem",
        "components-promo-alternate-spacing-large-padding-content-y": "0rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-y": "0rem",
        "components-promo-alternate-spacing-large-padding-image-x": "0rem",
        "components-promo-alternate-spacing-large-padding-image-y": "0rem",
        "components-promo-alternate-spacing-large-padding-image-top": "5rem",
        "components-promo-alternate-spacing-large-title-margin-bottom": "2rem",
        "components-promo-alternate-spacing-small-padding-x": "0rem",
        "components-promo-alternate-spacing-small-padding-y": "0rem",
        "components-promo-alternate-spacing-small-padding-content-x": "1.5rem",
        "components-promo-alternate-spacing-small-padding-content-y": "2.5rem",
        "components-promo-alternate-spacing-small-padding-image-x": "0rem",
        "components-promo-alternate-spacing-small-padding-image-y": "0rem",
        "components-promo-alternate-spacing-small-padding-image-top": "0rem",
        "components-promo-alternate-spacing-small-title-margin-bottom": "1.5rem",
        "components-share-list-spacing-padding-x": "1rem",
        "components-share-list-spacing-padding-y": "1rem",
        "components-header-copy-margin-bottom": "1rem",
        "components-header-margin-bottom": "1.5rem",
        "components-item-margin-bottom": "1.5rem",
        "components-site-search-padding-x": "2.5rem",
        "components-site-search-padding-y": "5rem",
        "components-site-search-facet-label-space-between": "0.25rem",
        "components-site-search-refine-padding-x": "1.5rem",
        "components-site-search-refine-padding-y": "0rem",
        "components-site-search-hero-search-height": "18.5rem",
        "components-site-search-hero-search-large-padding-x": "5rem",
        "components-site-search-hero-search-large-padding-y": "5rem",
        "components-stats-spacing-stat-text-space-between": "0.5rem",
        "components-stats-spacing-large-inner-padding-x": "5rem",
        "components-stats-spacing-large-inner-padding-y": "5rem",
        "components-stats-spacing-small-inner-padding-x": "1.5rem",
        "components-stats-spacing-small-inner-padding-y": "1.5rem",
        "components-icon-card-listing-spacing-small-top-space-between": "1rem",
        "components-supporting-text-padding-x": "0rem",
        "components-supporting-text-padding-bottom": "0.5rem",
        "components-tab-item-padding-y": "0.75rem",
        "components-tab-item-padding-x": "0.75rem",
        "components-tab-item-spacing-x": "0.75rem",
        "components-tab-input-padding-y": "0.25rem",
        "components-tab-input-padding-x": "1rem",
        "components-tag-spacing-padding-x": "0.5rem",
        "components-tag-spacing-padding-y": "0.5rem",
        "components-tag-spacing-space-between": "0.5rem",
        "components-text-field-input-padding-y": "0.25rem",
        "components-text-field-input-padding-x": "1rem",
        "components-text-field-area-input-padding-y": "1rem",
        "components-text-field-area-input-padding-x": "1rem",
        "components-text-field-supporting-text-padding-x": "0rem",
        "components-text-field-supporting-text-padding-top": "0.5rem",
        "themes-general-surface-spacing-large-padding-x": "5rem",
        "themes-general-surface-spacing-large-padding-y": "5rem",
        "themes-general-surface-spacing-large-title-margin-bottom": "2rem",
        "themes-general-surface-spacing-large-body-margin-right": "4rem",
        "themes-general-surface-spacing-large-body-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-large-margin-bottom": "4rem",
        "themes-general-surface-spacing-large-cards-space-between": "1.5rem",
        "themes-general-surface-spacing-large-button-margin-top": "2.5rem",
        "themes-general-surface-spacing-small-padding-x": "1.5rem",
        "themes-general-surface-spacing-small-padding-y": "2.5rem",
        "themes-general-surface-spacing-small-title-margin-bottom": "1rem",
        "themes-general-surface-spacing-small-body-margin-right": "2.5rem",
        "themes-general-surface-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-surface-spacing-small-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-small-cards-space-between": "1rem",
        "themes-general-surface-spacing-small-button-margin-top": "1.5rem",
        "themes-general-card-spacing-large-eyebrow-margin-bottom": "1.5rem",
        "themes-general-card-spacing-large-title-margin-bottom": "1rem",
        "themes-general-card-spacing-large-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-large-button-space-between": "1.5rem",
        "themes-general-card-spacing-small-eyebrow-margin-bottom": "1rem",
        "themes-general-card-spacing-small-title-margin-bottom": "1.5rem",
        "themes-general-card-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-small-button-space-between": "1.5rem",
        "themes-shadow-share-list-spacing-x": "0rem",
        "themes-shadow-share-list-spacing-y": "0.75rem",
        "themes-shadow-share-list-spacing-blur": "1rem",
        "themes-shadow-share-list-spacing-spread": "-0.5rem",
        "themes-shadow-header-menu-spacing-x": "0rem",
        "themes-shadow-header-menu-spacing-y": "0.75rem",
        "themes-shadow-header-menu-spacing-blur": "1rem",
        "themes-shadow-header-menu-spacing-spread": "-0.5rem",
        "themes-typography-rte-large-h1-textsize": "3rem",
        "themes-typography-rte-large-h2-textsize": "2.5rem",
        "themes-typography-rte-large-h3-textsize": "2.25rem",
        "themes-typography-rte-large-h4-textsize": "2rem",
        "themes-typography-rte-large-h5-textsize": "1.5rem",
        "themes-typography-rte-large-h6-textsize": "1.25rem",
        "themes-typography-rte-medium-h1-textsize": "2.5rem",
        "themes-typography-rte-medium-h2-textsize": "2.25rem",
        "themes-typography-rte-medium-h3-textsize": "1.75rem",
        "themes-typography-rte-medium-h4-textsize": "1.5rem",
        "themes-typography-rte-medium-h5-textsize": "1.25rem",
        "themes-typography-rte-medium-h6-textsize": "1.125rem",
        "themes-typography-rte-small-h1-textsize": "2.5rem",
        "themes-typography-rte-small-h2-textsize": "2rem",
        "themes-typography-rte-small-h3-textsize": "1.75rem",
        "themes-typography-rte-small-h4-textsize": "1.5rem",
        "themes-typography-rte-small-h5-textsize": "1.125rem",
        "themes-typography-rte-small-h6-textsize": "1rem",
        "spacing-margin-small-1": "0.125rem",
        "spacing-margin-small-2": "0.25rem",
        "spacing-margin-small-3": "0.5rem",
        "spacing-margin-small-4": "0.75rem",
        "spacing-margin-small-5": "1rem",
        "spacing-margin-small-6": "1.5rem",
        "spacing-margin-small-7": "2.5rem",
        "spacing-margin-medium-2": "0.75rem",
        "spacing-margin-medium-4": "1.5rem",
        "spacing-margin-medium-5": "2rem",
        "spacing-margin-large-1": "0.5rem",
        "spacing-margin-large-2": "1rem",
        "spacing-margin-large-3": "1.5rem",
        "spacing-margin-large-4": "2rem",
        "spacing-margin-large-5": "2.5rem",
        "spacing-margin-large-6": "4rem",
        "spacing-margin-large-7": "5rem",
        "spacing-space-between-small-1": "0.125rem",
        "spacing-space-between-small-2": "0.25rem",
        "spacing-space-between-small-3": "0.5rem",
        "spacing-space-between-small-4": "0.75rem",
        "spacing-space-between-small-5": "1rem",
        "spacing-space-between-small-6": "1.5rem",
        "spacing-space-between-small-7": "2.5rem",
        "spacing-space-between-medium-1": "0.125rem",
        "spacing-space-between-medium-2": "0.25rem",
        "spacing-space-between-medium-3": "0.5rem",
        "spacing-space-between-medium-4": "0.75rem",
        "spacing-space-between-medium-5": "1rem",
        "spacing-space-between-large-1": "0.25rem",
        "spacing-space-between-large-2": "0.5rem",
        "spacing-space-between-large-3": "1rem",
        "spacing-space-between-large-4": "1.5rem",
        "spacing-space-between-large-5": "1.5rem",
        "spacing-space-between-large-6": "2.5rem",
        "spacing-space-between-large-7": "4rem",
        "spacing-padding-small-1": "0.125rem",
        "spacing-padding-small-2": "0.25rem",
        "spacing-padding-small-3": "0.5rem",
        "spacing-padding-small-4": "0.75rem",
        "spacing-padding-small-5": "1rem",
        "spacing-padding-small-6": "1.5rem",
        "spacing-padding-small-7": "2.5rem",
        "spacing-padding-medium-1": "0.25rem",
        "spacing-padding-medium-2": "0.75rem",
        "spacing-padding-medium-3": "1rem",
        "spacing-padding-medium-4": "1.5rem",
        "spacing-padding-medium-5": "2rem",
        "spacing-padding-medium-6": "3rem",
        "spacing-padding-medium-7": "4rem",
        "spacing-padding-large-1": "0.5rem",
        "spacing-padding-large-2": "1rem",
        "spacing-padding-large-3": "1.5rem",
        "spacing-padding-large-4": "2rem",
        "spacing-padding-large-5": "2.5rem",
        "spacing-padding-large-6": "4rem",
        "spacing-padding-large-7": "5rem"
      },
      "string": {
        "components-accordion-section-button": "var(--brand)",
        "components-article-card-listing-button-theme": "var(--brand)",
        "components-article-hero-pagination-buttons": "var(--brand)",
        "components-banner-banner-button": "var(--white)",
        "components-category-card-listing-button-theme": "var(--brand)",
        "components-contact-us-promo-hierarchy-button-theme-1": "var(--filled)",
        "components-contact-us-promo-hierarchy-button-theme-2": "var(--outlined)",
        "components-contact-us-promo-color-button-theme": "var(--brand)",
        "components-contact-us-promo-color-brand-button-theme": "var(--brand)",
        "components-generic-card-button-theme": "var(--brand)",
        "components-global-search-loader": "var(--default)",
        "components-generic-card-listing-decor-top": "var(--raid-1)",
        "components-generic-card-listing-decor-bottom": "var(--raid-2)",
        "components-hero-homepage-pagination-buttons": "var(--tonal)",
        "components-hero-homepage-pattern": "var(--raid)",
        "components-hero-homepage-button-1": "var(--tonal)",
        "components-hero-homepage-button-2": "var(--tonal)",
        "components-hero-homepage-image-ratio": "var(--5:4)",
        "components-hero-internal-theme-button-one-type": "var(--filled)",
        "components-hero-internal-theme-button-two-type": "var(--outlined)",
        "components-hero-internal-theme-button-color": "var(--tonal)",
        "components-hero-internal-theme-button-color-with-image": "var(--tonal)",
        "components-lightbox-button-type": "var(--brand)",
        "components-pdf-link-card-hierarchy-button-theme-1": "var(--filled)",
        "components-pdf-link-card-hierarchy-button-theme-2": "var(--outlined)",
        "components-pdf-link-card-color-button-theme": "var(--brand)",
        "components-product-card-badge-align": "var(--left)",
        "components-product-card-listing-button-color": "var(--brand)",
        "components-promo-theme-button-one-theme": "var(--filled)",
        "components-promo-theme-button-two-theme": "var(--outlined)",
        "components-promo-color-default-button-theme": "var(--brand)",
        "components-promo-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-color-brand-button-theme": "var(--white)",
        "components-pull-quote-border-decor": "var(--glade)",
        "components-promo-alternate-theme-button-one-theme": "var(--filled)",
        "components-promo-alternate-theme-button-two-theme": "var(--outlined)",
        "components-promo-alternate-color-default-button-theme": "var(--brand)",
        "components-promo-alternate-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-alternate-color-brand-button-theme": "var(--brand)",
        "components-share-list-button-color": "var(--brand)",
        "components-site-search-hero-search-hero-pattern": "var(--raid)",
        "components-stats-decor-top": "var(--raid-1)",
        "components-stats-decor-bottom": "var(--raid-2)",
        "themes-brand-loader": "var(--default)",
        "themes-brand-variants": "var(--raid)",
        "themes-tab-variant": "var(--default)",
        "themes-label-variants": "var(--default)",
        "themes-logo": "var(--raid)",
        "themes-category-card": "var(--raid)",
        "themes-title-decor": "var(--raid)",
        "themes-typography-heading-fontfamily": "var(--korolev-condensed)",
        "themes-typography-heading-large-xxlarge-fontweight": "var(--bold)",
        "themes-typography-heading-large-xlarge-fontweight": "var(--bold)",
        "themes-typography-heading-large-large-fontweight": "var(--bold)",
        "themes-typography-heading-large-medium-fontweight": "var(--bold)",
        "themes-typography-heading-large-small-fontweight": "var(--bold)",
        "themes-typography-heading-large-xsmall-fontweight": "var(--bold)",
        "themes-typography-heading-large-xxsmall-fontweight": "var(--bold)",
        "themes-typography-heading-medium-xxlarge-fontweight": "var(--bold)",
        "themes-typography-heading-medium-xlarge-fontweight": "var(--bold)",
        "themes-typography-heading-medium-large-fontweight": "var(--bold)",
        "themes-typography-heading-medium-medium-fontweight": "var(--bold)",
        "themes-typography-heading-medium-small-fontweight": "var(--bold)",
        "themes-typography-heading-medium-xsmall-fontweight": "var(--bold)",
        "themes-typography-heading-medium-xxsmall-fontweight": "var(--bold)",
        "themes-typography-heading-small-xxlarge-fontweight": "var(--bold)",
        "themes-typography-heading-small-xlarge-fontweight": "var(--bold)",
        "themes-typography-heading-small-large-fontweight": "var(--bold)",
        "themes-typography-heading-small-medium-fontweight": "var(--bold)",
        "themes-typography-heading-small-small-fontweight": "var(--bold)",
        "themes-typography-heading-small-xsmall-fontweight": "var(--bold)",
        "themes-typography-heading-small-xxsmall-fontweight": "var(--bold)",
        "themes-typography-rte-fontfamily": "var(--korolev-condensed)",
        "themes-typography-rte-fontweight": "var(--bold)"
      },
      "borderRadius": {
        "components-accordion-spacing-large-item-item-radius": "0rem",
        "components-article-card-spacing-large-image-radius-tl": "0rem",
        "components-article-card-spacing-large-image-radius-tr": "0rem",
        "components-article-card-spacing-large-image-radius-bl": "0rem",
        "components-article-card-spacing-large-image-radius-br": "0rem",
        "components-article-card-spacing-large-list-image-radius-br": "0rem",
        "components-article-card-spacing-small-image-radius-br": "0rem",
        "components-article-card-spacing-small-list-image-radius-br": "0rem",
        "components-article-hero-spacing-large-content-radius": "0rem",
        "components-article-hero-spacing-small-content-radius": "0rem",
        "components-button-radius-large": "0rem",
        "components-button-group-spacing-border-radius": "0rem",
        "components-category-card-radius-border-width": "0.0625rem",
        "components-category-card-radius-border-radius": "7.5rem",
        "components-category-card-radius-border-radius-condensed": "0.5rem",
        "components-category-card-radius-media-radius": "0rem",
        "components-content-list-icon-radius": "7.5rem",
        "components-fab-spacing-border-radius": "0rem",
        "components-header-spacing-large-search-border-radius-top": "0rem",
        "components-header-spacing-large-search-border-radius-bottom": "0rem",
        "components-header-spacing-large-language-selector-radius": "0.25rem",
        "components-header-spacing-large-dropdown-radius": "0rem",
        "components-hero-homepage-spacing-large-container-radius": "0rem",
        "components-hero-internal-spacing-large-icon-radius": "7.5rem",
        "components-hero-internal-spacing-small-icon-radius": "7.5rem",
        "components-lightbox-spacing-large-overlay-radius": "0rem",
        "components-pagination-scrollbar-radius": "62.5rem",
        "components-product-card-spacing-image-radius-tl": "0rem",
        "components-product-card-spacing-image-radius-tr": "0rem",
        "components-product-card-spacing-image-radius-bl": "0rem",
        "components-product-card-spacing-image-radius-br": "0rem",
        "components-product-information-spacing-large-content-radius": "0rem",
        "components-product-information-spacing-small-content-radius": "0rem",
        "components-promo-spacing-large-content-radius": "0rem",
        "components-promo-spacing-large-content-radius-tl": "0rem",
        "components-promo-spacing-large-content-radius-tr": "0rem",
        "components-promo-spacing-large-content-radius-bl": "0rem",
        "components-promo-spacing-large-content-radius-br": "0rem",
        "components-promo-spacing-large-image-radius-tl": "0rem",
        "components-promo-spacing-large-image-radius-tr": "0rem",
        "components-promo-spacing-large-image-radius-bl": "0rem",
        "components-promo-spacing-large-image-radius-br": "0rem",
        "components-promo-spacing-small-content-radius": "0rem",
        "components-promo-spacing-small-image-radius-tl": "0rem",
        "components-promo-spacing-small-image-radius-tr": "0rem",
        "components-promo-spacing-small-image-radius-bl": "0rem",
        "components-promo-spacing-small-image-radius-br": "0rem",
        "components-promo-alternate-spacing-large-content-radius": "0rem",
        "components-promo-alternate-spacing-large-image-radius-tl": "0rem",
        "components-promo-alternate-spacing-large-image-radius-tr": "0rem",
        "components-promo-alternate-spacing-large-image-radius-bl": "0rem",
        "components-promo-alternate-spacing-large-image-radius-br": "0rem",
        "components-promo-alternate-spacing-small-content-radius": "0rem",
        "components-promo-alternate-spacing-small-image-radius-tl": "0rem",
        "components-promo-alternate-spacing-small-image-radius-tr": "0rem",
        "components-promo-alternate-spacing-small-image-radius-bl": "0rem",
        "components-promo-alternate-spacing-small-image-radius-br": "0rem",
        "components-share-list-spacing-radius": "0.5rem",
        "components-share-list-spacing-link-radius": "0.5rem",
        "components-site-search-filter-radius": "0.5rem",
        "components-site-search-facet-radius": "0rem",
        "components-site-search-refine-radius": "0rem",
        "components-tag-spacing-radius": "0rem",
        "components-tip-spacing-radius": "0rem",
        "themes-spacing-radius-pagination-bar": "62.5rem",
        "themes-spacing-radius-large-button": "0rem",
        "themes-spacing-radius-large-button-icon": "0rem",
        "themes-spacing-radius-large-image": "0rem",
        "themes-spacing-radius-large-image-hero": "0rem",
        "themes-spacing-radius-large-image-card": "0rem",
        "themes-spacing-radius-large-card": "0rem",
        "themes-spacing-radius-large-icon": "7.5rem",
        "themes-spacing-radius-large-label": "0rem",
        "themes-spacing-radius-large-tag": "0rem",
        "themes-spacing-radius-large-container-tip": "0rem",
        "themes-spacing-radius-large-badge": "0rem",
        "themes-spacing-radius-large-item": "0.5rem",
        "themes-spacing-radius-large-form-field-input": "0rem",
        "themes-spacing-radius-large-form-area-input": "0.25rem",
        "themes-spacing-radius-large-general": "0rem",
        "themes-spacing-radius-small-button": "0rem",
        "themes-spacing-radius-small-button-icon": "0rem",
        "themes-spacing-radius-small-image": "0rem",
        "themes-spacing-radius-small-image-card": "0rem",
        "themes-spacing-radius-small-card": "0rem",
        "themes-spacing-radius-small-icon": "7.5rem",
        "themes-spacing-radius-small-label": "0rem",
        "themes-spacing-radius-small-tag": "0rem",
        "themes-spacing-radius-small-badge": "0rem",
        "themes-spacing-radius-small-item": "0rem",
        "themes-spacing-radius-small-form-field-input": "0rem",
        "themes-spacing-radius-small-general": "0rem"
      },
      "borderWidth": {
        "3": "3px",
        "components-article-card-spacing-large-border-width": "0.0625rem",
        "components-generic-card-color-border-width": "0.0625rem",
        "components-header-spacing-large-search-border-width-top": "0.125rem",
        "components-header-spacing-large-search-border-width-right": "0.125rem",
        "components-header-spacing-large-search-border-width-bottom": "0.125rem",
        "components-header-spacing-large-search-border-width-left": "0.125rem",
        "components-product-card-spacing-border-width": "0.0625rem"
      },
      "maxHeight": {},
      "maxWidth": {
        "components-article-hero-spacing-large-header-max-width": "66.375rem",
        "components-content-list-spacing-large-item-image-max-width": "33.75rem",
        "components-content-list-spacing-small-item-image-max-width": "33.75rem"
      },
      "minHeight": {
        "components-dropdown-item-min-height": "3rem",
        "components-icon-min-height": "3rem",
        "components-tab-item-min-height": "3rem",
        "components-text-field-input-min-height": "3rem",
        "components-text-field-icon-min-height": "3rem"
      },
      "minWidth": {
        "components-content-list-spacing-small-item-image-min-width": "18.75rem",
        "components-icon-min-width": "3rem",
        "components-text-field-icon-min-width": "3rem"
      },
      "opacity": {
        "components-accessibility-bar-color-bg": 1,
        "components-accessibility-bar-color-text": 1,
        "components-accordion-color-bg": 1,
        "components-accordion-color-title": 1,
        "components-accordion-color-body": 1,
        "components-accordion-color-section-section-bg": 1,
        "components-accordion-color-section-section-title": 1,
        "components-accordion-color-section-section-body": 1,
        "components-accordion-color-item-item-bg": 1,
        "components-accordion-color-item-divider": 1,
        "components-accordion-color-item-icon-icon-default": 1,
        "components-accordion-color-item-icon-icon-hover": 1,
        "components-accordion-color-item-icon-icon-pressed": 1,
        "components-accordion-color-item-icon-icon-active": 1,
        "components-accordion-color-item-title-item-default": 1,
        "components-accordion-color-item-title-item-hover": 1,
        "components-accordion-color-item-title-item-pressed": 1,
        "components-accordion-color-item-title-item-active": 1,
        "components-accordion-color-content-bg": 1,
        "components-accordion-color-content-title": 1,
        "components-accordion-color-content-body": 1,
        "components-article-card-color-bg": 1,
        "components-article-card-color-border": 1,
        "components-article-card-color-content-bg": 1,
        "components-article-card-color-title": 1,
        "components-article-card-color-subtitle": 1,
        "components-article-card-color-copy": 1,
        "components-article-card-color-description": 1,
        "components-article-card-listing-color-default-bg": 1,
        "components-article-card-listing-color-default-title": 1,
        "components-article-card-listing-color-default-copy": 1,
        "components-article-hero-color-content-bg": 1,
        "components-article-hero-color-bg": 1,
        "components-article-hero-color-headline": 1,
        "components-article-hero-color-body": 1,
        "components-article-hero-color-detail-text": 1,
        "components-article-hero-color-divider": 1,
        "components-article-hero-color-icon": 1,
        "components-article-hero-color-play-icon": 1,
        "components-article-hero-color-selected-stroke": 1,
        "components-banner-color-bg-default": 1,
        "components-banner-color-icon-default": 1,
        "components-banner-color-title-default": 1,
        "components-banner-color-body-default": 1,
        "components-banner-color-icon-close-default": 1,
        "components-banner-color-bg-alert": 1,
        "components-banner-color-icon-alert": 1,
        "components-banner-color-title-alert": 1,
        "components-banner-color-body-alert": 1,
        "components-banner-color-icon-close-alert": 1,
        "components-breadcrumb-color-bg": 1,
        "components-breadcrumb-color-breadcrumb-link-default": 1,
        "components-breadcrumb-color-breadcrumb-link-hover": 1,
        "components-breadcrumb-color-breadcrumb-link-active": 1,
        "components-breadcrumb-color-icon-divider": 1,
        "components-bug-finder-prompt": 1,
        "components-button-color-filled-brand-default-bg": 1,
        "components-button-color-filled-brand-default-text": 1,
        "components-button-color-filled-brand-default-stroke": 1,
        "components-button-color-filled-brand-default-icon": 1,
        "components-button-color-filled-brand-hover-bg": 1,
        "components-button-color-filled-brand-hover-text": 1,
        "components-button-color-filled-brand-hover-stroke": 1,
        "components-button-color-filled-brand-hover-icon": 1,
        "components-button-color-filled-brand-pressed-bg": 1,
        "components-button-color-filled-brand-pressed-text": 1,
        "components-button-color-filled-brand-pressed-stroke": 1,
        "components-button-color-filled-brand-pressed-icon": 1,
        "components-button-color-filled-brand-disabled-bg": null,
        "components-button-color-filled-brand-disabled-text": 0.02,
        "components-button-color-filled-brand-disabled-stroke": null,
        "components-button-color-filled-brand-disabled-icon": 0.02,
        "components-button-color-filled-brand-focus-bg": 1,
        "components-button-color-filled-brand-focus-text": 1,
        "components-button-color-filled-brand-focus-stroke": 1,
        "components-button-color-filled-brand-focus-icon": 1,
        "components-button-color-filled-brand-focus-outline": 1,
        "components-button-color-filled-tonal-default-bg": 1,
        "components-button-color-filled-tonal-default-text": 1,
        "components-button-color-filled-tonal-default-stroke": 1,
        "components-button-color-filled-tonal-default-icon": 1,
        "components-button-color-filled-tonal-hover-bg": 1,
        "components-button-color-filled-tonal-hover-text": 1,
        "components-button-color-filled-tonal-hover-stroke": 1,
        "components-button-color-filled-tonal-hover-icon": 1,
        "components-button-color-filled-tonal-pressed-bg": 1,
        "components-button-color-filled-tonal-pressed-text": 1,
        "components-button-color-filled-tonal-pressed-stroke": 1,
        "components-button-color-filled-tonal-pressed-icon": 1,
        "components-button-color-filled-tonal-disabled-bg": 0.02,
        "components-button-color-filled-tonal-disabled-text": null,
        "components-button-color-filled-tonal-disabled-stroke": 0.02,
        "components-button-color-filled-tonal-disabled-icon": null,
        "components-button-color-filled-tonal-focus-bg": 1,
        "components-button-color-filled-tonal-focus-text": 1,
        "components-button-color-filled-tonal-focus-stroke": 1,
        "components-button-color-filled-tonal-focus-icon": 1,
        "components-button-color-filled-tonal-focus-outline": 1,
        "components-button-color-filled-white-default-bg": 1,
        "components-button-color-filled-white-default-text": 1,
        "components-button-color-filled-white-default-stroke": 1,
        "components-button-color-filled-white-default-icon": 1,
        "components-button-color-filled-white-hover-bg": 1,
        "components-button-color-filled-white-hover-text": 1,
        "components-button-color-filled-white-hover-stroke": 1,
        "components-button-color-filled-white-hover-icon": 1,
        "components-button-color-filled-white-pressed-bg": 1,
        "components-button-color-filled-white-pressed-text": 1,
        "components-button-color-filled-white-pressed-stroke": 1,
        "components-button-color-filled-white-pressed-icon": 1,
        "components-button-color-filled-white-disabled-bg": 0.02,
        "components-button-color-filled-white-disabled-text": null,
        "components-button-color-filled-white-disabled-stroke": 0.02,
        "components-button-color-filled-white-disabled-icon": null,
        "components-button-color-filled-white-focus-bg": 1,
        "components-button-color-filled-white-focus-text": 1,
        "components-button-color-filled-white-focus-stroke": 1,
        "components-button-color-filled-white-focus-icon": 1,
        "components-button-color-filled-white-focus-outline": 1,
        "components-button-color-outline-brand-default-bg": 0.02,
        "components-button-color-outline-brand-default-text": 1,
        "components-button-color-outline-brand-default-stroke": 1,
        "components-button-color-outline-brand-default-icon": 1,
        "components-button-color-outline-brand-hover-bg": 1,
        "components-button-color-outline-brand-hover-text": 1,
        "components-button-color-outline-brand-hover-stroke": 1,
        "components-button-color-outline-brand-hover-icon": 1,
        "components-button-color-outline-brand-pressed-bg": 1,
        "components-button-color-outline-brand-pressed-text": 1,
        "components-button-color-outline-brand-pressed-stroke": 1,
        "components-button-color-outline-brand-pressed-icon": 1,
        "components-button-color-outline-brand-disabled-bg": 0.02,
        "components-button-color-outline-brand-disabled-text": null,
        "components-button-color-outline-brand-disabled-stroke": null,
        "components-button-color-outline-brand-disabled-icon": null,
        "components-button-color-outline-brand-focus-bg": 1,
        "components-button-color-outline-brand-focus-text": 1,
        "components-button-color-outline-brand-focus-stroke": 1,
        "components-button-color-outline-brand-focus-icon": 1,
        "components-button-color-outline-brand-focus-outline": 1,
        "components-button-color-outline-tonal-default-bg": null,
        "components-button-color-outline-tonal-default-text": 1,
        "components-button-color-outline-tonal-default-stroke": 1,
        "components-button-color-outline-tonal-default-icon": 1,
        "components-button-color-outline-tonal-hover-bg": 1,
        "components-button-color-outline-tonal-hover-text": 1,
        "components-button-color-outline-tonal-hover-stroke": 1,
        "components-button-color-outline-tonal-hover-icon": 1,
        "components-button-color-outline-tonal-pressed-bg": 1,
        "components-button-color-outline-tonal-pressed-text": 1,
        "components-button-color-outline-tonal-pressed-stroke": 1,
        "components-button-color-outline-tonal-pressed-icon": 1,
        "components-button-color-outline-tonal-disabled-bg": 0.02,
        "components-button-color-outline-tonal-disabled-text": 0.02,
        "components-button-color-outline-tonal-disabled-stroke": 0.02,
        "components-button-color-outline-tonal-disabled-icon": 0.02,
        "components-button-color-outline-tonal-focus-bg": null,
        "components-button-color-outline-tonal-focus-text": 1,
        "components-button-color-outline-tonal-focus-stroke": 1,
        "components-button-color-outline-tonal-focus-icon": 1,
        "components-button-color-outline-tonal-focus-outline": 1,
        "components-button-color-outline-white-default-bg": 0.02,
        "components-button-color-outline-white-default-text": 1,
        "components-button-color-outline-white-default-stroke": 1,
        "components-button-color-outline-white-default-icon": 1,
        "components-button-color-outline-white-hover-bg": 1,
        "components-button-color-outline-white-hover-text": 1,
        "components-button-color-outline-white-hover-stroke": 1,
        "components-button-color-outline-white-hover-icon": 1,
        "components-button-color-outline-white-pressed-bg": 1,
        "components-button-color-outline-white-pressed-text": 1,
        "components-button-color-outline-white-pressed-stroke": 1,
        "components-button-color-outline-white-pressed-icon": 1,
        "components-button-color-outline-white-disabled-bg": 0.02,
        "components-button-color-outline-white-disabled-text": 0.02,
        "components-button-color-outline-white-disabled-stroke": 0.02,
        "components-button-color-outline-white-disabled-icon": 0.02,
        "components-button-color-outline-white-focus-bg": 0.02,
        "components-button-color-outline-white-focus-text": 1,
        "components-button-color-outline-white-focus-stroke": 1,
        "components-button-color-outline-white-focus-icon": 1,
        "components-button-color-outline-white-focus-outline": 1,
        "components-button-color-text-brand-default-bg": 0.02,
        "components-button-color-text-brand-default-text": 1,
        "components-button-color-text-brand-default-stroke": 1,
        "components-button-color-text-brand-default-icon": 1,
        "components-button-color-text-brand-hover-bg": 0.02,
        "components-button-color-text-brand-hover-bg-icon-only": 1,
        "components-button-color-text-brand-hover-text": 1,
        "components-button-color-text-brand-hover-stroke": 1,
        "components-button-color-text-brand-hover-icon": 1,
        "components-button-color-text-brand-pressed-bg": 0.02,
        "components-button-color-text-brand-pressed-bg-icon-only": 1,
        "components-button-color-text-brand-pressed-text": 1,
        "components-button-color-text-brand-pressed-stroke": 1,
        "components-button-color-text-brand-pressed-icon": 1,
        "components-button-color-text-brand-disabled-bg": 0.02,
        "components-button-color-text-brand-disabled-text": null,
        "components-button-color-text-brand-disabled-stroke": null,
        "components-button-color-text-brand-disabled-icon": null,
        "components-button-color-text-brand-focus-bg": 1,
        "components-button-color-text-brand-focus-text": 1,
        "components-button-color-text-brand-focus-stroke": 1,
        "components-button-color-text-brand-focus-icon": 1,
        "components-button-color-text-brand-focus-outline": 1,
        "components-button-color-text-tonal-default-bg": 0.02,
        "components-button-color-text-tonal-default-text": 1,
        "components-button-color-text-tonal-default-stroke": 1,
        "components-button-color-text-tonal-default-icon": 1,
        "components-button-color-text-tonal-hover-bg": 0.02,
        "components-button-color-text-tonal-hover-bg-icon-only": 1,
        "components-button-color-text-tonal-hover-text": 1,
        "components-button-color-text-tonal-hover-stroke": 1,
        "components-button-color-text-tonal-hover-icon": 1,
        "components-button-color-text-tonal-pressed-bg": 0.02,
        "components-button-color-text-tonal-pressed-bg-icon-only": 1,
        "components-button-color-text-tonal-pressed-text": 1,
        "components-button-color-text-tonal-pressed-stroke": 1,
        "components-button-color-text-tonal-pressed-icon": 1,
        "components-button-color-text-tonal-disabled-bg": 0.02,
        "components-button-color-text-tonal-disabled-text": 0.02,
        "components-button-color-text-tonal-disabled-stroke": 0.02,
        "components-button-color-text-tonal-disabled-icon": 0.02,
        "components-button-color-text-tonal-focus-bg": 1,
        "components-button-color-text-tonal-focus-text": 1,
        "components-button-color-text-tonal-focus-stroke": 1,
        "components-button-color-text-tonal-focus-icon": 1,
        "components-button-color-text-tonal-focus-outline": 1,
        "components-button-color-text-white-default-bg": 0.02,
        "components-button-color-text-white-default-text": 1,
        "components-button-color-text-white-default-stroke": 1,
        "components-button-color-text-white-default-icon": 1,
        "components-button-color-text-white-hover-bg": 0.02,
        "components-button-color-text-white-hover-bg-icon-only": 1,
        "components-button-color-text-white-hover-text": 1,
        "components-button-color-text-white-hover-stroke": 1,
        "components-button-color-text-white-hover-icon": 1,
        "components-button-color-text-white-pressed-bg": 0.02,
        "components-button-color-text-white-pressed-bg-icon-only": 1,
        "components-button-color-text-white-pressed-text": 1,
        "components-button-color-text-white-pressed-stroke": 1,
        "components-button-color-text-white-pressed-icon": 1,
        "components-button-color-text-white-disabled-bg": 0.02,
        "components-button-color-text-white-disabled-text": 0.01,
        "components-button-color-text-white-disabled-stroke": 0.01,
        "components-button-color-text-white-disabled-icon": 0.01,
        "components-button-color-text-white-focus-bg": 1,
        "components-button-color-text-white-focus-text": 1,
        "components-button-color-text-white-focus-stroke": 1,
        "components-button-color-text-white-focus-icon": 1,
        "components-button-color-text-white-focus-outline": 1,
        "components-button-group-color-border": 1,
        "components-button-group-color-button-bg-default": 0.02,
        "components-button-group-color-button-fg-default": 1,
        "components-button-group-color-button-bg-hover": null,
        "components-button-group-color-button-fg-hover": 1,
        "components-button-group-color-button-bg-active": 1,
        "components-button-group-color-button-fg-active": 1,
        "components-button-group-color-overlay-left-gradient-fill-1": 1,
        "components-button-group-color-overlay-left-gradient-fill-2": 0.01,
        "components-button-group-color-stroke-default-gradient-fill-1": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-3": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-4": 0.02,
        "components-button-group-color-stroke-hover-gradient-fill-1": null,
        "components-button-group-color-stroke-hover-gradient-fill-2": null,
        "components-button-group-color-stroke-hover-gradient-fill-3": null,
        "components-button-group-color-stroke-hover-gradient-fill-4": null,
        "components-button-group-color-stroke-active-gradient-fill-1": 1,
        "components-button-group-color-stroke-active-gradient-fill-2": 1,
        "components-button-group-color-stroke-active-gradient-fill-3": 1,
        "components-button-group-color-stroke-active-gradient-fill-4": 1,
        "components-category-card-color-bg": 1,
        "components-category-card-color-content-bg": 1,
        "components-category-card-color-category-bg": 1,
        "components-category-card-color-category-bg-2": 1,
        "components-category-card-color-category-bg-3": 1,
        "components-category-card-color-category-bg-4": 1,
        "components-category-card-color-category-bg-5": 1,
        "components-category-card-color-category-bg-6": 1,
        "components-category-card-color-title": 1,
        "components-category-card-color-copy": 1,
        "components-category-card-color-stroke": 1,
        "components-category-card-color-stroke-hover": 1,
        "components-category-card-listing-color-bg": 1,
        "components-category-card-listing-color-title": 1,
        "components-category-card-listing-color-copy": 1,
        "components-card-search-result-color-bg": 1,
        "components-card-search-result-color-label": 1,
        "components-card-search-result-color-title": 1,
        "components-card-search-result-color-body": 1,
        "components-card-search-result-color-divider-line": 1,
        "components-contact-us-promo-color-light-bg": 1,
        "components-contact-us-promo-color-light-title": 1,
        "components-contact-us-promo-color-light-body": 1,
        "components-contact-us-promo-color-default-bg": 1,
        "components-contact-us-promo-color-default-title": 1,
        "components-contact-us-promo-color-default-body": 1,
        "components-contact-us-promo-color-brand-bg": 1,
        "components-contact-us-promo-color-brand-title": 1,
        "components-contact-us-promo-color-brand-body": 1,
        "components-content-list-color-bg": 1,
        "components-content-list-color-title": 1,
        "components-content-list-color-body": 1,
        "components-content-list-color-icon": 1,
        "components-cookies-colors-bg": 1,
        "components-cookies-colors-title": 1,
        "components-cookies-colors-body": 1,
        "components-cookies-colors-icon": 1,
        "components-dropdown-bg": 1,
        "components-dropdown-border-default": 1,
        "components-dropdown-border-hover": 1,
        "components-dropdown-border-focused": 1,
        "components-dropdown-border-destructive": 1,
        "components-dropdown-label-text-default": 1,
        "components-dropdown-item-text-default": 1,
        "components-dropdown-item-bg-default": 1,
        "components-dropdown-item-bg-hover": 1,
        "components-dropdown-input-field-default": 1,
        "components-dropdown-input-field-focused": 1,
        "components-dropdown-input-label-focused": 1,
        "components-dropdown-input-field-destructive": 1,
        "components-external-link-card-bg": 1,
        "components-external-link-card-card-bg": 1,
        "components-external-link-card-card-title": 1,
        "components-external-link-card-card-subtitle": 1,
        "components-external-link-card-card-eyebrow": 1,
        "components-fab-color-border-default": 1,
        "components-footer-brand-color-bg": 1,
        "components-footer-brand-color-logo": 1,
        "components-footer-brand-color-title-text": 1,
        "components-footer-brand-color-description-text": 1,
        "components-footer-brand-color-footer-menu-expanded": 1,
        "components-footer-brand-color-link-default": 1,
        "components-footer-brand-color-link-hover": 1,
        "components-footer-brand-color-link-pressed": 1,
        "components-footer-brand-color-category-link-default": 1,
        "components-footer-brand-color-category-link-hover": 1,
        "components-footer-brand-color-category-link-pressed": 1,
        "components-footer-brand-color-subcategory-link-default": 1,
        "components-footer-brand-color-subcategory-link-hover": 1,
        "components-footer-brand-color-subcategory-link-pressed": 1,
        "components-footer-brand-color-icon-icon-default": 1,
        "components-footer-brand-color-icon-icon-pressed": 1,
        "components-footer-brand-color-social-icon-default": 1,
        "components-footer-brand-color-social-icon-hover": 1,
        "components-footer-brand-color-social-icon-pressed": 1,
        "components-footer-brand-spacing-category-link-hover": 1,
        "components-footer-global-color-bg": 1,
        "components-footer-global-color-title-text": 1,
        "components-footer-global-color-disclaimer-text": 1,
        "components-footer-global-color-link-default": 1,
        "components-footer-global-color-link-hover": 1,
        "components-footer-global-color-link-pressed": 1,
        "components-generic-card-color-bg": 1,
        "components-generic-card-color-content-bg": 1,
        "components-generic-card-color-title": 1,
        "components-generic-card-color-subtitle": 1,
        "components-generic-card-color-copy": 1,
        "components-global-search-color-omnibox-list-bg": 1,
        "components-global-search-color-omnibox-divider": 1,
        "components-global-search-color-omnibox-item-bg-default": 1,
        "components-global-search-color-omnibox-item-bg-hover": 1,
        "components-global-search-color-omnibox-item-text-default": 1,
        "components-global-search-color-omnibox-item-text-hover": 1,
        "components-global-search-color-omnibox-label-text-default": 1,
        "components-global-search-color-omnibox-category-text-default": 1,
        "components-generic-card-listing-color-bg": 1,
        "components-generic-card-listing-color-title": 1,
        "components-generic-card-listing-color-copy": 1,
        "components-header-color-bg": 1,
        "components-header-color-bottom-stroke": 1,
        "components-header-color-search-search-border-default": 1,
        "components-header-color-search-search-text": 1,
        "components-header-color-search-search-icon": 1,
        "components-header-color-search-search-bar": 1,
        "components-header-color-mobile-mobile-expanded-bg": 1,
        "components-header-color-mobile-pressed-bg": 1,
        "components-header-color-language-selector-language": 1,
        "components-header-color-language-selector-country": 1,
        "components-header-color-language-selector-country-hover-bg": 1,
        "components-header-color-language-selector-megamenu-language": 1,
        "components-header-color-language-selector-megamenu-country": 1,
        "components-header-color-language-selector-megamenu-country-hover": 1,
        "components-header-color-language-selector-bg": 1,
        "components-header-color-language-selector-stroke": 1,
        "components-header-color-mega-menu-bg": 1,
        "components-header-color-mega-menu-featured-bg": 1,
        "components-header-color-mega-menu-featured-title": 1,
        "components-header-color-mega-menu-category-title": 1,
        "components-header-color-mega-menu-bg-accent": 1,
        "components-header-color-icon-icon-default": 1,
        "components-header-color-icon-icon-hover": 1,
        "components-header-color-icon-icon-pressed": 1,
        "components-header-color-links-nav-main-link-default": 1,
        "components-header-color-links-nav-main-link-hover": 1,
        "components-header-color-links-nav-main-link-pressed": 1,
        "components-header-color-links-nav-main-link-active": 1,
        "components-header-color-links-nav-main-active-bar": 1,
        "components-header-color-links-nav-main-bg-color": 1,
        "components-header-color-links-nav-category-link-category-default": 1,
        "components-header-color-links-nav-category-link-category-hover": 1,
        "components-header-color-links-nav-category-link-category-pressed": 1,
        "components-header-color-links-nav-category-link-category-active": 1,
        "components-header-color-links-nav-menu-link-link-default": 1,
        "components-header-color-links-nav-menu-link-link-hover": 1,
        "components-header-color-links-nav-menu-link-link-pressed": 1,
        "components-header-color-links-nav-menu-link-link-active": 1,
        "components-header-color-links-nav-menu-link-bg": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-default": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-active": 1,
        "components-hero-homepage-color-bg": 1,
        "components-hero-homepage-color-container-bg": 1,
        "components-hero-homepage-color-eyebrow": 1,
        "components-hero-homepage-color-header": 1,
        "components-hero-homepage-color-sub-header": 1,
        "components-hero-homepage-color-body": 1,
        "components-hero-homepage-color-play-pause": 1,
        "components-hero-internal-color-default-title": 1,
        "components-hero-internal-color-default-body": 1,
        "components-hero-internal-color-default-bg": 1,
        "components-hero-internal-color-accent-bg": 1,
        "components-hero-internal-color-with-image-bg": 1,
        "components-hero-internal-color-default-bg-2": 1,
        "components-hero-internal-color-with-image-title": 1,
        "components-hero-internal-color-with-image-body": 1,
        "components-heading-page-title-color-default-bg": 1,
        "components-heading-page-title-color-default-title": 1,
        "components-heading-page-title-color-default-body": 1,
        "components-heading-page-title-color-light-bg": 1,
        "components-heading-page-title-color-light-title": 1,
        "components-heading-page-title-color-light-body": 1,
        "components-heading-page-title-color-color-bg": 1,
        "components-heading-page-title-color-color-title": 1,
        "components-heading-page-title-color-color-body": 1,
        "components-hyperlink-color-dark-default": 1,
        "components-hyperlink-color-dark-hover": 1,
        "components-hyperlink-color-dark-visited": 1,
        "components-hyperlink-color-light-default": 1,
        "components-hyperlink-color-light-hover": 1,
        "components-hyperlink-color-light-visited": 1,
        "components-icon-default": 1,
        "components-icon-focused": 1,
        "components-icon-card-color-bg": 1,
        "components-icon-card-color-icon-bg": 1,
        "components-icon-card-color-icon": 1,
        "components-icon-card-color-title": 1,
        "components-icon-card-color-subtitle": 1,
        "components-icon-card-color-description": 1,
        "components-icon-card-listing-color-bg": 1,
        "components-icon-card-listing-color-inner-bg": 1,
        "components-icon-card-listing-color-brand-bg": 1,
        "components-icon-card-listing-color-title": 1,
        "components-icon-card-listing-color-copy": 1,
        "components-icon-card-listing-color-inner-legal-text": 1,
        "components-jumplink-color-bg-default": 1,
        "components-jumplink-color-link-text-default": 1,
        "components-jumplink-color-link-text-hover": 1,
        "components-jumplink-color-link-border-hover": 1,
        "components-jumplink-color-link-text-active": 1,
        "components-jumplink-color-link-border-active": 1,
        "components-jumplink-color-title-text": 1,
        "components-jumplink-color-description-text": 1,
        "components-layout-bg-default": 1,
        "components-lightbox-color-overlay-bg": 0.01,
        "components-lightbox-color-title": 1,
        "components-lightbox-color-body": 1,
        "components-label-color-on-color-label-label-default": 1,
        "components-label-color-on-color-label-label-hover": 1,
        "components-label-color-on-color-label-label-pressed": 1,
        "components-label-color-on-color-background-bg-default": 0.02,
        "components-label-color-on-color-background-bg-hover": 1,
        "components-label-color-on-color-background-bg-pressed": 1,
        "components-label-color-on-color-stroke-hover": 1,
        "components-label-color-on-color-stroke-pressed": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-4": 1,
        "components-label-color-on-white-label-label-default": 1,
        "components-label-color-on-white-label-label-hover": 1,
        "components-label-color-on-white-label-label-pressed": 1,
        "components-label-color-on-white-background-bg-default": 0.02,
        "components-label-color-on-white-background-bg-hover": 1,
        "components-label-color-on-white-background-bg-pressed": 1,
        "components-label-color-on-white-stroke-hover": 1,
        "components-label-color-on-white-stroke-pressed": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-4": 1,
        "components-legal-disclaimer-color-default-bg": 1,
        "components-legal-disclaimer-color-default-title": 1,
        "components-legal-disclaimer-color-default-body": 1,
        "components-legal-disclaimer-color-light-bg": 1,
        "components-legal-disclaimer-color-light-title": 1,
        "components-legal-disclaimer-color-light-body": 1,
        "components-legal-disclaimer-color-brand-bg": 1,
        "components-legal-disclaimer-color-brand-title": 1,
        "components-legal-disclaimer-color-brand-body": 1,
        "components-media-video-thumbnail-overlay-bg": null,
        "components-media-caption-color-bg": 1,
        "components-media-caption-color-text": 1,
        "components-media-gallery-color-bg": 1,
        "components-media-gallery-color-title": 1,
        "components-media-gallery-color-body": 1,
        "components-pagination-on-white-accent-default": 1,
        "components-pagination-on-white-accent-scroll": 1,
        "components-pagination-on-white-disabled": 1,
        "components-pagination-on-white-bg": 1,
        "components-pagination-on-white-stroke": 1,
        "components-pagination-on-white-bg-active": 1,
        "components-pagination-on-white-bg-hover": 1,
        "components-pagination-on-white-text-default": 1,
        "components-pagination-on-white-text-hover": 1,
        "components-pagination-on-white-text-active": 1,
        "components-pagination-on-color-accent-default": 1,
        "components-pagination-on-color-accent-scroll": 1,
        "components-pagination-on-color-disabled": 1,
        "components-pagination-on-color-bg": 1,
        "components-pagination-on-color-stroke": 0.02,
        "components-pagination-on-color-bg-active": 1,
        "components-pagination-on-color-bg-hover": 1,
        "components-pagination-on-color-text-default": 1,
        "components-pagination-on-color-text-hover": 1,
        "components-pagination-on-color-text-active": 1,
        "components-pdf-link-card-color-bg": 1,
        "components-pdf-link-card-color-card-bg": 1,
        "components-pdf-link-card-color-card-title": 1,
        "components-pdf-link-card-color-card-subtitle": 1,
        "components-pdf-link-card-color-card-body": 1,
        "components-product-card-color-bg": 1,
        "components-product-card-color-content-bg": 1,
        "components-product-card-color-border": 1,
        "components-product-card-color-title": 1,
        "components-product-card-color-description": 1,
        "components-product-card-color-star-rating": 1,
        "components-product-card-color-badge-bg": 1,
        "components-product-card-color-badge-text": 1,
        "components-product-card-listing-color-bg": 1,
        "components-product-card-listing-color-title": 1,
        "components-product-card-listing-color-copy": 1,
        "components-product-card-listing-color-legal-text": 1,
        "components-product-information-color-bg": 1,
        "components-product-information-color-content-bg": 1,
        "components-product-information-color-headline": 1,
        "components-product-information-color-body": 1,
        "components-product-information-color-detail-text": 1,
        "components-product-information-color-divider": 1,
        "components-product-information-color-icon": 1,
        "components-product-information-color-play-icon": 1,
        "components-product-information-color-selected-stroke": 1,
        "components-product-results-list-with-search-color-bg": 1,
        "components-promo-color-default-bg": 1,
        "components-promo-color-default-image-bg": 1,
        "components-promo-color-default-content-bg": 1,
        "components-promo-color-default-title": 1,
        "components-promo-color-default-body": 1,
        "components-promo-color-default-category": 1,
        "components-promo-color-light-bg": 1,
        "components-promo-color-light-image-bg": 1,
        "components-promo-color-light-content-bg": 1,
        "components-promo-color-light-title": 1,
        "components-promo-color-light-body": 1,
        "components-promo-color-light-category": 1,
        "components-promo-color-brand-bg": 1,
        "components-promo-color-brand-image-bg": 1,
        "components-promo-color-brand-content-bg": 1,
        "components-promo-color-brand-title": 1,
        "components-promo-color-brand-body": 1,
        "components-promo-color-brand-category": 1,
        "components-pull-quote-color-bg": 1,
        "components-pull-quote-color-stroke": 1,
        "components-pull-quote-color-quote-bg": 1,
        "components-pull-quote-color-quote-title": 1,
        "components-pull-quote-color-quote-by-text": 1,
        "components-pull-quote-color-quote-detail": 1,
        "components-pull-quote-color-quote-highlight": 1,
        "components-promo-alternate-color-default-bg": 1,
        "components-promo-alternate-color-default-image-bg": 1,
        "components-promo-alternate-color-default-content-bg": 1,
        "components-promo-alternate-color-default-title": 1,
        "components-promo-alternate-color-default-body": 1,
        "components-promo-alternate-color-default-category": 1,
        "components-promo-alternate-color-light-bg": 1,
        "components-promo-alternate-color-light-image-bg": 1,
        "components-promo-alternate-color-light-content-bg": 1,
        "components-promo-alternate-color-light-title": 1,
        "components-promo-alternate-color-light-body": 1,
        "components-promo-alternate-color-light-category": 1,
        "components-promo-alternate-color-brand-bg": 1,
        "components-promo-alternate-color-brand-image-bg": 1,
        "components-promo-alternate-color-brand-content-bg": 1,
        "components-promo-alternate-color-brand-title": 1,
        "components-promo-alternate-color-brand-body": 1,
        "components-promo-alternate-color-brand-category": 1,
        "components-share-list-color-title": 1,
        "components-share-list-color-bg": 1,
        "components-share-list-color-link-default": 1,
        "components-share-list-color-link-hover": 1,
        "components-share-list-color-bg-link-hover": 1,
        "components-share-list-color-close-icon": 1,
        "components-share-list-color-facebook-icon": 1,
        "components-share-list-color-pinterest-icon": 1,
        "components-share-list-color-twitter-icon": 1,
        "components-share-list-color-basic-icon-default": 1,
        "components-share-list-color-icon-hover": 1,
        "components-share-list-color-border": 1,
        "components-site-search-bg": 1,
        "components-site-search-results_text": 1,
        "components-site-search-filter-filter-selected-bg": 1,
        "components-site-search-facet-group-label-bottom-line": 1,
        "components-site-search-facet-group-label-text": 1,
        "components-site-search-facet-group-label-bg": 1,
        "components-site-search-facet-bg": 1,
        "components-site-search-facet-stroke": 1,
        "components-site-search-refine-bg": 1,
        "components-site-search-refine-right-divider": 1,
        "components-site-search-refine-title": 1,
        "components-site-search-hero-search-bg": 1,
        "components-site-search-hero-search-accent-bg": 1,
        "components-site-search-hero-search-title": 1,
        "components-stats-stat-text": 1,
        "components-stats-stat-text-on-light": 1,
        "components-stats-supporting-text": 1,
        "components-stats-bg-default": 1,
        "components-stats-bg-inner": 1,
        "components-stats-bg-section-inner": 1,
        "components-stats-title": 1,
        "components-stats-copy": 1,
        "components-supporting-text-default": 1,
        "components-supporting-text-destructive": 1,
        "components-tab-bg": 1,
        "components-tab-border-default": 1,
        "components-tab-border-hover": 1,
        "components-tab-border-focused": 1,
        "components-tab-border-destructive": 1,
        "components-tab-label-text-default": 1,
        "components-tab-item-text-default": 1,
        "components-tab-item-bg-default": 1,
        "components-tab-item-bg-hover": 1,
        "components-tab-input-field-default": 1,
        "components-tab-input-field-focused": 1,
        "components-tab-input-label-focused": 1,
        "components-tab-input-field-destructive": 1,
        "components-tab-bg-2": 1,
        "components-tag-color-bg": 0.02,
        "components-tag-color-text": 1,
        "components-tag-color-stroke": 0.02,
        "components-tag-color-bg-hover": 1,
        "components-tag-color-text-hover": 1,
        "components-tag-color-stroke-hover": 1,
        "components-text-field-bg": 1,
        "components-text-field-bg-focused": 1,
        "components-text-field-border-default": 1,
        "components-text-field-border-hover": 1,
        "components-text-field-border-focused": 1,
        "components-text-field-border-destructive": 1,
        "components-text-field-input-field-default": 1,
        "components-text-field-input-field-focused": 1,
        "components-text-field-input-label-focused": 1,
        "components-text-field-input-field-destructive": 1,
        "components-text-field-icon-default": 1,
        "components-text-field-icon-destructive": 1,
        "components-text-field-supporting-text-default": 1,
        "components-text-field-supporting-text-destructive": 1,
        "components-tip-color-bg": 1,
        "components-tip-color-title": 1,
        "components-tip-color-body": 1,
        "components-tip-color-stroke": 1,
        "components-video-color-bg": 1,
        "themes-general-surface-color-light-bg": 1,
        "themes-general-surface-color-light-title": 1,
        "themes-general-surface-color-light-body": 1,
        "themes-general-surface-color-light-decor-line-break": 1,
        "themes-general-surface-color-light-rule-line": 1,
        "themes-general-surface-color-dark-bg": 1,
        "themes-general-surface-color-dark-title": 1,
        "themes-general-surface-color-dark-body": 1,
        "themes-general-surface-color-dark-decor-line-break": 1,
        "themes-general-card-color-dark-title": 1,
        "themes-general-card-color-dark-subtitle": 1,
        "themes-general-card-color-dark-body": 1,
        "themes-general-card-color-light-title": 1,
        "themes-general-card-color-light-subtitle": 1,
        "themes-general-card-color-light-body": 1,
        "themes-shadow-color": 1,
        "themes-shadow-share-list-color-fill": 0,
        "themes-shadow-header-menu-color-fill": 0,
        "themes-gradient-loader-gradient-fill-1": 1,
        "themes-gradient-loader-gradient-fill-2": null,
        "themes-color-background-white": 1,
        "themes-color-background-light": 1,
        "themes-color-background-dark": 1,
        "themes-color-background-black": 1,
        "themes-color-background-brand1": 1,
        "themes-color-background-brand2": 1,
        "themes-color-background-accent1": 1,
        "themes-color-background-accent2": 1,
        "themes-color-background-body": 1,
        "themes-color-stroke-white": 1,
        "themes-color-stroke-light": 1,
        "themes-color-stroke-dark": 1,
        "themes-color-stroke-black": 1,
        "themes-color-stroke-brand1": 1,
        "themes-color-stroke-brand2": 1,
        "themes-color-stroke-accent1": 1,
        "themes-color-stroke-accent2": 1,
        "themes-color-text-white": 1,
        "themes-color-text-light": 1,
        "themes-color-text-dark": 1,
        "themes-color-text-black": 1,
        "themes-color-text-brand1": 1,
        "themes-color-text-brand2": 1,
        "themes-color-text-accent1": 1,
        "themes-color-text-accent2": 1,
        "colors-grayscale-100": 1,
        "colors-grayscale-200": 1,
        "colors-grayscale-300": 1,
        "colors-grayscale-400": 1,
        "colors-grayscale-500": 1,
        "colors-grayscale-600": 1,
        "colors-grayscale-700": 1,
        "colors-brand-1-100": 1,
        "colors-brand-1-200": 1,
        "colors-brand-1-300": 1,
        "colors-brand-1-400": 1,
        "colors-brand-1-500": 1,
        "colors-brand-1-600": 1,
        "colors-brand-1-700": 1,
        "colors-brand-2-100": 1,
        "colors-brand-2-200": 1,
        "colors-brand-2-300": 1,
        "colors-brand-2-400": 1,
        "colors-brand-2-500": 1,
        "colors-brand-2-600": 1,
        "colors-brand-2-700": 1,
        "colors-accent-1-100": 1,
        "colors-accent-1-200": 1,
        "colors-accent-1-300": 1,
        "colors-accent-1-400": 1,
        "colors-accent-1-500": 1,
        "colors-accent-1-600": 1,
        "colors-accent-1-700": 1,
        "colors-accent-2-100": 1,
        "colors-accent-2-200": 1,
        "colors-accent-2-300": 1,
        "colors-accent-2-400": 1,
        "colors-accent-2-500": 1,
        "colors-accent-2-600": 1,
        "colors-accent-2-700": 1,
        "colors-accent-3-100": 1,
        "colors-accent-3-200": 1,
        "colors-accent-3-300": 1,
        "colors-accent-3-400": 1,
        "colors-accent-3-500": 1,
        "colors-accent-3-600": 1,
        "colors-accent-3-700": 1,
        "colors-accent-4-400_storage": 1,
        "colors-accessible-accent-1-100": 1,
        "colors-accessible-accent-1-200": 1,
        "colors-accessible-accent-1-300": 1,
        "colors-accessible-accent-1-400": 1,
        "colors-accessible-accent-1-500": 1,
        "colors-accessible-accent-1-600": 1,
        "colors-accessible-accent-1-700": 1,
        "colors-accessible-accent-2-100": 1,
        "colors-accessible-accent-2-200": 1,
        "colors-accessible-accent-2-300": 1,
        "colors-accessible-accent-2-400": 1,
        "colors-accessible-accent-2-500": 1,
        "colors-accessible-accent-2-600": 1,
        "colors-accessible-accent-2-700": 1,
        "colors-accessible-brand-2-100": 1,
        "colors-accessible-brand-2-200": 1,
        "colors-accessible-brand-2-300": 1,
        "colors-accessible-brand-2-400": 1,
        "colors-accessible-brand-2-500": 1,
        "colors-accessible-brand-2-600": 1,
        "colors-accessible-brand-2-700": 1
      }
    },
    "Glade": {
      "colors": {
        "components-accessibility-bar-color-bg": "#12171c",
        "components-accessibility-bar-color-text": "#ffffff",
        "components-accordion-color-bg": "#ffffff",
        "components-accordion-color-title": "#121111",
        "components-accordion-color-body": "#373432",
        "components-accordion-color-section-section-bg": "#ffffff",
        "components-accordion-color-section-section-title": "#121111",
        "components-accordion-color-section-section-body": "#373432",
        "components-accordion-color-item-item-bg": "#ffffff",
        "components-accordion-color-item-divider": "#eae8e8",
        "components-accordion-color-item-icon-icon-default": "#12202e",
        "components-accordion-color-item-icon-icon-hover": "#ffffff",
        "components-accordion-color-item-icon-icon-pressed": "#ffffff",
        "components-accordion-color-item-icon-icon-active": "#ffffff",
        "components-accordion-color-item-title-item-default": "#12202e",
        "components-accordion-color-item-title-item-hover": "#ffffff",
        "components-accordion-color-item-title-item-pressed": "#ffffff",
        "components-accordion-color-item-title-item-active": "#ffffff",
        "components-accordion-color-content-bg": "#ffffff",
        "components-accordion-color-content-title": "#121111",
        "components-accordion-color-content-body": "#373432",
        "components-article-card-color-bg": "#fdfdfc",
        "components-article-card-color-border": "#aba8a6",
        "components-article-card-color-content-bg": "#fdfdfc",
        "components-article-card-color-title": "#121111",
        "components-article-card-color-subtitle": "#373432",
        "components-article-card-color-copy": "#373432",
        "components-article-card-color-description": "#373432",
        "components-article-card-listing-color-default-bg": "#ebf1f9",
        "components-article-card-listing-color-default-title": "#121111",
        "components-article-card-listing-color-default-copy": "#373432",
        "components-article-hero-color-content-bg": "#ffffff",
        "components-article-hero-color-bg": "#ffffff",
        "components-article-hero-color-headline": "#121111",
        "components-article-hero-color-body": "#373432",
        "components-article-hero-color-detail-text": "#373432",
        "components-article-hero-color-divider": "#aba8a6",
        "components-article-hero-color-icon": "#00254d",
        "components-article-hero-color-play-icon": "#ffffff",
        "components-article-hero-color-selected-stroke": "#00254d",
        "components-banner-color-bg-default": "#ebf1f9",
        "components-banner-color-icon-default": "#00254d",
        "components-banner-color-title-default": "#0c0e0f",
        "components-banner-color-body-default": "#373432",
        "components-banner-color-icon-close-default": "#121111",
        "components-banner-color-bg-alert": "#b32b30",
        "components-banner-color-icon-alert": "#ffffff",
        "components-banner-color-title-alert": "#ffffff",
        "components-banner-color-body-alert": "#ffffff",
        "components-banner-color-icon-close-alert": "#ffffff",
        "components-breadcrumb-color-bg": "#ffffff",
        "components-breadcrumb-color-breadcrumb-link-default": "#807a77",
        "components-breadcrumb-color-breadcrumb-link-hover": "#5b5654",
        "components-breadcrumb-color-breadcrumb-link-active": "#373432",
        "components-breadcrumb-color-icon-divider": "#aba8a6",
        "components-bug-finder-prompt": "#ffffff",
        "components-button-color-filled-brand-default-bg": "#00254d",
        "components-button-color-filled-brand-default-text": "#ffffff",
        "components-button-color-filled-brand-default-stroke": "#00254d",
        "components-button-color-filled-brand-default-icon": "#ffffff",
        "components-button-color-filled-brand-hover-bg": "#12202e",
        "components-button-color-filled-brand-hover-text": "#ffffff",
        "components-button-color-filled-brand-hover-stroke": "#12202e",
        "components-button-color-filled-brand-hover-icon": "#ffffff",
        "components-button-color-filled-brand-pressed-bg": "#12171c",
        "components-button-color-filled-brand-pressed-text": "#ffffff",
        "components-button-color-filled-brand-pressed-stroke": "#12171c",
        "components-button-color-filled-brand-pressed-icon": "#ffffff",
        "components-button-color-filled-brand-disabled-bg": "rgba(0, 83, 180, 0.38)",
        "components-button-color-filled-brand-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-disabled-stroke": "rgba(0, 83, 180, 0.38)",
        "components-button-color-filled-brand-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-focus-bg": "#00254d",
        "components-button-color-filled-brand-focus-text": "#ffffff",
        "components-button-color-filled-brand-focus-stroke": "#00254d",
        "components-button-color-filled-brand-focus-icon": "#ffffff",
        "components-button-color-filled-brand-focus-outline": "#286efa",
        "components-button-color-filled-tonal-default-bg": "#ebf1f9",
        "components-button-color-filled-tonal-default-text": "#12202e",
        "components-button-color-filled-tonal-default-stroke": "#ebf1f9",
        "components-button-color-filled-tonal-default-icon": "#12202e",
        "components-button-color-filled-tonal-hover-bg": "#b0c5db",
        "components-button-color-filled-tonal-hover-text": "#12171c",
        "components-button-color-filled-tonal-hover-stroke": "#b0c5db",
        "components-button-color-filled-tonal-hover-icon": "#12171c",
        "components-button-color-filled-tonal-pressed-bg": "#b0c5db",
        "components-button-color-filled-tonal-pressed-text": "#12171c",
        "components-button-color-filled-tonal-pressed-stroke": "#b0c5db",
        "components-button-color-filled-tonal-pressed-icon": "#12171c",
        "components-button-color-filled-tonal-disabled-bg": "rgba(235, 241, 249, 0.38)",
        "components-button-color-filled-tonal-disabled-text": "rgba(0, 70, 151, 0.38)",
        "components-button-color-filled-tonal-disabled-stroke": "rgba(235, 241, 249, 0.38)",
        "components-button-color-filled-tonal-disabled-icon": "rgba(0, 70, 151, 0.38)",
        "components-button-color-filled-tonal-focus-bg": "#ebf1f9",
        "components-button-color-filled-tonal-focus-text": "#12202e",
        "components-button-color-filled-tonal-focus-stroke": "#ebf1f9",
        "components-button-color-filled-tonal-focus-icon": "#12202e",
        "components-button-color-filled-tonal-focus-outline": "#1c4cae",
        "components-button-color-filled-white-default-bg": "#ffffff",
        "components-button-color-filled-white-default-text": "#121111",
        "components-button-color-filled-white-default-stroke": "#ffffff",
        "components-button-color-filled-white-default-icon": "#00254d",
        "components-button-color-filled-white-hover-bg": "#ebf1f9",
        "components-button-color-filled-white-hover-text": "#121111",
        "components-button-color-filled-white-hover-stroke": "#ebf1f9",
        "components-button-color-filled-white-hover-icon": "#12202e",
        "components-button-color-filled-white-pressed-bg": "#ebf1f9",
        "components-button-color-filled-white-pressed-text": "#121111",
        "components-button-color-filled-white-pressed-stroke": "#ebf1f9",
        "components-button-color-filled-white-pressed-icon": "#12202e",
        "components-button-color-filled-white-disabled-bg": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-text": "rgba(25, 27, 28, 0.38)",
        "components-button-color-filled-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-icon": "rgba(18, 32, 46, 0.38)",
        "components-button-color-filled-white-focus-bg": "#ffffff",
        "components-button-color-filled-white-focus-text": "#121111",
        "components-button-color-filled-white-focus-stroke": "#ffffff",
        "components-button-color-filled-white-focus-icon": "#00254d",
        "components-button-color-filled-white-focus-outline": "#1c4cae",
        "components-button-color-outline-brand-default-bg": "rgba(0, 83, 180, 0.00)",
        "components-button-color-outline-brand-default-text": "#00254d",
        "components-button-color-outline-brand-default-stroke": "#00254d",
        "components-button-color-outline-brand-default-icon": "#00254d",
        "components-button-color-outline-brand-hover-bg": "#12202e",
        "components-button-color-outline-brand-hover-text": "#ffffff",
        "components-button-color-outline-brand-hover-stroke": "#12202e",
        "components-button-color-outline-brand-hover-icon": "#ffffff",
        "components-button-color-outline-brand-pressed-bg": "#12171c",
        "components-button-color-outline-brand-pressed-text": "#ffffff",
        "components-button-color-outline-brand-pressed-stroke": "#12171c",
        "components-button-color-outline-brand-pressed-icon": "#ffffff",
        "components-button-color-outline-brand-disabled-bg": "rgba(0, 83, 180, 0.00)",
        "components-button-color-outline-brand-disabled-text": "rgba(0, 83, 180, 0.38)",
        "components-button-color-outline-brand-disabled-stroke": "rgba(0, 83, 180, 0.38)",
        "components-button-color-outline-brand-disabled-icon": "rgba(0, 83, 180, 0.38)",
        "components-button-color-outline-brand-focus-bg": "#ffffff",
        "components-button-color-outline-brand-focus-text": "#00254d",
        "components-button-color-outline-brand-focus-stroke": "#00254d",
        "components-button-color-outline-brand-focus-icon": "#00254d",
        "components-button-color-outline-brand-focus-outline": "#286efa",
        "components-button-color-outline-tonal-default-bg": "#ffffff",
        "components-button-color-outline-tonal-default-text": "#807a77",
        "components-button-color-outline-tonal-default-stroke": "#807a77",
        "components-button-color-outline-tonal-default-icon": "#807a77",
        "components-button-color-outline-tonal-hover-bg": "#ffffff",
        "components-button-color-outline-tonal-hover-text": "#5b5654",
        "components-button-color-outline-tonal-hover-stroke": "#5b5654",
        "components-button-color-outline-tonal-hover-icon": "#5b5654",
        "components-button-color-outline-tonal-pressed-bg": "#ffffff",
        "components-button-color-outline-tonal-pressed-text": "#5b5654",
        "components-button-color-outline-tonal-pressed-stroke": "#5b5654",
        "components-button-color-outline-tonal-pressed-icon": "#5b5654",
        "components-button-color-outline-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-tonal-disabled-text": "rgba(114, 117, 126, 0.38)",
        "components-button-color-outline-tonal-disabled-stroke": "rgba(114, 117, 126, 0.38)",
        "components-button-color-outline-tonal-disabled-icon": "rgba(114, 117, 126, 0.38)",
        "components-button-color-outline-tonal-focus-bg": "#ffffff",
        "components-button-color-outline-tonal-focus-text": "#807a77",
        "components-button-color-outline-tonal-focus-stroke": "#807a77",
        "components-button-color-outline-tonal-focus-icon": "#807a77",
        "components-button-color-outline-tonal-focus-outline": "#286efa",
        "components-button-color-outline-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-default-text": "#ffffff",
        "components-button-color-outline-white-default-stroke": "#ffffff",
        "components-button-color-outline-white-default-icon": "#ffffff",
        "components-button-color-outline-white-hover-bg": "#ffffff",
        "components-button-color-outline-white-hover-text": "#121111",
        "components-button-color-outline-white-hover-stroke": "#ffffff",
        "components-button-color-outline-white-hover-icon": "#00254d",
        "components-button-color-outline-white-pressed-bg": "#ffffff",
        "components-button-color-outline-white-pressed-text": "#121111",
        "components-button-color-outline-white-pressed-stroke": "#ffffff",
        "components-button-color-outline-white-pressed-icon": "#00254d",
        "components-button-color-outline-white-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-focus-bg": "rgba(255, 255, 255, 0.01)",
        "components-button-color-outline-white-focus-text": "#ffffff",
        "components-button-color-outline-white-focus-stroke": "#ffffff",
        "components-button-color-outline-white-focus-icon": "#ffffff",
        "components-button-color-outline-white-focus-outline": "#286efa",
        "components-button-color-text-brand-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-default-text": "#00254d",
        "components-button-color-text-brand-default-stroke": "#00254d",
        "components-button-color-text-brand-default-icon": "#00254d",
        "components-button-color-text-brand-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-hover-bg-icon-only": "#eae8e8",
        "components-button-color-text-brand-hover-text": "#12202e",
        "components-button-color-text-brand-hover-stroke": "#12202e",
        "components-button-color-text-brand-hover-icon": "#12202e",
        "components-button-color-text-brand-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-pressed-bg-icon-only": "#eae8e8",
        "components-button-color-text-brand-pressed-text": "#12171c",
        "components-button-color-text-brand-pressed-stroke": "#12171c",
        "components-button-color-text-brand-pressed-icon": "#12171c",
        "components-button-color-text-brand-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-disabled-text": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-brand-disabled-stroke": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-brand-disabled-icon": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-brand-focus-bg": "#d5e3ff",
        "components-button-color-text-brand-focus-text": "#12202e",
        "components-button-color-text-brand-focus-stroke": "#12202e",
        "components-button-color-text-brand-focus-icon": "#1c4cae",
        "components-button-color-text-brand-focus-outline": "#1c4cae",
        "components-button-color-text-tonal-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-default-text": "#00254d",
        "components-button-color-text-tonal-default-stroke": "#00254d",
        "components-button-color-text-tonal-default-icon": "#00254d",
        "components-button-color-text-tonal-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-hover-bg-icon-only": "#ebf1f9",
        "components-button-color-text-tonal-hover-text": "#12202e",
        "components-button-color-text-tonal-hover-stroke": "#00254d",
        "components-button-color-text-tonal-hover-icon": "#12202e",
        "components-button-color-text-tonal-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-pressed-bg-icon-only": "#ebf1f9",
        "components-button-color-text-tonal-pressed-text": "#12202e",
        "components-button-color-text-tonal-pressed-stroke": "#00254d",
        "components-button-color-text-tonal-pressed-icon": "#12202e",
        "components-button-color-text-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-disabled-text": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-tonal-disabled-stroke": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-tonal-disabled-icon": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-tonal-focus-bg": "#d5e3ff",
        "components-button-color-text-tonal-focus-text": "#12202e",
        "components-button-color-text-tonal-focus-stroke": "#12202e",
        "components-button-color-text-tonal-focus-icon": "#1c4cae",
        "components-button-color-text-tonal-focus-outline": "#1c4cae",
        "components-button-color-text-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-default-text": "#ffffff",
        "components-button-color-text-white-default-stroke": "#ffffff",
        "components-button-color-text-white-default-icon": "#ffffff",
        "components-button-color-text-white-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-hover-bg-icon-only": "#00254d",
        "components-button-color-text-white-hover-text": "#ffffff",
        "components-button-color-text-white-hover-stroke": "#ffffff",
        "components-button-color-text-white-hover-icon": "#ffffff",
        "components-button-color-text-white-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-pressed-bg-icon-only": "#00254d",
        "components-button-color-text-white-pressed-text": "#ffffff",
        "components-button-color-text-white-pressed-stroke": "#ffffff",
        "components-button-color-text-white-pressed-icon": "#ffffff",
        "components-button-color-text-white-disabled-bg": "rgba(115, 119, 128, 0.00)",
        "components-button-color-text-white-disabled-text": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-stroke": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-icon": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-focus-bg": "#d5e3ff",
        "components-button-color-text-white-focus-text": "#ffffff",
        "components-button-color-text-white-focus-stroke": "#ffffff",
        "components-button-color-text-white-focus-icon": "#1c4cae",
        "components-button-color-text-white-focus-outline": "#1c4cae",
        "components-button-group-color-border": "#eae8e8",
        "components-button-group-color-button-bg-default": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-button-fg-default": "#12202e",
        "components-button-group-color-button-bg-hover": "#b0c5db",
        "components-button-group-color-button-fg-hover": "#12171c",
        "components-button-group-color-button-bg-active": "#00254d",
        "components-button-group-color-button-fg-active": "#ffffff",
        "components-button-group-color-overlay-left-gradient-fill-1": "#ebf1f9",
        "components-button-group-color-overlay-left-gradient-fill-2": "rgba(235, 241, 249, 0.50)",
        "components-button-group-color-stroke-default-gradient-fill-1": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-2": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-3": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-4": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-1": "#b0c5db",
        "components-button-group-color-stroke-hover-gradient-fill-2": "#b0c5db",
        "components-button-group-color-stroke-hover-gradient-fill-3": "#b0c5db",
        "components-button-group-color-stroke-hover-gradient-fill-4": "#b0c5db",
        "components-button-group-color-stroke-active-gradient-fill-1": "#00254d",
        "components-button-group-color-stroke-active-gradient-fill-2": "#00254d",
        "components-button-group-color-stroke-active-gradient-fill-3": "#00254d",
        "components-button-group-color-stroke-active-gradient-fill-4": "#00254d",
        "components-category-card-color-bg": "#ffffff",
        "components-category-card-color-content-bg": "#ffffff",
        "components-category-card-color-category-bg": "#ebf1f9",
        "components-category-card-color-category-bg-2": "#ebf1f9",
        "components-category-card-color-category-bg-3": "#ebf1f9",
        "components-category-card-color-category-bg-4": "#ebf1f9",
        "components-category-card-color-category-bg-5": "#ebf1f9",
        "components-category-card-color-category-bg-6": "#ebf1f9",
        "components-category-card-color-title": "#121111",
        "components-category-card-color-copy": "#373432",
        "components-category-card-color-stroke": "#aba8a6",
        "components-category-card-color-stroke-hover": "#00254d",
        "components-category-card-listing-color-bg": "#00254d",
        "components-category-card-listing-color-title": "#ffffff",
        "components-category-card-listing-color-copy": "#fdfdfc",
        "components-card-search-result-color-bg": "#ffffff",
        "components-card-search-result-color-label": "#373432",
        "components-card-search-result-color-title": "#121111",
        "components-card-search-result-color-body": "#373432",
        "components-card-search-result-color-divider-line": "#aba8a6",
        "components-contact-us-promo-color-light-bg": "#ebf1f9",
        "components-contact-us-promo-color-light-title": "#121111",
        "components-contact-us-promo-color-light-body": "#373432",
        "components-contact-us-promo-color-default-bg": "#ffffff",
        "components-contact-us-promo-color-default-title": "#121111",
        "components-contact-us-promo-color-default-body": "#373432",
        "components-contact-us-promo-color-brand-bg": "#00254d",
        "components-contact-us-promo-color-brand-title": "#ffffff",
        "components-contact-us-promo-color-brand-body": "#fdfdfc",
        "components-content-list-color-bg": "#ffffff",
        "components-content-list-color-title": "#121111",
        "components-content-list-color-body": "#373432",
        "components-content-list-color-icon": "#ff7435",
        "components-cookies-colors-bg": "#ffffff",
        "components-cookies-colors-title": "#121111",
        "components-cookies-colors-body": "#373432",
        "components-cookies-colors-icon": "#00254d",
        "components-dropdown-bg": "#ffffff",
        "components-dropdown-border-default": "#807a77",
        "components-dropdown-border-hover": "#00254d",
        "components-dropdown-border-focused": "#121111",
        "components-dropdown-border-destructive": "#b32b30",
        "components-dropdown-label-text-default": "#121111",
        "components-dropdown-item-text-default": "#121111",
        "components-dropdown-item-bg-default": "#ffffff",
        "components-dropdown-item-bg-hover": "#ebf1f9",
        "components-dropdown-input-field-default": "#807a77",
        "components-dropdown-input-field-focused": "#121111",
        "components-dropdown-input-label-focused": "#121111",
        "components-dropdown-input-field-destructive": "#b32b30",
        "components-external-link-card-bg": "#ebf1f9",
        "components-external-link-card-card-bg": "#ffffff",
        "components-external-link-card-card-title": "#121111",
        "components-external-link-card-card-subtitle": "#373432",
        "components-external-link-card-card-eyebrow": "#5b5654",
        "components-fab-color-border-default": "#ffffff",
        "components-footer-brand-color-bg": "#00254d",
        "components-footer-brand-color-logo": "#ffffff",
        "components-footer-brand-color-title-text": "#ffffff",
        "components-footer-brand-color-description-text": "#fdfdfc",
        "components-footer-brand-color-footer-menu-expanded": "#00254d",
        "components-footer-brand-color-link-default": "#ffffff",
        "components-footer-brand-color-link-hover": "#ebf1f9",
        "components-footer-brand-color-link-pressed": "#b0c5db",
        "components-footer-brand-color-category-link-default": "#ffffff",
        "components-footer-brand-color-category-link-hover": "#fdfdfc",
        "components-footer-brand-color-category-link-pressed": "#eae8e8",
        "components-footer-brand-color-subcategory-link-default": "#fdfdfc",
        "components-footer-brand-color-subcategory-link-hover": "#ebf1f9",
        "components-footer-brand-color-subcategory-link-pressed": "#28588a",
        "components-footer-brand-color-icon-icon-default": "#ffffff",
        "components-footer-brand-color-icon-icon-pressed": "#eae8e8",
        "components-footer-brand-color-social-icon-default": "#ffffff",
        "components-footer-brand-color-social-icon-hover": "#b0c5db",
        "components-footer-brand-color-social-icon-pressed": "#12202e",
        "components-footer-brand-spacing-category-link-hover": "#000000",
        "components-footer-global-color-bg": "#ffffff",
        "components-footer-global-color-title-text": "#000000",
        "components-footer-global-color-disclaimer-text": "#000000",
        "components-footer-global-color-link-default": "#000000",
        "components-footer-global-color-link-hover": "#000000",
        "components-footer-global-color-link-pressed": "#000000",
        "components-generic-card-color-bg": "#fdfdfc",
        "components-generic-card-color-content-bg": "#fdfdfc",
        "components-generic-card-color-title": "#121111",
        "components-generic-card-color-subtitle": "#373432",
        "components-generic-card-color-copy": "#373432",
        "components-global-search-color-omnibox-list-bg": "#ffffff",
        "components-global-search-color-omnibox-divider": "#aba8a6",
        "components-global-search-color-omnibox-item-bg-default": "#ffffff",
        "components-global-search-color-omnibox-item-bg-hover": "#fdfdfc",
        "components-global-search-color-omnibox-item-text-default": "#00254d",
        "components-global-search-color-omnibox-item-text-hover": "#12202e",
        "components-global-search-color-omnibox-label-text-default": "#121111",
        "components-global-search-color-omnibox-category-text-default": "#121111",
        "components-generic-card-listing-color-bg": "#ffffff",
        "components-generic-card-listing-color-title": "#121111",
        "components-generic-card-listing-color-copy": "#373432",
        "components-header-color-bg": "#ffffff",
        "components-header-color-bottom-stroke": "#aba8a6",
        "components-header-color-search-search-border-default": "#121111",
        "components-header-color-search-search-text": "#5b5654",
        "components-header-color-search-search-icon": "#00254d",
        "components-header-color-search-search-bar": "#121111",
        "components-header-color-mobile-mobile-expanded-bg": "#ebf1f9",
        "components-header-color-mobile-pressed-bg": "#b0c5db",
        "components-header-color-language-selector-language": "#5b5654",
        "components-header-color-language-selector-country": "#121111",
        "components-header-color-language-selector-country-hover-bg": "#fdfdfc",
        "components-header-color-language-selector-megamenu-language": "#5b5654",
        "components-header-color-language-selector-megamenu-country": "#121111",
        "components-header-color-language-selector-megamenu-country-hover": "#fdfdfc",
        "components-header-color-language-selector-bg": "#ffffff",
        "components-header-color-language-selector-stroke": "#807a77",
        "components-header-color-mega-menu-bg": "#ebf1f9",
        "components-header-color-mega-menu-featured-bg": "#00254d",
        "components-header-color-mega-menu-featured-title": "#ffffff",
        "components-header-color-mega-menu-category-title": "#373432",
        "components-header-color-mega-menu-bg-accent": "#ffffff",
        "components-header-color-icon-icon-default": "#121111",
        "components-header-color-icon-icon-hover": "#12202e",
        "components-header-color-icon-icon-pressed": "#12171c",
        "components-header-color-links-nav-main-link-default": "#00254d",
        "components-header-color-links-nav-main-link-hover": "#12171c",
        "components-header-color-links-nav-main-link-pressed": "#12171c",
        "components-header-color-links-nav-main-link-active": "#12171c",
        "components-header-color-links-nav-main-active-bar": "#12171c",
        "components-header-color-links-nav-main-bg-color": "#ffffff",
        "components-header-color-links-nav-category-link-category-default": "#00254d",
        "components-header-color-links-nav-category-link-category-hover": "#373432",
        "components-header-color-links-nav-category-link-category-pressed": "#12171c",
        "components-header-color-links-nav-category-link-category-active": "#12171c",
        "components-header-color-links-nav-menu-link-link-default": "#28588a",
        "components-header-color-links-nav-menu-link-link-hover": "#00254d",
        "components-header-color-links-nav-menu-link-link-pressed": "#12171c",
        "components-header-color-links-nav-menu-link-link-active": "#12171c",
        "components-header-color-links-nav-menu-link-bg": "#ebf1f9",
        "components-header-color-links-nav-subcategory-link-subcategory-default": "#00254d",
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": "#12171c",
        "components-header-color-links-nav-subcategory-link-subcategory-active": "#12171c",
        "components-hero-homepage-color-bg": "#ffffff",
        "components-hero-homepage-color-container-bg": "#8cc6e7",
        "components-hero-homepage-color-eyebrow": "#807a77",
        "components-hero-homepage-color-header": "#121111",
        "components-hero-homepage-color-sub-header": "#373432",
        "components-hero-homepage-color-body": "#373432",
        "components-hero-homepage-color-play-pause": "#000000",
        "components-hero-internal-color-default-title": "#ffffff",
        "components-hero-internal-color-default-body": "#fdfdfc",
        "components-hero-internal-color-default-bg": "#12202e",
        "components-hero-internal-color-accent-bg": "#12202e",
        "components-hero-internal-color-with-image-bg": "#ebf1f9",
        "components-hero-internal-color-default-bg-2": "#12202e",
        "components-hero-internal-color-with-image-title": "#121111",
        "components-hero-internal-color-with-image-body": "#373432",
        "components-heading-page-title-color-default-bg": "#ffffff",
        "components-heading-page-title-color-default-title": "#121111",
        "components-heading-page-title-color-default-body": "#373432",
        "components-heading-page-title-color-light-bg": "#ebf1f9",
        "components-heading-page-title-color-light-title": "#121111",
        "components-heading-page-title-color-light-body": "#373432",
        "components-heading-page-title-color-color-bg": "#00254d",
        "components-heading-page-title-color-color-title": "#ffffff",
        "components-heading-page-title-color-color-body": "#fdfdfc",
        "components-hyperlink-color-dark-default": "#1e837e",
        "components-hyperlink-color-dark-hover": "#155d59",
        "components-hyperlink-color-dark-visited": "#6d3ad8",
        "components-hyperlink-color-light-default": "#19a0bc",
        "components-hyperlink-color-light-hover": "#74b7cb",
        "components-hyperlink-color-light-visited": "#ab8fe9",
        "components-icon-default": "#807a77",
        "components-icon-focused": "#807a77",
        "components-icon-card-color-bg": "#ffffff",
        "components-icon-card-color-icon-bg": "#00254d",
        "components-icon-card-color-icon": "#ffffff",
        "components-icon-card-color-title": "#121111",
        "components-icon-card-color-subtitle": "#5b5654",
        "components-icon-card-color-description": "#373432",
        "components-icon-card-listing-color-bg": "#ffffff",
        "components-icon-card-listing-color-inner-bg": "#ffffff",
        "components-icon-card-listing-color-brand-bg": "#00254d",
        "components-icon-card-listing-color-title": "#121111",
        "components-icon-card-listing-color-copy": "#373432",
        "components-icon-card-listing-color-inner-legal-text": "#fdfdfc",
        "components-jumplink-color-bg-default": "#ffffff",
        "components-jumplink-color-link-text-default": "#5b5654",
        "components-jumplink-color-link-text-hover": "#12171c",
        "components-jumplink-color-link-border-hover": "#12171c",
        "components-jumplink-color-link-text-active": "#00254d",
        "components-jumplink-color-link-border-active": "#00254d",
        "components-jumplink-color-title-text": "#121111",
        "components-jumplink-color-description-text": "#373432",
        "components-layout-bg-default": "#ffffff",
        "components-lightbox-color-overlay-bg": "rgba(235, 241, 249, 0.95)",
        "components-lightbox-color-title": "#121111",
        "components-lightbox-color-body": "#373432",
        "components-label-color-on-color-label-label-default": "#ffffff",
        "components-label-color-on-color-label-label-hover": "#5b5654",
        "components-label-color-on-color-label-label-pressed": "#373432",
        "components-label-color-on-color-background-bg-default": "#807a77",
        "components-label-color-on-color-background-bg-hover": "#ffffff",
        "components-label-color-on-color-background-bg-pressed": "#eae8e8",
        "components-label-color-on-color-stroke-hover": "#ffffff",
        "components-label-color-on-color-stroke-pressed": "#eae8e8",
        "components-label-color-on-color-stroke-default-gradient-fill-1": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-2": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-3": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-4": "#ffffff",
        "components-label-color-on-white-label-label-default": "#00254d",
        "components-label-color-on-white-label-label-hover": "#ffffff",
        "components-label-color-on-white-label-label-pressed": "#ffffff",
        "components-label-color-on-white-background-bg-default": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-white-background-bg-hover": "#00254d",
        "components-label-color-on-white-background-bg-pressed": "#00254d",
        "components-label-color-on-white-stroke-hover": "#00254d",
        "components-label-color-on-white-stroke-pressed": "#12171c",
        "components-label-color-on-white-stroke-default-gradient-fill-1": "#00254d",
        "components-label-color-on-white-stroke-default-gradient-fill-2": "#00254d",
        "components-label-color-on-white-stroke-default-gradient-fill-3": "#00254d",
        "components-label-color-on-white-stroke-default-gradient-fill-4": "#00254d",
        "components-legal-disclaimer-color-default-bg": "#ffffff",
        "components-legal-disclaimer-color-default-title": "#121111",
        "components-legal-disclaimer-color-default-body": "#373432",
        "components-legal-disclaimer-color-light-bg": "#ebf1f9",
        "components-legal-disclaimer-color-light-title": "#121111",
        "components-legal-disclaimer-color-light-body": "#373432",
        "components-legal-disclaimer-color-brand-bg": "#121111",
        "components-legal-disclaimer-color-brand-title": "#ffffff",
        "components-legal-disclaimer-color-brand-body": "#fdfdfc",
        "components-media-video-thumbnail-overlay-bg": "rgba(25, 27, 28, 0.80)",
        "components-media-caption-color-bg": "#fdfdfc",
        "components-media-caption-color-text": "#373432",
        "components-media-gallery-color-bg": "#ebf1f9",
        "components-media-gallery-color-title": "#121111",
        "components-media-gallery-color-body": "#373432",
        "components-pagination-on-white-accent-default": "#373432",
        "components-pagination-on-white-accent-scroll": "#1686b4",
        "components-pagination-on-white-disabled": "#aba8a6",
        "components-pagination-on-white-bg": "#eae8e8",
        "components-pagination-on-white-stroke": "#eae8e8",
        "components-pagination-on-white-bg-active": "#00254d",
        "components-pagination-on-white-bg-hover": "#eae8e8",
        "components-pagination-on-white-text-default": "#807a77",
        "components-pagination-on-white-text-hover": "#373432",
        "components-pagination-on-white-text-active": "#ffffff",
        "components-pagination-on-color-accent-default": "#ffffff",
        "components-pagination-on-color-accent-scroll": "#1686b4",
        "components-pagination-on-color-disabled": "#0c0e0f",
        "components-pagination-on-color-bg": "#eae8e8",
        "components-pagination-on-color-stroke": "rgba(255, 255, 255, 0.38)",
        "components-pagination-on-color-bg-active": "#ffffff",
        "components-pagination-on-color-bg-hover": "#373432",
        "components-pagination-on-color-text-default": "#ffffff",
        "components-pagination-on-color-text-hover": "#ffffff",
        "components-pagination-on-color-text-active": "#121111",
        "components-pdf-link-card-color-bg": "#ffffff",
        "components-pdf-link-card-color-card-bg": "#ffffff",
        "components-pdf-link-card-color-card-title": "#121111",
        "components-pdf-link-card-color-card-subtitle": "#373432",
        "components-pdf-link-card-color-card-body": "#373432",
        "components-product-card-color-bg": "#fdfdfc",
        "components-product-card-color-content-bg": "#fdfdfc",
        "components-product-card-color-border": "#aba8a6",
        "components-product-card-color-title": "#121111",
        "components-product-card-color-description": "#373432",
        "components-product-card-color-star-rating": "#fec02f",
        "components-product-card-color-badge-bg": "#4ebaea",
        "components-product-card-color-badge-text": "#121111",
        "components-product-card-listing-color-bg": "#ebf1f9",
        "components-product-card-listing-color-title": "#121111",
        "components-product-card-listing-color-copy": "#373432",
        "components-product-card-listing-color-legal-text": "#373432",
        "components-product-information-color-bg": "#ffffff",
        "components-product-information-color-content-bg": "#ffffff",
        "components-product-information-color-headline": "#121111",
        "components-product-information-color-body": "#373432",
        "components-product-information-color-detail-text": "#373432",
        "components-product-information-color-divider": "#aba8a6",
        "components-product-information-color-icon": "#00254d",
        "components-product-information-color-play-icon": "#ffffff",
        "components-product-information-color-selected-stroke": "#00254d",
        "components-product-results-list-with-search-color-bg": "#ffffff",
        "components-promo-color-default-bg": "#ffffff",
        "components-promo-color-default-image-bg": "#ffffff",
        "components-promo-color-default-content-bg": "#ffffff",
        "components-promo-color-default-title": "#121111",
        "components-promo-color-default-body": "#373432",
        "components-promo-color-default-category": "#121111",
        "components-promo-color-light-bg": "#ebf1f9",
        "components-promo-color-light-image-bg": "#ebf1f9",
        "components-promo-color-light-content-bg": "#ebf1f9",
        "components-promo-color-light-title": "#121111",
        "components-promo-color-light-body": "#373432",
        "components-promo-color-light-category": "#121111",
        "components-promo-color-brand-bg": "#00254d",
        "components-promo-color-brand-image-bg": "#00254d",
        "components-promo-color-brand-content-bg": "#00254d",
        "components-promo-color-brand-title": "#ffffff",
        "components-promo-color-brand-body": "#fdfdfc",
        "components-promo-color-brand-category": "#ffffff",
        "components-pull-quote-color-bg": "#00254d",
        "components-pull-quote-color-stroke": "#00254d",
        "components-pull-quote-color-quote-bg": "#ffffff",
        "components-pull-quote-color-quote-title": "#00254d",
        "components-pull-quote-color-quote-by-text": "#373432",
        "components-pull-quote-color-quote-detail": "#807a77",
        "components-pull-quote-color-quote-highlight": "#1a9bd0",
        "components-promo-alternate-color-default-bg": "#ffffff",
        "components-promo-alternate-color-default-image-bg": "#ffffff",
        "components-promo-alternate-color-default-content-bg": "#ffffff",
        "components-promo-alternate-color-default-title": "#121111",
        "components-promo-alternate-color-default-body": "#373432",
        "components-promo-alternate-color-default-category": "#121111",
        "components-promo-alternate-color-light-bg": "#ebf1f9",
        "components-promo-alternate-color-light-image-bg": "#ebf1f9",
        "components-promo-alternate-color-light-content-bg": "#ebf1f9",
        "components-promo-alternate-color-light-title": "#121111",
        "components-promo-alternate-color-light-body": "#373432",
        "components-promo-alternate-color-light-category": "#121111",
        "components-promo-alternate-color-brand-bg": "#00254d",
        "components-promo-alternate-color-brand-image-bg": "#00254d",
        "components-promo-alternate-color-brand-content-bg": "#00254d",
        "components-promo-alternate-color-brand-title": "#ffffff",
        "components-promo-alternate-color-brand-body": "#fdfdfc",
        "components-promo-alternate-color-brand-category": "#ffffff",
        "components-share-list-color-title": "#373432",
        "components-share-list-color-bg": "#fdfdfc",
        "components-share-list-color-link-default": "#373432",
        "components-share-list-color-link-hover": "#00254d",
        "components-share-list-color-bg-link-hover": "#eae8e8",
        "components-share-list-color-close-icon": "#27282b",
        "components-share-list-color-facebook-icon": "#3b5998",
        "components-share-list-color-pinterest-icon": "#e60023",
        "components-share-list-color-twitter-icon": "#000000",
        "components-share-list-color-basic-icon-default": "#27282b",
        "components-share-list-color-icon-hover": "#00254d",
        "components-share-list-color-border": "#eae8e8",
        "components-site-search-bg": "#ffffff",
        "components-site-search-results_text": "#373432",
        "components-site-search-filter-filter-selected-bg": "#ebf1f9",
        "components-site-search-facet-group-label-bottom-line": "#aba8a6",
        "components-site-search-facet-group-label-text": "#373432",
        "components-site-search-facet-group-label-bg": "#ffffff",
        "components-site-search-facet-bg": "#ffffff",
        "components-site-search-facet-stroke": "#aba8a6",
        "components-site-search-refine-bg": "#ffffff",
        "components-site-search-refine-right-divider": "#ebf1f9",
        "components-site-search-refine-title": "#121111",
        "components-site-search-hero-search-bg": "#12202e",
        "components-site-search-hero-search-accent-bg": "#28588a",
        "components-site-search-hero-search-title": "#ffffff",
        "components-stats-stat-text": "#12202e",
        "components-stats-stat-text-on-light": "#12202e",
        "components-stats-supporting-text": "#373432",
        "components-stats-bg-default": "#ffffff",
        "components-stats-bg-inner": "#ffffff",
        "components-stats-bg-section-inner": "#12202e",
        "components-stats-title": "#121111",
        "components-stats-copy": "#373432",
        "components-supporting-text-default": "#5b5654",
        "components-supporting-text-destructive": "#b32b30",
        "components-tab-bg": "#ffffff",
        "components-tab-border-default": "#807a77",
        "components-tab-border-hover": "#00254d",
        "components-tab-border-focused": "#121111",
        "components-tab-border-destructive": "#b32b30",
        "components-tab-label-text-default": "#121111",
        "components-tab-item-text-default": "#121111",
        "components-tab-item-bg-default": "#ffffff",
        "components-tab-item-bg-hover": "#ebf1f9",
        "components-tab-input-field-default": "#807a77",
        "components-tab-input-field-focused": "#121111",
        "components-tab-input-label-focused": "#121111",
        "components-tab-input-field-destructive": "#b32b30",
        "components-tab-bg-2": "#ebf1f9",
        "components-tag-color-bg": "#ffffff",
        "components-tag-color-text": "#00254d",
        "components-tag-color-stroke": "#00254d",
        "components-tag-color-bg-hover": "#12202e",
        "components-tag-color-text-hover": "#ffffff",
        "components-tag-color-stroke-hover": "#12202e",
        "components-text-field-bg": "#fdfdfc",
        "components-text-field-bg-focused": "#ebf1f9",
        "components-text-field-border-default": "#121111",
        "components-text-field-border-hover": "#00254d",
        "components-text-field-border-focused": "#121111",
        "components-text-field-border-destructive": "#b32b30",
        "components-text-field-input-field-default": "#5b5654",
        "components-text-field-input-field-focused": "#121111",
        "components-text-field-input-label-focused": "#121111",
        "components-text-field-input-field-destructive": "#b32b30",
        "components-text-field-icon-default": "#807a77",
        "components-text-field-icon-destructive": "#b32b30",
        "components-text-field-supporting-text-default": "#5b5654",
        "components-text-field-supporting-text-destructive": "#b32b30",
        "components-tip-color-bg": "#ebf1f9",
        "components-tip-color-title": "#121111",
        "components-tip-color-body": "#373432",
        "components-tip-color-stroke": "#b0c5db",
        "components-video-color-bg": "#00254d",
        "themes-general-surface-color-light-bg": "#ffffff",
        "themes-general-surface-color-light-title": "#121111",
        "themes-general-surface-color-light-body": "#373432",
        "themes-general-surface-color-light-decor-line-break": "#00254d",
        "themes-general-surface-color-light-rule-line": "#00254d",
        "themes-general-surface-color-dark-bg": "#00254d",
        "themes-general-surface-color-dark-title": "#ffffff",
        "themes-general-surface-color-dark-body": "#fdfdfc",
        "themes-general-surface-color-dark-decor-line-break": "#00254d",
        "themes-general-card-color-dark-title": "#121111",
        "themes-general-card-color-dark-subtitle": "#373432",
        "themes-general-card-color-dark-body": "#373432",
        "themes-general-card-color-light-title": "#ffffff",
        "themes-general-card-color-light-subtitle": "#fdfdfc",
        "themes-general-card-color-light-body": "#fdfdfc",
        "themes-shadow-color": "#ffffff",
        "themes-shadow-share-list-color-fill": "rgba(0, 0, 0, 0.40)",
        "themes-shadow-header-menu-color-fill": "rgba(0, 0, 0, 0.20)",
        "themes-gradient-loader-gradient-fill-1": "#00254d",
        "themes-gradient-loader-gradient-fill-2": "rgba(0, 37, 77, 0.00)",
        "themes-color-background-white": "#ffffff",
        "themes-color-background-light": "#ebf1f9",
        "themes-color-background-dark": "#27282b",
        "themes-color-background-black": "#121111",
        "themes-color-background-brand1": "#00254d",
        "themes-color-background-brand2": "#1dade9",
        "themes-color-background-accent1": "#2fcfc8",
        "themes-color-background-accent2": "#fec02f",
        "themes-color-background-body": "#ffffff",
        "themes-color-stroke-white": "#ffffff",
        "themes-color-stroke-light": "#aba8a6",
        "themes-color-stroke-dark": "#373432",
        "themes-color-stroke-black": "#121111",
        "themes-color-stroke-brand1": "#00254d",
        "themes-color-stroke-brand2": "#d44300",
        "themes-color-stroke-accent1": "#2fcfc8",
        "themes-color-stroke-accent2": "#fec02f",
        "themes-color-text-white": "#ffffff",
        "themes-color-text-light": "#fdfdfc",
        "themes-color-text-dark": "#373432",
        "themes-color-text-black": "#121111",
        "themes-color-text-brand1": "#00254d",
        "themes-color-text-brand2": "#d44300",
        "themes-color-text-accent1": "#1e837e",
        "themes-color-text-accent2": "#a1791e",
        "colors-grayscale-100": "#fdfdfc",
        "colors-grayscale-200": "#eae8e8",
        "colors-grayscale-300": "#aba8a6",
        "colors-grayscale-400": "#807a77",
        "colors-grayscale-500": "#5b5654",
        "colors-grayscale-600": "#373432",
        "colors-grayscale-700": "#121111",
        "colors-brand-1-100": "#ebf1f9",
        "colors-brand-1-200": "#b0c5db",
        "colors-brand-1-300": "#28588a",
        "colors-brand-1-400": "#00254d",
        "colors-brand-1-500": "#12202e",
        "colors-brand-1-600": "#12171c",
        "colors-brand-1-700": "#0c0e0f",
        "colors-brand-2-100": "#aed8eb",
        "colors-brand-2-200": "#7ac9ea",
        "colors-brand-2-300": "#4ebaea",
        "colors-brand-2-400": "#1dade9",
        "colors-brand-2-500": "#1a9bd0",
        "colors-brand-2-600": "#1686b4",
        "colors-brand-2-700": "#0d4d68",
        "colors-accent-1-100": "#e5f6f5",
        "colors-accent-1-200": "#c8edeb",
        "colors-accent-1-300": "#a5e3e0",
        "colors-accent-1-400": "#2fcfc8",
        "colors-accent-1-500": "#24a09b",
        "colors-accent-1-600": "#1e837e",
        "colors-accent-1-700": "#155d59",
        "colors-accent-2-100": "#fff9f2",
        "colors-accent-2-200": "#fff4e5",
        "colors-accent-2-300": "#fece7a",
        "colors-accent-2-400": "#fec02f",
        "colors-accent-2-500": "#e3ac2a",
        "colors-accent-2-600": "#c59524",
        "colors-accent-2-700": "#a1791e",
        "colors-accent-3-100": "#f2f7f9",
        "colors-accent-3-200": "#c6dee7",
        "colors-accent-3-300": "#74b7cb",
        "colors-accent-3-400": "#19a0bc",
        "colors-accent-3-500": "#168fa8",
        "colors-accent-3-600": "#137c92",
        "colors-accent-3-700": "#106577",
        "colors-accent-4-400_storage": "#f8f4f2",
        "colors-accessible-accent-1-100": "#f5f2fc",
        "colors-accessible-accent-1-200": "#cfbff2",
        "colors-accessible-accent-1-300": "#ab8fe9",
        "colors-accessible-accent-1-400": "#865ddf",
        "colors-accessible-accent-1-500": "#6d3ad8",
        "colors-accessible-accent-1-600": "#4c21a9",
        "colors-accessible-accent-1-700": "#341672",
        "colors-accessible-accent-2-100": "#e7f8e4",
        "colors-accessible-accent-2-200": "#7ddc6f",
        "colors-accessible-accent-2-300": "#3cb42c",
        "colors-accessible-accent-2-400": "#2d8721",
        "colors-accessible-accent-2-500": "#246d1a",
        "colors-accessible-accent-2-600": "#1b5214",
        "colors-accessible-accent-2-700": "#11340c",
        "colors-accessible-brand-2-100": "#fff1ea",
        "colors-accessible-brand-2-200": "#ffb694",
        "colors-accessible-brand-2-300": "#ff7435",
        "colors-accessible-brand-2-400": "#d44300",
        "colors-accessible-brand-2-500": "#b33900",
        "colors-accessible-brand-2-600": "#812900",
        "colors-accessible-brand-2-700": "#431500"
      },
      "spacing": {
        "components-accordion-spacing-large-padding-x": "5rem",
        "components-accordion-spacing-large-icon-margin-right": "2rem",
        "components-accordion-spacing-large-copy-margin-right": "2.5rem",
        "components-accordion-spacing-large-maxwidth": "59.625rem",
        "components-accordion-spacing-large-section-padding-x": "0rem",
        "components-accordion-spacing-large-section-padding-y": "2.5rem",
        "components-accordion-spacing-large-section-copy-margin-bottom": "2.5rem",
        "components-accordion-spacing-large-section-space-between": "4rem",
        "components-accordion-spacing-large-item-padding-x": "0rem",
        "components-accordion-spacing-large-item-padding-y": "1.5rem",
        "components-accordion-spacing-large-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-large-content-padding-x": "0rem",
        "components-accordion-spacing-large-content-padding-y": "2.5rem",
        "components-accordion-spacing-large-content-title-margin-bottom": "2.5rem",
        "components-accordion-spacing-large-content-content-space-between": "4rem",
        "components-accordion-spacing-large-content-content-margin-left": "1rem",
        "components-accordion-spacing-small-padding-top": "1rem",
        "components-accordion-spacing-small-padding-x": "1.5rem",
        "components-accordion-spacing-small-padding-y": "2.5rem",
        "components-accordion-spacing-small-icon-margin-right": "0.75rem",
        "components-accordion-spacing-small-title-margin-bottom": "0.5rem",
        "components-accordion-spacing-small-copy-margin-right": "2rem",
        "components-accordion-spacing-small-copy-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-padding-x": "0rem",
        "components-accordion-spacing-small-section-padding-y": "0rem",
        "components-accordion-spacing-small-section-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-space-between": "2.5rem",
        "components-accordion-spacing-small-item-padding-x": "0rem",
        "components-accordion-spacing-small-item-padding-y": "1rem",
        "components-accordion-spacing-small-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-small-content-padding-x": "0rem",
        "components-accordion-spacing-small-content-padding-y": "0.75rem",
        "components-accordion-spacing-small-content-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-content-content-space-between": "1rem",
        "components-accordion-spacing-small-content-content-margin-left": "1rem",
        "components-article-card-spacing-large-padding-image-x": "0rem",
        "components-article-card-spacing-large-padding-image-y": "0rem",
        "components-article-card-spacing-large-padding-content-x": "1.5rem",
        "components-article-card-spacing-large-padding-content-y": "1.5rem",
        "components-article-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-article-card-spacing-large-button-space-between": "1rem",
        "components-article-card-spacing-large-title-margin-bottom": "1rem",
        "components-article-card-spacing-large-list-content-gap": "1rem",
        "components-article-card-spacing-large-list-image-padding-x": "1rem",
        "components-article-card-spacing-large-list-image-padding-y": "1rem",
        "components-article-card-spacing-small-padding-image-x": "0rem",
        "components-article-card-spacing-small-padding-image-y": "0rem",
        "components-article-card-spacing-small-padding-content-x": "1.5rem",
        "components-article-card-spacing-small-padding-content-y": "1.5rem",
        "components-article-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-article-card-listing-spacing-small-padding-x": "1.5rem",
        "components-article-card-listing-spacing-small-padding-y": "2.5rem",
        "components-article-hero-spacing-large-padding-x": "0rem",
        "components-article-hero-spacing-large-padding-y": "0rem",
        "components-article-hero-spacing-large-title-margin-bottom": "2rem",
        "components-article-hero-spacing-large-label-share-margin-bottom": "2rem",
        "components-article-hero-spacing-large-author-name-margin-bottom": "0.75rem",
        "components-article-hero-spacing-large-author-thumbnail-margin-left": "1rem",
        "components-article-hero-spacing-large-details-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-space-between": "1rem",
        "components-article-hero-spacing-large-body-margin-bottom": "2rem",
        "components-article-hero-spacing-large-image-space-between": "1rem",
        "components-article-hero-spacing-large-image-margin-bottom": "1rem",
        "components-article-hero-spacing-large-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-stats-margin-bottom": "2.5rem",
        "components-article-hero-spacing-small-content-margin-top": "2.5rem",
        "components-article-hero-spacing-small-title-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-label-share-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-authorname-margin-bottom": "0.75rem",
        "components-article-hero-spacing-small-authorthumbnail-margin-left": "1rem",
        "components-article-hero-spacing-small-details-margin-bottom": "1rem",
        "components-article-hero-spacing-small-space-between": "0.75rem",
        "components-article-hero-spacing-small-body-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-image-space-between": "0.75rem",
        "components-article-hero-spacing-small-image-margin-bottom": "1rem",
        "components-article-hero-spacing-small-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-stats-margin-bottom": "2rem",
        "components-banner-spacing-large-content-padding-x": "1.5rem",
        "components-banner-spacing-large-content-space-between": "1rem",
        "components-banner-spacing-large-content-width": "69.375rem",
        "components-banner-spacing-icon-dimensions": "1.5rem",
        "components-banner-spacing-icon-margin-left": "0.5rem",
        "components-banner-spacing-small-space-between": "1rem",
        "components-banner-spacing-small-content-space-between": "1rem",
        "components-banner-spacing-small-title-margin-bottom": "0.75rem",
        "components-banner-spacing-small-content-margin-bottom": "1rem",
        "components-breadcrumb-spacing-large-space-between": "0.25rem",
        "components-breadcrumb-spacing-small-space-between": "0.25rem",
        "components-button-spacing-large-icon-large-x": "2.5rem",
        "components-button-spacing-large-icon-large-y": "2.5rem",
        "components-button-spacing-large-default-padding-y": "0.75rem",
        "components-button-spacing-large-default-padding-x": "1.5rem",
        "components-button-spacing-large-default-space-between": "0.25rem",
        "components-button-spacing-large-icon-left-padding-left": "1rem",
        "components-button-spacing-large-icon-right-padding-right": "1rem",
        "components-button-spacing-large-icon-only-padding-x": "0.75rem",
        "components-button-spacing-large-icon-only-padding-y": "0.75rem",
        "components-button-spacing-large-tertiary-content-height": "1.5rem",
        "components-button-spacing-compressed-icon-large-x": "2.5rem",
        "components-button-spacing-compressed-icon-large-y": "2.5rem",
        "components-button-spacing-compressed-default-padding-y": "0.5rem",
        "components-button-spacing-compressed-default-padding-x": "1rem",
        "components-button-spacing-compressed-default-space-between": "0.125rem",
        "components-button-spacing-compressed-icon-left-padding-left": "0.5rem",
        "components-button-spacing-compressed-icon-right-padding-right": "0.5rem",
        "components-button-spacing-compressed-icon-only-padding-x": "0.5rem",
        "components-button-group-spacing-padding-y": "0.125rem",
        "components-button-group-spacing-padding-x": "0.25rem",
        "components-button-group-spacing-space-between": "0.5rem",
        "components-category-card-spacing-large-padding-top": "0rem",
        "components-category-card-spacing-large-padding-x": "0rem",
        "components-category-card-spacing-large-padding-y": "0rem",
        "components-category-card-spacing-large-padding-image-x": "0rem",
        "components-category-card-spacing-large-padding-image-y": "0rem",
        "components-category-card-spacing-large-padding-content-x": "2rem",
        "components-category-card-spacing-large-padding-content-y": "2rem",
        "components-category-card-listing-spacing-large-padding-y": "5rem",
        "components-category-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-category-card-listing-spacing-large-card-margin-bottom": "0rem",
        "components-category-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-card-search-result-small-padding-top": "0rem",
        "components-card-search-result-small-padding-x": "0rem",
        "components-card-search-result-small-padding-y": "0rem",
        "components-card-search-result-small-padding-image-x": "0rem",
        "components-card-search-result-small-padding-image-y": "0rem",
        "components-card-search-result-small-padding-content-x": "1.5rem",
        "components-card-search-result-small-padding-content-y": "1.5rem",
        "components-card-search-result-spacing-large-padding-x": "0rem",
        "components-card-search-result-spacing-large-padding-y": "1.5rem",
        "components-card-search-result-spacing-large-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-image-margin-right": "1.5rem",
        "components-card-search-result-spacing-small-padding-x": "1.5rem",
        "components-card-search-result-spacing-small-padding-y": "1.5rem",
        "components-card-search-result-spacing-small-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-image-margin-right": "0.75rem",
        "components-contact-us-promo-spacing-large-padding-x": "5rem",
        "components-contact-us-promo-spacing-large-padding-y": "5rem",
        "components-contact-us-promo-spacing-large-content-margin-right": "5rem",
        "components-contact-us-promo-spacing-large-margin-y": "2.5rem",
        "components-contact-us-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-contact-us-promo-spacing-small-margin-y": "2.5rem",
        "components-content-list-list-item-container-padding-x": "0.75rem",
        "components-content-list-list-item-container-padding-y": "1rem",
        "components-content-list-list-item-content-space-between": "0.5rem",
        "components-content-list-list-item-content-default-padding-y": "0.25rem",
        "components-content-list-list-item-content-hyperlink-padding-y": "0rem",
        "components-content-list-spacing-large-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-large-item-title-margin-bottom": "1rem",
        "components-content-list-spacing-large-item-copy-margin-bottom": "2.5rem",
        "components-content-list-spacing-large-item-icon-copy-margin-top": "0rem",
        "components-content-list-spacing-large-item-margin-bottom": "2rem",
        "components-content-list-spacing-large-item-image-margin-bottom": "0.5rem",
        "components-content-list-spacing-large-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-y": "2.5rem",
        "components-content-list-spacing-small-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-small-item-title-margin-bottom": "0.5rem",
        "components-content-list-spacing-small-item-copy-margin-bottom": "1rem",
        "components-content-list-spacing-small-item-margin-bottom": "1.5rem",
        "components-content-list-spacing-small-header-title-margin-bottom": "1rem",
        "components-content-list-spacing-small-header-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-title-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-button-space-between": "1rem",
        "components-dropdown-item-padding-y": "0.75rem",
        "components-dropdown-item-padding-x": "0.75rem",
        "components-dropdown-item-spacing-x": "0.75rem",
        "components-dropdown-input-padding-y": "0.25rem",
        "components-dropdown-input-padding-x": "1rem",
        "components-footer-brand-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-large-column-margin-right": "4rem",
        "components-footer-brand-spacing-large-column-margin-bottom": "4rem",
        "components-footer-brand-spacing-large-link-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-padding-y": "2.5rem",
        "components-footer-brand-spacing-small-padding-x": "1rem",
        "components-footer-brand-spacing-small-logo-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-column-margin-right": "0.125rem",
        "components-footer-brand-spacing-small-column-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-small-link-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-title-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-text-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-footer-link-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-link-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-link-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-link-minheight": "3rem",
        "components-footer-brand-spacing-small-footer-category-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-category-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-category-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-category-minheight": "3rem",
        "components-footer-global-spacing-large-padding-y": "2.5rem",
        "components-footer-global-spacing-large-padding-x": "5rem",
        "components-footer-global-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-global-spacing-large-column-margin-right": "2rem",
        "components-footer-global-spacing-large-link-margin-bottom": "2rem",
        "components-footer-global-spacing-small-padding-y": "2.5rem",
        "components-footer-global-spacing-small-padding-x": "1rem",
        "components-footer-global-spacing-small-logo-margin-bottom": "1rem",
        "components-footer-global-spacing-small-column-margin-right": "1rem",
        "components-footer-global-spacing-small-link-margin-bottom": "1rem",
        "components-generic-card-spacing-large-padding-x": "0rem",
        "components-generic-card-spacing-large-padding-y": "0rem",
        "components-generic-card-spacing-large-padding-image-x": "0rem",
        "components-generic-card-spacing-large-padding-image-y": "0rem",
        "components-generic-card-spacing-large-padding-content-x": "2rem",
        "components-generic-card-spacing-large-padding-content-y": "2rem",
        "components-generic-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-generic-card-spacing-large-title-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-button-space-between": "1rem",
        "components-generic-card-spacing-small-padding-x": "0rem",
        "components-generic-card-spacing-small-padding-y": "0rem",
        "components-generic-card-spacing-small-padding-image-x": "0rem",
        "components-generic-card-spacing-small-padding-image-y": "1.5rem",
        "components-generic-card-spacing-small-padding-content-x": "1.5rem",
        "components-generic-card-spacing-small-padding-content-y": "1.5rem",
        "components-global-search-spacing-omnibox-padding-x": "0.125rem",
        "components-global-search-spacing-omnibox-list-padding-y": "0.5rem",
        "components-global-search-spacing-omnibox-label-padding": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-item-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-height": "3rem",
        "components-generic-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-generic-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-header-shadow-x": "0rem",
        "components-header-spacing-large-padding-y": "0rem",
        "components-header-spacing-large-padding-x": "0rem",
        "components-header-spacing-large-links-margin-right": "1.5rem",
        "components-header-spacing-large-icons-space-between": "1.5rem",
        "components-header-spacing-large-logo-margin-right": "2rem",
        "components-header-spacing-large-space-between-search": "0.5rem",
        "components-header-spacing-large-height": "5.5rem",
        "components-header-spacing-large-language-selector-padding-x": "1rem",
        "components-header-spacing-large-language-selector-padding-y": "0.5rem",
        "components-header-spacing-large-language-selector-space-between": "0.75rem",
        "components-header-spacing-large-language-selector-country-space-between": "0.5rem",
        "components-header-spacing-large-mega-menu-padding-y": "2.5rem",
        "components-header-spacing-large-mega-menu-padding-x": "2.5rem",
        "components-header-spacing-large-mega-menu-column-margin-right": "2.5rem",
        "components-header-spacing-large-mega-menu-category-link-margin-bottom": "1rem",
        "components-header-spacing-large-mega-menu-link-margin-bottom": "0.5rem",
        "components-header-spacing-large-mega-menu-featured-padding-x": "2rem",
        "components-header-spacing-large-mega-menu-featured-padding-y": "2rem",
        "components-header-spacing-large-mega-menu-featured-image-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-title-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-link-space-between": "1.5rem",
        "components-header-spacing-large-mega-menu-title-margin-bottom": "2.5rem",
        "components-header-spacing-large-dropdown-padding-y": "1.5rem",
        "components-header-spacing-large-dropdown-padding-x": "1.5rem",
        "components-header-spacing-large-dropdown-margin-bottom": "0.5rem",
        "components-header-spacing-small-language-selector-padding-x": "0.5rem",
        "components-header-spacing-small-language-selector-padding-y": "0.5rem",
        "components-header-spacing-small-language-selector-space-between": "0.5rem",
        "components-header-spacing-small-padding-left": "0rem",
        "components-header-spacing-small-padding-right": "1rem",
        "components-header-spacing-small-padding-top": "0rem",
        "components-header-spacing-small-padding-bottom": "0rem",
        "components-header-spacing-small-nav-main-minheight": "3rem",
        "components-header-spacing-small-nav-main-padding-y": "0.5rem",
        "components-header-spacing-small-nav-main-padding-left": "1.5rem",
        "components-header-spacing-small-nav-main-padding-right": "1rem",
        "components-header-spacing-small-nav-category-minheight": "3rem",
        "components-header-spacing-small-nav-category-padding-left": "1.5rem",
        "components-header-spacing-small-nav-category-padding-right": "0.75rem",
        "components-header-spacing-small-nav-category-padding-y": "0.75rem",
        "components-header-spacing-small-nav-category-space-between": "0rem",
        "components-header-spacing-small-nav-menu-link-minheight": "3rem",
        "components-header-spacing-small-nav-menu-link-padding-right": "1.5rem",
        "components-header-spacing-small-nav-menu-link-padding-left": "2.5rem",
        "components-header-spacing-small-nav-menu-link-padding-y": "0.75rem",
        "components-header-spacing-small-mega-menu-padding-y": "0rem",
        "components-header-spacing-small-mega-menu-padding-x": "0rem",
        "components-header-spacing-small-mega-menu-category-link-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-link-margin-bottom": "0rem",
        "components-header-spacing-small-mega-menu-featured-padding-x": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-padding-y": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-image-margin-bottom": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-title-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-featured-link-space-between": "0.75rem",
        "components-header-spacing-small-mega-menu-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-container-padding": "0rem",
        "components-hero-homepage-spacing-large-padding-x": "5rem",
        "components-hero-homepage-spacing-large-padding-y": "2.5rem",
        "components-hero-homepage-spacing-large-minheight": "34.3125rem",
        "components-hero-homepage-spacing-large-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-margin-bottom": "2rem",
        "components-hero-homepage-spacing-large-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-copy-margin-bottom": "2.5rem",
        "components-hero-homepage-spacing-large-cta-margin-right": "1rem",
        "components-hero-homepage-spacing-large-image-height": "34.3125rem",
        "components-hero-homepage-spacing-large-image-width": "45.75rem",
        "components-hero-homepage-spacing-small-padding-x": "0.5rem",
        "components-hero-homepage-spacing-small-padding-y": "1.5rem",
        "components-hero-homepage-spacing-small-minheight": "34.3125rem",
        "components-hero-homepage-spacing-small-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-copy-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-image-height": "17.5625rem",
        "components-hero-homepage-spacing-small-image-width": "45.75rem",
        "components-hero-internal-spacing-large-padding-y": "2.5rem",
        "components-hero-internal-spacing-large-padding-right": "10rem",
        "components-hero-internal-spacing-large-title-margin-bottom": "1.5rem",
        "components-hero-internal-spacing-large-button-padding-y": "2rem",
        "components-hero-internal-spacing-large-text-maxwidth": "59.625rem",
        "components-hero-internal-spacing-large-minheight": "18.5rem",
        "components-hero-internal-spacing-small-padding-x": "1.5rem",
        "components-hero-internal-spacing-small-padding-right": "0rem",
        "components-hero-internal-spacing-small-button-padding-y": "1.5rem",
        "components-hero-internal-spacing-small-text-maxwidth": "30rem",
        "components-hero-internal-spacing-small-minheight": "13.5rem",
        "components-icon-card-spacing-large-padding-x": "0rem",
        "components-icon-card-spacing-large-padding-y": "0rem",
        "components-icon-card-spacing-large-icon-margin": "1.5rem",
        "components-icon-card-spacing-large-margin-right": "1.5rem",
        "components-icon-card-spacing-large-title-margin-bottom": "1rem",
        "components-icon-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-icon-card-spacing-large-button-space-between": "1.5rem",
        "components-icon-card-spacing-large-top-space-between": "1rem",
        "components-icon-card-spacing-small-padding-x": "1.5rem",
        "components-icon-card-spacing-small-padding-y": "1.5rem",
        "components-icon-card-spacing-small-icon-margin": "1.5rem",
        "components-icon-card-spacing-small-margin-right": "1.5rem",
        "components-icon-card-spacing-small-title-margin-bottom": "1.5rem",
        "components-icon-card-dimensions-large-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-large-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-large-big-icon-height": "4rem",
        "components-icon-card-dimensions-large-big-icon-width": "3.5rem",
        "components-icon-card-dimensions-large-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-large-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-large-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-large-tiny-icon-width": "2.5rem",
        "components-icon-card-dimensions-small-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-small-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-small-big-icon-height": "3.5rem",
        "components-icon-card-dimensions-small-big-icon-width": "3.5rem",
        "components-icon-card-dimensions-small-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-small-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-small-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-small-tiny-icon-width": "2.5rem",
        "components-icon-card-listing-spacing-large-padding-inner-x": "4rem",
        "components-icon-card-listing-spacing-large-padding-inner-y": "4rem",
        "components-icon-card-listing-spacing-large-card-space-between": "4rem",
        "components-icon-card-listing-spacing-large-title-margin-bottom": "1.5rem",
        "components-icon-card-listing-spacing-large-card-margin-bottom": "4rem",
        "components-icon-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-x": "1.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-y": "2.5rem",
        "components-icon-card-listing-spacing-small-title-margin-bottom": "1rem",
        "components-icon-card-listing-spacing-small-button-margin-top": "1.5rem",
        "components-jumplink-link-padding-bottom": "0.5rem",
        "components-jumplink-spacing-small-title-area-margin-bottom": "1.5rem",
        "components-jumplink-spacing-small-link-container-padding-x": "0rem",
        "components-lightbox-spacing-large-carousel-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-carousel-space-between": "1.5rem",
        "components-lightbox-spacing-large-featured-image-height": "39.25rem",
        "components-lightbox-spacing-large-featured-image-width": "39.25rem",
        "components-lightbox-spacing-large-image-height": "17.0625rem",
        "components-lightbox-spacing-large-image-width": "17.0625rem",
        "components-lightbox-spacing-small-carousel-margin-bottom": "1rem",
        "components-lightbox-spacing-small-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-small-media-details-margin-top": "1rem",
        "components-lightbox-spacing-small-image-height": "20.4375rem",
        "components-lightbox-spacing-small-image-width": "20.4375rem",
        "components-legal-disclaimer-spacing-small-ruleline-width": "20.4375rem",
        "components-media-spacing-large-padding-y": "0.5rem",
        "components-media-spacing-large-padding-x": "0.75rem",
        "components-media-gallery-large-container-grid-gap-x": "1.5rem",
        "components-media-gallery-large-container-grid-gap-y": "1.5rem",
        "components-media-gallery-small-container-grid-gap-x": "0.5rem",
        "components-media-gallery-small-container-grid-gap-y": "0.5rem",
        "components-media-gallery-spacing-small-padding-x": "1.5rem",
        "components-media-gallery-spacing-small-padding-y": "2.5rem",
        "components-media-gallery-spacing-large-padding-x": "5rem",
        "components-media-gallery-spacing-large-padding-y": "5rem",
        "components-product-card-spacing-list-content-gap": "0rem",
        "components-product-card-spacing-large-padding-image-x": "0rem",
        "components-product-card-spacing-large-padding-image-y": "0rem",
        "components-product-card-spacing-large-padding-content-x": "1.5rem",
        "components-product-card-spacing-large-padding-content-y": "1.5rem",
        "components-product-card-spacing-large-category-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-title-margin-bottom": "1rem",
        "components-product-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-button-space-between": "1rem",
        "components-product-card-spacing-small-padding-image-x": "0rem",
        "components-product-card-spacing-small-padding-image-y": "0rem",
        "components-product-card-spacing-small-padding-content-x": "1.5rem",
        "components-product-card-spacing-small-padding-content-y": "1.5rem",
        "components-product-card-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-large-padding-x": "5rem",
        "components-product-information-spacing-large-padding-y": "2.5rem",
        "components-product-information-spacing-large-title-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-label-margin-bottom": "2.5rem",
        "components-product-information-spacing-large-ratings-margin-bottom": "1rem",
        "components-product-information-spacing-large-details-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-space-between": "1rem",
        "components-product-information-spacing-large-carousel-space-between": "5rem",
        "components-product-information-spacing-large-button-margin-y": "0.5rem",
        "components-product-information-spacing-large-body-margin-top": "1.5rem",
        "components-product-information-spacing-large-image-space-between": "1rem",
        "components-product-information-spacing-large-image-margin-bottom": "1rem",
        "components-product-information-spacing-small-padding-x": "1.5rem",
        "components-product-information-spacing-small-padding-y": "1.5rem",
        "components-product-information-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-small-label-margin-bottom": "1.5rem",
        "components-product-information-spacing-small-ratings-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-details-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-space-between": "0.75rem",
        "components-product-information-spacing-small-carousel-space-between": "2.5rem",
        "components-product-information-spacing-small-button-margin-y": "0.75rem",
        "components-product-information-spacing-small-body-margin-top": "1rem",
        "components-product-information-spacing-small-image-space-between": "0.75rem",
        "components-product-information-spacing-small-image-margin-bottom": "1rem",
        "components-product-results-list-with-search-spacing-large-padding-x": "5rem",
        "components-product-results-list-with-search-spacing-large-padding-y": "5rem",
        "components-product-results-list-with-search-spacing-large-ruleline-margin-top": "2.5rem",
        "components-product-results-list-with-search-spacing-large-filter-space-between": "1rem",
        "components-product-results-list-with-search-spacing-large-card-space-between": "1.5rem",
        "components-product-results-list-with-search-spacing-large-card-container-margin-bottom": "5rem",
        "components-product-results-list-with-search-spacing-small-padding-x": "1.5rem",
        "components-product-results-list-with-search-spacing-small-padding-y": "2.5rem",
        "components-product-results-list-with-search-spacing-small-ruleline-margin-top": "1rem",
        "components-product-results-list-with-search-spacing-small-filter-padding-x": "0.5rem",
        "components-product-results-list-with-search-spacing-small-card-space-between": "1rem",
        "components-product-results-list-with-search-spacing-small-card-margin-bottom": "1.5rem",
        "components-promo-spacing-large-padding-x": "0rem",
        "components-promo-spacing-large-padding-y": "0rem",
        "components-promo-spacing-large-content-space-between": "0rem",
        "components-promo-spacing-large-content-space-between-y": "0rem",
        "components-promo-spacing-large-padding-content-x": "5rem",
        "components-promo-spacing-large-padding-content-condensed-x": "5rem",
        "components-promo-spacing-large-padding-content-y": "5rem",
        "components-promo-spacing-large-padding-content-condensed-y": "5rem",
        "components-promo-spacing-large-padding-image-x": "0rem",
        "components-promo-spacing-large-padding-image-y": "0rem",
        "components-promo-spacing-large-padding-image-top": "5rem",
        "components-promo-spacing-large-title-margin-bottom": "2rem",
        "components-promo-spacing-large-margin-y": "2rem",
        "components-promo-spacing-large-buttons-space-between": "1.5rem",
        "components-promo-spacing-small-padding-x": "0rem",
        "components-promo-spacing-small-padding-y": "0rem",
        "components-promo-spacing-small-padding-top": "0rem",
        "components-promo-spacing-small-padding-content-x": "2.5rem",
        "components-promo-spacing-small-padding-content-y": "2.5rem",
        "components-promo-spacing-small-padding-image-x": "0rem",
        "components-promo-spacing-small-padding-image-y": "0rem",
        "components-promo-spacing-small-padding-image-top": "0rem",
        "components-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-promo-spacing-small-margin-y": "2.5rem",
        "components-promo-minheight": "30rem",
        "components-pull-quote-spacing-large-padding-quote-x": "4rem",
        "components-pull-quote-spacing-large-padding-quote-y": "4rem",
        "components-pull-quote-spacing-large-title-margin-bottom": "2rem",
        "components-pull-quote-spacing-large-quote-margin-bottom": "4rem",
        "components-pull-quote-spacing-large-quote-space-between": "1.5rem",
        "components-pull-quote-spacing-large-detail-space-between": "0.5rem",
        "components-pull-quote-spacing-large-quote-minheight": "25rem",
        "components-pull-quote-spacing-small-padding-quote-x": "1.5rem",
        "components-pull-quote-spacing-small-padding-quote-y": "2.5rem",
        "components-pull-quote-spacing-small-title-margin-bottom": "1.5rem",
        "components-pull-quote-spacing-small-quote-margin-bottom": "2.5rem",
        "components-pull-quote-spacing-small-quote-space-between": "1rem",
        "components-pull-quote-spacing-small-detail-space-between": "1rem",
        "components-pull-quote-spacing-small-quote-minheight": "15rem",
        "components-promo-alternate-minheight": "30rem",
        "components-promo-alternate-spacing-large-padding-x": "0rem",
        "components-promo-alternate-spacing-large-padding-y": "0rem",
        "components-promo-alternate-spacing-large-content-space-between": "0rem",
        "components-promo-alternate-spacing-large-padding-content-x": "5rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-x": "5rem",
        "components-promo-alternate-spacing-large-padding-content-y": "5rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-y": "5rem",
        "components-promo-alternate-spacing-large-padding-image-x": "0rem",
        "components-promo-alternate-spacing-large-padding-image-y": "0rem",
        "components-promo-alternate-spacing-large-padding-image-top": "5rem",
        "components-promo-alternate-spacing-large-title-margin-bottom": "2rem",
        "components-promo-alternate-spacing-small-padding-x": "0rem",
        "components-promo-alternate-spacing-small-padding-y": "0rem",
        "components-promo-alternate-spacing-small-padding-content-x": "2.5rem",
        "components-promo-alternate-spacing-small-padding-content-y": "2.5rem",
        "components-promo-alternate-spacing-small-padding-image-x": "0rem",
        "components-promo-alternate-spacing-small-padding-image-y": "0rem",
        "components-promo-alternate-spacing-small-padding-image-top": "0rem",
        "components-promo-alternate-spacing-small-title-margin-bottom": "1.5rem",
        "components-share-list-spacing-padding-x": "1rem",
        "components-share-list-spacing-padding-y": "1rem",
        "components-header-copy-margin-bottom": "1rem",
        "components-header-margin-bottom": "1.5rem",
        "components-item-margin-bottom": "1.5rem",
        "components-site-search-padding-x": "2.5rem",
        "components-site-search-padding-y": "5rem",
        "components-site-search-facet-label-space-between": "0.25rem",
        "components-site-search-refine-padding-x": "1.5rem",
        "components-site-search-refine-padding-y": "0rem",
        "components-site-search-hero-search-height": "18.5rem",
        "components-site-search-hero-search-large-padding-x": "5rem",
        "components-site-search-hero-search-large-padding-y": "5rem",
        "components-stats-spacing-stat-text-space-between": "0.5rem",
        "components-stats-spacing-large-inner-padding-x": "5rem",
        "components-stats-spacing-large-inner-padding-y": "5rem",
        "components-stats-spacing-small-inner-padding-x": "1.5rem",
        "components-stats-spacing-small-inner-padding-y": "1.5rem",
        "components-icon-card-listing-spacing-small-top-space-between": "1rem",
        "components-supporting-text-padding-x": "0rem",
        "components-supporting-text-padding-bottom": "0.5rem",
        "components-tab-item-padding-y": "0.75rem",
        "components-tab-item-padding-x": "0.75rem",
        "components-tab-item-spacing-x": "0.75rem",
        "components-tab-input-padding-y": "0.25rem",
        "components-tab-input-padding-x": "1rem",
        "components-tag-spacing-padding-x": "0.5rem",
        "components-tag-spacing-padding-y": "0.5rem",
        "components-tag-spacing-space-between": "0.5rem",
        "components-text-field-input-padding-y": "0.25rem",
        "components-text-field-input-padding-x": "1rem",
        "components-text-field-area-input-padding-y": "1rem",
        "components-text-field-area-input-padding-x": "1rem",
        "components-text-field-supporting-text-padding-x": "0rem",
        "components-text-field-supporting-text-padding-top": "0.5rem",
        "themes-general-surface-spacing-large-padding-x": "5rem",
        "themes-general-surface-spacing-large-padding-y": "5rem",
        "themes-general-surface-spacing-large-title-margin-bottom": "2rem",
        "themes-general-surface-spacing-large-body-margin-right": "4rem",
        "themes-general-surface-spacing-large-body-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-large-margin-bottom": "4rem",
        "themes-general-surface-spacing-large-cards-space-between": "1.5rem",
        "themes-general-surface-spacing-large-button-margin-top": "2rem",
        "themes-general-surface-spacing-small-padding-x": "1.5rem",
        "themes-general-surface-spacing-small-padding-y": "2.5rem",
        "themes-general-surface-spacing-small-title-margin-bottom": "1rem",
        "themes-general-surface-spacing-small-body-margin-right": "2.5rem",
        "themes-general-surface-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-surface-spacing-small-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-small-cards-space-between": "1rem",
        "themes-general-surface-spacing-small-button-margin-top": "1.5rem",
        "themes-general-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "themes-general-card-spacing-large-title-margin-bottom": "1rem",
        "themes-general-card-spacing-large-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-large-button-space-between": "1.5rem",
        "themes-general-card-spacing-small-eyebrow-margin-bottom": "1rem",
        "themes-general-card-spacing-small-title-margin-bottom": "1rem",
        "themes-general-card-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-small-button-space-between": "1.5rem",
        "themes-shadow-share-list-spacing-x": "0rem",
        "themes-shadow-share-list-spacing-y": "0.75rem",
        "themes-shadow-share-list-spacing-blur": "1rem",
        "themes-shadow-share-list-spacing-spread": "-0.5rem",
        "themes-shadow-header-menu-spacing-x": "0rem",
        "themes-shadow-header-menu-spacing-y": "0.75rem",
        "themes-shadow-header-menu-spacing-blur": "1rem",
        "themes-shadow-header-menu-spacing-spread": "-0.5rem",
        "themes-typography-rte-large-h1-textsize": "3rem",
        "themes-typography-rte-large-h2-textsize": "2.5rem",
        "themes-typography-rte-large-h3-textsize": "2.25rem",
        "themes-typography-rte-large-h4-textsize": "2rem",
        "themes-typography-rte-large-h5-textsize": "1.5rem",
        "themes-typography-rte-large-h6-textsize": "1.25rem",
        "themes-typography-rte-medium-h1-textsize": "2.5rem",
        "themes-typography-rte-medium-h2-textsize": "2.25rem",
        "themes-typography-rte-medium-h3-textsize": "1.75rem",
        "themes-typography-rte-medium-h4-textsize": "1.5rem",
        "themes-typography-rte-medium-h5-textsize": "1.25rem",
        "themes-typography-rte-medium-h6-textsize": "1.125rem",
        "themes-typography-rte-small-h1-textsize": "2.5rem",
        "themes-typography-rte-small-h2-textsize": "2rem",
        "themes-typography-rte-small-h3-textsize": "1.75rem",
        "themes-typography-rte-small-h4-textsize": "1.5rem",
        "themes-typography-rte-small-h5-textsize": "1.125rem",
        "themes-typography-rte-small-h6-textsize": "1rem",
        "spacing-margin-small-1": "0.125rem",
        "spacing-margin-small-2": "0.25rem",
        "spacing-margin-small-3": "0.5rem",
        "spacing-margin-small-4": "0.75rem",
        "spacing-margin-small-5": "1rem",
        "spacing-margin-small-6": "1.5rem",
        "spacing-margin-small-7": "2.5rem",
        "spacing-margin-medium-2": "0.75rem",
        "spacing-margin-medium-4": "1.5rem",
        "spacing-margin-medium-5": "2rem",
        "spacing-margin-large-1": "0.5rem",
        "spacing-margin-large-2": "1rem",
        "spacing-margin-large-3": "1.5rem",
        "spacing-margin-large-4": "2rem",
        "spacing-margin-large-5": "2.5rem",
        "spacing-margin-large-6": "4rem",
        "spacing-margin-large-7": "5rem",
        "spacing-space-between-small-1": "0.125rem",
        "spacing-space-between-small-2": "0.25rem",
        "spacing-space-between-small-3": "0.5rem",
        "spacing-space-between-small-4": "0.75rem",
        "spacing-space-between-small-5": "1rem",
        "spacing-space-between-small-6": "1.5rem",
        "spacing-space-between-small-7": "2.5rem",
        "spacing-space-between-medium-1": "0.125rem",
        "spacing-space-between-medium-2": "0.25rem",
        "spacing-space-between-medium-3": "0.5rem",
        "spacing-space-between-medium-4": "0.75rem",
        "spacing-space-between-medium-5": "1rem",
        "spacing-space-between-large-1": "0.25rem",
        "spacing-space-between-large-2": "0.5rem",
        "spacing-space-between-large-3": "1rem",
        "spacing-space-between-large-4": "1.5rem",
        "spacing-space-between-large-5": "1.5rem",
        "spacing-space-between-large-6": "2.5rem",
        "spacing-space-between-large-7": "4rem",
        "spacing-padding-small-1": "0.125rem",
        "spacing-padding-small-2": "0.25rem",
        "spacing-padding-small-3": "0.5rem",
        "spacing-padding-small-4": "0.75rem",
        "spacing-padding-small-5": "1rem",
        "spacing-padding-small-6": "1.5rem",
        "spacing-padding-small-7": "2.5rem",
        "spacing-padding-medium-1": "0.25rem",
        "spacing-padding-medium-2": "0.75rem",
        "spacing-padding-medium-3": "1rem",
        "spacing-padding-medium-4": "1.5rem",
        "spacing-padding-medium-5": "2rem",
        "spacing-padding-medium-6": "3rem",
        "spacing-padding-medium-7": "4rem",
        "spacing-padding-large-1": "0.5rem",
        "spacing-padding-large-2": "1rem",
        "spacing-padding-large-3": "1.5rem",
        "spacing-padding-large-4": "2rem",
        "spacing-padding-large-5": "2.5rem",
        "spacing-padding-large-6": "4rem",
        "spacing-padding-large-7": "5rem"
      },
      "string": {
        "components-accordion-section-button": "var(--brand)",
        "components-article-card-listing-button-theme": "var(--brand)",
        "components-article-hero-pagination-buttons": "var(--brand)",
        "components-banner-banner-button": "var(--brand)",
        "components-category-card-listing-button-theme": "var(--white)",
        "components-contact-us-promo-hierarchy-button-theme-1": "var(--filled)",
        "components-contact-us-promo-hierarchy-button-theme-2": "var(--outlined)",
        "components-contact-us-promo-color-button-theme": "var(--brand)",
        "components-contact-us-promo-color-brand-button-theme": "var(--white)",
        "components-generic-card-button-theme": "var(--brand)",
        "components-global-search-loader": "var(--default)",
        "components-generic-card-listing-decor-top": "var(--glade-1)",
        "components-generic-card-listing-decor-bottom": "var(--glade-2)",
        "components-hero-homepage-pagination-buttons": "var(--tonal)",
        "components-hero-homepage-pattern": "var(--none)",
        "components-hero-homepage-button-1": "var(--brand)",
        "components-hero-homepage-button-2": "var(--brand)",
        "components-hero-homepage-image-ratio": "var(--5:4)",
        "components-hero-internal-theme-button-one-type": "var(--filled)",
        "components-hero-internal-theme-button-two-type": "var(--outlined)",
        "components-hero-internal-theme-button-color": "var(--white)",
        "components-hero-internal-theme-button-color-with-image": "var(--brand)",
        "components-lightbox-button-type": "var(--brand)",
        "components-pdf-link-card-hierarchy-button-theme-1": "var(--filled)",
        "components-pdf-link-card-hierarchy-button-theme-2": "var(--outlined)",
        "components-pdf-link-card-color-button-theme": "var(--brand)",
        "components-product-card-badge-align": "var(--center)",
        "components-product-card-listing-button-color": "var(--brand)",
        "components-promo-theme-button-one-theme": "var(--filled)",
        "components-promo-theme-button-two-theme": "var(--text)",
        "components-promo-color-default-button-theme": "var(--brand)",
        "components-promo-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-color-brand-button-theme": "var(--white)",
        "components-pull-quote-border-decor": "var(--glade)",
        "components-promo-alternate-theme-button-one-theme": "var(--filled)",
        "components-promo-alternate-theme-button-two-theme": "var(--text)",
        "components-promo-alternate-color-default-button-theme": "var(--brand)",
        "components-promo-alternate-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-alternate-color-brand-button-theme": "var(--white)",
        "components-share-list-button-color": "var(--brand)",
        "components-site-search-hero-search-hero-pattern": "var(--glade)",
        "components-stats-decor-top": "var(--glade-1)",
        "components-stats-decor-bottom": "var(--glade-2)",
        "themes-brand-loader": "var(--default)",
        "themes-brand-variants": "var(--glade)",
        "themes-tab-variant": "var(--default)",
        "themes-label-variants": "var(--default)",
        "themes-logo": "var(--glade)",
        "themes-category-card": "var(--glade)",
        "themes-title-decor": "var(--glade)",
        "themes-typography-heading-fontfamily": "var(--contax-pro-75-bold)",
        "themes-typography-heading-large-xxlarge-fontweight": "var(--bold)",
        "themes-typography-heading-large-xlarge-fontweight": "var(--bold)",
        "themes-typography-heading-large-large-fontweight": "var(--bold)",
        "themes-typography-heading-large-medium-fontweight": "var(--bold)",
        "themes-typography-heading-large-small-fontweight": "var(--bold)",
        "themes-typography-heading-large-xsmall-fontweight": "var(--bold)",
        "themes-typography-heading-large-xxsmall-fontweight": "var(--bold)",
        "themes-typography-heading-medium-xxlarge-fontweight": "var(--bold)",
        "themes-typography-heading-medium-xlarge-fontweight": "var(--bold)",
        "themes-typography-heading-medium-large-fontweight": "var(--bold)",
        "themes-typography-heading-medium-medium-fontweight": "var(--bold)",
        "themes-typography-heading-medium-small-fontweight": "var(--bold)",
        "themes-typography-heading-medium-xsmall-fontweight": "var(--bold)",
        "themes-typography-heading-medium-xxsmall-fontweight": "var(--bold)",
        "themes-typography-heading-small-xxlarge-fontweight": "var(--bold)",
        "themes-typography-heading-small-xlarge-fontweight": "var(--bold)",
        "themes-typography-heading-small-large-fontweight": "var(--bold)",
        "themes-typography-heading-small-medium-fontweight": "var(--bold)",
        "themes-typography-heading-small-small-fontweight": "var(--bold)",
        "themes-typography-heading-small-xsmall-fontweight": "var(--bold)",
        "themes-typography-heading-small-xxsmall-fontweight": "var(--bold)",
        "themes-typography-rte-fontfamily": "var(--contax-pro-75-bold)",
        "themes-typography-rte-fontweight": "var(--bold)"
      },
      "borderRadius": {
        "components-accordion-spacing-large-item-item-radius": "0rem",
        "components-article-card-spacing-large-image-radius-tl": "0rem",
        "components-article-card-spacing-large-image-radius-tr": "0rem",
        "components-article-card-spacing-large-image-radius-bl": "0rem",
        "components-article-card-spacing-large-image-radius-br": "0rem",
        "components-article-card-spacing-large-list-image-radius-br": "0rem",
        "components-article-card-spacing-small-image-radius-br": "0rem",
        "components-article-card-spacing-small-list-image-radius-br": "0rem",
        "components-article-hero-spacing-large-content-radius": "0rem",
        "components-article-hero-spacing-small-content-radius": "0rem",
        "components-button-radius-large": "7.5rem",
        "components-button-group-spacing-border-radius": "7.5rem",
        "components-category-card-radius-border-width": "0rem",
        "components-category-card-radius-border-radius": "7.5rem",
        "components-category-card-radius-border-radius-condensed": "0.5rem",
        "components-category-card-radius-media-radius": "0rem",
        "components-content-list-icon-radius": "7.5rem",
        "components-fab-spacing-border-radius": "7.5rem",
        "components-header-spacing-large-search-border-radius-top": "0rem",
        "components-header-spacing-large-search-border-radius-bottom": "0rem",
        "components-header-spacing-large-language-selector-radius": "0.25rem",
        "components-header-spacing-large-dropdown-radius": "0rem",
        "components-hero-homepage-spacing-large-container-radius": "0rem",
        "components-hero-internal-spacing-large-icon-radius": "7.5rem",
        "components-hero-internal-spacing-small-icon-radius": "7.5rem",
        "components-lightbox-spacing-large-overlay-radius": "0rem",
        "components-pagination-scrollbar-radius": "62.5rem",
        "components-product-card-spacing-image-radius-tl": "0rem",
        "components-product-card-spacing-image-radius-tr": "0rem",
        "components-product-card-spacing-image-radius-bl": "0rem",
        "components-product-card-spacing-image-radius-br": "0rem",
        "components-product-information-spacing-large-content-radius": "0rem",
        "components-product-information-spacing-small-content-radius": "0rem",
        "components-promo-spacing-large-content-radius": "0rem",
        "components-promo-spacing-large-content-radius-tl": "0rem",
        "components-promo-spacing-large-content-radius-tr": "0rem",
        "components-promo-spacing-large-content-radius-bl": "0rem",
        "components-promo-spacing-large-content-radius-br": "0rem",
        "components-promo-spacing-large-image-radius-tl": "0rem",
        "components-promo-spacing-large-image-radius-tr": "0rem",
        "components-promo-spacing-large-image-radius-bl": "0rem",
        "components-promo-spacing-large-image-radius-br": "0rem",
        "components-promo-spacing-small-content-radius": "0rem",
        "components-promo-spacing-small-image-radius-tl": "0rem",
        "components-promo-spacing-small-image-radius-tr": "0rem",
        "components-promo-spacing-small-image-radius-bl": "0rem",
        "components-promo-spacing-small-image-radius-br": "0rem",
        "components-promo-alternate-spacing-large-content-radius": "0rem",
        "components-promo-alternate-spacing-large-image-radius-tl": "0rem",
        "components-promo-alternate-spacing-large-image-radius-tr": "0rem",
        "components-promo-alternate-spacing-large-image-radius-bl": "0rem",
        "components-promo-alternate-spacing-large-image-radius-br": "0rem",
        "components-promo-alternate-spacing-small-content-radius": "0rem",
        "components-promo-alternate-spacing-small-image-radius-tl": "0rem",
        "components-promo-alternate-spacing-small-image-radius-tr": "0rem",
        "components-promo-alternate-spacing-small-image-radius-bl": "0rem",
        "components-promo-alternate-spacing-small-image-radius-br": "0rem",
        "components-share-list-spacing-radius": "0.5rem",
        "components-share-list-spacing-link-radius": "0.5rem",
        "components-site-search-filter-radius": "0.5rem",
        "components-site-search-facet-radius": "0rem",
        "components-site-search-refine-radius": "0rem",
        "components-tag-spacing-radius": "7.5rem",
        "components-tip-spacing-radius": "0.25rem",
        "themes-spacing-radius-pagination-bar": "62.5rem",
        "themes-spacing-radius-large-button": "7.5rem",
        "themes-spacing-radius-large-button-icon": "7.5rem",
        "themes-spacing-radius-large-image": "0rem",
        "themes-spacing-radius-large-image-hero": "0rem",
        "themes-spacing-radius-large-image-card": "0rem",
        "themes-spacing-radius-large-card": "0rem",
        "themes-spacing-radius-large-icon": "7.5rem",
        "themes-spacing-radius-large-label": "7.5rem",
        "themes-spacing-radius-large-tag": "7.5rem",
        "themes-spacing-radius-large-container-tip": "0.25rem",
        "themes-spacing-radius-large-badge": "0rem",
        "themes-spacing-radius-large-item": "0.5rem",
        "themes-spacing-radius-large-form-field-input": "0rem",
        "themes-spacing-radius-large-form-area-input": "0.25rem",
        "themes-spacing-radius-large-general": "0rem",
        "themes-spacing-radius-small-button": "7.5rem",
        "themes-spacing-radius-small-button-icon": "0.5rem",
        "themes-spacing-radius-small-image": "0rem",
        "themes-spacing-radius-small-image-card": "0rem",
        "themes-spacing-radius-small-card": "0rem",
        "themes-spacing-radius-small-icon": "7.5rem",
        "themes-spacing-radius-small-label": "7.5rem",
        "themes-spacing-radius-small-tag": "7.5rem",
        "themes-spacing-radius-small-badge": "7.5rem",
        "themes-spacing-radius-small-item": "0rem",
        "themes-spacing-radius-small-form-field-input": "0rem",
        "themes-spacing-radius-small-general": "0rem"
      },
      "borderWidth": {
        "3": "3px",
        "components-article-card-spacing-large-border-width": "0.0625rem",
        "components-generic-card-color-border-width": "0.0625rem",
        "components-header-spacing-large-search-border-width-top": "0.125rem",
        "components-header-spacing-large-search-border-width-right": "0.125rem",
        "components-header-spacing-large-search-border-width-bottom": "0.125rem",
        "components-header-spacing-large-search-border-width-left": "0.125rem",
        "components-product-card-spacing-border-width": "0.0625rem"
      },
      "maxHeight": {},
      "maxWidth": {
        "components-article-hero-spacing-large-header-max-width": "66.375rem",
        "components-content-list-spacing-large-item-image-max-width": "33.75rem",
        "components-content-list-spacing-small-item-image-max-width": "33.75rem"
      },
      "minHeight": {
        "components-dropdown-item-min-height": "3rem",
        "components-icon-min-height": "3rem",
        "components-tab-item-min-height": "3rem",
        "components-text-field-input-min-height": "3rem",
        "components-text-field-icon-min-height": "3rem"
      },
      "minWidth": {
        "components-content-list-spacing-small-item-image-min-width": "18.75rem",
        "components-icon-min-width": "3rem",
        "components-text-field-icon-min-width": "3rem"
      },
      "opacity": {
        "components-accessibility-bar-color-bg": 1,
        "components-accessibility-bar-color-text": 1,
        "components-accordion-color-bg": 1,
        "components-accordion-color-title": 1,
        "components-accordion-color-body": 1,
        "components-accordion-color-section-section-bg": 1,
        "components-accordion-color-section-section-title": 1,
        "components-accordion-color-section-section-body": 1,
        "components-accordion-color-item-item-bg": 1,
        "components-accordion-color-item-divider": 1,
        "components-accordion-color-item-icon-icon-default": 1,
        "components-accordion-color-item-icon-icon-hover": 1,
        "components-accordion-color-item-icon-icon-pressed": 1,
        "components-accordion-color-item-icon-icon-active": 1,
        "components-accordion-color-item-title-item-default": 1,
        "components-accordion-color-item-title-item-hover": 1,
        "components-accordion-color-item-title-item-pressed": 1,
        "components-accordion-color-item-title-item-active": 1,
        "components-accordion-color-content-bg": 1,
        "components-accordion-color-content-title": 1,
        "components-accordion-color-content-body": 1,
        "components-article-card-color-bg": 1,
        "components-article-card-color-border": 1,
        "components-article-card-color-content-bg": 1,
        "components-article-card-color-title": 1,
        "components-article-card-color-subtitle": 1,
        "components-article-card-color-copy": 1,
        "components-article-card-color-description": 1,
        "components-article-card-listing-color-default-bg": 1,
        "components-article-card-listing-color-default-title": 1,
        "components-article-card-listing-color-default-copy": 1,
        "components-article-hero-color-content-bg": 1,
        "components-article-hero-color-bg": 1,
        "components-article-hero-color-headline": 1,
        "components-article-hero-color-body": 1,
        "components-article-hero-color-detail-text": 1,
        "components-article-hero-color-divider": 1,
        "components-article-hero-color-icon": 1,
        "components-article-hero-color-play-icon": 1,
        "components-article-hero-color-selected-stroke": 1,
        "components-banner-color-bg-default": 1,
        "components-banner-color-icon-default": 1,
        "components-banner-color-title-default": 1,
        "components-banner-color-body-default": 1,
        "components-banner-color-icon-close-default": 1,
        "components-banner-color-bg-alert": 1,
        "components-banner-color-icon-alert": 1,
        "components-banner-color-title-alert": 1,
        "components-banner-color-body-alert": 1,
        "components-banner-color-icon-close-alert": 1,
        "components-breadcrumb-color-bg": 1,
        "components-breadcrumb-color-breadcrumb-link-default": 1,
        "components-breadcrumb-color-breadcrumb-link-hover": 1,
        "components-breadcrumb-color-breadcrumb-link-active": 1,
        "components-breadcrumb-color-icon-divider": 1,
        "components-bug-finder-prompt": 1,
        "components-button-color-filled-brand-default-bg": 1,
        "components-button-color-filled-brand-default-text": 1,
        "components-button-color-filled-brand-default-stroke": 1,
        "components-button-color-filled-brand-default-icon": 1,
        "components-button-color-filled-brand-hover-bg": 1,
        "components-button-color-filled-brand-hover-text": 1,
        "components-button-color-filled-brand-hover-stroke": 1,
        "components-button-color-filled-brand-hover-icon": 1,
        "components-button-color-filled-brand-pressed-bg": 1,
        "components-button-color-filled-brand-pressed-text": 1,
        "components-button-color-filled-brand-pressed-stroke": 1,
        "components-button-color-filled-brand-pressed-icon": 1,
        "components-button-color-filled-brand-disabled-bg": 0.03,
        "components-button-color-filled-brand-disabled-text": 0.02,
        "components-button-color-filled-brand-disabled-stroke": 0.03,
        "components-button-color-filled-brand-disabled-icon": 0.02,
        "components-button-color-filled-brand-focus-bg": 1,
        "components-button-color-filled-brand-focus-text": 1,
        "components-button-color-filled-brand-focus-stroke": 1,
        "components-button-color-filled-brand-focus-icon": 1,
        "components-button-color-filled-brand-focus-outline": 1,
        "components-button-color-filled-tonal-default-bg": 1,
        "components-button-color-filled-tonal-default-text": 1,
        "components-button-color-filled-tonal-default-stroke": 1,
        "components-button-color-filled-tonal-default-icon": 1,
        "components-button-color-filled-tonal-hover-bg": 1,
        "components-button-color-filled-tonal-hover-text": 1,
        "components-button-color-filled-tonal-hover-stroke": 1,
        "components-button-color-filled-tonal-hover-icon": 1,
        "components-button-color-filled-tonal-pressed-bg": 1,
        "components-button-color-filled-tonal-pressed-text": 1,
        "components-button-color-filled-tonal-pressed-stroke": 1,
        "components-button-color-filled-tonal-pressed-icon": 1,
        "components-button-color-filled-tonal-disabled-bg": 0.02,
        "components-button-color-filled-tonal-disabled-text": 0.03,
        "components-button-color-filled-tonal-disabled-stroke": 0.02,
        "components-button-color-filled-tonal-disabled-icon": 0.03,
        "components-button-color-filled-tonal-focus-bg": 1,
        "components-button-color-filled-tonal-focus-text": 1,
        "components-button-color-filled-tonal-focus-stroke": 1,
        "components-button-color-filled-tonal-focus-icon": 1,
        "components-button-color-filled-tonal-focus-outline": 1,
        "components-button-color-filled-white-default-bg": 1,
        "components-button-color-filled-white-default-text": 1,
        "components-button-color-filled-white-default-stroke": 1,
        "components-button-color-filled-white-default-icon": 1,
        "components-button-color-filled-white-hover-bg": 1,
        "components-button-color-filled-white-hover-text": 1,
        "components-button-color-filled-white-hover-stroke": 1,
        "components-button-color-filled-white-hover-icon": 1,
        "components-button-color-filled-white-pressed-bg": 1,
        "components-button-color-filled-white-pressed-text": 1,
        "components-button-color-filled-white-pressed-stroke": 1,
        "components-button-color-filled-white-pressed-icon": 1,
        "components-button-color-filled-white-disabled-bg": 0.02,
        "components-button-color-filled-white-disabled-text": null,
        "components-button-color-filled-white-disabled-stroke": 0.02,
        "components-button-color-filled-white-disabled-icon": null,
        "components-button-color-filled-white-focus-bg": 1,
        "components-button-color-filled-white-focus-text": 1,
        "components-button-color-filled-white-focus-stroke": 1,
        "components-button-color-filled-white-focus-icon": 1,
        "components-button-color-filled-white-focus-outline": 1,
        "components-button-color-outline-brand-default-bg": 0.03,
        "components-button-color-outline-brand-default-text": 1,
        "components-button-color-outline-brand-default-stroke": 1,
        "components-button-color-outline-brand-default-icon": 1,
        "components-button-color-outline-brand-hover-bg": 1,
        "components-button-color-outline-brand-hover-text": 1,
        "components-button-color-outline-brand-hover-stroke": 1,
        "components-button-color-outline-brand-hover-icon": 1,
        "components-button-color-outline-brand-pressed-bg": 1,
        "components-button-color-outline-brand-pressed-text": 1,
        "components-button-color-outline-brand-pressed-stroke": 1,
        "components-button-color-outline-brand-pressed-icon": 1,
        "components-button-color-outline-brand-disabled-bg": 0.03,
        "components-button-color-outline-brand-disabled-text": 0.03,
        "components-button-color-outline-brand-disabled-stroke": 0.03,
        "components-button-color-outline-brand-disabled-icon": 0.03,
        "components-button-color-outline-brand-focus-bg": 1,
        "components-button-color-outline-brand-focus-text": 1,
        "components-button-color-outline-brand-focus-stroke": 1,
        "components-button-color-outline-brand-focus-icon": 1,
        "components-button-color-outline-brand-focus-outline": 1,
        "components-button-color-outline-tonal-default-bg": 1,
        "components-button-color-outline-tonal-default-text": 1,
        "components-button-color-outline-tonal-default-stroke": 1,
        "components-button-color-outline-tonal-default-icon": 1,
        "components-button-color-outline-tonal-hover-bg": 1,
        "components-button-color-outline-tonal-hover-text": 1,
        "components-button-color-outline-tonal-hover-stroke": 1,
        "components-button-color-outline-tonal-hover-icon": 1,
        "components-button-color-outline-tonal-pressed-bg": 1,
        "components-button-color-outline-tonal-pressed-text": 1,
        "components-button-color-outline-tonal-pressed-stroke": 1,
        "components-button-color-outline-tonal-pressed-icon": 1,
        "components-button-color-outline-tonal-disabled-bg": 0.02,
        "components-button-color-outline-tonal-disabled-text": 0.02,
        "components-button-color-outline-tonal-disabled-stroke": 0.02,
        "components-button-color-outline-tonal-disabled-icon": 0.02,
        "components-button-color-outline-tonal-focus-bg": 1,
        "components-button-color-outline-tonal-focus-text": 1,
        "components-button-color-outline-tonal-focus-stroke": 1,
        "components-button-color-outline-tonal-focus-icon": 1,
        "components-button-color-outline-tonal-focus-outline": 1,
        "components-button-color-outline-white-default-bg": 0.02,
        "components-button-color-outline-white-default-text": 1,
        "components-button-color-outline-white-default-stroke": 1,
        "components-button-color-outline-white-default-icon": 1,
        "components-button-color-outline-white-hover-bg": 1,
        "components-button-color-outline-white-hover-text": 1,
        "components-button-color-outline-white-hover-stroke": 1,
        "components-button-color-outline-white-hover-icon": 1,
        "components-button-color-outline-white-pressed-bg": 1,
        "components-button-color-outline-white-pressed-text": 1,
        "components-button-color-outline-white-pressed-stroke": 1,
        "components-button-color-outline-white-pressed-icon": 1,
        "components-button-color-outline-white-disabled-bg": 0.02,
        "components-button-color-outline-white-disabled-text": 0.02,
        "components-button-color-outline-white-disabled-stroke": 0.02,
        "components-button-color-outline-white-disabled-icon": 0.02,
        "components-button-color-outline-white-focus-bg": 0.02,
        "components-button-color-outline-white-focus-text": 1,
        "components-button-color-outline-white-focus-stroke": 1,
        "components-button-color-outline-white-focus-icon": 1,
        "components-button-color-outline-white-focus-outline": 1,
        "components-button-color-text-brand-default-bg": 0.02,
        "components-button-color-text-brand-default-text": 1,
        "components-button-color-text-brand-default-stroke": 1,
        "components-button-color-text-brand-default-icon": 1,
        "components-button-color-text-brand-hover-bg": 0.02,
        "components-button-color-text-brand-hover-bg-icon-only": 1,
        "components-button-color-text-brand-hover-text": 1,
        "components-button-color-text-brand-hover-stroke": 1,
        "components-button-color-text-brand-hover-icon": 1,
        "components-button-color-text-brand-pressed-bg": 0.02,
        "components-button-color-text-brand-pressed-bg-icon-only": 1,
        "components-button-color-text-brand-pressed-text": 1,
        "components-button-color-text-brand-pressed-stroke": 1,
        "components-button-color-text-brand-pressed-icon": 1,
        "components-button-color-text-brand-disabled-bg": 0.02,
        "components-button-color-text-brand-disabled-text": 0.03,
        "components-button-color-text-brand-disabled-stroke": 0.03,
        "components-button-color-text-brand-disabled-icon": 0.03,
        "components-button-color-text-brand-focus-bg": 1,
        "components-button-color-text-brand-focus-text": 1,
        "components-button-color-text-brand-focus-stroke": 1,
        "components-button-color-text-brand-focus-icon": 1,
        "components-button-color-text-brand-focus-outline": 1,
        "components-button-color-text-tonal-default-bg": 0.02,
        "components-button-color-text-tonal-default-text": 1,
        "components-button-color-text-tonal-default-stroke": 1,
        "components-button-color-text-tonal-default-icon": 1,
        "components-button-color-text-tonal-hover-bg": 0.02,
        "components-button-color-text-tonal-hover-bg-icon-only": 1,
        "components-button-color-text-tonal-hover-text": 1,
        "components-button-color-text-tonal-hover-stroke": 1,
        "components-button-color-text-tonal-hover-icon": 1,
        "components-button-color-text-tonal-pressed-bg": 0.02,
        "components-button-color-text-tonal-pressed-bg-icon-only": 1,
        "components-button-color-text-tonal-pressed-text": 1,
        "components-button-color-text-tonal-pressed-stroke": 1,
        "components-button-color-text-tonal-pressed-icon": 1,
        "components-button-color-text-tonal-disabled-bg": 0.02,
        "components-button-color-text-tonal-disabled-text": 0.03,
        "components-button-color-text-tonal-disabled-stroke": 0.03,
        "components-button-color-text-tonal-disabled-icon": 0.03,
        "components-button-color-text-tonal-focus-bg": 1,
        "components-button-color-text-tonal-focus-text": 1,
        "components-button-color-text-tonal-focus-stroke": 1,
        "components-button-color-text-tonal-focus-icon": 1,
        "components-button-color-text-tonal-focus-outline": 1,
        "components-button-color-text-white-default-bg": 0.02,
        "components-button-color-text-white-default-text": 1,
        "components-button-color-text-white-default-stroke": 1,
        "components-button-color-text-white-default-icon": 1,
        "components-button-color-text-white-hover-bg": 0.02,
        "components-button-color-text-white-hover-bg-icon-only": 1,
        "components-button-color-text-white-hover-text": 1,
        "components-button-color-text-white-hover-stroke": 1,
        "components-button-color-text-white-hover-icon": 1,
        "components-button-color-text-white-pressed-bg": 0.02,
        "components-button-color-text-white-pressed-bg-icon-only": 1,
        "components-button-color-text-white-pressed-text": 1,
        "components-button-color-text-white-pressed-stroke": 1,
        "components-button-color-text-white-pressed-icon": 1,
        "components-button-color-text-white-disabled-bg": 0.02,
        "components-button-color-text-white-disabled-text": 0.01,
        "components-button-color-text-white-disabled-stroke": 0.01,
        "components-button-color-text-white-disabled-icon": 0.01,
        "components-button-color-text-white-focus-bg": 1,
        "components-button-color-text-white-focus-text": 1,
        "components-button-color-text-white-focus-stroke": 1,
        "components-button-color-text-white-focus-icon": 1,
        "components-button-color-text-white-focus-outline": 1,
        "components-button-group-color-border": 1,
        "components-button-group-color-button-bg-default": 0.02,
        "components-button-group-color-button-fg-default": 1,
        "components-button-group-color-button-bg-hover": 1,
        "components-button-group-color-button-fg-hover": 1,
        "components-button-group-color-button-bg-active": 1,
        "components-button-group-color-button-fg-active": 1,
        "components-button-group-color-overlay-left-gradient-fill-1": 1,
        "components-button-group-color-overlay-left-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-1": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-3": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-4": 0.02,
        "components-button-group-color-stroke-hover-gradient-fill-1": 1,
        "components-button-group-color-stroke-hover-gradient-fill-2": 1,
        "components-button-group-color-stroke-hover-gradient-fill-3": 1,
        "components-button-group-color-stroke-hover-gradient-fill-4": 1,
        "components-button-group-color-stroke-active-gradient-fill-1": 1,
        "components-button-group-color-stroke-active-gradient-fill-2": 1,
        "components-button-group-color-stroke-active-gradient-fill-3": 1,
        "components-button-group-color-stroke-active-gradient-fill-4": 1,
        "components-category-card-color-bg": 1,
        "components-category-card-color-content-bg": 1,
        "components-category-card-color-category-bg": 1,
        "components-category-card-color-category-bg-2": 1,
        "components-category-card-color-category-bg-3": 1,
        "components-category-card-color-category-bg-4": 1,
        "components-category-card-color-category-bg-5": 1,
        "components-category-card-color-category-bg-6": 1,
        "components-category-card-color-title": 1,
        "components-category-card-color-copy": 1,
        "components-category-card-color-stroke": 1,
        "components-category-card-color-stroke-hover": 1,
        "components-category-card-listing-color-bg": 1,
        "components-category-card-listing-color-title": 1,
        "components-category-card-listing-color-copy": 1,
        "components-card-search-result-color-bg": 1,
        "components-card-search-result-color-label": 1,
        "components-card-search-result-color-title": 1,
        "components-card-search-result-color-body": 1,
        "components-card-search-result-color-divider-line": 1,
        "components-contact-us-promo-color-light-bg": 1,
        "components-contact-us-promo-color-light-title": 1,
        "components-contact-us-promo-color-light-body": 1,
        "components-contact-us-promo-color-default-bg": 1,
        "components-contact-us-promo-color-default-title": 1,
        "components-contact-us-promo-color-default-body": 1,
        "components-contact-us-promo-color-brand-bg": 1,
        "components-contact-us-promo-color-brand-title": 1,
        "components-contact-us-promo-color-brand-body": 1,
        "components-content-list-color-bg": 1,
        "components-content-list-color-title": 1,
        "components-content-list-color-body": 1,
        "components-content-list-color-icon": 1,
        "components-cookies-colors-bg": 1,
        "components-cookies-colors-title": 1,
        "components-cookies-colors-body": 1,
        "components-cookies-colors-icon": 1,
        "components-dropdown-bg": 1,
        "components-dropdown-border-default": 1,
        "components-dropdown-border-hover": 1,
        "components-dropdown-border-focused": 1,
        "components-dropdown-border-destructive": 1,
        "components-dropdown-label-text-default": 1,
        "components-dropdown-item-text-default": 1,
        "components-dropdown-item-bg-default": 1,
        "components-dropdown-item-bg-hover": 1,
        "components-dropdown-input-field-default": 1,
        "components-dropdown-input-field-focused": 1,
        "components-dropdown-input-label-focused": 1,
        "components-dropdown-input-field-destructive": 1,
        "components-external-link-card-bg": 1,
        "components-external-link-card-card-bg": 1,
        "components-external-link-card-card-title": 1,
        "components-external-link-card-card-subtitle": 1,
        "components-external-link-card-card-eyebrow": 1,
        "components-fab-color-border-default": 1,
        "components-footer-brand-color-bg": 1,
        "components-footer-brand-color-logo": 1,
        "components-footer-brand-color-title-text": 1,
        "components-footer-brand-color-description-text": 1,
        "components-footer-brand-color-footer-menu-expanded": 1,
        "components-footer-brand-color-link-default": 1,
        "components-footer-brand-color-link-hover": 1,
        "components-footer-brand-color-link-pressed": 1,
        "components-footer-brand-color-category-link-default": 1,
        "components-footer-brand-color-category-link-hover": 1,
        "components-footer-brand-color-category-link-pressed": 1,
        "components-footer-brand-color-subcategory-link-default": 1,
        "components-footer-brand-color-subcategory-link-hover": 1,
        "components-footer-brand-color-subcategory-link-pressed": 1,
        "components-footer-brand-color-icon-icon-default": 1,
        "components-footer-brand-color-icon-icon-pressed": 1,
        "components-footer-brand-color-social-icon-default": 1,
        "components-footer-brand-color-social-icon-hover": 1,
        "components-footer-brand-color-social-icon-pressed": 1,
        "components-footer-brand-spacing-category-link-hover": 1,
        "components-footer-global-color-bg": 1,
        "components-footer-global-color-title-text": 1,
        "components-footer-global-color-disclaimer-text": 1,
        "components-footer-global-color-link-default": 1,
        "components-footer-global-color-link-hover": 1,
        "components-footer-global-color-link-pressed": 1,
        "components-generic-card-color-bg": 1,
        "components-generic-card-color-content-bg": 1,
        "components-generic-card-color-title": 1,
        "components-generic-card-color-subtitle": 1,
        "components-generic-card-color-copy": 1,
        "components-global-search-color-omnibox-list-bg": 1,
        "components-global-search-color-omnibox-divider": 1,
        "components-global-search-color-omnibox-item-bg-default": 1,
        "components-global-search-color-omnibox-item-bg-hover": 1,
        "components-global-search-color-omnibox-item-text-default": 1,
        "components-global-search-color-omnibox-item-text-hover": 1,
        "components-global-search-color-omnibox-label-text-default": 1,
        "components-global-search-color-omnibox-category-text-default": 1,
        "components-generic-card-listing-color-bg": 1,
        "components-generic-card-listing-color-title": 1,
        "components-generic-card-listing-color-copy": 1,
        "components-header-color-bg": 1,
        "components-header-color-bottom-stroke": 1,
        "components-header-color-search-search-border-default": 1,
        "components-header-color-search-search-text": 1,
        "components-header-color-search-search-icon": 1,
        "components-header-color-search-search-bar": 1,
        "components-header-color-mobile-mobile-expanded-bg": 1,
        "components-header-color-mobile-pressed-bg": 1,
        "components-header-color-language-selector-language": 1,
        "components-header-color-language-selector-country": 1,
        "components-header-color-language-selector-country-hover-bg": 1,
        "components-header-color-language-selector-megamenu-language": 1,
        "components-header-color-language-selector-megamenu-country": 1,
        "components-header-color-language-selector-megamenu-country-hover": 1,
        "components-header-color-language-selector-bg": 1,
        "components-header-color-language-selector-stroke": 1,
        "components-header-color-mega-menu-bg": 1,
        "components-header-color-mega-menu-featured-bg": 1,
        "components-header-color-mega-menu-featured-title": 1,
        "components-header-color-mega-menu-category-title": 1,
        "components-header-color-mega-menu-bg-accent": 1,
        "components-header-color-icon-icon-default": 1,
        "components-header-color-icon-icon-hover": 1,
        "components-header-color-icon-icon-pressed": 1,
        "components-header-color-links-nav-main-link-default": 1,
        "components-header-color-links-nav-main-link-hover": 1,
        "components-header-color-links-nav-main-link-pressed": 1,
        "components-header-color-links-nav-main-link-active": 1,
        "components-header-color-links-nav-main-active-bar": 1,
        "components-header-color-links-nav-main-bg-color": 1,
        "components-header-color-links-nav-category-link-category-default": 1,
        "components-header-color-links-nav-category-link-category-hover": 1,
        "components-header-color-links-nav-category-link-category-pressed": 1,
        "components-header-color-links-nav-category-link-category-active": 1,
        "components-header-color-links-nav-menu-link-link-default": 1,
        "components-header-color-links-nav-menu-link-link-hover": 1,
        "components-header-color-links-nav-menu-link-link-pressed": 1,
        "components-header-color-links-nav-menu-link-link-active": 1,
        "components-header-color-links-nav-menu-link-bg": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-default": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-active": 1,
        "components-hero-homepage-color-bg": 1,
        "components-hero-homepage-color-container-bg": 1,
        "components-hero-homepage-color-eyebrow": 1,
        "components-hero-homepage-color-header": 1,
        "components-hero-homepage-color-sub-header": 1,
        "components-hero-homepage-color-body": 1,
        "components-hero-homepage-color-play-pause": 1,
        "components-hero-internal-color-default-title": 1,
        "components-hero-internal-color-default-body": 1,
        "components-hero-internal-color-default-bg": 1,
        "components-hero-internal-color-accent-bg": 1,
        "components-hero-internal-color-with-image-bg": 1,
        "components-hero-internal-color-default-bg-2": 1,
        "components-hero-internal-color-with-image-title": 1,
        "components-hero-internal-color-with-image-body": 1,
        "components-heading-page-title-color-default-bg": 1,
        "components-heading-page-title-color-default-title": 1,
        "components-heading-page-title-color-default-body": 1,
        "components-heading-page-title-color-light-bg": 1,
        "components-heading-page-title-color-light-title": 1,
        "components-heading-page-title-color-light-body": 1,
        "components-heading-page-title-color-color-bg": 1,
        "components-heading-page-title-color-color-title": 1,
        "components-heading-page-title-color-color-body": 1,
        "components-hyperlink-color-dark-default": 1,
        "components-hyperlink-color-dark-hover": 1,
        "components-hyperlink-color-dark-visited": 1,
        "components-hyperlink-color-light-default": 1,
        "components-hyperlink-color-light-hover": 1,
        "components-hyperlink-color-light-visited": 1,
        "components-icon-default": 1,
        "components-icon-focused": 1,
        "components-icon-card-color-bg": 1,
        "components-icon-card-color-icon-bg": 1,
        "components-icon-card-color-icon": 1,
        "components-icon-card-color-title": 1,
        "components-icon-card-color-subtitle": 1,
        "components-icon-card-color-description": 1,
        "components-icon-card-listing-color-bg": 1,
        "components-icon-card-listing-color-inner-bg": 1,
        "components-icon-card-listing-color-brand-bg": 1,
        "components-icon-card-listing-color-title": 1,
        "components-icon-card-listing-color-copy": 1,
        "components-icon-card-listing-color-inner-legal-text": 1,
        "components-jumplink-color-bg-default": 1,
        "components-jumplink-color-link-text-default": 1,
        "components-jumplink-color-link-text-hover": 1,
        "components-jumplink-color-link-border-hover": 1,
        "components-jumplink-color-link-text-active": 1,
        "components-jumplink-color-link-border-active": 1,
        "components-jumplink-color-title-text": 1,
        "components-jumplink-color-description-text": 1,
        "components-layout-bg-default": 1,
        "components-lightbox-color-overlay-bg": 0.02,
        "components-lightbox-color-title": 1,
        "components-lightbox-color-body": 1,
        "components-label-color-on-color-label-label-default": 1,
        "components-label-color-on-color-label-label-hover": 1,
        "components-label-color-on-color-label-label-pressed": 1,
        "components-label-color-on-color-background-bg-default": 1,
        "components-label-color-on-color-background-bg-hover": 1,
        "components-label-color-on-color-background-bg-pressed": 1,
        "components-label-color-on-color-stroke-hover": 1,
        "components-label-color-on-color-stroke-pressed": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-4": 1,
        "components-label-color-on-white-label-label-default": 1,
        "components-label-color-on-white-label-label-hover": 1,
        "components-label-color-on-white-label-label-pressed": 1,
        "components-label-color-on-white-background-bg-default": 0.02,
        "components-label-color-on-white-background-bg-hover": 1,
        "components-label-color-on-white-background-bg-pressed": 1,
        "components-label-color-on-white-stroke-hover": 1,
        "components-label-color-on-white-stroke-pressed": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-4": 1,
        "components-legal-disclaimer-color-default-bg": 1,
        "components-legal-disclaimer-color-default-title": 1,
        "components-legal-disclaimer-color-default-body": 1,
        "components-legal-disclaimer-color-light-bg": 1,
        "components-legal-disclaimer-color-light-title": 1,
        "components-legal-disclaimer-color-light-body": 1,
        "components-legal-disclaimer-color-brand-bg": 1,
        "components-legal-disclaimer-color-brand-title": 1,
        "components-legal-disclaimer-color-brand-body": 1,
        "components-media-video-thumbnail-overlay-bg": null,
        "components-media-caption-color-bg": 1,
        "components-media-caption-color-text": 1,
        "components-media-gallery-color-bg": 1,
        "components-media-gallery-color-title": 1,
        "components-media-gallery-color-body": 1,
        "components-pagination-on-white-accent-default": 1,
        "components-pagination-on-white-accent-scroll": 1,
        "components-pagination-on-white-disabled": 1,
        "components-pagination-on-white-bg": 1,
        "components-pagination-on-white-stroke": 1,
        "components-pagination-on-white-bg-active": 1,
        "components-pagination-on-white-bg-hover": 1,
        "components-pagination-on-white-text-default": 1,
        "components-pagination-on-white-text-hover": 1,
        "components-pagination-on-white-text-active": 1,
        "components-pagination-on-color-accent-default": 1,
        "components-pagination-on-color-accent-scroll": 1,
        "components-pagination-on-color-disabled": 1,
        "components-pagination-on-color-bg": 1,
        "components-pagination-on-color-stroke": 0.02,
        "components-pagination-on-color-bg-active": 1,
        "components-pagination-on-color-bg-hover": 1,
        "components-pagination-on-color-text-default": 1,
        "components-pagination-on-color-text-hover": 1,
        "components-pagination-on-color-text-active": 1,
        "components-pdf-link-card-color-bg": 1,
        "components-pdf-link-card-color-card-bg": 1,
        "components-pdf-link-card-color-card-title": 1,
        "components-pdf-link-card-color-card-subtitle": 1,
        "components-pdf-link-card-color-card-body": 1,
        "components-product-card-color-bg": 1,
        "components-product-card-color-content-bg": 1,
        "components-product-card-color-border": 1,
        "components-product-card-color-title": 1,
        "components-product-card-color-description": 1,
        "components-product-card-color-star-rating": 1,
        "components-product-card-color-badge-bg": 1,
        "components-product-card-color-badge-text": 1,
        "components-product-card-listing-color-bg": 1,
        "components-product-card-listing-color-title": 1,
        "components-product-card-listing-color-copy": 1,
        "components-product-card-listing-color-legal-text": 1,
        "components-product-information-color-bg": 1,
        "components-product-information-color-content-bg": 1,
        "components-product-information-color-headline": 1,
        "components-product-information-color-body": 1,
        "components-product-information-color-detail-text": 1,
        "components-product-information-color-divider": 1,
        "components-product-information-color-icon": 1,
        "components-product-information-color-play-icon": 1,
        "components-product-information-color-selected-stroke": 1,
        "components-product-results-list-with-search-color-bg": 1,
        "components-promo-color-default-bg": 1,
        "components-promo-color-default-image-bg": 1,
        "components-promo-color-default-content-bg": 1,
        "components-promo-color-default-title": 1,
        "components-promo-color-default-body": 1,
        "components-promo-color-default-category": 1,
        "components-promo-color-light-bg": 1,
        "components-promo-color-light-image-bg": 1,
        "components-promo-color-light-content-bg": 1,
        "components-promo-color-light-title": 1,
        "components-promo-color-light-body": 1,
        "components-promo-color-light-category": 1,
        "components-promo-color-brand-bg": 1,
        "components-promo-color-brand-image-bg": 1,
        "components-promo-color-brand-content-bg": 1,
        "components-promo-color-brand-title": 1,
        "components-promo-color-brand-body": 1,
        "components-promo-color-brand-category": 1,
        "components-pull-quote-color-bg": 1,
        "components-pull-quote-color-stroke": 1,
        "components-pull-quote-color-quote-bg": 1,
        "components-pull-quote-color-quote-title": 1,
        "components-pull-quote-color-quote-by-text": 1,
        "components-pull-quote-color-quote-detail": 1,
        "components-pull-quote-color-quote-highlight": 1,
        "components-promo-alternate-color-default-bg": 1,
        "components-promo-alternate-color-default-image-bg": 1,
        "components-promo-alternate-color-default-content-bg": 1,
        "components-promo-alternate-color-default-title": 1,
        "components-promo-alternate-color-default-body": 1,
        "components-promo-alternate-color-default-category": 1,
        "components-promo-alternate-color-light-bg": 1,
        "components-promo-alternate-color-light-image-bg": 1,
        "components-promo-alternate-color-light-content-bg": 1,
        "components-promo-alternate-color-light-title": 1,
        "components-promo-alternate-color-light-body": 1,
        "components-promo-alternate-color-light-category": 1,
        "components-promo-alternate-color-brand-bg": 1,
        "components-promo-alternate-color-brand-image-bg": 1,
        "components-promo-alternate-color-brand-content-bg": 1,
        "components-promo-alternate-color-brand-title": 1,
        "components-promo-alternate-color-brand-body": 1,
        "components-promo-alternate-color-brand-category": 1,
        "components-share-list-color-title": 1,
        "components-share-list-color-bg": 1,
        "components-share-list-color-link-default": 1,
        "components-share-list-color-link-hover": 1,
        "components-share-list-color-bg-link-hover": 1,
        "components-share-list-color-close-icon": 1,
        "components-share-list-color-facebook-icon": 1,
        "components-share-list-color-pinterest-icon": 1,
        "components-share-list-color-twitter-icon": 1,
        "components-share-list-color-basic-icon-default": 1,
        "components-share-list-color-icon-hover": 1,
        "components-share-list-color-border": 1,
        "components-site-search-bg": 1,
        "components-site-search-results_text": 1,
        "components-site-search-filter-filter-selected-bg": 1,
        "components-site-search-facet-group-label-bottom-line": 1,
        "components-site-search-facet-group-label-text": 1,
        "components-site-search-facet-group-label-bg": 1,
        "components-site-search-facet-bg": 1,
        "components-site-search-facet-stroke": 1,
        "components-site-search-refine-bg": 1,
        "components-site-search-refine-right-divider": 1,
        "components-site-search-refine-title": 1,
        "components-site-search-hero-search-bg": 1,
        "components-site-search-hero-search-accent-bg": 1,
        "components-site-search-hero-search-title": 1,
        "components-stats-stat-text": 1,
        "components-stats-stat-text-on-light": 1,
        "components-stats-supporting-text": 1,
        "components-stats-bg-default": 1,
        "components-stats-bg-inner": 1,
        "components-stats-bg-section-inner": 1,
        "components-stats-title": 1,
        "components-stats-copy": 1,
        "components-supporting-text-default": 1,
        "components-supporting-text-destructive": 1,
        "components-tab-bg": 1,
        "components-tab-border-default": 1,
        "components-tab-border-hover": 1,
        "components-tab-border-focused": 1,
        "components-tab-border-destructive": 1,
        "components-tab-label-text-default": 1,
        "components-tab-item-text-default": 1,
        "components-tab-item-bg-default": 1,
        "components-tab-item-bg-hover": 1,
        "components-tab-input-field-default": 1,
        "components-tab-input-field-focused": 1,
        "components-tab-input-label-focused": 1,
        "components-tab-input-field-destructive": 1,
        "components-tab-bg-2": 1,
        "components-tag-color-bg": 1,
        "components-tag-color-text": 1,
        "components-tag-color-stroke": 1,
        "components-tag-color-bg-hover": 1,
        "components-tag-color-text-hover": 1,
        "components-tag-color-stroke-hover": 1,
        "components-text-field-bg": 1,
        "components-text-field-bg-focused": 1,
        "components-text-field-border-default": 1,
        "components-text-field-border-hover": 1,
        "components-text-field-border-focused": 1,
        "components-text-field-border-destructive": 1,
        "components-text-field-input-field-default": 1,
        "components-text-field-input-field-focused": 1,
        "components-text-field-input-label-focused": 1,
        "components-text-field-input-field-destructive": 1,
        "components-text-field-icon-default": 1,
        "components-text-field-icon-destructive": 1,
        "components-text-field-supporting-text-default": 1,
        "components-text-field-supporting-text-destructive": 1,
        "components-tip-color-bg": 1,
        "components-tip-color-title": 1,
        "components-tip-color-body": 1,
        "components-tip-color-stroke": 1,
        "components-video-color-bg": 1,
        "themes-general-surface-color-light-bg": 1,
        "themes-general-surface-color-light-title": 1,
        "themes-general-surface-color-light-body": 1,
        "themes-general-surface-color-light-decor-line-break": 1,
        "themes-general-surface-color-light-rule-line": 1,
        "themes-general-surface-color-dark-bg": 1,
        "themes-general-surface-color-dark-title": 1,
        "themes-general-surface-color-dark-body": 1,
        "themes-general-surface-color-dark-decor-line-break": 1,
        "themes-general-card-color-dark-title": 1,
        "themes-general-card-color-dark-subtitle": 1,
        "themes-general-card-color-dark-body": 1,
        "themes-general-card-color-light-title": 1,
        "themes-general-card-color-light-subtitle": 1,
        "themes-general-card-color-light-body": 1,
        "themes-shadow-color": 1,
        "themes-shadow-share-list-color-fill": 0,
        "themes-shadow-header-menu-color-fill": 0,
        "themes-gradient-loader-gradient-fill-1": 1,
        "themes-gradient-loader-gradient-fill-2": 0.01,
        "themes-color-background-white": 1,
        "themes-color-background-light": 1,
        "themes-color-background-dark": 1,
        "themes-color-background-black": 1,
        "themes-color-background-brand1": 1,
        "themes-color-background-brand2": 1,
        "themes-color-background-accent1": 1,
        "themes-color-background-accent2": 1,
        "themes-color-background-body": 1,
        "themes-color-stroke-white": 1,
        "themes-color-stroke-light": 1,
        "themes-color-stroke-dark": 1,
        "themes-color-stroke-black": 1,
        "themes-color-stroke-brand1": 1,
        "themes-color-stroke-brand2": 1,
        "themes-color-stroke-accent1": 1,
        "themes-color-stroke-accent2": 1,
        "themes-color-text-white": 1,
        "themes-color-text-light": 1,
        "themes-color-text-dark": 1,
        "themes-color-text-black": 1,
        "themes-color-text-brand1": 1,
        "themes-color-text-brand2": 1,
        "themes-color-text-accent1": 1,
        "themes-color-text-accent2": 1,
        "colors-grayscale-100": 1,
        "colors-grayscale-200": 1,
        "colors-grayscale-300": 1,
        "colors-grayscale-400": 1,
        "colors-grayscale-500": 1,
        "colors-grayscale-600": 1,
        "colors-grayscale-700": 1,
        "colors-brand-1-100": 1,
        "colors-brand-1-200": 1,
        "colors-brand-1-300": 1,
        "colors-brand-1-400": 1,
        "colors-brand-1-500": 1,
        "colors-brand-1-600": 1,
        "colors-brand-1-700": 1,
        "colors-brand-2-100": 1,
        "colors-brand-2-200": 1,
        "colors-brand-2-300": 1,
        "colors-brand-2-400": 1,
        "colors-brand-2-500": 1,
        "colors-brand-2-600": 1,
        "colors-brand-2-700": 1,
        "colors-accent-1-100": 1,
        "colors-accent-1-200": 1,
        "colors-accent-1-300": 1,
        "colors-accent-1-400": 1,
        "colors-accent-1-500": 1,
        "colors-accent-1-600": 1,
        "colors-accent-1-700": 1,
        "colors-accent-2-100": 1,
        "colors-accent-2-200": 1,
        "colors-accent-2-300": 1,
        "colors-accent-2-400": 1,
        "colors-accent-2-500": 1,
        "colors-accent-2-600": 1,
        "colors-accent-2-700": 1,
        "colors-accent-3-100": 1,
        "colors-accent-3-200": 1,
        "colors-accent-3-300": 1,
        "colors-accent-3-400": 1,
        "colors-accent-3-500": 1,
        "colors-accent-3-600": 1,
        "colors-accent-3-700": 1,
        "colors-accent-4-400_storage": 1,
        "colors-accessible-accent-1-100": 1,
        "colors-accessible-accent-1-200": 1,
        "colors-accessible-accent-1-300": 1,
        "colors-accessible-accent-1-400": 1,
        "colors-accessible-accent-1-500": 1,
        "colors-accessible-accent-1-600": 1,
        "colors-accessible-accent-1-700": 1,
        "colors-accessible-accent-2-100": 1,
        "colors-accessible-accent-2-200": 1,
        "colors-accessible-accent-2-300": 1,
        "colors-accessible-accent-2-400": 1,
        "colors-accessible-accent-2-500": 1,
        "colors-accessible-accent-2-600": 1,
        "colors-accessible-accent-2-700": 1,
        "colors-accessible-brand-2-100": 1,
        "colors-accessible-brand-2-200": 1,
        "colors-accessible-brand-2-300": 1,
        "colors-accessible-brand-2-400": 1,
        "colors-accessible-brand-2-500": 1,
        "colors-accessible-brand-2-600": 1,
        "colors-accessible-brand-2-700": 1
      }
    },
    "Lysoform": {
      "colors": {
        "components-accessibility-bar-color-bg": "#0f0e19",
        "components-accessibility-bar-color-text": "#ffffff",
        "components-accordion-color-bg": "#ffffff",
        "components-accordion-color-title": "#121111",
        "components-accordion-color-body": "#373432",
        "components-accordion-color-section-section-bg": "#ffffff",
        "components-accordion-color-section-section-title": "#121111",
        "components-accordion-color-section-section-body": "#373432",
        "components-accordion-color-item-item-bg": "#ffffff",
        "components-accordion-color-item-divider": "#eae8e8",
        "components-accordion-color-item-icon-icon-default": "#22204c",
        "components-accordion-color-item-icon-icon-hover": "#ffffff",
        "components-accordion-color-item-icon-icon-pressed": "#ffffff",
        "components-accordion-color-item-icon-icon-active": "#ffffff",
        "components-accordion-color-item-title-item-default": "#22204c",
        "components-accordion-color-item-title-item-hover": "#ffffff",
        "components-accordion-color-item-title-item-pressed": "#ffffff",
        "components-accordion-color-item-title-item-active": "#ffffff",
        "components-accordion-color-content-bg": "#ffffff",
        "components-accordion-color-content-title": "#121111",
        "components-accordion-color-content-body": "#373432",
        "components-article-card-color-bg": "#fdfdfc",
        "components-article-card-color-border": "rgba(171, 168, 166, 0.00)",
        "components-article-card-color-content-bg": "#fdfdfc",
        "components-article-card-color-title": "#121111",
        "components-article-card-color-subtitle": "#373432",
        "components-article-card-color-copy": "#373432",
        "components-article-card-color-description": "#373432",
        "components-article-card-listing-color-default-bg": "#2a2569",
        "components-article-card-listing-color-default-title": "#ffffff",
        "components-article-card-listing-color-default-copy": "#fdfdfc",
        "components-article-hero-color-content-bg": "#ffffff",
        "components-article-hero-color-bg": "#ffffff",
        "components-article-hero-color-headline": "#121111",
        "components-article-hero-color-body": "#373432",
        "components-article-hero-color-detail-text": "#373432",
        "components-article-hero-color-divider": "#aba8a6",
        "components-article-hero-color-icon": "#2a2569",
        "components-article-hero-color-play-icon": "#ffffff",
        "components-article-hero-color-selected-stroke": "#2a2569",
        "components-banner-color-bg-default": "#78be20",
        "components-banner-color-icon-default": "#2a2569",
        "components-banner-color-title-default": "#121111",
        "components-banner-color-body-default": "#121111",
        "components-banner-color-icon-close-default": "#121111",
        "components-banner-color-bg-alert": "#b32b30",
        "components-banner-color-icon-alert": "#ffffff",
        "components-banner-color-title-alert": "#ffffff",
        "components-banner-color-body-alert": "#ffffff",
        "components-banner-color-icon-close-alert": "#ffffff",
        "components-breadcrumb-color-bg": "#ffffff",
        "components-breadcrumb-color-breadcrumb-link-default": "#807a77",
        "components-breadcrumb-color-breadcrumb-link-hover": "#5b5654",
        "components-breadcrumb-color-breadcrumb-link-active": "#373432",
        "components-breadcrumb-color-icon-divider": "#aba8a6",
        "components-bug-finder-prompt": "#ffffff",
        "components-button-color-filled-brand-default-bg": "#2a2569",
        "components-button-color-filled-brand-default-text": "#ffffff",
        "components-button-color-filled-brand-default-stroke": "#2a2569",
        "components-button-color-filled-brand-default-icon": "#ffffff",
        "components-button-color-filled-brand-hover-bg": "#22204c",
        "components-button-color-filled-brand-hover-text": "#ffffff",
        "components-button-color-filled-brand-hover-stroke": "#22204c",
        "components-button-color-filled-brand-hover-icon": "#ffffff",
        "components-button-color-filled-brand-pressed-bg": "#0f0e19",
        "components-button-color-filled-brand-pressed-text": "#ffffff",
        "components-button-color-filled-brand-pressed-stroke": "#0f0e19",
        "components-button-color-filled-brand-pressed-icon": "#ffffff",
        "components-button-color-filled-brand-disabled-bg": "rgba(0, 83, 180, 0.38)",
        "components-button-color-filled-brand-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-disabled-stroke": "rgba(0, 83, 180, 0.38)",
        "components-button-color-filled-brand-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-focus-bg": "#2a2569",
        "components-button-color-filled-brand-focus-text": "#ffffff",
        "components-button-color-filled-brand-focus-stroke": "#2a2569",
        "components-button-color-filled-brand-focus-icon": "#ffffff",
        "components-button-color-filled-brand-focus-outline": "#286efa",
        "components-button-color-filled-tonal-default-bg": "#eae9f2",
        "components-button-color-filled-tonal-default-text": "#22204c",
        "components-button-color-filled-tonal-default-stroke": "#eae9f2",
        "components-button-color-filled-tonal-default-icon": "#22204c",
        "components-button-color-filled-tonal-hover-bg": "#a7a3c1",
        "components-button-color-filled-tonal-hover-text": "#0f0e19",
        "components-button-color-filled-tonal-hover-stroke": "#a7a3c1",
        "components-button-color-filled-tonal-hover-icon": "#0f0e19",
        "components-button-color-filled-tonal-pressed-bg": "#a7a3c1",
        "components-button-color-filled-tonal-pressed-text": "#0f0e19",
        "components-button-color-filled-tonal-pressed-stroke": "#a7a3c1",
        "components-button-color-filled-tonal-pressed-icon": "#0f0e19",
        "components-button-color-filled-tonal-disabled-bg": "rgba(235, 241, 249, 0.38)",
        "components-button-color-filled-tonal-disabled-text": "rgba(0, 70, 151, 0.38)",
        "components-button-color-filled-tonal-disabled-stroke": "rgba(235, 241, 249, 0.38)",
        "components-button-color-filled-tonal-disabled-icon": "rgba(0, 70, 151, 0.38)",
        "components-button-color-filled-tonal-focus-bg": "#eae9f2",
        "components-button-color-filled-tonal-focus-text": "#22204c",
        "components-button-color-filled-tonal-focus-stroke": "#eae9f2",
        "components-button-color-filled-tonal-focus-icon": "#22204c",
        "components-button-color-filled-tonal-focus-outline": "#1c4cae",
        "components-button-color-filled-white-default-bg": "#ffffff",
        "components-button-color-filled-white-default-text": "#121111",
        "components-button-color-filled-white-default-stroke": "#ffffff",
        "components-button-color-filled-white-default-icon": "#2a2569",
        "components-button-color-filled-white-hover-bg": "#eae9f2",
        "components-button-color-filled-white-hover-text": "#121111",
        "components-button-color-filled-white-hover-stroke": "#eae9f2",
        "components-button-color-filled-white-hover-icon": "#22204c",
        "components-button-color-filled-white-pressed-bg": "#eae9f2",
        "components-button-color-filled-white-pressed-text": "#121111",
        "components-button-color-filled-white-pressed-stroke": "#eae9f2",
        "components-button-color-filled-white-pressed-icon": "#22204c",
        "components-button-color-filled-white-disabled-bg": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-text": "rgba(25, 27, 28, 0.38)",
        "components-button-color-filled-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-icon": "rgba(18, 32, 46, 0.38)",
        "components-button-color-filled-white-focus-bg": "#ffffff",
        "components-button-color-filled-white-focus-text": "#121111",
        "components-button-color-filled-white-focus-stroke": "#ffffff",
        "components-button-color-filled-white-focus-icon": "#2a2569",
        "components-button-color-filled-white-focus-outline": "#1c4cae",
        "components-button-color-outline-brand-default-bg": "rgba(0, 83, 180, 0.00)",
        "components-button-color-outline-brand-default-text": "#2a2569",
        "components-button-color-outline-brand-default-stroke": "#2a2569",
        "components-button-color-outline-brand-default-icon": "#2a2569",
        "components-button-color-outline-brand-hover-bg": "#22204c",
        "components-button-color-outline-brand-hover-text": "#ffffff",
        "components-button-color-outline-brand-hover-stroke": "#22204c",
        "components-button-color-outline-brand-hover-icon": "#ffffff",
        "components-button-color-outline-brand-pressed-bg": "#0f0e19",
        "components-button-color-outline-brand-pressed-text": "#ffffff",
        "components-button-color-outline-brand-pressed-stroke": "#0f0e19",
        "components-button-color-outline-brand-pressed-icon": "#ffffff",
        "components-button-color-outline-brand-disabled-bg": "rgba(0, 83, 180, 0.00)",
        "components-button-color-outline-brand-disabled-text": "rgba(0, 83, 180, 0.38)",
        "components-button-color-outline-brand-disabled-stroke": "rgba(0, 83, 180, 0.38)",
        "components-button-color-outline-brand-disabled-icon": "rgba(0, 83, 180, 0.38)",
        "components-button-color-outline-brand-focus-bg": "#ffffff",
        "components-button-color-outline-brand-focus-text": "#2a2569",
        "components-button-color-outline-brand-focus-stroke": "#2a2569",
        "components-button-color-outline-brand-focus-icon": "#2a2569",
        "components-button-color-outline-brand-focus-outline": "#286efa",
        "components-button-color-outline-tonal-default-bg": "#ffffff",
        "components-button-color-outline-tonal-default-text": "#807a77",
        "components-button-color-outline-tonal-default-stroke": "#807a77",
        "components-button-color-outline-tonal-default-icon": "#807a77",
        "components-button-color-outline-tonal-hover-bg": "#ffffff",
        "components-button-color-outline-tonal-hover-text": "#5b5654",
        "components-button-color-outline-tonal-hover-stroke": "#5b5654",
        "components-button-color-outline-tonal-hover-icon": "#5b5654",
        "components-button-color-outline-tonal-pressed-bg": "#ffffff",
        "components-button-color-outline-tonal-pressed-text": "#5b5654",
        "components-button-color-outline-tonal-pressed-stroke": "#5b5654",
        "components-button-color-outline-tonal-pressed-icon": "#5b5654",
        "components-button-color-outline-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-tonal-disabled-text": "rgba(114, 117, 126, 0.38)",
        "components-button-color-outline-tonal-disabled-stroke": "rgba(114, 117, 126, 0.38)",
        "components-button-color-outline-tonal-disabled-icon": "rgba(114, 117, 126, 0.38)",
        "components-button-color-outline-tonal-focus-bg": "#ffffff",
        "components-button-color-outline-tonal-focus-text": "#807a77",
        "components-button-color-outline-tonal-focus-stroke": "#807a77",
        "components-button-color-outline-tonal-focus-icon": "#807a77",
        "components-button-color-outline-tonal-focus-outline": "#286efa",
        "components-button-color-outline-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-default-text": "#ffffff",
        "components-button-color-outline-white-default-stroke": "#ffffff",
        "components-button-color-outline-white-default-icon": "#ffffff",
        "components-button-color-outline-white-hover-bg": "#ffffff",
        "components-button-color-outline-white-hover-text": "#121111",
        "components-button-color-outline-white-hover-stroke": "#ffffff",
        "components-button-color-outline-white-hover-icon": "#2a2569",
        "components-button-color-outline-white-pressed-bg": "#ffffff",
        "components-button-color-outline-white-pressed-text": "#121111",
        "components-button-color-outline-white-pressed-stroke": "#ffffff",
        "components-button-color-outline-white-pressed-icon": "#2a2569",
        "components-button-color-outline-white-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-focus-bg": "rgba(255, 255, 255, 0.01)",
        "components-button-color-outline-white-focus-text": "#ffffff",
        "components-button-color-outline-white-focus-stroke": "#ffffff",
        "components-button-color-outline-white-focus-icon": "#ffffff",
        "components-button-color-outline-white-focus-outline": "#286efa",
        "components-button-color-text-brand-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-default-text": "#2a2569",
        "components-button-color-text-brand-default-stroke": "#2a2569",
        "components-button-color-text-brand-default-icon": "#2a2569",
        "components-button-color-text-brand-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-hover-bg-icon-only": "#eae8e8",
        "components-button-color-text-brand-hover-text": "#22204c",
        "components-button-color-text-brand-hover-stroke": "#22204c",
        "components-button-color-text-brand-hover-icon": "#22204c",
        "components-button-color-text-brand-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-pressed-bg-icon-only": "#eae8e8",
        "components-button-color-text-brand-pressed-text": "#0f0e19",
        "components-button-color-text-brand-pressed-stroke": "#0f0e19",
        "components-button-color-text-brand-pressed-icon": "#0f0e19",
        "components-button-color-text-brand-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-disabled-text": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-brand-disabled-stroke": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-brand-disabled-icon": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-brand-focus-bg": "#d5e3ff",
        "components-button-color-text-brand-focus-text": "#22204c",
        "components-button-color-text-brand-focus-stroke": "#22204c",
        "components-button-color-text-brand-focus-icon": "#1c4cae",
        "components-button-color-text-brand-focus-outline": "#1c4cae",
        "components-button-color-text-tonal-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-default-text": "#2a2569",
        "components-button-color-text-tonal-default-stroke": "#2a2569",
        "components-button-color-text-tonal-default-icon": "#2a2569",
        "components-button-color-text-tonal-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-hover-bg-icon-only": "#eae9f2",
        "components-button-color-text-tonal-hover-text": "#22204c",
        "components-button-color-text-tonal-hover-stroke": "#2a2569",
        "components-button-color-text-tonal-hover-icon": "#22204c",
        "components-button-color-text-tonal-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-pressed-bg-icon-only": "#eae9f2",
        "components-button-color-text-tonal-pressed-text": "#22204c",
        "components-button-color-text-tonal-pressed-stroke": "#2a2569",
        "components-button-color-text-tonal-pressed-icon": "#22204c",
        "components-button-color-text-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-disabled-text": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-tonal-disabled-stroke": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-tonal-disabled-icon": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-tonal-focus-bg": "#d5e3ff",
        "components-button-color-text-tonal-focus-text": "#22204c",
        "components-button-color-text-tonal-focus-stroke": "#22204c",
        "components-button-color-text-tonal-focus-icon": "#1c4cae",
        "components-button-color-text-tonal-focus-outline": "#1c4cae",
        "components-button-color-text-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-default-text": "#ffffff",
        "components-button-color-text-white-default-stroke": "#ffffff",
        "components-button-color-text-white-default-icon": "#ffffff",
        "components-button-color-text-white-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-hover-bg-icon-only": "#2a2569",
        "components-button-color-text-white-hover-text": "#ffffff",
        "components-button-color-text-white-hover-stroke": "#ffffff",
        "components-button-color-text-white-hover-icon": "#ffffff",
        "components-button-color-text-white-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-pressed-bg-icon-only": "#2a2569",
        "components-button-color-text-white-pressed-text": "#ffffff",
        "components-button-color-text-white-pressed-stroke": "#ffffff",
        "components-button-color-text-white-pressed-icon": "#ffffff",
        "components-button-color-text-white-disabled-bg": "rgba(115, 119, 128, 0.00)",
        "components-button-color-text-white-disabled-text": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-stroke": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-icon": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-focus-bg": "#d5e3ff",
        "components-button-color-text-white-focus-text": "#ffffff",
        "components-button-color-text-white-focus-stroke": "#ffffff",
        "components-button-color-text-white-focus-icon": "#1c4cae",
        "components-button-color-text-white-focus-outline": "#1c4cae",
        "components-button-group-color-border": "#eae8e8",
        "components-button-group-color-button-bg-default": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-button-fg-default": "#22204c",
        "components-button-group-color-button-bg-hover": "#a7a3c1",
        "components-button-group-color-button-fg-hover": "#0f0e19",
        "components-button-group-color-button-bg-active": "#2a2569",
        "components-button-group-color-button-fg-active": "#ffffff",
        "components-button-group-color-overlay-left-gradient-fill-1": "#eae9f2",
        "components-button-group-color-overlay-left-gradient-fill-2": "rgba(234, 233, 242, 0.50)",
        "components-button-group-color-stroke-default-gradient-fill-1": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-2": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-3": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-4": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-1": "#a7a3c1",
        "components-button-group-color-stroke-hover-gradient-fill-2": "#a7a3c1",
        "components-button-group-color-stroke-hover-gradient-fill-3": "#a7a3c1",
        "components-button-group-color-stroke-hover-gradient-fill-4": "#a7a3c1",
        "components-button-group-color-stroke-active-gradient-fill-1": "#2a2569",
        "components-button-group-color-stroke-active-gradient-fill-2": "#2a2569",
        "components-button-group-color-stroke-active-gradient-fill-3": "#2a2569",
        "components-button-group-color-stroke-active-gradient-fill-4": "#2a2569",
        "components-category-card-color-bg": "#ffffff",
        "components-category-card-color-content-bg": "#ffffff",
        "components-category-card-color-category-bg": "#eae9f2",
        "components-category-card-color-category-bg-2": "#efe2cf",
        "components-category-card-color-category-bg-3": "#ffffff",
        "components-category-card-color-category-bg-4": "#ffffff",
        "components-category-card-color-category-bg-5": "#ffffff",
        "components-category-card-color-category-bg-6": "#ffffff",
        "components-category-card-color-title": "#121111",
        "components-category-card-color-copy": "#373432",
        "components-category-card-color-stroke": "#eae9f2",
        "components-category-card-color-stroke-hover": "#2a2569",
        "components-category-card-listing-color-bg": "#ffffff",
        "components-category-card-listing-color-title": "#121111",
        "components-category-card-listing-color-copy": "#373432",
        "components-card-search-result-color-bg": "#ffffff",
        "components-card-search-result-color-label": "#373432",
        "components-card-search-result-color-title": "#121111",
        "components-card-search-result-color-body": "#373432",
        "components-card-search-result-color-divider-line": "#eae9f2",
        "components-contact-us-promo-color-light-bg": "#eae9f2",
        "components-contact-us-promo-color-light-title": "#121111",
        "components-contact-us-promo-color-light-body": "#373432",
        "components-contact-us-promo-color-default-bg": "#ffffff",
        "components-contact-us-promo-color-default-title": "#121111",
        "components-contact-us-promo-color-default-body": "#373432",
        "components-contact-us-promo-color-brand-bg": "#2a2569",
        "components-contact-us-promo-color-brand-title": "#ffffff",
        "components-contact-us-promo-color-brand-body": "#fdfdfc",
        "components-content-list-color-bg": "#ffffff",
        "components-content-list-color-title": "#121111",
        "components-content-list-color-body": "#373432",
        "components-content-list-color-icon": "#60991a",
        "components-cookies-colors-bg": "#eae9f2",
        "components-cookies-colors-title": "#121111",
        "components-cookies-colors-body": "#373432",
        "components-cookies-colors-icon": "#2a2569",
        "components-dropdown-bg": "#ffffff",
        "components-dropdown-border-default": "#807a77",
        "components-dropdown-border-hover": "#2a2569",
        "components-dropdown-border-focused": "#121111",
        "components-dropdown-border-destructive": "#b32b30",
        "components-dropdown-label-text-default": "#121111",
        "components-dropdown-item-text-default": "#121111",
        "components-dropdown-item-bg-default": "#ffffff",
        "components-dropdown-item-bg-hover": "#eae9f2",
        "components-dropdown-input-field-default": "#807a77",
        "components-dropdown-input-field-focused": "#121111",
        "components-dropdown-input-label-focused": "#121111",
        "components-dropdown-input-field-destructive": "#b32b30",
        "components-external-link-card-bg": "#eae9f2",
        "components-external-link-card-card-bg": "#ffffff",
        "components-external-link-card-card-title": "#121111",
        "components-external-link-card-card-subtitle": "#373432",
        "components-external-link-card-card-eyebrow": "#5b5654",
        "components-fab-color-border-default": "#ffffff",
        "components-footer-brand-color-bg": "#2a2569",
        "components-footer-brand-color-logo": "#ffffff",
        "components-footer-brand-color-title-text": "#ffffff",
        "components-footer-brand-color-description-text": "#fdfdfc",
        "components-footer-brand-color-footer-menu-expanded": "#2a2569",
        "components-footer-brand-color-link-default": "#ffffff",
        "components-footer-brand-color-link-hover": "#eae9f2",
        "components-footer-brand-color-link-pressed": "#a7a3c1",
        "components-footer-brand-color-category-link-default": "#ffffff",
        "components-footer-brand-color-category-link-hover": "#fdfdfc",
        "components-footer-brand-color-category-link-pressed": "#eae8e8",
        "components-footer-brand-color-subcategory-link-default": "#fdfdfc",
        "components-footer-brand-color-subcategory-link-hover": "#eae9f2",
        "components-footer-brand-color-subcategory-link-pressed": "#494294",
        "components-footer-brand-color-icon-icon-default": "#ffffff",
        "components-footer-brand-color-icon-icon-pressed": "#eae8e8",
        "components-footer-brand-color-social-icon-default": "#ffffff",
        "components-footer-brand-color-social-icon-hover": "#a7a3c1",
        "components-footer-brand-color-social-icon-pressed": "#22204c",
        "components-footer-brand-spacing-category-link-hover": "#000000",
        "components-footer-global-color-bg": "#ffffff",
        "components-footer-global-color-title-text": "#000000",
        "components-footer-global-color-disclaimer-text": "#000000",
        "components-footer-global-color-link-default": "#000000",
        "components-footer-global-color-link-hover": "#000000",
        "components-footer-global-color-link-pressed": "#000000",
        "components-generic-card-color-bg": "#ffffff",
        "components-generic-card-color-content-bg": "#ffffff",
        "components-generic-card-color-title": "#121111",
        "components-generic-card-color-subtitle": "#373432",
        "components-generic-card-color-copy": "#373432",
        "components-global-search-color-omnibox-list-bg": "#ffffff",
        "components-global-search-color-omnibox-divider": "#aba8a6",
        "components-global-search-color-omnibox-item-bg-default": "#ffffff",
        "components-global-search-color-omnibox-item-bg-hover": "#fdfdfc",
        "components-global-search-color-omnibox-item-text-default": "#2a2569",
        "components-global-search-color-omnibox-item-text-hover": "#22204c",
        "components-global-search-color-omnibox-label-text-default": "#121111",
        "components-global-search-color-omnibox-category-text-default": "#121111",
        "components-generic-card-listing-color-bg": "#eae9f2",
        "components-generic-card-listing-color-title": "#121111",
        "components-generic-card-listing-color-copy": "#373432",
        "components-header-color-bg": "#ffffff",
        "components-header-color-bottom-stroke": "#eae9f2",
        "components-header-color-search-search-border-default": "#121111",
        "components-header-color-search-search-text": "#5b5654",
        "components-header-color-search-search-icon": "#2a2569",
        "components-header-color-search-search-bar": "#121111",
        "components-header-color-mobile-mobile-expanded-bg": "#eae9f2",
        "components-header-color-mobile-pressed-bg": "#a7a3c1",
        "components-header-color-language-selector-language": "#5b5654",
        "components-header-color-language-selector-country": "#121111",
        "components-header-color-language-selector-country-hover-bg": "#fdfdfc",
        "components-header-color-language-selector-megamenu-language": "#5b5654",
        "components-header-color-language-selector-megamenu-country": "#121111",
        "components-header-color-language-selector-megamenu-country-hover": "#fdfdfc",
        "components-header-color-language-selector-bg": "#ffffff",
        "components-header-color-language-selector-stroke": "#807a77",
        "components-header-color-mega-menu-bg": "#eae9f2",
        "components-header-color-mega-menu-featured-bg": "#2a2569",
        "components-header-color-mega-menu-featured-title": "#ffffff",
        "components-header-color-mega-menu-category-title": "#373432",
        "components-header-color-mega-menu-bg-accent": "#eae9f2",
        "components-header-color-icon-icon-default": "#2a2569",
        "components-header-color-icon-icon-hover": "#22204c",
        "components-header-color-icon-icon-pressed": "#22204c",
        "components-header-color-links-nav-main-link-default": "#2a2569",
        "components-header-color-links-nav-main-link-hover": "#22204c",
        "components-header-color-links-nav-main-link-pressed": "#22204c",
        "components-header-color-links-nav-main-link-active": "#0f0e19",
        "components-header-color-links-nav-main-active-bar": "#0f0e19",
        "components-header-color-links-nav-main-bg-color": "#ffffff",
        "components-header-color-links-nav-category-link-category-default": "#2a2569",
        "components-header-color-links-nav-category-link-category-hover": "#373432",
        "components-header-color-links-nav-category-link-category-pressed": "#0f0e19",
        "components-header-color-links-nav-category-link-category-active": "#0f0e19",
        "components-header-color-links-nav-menu-link-link-default": "#494294",
        "components-header-color-links-nav-menu-link-link-hover": "#2a2569",
        "components-header-color-links-nav-menu-link-link-pressed": "#0f0e19",
        "components-header-color-links-nav-menu-link-link-active": "#0f0e19",
        "components-header-color-links-nav-menu-link-bg": "#eae9f2",
        "components-header-color-links-nav-subcategory-link-subcategory-default": "#2a2569",
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": "#0f0e19",
        "components-header-color-links-nav-subcategory-link-subcategory-active": "#0f0e19",
        "components-hero-homepage-color-bg": "#ffffff",
        "components-hero-homepage-color-container-bg": "#2a2569",
        "components-hero-homepage-color-eyebrow": "#807a77",
        "components-hero-homepage-color-header": "#121111",
        "components-hero-homepage-color-sub-header": "#373432",
        "components-hero-homepage-color-body": "#373432",
        "components-hero-homepage-color-play-pause": "#ffffff",
        "components-hero-internal-color-default-title": "#ffffff",
        "components-hero-internal-color-default-body": "#fdfdfc",
        "components-hero-internal-color-default-bg": "#2a2569",
        "components-hero-internal-color-accent-bg": "#2a2569",
        "components-hero-internal-color-with-image-bg": "#2a2569",
        "components-hero-internal-color-default-bg-2": "#22204c",
        "components-hero-internal-color-with-image-title": "#ffffff",
        "components-hero-internal-color-with-image-body": "#fdfdfc",
        "components-heading-page-title-color-default-bg": "#ffffff",
        "components-heading-page-title-color-default-title": "#121111",
        "components-heading-page-title-color-default-body": "#373432",
        "components-heading-page-title-color-light-bg": "#eae9f2",
        "components-heading-page-title-color-light-title": "#121111",
        "components-heading-page-title-color-light-body": "#373432",
        "components-heading-page-title-color-color-bg": "#2a2569",
        "components-heading-page-title-color-color-title": "#ffffff",
        "components-heading-page-title-color-color-body": "#fdfdfc",
        "components-hyperlink-color-dark-default": "#647175",
        "components-hyperlink-color-dark-hover": "#4d5354",
        "components-hyperlink-color-dark-visited": "#7c8d95",
        "components-hyperlink-color-light-default": "#dfc7a6",
        "components-hyperlink-color-light-hover": "#efe2cf",
        "components-hyperlink-color-light-visited": "#c9d5d8",
        "components-icon-default": "#807a77",
        "components-icon-focused": "#807a77",
        "components-icon-card-color-bg": "#ffffff",
        "components-icon-card-color-icon-bg": "#2a2569",
        "components-icon-card-color-icon": "#ffffff",
        "components-icon-card-color-title": "#121111",
        "components-icon-card-color-subtitle": "#5b5654",
        "components-icon-card-color-description": "#373432",
        "components-icon-card-listing-color-bg": "#ffffff",
        "components-icon-card-listing-color-inner-bg": "#ffffff",
        "components-icon-card-listing-color-brand-bg": "#2a2569",
        "components-icon-card-listing-color-title": "#121111",
        "components-icon-card-listing-color-copy": "#373432",
        "components-icon-card-listing-color-inner-legal-text": "#373432",
        "components-jumplink-color-bg-default": "#ffffff",
        "components-jumplink-color-link-text-default": "#5b5654",
        "components-jumplink-color-link-text-hover": "#2a2569",
        "components-jumplink-color-link-border-hover": "#2a2569",
        "components-jumplink-color-link-text-active": "#2a2569",
        "components-jumplink-color-link-border-active": "#2a2569",
        "components-jumplink-color-title-text": "#121111",
        "components-jumplink-color-description-text": "#121111",
        "components-layout-bg-default": "#ffffff",
        "components-lightbox-color-overlay-bg": "rgba(234, 233, 242, 0.95)",
        "components-lightbox-color-title": "#121111",
        "components-lightbox-color-body": "#373432",
        "components-label-color-on-color-label-label-default": "#ffffff",
        "components-label-color-on-color-label-label-hover": "#5b5654",
        "components-label-color-on-color-label-label-pressed": "#373432",
        "components-label-color-on-color-background-bg-default": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-color-background-bg-hover": "#ffffff",
        "components-label-color-on-color-background-bg-pressed": "#eae8e8",
        "components-label-color-on-color-stroke-hover": "#ffffff",
        "components-label-color-on-color-stroke-pressed": "#eae8e8",
        "components-label-color-on-color-stroke-default-gradient-fill-1": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-2": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-3": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-4": "#ffffff",
        "components-label-color-on-white-label-label-default": "#2a2569",
        "components-label-color-on-white-label-label-hover": "#ffffff",
        "components-label-color-on-white-label-label-pressed": "#ffffff",
        "components-label-color-on-white-background-bg-default": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-white-background-bg-hover": "#2a2569",
        "components-label-color-on-white-background-bg-pressed": "#2a2569",
        "components-label-color-on-white-stroke-hover": "#78be20",
        "components-label-color-on-white-stroke-pressed": "#0f0e19",
        "components-label-color-on-white-stroke-default-gradient-fill-1": "#2a2569",
        "components-label-color-on-white-stroke-default-gradient-fill-2": "#2a2569",
        "components-label-color-on-white-stroke-default-gradient-fill-3": "#2a2569",
        "components-label-color-on-white-stroke-default-gradient-fill-4": "#2a2569",
        "components-legal-disclaimer-color-default-bg": "#ffffff",
        "components-legal-disclaimer-color-default-title": "#121111",
        "components-legal-disclaimer-color-default-body": "#373432",
        "components-legal-disclaimer-color-light-bg": "#eae9f2",
        "components-legal-disclaimer-color-light-title": "#121111",
        "components-legal-disclaimer-color-light-body": "#373432",
        "components-legal-disclaimer-color-brand-bg": "#121111",
        "components-legal-disclaimer-color-brand-title": "#ffffff",
        "components-legal-disclaimer-color-brand-body": "#fdfdfc",
        "components-media-video-thumbnail-overlay-bg": "rgba(25, 27, 28, 0.80)",
        "components-media-caption-color-bg": "#fdfdfc",
        "components-media-caption-color-text": "#373432",
        "components-media-gallery-color-bg": "#eae9f2",
        "components-media-gallery-color-title": "#121111",
        "components-media-gallery-color-body": "#373432",
        "components-pagination-on-white-accent-default": "#2a2569",
        "components-pagination-on-white-accent-scroll": "#2a2569",
        "components-pagination-on-white-disabled": "#aba8a6",
        "components-pagination-on-white-bg": "rgba(0, 83, 180, 0.20)",
        "components-pagination-on-white-stroke": "#aba8a6",
        "components-pagination-on-white-bg-active": "#2a2569",
        "components-pagination-on-white-bg-hover": "#eae8e8",
        "components-pagination-on-white-text-default": "#807a77",
        "components-pagination-on-white-text-hover": "#373432",
        "components-pagination-on-white-text-active": "#ffffff",
        "components-pagination-on-color-accent-default": "#ffffff",
        "components-pagination-on-color-accent-scroll": "#ffffff",
        "components-pagination-on-color-disabled": "#020203",
        "components-pagination-on-color-bg": "rgba(255, 255, 255, 0.38)",
        "components-pagination-on-color-stroke": "rgba(255, 255, 255, 0.38)",
        "components-pagination-on-color-bg-active": "#ffffff",
        "components-pagination-on-color-bg-hover": "#373432",
        "components-pagination-on-color-text-default": "#ffffff",
        "components-pagination-on-color-text-hover": "#ffffff",
        "components-pagination-on-color-text-active": "#121111",
        "components-pdf-link-card-color-bg": "#ffffff",
        "components-pdf-link-card-color-card-bg": "#ffffff",
        "components-pdf-link-card-color-card-title": "#121111",
        "components-pdf-link-card-color-card-subtitle": "#373432",
        "components-pdf-link-card-color-card-body": "#373432",
        "components-product-card-color-bg": "#ffffff",
        "components-product-card-color-content-bg": "#ffffff",
        "components-product-card-color-border": "#ffffff",
        "components-product-card-color-title": "#121111",
        "components-product-card-color-description": "#373432",
        "components-product-card-color-star-rating": "#60991a",
        "components-product-card-color-badge-bg": "#78be20",
        "components-product-card-color-badge-text": "#121111",
        "components-product-card-listing-color-bg": "#2a2569",
        "components-product-card-listing-color-title": "#ffffff",
        "components-product-card-listing-color-copy": "#fdfdfc",
        "components-product-card-listing-color-legal-text": "#121111",
        "components-product-information-color-bg": "#2a2569",
        "components-product-information-color-content-bg": "#2a2569",
        "components-product-information-color-headline": "#ffffff",
        "components-product-information-color-body": "#fdfdfc",
        "components-product-information-color-detail-text": "#fdfdfc",
        "components-product-information-color-divider": "#eae8e8",
        "components-product-information-color-icon": "#eae9f2",
        "components-product-information-color-play-icon": "#ffffff",
        "components-product-information-color-selected-stroke": "#78be20",
        "components-product-results-list-with-search-color-bg": "#eae9f2",
        "components-promo-color-default-bg": "#ffffff",
        "components-promo-color-default-image-bg": "#ffffff",
        "components-promo-color-default-content-bg": "#ffffff",
        "components-promo-color-default-title": "#121111",
        "components-promo-color-default-body": "#373432",
        "components-promo-color-default-category": "#121111",
        "components-promo-color-light-bg": "#eae9f2",
        "components-promo-color-light-image-bg": "#eae9f2",
        "components-promo-color-light-content-bg": "#eae9f2",
        "components-promo-color-light-title": "#121111",
        "components-promo-color-light-body": "#373432",
        "components-promo-color-light-category": "#121111",
        "components-promo-color-brand-bg": "#2a2569",
        "components-promo-color-brand-image-bg": "#2a2569",
        "components-promo-color-brand-content-bg": "#2a2569",
        "components-promo-color-brand-title": "#ffffff",
        "components-promo-color-brand-body": "#fdfdfc",
        "components-promo-color-brand-category": "#ffffff",
        "components-pull-quote-color-bg": "#2a2569",
        "components-pull-quote-color-stroke": "#2a2569",
        "components-pull-quote-color-quote-bg": "#ffffff",
        "components-pull-quote-color-quote-title": "#2a2569",
        "components-pull-quote-color-quote-by-text": "#373432",
        "components-pull-quote-color-quote-detail": "#807a77",
        "components-pull-quote-color-quote-highlight": "#60991a",
        "components-promo-alternate-color-default-bg": "#ffffff",
        "components-promo-alternate-color-default-image-bg": "#ffffff",
        "components-promo-alternate-color-default-content-bg": "#ffffff",
        "components-promo-alternate-color-default-title": "#121111",
        "components-promo-alternate-color-default-body": "#373432",
        "components-promo-alternate-color-default-category": "#121111",
        "components-promo-alternate-color-light-bg": "#eae9f2",
        "components-promo-alternate-color-light-image-bg": "#eae9f2",
        "components-promo-alternate-color-light-content-bg": "#eae9f2",
        "components-promo-alternate-color-light-title": "#121111",
        "components-promo-alternate-color-light-body": "#373432",
        "components-promo-alternate-color-light-category": "#121111",
        "components-promo-alternate-color-brand-bg": "#2a2569",
        "components-promo-alternate-color-brand-image-bg": "#2a2569",
        "components-promo-alternate-color-brand-content-bg": "#2a2569",
        "components-promo-alternate-color-brand-title": "#ffffff",
        "components-promo-alternate-color-brand-body": "#fdfdfc",
        "components-promo-alternate-color-brand-category": "#ffffff",
        "components-share-list-color-title": "#373432",
        "components-share-list-color-bg": "#fdfdfc",
        "components-share-list-color-link-default": "#373432",
        "components-share-list-color-link-hover": "#2a2569",
        "components-share-list-color-bg-link-hover": "#eae8e8",
        "components-share-list-color-close-icon": "#27282b",
        "components-share-list-color-facebook-icon": "#3b5998",
        "components-share-list-color-pinterest-icon": "#e60023",
        "components-share-list-color-twitter-icon": "#000000",
        "components-share-list-color-basic-icon-default": "#27282b",
        "components-share-list-color-icon-hover": "#2a2569",
        "components-share-list-color-border": "#eae8e8",
        "components-site-search-bg": "#ffffff",
        "components-site-search-results_text": "#373432",
        "components-site-search-filter-filter-selected-bg": "#eae9f2",
        "components-site-search-facet-group-label-bottom-line": "#eae9f2",
        "components-site-search-facet-group-label-text": "#373432",
        "components-site-search-facet-group-label-bg": "#ffffff",
        "components-site-search-facet-bg": "#ffffff",
        "components-site-search-facet-stroke": "#eae9f2",
        "components-site-search-refine-bg": "#ffffff",
        "components-site-search-refine-right-divider": "#eae9f2",
        "components-site-search-refine-title": "#121111",
        "components-site-search-hero-search-bg": "#22204c",
        "components-site-search-hero-search-accent-bg": "#494294",
        "components-site-search-hero-search-title": "#ffffff",
        "components-stats-stat-text": "#22204c",
        "components-stats-stat-text-on-light": "#22204c",
        "components-stats-supporting-text": "#373432",
        "components-stats-bg-default": "#ffffff",
        "components-stats-bg-inner": "#ffffff",
        "components-stats-bg-section-inner": "#22204c",
        "components-stats-title": "#121111",
        "components-stats-copy": "#373432",
        "components-supporting-text-default": "#5b5654",
        "components-supporting-text-destructive": "#b32b30",
        "components-tab-bg": "#ffffff",
        "components-tab-border-default": "#807a77",
        "components-tab-border-hover": "#2a2569",
        "components-tab-border-focused": "#121111",
        "components-tab-border-destructive": "#b32b30",
        "components-tab-label-text-default": "#121111",
        "components-tab-item-text-default": "#121111",
        "components-tab-item-bg-default": "#ffffff",
        "components-tab-item-bg-hover": "#eae9f2",
        "components-tab-input-field-default": "#807a77",
        "components-tab-input-field-focused": "#121111",
        "components-tab-input-label-focused": "#121111",
        "components-tab-input-field-destructive": "#b32b30",
        "components-tab-bg-2": "#eae9f2",
        "components-tag-color-bg": "#ffffff",
        "components-tag-color-text": "#2a2569",
        "components-tag-color-stroke": "#2a2569",
        "components-tag-color-bg-hover": "#22204c",
        "components-tag-color-text-hover": "#ffffff",
        "components-tag-color-stroke-hover": "#22204c",
        "components-text-field-bg": "#fdfdfc",
        "components-text-field-bg-focused": "#eae9f2",
        "components-text-field-border-default": "#121111",
        "components-text-field-border-hover": "#2a2569",
        "components-text-field-border-focused": "#121111",
        "components-text-field-border-destructive": "#b32b30",
        "components-text-field-input-field-default": "#5b5654",
        "components-text-field-input-field-focused": "#121111",
        "components-text-field-input-label-focused": "#121111",
        "components-text-field-input-field-destructive": "#b32b30",
        "components-text-field-icon-default": "#807a77",
        "components-text-field-icon-destructive": "#b32b30",
        "components-text-field-supporting-text-default": "#5b5654",
        "components-text-field-supporting-text-destructive": "#b32b30",
        "components-tip-color-bg": "#eae9f2",
        "components-tip-color-title": "#121111",
        "components-tip-color-body": "#373432",
        "components-tip-color-stroke": "#a7a3c1",
        "components-video-color-bg": "#2a2569",
        "themes-general-surface-color-light-bg": "#ffffff",
        "themes-general-surface-color-light-title": "#121111",
        "themes-general-surface-color-light-body": "#373432",
        "themes-general-surface-color-light-decor-line-break": "rgba(42, 37, 105, 0.15)",
        "themes-general-surface-color-light-rule-line": "rgba(42, 37, 105, 0.15)",
        "themes-general-surface-color-dark-bg": "#2a2569",
        "themes-general-surface-color-dark-title": "#ffffff",
        "themes-general-surface-color-dark-body": "#fdfdfc",
        "themes-general-surface-color-dark-decor-line-break": "rgba(42, 37, 105, 0.15)",
        "themes-general-card-color-dark-title": "#121111",
        "themes-general-card-color-dark-subtitle": "#373432",
        "themes-general-card-color-dark-body": "#373432",
        "themes-general-card-color-light-title": "#ffffff",
        "themes-general-card-color-light-subtitle": "#fdfdfc",
        "themes-general-card-color-light-body": "#fdfdfc",
        "themes-shadow-color": "#ffffff",
        "themes-shadow-share-list-color-fill": "rgba(0, 0, 0, 0.40)",
        "themes-shadow-header-menu-color-fill": "rgba(0, 0, 0, 0.20)",
        "themes-gradient-loader-gradient-fill-1": "#2a2569",
        "themes-gradient-loader-gradient-fill-2": "rgba(42, 37, 105, 0.00)",
        "themes-color-background-white": "#ffffff",
        "themes-color-background-light": "#eae9f2",
        "themes-color-background-dark": "#27282b",
        "themes-color-background-black": "#121111",
        "themes-color-background-brand1": "#2a2569",
        "themes-color-background-brand2": "#78be20",
        "themes-color-background-accent1": "#98a6b1",
        "themes-color-background-accent2": "#fec02f",
        "themes-color-background-body": "#eae9f2",
        "themes-color-stroke-white": "#ffffff",
        "themes-color-stroke-light": "#eae9f2",
        "themes-color-stroke-dark": "#373432",
        "themes-color-stroke-black": "#121111",
        "themes-color-stroke-brand1": "#2a2569",
        "themes-color-stroke-brand2": "#78be20",
        "themes-color-stroke-accent1": "#98a6b1",
        "themes-color-stroke-accent2": "#fec02f",
        "themes-color-text-white": "#ffffff",
        "themes-color-text-light": "#fdfdfc",
        "themes-color-text-dark": "#373432",
        "themes-color-text-black": "#121111",
        "themes-color-text-brand1": "#2a2569",
        "themes-color-text-brand2": "#47531f",
        "themes-color-text-accent1": "#647175",
        "themes-color-text-accent2": "#a1791e",
        "colors-grayscale-100": "#fdfdfc",
        "colors-grayscale-200": "#eae8e8",
        "colors-grayscale-300": "#aba8a6",
        "colors-grayscale-400": "#807a77",
        "colors-grayscale-500": "#5b5654",
        "colors-grayscale-600": "#373432",
        "colors-grayscale-700": "#121111",
        "colors-brand-1-100": "#eae9f2",
        "colors-brand-1-200": "#a7a3c1",
        "colors-brand-1-300": "#494294",
        "colors-brand-1-400": "#2a2569",
        "colors-brand-1-500": "#22204c",
        "colors-brand-1-600": "#0f0e19",
        "colors-brand-1-700": "#020203",
        "colors-brand-2-100": "#fdfdfc",
        "colors-brand-2-200": "#e3e6cd",
        "colors-brand-2-300": "#a8d253",
        "colors-brand-2-400": "#78be20",
        "colors-brand-2-500": "#60991a",
        "colors-brand-2-600": "#47531f",
        "colors-brand-2-700": "#030302",
        "colors-accent-1-100": "#fcfdfd",
        "colors-accent-1-200": "#e2eaeb",
        "colors-accent-1-300": "#c9d5d8",
        "colors-accent-1-400": "#98a6b1",
        "colors-accent-1-500": "#7c8d95",
        "colors-accent-1-600": "#647175",
        "colors-accent-1-700": "#4d5354",
        "colors-accent-2-100": "#fff9f2",
        "colors-accent-2-200": "#fff4e5",
        "colors-accent-2-300": "#fece7a",
        "colors-accent-2-400": "#fec02f",
        "colors-accent-2-500": "#e3ac2a",
        "colors-accent-2-600": "#c59524",
        "colors-accent-2-700": "#a1791e",
        "colors-accent-3-100": "#f6ede0",
        "colors-accent-3-200": "#f2e8d7",
        "colors-accent-3-300": "#efe2cf",
        "colors-accent-3-400": "#dfc7a6",
        "colors-accent-3-500": "#d6b992",
        "colors-accent-3-600": "#c49d6b",
        "colors-accent-3-700": "#967043",
        "colors-accent-4-400_storage": "#f8f4f2",
        "colors-accessible-accent-1-100": "#fcfdfd",
        "colors-accessible-accent-1-200": "#e2eaeb",
        "colors-accessible-accent-1-300": "#c9d5d8",
        "colors-accessible-accent-1-400": "#98a6b1",
        "colors-accessible-accent-1-500": "#7c8d95",
        "colors-accessible-accent-1-600": "#647175",
        "colors-accessible-accent-1-700": "#4d5354",
        "colors-accessible-accent-2-100": "#fff9f2",
        "colors-accessible-accent-2-200": "#fff4e5",
        "colors-accessible-accent-2-300": "#fece7a",
        "colors-accessible-accent-2-400": "#fec02f",
        "colors-accessible-accent-2-500": "#e3ac2a",
        "colors-accessible-accent-2-600": "#c59524",
        "colors-accessible-accent-2-700": "#a1791e",
        "colors-accessible-brand-2-100": "#fdfdfc",
        "colors-accessible-brand-2-200": "#e3e6cd",
        "colors-accessible-brand-2-300": "#a8d253",
        "colors-accessible-brand-2-400": "#78be20",
        "colors-accessible-brand-2-500": "#60991a",
        "colors-accessible-brand-2-600": "#47531f",
        "colors-accessible-brand-2-700": "#030302"
      },
      "spacing": {
        "components-accordion-spacing-large-padding-x": "5rem",
        "components-accordion-spacing-large-icon-margin-right": "2rem",
        "components-accordion-spacing-large-copy-margin-right": "2.5rem",
        "components-accordion-spacing-large-maxwidth": "59.625rem",
        "components-accordion-spacing-large-section-padding-x": "0rem",
        "components-accordion-spacing-large-section-padding-y": "2.5rem",
        "components-accordion-spacing-large-section-copy-margin-bottom": "2.5rem",
        "components-accordion-spacing-large-section-space-between": "4rem",
        "components-accordion-spacing-large-item-padding-x": "0rem",
        "components-accordion-spacing-large-item-padding-y": "1.5rem",
        "components-accordion-spacing-large-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-large-content-padding-x": "0rem",
        "components-accordion-spacing-large-content-padding-y": "2.5rem",
        "components-accordion-spacing-large-content-title-margin-bottom": "2.5rem",
        "components-accordion-spacing-large-content-content-space-between": "4rem",
        "components-accordion-spacing-large-content-content-margin-left": "1rem",
        "components-accordion-spacing-small-padding-top": "1rem",
        "components-accordion-spacing-small-padding-x": "1.5rem",
        "components-accordion-spacing-small-padding-y": "2.5rem",
        "components-accordion-spacing-small-icon-margin-right": "0.75rem",
        "components-accordion-spacing-small-title-margin-bottom": "0.5rem",
        "components-accordion-spacing-small-copy-margin-right": "2rem",
        "components-accordion-spacing-small-copy-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-padding-x": "0rem",
        "components-accordion-spacing-small-section-padding-y": "0rem",
        "components-accordion-spacing-small-section-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-space-between": "2.5rem",
        "components-accordion-spacing-small-item-padding-x": "0rem",
        "components-accordion-spacing-small-item-padding-y": "1rem",
        "components-accordion-spacing-small-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-small-content-padding-x": "0rem",
        "components-accordion-spacing-small-content-padding-y": "0.75rem",
        "components-accordion-spacing-small-content-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-content-content-space-between": "1rem",
        "components-accordion-spacing-small-content-content-margin-left": "1rem",
        "components-article-card-spacing-large-padding-image-x": "0rem",
        "components-article-card-spacing-large-padding-image-y": "0rem",
        "components-article-card-spacing-large-padding-content-x": "1.5rem",
        "components-article-card-spacing-large-padding-content-y": "1.5rem",
        "components-article-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-article-card-spacing-large-button-space-between": "1rem",
        "components-article-card-spacing-large-title-margin-bottom": "1rem",
        "components-article-card-spacing-large-list-content-gap": "1rem",
        "components-article-card-spacing-large-list-image-padding-x": "1rem",
        "components-article-card-spacing-large-list-image-padding-y": "1rem",
        "components-article-card-spacing-small-padding-image-x": "0rem",
        "components-article-card-spacing-small-padding-image-y": "0rem",
        "components-article-card-spacing-small-padding-content-x": "1.5rem",
        "components-article-card-spacing-small-padding-content-y": "1.5rem",
        "components-article-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-article-card-listing-spacing-small-padding-x": "1.5rem",
        "components-article-card-listing-spacing-small-padding-y": "2.5rem",
        "components-article-hero-spacing-large-padding-x": "0rem",
        "components-article-hero-spacing-large-padding-y": "0rem",
        "components-article-hero-spacing-large-title-margin-bottom": "2rem",
        "components-article-hero-spacing-large-label-share-margin-bottom": "2rem",
        "components-article-hero-spacing-large-author-name-margin-bottom": "0.75rem",
        "components-article-hero-spacing-large-author-thumbnail-margin-left": "1rem",
        "components-article-hero-spacing-large-details-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-space-between": "1rem",
        "components-article-hero-spacing-large-body-margin-bottom": "2rem",
        "components-article-hero-spacing-large-image-space-between": "1rem",
        "components-article-hero-spacing-large-image-margin-bottom": "1rem",
        "components-article-hero-spacing-large-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-stats-margin-bottom": "2.5rem",
        "components-article-hero-spacing-small-content-margin-top": "2.5rem",
        "components-article-hero-spacing-small-title-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-label-share-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-authorname-margin-bottom": "0.75rem",
        "components-article-hero-spacing-small-authorthumbnail-margin-left": "1rem",
        "components-article-hero-spacing-small-details-margin-bottom": "1rem",
        "components-article-hero-spacing-small-space-between": "0.75rem",
        "components-article-hero-spacing-small-body-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-image-space-between": "0.75rem",
        "components-article-hero-spacing-small-image-margin-bottom": "1rem",
        "components-article-hero-spacing-small-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-stats-margin-bottom": "2rem",
        "components-banner-spacing-large-content-padding-x": "1.5rem",
        "components-banner-spacing-large-content-space-between": "1rem",
        "components-banner-spacing-large-content-width": "69.375rem",
        "components-banner-spacing-icon-dimensions": "1.5rem",
        "components-banner-spacing-icon-margin-left": "0.5rem",
        "components-banner-spacing-small-space-between": "1rem",
        "components-banner-spacing-small-content-space-between": "1rem",
        "components-banner-spacing-small-title-margin-bottom": "0.75rem",
        "components-banner-spacing-small-content-margin-bottom": "1rem",
        "components-breadcrumb-spacing-large-space-between": "0.25rem",
        "components-breadcrumb-spacing-small-space-between": "0.25rem",
        "components-button-spacing-large-icon-large-x": "2.5rem",
        "components-button-spacing-large-icon-large-y": "2.5rem",
        "components-button-spacing-large-default-padding-y": "0.75rem",
        "components-button-spacing-large-default-padding-x": "1.5rem",
        "components-button-spacing-large-default-space-between": "0.25rem",
        "components-button-spacing-large-icon-left-padding-left": "1rem",
        "components-button-spacing-large-icon-right-padding-right": "1rem",
        "components-button-spacing-large-icon-only-padding-x": "0.75rem",
        "components-button-spacing-large-icon-only-padding-y": "0.75rem",
        "components-button-spacing-large-tertiary-content-height": "1.5rem",
        "components-button-spacing-compressed-icon-large-x": "2.5rem",
        "components-button-spacing-compressed-icon-large-y": "2.5rem",
        "components-button-spacing-compressed-default-padding-y": "0.5rem",
        "components-button-spacing-compressed-default-padding-x": "1rem",
        "components-button-spacing-compressed-default-space-between": "0.125rem",
        "components-button-spacing-compressed-icon-left-padding-left": "0.5rem",
        "components-button-spacing-compressed-icon-right-padding-right": "0.5rem",
        "components-button-spacing-compressed-icon-only-padding-x": "0.5rem",
        "components-button-group-spacing-padding-y": "0.125rem",
        "components-button-group-spacing-padding-x": "0.25rem",
        "components-button-group-spacing-space-between": "0.5rem",
        "components-category-card-spacing-large-padding-top": "0rem",
        "components-category-card-spacing-large-padding-x": "0rem",
        "components-category-card-spacing-large-padding-y": "0rem",
        "components-category-card-spacing-large-padding-image-x": "0rem",
        "components-category-card-spacing-large-padding-image-y": "0rem",
        "components-category-card-spacing-large-padding-content-x": "2rem",
        "components-category-card-spacing-large-padding-content-y": "2rem",
        "components-category-card-listing-spacing-large-padding-y": "5rem",
        "components-category-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-category-card-listing-spacing-large-card-margin-bottom": "0rem",
        "components-category-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-card-search-result-small-padding-top": "0rem",
        "components-card-search-result-small-padding-x": "0rem",
        "components-card-search-result-small-padding-y": "0rem",
        "components-card-search-result-small-padding-image-x": "0rem",
        "components-card-search-result-small-padding-image-y": "0rem",
        "components-card-search-result-small-padding-content-x": "1.5rem",
        "components-card-search-result-small-padding-content-y": "1.5rem",
        "components-card-search-result-spacing-large-padding-x": "0rem",
        "components-card-search-result-spacing-large-padding-y": "1.5rem",
        "components-card-search-result-spacing-large-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-image-margin-right": "1.5rem",
        "components-card-search-result-spacing-small-padding-x": "1.5rem",
        "components-card-search-result-spacing-small-padding-y": "1.5rem",
        "components-card-search-result-spacing-small-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-image-margin-right": "0.75rem",
        "components-contact-us-promo-spacing-large-padding-x": "5rem",
        "components-contact-us-promo-spacing-large-padding-y": "5rem",
        "components-contact-us-promo-spacing-large-content-margin-right": "5rem",
        "components-contact-us-promo-spacing-large-margin-y": "2.5rem",
        "components-contact-us-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-contact-us-promo-spacing-small-margin-y": "2.5rem",
        "components-content-list-list-item-container-padding-x": "0.75rem",
        "components-content-list-list-item-container-padding-y": "1rem",
        "components-content-list-list-item-content-space-between": "0.5rem",
        "components-content-list-list-item-content-default-padding-y": "0.25rem",
        "components-content-list-list-item-content-hyperlink-padding-y": "0rem",
        "components-content-list-spacing-large-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-large-item-title-margin-bottom": "1rem",
        "components-content-list-spacing-large-item-copy-margin-bottom": "2.5rem",
        "components-content-list-spacing-large-item-icon-copy-margin-top": "0rem",
        "components-content-list-spacing-large-item-margin-bottom": "2rem",
        "components-content-list-spacing-large-item-image-margin-bottom": "0.5rem",
        "components-content-list-spacing-large-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-y": "2.5rem",
        "components-content-list-spacing-small-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-small-item-title-margin-bottom": "0.5rem",
        "components-content-list-spacing-small-item-copy-margin-bottom": "1rem",
        "components-content-list-spacing-small-item-margin-bottom": "1.5rem",
        "components-content-list-spacing-small-header-title-margin-bottom": "1rem",
        "components-content-list-spacing-small-header-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-title-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-button-space-between": "1rem",
        "components-dropdown-item-padding-y": "0.75rem",
        "components-dropdown-item-padding-x": "0.75rem",
        "components-dropdown-item-spacing-x": "0.75rem",
        "components-dropdown-input-padding-y": "0.25rem",
        "components-dropdown-input-padding-x": "1rem",
        "components-footer-brand-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-large-column-margin-right": "4rem",
        "components-footer-brand-spacing-large-column-margin-bottom": "4rem",
        "components-footer-brand-spacing-large-link-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-padding-y": "2.5rem",
        "components-footer-brand-spacing-small-padding-x": "1rem",
        "components-footer-brand-spacing-small-logo-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-column-margin-right": "0.125rem",
        "components-footer-brand-spacing-small-column-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-small-link-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-title-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-text-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-footer-link-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-link-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-link-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-link-minheight": "3rem",
        "components-footer-brand-spacing-small-footer-category-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-category-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-category-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-category-minheight": "3rem",
        "components-footer-global-spacing-large-padding-y": "2.5rem",
        "components-footer-global-spacing-large-padding-x": "5rem",
        "components-footer-global-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-global-spacing-large-column-margin-right": "2rem",
        "components-footer-global-spacing-large-link-margin-bottom": "2rem",
        "components-footer-global-spacing-small-padding-y": "2.5rem",
        "components-footer-global-spacing-small-padding-x": "1rem",
        "components-footer-global-spacing-small-logo-margin-bottom": "1rem",
        "components-footer-global-spacing-small-column-margin-right": "1rem",
        "components-footer-global-spacing-small-link-margin-bottom": "1rem",
        "components-generic-card-spacing-large-padding-x": "0rem",
        "components-generic-card-spacing-large-padding-y": "0rem",
        "components-generic-card-spacing-large-padding-image-x": "0rem",
        "components-generic-card-spacing-large-padding-image-y": "0rem",
        "components-generic-card-spacing-large-padding-content-x": "2rem",
        "components-generic-card-spacing-large-padding-content-y": "2rem",
        "components-generic-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-generic-card-spacing-large-title-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-button-space-between": "1rem",
        "components-generic-card-spacing-small-padding-x": "0rem",
        "components-generic-card-spacing-small-padding-y": "0rem",
        "components-generic-card-spacing-small-padding-image-x": "0rem",
        "components-generic-card-spacing-small-padding-image-y": "1.5rem",
        "components-generic-card-spacing-small-padding-content-x": "1.5rem",
        "components-generic-card-spacing-small-padding-content-y": "1.5rem",
        "components-global-search-spacing-omnibox-padding-x": "0.125rem",
        "components-global-search-spacing-omnibox-list-padding-y": "0.5rem",
        "components-global-search-spacing-omnibox-label-padding": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-item-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-height": "3rem",
        "components-generic-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-generic-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-header-shadow-x": "0rem",
        "components-header-spacing-large-padding-y": "0.5rem",
        "components-header-spacing-large-padding-x": "0.5rem",
        "components-header-spacing-large-links-margin-right": "1.5rem",
        "components-header-spacing-large-icons-space-between": "1.5rem",
        "components-header-spacing-large-logo-margin-right": "2rem",
        "components-header-spacing-large-space-between-search": "0.5rem",
        "components-header-spacing-large-height": "5.5rem",
        "components-header-spacing-large-language-selector-padding-x": "1rem",
        "components-header-spacing-large-language-selector-padding-y": "0.5rem",
        "components-header-spacing-large-language-selector-space-between": "0.75rem",
        "components-header-spacing-large-language-selector-country-space-between": "0.5rem",
        "components-header-spacing-large-mega-menu-padding-y": "2.5rem",
        "components-header-spacing-large-mega-menu-padding-x": "2.5rem",
        "components-header-spacing-large-mega-menu-column-margin-right": "2.5rem",
        "components-header-spacing-large-mega-menu-category-link-margin-bottom": "1rem",
        "components-header-spacing-large-mega-menu-link-margin-bottom": "0.5rem",
        "components-header-spacing-large-mega-menu-featured-padding-x": "2rem",
        "components-header-spacing-large-mega-menu-featured-padding-y": "2rem",
        "components-header-spacing-large-mega-menu-featured-image-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-title-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-link-space-between": "1.5rem",
        "components-header-spacing-large-mega-menu-title-margin-bottom": "2.5rem",
        "components-header-spacing-large-dropdown-padding-y": "1.5rem",
        "components-header-spacing-large-dropdown-padding-x": "1.5rem",
        "components-header-spacing-large-dropdown-margin-bottom": "0.5rem",
        "components-header-spacing-small-language-selector-padding-x": "0.5rem",
        "components-header-spacing-small-language-selector-padding-y": "0.5rem",
        "components-header-spacing-small-language-selector-space-between": "0.5rem",
        "components-header-spacing-small-padding-left": "0rem",
        "components-header-spacing-small-padding-right": "1rem",
        "components-header-spacing-small-padding-top": "0rem",
        "components-header-spacing-small-padding-bottom": "0rem",
        "components-header-spacing-small-nav-main-minheight": "3rem",
        "components-header-spacing-small-nav-main-padding-y": "0.5rem",
        "components-header-spacing-small-nav-main-padding-left": "1.5rem",
        "components-header-spacing-small-nav-main-padding-right": "1rem",
        "components-header-spacing-small-nav-category-minheight": "3rem",
        "components-header-spacing-small-nav-category-padding-left": "1.5rem",
        "components-header-spacing-small-nav-category-padding-right": "0.75rem",
        "components-header-spacing-small-nav-category-padding-y": "0.75rem",
        "components-header-spacing-small-nav-category-space-between": "0rem",
        "components-header-spacing-small-nav-menu-link-minheight": "3rem",
        "components-header-spacing-small-nav-menu-link-padding-right": "1.5rem",
        "components-header-spacing-small-nav-menu-link-padding-left": "2.5rem",
        "components-header-spacing-small-nav-menu-link-padding-y": "0.75rem",
        "components-header-spacing-small-mega-menu-padding-y": "0rem",
        "components-header-spacing-small-mega-menu-padding-x": "0rem",
        "components-header-spacing-small-mega-menu-category-link-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-link-margin-bottom": "0rem",
        "components-header-spacing-small-mega-menu-featured-padding-x": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-padding-y": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-image-margin-bottom": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-title-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-featured-link-space-between": "0.75rem",
        "components-header-spacing-small-mega-menu-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-container-padding": "3rem",
        "components-hero-homepage-spacing-large-padding-x": "4rem",
        "components-hero-homepage-spacing-large-padding-y": "2.5rem",
        "components-hero-homepage-spacing-large-minheight": "34.3125rem",
        "components-hero-homepage-spacing-large-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-margin-bottom": "2rem",
        "components-hero-homepage-spacing-large-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-copy-margin-bottom": "2.5rem",
        "components-hero-homepage-spacing-large-cta-margin-right": "1rem",
        "components-hero-homepage-spacing-large-image-height": "34.3125rem",
        "components-hero-homepage-spacing-large-image-width": "45.75rem",
        "components-hero-homepage-spacing-small-padding-x": "0.5rem",
        "components-hero-homepage-spacing-small-padding-y": "1.5rem",
        "components-hero-homepage-spacing-small-minheight": "34.3125rem",
        "components-hero-homepage-spacing-small-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-copy-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-image-height": "17.5625rem",
        "components-hero-homepage-spacing-small-image-width": "45.75rem",
        "components-hero-internal-spacing-large-padding-y": "2.5rem",
        "components-hero-internal-spacing-large-padding-right": "10rem",
        "components-hero-internal-spacing-large-title-margin-bottom": "1.5rem",
        "components-hero-internal-spacing-large-button-padding-y": "2rem",
        "components-hero-internal-spacing-large-text-maxwidth": "59.625rem",
        "components-hero-internal-spacing-large-minheight": "18.5rem",
        "components-hero-internal-spacing-small-padding-x": "1.5rem",
        "components-hero-internal-spacing-small-padding-right": "0rem",
        "components-hero-internal-spacing-small-button-padding-y": "1.5rem",
        "components-hero-internal-spacing-small-text-maxwidth": "30rem",
        "components-hero-internal-spacing-small-minheight": "13.5rem",
        "components-icon-card-spacing-large-padding-x": "0rem",
        "components-icon-card-spacing-large-padding-y": "0rem",
        "components-icon-card-spacing-large-icon-margin": "1.5rem",
        "components-icon-card-spacing-large-margin-right": "1.5rem",
        "components-icon-card-spacing-large-title-margin-bottom": "1rem",
        "components-icon-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-icon-card-spacing-large-button-space-between": "1.5rem",
        "components-icon-card-spacing-large-top-space-between": "1rem",
        "components-icon-card-spacing-small-padding-x": "1.5rem",
        "components-icon-card-spacing-small-padding-y": "1.5rem",
        "components-icon-card-spacing-small-icon-margin": "1.5rem",
        "components-icon-card-spacing-small-margin-right": "1.5rem",
        "components-icon-card-spacing-small-title-margin-bottom": "1.5rem",
        "components-icon-card-dimensions-large-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-large-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-large-big-icon-height": "4rem",
        "components-icon-card-dimensions-large-big-icon-width": "3.5rem",
        "components-icon-card-dimensions-large-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-large-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-large-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-large-tiny-icon-width": "2.5rem",
        "components-icon-card-dimensions-small-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-small-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-small-big-icon-height": "3.5rem",
        "components-icon-card-dimensions-small-big-icon-width": "3.5rem",
        "components-icon-card-dimensions-small-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-small-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-small-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-small-tiny-icon-width": "2.5rem",
        "components-icon-card-listing-spacing-large-padding-inner-x": "4rem",
        "components-icon-card-listing-spacing-large-padding-inner-y": "4rem",
        "components-icon-card-listing-spacing-large-card-space-between": "4rem",
        "components-icon-card-listing-spacing-large-title-margin-bottom": "1.5rem",
        "components-icon-card-listing-spacing-large-card-margin-bottom": "4rem",
        "components-icon-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-x": "1.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-y": "2.5rem",
        "components-icon-card-listing-spacing-small-title-margin-bottom": "1rem",
        "components-icon-card-listing-spacing-small-button-margin-top": "1.5rem",
        "components-jumplink-link-padding-bottom": "0.5rem",
        "components-jumplink-spacing-small-title-area-margin-bottom": "1.5rem",
        "components-jumplink-spacing-small-link-container-padding-x": "0rem",
        "components-lightbox-spacing-large-carousel-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-carousel-space-between": "1.5rem",
        "components-lightbox-spacing-large-featured-image-height": "39.25rem",
        "components-lightbox-spacing-large-featured-image-width": "39.25rem",
        "components-lightbox-spacing-large-image-height": "17.0625rem",
        "components-lightbox-spacing-large-image-width": "17.0625rem",
        "components-lightbox-spacing-small-carousel-margin-bottom": "1rem",
        "components-lightbox-spacing-small-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-small-media-details-margin-top": "1rem",
        "components-lightbox-spacing-small-image-height": "20.4375rem",
        "components-lightbox-spacing-small-image-width": "20.4375rem",
        "components-legal-disclaimer-spacing-small-ruleline-width": "20.4375rem",
        "components-media-spacing-large-padding-y": "0.5rem",
        "components-media-spacing-large-padding-x": "0.75rem",
        "components-media-gallery-large-container-grid-gap-x": "1.5rem",
        "components-media-gallery-large-container-grid-gap-y": "1.5rem",
        "components-media-gallery-small-container-grid-gap-x": "0.5rem",
        "components-media-gallery-small-container-grid-gap-y": "0.5rem",
        "components-media-gallery-spacing-small-padding-x": "1.5rem",
        "components-media-gallery-spacing-small-padding-y": "2.5rem",
        "components-media-gallery-spacing-large-padding-x": "5rem",
        "components-media-gallery-spacing-large-padding-y": "5rem",
        "components-product-card-spacing-list-content-gap": "1rem",
        "components-product-card-spacing-large-padding-image-x": "0rem",
        "components-product-card-spacing-large-padding-image-y": "0rem",
        "components-product-card-spacing-large-padding-content-x": "1.5rem",
        "components-product-card-spacing-large-padding-content-y": "1.5rem",
        "components-product-card-spacing-large-category-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-title-margin-bottom": "1rem",
        "components-product-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-button-space-between": "1rem",
        "components-product-card-spacing-small-padding-image-x": "0rem",
        "components-product-card-spacing-small-padding-image-y": "0rem",
        "components-product-card-spacing-small-padding-content-x": "1.5rem",
        "components-product-card-spacing-small-padding-content-y": "1.5rem",
        "components-product-card-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-large-padding-x": "5rem",
        "components-product-information-spacing-large-padding-y": "2.5rem",
        "components-product-information-spacing-large-title-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-label-margin-bottom": "2.5rem",
        "components-product-information-spacing-large-ratings-margin-bottom": "1rem",
        "components-product-information-spacing-large-details-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-space-between": "1rem",
        "components-product-information-spacing-large-carousel-space-between": "5rem",
        "components-product-information-spacing-large-button-margin-y": "0.5rem",
        "components-product-information-spacing-large-body-margin-top": "1.5rem",
        "components-product-information-spacing-large-image-space-between": "1rem",
        "components-product-information-spacing-large-image-margin-bottom": "1rem",
        "components-product-information-spacing-small-padding-x": "1.5rem",
        "components-product-information-spacing-small-padding-y": "1.5rem",
        "components-product-information-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-small-label-margin-bottom": "1.5rem",
        "components-product-information-spacing-small-ratings-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-details-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-space-between": "0.75rem",
        "components-product-information-spacing-small-carousel-space-between": "2.5rem",
        "components-product-information-spacing-small-button-margin-y": "0.75rem",
        "components-product-information-spacing-small-body-margin-top": "1rem",
        "components-product-information-spacing-small-image-space-between": "0.75rem",
        "components-product-information-spacing-small-image-margin-bottom": "1rem",
        "components-product-results-list-with-search-spacing-large-padding-x": "5rem",
        "components-product-results-list-with-search-spacing-large-padding-y": "5rem",
        "components-product-results-list-with-search-spacing-large-ruleline-margin-top": "2.5rem",
        "components-product-results-list-with-search-spacing-large-filter-space-between": "1rem",
        "components-product-results-list-with-search-spacing-large-card-space-between": "1.5rem",
        "components-product-results-list-with-search-spacing-large-card-container-margin-bottom": "5rem",
        "components-product-results-list-with-search-spacing-small-padding-x": "1.5rem",
        "components-product-results-list-with-search-spacing-small-padding-y": "2.5rem",
        "components-product-results-list-with-search-spacing-small-ruleline-margin-top": "1rem",
        "components-product-results-list-with-search-spacing-small-filter-padding-x": "0.5rem",
        "components-product-results-list-with-search-spacing-small-card-space-between": "1rem",
        "components-product-results-list-with-search-spacing-small-card-margin-bottom": "1.5rem",
        "components-promo-spacing-large-padding-x": "5rem",
        "components-promo-spacing-large-padding-y": "2.5rem",
        "components-promo-spacing-large-content-space-between": "0rem",
        "components-promo-spacing-large-content-space-between-y": "0rem",
        "components-promo-spacing-large-padding-content-x": "3rem",
        "components-promo-spacing-large-padding-content-condensed-x": "5rem",
        "components-promo-spacing-large-padding-content-y": "3rem",
        "components-promo-spacing-large-padding-content-condensed-y": "5rem",
        "components-promo-spacing-large-padding-image-x": "0rem",
        "components-promo-spacing-large-padding-image-y": "0rem",
        "components-promo-spacing-large-padding-image-top": "5rem",
        "components-promo-spacing-large-title-margin-bottom": "2rem",
        "components-promo-spacing-large-margin-y": "2rem",
        "components-promo-spacing-large-buttons-space-between": "1.5rem",
        "components-promo-spacing-small-padding-x": "0rem",
        "components-promo-spacing-small-padding-y": "0rem",
        "components-promo-spacing-small-padding-top": "0rem",
        "components-promo-spacing-small-padding-content-x": "1.5rem",
        "components-promo-spacing-small-padding-content-y": "1.5rem",
        "components-promo-spacing-small-padding-image-x": "1rem",
        "components-promo-spacing-small-padding-image-y": "0rem",
        "components-promo-spacing-small-padding-image-top": "1rem",
        "components-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-promo-spacing-small-margin-y": "2.5rem",
        "components-promo-minheight": "30rem",
        "components-pull-quote-spacing-large-padding-quote-x": "4rem",
        "components-pull-quote-spacing-large-padding-quote-y": "4rem",
        "components-pull-quote-spacing-large-title-margin-bottom": "2rem",
        "components-pull-quote-spacing-large-quote-margin-bottom": "4rem",
        "components-pull-quote-spacing-large-quote-space-between": "1.5rem",
        "components-pull-quote-spacing-large-detail-space-between": "0.5rem",
        "components-pull-quote-spacing-large-quote-minheight": "25rem",
        "components-pull-quote-spacing-small-padding-quote-x": "1.5rem",
        "components-pull-quote-spacing-small-padding-quote-y": "2.5rem",
        "components-pull-quote-spacing-small-title-margin-bottom": "1.5rem",
        "components-pull-quote-spacing-small-quote-margin-bottom": "2.5rem",
        "components-pull-quote-spacing-small-quote-space-between": "1rem",
        "components-pull-quote-spacing-small-detail-space-between": "1rem",
        "components-pull-quote-spacing-small-quote-minheight": "15rem",
        "components-promo-alternate-minheight": "30rem",
        "components-promo-alternate-spacing-large-padding-x": "0rem",
        "components-promo-alternate-spacing-large-padding-y": "0rem",
        "components-promo-alternate-spacing-large-content-space-between": "0rem",
        "components-promo-alternate-spacing-large-padding-content-x": "5rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-x": "5rem",
        "components-promo-alternate-spacing-large-padding-content-y": "5rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-y": "5rem",
        "components-promo-alternate-spacing-large-padding-image-x": "0rem",
        "components-promo-alternate-spacing-large-padding-image-y": "0rem",
        "components-promo-alternate-spacing-large-padding-image-top": "5rem",
        "components-promo-alternate-spacing-large-title-margin-bottom": "2rem",
        "components-promo-alternate-spacing-small-padding-x": "0rem",
        "components-promo-alternate-spacing-small-padding-y": "0rem",
        "components-promo-alternate-spacing-small-padding-content-x": "2.5rem",
        "components-promo-alternate-spacing-small-padding-content-y": "2.5rem",
        "components-promo-alternate-spacing-small-padding-image-x": "0rem",
        "components-promo-alternate-spacing-small-padding-image-y": "0rem",
        "components-promo-alternate-spacing-small-padding-image-top": "0rem",
        "components-promo-alternate-spacing-small-title-margin-bottom": "1.5rem",
        "components-share-list-spacing-padding-x": "1rem",
        "components-share-list-spacing-padding-y": "1rem",
        "components-header-copy-margin-bottom": "1rem",
        "components-header-margin-bottom": "1.5rem",
        "components-item-margin-bottom": "1.5rem",
        "components-site-search-padding-x": "2.5rem",
        "components-site-search-padding-y": "5rem",
        "components-site-search-facet-label-space-between": "0.25rem",
        "components-site-search-refine-padding-x": "1.5rem",
        "components-site-search-refine-padding-y": "0rem",
        "components-site-search-hero-search-height": "18.5rem",
        "components-site-search-hero-search-large-padding-x": "5rem",
        "components-site-search-hero-search-large-padding-y": "5rem",
        "components-stats-spacing-stat-text-space-between": "0.5rem",
        "components-stats-spacing-large-inner-padding-x": "5rem",
        "components-stats-spacing-large-inner-padding-y": "5rem",
        "components-stats-spacing-small-inner-padding-x": "1.5rem",
        "components-stats-spacing-small-inner-padding-y": "1.5rem",
        "components-icon-card-listing-spacing-small-top-space-between": "1rem",
        "components-supporting-text-padding-x": "0rem",
        "components-supporting-text-padding-bottom": "0.5rem",
        "components-tab-item-padding-y": "0.75rem",
        "components-tab-item-padding-x": "0.75rem",
        "components-tab-item-spacing-x": "0.75rem",
        "components-tab-input-padding-y": "0.25rem",
        "components-tab-input-padding-x": "1rem",
        "components-tag-spacing-padding-x": "0.5rem",
        "components-tag-spacing-padding-y": "0.5rem",
        "components-tag-spacing-space-between": "0.5rem",
        "components-text-field-input-padding-y": "0.25rem",
        "components-text-field-input-padding-x": "1rem",
        "components-text-field-area-input-padding-y": "1rem",
        "components-text-field-area-input-padding-x": "1rem",
        "components-text-field-supporting-text-padding-x": "0rem",
        "components-text-field-supporting-text-padding-top": "0.5rem",
        "themes-general-surface-spacing-large-padding-x": "5rem",
        "themes-general-surface-spacing-large-padding-y": "5rem",
        "themes-general-surface-spacing-large-title-margin-bottom": "2rem",
        "themes-general-surface-spacing-large-body-margin-right": "4rem",
        "themes-general-surface-spacing-large-body-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-large-margin-bottom": "4rem",
        "themes-general-surface-spacing-large-cards-space-between": "1.5rem",
        "themes-general-surface-spacing-large-button-margin-top": "2rem",
        "themes-general-surface-spacing-small-padding-x": "1.5rem",
        "themes-general-surface-spacing-small-padding-y": "2.5rem",
        "themes-general-surface-spacing-small-title-margin-bottom": "1rem",
        "themes-general-surface-spacing-small-body-margin-right": "2.5rem",
        "themes-general-surface-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-surface-spacing-small-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-small-cards-space-between": "1rem",
        "themes-general-surface-spacing-small-button-margin-top": "1.5rem",
        "themes-general-card-spacing-large-eyebrow-margin-bottom": "1.5rem",
        "themes-general-card-spacing-large-title-margin-bottom": "1rem",
        "themes-general-card-spacing-large-body-margin-bottom": "2rem",
        "themes-general-card-spacing-large-button-space-between": "1rem",
        "themes-general-card-spacing-small-eyebrow-margin-bottom": "1rem",
        "themes-general-card-spacing-small-title-margin-bottom": "1rem",
        "themes-general-card-spacing-small-body-margin-bottom": "2rem",
        "themes-general-card-spacing-small-button-space-between": "1.5rem",
        "themes-shadow-share-list-spacing-x": "0rem",
        "themes-shadow-share-list-spacing-y": "0.75rem",
        "themes-shadow-share-list-spacing-blur": "1rem",
        "themes-shadow-share-list-spacing-spread": "-0.5rem",
        "themes-shadow-header-menu-spacing-x": "0rem",
        "themes-shadow-header-menu-spacing-y": "0.75rem",
        "themes-shadow-header-menu-spacing-blur": "1rem",
        "themes-shadow-header-menu-spacing-spread": "-0.5rem",
        "themes-typography-rte-large-h1-textsize": "3rem",
        "themes-typography-rte-large-h2-textsize": "2.5rem",
        "themes-typography-rte-large-h3-textsize": "2.25rem",
        "themes-typography-rte-large-h4-textsize": "2rem",
        "themes-typography-rte-large-h5-textsize": "1.5rem",
        "themes-typography-rte-large-h6-textsize": "1.25rem",
        "themes-typography-rte-medium-h1-textsize": "2.5rem",
        "themes-typography-rte-medium-h2-textsize": "2.25rem",
        "themes-typography-rte-medium-h3-textsize": "1.75rem",
        "themes-typography-rte-medium-h4-textsize": "1.5rem",
        "themes-typography-rte-medium-h5-textsize": "1.25rem",
        "themes-typography-rte-medium-h6-textsize": "1.125rem",
        "themes-typography-rte-small-h1-textsize": "2.5rem",
        "themes-typography-rte-small-h2-textsize": "2rem",
        "themes-typography-rte-small-h3-textsize": "1.75rem",
        "themes-typography-rte-small-h4-textsize": "1.5rem",
        "themes-typography-rte-small-h5-textsize": "1.125rem",
        "themes-typography-rte-small-h6-textsize": "1rem",
        "spacing-margin-small-1": "0.125rem",
        "spacing-margin-small-2": "0.25rem",
        "spacing-margin-small-3": "0.5rem",
        "spacing-margin-small-4": "0.75rem",
        "spacing-margin-small-5": "1rem",
        "spacing-margin-small-6": "1.5rem",
        "spacing-margin-small-7": "2.5rem",
        "spacing-margin-medium-2": "0.75rem",
        "spacing-margin-medium-4": "1.5rem",
        "spacing-margin-medium-5": "2rem",
        "spacing-margin-large-1": "0.5rem",
        "spacing-margin-large-2": "1rem",
        "spacing-margin-large-3": "1.5rem",
        "spacing-margin-large-4": "2rem",
        "spacing-margin-large-5": "2.5rem",
        "spacing-margin-large-6": "4rem",
        "spacing-margin-large-7": "5rem",
        "spacing-space-between-small-1": "0.125rem",
        "spacing-space-between-small-2": "0.25rem",
        "spacing-space-between-small-3": "0.5rem",
        "spacing-space-between-small-4": "0.75rem",
        "spacing-space-between-small-5": "1rem",
        "spacing-space-between-small-6": "1.5rem",
        "spacing-space-between-small-7": "2.5rem",
        "spacing-space-between-medium-1": "0.125rem",
        "spacing-space-between-medium-2": "0.25rem",
        "spacing-space-between-medium-3": "0.5rem",
        "spacing-space-between-medium-4": "0.75rem",
        "spacing-space-between-medium-5": "1rem",
        "spacing-space-between-large-1": "0.25rem",
        "spacing-space-between-large-2": "0.5rem",
        "spacing-space-between-large-3": "1rem",
        "spacing-space-between-large-4": "1.5rem",
        "spacing-space-between-large-5": "1.5rem",
        "spacing-space-between-large-6": "2.5rem",
        "spacing-space-between-large-7": "4rem",
        "spacing-padding-small-1": "0.125rem",
        "spacing-padding-small-2": "0.25rem",
        "spacing-padding-small-3": "0.5rem",
        "spacing-padding-small-4": "0.75rem",
        "spacing-padding-small-5": "1rem",
        "spacing-padding-small-6": "1.5rem",
        "spacing-padding-small-7": "2.5rem",
        "spacing-padding-medium-1": "0.25rem",
        "spacing-padding-medium-2": "0.75rem",
        "spacing-padding-medium-3": "1rem",
        "spacing-padding-medium-4": "1.5rem",
        "spacing-padding-medium-5": "2rem",
        "spacing-padding-medium-6": "3rem",
        "spacing-padding-medium-7": "4rem",
        "spacing-padding-large-1": "0.5rem",
        "spacing-padding-large-2": "1rem",
        "spacing-padding-large-3": "1.5rem",
        "spacing-padding-large-4": "2rem",
        "spacing-padding-large-5": "2.5rem",
        "spacing-padding-large-6": "4rem",
        "spacing-padding-large-7": "5rem"
      },
      "string": {
        "components-accordion-section-button": "var(--brand)",
        "components-article-card-listing-button-theme": "var(--white)",
        "components-article-hero-pagination-buttons": "var(--brand)",
        "components-banner-banner-button": "var(--brand)",
        "components-category-card-listing-button-theme": "var(--brand)",
        "components-contact-us-promo-hierarchy-button-theme-1": "var(--filled)",
        "components-contact-us-promo-hierarchy-button-theme-2": "var(--outlined)",
        "components-contact-us-promo-color-button-theme": "var(--brand)",
        "components-contact-us-promo-color-brand-button-theme": "var(--white)",
        "components-generic-card-button-theme": "var(--brand)",
        "components-global-search-loader": "var(--default)",
        "components-generic-card-listing-decor-top": "var(--lysoform-1)",
        "components-generic-card-listing-decor-bottom": "var(--lysoform-2)",
        "components-hero-homepage-pagination-buttons": "var(--brand)",
        "components-hero-homepage-pattern": "var(--lysoform)",
        "components-hero-homepage-button-1": "var(--brand)",
        "components-hero-homepage-button-2": "var(--brand)",
        "components-hero-homepage-image-ratio": "var(--5:4)",
        "components-hero-internal-theme-button-one-type": "var(--filled)",
        "components-hero-internal-theme-button-two-type": "var(--outlined)",
        "components-hero-internal-theme-button-color": "var(--white)",
        "components-hero-internal-theme-button-color-with-image": "var(--white)",
        "components-lightbox-button-type": "var(--brand)",
        "components-pdf-link-card-hierarchy-button-theme-1": "var(--filled)",
        "components-pdf-link-card-hierarchy-button-theme-2": "var(--outlined)",
        "components-pdf-link-card-color-button-theme": "var(--brand)",
        "components-product-card-badge-align": "var(--left)",
        "components-product-card-listing-button-color": "var(--white)",
        "components-promo-theme-button-one-theme": "var(--filled)",
        "components-promo-theme-button-two-theme": "var(--outlined)",
        "components-promo-color-default-button-theme": "var(--brand)",
        "components-promo-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-color-brand-button-theme": "var(--white)",
        "components-pull-quote-border-decor": "var(--glade)",
        "components-promo-alternate-theme-button-one-theme": "var(--filled)",
        "components-promo-alternate-theme-button-two-theme": "var(--text)",
        "components-promo-alternate-color-default-button-theme": "var(--brand)",
        "components-promo-alternate-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-alternate-color-brand-button-theme": "var(--white)",
        "components-share-list-button-color": "var(--brand)",
        "components-site-search-hero-search-hero-pattern": "var(--lysoform)",
        "components-stats-decor-top": "var(--lysoform-1)",
        "components-stats-decor-bottom": "var(--lysoform-2)",
        "themes-brand-loader": "var(--default)",
        "themes-brand-variants": "var(--lysoform)",
        "themes-tab-variant": "var(--default)",
        "themes-label-variants": "var(--default)",
        "themes-logo": "var(--lysoform)",
        "themes-category-card": "var(--lysoform)",
        "themes-title-decor": "var(--off)",
        "themes-typography-heading-fontfamily": "var(--proxima-nova)",
        "themes-typography-heading-large-xxlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-xlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-large-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-medium-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-small-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-xsmall-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-xxsmall-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-xxlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-xlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-large-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-medium-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-small-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-xsmall-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-xxsmall-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-xxlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-xlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-large-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-medium-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-small-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-xsmall-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-xxsmall-fontweight": "var(--extrabold)",
        "themes-typography-rte-fontfamily": "var(--proxima-nova)",
        "themes-typography-rte-fontweight": "var(--extrabold)"
      },
      "borderRadius": {
        "components-accordion-spacing-large-item-item-radius": "0rem",
        "components-article-card-spacing-large-image-radius-tl": "0rem",
        "components-article-card-spacing-large-image-radius-tr": "0rem",
        "components-article-card-spacing-large-image-radius-bl": "0rem",
        "components-article-card-spacing-large-image-radius-br": "0rem",
        "components-article-card-spacing-large-list-image-radius-br": "0rem",
        "components-article-card-spacing-small-image-radius-br": "0rem",
        "components-article-card-spacing-small-list-image-radius-br": "0rem",
        "components-article-hero-spacing-large-content-radius": "0.75rem",
        "components-article-hero-spacing-small-content-radius": "0.75rem",
        "components-button-radius-large": "7.5rem",
        "components-button-group-spacing-border-radius": "7.5rem",
        "components-category-card-radius-border-width": "0rem",
        "components-category-card-radius-border-radius": "7.5rem",
        "components-category-card-radius-border-radius-condensed": "0.5rem",
        "components-category-card-radius-media-radius": "0rem",
        "components-content-list-icon-radius": "7.5rem",
        "components-fab-spacing-border-radius": "7.5rem",
        "components-header-spacing-large-search-border-radius-top": "0rem",
        "components-header-spacing-large-search-border-radius-bottom": "0rem",
        "components-header-spacing-large-language-selector-radius": "0.25rem",
        "components-header-spacing-large-dropdown-radius": "0rem",
        "components-hero-homepage-spacing-large-container-radius": "1rem",
        "components-hero-internal-spacing-large-icon-radius": "7.5rem",
        "components-hero-internal-spacing-small-icon-radius": "7.5rem",
        "components-lightbox-spacing-large-overlay-radius": "0.75rem",
        "components-pagination-scrollbar-radius": "62.5rem",
        "components-product-card-spacing-image-radius-tl": "0rem",
        "components-product-card-spacing-image-radius-tr": "0rem",
        "components-product-card-spacing-image-radius-bl": "0rem",
        "components-product-card-spacing-image-radius-br": "0rem",
        "components-product-information-spacing-large-content-radius": "0.75rem",
        "components-product-information-spacing-small-content-radius": "0.75rem",
        "components-promo-spacing-large-content-radius": "0rem",
        "components-promo-spacing-large-content-radius-tl": "0rem",
        "components-promo-spacing-large-content-radius-tr": "0rem",
        "components-promo-spacing-large-content-radius-bl": "0rem",
        "components-promo-spacing-large-content-radius-br": "0rem",
        "components-promo-spacing-large-image-radius-tl": "0.75rem",
        "components-promo-spacing-large-image-radius-tr": "0.75rem",
        "components-promo-spacing-large-image-radius-bl": "0.75rem",
        "components-promo-spacing-large-image-radius-br": "0.75rem",
        "components-promo-spacing-small-content-radius": "0rem",
        "components-promo-spacing-small-image-radius-tl": "0.75rem",
        "components-promo-spacing-small-image-radius-tr": "0.75rem",
        "components-promo-spacing-small-image-radius-bl": "0.75rem",
        "components-promo-spacing-small-image-radius-br": "0.75rem",
        "components-promo-alternate-spacing-large-content-radius": "0rem",
        "components-promo-alternate-spacing-large-image-radius-tl": "0rem",
        "components-promo-alternate-spacing-large-image-radius-tr": "0rem",
        "components-promo-alternate-spacing-large-image-radius-bl": "0rem",
        "components-promo-alternate-spacing-large-image-radius-br": "0rem",
        "components-promo-alternate-spacing-small-content-radius": "0rem",
        "components-promo-alternate-spacing-small-image-radius-tl": "0rem",
        "components-promo-alternate-spacing-small-image-radius-tr": "0rem",
        "components-promo-alternate-spacing-small-image-radius-bl": "0rem",
        "components-promo-alternate-spacing-small-image-radius-br": "0rem",
        "components-share-list-spacing-radius": "0.5rem",
        "components-share-list-spacing-link-radius": "0.5rem",
        "components-site-search-filter-radius": "0.5rem",
        "components-site-search-facet-radius": "0rem",
        "components-site-search-refine-radius": "0rem",
        "components-tag-spacing-radius": "7.5rem",
        "components-tip-spacing-radius": "0.25rem",
        "themes-spacing-radius-pagination-bar": "62.5rem",
        "themes-spacing-radius-large-button": "7.5rem",
        "themes-spacing-radius-large-button-icon": "7.5rem",
        "themes-spacing-radius-large-image": "0.75rem",
        "themes-spacing-radius-large-image-hero": "0.75rem",
        "themes-spacing-radius-large-image-card": "0.75rem",
        "themes-spacing-radius-large-card": "0.75rem",
        "themes-spacing-radius-large-icon": "7.5rem",
        "themes-spacing-radius-large-label": "7.5rem",
        "themes-spacing-radius-large-tag": "7.5rem",
        "themes-spacing-radius-large-container-tip": "0.25rem",
        "themes-spacing-radius-large-badge": "0.25rem",
        "themes-spacing-radius-large-item": "0.5rem",
        "themes-spacing-radius-large-form-field-input": "0rem",
        "themes-spacing-radius-large-form-area-input": "0.25rem",
        "themes-spacing-radius-large-general": "0.75rem",
        "themes-spacing-radius-small-button": "7.5rem",
        "themes-spacing-radius-small-button-icon": "0.5rem",
        "themes-spacing-radius-small-image": "0.75rem",
        "themes-spacing-radius-small-image-card": "0rem",
        "themes-spacing-radius-small-card": "0.75rem",
        "themes-spacing-radius-small-icon": "7.5rem",
        "themes-spacing-radius-small-label": "7.5rem",
        "themes-spacing-radius-small-tag": "7.5rem",
        "themes-spacing-radius-small-badge": "0.25rem",
        "themes-spacing-radius-small-item": "0rem",
        "themes-spacing-radius-small-form-field-input": "0rem",
        "themes-spacing-radius-small-general": "0.75rem"
      },
      "borderWidth": {
        "3": "3px",
        "components-article-card-spacing-large-border-width": "0.0625rem",
        "components-generic-card-color-border-width": "0.0625rem",
        "components-header-spacing-large-search-border-width-top": "0.125rem",
        "components-header-spacing-large-search-border-width-right": "0.125rem",
        "components-header-spacing-large-search-border-width-bottom": "0.125rem",
        "components-header-spacing-large-search-border-width-left": "0.125rem",
        "components-product-card-spacing-border-width": "0.0625rem"
      },
      "maxHeight": {},
      "maxWidth": {
        "components-article-hero-spacing-large-header-max-width": "66.375rem",
        "components-content-list-spacing-large-item-image-max-width": "33.75rem",
        "components-content-list-spacing-small-item-image-max-width": "33.75rem"
      },
      "minHeight": {
        "components-dropdown-item-min-height": "3rem",
        "components-icon-min-height": "3rem",
        "components-tab-item-min-height": "3rem",
        "components-text-field-input-min-height": "3rem",
        "components-text-field-icon-min-height": "3rem"
      },
      "minWidth": {
        "components-content-list-spacing-small-item-image-min-width": "18.75rem",
        "components-icon-min-width": "3rem",
        "components-text-field-icon-min-width": "3rem"
      },
      "opacity": {
        "components-accessibility-bar-color-bg": 1,
        "components-accessibility-bar-color-text": 1,
        "components-accordion-color-bg": 1,
        "components-accordion-color-title": 1,
        "components-accordion-color-body": 1,
        "components-accordion-color-section-section-bg": 1,
        "components-accordion-color-section-section-title": 1,
        "components-accordion-color-section-section-body": 1,
        "components-accordion-color-item-item-bg": 1,
        "components-accordion-color-item-divider": 1,
        "components-accordion-color-item-icon-icon-default": 1,
        "components-accordion-color-item-icon-icon-hover": 1,
        "components-accordion-color-item-icon-icon-pressed": 1,
        "components-accordion-color-item-icon-icon-active": 1,
        "components-accordion-color-item-title-item-default": 1,
        "components-accordion-color-item-title-item-hover": 1,
        "components-accordion-color-item-title-item-pressed": 1,
        "components-accordion-color-item-title-item-active": 1,
        "components-accordion-color-content-bg": 1,
        "components-accordion-color-content-title": 1,
        "components-accordion-color-content-body": 1,
        "components-article-card-color-bg": 1,
        "components-article-card-color-border": 0,
        "components-article-card-color-content-bg": 1,
        "components-article-card-color-title": 1,
        "components-article-card-color-subtitle": 1,
        "components-article-card-color-copy": 1,
        "components-article-card-color-description": 1,
        "components-article-card-listing-color-default-bg": 1,
        "components-article-card-listing-color-default-title": 1,
        "components-article-card-listing-color-default-copy": 1,
        "components-article-hero-color-content-bg": 1,
        "components-article-hero-color-bg": 1,
        "components-article-hero-color-headline": 1,
        "components-article-hero-color-body": 1,
        "components-article-hero-color-detail-text": 1,
        "components-article-hero-color-divider": 1,
        "components-article-hero-color-icon": 1,
        "components-article-hero-color-play-icon": 1,
        "components-article-hero-color-selected-stroke": 1,
        "components-banner-color-bg-default": 1,
        "components-banner-color-icon-default": 1,
        "components-banner-color-title-default": 1,
        "components-banner-color-body-default": 1,
        "components-banner-color-icon-close-default": 1,
        "components-banner-color-bg-alert": 1,
        "components-banner-color-icon-alert": 1,
        "components-banner-color-title-alert": 1,
        "components-banner-color-body-alert": 1,
        "components-banner-color-icon-close-alert": 1,
        "components-breadcrumb-color-bg": 1,
        "components-breadcrumb-color-breadcrumb-link-default": 1,
        "components-breadcrumb-color-breadcrumb-link-hover": 1,
        "components-breadcrumb-color-breadcrumb-link-active": 1,
        "components-breadcrumb-color-icon-divider": 1,
        "components-bug-finder-prompt": 1,
        "components-button-color-filled-brand-default-bg": 1,
        "components-button-color-filled-brand-default-text": 1,
        "components-button-color-filled-brand-default-stroke": 1,
        "components-button-color-filled-brand-default-icon": 1,
        "components-button-color-filled-brand-hover-bg": 1,
        "components-button-color-filled-brand-hover-text": 1,
        "components-button-color-filled-brand-hover-stroke": 1,
        "components-button-color-filled-brand-hover-icon": 1,
        "components-button-color-filled-brand-pressed-bg": 1,
        "components-button-color-filled-brand-pressed-text": 1,
        "components-button-color-filled-brand-pressed-stroke": 1,
        "components-button-color-filled-brand-pressed-icon": 1,
        "components-button-color-filled-brand-disabled-bg": 0.03,
        "components-button-color-filled-brand-disabled-text": 0.02,
        "components-button-color-filled-brand-disabled-stroke": 0.03,
        "components-button-color-filled-brand-disabled-icon": 0.02,
        "components-button-color-filled-brand-focus-bg": 1,
        "components-button-color-filled-brand-focus-text": 1,
        "components-button-color-filled-brand-focus-stroke": 1,
        "components-button-color-filled-brand-focus-icon": 1,
        "components-button-color-filled-brand-focus-outline": 1,
        "components-button-color-filled-tonal-default-bg": 1,
        "components-button-color-filled-tonal-default-text": 1,
        "components-button-color-filled-tonal-default-stroke": 1,
        "components-button-color-filled-tonal-default-icon": 1,
        "components-button-color-filled-tonal-hover-bg": 1,
        "components-button-color-filled-tonal-hover-text": 1,
        "components-button-color-filled-tonal-hover-stroke": 1,
        "components-button-color-filled-tonal-hover-icon": 1,
        "components-button-color-filled-tonal-pressed-bg": 1,
        "components-button-color-filled-tonal-pressed-text": 1,
        "components-button-color-filled-tonal-pressed-stroke": 1,
        "components-button-color-filled-tonal-pressed-icon": 1,
        "components-button-color-filled-tonal-disabled-bg": 0.02,
        "components-button-color-filled-tonal-disabled-text": 0.03,
        "components-button-color-filled-tonal-disabled-stroke": 0.02,
        "components-button-color-filled-tonal-disabled-icon": 0.03,
        "components-button-color-filled-tonal-focus-bg": 1,
        "components-button-color-filled-tonal-focus-text": 1,
        "components-button-color-filled-tonal-focus-stroke": 1,
        "components-button-color-filled-tonal-focus-icon": 1,
        "components-button-color-filled-tonal-focus-outline": 1,
        "components-button-color-filled-white-default-bg": 1,
        "components-button-color-filled-white-default-text": 1,
        "components-button-color-filled-white-default-stroke": 1,
        "components-button-color-filled-white-default-icon": 1,
        "components-button-color-filled-white-hover-bg": 1,
        "components-button-color-filled-white-hover-text": 1,
        "components-button-color-filled-white-hover-stroke": 1,
        "components-button-color-filled-white-hover-icon": 1,
        "components-button-color-filled-white-pressed-bg": 1,
        "components-button-color-filled-white-pressed-text": 1,
        "components-button-color-filled-white-pressed-stroke": 1,
        "components-button-color-filled-white-pressed-icon": 1,
        "components-button-color-filled-white-disabled-bg": 0.02,
        "components-button-color-filled-white-disabled-text": null,
        "components-button-color-filled-white-disabled-stroke": 0.02,
        "components-button-color-filled-white-disabled-icon": null,
        "components-button-color-filled-white-focus-bg": 1,
        "components-button-color-filled-white-focus-text": 1,
        "components-button-color-filled-white-focus-stroke": 1,
        "components-button-color-filled-white-focus-icon": 1,
        "components-button-color-filled-white-focus-outline": 1,
        "components-button-color-outline-brand-default-bg": 0.03,
        "components-button-color-outline-brand-default-text": 1,
        "components-button-color-outline-brand-default-stroke": 1,
        "components-button-color-outline-brand-default-icon": 1,
        "components-button-color-outline-brand-hover-bg": 1,
        "components-button-color-outline-brand-hover-text": 1,
        "components-button-color-outline-brand-hover-stroke": 1,
        "components-button-color-outline-brand-hover-icon": 1,
        "components-button-color-outline-brand-pressed-bg": 1,
        "components-button-color-outline-brand-pressed-text": 1,
        "components-button-color-outline-brand-pressed-stroke": 1,
        "components-button-color-outline-brand-pressed-icon": 1,
        "components-button-color-outline-brand-disabled-bg": 0.03,
        "components-button-color-outline-brand-disabled-text": 0.03,
        "components-button-color-outline-brand-disabled-stroke": 0.03,
        "components-button-color-outline-brand-disabled-icon": 0.03,
        "components-button-color-outline-brand-focus-bg": 1,
        "components-button-color-outline-brand-focus-text": 1,
        "components-button-color-outline-brand-focus-stroke": 1,
        "components-button-color-outline-brand-focus-icon": 1,
        "components-button-color-outline-brand-focus-outline": 1,
        "components-button-color-outline-tonal-default-bg": 1,
        "components-button-color-outline-tonal-default-text": 1,
        "components-button-color-outline-tonal-default-stroke": 1,
        "components-button-color-outline-tonal-default-icon": 1,
        "components-button-color-outline-tonal-hover-bg": 1,
        "components-button-color-outline-tonal-hover-text": 1,
        "components-button-color-outline-tonal-hover-stroke": 1,
        "components-button-color-outline-tonal-hover-icon": 1,
        "components-button-color-outline-tonal-pressed-bg": 1,
        "components-button-color-outline-tonal-pressed-text": 1,
        "components-button-color-outline-tonal-pressed-stroke": 1,
        "components-button-color-outline-tonal-pressed-icon": 1,
        "components-button-color-outline-tonal-disabled-bg": 0.02,
        "components-button-color-outline-tonal-disabled-text": 0.02,
        "components-button-color-outline-tonal-disabled-stroke": 0.02,
        "components-button-color-outline-tonal-disabled-icon": 0.02,
        "components-button-color-outline-tonal-focus-bg": 1,
        "components-button-color-outline-tonal-focus-text": 1,
        "components-button-color-outline-tonal-focus-stroke": 1,
        "components-button-color-outline-tonal-focus-icon": 1,
        "components-button-color-outline-tonal-focus-outline": 1,
        "components-button-color-outline-white-default-bg": 0.02,
        "components-button-color-outline-white-default-text": 1,
        "components-button-color-outline-white-default-stroke": 1,
        "components-button-color-outline-white-default-icon": 1,
        "components-button-color-outline-white-hover-bg": 1,
        "components-button-color-outline-white-hover-text": 1,
        "components-button-color-outline-white-hover-stroke": 1,
        "components-button-color-outline-white-hover-icon": 1,
        "components-button-color-outline-white-pressed-bg": 1,
        "components-button-color-outline-white-pressed-text": 1,
        "components-button-color-outline-white-pressed-stroke": 1,
        "components-button-color-outline-white-pressed-icon": 1,
        "components-button-color-outline-white-disabled-bg": 0.02,
        "components-button-color-outline-white-disabled-text": 0.02,
        "components-button-color-outline-white-disabled-stroke": 0.02,
        "components-button-color-outline-white-disabled-icon": 0.02,
        "components-button-color-outline-white-focus-bg": 0.02,
        "components-button-color-outline-white-focus-text": 1,
        "components-button-color-outline-white-focus-stroke": 1,
        "components-button-color-outline-white-focus-icon": 1,
        "components-button-color-outline-white-focus-outline": 1,
        "components-button-color-text-brand-default-bg": 0.02,
        "components-button-color-text-brand-default-text": 1,
        "components-button-color-text-brand-default-stroke": 1,
        "components-button-color-text-brand-default-icon": 1,
        "components-button-color-text-brand-hover-bg": 0.02,
        "components-button-color-text-brand-hover-bg-icon-only": 1,
        "components-button-color-text-brand-hover-text": 1,
        "components-button-color-text-brand-hover-stroke": 1,
        "components-button-color-text-brand-hover-icon": 1,
        "components-button-color-text-brand-pressed-bg": 0.02,
        "components-button-color-text-brand-pressed-bg-icon-only": 1,
        "components-button-color-text-brand-pressed-text": 1,
        "components-button-color-text-brand-pressed-stroke": 1,
        "components-button-color-text-brand-pressed-icon": 1,
        "components-button-color-text-brand-disabled-bg": 0.02,
        "components-button-color-text-brand-disabled-text": 0.03,
        "components-button-color-text-brand-disabled-stroke": 0.03,
        "components-button-color-text-brand-disabled-icon": 0.03,
        "components-button-color-text-brand-focus-bg": 1,
        "components-button-color-text-brand-focus-text": 1,
        "components-button-color-text-brand-focus-stroke": 1,
        "components-button-color-text-brand-focus-icon": 1,
        "components-button-color-text-brand-focus-outline": 1,
        "components-button-color-text-tonal-default-bg": 0.02,
        "components-button-color-text-tonal-default-text": 1,
        "components-button-color-text-tonal-default-stroke": 1,
        "components-button-color-text-tonal-default-icon": 1,
        "components-button-color-text-tonal-hover-bg": 0.02,
        "components-button-color-text-tonal-hover-bg-icon-only": 1,
        "components-button-color-text-tonal-hover-text": 1,
        "components-button-color-text-tonal-hover-stroke": 1,
        "components-button-color-text-tonal-hover-icon": 1,
        "components-button-color-text-tonal-pressed-bg": 0.02,
        "components-button-color-text-tonal-pressed-bg-icon-only": 1,
        "components-button-color-text-tonal-pressed-text": 1,
        "components-button-color-text-tonal-pressed-stroke": 1,
        "components-button-color-text-tonal-pressed-icon": 1,
        "components-button-color-text-tonal-disabled-bg": 0.02,
        "components-button-color-text-tonal-disabled-text": 0.03,
        "components-button-color-text-tonal-disabled-stroke": 0.03,
        "components-button-color-text-tonal-disabled-icon": 0.03,
        "components-button-color-text-tonal-focus-bg": 1,
        "components-button-color-text-tonal-focus-text": 1,
        "components-button-color-text-tonal-focus-stroke": 1,
        "components-button-color-text-tonal-focus-icon": 1,
        "components-button-color-text-tonal-focus-outline": 1,
        "components-button-color-text-white-default-bg": 0.02,
        "components-button-color-text-white-default-text": 1,
        "components-button-color-text-white-default-stroke": 1,
        "components-button-color-text-white-default-icon": 1,
        "components-button-color-text-white-hover-bg": 0.02,
        "components-button-color-text-white-hover-bg-icon-only": 1,
        "components-button-color-text-white-hover-text": 1,
        "components-button-color-text-white-hover-stroke": 1,
        "components-button-color-text-white-hover-icon": 1,
        "components-button-color-text-white-pressed-bg": 0.02,
        "components-button-color-text-white-pressed-bg-icon-only": 1,
        "components-button-color-text-white-pressed-text": 1,
        "components-button-color-text-white-pressed-stroke": 1,
        "components-button-color-text-white-pressed-icon": 1,
        "components-button-color-text-white-disabled-bg": 0.02,
        "components-button-color-text-white-disabled-text": 0.01,
        "components-button-color-text-white-disabled-stroke": 0.01,
        "components-button-color-text-white-disabled-icon": 0.01,
        "components-button-color-text-white-focus-bg": 1,
        "components-button-color-text-white-focus-text": 1,
        "components-button-color-text-white-focus-stroke": 1,
        "components-button-color-text-white-focus-icon": 1,
        "components-button-color-text-white-focus-outline": 1,
        "components-button-group-color-border": 1,
        "components-button-group-color-button-bg-default": 0.02,
        "components-button-group-color-button-fg-default": 1,
        "components-button-group-color-button-bg-hover": 1,
        "components-button-group-color-button-fg-hover": 1,
        "components-button-group-color-button-bg-active": 1,
        "components-button-group-color-button-fg-active": 1,
        "components-button-group-color-overlay-left-gradient-fill-1": 1,
        "components-button-group-color-overlay-left-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-1": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-3": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-4": 0.02,
        "components-button-group-color-stroke-hover-gradient-fill-1": 1,
        "components-button-group-color-stroke-hover-gradient-fill-2": 1,
        "components-button-group-color-stroke-hover-gradient-fill-3": 1,
        "components-button-group-color-stroke-hover-gradient-fill-4": 1,
        "components-button-group-color-stroke-active-gradient-fill-1": 1,
        "components-button-group-color-stroke-active-gradient-fill-2": 1,
        "components-button-group-color-stroke-active-gradient-fill-3": 1,
        "components-button-group-color-stroke-active-gradient-fill-4": 1,
        "components-category-card-color-bg": 1,
        "components-category-card-color-content-bg": 1,
        "components-category-card-color-category-bg": 1,
        "components-category-card-color-category-bg-2": 1,
        "components-category-card-color-category-bg-3": 1,
        "components-category-card-color-category-bg-4": 1,
        "components-category-card-color-category-bg-5": 1,
        "components-category-card-color-category-bg-6": 1,
        "components-category-card-color-title": 1,
        "components-category-card-color-copy": 1,
        "components-category-card-color-stroke": 1,
        "components-category-card-color-stroke-hover": 1,
        "components-category-card-listing-color-bg": 1,
        "components-category-card-listing-color-title": 1,
        "components-category-card-listing-color-copy": 1,
        "components-card-search-result-color-bg": 1,
        "components-card-search-result-color-label": 1,
        "components-card-search-result-color-title": 1,
        "components-card-search-result-color-body": 1,
        "components-card-search-result-color-divider-line": 1,
        "components-contact-us-promo-color-light-bg": 1,
        "components-contact-us-promo-color-light-title": 1,
        "components-contact-us-promo-color-light-body": 1,
        "components-contact-us-promo-color-default-bg": 1,
        "components-contact-us-promo-color-default-title": 1,
        "components-contact-us-promo-color-default-body": 1,
        "components-contact-us-promo-color-brand-bg": 1,
        "components-contact-us-promo-color-brand-title": 1,
        "components-contact-us-promo-color-brand-body": 1,
        "components-content-list-color-bg": 1,
        "components-content-list-color-title": 1,
        "components-content-list-color-body": 1,
        "components-content-list-color-icon": 1,
        "components-cookies-colors-bg": 1,
        "components-cookies-colors-title": 1,
        "components-cookies-colors-body": 1,
        "components-cookies-colors-icon": 1,
        "components-dropdown-bg": 1,
        "components-dropdown-border-default": 1,
        "components-dropdown-border-hover": 1,
        "components-dropdown-border-focused": 1,
        "components-dropdown-border-destructive": 1,
        "components-dropdown-label-text-default": 1,
        "components-dropdown-item-text-default": 1,
        "components-dropdown-item-bg-default": 1,
        "components-dropdown-item-bg-hover": 1,
        "components-dropdown-input-field-default": 1,
        "components-dropdown-input-field-focused": 1,
        "components-dropdown-input-label-focused": 1,
        "components-dropdown-input-field-destructive": 1,
        "components-external-link-card-bg": 1,
        "components-external-link-card-card-bg": 1,
        "components-external-link-card-card-title": 1,
        "components-external-link-card-card-subtitle": 1,
        "components-external-link-card-card-eyebrow": 1,
        "components-fab-color-border-default": 1,
        "components-footer-brand-color-bg": 1,
        "components-footer-brand-color-logo": 1,
        "components-footer-brand-color-title-text": 1,
        "components-footer-brand-color-description-text": 1,
        "components-footer-brand-color-footer-menu-expanded": 1,
        "components-footer-brand-color-link-default": 1,
        "components-footer-brand-color-link-hover": 1,
        "components-footer-brand-color-link-pressed": 1,
        "components-footer-brand-color-category-link-default": 1,
        "components-footer-brand-color-category-link-hover": 1,
        "components-footer-brand-color-category-link-pressed": 1,
        "components-footer-brand-color-subcategory-link-default": 1,
        "components-footer-brand-color-subcategory-link-hover": 1,
        "components-footer-brand-color-subcategory-link-pressed": 1,
        "components-footer-brand-color-icon-icon-default": 1,
        "components-footer-brand-color-icon-icon-pressed": 1,
        "components-footer-brand-color-social-icon-default": 1,
        "components-footer-brand-color-social-icon-hover": 1,
        "components-footer-brand-color-social-icon-pressed": 1,
        "components-footer-brand-spacing-category-link-hover": 1,
        "components-footer-global-color-bg": 1,
        "components-footer-global-color-title-text": 1,
        "components-footer-global-color-disclaimer-text": 1,
        "components-footer-global-color-link-default": 1,
        "components-footer-global-color-link-hover": 1,
        "components-footer-global-color-link-pressed": 1,
        "components-generic-card-color-bg": 1,
        "components-generic-card-color-content-bg": 1,
        "components-generic-card-color-title": 1,
        "components-generic-card-color-subtitle": 1,
        "components-generic-card-color-copy": 1,
        "components-global-search-color-omnibox-list-bg": 1,
        "components-global-search-color-omnibox-divider": 1,
        "components-global-search-color-omnibox-item-bg-default": 1,
        "components-global-search-color-omnibox-item-bg-hover": 1,
        "components-global-search-color-omnibox-item-text-default": 1,
        "components-global-search-color-omnibox-item-text-hover": 1,
        "components-global-search-color-omnibox-label-text-default": 1,
        "components-global-search-color-omnibox-category-text-default": 1,
        "components-generic-card-listing-color-bg": 1,
        "components-generic-card-listing-color-title": 1,
        "components-generic-card-listing-color-copy": 1,
        "components-header-color-bg": 1,
        "components-header-color-bottom-stroke": 1,
        "components-header-color-search-search-border-default": 1,
        "components-header-color-search-search-text": 1,
        "components-header-color-search-search-icon": 1,
        "components-header-color-search-search-bar": 1,
        "components-header-color-mobile-mobile-expanded-bg": 1,
        "components-header-color-mobile-pressed-bg": 1,
        "components-header-color-language-selector-language": 1,
        "components-header-color-language-selector-country": 1,
        "components-header-color-language-selector-country-hover-bg": 1,
        "components-header-color-language-selector-megamenu-language": 1,
        "components-header-color-language-selector-megamenu-country": 1,
        "components-header-color-language-selector-megamenu-country-hover": 1,
        "components-header-color-language-selector-bg": 1,
        "components-header-color-language-selector-stroke": 1,
        "components-header-color-mega-menu-bg": 1,
        "components-header-color-mega-menu-featured-bg": 1,
        "components-header-color-mega-menu-featured-title": 1,
        "components-header-color-mega-menu-category-title": 1,
        "components-header-color-mega-menu-bg-accent": 1,
        "components-header-color-icon-icon-default": 1,
        "components-header-color-icon-icon-hover": 1,
        "components-header-color-icon-icon-pressed": 1,
        "components-header-color-links-nav-main-link-default": 1,
        "components-header-color-links-nav-main-link-hover": 1,
        "components-header-color-links-nav-main-link-pressed": 1,
        "components-header-color-links-nav-main-link-active": 1,
        "components-header-color-links-nav-main-active-bar": 1,
        "components-header-color-links-nav-main-bg-color": 1,
        "components-header-color-links-nav-category-link-category-default": 1,
        "components-header-color-links-nav-category-link-category-hover": 1,
        "components-header-color-links-nav-category-link-category-pressed": 1,
        "components-header-color-links-nav-category-link-category-active": 1,
        "components-header-color-links-nav-menu-link-link-default": 1,
        "components-header-color-links-nav-menu-link-link-hover": 1,
        "components-header-color-links-nav-menu-link-link-pressed": 1,
        "components-header-color-links-nav-menu-link-link-active": 1,
        "components-header-color-links-nav-menu-link-bg": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-default": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-active": 1,
        "components-hero-homepage-color-bg": 1,
        "components-hero-homepage-color-container-bg": 1,
        "components-hero-homepage-color-eyebrow": 1,
        "components-hero-homepage-color-header": 1,
        "components-hero-homepage-color-sub-header": 1,
        "components-hero-homepage-color-body": 1,
        "components-hero-homepage-color-play-pause": 1,
        "components-hero-internal-color-default-title": 1,
        "components-hero-internal-color-default-body": 1,
        "components-hero-internal-color-default-bg": 1,
        "components-hero-internal-color-accent-bg": 1,
        "components-hero-internal-color-with-image-bg": 1,
        "components-hero-internal-color-default-bg-2": 1,
        "components-hero-internal-color-with-image-title": 1,
        "components-hero-internal-color-with-image-body": 1,
        "components-heading-page-title-color-default-bg": 1,
        "components-heading-page-title-color-default-title": 1,
        "components-heading-page-title-color-default-body": 1,
        "components-heading-page-title-color-light-bg": 1,
        "components-heading-page-title-color-light-title": 1,
        "components-heading-page-title-color-light-body": 1,
        "components-heading-page-title-color-color-bg": 1,
        "components-heading-page-title-color-color-title": 1,
        "components-heading-page-title-color-color-body": 1,
        "components-hyperlink-color-dark-default": 1,
        "components-hyperlink-color-dark-hover": 1,
        "components-hyperlink-color-dark-visited": 1,
        "components-hyperlink-color-light-default": 1,
        "components-hyperlink-color-light-hover": 1,
        "components-hyperlink-color-light-visited": 1,
        "components-icon-default": 1,
        "components-icon-focused": 1,
        "components-icon-card-color-bg": 1,
        "components-icon-card-color-icon-bg": 1,
        "components-icon-card-color-icon": 1,
        "components-icon-card-color-title": 1,
        "components-icon-card-color-subtitle": 1,
        "components-icon-card-color-description": 1,
        "components-icon-card-listing-color-bg": 1,
        "components-icon-card-listing-color-inner-bg": 1,
        "components-icon-card-listing-color-brand-bg": 1,
        "components-icon-card-listing-color-title": 1,
        "components-icon-card-listing-color-copy": 1,
        "components-icon-card-listing-color-inner-legal-text": 1,
        "components-jumplink-color-bg-default": 1,
        "components-jumplink-color-link-text-default": 1,
        "components-jumplink-color-link-text-hover": 1,
        "components-jumplink-color-link-border-hover": 1,
        "components-jumplink-color-link-text-active": 1,
        "components-jumplink-color-link-border-active": 1,
        "components-jumplink-color-title-text": 1,
        "components-jumplink-color-description-text": 1,
        "components-layout-bg-default": 1,
        "components-lightbox-color-overlay-bg": 0.02,
        "components-lightbox-color-title": 1,
        "components-lightbox-color-body": 1,
        "components-label-color-on-color-label-label-default": 1,
        "components-label-color-on-color-label-label-hover": 1,
        "components-label-color-on-color-label-label-pressed": 1,
        "components-label-color-on-color-background-bg-default": 0.02,
        "components-label-color-on-color-background-bg-hover": 1,
        "components-label-color-on-color-background-bg-pressed": 1,
        "components-label-color-on-color-stroke-hover": 1,
        "components-label-color-on-color-stroke-pressed": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-4": 1,
        "components-label-color-on-white-label-label-default": 1,
        "components-label-color-on-white-label-label-hover": 1,
        "components-label-color-on-white-label-label-pressed": 1,
        "components-label-color-on-white-background-bg-default": 0.02,
        "components-label-color-on-white-background-bg-hover": 1,
        "components-label-color-on-white-background-bg-pressed": 1,
        "components-label-color-on-white-stroke-hover": 1,
        "components-label-color-on-white-stroke-pressed": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-4": 1,
        "components-legal-disclaimer-color-default-bg": 1,
        "components-legal-disclaimer-color-default-title": 1,
        "components-legal-disclaimer-color-default-body": 1,
        "components-legal-disclaimer-color-light-bg": 1,
        "components-legal-disclaimer-color-light-title": 1,
        "components-legal-disclaimer-color-light-body": 1,
        "components-legal-disclaimer-color-brand-bg": 1,
        "components-legal-disclaimer-color-brand-title": 1,
        "components-legal-disclaimer-color-brand-body": 1,
        "components-media-video-thumbnail-overlay-bg": null,
        "components-media-caption-color-bg": 1,
        "components-media-caption-color-text": 1,
        "components-media-gallery-color-bg": 1,
        "components-media-gallery-color-title": 1,
        "components-media-gallery-color-body": 1,
        "components-pagination-on-white-accent-default": 1,
        "components-pagination-on-white-accent-scroll": 1,
        "components-pagination-on-white-disabled": 1,
        "components-pagination-on-white-bg": 0.03,
        "components-pagination-on-white-stroke": 1,
        "components-pagination-on-white-bg-active": 1,
        "components-pagination-on-white-bg-hover": 1,
        "components-pagination-on-white-text-default": 1,
        "components-pagination-on-white-text-hover": 1,
        "components-pagination-on-white-text-active": 1,
        "components-pagination-on-color-accent-default": 1,
        "components-pagination-on-color-accent-scroll": 1,
        "components-pagination-on-color-disabled": 1,
        "components-pagination-on-color-bg": 0.02,
        "components-pagination-on-color-stroke": 0.02,
        "components-pagination-on-color-bg-active": 1,
        "components-pagination-on-color-bg-hover": 1,
        "components-pagination-on-color-text-default": 1,
        "components-pagination-on-color-text-hover": 1,
        "components-pagination-on-color-text-active": 1,
        "components-pdf-link-card-color-bg": 1,
        "components-pdf-link-card-color-card-bg": 1,
        "components-pdf-link-card-color-card-title": 1,
        "components-pdf-link-card-color-card-subtitle": 1,
        "components-pdf-link-card-color-card-body": 1,
        "components-product-card-color-bg": 1,
        "components-product-card-color-content-bg": 1,
        "components-product-card-color-border": 1,
        "components-product-card-color-title": 1,
        "components-product-card-color-description": 1,
        "components-product-card-color-star-rating": 1,
        "components-product-card-color-badge-bg": 1,
        "components-product-card-color-badge-text": 1,
        "components-product-card-listing-color-bg": 1,
        "components-product-card-listing-color-title": 1,
        "components-product-card-listing-color-copy": 1,
        "components-product-card-listing-color-legal-text": 1,
        "components-product-information-color-bg": 1,
        "components-product-information-color-content-bg": 1,
        "components-product-information-color-headline": 1,
        "components-product-information-color-body": 1,
        "components-product-information-color-detail-text": 1,
        "components-product-information-color-divider": 1,
        "components-product-information-color-icon": 1,
        "components-product-information-color-play-icon": 1,
        "components-product-information-color-selected-stroke": 1,
        "components-product-results-list-with-search-color-bg": 1,
        "components-promo-color-default-bg": 1,
        "components-promo-color-default-image-bg": 1,
        "components-promo-color-default-content-bg": 1,
        "components-promo-color-default-title": 1,
        "components-promo-color-default-body": 1,
        "components-promo-color-default-category": 1,
        "components-promo-color-light-bg": 1,
        "components-promo-color-light-image-bg": 1,
        "components-promo-color-light-content-bg": 1,
        "components-promo-color-light-title": 1,
        "components-promo-color-light-body": 1,
        "components-promo-color-light-category": 1,
        "components-promo-color-brand-bg": 1,
        "components-promo-color-brand-image-bg": 1,
        "components-promo-color-brand-content-bg": 1,
        "components-promo-color-brand-title": 1,
        "components-promo-color-brand-body": 1,
        "components-promo-color-brand-category": 1,
        "components-pull-quote-color-bg": 1,
        "components-pull-quote-color-stroke": 1,
        "components-pull-quote-color-quote-bg": 1,
        "components-pull-quote-color-quote-title": 1,
        "components-pull-quote-color-quote-by-text": 1,
        "components-pull-quote-color-quote-detail": 1,
        "components-pull-quote-color-quote-highlight": 1,
        "components-promo-alternate-color-default-bg": 1,
        "components-promo-alternate-color-default-image-bg": 1,
        "components-promo-alternate-color-default-content-bg": 1,
        "components-promo-alternate-color-default-title": 1,
        "components-promo-alternate-color-default-body": 1,
        "components-promo-alternate-color-default-category": 1,
        "components-promo-alternate-color-light-bg": 1,
        "components-promo-alternate-color-light-image-bg": 1,
        "components-promo-alternate-color-light-content-bg": 1,
        "components-promo-alternate-color-light-title": 1,
        "components-promo-alternate-color-light-body": 1,
        "components-promo-alternate-color-light-category": 1,
        "components-promo-alternate-color-brand-bg": 1,
        "components-promo-alternate-color-brand-image-bg": 1,
        "components-promo-alternate-color-brand-content-bg": 1,
        "components-promo-alternate-color-brand-title": 1,
        "components-promo-alternate-color-brand-body": 1,
        "components-promo-alternate-color-brand-category": 1,
        "components-share-list-color-title": 1,
        "components-share-list-color-bg": 1,
        "components-share-list-color-link-default": 1,
        "components-share-list-color-link-hover": 1,
        "components-share-list-color-bg-link-hover": 1,
        "components-share-list-color-close-icon": 1,
        "components-share-list-color-facebook-icon": 1,
        "components-share-list-color-pinterest-icon": 1,
        "components-share-list-color-twitter-icon": 1,
        "components-share-list-color-basic-icon-default": 1,
        "components-share-list-color-icon-hover": 1,
        "components-share-list-color-border": 1,
        "components-site-search-bg": 1,
        "components-site-search-results_text": 1,
        "components-site-search-filter-filter-selected-bg": 1,
        "components-site-search-facet-group-label-bottom-line": 1,
        "components-site-search-facet-group-label-text": 1,
        "components-site-search-facet-group-label-bg": 1,
        "components-site-search-facet-bg": 1,
        "components-site-search-facet-stroke": 1,
        "components-site-search-refine-bg": 1,
        "components-site-search-refine-right-divider": 1,
        "components-site-search-refine-title": 1,
        "components-site-search-hero-search-bg": 1,
        "components-site-search-hero-search-accent-bg": 1,
        "components-site-search-hero-search-title": 1,
        "components-stats-stat-text": 1,
        "components-stats-stat-text-on-light": 1,
        "components-stats-supporting-text": 1,
        "components-stats-bg-default": 1,
        "components-stats-bg-inner": 1,
        "components-stats-bg-section-inner": 1,
        "components-stats-title": 1,
        "components-stats-copy": 1,
        "components-supporting-text-default": 1,
        "components-supporting-text-destructive": 1,
        "components-tab-bg": 1,
        "components-tab-border-default": 1,
        "components-tab-border-hover": 1,
        "components-tab-border-focused": 1,
        "components-tab-border-destructive": 1,
        "components-tab-label-text-default": 1,
        "components-tab-item-text-default": 1,
        "components-tab-item-bg-default": 1,
        "components-tab-item-bg-hover": 1,
        "components-tab-input-field-default": 1,
        "components-tab-input-field-focused": 1,
        "components-tab-input-label-focused": 1,
        "components-tab-input-field-destructive": 1,
        "components-tab-bg-2": 1,
        "components-tag-color-bg": 1,
        "components-tag-color-text": 1,
        "components-tag-color-stroke": 1,
        "components-tag-color-bg-hover": 1,
        "components-tag-color-text-hover": 1,
        "components-tag-color-stroke-hover": 1,
        "components-text-field-bg": 1,
        "components-text-field-bg-focused": 1,
        "components-text-field-border-default": 1,
        "components-text-field-border-hover": 1,
        "components-text-field-border-focused": 1,
        "components-text-field-border-destructive": 1,
        "components-text-field-input-field-default": 1,
        "components-text-field-input-field-focused": 1,
        "components-text-field-input-label-focused": 1,
        "components-text-field-input-field-destructive": 1,
        "components-text-field-icon-default": 1,
        "components-text-field-icon-destructive": 1,
        "components-text-field-supporting-text-default": 1,
        "components-text-field-supporting-text-destructive": 1,
        "components-tip-color-bg": 1,
        "components-tip-color-title": 1,
        "components-tip-color-body": 1,
        "components-tip-color-stroke": 1,
        "components-video-color-bg": 1,
        "themes-general-surface-color-light-bg": 1,
        "themes-general-surface-color-light-title": 1,
        "themes-general-surface-color-light-body": 1,
        "themes-general-surface-color-light-decor-line-break": null,
        "themes-general-surface-color-light-rule-line": null,
        "themes-general-surface-color-dark-bg": 1,
        "themes-general-surface-color-dark-title": 1,
        "themes-general-surface-color-dark-body": 1,
        "themes-general-surface-color-dark-decor-line-break": null,
        "themes-general-card-color-dark-title": 1,
        "themes-general-card-color-dark-subtitle": 1,
        "themes-general-card-color-dark-body": 1,
        "themes-general-card-color-light-title": 1,
        "themes-general-card-color-light-subtitle": 1,
        "themes-general-card-color-light-body": 1,
        "themes-shadow-color": 1,
        "themes-shadow-share-list-color-fill": 0,
        "themes-shadow-header-menu-color-fill": 0,
        "themes-gradient-loader-gradient-fill-1": 1,
        "themes-gradient-loader-gradient-fill-2": null,
        "themes-color-background-white": 1,
        "themes-color-background-light": 1,
        "themes-color-background-dark": 1,
        "themes-color-background-black": 1,
        "themes-color-background-brand1": 1,
        "themes-color-background-brand2": 1,
        "themes-color-background-accent1": 1,
        "themes-color-background-accent2": 1,
        "themes-color-background-body": 1,
        "themes-color-stroke-white": 1,
        "themes-color-stroke-light": 1,
        "themes-color-stroke-dark": 1,
        "themes-color-stroke-black": 1,
        "themes-color-stroke-brand1": 1,
        "themes-color-stroke-brand2": 1,
        "themes-color-stroke-accent1": 1,
        "themes-color-stroke-accent2": 1,
        "themes-color-text-white": 1,
        "themes-color-text-light": 1,
        "themes-color-text-dark": 1,
        "themes-color-text-black": 1,
        "themes-color-text-brand1": 1,
        "themes-color-text-brand2": 1,
        "themes-color-text-accent1": 1,
        "themes-color-text-accent2": 1,
        "colors-grayscale-100": 1,
        "colors-grayscale-200": 1,
        "colors-grayscale-300": 1,
        "colors-grayscale-400": 1,
        "colors-grayscale-500": 1,
        "colors-grayscale-600": 1,
        "colors-grayscale-700": 1,
        "colors-brand-1-100": 1,
        "colors-brand-1-200": 1,
        "colors-brand-1-300": 1,
        "colors-brand-1-400": 1,
        "colors-brand-1-500": 1,
        "colors-brand-1-600": 1,
        "colors-brand-1-700": 1,
        "colors-brand-2-100": 1,
        "colors-brand-2-200": 1,
        "colors-brand-2-300": 1,
        "colors-brand-2-400": 1,
        "colors-brand-2-500": 1,
        "colors-brand-2-600": 1,
        "colors-brand-2-700": 1,
        "colors-accent-1-100": 1,
        "colors-accent-1-200": 1,
        "colors-accent-1-300": 1,
        "colors-accent-1-400": 1,
        "colors-accent-1-500": 1,
        "colors-accent-1-600": 1,
        "colors-accent-1-700": 1,
        "colors-accent-2-100": 1,
        "colors-accent-2-200": 1,
        "colors-accent-2-300": 1,
        "colors-accent-2-400": 1,
        "colors-accent-2-500": 1,
        "colors-accent-2-600": 1,
        "colors-accent-2-700": 1,
        "colors-accent-3-100": 1,
        "colors-accent-3-200": 1,
        "colors-accent-3-300": 1,
        "colors-accent-3-400": 1,
        "colors-accent-3-500": 1,
        "colors-accent-3-600": 1,
        "colors-accent-3-700": 1,
        "colors-accent-4-400_storage": 1,
        "colors-accessible-accent-1-100": 1,
        "colors-accessible-accent-1-200": 1,
        "colors-accessible-accent-1-300": 1,
        "colors-accessible-accent-1-400": 1,
        "colors-accessible-accent-1-500": 1,
        "colors-accessible-accent-1-600": 1,
        "colors-accessible-accent-1-700": 1,
        "colors-accessible-accent-2-100": 1,
        "colors-accessible-accent-2-200": 1,
        "colors-accessible-accent-2-300": 1,
        "colors-accessible-accent-2-400": 1,
        "colors-accessible-accent-2-500": 1,
        "colors-accessible-accent-2-600": 1,
        "colors-accessible-accent-2-700": 1,
        "colors-accessible-brand-2-100": 1,
        "colors-accessible-brand-2-200": 1,
        "colors-accessible-brand-2-300": 1,
        "colors-accessible-brand-2-400": 1,
        "colors-accessible-brand-2-500": 1,
        "colors-accessible-brand-2-600": 1,
        "colors-accessible-brand-2-700": 1
      }
    },
    "MrMuscle": {
      "colors": {
        "components-accessibility-bar-color-bg": "#f0f3ff",
        "components-accessibility-bar-color-text": "#191b1c",
        "components-accordion-color-bg": "#001871",
        "components-accordion-color-title": "#191b1c",
        "components-accordion-color-body": "#27282b",
        "components-accordion-color-section-section-bg": "#ffffff",
        "components-accordion-color-section-section-title": "#191b1c",
        "components-accordion-color-section-section-body": "#27282b",
        "components-accordion-color-item-item-bg": "#ffffff",
        "components-accordion-color-item-divider": "#e8e8ea",
        "components-accordion-color-item-icon-icon-default": "#fe5000",
        "components-accordion-color-item-icon-icon-hover": "#ffffff",
        "components-accordion-color-item-icon-icon-pressed": "#ffffff",
        "components-accordion-color-item-icon-icon-active": "#ffffff",
        "components-accordion-color-item-title-item-default": "#191b1c",
        "components-accordion-color-item-title-item-hover": "#ffffff",
        "components-accordion-color-item-title-item-pressed": "#ffffff",
        "components-accordion-color-item-title-item-active": "#ffffff",
        "components-accordion-color-content-bg": "#ffffff",
        "components-accordion-color-content-title": "#191b1c",
        "components-accordion-color-content-body": "#27282b",
        "components-article-card-color-bg": "#ffffff",
        "components-article-card-color-border": "#e8e8ea",
        "components-article-card-color-content-bg": "#ffffff",
        "components-article-card-color-title": "#191b1c",
        "components-article-card-color-subtitle": "#27282b",
        "components-article-card-color-copy": "#27282b",
        "components-article-card-color-description": "#27282b",
        "components-article-card-listing-color-default-bg": "#ffffff",
        "components-article-card-listing-color-default-title": "#191b1c",
        "components-article-card-listing-color-default-copy": "#27282b",
        "components-article-hero-color-content-bg": "#ffffff",
        "components-article-hero-color-bg": "#ffffff",
        "components-article-hero-color-headline": "#191b1c",
        "components-article-hero-color-body": "#27282b",
        "components-article-hero-color-detail-text": "#27282b",
        "components-article-hero-color-divider": "#a2a5ab",
        "components-article-hero-color-icon": "#fe5000",
        "components-article-hero-color-play-icon": "#ffffff",
        "components-article-hero-color-selected-stroke": "#001871",
        "components-banner-color-bg-default": "#001871",
        "components-banner-color-icon-default": "#fe5000",
        "components-banner-color-title-default": "#ffffff",
        "components-banner-color-body-default": "#ffffff",
        "components-banner-color-icon-close-default": "#ffffff",
        "components-banner-color-bg-alert": "#b32b30",
        "components-banner-color-icon-alert": "#ffffff",
        "components-banner-color-title-alert": "#ffffff",
        "components-banner-color-body-alert": "#ffffff",
        "components-banner-color-icon-close-alert": "#ffffff",
        "components-breadcrumb-color-bg": "#ffffff",
        "components-breadcrumb-color-breadcrumb-link-default": "#72757e",
        "components-breadcrumb-color-breadcrumb-link-hover": "#4e5056",
        "components-breadcrumb-color-breadcrumb-link-active": "#27282b",
        "components-breadcrumb-color-icon-divider": "#72757e",
        "components-bug-finder-prompt": "#ffffff",
        "components-button-color-filled-brand-default-bg": "#001871",
        "components-button-color-filled-brand-default-text": "#ffffff",
        "components-button-color-filled-brand-default-stroke": "#001871",
        "components-button-color-filled-brand-default-icon": "#ffffff",
        "components-button-color-filled-brand-hover-bg": "#001871",
        "components-button-color-filled-brand-hover-text": "#ffffff",
        "components-button-color-filled-brand-hover-stroke": "#001871",
        "components-button-color-filled-brand-hover-icon": "#ffffff",
        "components-button-color-filled-brand-pressed-bg": "#001871",
        "components-button-color-filled-brand-pressed-text": "#ffffff",
        "components-button-color-filled-brand-pressed-stroke": "#001871",
        "components-button-color-filled-brand-pressed-icon": "#ffffff",
        "components-button-color-filled-brand-disabled-bg": "rgba(0, 24, 113, 0.38)",
        "components-button-color-filled-brand-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-disabled-stroke": "rgba(0, 24, 113, 0.38)",
        "components-button-color-filled-brand-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-focus-bg": "#001871",
        "components-button-color-filled-brand-focus-text": "#ffffff",
        "components-button-color-filled-brand-focus-stroke": "#001871",
        "components-button-color-filled-brand-focus-icon": "#ffffff",
        "components-button-color-filled-brand-focus-outline": "#286efa",
        "components-button-color-filled-tonal-default-bg": "#ffb999",
        "components-button-color-filled-tonal-default-text": "#983000",
        "components-button-color-filled-tonal-default-stroke": "#ffb999",
        "components-button-color-filled-tonal-default-icon": "#983000",
        "components-button-color-filled-tonal-hover-bg": "#983000",
        "components-button-color-filled-tonal-hover-text": "#ffffff",
        "components-button-color-filled-tonal-hover-stroke": "#983000",
        "components-button-color-filled-tonal-hover-icon": "#ffffff",
        "components-button-color-filled-tonal-pressed-bg": "#983000",
        "components-button-color-filled-tonal-pressed-text": "#ffffff",
        "components-button-color-filled-tonal-pressed-stroke": "#983000",
        "components-button-color-filled-tonal-pressed-icon": "#ffffff",
        "components-button-color-filled-tonal-disabled-bg": "rgba(255, 185, 153, 0.38)",
        "components-button-color-filled-tonal-disabled-text": "rgba(209, 67, 0, 0.38)",
        "components-button-color-filled-tonal-disabled-stroke": "rgba(255, 185, 153, 0.38)",
        "components-button-color-filled-tonal-disabled-icon": "rgba(209, 67, 0, 0.38)",
        "components-button-color-filled-tonal-focus-bg": "#ffb999",
        "components-button-color-filled-tonal-focus-text": "#983000",
        "components-button-color-filled-tonal-focus-stroke": "#ffb999",
        "components-button-color-filled-tonal-focus-icon": "#983000",
        "components-button-color-filled-tonal-focus-outline": "#1c4cae",
        "components-button-color-filled-white-default-bg": "#ffffff",
        "components-button-color-filled-white-default-text": "#001871",
        "components-button-color-filled-white-default-stroke": "#ffffff",
        "components-button-color-filled-white-default-icon": "#001871",
        "components-button-color-filled-white-hover-bg": "#fcfcfd",
        "components-button-color-filled-white-hover-text": "#001871",
        "components-button-color-filled-white-hover-stroke": "#fcfcfd",
        "components-button-color-filled-white-hover-icon": "#001871",
        "components-button-color-filled-white-pressed-bg": "#e8e8ea",
        "components-button-color-filled-white-pressed-text": "#00114f",
        "components-button-color-filled-white-pressed-stroke": "#e8e8ea",
        "components-button-color-filled-white-pressed-icon": "#00114f",
        "components-button-color-filled-white-disabled-bg": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-text": "rgba(0, 24, 113, 0.38)",
        "components-button-color-filled-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-icon": "rgba(0, 24, 113, 0.38)",
        "components-button-color-filled-white-focus-bg": "#ffffff",
        "components-button-color-filled-white-focus-text": "#001871",
        "components-button-color-filled-white-focus-stroke": "#ffffff",
        "components-button-color-filled-white-focus-icon": "#001871",
        "components-button-color-filled-white-focus-outline": "#1c4cae",
        "components-button-color-outline-brand-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-brand-default-text": "#00114f",
        "components-button-color-outline-brand-default-stroke": "#001871",
        "components-button-color-outline-brand-default-icon": "#00114f",
        "components-button-color-outline-brand-hover-bg": "#001871",
        "components-button-color-outline-brand-hover-text": "#ffffff",
        "components-button-color-outline-brand-hover-stroke": "#001871",
        "components-button-color-outline-brand-hover-icon": "#ffffff",
        "components-button-color-outline-brand-pressed-bg": "#001871",
        "components-button-color-outline-brand-pressed-text": "#ffffff",
        "components-button-color-outline-brand-pressed-stroke": "#001871",
        "components-button-color-outline-brand-pressed-icon": "#ffffff",
        "components-button-color-outline-brand-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-brand-disabled-text": "rgba(0, 17, 79, 0.38)",
        "components-button-color-outline-brand-disabled-stroke": "rgba(0, 24, 113, 0.38)",
        "components-button-color-outline-brand-disabled-icon": "rgba(0, 17, 79, 0.38)",
        "components-button-color-outline-brand-focus-bg": "#ffffff",
        "components-button-color-outline-brand-focus-text": "#00114f",
        "components-button-color-outline-brand-focus-stroke": "#001871",
        "components-button-color-outline-brand-focus-icon": "#00114f",
        "components-button-color-outline-brand-focus-outline": "#286efa",
        "components-button-color-outline-tonal-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-tonal-default-text": "#983000",
        "components-button-color-outline-tonal-default-stroke": "#fe5000",
        "components-button-color-outline-tonal-default-icon": "#983000",
        "components-button-color-outline-tonal-hover-bg": "#d14300",
        "components-button-color-outline-tonal-hover-text": "#ffffff",
        "components-button-color-outline-tonal-hover-stroke": "#d14300",
        "components-button-color-outline-tonal-hover-icon": "#ffffff",
        "components-button-color-outline-tonal-pressed-bg": "#983000",
        "components-button-color-outline-tonal-pressed-text": "#ffffff",
        "components-button-color-outline-tonal-pressed-stroke": "#983000",
        "components-button-color-outline-tonal-pressed-icon": "#ffffff",
        "components-button-color-outline-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-tonal-disabled-text": "rgba(209, 67, 0, 0.38)",
        "components-button-color-outline-tonal-disabled-stroke": "rgba(254, 80, 0, 0.38)",
        "components-button-color-outline-tonal-disabled-icon": "rgba(209, 67, 0, 0.38)",
        "components-button-color-outline-tonal-focus-bg": "rgba(255, 255, 255, 0.01)",
        "components-button-color-outline-tonal-focus-text": "#983000",
        "components-button-color-outline-tonal-focus-stroke": "#fe5000",
        "components-button-color-outline-tonal-focus-icon": "#983000",
        "components-button-color-outline-tonal-focus-outline": "#286efa",
        "components-button-color-outline-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-default-text": "#ffffff",
        "components-button-color-outline-white-default-stroke": "#ffffff",
        "components-button-color-outline-white-default-icon": "#ffffff",
        "components-button-color-outline-white-hover-bg": "#ffffff",
        "components-button-color-outline-white-hover-text": "#001871",
        "components-button-color-outline-white-hover-stroke": "#ffffff",
        "components-button-color-outline-white-hover-icon": "#001871",
        "components-button-color-outline-white-pressed-bg": "#ffffff",
        "components-button-color-outline-white-pressed-text": "#001871",
        "components-button-color-outline-white-pressed-stroke": "#ffffff",
        "components-button-color-outline-white-pressed-icon": "#001871",
        "components-button-color-outline-white-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-focus-bg": "rgba(255, 255, 255, 0.01)",
        "components-button-color-outline-white-focus-text": "#ffffff",
        "components-button-color-outline-white-focus-stroke": "#ffffff",
        "components-button-color-outline-white-focus-icon": "#ffffff",
        "components-button-color-outline-white-focus-outline": "#286efa",
        "components-button-color-text-brand-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-default-text": "#00114f",
        "components-button-color-text-brand-default-stroke": "#001871",
        "components-button-color-text-brand-default-icon": "#00114f",
        "components-button-color-text-brand-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-hover-bg-icon-only": "#e8e8ea",
        "components-button-color-text-brand-hover-text": "#001871",
        "components-button-color-text-brand-hover-stroke": "#001871",
        "components-button-color-text-brand-hover-icon": "#001871",
        "components-button-color-text-brand-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-pressed-bg-icon-only": "#a2a5ab",
        "components-button-color-text-brand-pressed-text": "#001871",
        "components-button-color-text-brand-pressed-stroke": "#001871",
        "components-button-color-text-brand-pressed-icon": "#001871",
        "components-button-color-text-brand-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-disabled-text": "rgba(39, 40, 43, 0.38)",
        "components-button-color-text-brand-disabled-stroke": "rgba(39, 40, 43, 0.38)",
        "components-button-color-text-brand-disabled-icon": "rgba(39, 40, 43, 0.38)",
        "components-button-color-text-brand-focus-bg": "#d5e3ff",
        "components-button-color-text-brand-focus-text": "#00114f",
        "components-button-color-text-brand-focus-stroke": "#27282b",
        "components-button-color-text-brand-focus-icon": "#1c4cae",
        "components-button-color-text-brand-focus-outline": "#1c4cae",
        "components-button-color-text-tonal-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-default-text": "#983000",
        "components-button-color-text-tonal-default-stroke": "#d14300",
        "components-button-color-text-tonal-default-icon": "#983000",
        "components-button-color-text-tonal-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-hover-bg-icon-only": "#fcfcfd",
        "components-button-color-text-tonal-hover-text": "#471600",
        "components-button-color-text-tonal-hover-stroke": "#d14300",
        "components-button-color-text-tonal-hover-icon": "#471600",
        "components-button-color-text-tonal-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-pressed-bg-icon-only": "#e8e8ea",
        "components-button-color-text-tonal-pressed-text": "#471600",
        "components-button-color-text-tonal-pressed-stroke": "#d14300",
        "components-button-color-text-tonal-pressed-icon": "#471600",
        "components-button-color-text-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-disabled-text": "rgba(209, 67, 0, 0.38)",
        "components-button-color-text-tonal-disabled-stroke": "rgba(209, 67, 0, 0.38)",
        "components-button-color-text-tonal-disabled-icon": "rgba(209, 67, 0, 0.38)",
        "components-button-color-text-tonal-focus-bg": "#d5e3ff",
        "components-button-color-text-tonal-focus-text": "#1c4cae",
        "components-button-color-text-tonal-focus-stroke": "#d14300",
        "components-button-color-text-tonal-focus-icon": "#1c4cae",
        "components-button-color-text-tonal-focus-outline": "#1c4cae",
        "components-button-color-text-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-default-text": "#ffffff",
        "components-button-color-text-white-default-stroke": "#ffffff",
        "components-button-color-text-white-default-icon": "#ffffff",
        "components-button-color-text-white-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-hover-bg-icon-only": "#4e5056",
        "components-button-color-text-white-hover-text": "#ffffff",
        "components-button-color-text-white-hover-stroke": "#ffffff",
        "components-button-color-text-white-hover-icon": "#ffffff",
        "components-button-color-text-white-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-pressed-bg-icon-only": "#4e5056",
        "components-button-color-text-white-pressed-text": "#ffffff",
        "components-button-color-text-white-pressed-stroke": "#ffffff",
        "components-button-color-text-white-pressed-icon": "#ffffff",
        "components-button-color-text-white-disabled-bg": "rgba(115, 119, 128, 0.00)",
        "components-button-color-text-white-disabled-text": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-stroke": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-icon": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-focus-bg": "#d5e3ff",
        "components-button-color-text-white-focus-text": "#ffffff",
        "components-button-color-text-white-focus-stroke": "#ffffff",
        "components-button-color-text-white-focus-icon": "#1c4cae",
        "components-button-color-text-white-focus-outline": "#1c4cae",
        "components-button-group-color-border": "#e8e8ea",
        "components-button-group-color-button-bg-default": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-button-fg-default": "#4e5056",
        "components-button-group-color-button-bg-hover": "#fcfcfd",
        "components-button-group-color-button-fg-hover": "#00114f",
        "components-button-group-color-button-bg-active": "#fcfcfd",
        "components-button-group-color-button-fg-active": "#00114f",
        "components-button-group-color-overlay-left-gradient-fill-1": "#ffffff",
        "components-button-group-color-overlay-left-gradient-fill-2": "rgba(255, 255, 255, 0.50)",
        "components-button-group-color-stroke-default-gradient-fill-1": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-2": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-3": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-4": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-1": "#e8e8ea",
        "components-button-group-color-stroke-hover-gradient-fill-2": "#e8e8ea",
        "components-button-group-color-stroke-hover-gradient-fill-3": "#e8e8ea",
        "components-button-group-color-stroke-hover-gradient-fill-4": "#e8e8ea",
        "components-button-group-color-stroke-active-gradient-fill-1": "#e8e8ea",
        "components-button-group-color-stroke-active-gradient-fill-2": "#72757e",
        "components-button-group-color-stroke-active-gradient-fill-3": "#e8e8ea",
        "components-button-group-color-stroke-active-gradient-fill-4": "#a2a5ab",
        "components-category-card-color-bg": "#ffffff",
        "components-category-card-color-content-bg": "#ffffff",
        "components-category-card-color-category-bg": "#ffffff",
        "components-category-card-color-category-bg-2": "#ffffff",
        "components-category-card-color-category-bg-3": "#ffffff",
        "components-category-card-color-category-bg-4": "#ffffff",
        "components-category-card-color-category-bg-5": "#ffffff",
        "components-category-card-color-category-bg-6": "#ffffff",
        "components-category-card-color-title": "#191b1c",
        "components-category-card-color-copy": "#27282b",
        "components-category-card-color-stroke": "#e8e8ea",
        "components-category-card-color-stroke-hover": "#001871",
        "components-category-card-listing-color-bg": "#f0f3ff",
        "components-category-card-listing-color-title": "#191b1c",
        "components-category-card-listing-color-copy": "#27282b",
        "components-card-search-result-color-bg": "#ffffff",
        "components-card-search-result-color-label": "#27282b",
        "components-card-search-result-color-title": "#191b1c",
        "components-card-search-result-color-body": "#27282b",
        "components-card-search-result-color-divider-line": "#e8e8ea",
        "components-contact-us-promo-color-light-bg": "#f0f3ff",
        "components-contact-us-promo-color-light-title": "#191b1c",
        "components-contact-us-promo-color-light-body": "#27282b",
        "components-contact-us-promo-color-default-bg": "#ffffff",
        "components-contact-us-promo-color-default-title": "#191b1c",
        "components-contact-us-promo-color-default-body": "#27282b",
        "components-contact-us-promo-color-brand-bg": "#d14300",
        "components-contact-us-promo-color-brand-title": "#ffffff",
        "components-contact-us-promo-color-brand-body": "#fcfcfd",
        "components-content-list-color-bg": "#ffffff",
        "components-content-list-color-title": "#191b1c",
        "components-content-list-color-body": "#27282b",
        "components-content-list-color-icon": "#fe5000",
        "components-cookies-colors-bg": "#f0f3ff",
        "components-cookies-colors-title": "#191b1c",
        "components-cookies-colors-body": "#27282b",
        "components-cookies-colors-icon": "#d14300",
        "components-dropdown-bg": "#ffffff",
        "components-dropdown-border-default": "#72757e",
        "components-dropdown-border-hover": "#fe5000",
        "components-dropdown-border-focused": "#191b1c",
        "components-dropdown-border-destructive": "#b32b30",
        "components-dropdown-label-text-default": "#191b1c",
        "components-dropdown-item-text-default": "#191b1c",
        "components-dropdown-item-bg-default": "#ffffff",
        "components-dropdown-item-bg-hover": "#fff1eb",
        "components-dropdown-input-field-default": "#72757e",
        "components-dropdown-input-field-focused": "#191b1c",
        "components-dropdown-input-label-focused": "#191b1c",
        "components-dropdown-input-field-destructive": "#b32b30",
        "components-external-link-card-bg": "#f0f3ff",
        "components-external-link-card-card-bg": "#ffffff",
        "components-external-link-card-card-title": "#27282b",
        "components-external-link-card-card-subtitle": "#27282b",
        "components-external-link-card-card-eyebrow": "#4e5056",
        "components-fab-color-border-default": "#ffffff",
        "components-footer-brand-color-bg": "#000c39",
        "components-footer-brand-color-logo": "#ffffff",
        "components-footer-brand-color-title-text": "#ffffff",
        "components-footer-brand-color-description-text": "#ffffff",
        "components-footer-brand-color-footer-menu-expanded": "#000c39",
        "components-footer-brand-color-link-default": "#ffffff",
        "components-footer-brand-color-link-hover": "#e8e8ea",
        "components-footer-brand-color-link-pressed": "#e8e8ea",
        "components-footer-brand-color-category-link-default": "#ffffff",
        "components-footer-brand-color-category-link-hover": "#e8e8ea",
        "components-footer-brand-color-category-link-pressed": "#e8e8ea",
        "components-footer-brand-color-subcategory-link-default": "#ffffff",
        "components-footer-brand-color-subcategory-link-hover": "#e8e8ea",
        "components-footer-brand-color-subcategory-link-pressed": "#e8e8ea",
        "components-footer-brand-color-icon-icon-default": "#ffffff",
        "components-footer-brand-color-icon-icon-pressed": "#e8e8ea",
        "components-footer-brand-color-social-icon-default": "#ffffff",
        "components-footer-brand-color-social-icon-hover": "#e8e8ea",
        "components-footer-brand-color-social-icon-pressed": "#e8e8ea",
        "components-footer-brand-spacing-category-link-hover": "#000000",
        "components-footer-global-color-bg": "#ffffff",
        "components-footer-global-color-title-text": "#000000",
        "components-footer-global-color-disclaimer-text": "#000000",
        "components-footer-global-color-link-default": "#000000",
        "components-footer-global-color-link-hover": "#000000",
        "components-footer-global-color-link-pressed": "#000000",
        "components-generic-card-color-bg": "#ffffff",
        "components-generic-card-color-content-bg": "#ffffff",
        "components-generic-card-color-title": "#191b1c",
        "components-generic-card-color-subtitle": "#27282b",
        "components-generic-card-color-copy": "#27282b",
        "components-global-search-color-omnibox-list-bg": "#ffffff",
        "components-global-search-color-omnibox-divider": "#a2a5ab",
        "components-global-search-color-omnibox-item-bg-default": "#ffffff",
        "components-global-search-color-omnibox-item-bg-hover": "#f0f3ff",
        "components-global-search-color-omnibox-item-text-default": "#001871",
        "components-global-search-color-omnibox-item-text-hover": "#00114f",
        "components-global-search-color-omnibox-label-text-default": "#4e5056",
        "components-global-search-color-omnibox-category-text-default": "#4e5056",
        "components-generic-card-listing-color-bg": "#f0f3ff",
        "components-generic-card-listing-color-title": "#191b1c",
        "components-generic-card-listing-color-copy": "#27282b",
        "components-header-color-bg": "#ffffff",
        "components-header-color-bottom-stroke": "#e8e8ea",
        "components-header-color-search-search-border-default": "#191b1c",
        "components-header-color-search-search-text": "#4e5056",
        "components-header-color-search-search-icon": "#4e5056",
        "components-header-color-search-search-bar": "#191b1c",
        "components-header-color-mobile-mobile-expanded-bg": "#ffffff",
        "components-header-color-mobile-pressed-bg": "#ffb999",
        "components-header-color-language-selector-language": "#4e5056",
        "components-header-color-language-selector-country": "#191b1c",
        "components-header-color-language-selector-country-hover-bg": "#e8e8ea",
        "components-header-color-language-selector-megamenu-language": "#4e5056",
        "components-header-color-language-selector-megamenu-country": "#191b1c",
        "components-header-color-language-selector-megamenu-country-hover": "#e8e8ea",
        "components-header-color-language-selector-bg": "#ffffff",
        "components-header-color-language-selector-stroke": "#72757e",
        "components-header-color-mega-menu-bg": "#ffffff",
        "components-header-color-mega-menu-featured-bg": "#001871",
        "components-header-color-mega-menu-featured-title": "#ffffff",
        "components-header-color-mega-menu-category-title": "#27282b",
        "components-header-color-mega-menu-bg-accent": "#ffffff",
        "components-header-color-icon-icon-default": "#4e5056",
        "components-header-color-icon-icon-hover": "#191b1c",
        "components-header-color-icon-icon-pressed": "#000000",
        "components-header-color-links-nav-main-link-default": "#4e5056",
        "components-header-color-links-nav-main-link-hover": "#191b1c",
        "components-header-color-links-nav-main-link-pressed": "#000000",
        "components-header-color-links-nav-main-link-active": "#000000",
        "components-header-color-links-nav-main-active-bar": "#fe5000",
        "components-header-color-links-nav-main-bg-color": "#ffffff",
        "components-header-color-links-nav-category-link-category-default": "#4e5056",
        "components-header-color-links-nav-category-link-category-hover": "#191b1c",
        "components-header-color-links-nav-category-link-category-pressed": "#000000",
        "components-header-color-links-nav-category-link-category-active": "#000000",
        "components-header-color-links-nav-menu-link-link-default": "#4e5056",
        "components-header-color-links-nav-menu-link-link-hover": "#191b1c",
        "components-header-color-links-nav-menu-link-link-pressed": "#000000",
        "components-header-color-links-nav-menu-link-link-active": "#000000",
        "components-header-color-links-nav-menu-link-bg": "#ffffff",
        "components-header-color-links-nav-subcategory-link-subcategory-default": "#4e5056",
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": "#000000",
        "components-header-color-links-nav-subcategory-link-subcategory-active": "#000000",
        "components-hero-homepage-color-bg": "#d14300",
        "components-hero-homepage-color-container-bg": "#d14300",
        "components-hero-homepage-color-eyebrow": "#72757e",
        "components-hero-homepage-color-header": "#ffffff",
        "components-hero-homepage-color-sub-header": "#fcfcfd",
        "components-hero-homepage-color-body": "#fcfcfd",
        "components-hero-homepage-color-play-pause": "#ffffff",
        "components-hero-internal-color-default-title": "#ffffff",
        "components-hero-internal-color-default-body": "#fcfcfd",
        "components-hero-internal-color-default-bg": "#d14300",
        "components-hero-internal-color-accent-bg": "#d14300",
        "components-hero-internal-color-with-image-bg": "#d14300",
        "components-hero-internal-color-default-bg-2": "#d14300",
        "components-hero-internal-color-with-image-title": "#ffffff",
        "components-hero-internal-color-with-image-body": "#fcfcfd",
        "components-heading-page-title-color-default-bg": "#001871",
        "components-heading-page-title-color-default-title": "#ffffff",
        "components-heading-page-title-color-default-body": "#fcfcfd",
        "components-heading-page-title-color-light-bg": "#ffffff",
        "components-heading-page-title-color-light-title": "#191b1c",
        "components-heading-page-title-color-light-body": "#27282b",
        "components-heading-page-title-color-color-bg": "#d14300",
        "components-heading-page-title-color-color-title": "#ffffff",
        "components-heading-page-title-color-color-body": "#ffffff",
        "components-hyperlink-color-dark-default": "#001871",
        "components-hyperlink-color-dark-hover": "#000c39",
        "components-hyperlink-color-dark-visited": "#41b6e6",
        "components-hyperlink-color-light-default": "#8ca4f2",
        "components-hyperlink-color-light-hover": "#1a42d5",
        "components-hyperlink-color-light-visited": "#e6f2fa",
        "components-icon-default": "#72757e",
        "components-icon-focused": "#72757e",
        "components-icon-card-color-bg": "#ffffff",
        "components-icon-card-color-icon-bg": "#d14300",
        "components-icon-card-color-icon": "#191b1c",
        "components-icon-card-color-title": "#191b1c",
        "components-icon-card-color-subtitle": "#27282b",
        "components-icon-card-color-description": "#27282b",
        "components-icon-card-listing-color-bg": "#ffffff",
        "components-icon-card-listing-color-inner-bg": "#ffffff",
        "components-icon-card-listing-color-brand-bg": "#d14300",
        "components-icon-card-listing-color-title": "#191b1c",
        "components-icon-card-listing-color-copy": "#27282b",
        "components-icon-card-listing-color-inner-legal-text": "#ffffff",
        "components-jumplink-color-bg-default": "#ffffff",
        "components-jumplink-color-link-text-default": "#4e5056",
        "components-jumplink-color-link-text-hover": "#191b1c",
        "components-jumplink-color-link-border-hover": "#191b1c",
        "components-jumplink-color-link-text-active": "#000000",
        "components-jumplink-color-link-border-active": "#fe5000",
        "components-jumplink-color-title-text": "#191b1c",
        "components-jumplink-color-description-text": "#27282b",
        "components-layout-bg-default": "#ffffff",
        "components-lightbox-color-overlay-bg": "rgba(255, 255, 255, 0.95)",
        "components-lightbox-color-title": "#191b1c",
        "components-lightbox-color-body": "#27282b",
        "components-label-color-on-color-label-label-default": "#ffffff",
        "components-label-color-on-color-label-label-hover": "#191b1c",
        "components-label-color-on-color-label-label-pressed": "#191b1c",
        "components-label-color-on-color-background-bg-default": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-color-background-bg-hover": "#ffffff",
        "components-label-color-on-color-background-bg-pressed": "#e8e8ea",
        "components-label-color-on-color-stroke-hover": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-color-stroke-pressed": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-color-stroke-default-gradient-fill-1": "#e8e8ea",
        "components-label-color-on-color-stroke-default-gradient-fill-2": "#a2a5ab",
        "components-label-color-on-color-stroke-default-gradient-fill-3": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-4": "#a2a5ab",
        "components-label-color-on-white-label-label-default": "#4e5056",
        "components-label-color-on-white-label-label-hover": "#ffffff",
        "components-label-color-on-white-label-label-pressed": "#ffffff",
        "components-label-color-on-white-background-bg-default": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-white-background-bg-hover": "#4e5056",
        "components-label-color-on-white-background-bg-pressed": "#27282b",
        "components-label-color-on-white-stroke-hover": "#4e5056",
        "components-label-color-on-white-stroke-pressed": "#27282b",
        "components-label-color-on-white-stroke-default-gradient-fill-1": "#e8e8ea",
        "components-label-color-on-white-stroke-default-gradient-fill-2": "#72757e",
        "components-label-color-on-white-stroke-default-gradient-fill-3": "#e8e8ea",
        "components-label-color-on-white-stroke-default-gradient-fill-4": "#72757e",
        "components-legal-disclaimer-color-default-bg": "#f0f3ff",
        "components-legal-disclaimer-color-default-title": "#191b1c",
        "components-legal-disclaimer-color-default-body": "#27282b",
        "components-legal-disclaimer-color-light-bg": "#f0f3ff",
        "components-legal-disclaimer-color-light-title": "#191b1c",
        "components-legal-disclaimer-color-light-body": "#27282b",
        "components-legal-disclaimer-color-brand-bg": "#191b1c",
        "components-legal-disclaimer-color-brand-title": "#ffffff",
        "components-legal-disclaimer-color-brand-body": "#fcfcfd",
        "components-media-video-thumbnail-overlay-bg": "rgba(0, 61, 165, 0.70)",
        "components-media-caption-color-bg": "#fcfcfd",
        "components-media-caption-color-text": "#27282b",
        "components-media-gallery-color-bg": "#ffffff",
        "components-media-gallery-color-title": "#191b1c",
        "components-media-gallery-color-body": "#27282b",
        "components-pagination-on-white-accent-default": "#27282b",
        "components-pagination-on-white-accent-scroll": "#27282b",
        "components-pagination-on-white-disabled": "#a2a5ab",
        "components-pagination-on-white-bg": "rgba(162, 165, 171, 0.50)",
        "components-pagination-on-white-stroke": "#e8e8ea",
        "components-pagination-on-white-bg-active": "#001871",
        "components-pagination-on-white-bg-hover": "#e8e8ea",
        "components-pagination-on-white-text-default": "#72757e",
        "components-pagination-on-white-text-hover": "#27282b",
        "components-pagination-on-white-text-active": "#ffffff",
        "components-pagination-on-color-accent-default": "#ffffff",
        "components-pagination-on-color-accent-scroll": "#e8e8ea",
        "components-pagination-on-color-disabled": "#471600",
        "components-pagination-on-color-bg": "#1a42d5",
        "components-pagination-on-color-stroke": "rgba(255, 255, 255, 0.38)",
        "components-pagination-on-color-bg-active": "#ffffff",
        "components-pagination-on-color-bg-hover": "#27282b",
        "components-pagination-on-color-text-default": "#ffffff",
        "components-pagination-on-color-text-hover": "#ffffff",
        "components-pagination-on-color-text-active": "#191b1c",
        "components-pdf-link-card-color-bg": "#f0f3ff",
        "components-pdf-link-card-color-card-bg": "#ffffff",
        "components-pdf-link-card-color-card-title": "#191b1c",
        "components-pdf-link-card-color-card-subtitle": "#27282b",
        "components-pdf-link-card-color-card-body": "#27282b",
        "components-product-card-color-bg": "#ffffff",
        "components-product-card-color-content-bg": "#ffffff",
        "components-product-card-color-border": "#e8e8ea",
        "components-product-card-color-title": "#191b1c",
        "components-product-card-color-description": "#27282b",
        "components-product-card-color-star-rating": "#fe5000",
        "components-product-card-color-badge-bg": "#d14300",
        "components-product-card-color-badge-text": "#fcfcfd",
        "components-product-card-listing-color-bg": "#f0f3ff",
        "components-product-card-listing-color-title": "#191b1c",
        "components-product-card-listing-color-copy": "#27282b",
        "components-product-card-listing-color-legal-text": "#27282b",
        "components-product-information-color-bg": "#ffffff",
        "components-product-information-color-content-bg": "#ffffff",
        "components-product-information-color-headline": "#191b1c",
        "components-product-information-color-body": "#27282b",
        "components-product-information-color-detail-text": "#191b1c",
        "components-product-information-color-divider": "#a2a5ab",
        "components-product-information-color-icon": "#ffffff",
        "components-product-information-color-play-icon": "#ffffff",
        "components-product-information-color-selected-stroke": "#001871",
        "components-product-results-list-with-search-color-bg": "#ffffff",
        "components-promo-color-default-bg": "#ffffff",
        "components-promo-color-default-image-bg": "#ffffff",
        "components-promo-color-default-content-bg": "#ffffff",
        "components-promo-color-default-title": "#191b1c",
        "components-promo-color-default-body": "#27282b",
        "components-promo-color-default-category": "#191b1c",
        "components-promo-color-light-bg": "#f0f3ff",
        "components-promo-color-light-image-bg": "#f0f3ff",
        "components-promo-color-light-content-bg": "#f0f3ff",
        "components-promo-color-light-title": "#191b1c",
        "components-promo-color-light-body": "#27282b",
        "components-promo-color-light-category": "#191b1c",
        "components-promo-color-brand-bg": "#d14300",
        "components-promo-color-brand-image-bg": "#d14300",
        "components-promo-color-brand-content-bg": "#d14300",
        "components-promo-color-brand-title": "#ffffff",
        "components-promo-color-brand-body": "#fcfcfd",
        "components-promo-color-brand-category": "#ffffff",
        "components-pull-quote-color-bg": "#001871",
        "components-pull-quote-color-stroke": "#fe5000",
        "components-pull-quote-color-quote-bg": "#ffffff",
        "components-pull-quote-color-quote-title": "#191b1c",
        "components-pull-quote-color-quote-by-text": "#27282b",
        "components-pull-quote-color-quote-detail": "#d14300",
        "components-pull-quote-color-quote-highlight": "#fe5000",
        "components-promo-alternate-color-default-bg": "#ffffff",
        "components-promo-alternate-color-default-image-bg": "#ffffff",
        "components-promo-alternate-color-default-content-bg": "#ffffff",
        "components-promo-alternate-color-default-title": "#191b1c",
        "components-promo-alternate-color-default-body": "#27282b",
        "components-promo-alternate-color-default-category": "#191b1c",
        "components-promo-alternate-color-light-bg": "#f0f3ff",
        "components-promo-alternate-color-light-image-bg": "#f0f3ff",
        "components-promo-alternate-color-light-content-bg": "#f0f3ff",
        "components-promo-alternate-color-light-title": "#191b1c",
        "components-promo-alternate-color-light-body": "#27282b",
        "components-promo-alternate-color-light-category": "#191b1c",
        "components-promo-alternate-color-brand-bg": "#d14300",
        "components-promo-alternate-color-brand-image-bg": "#d14300",
        "components-promo-alternate-color-brand-content-bg": "#d14300",
        "components-promo-alternate-color-brand-title": "#ffffff",
        "components-promo-alternate-color-brand-body": "#fcfcfd",
        "components-promo-alternate-color-brand-category": "#ffffff",
        "components-share-list-color-title": "#191b1c",
        "components-share-list-color-bg": "#fcfcfd",
        "components-share-list-color-link-default": "#27282b",
        "components-share-list-color-link-hover": "#191b1c",
        "components-share-list-color-bg-link-hover": "#e8e8ea",
        "components-share-list-color-close-icon": "#191b1c",
        "components-share-list-color-facebook-icon": "#3b5998",
        "components-share-list-color-pinterest-icon": "#e60023",
        "components-share-list-color-twitter-icon": "#27282b",
        "components-share-list-color-basic-icon-default": "#191b1c",
        "components-share-list-color-icon-hover": "#191b1c",
        "components-share-list-color-border": "#e8e8ea",
        "components-site-search-bg": "#ffffff",
        "components-site-search-results_text": "#27282b",
        "components-site-search-filter-filter-selected-bg": "#fff1eb",
        "components-site-search-facet-group-label-bottom-line": "#e8e8ea",
        "components-site-search-facet-group-label-text": "#27282b",
        "components-site-search-facet-group-label-bg": "#ffffff",
        "components-site-search-facet-bg": "#ffffff",
        "components-site-search-facet-stroke": "#e8e8ea",
        "components-site-search-refine-bg": "#ffffff",
        "components-site-search-refine-right-divider": "#f0f3ff",
        "components-site-search-refine-title": "#191b1c",
        "components-site-search-hero-search-bg": "#d14300",
        "components-site-search-hero-search-accent-bg": "#d14300",
        "components-site-search-hero-search-title": "#ffffff",
        "components-stats-stat-text": "#fe5000",
        "components-stats-stat-text-on-light": "#191b1c",
        "components-stats-supporting-text": "#191b1c",
        "components-stats-bg-default": "#ffffff",
        "components-stats-bg-inner": "#ffffff",
        "components-stats-bg-section-inner": "#d14300",
        "components-stats-title": "#191b1c",
        "components-stats-copy": "#27282b",
        "components-supporting-text-default": "#4e5056",
        "components-supporting-text-destructive": "#b32b30",
        "components-tab-bg": "#ffffff",
        "components-tab-border-default": "#72757e",
        "components-tab-border-hover": "#fe5000",
        "components-tab-border-focused": "#191b1c",
        "components-tab-border-destructive": "#b32b30",
        "components-tab-label-text-default": "#191b1c",
        "components-tab-item-text-default": "#191b1c",
        "components-tab-item-bg-default": "#ffffff",
        "components-tab-item-bg-hover": "#fff1eb",
        "components-tab-input-field-default": "#72757e",
        "components-tab-input-field-focused": "#191b1c",
        "components-tab-input-label-focused": "#191b1c",
        "components-tab-input-field-destructive": "#b32b30",
        "components-tab-bg-2": "#ffffff",
        "components-tag-color-bg": "#ffffff",
        "components-tag-color-text": "#00114f",
        "components-tag-color-stroke": "#001871",
        "components-tag-color-bg-hover": "#00114f",
        "components-tag-color-text-hover": "#ffffff",
        "components-tag-color-stroke-hover": "#00114f",
        "components-text-field-bg": "#ffffff",
        "components-text-field-bg-focused": "#f0f3ff",
        "components-text-field-border-default": "#191b1c",
        "components-text-field-border-hover": "#001871",
        "components-text-field-border-focused": "#191b1c",
        "components-text-field-border-destructive": "#b32b30",
        "components-text-field-input-field-default": "#4e5056",
        "components-text-field-input-field-focused": "#191b1c",
        "components-text-field-input-label-focused": "#191b1c",
        "components-text-field-input-field-destructive": "#b32b30",
        "components-text-field-icon-default": "#4e5056",
        "components-text-field-icon-destructive": "#b32b30",
        "components-text-field-supporting-text-default": "#4e5056",
        "components-text-field-supporting-text-destructive": "#b32b30",
        "components-tip-color-bg": "#fcfcfd",
        "components-tip-color-title": "#191b1c",
        "components-tip-color-body": "#27282b",
        "components-tip-color-stroke": "#a2a5ab",
        "components-video-color-bg": "#ffffff",
        "themes-general-surface-color-light-bg": "#f0f3ff",
        "themes-general-surface-color-light-title": "#191b1c",
        "themes-general-surface-color-light-body": "#27282b",
        "themes-general-surface-color-light-decor-line-break": "#a2a5ab",
        "themes-general-surface-color-light-rule-line": "rgba(162, 165, 171, 0.40)",
        "themes-general-surface-color-dark-bg": "#d14300",
        "themes-general-surface-color-dark-title": "#ffffff",
        "themes-general-surface-color-dark-body": "#fcfcfd",
        "themes-general-surface-color-dark-decor-line-break": "#72757e",
        "themes-general-card-color-dark-title": "#191b1c",
        "themes-general-card-color-dark-subtitle": "#27282b",
        "themes-general-card-color-dark-body": "#27282b",
        "themes-general-card-color-light-title": "#ffffff",
        "themes-general-card-color-light-subtitle": "#fcfcfd",
        "themes-general-card-color-light-body": "#fcfcfd",
        "themes-shadow-color": "#ffffff",
        "themes-shadow-share-list-color-fill": "rgba(0, 0, 0, 0.40)",
        "themes-shadow-header-menu-color-fill": "rgba(0, 0, 0, 0.20)",
        "themes-gradient-loader-gradient-fill-1": "#00114f",
        "themes-gradient-loader-gradient-fill-2": "rgba(0, 17, 79, 0.00)",
        "themes-color-background-white": "#ffffff",
        "themes-color-background-light": "#f0f3ff",
        "themes-color-background-dark": "#27282b",
        "themes-color-background-black": "#191b1c",
        "themes-color-background-brand1": "#d14300",
        "themes-color-background-brand2": "#001871",
        "themes-color-background-accent1": "#f2a900",
        "themes-color-background-accent2": "#41b6e6",
        "themes-color-background-body": "#ffffff",
        "themes-color-stroke-white": "#ffffff",
        "themes-color-stroke-light": "#e8e8ea",
        "themes-color-stroke-dark": "#27282b",
        "themes-color-stroke-black": "#191b1c",
        "themes-color-stroke-brand1": "#983000",
        "themes-color-stroke-brand2": "#001871",
        "themes-color-stroke-accent1": "#f2a900",
        "themes-color-stroke-accent2": "#41b6e6",
        "themes-color-text-white": "#ffffff",
        "themes-color-text-light": "#fcfcfd",
        "themes-color-text-dark": "#27282b",
        "themes-color-text-black": "#191b1c",
        "themes-color-text-brand1": "#d14300",
        "themes-color-text-brand2": "#00114f",
        "themes-color-text-accent1": "#bb8300",
        "themes-color-text-accent2": "#1d5167",
        "colors-grayscale-100": "#fcfcfd",
        "colors-grayscale-200": "#e8e8ea",
        "colors-grayscale-300": "#a2a5ab",
        "colors-grayscale-400": "#72757e",
        "colors-grayscale-500": "#4e5056",
        "colors-grayscale-600": "#27282b",
        "colors-grayscale-700": "#191b1c",
        "colors-brand-1-100": "#fff1eb",
        "colors-brand-1-200": "#ffb999",
        "colors-brand-1-300": "#ff844c",
        "colors-brand-1-400": "#fe5000",
        "colors-brand-1-500": "#d14300",
        "colors-brand-1-600": "#983000",
        "colors-brand-1-700": "#471600",
        "colors-brand-2-100": "#f0f3ff",
        "colors-brand-2-200": "#8ca4f2",
        "colors-brand-2-300": "#1a42d5",
        "colors-brand-2-400": "#001871",
        "colors-brand-2-500": "#00114f",
        "colors-brand-2-600": "#000c39",
        "colors-brand-2-700": "#000722",
        "colors-accent-1-100": "#fef8f2",
        "colors-accent-1-200": "#fcf0e4",
        "colors-accent-1-300": "#fae1c6",
        "colors-accent-1-400": "#f2a900",
        "colors-accent-1-500": "#d89700",
        "colors-accent-1-600": "#bb8300",
        "colors-accent-1-700": "#996b00",
        "colors-accent-2-100": "#f3f9fd",
        "colors-accent-2-200": "#e6f2fa",
        "colors-accent-2-300": "#a9d6f0",
        "colors-accent-2-400": "#41b6e6",
        "colors-accent-2-500": "#3aa3ce",
        "colors-accent-2-600": "#328db2",
        "colors-accent-2-700": "#1d5167",
        "colors-accent-3-100": "#fcfcfd",
        "colors-accent-3-200": "#e8e8ea",
        "colors-accent-3-300": "#a2a5ab",
        "colors-accent-3-400": "#72757e",
        "colors-accent-3-500": "#4e5056",
        "colors-accent-3-600": "#27282b",
        "colors-accent-3-700": "#191b1c",
        "colors-accent-4-400_storage": "#000000",
        "colors-accessible-accent-1-100": "#fef8f2",
        "colors-accessible-accent-1-200": "#fcf0e4",
        "colors-accessible-accent-1-300": "#fae1c6",
        "colors-accessible-accent-1-400": "#f2a900",
        "colors-accessible-accent-1-500": "#d89700",
        "colors-accessible-accent-1-600": "#bb8300",
        "colors-accessible-accent-1-700": "#996b00",
        "colors-accessible-accent-2-100": "#f3f9fd",
        "colors-accessible-accent-2-200": "#e6f2fa",
        "colors-accessible-accent-2-300": "#a9d6f0",
        "colors-accessible-accent-2-400": "#41b6e6",
        "colors-accessible-accent-2-500": "#3aa3ce",
        "colors-accessible-accent-2-600": "#328db2",
        "colors-accessible-accent-2-700": "#1d5167",
        "colors-accessible-brand-2-100": "#f0f3ff",
        "colors-accessible-brand-2-200": "#8ca4f2",
        "colors-accessible-brand-2-300": "#1a42d5",
        "colors-accessible-brand-2-400": "#001871",
        "colors-accessible-brand-2-500": "#00114f",
        "colors-accessible-brand-2-600": "#000c39",
        "colors-accessible-brand-2-700": "#000722"
      },
      "spacing": {
        "components-accordion-spacing-large-padding-x": "5rem",
        "components-accordion-spacing-large-icon-margin-right": "2rem",
        "components-accordion-spacing-large-copy-margin-right": "2.5rem",
        "components-accordion-spacing-large-maxwidth": "64rem",
        "components-accordion-spacing-large-section-padding-x": "2.5rem",
        "components-accordion-spacing-large-section-padding-y": "2.5rem",
        "components-accordion-spacing-large-section-copy-margin-bottom": "2.5rem",
        "components-accordion-spacing-large-section-space-between": "4rem",
        "components-accordion-spacing-large-item-padding-x": "0rem",
        "components-accordion-spacing-large-item-padding-y": "1.5rem",
        "components-accordion-spacing-large-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-large-content-padding-x": "0rem",
        "components-accordion-spacing-large-content-padding-y": "1.5rem",
        "components-accordion-spacing-large-content-title-margin-bottom": "2rem",
        "components-accordion-spacing-large-content-content-space-between": "1.5rem",
        "components-accordion-spacing-large-content-content-margin-left": "1rem",
        "components-accordion-spacing-small-padding-top": "1rem",
        "components-accordion-spacing-small-padding-x": "1.5rem",
        "components-accordion-spacing-small-padding-y": "2.5rem",
        "components-accordion-spacing-small-icon-margin-right": "0.75rem",
        "components-accordion-spacing-small-title-margin-bottom": "0.5rem",
        "components-accordion-spacing-small-copy-margin-right": "2rem",
        "components-accordion-spacing-small-copy-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-padding-x": "1.5rem",
        "components-accordion-spacing-small-section-padding-y": "1.5rem",
        "components-accordion-spacing-small-section-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-space-between": "1rem",
        "components-accordion-spacing-small-item-padding-x": "0rem",
        "components-accordion-spacing-small-item-padding-y": "1rem",
        "components-accordion-spacing-small-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-small-content-padding-x": "1.5rem",
        "components-accordion-spacing-small-content-padding-y": "0.75rem",
        "components-accordion-spacing-small-content-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-content-content-space-between": "1rem",
        "components-accordion-spacing-small-content-content-margin-left": "1rem",
        "components-article-card-spacing-large-padding-image-x": "0rem",
        "components-article-card-spacing-large-padding-image-y": "0rem",
        "components-article-card-spacing-large-padding-content-x": "2rem",
        "components-article-card-spacing-large-padding-content-y": "2rem",
        "components-article-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-article-card-spacing-large-button-space-between": "1rem",
        "components-article-card-spacing-large-title-margin-bottom": "1rem",
        "components-article-card-spacing-large-list-content-gap": "1rem",
        "components-article-card-spacing-large-list-image-padding-x": "0rem",
        "components-article-card-spacing-large-list-image-padding-y": "0rem",
        "components-article-card-spacing-small-padding-image-x": "0rem",
        "components-article-card-spacing-small-padding-image-y": "0rem",
        "components-article-card-spacing-small-padding-content-x": "1.5rem",
        "components-article-card-spacing-small-padding-content-y": "1.5rem",
        "components-article-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-article-card-listing-spacing-small-padding-x": "1.5rem",
        "components-article-card-listing-spacing-small-padding-y": "2.5rem",
        "components-article-hero-spacing-large-padding-x": "0rem",
        "components-article-hero-spacing-large-padding-y": "0rem",
        "components-article-hero-spacing-large-title-margin-bottom": "2rem",
        "components-article-hero-spacing-large-label-share-margin-bottom": "2rem",
        "components-article-hero-spacing-large-author-name-margin-bottom": "0.75rem",
        "components-article-hero-spacing-large-author-thumbnail-margin-left": "1rem",
        "components-article-hero-spacing-large-details-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-space-between": "1rem",
        "components-article-hero-spacing-large-body-margin-bottom": "2rem",
        "components-article-hero-spacing-large-image-space-between": "1rem",
        "components-article-hero-spacing-large-image-margin-bottom": "1rem",
        "components-article-hero-spacing-large-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-stats-margin-bottom": "2.5rem",
        "components-article-hero-spacing-small-content-margin-top": "2.5rem",
        "components-article-hero-spacing-small-title-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-label-share-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-authorname-margin-bottom": "0.75rem",
        "components-article-hero-spacing-small-authorthumbnail-margin-left": "1rem",
        "components-article-hero-spacing-small-details-margin-bottom": "1rem",
        "components-article-hero-spacing-small-space-between": "0.75rem",
        "components-article-hero-spacing-small-body-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-image-space-between": "0.75rem",
        "components-article-hero-spacing-small-image-margin-bottom": "1rem",
        "components-article-hero-spacing-small-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-stats-margin-bottom": "2rem",
        "components-banner-spacing-large-content-padding-x": "1.5rem",
        "components-banner-spacing-large-content-space-between": "1rem",
        "components-banner-spacing-large-content-width": "69.375rem",
        "components-banner-spacing-icon-dimensions": "1.5rem",
        "components-banner-spacing-icon-margin-left": "0.5rem",
        "components-banner-spacing-small-space-between": "1rem",
        "components-banner-spacing-small-content-space-between": "1rem",
        "components-banner-spacing-small-title-margin-bottom": "0.75rem",
        "components-banner-spacing-small-content-margin-bottom": "1rem",
        "components-breadcrumb-spacing-large-space-between": "0.25rem",
        "components-breadcrumb-spacing-small-space-between": "0.25rem",
        "components-button-spacing-large-icon-large-x": "2.5rem",
        "components-button-spacing-large-icon-large-y": "2.5rem",
        "components-button-spacing-large-default-padding-y": "0.75rem",
        "components-button-spacing-large-default-padding-x": "1.5rem",
        "components-button-spacing-large-default-space-between": "0.25rem",
        "components-button-spacing-large-icon-left-padding-left": "1rem",
        "components-button-spacing-large-icon-right-padding-right": "1rem",
        "components-button-spacing-large-icon-only-padding-x": "0.75rem",
        "components-button-spacing-large-icon-only-padding-y": "0.75rem",
        "components-button-spacing-large-tertiary-content-height": "1.5rem",
        "components-button-spacing-compressed-icon-large-x": "2.5rem",
        "components-button-spacing-compressed-icon-large-y": "2.5rem",
        "components-button-spacing-compressed-default-padding-y": "0.5rem",
        "components-button-spacing-compressed-default-padding-x": "1rem",
        "components-button-spacing-compressed-default-space-between": "0.125rem",
        "components-button-spacing-compressed-icon-left-padding-left": "0.5rem",
        "components-button-spacing-compressed-icon-right-padding-right": "0.5rem",
        "components-button-spacing-compressed-icon-only-padding-x": "0.5rem",
        "components-button-group-spacing-padding-y": "0.125rem",
        "components-button-group-spacing-padding-x": "0.25rem",
        "components-button-group-spacing-space-between": "0.5rem",
        "components-category-card-spacing-large-padding-top": "0rem",
        "components-category-card-spacing-large-padding-x": "0rem",
        "components-category-card-spacing-large-padding-y": "0rem",
        "components-category-card-spacing-large-padding-image-x": "0rem",
        "components-category-card-spacing-large-padding-image-y": "0rem",
        "components-category-card-spacing-large-padding-content-x": "2rem",
        "components-category-card-spacing-large-padding-content-y": "2rem",
        "components-category-card-listing-spacing-large-padding-y": "5rem",
        "components-category-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-category-card-listing-spacing-large-card-margin-bottom": "0rem",
        "components-category-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-card-search-result-small-padding-top": "0rem",
        "components-card-search-result-small-padding-x": "0rem",
        "components-card-search-result-small-padding-y": "0rem",
        "components-card-search-result-small-padding-image-x": "0rem",
        "components-card-search-result-small-padding-image-y": "0rem",
        "components-card-search-result-small-padding-content-x": "1.5rem",
        "components-card-search-result-small-padding-content-y": "1.5rem",
        "components-card-search-result-spacing-large-padding-x": "0rem",
        "components-card-search-result-spacing-large-padding-y": "1.5rem",
        "components-card-search-result-spacing-large-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-image-margin-right": "1.5rem",
        "components-card-search-result-spacing-small-padding-x": "1.5rem",
        "components-card-search-result-spacing-small-padding-y": "1.5rem",
        "components-card-search-result-spacing-small-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-image-margin-right": "0.75rem",
        "components-contact-us-promo-spacing-large-padding-x": "5rem",
        "components-contact-us-promo-spacing-large-padding-y": "5rem",
        "components-contact-us-promo-spacing-large-content-margin-right": "5rem",
        "components-contact-us-promo-spacing-large-margin-y": "2.5rem",
        "components-contact-us-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-contact-us-promo-spacing-small-margin-y": "2.5rem",
        "components-content-list-list-item-container-padding-x": "0.75rem",
        "components-content-list-list-item-container-padding-y": "1rem",
        "components-content-list-list-item-content-space-between": "0.5rem",
        "components-content-list-list-item-content-default-padding-y": "0.25rem",
        "components-content-list-list-item-content-hyperlink-padding-y": "0rem",
        "components-content-list-spacing-large-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-large-item-title-margin-bottom": "1rem",
        "components-content-list-spacing-large-item-copy-margin-bottom": "2.5rem",
        "components-content-list-spacing-large-item-icon-copy-margin-top": "0rem",
        "components-content-list-spacing-large-item-margin-bottom": "2rem",
        "components-content-list-spacing-large-item-image-margin-bottom": "0.5rem",
        "components-content-list-spacing-large-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-y": "2.5rem",
        "components-content-list-spacing-small-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-small-item-title-margin-bottom": "0.5rem",
        "components-content-list-spacing-small-item-copy-margin-bottom": "1rem",
        "components-content-list-spacing-small-item-margin-bottom": "1.5rem",
        "components-content-list-spacing-small-header-title-margin-bottom": "1rem",
        "components-content-list-spacing-small-header-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-title-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-button-space-between": "1rem",
        "components-dropdown-item-padding-y": "0.75rem",
        "components-dropdown-item-padding-x": "0.75rem",
        "components-dropdown-item-spacing-x": "0.75rem",
        "components-dropdown-input-padding-y": "0.25rem",
        "components-dropdown-input-padding-x": "1rem",
        "components-footer-brand-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-large-column-margin-right": "4rem",
        "components-footer-brand-spacing-large-column-margin-bottom": "4rem",
        "components-footer-brand-spacing-large-link-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-padding-y": "2.5rem",
        "components-footer-brand-spacing-small-padding-x": "1rem",
        "components-footer-brand-spacing-small-logo-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-column-margin-right": "0.125rem",
        "components-footer-brand-spacing-small-column-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-small-link-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-title-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-text-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-footer-link-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-link-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-link-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-link-minheight": "3rem",
        "components-footer-brand-spacing-small-footer-category-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-category-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-category-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-category-minheight": "3rem",
        "components-footer-global-spacing-large-padding-y": "2.5rem",
        "components-footer-global-spacing-large-padding-x": "5rem",
        "components-footer-global-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-global-spacing-large-column-margin-right": "2rem",
        "components-footer-global-spacing-large-link-margin-bottom": "2rem",
        "components-footer-global-spacing-small-padding-y": "2.5rem",
        "components-footer-global-spacing-small-padding-x": "1rem",
        "components-footer-global-spacing-small-logo-margin-bottom": "1rem",
        "components-footer-global-spacing-small-column-margin-right": "1rem",
        "components-footer-global-spacing-small-link-margin-bottom": "1rem",
        "components-generic-card-spacing-large-padding-x": "0rem",
        "components-generic-card-spacing-large-padding-y": "0rem",
        "components-generic-card-spacing-large-padding-image-x": "0rem",
        "components-generic-card-spacing-large-padding-image-y": "0rem",
        "components-generic-card-spacing-large-padding-content-x": "2rem",
        "components-generic-card-spacing-large-padding-content-y": "2rem",
        "components-generic-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-generic-card-spacing-large-title-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-button-space-between": "1rem",
        "components-generic-card-spacing-small-padding-x": "0rem",
        "components-generic-card-spacing-small-padding-y": "0rem",
        "components-generic-card-spacing-small-padding-image-x": "0rem",
        "components-generic-card-spacing-small-padding-image-y": "0rem",
        "components-generic-card-spacing-small-padding-content-x": "1.5rem",
        "components-generic-card-spacing-small-padding-content-y": "1.5rem",
        "components-global-search-spacing-omnibox-padding-x": "0.125rem",
        "components-global-search-spacing-omnibox-list-padding-y": "0.5rem",
        "components-global-search-spacing-omnibox-label-padding": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-item-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-height": "3rem",
        "components-generic-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-generic-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-header-shadow-x": "0rem",
        "components-header-spacing-large-padding-y": "0.5rem",
        "components-header-spacing-large-padding-x": "1.5rem",
        "components-header-spacing-large-links-margin-right": "1.5rem",
        "components-header-spacing-large-icons-space-between": "1.5rem",
        "components-header-spacing-large-logo-margin-right": "2rem",
        "components-header-spacing-large-space-between-search": "0.5rem",
        "components-header-spacing-large-height": "5rem",
        "components-header-spacing-large-language-selector-padding-x": "1rem",
        "components-header-spacing-large-language-selector-padding-y": "0.5rem",
        "components-header-spacing-large-language-selector-space-between": "0.75rem",
        "components-header-spacing-large-language-selector-country-space-between": "0.5rem",
        "components-header-spacing-large-mega-menu-padding-y": "2.5rem",
        "components-header-spacing-large-mega-menu-padding-x": "2.5rem",
        "components-header-spacing-large-mega-menu-column-margin-right": "2.5rem",
        "components-header-spacing-large-mega-menu-category-link-margin-bottom": "1rem",
        "components-header-spacing-large-mega-menu-link-margin-bottom": "0.5rem",
        "components-header-spacing-large-mega-menu-featured-padding-x": "2rem",
        "components-header-spacing-large-mega-menu-featured-padding-y": "2rem",
        "components-header-spacing-large-mega-menu-featured-image-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-title-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-link-space-between": "1.5rem",
        "components-header-spacing-large-mega-menu-title-margin-bottom": "2.5rem",
        "components-header-spacing-large-dropdown-padding-y": "1.5rem",
        "components-header-spacing-large-dropdown-padding-x": "1.5rem",
        "components-header-spacing-large-dropdown-margin-bottom": "0.5rem",
        "components-header-spacing-small-language-selector-padding-x": "1rem",
        "components-header-spacing-small-language-selector-padding-y": "0.5rem",
        "components-header-spacing-small-language-selector-space-between": "0.5rem",
        "components-header-spacing-small-padding-left": "1rem",
        "components-header-spacing-small-padding-right": "1rem",
        "components-header-spacing-small-padding-top": "0.5rem",
        "components-header-spacing-small-padding-bottom": "0.5rem",
        "components-header-spacing-small-nav-main-minheight": "3rem",
        "components-header-spacing-small-nav-main-padding-y": "0.5rem",
        "components-header-spacing-small-nav-main-padding-left": "1.5rem",
        "components-header-spacing-small-nav-main-padding-right": "1rem",
        "components-header-spacing-small-nav-category-minheight": "3rem",
        "components-header-spacing-small-nav-category-padding-left": "1.5rem",
        "components-header-spacing-small-nav-category-padding-right": "0.75rem",
        "components-header-spacing-small-nav-category-padding-y": "0.75rem",
        "components-header-spacing-small-nav-category-space-between": "0rem",
        "components-header-spacing-small-nav-menu-link-minheight": "3rem",
        "components-header-spacing-small-nav-menu-link-padding-right": "1.5rem",
        "components-header-spacing-small-nav-menu-link-padding-left": "2.5rem",
        "components-header-spacing-small-nav-menu-link-padding-y": "0.75rem",
        "components-header-spacing-small-mega-menu-padding-y": "0rem",
        "components-header-spacing-small-mega-menu-padding-x": "0rem",
        "components-header-spacing-small-mega-menu-category-link-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-link-margin-bottom": "0rem",
        "components-header-spacing-small-mega-menu-featured-padding-x": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-padding-y": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-image-margin-bottom": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-title-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-featured-link-space-between": "0.75rem",
        "components-header-spacing-small-mega-menu-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-container-padding": "0rem",
        "components-hero-homepage-spacing-large-padding-x": "4rem",
        "components-hero-homepage-spacing-large-padding-y": "2.5rem",
        "components-hero-homepage-spacing-large-minheight": "34.3125rem",
        "components-hero-homepage-spacing-large-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-margin-bottom": "2rem",
        "components-hero-homepage-spacing-large-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-copy-margin-bottom": "2.5rem",
        "components-hero-homepage-spacing-large-cta-margin-right": "1rem",
        "components-hero-homepage-spacing-large-image-height": "34.3125rem",
        "components-hero-homepage-spacing-large-image-width": "45.75rem",
        "components-hero-homepage-spacing-small-padding-x": "0rem",
        "components-hero-homepage-spacing-small-padding-y": "1.5rem",
        "components-hero-homepage-spacing-small-minheight": "34.3125rem",
        "components-hero-homepage-spacing-small-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-copy-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-image-height": "17.5625rem",
        "components-hero-homepage-spacing-small-image-width": "45.75rem",
        "components-hero-internal-spacing-large-padding-y": "2.5rem",
        "components-hero-internal-spacing-large-padding-right": "10rem",
        "components-hero-internal-spacing-large-title-margin-bottom": "1.5rem",
        "components-hero-internal-spacing-large-button-padding-y": "2rem",
        "components-hero-internal-spacing-large-text-maxwidth": "59.625rem",
        "components-hero-internal-spacing-large-minheight": "18.5rem",
        "components-hero-internal-spacing-small-padding-x": "1.5rem",
        "components-hero-internal-spacing-small-padding-right": "0rem",
        "components-hero-internal-spacing-small-button-padding-y": "1.5rem",
        "components-hero-internal-spacing-small-text-maxwidth": "30rem",
        "components-hero-internal-spacing-small-minheight": "13.5rem",
        "components-icon-card-spacing-large-padding-x": "0rem",
        "components-icon-card-spacing-large-padding-y": "0rem",
        "components-icon-card-spacing-large-icon-margin": "1.5rem",
        "components-icon-card-spacing-large-margin-right": "1.5rem",
        "components-icon-card-spacing-large-title-margin-bottom": "1rem",
        "components-icon-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-icon-card-spacing-large-button-space-between": "1.5rem",
        "components-icon-card-spacing-large-top-space-between": "1rem",
        "components-icon-card-spacing-small-padding-x": "1.5rem",
        "components-icon-card-spacing-small-padding-y": "1.5rem",
        "components-icon-card-spacing-small-icon-margin": "1.5rem",
        "components-icon-card-spacing-small-margin-right": "1.5rem",
        "components-icon-card-spacing-small-title-margin-bottom": "1.5rem",
        "components-icon-card-dimensions-large-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-large-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-large-big-icon-height": "4rem",
        "components-icon-card-dimensions-large-big-icon-width": "4rem",
        "components-icon-card-dimensions-large-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-large-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-large-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-large-tiny-icon-width": "2.5rem",
        "components-icon-card-dimensions-small-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-small-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-small-big-icon-height": "3.5rem",
        "components-icon-card-dimensions-small-big-icon-width": "3.5rem",
        "components-icon-card-dimensions-small-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-small-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-small-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-small-tiny-icon-width": "2.5rem",
        "components-icon-card-listing-spacing-large-padding-inner-x": "4rem",
        "components-icon-card-listing-spacing-large-padding-inner-y": "4rem",
        "components-icon-card-listing-spacing-large-card-space-between": "4rem",
        "components-icon-card-listing-spacing-large-title-margin-bottom": "1.5rem",
        "components-icon-card-listing-spacing-large-card-margin-bottom": "4rem",
        "components-icon-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-x": "1.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-y": "2.5rem",
        "components-icon-card-listing-spacing-small-title-margin-bottom": "1rem",
        "components-icon-card-listing-spacing-small-button-margin-top": "1.5rem",
        "components-jumplink-link-padding-bottom": "0.5rem",
        "components-jumplink-spacing-small-title-area-margin-bottom": "1.5rem",
        "components-jumplink-spacing-small-link-container-padding-x": "0rem",
        "components-lightbox-spacing-large-carousel-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-carousel-space-between": "1.5rem",
        "components-lightbox-spacing-large-featured-image-height": "39.25rem",
        "components-lightbox-spacing-large-featured-image-width": "39.25rem",
        "components-lightbox-spacing-large-image-height": "17.0625rem",
        "components-lightbox-spacing-large-image-width": "17.0625rem",
        "components-lightbox-spacing-small-carousel-margin-bottom": "1rem",
        "components-lightbox-spacing-small-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-small-media-details-margin-top": "1rem",
        "components-lightbox-spacing-small-image-height": "20.4375rem",
        "components-lightbox-spacing-small-image-width": "20.4375rem",
        "components-legal-disclaimer-spacing-small-ruleline-width": "20.4375rem",
        "components-media-spacing-large-padding-y": "0.5rem",
        "components-media-spacing-large-padding-x": "0.75rem",
        "components-media-gallery-large-container-grid-gap-x": "1.5rem",
        "components-media-gallery-large-container-grid-gap-y": "1.5rem",
        "components-media-gallery-small-container-grid-gap-x": "0.5rem",
        "components-media-gallery-small-container-grid-gap-y": "0.5rem",
        "components-media-gallery-spacing-small-padding-x": "1.5rem",
        "components-media-gallery-spacing-small-padding-y": "2.5rem",
        "components-media-gallery-spacing-large-padding-x": "5rem",
        "components-media-gallery-spacing-large-padding-y": "5rem",
        "components-product-card-spacing-list-content-gap": "0rem",
        "components-product-card-spacing-large-padding-image-x": "0rem",
        "components-product-card-spacing-large-padding-image-y": "0rem",
        "components-product-card-spacing-large-padding-content-x": "1.5rem",
        "components-product-card-spacing-large-padding-content-y": "1.5rem",
        "components-product-card-spacing-large-category-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-title-margin-bottom": "1rem",
        "components-product-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-button-space-between": "1rem",
        "components-product-card-spacing-small-padding-image-x": "0rem",
        "components-product-card-spacing-small-padding-image-y": "0rem",
        "components-product-card-spacing-small-padding-content-x": "1.5rem",
        "components-product-card-spacing-small-padding-content-y": "1.5rem",
        "components-product-card-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-large-padding-x": "5rem",
        "components-product-information-spacing-large-padding-y": "2.5rem",
        "components-product-information-spacing-large-title-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-label-margin-bottom": "2.5rem",
        "components-product-information-spacing-large-ratings-margin-bottom": "1rem",
        "components-product-information-spacing-large-details-margin-bottom": "1rem",
        "components-product-information-spacing-large-space-between": "1rem",
        "components-product-information-spacing-large-carousel-space-between": "5rem",
        "components-product-information-spacing-large-button-margin-y": "0.5rem",
        "components-product-information-spacing-large-body-margin-top": "1.5rem",
        "components-product-information-spacing-large-image-space-between": "1rem",
        "components-product-information-spacing-large-image-margin-bottom": "1rem",
        "components-product-information-spacing-small-padding-x": "1.5rem",
        "components-product-information-spacing-small-padding-y": "1.5rem",
        "components-product-information-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-small-label-margin-bottom": "1.5rem",
        "components-product-information-spacing-small-ratings-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-details-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-space-between": "0.75rem",
        "components-product-information-spacing-small-carousel-space-between": "2.5rem",
        "components-product-information-spacing-small-button-margin-y": "0.75rem",
        "components-product-information-spacing-small-body-margin-top": "1rem",
        "components-product-information-spacing-small-image-space-between": "0.75rem",
        "components-product-information-spacing-small-image-margin-bottom": "1rem",
        "components-product-results-list-with-search-spacing-large-padding-x": "5rem",
        "components-product-results-list-with-search-spacing-large-padding-y": "5rem",
        "components-product-results-list-with-search-spacing-large-ruleline-margin-top": "2.5rem",
        "components-product-results-list-with-search-spacing-large-filter-space-between": "1rem",
        "components-product-results-list-with-search-spacing-large-card-space-between": "1.5rem",
        "components-product-results-list-with-search-spacing-large-card-container-margin-bottom": "5rem",
        "components-product-results-list-with-search-spacing-small-padding-x": "1.5rem",
        "components-product-results-list-with-search-spacing-small-padding-y": "2.5rem",
        "components-product-results-list-with-search-spacing-small-ruleline-margin-top": "1rem",
        "components-product-results-list-with-search-spacing-small-filter-padding-x": "0.5rem",
        "components-product-results-list-with-search-spacing-small-card-space-between": "1rem",
        "components-product-results-list-with-search-spacing-small-card-margin-bottom": "1.5rem",
        "components-promo-spacing-large-padding-x": "4rem",
        "components-promo-spacing-large-padding-y": "4rem",
        "components-promo-spacing-large-content-space-between": "3rem",
        "components-promo-spacing-large-content-space-between-y": "3rem",
        "components-promo-spacing-large-padding-content-x": "0rem",
        "components-promo-spacing-large-padding-content-condensed-x": "0rem",
        "components-promo-spacing-large-padding-content-y": "0rem",
        "components-promo-spacing-large-padding-content-condensed-y": "0rem",
        "components-promo-spacing-large-padding-image-x": "1.5rem",
        "components-promo-spacing-large-padding-image-y": "1.5rem",
        "components-promo-spacing-large-padding-image-top": "5rem",
        "components-promo-spacing-large-title-margin-bottom": "2rem",
        "components-promo-spacing-large-margin-y": "2rem",
        "components-promo-spacing-large-buttons-space-between": "1rem",
        "components-promo-spacing-small-padding-x": "0rem",
        "components-promo-spacing-small-padding-y": "0rem",
        "components-promo-spacing-small-padding-top": "0rem",
        "components-promo-spacing-small-padding-content-x": "1.5rem",
        "components-promo-spacing-small-padding-content-y": "2.5rem",
        "components-promo-spacing-small-padding-image-x": "1rem",
        "components-promo-spacing-small-padding-image-y": "1rem",
        "components-promo-spacing-small-padding-image-top": "0rem",
        "components-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-promo-spacing-small-margin-y": "2.5rem",
        "components-promo-minheight": "30rem",
        "components-pull-quote-spacing-large-padding-quote-x": "4rem",
        "components-pull-quote-spacing-large-padding-quote-y": "4rem",
        "components-pull-quote-spacing-large-title-margin-bottom": "2rem",
        "components-pull-quote-spacing-large-quote-margin-bottom": "4rem",
        "components-pull-quote-spacing-large-quote-space-between": "1.5rem",
        "components-pull-quote-spacing-large-detail-space-between": "0.5rem",
        "components-pull-quote-spacing-large-quote-minheight": "25rem",
        "components-pull-quote-spacing-small-padding-quote-x": "1.5rem",
        "components-pull-quote-spacing-small-padding-quote-y": "2.5rem",
        "components-pull-quote-spacing-small-title-margin-bottom": "1.5rem",
        "components-pull-quote-spacing-small-quote-margin-bottom": "2.5rem",
        "components-pull-quote-spacing-small-quote-space-between": "1rem",
        "components-pull-quote-spacing-small-detail-space-between": "1rem",
        "components-pull-quote-spacing-small-quote-minheight": "15rem",
        "components-promo-alternate-minheight": "30rem",
        "components-promo-alternate-spacing-large-padding-x": "4rem",
        "components-promo-alternate-spacing-large-padding-y": "4rem",
        "components-promo-alternate-spacing-large-content-space-between": "3rem",
        "components-promo-alternate-spacing-large-padding-content-x": "0rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-x": "0rem",
        "components-promo-alternate-spacing-large-padding-content-y": "0rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-y": "0rem",
        "components-promo-alternate-spacing-large-padding-image-x": "1.5rem",
        "components-promo-alternate-spacing-large-padding-image-y": "1.5rem",
        "components-promo-alternate-spacing-large-padding-image-top": "5rem",
        "components-promo-alternate-spacing-large-title-margin-bottom": "2rem",
        "components-promo-alternate-spacing-small-padding-x": "0rem",
        "components-promo-alternate-spacing-small-padding-y": "0rem",
        "components-promo-alternate-spacing-small-padding-content-x": "1.5rem",
        "components-promo-alternate-spacing-small-padding-content-y": "2.5rem",
        "components-promo-alternate-spacing-small-padding-image-x": "1rem",
        "components-promo-alternate-spacing-small-padding-image-y": "1rem",
        "components-promo-alternate-spacing-small-padding-image-top": "0rem",
        "components-promo-alternate-spacing-small-title-margin-bottom": "1.5rem",
        "components-share-list-spacing-padding-x": "1rem",
        "components-share-list-spacing-padding-y": "1rem",
        "components-header-copy-margin-bottom": "1rem",
        "components-header-margin-bottom": "1.5rem",
        "components-item-margin-bottom": "1.5rem",
        "components-site-search-padding-x": "2.5rem",
        "components-site-search-padding-y": "5rem",
        "components-site-search-facet-label-space-between": "0.25rem",
        "components-site-search-refine-padding-x": "1.5rem",
        "components-site-search-refine-padding-y": "0rem",
        "components-site-search-hero-search-height": "18.5rem",
        "components-site-search-hero-search-large-padding-x": "5rem",
        "components-site-search-hero-search-large-padding-y": "5rem",
        "components-stats-spacing-stat-text-space-between": "0.5rem",
        "components-stats-spacing-large-inner-padding-x": "5rem",
        "components-stats-spacing-large-inner-padding-y": "5rem",
        "components-stats-spacing-small-inner-padding-x": "1.5rem",
        "components-stats-spacing-small-inner-padding-y": "1.5rem",
        "components-icon-card-listing-spacing-small-top-space-between": "1rem",
        "components-supporting-text-padding-x": "0rem",
        "components-supporting-text-padding-bottom": "0.5rem",
        "components-tab-item-padding-y": "0.75rem",
        "components-tab-item-padding-x": "0.75rem",
        "components-tab-item-spacing-x": "0.75rem",
        "components-tab-input-padding-y": "0.25rem",
        "components-tab-input-padding-x": "1rem",
        "components-tag-spacing-padding-x": "0.5rem",
        "components-tag-spacing-padding-y": "0.5rem",
        "components-tag-spacing-space-between": "0.5rem",
        "components-text-field-input-padding-y": "0.25rem",
        "components-text-field-input-padding-x": "1rem",
        "components-text-field-area-input-padding-y": "1rem",
        "components-text-field-area-input-padding-x": "1rem",
        "components-text-field-supporting-text-padding-x": "0rem",
        "components-text-field-supporting-text-padding-top": "0.5rem",
        "themes-general-surface-spacing-large-padding-x": "4rem",
        "themes-general-surface-spacing-large-padding-y": "4rem",
        "themes-general-surface-spacing-large-title-margin-bottom": "2rem",
        "themes-general-surface-spacing-large-body-margin-right": "4rem",
        "themes-general-surface-spacing-large-body-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-large-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-large-cards-space-between": "1.5rem",
        "themes-general-surface-spacing-large-button-margin-top": "2.5rem",
        "themes-general-surface-spacing-small-padding-x": "1.5rem",
        "themes-general-surface-spacing-small-padding-y": "2.5rem",
        "themes-general-surface-spacing-small-title-margin-bottom": "1rem",
        "themes-general-surface-spacing-small-body-margin-right": "2.5rem",
        "themes-general-surface-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-surface-spacing-small-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-small-cards-space-between": "1rem",
        "themes-general-surface-spacing-small-button-margin-top": "1.5rem",
        "themes-general-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "themes-general-card-spacing-large-title-margin-bottom": "1rem",
        "themes-general-card-spacing-large-body-margin-bottom": "2rem",
        "themes-general-card-spacing-large-button-space-between": "1rem",
        "themes-general-card-spacing-small-eyebrow-margin-bottom": "1.5rem",
        "themes-general-card-spacing-small-title-margin-bottom": "1rem",
        "themes-general-card-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-small-button-space-between": "1rem",
        "themes-shadow-share-list-spacing-x": "0rem",
        "themes-shadow-share-list-spacing-y": "0.75rem",
        "themes-shadow-share-list-spacing-blur": "1rem",
        "themes-shadow-share-list-spacing-spread": "-0.5rem",
        "themes-shadow-header-menu-spacing-x": "0rem",
        "themes-shadow-header-menu-spacing-y": "0.75rem",
        "themes-shadow-header-menu-spacing-blur": "1rem",
        "themes-shadow-header-menu-spacing-spread": "-0.5rem",
        "themes-typography-rte-large-h1-textsize": "3rem",
        "themes-typography-rte-large-h2-textsize": "2.5rem",
        "themes-typography-rte-large-h3-textsize": "2.25rem",
        "themes-typography-rte-large-h4-textsize": "2rem",
        "themes-typography-rte-large-h5-textsize": "1.5rem",
        "themes-typography-rte-large-h6-textsize": "1.25rem",
        "themes-typography-rte-medium-h1-textsize": "2.5rem",
        "themes-typography-rte-medium-h2-textsize": "2.25rem",
        "themes-typography-rte-medium-h3-textsize": "1.75rem",
        "themes-typography-rte-medium-h4-textsize": "1.5rem",
        "themes-typography-rte-medium-h5-textsize": "1.25rem",
        "themes-typography-rte-medium-h6-textsize": "1.125rem",
        "themes-typography-rte-small-h1-textsize": "2.5rem",
        "themes-typography-rte-small-h2-textsize": "2rem",
        "themes-typography-rte-small-h3-textsize": "1.75rem",
        "themes-typography-rte-small-h4-textsize": "1.5rem",
        "themes-typography-rte-small-h5-textsize": "1.125rem",
        "themes-typography-rte-small-h6-textsize": "1rem",
        "spacing-margin-small-1": "0.125rem",
        "spacing-margin-small-2": "0.25rem",
        "spacing-margin-small-3": "0.5rem",
        "spacing-margin-small-4": "0.75rem",
        "spacing-margin-small-5": "1rem",
        "spacing-margin-small-6": "1.5rem",
        "spacing-margin-small-7": "2.5rem",
        "spacing-margin-medium-2": "0.75rem",
        "spacing-margin-medium-4": "1.5rem",
        "spacing-margin-medium-5": "2rem",
        "spacing-margin-large-1": "0.5rem",
        "spacing-margin-large-2": "1rem",
        "spacing-margin-large-3": "1.5rem",
        "spacing-margin-large-4": "2rem",
        "spacing-margin-large-5": "2.5rem",
        "spacing-margin-large-6": "4rem",
        "spacing-margin-large-7": "5rem",
        "spacing-space-between-small-1": "0.125rem",
        "spacing-space-between-small-2": "0.25rem",
        "spacing-space-between-small-3": "0.5rem",
        "spacing-space-between-small-4": "0.75rem",
        "spacing-space-between-small-5": "1rem",
        "spacing-space-between-small-6": "1.5rem",
        "spacing-space-between-small-7": "2.5rem",
        "spacing-space-between-medium-1": "0.125rem",
        "spacing-space-between-medium-2": "0.25rem",
        "spacing-space-between-medium-3": "0.5rem",
        "spacing-space-between-medium-4": "0.75rem",
        "spacing-space-between-medium-5": "1rem",
        "spacing-space-between-large-1": "0.25rem",
        "spacing-space-between-large-2": "0.5rem",
        "spacing-space-between-large-3": "1rem",
        "spacing-space-between-large-4": "1.5rem",
        "spacing-space-between-large-5": "1.5rem",
        "spacing-space-between-large-6": "2.5rem",
        "spacing-space-between-large-7": "4rem",
        "spacing-padding-small-1": "0.125rem",
        "spacing-padding-small-2": "0.25rem",
        "spacing-padding-small-3": "0.5rem",
        "spacing-padding-small-4": "0.75rem",
        "spacing-padding-small-5": "1rem",
        "spacing-padding-small-6": "1.5rem",
        "spacing-padding-small-7": "2.5rem",
        "spacing-padding-medium-1": "0.25rem",
        "spacing-padding-medium-2": "0.75rem",
        "spacing-padding-medium-3": "1rem",
        "spacing-padding-medium-4": "1.5rem",
        "spacing-padding-medium-5": "2rem",
        "spacing-padding-medium-6": "3rem",
        "spacing-padding-medium-7": "4rem",
        "spacing-padding-large-1": "0.5rem",
        "spacing-padding-large-2": "1rem",
        "spacing-padding-large-3": "1.5rem",
        "spacing-padding-large-4": "2rem",
        "spacing-padding-large-5": "2.5rem",
        "spacing-padding-large-6": "4rem",
        "spacing-padding-large-7": "5rem"
      },
      "string": {
        "components-accordion-section-button": "var(--brand)",
        "components-article-card-listing-button-theme": "var(--tonal)",
        "components-article-hero-pagination-buttons": "var(--brand)",
        "components-banner-banner-button": "var(--white)",
        "components-category-card-listing-button-theme": "var(--tonal)",
        "components-contact-us-promo-hierarchy-button-theme-1": "var(--filled)",
        "components-contact-us-promo-hierarchy-button-theme-2": "var(--outlined)",
        "components-contact-us-promo-color-button-theme": "var(--brand)",
        "components-contact-us-promo-color-brand-button-theme": "var(--brand)",
        "components-generic-card-button-theme": "var(--brand)",
        "components-global-search-loader": "var(--default)",
        "components-generic-card-listing-decor-top": "var(--raid-1)",
        "components-generic-card-listing-decor-bottom": "var(--raid-2)",
        "components-hero-homepage-pagination-buttons": "var(--tonal)",
        "components-hero-homepage-pattern": "var(--muscle)",
        "components-hero-homepage-button-1": "var(--brand)",
        "components-hero-homepage-button-2": "var(--white)",
        "components-hero-homepage-image-ratio": "var(--4:3)",
        "components-hero-internal-theme-button-one-type": "var(--filled)",
        "components-hero-internal-theme-button-two-type": "var(--outlined)",
        "components-hero-internal-theme-button-color": "var(--brand)",
        "components-hero-internal-theme-button-color-with-image": "var(--brand)",
        "components-lightbox-button-type": "var(--brand)",
        "components-pdf-link-card-hierarchy-button-theme-1": "var(--filled)",
        "components-pdf-link-card-hierarchy-button-theme-2": "var(--outlined)",
        "components-pdf-link-card-color-button-theme": "var(--brand)",
        "components-product-card-badge-align": "var(--left)",
        "components-product-card-listing-button-color": "var(--tonal)",
        "components-promo-theme-button-one-theme": "var(--filled)",
        "components-promo-theme-button-two-theme": "var(--outlined)",
        "components-promo-color-default-button-theme": "var(--brand)",
        "components-promo-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-color-brand-button-theme": "var(--white)",
        "components-pull-quote-border-decor": "var(--duck)",
        "components-promo-alternate-theme-button-one-theme": "var(--filled)",
        "components-promo-alternate-theme-button-two-theme": "var(--outlined)",
        "components-promo-alternate-color-default-button-theme": "var(--brand)",
        "components-promo-alternate-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-alternate-color-brand-button-theme": "var(--white)",
        "components-share-list-button-color": "var(--brand)",
        "components-site-search-hero-search-hero-pattern": "var(--muscle)",
        "components-stats-decor-top": "var(--raid-1)",
        "components-stats-decor-bottom": "var(--raid-2)",
        "themes-brand-loader": "var(--default)",
        "themes-brand-variants": "var(--muscle)",
        "themes-tab-variant": "var(--default)",
        "themes-label-variants": "var(--default)",
        "themes-logo": "var(--muscle)",
        "themes-category-card": "var(--muscle)",
        "themes-title-decor": "var(--off)",
        "themes-typography-heading-fontfamily": "var(--proxima-nova)",
        "themes-typography-heading-large-xxlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-xlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-large-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-medium-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-small-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-xsmall-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-xxsmall-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-xxlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-xlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-large-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-medium-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-small-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-xsmall-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-xxsmall-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-xxlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-xlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-large-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-medium-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-small-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-xsmall-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-xxsmall-fontweight": "var(--extrabold)",
        "themes-typography-rte-fontfamily": "var(--proxima-nova)",
        "themes-typography-rte-fontweight": "var(--extrabold)"
      },
      "borderRadius": {
        "components-accordion-spacing-large-item-item-radius": "0rem",
        "components-article-card-spacing-large-image-radius-tl": "1rem",
        "components-article-card-spacing-large-image-radius-tr": "1rem",
        "components-article-card-spacing-large-image-radius-bl": "0rem",
        "components-article-card-spacing-large-image-radius-br": "0rem",
        "components-article-card-spacing-large-list-image-radius-br": "0rem",
        "components-article-card-spacing-small-image-radius-br": "0rem",
        "components-article-card-spacing-small-list-image-radius-br": "0rem",
        "components-article-hero-spacing-large-content-radius": "1rem",
        "components-article-hero-spacing-small-content-radius": "1rem",
        "components-button-radius-large": "0.5rem",
        "components-button-group-spacing-border-radius": "0.5rem",
        "components-category-card-radius-border-width": "0.125rem",
        "components-category-card-radius-border-radius": "7.5rem",
        "components-category-card-radius-border-radius-condensed": "0.5rem",
        "components-category-card-radius-media-radius": "0rem",
        "components-content-list-icon-radius": "7.5rem",
        "components-fab-spacing-border-radius": "0.5rem",
        "components-header-spacing-large-search-border-radius-top": "0.25rem",
        "components-header-spacing-large-search-border-radius-bottom": "0.25rem",
        "components-header-spacing-large-language-selector-radius": "0.25rem",
        "components-header-spacing-large-dropdown-radius": "0rem",
        "components-hero-homepage-spacing-large-container-radius": "0rem",
        "components-hero-internal-spacing-large-icon-radius": "7.5rem",
        "components-hero-internal-spacing-small-icon-radius": "7.5rem",
        "components-lightbox-spacing-large-overlay-radius": "1rem",
        "components-pagination-scrollbar-radius": "62.5rem",
        "components-product-card-spacing-image-radius-tl": "0rem",
        "components-product-card-spacing-image-radius-tr": "0rem",
        "components-product-card-spacing-image-radius-bl": "0rem",
        "components-product-card-spacing-image-radius-br": "0rem",
        "components-product-information-spacing-large-content-radius": "1rem",
        "components-product-information-spacing-small-content-radius": "1rem",
        "components-promo-spacing-large-content-radius": "1rem",
        "components-promo-spacing-large-content-radius-tl": "1rem",
        "components-promo-spacing-large-content-radius-tr": "1rem",
        "components-promo-spacing-large-content-radius-bl": "1rem",
        "components-promo-spacing-large-content-radius-br": "1rem",
        "components-promo-spacing-large-image-radius-tl": "1rem",
        "components-promo-spacing-large-image-radius-tr": "1rem",
        "components-promo-spacing-large-image-radius-bl": "1rem",
        "components-promo-spacing-large-image-radius-br": "1rem",
        "components-promo-spacing-small-content-radius": "0.75rem",
        "components-promo-spacing-small-image-radius-tl": "0.75rem",
        "components-promo-spacing-small-image-radius-tr": "0.75rem",
        "components-promo-spacing-small-image-radius-bl": "0.75rem",
        "components-promo-spacing-small-image-radius-br": "0.75rem",
        "components-promo-alternate-spacing-large-content-radius": "1rem",
        "components-promo-alternate-spacing-large-image-radius-tl": "1rem",
        "components-promo-alternate-spacing-large-image-radius-tr": "1rem",
        "components-promo-alternate-spacing-large-image-radius-bl": "1rem",
        "components-promo-alternate-spacing-large-image-radius-br": "1rem",
        "components-promo-alternate-spacing-small-content-radius": "0.75rem",
        "components-promo-alternate-spacing-small-image-radius-tl": "0.75rem",
        "components-promo-alternate-spacing-small-image-radius-tr": "0.75rem",
        "components-promo-alternate-spacing-small-image-radius-bl": "0.75rem",
        "components-promo-alternate-spacing-small-image-radius-br": "0.75rem",
        "components-share-list-spacing-radius": "0.5rem",
        "components-share-list-spacing-link-radius": "0.5rem",
        "components-site-search-filter-radius": "0.5rem",
        "components-site-search-facet-radius": "0rem",
        "components-site-search-refine-radius": "0rem",
        "components-tag-spacing-radius": "0.25rem",
        "components-tip-spacing-radius": "0.5rem",
        "themes-spacing-radius-pagination-bar": "62.5rem",
        "themes-spacing-radius-large-button": "0.5rem",
        "themes-spacing-radius-large-button-icon": "0.5rem",
        "themes-spacing-radius-large-image": "1rem",
        "themes-spacing-radius-large-image-hero": "0rem",
        "themes-spacing-radius-large-image-card": "0.75rem",
        "themes-spacing-radius-large-card": "1rem",
        "themes-spacing-radius-large-icon": "7.5rem",
        "themes-spacing-radius-large-label": "0.25rem",
        "themes-spacing-radius-large-tag": "0.25rem",
        "themes-spacing-radius-large-container-tip": "0.5rem",
        "themes-spacing-radius-large-badge": "0.25rem",
        "themes-spacing-radius-large-item": "0.5rem",
        "themes-spacing-radius-large-form-field-input": "0.25rem",
        "themes-spacing-radius-large-form-area-input": "0.25rem",
        "themes-spacing-radius-large-general": "1rem",
        "themes-spacing-radius-small-button": "0.5rem",
        "themes-spacing-radius-small-button-icon": "0.5rem",
        "themes-spacing-radius-small-image": "0.75rem",
        "themes-spacing-radius-small-image-card": "0.75rem",
        "themes-spacing-radius-small-card": "0.75rem",
        "themes-spacing-radius-small-icon": "7.5rem",
        "themes-spacing-radius-small-label": "0.125rem",
        "themes-spacing-radius-small-tag": "0.25rem",
        "themes-spacing-radius-small-badge": "0rem",
        "themes-spacing-radius-small-item": "0rem",
        "themes-spacing-radius-small-form-field-input": "0.25rem",
        "themes-spacing-radius-small-general": "1rem"
      },
      "borderWidth": {
        "3": "3px",
        "components-article-card-spacing-large-border-width": "0.125rem",
        "components-generic-card-color-border-width": "0rem",
        "components-header-spacing-large-search-border-width-top": "0.125rem",
        "components-header-spacing-large-search-border-width-right": "0.125rem",
        "components-header-spacing-large-search-border-width-bottom": "0.125rem",
        "components-header-spacing-large-search-border-width-left": "0.125rem",
        "components-product-card-spacing-border-width": "0.125rem"
      },
      "maxHeight": {},
      "maxWidth": {
        "components-article-hero-spacing-large-header-max-width": "66.375rem",
        "components-content-list-spacing-large-item-image-max-width": "33.75rem",
        "components-content-list-spacing-small-item-image-max-width": "33.75rem"
      },
      "minHeight": {
        "components-dropdown-item-min-height": "3rem",
        "components-icon-min-height": "3rem",
        "components-tab-item-min-height": "3rem",
        "components-text-field-input-min-height": "3rem",
        "components-text-field-icon-min-height": "3rem"
      },
      "minWidth": {
        "components-content-list-spacing-small-item-image-min-width": "18.75rem",
        "components-icon-min-width": "3rem",
        "components-text-field-icon-min-width": "3rem"
      },
      "opacity": {
        "components-accessibility-bar-color-bg": 1,
        "components-accessibility-bar-color-text": 1,
        "components-accordion-color-bg": 1,
        "components-accordion-color-title": 1,
        "components-accordion-color-body": 1,
        "components-accordion-color-section-section-bg": 1,
        "components-accordion-color-section-section-title": 1,
        "components-accordion-color-section-section-body": 1,
        "components-accordion-color-item-item-bg": 1,
        "components-accordion-color-item-divider": 1,
        "components-accordion-color-item-icon-icon-default": 1,
        "components-accordion-color-item-icon-icon-hover": 1,
        "components-accordion-color-item-icon-icon-pressed": 1,
        "components-accordion-color-item-icon-icon-active": 1,
        "components-accordion-color-item-title-item-default": 1,
        "components-accordion-color-item-title-item-hover": 1,
        "components-accordion-color-item-title-item-pressed": 1,
        "components-accordion-color-item-title-item-active": 1,
        "components-accordion-color-content-bg": 1,
        "components-accordion-color-content-title": 1,
        "components-accordion-color-content-body": 1,
        "components-article-card-color-bg": 1,
        "components-article-card-color-border": 1,
        "components-article-card-color-content-bg": 1,
        "components-article-card-color-title": 1,
        "components-article-card-color-subtitle": 1,
        "components-article-card-color-copy": 1,
        "components-article-card-color-description": 1,
        "components-article-card-listing-color-default-bg": 1,
        "components-article-card-listing-color-default-title": 1,
        "components-article-card-listing-color-default-copy": 1,
        "components-article-hero-color-content-bg": 1,
        "components-article-hero-color-bg": 1,
        "components-article-hero-color-headline": 1,
        "components-article-hero-color-body": 1,
        "components-article-hero-color-detail-text": 1,
        "components-article-hero-color-divider": 1,
        "components-article-hero-color-icon": 1,
        "components-article-hero-color-play-icon": 1,
        "components-article-hero-color-selected-stroke": 1,
        "components-banner-color-bg-default": 1,
        "components-banner-color-icon-default": 1,
        "components-banner-color-title-default": 1,
        "components-banner-color-body-default": 1,
        "components-banner-color-icon-close-default": 1,
        "components-banner-color-bg-alert": 1,
        "components-banner-color-icon-alert": 1,
        "components-banner-color-title-alert": 1,
        "components-banner-color-body-alert": 1,
        "components-banner-color-icon-close-alert": 1,
        "components-breadcrumb-color-bg": 1,
        "components-breadcrumb-color-breadcrumb-link-default": 1,
        "components-breadcrumb-color-breadcrumb-link-hover": 1,
        "components-breadcrumb-color-breadcrumb-link-active": 1,
        "components-breadcrumb-color-icon-divider": 1,
        "components-bug-finder-prompt": 1,
        "components-button-color-filled-brand-default-bg": 1,
        "components-button-color-filled-brand-default-text": 1,
        "components-button-color-filled-brand-default-stroke": 1,
        "components-button-color-filled-brand-default-icon": 1,
        "components-button-color-filled-brand-hover-bg": 1,
        "components-button-color-filled-brand-hover-text": 1,
        "components-button-color-filled-brand-hover-stroke": 1,
        "components-button-color-filled-brand-hover-icon": 1,
        "components-button-color-filled-brand-pressed-bg": 1,
        "components-button-color-filled-brand-pressed-text": 1,
        "components-button-color-filled-brand-pressed-stroke": 1,
        "components-button-color-filled-brand-pressed-icon": 1,
        "components-button-color-filled-brand-disabled-bg": 0.01,
        "components-button-color-filled-brand-disabled-text": 0.02,
        "components-button-color-filled-brand-disabled-stroke": 0.01,
        "components-button-color-filled-brand-disabled-icon": 0.02,
        "components-button-color-filled-brand-focus-bg": 1,
        "components-button-color-filled-brand-focus-text": 1,
        "components-button-color-filled-brand-focus-stroke": 1,
        "components-button-color-filled-brand-focus-icon": 1,
        "components-button-color-filled-brand-focus-outline": 1,
        "components-button-color-filled-tonal-default-bg": 1,
        "components-button-color-filled-tonal-default-text": 1,
        "components-button-color-filled-tonal-default-stroke": 1,
        "components-button-color-filled-tonal-default-icon": 1,
        "components-button-color-filled-tonal-hover-bg": 1,
        "components-button-color-filled-tonal-hover-text": 1,
        "components-button-color-filled-tonal-hover-stroke": 1,
        "components-button-color-filled-tonal-hover-icon": 1,
        "components-button-color-filled-tonal-pressed-bg": 1,
        "components-button-color-filled-tonal-pressed-text": 1,
        "components-button-color-filled-tonal-pressed-stroke": 1,
        "components-button-color-filled-tonal-pressed-icon": 1,
        "components-button-color-filled-tonal-disabled-bg": 0.02,
        "components-button-color-filled-tonal-disabled-text": 0.04,
        "components-button-color-filled-tonal-disabled-stroke": 0.02,
        "components-button-color-filled-tonal-disabled-icon": 0.04,
        "components-button-color-filled-tonal-focus-bg": 1,
        "components-button-color-filled-tonal-focus-text": 1,
        "components-button-color-filled-tonal-focus-stroke": 1,
        "components-button-color-filled-tonal-focus-icon": 1,
        "components-button-color-filled-tonal-focus-outline": 1,
        "components-button-color-filled-white-default-bg": 1,
        "components-button-color-filled-white-default-text": 1,
        "components-button-color-filled-white-default-stroke": 1,
        "components-button-color-filled-white-default-icon": 1,
        "components-button-color-filled-white-hover-bg": 1,
        "components-button-color-filled-white-hover-text": 1,
        "components-button-color-filled-white-hover-stroke": 1,
        "components-button-color-filled-white-hover-icon": 1,
        "components-button-color-filled-white-pressed-bg": 1,
        "components-button-color-filled-white-pressed-text": 1,
        "components-button-color-filled-white-pressed-stroke": 1,
        "components-button-color-filled-white-pressed-icon": 1,
        "components-button-color-filled-white-disabled-bg": 0.02,
        "components-button-color-filled-white-disabled-text": 0.01,
        "components-button-color-filled-white-disabled-stroke": 0.02,
        "components-button-color-filled-white-disabled-icon": 0.01,
        "components-button-color-filled-white-focus-bg": 1,
        "components-button-color-filled-white-focus-text": 1,
        "components-button-color-filled-white-focus-stroke": 1,
        "components-button-color-filled-white-focus-icon": 1,
        "components-button-color-filled-white-focus-outline": 1,
        "components-button-color-outline-brand-default-bg": 0.02,
        "components-button-color-outline-brand-default-text": 1,
        "components-button-color-outline-brand-default-stroke": 1,
        "components-button-color-outline-brand-default-icon": 1,
        "components-button-color-outline-brand-hover-bg": 1,
        "components-button-color-outline-brand-hover-text": 1,
        "components-button-color-outline-brand-hover-stroke": 1,
        "components-button-color-outline-brand-hover-icon": 1,
        "components-button-color-outline-brand-pressed-bg": 1,
        "components-button-color-outline-brand-pressed-text": 1,
        "components-button-color-outline-brand-pressed-stroke": 1,
        "components-button-color-outline-brand-pressed-icon": 1,
        "components-button-color-outline-brand-disabled-bg": 0.02,
        "components-button-color-outline-brand-disabled-text": 0,
        "components-button-color-outline-brand-disabled-stroke": 0.01,
        "components-button-color-outline-brand-disabled-icon": 0,
        "components-button-color-outline-brand-focus-bg": 1,
        "components-button-color-outline-brand-focus-text": 1,
        "components-button-color-outline-brand-focus-stroke": 1,
        "components-button-color-outline-brand-focus-icon": 1,
        "components-button-color-outline-brand-focus-outline": 1,
        "components-button-color-outline-tonal-default-bg": 0.02,
        "components-button-color-outline-tonal-default-text": 1,
        "components-button-color-outline-tonal-default-stroke": 1,
        "components-button-color-outline-tonal-default-icon": 1,
        "components-button-color-outline-tonal-hover-bg": 1,
        "components-button-color-outline-tonal-hover-text": 1,
        "components-button-color-outline-tonal-hover-stroke": 1,
        "components-button-color-outline-tonal-hover-icon": 1,
        "components-button-color-outline-tonal-pressed-bg": 1,
        "components-button-color-outline-tonal-pressed-text": 1,
        "components-button-color-outline-tonal-pressed-stroke": 1,
        "components-button-color-outline-tonal-pressed-icon": 1,
        "components-button-color-outline-tonal-disabled-bg": 0.02,
        "components-button-color-outline-tonal-disabled-text": 0.04,
        "components-button-color-outline-tonal-disabled-stroke": 0.02,
        "components-button-color-outline-tonal-disabled-icon": 0.04,
        "components-button-color-outline-tonal-focus-bg": 0.02,
        "components-button-color-outline-tonal-focus-text": 1,
        "components-button-color-outline-tonal-focus-stroke": 1,
        "components-button-color-outline-tonal-focus-icon": 1,
        "components-button-color-outline-tonal-focus-outline": 1,
        "components-button-color-outline-white-default-bg": 0.02,
        "components-button-color-outline-white-default-text": 1,
        "components-button-color-outline-white-default-stroke": 1,
        "components-button-color-outline-white-default-icon": 1,
        "components-button-color-outline-white-hover-bg": 1,
        "components-button-color-outline-white-hover-text": 1,
        "components-button-color-outline-white-hover-stroke": 1,
        "components-button-color-outline-white-hover-icon": 1,
        "components-button-color-outline-white-pressed-bg": 1,
        "components-button-color-outline-white-pressed-text": 1,
        "components-button-color-outline-white-pressed-stroke": 1,
        "components-button-color-outline-white-pressed-icon": 1,
        "components-button-color-outline-white-disabled-bg": 0.02,
        "components-button-color-outline-white-disabled-text": 0.02,
        "components-button-color-outline-white-disabled-stroke": 0.02,
        "components-button-color-outline-white-disabled-icon": 0.02,
        "components-button-color-outline-white-focus-bg": 0.02,
        "components-button-color-outline-white-focus-text": 1,
        "components-button-color-outline-white-focus-stroke": 1,
        "components-button-color-outline-white-focus-icon": 1,
        "components-button-color-outline-white-focus-outline": 1,
        "components-button-color-text-brand-default-bg": 0.02,
        "components-button-color-text-brand-default-text": 1,
        "components-button-color-text-brand-default-stroke": 1,
        "components-button-color-text-brand-default-icon": 1,
        "components-button-color-text-brand-hover-bg": 0.02,
        "components-button-color-text-brand-hover-bg-icon-only": 1,
        "components-button-color-text-brand-hover-text": 1,
        "components-button-color-text-brand-hover-stroke": 1,
        "components-button-color-text-brand-hover-icon": 1,
        "components-button-color-text-brand-pressed-bg": 0.02,
        "components-button-color-text-brand-pressed-bg-icon-only": 1,
        "components-button-color-text-brand-pressed-text": 1,
        "components-button-color-text-brand-pressed-stroke": 1,
        "components-button-color-text-brand-pressed-icon": 1,
        "components-button-color-text-brand-disabled-bg": 0.02,
        "components-button-color-text-brand-disabled-text": null,
        "components-button-color-text-brand-disabled-stroke": null,
        "components-button-color-text-brand-disabled-icon": null,
        "components-button-color-text-brand-focus-bg": 1,
        "components-button-color-text-brand-focus-text": 1,
        "components-button-color-text-brand-focus-stroke": 1,
        "components-button-color-text-brand-focus-icon": 1,
        "components-button-color-text-brand-focus-outline": 1,
        "components-button-color-text-tonal-default-bg": 0.02,
        "components-button-color-text-tonal-default-text": 1,
        "components-button-color-text-tonal-default-stroke": 1,
        "components-button-color-text-tonal-default-icon": 1,
        "components-button-color-text-tonal-hover-bg": 0.02,
        "components-button-color-text-tonal-hover-bg-icon-only": 1,
        "components-button-color-text-tonal-hover-text": 1,
        "components-button-color-text-tonal-hover-stroke": 1,
        "components-button-color-text-tonal-hover-icon": 1,
        "components-button-color-text-tonal-pressed-bg": 0.02,
        "components-button-color-text-tonal-pressed-bg-icon-only": 1,
        "components-button-color-text-tonal-pressed-text": 1,
        "components-button-color-text-tonal-pressed-stroke": 1,
        "components-button-color-text-tonal-pressed-icon": 1,
        "components-button-color-text-tonal-disabled-bg": 0.02,
        "components-button-color-text-tonal-disabled-text": 0.04,
        "components-button-color-text-tonal-disabled-stroke": 0.04,
        "components-button-color-text-tonal-disabled-icon": 0.04,
        "components-button-color-text-tonal-focus-bg": 1,
        "components-button-color-text-tonal-focus-text": 1,
        "components-button-color-text-tonal-focus-stroke": 1,
        "components-button-color-text-tonal-focus-icon": 1,
        "components-button-color-text-tonal-focus-outline": 1,
        "components-button-color-text-white-default-bg": 0.02,
        "components-button-color-text-white-default-text": 1,
        "components-button-color-text-white-default-stroke": 1,
        "components-button-color-text-white-default-icon": 1,
        "components-button-color-text-white-hover-bg": 0.02,
        "components-button-color-text-white-hover-bg-icon-only": 1,
        "components-button-color-text-white-hover-text": 1,
        "components-button-color-text-white-hover-stroke": 1,
        "components-button-color-text-white-hover-icon": 1,
        "components-button-color-text-white-pressed-bg": 0.02,
        "components-button-color-text-white-pressed-bg-icon-only": 1,
        "components-button-color-text-white-pressed-text": 1,
        "components-button-color-text-white-pressed-stroke": 1,
        "components-button-color-text-white-pressed-icon": 1,
        "components-button-color-text-white-disabled-bg": 0.02,
        "components-button-color-text-white-disabled-text": 0.01,
        "components-button-color-text-white-disabled-stroke": 0.01,
        "components-button-color-text-white-disabled-icon": 0.01,
        "components-button-color-text-white-focus-bg": 1,
        "components-button-color-text-white-focus-text": 1,
        "components-button-color-text-white-focus-stroke": 1,
        "components-button-color-text-white-focus-icon": 1,
        "components-button-color-text-white-focus-outline": 1,
        "components-button-group-color-border": 1,
        "components-button-group-color-button-bg-default": 0.02,
        "components-button-group-color-button-fg-default": 1,
        "components-button-group-color-button-bg-hover": 1,
        "components-button-group-color-button-fg-hover": 1,
        "components-button-group-color-button-bg-active": 1,
        "components-button-group-color-button-fg-active": 1,
        "components-button-group-color-overlay-left-gradient-fill-1": 1,
        "components-button-group-color-overlay-left-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-1": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-3": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-4": 0.02,
        "components-button-group-color-stroke-hover-gradient-fill-1": 1,
        "components-button-group-color-stroke-hover-gradient-fill-2": 1,
        "components-button-group-color-stroke-hover-gradient-fill-3": 1,
        "components-button-group-color-stroke-hover-gradient-fill-4": 1,
        "components-button-group-color-stroke-active-gradient-fill-1": 1,
        "components-button-group-color-stroke-active-gradient-fill-2": 1,
        "components-button-group-color-stroke-active-gradient-fill-3": 1,
        "components-button-group-color-stroke-active-gradient-fill-4": 1,
        "components-category-card-color-bg": 1,
        "components-category-card-color-content-bg": 1,
        "components-category-card-color-category-bg": 1,
        "components-category-card-color-category-bg-2": 1,
        "components-category-card-color-category-bg-3": 1,
        "components-category-card-color-category-bg-4": 1,
        "components-category-card-color-category-bg-5": 1,
        "components-category-card-color-category-bg-6": 1,
        "components-category-card-color-title": 1,
        "components-category-card-color-copy": 1,
        "components-category-card-color-stroke": 1,
        "components-category-card-color-stroke-hover": 1,
        "components-category-card-listing-color-bg": 1,
        "components-category-card-listing-color-title": 1,
        "components-category-card-listing-color-copy": 1,
        "components-card-search-result-color-bg": 1,
        "components-card-search-result-color-label": 1,
        "components-card-search-result-color-title": 1,
        "components-card-search-result-color-body": 1,
        "components-card-search-result-color-divider-line": 1,
        "components-contact-us-promo-color-light-bg": 1,
        "components-contact-us-promo-color-light-title": 1,
        "components-contact-us-promo-color-light-body": 1,
        "components-contact-us-promo-color-default-bg": 1,
        "components-contact-us-promo-color-default-title": 1,
        "components-contact-us-promo-color-default-body": 1,
        "components-contact-us-promo-color-brand-bg": 1,
        "components-contact-us-promo-color-brand-title": 1,
        "components-contact-us-promo-color-brand-body": 1,
        "components-content-list-color-bg": 1,
        "components-content-list-color-title": 1,
        "components-content-list-color-body": 1,
        "components-content-list-color-icon": 1,
        "components-cookies-colors-bg": 1,
        "components-cookies-colors-title": 1,
        "components-cookies-colors-body": 1,
        "components-cookies-colors-icon": 1,
        "components-dropdown-bg": 1,
        "components-dropdown-border-default": 1,
        "components-dropdown-border-hover": 1,
        "components-dropdown-border-focused": 1,
        "components-dropdown-border-destructive": 1,
        "components-dropdown-label-text-default": 1,
        "components-dropdown-item-text-default": 1,
        "components-dropdown-item-bg-default": 1,
        "components-dropdown-item-bg-hover": 1,
        "components-dropdown-input-field-default": 1,
        "components-dropdown-input-field-focused": 1,
        "components-dropdown-input-label-focused": 1,
        "components-dropdown-input-field-destructive": 1,
        "components-external-link-card-bg": 1,
        "components-external-link-card-card-bg": 1,
        "components-external-link-card-card-title": 1,
        "components-external-link-card-card-subtitle": 1,
        "components-external-link-card-card-eyebrow": 1,
        "components-fab-color-border-default": 1,
        "components-footer-brand-color-bg": 1,
        "components-footer-brand-color-logo": 1,
        "components-footer-brand-color-title-text": 1,
        "components-footer-brand-color-description-text": 1,
        "components-footer-brand-color-footer-menu-expanded": 1,
        "components-footer-brand-color-link-default": 1,
        "components-footer-brand-color-link-hover": 1,
        "components-footer-brand-color-link-pressed": 1,
        "components-footer-brand-color-category-link-default": 1,
        "components-footer-brand-color-category-link-hover": 1,
        "components-footer-brand-color-category-link-pressed": 1,
        "components-footer-brand-color-subcategory-link-default": 1,
        "components-footer-brand-color-subcategory-link-hover": 1,
        "components-footer-brand-color-subcategory-link-pressed": 1,
        "components-footer-brand-color-icon-icon-default": 1,
        "components-footer-brand-color-icon-icon-pressed": 1,
        "components-footer-brand-color-social-icon-default": 1,
        "components-footer-brand-color-social-icon-hover": 1,
        "components-footer-brand-color-social-icon-pressed": 1,
        "components-footer-brand-spacing-category-link-hover": 1,
        "components-footer-global-color-bg": 1,
        "components-footer-global-color-title-text": 1,
        "components-footer-global-color-disclaimer-text": 1,
        "components-footer-global-color-link-default": 1,
        "components-footer-global-color-link-hover": 1,
        "components-footer-global-color-link-pressed": 1,
        "components-generic-card-color-bg": 1,
        "components-generic-card-color-content-bg": 1,
        "components-generic-card-color-title": 1,
        "components-generic-card-color-subtitle": 1,
        "components-generic-card-color-copy": 1,
        "components-global-search-color-omnibox-list-bg": 1,
        "components-global-search-color-omnibox-divider": 1,
        "components-global-search-color-omnibox-item-bg-default": 1,
        "components-global-search-color-omnibox-item-bg-hover": 1,
        "components-global-search-color-omnibox-item-text-default": 1,
        "components-global-search-color-omnibox-item-text-hover": 1,
        "components-global-search-color-omnibox-label-text-default": 1,
        "components-global-search-color-omnibox-category-text-default": 1,
        "components-generic-card-listing-color-bg": 1,
        "components-generic-card-listing-color-title": 1,
        "components-generic-card-listing-color-copy": 1,
        "components-header-color-bg": 1,
        "components-header-color-bottom-stroke": 1,
        "components-header-color-search-search-border-default": 1,
        "components-header-color-search-search-text": 1,
        "components-header-color-search-search-icon": 1,
        "components-header-color-search-search-bar": 1,
        "components-header-color-mobile-mobile-expanded-bg": 1,
        "components-header-color-mobile-pressed-bg": 1,
        "components-header-color-language-selector-language": 1,
        "components-header-color-language-selector-country": 1,
        "components-header-color-language-selector-country-hover-bg": 1,
        "components-header-color-language-selector-megamenu-language": 1,
        "components-header-color-language-selector-megamenu-country": 1,
        "components-header-color-language-selector-megamenu-country-hover": 1,
        "components-header-color-language-selector-bg": 1,
        "components-header-color-language-selector-stroke": 1,
        "components-header-color-mega-menu-bg": 1,
        "components-header-color-mega-menu-featured-bg": 1,
        "components-header-color-mega-menu-featured-title": 1,
        "components-header-color-mega-menu-category-title": 1,
        "components-header-color-mega-menu-bg-accent": 1,
        "components-header-color-icon-icon-default": 1,
        "components-header-color-icon-icon-hover": 1,
        "components-header-color-icon-icon-pressed": 1,
        "components-header-color-links-nav-main-link-default": 1,
        "components-header-color-links-nav-main-link-hover": 1,
        "components-header-color-links-nav-main-link-pressed": 1,
        "components-header-color-links-nav-main-link-active": 1,
        "components-header-color-links-nav-main-active-bar": 1,
        "components-header-color-links-nav-main-bg-color": 1,
        "components-header-color-links-nav-category-link-category-default": 1,
        "components-header-color-links-nav-category-link-category-hover": 1,
        "components-header-color-links-nav-category-link-category-pressed": 1,
        "components-header-color-links-nav-category-link-category-active": 1,
        "components-header-color-links-nav-menu-link-link-default": 1,
        "components-header-color-links-nav-menu-link-link-hover": 1,
        "components-header-color-links-nav-menu-link-link-pressed": 1,
        "components-header-color-links-nav-menu-link-link-active": 1,
        "components-header-color-links-nav-menu-link-bg": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-default": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-active": 1,
        "components-hero-homepage-color-bg": 1,
        "components-hero-homepage-color-container-bg": 1,
        "components-hero-homepage-color-eyebrow": 1,
        "components-hero-homepage-color-header": 1,
        "components-hero-homepage-color-sub-header": 1,
        "components-hero-homepage-color-body": 1,
        "components-hero-homepage-color-play-pause": 1,
        "components-hero-internal-color-default-title": 1,
        "components-hero-internal-color-default-body": 1,
        "components-hero-internal-color-default-bg": 1,
        "components-hero-internal-color-accent-bg": 1,
        "components-hero-internal-color-with-image-bg": 1,
        "components-hero-internal-color-default-bg-2": 1,
        "components-hero-internal-color-with-image-title": 1,
        "components-hero-internal-color-with-image-body": 1,
        "components-heading-page-title-color-default-bg": 1,
        "components-heading-page-title-color-default-title": 1,
        "components-heading-page-title-color-default-body": 1,
        "components-heading-page-title-color-light-bg": 1,
        "components-heading-page-title-color-light-title": 1,
        "components-heading-page-title-color-light-body": 1,
        "components-heading-page-title-color-color-bg": 1,
        "components-heading-page-title-color-color-title": 1,
        "components-heading-page-title-color-color-body": 1,
        "components-hyperlink-color-dark-default": 1,
        "components-hyperlink-color-dark-hover": 1,
        "components-hyperlink-color-dark-visited": 1,
        "components-hyperlink-color-light-default": 1,
        "components-hyperlink-color-light-hover": 1,
        "components-hyperlink-color-light-visited": 1,
        "components-icon-default": 1,
        "components-icon-focused": 1,
        "components-icon-card-color-bg": 1,
        "components-icon-card-color-icon-bg": 1,
        "components-icon-card-color-icon": 1,
        "components-icon-card-color-title": 1,
        "components-icon-card-color-subtitle": 1,
        "components-icon-card-color-description": 1,
        "components-icon-card-listing-color-bg": 1,
        "components-icon-card-listing-color-inner-bg": 1,
        "components-icon-card-listing-color-brand-bg": 1,
        "components-icon-card-listing-color-title": 1,
        "components-icon-card-listing-color-copy": 1,
        "components-icon-card-listing-color-inner-legal-text": 1,
        "components-jumplink-color-bg-default": 1,
        "components-jumplink-color-link-text-default": 1,
        "components-jumplink-color-link-text-hover": 1,
        "components-jumplink-color-link-border-hover": 1,
        "components-jumplink-color-link-text-active": 1,
        "components-jumplink-color-link-border-active": 1,
        "components-jumplink-color-title-text": 1,
        "components-jumplink-color-description-text": 1,
        "components-layout-bg-default": 1,
        "components-lightbox-color-overlay-bg": 0.02,
        "components-lightbox-color-title": 1,
        "components-lightbox-color-body": 1,
        "components-label-color-on-color-label-label-default": 1,
        "components-label-color-on-color-label-label-hover": 1,
        "components-label-color-on-color-label-label-pressed": 1,
        "components-label-color-on-color-background-bg-default": 0.02,
        "components-label-color-on-color-background-bg-hover": 1,
        "components-label-color-on-color-background-bg-pressed": 1,
        "components-label-color-on-color-stroke-hover": 0.02,
        "components-label-color-on-color-stroke-pressed": 0.02,
        "components-label-color-on-color-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-4": 1,
        "components-label-color-on-white-label-label-default": 1,
        "components-label-color-on-white-label-label-hover": 1,
        "components-label-color-on-white-label-label-pressed": 1,
        "components-label-color-on-white-background-bg-default": 0.02,
        "components-label-color-on-white-background-bg-hover": 1,
        "components-label-color-on-white-background-bg-pressed": 1,
        "components-label-color-on-white-stroke-hover": 1,
        "components-label-color-on-white-stroke-pressed": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-4": 1,
        "components-legal-disclaimer-color-default-bg": 1,
        "components-legal-disclaimer-color-default-title": 1,
        "components-legal-disclaimer-color-default-body": 1,
        "components-legal-disclaimer-color-light-bg": 1,
        "components-legal-disclaimer-color-light-title": 1,
        "components-legal-disclaimer-color-light-body": 1,
        "components-legal-disclaimer-color-brand-bg": 1,
        "components-legal-disclaimer-color-brand-title": 1,
        "components-legal-disclaimer-color-brand-body": 1,
        "components-media-video-thumbnail-overlay-bg": 0.02,
        "components-media-caption-color-bg": 1,
        "components-media-caption-color-text": 1,
        "components-media-gallery-color-bg": 1,
        "components-media-gallery-color-title": 1,
        "components-media-gallery-color-body": 1,
        "components-pagination-on-white-accent-default": 1,
        "components-pagination-on-white-accent-scroll": 1,
        "components-pagination-on-white-disabled": 1,
        "components-pagination-on-white-bg": 0.01,
        "components-pagination-on-white-stroke": 1,
        "components-pagination-on-white-bg-active": 1,
        "components-pagination-on-white-bg-hover": 1,
        "components-pagination-on-white-text-default": 1,
        "components-pagination-on-white-text-hover": 1,
        "components-pagination-on-white-text-active": 1,
        "components-pagination-on-color-accent-default": 1,
        "components-pagination-on-color-accent-scroll": 1,
        "components-pagination-on-color-disabled": 1,
        "components-pagination-on-color-bg": 1,
        "components-pagination-on-color-stroke": 0.02,
        "components-pagination-on-color-bg-active": 1,
        "components-pagination-on-color-bg-hover": 1,
        "components-pagination-on-color-text-default": 1,
        "components-pagination-on-color-text-hover": 1,
        "components-pagination-on-color-text-active": 1,
        "components-pdf-link-card-color-bg": 1,
        "components-pdf-link-card-color-card-bg": 1,
        "components-pdf-link-card-color-card-title": 1,
        "components-pdf-link-card-color-card-subtitle": 1,
        "components-pdf-link-card-color-card-body": 1,
        "components-product-card-color-bg": 1,
        "components-product-card-color-content-bg": 1,
        "components-product-card-color-border": 1,
        "components-product-card-color-title": 1,
        "components-product-card-color-description": 1,
        "components-product-card-color-star-rating": 1,
        "components-product-card-color-badge-bg": 1,
        "components-product-card-color-badge-text": 1,
        "components-product-card-listing-color-bg": 1,
        "components-product-card-listing-color-title": 1,
        "components-product-card-listing-color-copy": 1,
        "components-product-card-listing-color-legal-text": 1,
        "components-product-information-color-bg": 1,
        "components-product-information-color-content-bg": 1,
        "components-product-information-color-headline": 1,
        "components-product-information-color-body": 1,
        "components-product-information-color-detail-text": 1,
        "components-product-information-color-divider": 1,
        "components-product-information-color-icon": 1,
        "components-product-information-color-play-icon": 1,
        "components-product-information-color-selected-stroke": 1,
        "components-product-results-list-with-search-color-bg": 1,
        "components-promo-color-default-bg": 1,
        "components-promo-color-default-image-bg": 1,
        "components-promo-color-default-content-bg": 1,
        "components-promo-color-default-title": 1,
        "components-promo-color-default-body": 1,
        "components-promo-color-default-category": 1,
        "components-promo-color-light-bg": 1,
        "components-promo-color-light-image-bg": 1,
        "components-promo-color-light-content-bg": 1,
        "components-promo-color-light-title": 1,
        "components-promo-color-light-body": 1,
        "components-promo-color-light-category": 1,
        "components-promo-color-brand-bg": 1,
        "components-promo-color-brand-image-bg": 1,
        "components-promo-color-brand-content-bg": 1,
        "components-promo-color-brand-title": 1,
        "components-promo-color-brand-body": 1,
        "components-promo-color-brand-category": 1,
        "components-pull-quote-color-bg": 1,
        "components-pull-quote-color-stroke": 1,
        "components-pull-quote-color-quote-bg": 1,
        "components-pull-quote-color-quote-title": 1,
        "components-pull-quote-color-quote-by-text": 1,
        "components-pull-quote-color-quote-detail": 1,
        "components-pull-quote-color-quote-highlight": 1,
        "components-promo-alternate-color-default-bg": 1,
        "components-promo-alternate-color-default-image-bg": 1,
        "components-promo-alternate-color-default-content-bg": 1,
        "components-promo-alternate-color-default-title": 1,
        "components-promo-alternate-color-default-body": 1,
        "components-promo-alternate-color-default-category": 1,
        "components-promo-alternate-color-light-bg": 1,
        "components-promo-alternate-color-light-image-bg": 1,
        "components-promo-alternate-color-light-content-bg": 1,
        "components-promo-alternate-color-light-title": 1,
        "components-promo-alternate-color-light-body": 1,
        "components-promo-alternate-color-light-category": 1,
        "components-promo-alternate-color-brand-bg": 1,
        "components-promo-alternate-color-brand-image-bg": 1,
        "components-promo-alternate-color-brand-content-bg": 1,
        "components-promo-alternate-color-brand-title": 1,
        "components-promo-alternate-color-brand-body": 1,
        "components-promo-alternate-color-brand-category": 1,
        "components-share-list-color-title": 1,
        "components-share-list-color-bg": 1,
        "components-share-list-color-link-default": 1,
        "components-share-list-color-link-hover": 1,
        "components-share-list-color-bg-link-hover": 1,
        "components-share-list-color-close-icon": 1,
        "components-share-list-color-facebook-icon": 1,
        "components-share-list-color-pinterest-icon": 1,
        "components-share-list-color-twitter-icon": 1,
        "components-share-list-color-basic-icon-default": 1,
        "components-share-list-color-icon-hover": 1,
        "components-share-list-color-border": 1,
        "components-site-search-bg": 1,
        "components-site-search-results_text": 1,
        "components-site-search-filter-filter-selected-bg": 1,
        "components-site-search-facet-group-label-bottom-line": 1,
        "components-site-search-facet-group-label-text": 1,
        "components-site-search-facet-group-label-bg": 1,
        "components-site-search-facet-bg": 1,
        "components-site-search-facet-stroke": 1,
        "components-site-search-refine-bg": 1,
        "components-site-search-refine-right-divider": 1,
        "components-site-search-refine-title": 1,
        "components-site-search-hero-search-bg": 1,
        "components-site-search-hero-search-accent-bg": 1,
        "components-site-search-hero-search-title": 1,
        "components-stats-stat-text": 1,
        "components-stats-stat-text-on-light": 1,
        "components-stats-supporting-text": 1,
        "components-stats-bg-default": 1,
        "components-stats-bg-inner": 1,
        "components-stats-bg-section-inner": 1,
        "components-stats-title": 1,
        "components-stats-copy": 1,
        "components-supporting-text-default": 1,
        "components-supporting-text-destructive": 1,
        "components-tab-bg": 1,
        "components-tab-border-default": 1,
        "components-tab-border-hover": 1,
        "components-tab-border-focused": 1,
        "components-tab-border-destructive": 1,
        "components-tab-label-text-default": 1,
        "components-tab-item-text-default": 1,
        "components-tab-item-bg-default": 1,
        "components-tab-item-bg-hover": 1,
        "components-tab-input-field-default": 1,
        "components-tab-input-field-focused": 1,
        "components-tab-input-label-focused": 1,
        "components-tab-input-field-destructive": 1,
        "components-tab-bg-2": 1,
        "components-tag-color-bg": 1,
        "components-tag-color-text": 1,
        "components-tag-color-stroke": 1,
        "components-tag-color-bg-hover": 1,
        "components-tag-color-text-hover": 1,
        "components-tag-color-stroke-hover": 1,
        "components-text-field-bg": 1,
        "components-text-field-bg-focused": 1,
        "components-text-field-border-default": 1,
        "components-text-field-border-hover": 1,
        "components-text-field-border-focused": 1,
        "components-text-field-border-destructive": 1,
        "components-text-field-input-field-default": 1,
        "components-text-field-input-field-focused": 1,
        "components-text-field-input-label-focused": 1,
        "components-text-field-input-field-destructive": 1,
        "components-text-field-icon-default": 1,
        "components-text-field-icon-destructive": 1,
        "components-text-field-supporting-text-default": 1,
        "components-text-field-supporting-text-destructive": 1,
        "components-tip-color-bg": 1,
        "components-tip-color-title": 1,
        "components-tip-color-body": 1,
        "components-tip-color-stroke": 1,
        "components-video-color-bg": 1,
        "themes-general-surface-color-light-bg": 1,
        "themes-general-surface-color-light-title": 1,
        "themes-general-surface-color-light-body": 1,
        "themes-general-surface-color-light-decor-line-break": 1,
        "themes-general-surface-color-light-rule-line": 0.01,
        "themes-general-surface-color-dark-bg": 1,
        "themes-general-surface-color-dark-title": 1,
        "themes-general-surface-color-dark-body": 1,
        "themes-general-surface-color-dark-decor-line-break": 1,
        "themes-general-card-color-dark-title": 1,
        "themes-general-card-color-dark-subtitle": 1,
        "themes-general-card-color-dark-body": 1,
        "themes-general-card-color-light-title": 1,
        "themes-general-card-color-light-subtitle": 1,
        "themes-general-card-color-light-body": 1,
        "themes-shadow-color": 1,
        "themes-shadow-share-list-color-fill": 0,
        "themes-shadow-header-menu-color-fill": 0,
        "themes-gradient-loader-gradient-fill-1": 1,
        "themes-gradient-loader-gradient-fill-2": 0,
        "themes-color-background-white": 1,
        "themes-color-background-light": 1,
        "themes-color-background-dark": 1,
        "themes-color-background-black": 1,
        "themes-color-background-brand1": 1,
        "themes-color-background-brand2": 1,
        "themes-color-background-accent1": 1,
        "themes-color-background-accent2": 1,
        "themes-color-background-body": 1,
        "themes-color-stroke-white": 1,
        "themes-color-stroke-light": 1,
        "themes-color-stroke-dark": 1,
        "themes-color-stroke-black": 1,
        "themes-color-stroke-brand1": 1,
        "themes-color-stroke-brand2": 1,
        "themes-color-stroke-accent1": 1,
        "themes-color-stroke-accent2": 1,
        "themes-color-text-white": 1,
        "themes-color-text-light": 1,
        "themes-color-text-dark": 1,
        "themes-color-text-black": 1,
        "themes-color-text-brand1": 1,
        "themes-color-text-brand2": 1,
        "themes-color-text-accent1": 1,
        "themes-color-text-accent2": 1,
        "colors-grayscale-100": 1,
        "colors-grayscale-200": 1,
        "colors-grayscale-300": 1,
        "colors-grayscale-400": 1,
        "colors-grayscale-500": 1,
        "colors-grayscale-600": 1,
        "colors-grayscale-700": 1,
        "colors-brand-1-100": 1,
        "colors-brand-1-200": 1,
        "colors-brand-1-300": 1,
        "colors-brand-1-400": 1,
        "colors-brand-1-500": 1,
        "colors-brand-1-600": 1,
        "colors-brand-1-700": 1,
        "colors-brand-2-100": 1,
        "colors-brand-2-200": 1,
        "colors-brand-2-300": 1,
        "colors-brand-2-400": 1,
        "colors-brand-2-500": 1,
        "colors-brand-2-600": 1,
        "colors-brand-2-700": 1,
        "colors-accent-1-100": 1,
        "colors-accent-1-200": 1,
        "colors-accent-1-300": 1,
        "colors-accent-1-400": 1,
        "colors-accent-1-500": 1,
        "colors-accent-1-600": 1,
        "colors-accent-1-700": 1,
        "colors-accent-2-100": 1,
        "colors-accent-2-200": 1,
        "colors-accent-2-300": 1,
        "colors-accent-2-400": 1,
        "colors-accent-2-500": 1,
        "colors-accent-2-600": 1,
        "colors-accent-2-700": 1,
        "colors-accent-3-100": 1,
        "colors-accent-3-200": 1,
        "colors-accent-3-300": 1,
        "colors-accent-3-400": 1,
        "colors-accent-3-500": 1,
        "colors-accent-3-600": 1,
        "colors-accent-3-700": 1,
        "colors-accent-4-400_storage": 1,
        "colors-accessible-accent-1-100": 1,
        "colors-accessible-accent-1-200": 1,
        "colors-accessible-accent-1-300": 1,
        "colors-accessible-accent-1-400": 1,
        "colors-accessible-accent-1-500": 1,
        "colors-accessible-accent-1-600": 1,
        "colors-accessible-accent-1-700": 1,
        "colors-accessible-accent-2-100": 1,
        "colors-accessible-accent-2-200": 1,
        "colors-accessible-accent-2-300": 1,
        "colors-accessible-accent-2-400": 1,
        "colors-accessible-accent-2-500": 1,
        "colors-accessible-accent-2-600": 1,
        "colors-accessible-accent-2-700": 1,
        "colors-accessible-brand-2-100": 1,
        "colors-accessible-brand-2-200": 1,
        "colors-accessible-brand-2-300": 1,
        "colors-accessible-brand-2-400": 1,
        "colors-accessible-brand-2-500": 1,
        "colors-accessible-brand-2-600": 1,
        "colors-accessible-brand-2-700": 1
      }
    },
    "Off": {
      "colors": {
        "components-accessibility-bar-color-bg": "#183d20",
        "components-accessibility-bar-color-text": "#ffffff",
        "components-accordion-color-bg": "#ffffff",
        "components-accordion-color-title": "#191b1c",
        "components-accordion-color-body": "#27282b",
        "components-accordion-color-section-section-bg": "#ffffff",
        "components-accordion-color-section-section-title": "#191b1c",
        "components-accordion-color-section-section-body": "#27282b",
        "components-accordion-color-item-item-bg": "#ffffff",
        "components-accordion-color-item-divider": "#e8e8ea",
        "components-accordion-color-item-icon-icon-default": "#d44300",
        "components-accordion-color-item-icon-icon-hover": "#ffffff",
        "components-accordion-color-item-icon-icon-pressed": "#ffffff",
        "components-accordion-color-item-icon-icon-active": "#ffffff",
        "components-accordion-color-item-title-item-default": "#d44300",
        "components-accordion-color-item-title-item-hover": "#ffffff",
        "components-accordion-color-item-title-item-pressed": "#ffffff",
        "components-accordion-color-item-title-item-active": "#ffffff",
        "components-accordion-color-content-bg": "#ffffff",
        "components-accordion-color-content-title": "#191b1c",
        "components-accordion-color-content-body": "#27282b",
        "components-article-card-color-bg": "#fcfcfd",
        "components-article-card-color-border": "#e8e8ea",
        "components-article-card-color-content-bg": "#fcfcfd",
        "components-article-card-color-title": "#191b1c",
        "components-article-card-color-subtitle": "#27282b",
        "components-article-card-color-copy": "#27282b",
        "components-article-card-color-description": "#27282b",
        "components-article-card-listing-color-default-bg": "#ffffff",
        "components-article-card-listing-color-default-title": "#191b1c",
        "components-article-card-listing-color-default-copy": "#27282b",
        "components-article-hero-color-content-bg": "#ffffff",
        "components-article-hero-color-bg": "#ffffff",
        "components-article-hero-color-headline": "#191b1c",
        "components-article-hero-color-body": "#27282b",
        "components-article-hero-color-detail-text": "#27282b",
        "components-article-hero-color-divider": "#a2a5ab",
        "components-article-hero-color-icon": "#d44300",
        "components-article-hero-color-play-icon": "#ffffff",
        "components-article-hero-color-selected-stroke": "#e22726",
        "components-banner-color-bg-default": "#fff1eb",
        "components-banner-color-icon-default": "#fe5000",
        "components-banner-color-title-default": "#471600",
        "components-banner-color-body-default": "#373432",
        "components-banner-color-icon-close-default": "#191b1c",
        "components-banner-color-bg-alert": "#b32b30",
        "components-banner-color-icon-alert": "#ffffff",
        "components-banner-color-title-alert": "#ffffff",
        "components-banner-color-body-alert": "#ffffff",
        "components-banner-color-icon-close-alert": "#ffffff",
        "components-breadcrumb-color-bg": "#ffffff",
        "components-breadcrumb-color-breadcrumb-link-default": "#72757e",
        "components-breadcrumb-color-breadcrumb-link-hover": "#4e5056",
        "components-breadcrumb-color-breadcrumb-link-active": "#27282b",
        "components-breadcrumb-color-icon-divider": "#a2a5ab",
        "components-bug-finder-prompt": "#ffffff",
        "components-button-color-filled-brand-default-bg": "#191b1c",
        "components-button-color-filled-brand-default-text": "#ffffff",
        "components-button-color-filled-brand-default-stroke": "#191b1c",
        "components-button-color-filled-brand-default-icon": "#ffffff",
        "components-button-color-filled-brand-hover-bg": "#27282b",
        "components-button-color-filled-brand-hover-text": "#ffffff",
        "components-button-color-filled-brand-hover-stroke": "#27282b",
        "components-button-color-filled-brand-hover-icon": "#ffffff",
        "components-button-color-filled-brand-pressed-bg": "#27282b",
        "components-button-color-filled-brand-pressed-text": "#ffffff",
        "components-button-color-filled-brand-pressed-stroke": "#27282b",
        "components-button-color-filled-brand-pressed-icon": "#ffffff",
        "components-button-color-filled-brand-disabled-bg": "rgba(25, 27, 28, 0.38)",
        "components-button-color-filled-brand-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-disabled-stroke": "rgba(25, 27, 28, 0.38)",
        "components-button-color-filled-brand-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-focus-bg": "#191b1c",
        "components-button-color-filled-brand-focus-text": "#ffffff",
        "components-button-color-filled-brand-focus-stroke": "#191b1c",
        "components-button-color-filled-brand-focus-icon": "#ffffff",
        "components-button-color-filled-brand-focus-outline": "#286efa",
        "components-button-color-filled-tonal-default-bg": "#fff1ea",
        "components-button-color-filled-tonal-default-text": "#b33900",
        "components-button-color-filled-tonal-default-stroke": "#fff1ea",
        "components-button-color-filled-tonal-default-icon": "#b33900",
        "components-button-color-filled-tonal-hover-bg": "#ffb694",
        "components-button-color-filled-tonal-hover-text": "#812900",
        "components-button-color-filled-tonal-hover-stroke": "#ffb694",
        "components-button-color-filled-tonal-hover-icon": "#812900",
        "components-button-color-filled-tonal-pressed-bg": "#ffb694",
        "components-button-color-filled-tonal-pressed-text": "#812900",
        "components-button-color-filled-tonal-pressed-stroke": "#ffb694",
        "components-button-color-filled-tonal-pressed-icon": "#812900",
        "components-button-color-filled-tonal-disabled-bg": "rgba(255, 241, 234, 0.38)",
        "components-button-color-filled-tonal-disabled-text": "rgba(179, 57, 0, 0.38)",
        "components-button-color-filled-tonal-disabled-stroke": "rgba(255, 241, 234, 0.38)",
        "components-button-color-filled-tonal-disabled-icon": "rgba(179, 57, 0, 0.38)",
        "components-button-color-filled-tonal-focus-bg": "#fff1ea",
        "components-button-color-filled-tonal-focus-text": "#b33900",
        "components-button-color-filled-tonal-focus-stroke": "#fff1ea",
        "components-button-color-filled-tonal-focus-icon": "#b33900",
        "components-button-color-filled-tonal-focus-outline": "#1c4cae",
        "components-button-color-filled-white-default-bg": "#ffffff",
        "components-button-color-filled-white-default-text": "#191b1c",
        "components-button-color-filled-white-default-stroke": "#ffffff",
        "components-button-color-filled-white-default-icon": "#fe5000",
        "components-button-color-filled-white-hover-bg": "#fff1eb",
        "components-button-color-filled-white-hover-text": "#191b1c",
        "components-button-color-filled-white-hover-stroke": "#fff1eb",
        "components-button-color-filled-white-hover-icon": "#ce450d",
        "components-button-color-filled-white-pressed-bg": "#fff1eb",
        "components-button-color-filled-white-pressed-text": "#191b1c",
        "components-button-color-filled-white-pressed-stroke": "#fff1eb",
        "components-button-color-filled-white-pressed-icon": "#ce450d",
        "components-button-color-filled-white-disabled-bg": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-text": "rgba(25, 27, 28, 0.38)",
        "components-button-color-filled-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-icon": "rgba(254, 80, 0, 0.38)",
        "components-button-color-filled-white-focus-bg": "#ffffff",
        "components-button-color-filled-white-focus-text": "#191b1c",
        "components-button-color-filled-white-focus-stroke": "#ffffff",
        "components-button-color-filled-white-focus-icon": "#fe5000",
        "components-button-color-filled-white-focus-outline": "#1c4cae",
        "components-button-color-outline-brand-default-bg": "rgba(25, 27, 28, 0.00)",
        "components-button-color-outline-brand-default-text": "#191b1c",
        "components-button-color-outline-brand-default-stroke": "#191b1c",
        "components-button-color-outline-brand-default-icon": "#191b1c",
        "components-button-color-outline-brand-hover-bg": "#27282b",
        "components-button-color-outline-brand-hover-text": "#ffffff",
        "components-button-color-outline-brand-hover-stroke": "#27282b",
        "components-button-color-outline-brand-hover-icon": "#ffffff",
        "components-button-color-outline-brand-pressed-bg": "#27282b",
        "components-button-color-outline-brand-pressed-text": "#ffffff",
        "components-button-color-outline-brand-pressed-stroke": "#27282b",
        "components-button-color-outline-brand-pressed-icon": "#ffffff",
        "components-button-color-outline-brand-disabled-bg": "rgba(25, 27, 28, 0.00)",
        "components-button-color-outline-brand-disabled-text": "rgba(25, 27, 28, 0.38)",
        "components-button-color-outline-brand-disabled-stroke": "rgba(25, 27, 28, 0.38)",
        "components-button-color-outline-brand-disabled-icon": "rgba(25, 27, 28, 0.38)",
        "components-button-color-outline-brand-focus-bg": "#ffffff",
        "components-button-color-outline-brand-focus-text": "#191b1c",
        "components-button-color-outline-brand-focus-stroke": "#191b1c",
        "components-button-color-outline-brand-focus-icon": "#191b1c",
        "components-button-color-outline-brand-focus-outline": "#286efa",
        "components-button-color-outline-tonal-default-bg": "#ffffff",
        "components-button-color-outline-tonal-default-text": "#d44300",
        "components-button-color-outline-tonal-default-stroke": "#fe5000",
        "components-button-color-outline-tonal-default-icon": "#d44300",
        "components-button-color-outline-tonal-hover-bg": "#d44300",
        "components-button-color-outline-tonal-hover-text": "#ffffff",
        "components-button-color-outline-tonal-hover-stroke": "#d44300",
        "components-button-color-outline-tonal-hover-icon": "#ffffff",
        "components-button-color-outline-tonal-pressed-bg": "#d44300",
        "components-button-color-outline-tonal-pressed-text": "#ffffff",
        "components-button-color-outline-tonal-pressed-stroke": "#d44300",
        "components-button-color-outline-tonal-pressed-icon": "#ffffff",
        "components-button-color-outline-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-tonal-disabled-text": "rgba(254, 80, 0, 0.38)",
        "components-button-color-outline-tonal-disabled-stroke": "rgba(254, 80, 0, 0.38)",
        "components-button-color-outline-tonal-disabled-icon": "rgba(254, 80, 0, 0.38)",
        "components-button-color-outline-tonal-focus-bg": "#ffffff",
        "components-button-color-outline-tonal-focus-text": "#d44300",
        "components-button-color-outline-tonal-focus-stroke": "#fe5000",
        "components-button-color-outline-tonal-focus-icon": "#d44300",
        "components-button-color-outline-tonal-focus-outline": "#286efa",
        "components-button-color-outline-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-default-text": "#ffffff",
        "components-button-color-outline-white-default-stroke": "#ffffff",
        "components-button-color-outline-white-default-icon": "#ffffff",
        "components-button-color-outline-white-hover-bg": "#ffffff",
        "components-button-color-outline-white-hover-text": "#191b1c",
        "components-button-color-outline-white-hover-stroke": "#ffffff",
        "components-button-color-outline-white-hover-icon": "#fe5000",
        "components-button-color-outline-white-pressed-bg": "#ffffff",
        "components-button-color-outline-white-pressed-text": "#191b1c",
        "components-button-color-outline-white-pressed-stroke": "#ffffff",
        "components-button-color-outline-white-pressed-icon": "#fe5000",
        "components-button-color-outline-white-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-focus-bg": "rgba(255, 255, 255, 0.01)",
        "components-button-color-outline-white-focus-text": "#ffffff",
        "components-button-color-outline-white-focus-stroke": "#ffffff",
        "components-button-color-outline-white-focus-icon": "#ffffff",
        "components-button-color-outline-white-focus-outline": "#286efa",
        "components-button-color-text-brand-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-default-text": "#191b1c",
        "components-button-color-text-brand-default-stroke": "#191b1c",
        "components-button-color-text-brand-default-icon": "#191b1c",
        "components-button-color-text-brand-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-hover-bg-icon-only": "#e8e8ea",
        "components-button-color-text-brand-hover-text": "#191b1c",
        "components-button-color-text-brand-hover-stroke": "#191b1c",
        "components-button-color-text-brand-hover-icon": "#191b1c",
        "components-button-color-text-brand-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-pressed-bg-icon-only": "#e8e8ea",
        "components-button-color-text-brand-pressed-text": "#191b1c",
        "components-button-color-text-brand-pressed-stroke": "#191b1c",
        "components-button-color-text-brand-pressed-icon": "#191b1c",
        "components-button-color-text-brand-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-disabled-text": "rgba(25, 27, 28, 0.38)",
        "components-button-color-text-brand-disabled-stroke": "rgba(25, 27, 28, 0.38)",
        "components-button-color-text-brand-disabled-icon": "rgba(25, 27, 28, 0.38)",
        "components-button-color-text-brand-focus-bg": "#d5e3ff",
        "components-button-color-text-brand-focus-text": "#191b1c",
        "components-button-color-text-brand-focus-stroke": "#191b1c",
        "components-button-color-text-brand-focus-icon": "#1c4cae",
        "components-button-color-text-brand-focus-outline": "#1c4cae",
        "components-button-color-text-tonal-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-default-text": "#d44300",
        "components-button-color-text-tonal-default-stroke": "#fe5000",
        "components-button-color-text-tonal-default-icon": "#fe5000",
        "components-button-color-text-tonal-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-hover-bg-icon-only": "#fff1eb",
        "components-button-color-text-tonal-hover-text": "#b33900",
        "components-button-color-text-tonal-hover-stroke": "#fe5000",
        "components-button-color-text-tonal-hover-icon": "#b33900",
        "components-button-color-text-tonal-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-pressed-bg-icon-only": "#fff1eb",
        "components-button-color-text-tonal-pressed-text": "#b33900",
        "components-button-color-text-tonal-pressed-stroke": "#fe5000",
        "components-button-color-text-tonal-pressed-icon": "#b33900",
        "components-button-color-text-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-disabled-text": "rgba(254, 80, 0, 0.38)",
        "components-button-color-text-tonal-disabled-stroke": "rgba(254, 80, 0, 0.38)",
        "components-button-color-text-tonal-disabled-icon": "rgba(254, 80, 0, 0.38)",
        "components-button-color-text-tonal-focus-bg": "#d5e3ff",
        "components-button-color-text-tonal-focus-text": "#d44300",
        "components-button-color-text-tonal-focus-stroke": "#fe5000",
        "components-button-color-text-tonal-focus-icon": "#1c4cae",
        "components-button-color-text-tonal-focus-outline": "#1c4cae",
        "components-button-color-text-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-default-text": "#ffffff",
        "components-button-color-text-white-default-stroke": "#ffffff",
        "components-button-color-text-white-default-icon": "#ffffff",
        "components-button-color-text-white-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-hover-bg-icon-only": "#27282b",
        "components-button-color-text-white-hover-text": "#ffffff",
        "components-button-color-text-white-hover-stroke": "#ffffff",
        "components-button-color-text-white-hover-icon": "#ffffff",
        "components-button-color-text-white-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-pressed-bg-icon-only": "#27282b",
        "components-button-color-text-white-pressed-text": "#ffffff",
        "components-button-color-text-white-pressed-stroke": "#ffffff",
        "components-button-color-text-white-pressed-icon": "#ffffff",
        "components-button-color-text-white-disabled-bg": "rgba(115, 119, 128, 0.00)",
        "components-button-color-text-white-disabled-text": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-stroke": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-icon": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-focus-bg": "#d5e3ff",
        "components-button-color-text-white-focus-text": "#ffffff",
        "components-button-color-text-white-focus-stroke": "#ffffff",
        "components-button-color-text-white-focus-icon": "#1c4cae",
        "components-button-color-text-white-focus-outline": "#1c4cae",
        "components-button-group-color-border": "#e8e8ea",
        "components-button-group-color-button-bg-default": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-button-fg-default": "#b33900",
        "components-button-group-color-button-bg-hover": "#ffb694",
        "components-button-group-color-button-fg-hover": "#812900",
        "components-button-group-color-button-bg-active": "#fff1ea",
        "components-button-group-color-button-fg-active": "#b33900",
        "components-button-group-color-overlay-left-gradient-fill-1": "#ffffff",
        "components-button-group-color-overlay-left-gradient-fill-2": "rgba(255, 255, 255, 0.50)",
        "components-button-group-color-stroke-default-gradient-fill-1": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-2": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-3": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-4": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-1": "#ffb694",
        "components-button-group-color-stroke-hover-gradient-fill-2": "#ffb694",
        "components-button-group-color-stroke-hover-gradient-fill-3": "#ffb694",
        "components-button-group-color-stroke-hover-gradient-fill-4": "#ffb694",
        "components-button-group-color-stroke-active-gradient-fill-1": "#fff1ea",
        "components-button-group-color-stroke-active-gradient-fill-2": "#fff1ea",
        "components-button-group-color-stroke-active-gradient-fill-3": "#fff1ea",
        "components-button-group-color-stroke-active-gradient-fill-4": "#ffffff",
        "components-category-card-color-bg": "#ffffff",
        "components-category-card-color-content-bg": "#ffffff",
        "components-category-card-color-category-bg": "#9976e4",
        "components-category-card-color-category-bg-2": "#286636",
        "components-category-card-color-category-bg-3": "#ff844c",
        "components-category-card-color-category-bg-4": "#fe5000",
        "components-category-card-color-category-bg-5": "#a9e8a0",
        "components-category-card-color-category-bg-6": "#00aed6",
        "components-category-card-color-title": "#191b1c",
        "components-category-card-color-copy": "#27282b",
        "components-category-card-color-stroke": "#e8e8ea",
        "components-category-card-color-stroke-hover": "#fe5000",
        "components-category-card-listing-color-bg": "#ffffff",
        "components-category-card-listing-color-title": "#191b1c",
        "components-category-card-listing-color-copy": "#27282b",
        "components-card-search-result-color-bg": "#ffffff",
        "components-card-search-result-color-label": "#27282b",
        "components-card-search-result-color-title": "#191b1c",
        "components-card-search-result-color-body": "#27282b",
        "components-card-search-result-color-divider-line": "#e8e8ea",
        "components-contact-us-promo-color-light-bg": "#fff1eb",
        "components-contact-us-promo-color-light-title": "#191b1c",
        "components-contact-us-promo-color-light-body": "#27282b",
        "components-contact-us-promo-color-default-bg": "#ffffff",
        "components-contact-us-promo-color-default-title": "#191b1c",
        "components-contact-us-promo-color-default-body": "#27282b",
        "components-contact-us-promo-color-brand-bg": "#d44300",
        "components-contact-us-promo-color-brand-title": "#ffffff",
        "components-contact-us-promo-color-brand-body": "#fcfcfd",
        "components-content-list-color-bg": "#ffffff",
        "components-content-list-color-title": "#191b1c",
        "components-content-list-color-body": "#27282b",
        "components-content-list-color-icon": "#fe5000",
        "components-cookies-colors-bg": "#ffffff",
        "components-cookies-colors-title": "#191b1c",
        "components-cookies-colors-body": "#27282b",
        "components-cookies-colors-icon": "#d44300",
        "components-dropdown-bg": "#ffffff",
        "components-dropdown-border-default": "#72757e",
        "components-dropdown-border-hover": "#fe5000",
        "components-dropdown-border-focused": "#191b1c",
        "components-dropdown-border-destructive": "#b32b30",
        "components-dropdown-label-text-default": "#191b1c",
        "components-dropdown-item-text-default": "#191b1c",
        "components-dropdown-item-bg-default": "#ffffff",
        "components-dropdown-item-bg-hover": "#fff1eb",
        "components-dropdown-input-field-default": "#72757e",
        "components-dropdown-input-field-focused": "#191b1c",
        "components-dropdown-input-label-focused": "#191b1c",
        "components-dropdown-input-field-destructive": "#b32b30",
        "components-external-link-card-bg": "#ffffff",
        "components-external-link-card-card-bg": "#ffffff",
        "components-external-link-card-card-title": "#191b1c",
        "components-external-link-card-card-subtitle": "#27282b",
        "components-external-link-card-card-eyebrow": "#4e5056",
        "components-fab-color-border-default": "#ffffff",
        "components-footer-brand-color-bg": "#191b1c",
        "components-footer-brand-color-logo": "#ffffff",
        "components-footer-brand-color-title-text": "#ffffff",
        "components-footer-brand-color-description-text": "#fcfcfd",
        "components-footer-brand-color-footer-menu-expanded": "#191b1c",
        "components-footer-brand-color-link-default": "#ffffff",
        "components-footer-brand-color-link-hover": "#fff1eb",
        "components-footer-brand-color-link-pressed": "#ffb999",
        "components-footer-brand-color-category-link-default": "#ffffff",
        "components-footer-brand-color-category-link-hover": "#fcfcfd",
        "components-footer-brand-color-category-link-pressed": "#e8e8ea",
        "components-footer-brand-color-subcategory-link-default": "#fcfcfd",
        "components-footer-brand-color-subcategory-link-hover": "#fff1eb",
        "components-footer-brand-color-subcategory-link-pressed": "#ff844c",
        "components-footer-brand-color-icon-icon-default": "#ffffff",
        "components-footer-brand-color-icon-icon-pressed": "#e8e8ea",
        "components-footer-brand-color-social-icon-default": "#ffffff",
        "components-footer-brand-color-social-icon-hover": "#ffb999",
        "components-footer-brand-color-social-icon-pressed": "#ce450d",
        "components-footer-brand-spacing-category-link-hover": "#000000",
        "components-footer-global-color-bg": "#ffffff",
        "components-footer-global-color-title-text": "#000000",
        "components-footer-global-color-disclaimer-text": "#000000",
        "components-footer-global-color-link-default": "#000000",
        "components-footer-global-color-link-hover": "#000000",
        "components-footer-global-color-link-pressed": "#000000",
        "components-generic-card-color-bg": "#fcfcfd",
        "components-generic-card-color-content-bg": "#fcfcfd",
        "components-generic-card-color-title": "#191b1c",
        "components-generic-card-color-subtitle": "#27282b",
        "components-generic-card-color-copy": "#27282b",
        "components-global-search-color-omnibox-list-bg": "#ffffff",
        "components-global-search-color-omnibox-divider": "#a2a5ab",
        "components-global-search-color-omnibox-item-bg-default": "#ffffff",
        "components-global-search-color-omnibox-item-bg-hover": "#fcfcfd",
        "components-global-search-color-omnibox-item-text-default": "#d44300",
        "components-global-search-color-omnibox-item-text-hover": "#ce450d",
        "components-global-search-color-omnibox-label-text-default": "#191b1c",
        "components-global-search-color-omnibox-category-text-default": "#191b1c",
        "components-generic-card-listing-color-bg": "#ffffff",
        "components-generic-card-listing-color-title": "#191b1c",
        "components-generic-card-listing-color-copy": "#27282b",
        "components-header-color-bg": "#ffffff",
        "components-header-color-bottom-stroke": "#e8e8ea",
        "components-header-color-search-search-border-default": "#191b1c",
        "components-header-color-search-search-text": "#4e5056",
        "components-header-color-search-search-icon": "#fe5000",
        "components-header-color-search-search-bar": "#191b1c",
        "components-header-color-mobile-mobile-expanded-bg": "#ffffff",
        "components-header-color-mobile-pressed-bg": "#ffffff",
        "components-header-color-language-selector-language": "#4e5056",
        "components-header-color-language-selector-country": "#191b1c",
        "components-header-color-language-selector-country-hover-bg": "#fcfcfd",
        "components-header-color-language-selector-megamenu-language": "#4e5056",
        "components-header-color-language-selector-megamenu-country": "#191b1c",
        "components-header-color-language-selector-megamenu-country-hover": "#fcfcfd",
        "components-header-color-language-selector-bg": "#ffffff",
        "components-header-color-language-selector-stroke": "#72757e",
        "components-header-color-mega-menu-bg": "#fff1eb",
        "components-header-color-mega-menu-featured-bg": "#d44300",
        "components-header-color-mega-menu-featured-title": "#ffffff",
        "components-header-color-mega-menu-category-title": "#27282b",
        "components-header-color-mega-menu-bg-accent": "#ffffff",
        "components-header-color-icon-icon-default": "#191b1c",
        "components-header-color-icon-icon-hover": "#d44300",
        "components-header-color-icon-icon-pressed": "#b33900",
        "components-header-color-links-nav-main-link-default": "#191b1c",
        "components-header-color-links-nav-main-link-hover": "#d44300",
        "components-header-color-links-nav-main-link-pressed": "#b33900",
        "components-header-color-links-nav-main-link-active": "#812900",
        "components-header-color-links-nav-main-active-bar": "#fe5000",
        "components-header-color-links-nav-main-bg-color": "#ffffff",
        "components-header-color-links-nav-category-link-category-default": "#27282b",
        "components-header-color-links-nav-category-link-category-hover": "#b33900",
        "components-header-color-links-nav-category-link-category-pressed": "#b33900",
        "components-header-color-links-nav-category-link-category-active": "#812900",
        "components-header-color-links-nav-menu-link-link-default": "#191b1c",
        "components-header-color-links-nav-menu-link-link-hover": "#b33900",
        "components-header-color-links-nav-menu-link-link-pressed": "#b33900",
        "components-header-color-links-nav-menu-link-link-active": "#983000",
        "components-header-color-links-nav-menu-link-bg": "#fff1eb",
        "components-header-color-links-nav-subcategory-link-subcategory-default": "#27282b",
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": "#b33900",
        "components-header-color-links-nav-subcategory-link-subcategory-active": "#812900",
        "components-hero-homepage-color-bg": "#fcfcfd",
        "components-hero-homepage-color-container-bg": "#fe5000",
        "components-hero-homepage-color-eyebrow": "#72757e",
        "components-hero-homepage-color-header": "#d44300",
        "components-hero-homepage-color-sub-header": "#27282b",
        "components-hero-homepage-color-body": "#27282b",
        "components-hero-homepage-color-play-pause": "#000000",
        "components-hero-internal-color-default-title": "#ffffff",
        "components-hero-internal-color-default-body": "#fcfcfd",
        "components-hero-internal-color-default-bg": "#d44300",
        "components-hero-internal-color-accent-bg": "#d44300",
        "components-hero-internal-color-with-image-bg": "#d44300",
        "components-hero-internal-color-default-bg-2": "#d44300",
        "components-hero-internal-color-with-image-title": "#fcfcfd",
        "components-hero-internal-color-with-image-body": "#fcfcfd",
        "components-heading-page-title-color-default-bg": "#ffffff",
        "components-heading-page-title-color-default-title": "#191b1c",
        "components-heading-page-title-color-default-body": "#27282b",
        "components-heading-page-title-color-light-bg": "#fff1eb",
        "components-heading-page-title-color-light-title": "#191b1c",
        "components-heading-page-title-color-light-body": "#27282b",
        "components-heading-page-title-color-color-bg": "#191b1c",
        "components-heading-page-title-color-color-title": "#ffffff",
        "components-heading-page-title-color-color-body": "#fcfcfd",
        "components-hyperlink-color-dark-default": "#d44300",
        "components-hyperlink-color-dark-hover": "#b33900",
        "components-hyperlink-color-dark-visited": "#865ddf",
        "components-hyperlink-color-light-default": "#fff1ea",
        "components-hyperlink-color-light-hover": "#ff844c",
        "components-hyperlink-color-light-visited": "#b89fec",
        "components-icon-default": "#72757e",
        "components-icon-focused": "#72757e",
        "components-icon-card-color-bg": "#ffffff",
        "components-icon-card-color-icon-bg": "#191b1c",
        "components-icon-card-color-icon": "#ffffff",
        "components-icon-card-color-title": "#191b1c",
        "components-icon-card-color-subtitle": "#4e5056",
        "components-icon-card-color-description": "#27282b",
        "components-icon-card-listing-color-bg": "#ffffff",
        "components-icon-card-listing-color-inner-bg": "#ffffff",
        "components-icon-card-listing-color-brand-bg": "#d44300",
        "components-icon-card-listing-color-title": "#191b1c",
        "components-icon-card-listing-color-copy": "#27282b",
        "components-icon-card-listing-color-inner-legal-text": "#ffffff",
        "components-jumplink-color-bg-default": "#ffffff",
        "components-jumplink-color-link-text-default": "#4e5056",
        "components-jumplink-color-link-text-hover": "#983000",
        "components-jumplink-color-link-border-hover": "#983000",
        "components-jumplink-color-link-text-active": "#d44300",
        "components-jumplink-color-link-border-active": "#d44300",
        "components-jumplink-color-title-text": "#191b1c",
        "components-jumplink-color-description-text": "#27282b",
        "components-layout-bg-default": "#ffffff",
        "components-lightbox-color-overlay-bg": "rgba(255, 255, 255, 0.95)",
        "components-lightbox-color-title": "#191b1c",
        "components-lightbox-color-body": "#27282b",
        "components-label-color-on-color-label-label-default": "#ffffff",
        "components-label-color-on-color-label-label-hover": "#4e5056",
        "components-label-color-on-color-label-label-pressed": "#27282b",
        "components-label-color-on-color-background-bg-default": "#72757e",
        "components-label-color-on-color-background-bg-hover": "#ffffff",
        "components-label-color-on-color-background-bg-pressed": "#e8e8ea",
        "components-label-color-on-color-stroke-hover": "#ffffff",
        "components-label-color-on-color-stroke-pressed": "#e8e8ea",
        "components-label-color-on-color-stroke-default-gradient-fill-1": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-2": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-3": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-4": "#ffffff",
        "components-label-color-on-white-label-label-default": "#4e5056",
        "components-label-color-on-white-label-label-hover": "#ffffff",
        "components-label-color-on-white-label-label-pressed": "#ffffff",
        "components-label-color-on-white-background-bg-default": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-white-background-bg-hover": "#72757e",
        "components-label-color-on-white-background-bg-pressed": "#4e5056",
        "components-label-color-on-white-stroke-hover": "#72757e",
        "components-label-color-on-white-stroke-pressed": "#4e5056",
        "components-label-color-on-white-stroke-default-gradient-fill-1": "#72757e",
        "components-label-color-on-white-stroke-default-gradient-fill-2": "#72757e",
        "components-label-color-on-white-stroke-default-gradient-fill-3": "#72757e",
        "components-label-color-on-white-stroke-default-gradient-fill-4": "#72757e",
        "components-legal-disclaimer-color-default-bg": "#ffffff",
        "components-legal-disclaimer-color-default-title": "#191b1c",
        "components-legal-disclaimer-color-default-body": "#27282b",
        "components-legal-disclaimer-color-light-bg": "#fff1eb",
        "components-legal-disclaimer-color-light-title": "#191b1c",
        "components-legal-disclaimer-color-light-body": "#27282b",
        "components-legal-disclaimer-color-brand-bg": "#191b1c",
        "components-legal-disclaimer-color-brand-title": "#ffffff",
        "components-legal-disclaimer-color-brand-body": "#fcfcfd",
        "components-media-video-thumbnail-overlay-bg": "rgba(25, 27, 28, 0.80)",
        "components-media-caption-color-bg": "#fcfcfd",
        "components-media-caption-color-text": "#27282b",
        "components-media-gallery-color-bg": "#ffffff",
        "components-media-gallery-color-title": "#191b1c",
        "components-media-gallery-color-body": "#27282b",
        "components-pagination-on-white-accent-default": "#27282b",
        "components-pagination-on-white-accent-scroll": "#fe5000",
        "components-pagination-on-white-disabled": "#a2a5ab",
        "components-pagination-on-white-bg": "#e8e8ea",
        "components-pagination-on-white-stroke": "#e8e8ea",
        "components-pagination-on-white-bg-active": "#191b1c",
        "components-pagination-on-white-bg-hover": "#e8e8ea",
        "components-pagination-on-white-text-default": "#72757e",
        "components-pagination-on-white-text-hover": "#27282b",
        "components-pagination-on-white-text-active": "#ffffff",
        "components-pagination-on-color-accent-default": "#ffffff",
        "components-pagination-on-color-accent-scroll": "#27282b",
        "components-pagination-on-color-disabled": "#471600",
        "components-pagination-on-color-bg": "#fcfcfd",
        "components-pagination-on-color-stroke": "rgba(255, 255, 255, 0.38)",
        "components-pagination-on-color-bg-active": "#ffffff",
        "components-pagination-on-color-bg-hover": "#27282b",
        "components-pagination-on-color-text-default": "#ffffff",
        "components-pagination-on-color-text-hover": "#ffffff",
        "components-pagination-on-color-text-active": "#191b1c",
        "components-pdf-link-card-color-bg": "#ffffff",
        "components-pdf-link-card-color-card-bg": "#ffffff",
        "components-pdf-link-card-color-card-title": "#191b1c",
        "components-pdf-link-card-color-card-subtitle": "#27282b",
        "components-pdf-link-card-color-card-body": "#27282b",
        "components-product-card-color-bg": "#fcfcfd",
        "components-product-card-color-content-bg": "#fcfcfd",
        "components-product-card-color-border": "#fcfcfd",
        "components-product-card-color-title": "#191b1c",
        "components-product-card-color-description": "#27282b",
        "components-product-card-color-star-rating": "#d44300",
        "components-product-card-color-badge-bg": "#d44300",
        "components-product-card-color-badge-text": "#ffffff",
        "components-product-card-listing-color-bg": "#ffffff",
        "components-product-card-listing-color-title": "#191b1c",
        "components-product-card-listing-color-copy": "#27282b",
        "components-product-card-listing-color-legal-text": "#27282b",
        "components-product-information-color-bg": "#ffffff",
        "components-product-information-color-content-bg": "#ffffff",
        "components-product-information-color-headline": "#191b1c",
        "components-product-information-color-body": "#27282b",
        "components-product-information-color-detail-text": "#27282b",
        "components-product-information-color-divider": "#a2a5ab",
        "components-product-information-color-icon": "#d44300",
        "components-product-information-color-play-icon": "#ffffff",
        "components-product-information-color-selected-stroke": "#e22726",
        "components-product-results-list-with-search-color-bg": "#ffffff",
        "components-promo-color-default-bg": "#ffffff",
        "components-promo-color-default-image-bg": "#ffffff",
        "components-promo-color-default-content-bg": "#ffffff",
        "components-promo-color-default-title": "#191b1c",
        "components-promo-color-default-body": "#27282b",
        "components-promo-color-default-category": "#191b1c",
        "components-promo-color-light-bg": "#fff1eb",
        "components-promo-color-light-image-bg": "#fff1eb",
        "components-promo-color-light-content-bg": "#fff1eb",
        "components-promo-color-light-title": "#191b1c",
        "components-promo-color-light-body": "#27282b",
        "components-promo-color-light-category": "#191b1c",
        "components-promo-color-brand-bg": "#d44300",
        "components-promo-color-brand-image-bg": "#d44300",
        "components-promo-color-brand-content-bg": "#d44300",
        "components-promo-color-brand-title": "#ffffff",
        "components-promo-color-brand-body": "#fcfcfd",
        "components-promo-color-brand-category": "#ffffff",
        "components-pull-quote-color-bg": "#fff1eb",
        "components-pull-quote-color-stroke": "#e22726",
        "components-pull-quote-color-quote-bg": "#ffffff",
        "components-pull-quote-color-quote-title": "#191b1c",
        "components-pull-quote-color-quote-by-text": "#27282b",
        "components-pull-quote-color-quote-detail": "#72757e",
        "components-pull-quote-color-quote-highlight": "#d44300",
        "components-promo-alternate-color-default-bg": "#ffffff",
        "components-promo-alternate-color-default-image-bg": "#ffffff",
        "components-promo-alternate-color-default-content-bg": "#ffffff",
        "components-promo-alternate-color-default-title": "#191b1c",
        "components-promo-alternate-color-default-body": "#27282b",
        "components-promo-alternate-color-default-category": "#191b1c",
        "components-promo-alternate-color-light-bg": "#fff1eb",
        "components-promo-alternate-color-light-image-bg": "#fff1eb",
        "components-promo-alternate-color-light-content-bg": "#fff1eb",
        "components-promo-alternate-color-light-title": "#191b1c",
        "components-promo-alternate-color-light-body": "#27282b",
        "components-promo-alternate-color-light-category": "#191b1c",
        "components-promo-alternate-color-brand-bg": "#d44300",
        "components-promo-alternate-color-brand-image-bg": "#d44300",
        "components-promo-alternate-color-brand-content-bg": "#d44300",
        "components-promo-alternate-color-brand-title": "#ffffff",
        "components-promo-alternate-color-brand-body": "#fcfcfd",
        "components-promo-alternate-color-brand-category": "#ffffff",
        "components-share-list-color-title": "#27282b",
        "components-share-list-color-bg": "#fcfcfd",
        "components-share-list-color-link-default": "#27282b",
        "components-share-list-color-link-hover": "#d44300",
        "components-share-list-color-bg-link-hover": "#e8e8ea",
        "components-share-list-color-close-icon": "#27282b",
        "components-share-list-color-facebook-icon": "#3b5998",
        "components-share-list-color-pinterest-icon": "#e60023",
        "components-share-list-color-twitter-icon": "#000000",
        "components-share-list-color-basic-icon-default": "#27282b",
        "components-share-list-color-icon-hover": "#d44300",
        "components-share-list-color-border": "#e8e8ea",
        "components-site-search-bg": "#ffffff",
        "components-site-search-results_text": "#27282b",
        "components-site-search-filter-filter-selected-bg": "#fff1eb",
        "components-site-search-facet-group-label-bottom-line": "#e8e8ea",
        "components-site-search-facet-group-label-text": "#27282b",
        "components-site-search-facet-group-label-bg": "#ffffff",
        "components-site-search-facet-bg": "#ffffff",
        "components-site-search-facet-stroke": "#e8e8ea",
        "components-site-search-refine-bg": "#ffffff",
        "components-site-search-refine-right-divider": "#fff1eb",
        "components-site-search-refine-title": "#191b1c",
        "components-site-search-hero-search-bg": "#d44300",
        "components-site-search-hero-search-accent-bg": "#b33900",
        "components-site-search-hero-search-title": "#ffffff",
        "components-stats-stat-text": "#d44300",
        "components-stats-stat-text-on-light": "#d44300",
        "components-stats-supporting-text": "#27282b",
        "components-stats-bg-default": "#ffffff",
        "components-stats-bg-inner": "#ffffff",
        "components-stats-bg-section-inner": "#fe5000",
        "components-stats-title": "#191b1c",
        "components-stats-copy": "#27282b",
        "components-supporting-text-default": "#4e5056",
        "components-supporting-text-destructive": "#b32b30",
        "components-tab-bg": "#ffffff",
        "components-tab-border-default": "#72757e",
        "components-tab-border-hover": "#fe5000",
        "components-tab-border-focused": "#191b1c",
        "components-tab-border-destructive": "#b32b30",
        "components-tab-label-text-default": "#191b1c",
        "components-tab-item-text-default": "#191b1c",
        "components-tab-item-bg-default": "#ffffff",
        "components-tab-item-bg-hover": "#fff1eb",
        "components-tab-input-field-default": "#72757e",
        "components-tab-input-field-focused": "#191b1c",
        "components-tab-input-label-focused": "#191b1c",
        "components-tab-input-field-destructive": "#b32b30",
        "components-tab-bg-2": "#ffffff",
        "components-tag-color-bg": "#ffffff",
        "components-tag-color-text": "#191b1c",
        "components-tag-color-stroke": "#fe5000",
        "components-tag-color-bg-hover": "#d44300",
        "components-tag-color-text-hover": "#ffffff",
        "components-tag-color-stroke-hover": "#d44300",
        "components-text-field-bg": "#fcfcfd",
        "components-text-field-bg-focused": "#fff1eb",
        "components-text-field-border-default": "#191b1c",
        "components-text-field-border-hover": "#fe5000",
        "components-text-field-border-focused": "#191b1c",
        "components-text-field-border-destructive": "#b32b30",
        "components-text-field-input-field-default": "#4e5056",
        "components-text-field-input-field-focused": "#191b1c",
        "components-text-field-input-label-focused": "#191b1c",
        "components-text-field-input-field-destructive": "#b32b30",
        "components-text-field-icon-default": "#72757e",
        "components-text-field-icon-destructive": "#b32b30",
        "components-text-field-supporting-text-default": "#4e5056",
        "components-text-field-supporting-text-destructive": "#b32b30",
        "components-tip-color-bg": "#fff1eb",
        "components-tip-color-title": "#191b1c",
        "components-tip-color-body": "#27282b",
        "components-tip-color-stroke": "#ffb999",
        "components-video-color-bg": "#ffffff",
        "themes-general-surface-color-light-bg": "#ffffff",
        "themes-general-surface-color-light-title": "#191b1c",
        "themes-general-surface-color-light-body": "#27282b",
        "themes-general-surface-color-light-decor-line-break": "#ffb999",
        "themes-general-surface-color-light-rule-line": "#ffb999",
        "themes-general-surface-color-dark-bg": "#d44300",
        "themes-general-surface-color-dark-title": "#ffffff",
        "themes-general-surface-color-dark-body": "#fcfcfd",
        "themes-general-surface-color-dark-decor-line-break": "#ffb999",
        "themes-general-card-color-dark-title": "#191b1c",
        "themes-general-card-color-dark-subtitle": "#27282b",
        "themes-general-card-color-dark-body": "#27282b",
        "themes-general-card-color-light-title": "#ffffff",
        "themes-general-card-color-light-subtitle": "#fcfcfd",
        "themes-general-card-color-light-body": "#fcfcfd",
        "themes-shadow-color": "#ffffff",
        "themes-shadow-share-list-color-fill": "rgba(0, 0, 0, 0.40)",
        "themes-shadow-header-menu-color-fill": "rgba(0, 0, 0, 0.20)",
        "themes-gradient-loader-gradient-fill-1": "#191b1c",
        "themes-gradient-loader-gradient-fill-2": "rgba(25, 27, 28, 0.00)",
        "themes-color-background-white": "#ffffff",
        "themes-color-background-light": "#fff1eb",
        "themes-color-background-dark": "#27282b",
        "themes-color-background-black": "#191b1c",
        "themes-color-background-brand1": "#d44300",
        "themes-color-background-brand2": "#e22726",
        "themes-color-background-accent1": "#286636",
        "themes-color-background-accent2": "#9976e4",
        "themes-color-background-body": "#ffffff",
        "themes-color-stroke-white": "#ffffff",
        "themes-color-stroke-light": "#e8e8ea",
        "themes-color-stroke-dark": "#27282b",
        "themes-color-stroke-black": "#191b1c",
        "themes-color-stroke-brand1": "#fe5000",
        "themes-color-stroke-brand2": "#e22726",
        "themes-color-stroke-accent1": "#286636",
        "themes-color-stroke-accent2": "#9976e4",
        "themes-color-text-white": "#ffffff",
        "themes-color-text-light": "#fcfcfd",
        "themes-color-text-dark": "#27282b",
        "themes-color-text-black": "#191b1c",
        "themes-color-text-brand1": "#d44300",
        "themes-color-text-brand2": "#e22726",
        "themes-color-text-accent1": "#183d20",
        "themes-color-text-accent2": "#2f1568",
        "colors-grayscale-100": "#fcfcfd",
        "colors-grayscale-200": "#e8e8ea",
        "colors-grayscale-300": "#a2a5ab",
        "colors-grayscale-400": "#72757e",
        "colors-grayscale-500": "#4e5056",
        "colors-grayscale-600": "#27282b",
        "colors-grayscale-700": "#191b1c",
        "colors-brand-1-100": "#fff1eb",
        "colors-brand-1-200": "#ffb999",
        "colors-brand-1-300": "#ff844c",
        "colors-brand-1-400": "#fe5000",
        "colors-brand-1-500": "#ce450d",
        "colors-brand-1-600": "#983000",
        "colors-brand-1-700": "#471600",
        "colors-brand-2-100": "#fdeeee",
        "colors-brand-2-200": "#f3a9a8",
        "colors-brand-2-300": "#eb6867",
        "colors-brand-2-400": "#e22726",
        "colors-brand-2-500": "#c41b1a",
        "colors-brand-2-600": "#8c1313",
        "colors-brand-2-700": "#410909",
        "colors-accent-1-100": "#eaf7ed",
        "colors-accent-1-200": "#95d6a4",
        "colors-accent-1-300": "#47b560",
        "colors-accent-1-400": "#286636",
        "colors-accent-1-500": "#22562d",
        "colors-accent-1-600": "#183d20",
        "colors-accent-1-700": "#0e2513",
        "colors-accent-2-100": "#f7f4fd",
        "colors-accent-2-200": "#d6c8f4",
        "colors-accent-2-300": "#b89fec",
        "colors-accent-2-400": "#9976e4",
        "colors-accent-2-500": "#7748db",
        "colors-accent-2-600": "#4920a2",
        "colors-accent-2-700": "#2f1568",
        "colors-accent-3-100": "#eefaec",
        "colors-accent-3-200": "#a9e8a0",
        "colors-accent-3-300": "#68d759",
        "colors-accent-3-400": "#3aae2a",
        "colors-accent-3-500": "#319223",
        "colors-accent-3-600": "#236819",
        "colors-accent-3-700": "#153f0f",
        "colors-accent-4-400_storage": "#00aed6",
        "colors-accessible-accent-1-100": "#f5f2fc",
        "colors-accessible-accent-1-200": "#cfbff2",
        "colors-accessible-accent-1-300": "#ab8fe9",
        "colors-accessible-accent-1-400": "#865ddf",
        "colors-accessible-accent-1-500": "#6d3ad8",
        "colors-accessible-accent-1-600": "#4c21a9",
        "colors-accessible-accent-1-700": "#341672",
        "colors-accessible-accent-2-100": "#e7f8e4",
        "colors-accessible-accent-2-200": "#7ddc6f",
        "colors-accessible-accent-2-300": "#3cb42c",
        "colors-accessible-accent-2-400": "#2d8721",
        "colors-accessible-accent-2-500": "#246d1a",
        "colors-accessible-accent-2-600": "#1b5214",
        "colors-accessible-accent-2-700": "#11340c",
        "colors-accessible-brand-2-100": "#fff1ea",
        "colors-accessible-brand-2-200": "#ffb694",
        "colors-accessible-brand-2-300": "#ff7435",
        "colors-accessible-brand-2-400": "#d44300",
        "colors-accessible-brand-2-500": "#b33900",
        "colors-accessible-brand-2-600": "#812900",
        "colors-accessible-brand-2-700": "#431500"
      },
      "spacing": {
        "components-accordion-spacing-large-padding-x": "5rem",
        "components-accordion-spacing-large-icon-margin-right": "2rem",
        "components-accordion-spacing-large-copy-margin-right": "2.5rem",
        "components-accordion-spacing-large-maxwidth": "59.625rem",
        "components-accordion-spacing-large-section-padding-x": "0rem",
        "components-accordion-spacing-large-section-padding-y": "2.5rem",
        "components-accordion-spacing-large-section-copy-margin-bottom": "2.5rem",
        "components-accordion-spacing-large-section-space-between": "4rem",
        "components-accordion-spacing-large-item-padding-x": "0rem",
        "components-accordion-spacing-large-item-padding-y": "1.5rem",
        "components-accordion-spacing-large-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-large-content-padding-x": "0rem",
        "components-accordion-spacing-large-content-padding-y": "2.5rem",
        "components-accordion-spacing-large-content-title-margin-bottom": "2.5rem",
        "components-accordion-spacing-large-content-content-space-between": "4rem",
        "components-accordion-spacing-large-content-content-margin-left": "1rem",
        "components-accordion-spacing-small-padding-top": "1rem",
        "components-accordion-spacing-small-padding-x": "1.5rem",
        "components-accordion-spacing-small-padding-y": "2.5rem",
        "components-accordion-spacing-small-icon-margin-right": "0.75rem",
        "components-accordion-spacing-small-title-margin-bottom": "0.5rem",
        "components-accordion-spacing-small-copy-margin-right": "2rem",
        "components-accordion-spacing-small-copy-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-padding-x": "0rem",
        "components-accordion-spacing-small-section-padding-y": "0rem",
        "components-accordion-spacing-small-section-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-space-between": "2.5rem",
        "components-accordion-spacing-small-item-padding-x": "0rem",
        "components-accordion-spacing-small-item-padding-y": "1rem",
        "components-accordion-spacing-small-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-small-content-padding-x": "0rem",
        "components-accordion-spacing-small-content-padding-y": "0.75rem",
        "components-accordion-spacing-small-content-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-content-content-space-between": "1rem",
        "components-accordion-spacing-small-content-content-margin-left": "1rem",
        "components-article-card-spacing-large-padding-image-x": "0rem",
        "components-article-card-spacing-large-padding-image-y": "0rem",
        "components-article-card-spacing-large-padding-content-x": "1.5rem",
        "components-article-card-spacing-large-padding-content-y": "1.5rem",
        "components-article-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-article-card-spacing-large-button-space-between": "1rem",
        "components-article-card-spacing-large-title-margin-bottom": "1rem",
        "components-article-card-spacing-large-list-content-gap": "1rem",
        "components-article-card-spacing-large-list-image-padding-x": "0rem",
        "components-article-card-spacing-large-list-image-padding-y": "0rem",
        "components-article-card-spacing-small-padding-image-x": "0rem",
        "components-article-card-spacing-small-padding-image-y": "0rem",
        "components-article-card-spacing-small-padding-content-x": "1.5rem",
        "components-article-card-spacing-small-padding-content-y": "1.5rem",
        "components-article-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-article-card-listing-spacing-small-padding-x": "1.5rem",
        "components-article-card-listing-spacing-small-padding-y": "2.5rem",
        "components-article-hero-spacing-large-padding-x": "0rem",
        "components-article-hero-spacing-large-padding-y": "0rem",
        "components-article-hero-spacing-large-title-margin-bottom": "2rem",
        "components-article-hero-spacing-large-label-share-margin-bottom": "2rem",
        "components-article-hero-spacing-large-author-name-margin-bottom": "0.75rem",
        "components-article-hero-spacing-large-author-thumbnail-margin-left": "1rem",
        "components-article-hero-spacing-large-details-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-space-between": "1rem",
        "components-article-hero-spacing-large-body-margin-bottom": "2rem",
        "components-article-hero-spacing-large-image-space-between": "1rem",
        "components-article-hero-spacing-large-image-margin-bottom": "1rem",
        "components-article-hero-spacing-large-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-stats-margin-bottom": "2.5rem",
        "components-article-hero-spacing-small-content-margin-top": "2.5rem",
        "components-article-hero-spacing-small-title-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-label-share-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-authorname-margin-bottom": "0.75rem",
        "components-article-hero-spacing-small-authorthumbnail-margin-left": "1rem",
        "components-article-hero-spacing-small-details-margin-bottom": "1rem",
        "components-article-hero-spacing-small-space-between": "0.75rem",
        "components-article-hero-spacing-small-body-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-image-space-between": "0.75rem",
        "components-article-hero-spacing-small-image-margin-bottom": "1rem",
        "components-article-hero-spacing-small-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-stats-margin-bottom": "2rem",
        "components-banner-spacing-large-content-padding-x": "1.5rem",
        "components-banner-spacing-large-content-space-between": "1rem",
        "components-banner-spacing-large-content-width": "69.375rem",
        "components-banner-spacing-icon-dimensions": "1.5rem",
        "components-banner-spacing-icon-margin-left": "0.5rem",
        "components-banner-spacing-small-space-between": "1rem",
        "components-banner-spacing-small-content-space-between": "1rem",
        "components-banner-spacing-small-title-margin-bottom": "0.75rem",
        "components-banner-spacing-small-content-margin-bottom": "1rem",
        "components-breadcrumb-spacing-large-space-between": "0.25rem",
        "components-breadcrumb-spacing-small-space-between": "0.25rem",
        "components-button-spacing-large-icon-large-x": "2.5rem",
        "components-button-spacing-large-icon-large-y": "2.5rem",
        "components-button-spacing-large-default-padding-y": "0.75rem",
        "components-button-spacing-large-default-padding-x": "1.5rem",
        "components-button-spacing-large-default-space-between": "0.25rem",
        "components-button-spacing-large-icon-left-padding-left": "1rem",
        "components-button-spacing-large-icon-right-padding-right": "1rem",
        "components-button-spacing-large-icon-only-padding-x": "0.75rem",
        "components-button-spacing-large-icon-only-padding-y": "0.75rem",
        "components-button-spacing-large-tertiary-content-height": "1.5rem",
        "components-button-spacing-compressed-icon-large-x": "2.5rem",
        "components-button-spacing-compressed-icon-large-y": "2.5rem",
        "components-button-spacing-compressed-default-padding-y": "0.5rem",
        "components-button-spacing-compressed-default-padding-x": "1rem",
        "components-button-spacing-compressed-default-space-between": "0.125rem",
        "components-button-spacing-compressed-icon-left-padding-left": "0.5rem",
        "components-button-spacing-compressed-icon-right-padding-right": "0.5rem",
        "components-button-spacing-compressed-icon-only-padding-x": "0.5rem",
        "components-button-group-spacing-padding-y": "0.125rem",
        "components-button-group-spacing-padding-x": "0.25rem",
        "components-button-group-spacing-space-between": "0.5rem",
        "components-category-card-spacing-large-padding-top": "0rem",
        "components-category-card-spacing-large-padding-x": "0rem",
        "components-category-card-spacing-large-padding-y": "0rem",
        "components-category-card-spacing-large-padding-image-x": "0rem",
        "components-category-card-spacing-large-padding-image-y": "0rem",
        "components-category-card-spacing-large-padding-content-x": "2rem",
        "components-category-card-spacing-large-padding-content-y": "2rem",
        "components-category-card-listing-spacing-large-padding-y": "5rem",
        "components-category-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-category-card-listing-spacing-large-card-margin-bottom": "0rem",
        "components-category-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-card-search-result-small-padding-top": "0rem",
        "components-card-search-result-small-padding-x": "0rem",
        "components-card-search-result-small-padding-y": "0rem",
        "components-card-search-result-small-padding-image-x": "0rem",
        "components-card-search-result-small-padding-image-y": "0rem",
        "components-card-search-result-small-padding-content-x": "1.5rem",
        "components-card-search-result-small-padding-content-y": "1.5rem",
        "components-card-search-result-spacing-large-padding-x": "0rem",
        "components-card-search-result-spacing-large-padding-y": "1.5rem",
        "components-card-search-result-spacing-large-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-image-margin-right": "1.5rem",
        "components-card-search-result-spacing-small-padding-x": "1.5rem",
        "components-card-search-result-spacing-small-padding-y": "1.5rem",
        "components-card-search-result-spacing-small-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-image-margin-right": "0.75rem",
        "components-contact-us-promo-spacing-large-padding-x": "5rem",
        "components-contact-us-promo-spacing-large-padding-y": "5rem",
        "components-contact-us-promo-spacing-large-content-margin-right": "5rem",
        "components-contact-us-promo-spacing-large-margin-y": "2.5rem",
        "components-contact-us-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-contact-us-promo-spacing-small-margin-y": "2.5rem",
        "components-content-list-list-item-container-padding-x": "0.75rem",
        "components-content-list-list-item-container-padding-y": "1rem",
        "components-content-list-list-item-content-space-between": "0.5rem",
        "components-content-list-list-item-content-default-padding-y": "0.25rem",
        "components-content-list-list-item-content-hyperlink-padding-y": "0rem",
        "components-content-list-spacing-large-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-large-item-title-margin-bottom": "1rem",
        "components-content-list-spacing-large-item-copy-margin-bottom": "2.5rem",
        "components-content-list-spacing-large-item-icon-copy-margin-top": "0rem",
        "components-content-list-spacing-large-item-margin-bottom": "2rem",
        "components-content-list-spacing-large-item-image-margin-bottom": "0.5rem",
        "components-content-list-spacing-large-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-y": "2.5rem",
        "components-content-list-spacing-small-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-small-item-title-margin-bottom": "0.5rem",
        "components-content-list-spacing-small-item-copy-margin-bottom": "1rem",
        "components-content-list-spacing-small-item-margin-bottom": "1.5rem",
        "components-content-list-spacing-small-header-title-margin-bottom": "1rem",
        "components-content-list-spacing-small-header-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-title-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-button-space-between": "1rem",
        "components-dropdown-item-padding-y": "0.75rem",
        "components-dropdown-item-padding-x": "0.75rem",
        "components-dropdown-item-spacing-x": "0.75rem",
        "components-dropdown-input-padding-y": "0.25rem",
        "components-dropdown-input-padding-x": "1rem",
        "components-footer-brand-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-large-column-margin-right": "4rem",
        "components-footer-brand-spacing-large-column-margin-bottom": "4rem",
        "components-footer-brand-spacing-large-link-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-padding-y": "2.5rem",
        "components-footer-brand-spacing-small-padding-x": "1rem",
        "components-footer-brand-spacing-small-logo-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-column-margin-right": "0.125rem",
        "components-footer-brand-spacing-small-column-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-small-link-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-title-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-text-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-footer-link-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-link-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-link-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-link-minheight": "3rem",
        "components-footer-brand-spacing-small-footer-category-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-category-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-category-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-category-minheight": "3rem",
        "components-footer-global-spacing-large-padding-y": "2.5rem",
        "components-footer-global-spacing-large-padding-x": "5rem",
        "components-footer-global-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-global-spacing-large-column-margin-right": "2rem",
        "components-footer-global-spacing-large-link-margin-bottom": "2rem",
        "components-footer-global-spacing-small-padding-y": "2.5rem",
        "components-footer-global-spacing-small-padding-x": "1rem",
        "components-footer-global-spacing-small-logo-margin-bottom": "1rem",
        "components-footer-global-spacing-small-column-margin-right": "1rem",
        "components-footer-global-spacing-small-link-margin-bottom": "1rem",
        "components-generic-card-spacing-large-padding-x": "0rem",
        "components-generic-card-spacing-large-padding-y": "0rem",
        "components-generic-card-spacing-large-padding-image-x": "0rem",
        "components-generic-card-spacing-large-padding-image-y": "0rem",
        "components-generic-card-spacing-large-padding-content-x": "2rem",
        "components-generic-card-spacing-large-padding-content-y": "2rem",
        "components-generic-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-generic-card-spacing-large-title-margin-bottom": "1rem",
        "components-generic-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-button-space-between": "1rem",
        "components-generic-card-spacing-small-padding-x": "0rem",
        "components-generic-card-spacing-small-padding-y": "0rem",
        "components-generic-card-spacing-small-padding-image-x": "0rem",
        "components-generic-card-spacing-small-padding-image-y": "0rem",
        "components-generic-card-spacing-small-padding-content-x": "1.5rem",
        "components-generic-card-spacing-small-padding-content-y": "1.5rem",
        "components-global-search-spacing-omnibox-padding-x": "0.125rem",
        "components-global-search-spacing-omnibox-list-padding-y": "0.5rem",
        "components-global-search-spacing-omnibox-label-padding": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-item-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-height": "3rem",
        "components-generic-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-generic-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-header-shadow-x": "0rem",
        "components-header-spacing-large-padding-y": "1rem",
        "components-header-spacing-large-padding-x": "1.5rem",
        "components-header-spacing-large-links-margin-right": "1.5rem",
        "components-header-spacing-large-icons-space-between": "1.5rem",
        "components-header-spacing-large-logo-margin-right": "2rem",
        "components-header-spacing-large-space-between-search": "0.5rem",
        "components-header-spacing-large-height": "5rem",
        "components-header-spacing-large-language-selector-padding-x": "1rem",
        "components-header-spacing-large-language-selector-padding-y": "0.5rem",
        "components-header-spacing-large-language-selector-space-between": "0.75rem",
        "components-header-spacing-large-language-selector-country-space-between": "0.5rem",
        "components-header-spacing-large-mega-menu-padding-y": "2.5rem",
        "components-header-spacing-large-mega-menu-padding-x": "2.5rem",
        "components-header-spacing-large-mega-menu-column-margin-right": "5rem",
        "components-header-spacing-large-mega-menu-category-link-margin-bottom": "1rem",
        "components-header-spacing-large-mega-menu-link-margin-bottom": "0.5rem",
        "components-header-spacing-large-mega-menu-featured-padding-x": "2rem",
        "components-header-spacing-large-mega-menu-featured-padding-y": "2rem",
        "components-header-spacing-large-mega-menu-featured-image-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-title-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-link-space-between": "1.5rem",
        "components-header-spacing-large-mega-menu-title-margin-bottom": "2.5rem",
        "components-header-spacing-large-dropdown-padding-y": "1.5rem",
        "components-header-spacing-large-dropdown-padding-x": "1.5rem",
        "components-header-spacing-large-dropdown-margin-bottom": "0.5rem",
        "components-header-spacing-small-language-selector-padding-x": "1rem",
        "components-header-spacing-small-language-selector-padding-y": "0.5rem",
        "components-header-spacing-small-language-selector-space-between": "0.5rem",
        "components-header-spacing-small-padding-left": "1rem",
        "components-header-spacing-small-padding-right": "1rem",
        "components-header-spacing-small-padding-top": "0.5rem",
        "components-header-spacing-small-padding-bottom": "0.5rem",
        "components-header-spacing-small-nav-main-minheight": "3rem",
        "components-header-spacing-small-nav-main-padding-y": "0.5rem",
        "components-header-spacing-small-nav-main-padding-left": "1.5rem",
        "components-header-spacing-small-nav-main-padding-right": "1rem",
        "components-header-spacing-small-nav-category-minheight": "3rem",
        "components-header-spacing-small-nav-category-padding-left": "1.5rem",
        "components-header-spacing-small-nav-category-padding-right": "0.75rem",
        "components-header-spacing-small-nav-category-padding-y": "0.75rem",
        "components-header-spacing-small-nav-category-space-between": "0rem",
        "components-header-spacing-small-nav-menu-link-minheight": "3rem",
        "components-header-spacing-small-nav-menu-link-padding-right": "1.5rem",
        "components-header-spacing-small-nav-menu-link-padding-left": "2.5rem",
        "components-header-spacing-small-nav-menu-link-padding-y": "0.75rem",
        "components-header-spacing-small-mega-menu-padding-y": "0rem",
        "components-header-spacing-small-mega-menu-padding-x": "0rem",
        "components-header-spacing-small-mega-menu-category-link-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-link-margin-bottom": "0rem",
        "components-header-spacing-small-mega-menu-featured-padding-x": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-padding-y": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-image-margin-bottom": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-title-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-featured-link-space-between": "0.75rem",
        "components-header-spacing-small-mega-menu-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-container-padding": "1rem",
        "components-hero-homepage-spacing-large-padding-x": "5rem",
        "components-hero-homepage-spacing-large-padding-y": "2.5rem",
        "components-hero-homepage-spacing-large-minheight": "34.3125rem",
        "components-hero-homepage-spacing-large-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-margin-bottom": "4rem",
        "components-hero-homepage-spacing-large-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-copy-margin-bottom": "2.5rem",
        "components-hero-homepage-spacing-large-cta-margin-right": "1rem",
        "components-hero-homepage-spacing-large-image-height": "34.3125rem",
        "components-hero-homepage-spacing-large-image-width": "45.75rem",
        "components-hero-homepage-spacing-small-padding-x": "0.5rem",
        "components-hero-homepage-spacing-small-padding-y": "1.5rem",
        "components-hero-homepage-spacing-small-minheight": "34.3125rem",
        "components-hero-homepage-spacing-small-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-copy-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-image-height": "17.5625rem",
        "components-hero-homepage-spacing-small-image-width": "45.75rem",
        "components-hero-internal-spacing-large-padding-y": "2.5rem",
        "components-hero-internal-spacing-large-padding-right": "10rem",
        "components-hero-internal-spacing-large-title-margin-bottom": "1.5rem",
        "components-hero-internal-spacing-large-button-padding-y": "2rem",
        "components-hero-internal-spacing-large-text-maxwidth": "59.625rem",
        "components-hero-internal-spacing-large-minheight": "18.5rem",
        "components-hero-internal-spacing-small-padding-x": "1.5rem",
        "components-hero-internal-spacing-small-padding-right": "0rem",
        "components-hero-internal-spacing-small-button-padding-y": "1.5rem",
        "components-hero-internal-spacing-small-text-maxwidth": "30rem",
        "components-hero-internal-spacing-small-minheight": "13.5rem",
        "components-icon-card-spacing-large-padding-x": "0rem",
        "components-icon-card-spacing-large-padding-y": "0rem",
        "components-icon-card-spacing-large-icon-margin": "1.5rem",
        "components-icon-card-spacing-large-margin-right": "1.5rem",
        "components-icon-card-spacing-large-title-margin-bottom": "1rem",
        "components-icon-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-icon-card-spacing-large-button-space-between": "0.5rem",
        "components-icon-card-spacing-large-top-space-between": "1rem",
        "components-icon-card-spacing-small-padding-x": "1.5rem",
        "components-icon-card-spacing-small-padding-y": "1.5rem",
        "components-icon-card-spacing-small-icon-margin": "1.5rem",
        "components-icon-card-spacing-small-margin-right": "1.5rem",
        "components-icon-card-spacing-small-title-margin-bottom": "1.5rem",
        "components-icon-card-dimensions-large-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-large-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-large-big-icon-height": "4rem",
        "components-icon-card-dimensions-large-big-icon-width": "4rem",
        "components-icon-card-dimensions-large-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-large-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-large-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-large-tiny-icon-width": "2.5rem",
        "components-icon-card-dimensions-small-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-small-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-small-big-icon-height": "3.5rem",
        "components-icon-card-dimensions-small-big-icon-width": "3.5rem",
        "components-icon-card-dimensions-small-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-small-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-small-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-small-tiny-icon-width": "2.5rem",
        "components-icon-card-listing-spacing-large-padding-inner-x": "4rem",
        "components-icon-card-listing-spacing-large-padding-inner-y": "4rem",
        "components-icon-card-listing-spacing-large-card-space-between": "4rem",
        "components-icon-card-listing-spacing-large-title-margin-bottom": "1.5rem",
        "components-icon-card-listing-spacing-large-card-margin-bottom": "4rem",
        "components-icon-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-x": "1.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-y": "2.5rem",
        "components-icon-card-listing-spacing-small-title-margin-bottom": "1rem",
        "components-icon-card-listing-spacing-small-button-margin-top": "1.5rem",
        "components-jumplink-link-padding-bottom": "0.5rem",
        "components-jumplink-spacing-small-title-area-margin-bottom": "1.5rem",
        "components-jumplink-spacing-small-link-container-padding-x": "0rem",
        "components-lightbox-spacing-large-carousel-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-carousel-space-between": "1.5rem",
        "components-lightbox-spacing-large-featured-image-height": "39.25rem",
        "components-lightbox-spacing-large-featured-image-width": "39.25rem",
        "components-lightbox-spacing-large-image-height": "17.0625rem",
        "components-lightbox-spacing-large-image-width": "17.0625rem",
        "components-lightbox-spacing-small-carousel-margin-bottom": "1rem",
        "components-lightbox-spacing-small-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-small-media-details-margin-top": "1rem",
        "components-lightbox-spacing-small-image-height": "20.4375rem",
        "components-lightbox-spacing-small-image-width": "20.4375rem",
        "components-legal-disclaimer-spacing-small-ruleline-width": "20.4375rem",
        "components-media-spacing-large-padding-y": "0.5rem",
        "components-media-spacing-large-padding-x": "0.5rem",
        "components-media-gallery-large-container-grid-gap-x": "1.5rem",
        "components-media-gallery-large-container-grid-gap-y": "1.5rem",
        "components-media-gallery-small-container-grid-gap-x": "0.5rem",
        "components-media-gallery-small-container-grid-gap-y": "0.5rem",
        "components-media-gallery-spacing-small-padding-x": "1.5rem",
        "components-media-gallery-spacing-small-padding-y": "2.5rem",
        "components-media-gallery-spacing-large-padding-x": "5rem",
        "components-media-gallery-spacing-large-padding-y": "5rem",
        "components-product-card-spacing-list-content-gap": "0rem",
        "components-product-card-spacing-large-padding-image-x": "0rem",
        "components-product-card-spacing-large-padding-image-y": "0rem",
        "components-product-card-spacing-large-padding-content-x": "1.5rem",
        "components-product-card-spacing-large-padding-content-y": "1.5rem",
        "components-product-card-spacing-large-category-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-title-margin-bottom": "1rem",
        "components-product-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-button-space-between": "1rem",
        "components-product-card-spacing-small-padding-image-x": "0rem",
        "components-product-card-spacing-small-padding-image-y": "0rem",
        "components-product-card-spacing-small-padding-content-x": "1.5rem",
        "components-product-card-spacing-small-padding-content-y": "1.5rem",
        "components-product-card-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-large-padding-x": "5rem",
        "components-product-information-spacing-large-padding-y": "1.5rem",
        "components-product-information-spacing-large-title-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-label-margin-bottom": "2.5rem",
        "components-product-information-spacing-large-ratings-margin-bottom": "1rem",
        "components-product-information-spacing-large-details-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-space-between": "1rem",
        "components-product-information-spacing-large-carousel-space-between": "5rem",
        "components-product-information-spacing-large-button-margin-y": "0.5rem",
        "components-product-information-spacing-large-body-margin-top": "1.5rem",
        "components-product-information-spacing-large-image-space-between": "1rem",
        "components-product-information-spacing-large-image-margin-bottom": "1rem",
        "components-product-information-spacing-small-padding-x": "1.5rem",
        "components-product-information-spacing-small-padding-y": "0.5rem",
        "components-product-information-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-small-label-margin-bottom": "1.5rem",
        "components-product-information-spacing-small-ratings-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-details-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-space-between": "0.75rem",
        "components-product-information-spacing-small-carousel-space-between": "2.5rem",
        "components-product-information-spacing-small-button-margin-y": "0.75rem",
        "components-product-information-spacing-small-body-margin-top": "1rem",
        "components-product-information-spacing-small-image-space-between": "0.75rem",
        "components-product-information-spacing-small-image-margin-bottom": "1rem",
        "components-product-results-list-with-search-spacing-large-padding-x": "5rem",
        "components-product-results-list-with-search-spacing-large-padding-y": "5rem",
        "components-product-results-list-with-search-spacing-large-ruleline-margin-top": "2.5rem",
        "components-product-results-list-with-search-spacing-large-filter-space-between": "1rem",
        "components-product-results-list-with-search-spacing-large-card-space-between": "1.5rem",
        "components-product-results-list-with-search-spacing-large-card-container-margin-bottom": "5rem",
        "components-product-results-list-with-search-spacing-small-padding-x": "1.5rem",
        "components-product-results-list-with-search-spacing-small-padding-y": "2.5rem",
        "components-product-results-list-with-search-spacing-small-ruleline-margin-top": "1rem",
        "components-product-results-list-with-search-spacing-small-filter-padding-x": "0.5rem",
        "components-product-results-list-with-search-spacing-small-card-space-between": "1rem",
        "components-product-results-list-with-search-spacing-small-card-margin-bottom": "1.5rem",
        "components-promo-spacing-large-padding-x": "4rem",
        "components-promo-spacing-large-padding-y": "0rem",
        "components-promo-spacing-large-content-space-between": "0rem",
        "components-promo-spacing-large-content-space-between-y": "0rem",
        "components-promo-spacing-large-padding-content-x": "7.5rem",
        "components-promo-spacing-large-padding-content-condensed-x": "7.5rem",
        "components-promo-spacing-large-padding-content-y": "7.5rem",
        "components-promo-spacing-large-padding-content-condensed-y": "7.5rem",
        "components-promo-spacing-large-padding-image-x": "0rem",
        "components-promo-spacing-large-padding-image-y": "0rem",
        "components-promo-spacing-large-padding-image-top": "1.5rem",
        "components-promo-spacing-large-title-margin-bottom": "2rem",
        "components-promo-spacing-large-margin-y": "2.5rem",
        "components-promo-spacing-large-buttons-space-between": "1rem",
        "components-promo-spacing-small-padding-x": "1rem",
        "components-promo-spacing-small-padding-y": "2.5rem",
        "components-promo-spacing-small-padding-top": "0rem",
        "components-promo-spacing-small-padding-content-x": "1.5rem",
        "components-promo-spacing-small-padding-content-y": "2.5rem",
        "components-promo-spacing-small-padding-image-x": "0rem",
        "components-promo-spacing-small-padding-image-y": "0rem",
        "components-promo-spacing-small-padding-image-top": "0rem",
        "components-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-promo-spacing-small-margin-y": "2.5rem",
        "components-promo-minheight": "30rem",
        "components-pull-quote-spacing-large-padding-quote-x": "4rem",
        "components-pull-quote-spacing-large-padding-quote-y": "4rem",
        "components-pull-quote-spacing-large-title-margin-bottom": "2rem",
        "components-pull-quote-spacing-large-quote-margin-bottom": "4rem",
        "components-pull-quote-spacing-large-quote-space-between": "1.5rem",
        "components-pull-quote-spacing-large-detail-space-between": "0.5rem",
        "components-pull-quote-spacing-large-quote-minheight": "25rem",
        "components-pull-quote-spacing-small-padding-quote-x": "1.5rem",
        "components-pull-quote-spacing-small-padding-quote-y": "2.5rem",
        "components-pull-quote-spacing-small-title-margin-bottom": "1.5rem",
        "components-pull-quote-spacing-small-quote-margin-bottom": "2.5rem",
        "components-pull-quote-spacing-small-quote-space-between": "1rem",
        "components-pull-quote-spacing-small-detail-space-between": "1rem",
        "components-pull-quote-spacing-small-quote-minheight": "15rem",
        "components-promo-alternate-minheight": "30rem",
        "components-promo-alternate-spacing-large-padding-x": "4rem",
        "components-promo-alternate-spacing-large-padding-y": "0rem",
        "components-promo-alternate-spacing-large-content-space-between": "0rem",
        "components-promo-alternate-spacing-large-padding-content-x": "7.5rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-x": "7.5rem",
        "components-promo-alternate-spacing-large-padding-content-y": "7.5rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-y": "7.5rem",
        "components-promo-alternate-spacing-large-padding-image-x": "0rem",
        "components-promo-alternate-spacing-large-padding-image-y": "0rem",
        "components-promo-alternate-spacing-large-padding-image-top": "1.5rem",
        "components-promo-alternate-spacing-large-title-margin-bottom": "2rem",
        "components-promo-alternate-spacing-small-padding-x": "1rem",
        "components-promo-alternate-spacing-small-padding-y": "2.5rem",
        "components-promo-alternate-spacing-small-padding-content-x": "1.5rem",
        "components-promo-alternate-spacing-small-padding-content-y": "2.5rem",
        "components-promo-alternate-spacing-small-padding-image-x": "0rem",
        "components-promo-alternate-spacing-small-padding-image-y": "0rem",
        "components-promo-alternate-spacing-small-padding-image-top": "0rem",
        "components-promo-alternate-spacing-small-title-margin-bottom": "1.5rem",
        "components-share-list-spacing-padding-x": "1rem",
        "components-share-list-spacing-padding-y": "1rem",
        "components-header-copy-margin-bottom": "1rem",
        "components-header-margin-bottom": "1.5rem",
        "components-item-margin-bottom": "1.5rem",
        "components-site-search-padding-x": "2.5rem",
        "components-site-search-padding-y": "5rem",
        "components-site-search-facet-label-space-between": "0.25rem",
        "components-site-search-refine-padding-x": "1.5rem",
        "components-site-search-refine-padding-y": "0rem",
        "components-site-search-hero-search-height": "18.5rem",
        "components-site-search-hero-search-large-padding-x": "5rem",
        "components-site-search-hero-search-large-padding-y": "5rem",
        "components-stats-spacing-stat-text-space-between": "0.5rem",
        "components-stats-spacing-large-inner-padding-x": "5rem",
        "components-stats-spacing-large-inner-padding-y": "5rem",
        "components-stats-spacing-small-inner-padding-x": "1.5rem",
        "components-stats-spacing-small-inner-padding-y": "1.5rem",
        "components-icon-card-listing-spacing-small-top-space-between": "1rem",
        "components-supporting-text-padding-x": "0rem",
        "components-supporting-text-padding-bottom": "0.5rem",
        "components-tab-item-padding-y": "0.75rem",
        "components-tab-item-padding-x": "0.75rem",
        "components-tab-item-spacing-x": "0.75rem",
        "components-tab-input-padding-y": "0.25rem",
        "components-tab-input-padding-x": "1rem",
        "components-tag-spacing-padding-x": "0.5rem",
        "components-tag-spacing-padding-y": "0.5rem",
        "components-tag-spacing-space-between": "0.5rem",
        "components-text-field-input-padding-y": "0.25rem",
        "components-text-field-input-padding-x": "1rem",
        "components-text-field-area-input-padding-y": "1rem",
        "components-text-field-area-input-padding-x": "1rem",
        "components-text-field-supporting-text-padding-x": "0rem",
        "components-text-field-supporting-text-padding-top": "0.5rem",
        "themes-general-surface-spacing-large-padding-x": "5rem",
        "themes-general-surface-spacing-large-padding-y": "5rem",
        "themes-general-surface-spacing-large-title-margin-bottom": "2rem",
        "themes-general-surface-spacing-large-body-margin-right": "4rem",
        "themes-general-surface-spacing-large-body-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-large-margin-bottom": "4rem",
        "themes-general-surface-spacing-large-cards-space-between": "1.5rem",
        "themes-general-surface-spacing-large-button-margin-top": "2rem",
        "themes-general-surface-spacing-small-padding-x": "1.5rem",
        "themes-general-surface-spacing-small-padding-y": "2.5rem",
        "themes-general-surface-spacing-small-title-margin-bottom": "1rem",
        "themes-general-surface-spacing-small-body-margin-right": "2.5rem",
        "themes-general-surface-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-surface-spacing-small-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-small-cards-space-between": "1rem",
        "themes-general-surface-spacing-small-button-margin-top": "1.5rem",
        "themes-general-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "themes-general-card-spacing-large-title-margin-bottom": "1rem",
        "themes-general-card-spacing-large-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-large-button-space-between": "1.5rem",
        "themes-general-card-spacing-small-eyebrow-margin-bottom": "1rem",
        "themes-general-card-spacing-small-title-margin-bottom": "1rem",
        "themes-general-card-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-small-button-space-between": "1.5rem",
        "themes-shadow-share-list-spacing-x": "0rem",
        "themes-shadow-share-list-spacing-y": "0.75rem",
        "themes-shadow-share-list-spacing-blur": "1rem",
        "themes-shadow-share-list-spacing-spread": "-0.5rem",
        "themes-shadow-header-menu-spacing-x": "0rem",
        "themes-shadow-header-menu-spacing-y": "0.75rem",
        "themes-shadow-header-menu-spacing-blur": "1rem",
        "themes-shadow-header-menu-spacing-spread": "-0.5rem",
        "themes-typography-rte-large-h1-textsize": "3rem",
        "themes-typography-rte-large-h2-textsize": "2.5rem",
        "themes-typography-rte-large-h3-textsize": "2.25rem",
        "themes-typography-rte-large-h4-textsize": "2rem",
        "themes-typography-rte-large-h5-textsize": "1.5rem",
        "themes-typography-rte-large-h6-textsize": "1.25rem",
        "themes-typography-rte-medium-h1-textsize": "2.5rem",
        "themes-typography-rte-medium-h2-textsize": "2.25rem",
        "themes-typography-rte-medium-h3-textsize": "1.75rem",
        "themes-typography-rte-medium-h4-textsize": "1.5rem",
        "themes-typography-rte-medium-h5-textsize": "1.25rem",
        "themes-typography-rte-medium-h6-textsize": "1.125rem",
        "themes-typography-rte-small-h1-textsize": "2.5rem",
        "themes-typography-rte-small-h2-textsize": "2rem",
        "themes-typography-rte-small-h3-textsize": "1.75rem",
        "themes-typography-rte-small-h4-textsize": "1.5rem",
        "themes-typography-rte-small-h5-textsize": "1.125rem",
        "themes-typography-rte-small-h6-textsize": "1rem",
        "spacing-margin-small-1": "0.125rem",
        "spacing-margin-small-2": "0.25rem",
        "spacing-margin-small-3": "0.5rem",
        "spacing-margin-small-4": "0.75rem",
        "spacing-margin-small-5": "1rem",
        "spacing-margin-small-6": "1.5rem",
        "spacing-margin-small-7": "2.5rem",
        "spacing-margin-medium-2": "0.75rem",
        "spacing-margin-medium-4": "1.5rem",
        "spacing-margin-medium-5": "2rem",
        "spacing-margin-large-1": "0.5rem",
        "spacing-margin-large-2": "1rem",
        "spacing-margin-large-3": "1.5rem",
        "spacing-margin-large-4": "2rem",
        "spacing-margin-large-5": "2.5rem",
        "spacing-margin-large-6": "4rem",
        "spacing-margin-large-7": "5rem",
        "spacing-space-between-small-1": "0.125rem",
        "spacing-space-between-small-2": "0.25rem",
        "spacing-space-between-small-3": "0.5rem",
        "spacing-space-between-small-4": "0.75rem",
        "spacing-space-between-small-5": "1rem",
        "spacing-space-between-small-6": "1.5rem",
        "spacing-space-between-small-7": "2.5rem",
        "spacing-space-between-medium-1": "0.125rem",
        "spacing-space-between-medium-2": "0.25rem",
        "spacing-space-between-medium-3": "0.5rem",
        "spacing-space-between-medium-4": "0.75rem",
        "spacing-space-between-medium-5": "1rem",
        "spacing-space-between-large-1": "0.25rem",
        "spacing-space-between-large-2": "0.5rem",
        "spacing-space-between-large-3": "1rem",
        "spacing-space-between-large-4": "1.5rem",
        "spacing-space-between-large-5": "1.5rem",
        "spacing-space-between-large-6": "2.5rem",
        "spacing-space-between-large-7": "4rem",
        "spacing-padding-small-1": "0.125rem",
        "spacing-padding-small-2": "0.25rem",
        "spacing-padding-small-3": "0.5rem",
        "spacing-padding-small-4": "0.75rem",
        "spacing-padding-small-5": "1rem",
        "spacing-padding-small-6": "1.5rem",
        "spacing-padding-small-7": "2.5rem",
        "spacing-padding-medium-1": "0.25rem",
        "spacing-padding-medium-2": "0.75rem",
        "spacing-padding-medium-3": "1rem",
        "spacing-padding-medium-4": "1.5rem",
        "spacing-padding-medium-5": "2rem",
        "spacing-padding-medium-6": "3rem",
        "spacing-padding-medium-7": "4rem",
        "spacing-padding-large-1": "0.5rem",
        "spacing-padding-large-2": "1rem",
        "spacing-padding-large-3": "1.5rem",
        "spacing-padding-large-4": "2rem",
        "spacing-padding-large-5": "2.5rem",
        "spacing-padding-large-6": "4rem",
        "spacing-padding-large-7": "5rem"
      },
      "string": {
        "components-accordion-section-button": "var(--brand)",
        "components-article-card-listing-button-theme": "var(--brand)",
        "components-article-hero-pagination-buttons": "var(--brand)",
        "components-banner-banner-button": "var(--brand)",
        "components-category-card-listing-button-theme": "var(--brand)",
        "components-contact-us-promo-hierarchy-button-theme-1": "var(--filled)",
        "components-contact-us-promo-hierarchy-button-theme-2": "var(--outlined)",
        "components-contact-us-promo-color-button-theme": "var(--brand)",
        "components-contact-us-promo-color-brand-button-theme": "var(--white)",
        "components-generic-card-button-theme": "var(--brand)",
        "components-global-search-loader": "var(--default)",
        "components-generic-card-listing-decor-top": "var(--off-2)",
        "components-generic-card-listing-decor-bottom": "var(--off-4)",
        "components-hero-homepage-pagination-buttons": "var(--brand)",
        "components-hero-homepage-pattern": "var(--off)",
        "components-hero-homepage-button-1": "var(--brand)",
        "components-hero-homepage-button-2": "var(--brand)",
        "components-hero-homepage-image-ratio": "var(--5:4)",
        "components-hero-internal-theme-button-one-type": "var(--filled)",
        "components-hero-internal-theme-button-two-type": "var(--outlined)",
        "components-hero-internal-theme-button-color": "var(--white)",
        "components-hero-internal-theme-button-color-with-image": "var(--white)",
        "components-lightbox-button-type": "var(--brand)",
        "components-pdf-link-card-hierarchy-button-theme-1": "var(--filled)",
        "components-pdf-link-card-hierarchy-button-theme-2": "var(--outlined)",
        "components-pdf-link-card-color-button-theme": "var(--brand)",
        "components-product-card-badge-align": "var(--center)",
        "components-product-card-listing-button-color": "var(--tonal)",
        "components-promo-theme-button-one-theme": "var(--filled)",
        "components-promo-theme-button-two-theme": "var(--text)",
        "components-promo-color-default-button-theme": "var(--brand)",
        "components-promo-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-color-brand-button-theme": "var(--white)",
        "components-pull-quote-border-decor": "var(--off-1)",
        "components-promo-alternate-theme-button-one-theme": "var(--filled)",
        "components-promo-alternate-theme-button-two-theme": "var(--text)",
        "components-promo-alternate-color-default-button-theme": "var(--brand)",
        "components-promo-alternate-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-alternate-color-brand-button-theme": "var(--white)",
        "components-share-list-button-color": "var(--brand)",
        "components-site-search-hero-search-hero-pattern": "var(--off)",
        "components-stats-decor-top": "var(--off-2)",
        "components-stats-decor-bottom": "var(--off-4)",
        "themes-brand-loader": "var(--default)",
        "themes-brand-variants": "var(--off)",
        "themes-tab-variant": "var(--default)",
        "themes-label-variants": "var(--default)",
        "themes-logo": "var(--off)",
        "themes-category-card": "var(--off)",
        "themes-title-decor": "var(--off)",
        "themes-typography-heading-fontfamily": "var(--poppins)",
        "themes-typography-heading-large-xxlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-xlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-large-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-medium-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-small-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-xsmall-fontweight": "var(--bold)",
        "themes-typography-heading-large-xxsmall-fontweight": "var(--semibold)",
        "themes-typography-heading-medium-xxlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-xlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-large-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-medium-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-small-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-xsmall-fontweight": "var(--bold)",
        "themes-typography-heading-medium-xxsmall-fontweight": "var(--semibold)",
        "themes-typography-heading-small-xxlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-xlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-large-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-medium-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-small-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-xsmall-fontweight": "var(--bold)",
        "themes-typography-heading-small-xxsmall-fontweight": "var(--semibold)",
        "themes-typography-rte-fontfamily": "var(--poppins)",
        "themes-typography-rte-fontweight": "var(--extrabold)"
      },
      "borderRadius": {
        "components-accordion-spacing-large-item-item-radius": "0rem",
        "components-article-card-spacing-large-image-radius-tl": "0rem",
        "components-article-card-spacing-large-image-radius-tr": "0rem",
        "components-article-card-spacing-large-image-radius-bl": "0rem",
        "components-article-card-spacing-large-image-radius-br": "4rem",
        "components-article-card-spacing-large-list-image-radius-br": "4rem",
        "components-article-card-spacing-small-image-radius-br": "2.5rem",
        "components-article-card-spacing-small-list-image-radius-br": "1rem",
        "components-article-hero-spacing-large-content-radius": "1.5rem",
        "components-article-hero-spacing-small-content-radius": "1rem",
        "components-button-radius-large": "7.5rem",
        "components-button-group-spacing-border-radius": "7.5rem",
        "components-category-card-radius-border-width": "0.0625rem",
        "components-category-card-radius-border-radius": "7.5rem",
        "components-category-card-radius-border-radius-condensed": "0.5rem",
        "components-category-card-radius-media-radius": "0.75rem",
        "components-content-list-icon-radius": "7.5rem",
        "components-fab-spacing-border-radius": "7.5rem",
        "components-header-spacing-large-search-border-radius-top": "7.5rem",
        "components-header-spacing-large-search-border-radius-bottom": "7.5rem",
        "components-header-spacing-large-language-selector-radius": "0.25rem",
        "components-header-spacing-large-dropdown-radius": "0rem",
        "components-hero-homepage-spacing-large-container-radius": "1rem",
        "components-hero-internal-spacing-large-icon-radius": "7.5rem",
        "components-hero-internal-spacing-small-icon-radius": "7.5rem",
        "components-lightbox-spacing-large-overlay-radius": "1rem",
        "components-pagination-scrollbar-radius": "62.5rem",
        "components-product-card-spacing-image-radius-tl": "0rem",
        "components-product-card-spacing-image-radius-tr": "0rem",
        "components-product-card-spacing-image-radius-bl": "0rem",
        "components-product-card-spacing-image-radius-br": "0rem",
        "components-product-information-spacing-large-content-radius": "1.5rem",
        "components-product-information-spacing-small-content-radius": "1rem",
        "components-promo-spacing-large-content-radius": "0rem",
        "components-promo-spacing-large-content-radius-tl": "0rem",
        "components-promo-spacing-large-content-radius-tr": "0rem",
        "components-promo-spacing-large-content-radius-bl": "0rem",
        "components-promo-spacing-large-content-radius-br": "0rem",
        "components-promo-spacing-large-image-radius-tl": "12.5rem",
        "components-promo-spacing-large-image-radius-tr": "0rem",
        "components-promo-spacing-large-image-radius-bl": "0rem",
        "components-promo-spacing-large-image-radius-br": "12.5rem",
        "components-promo-spacing-small-content-radius": "5rem",
        "components-promo-spacing-small-image-radius-tl": "5rem",
        "components-promo-spacing-small-image-radius-tr": "0rem",
        "components-promo-spacing-small-image-radius-bl": "0rem",
        "components-promo-spacing-small-image-radius-br": "5rem",
        "components-promo-alternate-spacing-large-content-radius": "12.5rem",
        "components-promo-alternate-spacing-large-image-radius-tl": "12.5rem",
        "components-promo-alternate-spacing-large-image-radius-tr": "0rem",
        "components-promo-alternate-spacing-large-image-radius-bl": "0rem",
        "components-promo-alternate-spacing-large-image-radius-br": "12.5rem",
        "components-promo-alternate-spacing-small-content-radius": "5rem",
        "components-promo-alternate-spacing-small-image-radius-tl": "5rem",
        "components-promo-alternate-spacing-small-image-radius-tr": "0rem",
        "components-promo-alternate-spacing-small-image-radius-bl": "0rem",
        "components-promo-alternate-spacing-small-image-radius-br": "5rem",
        "components-share-list-spacing-radius": "0.5rem",
        "components-share-list-spacing-link-radius": "0.5rem",
        "components-site-search-filter-radius": "0.5rem",
        "components-site-search-facet-radius": "0rem",
        "components-site-search-refine-radius": "0rem",
        "components-tag-spacing-radius": "0.25rem",
        "components-tip-spacing-radius": "0.25rem",
        "themes-spacing-radius-pagination-bar": "62.5rem",
        "themes-spacing-radius-large-button": "7.5rem",
        "themes-spacing-radius-large-button-icon": "7.5rem",
        "themes-spacing-radius-large-image": "1rem",
        "themes-spacing-radius-large-image-hero": "1rem",
        "themes-spacing-radius-large-image-card": "0.75rem",
        "themes-spacing-radius-large-card": "1rem",
        "themes-spacing-radius-large-icon": "7.5rem",
        "themes-spacing-radius-large-label": "0.25rem",
        "themes-spacing-radius-large-tag": "0.25rem",
        "themes-spacing-radius-large-container-tip": "0.25rem",
        "themes-spacing-radius-large-badge": "0rem",
        "themes-spacing-radius-large-item": "0.5rem",
        "themes-spacing-radius-large-form-field-input": "7.5rem",
        "themes-spacing-radius-large-form-area-input": "0.25rem",
        "themes-spacing-radius-large-general": "1.5rem",
        "themes-spacing-radius-small-button": "7.5rem",
        "themes-spacing-radius-small-button-icon": "7.5rem",
        "themes-spacing-radius-small-image": "0.75rem",
        "themes-spacing-radius-small-image-card": "0.75rem",
        "themes-spacing-radius-small-card": "0.75rem",
        "themes-spacing-radius-small-icon": "7.5rem",
        "themes-spacing-radius-small-label": "0.125rem",
        "themes-spacing-radius-small-tag": "0.25rem",
        "themes-spacing-radius-small-badge": "0rem",
        "themes-spacing-radius-small-item": "0rem",
        "themes-spacing-radius-small-form-field-input": "0.5rem",
        "themes-spacing-radius-small-general": "1rem"
      },
      "borderWidth": {
        "3": "3px",
        "components-article-card-spacing-large-border-width": "0.0625rem",
        "components-generic-card-color-border-width": "0.0625rem",
        "components-header-spacing-large-search-border-width-top": "0.125rem",
        "components-header-spacing-large-search-border-width-right": "0.125rem",
        "components-header-spacing-large-search-border-width-bottom": "0.125rem",
        "components-header-spacing-large-search-border-width-left": "0.125rem",
        "components-product-card-spacing-border-width": "0.0625rem"
      },
      "maxHeight": {},
      "maxWidth": {
        "components-article-hero-spacing-large-header-max-width": "66.375rem",
        "components-content-list-spacing-large-item-image-max-width": "33.75rem",
        "components-content-list-spacing-small-item-image-max-width": "33.75rem"
      },
      "minHeight": {
        "components-dropdown-item-min-height": "3rem",
        "components-icon-min-height": "3rem",
        "components-tab-item-min-height": "3rem",
        "components-text-field-input-min-height": "3rem",
        "components-text-field-icon-min-height": "3rem"
      },
      "minWidth": {
        "components-content-list-spacing-small-item-image-min-width": "18.75rem",
        "components-icon-min-width": "3rem",
        "components-text-field-icon-min-width": "3rem"
      },
      "opacity": {
        "components-accessibility-bar-color-bg": 1,
        "components-accessibility-bar-color-text": 1,
        "components-accordion-color-bg": 1,
        "components-accordion-color-title": 1,
        "components-accordion-color-body": 1,
        "components-accordion-color-section-section-bg": 1,
        "components-accordion-color-section-section-title": 1,
        "components-accordion-color-section-section-body": 1,
        "components-accordion-color-item-item-bg": 1,
        "components-accordion-color-item-divider": 1,
        "components-accordion-color-item-icon-icon-default": 1,
        "components-accordion-color-item-icon-icon-hover": 1,
        "components-accordion-color-item-icon-icon-pressed": 1,
        "components-accordion-color-item-icon-icon-active": 1,
        "components-accordion-color-item-title-item-default": 1,
        "components-accordion-color-item-title-item-hover": 1,
        "components-accordion-color-item-title-item-pressed": 1,
        "components-accordion-color-item-title-item-active": 1,
        "components-accordion-color-content-bg": 1,
        "components-accordion-color-content-title": 1,
        "components-accordion-color-content-body": 1,
        "components-article-card-color-bg": 1,
        "components-article-card-color-border": 1,
        "components-article-card-color-content-bg": 1,
        "components-article-card-color-title": 1,
        "components-article-card-color-subtitle": 1,
        "components-article-card-color-copy": 1,
        "components-article-card-color-description": 1,
        "components-article-card-listing-color-default-bg": 1,
        "components-article-card-listing-color-default-title": 1,
        "components-article-card-listing-color-default-copy": 1,
        "components-article-hero-color-content-bg": 1,
        "components-article-hero-color-bg": 1,
        "components-article-hero-color-headline": 1,
        "components-article-hero-color-body": 1,
        "components-article-hero-color-detail-text": 1,
        "components-article-hero-color-divider": 1,
        "components-article-hero-color-icon": 1,
        "components-article-hero-color-play-icon": 1,
        "components-article-hero-color-selected-stroke": 1,
        "components-banner-color-bg-default": 1,
        "components-banner-color-icon-default": 1,
        "components-banner-color-title-default": 1,
        "components-banner-color-body-default": 1,
        "components-banner-color-icon-close-default": 1,
        "components-banner-color-bg-alert": 1,
        "components-banner-color-icon-alert": 1,
        "components-banner-color-title-alert": 1,
        "components-banner-color-body-alert": 1,
        "components-banner-color-icon-close-alert": 1,
        "components-breadcrumb-color-bg": 1,
        "components-breadcrumb-color-breadcrumb-link-default": 1,
        "components-breadcrumb-color-breadcrumb-link-hover": 1,
        "components-breadcrumb-color-breadcrumb-link-active": 1,
        "components-breadcrumb-color-icon-divider": 1,
        "components-bug-finder-prompt": 1,
        "components-button-color-filled-brand-default-bg": 1,
        "components-button-color-filled-brand-default-text": 1,
        "components-button-color-filled-brand-default-stroke": 1,
        "components-button-color-filled-brand-default-icon": 1,
        "components-button-color-filled-brand-hover-bg": 1,
        "components-button-color-filled-brand-hover-text": 1,
        "components-button-color-filled-brand-hover-stroke": 1,
        "components-button-color-filled-brand-hover-icon": 1,
        "components-button-color-filled-brand-pressed-bg": 1,
        "components-button-color-filled-brand-pressed-text": 1,
        "components-button-color-filled-brand-pressed-stroke": 1,
        "components-button-color-filled-brand-pressed-icon": 1,
        "components-button-color-filled-brand-disabled-bg": null,
        "components-button-color-filled-brand-disabled-text": 0.02,
        "components-button-color-filled-brand-disabled-stroke": null,
        "components-button-color-filled-brand-disabled-icon": 0.02,
        "components-button-color-filled-brand-focus-bg": 1,
        "components-button-color-filled-brand-focus-text": 1,
        "components-button-color-filled-brand-focus-stroke": 1,
        "components-button-color-filled-brand-focus-icon": 1,
        "components-button-color-filled-brand-focus-outline": 1,
        "components-button-color-filled-tonal-default-bg": 1,
        "components-button-color-filled-tonal-default-text": 1,
        "components-button-color-filled-tonal-default-stroke": 1,
        "components-button-color-filled-tonal-default-icon": 1,
        "components-button-color-filled-tonal-hover-bg": 1,
        "components-button-color-filled-tonal-hover-text": 1,
        "components-button-color-filled-tonal-hover-stroke": 1,
        "components-button-color-filled-tonal-hover-icon": 1,
        "components-button-color-filled-tonal-pressed-bg": 1,
        "components-button-color-filled-tonal-pressed-text": 1,
        "components-button-color-filled-tonal-pressed-stroke": 1,
        "components-button-color-filled-tonal-pressed-icon": 1,
        "components-button-color-filled-tonal-disabled-bg": 0.02,
        "components-button-color-filled-tonal-disabled-text": 0.04,
        "components-button-color-filled-tonal-disabled-stroke": 0.02,
        "components-button-color-filled-tonal-disabled-icon": 0.04,
        "components-button-color-filled-tonal-focus-bg": 1,
        "components-button-color-filled-tonal-focus-text": 1,
        "components-button-color-filled-tonal-focus-stroke": 1,
        "components-button-color-filled-tonal-focus-icon": 1,
        "components-button-color-filled-tonal-focus-outline": 1,
        "components-button-color-filled-white-default-bg": 1,
        "components-button-color-filled-white-default-text": 1,
        "components-button-color-filled-white-default-stroke": 1,
        "components-button-color-filled-white-default-icon": 1,
        "components-button-color-filled-white-hover-bg": 1,
        "components-button-color-filled-white-hover-text": 1,
        "components-button-color-filled-white-hover-stroke": 1,
        "components-button-color-filled-white-hover-icon": 1,
        "components-button-color-filled-white-pressed-bg": 1,
        "components-button-color-filled-white-pressed-text": 1,
        "components-button-color-filled-white-pressed-stroke": 1,
        "components-button-color-filled-white-pressed-icon": 1,
        "components-button-color-filled-white-disabled-bg": 0.02,
        "components-button-color-filled-white-disabled-text": null,
        "components-button-color-filled-white-disabled-stroke": 0.02,
        "components-button-color-filled-white-disabled-icon": 0.02,
        "components-button-color-filled-white-focus-bg": 1,
        "components-button-color-filled-white-focus-text": 1,
        "components-button-color-filled-white-focus-stroke": 1,
        "components-button-color-filled-white-focus-icon": 1,
        "components-button-color-filled-white-focus-outline": 1,
        "components-button-color-outline-brand-default-bg": null,
        "components-button-color-outline-brand-default-text": 1,
        "components-button-color-outline-brand-default-stroke": 1,
        "components-button-color-outline-brand-default-icon": 1,
        "components-button-color-outline-brand-hover-bg": 1,
        "components-button-color-outline-brand-hover-text": 1,
        "components-button-color-outline-brand-hover-stroke": 1,
        "components-button-color-outline-brand-hover-icon": 1,
        "components-button-color-outline-brand-pressed-bg": 1,
        "components-button-color-outline-brand-pressed-text": 1,
        "components-button-color-outline-brand-pressed-stroke": 1,
        "components-button-color-outline-brand-pressed-icon": 1,
        "components-button-color-outline-brand-disabled-bg": null,
        "components-button-color-outline-brand-disabled-text": null,
        "components-button-color-outline-brand-disabled-stroke": null,
        "components-button-color-outline-brand-disabled-icon": null,
        "components-button-color-outline-brand-focus-bg": 1,
        "components-button-color-outline-brand-focus-text": 1,
        "components-button-color-outline-brand-focus-stroke": 1,
        "components-button-color-outline-brand-focus-icon": 1,
        "components-button-color-outline-brand-focus-outline": 1,
        "components-button-color-outline-tonal-default-bg": 1,
        "components-button-color-outline-tonal-default-text": 1,
        "components-button-color-outline-tonal-default-stroke": 1,
        "components-button-color-outline-tonal-default-icon": 1,
        "components-button-color-outline-tonal-hover-bg": 1,
        "components-button-color-outline-tonal-hover-text": 1,
        "components-button-color-outline-tonal-hover-stroke": 1,
        "components-button-color-outline-tonal-hover-icon": 1,
        "components-button-color-outline-tonal-pressed-bg": 1,
        "components-button-color-outline-tonal-pressed-text": 1,
        "components-button-color-outline-tonal-pressed-stroke": 1,
        "components-button-color-outline-tonal-pressed-icon": 1,
        "components-button-color-outline-tonal-disabled-bg": 0.02,
        "components-button-color-outline-tonal-disabled-text": 0.02,
        "components-button-color-outline-tonal-disabled-stroke": 0.02,
        "components-button-color-outline-tonal-disabled-icon": 0.02,
        "components-button-color-outline-tonal-focus-bg": 1,
        "components-button-color-outline-tonal-focus-text": 1,
        "components-button-color-outline-tonal-focus-stroke": 1,
        "components-button-color-outline-tonal-focus-icon": 1,
        "components-button-color-outline-tonal-focus-outline": 1,
        "components-button-color-outline-white-default-bg": 0.02,
        "components-button-color-outline-white-default-text": 1,
        "components-button-color-outline-white-default-stroke": 1,
        "components-button-color-outline-white-default-icon": 1,
        "components-button-color-outline-white-hover-bg": 1,
        "components-button-color-outline-white-hover-text": 1,
        "components-button-color-outline-white-hover-stroke": 1,
        "components-button-color-outline-white-hover-icon": 1,
        "components-button-color-outline-white-pressed-bg": 1,
        "components-button-color-outline-white-pressed-text": 1,
        "components-button-color-outline-white-pressed-stroke": 1,
        "components-button-color-outline-white-pressed-icon": 1,
        "components-button-color-outline-white-disabled-bg": 0.02,
        "components-button-color-outline-white-disabled-text": 0.02,
        "components-button-color-outline-white-disabled-stroke": 0.02,
        "components-button-color-outline-white-disabled-icon": 0.02,
        "components-button-color-outline-white-focus-bg": 0.02,
        "components-button-color-outline-white-focus-text": 1,
        "components-button-color-outline-white-focus-stroke": 1,
        "components-button-color-outline-white-focus-icon": 1,
        "components-button-color-outline-white-focus-outline": 1,
        "components-button-color-text-brand-default-bg": 0.02,
        "components-button-color-text-brand-default-text": 1,
        "components-button-color-text-brand-default-stroke": 1,
        "components-button-color-text-brand-default-icon": 1,
        "components-button-color-text-brand-hover-bg": 0.02,
        "components-button-color-text-brand-hover-bg-icon-only": 1,
        "components-button-color-text-brand-hover-text": 1,
        "components-button-color-text-brand-hover-stroke": 1,
        "components-button-color-text-brand-hover-icon": 1,
        "components-button-color-text-brand-pressed-bg": 0.02,
        "components-button-color-text-brand-pressed-bg-icon-only": 1,
        "components-button-color-text-brand-pressed-text": 1,
        "components-button-color-text-brand-pressed-stroke": 1,
        "components-button-color-text-brand-pressed-icon": 1,
        "components-button-color-text-brand-disabled-bg": 0.02,
        "components-button-color-text-brand-disabled-text": null,
        "components-button-color-text-brand-disabled-stroke": null,
        "components-button-color-text-brand-disabled-icon": null,
        "components-button-color-text-brand-focus-bg": 1,
        "components-button-color-text-brand-focus-text": 1,
        "components-button-color-text-brand-focus-stroke": 1,
        "components-button-color-text-brand-focus-icon": 1,
        "components-button-color-text-brand-focus-outline": 1,
        "components-button-color-text-tonal-default-bg": 0.02,
        "components-button-color-text-tonal-default-text": 1,
        "components-button-color-text-tonal-default-stroke": 1,
        "components-button-color-text-tonal-default-icon": 1,
        "components-button-color-text-tonal-hover-bg": 0.02,
        "components-button-color-text-tonal-hover-bg-icon-only": 1,
        "components-button-color-text-tonal-hover-text": 1,
        "components-button-color-text-tonal-hover-stroke": 1,
        "components-button-color-text-tonal-hover-icon": 1,
        "components-button-color-text-tonal-pressed-bg": 0.02,
        "components-button-color-text-tonal-pressed-bg-icon-only": 1,
        "components-button-color-text-tonal-pressed-text": 1,
        "components-button-color-text-tonal-pressed-stroke": 1,
        "components-button-color-text-tonal-pressed-icon": 1,
        "components-button-color-text-tonal-disabled-bg": 0.02,
        "components-button-color-text-tonal-disabled-text": 0.02,
        "components-button-color-text-tonal-disabled-stroke": 0.02,
        "components-button-color-text-tonal-disabled-icon": 0.02,
        "components-button-color-text-tonal-focus-bg": 1,
        "components-button-color-text-tonal-focus-text": 1,
        "components-button-color-text-tonal-focus-stroke": 1,
        "components-button-color-text-tonal-focus-icon": 1,
        "components-button-color-text-tonal-focus-outline": 1,
        "components-button-color-text-white-default-bg": 0.02,
        "components-button-color-text-white-default-text": 1,
        "components-button-color-text-white-default-stroke": 1,
        "components-button-color-text-white-default-icon": 1,
        "components-button-color-text-white-hover-bg": 0.02,
        "components-button-color-text-white-hover-bg-icon-only": 1,
        "components-button-color-text-white-hover-text": 1,
        "components-button-color-text-white-hover-stroke": 1,
        "components-button-color-text-white-hover-icon": 1,
        "components-button-color-text-white-pressed-bg": 0.02,
        "components-button-color-text-white-pressed-bg-icon-only": 1,
        "components-button-color-text-white-pressed-text": 1,
        "components-button-color-text-white-pressed-stroke": 1,
        "components-button-color-text-white-pressed-icon": 1,
        "components-button-color-text-white-disabled-bg": 0.02,
        "components-button-color-text-white-disabled-text": 0.01,
        "components-button-color-text-white-disabled-stroke": 0.01,
        "components-button-color-text-white-disabled-icon": 0.01,
        "components-button-color-text-white-focus-bg": 1,
        "components-button-color-text-white-focus-text": 1,
        "components-button-color-text-white-focus-stroke": 1,
        "components-button-color-text-white-focus-icon": 1,
        "components-button-color-text-white-focus-outline": 1,
        "components-button-group-color-border": 1,
        "components-button-group-color-button-bg-default": 0.02,
        "components-button-group-color-button-fg-default": 1,
        "components-button-group-color-button-bg-hover": 1,
        "components-button-group-color-button-fg-hover": 1,
        "components-button-group-color-button-bg-active": 1,
        "components-button-group-color-button-fg-active": 1,
        "components-button-group-color-overlay-left-gradient-fill-1": 1,
        "components-button-group-color-overlay-left-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-1": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-3": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-4": 0.02,
        "components-button-group-color-stroke-hover-gradient-fill-1": 1,
        "components-button-group-color-stroke-hover-gradient-fill-2": 1,
        "components-button-group-color-stroke-hover-gradient-fill-3": 1,
        "components-button-group-color-stroke-hover-gradient-fill-4": 1,
        "components-button-group-color-stroke-active-gradient-fill-1": 1,
        "components-button-group-color-stroke-active-gradient-fill-2": 1,
        "components-button-group-color-stroke-active-gradient-fill-3": 1,
        "components-button-group-color-stroke-active-gradient-fill-4": 1,
        "components-category-card-color-bg": 1,
        "components-category-card-color-content-bg": 1,
        "components-category-card-color-category-bg": 1,
        "components-category-card-color-category-bg-2": 1,
        "components-category-card-color-category-bg-3": 1,
        "components-category-card-color-category-bg-4": 1,
        "components-category-card-color-category-bg-5": 1,
        "components-category-card-color-category-bg-6": 1,
        "components-category-card-color-title": 1,
        "components-category-card-color-copy": 1,
        "components-category-card-color-stroke": 1,
        "components-category-card-color-stroke-hover": 1,
        "components-category-card-listing-color-bg": 1,
        "components-category-card-listing-color-title": 1,
        "components-category-card-listing-color-copy": 1,
        "components-card-search-result-color-bg": 1,
        "components-card-search-result-color-label": 1,
        "components-card-search-result-color-title": 1,
        "components-card-search-result-color-body": 1,
        "components-card-search-result-color-divider-line": 1,
        "components-contact-us-promo-color-light-bg": 1,
        "components-contact-us-promo-color-light-title": 1,
        "components-contact-us-promo-color-light-body": 1,
        "components-contact-us-promo-color-default-bg": 1,
        "components-contact-us-promo-color-default-title": 1,
        "components-contact-us-promo-color-default-body": 1,
        "components-contact-us-promo-color-brand-bg": 1,
        "components-contact-us-promo-color-brand-title": 1,
        "components-contact-us-promo-color-brand-body": 1,
        "components-content-list-color-bg": 1,
        "components-content-list-color-title": 1,
        "components-content-list-color-body": 1,
        "components-content-list-color-icon": 1,
        "components-cookies-colors-bg": 1,
        "components-cookies-colors-title": 1,
        "components-cookies-colors-body": 1,
        "components-cookies-colors-icon": 1,
        "components-dropdown-bg": 1,
        "components-dropdown-border-default": 1,
        "components-dropdown-border-hover": 1,
        "components-dropdown-border-focused": 1,
        "components-dropdown-border-destructive": 1,
        "components-dropdown-label-text-default": 1,
        "components-dropdown-item-text-default": 1,
        "components-dropdown-item-bg-default": 1,
        "components-dropdown-item-bg-hover": 1,
        "components-dropdown-input-field-default": 1,
        "components-dropdown-input-field-focused": 1,
        "components-dropdown-input-label-focused": 1,
        "components-dropdown-input-field-destructive": 1,
        "components-external-link-card-bg": 1,
        "components-external-link-card-card-bg": 1,
        "components-external-link-card-card-title": 1,
        "components-external-link-card-card-subtitle": 1,
        "components-external-link-card-card-eyebrow": 1,
        "components-fab-color-border-default": 1,
        "components-footer-brand-color-bg": 1,
        "components-footer-brand-color-logo": 1,
        "components-footer-brand-color-title-text": 1,
        "components-footer-brand-color-description-text": 1,
        "components-footer-brand-color-footer-menu-expanded": 1,
        "components-footer-brand-color-link-default": 1,
        "components-footer-brand-color-link-hover": 1,
        "components-footer-brand-color-link-pressed": 1,
        "components-footer-brand-color-category-link-default": 1,
        "components-footer-brand-color-category-link-hover": 1,
        "components-footer-brand-color-category-link-pressed": 1,
        "components-footer-brand-color-subcategory-link-default": 1,
        "components-footer-brand-color-subcategory-link-hover": 1,
        "components-footer-brand-color-subcategory-link-pressed": 1,
        "components-footer-brand-color-icon-icon-default": 1,
        "components-footer-brand-color-icon-icon-pressed": 1,
        "components-footer-brand-color-social-icon-default": 1,
        "components-footer-brand-color-social-icon-hover": 1,
        "components-footer-brand-color-social-icon-pressed": 1,
        "components-footer-brand-spacing-category-link-hover": 1,
        "components-footer-global-color-bg": 1,
        "components-footer-global-color-title-text": 1,
        "components-footer-global-color-disclaimer-text": 1,
        "components-footer-global-color-link-default": 1,
        "components-footer-global-color-link-hover": 1,
        "components-footer-global-color-link-pressed": 1,
        "components-generic-card-color-bg": 1,
        "components-generic-card-color-content-bg": 1,
        "components-generic-card-color-title": 1,
        "components-generic-card-color-subtitle": 1,
        "components-generic-card-color-copy": 1,
        "components-global-search-color-omnibox-list-bg": 1,
        "components-global-search-color-omnibox-divider": 1,
        "components-global-search-color-omnibox-item-bg-default": 1,
        "components-global-search-color-omnibox-item-bg-hover": 1,
        "components-global-search-color-omnibox-item-text-default": 1,
        "components-global-search-color-omnibox-item-text-hover": 1,
        "components-global-search-color-omnibox-label-text-default": 1,
        "components-global-search-color-omnibox-category-text-default": 1,
        "components-generic-card-listing-color-bg": 1,
        "components-generic-card-listing-color-title": 1,
        "components-generic-card-listing-color-copy": 1,
        "components-header-color-bg": 1,
        "components-header-color-bottom-stroke": 1,
        "components-header-color-search-search-border-default": 1,
        "components-header-color-search-search-text": 1,
        "components-header-color-search-search-icon": 1,
        "components-header-color-search-search-bar": 1,
        "components-header-color-mobile-mobile-expanded-bg": 1,
        "components-header-color-mobile-pressed-bg": 1,
        "components-header-color-language-selector-language": 1,
        "components-header-color-language-selector-country": 1,
        "components-header-color-language-selector-country-hover-bg": 1,
        "components-header-color-language-selector-megamenu-language": 1,
        "components-header-color-language-selector-megamenu-country": 1,
        "components-header-color-language-selector-megamenu-country-hover": 1,
        "components-header-color-language-selector-bg": 1,
        "components-header-color-language-selector-stroke": 1,
        "components-header-color-mega-menu-bg": 1,
        "components-header-color-mega-menu-featured-bg": 1,
        "components-header-color-mega-menu-featured-title": 1,
        "components-header-color-mega-menu-category-title": 1,
        "components-header-color-mega-menu-bg-accent": 1,
        "components-header-color-icon-icon-default": 1,
        "components-header-color-icon-icon-hover": 1,
        "components-header-color-icon-icon-pressed": 1,
        "components-header-color-links-nav-main-link-default": 1,
        "components-header-color-links-nav-main-link-hover": 1,
        "components-header-color-links-nav-main-link-pressed": 1,
        "components-header-color-links-nav-main-link-active": 1,
        "components-header-color-links-nav-main-active-bar": 1,
        "components-header-color-links-nav-main-bg-color": 1,
        "components-header-color-links-nav-category-link-category-default": 1,
        "components-header-color-links-nav-category-link-category-hover": 1,
        "components-header-color-links-nav-category-link-category-pressed": 1,
        "components-header-color-links-nav-category-link-category-active": 1,
        "components-header-color-links-nav-menu-link-link-default": 1,
        "components-header-color-links-nav-menu-link-link-hover": 1,
        "components-header-color-links-nav-menu-link-link-pressed": 1,
        "components-header-color-links-nav-menu-link-link-active": 1,
        "components-header-color-links-nav-menu-link-bg": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-default": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-active": 1,
        "components-hero-homepage-color-bg": 1,
        "components-hero-homepage-color-container-bg": 1,
        "components-hero-homepage-color-eyebrow": 1,
        "components-hero-homepage-color-header": 1,
        "components-hero-homepage-color-sub-header": 1,
        "components-hero-homepage-color-body": 1,
        "components-hero-homepage-color-play-pause": 1,
        "components-hero-internal-color-default-title": 1,
        "components-hero-internal-color-default-body": 1,
        "components-hero-internal-color-default-bg": 1,
        "components-hero-internal-color-accent-bg": 1,
        "components-hero-internal-color-with-image-bg": 1,
        "components-hero-internal-color-default-bg-2": 1,
        "components-hero-internal-color-with-image-title": 1,
        "components-hero-internal-color-with-image-body": 1,
        "components-heading-page-title-color-default-bg": 1,
        "components-heading-page-title-color-default-title": 1,
        "components-heading-page-title-color-default-body": 1,
        "components-heading-page-title-color-light-bg": 1,
        "components-heading-page-title-color-light-title": 1,
        "components-heading-page-title-color-light-body": 1,
        "components-heading-page-title-color-color-bg": 1,
        "components-heading-page-title-color-color-title": 1,
        "components-heading-page-title-color-color-body": 1,
        "components-hyperlink-color-dark-default": 1,
        "components-hyperlink-color-dark-hover": 1,
        "components-hyperlink-color-dark-visited": 1,
        "components-hyperlink-color-light-default": 1,
        "components-hyperlink-color-light-hover": 1,
        "components-hyperlink-color-light-visited": 1,
        "components-icon-default": 1,
        "components-icon-focused": 1,
        "components-icon-card-color-bg": 1,
        "components-icon-card-color-icon-bg": 1,
        "components-icon-card-color-icon": 1,
        "components-icon-card-color-title": 1,
        "components-icon-card-color-subtitle": 1,
        "components-icon-card-color-description": 1,
        "components-icon-card-listing-color-bg": 1,
        "components-icon-card-listing-color-inner-bg": 1,
        "components-icon-card-listing-color-brand-bg": 1,
        "components-icon-card-listing-color-title": 1,
        "components-icon-card-listing-color-copy": 1,
        "components-icon-card-listing-color-inner-legal-text": 1,
        "components-jumplink-color-bg-default": 1,
        "components-jumplink-color-link-text-default": 1,
        "components-jumplink-color-link-text-hover": 1,
        "components-jumplink-color-link-border-hover": 1,
        "components-jumplink-color-link-text-active": 1,
        "components-jumplink-color-link-border-active": 1,
        "components-jumplink-color-title-text": 1,
        "components-jumplink-color-description-text": 1,
        "components-layout-bg-default": 1,
        "components-lightbox-color-overlay-bg": 0.02,
        "components-lightbox-color-title": 1,
        "components-lightbox-color-body": 1,
        "components-label-color-on-color-label-label-default": 1,
        "components-label-color-on-color-label-label-hover": 1,
        "components-label-color-on-color-label-label-pressed": 1,
        "components-label-color-on-color-background-bg-default": 1,
        "components-label-color-on-color-background-bg-hover": 1,
        "components-label-color-on-color-background-bg-pressed": 1,
        "components-label-color-on-color-stroke-hover": 1,
        "components-label-color-on-color-stroke-pressed": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-4": 1,
        "components-label-color-on-white-label-label-default": 1,
        "components-label-color-on-white-label-label-hover": 1,
        "components-label-color-on-white-label-label-pressed": 1,
        "components-label-color-on-white-background-bg-default": 0.02,
        "components-label-color-on-white-background-bg-hover": 1,
        "components-label-color-on-white-background-bg-pressed": 1,
        "components-label-color-on-white-stroke-hover": 1,
        "components-label-color-on-white-stroke-pressed": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-4": 1,
        "components-legal-disclaimer-color-default-bg": 1,
        "components-legal-disclaimer-color-default-title": 1,
        "components-legal-disclaimer-color-default-body": 1,
        "components-legal-disclaimer-color-light-bg": 1,
        "components-legal-disclaimer-color-light-title": 1,
        "components-legal-disclaimer-color-light-body": 1,
        "components-legal-disclaimer-color-brand-bg": 1,
        "components-legal-disclaimer-color-brand-title": 1,
        "components-legal-disclaimer-color-brand-body": 1,
        "components-media-video-thumbnail-overlay-bg": null,
        "components-media-caption-color-bg": 1,
        "components-media-caption-color-text": 1,
        "components-media-gallery-color-bg": 1,
        "components-media-gallery-color-title": 1,
        "components-media-gallery-color-body": 1,
        "components-pagination-on-white-accent-default": 1,
        "components-pagination-on-white-accent-scroll": 1,
        "components-pagination-on-white-disabled": 1,
        "components-pagination-on-white-bg": 1,
        "components-pagination-on-white-stroke": 1,
        "components-pagination-on-white-bg-active": 1,
        "components-pagination-on-white-bg-hover": 1,
        "components-pagination-on-white-text-default": 1,
        "components-pagination-on-white-text-hover": 1,
        "components-pagination-on-white-text-active": 1,
        "components-pagination-on-color-accent-default": 1,
        "components-pagination-on-color-accent-scroll": 1,
        "components-pagination-on-color-disabled": 1,
        "components-pagination-on-color-bg": 1,
        "components-pagination-on-color-stroke": 0.02,
        "components-pagination-on-color-bg-active": 1,
        "components-pagination-on-color-bg-hover": 1,
        "components-pagination-on-color-text-default": 1,
        "components-pagination-on-color-text-hover": 1,
        "components-pagination-on-color-text-active": 1,
        "components-pdf-link-card-color-bg": 1,
        "components-pdf-link-card-color-card-bg": 1,
        "components-pdf-link-card-color-card-title": 1,
        "components-pdf-link-card-color-card-subtitle": 1,
        "components-pdf-link-card-color-card-body": 1,
        "components-product-card-color-bg": 1,
        "components-product-card-color-content-bg": 1,
        "components-product-card-color-border": 1,
        "components-product-card-color-title": 1,
        "components-product-card-color-description": 1,
        "components-product-card-color-star-rating": 1,
        "components-product-card-color-badge-bg": 1,
        "components-product-card-color-badge-text": 1,
        "components-product-card-listing-color-bg": 1,
        "components-product-card-listing-color-title": 1,
        "components-product-card-listing-color-copy": 1,
        "components-product-card-listing-color-legal-text": 1,
        "components-product-information-color-bg": 1,
        "components-product-information-color-content-bg": 1,
        "components-product-information-color-headline": 1,
        "components-product-information-color-body": 1,
        "components-product-information-color-detail-text": 1,
        "components-product-information-color-divider": 1,
        "components-product-information-color-icon": 1,
        "components-product-information-color-play-icon": 1,
        "components-product-information-color-selected-stroke": 1,
        "components-product-results-list-with-search-color-bg": 1,
        "components-promo-color-default-bg": 1,
        "components-promo-color-default-image-bg": 1,
        "components-promo-color-default-content-bg": 1,
        "components-promo-color-default-title": 1,
        "components-promo-color-default-body": 1,
        "components-promo-color-default-category": 1,
        "components-promo-color-light-bg": 1,
        "components-promo-color-light-image-bg": 1,
        "components-promo-color-light-content-bg": 1,
        "components-promo-color-light-title": 1,
        "components-promo-color-light-body": 1,
        "components-promo-color-light-category": 1,
        "components-promo-color-brand-bg": 1,
        "components-promo-color-brand-image-bg": 1,
        "components-promo-color-brand-content-bg": 1,
        "components-promo-color-brand-title": 1,
        "components-promo-color-brand-body": 1,
        "components-promo-color-brand-category": 1,
        "components-pull-quote-color-bg": 1,
        "components-pull-quote-color-stroke": 1,
        "components-pull-quote-color-quote-bg": 1,
        "components-pull-quote-color-quote-title": 1,
        "components-pull-quote-color-quote-by-text": 1,
        "components-pull-quote-color-quote-detail": 1,
        "components-pull-quote-color-quote-highlight": 1,
        "components-promo-alternate-color-default-bg": 1,
        "components-promo-alternate-color-default-image-bg": 1,
        "components-promo-alternate-color-default-content-bg": 1,
        "components-promo-alternate-color-default-title": 1,
        "components-promo-alternate-color-default-body": 1,
        "components-promo-alternate-color-default-category": 1,
        "components-promo-alternate-color-light-bg": 1,
        "components-promo-alternate-color-light-image-bg": 1,
        "components-promo-alternate-color-light-content-bg": 1,
        "components-promo-alternate-color-light-title": 1,
        "components-promo-alternate-color-light-body": 1,
        "components-promo-alternate-color-light-category": 1,
        "components-promo-alternate-color-brand-bg": 1,
        "components-promo-alternate-color-brand-image-bg": 1,
        "components-promo-alternate-color-brand-content-bg": 1,
        "components-promo-alternate-color-brand-title": 1,
        "components-promo-alternate-color-brand-body": 1,
        "components-promo-alternate-color-brand-category": 1,
        "components-share-list-color-title": 1,
        "components-share-list-color-bg": 1,
        "components-share-list-color-link-default": 1,
        "components-share-list-color-link-hover": 1,
        "components-share-list-color-bg-link-hover": 1,
        "components-share-list-color-close-icon": 1,
        "components-share-list-color-facebook-icon": 1,
        "components-share-list-color-pinterest-icon": 1,
        "components-share-list-color-twitter-icon": 1,
        "components-share-list-color-basic-icon-default": 1,
        "components-share-list-color-icon-hover": 1,
        "components-share-list-color-border": 1,
        "components-site-search-bg": 1,
        "components-site-search-results_text": 1,
        "components-site-search-filter-filter-selected-bg": 1,
        "components-site-search-facet-group-label-bottom-line": 1,
        "components-site-search-facet-group-label-text": 1,
        "components-site-search-facet-group-label-bg": 1,
        "components-site-search-facet-bg": 1,
        "components-site-search-facet-stroke": 1,
        "components-site-search-refine-bg": 1,
        "components-site-search-refine-right-divider": 1,
        "components-site-search-refine-title": 1,
        "components-site-search-hero-search-bg": 1,
        "components-site-search-hero-search-accent-bg": 1,
        "components-site-search-hero-search-title": 1,
        "components-stats-stat-text": 1,
        "components-stats-stat-text-on-light": 1,
        "components-stats-supporting-text": 1,
        "components-stats-bg-default": 1,
        "components-stats-bg-inner": 1,
        "components-stats-bg-section-inner": 1,
        "components-stats-title": 1,
        "components-stats-copy": 1,
        "components-supporting-text-default": 1,
        "components-supporting-text-destructive": 1,
        "components-tab-bg": 1,
        "components-tab-border-default": 1,
        "components-tab-border-hover": 1,
        "components-tab-border-focused": 1,
        "components-tab-border-destructive": 1,
        "components-tab-label-text-default": 1,
        "components-tab-item-text-default": 1,
        "components-tab-item-bg-default": 1,
        "components-tab-item-bg-hover": 1,
        "components-tab-input-field-default": 1,
        "components-tab-input-field-focused": 1,
        "components-tab-input-label-focused": 1,
        "components-tab-input-field-destructive": 1,
        "components-tab-bg-2": 1,
        "components-tag-color-bg": 1,
        "components-tag-color-text": 1,
        "components-tag-color-stroke": 1,
        "components-tag-color-bg-hover": 1,
        "components-tag-color-text-hover": 1,
        "components-tag-color-stroke-hover": 1,
        "components-text-field-bg": 1,
        "components-text-field-bg-focused": 1,
        "components-text-field-border-default": 1,
        "components-text-field-border-hover": 1,
        "components-text-field-border-focused": 1,
        "components-text-field-border-destructive": 1,
        "components-text-field-input-field-default": 1,
        "components-text-field-input-field-focused": 1,
        "components-text-field-input-label-focused": 1,
        "components-text-field-input-field-destructive": 1,
        "components-text-field-icon-default": 1,
        "components-text-field-icon-destructive": 1,
        "components-text-field-supporting-text-default": 1,
        "components-text-field-supporting-text-destructive": 1,
        "components-tip-color-bg": 1,
        "components-tip-color-title": 1,
        "components-tip-color-body": 1,
        "components-tip-color-stroke": 1,
        "components-video-color-bg": 1,
        "themes-general-surface-color-light-bg": 1,
        "themes-general-surface-color-light-title": 1,
        "themes-general-surface-color-light-body": 1,
        "themes-general-surface-color-light-decor-line-break": 1,
        "themes-general-surface-color-light-rule-line": 1,
        "themes-general-surface-color-dark-bg": 1,
        "themes-general-surface-color-dark-title": 1,
        "themes-general-surface-color-dark-body": 1,
        "themes-general-surface-color-dark-decor-line-break": 1,
        "themes-general-card-color-dark-title": 1,
        "themes-general-card-color-dark-subtitle": 1,
        "themes-general-card-color-dark-body": 1,
        "themes-general-card-color-light-title": 1,
        "themes-general-card-color-light-subtitle": 1,
        "themes-general-card-color-light-body": 1,
        "themes-shadow-color": 1,
        "themes-shadow-share-list-color-fill": 0,
        "themes-shadow-header-menu-color-fill": 0,
        "themes-gradient-loader-gradient-fill-1": 1,
        "themes-gradient-loader-gradient-fill-2": null,
        "themes-color-background-white": 1,
        "themes-color-background-light": 1,
        "themes-color-background-dark": 1,
        "themes-color-background-black": 1,
        "themes-color-background-brand1": 1,
        "themes-color-background-brand2": 1,
        "themes-color-background-accent1": 1,
        "themes-color-background-accent2": 1,
        "themes-color-background-body": 1,
        "themes-color-stroke-white": 1,
        "themes-color-stroke-light": 1,
        "themes-color-stroke-dark": 1,
        "themes-color-stroke-black": 1,
        "themes-color-stroke-brand1": 1,
        "themes-color-stroke-brand2": 1,
        "themes-color-stroke-accent1": 1,
        "themes-color-stroke-accent2": 1,
        "themes-color-text-white": 1,
        "themes-color-text-light": 1,
        "themes-color-text-dark": 1,
        "themes-color-text-black": 1,
        "themes-color-text-brand1": 1,
        "themes-color-text-brand2": 1,
        "themes-color-text-accent1": 1,
        "themes-color-text-accent2": 1,
        "colors-grayscale-100": 1,
        "colors-grayscale-200": 1,
        "colors-grayscale-300": 1,
        "colors-grayscale-400": 1,
        "colors-grayscale-500": 1,
        "colors-grayscale-600": 1,
        "colors-grayscale-700": 1,
        "colors-brand-1-100": 1,
        "colors-brand-1-200": 1,
        "colors-brand-1-300": 1,
        "colors-brand-1-400": 1,
        "colors-brand-1-500": 1,
        "colors-brand-1-600": 1,
        "colors-brand-1-700": 1,
        "colors-brand-2-100": 1,
        "colors-brand-2-200": 1,
        "colors-brand-2-300": 1,
        "colors-brand-2-400": 1,
        "colors-brand-2-500": 1,
        "colors-brand-2-600": 1,
        "colors-brand-2-700": 1,
        "colors-accent-1-100": 1,
        "colors-accent-1-200": 1,
        "colors-accent-1-300": 1,
        "colors-accent-1-400": 1,
        "colors-accent-1-500": 1,
        "colors-accent-1-600": 1,
        "colors-accent-1-700": 1,
        "colors-accent-2-100": 1,
        "colors-accent-2-200": 1,
        "colors-accent-2-300": 1,
        "colors-accent-2-400": 1,
        "colors-accent-2-500": 1,
        "colors-accent-2-600": 1,
        "colors-accent-2-700": 1,
        "colors-accent-3-100": 1,
        "colors-accent-3-200": 1,
        "colors-accent-3-300": 1,
        "colors-accent-3-400": 1,
        "colors-accent-3-500": 1,
        "colors-accent-3-600": 1,
        "colors-accent-3-700": 1,
        "colors-accent-4-400_storage": 1,
        "colors-accessible-accent-1-100": 1,
        "colors-accessible-accent-1-200": 1,
        "colors-accessible-accent-1-300": 1,
        "colors-accessible-accent-1-400": 1,
        "colors-accessible-accent-1-500": 1,
        "colors-accessible-accent-1-600": 1,
        "colors-accessible-accent-1-700": 1,
        "colors-accessible-accent-2-100": 1,
        "colors-accessible-accent-2-200": 1,
        "colors-accessible-accent-2-300": 1,
        "colors-accessible-accent-2-400": 1,
        "colors-accessible-accent-2-500": 1,
        "colors-accessible-accent-2-600": 1,
        "colors-accessible-accent-2-700": 1,
        "colors-accessible-brand-2-100": 1,
        "colors-accessible-brand-2-200": 1,
        "colors-accessible-brand-2-300": 1,
        "colors-accessible-brand-2-400": 1,
        "colors-accessible-brand-2-500": 1,
        "colors-accessible-brand-2-600": 1,
        "colors-accessible-brand-2-700": 1
      }
    },
    "OffDefense": {
      "colors": {
        "components-accessibility-bar-color-bg": "#00326c",
        "components-accessibility-bar-color-text": "#ffffff",
        "components-accordion-color-bg": "#ffffff",
        "components-accordion-color-title": "#191b1c",
        "components-accordion-color-body": "#27282b",
        "components-accordion-color-section-section-bg": "#ffffff",
        "components-accordion-color-section-section-title": "#191b1c",
        "components-accordion-color-section-section-body": "#27282b",
        "components-accordion-color-item-item-bg": "#ffffff",
        "components-accordion-color-item-divider": "#e8e8ea",
        "components-accordion-color-item-icon-icon-default": "#004697",
        "components-accordion-color-item-icon-icon-hover": "#ffffff",
        "components-accordion-color-item-icon-icon-pressed": "#ffffff",
        "components-accordion-color-item-icon-icon-active": "#ffffff",
        "components-accordion-color-item-title-item-default": "#004697",
        "components-accordion-color-item-title-item-hover": "#ffffff",
        "components-accordion-color-item-title-item-pressed": "#ffffff",
        "components-accordion-color-item-title-item-active": "#ffffff",
        "components-accordion-color-content-bg": "#ffffff",
        "components-accordion-color-content-title": "#191b1c",
        "components-accordion-color-content-body": "#27282b",
        "components-article-card-color-bg": "#fcfcfd",
        "components-article-card-color-border": "#e8e8ea",
        "components-article-card-color-content-bg": "#fcfcfd",
        "components-article-card-color-title": "#191b1c",
        "components-article-card-color-subtitle": "#27282b",
        "components-article-card-color-copy": "#27282b",
        "components-article-card-color-description": "#27282b",
        "components-article-card-listing-color-default-bg": "#ebf1f9",
        "components-article-card-listing-color-default-title": "#191b1c",
        "components-article-card-listing-color-default-copy": "#27282b",
        "components-article-hero-color-content-bg": "#ffffff",
        "components-article-hero-color-bg": "#ffffff",
        "components-article-hero-color-headline": "#191b1c",
        "components-article-hero-color-body": "#27282b",
        "components-article-hero-color-detail-text": "#27282b",
        "components-article-hero-color-divider": "#a2a5ab",
        "components-article-hero-color-icon": "#0053b4",
        "components-article-hero-color-play-icon": "#ffffff",
        "components-article-hero-color-selected-stroke": "#0053b4",
        "components-banner-color-bg-default": "#ebf1f9",
        "components-banner-color-icon-default": "#0053b4",
        "components-banner-color-title-default": "#001936",
        "components-banner-color-body-default": "#373432",
        "components-banner-color-icon-close-default": "#191b1c",
        "components-banner-color-bg-alert": "#b32b30",
        "components-banner-color-icon-alert": "#ffffff",
        "components-banner-color-title-alert": "#ffffff",
        "components-banner-color-body-alert": "#ffffff",
        "components-banner-color-icon-close-alert": "#ffffff",
        "components-breadcrumb-color-bg": "#ffffff",
        "components-breadcrumb-color-breadcrumb-link-default": "#72757e",
        "components-breadcrumb-color-breadcrumb-link-hover": "#4e5056",
        "components-breadcrumb-color-breadcrumb-link-active": "#27282b",
        "components-breadcrumb-color-icon-divider": "#a2a5ab",
        "components-bug-finder-prompt": "#ffffff",
        "components-button-color-filled-brand-default-bg": "#0053b4",
        "components-button-color-filled-brand-default-text": "#ffffff",
        "components-button-color-filled-brand-default-stroke": "#0053b4",
        "components-button-color-filled-brand-default-icon": "#ffffff",
        "components-button-color-filled-brand-hover-bg": "#004697",
        "components-button-color-filled-brand-hover-text": "#ffffff",
        "components-button-color-filled-brand-hover-stroke": "#004697",
        "components-button-color-filled-brand-hover-icon": "#ffffff",
        "components-button-color-filled-brand-pressed-bg": "#00326c",
        "components-button-color-filled-brand-pressed-text": "#ffffff",
        "components-button-color-filled-brand-pressed-stroke": "#00326c",
        "components-button-color-filled-brand-pressed-icon": "#ffffff",
        "components-button-color-filled-brand-disabled-bg": "rgba(0, 83, 180, 0.38)",
        "components-button-color-filled-brand-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-disabled-stroke": "rgba(0, 83, 180, 0.38)",
        "components-button-color-filled-brand-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-focus-bg": "#0053b4",
        "components-button-color-filled-brand-focus-text": "#ffffff",
        "components-button-color-filled-brand-focus-stroke": "#0053b4",
        "components-button-color-filled-brand-focus-icon": "#ffffff",
        "components-button-color-filled-brand-focus-outline": "#286efa",
        "components-button-color-filled-tonal-default-bg": "#ebf1f9",
        "components-button-color-filled-tonal-default-text": "#004697",
        "components-button-color-filled-tonal-default-stroke": "#ebf1f9",
        "components-button-color-filled-tonal-default-icon": "#004697",
        "components-button-color-filled-tonal-hover-bg": "#6698d2",
        "components-button-color-filled-tonal-hover-text": "#00326c",
        "components-button-color-filled-tonal-hover-stroke": "#6698d2",
        "components-button-color-filled-tonal-hover-icon": "#00326c",
        "components-button-color-filled-tonal-pressed-bg": "#6698d2",
        "components-button-color-filled-tonal-pressed-text": "#00326c",
        "components-button-color-filled-tonal-pressed-stroke": "#6698d2",
        "components-button-color-filled-tonal-pressed-icon": "#00326c",
        "components-button-color-filled-tonal-disabled-bg": "rgba(235, 241, 249, 0.38)",
        "components-button-color-filled-tonal-disabled-text": "rgba(0, 70, 151, 0.38)",
        "components-button-color-filled-tonal-disabled-stroke": "rgba(235, 241, 249, 0.38)",
        "components-button-color-filled-tonal-disabled-icon": "rgba(0, 70, 151, 0.38)",
        "components-button-color-filled-tonal-focus-bg": "#ebf1f9",
        "components-button-color-filled-tonal-focus-text": "#004697",
        "components-button-color-filled-tonal-focus-stroke": "#ebf1f9",
        "components-button-color-filled-tonal-focus-icon": "#004697",
        "components-button-color-filled-tonal-focus-outline": "#1c4cae",
        "components-button-color-filled-white-default-bg": "#ffffff",
        "components-button-color-filled-white-default-text": "#191b1c",
        "components-button-color-filled-white-default-stroke": "#ffffff",
        "components-button-color-filled-white-default-icon": "#0053b4",
        "components-button-color-filled-white-hover-bg": "#ebf1f9",
        "components-button-color-filled-white-hover-text": "#191b1c",
        "components-button-color-filled-white-hover-stroke": "#ebf1f9",
        "components-button-color-filled-white-hover-icon": "#004697",
        "components-button-color-filled-white-pressed-bg": "#ebf1f9",
        "components-button-color-filled-white-pressed-text": "#191b1c",
        "components-button-color-filled-white-pressed-stroke": "#ebf1f9",
        "components-button-color-filled-white-pressed-icon": "#004697",
        "components-button-color-filled-white-disabled-bg": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-text": "rgba(25, 27, 28, 0.38)",
        "components-button-color-filled-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-icon": "rgba(0, 83, 180, 0.38)",
        "components-button-color-filled-white-focus-bg": "#ffffff",
        "components-button-color-filled-white-focus-text": "#191b1c",
        "components-button-color-filled-white-focus-stroke": "#ffffff",
        "components-button-color-filled-white-focus-icon": "#0053b4",
        "components-button-color-filled-white-focus-outline": "#1c4cae",
        "components-button-color-outline-brand-default-bg": "rgba(0, 83, 180, 0.00)",
        "components-button-color-outline-brand-default-text": "#0053b4",
        "components-button-color-outline-brand-default-stroke": "#0053b4",
        "components-button-color-outline-brand-default-icon": "#0053b4",
        "components-button-color-outline-brand-hover-bg": "#004697",
        "components-button-color-outline-brand-hover-text": "#ffffff",
        "components-button-color-outline-brand-hover-stroke": "#004697",
        "components-button-color-outline-brand-hover-icon": "#ffffff",
        "components-button-color-outline-brand-pressed-bg": "#00326c",
        "components-button-color-outline-brand-pressed-text": "#ffffff",
        "components-button-color-outline-brand-pressed-stroke": "#00326c",
        "components-button-color-outline-brand-pressed-icon": "#ffffff",
        "components-button-color-outline-brand-disabled-bg": "rgba(0, 83, 180, 0.00)",
        "components-button-color-outline-brand-disabled-text": "rgba(0, 83, 180, 0.38)",
        "components-button-color-outline-brand-disabled-stroke": "rgba(0, 83, 180, 0.38)",
        "components-button-color-outline-brand-disabled-icon": "rgba(0, 83, 180, 0.38)",
        "components-button-color-outline-brand-focus-bg": "#ffffff",
        "components-button-color-outline-brand-focus-text": "#0053b4",
        "components-button-color-outline-brand-focus-stroke": "#0053b4",
        "components-button-color-outline-brand-focus-icon": "#0053b4",
        "components-button-color-outline-brand-focus-outline": "#286efa",
        "components-button-color-outline-tonal-default-bg": "#ffffff",
        "components-button-color-outline-tonal-default-text": "#72757e",
        "components-button-color-outline-tonal-default-stroke": "#72757e",
        "components-button-color-outline-tonal-default-icon": "#72757e",
        "components-button-color-outline-tonal-hover-bg": "#ffffff",
        "components-button-color-outline-tonal-hover-text": "#4e5056",
        "components-button-color-outline-tonal-hover-stroke": "#4e5056",
        "components-button-color-outline-tonal-hover-icon": "#4e5056",
        "components-button-color-outline-tonal-pressed-bg": "#ffffff",
        "components-button-color-outline-tonal-pressed-text": "#4e5056",
        "components-button-color-outline-tonal-pressed-stroke": "#4e5056",
        "components-button-color-outline-tonal-pressed-icon": "#4e5056",
        "components-button-color-outline-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-tonal-disabled-text": "rgba(114, 117, 126, 0.38)",
        "components-button-color-outline-tonal-disabled-stroke": "rgba(114, 117, 126, 0.38)",
        "components-button-color-outline-tonal-disabled-icon": "rgba(114, 117, 126, 0.38)",
        "components-button-color-outline-tonal-focus-bg": "#ffffff",
        "components-button-color-outline-tonal-focus-text": "#72757e",
        "components-button-color-outline-tonal-focus-stroke": "#72757e",
        "components-button-color-outline-tonal-focus-icon": "#72757e",
        "components-button-color-outline-tonal-focus-outline": "#286efa",
        "components-button-color-outline-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-default-text": "#ffffff",
        "components-button-color-outline-white-default-stroke": "#ffffff",
        "components-button-color-outline-white-default-icon": "#ffffff",
        "components-button-color-outline-white-hover-bg": "#ffffff",
        "components-button-color-outline-white-hover-text": "#191b1c",
        "components-button-color-outline-white-hover-stroke": "#ffffff",
        "components-button-color-outline-white-hover-icon": "#0053b4",
        "components-button-color-outline-white-pressed-bg": "#ffffff",
        "components-button-color-outline-white-pressed-text": "#191b1c",
        "components-button-color-outline-white-pressed-stroke": "#ffffff",
        "components-button-color-outline-white-pressed-icon": "#0053b4",
        "components-button-color-outline-white-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-focus-bg": "rgba(255, 255, 255, 0.01)",
        "components-button-color-outline-white-focus-text": "#ffffff",
        "components-button-color-outline-white-focus-stroke": "#ffffff",
        "components-button-color-outline-white-focus-icon": "#ffffff",
        "components-button-color-outline-white-focus-outline": "#286efa",
        "components-button-color-text-brand-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-default-text": "#0053b4",
        "components-button-color-text-brand-default-stroke": "#0053b4",
        "components-button-color-text-brand-default-icon": "#0053b4",
        "components-button-color-text-brand-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-hover-bg-icon-only": "#e8e8ea",
        "components-button-color-text-brand-hover-text": "#004697",
        "components-button-color-text-brand-hover-stroke": "#004697",
        "components-button-color-text-brand-hover-icon": "#004697",
        "components-button-color-text-brand-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-pressed-bg-icon-only": "#e8e8ea",
        "components-button-color-text-brand-pressed-text": "#00326c",
        "components-button-color-text-brand-pressed-stroke": "#00326c",
        "components-button-color-text-brand-pressed-icon": "#00326c",
        "components-button-color-text-brand-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-disabled-text": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-brand-disabled-stroke": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-brand-disabled-icon": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-brand-focus-bg": "#d5e3ff",
        "components-button-color-text-brand-focus-text": "#004697",
        "components-button-color-text-brand-focus-stroke": "#004697",
        "components-button-color-text-brand-focus-icon": "#1c4cae",
        "components-button-color-text-brand-focus-outline": "#1c4cae",
        "components-button-color-text-tonal-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-default-text": "#0053b4",
        "components-button-color-text-tonal-default-stroke": "#0053b4",
        "components-button-color-text-tonal-default-icon": "#0053b4",
        "components-button-color-text-tonal-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-hover-bg-icon-only": "#ebf1f9",
        "components-button-color-text-tonal-hover-text": "#004697",
        "components-button-color-text-tonal-hover-stroke": "#0053b4",
        "components-button-color-text-tonal-hover-icon": "#004697",
        "components-button-color-text-tonal-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-pressed-bg-icon-only": "#ebf1f9",
        "components-button-color-text-tonal-pressed-text": "#004697",
        "components-button-color-text-tonal-pressed-stroke": "#0053b4",
        "components-button-color-text-tonal-pressed-icon": "#004697",
        "components-button-color-text-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-disabled-text": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-tonal-disabled-stroke": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-tonal-disabled-icon": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-tonal-focus-bg": "#d5e3ff",
        "components-button-color-text-tonal-focus-text": "#004697",
        "components-button-color-text-tonal-focus-stroke": "#004697",
        "components-button-color-text-tonal-focus-icon": "#1c4cae",
        "components-button-color-text-tonal-focus-outline": "#1c4cae",
        "components-button-color-text-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-default-text": "#ffffff",
        "components-button-color-text-white-default-stroke": "#ffffff",
        "components-button-color-text-white-default-icon": "#ffffff",
        "components-button-color-text-white-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-hover-bg-icon-only": "#0053b4",
        "components-button-color-text-white-hover-text": "#ffffff",
        "components-button-color-text-white-hover-stroke": "#ffffff",
        "components-button-color-text-white-hover-icon": "#ffffff",
        "components-button-color-text-white-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-pressed-bg-icon-only": "#0053b4",
        "components-button-color-text-white-pressed-text": "#ffffff",
        "components-button-color-text-white-pressed-stroke": "#ffffff",
        "components-button-color-text-white-pressed-icon": "#ffffff",
        "components-button-color-text-white-disabled-bg": "rgba(115, 119, 128, 0.00)",
        "components-button-color-text-white-disabled-text": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-stroke": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-icon": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-focus-bg": "#d5e3ff",
        "components-button-color-text-white-focus-text": "#ffffff",
        "components-button-color-text-white-focus-stroke": "#ffffff",
        "components-button-color-text-white-focus-icon": "#1c4cae",
        "components-button-color-text-white-focus-outline": "#1c4cae",
        "components-button-group-color-border": "#e8e8ea",
        "components-button-group-color-button-bg-default": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-button-fg-default": "#004697",
        "components-button-group-color-button-bg-hover": "#2e72c2",
        "components-button-group-color-button-fg-hover": "#ffffff",
        "components-button-group-color-button-bg-active": "#004697",
        "components-button-group-color-button-fg-active": "#ffffff",
        "components-button-group-color-overlay-left-gradient-fill-1": "#ebf1f9",
        "components-button-group-color-overlay-left-gradient-fill-2": "rgba(235, 241, 249, 0.50)",
        "components-button-group-color-stroke-default-gradient-fill-1": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-2": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-3": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-4": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-1": "#2e72c2",
        "components-button-group-color-stroke-hover-gradient-fill-2": "#2e72c2",
        "components-button-group-color-stroke-hover-gradient-fill-3": "#2e72c2",
        "components-button-group-color-stroke-hover-gradient-fill-4": "#2e72c2",
        "components-button-group-color-stroke-active-gradient-fill-1": "#004697",
        "components-button-group-color-stroke-active-gradient-fill-2": "#004697",
        "components-button-group-color-stroke-active-gradient-fill-3": "#004697",
        "components-button-group-color-stroke-active-gradient-fill-4": "#004697",
        "components-category-card-color-bg": "#ffffff",
        "components-category-card-color-content-bg": "#ffffff",
        "components-category-card-color-category-bg": "#ebf1f9",
        "components-category-card-color-category-bg-2": "#ebf1f9",
        "components-category-card-color-category-bg-3": "#ebf1f9",
        "components-category-card-color-category-bg-4": "#ebf1f9",
        "components-category-card-color-category-bg-5": "#ebf1f9",
        "components-category-card-color-category-bg-6": "#ebf1f9",
        "components-category-card-color-title": "#191b1c",
        "components-category-card-color-copy": "#27282b",
        "components-category-card-color-stroke": "#e8e8ea",
        "components-category-card-color-stroke-hover": "#0053b4",
        "components-category-card-listing-color-bg": "#ffffff",
        "components-category-card-listing-color-title": "#191b1c",
        "components-category-card-listing-color-copy": "#27282b",
        "components-card-search-result-color-bg": "#ffffff",
        "components-card-search-result-color-label": "#27282b",
        "components-card-search-result-color-title": "#191b1c",
        "components-card-search-result-color-body": "#27282b",
        "components-card-search-result-color-divider-line": "#e8e8ea",
        "components-contact-us-promo-color-light-bg": "#ebf1f9",
        "components-contact-us-promo-color-light-title": "#191b1c",
        "components-contact-us-promo-color-light-body": "#27282b",
        "components-contact-us-promo-color-default-bg": "#ffffff",
        "components-contact-us-promo-color-default-title": "#191b1c",
        "components-contact-us-promo-color-default-body": "#27282b",
        "components-contact-us-promo-color-brand-bg": "#0053b4",
        "components-contact-us-promo-color-brand-title": "#ffffff",
        "components-contact-us-promo-color-brand-body": "#fcfcfd",
        "components-content-list-color-bg": "#ffffff",
        "components-content-list-color-title": "#191b1c",
        "components-content-list-color-body": "#27282b",
        "components-content-list-color-icon": "#ff7435",
        "components-cookies-colors-bg": "#ffffff",
        "components-cookies-colors-title": "#191b1c",
        "components-cookies-colors-body": "#27282b",
        "components-cookies-colors-icon": "#004697",
        "components-dropdown-bg": "#ffffff",
        "components-dropdown-border-default": "#72757e",
        "components-dropdown-border-hover": "#0053b4",
        "components-dropdown-border-focused": "#191b1c",
        "components-dropdown-border-destructive": "#b32b30",
        "components-dropdown-label-text-default": "#191b1c",
        "components-dropdown-item-text-default": "#191b1c",
        "components-dropdown-item-bg-default": "#ffffff",
        "components-dropdown-item-bg-hover": "#ebf1f9",
        "components-dropdown-input-field-default": "#72757e",
        "components-dropdown-input-field-focused": "#191b1c",
        "components-dropdown-input-label-focused": "#191b1c",
        "components-dropdown-input-field-destructive": "#b32b30",
        "components-external-link-card-bg": "#ffffff",
        "components-external-link-card-card-bg": "#ffffff",
        "components-external-link-card-card-title": "#191b1c",
        "components-external-link-card-card-subtitle": "#27282b",
        "components-external-link-card-card-eyebrow": "#4e5056",
        "components-fab-color-border-default": "#ffffff",
        "components-footer-brand-color-bg": "#191b1c",
        "components-footer-brand-color-logo": "#ffffff",
        "components-footer-brand-color-title-text": "#ffffff",
        "components-footer-brand-color-description-text": "#fcfcfd",
        "components-footer-brand-color-footer-menu-expanded": "#191b1c",
        "components-footer-brand-color-link-default": "#ffffff",
        "components-footer-brand-color-link-hover": "#ebf1f9",
        "components-footer-brand-color-link-pressed": "#6698d2",
        "components-footer-brand-color-category-link-default": "#ffffff",
        "components-footer-brand-color-category-link-hover": "#fcfcfd",
        "components-footer-brand-color-category-link-pressed": "#e8e8ea",
        "components-footer-brand-color-subcategory-link-default": "#fcfcfd",
        "components-footer-brand-color-subcategory-link-hover": "#ebf1f9",
        "components-footer-brand-color-subcategory-link-pressed": "#2e72c2",
        "components-footer-brand-color-icon-icon-default": "#ffffff",
        "components-footer-brand-color-icon-icon-pressed": "#e8e8ea",
        "components-footer-brand-color-social-icon-default": "#ffffff",
        "components-footer-brand-color-social-icon-hover": "#6698d2",
        "components-footer-brand-color-social-icon-pressed": "#004697",
        "components-footer-brand-spacing-category-link-hover": "#000000",
        "components-footer-global-color-bg": "#ffffff",
        "components-footer-global-color-title-text": "#000000",
        "components-footer-global-color-disclaimer-text": "#000000",
        "components-footer-global-color-link-default": "#000000",
        "components-footer-global-color-link-hover": "#000000",
        "components-footer-global-color-link-pressed": "#000000",
        "components-generic-card-color-bg": "#fcfcfd",
        "components-generic-card-color-content-bg": "#fcfcfd",
        "components-generic-card-color-title": "#191b1c",
        "components-generic-card-color-subtitle": "#27282b",
        "components-generic-card-color-copy": "#27282b",
        "components-global-search-color-omnibox-list-bg": "#ffffff",
        "components-global-search-color-omnibox-divider": "#a2a5ab",
        "components-global-search-color-omnibox-item-bg-default": "#ffffff",
        "components-global-search-color-omnibox-item-bg-hover": "#fcfcfd",
        "components-global-search-color-omnibox-item-text-default": "#0053b4",
        "components-global-search-color-omnibox-item-text-hover": "#004697",
        "components-global-search-color-omnibox-label-text-default": "#191b1c",
        "components-global-search-color-omnibox-category-text-default": "#191b1c",
        "components-generic-card-listing-color-bg": "#ffffff",
        "components-generic-card-listing-color-title": "#191b1c",
        "components-generic-card-listing-color-copy": "#27282b",
        "components-header-color-bg": "#ffffff",
        "components-header-color-bottom-stroke": "#e8e8ea",
        "components-header-color-search-search-border-default": "#191b1c",
        "components-header-color-search-search-text": "#4e5056",
        "components-header-color-search-search-icon": "#0053b4",
        "components-header-color-search-search-bar": "#191b1c",
        "components-header-color-mobile-mobile-expanded-bg": "#ffffff",
        "components-header-color-mobile-pressed-bg": "#ffffff",
        "components-header-color-language-selector-language": "#4e5056",
        "components-header-color-language-selector-country": "#191b1c",
        "components-header-color-language-selector-country-hover-bg": "#fcfcfd",
        "components-header-color-language-selector-megamenu-language": "#4e5056",
        "components-header-color-language-selector-megamenu-country": "#191b1c",
        "components-header-color-language-selector-megamenu-country-hover": "#fcfcfd",
        "components-header-color-language-selector-bg": "#ffffff",
        "components-header-color-language-selector-stroke": "#72757e",
        "components-header-color-mega-menu-bg": "#ebf1f9",
        "components-header-color-mega-menu-featured-bg": "#004697",
        "components-header-color-mega-menu-featured-title": "#ffffff",
        "components-header-color-mega-menu-category-title": "#27282b",
        "components-header-color-mega-menu-bg-accent": "#ffffff",
        "components-header-color-icon-icon-default": "#191b1c",
        "components-header-color-icon-icon-hover": "#004697",
        "components-header-color-icon-icon-pressed": "#00326c",
        "components-header-color-links-nav-main-link-default": "#191b1c",
        "components-header-color-links-nav-main-link-hover": "#0053b4",
        "components-header-color-links-nav-main-link-pressed": "#004697",
        "components-header-color-links-nav-main-link-active": "#00326c",
        "components-header-color-links-nav-main-active-bar": "#0053b4",
        "components-header-color-links-nav-main-bg-color": "#ffffff",
        "components-header-color-links-nav-category-link-category-default": "#27282b",
        "components-header-color-links-nav-category-link-category-hover": "#004697",
        "components-header-color-links-nav-category-link-category-pressed": "#004697",
        "components-header-color-links-nav-category-link-category-active": "#00326c",
        "components-header-color-links-nav-menu-link-link-default": "#191b1c",
        "components-header-color-links-nav-menu-link-link-hover": "#004697",
        "components-header-color-links-nav-menu-link-link-pressed": "#004697",
        "components-header-color-links-nav-menu-link-link-active": "#00326c",
        "components-header-color-links-nav-menu-link-bg": "#ebf1f9",
        "components-header-color-links-nav-subcategory-link-subcategory-default": "#27282b",
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": "#004697",
        "components-header-color-links-nav-subcategory-link-subcategory-active": "#00326c",
        "components-hero-homepage-color-bg": "#fcfcfd",
        "components-hero-homepage-color-container-bg": "#8cc6e7",
        "components-hero-homepage-color-eyebrow": "#72757e",
        "components-hero-homepage-color-header": "#00326c",
        "components-hero-homepage-color-sub-header": "#27282b",
        "components-hero-homepage-color-body": "#27282b",
        "components-hero-homepage-color-play-pause": "#000000",
        "components-hero-internal-color-default-title": "#ffffff",
        "components-hero-internal-color-default-body": "#fcfcfd",
        "components-hero-internal-color-default-bg": "#004697",
        "components-hero-internal-color-accent-bg": "#00326c",
        "components-hero-internal-color-with-image-bg": "#004697",
        "components-hero-internal-color-default-bg-2": "#004697",
        "components-hero-internal-color-with-image-title": "#ffffff",
        "components-hero-internal-color-with-image-body": "#fcfcfd",
        "components-heading-page-title-color-default-bg": "#ffffff",
        "components-heading-page-title-color-default-title": "#191b1c",
        "components-heading-page-title-color-default-body": "#27282b",
        "components-heading-page-title-color-light-bg": "#ebf1f9",
        "components-heading-page-title-color-light-title": "#191b1c",
        "components-heading-page-title-color-light-body": "#27282b",
        "components-heading-page-title-color-color-bg": "#191b1c",
        "components-heading-page-title-color-color-title": "#ffffff",
        "components-heading-page-title-color-color-body": "#fcfcfd",
        "components-hyperlink-color-dark-default": "#0053b4",
        "components-hyperlink-color-dark-hover": "#004697",
        "components-hyperlink-color-dark-visited": "#865ddf",
        "components-hyperlink-color-light-default": "#ebf1f9",
        "components-hyperlink-color-light-hover": "#2e72c2",
        "components-hyperlink-color-light-visited": "#b89fec",
        "components-icon-default": "#72757e",
        "components-icon-focused": "#72757e",
        "components-icon-card-color-bg": "#ffffff",
        "components-icon-card-color-icon-bg": "#191b1c",
        "components-icon-card-color-icon": "#ffffff",
        "components-icon-card-color-title": "#191b1c",
        "components-icon-card-color-subtitle": "#4e5056",
        "components-icon-card-color-description": "#27282b",
        "components-icon-card-listing-color-bg": "#ffffff",
        "components-icon-card-listing-color-inner-bg": "#ffffff",
        "components-icon-card-listing-color-brand-bg": "#004697",
        "components-icon-card-listing-color-title": "#191b1c",
        "components-icon-card-listing-color-copy": "#27282b",
        "components-icon-card-listing-color-inner-legal-text": "#fcfcfd",
        "components-jumplink-color-bg-default": "#ffffff",
        "components-jumplink-color-link-text-default": "#4e5056",
        "components-jumplink-color-link-text-hover": "#00326c",
        "components-jumplink-color-link-border-hover": "#00326c",
        "components-jumplink-color-link-text-active": "#0053b4",
        "components-jumplink-color-link-border-active": "#0053b4",
        "components-jumplink-color-title-text": "#191b1c",
        "components-jumplink-color-description-text": "#27282b",
        "components-layout-bg-default": "#ffffff",
        "components-lightbox-color-overlay-bg": "rgba(235, 241, 249, 0.95)",
        "components-lightbox-color-title": "#191b1c",
        "components-lightbox-color-body": "#27282b",
        "components-label-color-on-color-label-label-default": "#ffffff",
        "components-label-color-on-color-label-label-hover": "#4e5056",
        "components-label-color-on-color-label-label-pressed": "#27282b",
        "components-label-color-on-color-background-bg-default": "#72757e",
        "components-label-color-on-color-background-bg-hover": "#ffffff",
        "components-label-color-on-color-background-bg-pressed": "#e8e8ea",
        "components-label-color-on-color-stroke-hover": "#ffffff",
        "components-label-color-on-color-stroke-pressed": "#e8e8ea",
        "components-label-color-on-color-stroke-default-gradient-fill-1": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-2": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-3": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-4": "#ffffff",
        "components-label-color-on-white-label-label-default": "#4e5056",
        "components-label-color-on-white-label-label-hover": "#ffffff",
        "components-label-color-on-white-label-label-pressed": "#ffffff",
        "components-label-color-on-white-background-bg-default": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-white-background-bg-hover": "#72757e",
        "components-label-color-on-white-background-bg-pressed": "#4e5056",
        "components-label-color-on-white-stroke-hover": "#72757e",
        "components-label-color-on-white-stroke-pressed": "#4e5056",
        "components-label-color-on-white-stroke-default-gradient-fill-1": "#72757e",
        "components-label-color-on-white-stroke-default-gradient-fill-2": "#72757e",
        "components-label-color-on-white-stroke-default-gradient-fill-3": "#72757e",
        "components-label-color-on-white-stroke-default-gradient-fill-4": "#72757e",
        "components-legal-disclaimer-color-default-bg": "#ffffff",
        "components-legal-disclaimer-color-default-title": "#191b1c",
        "components-legal-disclaimer-color-default-body": "#27282b",
        "components-legal-disclaimer-color-light-bg": "#ebf1f9",
        "components-legal-disclaimer-color-light-title": "#191b1c",
        "components-legal-disclaimer-color-light-body": "#27282b",
        "components-legal-disclaimer-color-brand-bg": "#191b1c",
        "components-legal-disclaimer-color-brand-title": "#ffffff",
        "components-legal-disclaimer-color-brand-body": "#fcfcfd",
        "components-media-video-thumbnail-overlay-bg": "rgba(25, 27, 28, 0.80)",
        "components-media-caption-color-bg": "#fcfcfd",
        "components-media-caption-color-text": "#27282b",
        "components-media-gallery-color-bg": "#ffffff",
        "components-media-gallery-color-title": "#191b1c",
        "components-media-gallery-color-body": "#27282b",
        "components-pagination-on-white-accent-default": "#27282b",
        "components-pagination-on-white-accent-scroll": "#b33900",
        "components-pagination-on-white-disabled": "#a2a5ab",
        "components-pagination-on-white-bg": "#fff1ea",
        "components-pagination-on-white-stroke": "#e8e8ea",
        "components-pagination-on-white-bg-active": "#0053b4",
        "components-pagination-on-white-bg-hover": "#e8e8ea",
        "components-pagination-on-white-text-default": "#72757e",
        "components-pagination-on-white-text-hover": "#27282b",
        "components-pagination-on-white-text-active": "#ffffff",
        "components-pagination-on-color-accent-default": "#ffffff",
        "components-pagination-on-color-accent-scroll": "#27282b",
        "components-pagination-on-color-disabled": "#001936",
        "components-pagination-on-color-bg": "#e8e8ea",
        "components-pagination-on-color-stroke": "rgba(255, 255, 255, 0.38)",
        "components-pagination-on-color-bg-active": "#ffffff",
        "components-pagination-on-color-bg-hover": "#27282b",
        "components-pagination-on-color-text-default": "#ffffff",
        "components-pagination-on-color-text-hover": "#ffffff",
        "components-pagination-on-color-text-active": "#191b1c",
        "components-pdf-link-card-color-bg": "#ffffff",
        "components-pdf-link-card-color-card-bg": "#ffffff",
        "components-pdf-link-card-color-card-title": "#191b1c",
        "components-pdf-link-card-color-card-subtitle": "#27282b",
        "components-pdf-link-card-color-card-body": "#27282b",
        "components-product-card-color-bg": "#fcfcfd",
        "components-product-card-color-content-bg": "#fcfcfd",
        "components-product-card-color-border": "#e8e8ea",
        "components-product-card-color-title": "#191b1c",
        "components-product-card-color-description": "#27282b",
        "components-product-card-color-star-rating": "#d44300",
        "components-product-card-color-badge-bg": "#d44300",
        "components-product-card-color-badge-text": "#ffffff",
        "components-product-card-listing-color-bg": "#ffffff",
        "components-product-card-listing-color-title": "#191b1c",
        "components-product-card-listing-color-copy": "#27282b",
        "components-product-card-listing-color-legal-text": "#27282b",
        "components-product-information-color-bg": "#ffffff",
        "components-product-information-color-content-bg": "#ffffff",
        "components-product-information-color-headline": "#191b1c",
        "components-product-information-color-body": "#27282b",
        "components-product-information-color-detail-text": "#27282b",
        "components-product-information-color-divider": "#a2a5ab",
        "components-product-information-color-icon": "#004697",
        "components-product-information-color-play-icon": "#ffffff",
        "components-product-information-color-selected-stroke": "#0053b4",
        "components-product-results-list-with-search-color-bg": "#ffffff",
        "components-promo-color-default-bg": "#ffffff",
        "components-promo-color-default-image-bg": "#ffffff",
        "components-promo-color-default-content-bg": "#ffffff",
        "components-promo-color-default-title": "#191b1c",
        "components-promo-color-default-body": "#27282b",
        "components-promo-color-default-category": "#191b1c",
        "components-promo-color-light-bg": "#ebf1f9",
        "components-promo-color-light-image-bg": "#ebf1f9",
        "components-promo-color-light-content-bg": "#ebf1f9",
        "components-promo-color-light-title": "#191b1c",
        "components-promo-color-light-body": "#27282b",
        "components-promo-color-light-category": "#191b1c",
        "components-promo-color-brand-bg": "#0053b4",
        "components-promo-color-brand-image-bg": "#0053b4",
        "components-promo-color-brand-content-bg": "#0053b4",
        "components-promo-color-brand-title": "#ffffff",
        "components-promo-color-brand-body": "#fcfcfd",
        "components-promo-color-brand-category": "#ffffff",
        "components-pull-quote-color-bg": "#fcfcfd",
        "components-pull-quote-color-stroke": "#0053b4",
        "components-pull-quote-color-quote-bg": "#ffffff",
        "components-pull-quote-color-quote-title": "#191b1c",
        "components-pull-quote-color-quote-by-text": "#27282b",
        "components-pull-quote-color-quote-detail": "#72757e",
        "components-pull-quote-color-quote-highlight": "#d44300",
        "components-promo-alternate-color-default-bg": "#ffffff",
        "components-promo-alternate-color-default-image-bg": "#ffffff",
        "components-promo-alternate-color-default-content-bg": "#ffffff",
        "components-promo-alternate-color-default-title": "#191b1c",
        "components-promo-alternate-color-default-body": "#27282b",
        "components-promo-alternate-color-default-category": "#191b1c",
        "components-promo-alternate-color-light-bg": "#ebf1f9",
        "components-promo-alternate-color-light-image-bg": "#ebf1f9",
        "components-promo-alternate-color-light-content-bg": "#ebf1f9",
        "components-promo-alternate-color-light-title": "#191b1c",
        "components-promo-alternate-color-light-body": "#27282b",
        "components-promo-alternate-color-light-category": "#191b1c",
        "components-promo-alternate-color-brand-bg": "#0053b4",
        "components-promo-alternate-color-brand-image-bg": "#0053b4",
        "components-promo-alternate-color-brand-content-bg": "#0053b4",
        "components-promo-alternate-color-brand-title": "#ffffff",
        "components-promo-alternate-color-brand-body": "#fcfcfd",
        "components-promo-alternate-color-brand-category": "#ffffff",
        "components-share-list-color-title": "#27282b",
        "components-share-list-color-bg": "#fcfcfd",
        "components-share-list-color-link-default": "#27282b",
        "components-share-list-color-link-hover": "#0053b4",
        "components-share-list-color-bg-link-hover": "#e8e8ea",
        "components-share-list-color-close-icon": "#27282b",
        "components-share-list-color-facebook-icon": "#3b5998",
        "components-share-list-color-pinterest-icon": "#e60023",
        "components-share-list-color-twitter-icon": "#000000",
        "components-share-list-color-basic-icon-default": "#27282b",
        "components-share-list-color-icon-hover": "#004697",
        "components-share-list-color-border": "#e8e8ea",
        "components-site-search-bg": "#ffffff",
        "components-site-search-results_text": "#27282b",
        "components-site-search-filter-filter-selected-bg": "#ebf1f9",
        "components-site-search-facet-group-label-bottom-line": "#e8e8ea",
        "components-site-search-facet-group-label-text": "#27282b",
        "components-site-search-facet-group-label-bg": "#ffffff",
        "components-site-search-facet-bg": "#ffffff",
        "components-site-search-facet-stroke": "#e8e8ea",
        "components-site-search-refine-bg": "#ffffff",
        "components-site-search-refine-right-divider": "#ebf1f9",
        "components-site-search-refine-title": "#191b1c",
        "components-site-search-hero-search-bg": "#004697",
        "components-site-search-hero-search-accent-bg": "#00326c",
        "components-site-search-hero-search-title": "#ffffff",
        "components-stats-stat-text": "#004697",
        "components-stats-stat-text-on-light": "#004697",
        "components-stats-supporting-text": "#27282b",
        "components-stats-bg-default": "#ffffff",
        "components-stats-bg-inner": "#ffffff",
        "components-stats-bg-section-inner": "#004697",
        "components-stats-title": "#191b1c",
        "components-stats-copy": "#27282b",
        "components-supporting-text-default": "#4e5056",
        "components-supporting-text-destructive": "#b32b30",
        "components-tab-bg": "#ffffff",
        "components-tab-border-default": "#72757e",
        "components-tab-border-hover": "#0053b4",
        "components-tab-border-focused": "#191b1c",
        "components-tab-border-destructive": "#b32b30",
        "components-tab-label-text-default": "#191b1c",
        "components-tab-item-text-default": "#191b1c",
        "components-tab-item-bg-default": "#ffffff",
        "components-tab-item-bg-hover": "#ebf1f9",
        "components-tab-input-field-default": "#72757e",
        "components-tab-input-field-focused": "#191b1c",
        "components-tab-input-label-focused": "#191b1c",
        "components-tab-input-field-destructive": "#b32b30",
        "components-tab-bg-2": "#ffffff",
        "components-tag-color-bg": "#ffffff",
        "components-tag-color-text": "#0053b4",
        "components-tag-color-stroke": "#0053b4",
        "components-tag-color-bg-hover": "#004697",
        "components-tag-color-text-hover": "#ffffff",
        "components-tag-color-stroke-hover": "#004697",
        "components-text-field-bg": "#fcfcfd",
        "components-text-field-bg-focused": "#ebf1f9",
        "components-text-field-border-default": "#191b1c",
        "components-text-field-border-hover": "#0053b4",
        "components-text-field-border-focused": "#191b1c",
        "components-text-field-border-destructive": "#b32b30",
        "components-text-field-input-field-default": "#4e5056",
        "components-text-field-input-field-focused": "#191b1c",
        "components-text-field-input-label-focused": "#191b1c",
        "components-text-field-input-field-destructive": "#b32b30",
        "components-text-field-icon-default": "#72757e",
        "components-text-field-icon-destructive": "#b32b30",
        "components-text-field-supporting-text-default": "#4e5056",
        "components-text-field-supporting-text-destructive": "#b32b30",
        "components-tip-color-bg": "#ebf1f9",
        "components-tip-color-title": "#191b1c",
        "components-tip-color-body": "#27282b",
        "components-tip-color-stroke": "#6698d2",
        "components-video-color-bg": "#ffffff",
        "themes-general-surface-color-light-bg": "#ffffff",
        "themes-general-surface-color-light-title": "#191b1c",
        "themes-general-surface-color-light-body": "#27282b",
        "themes-general-surface-color-light-decor-line-break": "#6698d2",
        "themes-general-surface-color-light-rule-line": "#6698d2",
        "themes-general-surface-color-dark-bg": "#0053b4",
        "themes-general-surface-color-dark-title": "#ffffff",
        "themes-general-surface-color-dark-body": "#fcfcfd",
        "themes-general-surface-color-dark-decor-line-break": "#6698d2",
        "themes-general-card-color-dark-title": "#191b1c",
        "themes-general-card-color-dark-subtitle": "#27282b",
        "themes-general-card-color-dark-body": "#27282b",
        "themes-general-card-color-light-title": "#ffffff",
        "themes-general-card-color-light-subtitle": "#fcfcfd",
        "themes-general-card-color-light-body": "#fcfcfd",
        "themes-shadow-color": "#ffffff",
        "themes-shadow-share-list-color-fill": "rgba(0, 0, 0, 0.40)",
        "themes-shadow-header-menu-color-fill": "rgba(0, 0, 0, 0.20)",
        "themes-gradient-loader-gradient-fill-1": "#0053b4",
        "themes-gradient-loader-gradient-fill-2": "rgba(0, 83, 180, 0.00)",
        "themes-color-background-white": "#ffffff",
        "themes-color-background-light": "#ebf1f9",
        "themes-color-background-dark": "#27282b",
        "themes-color-background-black": "#191b1c",
        "themes-color-background-brand1": "#004697",
        "themes-color-background-brand2": "#d44300",
        "themes-color-background-accent1": "#286636",
        "themes-color-background-accent2": "#9976e4",
        "themes-color-background-body": "#ffffff",
        "themes-color-stroke-white": "#ffffff",
        "themes-color-stroke-light": "#e8e8ea",
        "themes-color-stroke-dark": "#27282b",
        "themes-color-stroke-black": "#191b1c",
        "themes-color-stroke-brand1": "#0053b4",
        "themes-color-stroke-brand2": "#d44300",
        "themes-color-stroke-accent1": "#286636",
        "themes-color-stroke-accent2": "#9976e4",
        "themes-color-text-white": "#ffffff",
        "themes-color-text-light": "#fcfcfd",
        "themes-color-text-dark": "#27282b",
        "themes-color-text-black": "#191b1c",
        "themes-color-text-brand1": "#0053b4",
        "themes-color-text-brand2": "#d44300",
        "themes-color-text-accent1": "#183d20",
        "themes-color-text-accent2": "#2f1568",
        "colors-grayscale-100": "#fcfcfd",
        "colors-grayscale-200": "#e8e8ea",
        "colors-grayscale-300": "#a2a5ab",
        "colors-grayscale-400": "#72757e",
        "colors-grayscale-500": "#4e5056",
        "colors-grayscale-600": "#27282b",
        "colors-grayscale-700": "#191b1c",
        "colors-brand-1-100": "#ebf1f9",
        "colors-brand-1-200": "#6698d2",
        "colors-brand-1-300": "#2e72c2",
        "colors-brand-1-400": "#0053b4",
        "colors-brand-1-500": "#004697",
        "colors-brand-1-600": "#00326c",
        "colors-brand-1-700": "#001936",
        "colors-brand-2-100": "#fdeeee",
        "colors-brand-2-200": "#f3a9a8",
        "colors-brand-2-300": "#eb6867",
        "colors-brand-2-400": "#e22726",
        "colors-brand-2-500": "#c41b1a",
        "colors-brand-2-600": "#8c1313",
        "colors-brand-2-700": "#410909",
        "colors-accent-1-100": "#eaf7ed",
        "colors-accent-1-200": "#95d6a4",
        "colors-accent-1-300": "#47b560",
        "colors-accent-1-400": "#286636",
        "colors-accent-1-500": "#22562d",
        "colors-accent-1-600": "#183d20",
        "colors-accent-1-700": "#0e2513",
        "colors-accent-2-100": "#f7f4fd",
        "colors-accent-2-200": "#d6c8f4",
        "colors-accent-2-300": "#b89fec",
        "colors-accent-2-400": "#9976e4",
        "colors-accent-2-500": "#7748db",
        "colors-accent-2-600": "#4920a2",
        "colors-accent-2-700": "#2f1568",
        "colors-accent-3-100": "#eefaec",
        "colors-accent-3-200": "#a9e8a0",
        "colors-accent-3-300": "#68d759",
        "colors-accent-3-400": "#3aae2a",
        "colors-accent-3-500": "#319223",
        "colors-accent-3-600": "#236819",
        "colors-accent-3-700": "#153f0f",
        "colors-accent-4-400_storage": "#00aed6",
        "colors-accessible-accent-1-100": "#f5f2fc",
        "colors-accessible-accent-1-200": "#cfbff2",
        "colors-accessible-accent-1-300": "#ab8fe9",
        "colors-accessible-accent-1-400": "#865ddf",
        "colors-accessible-accent-1-500": "#6d3ad8",
        "colors-accessible-accent-1-600": "#4c21a9",
        "colors-accessible-accent-1-700": "#341672",
        "colors-accessible-accent-2-100": "#e7f8e4",
        "colors-accessible-accent-2-200": "#7ddc6f",
        "colors-accessible-accent-2-300": "#3cb42c",
        "colors-accessible-accent-2-400": "#2d8721",
        "colors-accessible-accent-2-500": "#246d1a",
        "colors-accessible-accent-2-600": "#1b5214",
        "colors-accessible-accent-2-700": "#11340c",
        "colors-accessible-brand-2-100": "#fff1ea",
        "colors-accessible-brand-2-200": "#ffb694",
        "colors-accessible-brand-2-300": "#ff7435",
        "colors-accessible-brand-2-400": "#d44300",
        "colors-accessible-brand-2-500": "#b33900",
        "colors-accessible-brand-2-600": "#812900",
        "colors-accessible-brand-2-700": "#431500"
      },
      "spacing": {
        "components-accordion-spacing-large-padding-x": "5rem",
        "components-accordion-spacing-large-icon-margin-right": "2rem",
        "components-accordion-spacing-large-copy-margin-right": "2.5rem",
        "components-accordion-spacing-large-maxwidth": "59.625rem",
        "components-accordion-spacing-large-section-padding-x": "0rem",
        "components-accordion-spacing-large-section-padding-y": "2.5rem",
        "components-accordion-spacing-large-section-copy-margin-bottom": "2.5rem",
        "components-accordion-spacing-large-section-space-between": "4rem",
        "components-accordion-spacing-large-item-padding-x": "0rem",
        "components-accordion-spacing-large-item-padding-y": "1.5rem",
        "components-accordion-spacing-large-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-large-content-padding-x": "0rem",
        "components-accordion-spacing-large-content-padding-y": "2.5rem",
        "components-accordion-spacing-large-content-title-margin-bottom": "2.5rem",
        "components-accordion-spacing-large-content-content-space-between": "4rem",
        "components-accordion-spacing-large-content-content-margin-left": "1rem",
        "components-accordion-spacing-small-padding-top": "1rem",
        "components-accordion-spacing-small-padding-x": "1.5rem",
        "components-accordion-spacing-small-padding-y": "2.5rem",
        "components-accordion-spacing-small-icon-margin-right": "0.75rem",
        "components-accordion-spacing-small-title-margin-bottom": "0.5rem",
        "components-accordion-spacing-small-copy-margin-right": "2rem",
        "components-accordion-spacing-small-copy-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-padding-x": "0rem",
        "components-accordion-spacing-small-section-padding-y": "0rem",
        "components-accordion-spacing-small-section-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-space-between": "2.5rem",
        "components-accordion-spacing-small-item-padding-x": "0rem",
        "components-accordion-spacing-small-item-padding-y": "1rem",
        "components-accordion-spacing-small-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-small-content-padding-x": "0rem",
        "components-accordion-spacing-small-content-padding-y": "0.75rem",
        "components-accordion-spacing-small-content-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-content-content-space-between": "1rem",
        "components-accordion-spacing-small-content-content-margin-left": "1rem",
        "components-article-card-spacing-large-padding-image-x": "0rem",
        "components-article-card-spacing-large-padding-image-y": "0rem",
        "components-article-card-spacing-large-padding-content-x": "1.5rem",
        "components-article-card-spacing-large-padding-content-y": "1.5rem",
        "components-article-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-article-card-spacing-large-button-space-between": "1rem",
        "components-article-card-spacing-large-title-margin-bottom": "1rem",
        "components-article-card-spacing-large-list-content-gap": "0rem",
        "components-article-card-spacing-large-list-image-padding-x": "0rem",
        "components-article-card-spacing-large-list-image-padding-y": "0rem",
        "components-article-card-spacing-small-padding-image-x": "0rem",
        "components-article-card-spacing-small-padding-image-y": "0rem",
        "components-article-card-spacing-small-padding-content-x": "1.5rem",
        "components-article-card-spacing-small-padding-content-y": "1.5rem",
        "components-article-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-article-card-listing-spacing-small-padding-x": "1.5rem",
        "components-article-card-listing-spacing-small-padding-y": "2.5rem",
        "components-article-hero-spacing-large-padding-x": "0rem",
        "components-article-hero-spacing-large-padding-y": "0rem",
        "components-article-hero-spacing-large-title-margin-bottom": "2rem",
        "components-article-hero-spacing-large-label-share-margin-bottom": "2rem",
        "components-article-hero-spacing-large-author-name-margin-bottom": "0.75rem",
        "components-article-hero-spacing-large-author-thumbnail-margin-left": "1rem",
        "components-article-hero-spacing-large-details-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-space-between": "1rem",
        "components-article-hero-spacing-large-body-margin-bottom": "2rem",
        "components-article-hero-spacing-large-image-space-between": "1rem",
        "components-article-hero-spacing-large-image-margin-bottom": "1rem",
        "components-article-hero-spacing-large-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-stats-margin-bottom": "2.5rem",
        "components-article-hero-spacing-small-content-margin-top": "2.5rem",
        "components-article-hero-spacing-small-title-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-label-share-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-authorname-margin-bottom": "0.75rem",
        "components-article-hero-spacing-small-authorthumbnail-margin-left": "1rem",
        "components-article-hero-spacing-small-details-margin-bottom": "1rem",
        "components-article-hero-spacing-small-space-between": "0.75rem",
        "components-article-hero-spacing-small-body-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-image-space-between": "0.75rem",
        "components-article-hero-spacing-small-image-margin-bottom": "1rem",
        "components-article-hero-spacing-small-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-stats-margin-bottom": "2rem",
        "components-banner-spacing-large-content-padding-x": "1.5rem",
        "components-banner-spacing-large-content-space-between": "1rem",
        "components-banner-spacing-large-content-width": "69.375rem",
        "components-banner-spacing-icon-dimensions": "1.5rem",
        "components-banner-spacing-icon-margin-left": "0.5rem",
        "components-banner-spacing-small-space-between": "1rem",
        "components-banner-spacing-small-content-space-between": "1rem",
        "components-banner-spacing-small-title-margin-bottom": "0.75rem",
        "components-banner-spacing-small-content-margin-bottom": "1rem",
        "components-breadcrumb-spacing-large-space-between": "0.25rem",
        "components-breadcrumb-spacing-small-space-between": "0.25rem",
        "components-button-spacing-large-icon-large-x": "2.5rem",
        "components-button-spacing-large-icon-large-y": "2.5rem",
        "components-button-spacing-large-default-padding-y": "0.75rem",
        "components-button-spacing-large-default-padding-x": "1.5rem",
        "components-button-spacing-large-default-space-between": "0.25rem",
        "components-button-spacing-large-icon-left-padding-left": "1rem",
        "components-button-spacing-large-icon-right-padding-right": "1rem",
        "components-button-spacing-large-icon-only-padding-x": "0.75rem",
        "components-button-spacing-large-icon-only-padding-y": "0.75rem",
        "components-button-spacing-large-tertiary-content-height": "1.5rem",
        "components-button-spacing-compressed-icon-large-x": "2.5rem",
        "components-button-spacing-compressed-icon-large-y": "2.5rem",
        "components-button-spacing-compressed-default-padding-y": "0.5rem",
        "components-button-spacing-compressed-default-padding-x": "1rem",
        "components-button-spacing-compressed-default-space-between": "0.125rem",
        "components-button-spacing-compressed-icon-left-padding-left": "0.5rem",
        "components-button-spacing-compressed-icon-right-padding-right": "0.5rem",
        "components-button-spacing-compressed-icon-only-padding-x": "0.5rem",
        "components-button-group-spacing-padding-y": "0.125rem",
        "components-button-group-spacing-padding-x": "0.25rem",
        "components-button-group-spacing-space-between": "0.5rem",
        "components-category-card-spacing-large-padding-top": "0rem",
        "components-category-card-spacing-large-padding-x": "0rem",
        "components-category-card-spacing-large-padding-y": "0rem",
        "components-category-card-spacing-large-padding-image-x": "0rem",
        "components-category-card-spacing-large-padding-image-y": "0rem",
        "components-category-card-spacing-large-padding-content-x": "2rem",
        "components-category-card-spacing-large-padding-content-y": "2rem",
        "components-category-card-listing-spacing-large-padding-y": "5rem",
        "components-category-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-category-card-listing-spacing-large-card-margin-bottom": "0rem",
        "components-category-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-card-search-result-small-padding-top": "0rem",
        "components-card-search-result-small-padding-x": "0rem",
        "components-card-search-result-small-padding-y": "0rem",
        "components-card-search-result-small-padding-image-x": "0rem",
        "components-card-search-result-small-padding-image-y": "0rem",
        "components-card-search-result-small-padding-content-x": "1.5rem",
        "components-card-search-result-small-padding-content-y": "1.5rem",
        "components-card-search-result-spacing-large-padding-x": "0rem",
        "components-card-search-result-spacing-large-padding-y": "1.5rem",
        "components-card-search-result-spacing-large-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-image-margin-right": "1.5rem",
        "components-card-search-result-spacing-small-padding-x": "1.5rem",
        "components-card-search-result-spacing-small-padding-y": "1.5rem",
        "components-card-search-result-spacing-small-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-image-margin-right": "0.75rem",
        "components-contact-us-promo-spacing-large-padding-x": "5rem",
        "components-contact-us-promo-spacing-large-padding-y": "5rem",
        "components-contact-us-promo-spacing-large-content-margin-right": "5rem",
        "components-contact-us-promo-spacing-large-margin-y": "2.5rem",
        "components-contact-us-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-contact-us-promo-spacing-small-margin-y": "2.5rem",
        "components-content-list-list-item-container-padding-x": "0.75rem",
        "components-content-list-list-item-container-padding-y": "1rem",
        "components-content-list-list-item-content-space-between": "0.5rem",
        "components-content-list-list-item-content-default-padding-y": "0.25rem",
        "components-content-list-list-item-content-hyperlink-padding-y": "0rem",
        "components-content-list-spacing-large-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-large-item-title-margin-bottom": "1rem",
        "components-content-list-spacing-large-item-copy-margin-bottom": "2.5rem",
        "components-content-list-spacing-large-item-icon-copy-margin-top": "0rem",
        "components-content-list-spacing-large-item-margin-bottom": "2rem",
        "components-content-list-spacing-large-item-image-margin-bottom": "0.5rem",
        "components-content-list-spacing-large-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-y": "2.5rem",
        "components-content-list-spacing-small-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-small-item-title-margin-bottom": "0.5rem",
        "components-content-list-spacing-small-item-copy-margin-bottom": "1rem",
        "components-content-list-spacing-small-item-margin-bottom": "1.5rem",
        "components-content-list-spacing-small-header-title-margin-bottom": "1rem",
        "components-content-list-spacing-small-header-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-title-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-button-space-between": "1rem",
        "components-dropdown-item-padding-y": "0.75rem",
        "components-dropdown-item-padding-x": "0.75rem",
        "components-dropdown-item-spacing-x": "0.75rem",
        "components-dropdown-input-padding-y": "0.25rem",
        "components-dropdown-input-padding-x": "1rem",
        "components-footer-brand-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-large-column-margin-right": "4rem",
        "components-footer-brand-spacing-large-column-margin-bottom": "4rem",
        "components-footer-brand-spacing-large-link-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-padding-y": "2.5rem",
        "components-footer-brand-spacing-small-padding-x": "1rem",
        "components-footer-brand-spacing-small-logo-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-column-margin-right": "0.125rem",
        "components-footer-brand-spacing-small-column-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-small-link-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-title-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-text-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-footer-link-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-link-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-link-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-link-minheight": "3rem",
        "components-footer-brand-spacing-small-footer-category-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-category-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-category-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-category-minheight": "3rem",
        "components-footer-global-spacing-large-padding-y": "2.5rem",
        "components-footer-global-spacing-large-padding-x": "5rem",
        "components-footer-global-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-global-spacing-large-column-margin-right": "2rem",
        "components-footer-global-spacing-large-link-margin-bottom": "2rem",
        "components-footer-global-spacing-small-padding-y": "2.5rem",
        "components-footer-global-spacing-small-padding-x": "1rem",
        "components-footer-global-spacing-small-logo-margin-bottom": "1rem",
        "components-footer-global-spacing-small-column-margin-right": "1rem",
        "components-footer-global-spacing-small-link-margin-bottom": "1rem",
        "components-generic-card-spacing-large-padding-x": "0rem",
        "components-generic-card-spacing-large-padding-y": "0rem",
        "components-generic-card-spacing-large-padding-image-x": "0rem",
        "components-generic-card-spacing-large-padding-image-y": "0rem",
        "components-generic-card-spacing-large-padding-content-x": "2rem",
        "components-generic-card-spacing-large-padding-content-y": "2rem",
        "components-generic-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-generic-card-spacing-large-title-margin-bottom": "1rem",
        "components-generic-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-button-space-between": "1rem",
        "components-generic-card-spacing-small-padding-x": "0rem",
        "components-generic-card-spacing-small-padding-y": "0rem",
        "components-generic-card-spacing-small-padding-image-x": "0rem",
        "components-generic-card-spacing-small-padding-image-y": "0rem",
        "components-generic-card-spacing-small-padding-content-x": "1.5rem",
        "components-generic-card-spacing-small-padding-content-y": "1.5rem",
        "components-global-search-spacing-omnibox-padding-x": "0.125rem",
        "components-global-search-spacing-omnibox-list-padding-y": "0.5rem",
        "components-global-search-spacing-omnibox-label-padding": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-item-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-height": "3rem",
        "components-generic-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-generic-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-header-shadow-x": "0rem",
        "components-header-spacing-large-padding-y": "1rem",
        "components-header-spacing-large-padding-x": "1.5rem",
        "components-header-spacing-large-links-margin-right": "1.5rem",
        "components-header-spacing-large-icons-space-between": "1.5rem",
        "components-header-spacing-large-logo-margin-right": "2rem",
        "components-header-spacing-large-space-between-search": "0.5rem",
        "components-header-spacing-large-height": "6rem",
        "components-header-spacing-large-language-selector-padding-x": "1rem",
        "components-header-spacing-large-language-selector-padding-y": "0.5rem",
        "components-header-spacing-large-language-selector-space-between": "0.75rem",
        "components-header-spacing-large-language-selector-country-space-between": "0.5rem",
        "components-header-spacing-large-mega-menu-padding-y": "2.5rem",
        "components-header-spacing-large-mega-menu-padding-x": "2.5rem",
        "components-header-spacing-large-mega-menu-column-margin-right": "5rem",
        "components-header-spacing-large-mega-menu-category-link-margin-bottom": "1rem",
        "components-header-spacing-large-mega-menu-link-margin-bottom": "0.5rem",
        "components-header-spacing-large-mega-menu-featured-padding-x": "2rem",
        "components-header-spacing-large-mega-menu-featured-padding-y": "2rem",
        "components-header-spacing-large-mega-menu-featured-image-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-title-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-link-space-between": "1.5rem",
        "components-header-spacing-large-mega-menu-title-margin-bottom": "2.5rem",
        "components-header-spacing-large-dropdown-padding-y": "1.5rem",
        "components-header-spacing-large-dropdown-padding-x": "1.5rem",
        "components-header-spacing-large-dropdown-margin-bottom": "0.5rem",
        "components-header-spacing-small-language-selector-padding-x": "1rem",
        "components-header-spacing-small-language-selector-padding-y": "0.5rem",
        "components-header-spacing-small-language-selector-space-between": "0.5rem",
        "components-header-spacing-small-padding-left": "1rem",
        "components-header-spacing-small-padding-right": "1rem",
        "components-header-spacing-small-padding-top": "0.5rem",
        "components-header-spacing-small-padding-bottom": "0.5rem",
        "components-header-spacing-small-nav-main-minheight": "3rem",
        "components-header-spacing-small-nav-main-padding-y": "0.5rem",
        "components-header-spacing-small-nav-main-padding-left": "1.5rem",
        "components-header-spacing-small-nav-main-padding-right": "1rem",
        "components-header-spacing-small-nav-category-minheight": "3rem",
        "components-header-spacing-small-nav-category-padding-left": "1.5rem",
        "components-header-spacing-small-nav-category-padding-right": "0.75rem",
        "components-header-spacing-small-nav-category-padding-y": "0.75rem",
        "components-header-spacing-small-nav-category-space-between": "0rem",
        "components-header-spacing-small-nav-menu-link-minheight": "3rem",
        "components-header-spacing-small-nav-menu-link-padding-right": "1.5rem",
        "components-header-spacing-small-nav-menu-link-padding-left": "2.5rem",
        "components-header-spacing-small-nav-menu-link-padding-y": "0.75rem",
        "components-header-spacing-small-mega-menu-padding-y": "0rem",
        "components-header-spacing-small-mega-menu-padding-x": "0rem",
        "components-header-spacing-small-mega-menu-category-link-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-link-margin-bottom": "0rem",
        "components-header-spacing-small-mega-menu-featured-padding-x": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-padding-y": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-image-margin-bottom": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-title-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-featured-link-space-between": "0.75rem",
        "components-header-spacing-small-mega-menu-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-container-padding": "1rem",
        "components-hero-homepage-spacing-large-padding-x": "5rem",
        "components-hero-homepage-spacing-large-padding-y": "2.5rem",
        "components-hero-homepage-spacing-large-minheight": "34.3125rem",
        "components-hero-homepage-spacing-large-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-margin-bottom": "4rem",
        "components-hero-homepage-spacing-large-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-copy-margin-bottom": "2.5rem",
        "components-hero-homepage-spacing-large-cta-margin-right": "1rem",
        "components-hero-homepage-spacing-large-image-height": "34.3125rem",
        "components-hero-homepage-spacing-large-image-width": "45.75rem",
        "components-hero-homepage-spacing-small-padding-x": "0.5rem",
        "components-hero-homepage-spacing-small-padding-y": "1.5rem",
        "components-hero-homepage-spacing-small-minheight": "34.3125rem",
        "components-hero-homepage-spacing-small-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-copy-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-image-height": "17.5625rem",
        "components-hero-homepage-spacing-small-image-width": "45.75rem",
        "components-hero-internal-spacing-large-padding-y": "2.5rem",
        "components-hero-internal-spacing-large-padding-right": "10rem",
        "components-hero-internal-spacing-large-title-margin-bottom": "1.5rem",
        "components-hero-internal-spacing-large-button-padding-y": "2rem",
        "components-hero-internal-spacing-large-text-maxwidth": "59.625rem",
        "components-hero-internal-spacing-large-minheight": "18.5rem",
        "components-hero-internal-spacing-small-padding-x": "1.5rem",
        "components-hero-internal-spacing-small-padding-right": "0rem",
        "components-hero-internal-spacing-small-button-padding-y": "1.5rem",
        "components-hero-internal-spacing-small-text-maxwidth": "30rem",
        "components-hero-internal-spacing-small-minheight": "13.5rem",
        "components-icon-card-spacing-large-padding-x": "0rem",
        "components-icon-card-spacing-large-padding-y": "0rem",
        "components-icon-card-spacing-large-icon-margin": "1.5rem",
        "components-icon-card-spacing-large-margin-right": "1.5rem",
        "components-icon-card-spacing-large-title-margin-bottom": "1rem",
        "components-icon-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-icon-card-spacing-large-button-space-between": "0.5rem",
        "components-icon-card-spacing-large-top-space-between": "1rem",
        "components-icon-card-spacing-small-padding-x": "1.5rem",
        "components-icon-card-spacing-small-padding-y": "1.5rem",
        "components-icon-card-spacing-small-icon-margin": "1.5rem",
        "components-icon-card-spacing-small-margin-right": "1.5rem",
        "components-icon-card-spacing-small-title-margin-bottom": "1.5rem",
        "components-icon-card-dimensions-large-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-large-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-large-big-icon-height": "4rem",
        "components-icon-card-dimensions-large-big-icon-width": "4rem",
        "components-icon-card-dimensions-large-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-large-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-large-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-large-tiny-icon-width": "2.5rem",
        "components-icon-card-dimensions-small-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-small-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-small-big-icon-height": "3.5rem",
        "components-icon-card-dimensions-small-big-icon-width": "3.5rem",
        "components-icon-card-dimensions-small-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-small-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-small-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-small-tiny-icon-width": "2.5rem",
        "components-icon-card-listing-spacing-large-padding-inner-x": "4rem",
        "components-icon-card-listing-spacing-large-padding-inner-y": "4rem",
        "components-icon-card-listing-spacing-large-card-space-between": "4rem",
        "components-icon-card-listing-spacing-large-title-margin-bottom": "1.5rem",
        "components-icon-card-listing-spacing-large-card-margin-bottom": "4rem",
        "components-icon-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-x": "1.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-y": "2.5rem",
        "components-icon-card-listing-spacing-small-title-margin-bottom": "1rem",
        "components-icon-card-listing-spacing-small-button-margin-top": "1.5rem",
        "components-jumplink-link-padding-bottom": "0.5rem",
        "components-jumplink-spacing-small-title-area-margin-bottom": "1.5rem",
        "components-jumplink-spacing-small-link-container-padding-x": "0rem",
        "components-lightbox-spacing-large-carousel-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-carousel-space-between": "1.5rem",
        "components-lightbox-spacing-large-featured-image-height": "39.25rem",
        "components-lightbox-spacing-large-featured-image-width": "39.25rem",
        "components-lightbox-spacing-large-image-height": "17.0625rem",
        "components-lightbox-spacing-large-image-width": "17.0625rem",
        "components-lightbox-spacing-small-carousel-margin-bottom": "1rem",
        "components-lightbox-spacing-small-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-small-media-details-margin-top": "1rem",
        "components-lightbox-spacing-small-image-height": "20.4375rem",
        "components-lightbox-spacing-small-image-width": "20.4375rem",
        "components-legal-disclaimer-spacing-small-ruleline-width": "20.4375rem",
        "components-media-spacing-large-padding-y": "0.5rem",
        "components-media-spacing-large-padding-x": "0.5rem",
        "components-media-gallery-large-container-grid-gap-x": "1.5rem",
        "components-media-gallery-large-container-grid-gap-y": "1.5rem",
        "components-media-gallery-small-container-grid-gap-x": "0.5rem",
        "components-media-gallery-small-container-grid-gap-y": "0.5rem",
        "components-media-gallery-spacing-small-padding-x": "1.5rem",
        "components-media-gallery-spacing-small-padding-y": "2.5rem",
        "components-media-gallery-spacing-large-padding-x": "5rem",
        "components-media-gallery-spacing-large-padding-y": "5rem",
        "components-product-card-spacing-list-content-gap": "0rem",
        "components-product-card-spacing-large-padding-image-x": "0rem",
        "components-product-card-spacing-large-padding-image-y": "0rem",
        "components-product-card-spacing-large-padding-content-x": "1.5rem",
        "components-product-card-spacing-large-padding-content-y": "1.5rem",
        "components-product-card-spacing-large-category-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-title-margin-bottom": "1rem",
        "components-product-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-button-space-between": "1rem",
        "components-product-card-spacing-small-padding-image-x": "0rem",
        "components-product-card-spacing-small-padding-image-y": "0rem",
        "components-product-card-spacing-small-padding-content-x": "1.5rem",
        "components-product-card-spacing-small-padding-content-y": "1.5rem",
        "components-product-card-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-large-padding-x": "5rem",
        "components-product-information-spacing-large-padding-y": "1.5rem",
        "components-product-information-spacing-large-title-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-label-margin-bottom": "2.5rem",
        "components-product-information-spacing-large-ratings-margin-bottom": "1rem",
        "components-product-information-spacing-large-details-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-space-between": "1rem",
        "components-product-information-spacing-large-carousel-space-between": "5rem",
        "components-product-information-spacing-large-button-margin-y": "0.5rem",
        "components-product-information-spacing-large-body-margin-top": "1.5rem",
        "components-product-information-spacing-large-image-space-between": "1rem",
        "components-product-information-spacing-large-image-margin-bottom": "1rem",
        "components-product-information-spacing-small-padding-x": "1.5rem",
        "components-product-information-spacing-small-padding-y": "0.5rem",
        "components-product-information-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-small-label-margin-bottom": "1.5rem",
        "components-product-information-spacing-small-ratings-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-details-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-space-between": "0.75rem",
        "components-product-information-spacing-small-carousel-space-between": "2.5rem",
        "components-product-information-spacing-small-button-margin-y": "0.75rem",
        "components-product-information-spacing-small-body-margin-top": "1rem",
        "components-product-information-spacing-small-image-space-between": "0.75rem",
        "components-product-information-spacing-small-image-margin-bottom": "1rem",
        "components-product-results-list-with-search-spacing-large-padding-x": "5rem",
        "components-product-results-list-with-search-spacing-large-padding-y": "5rem",
        "components-product-results-list-with-search-spacing-large-ruleline-margin-top": "2.5rem",
        "components-product-results-list-with-search-spacing-large-filter-space-between": "1rem",
        "components-product-results-list-with-search-spacing-large-card-space-between": "1.5rem",
        "components-product-results-list-with-search-spacing-large-card-container-margin-bottom": "5rem",
        "components-product-results-list-with-search-spacing-small-padding-x": "1.5rem",
        "components-product-results-list-with-search-spacing-small-padding-y": "2.5rem",
        "components-product-results-list-with-search-spacing-small-ruleline-margin-top": "1rem",
        "components-product-results-list-with-search-spacing-small-filter-padding-x": "0.5rem",
        "components-product-results-list-with-search-spacing-small-card-space-between": "1rem",
        "components-product-results-list-with-search-spacing-small-card-margin-bottom": "1.5rem",
        "components-promo-spacing-large-padding-x": "0rem",
        "components-promo-spacing-large-padding-y": "0rem",
        "components-promo-spacing-large-content-space-between": "0rem",
        "components-promo-spacing-large-content-space-between-y": "0rem",
        "components-promo-spacing-large-padding-content-x": "5rem",
        "components-promo-spacing-large-padding-content-condensed-x": "5rem",
        "components-promo-spacing-large-padding-content-y": "5rem",
        "components-promo-spacing-large-padding-content-condensed-y": "5rem",
        "components-promo-spacing-large-padding-image-x": "0rem",
        "components-promo-spacing-large-padding-image-y": "0rem",
        "components-promo-spacing-large-padding-image-top": "5rem",
        "components-promo-spacing-large-title-margin-bottom": "2rem",
        "components-promo-spacing-large-margin-y": "2rem",
        "components-promo-spacing-large-buttons-space-between": "1.5rem",
        "components-promo-spacing-small-padding-x": "0rem",
        "components-promo-spacing-small-padding-y": "0rem",
        "components-promo-spacing-small-padding-top": "0rem",
        "components-promo-spacing-small-padding-content-x": "2.5rem",
        "components-promo-spacing-small-padding-content-y": "2.5rem",
        "components-promo-spacing-small-padding-image-x": "0rem",
        "components-promo-spacing-small-padding-image-y": "0rem",
        "components-promo-spacing-small-padding-image-top": "0rem",
        "components-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-promo-spacing-small-margin-y": "2.5rem",
        "components-promo-minheight": "30rem",
        "components-pull-quote-spacing-large-padding-quote-x": "4rem",
        "components-pull-quote-spacing-large-padding-quote-y": "4rem",
        "components-pull-quote-spacing-large-title-margin-bottom": "2rem",
        "components-pull-quote-spacing-large-quote-margin-bottom": "4rem",
        "components-pull-quote-spacing-large-quote-space-between": "1.5rem",
        "components-pull-quote-spacing-large-detail-space-between": "0.5rem",
        "components-pull-quote-spacing-large-quote-minheight": "25rem",
        "components-pull-quote-spacing-small-padding-quote-x": "1.5rem",
        "components-pull-quote-spacing-small-padding-quote-y": "2.5rem",
        "components-pull-quote-spacing-small-title-margin-bottom": "1.5rem",
        "components-pull-quote-spacing-small-quote-margin-bottom": "2.5rem",
        "components-pull-quote-spacing-small-quote-space-between": "1rem",
        "components-pull-quote-spacing-small-detail-space-between": "1rem",
        "components-pull-quote-spacing-small-quote-minheight": "15rem",
        "components-promo-alternate-minheight": "30rem",
        "components-promo-alternate-spacing-large-padding-x": "0rem",
        "components-promo-alternate-spacing-large-padding-y": "0rem",
        "components-promo-alternate-spacing-large-content-space-between": "0rem",
        "components-promo-alternate-spacing-large-padding-content-x": "5rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-x": "5rem",
        "components-promo-alternate-spacing-large-padding-content-y": "5rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-y": "5rem",
        "components-promo-alternate-spacing-large-padding-image-x": "0rem",
        "components-promo-alternate-spacing-large-padding-image-y": "0rem",
        "components-promo-alternate-spacing-large-padding-image-top": "5rem",
        "components-promo-alternate-spacing-large-title-margin-bottom": "2rem",
        "components-promo-alternate-spacing-small-padding-x": "0rem",
        "components-promo-alternate-spacing-small-padding-y": "0rem",
        "components-promo-alternate-spacing-small-padding-content-x": "2.5rem",
        "components-promo-alternate-spacing-small-padding-content-y": "2.5rem",
        "components-promo-alternate-spacing-small-padding-image-x": "0rem",
        "components-promo-alternate-spacing-small-padding-image-y": "0rem",
        "components-promo-alternate-spacing-small-padding-image-top": "0rem",
        "components-promo-alternate-spacing-small-title-margin-bottom": "1.5rem",
        "components-share-list-spacing-padding-x": "1rem",
        "components-share-list-spacing-padding-y": "1rem",
        "components-header-copy-margin-bottom": "1rem",
        "components-header-margin-bottom": "1.5rem",
        "components-item-margin-bottom": "1.5rem",
        "components-site-search-padding-x": "2.5rem",
        "components-site-search-padding-y": "5rem",
        "components-site-search-facet-label-space-between": "0.25rem",
        "components-site-search-refine-padding-x": "1.5rem",
        "components-site-search-refine-padding-y": "0rem",
        "components-site-search-hero-search-height": "18.5rem",
        "components-site-search-hero-search-large-padding-x": "5rem",
        "components-site-search-hero-search-large-padding-y": "5rem",
        "components-stats-spacing-stat-text-space-between": "0.5rem",
        "components-stats-spacing-large-inner-padding-x": "5rem",
        "components-stats-spacing-large-inner-padding-y": "5rem",
        "components-stats-spacing-small-inner-padding-x": "1.5rem",
        "components-stats-spacing-small-inner-padding-y": "1.5rem",
        "components-icon-card-listing-spacing-small-top-space-between": "1rem",
        "components-supporting-text-padding-x": "0rem",
        "components-supporting-text-padding-bottom": "0.5rem",
        "components-tab-item-padding-y": "0.75rem",
        "components-tab-item-padding-x": "0.75rem",
        "components-tab-item-spacing-x": "0.75rem",
        "components-tab-input-padding-y": "0.25rem",
        "components-tab-input-padding-x": "1rem",
        "components-tag-spacing-padding-x": "0.5rem",
        "components-tag-spacing-padding-y": "0.5rem",
        "components-tag-spacing-space-between": "0.5rem",
        "components-text-field-input-padding-y": "0.25rem",
        "components-text-field-input-padding-x": "1rem",
        "components-text-field-area-input-padding-y": "1rem",
        "components-text-field-area-input-padding-x": "1rem",
        "components-text-field-supporting-text-padding-x": "0rem",
        "components-text-field-supporting-text-padding-top": "0.5rem",
        "themes-general-surface-spacing-large-padding-x": "5rem",
        "themes-general-surface-spacing-large-padding-y": "5rem",
        "themes-general-surface-spacing-large-title-margin-bottom": "2rem",
        "themes-general-surface-spacing-large-body-margin-right": "4rem",
        "themes-general-surface-spacing-large-body-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-large-margin-bottom": "4rem",
        "themes-general-surface-spacing-large-cards-space-between": "1.5rem",
        "themes-general-surface-spacing-large-button-margin-top": "2rem",
        "themes-general-surface-spacing-small-padding-x": "1.5rem",
        "themes-general-surface-spacing-small-padding-y": "2.5rem",
        "themes-general-surface-spacing-small-title-margin-bottom": "1rem",
        "themes-general-surface-spacing-small-body-margin-right": "2.5rem",
        "themes-general-surface-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-surface-spacing-small-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-small-cards-space-between": "1.5rem",
        "themes-general-surface-spacing-small-button-margin-top": "1.5rem",
        "themes-general-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "themes-general-card-spacing-large-title-margin-bottom": "1rem",
        "themes-general-card-spacing-large-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-large-button-space-between": "1.5rem",
        "themes-general-card-spacing-small-eyebrow-margin-bottom": "1rem",
        "themes-general-card-spacing-small-title-margin-bottom": "1rem",
        "themes-general-card-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-small-button-space-between": "1.5rem",
        "themes-shadow-share-list-spacing-x": "0rem",
        "themes-shadow-share-list-spacing-y": "0.75rem",
        "themes-shadow-share-list-spacing-blur": "1rem",
        "themes-shadow-share-list-spacing-spread": "-0.5rem",
        "themes-shadow-header-menu-spacing-x": "0rem",
        "themes-shadow-header-menu-spacing-y": "0.75rem",
        "themes-shadow-header-menu-spacing-blur": "1rem",
        "themes-shadow-header-menu-spacing-spread": "-0.5rem",
        "themes-typography-rte-large-h1-textsize": "3rem",
        "themes-typography-rte-large-h2-textsize": "2.5rem",
        "themes-typography-rte-large-h3-textsize": "2.25rem",
        "themes-typography-rte-large-h4-textsize": "2rem",
        "themes-typography-rte-large-h5-textsize": "1.5rem",
        "themes-typography-rte-large-h6-textsize": "1.25rem",
        "themes-typography-rte-medium-h1-textsize": "2.5rem",
        "themes-typography-rte-medium-h2-textsize": "2.25rem",
        "themes-typography-rte-medium-h3-textsize": "1.75rem",
        "themes-typography-rte-medium-h4-textsize": "1.5rem",
        "themes-typography-rte-medium-h5-textsize": "1.25rem",
        "themes-typography-rte-medium-h6-textsize": "1.125rem",
        "themes-typography-rte-small-h1-textsize": "2.5rem",
        "themes-typography-rte-small-h2-textsize": "2rem",
        "themes-typography-rte-small-h3-textsize": "1.75rem",
        "themes-typography-rte-small-h4-textsize": "1.5rem",
        "themes-typography-rte-small-h5-textsize": "1.125rem",
        "themes-typography-rte-small-h6-textsize": "1rem",
        "spacing-margin-small-1": "0.125rem",
        "spacing-margin-small-2": "0.25rem",
        "spacing-margin-small-3": "0.5rem",
        "spacing-margin-small-4": "0.75rem",
        "spacing-margin-small-5": "1rem",
        "spacing-margin-small-6": "1.5rem",
        "spacing-margin-small-7": "2.5rem",
        "spacing-margin-medium-2": "0.75rem",
        "spacing-margin-medium-4": "1.5rem",
        "spacing-margin-medium-5": "2rem",
        "spacing-margin-large-1": "0.5rem",
        "spacing-margin-large-2": "1rem",
        "spacing-margin-large-3": "1.5rem",
        "spacing-margin-large-4": "2rem",
        "spacing-margin-large-5": "2.5rem",
        "spacing-margin-large-6": "4rem",
        "spacing-margin-large-7": "5rem",
        "spacing-space-between-small-1": "0.125rem",
        "spacing-space-between-small-2": "0.25rem",
        "spacing-space-between-small-3": "0.5rem",
        "spacing-space-between-small-4": "0.75rem",
        "spacing-space-between-small-5": "1rem",
        "spacing-space-between-small-6": "1.5rem",
        "spacing-space-between-small-7": "2.5rem",
        "spacing-space-between-medium-1": "0.125rem",
        "spacing-space-between-medium-2": "0.25rem",
        "spacing-space-between-medium-3": "0.5rem",
        "spacing-space-between-medium-4": "0.75rem",
        "spacing-space-between-medium-5": "1rem",
        "spacing-space-between-large-1": "0.25rem",
        "spacing-space-between-large-2": "0.5rem",
        "spacing-space-between-large-3": "1rem",
        "spacing-space-between-large-4": "1.5rem",
        "spacing-space-between-large-5": "1.5rem",
        "spacing-space-between-large-6": "2.5rem",
        "spacing-space-between-large-7": "4rem",
        "spacing-padding-small-1": "0.125rem",
        "spacing-padding-small-2": "0.25rem",
        "spacing-padding-small-3": "0.5rem",
        "spacing-padding-small-4": "0.75rem",
        "spacing-padding-small-5": "1rem",
        "spacing-padding-small-6": "1.5rem",
        "spacing-padding-small-7": "2.5rem",
        "spacing-padding-medium-1": "0.25rem",
        "spacing-padding-medium-2": "0.75rem",
        "spacing-padding-medium-3": "1rem",
        "spacing-padding-medium-4": "1.5rem",
        "spacing-padding-medium-5": "2rem",
        "spacing-padding-medium-6": "3rem",
        "spacing-padding-medium-7": "4rem",
        "spacing-padding-large-1": "0.5rem",
        "spacing-padding-large-2": "1rem",
        "spacing-padding-large-3": "1.5rem",
        "spacing-padding-large-4": "2rem",
        "spacing-padding-large-5": "2.5rem",
        "spacing-padding-large-6": "4rem",
        "spacing-padding-large-7": "5rem"
      },
      "string": {
        "components-accordion-section-button": "var(--brand)",
        "components-article-card-listing-button-theme": "var(--brand)",
        "components-article-hero-pagination-buttons": "var(--tonal)",
        "components-banner-banner-button": "var(--brand)",
        "components-category-card-listing-button-theme": "var(--brand)",
        "components-contact-us-promo-hierarchy-button-theme-1": "var(--filled)",
        "components-contact-us-promo-hierarchy-button-theme-2": "var(--outlined)",
        "components-contact-us-promo-color-button-theme": "var(--brand)",
        "components-contact-us-promo-color-brand-button-theme": "var(--white)",
        "components-generic-card-button-theme": "var(--brand)",
        "components-global-search-loader": "var(--default)",
        "components-generic-card-listing-decor-top": "var(--off-2)",
        "components-generic-card-listing-decor-bottom": "var(--off-4)",
        "components-hero-homepage-pagination-buttons": "var(--tonal)",
        "components-hero-homepage-pattern": "var(--off-d)",
        "components-hero-homepage-button-1": "var(--brand)",
        "components-hero-homepage-button-2": "var(--brand)",
        "components-hero-homepage-image-ratio": "var(--5:4)",
        "components-hero-internal-theme-button-one-type": "var(--filled)",
        "components-hero-internal-theme-button-two-type": "var(--outlined)",
        "components-hero-internal-theme-button-color": "var(--white)",
        "components-hero-internal-theme-button-color-with-image": "var(--white)",
        "components-lightbox-button-type": "var(--brand)",
        "components-pdf-link-card-hierarchy-button-theme-1": "var(--filled)",
        "components-pdf-link-card-hierarchy-button-theme-2": "var(--outlined)",
        "components-pdf-link-card-color-button-theme": "var(--brand)",
        "components-product-card-badge-align": "var(--center)",
        "components-product-card-listing-button-color": "var(--tonal)",
        "components-promo-theme-button-one-theme": "var(--outlined)",
        "components-promo-theme-button-two-theme": "var(--text)",
        "components-promo-color-default-button-theme": "var(--brand)",
        "components-promo-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-color-brand-button-theme": "var(--white)",
        "components-pull-quote-border-decor": "var(--off-1)",
        "components-promo-alternate-theme-button-one-theme": "var(--outlined)",
        "components-promo-alternate-theme-button-two-theme": "var(--text)",
        "components-promo-alternate-color-default-button-theme": "var(--brand)",
        "components-promo-alternate-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-alternate-color-brand-button-theme": "var(--white)",
        "components-share-list-button-color": "var(--brand)",
        "components-site-search-hero-search-hero-pattern": "var(--defense)",
        "components-stats-decor-top": "var(--off-2)",
        "components-stats-decor-bottom": "var(--off-4)",
        "themes-brand-loader": "var(--default)",
        "themes-brand-variants": "var(--off)",
        "themes-tab-variant": "var(--default)",
        "themes-label-variants": "var(--default)",
        "themes-logo": "var(--offdefense)",
        "themes-category-card": "var(--off)",
        "themes-title-decor": "var(--off)",
        "themes-typography-heading-fontfamily": "var(--poppins)",
        "themes-typography-heading-large-xxlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-xlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-large-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-medium-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-small-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-xsmall-fontweight": "var(--bold)",
        "themes-typography-heading-large-xxsmall-fontweight": "var(--semibold)",
        "themes-typography-heading-medium-xxlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-xlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-large-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-medium-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-small-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-xsmall-fontweight": "var(--bold)",
        "themes-typography-heading-medium-xxsmall-fontweight": "var(--semibold)",
        "themes-typography-heading-small-xxlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-xlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-large-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-medium-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-small-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-xsmall-fontweight": "var(--bold)",
        "themes-typography-heading-small-xxsmall-fontweight": "var(--semibold)",
        "themes-typography-rte-fontfamily": "var(--poppins)",
        "themes-typography-rte-fontweight": "var(--extrabold)"
      },
      "borderRadius": {
        "components-accordion-spacing-large-item-item-radius": "0rem",
        "components-article-card-spacing-large-image-radius-tl": "0rem",
        "components-article-card-spacing-large-image-radius-tr": "0rem",
        "components-article-card-spacing-large-image-radius-bl": "0rem",
        "components-article-card-spacing-large-image-radius-br": "0rem",
        "components-article-card-spacing-large-list-image-radius-br": "0rem",
        "components-article-card-spacing-small-image-radius-br": "0rem",
        "components-article-card-spacing-small-list-image-radius-br": "0rem",
        "components-article-hero-spacing-large-content-radius": "1.5rem",
        "components-article-hero-spacing-small-content-radius": "1rem",
        "components-button-radius-large": "0.5rem",
        "components-button-group-spacing-border-radius": "0.5rem",
        "components-category-card-radius-border-width": "0.0625rem",
        "components-category-card-radius-border-radius": "7.5rem",
        "components-category-card-radius-border-radius-condensed": "0.5rem",
        "components-category-card-radius-media-radius": "0.75rem",
        "components-content-list-icon-radius": "7.5rem",
        "components-fab-spacing-border-radius": "0.5rem",
        "components-header-spacing-large-search-border-radius-top": "0.25rem",
        "components-header-spacing-large-search-border-radius-bottom": "0.25rem",
        "components-header-spacing-large-language-selector-radius": "0.25rem",
        "components-header-spacing-large-dropdown-radius": "0rem",
        "components-hero-homepage-spacing-large-container-radius": "1rem",
        "components-hero-internal-spacing-large-icon-radius": "7.5rem",
        "components-hero-internal-spacing-small-icon-radius": "7.5rem",
        "components-lightbox-spacing-large-overlay-radius": "1rem",
        "components-pagination-scrollbar-radius": "62.5rem",
        "components-product-card-spacing-image-radius-tl": "0rem",
        "components-product-card-spacing-image-radius-tr": "0rem",
        "components-product-card-spacing-image-radius-bl": "0rem",
        "components-product-card-spacing-image-radius-br": "0rem",
        "components-product-information-spacing-large-content-radius": "1.5rem",
        "components-product-information-spacing-small-content-radius": "1rem",
        "components-promo-spacing-large-content-radius": "0rem",
        "components-promo-spacing-large-content-radius-tl": "0rem",
        "components-promo-spacing-large-content-radius-tr": "0rem",
        "components-promo-spacing-large-content-radius-bl": "0rem",
        "components-promo-spacing-large-content-radius-br": "0rem",
        "components-promo-spacing-large-image-radius-tl": "0rem",
        "components-promo-spacing-large-image-radius-tr": "0rem",
        "components-promo-spacing-large-image-radius-bl": "0rem",
        "components-promo-spacing-large-image-radius-br": "0rem",
        "components-promo-spacing-small-content-radius": "0rem",
        "components-promo-spacing-small-image-radius-tl": "0rem",
        "components-promo-spacing-small-image-radius-tr": "0rem",
        "components-promo-spacing-small-image-radius-bl": "0rem",
        "components-promo-spacing-small-image-radius-br": "0rem",
        "components-promo-alternate-spacing-large-content-radius": "0rem",
        "components-promo-alternate-spacing-large-image-radius-tl": "0rem",
        "components-promo-alternate-spacing-large-image-radius-tr": "0rem",
        "components-promo-alternate-spacing-large-image-radius-bl": "0rem",
        "components-promo-alternate-spacing-large-image-radius-br": "0rem",
        "components-promo-alternate-spacing-small-content-radius": "0rem",
        "components-promo-alternate-spacing-small-image-radius-tl": "0rem",
        "components-promo-alternate-spacing-small-image-radius-tr": "0rem",
        "components-promo-alternate-spacing-small-image-radius-bl": "0rem",
        "components-promo-alternate-spacing-small-image-radius-br": "0rem",
        "components-share-list-spacing-radius": "0.5rem",
        "components-share-list-spacing-link-radius": "0.5rem",
        "components-site-search-filter-radius": "0.5rem",
        "components-site-search-facet-radius": "0rem",
        "components-site-search-refine-radius": "0rem",
        "components-tag-spacing-radius": "0.25rem",
        "components-tip-spacing-radius": "0.25rem",
        "themes-spacing-radius-pagination-bar": "62.5rem",
        "themes-spacing-radius-large-button": "0.5rem",
        "themes-spacing-radius-large-button-icon": "0.5rem",
        "themes-spacing-radius-large-image": "1rem",
        "themes-spacing-radius-large-image-hero": "1rem",
        "themes-spacing-radius-large-image-card": "0.75rem",
        "themes-spacing-radius-large-card": "1rem",
        "themes-spacing-radius-large-icon": "7.5rem",
        "themes-spacing-radius-large-label": "0.25rem",
        "themes-spacing-radius-large-tag": "0.25rem",
        "themes-spacing-radius-large-container-tip": "0.25rem",
        "themes-spacing-radius-large-badge": "0rem",
        "themes-spacing-radius-large-item": "0.5rem",
        "themes-spacing-radius-large-form-field-input": "0.25rem",
        "themes-spacing-radius-large-form-area-input": "0.25rem",
        "themes-spacing-radius-large-general": "1.5rem",
        "themes-spacing-radius-small-button": "0.5rem",
        "themes-spacing-radius-small-button-icon": "0.5rem",
        "themes-spacing-radius-small-image": "0.75rem",
        "themes-spacing-radius-small-image-card": "0.75rem",
        "themes-spacing-radius-small-card": "0.75rem",
        "themes-spacing-radius-small-icon": "7.5rem",
        "themes-spacing-radius-small-label": "0.125rem",
        "themes-spacing-radius-small-tag": "0.25rem",
        "themes-spacing-radius-small-badge": "0rem",
        "themes-spacing-radius-small-item": "0rem",
        "themes-spacing-radius-small-form-field-input": "0.25rem",
        "themes-spacing-radius-small-general": "1rem"
      },
      "borderWidth": {
        "3": "3px",
        "components-article-card-spacing-large-border-width": "0.0625rem",
        "components-generic-card-color-border-width": "0.0625rem",
        "components-header-spacing-large-search-border-width-top": "0.125rem",
        "components-header-spacing-large-search-border-width-right": "0.125rem",
        "components-header-spacing-large-search-border-width-bottom": "0.125rem",
        "components-header-spacing-large-search-border-width-left": "0.125rem",
        "components-product-card-spacing-border-width": "0.0625rem"
      },
      "maxHeight": {},
      "maxWidth": {
        "components-article-hero-spacing-large-header-max-width": "66.375rem",
        "components-content-list-spacing-large-item-image-max-width": "33.75rem",
        "components-content-list-spacing-small-item-image-max-width": "33.75rem"
      },
      "minHeight": {
        "components-dropdown-item-min-height": "3rem",
        "components-icon-min-height": "3rem",
        "components-tab-item-min-height": "3rem",
        "components-text-field-input-min-height": "3rem",
        "components-text-field-icon-min-height": "3rem"
      },
      "minWidth": {
        "components-content-list-spacing-small-item-image-min-width": "18.75rem",
        "components-icon-min-width": "3rem",
        "components-text-field-icon-min-width": "3rem"
      },
      "opacity": {
        "components-accessibility-bar-color-bg": 1,
        "components-accessibility-bar-color-text": 1,
        "components-accordion-color-bg": 1,
        "components-accordion-color-title": 1,
        "components-accordion-color-body": 1,
        "components-accordion-color-section-section-bg": 1,
        "components-accordion-color-section-section-title": 1,
        "components-accordion-color-section-section-body": 1,
        "components-accordion-color-item-item-bg": 1,
        "components-accordion-color-item-divider": 1,
        "components-accordion-color-item-icon-icon-default": 1,
        "components-accordion-color-item-icon-icon-hover": 1,
        "components-accordion-color-item-icon-icon-pressed": 1,
        "components-accordion-color-item-icon-icon-active": 1,
        "components-accordion-color-item-title-item-default": 1,
        "components-accordion-color-item-title-item-hover": 1,
        "components-accordion-color-item-title-item-pressed": 1,
        "components-accordion-color-item-title-item-active": 1,
        "components-accordion-color-content-bg": 1,
        "components-accordion-color-content-title": 1,
        "components-accordion-color-content-body": 1,
        "components-article-card-color-bg": 1,
        "components-article-card-color-border": 1,
        "components-article-card-color-content-bg": 1,
        "components-article-card-color-title": 1,
        "components-article-card-color-subtitle": 1,
        "components-article-card-color-copy": 1,
        "components-article-card-color-description": 1,
        "components-article-card-listing-color-default-bg": 1,
        "components-article-card-listing-color-default-title": 1,
        "components-article-card-listing-color-default-copy": 1,
        "components-article-hero-color-content-bg": 1,
        "components-article-hero-color-bg": 1,
        "components-article-hero-color-headline": 1,
        "components-article-hero-color-body": 1,
        "components-article-hero-color-detail-text": 1,
        "components-article-hero-color-divider": 1,
        "components-article-hero-color-icon": 1,
        "components-article-hero-color-play-icon": 1,
        "components-article-hero-color-selected-stroke": 1,
        "components-banner-color-bg-default": 1,
        "components-banner-color-icon-default": 1,
        "components-banner-color-title-default": 1,
        "components-banner-color-body-default": 1,
        "components-banner-color-icon-close-default": 1,
        "components-banner-color-bg-alert": 1,
        "components-banner-color-icon-alert": 1,
        "components-banner-color-title-alert": 1,
        "components-banner-color-body-alert": 1,
        "components-banner-color-icon-close-alert": 1,
        "components-breadcrumb-color-bg": 1,
        "components-breadcrumb-color-breadcrumb-link-default": 1,
        "components-breadcrumb-color-breadcrumb-link-hover": 1,
        "components-breadcrumb-color-breadcrumb-link-active": 1,
        "components-breadcrumb-color-icon-divider": 1,
        "components-bug-finder-prompt": 1,
        "components-button-color-filled-brand-default-bg": 1,
        "components-button-color-filled-brand-default-text": 1,
        "components-button-color-filled-brand-default-stroke": 1,
        "components-button-color-filled-brand-default-icon": 1,
        "components-button-color-filled-brand-hover-bg": 1,
        "components-button-color-filled-brand-hover-text": 1,
        "components-button-color-filled-brand-hover-stroke": 1,
        "components-button-color-filled-brand-hover-icon": 1,
        "components-button-color-filled-brand-pressed-bg": 1,
        "components-button-color-filled-brand-pressed-text": 1,
        "components-button-color-filled-brand-pressed-stroke": 1,
        "components-button-color-filled-brand-pressed-icon": 1,
        "components-button-color-filled-brand-disabled-bg": 0.03,
        "components-button-color-filled-brand-disabled-text": 0.02,
        "components-button-color-filled-brand-disabled-stroke": 0.03,
        "components-button-color-filled-brand-disabled-icon": 0.02,
        "components-button-color-filled-brand-focus-bg": 1,
        "components-button-color-filled-brand-focus-text": 1,
        "components-button-color-filled-brand-focus-stroke": 1,
        "components-button-color-filled-brand-focus-icon": 1,
        "components-button-color-filled-brand-focus-outline": 1,
        "components-button-color-filled-tonal-default-bg": 1,
        "components-button-color-filled-tonal-default-text": 1,
        "components-button-color-filled-tonal-default-stroke": 1,
        "components-button-color-filled-tonal-default-icon": 1,
        "components-button-color-filled-tonal-hover-bg": 1,
        "components-button-color-filled-tonal-hover-text": 1,
        "components-button-color-filled-tonal-hover-stroke": 1,
        "components-button-color-filled-tonal-hover-icon": 1,
        "components-button-color-filled-tonal-pressed-bg": 1,
        "components-button-color-filled-tonal-pressed-text": 1,
        "components-button-color-filled-tonal-pressed-stroke": 1,
        "components-button-color-filled-tonal-pressed-icon": 1,
        "components-button-color-filled-tonal-disabled-bg": 0.02,
        "components-button-color-filled-tonal-disabled-text": 0.03,
        "components-button-color-filled-tonal-disabled-stroke": 0.02,
        "components-button-color-filled-tonal-disabled-icon": 0.03,
        "components-button-color-filled-tonal-focus-bg": 1,
        "components-button-color-filled-tonal-focus-text": 1,
        "components-button-color-filled-tonal-focus-stroke": 1,
        "components-button-color-filled-tonal-focus-icon": 1,
        "components-button-color-filled-tonal-focus-outline": 1,
        "components-button-color-filled-white-default-bg": 1,
        "components-button-color-filled-white-default-text": 1,
        "components-button-color-filled-white-default-stroke": 1,
        "components-button-color-filled-white-default-icon": 1,
        "components-button-color-filled-white-hover-bg": 1,
        "components-button-color-filled-white-hover-text": 1,
        "components-button-color-filled-white-hover-stroke": 1,
        "components-button-color-filled-white-hover-icon": 1,
        "components-button-color-filled-white-pressed-bg": 1,
        "components-button-color-filled-white-pressed-text": 1,
        "components-button-color-filled-white-pressed-stroke": 1,
        "components-button-color-filled-white-pressed-icon": 1,
        "components-button-color-filled-white-disabled-bg": 0.02,
        "components-button-color-filled-white-disabled-text": null,
        "components-button-color-filled-white-disabled-stroke": 0.02,
        "components-button-color-filled-white-disabled-icon": 0.03,
        "components-button-color-filled-white-focus-bg": 1,
        "components-button-color-filled-white-focus-text": 1,
        "components-button-color-filled-white-focus-stroke": 1,
        "components-button-color-filled-white-focus-icon": 1,
        "components-button-color-filled-white-focus-outline": 1,
        "components-button-color-outline-brand-default-bg": 0.03,
        "components-button-color-outline-brand-default-text": 1,
        "components-button-color-outline-brand-default-stroke": 1,
        "components-button-color-outline-brand-default-icon": 1,
        "components-button-color-outline-brand-hover-bg": 1,
        "components-button-color-outline-brand-hover-text": 1,
        "components-button-color-outline-brand-hover-stroke": 1,
        "components-button-color-outline-brand-hover-icon": 1,
        "components-button-color-outline-brand-pressed-bg": 1,
        "components-button-color-outline-brand-pressed-text": 1,
        "components-button-color-outline-brand-pressed-stroke": 1,
        "components-button-color-outline-brand-pressed-icon": 1,
        "components-button-color-outline-brand-disabled-bg": 0.03,
        "components-button-color-outline-brand-disabled-text": 0.03,
        "components-button-color-outline-brand-disabled-stroke": 0.03,
        "components-button-color-outline-brand-disabled-icon": 0.03,
        "components-button-color-outline-brand-focus-bg": 1,
        "components-button-color-outline-brand-focus-text": 1,
        "components-button-color-outline-brand-focus-stroke": 1,
        "components-button-color-outline-brand-focus-icon": 1,
        "components-button-color-outline-brand-focus-outline": 1,
        "components-button-color-outline-tonal-default-bg": 1,
        "components-button-color-outline-tonal-default-text": 1,
        "components-button-color-outline-tonal-default-stroke": 1,
        "components-button-color-outline-tonal-default-icon": 1,
        "components-button-color-outline-tonal-hover-bg": 1,
        "components-button-color-outline-tonal-hover-text": 1,
        "components-button-color-outline-tonal-hover-stroke": 1,
        "components-button-color-outline-tonal-hover-icon": 1,
        "components-button-color-outline-tonal-pressed-bg": 1,
        "components-button-color-outline-tonal-pressed-text": 1,
        "components-button-color-outline-tonal-pressed-stroke": 1,
        "components-button-color-outline-tonal-pressed-icon": 1,
        "components-button-color-outline-tonal-disabled-bg": 0.02,
        "components-button-color-outline-tonal-disabled-text": 0.02,
        "components-button-color-outline-tonal-disabled-stroke": 0.02,
        "components-button-color-outline-tonal-disabled-icon": 0.02,
        "components-button-color-outline-tonal-focus-bg": 1,
        "components-button-color-outline-tonal-focus-text": 1,
        "components-button-color-outline-tonal-focus-stroke": 1,
        "components-button-color-outline-tonal-focus-icon": 1,
        "components-button-color-outline-tonal-focus-outline": 1,
        "components-button-color-outline-white-default-bg": 0.02,
        "components-button-color-outline-white-default-text": 1,
        "components-button-color-outline-white-default-stroke": 1,
        "components-button-color-outline-white-default-icon": 1,
        "components-button-color-outline-white-hover-bg": 1,
        "components-button-color-outline-white-hover-text": 1,
        "components-button-color-outline-white-hover-stroke": 1,
        "components-button-color-outline-white-hover-icon": 1,
        "components-button-color-outline-white-pressed-bg": 1,
        "components-button-color-outline-white-pressed-text": 1,
        "components-button-color-outline-white-pressed-stroke": 1,
        "components-button-color-outline-white-pressed-icon": 1,
        "components-button-color-outline-white-disabled-bg": 0.02,
        "components-button-color-outline-white-disabled-text": 0.02,
        "components-button-color-outline-white-disabled-stroke": 0.02,
        "components-button-color-outline-white-disabled-icon": 0.02,
        "components-button-color-outline-white-focus-bg": 0.02,
        "components-button-color-outline-white-focus-text": 1,
        "components-button-color-outline-white-focus-stroke": 1,
        "components-button-color-outline-white-focus-icon": 1,
        "components-button-color-outline-white-focus-outline": 1,
        "components-button-color-text-brand-default-bg": 0.02,
        "components-button-color-text-brand-default-text": 1,
        "components-button-color-text-brand-default-stroke": 1,
        "components-button-color-text-brand-default-icon": 1,
        "components-button-color-text-brand-hover-bg": 0.02,
        "components-button-color-text-brand-hover-bg-icon-only": 1,
        "components-button-color-text-brand-hover-text": 1,
        "components-button-color-text-brand-hover-stroke": 1,
        "components-button-color-text-brand-hover-icon": 1,
        "components-button-color-text-brand-pressed-bg": 0.02,
        "components-button-color-text-brand-pressed-bg-icon-only": 1,
        "components-button-color-text-brand-pressed-text": 1,
        "components-button-color-text-brand-pressed-stroke": 1,
        "components-button-color-text-brand-pressed-icon": 1,
        "components-button-color-text-brand-disabled-bg": 0.02,
        "components-button-color-text-brand-disabled-text": 0.03,
        "components-button-color-text-brand-disabled-stroke": 0.03,
        "components-button-color-text-brand-disabled-icon": 0.03,
        "components-button-color-text-brand-focus-bg": 1,
        "components-button-color-text-brand-focus-text": 1,
        "components-button-color-text-brand-focus-stroke": 1,
        "components-button-color-text-brand-focus-icon": 1,
        "components-button-color-text-brand-focus-outline": 1,
        "components-button-color-text-tonal-default-bg": 0.02,
        "components-button-color-text-tonal-default-text": 1,
        "components-button-color-text-tonal-default-stroke": 1,
        "components-button-color-text-tonal-default-icon": 1,
        "components-button-color-text-tonal-hover-bg": 0.02,
        "components-button-color-text-tonal-hover-bg-icon-only": 1,
        "components-button-color-text-tonal-hover-text": 1,
        "components-button-color-text-tonal-hover-stroke": 1,
        "components-button-color-text-tonal-hover-icon": 1,
        "components-button-color-text-tonal-pressed-bg": 0.02,
        "components-button-color-text-tonal-pressed-bg-icon-only": 1,
        "components-button-color-text-tonal-pressed-text": 1,
        "components-button-color-text-tonal-pressed-stroke": 1,
        "components-button-color-text-tonal-pressed-icon": 1,
        "components-button-color-text-tonal-disabled-bg": 0.02,
        "components-button-color-text-tonal-disabled-text": 0.03,
        "components-button-color-text-tonal-disabled-stroke": 0.03,
        "components-button-color-text-tonal-disabled-icon": 0.03,
        "components-button-color-text-tonal-focus-bg": 1,
        "components-button-color-text-tonal-focus-text": 1,
        "components-button-color-text-tonal-focus-stroke": 1,
        "components-button-color-text-tonal-focus-icon": 1,
        "components-button-color-text-tonal-focus-outline": 1,
        "components-button-color-text-white-default-bg": 0.02,
        "components-button-color-text-white-default-text": 1,
        "components-button-color-text-white-default-stroke": 1,
        "components-button-color-text-white-default-icon": 1,
        "components-button-color-text-white-hover-bg": 0.02,
        "components-button-color-text-white-hover-bg-icon-only": 1,
        "components-button-color-text-white-hover-text": 1,
        "components-button-color-text-white-hover-stroke": 1,
        "components-button-color-text-white-hover-icon": 1,
        "components-button-color-text-white-pressed-bg": 0.02,
        "components-button-color-text-white-pressed-bg-icon-only": 1,
        "components-button-color-text-white-pressed-text": 1,
        "components-button-color-text-white-pressed-stroke": 1,
        "components-button-color-text-white-pressed-icon": 1,
        "components-button-color-text-white-disabled-bg": 0.02,
        "components-button-color-text-white-disabled-text": 0.01,
        "components-button-color-text-white-disabled-stroke": 0.01,
        "components-button-color-text-white-disabled-icon": 0.01,
        "components-button-color-text-white-focus-bg": 1,
        "components-button-color-text-white-focus-text": 1,
        "components-button-color-text-white-focus-stroke": 1,
        "components-button-color-text-white-focus-icon": 1,
        "components-button-color-text-white-focus-outline": 1,
        "components-button-group-color-border": 1,
        "components-button-group-color-button-bg-default": 0.02,
        "components-button-group-color-button-fg-default": 1,
        "components-button-group-color-button-bg-hover": 1,
        "components-button-group-color-button-fg-hover": 1,
        "components-button-group-color-button-bg-active": 1,
        "components-button-group-color-button-fg-active": 1,
        "components-button-group-color-overlay-left-gradient-fill-1": 1,
        "components-button-group-color-overlay-left-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-1": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-3": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-4": 0.02,
        "components-button-group-color-stroke-hover-gradient-fill-1": 1,
        "components-button-group-color-stroke-hover-gradient-fill-2": 1,
        "components-button-group-color-stroke-hover-gradient-fill-3": 1,
        "components-button-group-color-stroke-hover-gradient-fill-4": 1,
        "components-button-group-color-stroke-active-gradient-fill-1": 1,
        "components-button-group-color-stroke-active-gradient-fill-2": 1,
        "components-button-group-color-stroke-active-gradient-fill-3": 1,
        "components-button-group-color-stroke-active-gradient-fill-4": 1,
        "components-category-card-color-bg": 1,
        "components-category-card-color-content-bg": 1,
        "components-category-card-color-category-bg": 1,
        "components-category-card-color-category-bg-2": 1,
        "components-category-card-color-category-bg-3": 1,
        "components-category-card-color-category-bg-4": 1,
        "components-category-card-color-category-bg-5": 1,
        "components-category-card-color-category-bg-6": 1,
        "components-category-card-color-title": 1,
        "components-category-card-color-copy": 1,
        "components-category-card-color-stroke": 1,
        "components-category-card-color-stroke-hover": 1,
        "components-category-card-listing-color-bg": 1,
        "components-category-card-listing-color-title": 1,
        "components-category-card-listing-color-copy": 1,
        "components-card-search-result-color-bg": 1,
        "components-card-search-result-color-label": 1,
        "components-card-search-result-color-title": 1,
        "components-card-search-result-color-body": 1,
        "components-card-search-result-color-divider-line": 1,
        "components-contact-us-promo-color-light-bg": 1,
        "components-contact-us-promo-color-light-title": 1,
        "components-contact-us-promo-color-light-body": 1,
        "components-contact-us-promo-color-default-bg": 1,
        "components-contact-us-promo-color-default-title": 1,
        "components-contact-us-promo-color-default-body": 1,
        "components-contact-us-promo-color-brand-bg": 1,
        "components-contact-us-promo-color-brand-title": 1,
        "components-contact-us-promo-color-brand-body": 1,
        "components-content-list-color-bg": 1,
        "components-content-list-color-title": 1,
        "components-content-list-color-body": 1,
        "components-content-list-color-icon": 1,
        "components-cookies-colors-bg": 1,
        "components-cookies-colors-title": 1,
        "components-cookies-colors-body": 1,
        "components-cookies-colors-icon": 1,
        "components-dropdown-bg": 1,
        "components-dropdown-border-default": 1,
        "components-dropdown-border-hover": 1,
        "components-dropdown-border-focused": 1,
        "components-dropdown-border-destructive": 1,
        "components-dropdown-label-text-default": 1,
        "components-dropdown-item-text-default": 1,
        "components-dropdown-item-bg-default": 1,
        "components-dropdown-item-bg-hover": 1,
        "components-dropdown-input-field-default": 1,
        "components-dropdown-input-field-focused": 1,
        "components-dropdown-input-label-focused": 1,
        "components-dropdown-input-field-destructive": 1,
        "components-external-link-card-bg": 1,
        "components-external-link-card-card-bg": 1,
        "components-external-link-card-card-title": 1,
        "components-external-link-card-card-subtitle": 1,
        "components-external-link-card-card-eyebrow": 1,
        "components-fab-color-border-default": 1,
        "components-footer-brand-color-bg": 1,
        "components-footer-brand-color-logo": 1,
        "components-footer-brand-color-title-text": 1,
        "components-footer-brand-color-description-text": 1,
        "components-footer-brand-color-footer-menu-expanded": 1,
        "components-footer-brand-color-link-default": 1,
        "components-footer-brand-color-link-hover": 1,
        "components-footer-brand-color-link-pressed": 1,
        "components-footer-brand-color-category-link-default": 1,
        "components-footer-brand-color-category-link-hover": 1,
        "components-footer-brand-color-category-link-pressed": 1,
        "components-footer-brand-color-subcategory-link-default": 1,
        "components-footer-brand-color-subcategory-link-hover": 1,
        "components-footer-brand-color-subcategory-link-pressed": 1,
        "components-footer-brand-color-icon-icon-default": 1,
        "components-footer-brand-color-icon-icon-pressed": 1,
        "components-footer-brand-color-social-icon-default": 1,
        "components-footer-brand-color-social-icon-hover": 1,
        "components-footer-brand-color-social-icon-pressed": 1,
        "components-footer-brand-spacing-category-link-hover": 1,
        "components-footer-global-color-bg": 1,
        "components-footer-global-color-title-text": 1,
        "components-footer-global-color-disclaimer-text": 1,
        "components-footer-global-color-link-default": 1,
        "components-footer-global-color-link-hover": 1,
        "components-footer-global-color-link-pressed": 1,
        "components-generic-card-color-bg": 1,
        "components-generic-card-color-content-bg": 1,
        "components-generic-card-color-title": 1,
        "components-generic-card-color-subtitle": 1,
        "components-generic-card-color-copy": 1,
        "components-global-search-color-omnibox-list-bg": 1,
        "components-global-search-color-omnibox-divider": 1,
        "components-global-search-color-omnibox-item-bg-default": 1,
        "components-global-search-color-omnibox-item-bg-hover": 1,
        "components-global-search-color-omnibox-item-text-default": 1,
        "components-global-search-color-omnibox-item-text-hover": 1,
        "components-global-search-color-omnibox-label-text-default": 1,
        "components-global-search-color-omnibox-category-text-default": 1,
        "components-generic-card-listing-color-bg": 1,
        "components-generic-card-listing-color-title": 1,
        "components-generic-card-listing-color-copy": 1,
        "components-header-color-bg": 1,
        "components-header-color-bottom-stroke": 1,
        "components-header-color-search-search-border-default": 1,
        "components-header-color-search-search-text": 1,
        "components-header-color-search-search-icon": 1,
        "components-header-color-search-search-bar": 1,
        "components-header-color-mobile-mobile-expanded-bg": 1,
        "components-header-color-mobile-pressed-bg": 1,
        "components-header-color-language-selector-language": 1,
        "components-header-color-language-selector-country": 1,
        "components-header-color-language-selector-country-hover-bg": 1,
        "components-header-color-language-selector-megamenu-language": 1,
        "components-header-color-language-selector-megamenu-country": 1,
        "components-header-color-language-selector-megamenu-country-hover": 1,
        "components-header-color-language-selector-bg": 1,
        "components-header-color-language-selector-stroke": 1,
        "components-header-color-mega-menu-bg": 1,
        "components-header-color-mega-menu-featured-bg": 1,
        "components-header-color-mega-menu-featured-title": 1,
        "components-header-color-mega-menu-category-title": 1,
        "components-header-color-mega-menu-bg-accent": 1,
        "components-header-color-icon-icon-default": 1,
        "components-header-color-icon-icon-hover": 1,
        "components-header-color-icon-icon-pressed": 1,
        "components-header-color-links-nav-main-link-default": 1,
        "components-header-color-links-nav-main-link-hover": 1,
        "components-header-color-links-nav-main-link-pressed": 1,
        "components-header-color-links-nav-main-link-active": 1,
        "components-header-color-links-nav-main-active-bar": 1,
        "components-header-color-links-nav-main-bg-color": 1,
        "components-header-color-links-nav-category-link-category-default": 1,
        "components-header-color-links-nav-category-link-category-hover": 1,
        "components-header-color-links-nav-category-link-category-pressed": 1,
        "components-header-color-links-nav-category-link-category-active": 1,
        "components-header-color-links-nav-menu-link-link-default": 1,
        "components-header-color-links-nav-menu-link-link-hover": 1,
        "components-header-color-links-nav-menu-link-link-pressed": 1,
        "components-header-color-links-nav-menu-link-link-active": 1,
        "components-header-color-links-nav-menu-link-bg": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-default": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-active": 1,
        "components-hero-homepage-color-bg": 1,
        "components-hero-homepage-color-container-bg": 1,
        "components-hero-homepage-color-eyebrow": 1,
        "components-hero-homepage-color-header": 1,
        "components-hero-homepage-color-sub-header": 1,
        "components-hero-homepage-color-body": 1,
        "components-hero-homepage-color-play-pause": 1,
        "components-hero-internal-color-default-title": 1,
        "components-hero-internal-color-default-body": 1,
        "components-hero-internal-color-default-bg": 1,
        "components-hero-internal-color-accent-bg": 1,
        "components-hero-internal-color-with-image-bg": 1,
        "components-hero-internal-color-default-bg-2": 1,
        "components-hero-internal-color-with-image-title": 1,
        "components-hero-internal-color-with-image-body": 1,
        "components-heading-page-title-color-default-bg": 1,
        "components-heading-page-title-color-default-title": 1,
        "components-heading-page-title-color-default-body": 1,
        "components-heading-page-title-color-light-bg": 1,
        "components-heading-page-title-color-light-title": 1,
        "components-heading-page-title-color-light-body": 1,
        "components-heading-page-title-color-color-bg": 1,
        "components-heading-page-title-color-color-title": 1,
        "components-heading-page-title-color-color-body": 1,
        "components-hyperlink-color-dark-default": 1,
        "components-hyperlink-color-dark-hover": 1,
        "components-hyperlink-color-dark-visited": 1,
        "components-hyperlink-color-light-default": 1,
        "components-hyperlink-color-light-hover": 1,
        "components-hyperlink-color-light-visited": 1,
        "components-icon-default": 1,
        "components-icon-focused": 1,
        "components-icon-card-color-bg": 1,
        "components-icon-card-color-icon-bg": 1,
        "components-icon-card-color-icon": 1,
        "components-icon-card-color-title": 1,
        "components-icon-card-color-subtitle": 1,
        "components-icon-card-color-description": 1,
        "components-icon-card-listing-color-bg": 1,
        "components-icon-card-listing-color-inner-bg": 1,
        "components-icon-card-listing-color-brand-bg": 1,
        "components-icon-card-listing-color-title": 1,
        "components-icon-card-listing-color-copy": 1,
        "components-icon-card-listing-color-inner-legal-text": 1,
        "components-jumplink-color-bg-default": 1,
        "components-jumplink-color-link-text-default": 1,
        "components-jumplink-color-link-text-hover": 1,
        "components-jumplink-color-link-border-hover": 1,
        "components-jumplink-color-link-text-active": 1,
        "components-jumplink-color-link-border-active": 1,
        "components-jumplink-color-title-text": 1,
        "components-jumplink-color-description-text": 1,
        "components-layout-bg-default": 1,
        "components-lightbox-color-overlay-bg": 0.02,
        "components-lightbox-color-title": 1,
        "components-lightbox-color-body": 1,
        "components-label-color-on-color-label-label-default": 1,
        "components-label-color-on-color-label-label-hover": 1,
        "components-label-color-on-color-label-label-pressed": 1,
        "components-label-color-on-color-background-bg-default": 1,
        "components-label-color-on-color-background-bg-hover": 1,
        "components-label-color-on-color-background-bg-pressed": 1,
        "components-label-color-on-color-stroke-hover": 1,
        "components-label-color-on-color-stroke-pressed": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-4": 1,
        "components-label-color-on-white-label-label-default": 1,
        "components-label-color-on-white-label-label-hover": 1,
        "components-label-color-on-white-label-label-pressed": 1,
        "components-label-color-on-white-background-bg-default": 0.02,
        "components-label-color-on-white-background-bg-hover": 1,
        "components-label-color-on-white-background-bg-pressed": 1,
        "components-label-color-on-white-stroke-hover": 1,
        "components-label-color-on-white-stroke-pressed": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-4": 1,
        "components-legal-disclaimer-color-default-bg": 1,
        "components-legal-disclaimer-color-default-title": 1,
        "components-legal-disclaimer-color-default-body": 1,
        "components-legal-disclaimer-color-light-bg": 1,
        "components-legal-disclaimer-color-light-title": 1,
        "components-legal-disclaimer-color-light-body": 1,
        "components-legal-disclaimer-color-brand-bg": 1,
        "components-legal-disclaimer-color-brand-title": 1,
        "components-legal-disclaimer-color-brand-body": 1,
        "components-media-video-thumbnail-overlay-bg": null,
        "components-media-caption-color-bg": 1,
        "components-media-caption-color-text": 1,
        "components-media-gallery-color-bg": 1,
        "components-media-gallery-color-title": 1,
        "components-media-gallery-color-body": 1,
        "components-pagination-on-white-accent-default": 1,
        "components-pagination-on-white-accent-scroll": 1,
        "components-pagination-on-white-disabled": 1,
        "components-pagination-on-white-bg": 1,
        "components-pagination-on-white-stroke": 1,
        "components-pagination-on-white-bg-active": 1,
        "components-pagination-on-white-bg-hover": 1,
        "components-pagination-on-white-text-default": 1,
        "components-pagination-on-white-text-hover": 1,
        "components-pagination-on-white-text-active": 1,
        "components-pagination-on-color-accent-default": 1,
        "components-pagination-on-color-accent-scroll": 1,
        "components-pagination-on-color-disabled": 1,
        "components-pagination-on-color-bg": 1,
        "components-pagination-on-color-stroke": 0.02,
        "components-pagination-on-color-bg-active": 1,
        "components-pagination-on-color-bg-hover": 1,
        "components-pagination-on-color-text-default": 1,
        "components-pagination-on-color-text-hover": 1,
        "components-pagination-on-color-text-active": 1,
        "components-pdf-link-card-color-bg": 1,
        "components-pdf-link-card-color-card-bg": 1,
        "components-pdf-link-card-color-card-title": 1,
        "components-pdf-link-card-color-card-subtitle": 1,
        "components-pdf-link-card-color-card-body": 1,
        "components-product-card-color-bg": 1,
        "components-product-card-color-content-bg": 1,
        "components-product-card-color-border": 1,
        "components-product-card-color-title": 1,
        "components-product-card-color-description": 1,
        "components-product-card-color-star-rating": 1,
        "components-product-card-color-badge-bg": 1,
        "components-product-card-color-badge-text": 1,
        "components-product-card-listing-color-bg": 1,
        "components-product-card-listing-color-title": 1,
        "components-product-card-listing-color-copy": 1,
        "components-product-card-listing-color-legal-text": 1,
        "components-product-information-color-bg": 1,
        "components-product-information-color-content-bg": 1,
        "components-product-information-color-headline": 1,
        "components-product-information-color-body": 1,
        "components-product-information-color-detail-text": 1,
        "components-product-information-color-divider": 1,
        "components-product-information-color-icon": 1,
        "components-product-information-color-play-icon": 1,
        "components-product-information-color-selected-stroke": 1,
        "components-product-results-list-with-search-color-bg": 1,
        "components-promo-color-default-bg": 1,
        "components-promo-color-default-image-bg": 1,
        "components-promo-color-default-content-bg": 1,
        "components-promo-color-default-title": 1,
        "components-promo-color-default-body": 1,
        "components-promo-color-default-category": 1,
        "components-promo-color-light-bg": 1,
        "components-promo-color-light-image-bg": 1,
        "components-promo-color-light-content-bg": 1,
        "components-promo-color-light-title": 1,
        "components-promo-color-light-body": 1,
        "components-promo-color-light-category": 1,
        "components-promo-color-brand-bg": 1,
        "components-promo-color-brand-image-bg": 1,
        "components-promo-color-brand-content-bg": 1,
        "components-promo-color-brand-title": 1,
        "components-promo-color-brand-body": 1,
        "components-promo-color-brand-category": 1,
        "components-pull-quote-color-bg": 1,
        "components-pull-quote-color-stroke": 1,
        "components-pull-quote-color-quote-bg": 1,
        "components-pull-quote-color-quote-title": 1,
        "components-pull-quote-color-quote-by-text": 1,
        "components-pull-quote-color-quote-detail": 1,
        "components-pull-quote-color-quote-highlight": 1,
        "components-promo-alternate-color-default-bg": 1,
        "components-promo-alternate-color-default-image-bg": 1,
        "components-promo-alternate-color-default-content-bg": 1,
        "components-promo-alternate-color-default-title": 1,
        "components-promo-alternate-color-default-body": 1,
        "components-promo-alternate-color-default-category": 1,
        "components-promo-alternate-color-light-bg": 1,
        "components-promo-alternate-color-light-image-bg": 1,
        "components-promo-alternate-color-light-content-bg": 1,
        "components-promo-alternate-color-light-title": 1,
        "components-promo-alternate-color-light-body": 1,
        "components-promo-alternate-color-light-category": 1,
        "components-promo-alternate-color-brand-bg": 1,
        "components-promo-alternate-color-brand-image-bg": 1,
        "components-promo-alternate-color-brand-content-bg": 1,
        "components-promo-alternate-color-brand-title": 1,
        "components-promo-alternate-color-brand-body": 1,
        "components-promo-alternate-color-brand-category": 1,
        "components-share-list-color-title": 1,
        "components-share-list-color-bg": 1,
        "components-share-list-color-link-default": 1,
        "components-share-list-color-link-hover": 1,
        "components-share-list-color-bg-link-hover": 1,
        "components-share-list-color-close-icon": 1,
        "components-share-list-color-facebook-icon": 1,
        "components-share-list-color-pinterest-icon": 1,
        "components-share-list-color-twitter-icon": 1,
        "components-share-list-color-basic-icon-default": 1,
        "components-share-list-color-icon-hover": 1,
        "components-share-list-color-border": 1,
        "components-site-search-bg": 1,
        "components-site-search-results_text": 1,
        "components-site-search-filter-filter-selected-bg": 1,
        "components-site-search-facet-group-label-bottom-line": 1,
        "components-site-search-facet-group-label-text": 1,
        "components-site-search-facet-group-label-bg": 1,
        "components-site-search-facet-bg": 1,
        "components-site-search-facet-stroke": 1,
        "components-site-search-refine-bg": 1,
        "components-site-search-refine-right-divider": 1,
        "components-site-search-refine-title": 1,
        "components-site-search-hero-search-bg": 1,
        "components-site-search-hero-search-accent-bg": 1,
        "components-site-search-hero-search-title": 1,
        "components-stats-stat-text": 1,
        "components-stats-stat-text-on-light": 1,
        "components-stats-supporting-text": 1,
        "components-stats-bg-default": 1,
        "components-stats-bg-inner": 1,
        "components-stats-bg-section-inner": 1,
        "components-stats-title": 1,
        "components-stats-copy": 1,
        "components-supporting-text-default": 1,
        "components-supporting-text-destructive": 1,
        "components-tab-bg": 1,
        "components-tab-border-default": 1,
        "components-tab-border-hover": 1,
        "components-tab-border-focused": 1,
        "components-tab-border-destructive": 1,
        "components-tab-label-text-default": 1,
        "components-tab-item-text-default": 1,
        "components-tab-item-bg-default": 1,
        "components-tab-item-bg-hover": 1,
        "components-tab-input-field-default": 1,
        "components-tab-input-field-focused": 1,
        "components-tab-input-label-focused": 1,
        "components-tab-input-field-destructive": 1,
        "components-tab-bg-2": 1,
        "components-tag-color-bg": 1,
        "components-tag-color-text": 1,
        "components-tag-color-stroke": 1,
        "components-tag-color-bg-hover": 1,
        "components-tag-color-text-hover": 1,
        "components-tag-color-stroke-hover": 1,
        "components-text-field-bg": 1,
        "components-text-field-bg-focused": 1,
        "components-text-field-border-default": 1,
        "components-text-field-border-hover": 1,
        "components-text-field-border-focused": 1,
        "components-text-field-border-destructive": 1,
        "components-text-field-input-field-default": 1,
        "components-text-field-input-field-focused": 1,
        "components-text-field-input-label-focused": 1,
        "components-text-field-input-field-destructive": 1,
        "components-text-field-icon-default": 1,
        "components-text-field-icon-destructive": 1,
        "components-text-field-supporting-text-default": 1,
        "components-text-field-supporting-text-destructive": 1,
        "components-tip-color-bg": 1,
        "components-tip-color-title": 1,
        "components-tip-color-body": 1,
        "components-tip-color-stroke": 1,
        "components-video-color-bg": 1,
        "themes-general-surface-color-light-bg": 1,
        "themes-general-surface-color-light-title": 1,
        "themes-general-surface-color-light-body": 1,
        "themes-general-surface-color-light-decor-line-break": 1,
        "themes-general-surface-color-light-rule-line": 1,
        "themes-general-surface-color-dark-bg": 1,
        "themes-general-surface-color-dark-title": 1,
        "themes-general-surface-color-dark-body": 1,
        "themes-general-surface-color-dark-decor-line-break": 1,
        "themes-general-card-color-dark-title": 1,
        "themes-general-card-color-dark-subtitle": 1,
        "themes-general-card-color-dark-body": 1,
        "themes-general-card-color-light-title": 1,
        "themes-general-card-color-light-subtitle": 1,
        "themes-general-card-color-light-body": 1,
        "themes-shadow-color": 1,
        "themes-shadow-share-list-color-fill": 0,
        "themes-shadow-header-menu-color-fill": 0,
        "themes-gradient-loader-gradient-fill-1": 1,
        "themes-gradient-loader-gradient-fill-2": 0.03,
        "themes-color-background-white": 1,
        "themes-color-background-light": 1,
        "themes-color-background-dark": 1,
        "themes-color-background-black": 1,
        "themes-color-background-brand1": 1,
        "themes-color-background-brand2": 1,
        "themes-color-background-accent1": 1,
        "themes-color-background-accent2": 1,
        "themes-color-background-body": 1,
        "themes-color-stroke-white": 1,
        "themes-color-stroke-light": 1,
        "themes-color-stroke-dark": 1,
        "themes-color-stroke-black": 1,
        "themes-color-stroke-brand1": 1,
        "themes-color-stroke-brand2": 1,
        "themes-color-stroke-accent1": 1,
        "themes-color-stroke-accent2": 1,
        "themes-color-text-white": 1,
        "themes-color-text-light": 1,
        "themes-color-text-dark": 1,
        "themes-color-text-black": 1,
        "themes-color-text-brand1": 1,
        "themes-color-text-brand2": 1,
        "themes-color-text-accent1": 1,
        "themes-color-text-accent2": 1,
        "colors-grayscale-100": 1,
        "colors-grayscale-200": 1,
        "colors-grayscale-300": 1,
        "colors-grayscale-400": 1,
        "colors-grayscale-500": 1,
        "colors-grayscale-600": 1,
        "colors-grayscale-700": 1,
        "colors-brand-1-100": 1,
        "colors-brand-1-200": 1,
        "colors-brand-1-300": 1,
        "colors-brand-1-400": 1,
        "colors-brand-1-500": 1,
        "colors-brand-1-600": 1,
        "colors-brand-1-700": 1,
        "colors-brand-2-100": 1,
        "colors-brand-2-200": 1,
        "colors-brand-2-300": 1,
        "colors-brand-2-400": 1,
        "colors-brand-2-500": 1,
        "colors-brand-2-600": 1,
        "colors-brand-2-700": 1,
        "colors-accent-1-100": 1,
        "colors-accent-1-200": 1,
        "colors-accent-1-300": 1,
        "colors-accent-1-400": 1,
        "colors-accent-1-500": 1,
        "colors-accent-1-600": 1,
        "colors-accent-1-700": 1,
        "colors-accent-2-100": 1,
        "colors-accent-2-200": 1,
        "colors-accent-2-300": 1,
        "colors-accent-2-400": 1,
        "colors-accent-2-500": 1,
        "colors-accent-2-600": 1,
        "colors-accent-2-700": 1,
        "colors-accent-3-100": 1,
        "colors-accent-3-200": 1,
        "colors-accent-3-300": 1,
        "colors-accent-3-400": 1,
        "colors-accent-3-500": 1,
        "colors-accent-3-600": 1,
        "colors-accent-3-700": 1,
        "colors-accent-4-400_storage": 1,
        "colors-accessible-accent-1-100": 1,
        "colors-accessible-accent-1-200": 1,
        "colors-accessible-accent-1-300": 1,
        "colors-accessible-accent-1-400": 1,
        "colors-accessible-accent-1-500": 1,
        "colors-accessible-accent-1-600": 1,
        "colors-accessible-accent-1-700": 1,
        "colors-accessible-accent-2-100": 1,
        "colors-accessible-accent-2-200": 1,
        "colors-accessible-accent-2-300": 1,
        "colors-accessible-accent-2-400": 1,
        "colors-accessible-accent-2-500": 1,
        "colors-accessible-accent-2-600": 1,
        "colors-accessible-accent-2-700": 1,
        "colors-accessible-brand-2-100": 1,
        "colors-accessible-brand-2-200": 1,
        "colors-accessible-brand-2-300": 1,
        "colors-accessible-brand-2-400": 1,
        "colors-accessible-brand-2-500": 1,
        "colors-accessible-brand-2-600": 1,
        "colors-accessible-brand-2-700": 1
      }
    },
    "PIDS": {
      "colors": {
        "components-accessibility-bar-color-bg": "#a82220",
        "components-accessibility-bar-color-text": "#ffffff",
        "components-accordion-color-bg": "#ffffff",
        "components-accordion-color-title": "#000000",
        "components-accordion-color-body": "#121111",
        "components-accordion-color-section-section-bg": "#ffffff",
        "components-accordion-color-section-section-title": "#000000",
        "components-accordion-color-section-section-body": "#121111",
        "components-accordion-color-item-item-bg": "#ffffff",
        "components-accordion-color-item-divider": "#eae8e8",
        "components-accordion-color-item-icon-icon-default": "#0b40a3",
        "components-accordion-color-item-icon-icon-hover": "#ffffff",
        "components-accordion-color-item-icon-icon-pressed": "#ffffff",
        "components-accordion-color-item-icon-icon-active": "#ffffff",
        "components-accordion-color-item-title-item-default": "#0b40a3",
        "components-accordion-color-item-title-item-hover": "#ffffff",
        "components-accordion-color-item-title-item-pressed": "#ffffff",
        "components-accordion-color-item-title-item-active": "#ffffff",
        "components-accordion-color-content-bg": "#ffffff",
        "components-accordion-color-content-title": "#000000",
        "components-accordion-color-content-body": "#121111",
        "components-article-card-color-bg": "#f0f2f7",
        "components-article-card-color-border": "#f0f2f7",
        "components-article-card-color-content-bg": "#f0f2f7",
        "components-article-card-color-title": "#000000",
        "components-article-card-color-subtitle": "#121111",
        "components-article-card-color-copy": "#121111",
        "components-article-card-color-description": "#121111",
        "components-article-card-listing-color-default-bg": "#ffffff",
        "components-article-card-listing-color-default-title": "#000000",
        "components-article-card-listing-color-default-copy": "#121111",
        "components-article-hero-color-content-bg": "#ffffff",
        "components-article-hero-color-bg": "#ffffff",
        "components-article-hero-color-headline": "#000000",
        "components-article-hero-color-body": "#121111",
        "components-article-hero-color-detail-text": "#121111",
        "components-article-hero-color-divider": "#aba8a6",
        "components-article-hero-color-icon": "#d92c29",
        "components-article-hero-color-play-icon": "#ffffff",
        "components-article-hero-color-selected-stroke": "#d92c29",
        "components-banner-color-bg-default": "#f0f2f7",
        "components-banner-color-icon-default": "#d92c29",
        "components-banner-color-title-default": "#121111",
        "components-banner-color-body-default": "#373432",
        "components-banner-color-icon-close-default": "#121111",
        "components-banner-color-bg-alert": "#b32b30",
        "components-banner-color-icon-alert": "#ffffff",
        "components-banner-color-title-alert": "#ffffff",
        "components-banner-color-body-alert": "#ffffff",
        "components-banner-color-icon-close-alert": "#ffffff",
        "components-breadcrumb-color-bg": "#ffffff",
        "components-breadcrumb-color-breadcrumb-link-default": "#0b40a3",
        "components-breadcrumb-color-breadcrumb-link-hover": "#09327e",
        "components-breadcrumb-color-breadcrumb-link-active": "#121111",
        "components-breadcrumb-color-icon-divider": "#5b5654",
        "components-bug-finder-prompt": "#ffffff",
        "components-button-color-filled-brand-default-bg": "#000000",
        "components-button-color-filled-brand-default-text": "#ffffff",
        "components-button-color-filled-brand-default-stroke": "#000000",
        "components-button-color-filled-brand-default-icon": "#ffffff",
        "components-button-color-filled-brand-hover-bg": "#c22725",
        "components-button-color-filled-brand-hover-text": "#ffffff",
        "components-button-color-filled-brand-hover-stroke": "#c22725",
        "components-button-color-filled-brand-hover-icon": "#ffffff",
        "components-button-color-filled-brand-pressed-bg": "#a82220",
        "components-button-color-filled-brand-pressed-text": "#ffffff",
        "components-button-color-filled-brand-pressed-stroke": "#a82220",
        "components-button-color-filled-brand-pressed-icon": "#ffffff",
        "components-button-color-filled-brand-disabled-bg": "rgba(0, 0, 0, 0.38)",
        "components-button-color-filled-brand-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-disabled-stroke": "rgba(0, 0, 0, 0.38)",
        "components-button-color-filled-brand-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-focus-bg": "#000000",
        "components-button-color-filled-brand-focus-text": "#ffffff",
        "components-button-color-filled-brand-focus-stroke": "#000000",
        "components-button-color-filled-brand-focus-icon": "#ffffff",
        "components-button-color-filled-brand-focus-outline": "#286efa",
        "components-button-color-filled-tonal-default-bg": "#f0f2f7",
        "components-button-color-filled-tonal-default-text": "#0b40a3",
        "components-button-color-filled-tonal-default-stroke": "#0b40a3",
        "components-button-color-filled-tonal-default-icon": "#0b40a3",
        "components-button-color-filled-tonal-hover-bg": "#c6cadf",
        "components-button-color-filled-tonal-hover-text": "#072867",
        "components-button-color-filled-tonal-hover-stroke": "#072867",
        "components-button-color-filled-tonal-hover-icon": "#072867",
        "components-button-color-filled-tonal-pressed-bg": "#c6cadf",
        "components-button-color-filled-tonal-pressed-text": "#072867",
        "components-button-color-filled-tonal-pressed-stroke": "#072867",
        "components-button-color-filled-tonal-pressed-icon": "#072867",
        "components-button-color-filled-tonal-disabled-bg": "rgba(228, 230, 239, 0.38)",
        "components-button-color-filled-tonal-disabled-text": "rgba(7, 40, 103, 0.38)",
        "components-button-color-filled-tonal-disabled-stroke": "rgba(228, 230, 239, 0.38)",
        "components-button-color-filled-tonal-disabled-icon": "rgba(7, 40, 103, 0.38)",
        "components-button-color-filled-tonal-focus-bg": "#f0f2f7",
        "components-button-color-filled-tonal-focus-text": "#0b40a3",
        "components-button-color-filled-tonal-focus-stroke": "#0b40a3",
        "components-button-color-filled-tonal-focus-icon": "#0b40a3",
        "components-button-color-filled-tonal-focus-outline": "#1c4cae",
        "components-button-color-filled-white-default-bg": "#ffffff",
        "components-button-color-filled-white-default-text": "#000000",
        "components-button-color-filled-white-default-stroke": "#ffffff",
        "components-button-color-filled-white-default-icon": "#000000",
        "components-button-color-filled-white-hover-bg": "#eae8e8",
        "components-button-color-filled-white-hover-text": "#000000",
        "components-button-color-filled-white-hover-stroke": "#c6cadf",
        "components-button-color-filled-white-hover-icon": "#000000",
        "components-button-color-filled-white-pressed-bg": "#aba8a6",
        "components-button-color-filled-white-pressed-text": "#000000",
        "components-button-color-filled-white-pressed-stroke": "#c6cadf",
        "components-button-color-filled-white-pressed-icon": "#000000",
        "components-button-color-filled-white-disabled-bg": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-text": "rgba(0, 0, 0, 0.38)",
        "components-button-color-filled-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-icon": "rgba(0, 0, 0, 0.38)",
        "components-button-color-filled-white-focus-bg": "#ffffff",
        "components-button-color-filled-white-focus-text": "#000000",
        "components-button-color-filled-white-focus-stroke": "#ffffff",
        "components-button-color-filled-white-focus-icon": "#000000",
        "components-button-color-filled-white-focus-outline": "#1c4cae",
        "components-button-color-outline-brand-default-bg": "rgba(0, 83, 180, 0.00)",
        "components-button-color-outline-brand-default-text": "#0b40a3",
        "components-button-color-outline-brand-default-stroke": "#0b40a3",
        "components-button-color-outline-brand-default-icon": "#0b40a3",
        "components-button-color-outline-brand-hover-bg": "#c22725",
        "components-button-color-outline-brand-hover-text": "#ffffff",
        "components-button-color-outline-brand-hover-stroke": "#c22725",
        "components-button-color-outline-brand-hover-icon": "#ffffff",
        "components-button-color-outline-brand-pressed-bg": "#a82220",
        "components-button-color-outline-brand-pressed-text": "#ffffff",
        "components-button-color-outline-brand-pressed-stroke": "#a82220",
        "components-button-color-outline-brand-pressed-icon": "#ffffff",
        "components-button-color-outline-brand-disabled-bg": "rgba(0, 83, 180, 0.00)",
        "components-button-color-outline-brand-disabled-text": "rgba(0, 83, 180, 0.38)",
        "components-button-color-outline-brand-disabled-stroke": "rgba(0, 83, 180, 0.38)",
        "components-button-color-outline-brand-disabled-icon": "rgba(0, 83, 180, 0.38)",
        "components-button-color-outline-brand-focus-bg": "#ffffff",
        "components-button-color-outline-brand-focus-text": "#0b40a3",
        "components-button-color-outline-brand-focus-stroke": "#0b40a3",
        "components-button-color-outline-brand-focus-icon": "#0b40a3",
        "components-button-color-outline-brand-focus-outline": "#286efa",
        "components-button-color-outline-tonal-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-tonal-default-text": "#09327e",
        "components-button-color-outline-tonal-default-stroke": "#c6cadf",
        "components-button-color-outline-tonal-default-icon": "#09327e",
        "components-button-color-outline-tonal-hover-bg": "#c6cadf",
        "components-button-color-outline-tonal-hover-text": "#072867",
        "components-button-color-outline-tonal-hover-stroke": "#09327e",
        "components-button-color-outline-tonal-hover-icon": "#072867",
        "components-button-color-outline-tonal-pressed-bg": "#09327e",
        "components-button-color-outline-tonal-pressed-text": "#ffffff",
        "components-button-color-outline-tonal-pressed-stroke": "#09327e",
        "components-button-color-outline-tonal-pressed-icon": "#ffffff",
        "components-button-color-outline-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-tonal-disabled-text": "rgba(7, 40, 103, 0.38)",
        "components-button-color-outline-tonal-disabled-stroke": "rgba(7, 40, 103, 0.38)",
        "components-button-color-outline-tonal-disabled-icon": "rgba(0, 83, 180, 0.38)",
        "components-button-color-outline-tonal-focus-bg": "#ffffff",
        "components-button-color-outline-tonal-focus-text": "#09327e",
        "components-button-color-outline-tonal-focus-stroke": "#c6cadf",
        "components-button-color-outline-tonal-focus-icon": "#09327e",
        "components-button-color-outline-tonal-focus-outline": "#286efa",
        "components-button-color-outline-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-default-text": "#ffffff",
        "components-button-color-outline-white-default-stroke": "#ffffff",
        "components-button-color-outline-white-default-icon": "#ffffff",
        "components-button-color-outline-white-hover-bg": "#ffffff",
        "components-button-color-outline-white-hover-text": "#000000",
        "components-button-color-outline-white-hover-stroke": "#ffffff",
        "components-button-color-outline-white-hover-icon": "#000000",
        "components-button-color-outline-white-pressed-bg": "#eae8e8",
        "components-button-color-outline-white-pressed-text": "#000000",
        "components-button-color-outline-white-pressed-stroke": "#eae8e8",
        "components-button-color-outline-white-pressed-icon": "#000000",
        "components-button-color-outline-white-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-focus-bg": "rgba(255, 255, 255, 0.01)",
        "components-button-color-outline-white-focus-text": "#ffffff",
        "components-button-color-outline-white-focus-stroke": "#ffffff",
        "components-button-color-outline-white-focus-icon": "#ffffff",
        "components-button-color-outline-white-focus-outline": "#286efa",
        "components-button-color-text-brand-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-default-text": "#0b40a3",
        "components-button-color-text-brand-default-stroke": "#0b40a3",
        "components-button-color-text-brand-default-icon": "#0b40a3",
        "components-button-color-text-brand-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-hover-bg-icon-only": "#f8e5e5",
        "components-button-color-text-brand-hover-text": "#000000",
        "components-button-color-text-brand-hover-stroke": "#d92c29",
        "components-button-color-text-brand-hover-icon": "#000000",
        "components-button-color-text-brand-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-pressed-bg-icon-only": "#f1c7c7",
        "components-button-color-text-brand-pressed-text": "#000000",
        "components-button-color-text-brand-pressed-stroke": "#000000",
        "components-button-color-text-brand-pressed-icon": "#000000",
        "components-button-color-text-brand-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-disabled-text": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-brand-disabled-stroke": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-brand-disabled-icon": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-brand-focus-bg": "#d5e3ff",
        "components-button-color-text-brand-focus-text": "#09327e",
        "components-button-color-text-brand-focus-stroke": "#09327e",
        "components-button-color-text-brand-focus-icon": "#1c4cae",
        "components-button-color-text-brand-focus-outline": "#1c4cae",
        "components-button-color-text-tonal-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-default-text": "#0b40a3",
        "components-button-color-text-tonal-default-stroke": "#0b40a3",
        "components-button-color-text-tonal-default-icon": "#0b40a3",
        "components-button-color-text-tonal-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-hover-bg-icon-only": "#e4e6ef",
        "components-button-color-text-tonal-hover-text": "#09327e",
        "components-button-color-text-tonal-hover-stroke": "#0b40a3",
        "components-button-color-text-tonal-hover-icon": "#09327e",
        "components-button-color-text-tonal-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-pressed-bg-icon-only": "#c6cadf",
        "components-button-color-text-tonal-pressed-text": "#072867",
        "components-button-color-text-tonal-pressed-stroke": "#09327e",
        "components-button-color-text-tonal-pressed-icon": "#072867",
        "components-button-color-text-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-disabled-text": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-tonal-disabled-stroke": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-tonal-disabled-icon": "rgba(0, 83, 180, 0.38)",
        "components-button-color-text-tonal-focus-bg": "#d5e3ff",
        "components-button-color-text-tonal-focus-text": "#09327e",
        "components-button-color-text-tonal-focus-stroke": "#09327e",
        "components-button-color-text-tonal-focus-icon": "#1c4cae",
        "components-button-color-text-tonal-focus-outline": "#1c4cae",
        "components-button-color-text-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-default-text": "#ffffff",
        "components-button-color-text-white-default-stroke": "#ffffff",
        "components-button-color-text-white-default-icon": "#ffffff",
        "components-button-color-text-white-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-hover-bg-icon-only": "#72757e",
        "components-button-color-text-white-hover-text": "#ffffff",
        "components-button-color-text-white-hover-stroke": "#ffffff",
        "components-button-color-text-white-hover-icon": "#ffffff",
        "components-button-color-text-white-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-pressed-bg-icon-only": "#5b5654",
        "components-button-color-text-white-pressed-text": "#ffffff",
        "components-button-color-text-white-pressed-stroke": "#ffffff",
        "components-button-color-text-white-pressed-icon": "#ffffff",
        "components-button-color-text-white-disabled-bg": "rgba(115, 119, 128, 0.00)",
        "components-button-color-text-white-disabled-text": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-stroke": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-icon": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-focus-bg": "#d5e3ff",
        "components-button-color-text-white-focus-text": "#ffffff",
        "components-button-color-text-white-focus-stroke": "#ffffff",
        "components-button-color-text-white-focus-icon": "#1c4cae",
        "components-button-color-text-white-focus-outline": "#1c4cae",
        "components-button-group-color-border": "#eae8e8",
        "components-button-group-color-button-bg-default": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-button-fg-default": "#0b40a3",
        "components-button-group-color-button-bg-hover": "#c6cadf",
        "components-button-group-color-button-fg-hover": "#072867",
        "components-button-group-color-button-bg-active": "#000000",
        "components-button-group-color-button-fg-active": "#ffffff",
        "components-button-group-color-overlay-left-gradient-fill-1": "#ffffff",
        "components-button-group-color-overlay-left-gradient-fill-2": "rgba(255, 255, 255, 0.50)",
        "components-button-group-color-stroke-default-gradient-fill-1": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-2": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-3": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-4": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-1": "#c6cadf",
        "components-button-group-color-stroke-hover-gradient-fill-2": "#c6cadf",
        "components-button-group-color-stroke-hover-gradient-fill-3": "#c6cadf",
        "components-button-group-color-stroke-hover-gradient-fill-4": "#c6cadf",
        "components-button-group-color-stroke-active-gradient-fill-1": "#000000",
        "components-button-group-color-stroke-active-gradient-fill-2": "#000000",
        "components-button-group-color-stroke-active-gradient-fill-3": "#000000",
        "components-button-group-color-stroke-active-gradient-fill-4": "#000000",
        "components-category-card-color-bg": "#f0f2f7",
        "components-category-card-color-content-bg": "#ffffff",
        "components-category-card-color-category-bg": "#f0f2f7",
        "components-category-card-color-category-bg-2": "#f0f2f7",
        "components-category-card-color-category-bg-3": "#f0f2f7",
        "components-category-card-color-category-bg-4": "#f0f2f7",
        "components-category-card-color-category-bg-5": "#f0f2f7",
        "components-category-card-color-category-bg-6": "#f0f2f7",
        "components-category-card-color-title": "#000000",
        "components-category-card-color-copy": "#121111",
        "components-category-card-color-stroke": "#f0f2f7",
        "components-category-card-color-stroke-hover": "#d92c29",
        "components-category-card-listing-color-bg": "#ffffff",
        "components-category-card-listing-color-title": "#000000",
        "components-category-card-listing-color-copy": "#121111",
        "components-card-search-result-color-bg": "#ffffff",
        "components-card-search-result-color-label": "#121111",
        "components-card-search-result-color-title": "#000000",
        "components-card-search-result-color-body": "#121111",
        "components-card-search-result-color-divider-line": "#f0f2f7",
        "components-contact-us-promo-color-light-bg": "#c22725",
        "components-contact-us-promo-color-light-title": "#ffffff",
        "components-contact-us-promo-color-light-body": "#f0f2f7",
        "components-contact-us-promo-color-default-bg": "#f0f2f7",
        "components-contact-us-promo-color-default-title": "#000000",
        "components-contact-us-promo-color-default-body": "#121111",
        "components-contact-us-promo-color-brand-bg": "#000000",
        "components-contact-us-promo-color-brand-title": "#ffffff",
        "components-contact-us-promo-color-brand-body": "#f0f2f7",
        "components-content-list-color-bg": "#ffffff",
        "components-content-list-color-title": "#000000",
        "components-content-list-color-body": "#121111",
        "components-content-list-color-icon": "#c22725",
        "components-cookies-colors-bg": "#ffffff",
        "components-cookies-colors-title": "#000000",
        "components-cookies-colors-body": "#121111",
        "components-cookies-colors-icon": "#c22725",
        "components-dropdown-bg": "#ffffff",
        "components-dropdown-border-default": "#72757e",
        "components-dropdown-border-hover": "#d92c29",
        "components-dropdown-border-focused": "#000000",
        "components-dropdown-border-destructive": "#b32b30",
        "components-dropdown-label-text-default": "#121111",
        "components-dropdown-item-text-default": "#121111",
        "components-dropdown-item-bg-default": "#ffffff",
        "components-dropdown-item-bg-hover": "#e4e6ef",
        "components-dropdown-input-field-default": "#72757e",
        "components-dropdown-input-field-focused": "#121111",
        "components-dropdown-input-label-focused": "#000000",
        "components-dropdown-input-field-destructive": "#b32b30",
        "components-external-link-card-bg": "#f0f2f7",
        "components-external-link-card-card-bg": "#ffffff",
        "components-external-link-card-card-title": "#000000",
        "components-external-link-card-card-subtitle": "#121111",
        "components-external-link-card-card-eyebrow": "#5b5654",
        "components-fab-color-border-default": "#ffffff",
        "components-footer-brand-color-bg": "#000000",
        "components-footer-brand-color-logo": "#ffffff",
        "components-footer-brand-color-title-text": "#ffffff",
        "components-footer-brand-color-description-text": "#f0f2f7",
        "components-footer-brand-color-footer-menu-expanded": "#000000",
        "components-footer-brand-color-link-default": "#ffffff",
        "components-footer-brand-color-link-hover": "#fbf2f2",
        "components-footer-brand-color-link-pressed": "#f8e5e5",
        "components-footer-brand-color-category-link-default": "#ffffff",
        "components-footer-brand-color-category-link-hover": "#f0f2f7",
        "components-footer-brand-color-category-link-pressed": "#eae8e8",
        "components-footer-brand-color-subcategory-link-default": "#f0f2f7",
        "components-footer-brand-color-subcategory-link-hover": "#fbf2f2",
        "components-footer-brand-color-subcategory-link-pressed": "#f1c7c7",
        "components-footer-brand-color-icon-icon-default": "#ffffff",
        "components-footer-brand-color-icon-icon-pressed": "#eae8e8",
        "components-footer-brand-color-social-icon-default": "#ffffff",
        "components-footer-brand-color-social-icon-hover": "#f8e5e5",
        "components-footer-brand-color-social-icon-pressed": "#c22725",
        "components-footer-brand-spacing-category-link-hover": "#000000",
        "components-footer-global-color-bg": "#ffffff",
        "components-footer-global-color-title-text": "#000000",
        "components-footer-global-color-disclaimer-text": "#000000",
        "components-footer-global-color-link-default": "#000000",
        "components-footer-global-color-link-hover": "#000000",
        "components-footer-global-color-link-pressed": "#000000",
        "components-generic-card-color-bg": "#000000",
        "components-generic-card-color-content-bg": "#000000",
        "components-generic-card-color-title": "#ffffff",
        "components-generic-card-color-subtitle": "#f0f2f7",
        "components-generic-card-color-copy": "#f0f2f7",
        "components-global-search-color-omnibox-list-bg": "#ffffff",
        "components-global-search-color-omnibox-divider": "#aba8a6",
        "components-global-search-color-omnibox-item-bg-default": "#ffffff",
        "components-global-search-color-omnibox-item-bg-hover": "#f0f2f7",
        "components-global-search-color-omnibox-item-text-default": "#0b40a3",
        "components-global-search-color-omnibox-item-text-hover": "#09327e",
        "components-global-search-color-omnibox-label-text-default": "#121111",
        "components-global-search-color-omnibox-category-text-default": "#121111",
        "components-generic-card-listing-color-bg": "#ffffff",
        "components-generic-card-listing-color-title": "#000000",
        "components-generic-card-listing-color-copy": "#121111",
        "components-header-color-bg": "#ffffff",
        "components-header-color-bottom-stroke": "#f0f2f7",
        "components-header-color-search-search-border-default": "#121111",
        "components-header-color-search-search-text": "#5b5654",
        "components-header-color-search-search-icon": "#d92c29",
        "components-header-color-search-search-bar": "#121111",
        "components-header-color-mobile-mobile-expanded-bg": "#ffffff",
        "components-header-color-mobile-pressed-bg": "#ffffff",
        "components-header-color-language-selector-language": "#5b5654",
        "components-header-color-language-selector-country": "#000000",
        "components-header-color-language-selector-country-hover-bg": "#f0f2f7",
        "components-header-color-language-selector-megamenu-language": "#5b5654",
        "components-header-color-language-selector-megamenu-country": "#000000",
        "components-header-color-language-selector-megamenu-country-hover": "#f0f2f7",
        "components-header-color-language-selector-bg": "#ffffff",
        "components-header-color-language-selector-stroke": "#72757e",
        "components-header-color-mega-menu-bg": "#f0f2f7",
        "components-header-color-mega-menu-featured-bg": "#000000",
        "components-header-color-mega-menu-featured-title": "#ffffff",
        "components-header-color-mega-menu-category-title": "#121111",
        "components-header-color-mega-menu-bg-accent": "#ffffff",
        "components-header-color-icon-icon-default": "#000000",
        "components-header-color-icon-icon-hover": "#c22725",
        "components-header-color-icon-icon-pressed": "#a82220",
        "components-header-color-links-nav-main-link-default": "#000000",
        "components-header-color-links-nav-main-link-hover": "#d92c29",
        "components-header-color-links-nav-main-link-pressed": "#c22725",
        "components-header-color-links-nav-main-link-active": "#a82220",
        "components-header-color-links-nav-main-active-bar": "#d92c29",
        "components-header-color-links-nav-main-bg-color": "#ffffff",
        "components-header-color-links-nav-category-link-category-default": "#121111",
        "components-header-color-links-nav-category-link-category-hover": "#c22725",
        "components-header-color-links-nav-category-link-category-pressed": "#c22725",
        "components-header-color-links-nav-category-link-category-active": "#a82220",
        "components-header-color-links-nav-menu-link-link-default": "#000000",
        "components-header-color-links-nav-menu-link-link-hover": "#c22725",
        "components-header-color-links-nav-menu-link-link-pressed": "#c22725",
        "components-header-color-links-nav-menu-link-link-active": "#a82220",
        "components-header-color-links-nav-menu-link-bg": "#f0f2f7",
        "components-header-color-links-nav-subcategory-link-subcategory-default": "#121111",
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": "#c22725",
        "components-header-color-links-nav-subcategory-link-subcategory-active": "#a82220",
        "components-hero-homepage-color-bg": "#ffffff",
        "components-hero-homepage-color-container-bg": "#ffffff",
        "components-hero-homepage-color-eyebrow": "#72757e",
        "components-hero-homepage-color-header": "#000000",
        "components-hero-homepage-color-sub-header": "#121111",
        "components-hero-homepage-color-body": "#121111",
        "components-hero-homepage-color-play-pause": "#000000",
        "components-hero-internal-color-default-title": "#ffffff",
        "components-hero-internal-color-default-body": "#f0f2f7",
        "components-hero-internal-color-default-bg": "#000000",
        "components-hero-internal-color-accent-bg": "#000000",
        "components-hero-internal-color-with-image-bg": "#000000",
        "components-hero-internal-color-default-bg-2": "#000000",
        "components-hero-internal-color-with-image-title": "#ffffff",
        "components-hero-internal-color-with-image-body": "#f0f2f7",
        "components-heading-page-title-color-default-bg": "#ffffff",
        "components-heading-page-title-color-default-title": "#000000",
        "components-heading-page-title-color-default-body": "#121111",
        "components-heading-page-title-color-light-bg": "#f0f2f7",
        "components-heading-page-title-color-light-title": "#000000",
        "components-heading-page-title-color-light-body": "#121111",
        "components-heading-page-title-color-color-bg": "#000000",
        "components-heading-page-title-color-color-title": "#ffffff",
        "components-heading-page-title-color-color-body": "#f0f2f7",
        "components-hyperlink-color-dark-default": "#d92c29",
        "components-hyperlink-color-dark-hover": "#c22725",
        "components-hyperlink-color-dark-visited": "#865ddf",
        "components-hyperlink-color-light-default": "#fbf2f2",
        "components-hyperlink-color-light-hover": "#f1c7c7",
        "components-hyperlink-color-light-visited": "#ffedc8",
        "components-icon-default": "#72757e",
        "components-icon-focused": "#72757e",
        "components-icon-card-color-bg": "#ffffff",
        "components-icon-card-color-icon-bg": "#fece2f",
        "components-icon-card-color-icon": "#ffffff",
        "components-icon-card-color-title": "#000000",
        "components-icon-card-color-subtitle": "#5b5654",
        "components-icon-card-color-description": "#121111",
        "components-icon-card-listing-color-bg": "#ffffff",
        "components-icon-card-listing-color-inner-bg": "#ffffff",
        "components-icon-card-listing-color-brand-bg": "#f0f2f7",
        "components-icon-card-listing-color-title": "#000000",
        "components-icon-card-listing-color-copy": "#121111",
        "components-icon-card-listing-color-inner-legal-text": "#121111",
        "components-jumplink-color-bg-default": "#ffffff",
        "components-jumplink-color-link-text-default": "#5b5654",
        "components-jumplink-color-link-text-hover": "#a82220",
        "components-jumplink-color-link-border-hover": "#a82220",
        "components-jumplink-color-link-text-active": "#c22725",
        "components-jumplink-color-link-border-active": "#c22725",
        "components-jumplink-color-title-text": "#000000",
        "components-jumplink-color-description-text": "#121111",
        "components-layout-bg-default": "#ffffff",
        "components-lightbox-color-overlay-bg": "rgba(255, 255, 255, 0.95)",
        "components-lightbox-color-title": "#000000",
        "components-lightbox-color-body": "#121111",
        "components-label-color-on-color-label-label-default": "#ffffff",
        "components-label-color-on-color-label-label-hover": "#5b5654",
        "components-label-color-on-color-label-label-pressed": "#373432",
        "components-label-color-on-color-background-bg-default": "rgba(114, 117, 126, 0.00)",
        "components-label-color-on-color-background-bg-hover": "#ffffff",
        "components-label-color-on-color-background-bg-pressed": "#eae8e8",
        "components-label-color-on-color-stroke-hover": "#ffffff",
        "components-label-color-on-color-stroke-pressed": "#eae8e8",
        "components-label-color-on-color-stroke-default-gradient-fill-1": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-2": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-3": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-4": "#ffffff",
        "components-label-color-on-white-label-label-default": "#5b5654",
        "components-label-color-on-white-label-label-hover": "#ffffff",
        "components-label-color-on-white-label-label-pressed": "#ffffff",
        "components-label-color-on-white-background-bg-default": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-white-background-bg-hover": "#72757e",
        "components-label-color-on-white-background-bg-pressed": "#5b5654",
        "components-label-color-on-white-stroke-hover": "#72757e",
        "components-label-color-on-white-stroke-pressed": "#5b5654",
        "components-label-color-on-white-stroke-default-gradient-fill-1": "#72757e",
        "components-label-color-on-white-stroke-default-gradient-fill-2": "#72757e",
        "components-label-color-on-white-stroke-default-gradient-fill-3": "#72757e",
        "components-label-color-on-white-stroke-default-gradient-fill-4": "#72757e",
        "components-legal-disclaimer-color-default-bg": "#ffffff",
        "components-legal-disclaimer-color-default-title": "#000000",
        "components-legal-disclaimer-color-default-body": "#121111",
        "components-legal-disclaimer-color-light-bg": "#f0f2f7",
        "components-legal-disclaimer-color-light-title": "#000000",
        "components-legal-disclaimer-color-light-body": "#121111",
        "components-legal-disclaimer-color-brand-bg": "#000000",
        "components-legal-disclaimer-color-brand-title": "#ffffff",
        "components-legal-disclaimer-color-brand-body": "#f0f2f7",
        "components-media-video-thumbnail-overlay-bg": "rgba(25, 27, 28, 0.80)",
        "components-media-caption-color-bg": "#f0f2f7",
        "components-media-caption-color-text": "#121111",
        "components-media-gallery-color-bg": "#ffffff",
        "components-media-gallery-color-title": "#000000",
        "components-media-gallery-color-body": "#121111",
        "components-pagination-on-white-accent-default": "#121111",
        "components-pagination-on-white-accent-scroll": "#0b40a3",
        "components-pagination-on-white-disabled": "#aba8a6",
        "components-pagination-on-white-bg": "rgba(171, 168, 166, 0.50)",
        "components-pagination-on-white-stroke": "#eae8e8",
        "components-pagination-on-white-bg-active": "#000000",
        "components-pagination-on-white-bg-hover": "#c6cadf",
        "components-pagination-on-white-text-default": "#72757e",
        "components-pagination-on-white-text-hover": "#121111",
        "components-pagination-on-white-text-active": "#ffffff",
        "components-pagination-on-color-accent-default": "#000000",
        "components-pagination-on-color-accent-scroll": "#ffffff",
        "components-pagination-on-color-disabled": "#991f1d",
        "components-pagination-on-color-bg": "#000000",
        "components-pagination-on-color-stroke": "rgba(255, 255, 255, 0.38)",
        "components-pagination-on-color-bg-active": "#ffffff",
        "components-pagination-on-color-bg-hover": "#c6cadf",
        "components-pagination-on-color-text-default": "#ffffff",
        "components-pagination-on-color-text-hover": "#000000",
        "components-pagination-on-color-text-active": "#000000",
        "components-pdf-link-card-color-bg": "#ffffff",
        "components-pdf-link-card-color-card-bg": "#ffffff",
        "components-pdf-link-card-color-card-title": "#000000",
        "components-pdf-link-card-color-card-subtitle": "#121111",
        "components-pdf-link-card-color-card-body": "#121111",
        "components-product-card-color-bg": "#ffffff",
        "components-product-card-color-content-bg": "#ffffff",
        "components-product-card-color-border": "rgba(255, 255, 255, 0.00)",
        "components-product-card-color-title": "#000000",
        "components-product-card-color-description": "#121111",
        "components-product-card-color-star-rating": "#c22725",
        "components-product-card-color-badge-bg": "#128437",
        "components-product-card-color-badge-text": "#ffffff",
        "components-product-card-listing-color-bg": "#f0f2f7",
        "components-product-card-listing-color-title": "#000000",
        "components-product-card-listing-color-copy": "#121111",
        "components-product-card-listing-color-legal-text": "#121111",
        "components-product-information-color-bg": "#f0f2f7",
        "components-product-information-color-content-bg": "#f0f2f7",
        "components-product-information-color-headline": "#000000",
        "components-product-information-color-body": "#121111",
        "components-product-information-color-detail-text": "#121111",
        "components-product-information-color-divider": "#aba8a6",
        "components-product-information-color-icon": "#c22725",
        "components-product-information-color-play-icon": "#ffffff",
        "components-product-information-color-selected-stroke": "#d92c29",
        "components-product-results-list-with-search-color-bg": "#f0f2f7",
        "components-promo-color-default-bg": "#f0f2f7",
        "components-promo-color-default-image-bg": "#ffffff",
        "components-promo-color-default-content-bg": "#ffffff",
        "components-promo-color-default-title": "#000000",
        "components-promo-color-default-body": "#121111",
        "components-promo-color-default-category": "#000000",
        "components-promo-color-light-bg": "#f0f2f7",
        "components-promo-color-light-image-bg": "#c22725",
        "components-promo-color-light-content-bg": "#c22725",
        "components-promo-color-light-title": "#ffffff",
        "components-promo-color-light-body": "#f0f2f7",
        "components-promo-color-light-category": "#ffffff",
        "components-promo-color-brand-bg": "#f0f2f7",
        "components-promo-color-brand-image-bg": "#000000",
        "components-promo-color-brand-content-bg": "#000000",
        "components-promo-color-brand-title": "#ffffff",
        "components-promo-color-brand-body": "#f0f2f7",
        "components-promo-color-brand-category": "#ffffff",
        "components-pull-quote-color-bg": "#fece2f",
        "components-pull-quote-color-stroke": "#fece2f",
        "components-pull-quote-color-quote-bg": "#fece2f",
        "components-pull-quote-color-quote-title": "#000000",
        "components-pull-quote-color-quote-by-text": "#121111",
        "components-pull-quote-color-quote-detail": "#121111",
        "components-pull-quote-color-quote-highlight": "#ffffff",
        "components-promo-alternate-color-default-bg": "#ffffff",
        "components-promo-alternate-color-default-image-bg": "#ffffff",
        "components-promo-alternate-color-default-content-bg": "#ffffff",
        "components-promo-alternate-color-default-title": "#000000",
        "components-promo-alternate-color-default-body": "#121111",
        "components-promo-alternate-color-default-category": "#000000",
        "components-promo-alternate-color-light-bg": "#c22725",
        "components-promo-alternate-color-light-image-bg": "#c22725",
        "components-promo-alternate-color-light-content-bg": "#c22725",
        "components-promo-alternate-color-light-title": "#ffffff",
        "components-promo-alternate-color-light-body": "#f0f2f7",
        "components-promo-alternate-color-light-category": "#ffffff",
        "components-promo-alternate-color-brand-bg": "#000000",
        "components-promo-alternate-color-brand-image-bg": "#000000",
        "components-promo-alternate-color-brand-content-bg": "#000000",
        "components-promo-alternate-color-brand-title": "#ffffff",
        "components-promo-alternate-color-brand-body": "#f0f2f7",
        "components-promo-alternate-color-brand-category": "#ffffff",
        "components-share-list-color-title": "#121111",
        "components-share-list-color-bg": "#f0f2f7",
        "components-share-list-color-link-default": "#121111",
        "components-share-list-color-link-hover": "#c22725",
        "components-share-list-color-bg-link-hover": "#eae8e8",
        "components-share-list-color-close-icon": "#191b1c",
        "components-share-list-color-facebook-icon": "#3b5998",
        "components-share-list-color-pinterest-icon": "#e60023",
        "components-share-list-color-twitter-icon": "#000000",
        "components-share-list-color-basic-icon-default": "#191b1c",
        "components-share-list-color-icon-hover": "#c22725",
        "components-share-list-color-border": "#eae8e8",
        "components-site-search-bg": "#ffffff",
        "components-site-search-results_text": "#121111",
        "components-site-search-filter-filter-selected-bg": "#fbf2f2",
        "components-site-search-facet-group-label-bottom-line": "#f0f2f7",
        "components-site-search-facet-group-label-text": "#121111",
        "components-site-search-facet-group-label-bg": "#ffffff",
        "components-site-search-facet-bg": "#ffffff",
        "components-site-search-facet-stroke": "#f0f2f7",
        "components-site-search-refine-bg": "#ffffff",
        "components-site-search-refine-right-divider": "#f0f2f7",
        "components-site-search-refine-title": "#000000",
        "components-site-search-hero-search-bg": "#000000",
        "components-site-search-hero-search-accent-bg": "#000000",
        "components-site-search-hero-search-title": "#ffffff",
        "components-stats-stat-text": "#c22725",
        "components-stats-stat-text-on-light": "#c22725",
        "components-stats-supporting-text": "#121111",
        "components-stats-bg-default": "#ffffff",
        "components-stats-bg-inner": "#ffffff",
        "components-stats-bg-section-inner": "#0b40a3",
        "components-stats-title": "#000000",
        "components-stats-copy": "#121111",
        "components-supporting-text-default": "#5b5654",
        "components-supporting-text-destructive": "#b32b30",
        "components-tab-bg": "#ffffff",
        "components-tab-border-default": "#72757e",
        "components-tab-border-hover": "#d92c29",
        "components-tab-border-focused": "#000000",
        "components-tab-border-destructive": "#b32b30",
        "components-tab-label-text-default": "#121111",
        "components-tab-item-text-default": "#121111",
        "components-tab-item-bg-default": "#ffffff",
        "components-tab-item-bg-hover": "#e4e6ef",
        "components-tab-input-field-default": "#72757e",
        "components-tab-input-field-focused": "#121111",
        "components-tab-input-label-focused": "#000000",
        "components-tab-input-field-destructive": "#b32b30",
        "components-tab-bg-2": "#ffffff",
        "components-tag-color-bg": "#ffffff",
        "components-tag-color-text": "#0b40a3",
        "components-tag-color-stroke": "#0b40a3",
        "components-tag-color-bg-hover": "#c22725",
        "components-tag-color-text-hover": "#ffffff",
        "components-tag-color-stroke-hover": "#c22725",
        "components-text-field-bg": "#ffffff",
        "components-text-field-bg-focused": "#f0f2f7",
        "components-text-field-border-default": "#121111",
        "components-text-field-border-hover": "#0b40a3",
        "components-text-field-border-focused": "#000000",
        "components-text-field-border-destructive": "#b32b30",
        "components-text-field-input-field-default": "#5b5654",
        "components-text-field-input-field-focused": "#121111",
        "components-text-field-input-label-focused": "#000000",
        "components-text-field-input-field-destructive": "#b32b30",
        "components-text-field-icon-default": "#72757e",
        "components-text-field-icon-destructive": "#b32b30",
        "components-text-field-supporting-text-default": "#5b5654",
        "components-text-field-supporting-text-destructive": "#b32b30",
        "components-tip-color-bg": "#f0f2f7",
        "components-tip-color-title": "#000000",
        "components-tip-color-body": "#121111",
        "components-tip-color-stroke": "#0b40a3",
        "components-video-color-bg": "#ffffff",
        "themes-general-surface-color-light-bg": "#ffffff",
        "themes-general-surface-color-light-title": "#000000",
        "themes-general-surface-color-light-body": "#121111",
        "themes-general-surface-color-light-decor-line-break": "#eae8e8",
        "themes-general-surface-color-light-rule-line": "#eae8e8",
        "themes-general-surface-color-dark-bg": "#000000",
        "themes-general-surface-color-dark-title": "#ffffff",
        "themes-general-surface-color-dark-body": "#f0f2f7",
        "themes-general-surface-color-dark-decor-line-break": "#eae8e8",
        "themes-general-card-color-dark-title": "#000000",
        "themes-general-card-color-dark-subtitle": "#121111",
        "themes-general-card-color-dark-body": "#121111",
        "themes-general-card-color-light-title": "#ffffff",
        "themes-general-card-color-light-subtitle": "#f0f2f7",
        "themes-general-card-color-light-body": "#f0f2f7",
        "themes-shadow-color": "#ffffff",
        "themes-shadow-share-list-color-fill": "rgba(0, 0, 0, 0.40)",
        "themes-shadow-header-menu-color-fill": "rgba(0, 0, 0, 0.20)",
        "themes-gradient-loader-gradient-fill-1": "#d92c29",
        "themes-gradient-loader-gradient-fill-2": "rgba(217, 44, 41, 0.00)",
        "themes-color-background-white": "#ffffff",
        "themes-color-background-light": "#f0f2f7",
        "themes-color-background-dark": "#191b1c",
        "themes-color-background-black": "#000000",
        "themes-color-background-brand1": "#c22725",
        "themes-color-background-brand2": "#0b40a3",
        "themes-color-background-accent1": "#128437",
        "themes-color-background-accent2": "#fece2f",
        "themes-color-background-body": "#ffffff",
        "themes-color-stroke-white": "#ffffff",
        "themes-color-stroke-light": "#f0f2f7",
        "themes-color-stroke-dark": "#373432",
        "themes-color-stroke-black": "#000000",
        "themes-color-stroke-brand1": "#d92c29",
        "themes-color-stroke-brand2": "#0b40a3",
        "themes-color-stroke-accent1": "#14943d",
        "themes-color-stroke-accent2": "#fece2f",
        "themes-color-text-white": "#ffffff",
        "themes-color-text-light": "#f0f2f7",
        "themes-color-text-dark": "#121111",
        "themes-color-text-black": "#000000",
        "themes-color-text-brand1": "#c22725",
        "themes-color-text-brand2": "#0b40a3",
        "themes-color-text-accent1": "#0d5e27",
        "themes-color-text-accent2": "#c5a024",
        "colors-grayscale-100": "#f0f2f7",
        "colors-grayscale-200": "#eae8e8",
        "colors-grayscale-300": "#aba8a6",
        "colors-grayscale-400": "#72757e",
        "colors-grayscale-500": "#5b5654",
        "colors-grayscale-600": "#373432",
        "colors-grayscale-700": "#121111",
        "colors-brand-1-100": "#fbf2f2",
        "colors-brand-1-200": "#f8e5e5",
        "colors-brand-1-300": "#f1c7c7",
        "colors-brand-1-400": "#d92c29",
        "colors-brand-1-500": "#c22725",
        "colors-brand-1-600": "#a82220",
        "colors-brand-1-700": "#991f1d",
        "colors-brand-2-100": "#f2f3f7",
        "colors-brand-2-200": "#e4e6ef",
        "colors-brand-2-300": "#c6cadf",
        "colors-brand-2-400": "#0b40a3",
        "colors-brand-2-500": "#09327e",
        "colors-brand-2-600": "#072867",
        "colors-brand-2-700": "#051d49",
        "colors-accent-1-100": "#f2f6f3",
        "colors-accent-1-200": "#e4ede6",
        "colors-accent-1-300": "#a2c6a8",
        "colors-accent-1-400": "#14943d",
        "colors-accent-1-500": "#128437",
        "colors-accent-1-600": "#0d5e27",
        "colors-accent-1-700": "#09421b",
        "colors-accent-2-100": "#fffbf2",
        "colors-accent-2-200": "#fff6e5",
        "colors-accent-2-300": "#ffedc8",
        "colors-accent-2-400": "#fece2f",
        "colors-accent-2-500": "#e3b82a",
        "colors-accent-2-600": "#c5a024",
        "colors-accent-2-700": "#c5a024",
        "colors-accent-3-100": "#eefaec",
        "colors-accent-3-200": "#a9e8a0",
        "colors-accent-3-300": "#68d759",
        "colors-accent-3-400": "#3aae2a",
        "colors-accent-3-500": "#319223",
        "colors-accent-3-600": "#236819",
        "colors-accent-3-700": "#153f0f",
        "colors-accent-4-400_storage": "#00aed6",
        "colors-accessible-accent-1-100": "#f5f2fc",
        "colors-accessible-accent-1-200": "#cfbff2",
        "colors-accessible-accent-1-300": "#ab8fe9",
        "colors-accessible-accent-1-400": "#865ddf",
        "colors-accessible-accent-1-500": "#6d3ad8",
        "colors-accessible-accent-1-600": "#4c21a9",
        "colors-accessible-accent-1-700": "#341672",
        "colors-accessible-accent-2-100": "#e7f8e4",
        "colors-accessible-accent-2-200": "#7ddc6f",
        "colors-accessible-accent-2-300": "#3cb42c",
        "colors-accessible-accent-2-400": "#2d8721",
        "colors-accessible-accent-2-500": "#246d1a",
        "colors-accessible-accent-2-600": "#1b5214",
        "colors-accessible-accent-2-700": "#11340c",
        "colors-accessible-brand-2-100": "#f2f3f7",
        "colors-accessible-brand-2-200": "#e4e6ef",
        "colors-accessible-brand-2-300": "#c6cadf",
        "colors-accessible-brand-2-400": "#0b40a3",
        "colors-accessible-brand-2-500": "#09327e",
        "colors-accessible-brand-2-600": "#072867",
        "colors-accessible-brand-2-700": "#051d49"
      },
      "spacing": {
        "components-accordion-spacing-large-padding-x": "5rem",
        "components-accordion-spacing-large-icon-margin-right": "2rem",
        "components-accordion-spacing-large-copy-margin-right": "2.5rem",
        "components-accordion-spacing-large-maxwidth": "59.625rem",
        "components-accordion-spacing-large-section-padding-x": "0rem",
        "components-accordion-spacing-large-section-padding-y": "2.5rem",
        "components-accordion-spacing-large-section-copy-margin-bottom": "2.5rem",
        "components-accordion-spacing-large-section-space-between": "4rem",
        "components-accordion-spacing-large-item-padding-x": "0rem",
        "components-accordion-spacing-large-item-padding-y": "1.5rem",
        "components-accordion-spacing-large-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-large-content-padding-x": "0rem",
        "components-accordion-spacing-large-content-padding-y": "2.5rem",
        "components-accordion-spacing-large-content-title-margin-bottom": "2.5rem",
        "components-accordion-spacing-large-content-content-space-between": "4rem",
        "components-accordion-spacing-large-content-content-margin-left": "1rem",
        "components-accordion-spacing-small-padding-top": "1rem",
        "components-accordion-spacing-small-padding-x": "1.5rem",
        "components-accordion-spacing-small-padding-y": "2.5rem",
        "components-accordion-spacing-small-icon-margin-right": "0.75rem",
        "components-accordion-spacing-small-title-margin-bottom": "0.5rem",
        "components-accordion-spacing-small-copy-margin-right": "2rem",
        "components-accordion-spacing-small-copy-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-padding-x": "0rem",
        "components-accordion-spacing-small-section-padding-y": "0rem",
        "components-accordion-spacing-small-section-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-space-between": "2.5rem",
        "components-accordion-spacing-small-item-padding-x": "0rem",
        "components-accordion-spacing-small-item-padding-y": "1rem",
        "components-accordion-spacing-small-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-small-content-padding-x": "0rem",
        "components-accordion-spacing-small-content-padding-y": "0.75rem",
        "components-accordion-spacing-small-content-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-content-content-space-between": "1rem",
        "components-accordion-spacing-small-content-content-margin-left": "1rem",
        "components-article-card-spacing-large-padding-image-x": "0rem",
        "components-article-card-spacing-large-padding-image-y": "0rem",
        "components-article-card-spacing-large-padding-content-x": "1.5rem",
        "components-article-card-spacing-large-padding-content-y": "1.5rem",
        "components-article-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-article-card-spacing-large-button-space-between": "1rem",
        "components-article-card-spacing-large-title-margin-bottom": "1rem",
        "components-article-card-spacing-large-list-content-gap": "1rem",
        "components-article-card-spacing-large-list-image-padding-x": "1rem",
        "components-article-card-spacing-large-list-image-padding-y": "1rem",
        "components-article-card-spacing-small-padding-image-x": "0rem",
        "components-article-card-spacing-small-padding-image-y": "0rem",
        "components-article-card-spacing-small-padding-content-x": "1.5rem",
        "components-article-card-spacing-small-padding-content-y": "1.5rem",
        "components-article-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-article-card-listing-spacing-small-padding-x": "1.5rem",
        "components-article-card-listing-spacing-small-padding-y": "2.5rem",
        "components-article-hero-spacing-large-padding-x": "0rem",
        "components-article-hero-spacing-large-padding-y": "0rem",
        "components-article-hero-spacing-large-title-margin-bottom": "2rem",
        "components-article-hero-spacing-large-label-share-margin-bottom": "2rem",
        "components-article-hero-spacing-large-author-name-margin-bottom": "0.75rem",
        "components-article-hero-spacing-large-author-thumbnail-margin-left": "1rem",
        "components-article-hero-spacing-large-details-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-space-between": "1rem",
        "components-article-hero-spacing-large-body-margin-bottom": "2rem",
        "components-article-hero-spacing-large-image-space-between": "1rem",
        "components-article-hero-spacing-large-image-margin-bottom": "1rem",
        "components-article-hero-spacing-large-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-stats-margin-bottom": "2.5rem",
        "components-article-hero-spacing-small-content-margin-top": "2.5rem",
        "components-article-hero-spacing-small-title-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-label-share-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-authorname-margin-bottom": "0.75rem",
        "components-article-hero-spacing-small-authorthumbnail-margin-left": "1rem",
        "components-article-hero-spacing-small-details-margin-bottom": "1rem",
        "components-article-hero-spacing-small-space-between": "0.75rem",
        "components-article-hero-spacing-small-body-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-image-space-between": "0.75rem",
        "components-article-hero-spacing-small-image-margin-bottom": "1rem",
        "components-article-hero-spacing-small-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-stats-margin-bottom": "2rem",
        "components-banner-spacing-large-content-padding-x": "1.5rem",
        "components-banner-spacing-large-content-space-between": "1rem",
        "components-banner-spacing-large-content-width": "69.375rem",
        "components-banner-spacing-icon-dimensions": "1.5rem",
        "components-banner-spacing-icon-margin-left": "0.5rem",
        "components-banner-spacing-small-space-between": "1rem",
        "components-banner-spacing-small-content-space-between": "1rem",
        "components-banner-spacing-small-title-margin-bottom": "0.75rem",
        "components-banner-spacing-small-content-margin-bottom": "1rem",
        "components-breadcrumb-spacing-large-space-between": "0.25rem",
        "components-breadcrumb-spacing-small-space-between": "0.25rem",
        "components-button-spacing-large-icon-large-x": "2.5rem",
        "components-button-spacing-large-icon-large-y": "2.5rem",
        "components-button-spacing-large-default-padding-y": "0.75rem",
        "components-button-spacing-large-default-padding-x": "1.5rem",
        "components-button-spacing-large-default-space-between": "0.25rem",
        "components-button-spacing-large-icon-left-padding-left": "1rem",
        "components-button-spacing-large-icon-right-padding-right": "1rem",
        "components-button-spacing-large-icon-only-padding-x": "0.75rem",
        "components-button-spacing-large-icon-only-padding-y": "0.75rem",
        "components-button-spacing-large-tertiary-content-height": "1.5rem",
        "components-button-spacing-compressed-icon-large-x": "2.5rem",
        "components-button-spacing-compressed-icon-large-y": "2.5rem",
        "components-button-spacing-compressed-default-padding-y": "0.5rem",
        "components-button-spacing-compressed-default-padding-x": "1rem",
        "components-button-spacing-compressed-default-space-between": "0.125rem",
        "components-button-spacing-compressed-icon-left-padding-left": "0.5rem",
        "components-button-spacing-compressed-icon-right-padding-right": "0.5rem",
        "components-button-spacing-compressed-icon-only-padding-x": "0.5rem",
        "components-button-group-spacing-padding-y": "0.125rem",
        "components-button-group-spacing-padding-x": "0.25rem",
        "components-button-group-spacing-space-between": "0.5rem",
        "components-category-card-spacing-large-padding-top": "0rem",
        "components-category-card-spacing-large-padding-x": "0rem",
        "components-category-card-spacing-large-padding-y": "0rem",
        "components-category-card-spacing-large-padding-image-x": "0rem",
        "components-category-card-spacing-large-padding-image-y": "0rem",
        "components-category-card-spacing-large-padding-content-x": "2rem",
        "components-category-card-spacing-large-padding-content-y": "2rem",
        "components-category-card-listing-spacing-large-padding-y": "5rem",
        "components-category-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-category-card-listing-spacing-large-card-margin-bottom": "0rem",
        "components-category-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-card-search-result-small-padding-top": "0rem",
        "components-card-search-result-small-padding-x": "0rem",
        "components-card-search-result-small-padding-y": "0rem",
        "components-card-search-result-small-padding-image-x": "0rem",
        "components-card-search-result-small-padding-image-y": "0rem",
        "components-card-search-result-small-padding-content-x": "1.5rem",
        "components-card-search-result-small-padding-content-y": "1.5rem",
        "components-card-search-result-spacing-large-padding-x": "0rem",
        "components-card-search-result-spacing-large-padding-y": "1.5rem",
        "components-card-search-result-spacing-large-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-image-margin-right": "1.5rem",
        "components-card-search-result-spacing-small-padding-x": "1.5rem",
        "components-card-search-result-spacing-small-padding-y": "1.5rem",
        "components-card-search-result-spacing-small-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-image-margin-right": "0.75rem",
        "components-contact-us-promo-spacing-large-padding-x": "5rem",
        "components-contact-us-promo-spacing-large-padding-y": "5rem",
        "components-contact-us-promo-spacing-large-content-margin-right": "5rem",
        "components-contact-us-promo-spacing-large-margin-y": "2.5rem",
        "components-contact-us-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-contact-us-promo-spacing-small-margin-y": "2.5rem",
        "components-content-list-list-item-container-padding-x": "0.75rem",
        "components-content-list-list-item-container-padding-y": "1rem",
        "components-content-list-list-item-content-space-between": "0.5rem",
        "components-content-list-list-item-content-default-padding-y": "0.25rem",
        "components-content-list-list-item-content-hyperlink-padding-y": "0rem",
        "components-content-list-spacing-large-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-large-item-title-margin-bottom": "1rem",
        "components-content-list-spacing-large-item-copy-margin-bottom": "2.5rem",
        "components-content-list-spacing-large-item-icon-copy-margin-top": "0rem",
        "components-content-list-spacing-large-item-margin-bottom": "2rem",
        "components-content-list-spacing-large-item-image-margin-bottom": "0.5rem",
        "components-content-list-spacing-large-padding-x": "2.5rem",
        "components-content-list-spacing-small-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-y": "2.5rem",
        "components-content-list-spacing-small-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-small-item-title-margin-bottom": "0.5rem",
        "components-content-list-spacing-small-item-copy-margin-bottom": "1rem",
        "components-content-list-spacing-small-item-margin-bottom": "2rem",
        "components-content-list-spacing-small-header-title-margin-bottom": "2rem",
        "components-content-list-spacing-small-header-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-title-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-button-space-between": "1rem",
        "components-dropdown-item-padding-y": "0.75rem",
        "components-dropdown-item-padding-x": "0.75rem",
        "components-dropdown-item-spacing-x": "0.75rem",
        "components-dropdown-input-padding-y": "0.25rem",
        "components-dropdown-input-padding-x": "1rem",
        "components-footer-brand-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-large-column-margin-right": "2rem",
        "components-footer-brand-spacing-large-column-margin-bottom": "4rem",
        "components-footer-brand-spacing-large-link-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-padding-y": "2.5rem",
        "components-footer-brand-spacing-small-padding-x": "1rem",
        "components-footer-brand-spacing-small-logo-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-column-margin-right": "0.125rem",
        "components-footer-brand-spacing-small-column-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-small-link-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-title-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-text-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-footer-link-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-link-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-link-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-link-minheight": "3rem",
        "components-footer-brand-spacing-small-footer-category-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-category-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-category-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-category-minheight": "3rem",
        "components-footer-global-spacing-large-padding-y": "2.5rem",
        "components-footer-global-spacing-large-padding-x": "5rem",
        "components-footer-global-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-global-spacing-large-column-margin-right": "2rem",
        "components-footer-global-spacing-large-link-margin-bottom": "2rem",
        "components-footer-global-spacing-small-padding-y": "2.5rem",
        "components-footer-global-spacing-small-padding-x": "1rem",
        "components-footer-global-spacing-small-logo-margin-bottom": "1rem",
        "components-footer-global-spacing-small-column-margin-right": "1rem",
        "components-footer-global-spacing-small-link-margin-bottom": "1rem",
        "components-generic-card-spacing-large-padding-x": "0rem",
        "components-generic-card-spacing-large-padding-y": "0rem",
        "components-generic-card-spacing-large-padding-image-x": "0rem",
        "components-generic-card-spacing-large-padding-image-y": "0rem",
        "components-generic-card-spacing-large-padding-content-x": "2rem",
        "components-generic-card-spacing-large-padding-content-y": "2rem",
        "components-generic-card-spacing-large-eyebrow-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-title-margin-bottom": "1rem",
        "components-generic-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-button-space-between": "1rem",
        "components-generic-card-spacing-small-padding-x": "0rem",
        "components-generic-card-spacing-small-padding-y": "0rem",
        "components-generic-card-spacing-small-padding-image-x": "0rem",
        "components-generic-card-spacing-small-padding-image-y": "0rem",
        "components-generic-card-spacing-small-padding-content-x": "1.5rem",
        "components-generic-card-spacing-small-padding-content-y": "1.5rem",
        "components-global-search-spacing-omnibox-padding-x": "0.125rem",
        "components-global-search-spacing-omnibox-list-padding-y": "0.5rem",
        "components-global-search-spacing-omnibox-label-padding": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-item-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-height": "3rem",
        "components-generic-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-generic-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-header-shadow-x": "0rem",
        "components-header-spacing-large-padding-y": "1.5rem",
        "components-header-spacing-large-padding-x": "2rem",
        "components-header-spacing-large-links-margin-right": "1.5rem",
        "components-header-spacing-large-icons-space-between": "1.5rem",
        "components-header-spacing-large-logo-margin-right": "2rem",
        "components-header-spacing-large-space-between-search": "0.5rem",
        "components-header-spacing-large-height": "6.625rem",
        "components-header-spacing-large-language-selector-padding-x": "1rem",
        "components-header-spacing-large-language-selector-padding-y": "0.5rem",
        "components-header-spacing-large-language-selector-space-between": "0.75rem",
        "components-header-spacing-large-language-selector-country-space-between": "0.5rem",
        "components-header-spacing-large-mega-menu-padding-y": "2.5rem",
        "components-header-spacing-large-mega-menu-padding-x": "2.5rem",
        "components-header-spacing-large-mega-menu-column-margin-right": "2rem",
        "components-header-spacing-large-mega-menu-category-link-margin-bottom": "1rem",
        "components-header-spacing-large-mega-menu-link-margin-bottom": "0.5rem",
        "components-header-spacing-large-mega-menu-featured-padding-x": "2rem",
        "components-header-spacing-large-mega-menu-featured-padding-y": "2rem",
        "components-header-spacing-large-mega-menu-featured-image-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-title-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-link-space-between": "1.5rem",
        "components-header-spacing-large-mega-menu-title-margin-bottom": "2.5rem",
        "components-header-spacing-large-dropdown-padding-y": "1.5rem",
        "components-header-spacing-large-dropdown-padding-x": "1.5rem",
        "components-header-spacing-large-dropdown-margin-bottom": "0.5rem",
        "components-header-spacing-small-language-selector-padding-x": "1rem",
        "components-header-spacing-small-language-selector-padding-y": "0.5rem",
        "components-header-spacing-small-language-selector-space-between": "0.5rem",
        "components-header-spacing-small-padding-left": "1rem",
        "components-header-spacing-small-padding-right": "1rem",
        "components-header-spacing-small-padding-top": "0.5rem",
        "components-header-spacing-small-padding-bottom": "0.5rem",
        "components-header-spacing-small-nav-main-minheight": "3rem",
        "components-header-spacing-small-nav-main-padding-y": "0.5rem",
        "components-header-spacing-small-nav-main-padding-left": "1.5rem",
        "components-header-spacing-small-nav-main-padding-right": "1rem",
        "components-header-spacing-small-nav-category-minheight": "3rem",
        "components-header-spacing-small-nav-category-padding-left": "1.5rem",
        "components-header-spacing-small-nav-category-padding-right": "0.75rem",
        "components-header-spacing-small-nav-category-padding-y": "0.75rem",
        "components-header-spacing-small-nav-category-space-between": "0rem",
        "components-header-spacing-small-nav-menu-link-minheight": "3rem",
        "components-header-spacing-small-nav-menu-link-padding-right": "1.5rem",
        "components-header-spacing-small-nav-menu-link-padding-left": "2.5rem",
        "components-header-spacing-small-nav-menu-link-padding-y": "0.75rem",
        "components-header-spacing-small-mega-menu-padding-y": "0rem",
        "components-header-spacing-small-mega-menu-padding-x": "0rem",
        "components-header-spacing-small-mega-menu-category-link-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-link-margin-bottom": "0rem",
        "components-header-spacing-small-mega-menu-featured-padding-x": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-padding-y": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-image-margin-bottom": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-title-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-featured-link-space-between": "0.75rem",
        "components-header-spacing-small-mega-menu-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-container-padding": "1rem",
        "components-hero-homepage-spacing-large-padding-x": "5rem",
        "components-hero-homepage-spacing-large-padding-y": "2.5rem",
        "components-hero-homepage-spacing-large-minheight": "34.3125rem",
        "components-hero-homepage-spacing-large-content-title-margin-bottom": "2rem",
        "components-hero-homepage-spacing-large-content-margin-bottom": "2rem",
        "components-hero-homepage-spacing-large-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-copy-margin-bottom": "2.5rem",
        "components-hero-homepage-spacing-large-cta-margin-right": "1rem",
        "components-hero-homepage-spacing-large-image-height": "34.3125rem",
        "components-hero-homepage-spacing-large-image-width": "45.75rem",
        "components-hero-homepage-spacing-small-padding-x": "0.5rem",
        "components-hero-homepage-spacing-small-padding-y": "1.5rem",
        "components-hero-homepage-spacing-small-minheight": "34.3125rem",
        "components-hero-homepage-spacing-small-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-copy-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-image-height": "17.5625rem",
        "components-hero-homepage-spacing-small-image-width": "45.75rem",
        "components-hero-internal-spacing-large-padding-y": "5rem",
        "components-hero-internal-spacing-large-padding-right": "5rem",
        "components-hero-internal-spacing-large-title-margin-bottom": "1.5rem",
        "components-hero-internal-spacing-large-button-padding-y": "2rem",
        "components-hero-internal-spacing-large-text-maxwidth": "59.625rem",
        "components-hero-internal-spacing-large-minheight": "18.5rem",
        "components-hero-internal-spacing-small-padding-x": "1.5rem",
        "components-hero-internal-spacing-small-padding-right": "0rem",
        "components-hero-internal-spacing-small-button-padding-y": "1.5rem",
        "components-hero-internal-spacing-small-text-maxwidth": "30rem",
        "components-hero-internal-spacing-small-minheight": "20rem",
        "components-icon-card-spacing-large-padding-x": "0rem",
        "components-icon-card-spacing-large-padding-y": "0rem",
        "components-icon-card-spacing-large-icon-margin": "1.5rem",
        "components-icon-card-spacing-large-margin-right": "1.5rem",
        "components-icon-card-spacing-large-title-margin-bottom": "1rem",
        "components-icon-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-icon-card-spacing-large-button-space-between": "1.5rem",
        "components-icon-card-spacing-large-top-space-between": "1rem",
        "components-icon-card-spacing-small-padding-x": "1.5rem",
        "components-icon-card-spacing-small-padding-y": "1.5rem",
        "components-icon-card-spacing-small-icon-margin": "1.5rem",
        "components-icon-card-spacing-small-margin-right": "1.5rem",
        "components-icon-card-spacing-small-title-margin-bottom": "1.5rem",
        "components-icon-card-dimensions-large-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-large-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-large-big-icon-height": "5rem",
        "components-icon-card-dimensions-large-big-icon-width": "5rem",
        "components-icon-card-dimensions-large-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-large-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-large-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-large-tiny-icon-width": "2.5rem",
        "components-icon-card-dimensions-small-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-small-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-small-big-icon-height": "3.5rem",
        "components-icon-card-dimensions-small-big-icon-width": "3.5rem",
        "components-icon-card-dimensions-small-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-small-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-small-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-small-tiny-icon-width": "2.5rem",
        "components-icon-card-listing-spacing-large-padding-inner-x": "4rem",
        "components-icon-card-listing-spacing-large-padding-inner-y": "4rem",
        "components-icon-card-listing-spacing-large-card-space-between": "4rem",
        "components-icon-card-listing-spacing-large-title-margin-bottom": "1.5rem",
        "components-icon-card-listing-spacing-large-card-margin-bottom": "4rem",
        "components-icon-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-x": "1.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-y": "2.5rem",
        "components-icon-card-listing-spacing-small-title-margin-bottom": "1rem",
        "components-icon-card-listing-spacing-small-button-margin-top": "1.5rem",
        "components-jumplink-link-padding-bottom": "0.5rem",
        "components-jumplink-spacing-small-title-area-margin-bottom": "1.5rem",
        "components-jumplink-spacing-small-link-container-padding-x": "0rem",
        "components-lightbox-spacing-large-carousel-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-carousel-space-between": "1.5rem",
        "components-lightbox-spacing-large-featured-image-height": "39.25rem",
        "components-lightbox-spacing-large-featured-image-width": "39.25rem",
        "components-lightbox-spacing-large-image-height": "17.0625rem",
        "components-lightbox-spacing-large-image-width": "17.0625rem",
        "components-lightbox-spacing-small-carousel-margin-bottom": "1rem",
        "components-lightbox-spacing-small-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-small-media-details-margin-top": "1rem",
        "components-lightbox-spacing-small-image-height": "20.4375rem",
        "components-lightbox-spacing-small-image-width": "20.4375rem",
        "components-legal-disclaimer-spacing-small-ruleline-width": "20.4375rem",
        "components-media-spacing-large-padding-y": "0.5rem",
        "components-media-spacing-large-padding-x": "0.5rem",
        "components-media-gallery-large-container-grid-gap-x": "1.5rem",
        "components-media-gallery-large-container-grid-gap-y": "1.5rem",
        "components-media-gallery-small-container-grid-gap-x": "0.5rem",
        "components-media-gallery-small-container-grid-gap-y": "0.5rem",
        "components-media-gallery-spacing-small-padding-x": "1.5rem",
        "components-media-gallery-spacing-small-padding-y": "2.5rem",
        "components-media-gallery-spacing-large-padding-x": "5rem",
        "components-media-gallery-spacing-large-padding-y": "5rem",
        "components-product-card-spacing-list-content-gap": "0rem",
        "components-product-card-spacing-large-padding-image-x": "0rem",
        "components-product-card-spacing-large-padding-image-y": "0rem",
        "components-product-card-spacing-large-padding-content-x": "1.5rem",
        "components-product-card-spacing-large-padding-content-y": "1.5rem",
        "components-product-card-spacing-large-category-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-title-margin-bottom": "1rem",
        "components-product-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-button-space-between": "1rem",
        "components-product-card-spacing-small-padding-image-x": "0rem",
        "components-product-card-spacing-small-padding-image-y": "0rem",
        "components-product-card-spacing-small-padding-content-x": "1.5rem",
        "components-product-card-spacing-small-padding-content-y": "1.5rem",
        "components-product-card-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-large-padding-x": "5rem",
        "components-product-information-spacing-large-padding-y": "1.5rem",
        "components-product-information-spacing-large-title-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-label-margin-bottom": "2.5rem",
        "components-product-information-spacing-large-ratings-margin-bottom": "1rem",
        "components-product-information-spacing-large-details-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-space-between": "1rem",
        "components-product-information-spacing-large-carousel-space-between": "5rem",
        "components-product-information-spacing-large-button-margin-y": "0.5rem",
        "components-product-information-spacing-large-body-margin-top": "1.5rem",
        "components-product-information-spacing-large-image-space-between": "1rem",
        "components-product-information-spacing-large-image-margin-bottom": "1rem",
        "components-product-information-spacing-small-padding-x": "1.5rem",
        "components-product-information-spacing-small-padding-y": "0.5rem",
        "components-product-information-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-small-label-margin-bottom": "1.5rem",
        "components-product-information-spacing-small-ratings-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-details-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-space-between": "0.75rem",
        "components-product-information-spacing-small-carousel-space-between": "2.5rem",
        "components-product-information-spacing-small-button-margin-y": "0.75rem",
        "components-product-information-spacing-small-body-margin-top": "1rem",
        "components-product-information-spacing-small-image-space-between": "0.75rem",
        "components-product-information-spacing-small-image-margin-bottom": "1rem",
        "components-product-results-list-with-search-spacing-large-padding-x": "5rem",
        "components-product-results-list-with-search-spacing-large-padding-y": "5rem",
        "components-product-results-list-with-search-spacing-large-ruleline-margin-top": "2.5rem",
        "components-product-results-list-with-search-spacing-large-filter-space-between": "1rem",
        "components-product-results-list-with-search-spacing-large-card-space-between": "1.5rem",
        "components-product-results-list-with-search-spacing-large-card-container-margin-bottom": "5rem",
        "components-product-results-list-with-search-spacing-small-padding-x": "1.5rem",
        "components-product-results-list-with-search-spacing-small-padding-y": "2.5rem",
        "components-product-results-list-with-search-spacing-small-ruleline-margin-top": "1rem",
        "components-product-results-list-with-search-spacing-small-filter-padding-x": "0.5rem",
        "components-product-results-list-with-search-spacing-small-card-space-between": "1rem",
        "components-product-results-list-with-search-spacing-small-card-margin-bottom": "1.5rem",
        "components-promo-spacing-large-padding-x": "2rem",
        "components-promo-spacing-large-padding-y": "2rem",
        "components-promo-spacing-large-content-space-between": "-5rem",
        "components-promo-spacing-large-content-space-between-y": "-5rem",
        "components-promo-spacing-large-padding-content-x": "10rem",
        "components-promo-spacing-large-padding-content-condensed-x": "7.5rem",
        "components-promo-spacing-large-padding-content-y": "10rem",
        "components-promo-spacing-large-padding-content-condensed-y": "7.5rem",
        "components-promo-spacing-large-padding-image-x": "0rem",
        "components-promo-spacing-large-padding-image-y": "0rem",
        "components-promo-spacing-large-padding-image-top": "0.5rem",
        "components-promo-spacing-large-title-margin-bottom": "2rem",
        "components-promo-spacing-large-margin-y": "2rem",
        "components-promo-spacing-large-buttons-space-between": "1rem",
        "components-promo-spacing-small-padding-x": "0rem",
        "components-promo-spacing-small-padding-y": "0rem",
        "components-promo-spacing-small-padding-top": "0rem",
        "components-promo-spacing-small-padding-content-x": "2.5rem",
        "components-promo-spacing-small-padding-content-y": "1.5rem",
        "components-promo-spacing-small-padding-image-x": "1rem",
        "components-promo-spacing-small-padding-image-y": "0rem",
        "components-promo-spacing-small-padding-image-top": "1rem",
        "components-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-promo-spacing-small-margin-y": "1.5rem",
        "components-promo-minheight": "30rem",
        "components-pull-quote-spacing-large-padding-quote-x": "4rem",
        "components-pull-quote-spacing-large-padding-quote-y": "4rem",
        "components-pull-quote-spacing-large-title-margin-bottom": "2rem",
        "components-pull-quote-spacing-large-quote-margin-bottom": "4rem",
        "components-pull-quote-spacing-large-quote-space-between": "1.5rem",
        "components-pull-quote-spacing-large-detail-space-between": "0.5rem",
        "components-pull-quote-spacing-large-quote-minheight": "25rem",
        "components-pull-quote-spacing-small-padding-quote-x": "1.5rem",
        "components-pull-quote-spacing-small-padding-quote-y": "2.5rem",
        "components-pull-quote-spacing-small-title-margin-bottom": "1.5rem",
        "components-pull-quote-spacing-small-quote-margin-bottom": "2.5rem",
        "components-pull-quote-spacing-small-quote-space-between": "1rem",
        "components-pull-quote-spacing-small-detail-space-between": "1rem",
        "components-pull-quote-spacing-small-quote-minheight": "15rem",
        "components-promo-alternate-minheight": "30rem",
        "components-promo-alternate-spacing-large-padding-x": "5rem",
        "components-promo-alternate-spacing-large-padding-y": "5rem",
        "components-promo-alternate-spacing-large-content-space-between": "0rem",
        "components-promo-alternate-spacing-large-padding-content-x": "3rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-x": "3rem",
        "components-promo-alternate-spacing-large-padding-content-y": "3rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-y": "3rem",
        "components-promo-alternate-spacing-large-padding-image-x": "2rem",
        "components-promo-alternate-spacing-large-padding-image-y": "2rem",
        "components-promo-alternate-spacing-large-padding-image-top": "0.5rem",
        "components-promo-alternate-spacing-large-title-margin-bottom": "2rem",
        "components-promo-alternate-spacing-small-padding-x": "0rem",
        "components-promo-alternate-spacing-small-padding-y": "0rem",
        "components-promo-alternate-spacing-small-padding-content-x": "2.5rem",
        "components-promo-alternate-spacing-small-padding-content-y": "1.5rem",
        "components-promo-alternate-spacing-small-padding-image-x": "1rem",
        "components-promo-alternate-spacing-small-padding-image-y": "0rem",
        "components-promo-alternate-spacing-small-padding-image-top": "1rem",
        "components-promo-alternate-spacing-small-title-margin-bottom": "1.5rem",
        "components-share-list-spacing-padding-x": "1rem",
        "components-share-list-spacing-padding-y": "1rem",
        "components-header-copy-margin-bottom": "1rem",
        "components-header-margin-bottom": "1.5rem",
        "components-item-margin-bottom": "1.5rem",
        "components-site-search-padding-x": "2.5rem",
        "components-site-search-padding-y": "5rem",
        "components-site-search-facet-label-space-between": "0.25rem",
        "components-site-search-refine-padding-x": "1.5rem",
        "components-site-search-refine-padding-y": "0rem",
        "components-site-search-hero-search-height": "22.5rem",
        "components-site-search-hero-search-large-padding-x": "5rem",
        "components-site-search-hero-search-large-padding-y": "5rem",
        "components-stats-spacing-stat-text-space-between": "0.5rem",
        "components-stats-spacing-large-inner-padding-x": "5rem",
        "components-stats-spacing-large-inner-padding-y": "5rem",
        "components-stats-spacing-small-inner-padding-x": "1.5rem",
        "components-stats-spacing-small-inner-padding-y": "1.5rem",
        "components-icon-card-listing-spacing-small-top-space-between": "1rem",
        "components-supporting-text-padding-x": "0rem",
        "components-supporting-text-padding-bottom": "0.5rem",
        "components-tab-item-padding-y": "0.75rem",
        "components-tab-item-padding-x": "0.75rem",
        "components-tab-item-spacing-x": "0.75rem",
        "components-tab-input-padding-y": "0.25rem",
        "components-tab-input-padding-x": "1rem",
        "components-tag-spacing-padding-x": "0.5rem",
        "components-tag-spacing-padding-y": "0.5rem",
        "components-tag-spacing-space-between": "0.5rem",
        "components-text-field-input-padding-y": "0.25rem",
        "components-text-field-input-padding-x": "1rem",
        "components-text-field-area-input-padding-y": "1rem",
        "components-text-field-area-input-padding-x": "1rem",
        "components-text-field-supporting-text-padding-x": "0rem",
        "components-text-field-supporting-text-padding-top": "0.5rem",
        "themes-general-surface-spacing-large-padding-x": "5rem",
        "themes-general-surface-spacing-large-padding-y": "5rem",
        "themes-general-surface-spacing-large-title-margin-bottom": "2rem",
        "themes-general-surface-spacing-large-body-margin-right": "4rem",
        "themes-general-surface-spacing-large-body-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-large-margin-bottom": "4rem",
        "themes-general-surface-spacing-large-cards-space-between": "1.5rem",
        "themes-general-surface-spacing-large-button-margin-top": "2.5rem",
        "themes-general-surface-spacing-small-padding-x": "1.5rem",
        "themes-general-surface-spacing-small-padding-y": "2.5rem",
        "themes-general-surface-spacing-small-title-margin-bottom": "1.5rem",
        "themes-general-surface-spacing-small-body-margin-right": "2.5rem",
        "themes-general-surface-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-surface-spacing-small-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-small-cards-space-between": "1rem",
        "themes-general-surface-spacing-small-button-margin-top": "1.5rem",
        "themes-general-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "themes-general-card-spacing-large-title-margin-bottom": "1rem",
        "themes-general-card-spacing-large-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-large-button-space-between": "1.5rem",
        "themes-general-card-spacing-small-eyebrow-margin-bottom": "1rem",
        "themes-general-card-spacing-small-title-margin-bottom": "1rem",
        "themes-general-card-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-small-button-space-between": "1.5rem",
        "themes-shadow-share-list-spacing-x": "0rem",
        "themes-shadow-share-list-spacing-y": "0.75rem",
        "themes-shadow-share-list-spacing-blur": "1rem",
        "themes-shadow-share-list-spacing-spread": "-0.5rem",
        "themes-shadow-header-menu-spacing-x": "0rem",
        "themes-shadow-header-menu-spacing-y": "0.75rem",
        "themes-shadow-header-menu-spacing-blur": "1rem",
        "themes-shadow-header-menu-spacing-spread": "-0.5rem",
        "themes-typography-rte-large-h1-textsize": "3rem",
        "themes-typography-rte-large-h2-textsize": "2.5rem",
        "themes-typography-rte-large-h3-textsize": "2.25rem",
        "themes-typography-rte-large-h4-textsize": "2rem",
        "themes-typography-rte-large-h5-textsize": "1.5rem",
        "themes-typography-rte-large-h6-textsize": "1.25rem",
        "themes-typography-rte-medium-h1-textsize": "2.5rem",
        "themes-typography-rte-medium-h2-textsize": "2.25rem",
        "themes-typography-rte-medium-h3-textsize": "1.75rem",
        "themes-typography-rte-medium-h4-textsize": "1.5rem",
        "themes-typography-rte-medium-h5-textsize": "1.25rem",
        "themes-typography-rte-medium-h6-textsize": "1.125rem",
        "themes-typography-rte-small-h1-textsize": "2.5rem",
        "themes-typography-rte-small-h2-textsize": "2rem",
        "themes-typography-rte-small-h3-textsize": "1.75rem",
        "themes-typography-rte-small-h4-textsize": "1.5rem",
        "themes-typography-rte-small-h5-textsize": "1.125rem",
        "themes-typography-rte-small-h6-textsize": "1rem",
        "spacing-margin-small-1": "0.125rem",
        "spacing-margin-small-2": "0.25rem",
        "spacing-margin-small-3": "0.5rem",
        "spacing-margin-small-4": "0.75rem",
        "spacing-margin-small-5": "1rem",
        "spacing-margin-small-6": "1.5rem",
        "spacing-margin-small-7": "2.5rem",
        "spacing-margin-medium-2": "0.75rem",
        "spacing-margin-medium-4": "1.5rem",
        "spacing-margin-medium-5": "2rem",
        "spacing-margin-large-1": "0.5rem",
        "spacing-margin-large-2": "1rem",
        "spacing-margin-large-3": "1.5rem",
        "spacing-margin-large-4": "2rem",
        "spacing-margin-large-5": "2.5rem",
        "spacing-margin-large-6": "4rem",
        "spacing-margin-large-7": "5rem",
        "spacing-space-between-small-1": "0.125rem",
        "spacing-space-between-small-2": "0.25rem",
        "spacing-space-between-small-3": "0.5rem",
        "spacing-space-between-small-4": "0.75rem",
        "spacing-space-between-small-5": "1rem",
        "spacing-space-between-small-6": "1.5rem",
        "spacing-space-between-small-7": "2.5rem",
        "spacing-space-between-medium-1": "0.125rem",
        "spacing-space-between-medium-2": "0.25rem",
        "spacing-space-between-medium-3": "0.5rem",
        "spacing-space-between-medium-4": "0.75rem",
        "spacing-space-between-medium-5": "1rem",
        "spacing-space-between-large-1": "0.25rem",
        "spacing-space-between-large-2": "0.5rem",
        "spacing-space-between-large-3": "1rem",
        "spacing-space-between-large-4": "1.5rem",
        "spacing-space-between-large-5": "1.5rem",
        "spacing-space-between-large-6": "2.5rem",
        "spacing-space-between-large-7": "4rem",
        "spacing-padding-small-1": "0.125rem",
        "spacing-padding-small-2": "0.25rem",
        "spacing-padding-small-3": "0.5rem",
        "spacing-padding-small-4": "0.75rem",
        "spacing-padding-small-5": "1rem",
        "spacing-padding-small-6": "1.5rem",
        "spacing-padding-small-7": "2.5rem",
        "spacing-padding-medium-1": "0.25rem",
        "spacing-padding-medium-2": "0.75rem",
        "spacing-padding-medium-3": "1rem",
        "spacing-padding-medium-4": "1.5rem",
        "spacing-padding-medium-5": "2rem",
        "spacing-padding-medium-6": "3rem",
        "spacing-padding-medium-7": "4rem",
        "spacing-padding-large-1": "0.5rem",
        "spacing-padding-large-2": "1rem",
        "spacing-padding-large-3": "1.5rem",
        "spacing-padding-large-4": "2rem",
        "spacing-padding-large-5": "2.5rem",
        "spacing-padding-large-6": "4rem",
        "spacing-padding-large-7": "5rem"
      },
      "string": {
        "components-accordion-section-button": "var(--brand)",
        "components-article-card-listing-button-theme": "var(--brand)",
        "components-article-hero-pagination-buttons": "var(--tonal)",
        "components-banner-banner-button": "var(--brand)",
        "components-category-card-listing-button-theme": "var(--brand)",
        "components-contact-us-promo-hierarchy-button-theme-1": "var(--filled)",
        "components-contact-us-promo-hierarchy-button-theme-2": "var(--outlined)",
        "components-contact-us-promo-color-button-theme": "var(--tonal)",
        "components-contact-us-promo-color-brand-button-theme": "var(--white)",
        "components-generic-card-button-theme": "var(--white)",
        "components-global-search-loader": "var(--default)",
        "components-generic-card-listing-decor-top": "var(--corporate-1)",
        "components-generic-card-listing-decor-bottom": "var(--corporate-2)",
        "components-hero-homepage-pagination-buttons": "var(--tonal)",
        "components-hero-homepage-pattern": "var(--none)",
        "components-hero-homepage-button-1": "var(--brand)",
        "components-hero-homepage-button-2": "var(--tonal)",
        "components-hero-homepage-image-ratio": "var(--5:4)",
        "components-hero-internal-theme-button-one-type": "var(--filled)",
        "components-hero-internal-theme-button-two-type": "var(--outlined)",
        "components-hero-internal-theme-button-color": "var(--white)",
        "components-hero-internal-theme-button-color-with-image": "var(--white)",
        "components-lightbox-button-type": "var(--brand)",
        "components-pdf-link-card-hierarchy-button-theme-1": "var(--filled)",
        "components-pdf-link-card-hierarchy-button-theme-2": "var(--outlined)",
        "components-pdf-link-card-color-button-theme": "var(--tonal)",
        "components-product-card-badge-align": "var(--center)",
        "components-product-card-listing-button-color": "var(--brand)",
        "components-promo-theme-button-one-theme": "var(--outlined)",
        "components-promo-theme-button-two-theme": "var(--text)",
        "components-promo-color-default-button-theme": "var(--brand)",
        "components-promo-color-light-brand-light-button-theme": "var(--white)",
        "components-promo-color-brand-button-theme": "var(--white)",
        "components-pull-quote-border-decor": "var(--corporate)",
        "components-promo-alternate-theme-button-one-theme": "var(--outlined)",
        "components-promo-alternate-theme-button-two-theme": "var(--text)",
        "components-promo-alternate-color-default-button-theme": "var(--brand)",
        "components-promo-alternate-color-light-brand-light-button-theme": "var(--white)",
        "components-promo-alternate-color-brand-button-theme": "var(--white)",
        "components-share-list-button-color": "var(--brand)",
        "components-site-search-hero-search-hero-pattern": "var(--scj)",
        "components-stats-decor-top": "var(--corporate-1)",
        "components-stats-decor-bottom": "var(--corporate-2)",
        "themes-brand-loader": "var(--default)",
        "themes-brand-variants": "var(--corporate)",
        "themes-tab-variant": "var(--default)",
        "themes-label-variants": "var(--default)",
        "themes-logo": "var(--corporate)",
        "themes-category-card": "var(--corporate)",
        "themes-title-decor": "var(--corporate)",
        "themes-typography-heading-fontfamily": "var(--gotham)",
        "themes-typography-heading-large-xxlarge-fontweight": "var(--black)",
        "themes-typography-heading-large-xlarge-fontweight": "var(--black)",
        "themes-typography-heading-large-large-fontweight": "var(--black)",
        "themes-typography-heading-large-medium-fontweight": "var(--black)",
        "themes-typography-heading-large-small-fontweight": "var(--black)",
        "themes-typography-heading-large-xsmall-fontweight": "var(--black)",
        "themes-typography-heading-large-xxsmall-fontweight": "var(--black)",
        "themes-typography-heading-medium-xxlarge-fontweight": "var(--black)",
        "themes-typography-heading-medium-xlarge-fontweight": "var(--black)",
        "themes-typography-heading-medium-large-fontweight": "var(--black)",
        "themes-typography-heading-medium-medium-fontweight": "var(--black)",
        "themes-typography-heading-medium-small-fontweight": "var(--black)",
        "themes-typography-heading-medium-xsmall-fontweight": "var(--black)",
        "themes-typography-heading-medium-xxsmall-fontweight": "var(--black)",
        "themes-typography-heading-small-xxlarge-fontweight": "var(--black)",
        "themes-typography-heading-small-xlarge-fontweight": "var(--black)",
        "themes-typography-heading-small-large-fontweight": "var(--black)",
        "themes-typography-heading-small-medium-fontweight": "var(--black)",
        "themes-typography-heading-small-small-fontweight": "var(--black)",
        "themes-typography-heading-small-xsmall-fontweight": "var(--black)",
        "themes-typography-heading-small-xxsmall-fontweight": "var(--black)",
        "themes-typography-rte-fontfamily": "var(--gotham)",
        "themes-typography-rte-fontweight": "var(--black)"
      },
      "borderRadius": {
        "components-accordion-spacing-large-item-item-radius": "0rem",
        "components-article-card-spacing-large-image-radius-tl": "0rem",
        "components-article-card-spacing-large-image-radius-tr": "0rem",
        "components-article-card-spacing-large-image-radius-bl": "0rem",
        "components-article-card-spacing-large-image-radius-br": "0rem",
        "components-article-card-spacing-large-list-image-radius-br": "0rem",
        "components-article-card-spacing-small-image-radius-br": "0rem",
        "components-article-card-spacing-small-list-image-radius-br": "0rem",
        "components-article-hero-spacing-large-content-radius": "1.5rem",
        "components-article-hero-spacing-small-content-radius": "1rem",
        "components-button-radius-large": "7.5rem",
        "components-button-group-spacing-border-radius": "7.5rem",
        "components-category-card-radius-border-width": "0.0625rem",
        "components-category-card-radius-border-radius": "7.5rem",
        "components-category-card-radius-border-radius-condensed": "0.5rem",
        "components-category-card-radius-media-radius": "0.75rem",
        "components-content-list-icon-radius": "7.5rem",
        "components-fab-spacing-border-radius": "7.5rem",
        "components-header-spacing-large-search-border-radius-top": "7.5rem",
        "components-header-spacing-large-search-border-radius-bottom": "7.5rem",
        "components-header-spacing-large-language-selector-radius": "0.5rem",
        "components-header-spacing-large-dropdown-radius": "0.5rem",
        "components-hero-homepage-spacing-large-container-radius": "1rem",
        "components-hero-internal-spacing-large-icon-radius": "7.5rem",
        "components-hero-internal-spacing-small-icon-radius": "7.5rem",
        "components-lightbox-spacing-large-overlay-radius": "1rem",
        "components-pagination-scrollbar-radius": "62.5rem",
        "components-product-card-spacing-image-radius-tl": "0rem",
        "components-product-card-spacing-image-radius-tr": "0rem",
        "components-product-card-spacing-image-radius-bl": "0rem",
        "components-product-card-spacing-image-radius-br": "0rem",
        "components-product-information-spacing-large-content-radius": "1.5rem",
        "components-product-information-spacing-small-content-radius": "1rem",
        "components-promo-spacing-large-content-radius": "1rem",
        "components-promo-spacing-large-content-radius-tl": "1rem",
        "components-promo-spacing-large-content-radius-tr": "1rem",
        "components-promo-spacing-large-content-radius-bl": "1rem",
        "components-promo-spacing-large-content-radius-br": "1rem",
        "components-promo-spacing-large-image-radius-tl": "1rem",
        "components-promo-spacing-large-image-radius-tr": "1rem",
        "components-promo-spacing-large-image-radius-bl": "1rem",
        "components-promo-spacing-large-image-radius-br": "1rem",
        "components-promo-spacing-small-content-radius": "0rem",
        "components-promo-spacing-small-image-radius-tl": "0.75rem",
        "components-promo-spacing-small-image-radius-tr": "0.75rem",
        "components-promo-spacing-small-image-radius-bl": "0.75rem",
        "components-promo-spacing-small-image-radius-br": "0.75rem",
        "components-promo-alternate-spacing-large-content-radius": "0rem",
        "components-promo-alternate-spacing-large-image-radius-tl": "1rem",
        "components-promo-alternate-spacing-large-image-radius-tr": "1rem",
        "components-promo-alternate-spacing-large-image-radius-bl": "1rem",
        "components-promo-alternate-spacing-large-image-radius-br": "1rem",
        "components-promo-alternate-spacing-small-content-radius": "0rem",
        "components-promo-alternate-spacing-small-image-radius-tl": "0.75rem",
        "components-promo-alternate-spacing-small-image-radius-tr": "0.75rem",
        "components-promo-alternate-spacing-small-image-radius-bl": "0.75rem",
        "components-promo-alternate-spacing-small-image-radius-br": "0.75rem",
        "components-share-list-spacing-radius": "0.5rem",
        "components-share-list-spacing-link-radius": "0.5rem",
        "components-site-search-filter-radius": "0.5rem",
        "components-site-search-facet-radius": "0rem",
        "components-site-search-refine-radius": "0rem",
        "components-tag-spacing-radius": "0.25rem",
        "components-tip-spacing-radius": "0.25rem",
        "themes-spacing-radius-pagination-bar": "62.5rem",
        "themes-spacing-radius-large-button": "7.5rem",
        "themes-spacing-radius-large-button-icon": "7.5rem",
        "themes-spacing-radius-large-image": "1rem",
        "themes-spacing-radius-large-image-hero": "1rem",
        "themes-spacing-radius-large-image-card": "0.75rem",
        "themes-spacing-radius-large-card": "1rem",
        "themes-spacing-radius-large-icon": "7.5rem",
        "themes-spacing-radius-large-label": "0.25rem",
        "themes-spacing-radius-large-tag": "0.25rem",
        "themes-spacing-radius-large-container-tip": "0.25rem",
        "themes-spacing-radius-large-badge": "0rem",
        "themes-spacing-radius-large-item": "0.5rem",
        "themes-spacing-radius-large-form-field-input": "7.5rem",
        "themes-spacing-radius-large-form-area-input": "7.5rem",
        "themes-spacing-radius-large-general": "1.5rem",
        "themes-spacing-radius-small-button": "7.5rem",
        "themes-spacing-radius-small-button-icon": "7.5rem",
        "themes-spacing-radius-small-image": "0.75rem",
        "themes-spacing-radius-small-image-card": "0.75rem",
        "themes-spacing-radius-small-card": "0.75rem",
        "themes-spacing-radius-small-icon": "7.5rem",
        "themes-spacing-radius-small-label": "0.125rem",
        "themes-spacing-radius-small-tag": "0.25rem",
        "themes-spacing-radius-small-badge": "0rem",
        "themes-spacing-radius-small-item": "0rem",
        "themes-spacing-radius-small-form-field-input": "7.5rem",
        "themes-spacing-radius-small-general": "1rem"
      },
      "borderWidth": {
        "3": "3px",
        "components-article-card-spacing-large-border-width": "0rem",
        "components-generic-card-color-border-width": "0rem",
        "components-header-spacing-large-search-border-width-top": "0.125rem",
        "components-header-spacing-large-search-border-width-right": "0.125rem",
        "components-header-spacing-large-search-border-width-bottom": "0.125rem",
        "components-header-spacing-large-search-border-width-left": "0.125rem",
        "components-product-card-spacing-border-width": "0.0625rem"
      },
      "maxHeight": {},
      "maxWidth": {
        "components-article-hero-spacing-large-header-max-width": "66.375rem",
        "components-content-list-spacing-large-item-image-max-width": "33.75rem",
        "components-content-list-spacing-small-item-image-max-width": "33.75rem"
      },
      "minHeight": {
        "components-dropdown-item-min-height": "3rem",
        "components-icon-min-height": "3rem",
        "components-tab-item-min-height": "3rem",
        "components-text-field-input-min-height": "3rem",
        "components-text-field-icon-min-height": "3rem"
      },
      "minWidth": {
        "components-content-list-spacing-small-item-image-min-width": "18.75rem",
        "components-icon-min-width": "3rem",
        "components-text-field-icon-min-width": "3rem"
      },
      "opacity": {
        "components-accessibility-bar-color-bg": 1,
        "components-accessibility-bar-color-text": 1,
        "components-accordion-color-bg": 1,
        "components-accordion-color-title": 1,
        "components-accordion-color-body": 1,
        "components-accordion-color-section-section-bg": 1,
        "components-accordion-color-section-section-title": 1,
        "components-accordion-color-section-section-body": 1,
        "components-accordion-color-item-item-bg": 1,
        "components-accordion-color-item-divider": 1,
        "components-accordion-color-item-icon-icon-default": 1,
        "components-accordion-color-item-icon-icon-hover": 1,
        "components-accordion-color-item-icon-icon-pressed": 1,
        "components-accordion-color-item-icon-icon-active": 1,
        "components-accordion-color-item-title-item-default": 1,
        "components-accordion-color-item-title-item-hover": 1,
        "components-accordion-color-item-title-item-pressed": 1,
        "components-accordion-color-item-title-item-active": 1,
        "components-accordion-color-content-bg": 1,
        "components-accordion-color-content-title": 1,
        "components-accordion-color-content-body": 1,
        "components-article-card-color-bg": 1,
        "components-article-card-color-border": 1,
        "components-article-card-color-content-bg": 1,
        "components-article-card-color-title": 1,
        "components-article-card-color-subtitle": 1,
        "components-article-card-color-copy": 1,
        "components-article-card-color-description": 1,
        "components-article-card-listing-color-default-bg": 1,
        "components-article-card-listing-color-default-title": 1,
        "components-article-card-listing-color-default-copy": 1,
        "components-article-hero-color-content-bg": 1,
        "components-article-hero-color-bg": 1,
        "components-article-hero-color-headline": 1,
        "components-article-hero-color-body": 1,
        "components-article-hero-color-detail-text": 1,
        "components-article-hero-color-divider": 1,
        "components-article-hero-color-icon": 1,
        "components-article-hero-color-play-icon": 1,
        "components-article-hero-color-selected-stroke": 1,
        "components-banner-color-bg-default": 1,
        "components-banner-color-icon-default": 1,
        "components-banner-color-title-default": 1,
        "components-banner-color-body-default": 1,
        "components-banner-color-icon-close-default": 1,
        "components-banner-color-bg-alert": 1,
        "components-banner-color-icon-alert": 1,
        "components-banner-color-title-alert": 1,
        "components-banner-color-body-alert": 1,
        "components-banner-color-icon-close-alert": 1,
        "components-breadcrumb-color-bg": 1,
        "components-breadcrumb-color-breadcrumb-link-default": 1,
        "components-breadcrumb-color-breadcrumb-link-hover": 1,
        "components-breadcrumb-color-breadcrumb-link-active": 1,
        "components-breadcrumb-color-icon-divider": 1,
        "components-bug-finder-prompt": 1,
        "components-button-color-filled-brand-default-bg": 1,
        "components-button-color-filled-brand-default-text": 1,
        "components-button-color-filled-brand-default-stroke": 1,
        "components-button-color-filled-brand-default-icon": 1,
        "components-button-color-filled-brand-hover-bg": 1,
        "components-button-color-filled-brand-hover-text": 1,
        "components-button-color-filled-brand-hover-stroke": 1,
        "components-button-color-filled-brand-hover-icon": 1,
        "components-button-color-filled-brand-pressed-bg": 1,
        "components-button-color-filled-brand-pressed-text": 1,
        "components-button-color-filled-brand-pressed-stroke": 1,
        "components-button-color-filled-brand-pressed-icon": 1,
        "components-button-color-filled-brand-disabled-bg": 0,
        "components-button-color-filled-brand-disabled-text": 0.02,
        "components-button-color-filled-brand-disabled-stroke": 0,
        "components-button-color-filled-brand-disabled-icon": 0.02,
        "components-button-color-filled-brand-focus-bg": 1,
        "components-button-color-filled-brand-focus-text": 1,
        "components-button-color-filled-brand-focus-stroke": 1,
        "components-button-color-filled-brand-focus-icon": 1,
        "components-button-color-filled-brand-focus-outline": 1,
        "components-button-color-filled-tonal-default-bg": 1,
        "components-button-color-filled-tonal-default-text": 1,
        "components-button-color-filled-tonal-default-stroke": 1,
        "components-button-color-filled-tonal-default-icon": 1,
        "components-button-color-filled-tonal-hover-bg": 1,
        "components-button-color-filled-tonal-hover-text": 1,
        "components-button-color-filled-tonal-hover-stroke": 1,
        "components-button-color-filled-tonal-hover-icon": 1,
        "components-button-color-filled-tonal-pressed-bg": 1,
        "components-button-color-filled-tonal-pressed-text": 1,
        "components-button-color-filled-tonal-pressed-stroke": 1,
        "components-button-color-filled-tonal-pressed-icon": 1,
        "components-button-color-filled-tonal-disabled-bg": 0.03,
        "components-button-color-filled-tonal-disabled-text": 0.02,
        "components-button-color-filled-tonal-disabled-stroke": 0.03,
        "components-button-color-filled-tonal-disabled-icon": 0.02,
        "components-button-color-filled-tonal-focus-bg": 1,
        "components-button-color-filled-tonal-focus-text": 1,
        "components-button-color-filled-tonal-focus-stroke": 1,
        "components-button-color-filled-tonal-focus-icon": 1,
        "components-button-color-filled-tonal-focus-outline": 1,
        "components-button-color-filled-white-default-bg": 1,
        "components-button-color-filled-white-default-text": 1,
        "components-button-color-filled-white-default-stroke": 1,
        "components-button-color-filled-white-default-icon": 1,
        "components-button-color-filled-white-hover-bg": 1,
        "components-button-color-filled-white-hover-text": 1,
        "components-button-color-filled-white-hover-stroke": 1,
        "components-button-color-filled-white-hover-icon": 1,
        "components-button-color-filled-white-pressed-bg": 1,
        "components-button-color-filled-white-pressed-text": 1,
        "components-button-color-filled-white-pressed-stroke": 1,
        "components-button-color-filled-white-pressed-icon": 1,
        "components-button-color-filled-white-disabled-bg": 0.02,
        "components-button-color-filled-white-disabled-text": 0,
        "components-button-color-filled-white-disabled-stroke": 0.02,
        "components-button-color-filled-white-disabled-icon": 0,
        "components-button-color-filled-white-focus-bg": 1,
        "components-button-color-filled-white-focus-text": 1,
        "components-button-color-filled-white-focus-stroke": 1,
        "components-button-color-filled-white-focus-icon": 1,
        "components-button-color-filled-white-focus-outline": 1,
        "components-button-color-outline-brand-default-bg": 0.03,
        "components-button-color-outline-brand-default-text": 1,
        "components-button-color-outline-brand-default-stroke": 1,
        "components-button-color-outline-brand-default-icon": 1,
        "components-button-color-outline-brand-hover-bg": 1,
        "components-button-color-outline-brand-hover-text": 1,
        "components-button-color-outline-brand-hover-stroke": 1,
        "components-button-color-outline-brand-hover-icon": 1,
        "components-button-color-outline-brand-pressed-bg": 1,
        "components-button-color-outline-brand-pressed-text": 1,
        "components-button-color-outline-brand-pressed-stroke": 1,
        "components-button-color-outline-brand-pressed-icon": 1,
        "components-button-color-outline-brand-disabled-bg": 0.03,
        "components-button-color-outline-brand-disabled-text": 0.03,
        "components-button-color-outline-brand-disabled-stroke": 0.03,
        "components-button-color-outline-brand-disabled-icon": 0.03,
        "components-button-color-outline-brand-focus-bg": 1,
        "components-button-color-outline-brand-focus-text": 1,
        "components-button-color-outline-brand-focus-stroke": 1,
        "components-button-color-outline-brand-focus-icon": 1,
        "components-button-color-outline-brand-focus-outline": 1,
        "components-button-color-outline-tonal-default-bg": 0.02,
        "components-button-color-outline-tonal-default-text": 1,
        "components-button-color-outline-tonal-default-stroke": 1,
        "components-button-color-outline-tonal-default-icon": 1,
        "components-button-color-outline-tonal-hover-bg": 1,
        "components-button-color-outline-tonal-hover-text": 1,
        "components-button-color-outline-tonal-hover-stroke": 1,
        "components-button-color-outline-tonal-hover-icon": 1,
        "components-button-color-outline-tonal-pressed-bg": 1,
        "components-button-color-outline-tonal-pressed-text": 1,
        "components-button-color-outline-tonal-pressed-stroke": 1,
        "components-button-color-outline-tonal-pressed-icon": 1,
        "components-button-color-outline-tonal-disabled-bg": 0.02,
        "components-button-color-outline-tonal-disabled-text": 0.02,
        "components-button-color-outline-tonal-disabled-stroke": 0.02,
        "components-button-color-outline-tonal-disabled-icon": 0.03,
        "components-button-color-outline-tonal-focus-bg": 1,
        "components-button-color-outline-tonal-focus-text": 1,
        "components-button-color-outline-tonal-focus-stroke": 1,
        "components-button-color-outline-tonal-focus-icon": 1,
        "components-button-color-outline-tonal-focus-outline": 1,
        "components-button-color-outline-white-default-bg": 0.02,
        "components-button-color-outline-white-default-text": 1,
        "components-button-color-outline-white-default-stroke": 1,
        "components-button-color-outline-white-default-icon": 1,
        "components-button-color-outline-white-hover-bg": 1,
        "components-button-color-outline-white-hover-text": 1,
        "components-button-color-outline-white-hover-stroke": 1,
        "components-button-color-outline-white-hover-icon": 1,
        "components-button-color-outline-white-pressed-bg": 1,
        "components-button-color-outline-white-pressed-text": 1,
        "components-button-color-outline-white-pressed-stroke": 1,
        "components-button-color-outline-white-pressed-icon": 1,
        "components-button-color-outline-white-disabled-bg": 0.02,
        "components-button-color-outline-white-disabled-text": 0.02,
        "components-button-color-outline-white-disabled-stroke": 0.02,
        "components-button-color-outline-white-disabled-icon": 0.02,
        "components-button-color-outline-white-focus-bg": 0.02,
        "components-button-color-outline-white-focus-text": 1,
        "components-button-color-outline-white-focus-stroke": 1,
        "components-button-color-outline-white-focus-icon": 1,
        "components-button-color-outline-white-focus-outline": 1,
        "components-button-color-text-brand-default-bg": 0.02,
        "components-button-color-text-brand-default-text": 1,
        "components-button-color-text-brand-default-stroke": 1,
        "components-button-color-text-brand-default-icon": 1,
        "components-button-color-text-brand-hover-bg": 0.02,
        "components-button-color-text-brand-hover-bg-icon-only": 1,
        "components-button-color-text-brand-hover-text": 1,
        "components-button-color-text-brand-hover-stroke": 1,
        "components-button-color-text-brand-hover-icon": 1,
        "components-button-color-text-brand-pressed-bg": 0.02,
        "components-button-color-text-brand-pressed-bg-icon-only": 1,
        "components-button-color-text-brand-pressed-text": 1,
        "components-button-color-text-brand-pressed-stroke": 1,
        "components-button-color-text-brand-pressed-icon": 1,
        "components-button-color-text-brand-disabled-bg": 0.02,
        "components-button-color-text-brand-disabled-text": 0.03,
        "components-button-color-text-brand-disabled-stroke": 0.03,
        "components-button-color-text-brand-disabled-icon": 0.03,
        "components-button-color-text-brand-focus-bg": 1,
        "components-button-color-text-brand-focus-text": 1,
        "components-button-color-text-brand-focus-stroke": 1,
        "components-button-color-text-brand-focus-icon": 1,
        "components-button-color-text-brand-focus-outline": 1,
        "components-button-color-text-tonal-default-bg": 0.02,
        "components-button-color-text-tonal-default-text": 1,
        "components-button-color-text-tonal-default-stroke": 1,
        "components-button-color-text-tonal-default-icon": 1,
        "components-button-color-text-tonal-hover-bg": 0.02,
        "components-button-color-text-tonal-hover-bg-icon-only": 1,
        "components-button-color-text-tonal-hover-text": 1,
        "components-button-color-text-tonal-hover-stroke": 1,
        "components-button-color-text-tonal-hover-icon": 1,
        "components-button-color-text-tonal-pressed-bg": 0.02,
        "components-button-color-text-tonal-pressed-bg-icon-only": 1,
        "components-button-color-text-tonal-pressed-text": 1,
        "components-button-color-text-tonal-pressed-stroke": 1,
        "components-button-color-text-tonal-pressed-icon": 1,
        "components-button-color-text-tonal-disabled-bg": 0.02,
        "components-button-color-text-tonal-disabled-text": 0.03,
        "components-button-color-text-tonal-disabled-stroke": 0.03,
        "components-button-color-text-tonal-disabled-icon": 0.03,
        "components-button-color-text-tonal-focus-bg": 1,
        "components-button-color-text-tonal-focus-text": 1,
        "components-button-color-text-tonal-focus-stroke": 1,
        "components-button-color-text-tonal-focus-icon": 1,
        "components-button-color-text-tonal-focus-outline": 1,
        "components-button-color-text-white-default-bg": 0.02,
        "components-button-color-text-white-default-text": 1,
        "components-button-color-text-white-default-stroke": 1,
        "components-button-color-text-white-default-icon": 1,
        "components-button-color-text-white-hover-bg": 0.02,
        "components-button-color-text-white-hover-bg-icon-only": 1,
        "components-button-color-text-white-hover-text": 1,
        "components-button-color-text-white-hover-stroke": 1,
        "components-button-color-text-white-hover-icon": 1,
        "components-button-color-text-white-pressed-bg": 0.02,
        "components-button-color-text-white-pressed-bg-icon-only": 1,
        "components-button-color-text-white-pressed-text": 1,
        "components-button-color-text-white-pressed-stroke": 1,
        "components-button-color-text-white-pressed-icon": 1,
        "components-button-color-text-white-disabled-bg": 0.02,
        "components-button-color-text-white-disabled-text": 0.01,
        "components-button-color-text-white-disabled-stroke": 0.01,
        "components-button-color-text-white-disabled-icon": 0.01,
        "components-button-color-text-white-focus-bg": 1,
        "components-button-color-text-white-focus-text": 1,
        "components-button-color-text-white-focus-stroke": 1,
        "components-button-color-text-white-focus-icon": 1,
        "components-button-color-text-white-focus-outline": 1,
        "components-button-group-color-border": 1,
        "components-button-group-color-button-bg-default": 0.02,
        "components-button-group-color-button-fg-default": 1,
        "components-button-group-color-button-bg-hover": 1,
        "components-button-group-color-button-fg-hover": 1,
        "components-button-group-color-button-bg-active": 1,
        "components-button-group-color-button-fg-active": 1,
        "components-button-group-color-overlay-left-gradient-fill-1": 1,
        "components-button-group-color-overlay-left-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-1": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-3": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-4": 0.02,
        "components-button-group-color-stroke-hover-gradient-fill-1": 1,
        "components-button-group-color-stroke-hover-gradient-fill-2": 1,
        "components-button-group-color-stroke-hover-gradient-fill-3": 1,
        "components-button-group-color-stroke-hover-gradient-fill-4": 1,
        "components-button-group-color-stroke-active-gradient-fill-1": 1,
        "components-button-group-color-stroke-active-gradient-fill-2": 1,
        "components-button-group-color-stroke-active-gradient-fill-3": 1,
        "components-button-group-color-stroke-active-gradient-fill-4": 1,
        "components-category-card-color-bg": 1,
        "components-category-card-color-content-bg": 1,
        "components-category-card-color-category-bg": 1,
        "components-category-card-color-category-bg-2": 1,
        "components-category-card-color-category-bg-3": 1,
        "components-category-card-color-category-bg-4": 1,
        "components-category-card-color-category-bg-5": 1,
        "components-category-card-color-category-bg-6": 1,
        "components-category-card-color-title": 1,
        "components-category-card-color-copy": 1,
        "components-category-card-color-stroke": 1,
        "components-category-card-color-stroke-hover": 1,
        "components-category-card-listing-color-bg": 1,
        "components-category-card-listing-color-title": 1,
        "components-category-card-listing-color-copy": 1,
        "components-card-search-result-color-bg": 1,
        "components-card-search-result-color-label": 1,
        "components-card-search-result-color-title": 1,
        "components-card-search-result-color-body": 1,
        "components-card-search-result-color-divider-line": 1,
        "components-contact-us-promo-color-light-bg": 1,
        "components-contact-us-promo-color-light-title": 1,
        "components-contact-us-promo-color-light-body": 1,
        "components-contact-us-promo-color-default-bg": 1,
        "components-contact-us-promo-color-default-title": 1,
        "components-contact-us-promo-color-default-body": 1,
        "components-contact-us-promo-color-brand-bg": 1,
        "components-contact-us-promo-color-brand-title": 1,
        "components-contact-us-promo-color-brand-body": 1,
        "components-content-list-color-bg": 1,
        "components-content-list-color-title": 1,
        "components-content-list-color-body": 1,
        "components-content-list-color-icon": 1,
        "components-cookies-colors-bg": 1,
        "components-cookies-colors-title": 1,
        "components-cookies-colors-body": 1,
        "components-cookies-colors-icon": 1,
        "components-dropdown-bg": 1,
        "components-dropdown-border-default": 1,
        "components-dropdown-border-hover": 1,
        "components-dropdown-border-focused": 1,
        "components-dropdown-border-destructive": 1,
        "components-dropdown-label-text-default": 1,
        "components-dropdown-item-text-default": 1,
        "components-dropdown-item-bg-default": 1,
        "components-dropdown-item-bg-hover": 1,
        "components-dropdown-input-field-default": 1,
        "components-dropdown-input-field-focused": 1,
        "components-dropdown-input-label-focused": 1,
        "components-dropdown-input-field-destructive": 1,
        "components-external-link-card-bg": 1,
        "components-external-link-card-card-bg": 1,
        "components-external-link-card-card-title": 1,
        "components-external-link-card-card-subtitle": 1,
        "components-external-link-card-card-eyebrow": 1,
        "components-fab-color-border-default": 1,
        "components-footer-brand-color-bg": 1,
        "components-footer-brand-color-logo": 1,
        "components-footer-brand-color-title-text": 1,
        "components-footer-brand-color-description-text": 1,
        "components-footer-brand-color-footer-menu-expanded": 1,
        "components-footer-brand-color-link-default": 1,
        "components-footer-brand-color-link-hover": 1,
        "components-footer-brand-color-link-pressed": 1,
        "components-footer-brand-color-category-link-default": 1,
        "components-footer-brand-color-category-link-hover": 1,
        "components-footer-brand-color-category-link-pressed": 1,
        "components-footer-brand-color-subcategory-link-default": 1,
        "components-footer-brand-color-subcategory-link-hover": 1,
        "components-footer-brand-color-subcategory-link-pressed": 1,
        "components-footer-brand-color-icon-icon-default": 1,
        "components-footer-brand-color-icon-icon-pressed": 1,
        "components-footer-brand-color-social-icon-default": 1,
        "components-footer-brand-color-social-icon-hover": 1,
        "components-footer-brand-color-social-icon-pressed": 1,
        "components-footer-brand-spacing-category-link-hover": 1,
        "components-footer-global-color-bg": 1,
        "components-footer-global-color-title-text": 1,
        "components-footer-global-color-disclaimer-text": 1,
        "components-footer-global-color-link-default": 1,
        "components-footer-global-color-link-hover": 1,
        "components-footer-global-color-link-pressed": 1,
        "components-generic-card-color-bg": 1,
        "components-generic-card-color-content-bg": 1,
        "components-generic-card-color-title": 1,
        "components-generic-card-color-subtitle": 1,
        "components-generic-card-color-copy": 1,
        "components-global-search-color-omnibox-list-bg": 1,
        "components-global-search-color-omnibox-divider": 1,
        "components-global-search-color-omnibox-item-bg-default": 1,
        "components-global-search-color-omnibox-item-bg-hover": 1,
        "components-global-search-color-omnibox-item-text-default": 1,
        "components-global-search-color-omnibox-item-text-hover": 1,
        "components-global-search-color-omnibox-label-text-default": 1,
        "components-global-search-color-omnibox-category-text-default": 1,
        "components-generic-card-listing-color-bg": 1,
        "components-generic-card-listing-color-title": 1,
        "components-generic-card-listing-color-copy": 1,
        "components-header-color-bg": 1,
        "components-header-color-bottom-stroke": 1,
        "components-header-color-search-search-border-default": 1,
        "components-header-color-search-search-text": 1,
        "components-header-color-search-search-icon": 1,
        "components-header-color-search-search-bar": 1,
        "components-header-color-mobile-mobile-expanded-bg": 1,
        "components-header-color-mobile-pressed-bg": 1,
        "components-header-color-language-selector-language": 1,
        "components-header-color-language-selector-country": 1,
        "components-header-color-language-selector-country-hover-bg": 1,
        "components-header-color-language-selector-megamenu-language": 1,
        "components-header-color-language-selector-megamenu-country": 1,
        "components-header-color-language-selector-megamenu-country-hover": 1,
        "components-header-color-language-selector-bg": 1,
        "components-header-color-language-selector-stroke": 1,
        "components-header-color-mega-menu-bg": 1,
        "components-header-color-mega-menu-featured-bg": 1,
        "components-header-color-mega-menu-featured-title": 1,
        "components-header-color-mega-menu-category-title": 1,
        "components-header-color-mega-menu-bg-accent": 1,
        "components-header-color-icon-icon-default": 1,
        "components-header-color-icon-icon-hover": 1,
        "components-header-color-icon-icon-pressed": 1,
        "components-header-color-links-nav-main-link-default": 1,
        "components-header-color-links-nav-main-link-hover": 1,
        "components-header-color-links-nav-main-link-pressed": 1,
        "components-header-color-links-nav-main-link-active": 1,
        "components-header-color-links-nav-main-active-bar": 1,
        "components-header-color-links-nav-main-bg-color": 1,
        "components-header-color-links-nav-category-link-category-default": 1,
        "components-header-color-links-nav-category-link-category-hover": 1,
        "components-header-color-links-nav-category-link-category-pressed": 1,
        "components-header-color-links-nav-category-link-category-active": 1,
        "components-header-color-links-nav-menu-link-link-default": 1,
        "components-header-color-links-nav-menu-link-link-hover": 1,
        "components-header-color-links-nav-menu-link-link-pressed": 1,
        "components-header-color-links-nav-menu-link-link-active": 1,
        "components-header-color-links-nav-menu-link-bg": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-default": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-active": 1,
        "components-hero-homepage-color-bg": 1,
        "components-hero-homepage-color-container-bg": 1,
        "components-hero-homepage-color-eyebrow": 1,
        "components-hero-homepage-color-header": 1,
        "components-hero-homepage-color-sub-header": 1,
        "components-hero-homepage-color-body": 1,
        "components-hero-homepage-color-play-pause": 1,
        "components-hero-internal-color-default-title": 1,
        "components-hero-internal-color-default-body": 1,
        "components-hero-internal-color-default-bg": 1,
        "components-hero-internal-color-accent-bg": 1,
        "components-hero-internal-color-with-image-bg": 1,
        "components-hero-internal-color-default-bg-2": 1,
        "components-hero-internal-color-with-image-title": 1,
        "components-hero-internal-color-with-image-body": 1,
        "components-heading-page-title-color-default-bg": 1,
        "components-heading-page-title-color-default-title": 1,
        "components-heading-page-title-color-default-body": 1,
        "components-heading-page-title-color-light-bg": 1,
        "components-heading-page-title-color-light-title": 1,
        "components-heading-page-title-color-light-body": 1,
        "components-heading-page-title-color-color-bg": 1,
        "components-heading-page-title-color-color-title": 1,
        "components-heading-page-title-color-color-body": 1,
        "components-hyperlink-color-dark-default": 1,
        "components-hyperlink-color-dark-hover": 1,
        "components-hyperlink-color-dark-visited": 1,
        "components-hyperlink-color-light-default": 1,
        "components-hyperlink-color-light-hover": 1,
        "components-hyperlink-color-light-visited": 1,
        "components-icon-default": 1,
        "components-icon-focused": 1,
        "components-icon-card-color-bg": 1,
        "components-icon-card-color-icon-bg": 1,
        "components-icon-card-color-icon": 1,
        "components-icon-card-color-title": 1,
        "components-icon-card-color-subtitle": 1,
        "components-icon-card-color-description": 1,
        "components-icon-card-listing-color-bg": 1,
        "components-icon-card-listing-color-inner-bg": 1,
        "components-icon-card-listing-color-brand-bg": 1,
        "components-icon-card-listing-color-title": 1,
        "components-icon-card-listing-color-copy": 1,
        "components-icon-card-listing-color-inner-legal-text": 1,
        "components-jumplink-color-bg-default": 1,
        "components-jumplink-color-link-text-default": 1,
        "components-jumplink-color-link-text-hover": 1,
        "components-jumplink-color-link-border-hover": 1,
        "components-jumplink-color-link-text-active": 1,
        "components-jumplink-color-link-border-active": 1,
        "components-jumplink-color-title-text": 1,
        "components-jumplink-color-description-text": 1,
        "components-layout-bg-default": 1,
        "components-lightbox-color-overlay-bg": 0.02,
        "components-lightbox-color-title": 1,
        "components-lightbox-color-body": 1,
        "components-label-color-on-color-label-label-default": 1,
        "components-label-color-on-color-label-label-hover": 1,
        "components-label-color-on-color-label-label-pressed": 1,
        "components-label-color-on-color-background-bg-default": 0.02,
        "components-label-color-on-color-background-bg-hover": 1,
        "components-label-color-on-color-background-bg-pressed": 1,
        "components-label-color-on-color-stroke-hover": 1,
        "components-label-color-on-color-stroke-pressed": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-4": 1,
        "components-label-color-on-white-label-label-default": 1,
        "components-label-color-on-white-label-label-hover": 1,
        "components-label-color-on-white-label-label-pressed": 1,
        "components-label-color-on-white-background-bg-default": 0.02,
        "components-label-color-on-white-background-bg-hover": 1,
        "components-label-color-on-white-background-bg-pressed": 1,
        "components-label-color-on-white-stroke-hover": 1,
        "components-label-color-on-white-stroke-pressed": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-4": 1,
        "components-legal-disclaimer-color-default-bg": 1,
        "components-legal-disclaimer-color-default-title": 1,
        "components-legal-disclaimer-color-default-body": 1,
        "components-legal-disclaimer-color-light-bg": 1,
        "components-legal-disclaimer-color-light-title": 1,
        "components-legal-disclaimer-color-light-body": 1,
        "components-legal-disclaimer-color-brand-bg": 1,
        "components-legal-disclaimer-color-brand-title": 1,
        "components-legal-disclaimer-color-brand-body": 1,
        "components-media-video-thumbnail-overlay-bg": null,
        "components-media-caption-color-bg": 1,
        "components-media-caption-color-text": 1,
        "components-media-gallery-color-bg": 1,
        "components-media-gallery-color-title": 1,
        "components-media-gallery-color-body": 1,
        "components-pagination-on-white-accent-default": 1,
        "components-pagination-on-white-accent-scroll": 1,
        "components-pagination-on-white-disabled": 1,
        "components-pagination-on-white-bg": 0,
        "components-pagination-on-white-stroke": 1,
        "components-pagination-on-white-bg-active": 1,
        "components-pagination-on-white-bg-hover": 1,
        "components-pagination-on-white-text-default": 1,
        "components-pagination-on-white-text-hover": 1,
        "components-pagination-on-white-text-active": 1,
        "components-pagination-on-color-accent-default": 1,
        "components-pagination-on-color-accent-scroll": 1,
        "components-pagination-on-color-disabled": 1,
        "components-pagination-on-color-bg": 1,
        "components-pagination-on-color-stroke": 0.02,
        "components-pagination-on-color-bg-active": 1,
        "components-pagination-on-color-bg-hover": 1,
        "components-pagination-on-color-text-default": 1,
        "components-pagination-on-color-text-hover": 1,
        "components-pagination-on-color-text-active": 1,
        "components-pdf-link-card-color-bg": 1,
        "components-pdf-link-card-color-card-bg": 1,
        "components-pdf-link-card-color-card-title": 1,
        "components-pdf-link-card-color-card-subtitle": 1,
        "components-pdf-link-card-color-card-body": 1,
        "components-product-card-color-bg": 1,
        "components-product-card-color-content-bg": 1,
        "components-product-card-color-border": 0.02,
        "components-product-card-color-title": 1,
        "components-product-card-color-description": 1,
        "components-product-card-color-star-rating": 1,
        "components-product-card-color-badge-bg": 1,
        "components-product-card-color-badge-text": 1,
        "components-product-card-listing-color-bg": 1,
        "components-product-card-listing-color-title": 1,
        "components-product-card-listing-color-copy": 1,
        "components-product-card-listing-color-legal-text": 1,
        "components-product-information-color-bg": 1,
        "components-product-information-color-content-bg": 1,
        "components-product-information-color-headline": 1,
        "components-product-information-color-body": 1,
        "components-product-information-color-detail-text": 1,
        "components-product-information-color-divider": 1,
        "components-product-information-color-icon": 1,
        "components-product-information-color-play-icon": 1,
        "components-product-information-color-selected-stroke": 1,
        "components-product-results-list-with-search-color-bg": 1,
        "components-promo-color-default-bg": 1,
        "components-promo-color-default-image-bg": 1,
        "components-promo-color-default-content-bg": 1,
        "components-promo-color-default-title": 1,
        "components-promo-color-default-body": 1,
        "components-promo-color-default-category": 1,
        "components-promo-color-light-bg": 1,
        "components-promo-color-light-image-bg": 1,
        "components-promo-color-light-content-bg": 1,
        "components-promo-color-light-title": 1,
        "components-promo-color-light-body": 1,
        "components-promo-color-light-category": 1,
        "components-promo-color-brand-bg": 1,
        "components-promo-color-brand-image-bg": 1,
        "components-promo-color-brand-content-bg": 1,
        "components-promo-color-brand-title": 1,
        "components-promo-color-brand-body": 1,
        "components-promo-color-brand-category": 1,
        "components-pull-quote-color-bg": 1,
        "components-pull-quote-color-stroke": 1,
        "components-pull-quote-color-quote-bg": 1,
        "components-pull-quote-color-quote-title": 1,
        "components-pull-quote-color-quote-by-text": 1,
        "components-pull-quote-color-quote-detail": 1,
        "components-pull-quote-color-quote-highlight": 1,
        "components-promo-alternate-color-default-bg": 1,
        "components-promo-alternate-color-default-image-bg": 1,
        "components-promo-alternate-color-default-content-bg": 1,
        "components-promo-alternate-color-default-title": 1,
        "components-promo-alternate-color-default-body": 1,
        "components-promo-alternate-color-default-category": 1,
        "components-promo-alternate-color-light-bg": 1,
        "components-promo-alternate-color-light-image-bg": 1,
        "components-promo-alternate-color-light-content-bg": 1,
        "components-promo-alternate-color-light-title": 1,
        "components-promo-alternate-color-light-body": 1,
        "components-promo-alternate-color-light-category": 1,
        "components-promo-alternate-color-brand-bg": 1,
        "components-promo-alternate-color-brand-image-bg": 1,
        "components-promo-alternate-color-brand-content-bg": 1,
        "components-promo-alternate-color-brand-title": 1,
        "components-promo-alternate-color-brand-body": 1,
        "components-promo-alternate-color-brand-category": 1,
        "components-share-list-color-title": 1,
        "components-share-list-color-bg": 1,
        "components-share-list-color-link-default": 1,
        "components-share-list-color-link-hover": 1,
        "components-share-list-color-bg-link-hover": 1,
        "components-share-list-color-close-icon": 1,
        "components-share-list-color-facebook-icon": 1,
        "components-share-list-color-pinterest-icon": 1,
        "components-share-list-color-twitter-icon": 1,
        "components-share-list-color-basic-icon-default": 1,
        "components-share-list-color-icon-hover": 1,
        "components-share-list-color-border": 1,
        "components-site-search-bg": 1,
        "components-site-search-results_text": 1,
        "components-site-search-filter-filter-selected-bg": 1,
        "components-site-search-facet-group-label-bottom-line": 1,
        "components-site-search-facet-group-label-text": 1,
        "components-site-search-facet-group-label-bg": 1,
        "components-site-search-facet-bg": 1,
        "components-site-search-facet-stroke": 1,
        "components-site-search-refine-bg": 1,
        "components-site-search-refine-right-divider": 1,
        "components-site-search-refine-title": 1,
        "components-site-search-hero-search-bg": 1,
        "components-site-search-hero-search-accent-bg": 1,
        "components-site-search-hero-search-title": 1,
        "components-stats-stat-text": 1,
        "components-stats-stat-text-on-light": 1,
        "components-stats-supporting-text": 1,
        "components-stats-bg-default": 1,
        "components-stats-bg-inner": 1,
        "components-stats-bg-section-inner": 1,
        "components-stats-title": 1,
        "components-stats-copy": 1,
        "components-supporting-text-default": 1,
        "components-supporting-text-destructive": 1,
        "components-tab-bg": 1,
        "components-tab-border-default": 1,
        "components-tab-border-hover": 1,
        "components-tab-border-focused": 1,
        "components-tab-border-destructive": 1,
        "components-tab-label-text-default": 1,
        "components-tab-item-text-default": 1,
        "components-tab-item-bg-default": 1,
        "components-tab-item-bg-hover": 1,
        "components-tab-input-field-default": 1,
        "components-tab-input-field-focused": 1,
        "components-tab-input-label-focused": 1,
        "components-tab-input-field-destructive": 1,
        "components-tab-bg-2": 1,
        "components-tag-color-bg": 1,
        "components-tag-color-text": 1,
        "components-tag-color-stroke": 1,
        "components-tag-color-bg-hover": 1,
        "components-tag-color-text-hover": 1,
        "components-tag-color-stroke-hover": 1,
        "components-text-field-bg": 1,
        "components-text-field-bg-focused": 1,
        "components-text-field-border-default": 1,
        "components-text-field-border-hover": 1,
        "components-text-field-border-focused": 1,
        "components-text-field-border-destructive": 1,
        "components-text-field-input-field-default": 1,
        "components-text-field-input-field-focused": 1,
        "components-text-field-input-label-focused": 1,
        "components-text-field-input-field-destructive": 1,
        "components-text-field-icon-default": 1,
        "components-text-field-icon-destructive": 1,
        "components-text-field-supporting-text-default": 1,
        "components-text-field-supporting-text-destructive": 1,
        "components-tip-color-bg": 1,
        "components-tip-color-title": 1,
        "components-tip-color-body": 1,
        "components-tip-color-stroke": 1,
        "components-video-color-bg": 1,
        "themes-general-surface-color-light-bg": 1,
        "themes-general-surface-color-light-title": 1,
        "themes-general-surface-color-light-body": 1,
        "themes-general-surface-color-light-decor-line-break": 1,
        "themes-general-surface-color-light-rule-line": 1,
        "themes-general-surface-color-dark-bg": 1,
        "themes-general-surface-color-dark-title": 1,
        "themes-general-surface-color-dark-body": 1,
        "themes-general-surface-color-dark-decor-line-break": 1,
        "themes-general-card-color-dark-title": 1,
        "themes-general-card-color-dark-subtitle": 1,
        "themes-general-card-color-dark-body": 1,
        "themes-general-card-color-light-title": 1,
        "themes-general-card-color-light-subtitle": 1,
        "themes-general-card-color-light-body": 1,
        "themes-shadow-color": 1,
        "themes-shadow-share-list-color-fill": 0,
        "themes-shadow-header-menu-color-fill": 0,
        "themes-gradient-loader-gradient-fill-1": 1,
        "themes-gradient-loader-gradient-fill-2": 0.03,
        "themes-color-background-white": 1,
        "themes-color-background-light": 1,
        "themes-color-background-dark": 1,
        "themes-color-background-black": 1,
        "themes-color-background-brand1": 1,
        "themes-color-background-brand2": 1,
        "themes-color-background-accent1": 1,
        "themes-color-background-accent2": 1,
        "themes-color-background-body": 1,
        "themes-color-stroke-white": 1,
        "themes-color-stroke-light": 1,
        "themes-color-stroke-dark": 1,
        "themes-color-stroke-black": 1,
        "themes-color-stroke-brand1": 1,
        "themes-color-stroke-brand2": 1,
        "themes-color-stroke-accent1": 1,
        "themes-color-stroke-accent2": 1,
        "themes-color-text-white": 1,
        "themes-color-text-light": 1,
        "themes-color-text-dark": 1,
        "themes-color-text-black": 1,
        "themes-color-text-brand1": 1,
        "themes-color-text-brand2": 1,
        "themes-color-text-accent1": 1,
        "themes-color-text-accent2": 1,
        "colors-grayscale-100": 1,
        "colors-grayscale-200": 1,
        "colors-grayscale-300": 1,
        "colors-grayscale-400": 1,
        "colors-grayscale-500": 1,
        "colors-grayscale-600": 1,
        "colors-grayscale-700": 1,
        "colors-brand-1-100": 1,
        "colors-brand-1-200": 1,
        "colors-brand-1-300": 1,
        "colors-brand-1-400": 1,
        "colors-brand-1-500": 1,
        "colors-brand-1-600": 1,
        "colors-brand-1-700": 1,
        "colors-brand-2-100": 1,
        "colors-brand-2-200": 1,
        "colors-brand-2-300": 1,
        "colors-brand-2-400": 1,
        "colors-brand-2-500": 1,
        "colors-brand-2-600": 1,
        "colors-brand-2-700": 1,
        "colors-accent-1-100": 1,
        "colors-accent-1-200": 1,
        "colors-accent-1-300": 1,
        "colors-accent-1-400": 1,
        "colors-accent-1-500": 1,
        "colors-accent-1-600": 1,
        "colors-accent-1-700": 1,
        "colors-accent-2-100": 1,
        "colors-accent-2-200": 1,
        "colors-accent-2-300": 1,
        "colors-accent-2-400": 1,
        "colors-accent-2-500": 1,
        "colors-accent-2-600": 1,
        "colors-accent-2-700": 1,
        "colors-accent-3-100": 1,
        "colors-accent-3-200": 1,
        "colors-accent-3-300": 1,
        "colors-accent-3-400": 1,
        "colors-accent-3-500": 1,
        "colors-accent-3-600": 1,
        "colors-accent-3-700": 1,
        "colors-accent-4-400_storage": 1,
        "colors-accessible-accent-1-100": 1,
        "colors-accessible-accent-1-200": 1,
        "colors-accessible-accent-1-300": 1,
        "colors-accessible-accent-1-400": 1,
        "colors-accessible-accent-1-500": 1,
        "colors-accessible-accent-1-600": 1,
        "colors-accessible-accent-1-700": 1,
        "colors-accessible-accent-2-100": 1,
        "colors-accessible-accent-2-200": 1,
        "colors-accessible-accent-2-300": 1,
        "colors-accessible-accent-2-400": 1,
        "colors-accessible-accent-2-500": 1,
        "colors-accessible-accent-2-600": 1,
        "colors-accessible-accent-2-700": 1,
        "colors-accessible-brand-2-100": 1,
        "colors-accessible-brand-2-200": 1,
        "colors-accessible-brand-2-300": 1,
        "colors-accessible-brand-2-400": 1,
        "colors-accessible-brand-2-500": 1,
        "colors-accessible-brand-2-600": 1,
        "colors-accessible-brand-2-700": 1
      }
    },
    "Pledge": {
      "colors": {
        "components-accessibility-bar-color-bg": "#1e395e",
        "components-accessibility-bar-color-text": "#ffffff",
        "components-accordion-color-bg": "#eff7fd",
        "components-accordion-color-title": "#182e4d",
        "components-accordion-color-body": "#27282b",
        "components-accordion-color-section-section-bg": "#ffffff",
        "components-accordion-color-section-section-title": "#182e4d",
        "components-accordion-color-section-section-body": "#27282b",
        "components-accordion-color-item-item-bg": "#ffffff",
        "components-accordion-color-item-divider": "#f6f6f6",
        "components-accordion-color-item-icon-icon-default": "#1e395e",
        "components-accordion-color-item-icon-icon-hover": "#ffffff",
        "components-accordion-color-item-icon-icon-pressed": "#ffffff",
        "components-accordion-color-item-icon-icon-active": "#ffffff",
        "components-accordion-color-item-title-item-default": "#182e4d",
        "components-accordion-color-item-title-item-hover": "#ffffff",
        "components-accordion-color-item-title-item-pressed": "#ffffff",
        "components-accordion-color-item-title-item-active": "#ffffff",
        "components-accordion-color-content-bg": "#ffffff",
        "components-accordion-color-content-title": "#182e4d",
        "components-accordion-color-content-body": "#27282b",
        "components-article-card-color-bg": "#ffffff",
        "components-article-card-color-border": "rgba(246, 246, 246, 0.00)",
        "components-article-card-color-content-bg": "#ffffff",
        "components-article-card-color-title": "#182e4d",
        "components-article-card-color-subtitle": "#27282b",
        "components-article-card-color-copy": "#27282b",
        "components-article-card-color-description": "#27282b",
        "components-article-card-listing-color-default-bg": "#ffffff",
        "components-article-card-listing-color-default-title": "#182e4d",
        "components-article-card-listing-color-default-copy": "#182e4d",
        "components-article-hero-color-content-bg": "#eff7fd",
        "components-article-hero-color-bg": "#eff7fd",
        "components-article-hero-color-headline": "#182e4d",
        "components-article-hero-color-body": "#182e4d",
        "components-article-hero-color-detail-text": "#27282b",
        "components-article-hero-color-divider": "#a2a5ab",
        "components-article-hero-color-icon": "#1e395e",
        "components-article-hero-color-play-icon": "#ffffff",
        "components-article-hero-color-selected-stroke": "#f7d417",
        "components-banner-color-bg-default": "#0e1a2b",
        "components-banner-color-icon-default": "#ffffff",
        "components-banner-color-title-default": "#ffffff",
        "components-banner-color-body-default": "#ffffff",
        "components-banner-color-icon-close-default": "#ffffff",
        "components-banner-color-bg-alert": "#4da9e7",
        "components-banner-color-icon-alert": "#000000",
        "components-banner-color-title-alert": "#182e4d",
        "components-banner-color-body-alert": "#182e4d",
        "components-banner-color-icon-close-alert": "#191b1c",
        "components-breadcrumb-color-bg": "#eff7fd",
        "components-breadcrumb-color-breadcrumb-link-default": "#135c8c",
        "components-breadcrumb-color-breadcrumb-link-hover": "#1e395e",
        "components-breadcrumb-color-breadcrumb-link-active": "#142740",
        "components-breadcrumb-color-icon-divider": "#1d8ad3",
        "components-bug-finder-prompt": "#ffffff",
        "components-button-color-filled-brand-default-bg": "#1e395e",
        "components-button-color-filled-brand-default-text": "#ffffff",
        "components-button-color-filled-brand-default-stroke": "#1e395e",
        "components-button-color-filled-brand-default-icon": "#ffffff",
        "components-button-color-filled-brand-hover-bg": "#142740",
        "components-button-color-filled-brand-hover-text": "#ffffff",
        "components-button-color-filled-brand-hover-stroke": "#f7d417",
        "components-button-color-filled-brand-hover-icon": "#ffffff",
        "components-button-color-filled-brand-pressed-bg": "#142740",
        "components-button-color-filled-brand-pressed-text": "#ffffff",
        "components-button-color-filled-brand-pressed-stroke": "#f7d417",
        "components-button-color-filled-brand-pressed-icon": "#ffffff",
        "components-button-color-filled-brand-disabled-bg": "rgba(25, 27, 28, 0.38)",
        "components-button-color-filled-brand-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-disabled-stroke": "rgba(39, 40, 43, 0.38)",
        "components-button-color-filled-brand-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-focus-bg": "#1e395e",
        "components-button-color-filled-brand-focus-text": "#ffffff",
        "components-button-color-filled-brand-focus-stroke": "#1e395e",
        "components-button-color-filled-brand-focus-icon": "#ffffff",
        "components-button-color-filled-brand-focus-outline": "#286efa",
        "components-button-color-filled-tonal-default-bg": "#bfd1eb",
        "components-button-color-filled-tonal-default-text": "#182e4d",
        "components-button-color-filled-tonal-default-stroke": "#bfd1eb",
        "components-button-color-filled-tonal-default-icon": "#182e4d",
        "components-button-color-filled-tonal-hover-bg": "#142740",
        "components-button-color-filled-tonal-hover-text": "#ffffff",
        "components-button-color-filled-tonal-hover-stroke": "#142740",
        "components-button-color-filled-tonal-hover-icon": "#ffffff",
        "components-button-color-filled-tonal-pressed-bg": "#142740",
        "components-button-color-filled-tonal-pressed-text": "#ffffff",
        "components-button-color-filled-tonal-pressed-stroke": "#142740",
        "components-button-color-filled-tonal-pressed-icon": "#ffffff",
        "components-button-color-filled-tonal-disabled-bg": "rgba(191, 209, 235, 0.40)",
        "components-button-color-filled-tonal-disabled-text": "#182e4d",
        "components-button-color-filled-tonal-disabled-stroke": "rgba(191, 209, 235, 0.40)",
        "components-button-color-filled-tonal-disabled-icon": "#182e4d",
        "components-button-color-filled-tonal-focus-bg": "#bfd1eb",
        "components-button-color-filled-tonal-focus-text": "#182e4d",
        "components-button-color-filled-tonal-focus-stroke": "#bfd1eb",
        "components-button-color-filled-tonal-focus-icon": "#182e4d",
        "components-button-color-filled-tonal-focus-outline": "#1c4cae",
        "components-button-color-filled-white-default-bg": "#ffffff",
        "components-button-color-filled-white-default-text": "#191b1c",
        "components-button-color-filled-white-default-stroke": "#ffffff",
        "components-button-color-filled-white-default-icon": "#f7d417",
        "components-button-color-filled-white-hover-bg": "#fcfcfd",
        "components-button-color-filled-white-hover-text": "#191b1c",
        "components-button-color-filled-white-hover-stroke": "#fcfcfd",
        "components-button-color-filled-white-hover-icon": "#f7d417",
        "components-button-color-filled-white-pressed-bg": "#f6f6f6",
        "components-button-color-filled-white-pressed-text": "#191b1c",
        "components-button-color-filled-white-pressed-stroke": "#f6f6f6",
        "components-button-color-filled-white-pressed-icon": "#f7d417",
        "components-button-color-filled-white-disabled-bg": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-text": "rgba(25, 27, 28, 0.38)",
        "components-button-color-filled-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-icon": "rgba(195, 37, 25, 0.38)",
        "components-button-color-filled-white-focus-bg": "#ffffff",
        "components-button-color-filled-white-focus-text": "#191b1c",
        "components-button-color-filled-white-focus-stroke": "#ffffff",
        "components-button-color-filled-white-focus-icon": "#f7d417",
        "components-button-color-filled-white-focus-outline": "#1c4cae",
        "components-button-color-outline-brand-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-brand-default-text": "#182e4d",
        "components-button-color-outline-brand-default-stroke": "#142740",
        "components-button-color-outline-brand-default-icon": "#182e4d",
        "components-button-color-outline-brand-hover-bg": "#142740",
        "components-button-color-outline-brand-hover-text": "#ffffff",
        "components-button-color-outline-brand-hover-stroke": "#142740",
        "components-button-color-outline-brand-hover-icon": "#ffffff",
        "components-button-color-outline-brand-pressed-bg": "#142740",
        "components-button-color-outline-brand-pressed-text": "#ffffff",
        "components-button-color-outline-brand-pressed-stroke": "#142740",
        "components-button-color-outline-brand-pressed-icon": "#ffffff",
        "components-button-color-outline-brand-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-brand-disabled-text": "rgba(20, 39, 64, 0.40)",
        "components-button-color-outline-brand-disabled-stroke": "rgba(20, 39, 64, 0.38)",
        "components-button-color-outline-brand-disabled-icon": "rgba(25, 27, 28, 0.38)",
        "components-button-color-outline-brand-focus-bg": "#ffffff",
        "components-button-color-outline-brand-focus-text": "#182e4d",
        "components-button-color-outline-brand-focus-stroke": "#142740",
        "components-button-color-outline-brand-focus-icon": "#182e4d",
        "components-button-color-outline-brand-focus-outline": "#286efa",
        "components-button-color-outline-tonal-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-tonal-default-text": "#142740",
        "components-button-color-outline-tonal-default-stroke": "#3f76c2",
        "components-button-color-outline-tonal-default-icon": "#142740",
        "components-button-color-outline-tonal-hover-bg": "#142740",
        "components-button-color-outline-tonal-hover-text": "#ffffff",
        "components-button-color-outline-tonal-hover-stroke": "#142740",
        "components-button-color-outline-tonal-hover-icon": "#ffffff",
        "components-button-color-outline-tonal-pressed-bg": "#142740",
        "components-button-color-outline-tonal-pressed-text": "#ffffff",
        "components-button-color-outline-tonal-pressed-stroke": "#142740",
        "components-button-color-outline-tonal-pressed-icon": "#ffffff",
        "components-button-color-outline-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-tonal-disabled-text": "rgba(65, 95, 45, 0.38)",
        "components-button-color-outline-tonal-disabled-stroke": "rgba(88, 129, 61, 0.38)",
        "components-button-color-outline-tonal-disabled-icon": "rgba(65, 95, 45, 0.38)",
        "components-button-color-outline-tonal-focus-bg": "rgba(255, 255, 255, 0.01)",
        "components-button-color-outline-tonal-focus-text": "#142740",
        "components-button-color-outline-tonal-focus-stroke": "#3f76c2",
        "components-button-color-outline-tonal-focus-icon": "#142740",
        "components-button-color-outline-tonal-focus-outline": "#286efa",
        "components-button-color-outline-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-default-text": "#ffffff",
        "components-button-color-outline-white-default-stroke": "#f7d417",
        "components-button-color-outline-white-default-icon": "#ffffff",
        "components-button-color-outline-white-hover-bg": "#ffffff",
        "components-button-color-outline-white-hover-text": "#191b1c",
        "components-button-color-outline-white-hover-stroke": "#ffffff",
        "components-button-color-outline-white-hover-icon": "#f7d417",
        "components-button-color-outline-white-pressed-bg": "#ffffff",
        "components-button-color-outline-white-pressed-text": "#191b1c",
        "components-button-color-outline-white-pressed-stroke": "#ffffff",
        "components-button-color-outline-white-pressed-icon": "#f7d417",
        "components-button-color-outline-white-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-focus-bg": "rgba(255, 255, 255, 0.01)",
        "components-button-color-outline-white-focus-text": "#ffffff",
        "components-button-color-outline-white-focus-stroke": "#ffffff",
        "components-button-color-outline-white-focus-icon": "#ffffff",
        "components-button-color-outline-white-focus-outline": "#286efa",
        "components-button-color-text-brand-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-default-text": "#182e4d",
        "components-button-color-text-brand-default-stroke": "#142740",
        "components-button-color-text-brand-default-icon": "#182e4d",
        "components-button-color-text-brand-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-hover-bg-icon-only": "#f6f6f6",
        "components-button-color-text-brand-hover-text": "#1e395e",
        "components-button-color-text-brand-hover-stroke": "#1e395e",
        "components-button-color-text-brand-hover-icon": "#1e395e",
        "components-button-color-text-brand-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-pressed-bg-icon-only": "#a2a5ab",
        "components-button-color-text-brand-pressed-text": "#1e395e",
        "components-button-color-text-brand-pressed-stroke": "#1e395e",
        "components-button-color-text-brand-pressed-icon": "#1e395e",
        "components-button-color-text-brand-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-disabled-text": "rgba(20, 39, 64, 0.38)",
        "components-button-color-text-brand-disabled-stroke": "rgba(20, 39, 64, 0.38)",
        "components-button-color-text-brand-disabled-icon": "rgba(20, 39, 64, 0.38)",
        "components-button-color-text-brand-focus-bg": "#d5e3ff",
        "components-button-color-text-brand-focus-text": "#182e4d",
        "components-button-color-text-brand-focus-stroke": "#27282b",
        "components-button-color-text-brand-focus-icon": "#1c4cae",
        "components-button-color-text-brand-focus-outline": "#1c4cae",
        "components-button-color-text-tonal-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-default-text": "#142740",
        "components-button-color-text-tonal-default-stroke": "#182e4d",
        "components-button-color-text-tonal-default-icon": "#142740",
        "components-button-color-text-tonal-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-hover-bg-icon-only": "#fcfcfd",
        "components-button-color-text-tonal-hover-text": "#0e1a2b",
        "components-button-color-text-tonal-hover-stroke": "#182e4d",
        "components-button-color-text-tonal-hover-icon": "#0e1a2b",
        "components-button-color-text-tonal-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-pressed-bg-icon-only": "#f6f6f6",
        "components-button-color-text-tonal-pressed-text": "#0e1a2b",
        "components-button-color-text-tonal-pressed-stroke": "#182e4d",
        "components-button-color-text-tonal-pressed-icon": "#0e1a2b",
        "components-button-color-text-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-disabled-text": "rgba(65, 95, 45, 0.38)",
        "components-button-color-text-tonal-disabled-stroke": "rgba(65, 95, 45, 0.38)",
        "components-button-color-text-tonal-disabled-icon": "rgba(65, 95, 45, 0.38)",
        "components-button-color-text-tonal-focus-bg": "#d5e3ff",
        "components-button-color-text-tonal-focus-text": "#1c4cae",
        "components-button-color-text-tonal-focus-stroke": "#182e4d",
        "components-button-color-text-tonal-focus-icon": "#1c4cae",
        "components-button-color-text-tonal-focus-outline": "#1c4cae",
        "components-button-color-text-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-default-text": "#ffffff",
        "components-button-color-text-white-default-stroke": "#ffffff",
        "components-button-color-text-white-default-icon": "#ffffff",
        "components-button-color-text-white-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-hover-bg-icon-only": "#4e5056",
        "components-button-color-text-white-hover-text": "#ffffff",
        "components-button-color-text-white-hover-stroke": "#ffffff",
        "components-button-color-text-white-hover-icon": "#ffffff",
        "components-button-color-text-white-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-pressed-bg-icon-only": "#4e5056",
        "components-button-color-text-white-pressed-text": "#ffffff",
        "components-button-color-text-white-pressed-stroke": "#ffffff",
        "components-button-color-text-white-pressed-icon": "#ffffff",
        "components-button-color-text-white-disabled-bg": "rgba(115, 119, 128, 0.00)",
        "components-button-color-text-white-disabled-text": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-stroke": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-icon": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-focus-bg": "#d5e3ff",
        "components-button-color-text-white-focus-text": "#ffffff",
        "components-button-color-text-white-focus-stroke": "#ffffff",
        "components-button-color-text-white-focus-icon": "#1c4cae",
        "components-button-color-text-white-focus-outline": "#1c4cae",
        "components-button-group-color-border": "#f6f6f6",
        "components-button-group-color-button-bg-default": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-button-fg-default": "#191b1c",
        "components-button-group-color-button-bg-hover": "rgba(25, 27, 28, 0.38)",
        "components-button-group-color-button-fg-hover": "#191b1c",
        "components-button-group-color-button-bg-active": "#1e395e",
        "components-button-group-color-button-fg-active": "#ffffff",
        "components-button-group-color-overlay-left-gradient-fill-1": "#ffffff",
        "components-button-group-color-overlay-left-gradient-fill-2": "rgba(255, 255, 255, 0.50)",
        "components-button-group-color-stroke-default-gradient-fill-1": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-2": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-3": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-4": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-1": "rgba(25, 27, 28, 0.38)",
        "components-button-group-color-stroke-hover-gradient-fill-2": "rgba(25, 27, 28, 0.38)",
        "components-button-group-color-stroke-hover-gradient-fill-3": "rgba(25, 27, 28, 0.38)",
        "components-button-group-color-stroke-hover-gradient-fill-4": "rgba(25, 27, 28, 0.38)",
        "components-button-group-color-stroke-active-gradient-fill-1": "#1e395e",
        "components-button-group-color-stroke-active-gradient-fill-2": "#1e395e",
        "components-button-group-color-stroke-active-gradient-fill-3": "#1e395e",
        "components-button-group-color-stroke-active-gradient-fill-4": "#1e395e",
        "components-category-card-color-bg": "#ffffff",
        "components-category-card-color-content-bg": "#ffffff",
        "components-category-card-color-category-bg": "#ffffff",
        "components-category-card-color-category-bg-2": "#ffffff",
        "components-category-card-color-category-bg-3": "#ffffff",
        "components-category-card-color-category-bg-4": "#ffffff",
        "components-category-card-color-category-bg-5": "#ffffff",
        "components-category-card-color-category-bg-6": "#ffffff",
        "components-category-card-color-title": "#182e4d",
        "components-category-card-color-copy": "#27282b",
        "components-category-card-color-stroke": "#eff7fd",
        "components-category-card-color-stroke-hover": "#142740",
        "components-category-card-listing-color-bg": "#eff7fd",
        "components-category-card-listing-color-title": "#182e4d",
        "components-category-card-listing-color-copy": "#182e4d",
        "components-card-search-result-color-bg": "#ffffff",
        "components-card-search-result-color-label": "#27282b",
        "components-card-search-result-color-title": "#182e4d",
        "components-card-search-result-color-body": "#27282b",
        "components-card-search-result-color-divider-line": "#d0e8f9",
        "components-contact-us-promo-color-light-bg": "#eff7fd",
        "components-contact-us-promo-color-light-title": "#182e4d",
        "components-contact-us-promo-color-light-body": "#182e4d",
        "components-contact-us-promo-color-default-bg": "#ffffff",
        "components-contact-us-promo-color-default-title": "#182e4d",
        "components-contact-us-promo-color-default-body": "#182e4d",
        "components-contact-us-promo-color-brand-bg": "#f7d417",
        "components-contact-us-promo-color-brand-title": "#182e4d",
        "components-contact-us-promo-color-brand-body": "#27282b",
        "components-content-list-color-bg": "#ffffff",
        "components-content-list-color-title": "#182e4d",
        "components-content-list-color-body": "#27282b",
        "components-content-list-color-icon": "#1e395e",
        "components-cookies-colors-bg": "#eff7fd",
        "components-cookies-colors-title": "#182e4d",
        "components-cookies-colors-body": "#27282b",
        "components-cookies-colors-icon": "#1e395e",
        "components-dropdown-bg": "#ffffff",
        "components-dropdown-border-default": "#72757e",
        "components-dropdown-border-hover": "#1e395e",
        "components-dropdown-border-focused": "#191b1c",
        "components-dropdown-border-destructive": "#b32b30",
        "components-dropdown-label-text-default": "#191b1c",
        "components-dropdown-item-text-default": "#191b1c",
        "components-dropdown-item-bg-default": "#ffffff",
        "components-dropdown-item-bg-hover": "#f2f6fb",
        "components-dropdown-input-field-default": "#72757e",
        "components-dropdown-input-field-focused": "#191b1c",
        "components-dropdown-input-label-focused": "#182e4d",
        "components-dropdown-input-field-destructive": "#b32b30",
        "components-external-link-card-bg": "#eff7fd",
        "components-external-link-card-card-bg": "#ffffff",
        "components-external-link-card-card-title": "#27282b",
        "components-external-link-card-card-subtitle": "#27282b",
        "components-external-link-card-card-eyebrow": "#4e5056",
        "components-fab-color-border-default": "#ffffff",
        "components-footer-brand-color-bg": "#182e4d",
        "components-footer-brand-color-logo": "#ffffff",
        "components-footer-brand-color-title-text": "#ffffff",
        "components-footer-brand-color-description-text": "#ffffff",
        "components-footer-brand-color-footer-menu-expanded": "#182e4d",
        "components-footer-brand-color-link-default": "#ffffff",
        "components-footer-brand-color-link-hover": "#f6f6f6",
        "components-footer-brand-color-link-pressed": "#f6f6f6",
        "components-footer-brand-color-category-link-default": "#ffffff",
        "components-footer-brand-color-category-link-hover": "#f6f6f6",
        "components-footer-brand-color-category-link-pressed": "#f6f6f6",
        "components-footer-brand-color-subcategory-link-default": "#ffffff",
        "components-footer-brand-color-subcategory-link-hover": "#f6f6f6",
        "components-footer-brand-color-subcategory-link-pressed": "#f6f6f6",
        "components-footer-brand-color-icon-icon-default": "#ffffff",
        "components-footer-brand-color-icon-icon-pressed": "#f6f6f6",
        "components-footer-brand-color-social-icon-default": "#ffffff",
        "components-footer-brand-color-social-icon-hover": "#f6f6f6",
        "components-footer-brand-color-social-icon-pressed": "#f6f6f6",
        "components-footer-brand-spacing-category-link-hover": "#000000",
        "components-footer-global-color-bg": "#ffffff",
        "components-footer-global-color-title-text": "#000000",
        "components-footer-global-color-disclaimer-text": "#000000",
        "components-footer-global-color-link-default": "#000000",
        "components-footer-global-color-link-hover": "#000000",
        "components-footer-global-color-link-pressed": "#000000",
        "components-generic-card-color-bg": "#ffffff",
        "components-generic-card-color-content-bg": "#ffffff",
        "components-generic-card-color-title": "#182e4d",
        "components-generic-card-color-subtitle": "#27282b",
        "components-generic-card-color-copy": "#27282b",
        "components-global-search-color-omnibox-list-bg": "#ffffff",
        "components-global-search-color-omnibox-divider": "#a2a5ab",
        "components-global-search-color-omnibox-item-bg-default": "#ffffff",
        "components-global-search-color-omnibox-item-bg-hover": "#fffdf5",
        "components-global-search-color-omnibox-item-text-default": "#1e395e",
        "components-global-search-color-omnibox-item-text-hover": "#142740",
        "components-global-search-color-omnibox-label-text-default": "#4e5056",
        "components-global-search-color-omnibox-category-text-default": "#4e5056",
        "components-generic-card-listing-color-bg": "#eff7fd",
        "components-generic-card-listing-color-title": "#182e4d",
        "components-generic-card-listing-color-copy": "#182e4d",
        "components-header-color-bg": "#1e395e",
        "components-header-color-bottom-stroke": "#182e4d",
        "components-header-color-search-search-border-default": "#142740",
        "components-header-color-search-search-text": "#182e4d",
        "components-header-color-search-search-icon": "#1e395e",
        "components-header-color-search-search-bar": "#142740",
        "components-header-color-mobile-mobile-expanded-bg": "#eff7fd",
        "components-header-color-mobile-pressed-bg": "#142740",
        "components-header-color-language-selector-language": "#f6f6f6",
        "components-header-color-language-selector-country": "#ffffff",
        "components-header-color-language-selector-country-hover-bg": "rgba(255, 255, 255, 0.10)",
        "components-header-color-language-selector-megamenu-language": "#182e4d",
        "components-header-color-language-selector-megamenu-country": "#182e4d",
        "components-header-color-language-selector-megamenu-country-hover": "#142740",
        "components-header-color-language-selector-bg": "#ffffff",
        "components-header-color-language-selector-stroke": "#72757e",
        "components-header-color-mega-menu-bg": "#eff7fd",
        "components-header-color-mega-menu-featured-bg": "#182e4d",
        "components-header-color-mega-menu-featured-title": "#ffffff",
        "components-header-color-mega-menu-category-title": "#182e4d",
        "components-header-color-mega-menu-bg-accent": "#eff7fd",
        "components-header-color-icon-icon-default": "#f7d417",
        "components-header-color-icon-icon-hover": "#f7d417",
        "components-header-color-icon-icon-pressed": "#f7d417",
        "components-header-color-links-nav-main-link-default": "#ffffff",
        "components-header-color-links-nav-main-link-hover": "#f7d417",
        "components-header-color-links-nav-main-link-pressed": "#f7d417",
        "components-header-color-links-nav-main-link-active": "#f2f6fb",
        "components-header-color-links-nav-main-active-bar": "#f7d417",
        "components-header-color-links-nav-main-bg-color": "#ffffff",
        "components-header-color-links-nav-category-link-category-default": "#27282b",
        "components-header-color-links-nav-category-link-category-hover": "#1e395e",
        "components-header-color-links-nav-category-link-category-pressed": "#142740",
        "components-header-color-links-nav-category-link-category-active": "#0e1a2b",
        "components-header-color-links-nav-menu-link-link-default": "#182e4d",
        "components-header-color-links-nav-menu-link-link-hover": "#1e395e",
        "components-header-color-links-nav-menu-link-link-pressed": "#142740",
        "components-header-color-links-nav-menu-link-link-active": "#0e1a2b",
        "components-header-color-links-nav-menu-link-bg": "#ffffff",
        "components-header-color-links-nav-subcategory-link-subcategory-default": "#182e4d",
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": "#142740",
        "components-header-color-links-nav-subcategory-link-subcategory-active": "#0e1a2b",
        "components-hero-homepage-color-bg": "#ffffff",
        "components-hero-homepage-color-container-bg": "#f7d417",
        "components-hero-homepage-color-eyebrow": "#72757e",
        "components-hero-homepage-color-header": "#182e4d",
        "components-hero-homepage-color-sub-header": "#182e4d",
        "components-hero-homepage-color-body": "#27282b",
        "components-hero-homepage-color-play-pause": "#ffffff",
        "components-hero-internal-color-default-title": "#ffffff",
        "components-hero-internal-color-default-body": "#fcfcfd",
        "components-hero-internal-color-default-bg": "#1e395e",
        "components-hero-internal-color-accent-bg": "#142740",
        "components-hero-internal-color-with-image-bg": "#1e395e",
        "components-hero-internal-color-default-bg-2": "#191b1c",
        "components-hero-internal-color-with-image-title": "#ffffff",
        "components-hero-internal-color-with-image-body": "#fcfcfd",
        "components-heading-page-title-color-default-bg": "#1e395e",
        "components-heading-page-title-color-default-title": "#ffffff",
        "components-heading-page-title-color-default-body": "#fcfcfd",
        "components-heading-page-title-color-light-bg": "#ffffff",
        "components-heading-page-title-color-light-title": "#182e4d",
        "components-heading-page-title-color-light-body": "#182e4d",
        "components-heading-page-title-color-color-bg": "#eff7fd",
        "components-heading-page-title-color-color-title": "#182e4d",
        "components-heading-page-title-color-color-body": "#182e4d",
        "components-hyperlink-color-dark-default": "#142740",
        "components-hyperlink-color-dark-hover": "#1e395e",
        "components-hyperlink-color-dark-visited": "#0e1a2b",
        "components-hyperlink-color-light-default": "#f7d417",
        "components-hyperlink-color-light-hover": "#d7b808",
        "components-hyperlink-color-light-visited": "#5b4e03",
        "components-icon-default": "#72757e",
        "components-icon-focused": "#72757e",
        "components-icon-card-color-bg": "#ffffff",
        "components-icon-card-color-icon-bg": "#1e395e",
        "components-icon-card-color-icon": "#ffffff",
        "components-icon-card-color-title": "#182e4d",
        "components-icon-card-color-subtitle": "#27282b",
        "components-icon-card-color-description": "#27282b",
        "components-icon-card-listing-color-bg": "#ffffff",
        "components-icon-card-listing-color-inner-bg": "#eff7fd",
        "components-icon-card-listing-color-brand-bg": "#4da9e7",
        "components-icon-card-listing-color-title": "#182e4d",
        "components-icon-card-listing-color-copy": "#182e4d",
        "components-icon-card-listing-color-inner-legal-text": "#ffffff",
        "components-jumplink-color-bg-default": "#ffffff",
        "components-jumplink-color-link-text-default": "#4e5056",
        "components-jumplink-color-link-text-hover": "#142740",
        "components-jumplink-color-link-border-hover": "#142740",
        "components-jumplink-color-link-text-active": "#182e4d",
        "components-jumplink-color-link-border-active": "#142740",
        "components-jumplink-color-title-text": "#182e4d",
        "components-jumplink-color-description-text": "#27282b",
        "components-layout-bg-default": "#ffffff",
        "components-lightbox-color-overlay-bg": "rgba(255, 255, 255, 0.95)",
        "components-lightbox-color-title": "#182e4d",
        "components-lightbox-color-body": "#182e4d",
        "components-label-color-on-color-label-label-default": "#ffffff",
        "components-label-color-on-color-label-label-hover": "#182e4d",
        "components-label-color-on-color-label-label-pressed": "#182e4d",
        "components-label-color-on-color-background-bg-default": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-color-background-bg-hover": "#ffffff",
        "components-label-color-on-color-background-bg-pressed": "#f6f6f6",
        "components-label-color-on-color-stroke-hover": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-color-stroke-pressed": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-color-stroke-default-gradient-fill-1": "#f6f6f6",
        "components-label-color-on-color-stroke-default-gradient-fill-2": "#f6f6f6",
        "components-label-color-on-color-stroke-default-gradient-fill-3": "#f6f6f6",
        "components-label-color-on-color-stroke-default-gradient-fill-4": "#f6f6f6",
        "components-label-color-on-white-label-label-default": "#182e4d",
        "components-label-color-on-white-label-label-hover": "#ffffff",
        "components-label-color-on-white-label-label-pressed": "#ffffff",
        "components-label-color-on-white-background-bg-default": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-white-background-bg-hover": "#1e395e",
        "components-label-color-on-white-background-bg-pressed": "#142740",
        "components-label-color-on-white-stroke-hover": "#1e395e",
        "components-label-color-on-white-stroke-pressed": "#142740",
        "components-label-color-on-white-stroke-default-gradient-fill-1": "#1e395e",
        "components-label-color-on-white-stroke-default-gradient-fill-2": "#1e395e",
        "components-label-color-on-white-stroke-default-gradient-fill-3": "#1e395e",
        "components-label-color-on-white-stroke-default-gradient-fill-4": "#1e395e",
        "components-legal-disclaimer-color-default-bg": "#eff7fd",
        "components-legal-disclaimer-color-default-title": "#182e4d",
        "components-legal-disclaimer-color-default-body": "#182e4d",
        "components-legal-disclaimer-color-light-bg": "#eff7fd",
        "components-legal-disclaimer-color-light-title": "#182e4d",
        "components-legal-disclaimer-color-light-body": "#182e4d",
        "components-legal-disclaimer-color-brand-bg": "#191b1c",
        "components-legal-disclaimer-color-brand-title": "#ffffff",
        "components-legal-disclaimer-color-brand-body": "#fcfcfd",
        "components-media-video-thumbnail-overlay-bg": "rgba(0, 61, 165, 0.70)",
        "components-media-caption-color-bg": "#fcfcfd",
        "components-media-caption-color-text": "#27282b",
        "components-media-gallery-color-bg": "#eff7fd",
        "components-media-gallery-color-title": "#182e4d",
        "components-media-gallery-color-body": "#182e4d",
        "components-pagination-on-white-accent-default": "#27282b",
        "components-pagination-on-white-accent-scroll": "#182e4d",
        "components-pagination-on-white-disabled": "#a2a5ab",
        "components-pagination-on-white-bg": "#bfd1eb",
        "components-pagination-on-white-stroke": "#f6f6f6",
        "components-pagination-on-white-bg-active": "#1e395e",
        "components-pagination-on-white-bg-hover": "#f6f6f6",
        "components-pagination-on-white-text-default": "#72757e",
        "components-pagination-on-white-text-hover": "#27282b",
        "components-pagination-on-white-text-active": "#ffffff",
        "components-pagination-on-color-accent-default": "#ffffff",
        "components-pagination-on-color-accent-scroll": "#a1d2f2",
        "components-pagination-on-color-disabled": "#0e1a2b",
        "components-pagination-on-color-bg": "rgba(255, 255, 255, 0.20)",
        "components-pagination-on-color-stroke": "rgba(255, 255, 255, 0.38)",
        "components-pagination-on-color-bg-active": "#ffffff",
        "components-pagination-on-color-bg-hover": "#27282b",
        "components-pagination-on-color-text-default": "#ffffff",
        "components-pagination-on-color-text-hover": "#ffffff",
        "components-pagination-on-color-text-active": "#182e4d",
        "components-pdf-link-card-color-bg": "#eff7fd",
        "components-pdf-link-card-color-card-bg": "#ffffff",
        "components-pdf-link-card-color-card-title": "#182e4d",
        "components-pdf-link-card-color-card-subtitle": "#27282b",
        "components-pdf-link-card-color-card-body": "#27282b",
        "components-product-card-color-bg": "#ffffff",
        "components-product-card-color-content-bg": "#ffffff",
        "components-product-card-color-border": "#d0e8f9",
        "components-product-card-color-title": "#182e4d",
        "components-product-card-color-description": "#27282b",
        "components-product-card-color-star-rating": "#0698b1",
        "components-product-card-color-badge-bg": "#046576",
        "components-product-card-color-badge-text": "#fcfcfd",
        "components-product-card-listing-color-bg": "#1e395e",
        "components-product-card-listing-color-title": "#ffffff",
        "components-product-card-listing-color-copy": "#fcfcfd",
        "components-product-card-listing-color-legal-text": "#fcfcfd",
        "components-product-information-color-bg": "#eff7fd",
        "components-product-information-color-content-bg": "#eff7fd",
        "components-product-information-color-headline": "#182e4d",
        "components-product-information-color-body": "#27282b",
        "components-product-information-color-detail-text": "#182e4d",
        "components-product-information-color-divider": "#a2a5ab",
        "components-product-information-color-icon": "#ffffff",
        "components-product-information-color-play-icon": "#ffffff",
        "components-product-information-color-selected-stroke": "#142740",
        "components-product-results-list-with-search-color-bg": "#ffffff",
        "components-promo-color-default-bg": "#ffffff",
        "components-promo-color-default-image-bg": "#ffffff",
        "components-promo-color-default-content-bg": "#ffffff",
        "components-promo-color-default-title": "#182e4d",
        "components-promo-color-default-body": "#182e4d",
        "components-promo-color-default-category": "#182e4d",
        "components-promo-color-light-bg": "#eff7fd",
        "components-promo-color-light-image-bg": "#eff7fd",
        "components-promo-color-light-content-bg": "#eff7fd",
        "components-promo-color-light-title": "#182e4d",
        "components-promo-color-light-body": "#182e4d",
        "components-promo-color-light-category": "#182e4d",
        "components-promo-color-brand-bg": "#f7d417",
        "components-promo-color-brand-image-bg": "#f7d417",
        "components-promo-color-brand-content-bg": "#f7d417",
        "components-promo-color-brand-title": "#182e4d",
        "components-promo-color-brand-body": "#27282b",
        "components-promo-color-brand-category": "#27282b",
        "components-pull-quote-color-bg": "#1e395e",
        "components-pull-quote-color-stroke": "#142740",
        "components-pull-quote-color-quote-bg": "#ffffff",
        "components-pull-quote-color-quote-title": "#182e4d",
        "components-pull-quote-color-quote-by-text": "#27282b",
        "components-pull-quote-color-quote-detail": "#1e395e",
        "components-pull-quote-color-quote-highlight": "#1e395e",
        "components-promo-alternate-color-default-bg": "#ffffff",
        "components-promo-alternate-color-default-image-bg": "#ffffff",
        "components-promo-alternate-color-default-content-bg": "#ffffff",
        "components-promo-alternate-color-default-title": "#182e4d",
        "components-promo-alternate-color-default-body": "#182e4d",
        "components-promo-alternate-color-default-category": "#182e4d",
        "components-promo-alternate-color-light-bg": "#eff7fd",
        "components-promo-alternate-color-light-image-bg": "#eff7fd",
        "components-promo-alternate-color-light-content-bg": "#eff7fd",
        "components-promo-alternate-color-light-title": "#182e4d",
        "components-promo-alternate-color-light-body": "#182e4d",
        "components-promo-alternate-color-light-category": "#182e4d",
        "components-promo-alternate-color-brand-bg": "#f7d417",
        "components-promo-alternate-color-brand-image-bg": "#f7d417",
        "components-promo-alternate-color-brand-content-bg": "#f7d417",
        "components-promo-alternate-color-brand-title": "#182e4d",
        "components-promo-alternate-color-brand-body": "#27282b",
        "components-promo-alternate-color-brand-category": "#27282b",
        "components-share-list-color-title": "#27282b",
        "components-share-list-color-bg": "#ffffff",
        "components-share-list-color-link-default": "#27282b",
        "components-share-list-color-link-hover": "#182e4d",
        "components-share-list-color-bg-link-hover": "#eff7fd",
        "components-share-list-color-close-icon": "#27282b",
        "components-share-list-color-facebook-icon": "#3b5998",
        "components-share-list-color-pinterest-icon": "#e60023",
        "components-share-list-color-twitter-icon": "#000000",
        "components-share-list-color-basic-icon-default": "#1e395e",
        "components-share-list-color-icon-hover": "#142740",
        "components-share-list-color-border": "#182e4d",
        "components-site-search-bg": "#ffffff",
        "components-site-search-results_text": "#27282b",
        "components-site-search-filter-filter-selected-bg": "#f2f6fb",
        "components-site-search-facet-group-label-bottom-line": "#d0e8f9",
        "components-site-search-facet-group-label-text": "#27282b",
        "components-site-search-facet-group-label-bg": "#ffffff",
        "components-site-search-facet-bg": "#ffffff",
        "components-site-search-facet-stroke": "#d0e8f9",
        "components-site-search-refine-bg": "#ffffff",
        "components-site-search-refine-right-divider": "#eff7fd",
        "components-site-search-refine-title": "#182e4d",
        "components-site-search-hero-search-bg": "#1e395e",
        "components-site-search-hero-search-accent-bg": "#1e395e",
        "components-site-search-hero-search-title": "#ffffff",
        "components-stats-stat-text": "#1e395e",
        "components-stats-stat-text-on-light": "#182e4d",
        "components-stats-supporting-text": "#182e4d",
        "components-stats-bg-default": "#eff7fd",
        "components-stats-bg-inner": "#ffffff",
        "components-stats-bg-section-inner": "#1e395e",
        "components-stats-title": "#182e4d",
        "components-stats-copy": "#27282b",
        "components-supporting-text-default": "#4e5056",
        "components-supporting-text-destructive": "#b32b30",
        "components-tab-bg": "#ffffff",
        "components-tab-border-default": "#72757e",
        "components-tab-border-hover": "#1e395e",
        "components-tab-border-focused": "#191b1c",
        "components-tab-border-destructive": "#b32b30",
        "components-tab-label-text-default": "#191b1c",
        "components-tab-item-text-default": "#191b1c",
        "components-tab-item-bg-default": "#ffffff",
        "components-tab-item-bg-hover": "#f2f6fb",
        "components-tab-input-field-default": "#72757e",
        "components-tab-input-field-focused": "#191b1c",
        "components-tab-input-label-focused": "#182e4d",
        "components-tab-input-field-destructive": "#b32b30",
        "components-tab-bg-2": "#eff7fd",
        "components-tag-color-bg": "rgba(255, 255, 255, 0.00)",
        "components-tag-color-text": "#182e4d",
        "components-tag-color-stroke": "#1e395e",
        "components-tag-color-bg-hover": "#182e4d",
        "components-tag-color-text-hover": "#ffffff",
        "components-tag-color-stroke-hover": "#182e4d",
        "components-text-field-bg": "#ffffff",
        "components-text-field-bg-focused": "#f2f6fb",
        "components-text-field-border-default": "#142740",
        "components-text-field-border-hover": "#4da9e7",
        "components-text-field-border-focused": "#142740",
        "components-text-field-border-destructive": "#b32b30",
        "components-text-field-input-field-default": "#4e5056",
        "components-text-field-input-field-focused": "#191b1c",
        "components-text-field-input-label-focused": "#182e4d",
        "components-text-field-input-field-destructive": "#b32b30",
        "components-text-field-icon-default": "#1e395e",
        "components-text-field-icon-destructive": "#b32b30",
        "components-text-field-supporting-text-default": "#182e4d",
        "components-text-field-supporting-text-destructive": "#b32b30",
        "components-tip-color-bg": "#eff7fd",
        "components-tip-color-title": "#182e4d",
        "components-tip-color-body": "#27282b",
        "components-tip-color-stroke": "#bfd1eb",
        "components-video-color-bg": "#ffffff",
        "themes-general-surface-color-light-bg": "#eff7fd",
        "themes-general-surface-color-light-title": "#182e4d",
        "themes-general-surface-color-light-body": "#182e4d",
        "themes-general-surface-color-light-decor-line-break": "#f6f6f6",
        "themes-general-surface-color-light-rule-line": "#bfd1eb",
        "themes-general-surface-color-dark-bg": "#1e395e",
        "themes-general-surface-color-dark-title": "#ffffff",
        "themes-general-surface-color-dark-body": "#fcfcfd",
        "themes-general-surface-color-dark-decor-line-break": "#1e395e",
        "themes-general-card-color-dark-title": "#182e4d",
        "themes-general-card-color-dark-subtitle": "#27282b",
        "themes-general-card-color-dark-body": "#27282b",
        "themes-general-card-color-light-title": "#ffffff",
        "themes-general-card-color-light-subtitle": "#fcfcfd",
        "themes-general-card-color-light-body": "#fcfcfd",
        "themes-shadow-color": "#ffffff",
        "themes-shadow-share-list-color-fill": "rgba(0, 0, 0, 0.40)",
        "themes-shadow-header-menu-color-fill": "rgba(0, 0, 0, 0.20)",
        "themes-gradient-loader-gradient-fill-1": "#1e395e",
        "themes-gradient-loader-gradient-fill-2": "rgba(30, 57, 94, 0.00)",
        "themes-color-background-white": "#ffffff",
        "themes-color-background-light": "#eff7fd",
        "themes-color-background-dark": "#27282b",
        "themes-color-background-black": "#191b1c",
        "themes-color-background-brand1": "#1e395e",
        "themes-color-background-brand2": "#f7d417",
        "themes-color-background-accent1": "#0698b1",
        "themes-color-background-accent2": "#4da9e7",
        "themes-color-background-body": "#ffffff",
        "themes-color-stroke-white": "#ffffff",
        "themes-color-stroke-light": "#d0e8f9",
        "themes-color-stroke-dark": "#27282b",
        "themes-color-stroke-black": "#191b1c",
        "themes-color-stroke-brand1": "#142740",
        "themes-color-stroke-brand2": "#f7d417",
        "themes-color-stroke-accent1": "#08c0e0",
        "themes-color-stroke-accent2": "#4da9e7",
        "themes-color-text-white": "#ffffff",
        "themes-color-text-light": "#fcfcfd",
        "themes-color-text-dark": "#27282b",
        "themes-color-text-black": "#182e4d",
        "themes-color-text-brand1": "#182e4d",
        "themes-color-text-brand2": "#f7d417",
        "themes-color-text-accent1": "#046576",
        "themes-color-text-accent2": "#135c8c",
        "colors-grayscale-100": "#fcfcfd",
        "colors-grayscale-200": "#f6f6f6",
        "colors-grayscale-300": "#a2a5ab",
        "colors-grayscale-400": "#72757e",
        "colors-grayscale-500": "#4e5056",
        "colors-grayscale-600": "#27282b",
        "colors-grayscale-700": "#191b1c",
        "colors-brand-1-100": "#f2f6fb",
        "colors-brand-1-200": "#bfd1eb",
        "colors-brand-1-300": "#3f76c2",
        "colors-brand-1-400": "#1e395e",
        "colors-brand-1-500": "#182e4d",
        "colors-brand-1-600": "#142740",
        "colors-brand-1-700": "#0e1a2b",
        "colors-brand-2-100": "#fffdf5",
        "colors-brand-2-200": "#fdf4c3",
        "colors-brand-2-300": "#fae77c",
        "colors-brand-2-400": "#f7d417",
        "colors-brand-2-500": "#d7b808",
        "colors-brand-2-600": "#ebb924",
        "colors-brand-2-700": "#5b4e03",
        "colors-accent-1-100": "#f3fdff",
        "colors-accent-1-200": "#baf2fc",
        "colors-accent-1-300": "#7ed2f6",
        "colors-accent-1-400": "#08c0e0",
        "colors-accent-1-500": "#0698b1",
        "colors-accent-1-600": "#046576",
        "colors-accent-1-700": "#02333b",
        "colors-accent-2-100": "#eff7fd",
        "colors-accent-2-200": "#d0e8f9",
        "colors-accent-2-300": "#a1d2f2",
        "colors-accent-2-400": "#4da9e7",
        "colors-accent-2-500": "#1d8ad3",
        "colors-accent-2-600": "#135c8c",
        "colors-accent-2-700": "#0a2e46",
        "colors-accent-3-100": "#eff7fd",
        "colors-accent-3-200": "#d0e8f9",
        "colors-accent-3-300": "#a1d2f2",
        "colors-accent-3-400": "#4da9e7",
        "colors-accent-3-500": "#1d8ad3",
        "colors-accent-3-600": "#135c8c",
        "colors-accent-3-700": "#0a2e46",
        "colors-accent-4-400_storage": "#000000",
        "colors-accessible-accent-1-100": "#f3fdff",
        "colors-accessible-accent-1-200": "#baf2fc",
        "colors-accessible-accent-1-300": "#7ed2f6",
        "colors-accessible-accent-1-400": "#08c0e0",
        "colors-accessible-accent-1-500": "#0698b1",
        "colors-accessible-accent-1-600": "#046576",
        "colors-accessible-accent-1-700": "#02333b",
        "colors-accessible-accent-2-100": "#eff7fd",
        "colors-accessible-accent-2-200": "#d0e8f9",
        "colors-accessible-accent-2-300": "#a1d2f2",
        "colors-accessible-accent-2-400": "#4da9e7",
        "colors-accessible-accent-2-500": "#1d8ad3",
        "colors-accessible-accent-2-600": "#135c8c",
        "colors-accessible-accent-2-700": "#0a2e46",
        "colors-accessible-brand-2-100": "#fffdf5",
        "colors-accessible-brand-2-200": "#fdf4c3",
        "colors-accessible-brand-2-300": "#fae77c",
        "colors-accessible-brand-2-400": "#f7d417",
        "colors-accessible-brand-2-500": "#d7b808",
        "colors-accessible-brand-2-600": "#ebb924",
        "colors-accessible-brand-2-700": "#5b4e03"
      },
      "spacing": {
        "components-accordion-spacing-large-padding-x": "5rem",
        "components-accordion-spacing-large-icon-margin-right": "2rem",
        "components-accordion-spacing-large-copy-margin-right": "2.5rem",
        "components-accordion-spacing-large-maxwidth": "64rem",
        "components-accordion-spacing-large-section-padding-x": "2.5rem",
        "components-accordion-spacing-large-section-padding-y": "2.5rem",
        "components-accordion-spacing-large-section-copy-margin-bottom": "2.5rem",
        "components-accordion-spacing-large-section-space-between": "4rem",
        "components-accordion-spacing-large-item-padding-x": "0rem",
        "components-accordion-spacing-large-item-padding-y": "1.5rem",
        "components-accordion-spacing-large-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-large-content-padding-x": "0rem",
        "components-accordion-spacing-large-content-padding-y": "1.5rem",
        "components-accordion-spacing-large-content-title-margin-bottom": "2rem",
        "components-accordion-spacing-large-content-content-space-between": "1.5rem",
        "components-accordion-spacing-large-content-content-margin-left": "1rem",
        "components-accordion-spacing-small-padding-top": "1rem",
        "components-accordion-spacing-small-padding-x": "1.5rem",
        "components-accordion-spacing-small-padding-y": "2.5rem",
        "components-accordion-spacing-small-icon-margin-right": "0.75rem",
        "components-accordion-spacing-small-title-margin-bottom": "0.5rem",
        "components-accordion-spacing-small-copy-margin-right": "2rem",
        "components-accordion-spacing-small-copy-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-padding-x": "1.5rem",
        "components-accordion-spacing-small-section-padding-y": "1.5rem",
        "components-accordion-spacing-small-section-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-space-between": "1rem",
        "components-accordion-spacing-small-item-padding-x": "0rem",
        "components-accordion-spacing-small-item-padding-y": "1rem",
        "components-accordion-spacing-small-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-small-content-padding-x": "1.5rem",
        "components-accordion-spacing-small-content-padding-y": "0.75rem",
        "components-accordion-spacing-small-content-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-content-content-space-between": "1rem",
        "components-accordion-spacing-small-content-content-margin-left": "1rem",
        "components-article-card-spacing-large-padding-image-x": "0rem",
        "components-article-card-spacing-large-padding-image-y": "0rem",
        "components-article-card-spacing-large-padding-content-x": "0rem",
        "components-article-card-spacing-large-padding-content-y": "2rem",
        "components-article-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-article-card-spacing-large-button-space-between": "1rem",
        "components-article-card-spacing-large-title-margin-bottom": "1rem",
        "components-article-card-spacing-large-list-content-gap": "1rem",
        "components-article-card-spacing-large-list-image-padding-x": "0rem",
        "components-article-card-spacing-large-list-image-padding-y": "0rem",
        "components-article-card-spacing-small-padding-image-x": "0rem",
        "components-article-card-spacing-small-padding-image-y": "0rem",
        "components-article-card-spacing-small-padding-content-x": "1.5rem",
        "components-article-card-spacing-small-padding-content-y": "1.5rem",
        "components-article-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-article-card-listing-spacing-small-padding-x": "1.5rem",
        "components-article-card-listing-spacing-small-padding-y": "2.5rem",
        "components-article-hero-spacing-large-padding-x": "0rem",
        "components-article-hero-spacing-large-padding-y": "0rem",
        "components-article-hero-spacing-large-title-margin-bottom": "2rem",
        "components-article-hero-spacing-large-label-share-margin-bottom": "2rem",
        "components-article-hero-spacing-large-author-name-margin-bottom": "0.75rem",
        "components-article-hero-spacing-large-author-thumbnail-margin-left": "1rem",
        "components-article-hero-spacing-large-details-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-space-between": "1rem",
        "components-article-hero-spacing-large-body-margin-bottom": "2rem",
        "components-article-hero-spacing-large-image-space-between": "1rem",
        "components-article-hero-spacing-large-image-margin-bottom": "1rem",
        "components-article-hero-spacing-large-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-stats-margin-bottom": "2.5rem",
        "components-article-hero-spacing-small-content-margin-top": "2.5rem",
        "components-article-hero-spacing-small-title-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-label-share-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-authorname-margin-bottom": "0.75rem",
        "components-article-hero-spacing-small-authorthumbnail-margin-left": "1rem",
        "components-article-hero-spacing-small-details-margin-bottom": "1rem",
        "components-article-hero-spacing-small-space-between": "0.75rem",
        "components-article-hero-spacing-small-body-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-image-space-between": "0.75rem",
        "components-article-hero-spacing-small-image-margin-bottom": "1rem",
        "components-article-hero-spacing-small-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-stats-margin-bottom": "2rem",
        "components-banner-spacing-large-content-padding-x": "1.5rem",
        "components-banner-spacing-large-content-space-between": "1rem",
        "components-banner-spacing-large-content-width": "69.375rem",
        "components-banner-spacing-icon-dimensions": "1.5rem",
        "components-banner-spacing-icon-margin-left": "0.5rem",
        "components-banner-spacing-small-space-between": "1rem",
        "components-banner-spacing-small-content-space-between": "1rem",
        "components-banner-spacing-small-title-margin-bottom": "0.75rem",
        "components-banner-spacing-small-content-margin-bottom": "1rem",
        "components-breadcrumb-spacing-large-space-between": "0.25rem",
        "components-breadcrumb-spacing-small-space-between": "0.25rem",
        "components-button-spacing-large-icon-large-x": "2.5rem",
        "components-button-spacing-large-icon-large-y": "2.5rem",
        "components-button-spacing-large-default-padding-y": "0.75rem",
        "components-button-spacing-large-default-padding-x": "1.5rem",
        "components-button-spacing-large-default-space-between": "0.25rem",
        "components-button-spacing-large-icon-left-padding-left": "1rem",
        "components-button-spacing-large-icon-right-padding-right": "1rem",
        "components-button-spacing-large-icon-only-padding-x": "0.75rem",
        "components-button-spacing-large-icon-only-padding-y": "0.75rem",
        "components-button-spacing-large-tertiary-content-height": "1.5rem",
        "components-button-spacing-compressed-icon-large-x": "2.5rem",
        "components-button-spacing-compressed-icon-large-y": "2.5rem",
        "components-button-spacing-compressed-default-padding-y": "0.5rem",
        "components-button-spacing-compressed-default-padding-x": "1rem",
        "components-button-spacing-compressed-default-space-between": "0.125rem",
        "components-button-spacing-compressed-icon-left-padding-left": "0.5rem",
        "components-button-spacing-compressed-icon-right-padding-right": "0.5rem",
        "components-button-spacing-compressed-icon-only-padding-x": "0.5rem",
        "components-button-group-spacing-padding-y": "0.125rem",
        "components-button-group-spacing-padding-x": "0.25rem",
        "components-button-group-spacing-space-between": "0.5rem",
        "components-category-card-spacing-large-padding-top": "0rem",
        "components-category-card-spacing-large-padding-x": "0rem",
        "components-category-card-spacing-large-padding-y": "0rem",
        "components-category-card-spacing-large-padding-image-x": "0rem",
        "components-category-card-spacing-large-padding-image-y": "0rem",
        "components-category-card-spacing-large-padding-content-x": "2rem",
        "components-category-card-spacing-large-padding-content-y": "2rem",
        "components-category-card-listing-spacing-large-padding-y": "5rem",
        "components-category-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-category-card-listing-spacing-large-card-margin-bottom": "0rem",
        "components-category-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-card-search-result-small-padding-top": "0rem",
        "components-card-search-result-small-padding-x": "0rem",
        "components-card-search-result-small-padding-y": "0rem",
        "components-card-search-result-small-padding-image-x": "0rem",
        "components-card-search-result-small-padding-image-y": "0rem",
        "components-card-search-result-small-padding-content-x": "1.5rem",
        "components-card-search-result-small-padding-content-y": "1.5rem",
        "components-card-search-result-spacing-large-padding-x": "0rem",
        "components-card-search-result-spacing-large-padding-y": "1.5rem",
        "components-card-search-result-spacing-large-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-image-margin-right": "1.5rem",
        "components-card-search-result-spacing-small-padding-x": "1.5rem",
        "components-card-search-result-spacing-small-padding-y": "1.5rem",
        "components-card-search-result-spacing-small-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-image-margin-right": "0.75rem",
        "components-contact-us-promo-spacing-large-padding-x": "5rem",
        "components-contact-us-promo-spacing-large-padding-y": "5rem",
        "components-contact-us-promo-spacing-large-content-margin-right": "5rem",
        "components-contact-us-promo-spacing-large-margin-y": "2.5rem",
        "components-contact-us-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-contact-us-promo-spacing-small-margin-y": "2.5rem",
        "components-content-list-list-item-container-padding-x": "0.75rem",
        "components-content-list-list-item-container-padding-y": "1rem",
        "components-content-list-list-item-content-space-between": "0.5rem",
        "components-content-list-list-item-content-default-padding-y": "0.25rem",
        "components-content-list-list-item-content-hyperlink-padding-y": "0rem",
        "components-content-list-spacing-large-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-large-item-title-margin-bottom": "1rem",
        "components-content-list-spacing-large-item-copy-margin-bottom": "2.5rem",
        "components-content-list-spacing-large-item-icon-copy-margin-top": "0rem",
        "components-content-list-spacing-large-item-margin-bottom": "2rem",
        "components-content-list-spacing-large-item-image-margin-bottom": "0.5rem",
        "components-content-list-spacing-large-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-y": "2.5rem",
        "components-content-list-spacing-small-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-small-item-title-margin-bottom": "0.5rem",
        "components-content-list-spacing-small-item-copy-margin-bottom": "1rem",
        "components-content-list-spacing-small-item-margin-bottom": "1.5rem",
        "components-content-list-spacing-small-header-title-margin-bottom": "1rem",
        "components-content-list-spacing-small-header-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-title-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-button-space-between": "1rem",
        "components-dropdown-item-padding-y": "0.75rem",
        "components-dropdown-item-padding-x": "0.75rem",
        "components-dropdown-item-spacing-x": "0.75rem",
        "components-dropdown-input-padding-y": "0.25rem",
        "components-dropdown-input-padding-x": "1rem",
        "components-footer-brand-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-large-column-margin-right": "4rem",
        "components-footer-brand-spacing-large-column-margin-bottom": "4rem",
        "components-footer-brand-spacing-large-link-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-padding-y": "2.5rem",
        "components-footer-brand-spacing-small-padding-x": "1rem",
        "components-footer-brand-spacing-small-logo-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-column-margin-right": "0.125rem",
        "components-footer-brand-spacing-small-column-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-small-link-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-title-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-text-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-footer-link-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-link-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-link-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-link-minheight": "3rem",
        "components-footer-brand-spacing-small-footer-category-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-category-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-category-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-category-minheight": "3rem",
        "components-footer-global-spacing-large-padding-y": "2.5rem",
        "components-footer-global-spacing-large-padding-x": "5rem",
        "components-footer-global-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-global-spacing-large-column-margin-right": "2rem",
        "components-footer-global-spacing-large-link-margin-bottom": "2rem",
        "components-footer-global-spacing-small-padding-y": "2.5rem",
        "components-footer-global-spacing-small-padding-x": "1rem",
        "components-footer-global-spacing-small-logo-margin-bottom": "1rem",
        "components-footer-global-spacing-small-column-margin-right": "1rem",
        "components-footer-global-spacing-small-link-margin-bottom": "1rem",
        "components-generic-card-spacing-large-padding-x": "0rem",
        "components-generic-card-spacing-large-padding-y": "0rem",
        "components-generic-card-spacing-large-padding-image-x": "0rem",
        "components-generic-card-spacing-large-padding-image-y": "0rem",
        "components-generic-card-spacing-large-padding-content-x": "2rem",
        "components-generic-card-spacing-large-padding-content-y": "2rem",
        "components-generic-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-generic-card-spacing-large-title-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-button-space-between": "1rem",
        "components-generic-card-spacing-small-padding-x": "0rem",
        "components-generic-card-spacing-small-padding-y": "0rem",
        "components-generic-card-spacing-small-padding-image-x": "0rem",
        "components-generic-card-spacing-small-padding-image-y": "0rem",
        "components-generic-card-spacing-small-padding-content-x": "1.5rem",
        "components-generic-card-spacing-small-padding-content-y": "1.5rem",
        "components-global-search-spacing-omnibox-padding-x": "0.125rem",
        "components-global-search-spacing-omnibox-list-padding-y": "0.5rem",
        "components-global-search-spacing-omnibox-label-padding": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-item-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-height": "3rem",
        "components-generic-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-generic-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-header-shadow-x": "0rem",
        "components-header-spacing-large-padding-y": "0.5rem",
        "components-header-spacing-large-padding-x": "1.5rem",
        "components-header-spacing-large-links-margin-right": "1.5rem",
        "components-header-spacing-large-icons-space-between": "1.5rem",
        "components-header-spacing-large-logo-margin-right": "2rem",
        "components-header-spacing-large-space-between-search": "0.5rem",
        "components-header-spacing-large-height": "5rem",
        "components-header-spacing-large-language-selector-padding-x": "1rem",
        "components-header-spacing-large-language-selector-padding-y": "0.5rem",
        "components-header-spacing-large-language-selector-space-between": "0.75rem",
        "components-header-spacing-large-language-selector-country-space-between": "0.5rem",
        "components-header-spacing-large-mega-menu-padding-y": "2.5rem",
        "components-header-spacing-large-mega-menu-padding-x": "2.5rem",
        "components-header-spacing-large-mega-menu-column-margin-right": "2.5rem",
        "components-header-spacing-large-mega-menu-category-link-margin-bottom": "1rem",
        "components-header-spacing-large-mega-menu-link-margin-bottom": "0.5rem",
        "components-header-spacing-large-mega-menu-featured-padding-x": "2rem",
        "components-header-spacing-large-mega-menu-featured-padding-y": "2rem",
        "components-header-spacing-large-mega-menu-featured-image-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-title-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-link-space-between": "1.5rem",
        "components-header-spacing-large-mega-menu-title-margin-bottom": "2.5rem",
        "components-header-spacing-large-dropdown-padding-y": "1.5rem",
        "components-header-spacing-large-dropdown-padding-x": "1.5rem",
        "components-header-spacing-large-dropdown-margin-bottom": "0.5rem",
        "components-header-spacing-small-language-selector-padding-x": "1rem",
        "components-header-spacing-small-language-selector-padding-y": "0.5rem",
        "components-header-spacing-small-language-selector-space-between": "0.5rem",
        "components-header-spacing-small-padding-left": "1rem",
        "components-header-spacing-small-padding-right": "1rem",
        "components-header-spacing-small-padding-top": "0rem",
        "components-header-spacing-small-padding-bottom": "0rem",
        "components-header-spacing-small-nav-main-minheight": "3rem",
        "components-header-spacing-small-nav-main-padding-y": "0.5rem",
        "components-header-spacing-small-nav-main-padding-left": "1.5rem",
        "components-header-spacing-small-nav-main-padding-right": "1rem",
        "components-header-spacing-small-nav-category-minheight": "3rem",
        "components-header-spacing-small-nav-category-padding-left": "1.5rem",
        "components-header-spacing-small-nav-category-padding-right": "0.75rem",
        "components-header-spacing-small-nav-category-padding-y": "0.75rem",
        "components-header-spacing-small-nav-category-space-between": "0rem",
        "components-header-spacing-small-nav-menu-link-minheight": "3rem",
        "components-header-spacing-small-nav-menu-link-padding-right": "1.5rem",
        "components-header-spacing-small-nav-menu-link-padding-left": "2.5rem",
        "components-header-spacing-small-nav-menu-link-padding-y": "0.75rem",
        "components-header-spacing-small-mega-menu-padding-y": "0rem",
        "components-header-spacing-small-mega-menu-padding-x": "0rem",
        "components-header-spacing-small-mega-menu-category-link-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-link-margin-bottom": "0rem",
        "components-header-spacing-small-mega-menu-featured-padding-x": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-padding-y": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-image-margin-bottom": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-title-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-featured-link-space-between": "0.75rem",
        "components-header-spacing-small-mega-menu-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-container-padding": "2.5rem",
        "components-hero-homepage-spacing-large-padding-x": "4rem",
        "components-hero-homepage-spacing-large-padding-y": "2.5rem",
        "components-hero-homepage-spacing-large-minheight": "34.3125rem",
        "components-hero-homepage-spacing-large-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-margin-bottom": "2rem",
        "components-hero-homepage-spacing-large-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-copy-margin-bottom": "2.5rem",
        "components-hero-homepage-spacing-large-cta-margin-right": "1rem",
        "components-hero-homepage-spacing-large-image-height": "34.3125rem",
        "components-hero-homepage-spacing-large-image-width": "45.75rem",
        "components-hero-homepage-spacing-small-padding-x": "0rem",
        "components-hero-homepage-spacing-small-padding-y": "1.5rem",
        "components-hero-homepage-spacing-small-minheight": "34.3125rem",
        "components-hero-homepage-spacing-small-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-copy-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-image-height": "17.5625rem",
        "components-hero-homepage-spacing-small-image-width": "45.75rem",
        "components-hero-internal-spacing-large-padding-y": "2.5rem",
        "components-hero-internal-spacing-large-padding-right": "10rem",
        "components-hero-internal-spacing-large-title-margin-bottom": "1.5rem",
        "components-hero-internal-spacing-large-button-padding-y": "2rem",
        "components-hero-internal-spacing-large-text-maxwidth": "59.625rem",
        "components-hero-internal-spacing-large-minheight": "18.5rem",
        "components-hero-internal-spacing-small-padding-x": "1.5rem",
        "components-hero-internal-spacing-small-padding-right": "0rem",
        "components-hero-internal-spacing-small-button-padding-y": "1.5rem",
        "components-hero-internal-spacing-small-text-maxwidth": "30rem",
        "components-hero-internal-spacing-small-minheight": "13.5rem",
        "components-icon-card-spacing-large-padding-x": "0rem",
        "components-icon-card-spacing-large-padding-y": "0rem",
        "components-icon-card-spacing-large-icon-margin": "1.5rem",
        "components-icon-card-spacing-large-margin-right": "1.5rem",
        "components-icon-card-spacing-large-title-margin-bottom": "1rem",
        "components-icon-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-icon-card-spacing-large-button-space-between": "1.5rem",
        "components-icon-card-spacing-large-top-space-between": "1rem",
        "components-icon-card-spacing-small-padding-x": "1.5rem",
        "components-icon-card-spacing-small-padding-y": "1.5rem",
        "components-icon-card-spacing-small-icon-margin": "1.5rem",
        "components-icon-card-spacing-small-margin-right": "1.5rem",
        "components-icon-card-spacing-small-title-margin-bottom": "1.5rem",
        "components-icon-card-dimensions-large-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-large-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-large-big-icon-height": "4rem",
        "components-icon-card-dimensions-large-big-icon-width": "4rem",
        "components-icon-card-dimensions-large-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-large-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-large-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-large-tiny-icon-width": "2.5rem",
        "components-icon-card-dimensions-small-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-small-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-small-big-icon-height": "3.5rem",
        "components-icon-card-dimensions-small-big-icon-width": "3.5rem",
        "components-icon-card-dimensions-small-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-small-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-small-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-small-tiny-icon-width": "2.5rem",
        "components-icon-card-listing-spacing-large-padding-inner-x": "4rem",
        "components-icon-card-listing-spacing-large-padding-inner-y": "4rem",
        "components-icon-card-listing-spacing-large-card-space-between": "4rem",
        "components-icon-card-listing-spacing-large-title-margin-bottom": "1.5rem",
        "components-icon-card-listing-spacing-large-card-margin-bottom": "4rem",
        "components-icon-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-x": "1.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-y": "2.5rem",
        "components-icon-card-listing-spacing-small-title-margin-bottom": "1rem",
        "components-icon-card-listing-spacing-small-button-margin-top": "1.5rem",
        "components-jumplink-link-padding-bottom": "0.5rem",
        "components-jumplink-spacing-small-title-area-margin-bottom": "1.5rem",
        "components-jumplink-spacing-small-link-container-padding-x": "0rem",
        "components-lightbox-spacing-large-carousel-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-carousel-space-between": "1.5rem",
        "components-lightbox-spacing-large-featured-image-height": "39.25rem",
        "components-lightbox-spacing-large-featured-image-width": "39.25rem",
        "components-lightbox-spacing-large-image-height": "17.0625rem",
        "components-lightbox-spacing-large-image-width": "17.0625rem",
        "components-lightbox-spacing-small-carousel-margin-bottom": "1rem",
        "components-lightbox-spacing-small-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-small-media-details-margin-top": "1rem",
        "components-lightbox-spacing-small-image-height": "20.4375rem",
        "components-lightbox-spacing-small-image-width": "20.4375rem",
        "components-legal-disclaimer-spacing-small-ruleline-width": "20.4375rem",
        "components-media-spacing-large-padding-y": "0.5rem",
        "components-media-spacing-large-padding-x": "0.75rem",
        "components-media-gallery-large-container-grid-gap-x": "1.5rem",
        "components-media-gallery-large-container-grid-gap-y": "1.5rem",
        "components-media-gallery-small-container-grid-gap-x": "0.5rem",
        "components-media-gallery-small-container-grid-gap-y": "0.5rem",
        "components-media-gallery-spacing-small-padding-x": "1.5rem",
        "components-media-gallery-spacing-small-padding-y": "2.5rem",
        "components-media-gallery-spacing-large-padding-x": "5rem",
        "components-media-gallery-spacing-large-padding-y": "5rem",
        "components-product-card-spacing-list-content-gap": "0rem",
        "components-product-card-spacing-large-padding-image-x": "0rem",
        "components-product-card-spacing-large-padding-image-y": "0rem",
        "components-product-card-spacing-large-padding-content-x": "1.5rem",
        "components-product-card-spacing-large-padding-content-y": "1.5rem",
        "components-product-card-spacing-large-category-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-title-margin-bottom": "1rem",
        "components-product-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-button-space-between": "1rem",
        "components-product-card-spacing-small-padding-image-x": "0rem",
        "components-product-card-spacing-small-padding-image-y": "0rem",
        "components-product-card-spacing-small-padding-content-x": "1.5rem",
        "components-product-card-spacing-small-padding-content-y": "1.5rem",
        "components-product-card-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-large-padding-x": "5rem",
        "components-product-information-spacing-large-padding-y": "2.5rem",
        "components-product-information-spacing-large-title-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-label-margin-bottom": "2.5rem",
        "components-product-information-spacing-large-ratings-margin-bottom": "1rem",
        "components-product-information-spacing-large-details-margin-bottom": "1rem",
        "components-product-information-spacing-large-space-between": "1rem",
        "components-product-information-spacing-large-carousel-space-between": "5rem",
        "components-product-information-spacing-large-button-margin-y": "0.5rem",
        "components-product-information-spacing-large-body-margin-top": "1.5rem",
        "components-product-information-spacing-large-image-space-between": "1rem",
        "components-product-information-spacing-large-image-margin-bottom": "1rem",
        "components-product-information-spacing-small-padding-x": "1.5rem",
        "components-product-information-spacing-small-padding-y": "1.5rem",
        "components-product-information-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-small-label-margin-bottom": "1.5rem",
        "components-product-information-spacing-small-ratings-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-details-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-space-between": "0.75rem",
        "components-product-information-spacing-small-carousel-space-between": "2.5rem",
        "components-product-information-spacing-small-button-margin-y": "0.75rem",
        "components-product-information-spacing-small-body-margin-top": "1rem",
        "components-product-information-spacing-small-image-space-between": "0.75rem",
        "components-product-information-spacing-small-image-margin-bottom": "1rem",
        "components-product-results-list-with-search-spacing-large-padding-x": "5rem",
        "components-product-results-list-with-search-spacing-large-padding-y": "5rem",
        "components-product-results-list-with-search-spacing-large-ruleline-margin-top": "2.5rem",
        "components-product-results-list-with-search-spacing-large-filter-space-between": "1rem",
        "components-product-results-list-with-search-spacing-large-card-space-between": "1.5rem",
        "components-product-results-list-with-search-spacing-large-card-container-margin-bottom": "5rem",
        "components-product-results-list-with-search-spacing-small-padding-x": "1.5rem",
        "components-product-results-list-with-search-spacing-small-padding-y": "2.5rem",
        "components-product-results-list-with-search-spacing-small-ruleline-margin-top": "1rem",
        "components-product-results-list-with-search-spacing-small-filter-padding-x": "0.5rem",
        "components-product-results-list-with-search-spacing-small-card-space-between": "1rem",
        "components-product-results-list-with-search-spacing-small-card-margin-bottom": "1.5rem",
        "components-promo-spacing-large-padding-x": "4rem",
        "components-promo-spacing-large-padding-y": "4rem",
        "components-promo-spacing-large-content-space-between": "3rem",
        "components-promo-spacing-large-content-space-between-y": "3rem",
        "components-promo-spacing-large-padding-content-x": "0rem",
        "components-promo-spacing-large-padding-content-condensed-x": "0rem",
        "components-promo-spacing-large-padding-content-y": "0rem",
        "components-promo-spacing-large-padding-content-condensed-y": "0rem",
        "components-promo-spacing-large-padding-image-x": "1.5rem",
        "components-promo-spacing-large-padding-image-y": "1.5rem",
        "components-promo-spacing-large-padding-image-top": "5rem",
        "components-promo-spacing-large-title-margin-bottom": "2rem",
        "components-promo-spacing-large-margin-y": "2rem",
        "components-promo-spacing-large-buttons-space-between": "1rem",
        "components-promo-spacing-small-padding-x": "0rem",
        "components-promo-spacing-small-padding-y": "0rem",
        "components-promo-spacing-small-padding-top": "0rem",
        "components-promo-spacing-small-padding-content-x": "1.5rem",
        "components-promo-spacing-small-padding-content-y": "2.5rem",
        "components-promo-spacing-small-padding-image-x": "1rem",
        "components-promo-spacing-small-padding-image-y": "0rem",
        "components-promo-spacing-small-padding-image-top": "1rem",
        "components-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-promo-spacing-small-margin-y": "2.5rem",
        "components-promo-minheight": "30rem",
        "components-pull-quote-spacing-large-padding-quote-x": "4rem",
        "components-pull-quote-spacing-large-padding-quote-y": "4rem",
        "components-pull-quote-spacing-large-title-margin-bottom": "2rem",
        "components-pull-quote-spacing-large-quote-margin-bottom": "4rem",
        "components-pull-quote-spacing-large-quote-space-between": "1.5rem",
        "components-pull-quote-spacing-large-detail-space-between": "0.5rem",
        "components-pull-quote-spacing-large-quote-minheight": "25rem",
        "components-pull-quote-spacing-small-padding-quote-x": "1.5rem",
        "components-pull-quote-spacing-small-padding-quote-y": "2.5rem",
        "components-pull-quote-spacing-small-title-margin-bottom": "1.5rem",
        "components-pull-quote-spacing-small-quote-margin-bottom": "2.5rem",
        "components-pull-quote-spacing-small-quote-space-between": "1rem",
        "components-pull-quote-spacing-small-detail-space-between": "1rem",
        "components-pull-quote-spacing-small-quote-minheight": "15rem",
        "components-promo-alternate-minheight": "30rem",
        "components-promo-alternate-spacing-large-padding-x": "4rem",
        "components-promo-alternate-spacing-large-padding-y": "4rem",
        "components-promo-alternate-spacing-large-content-space-between": "3rem",
        "components-promo-alternate-spacing-large-padding-content-x": "0rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-x": "0rem",
        "components-promo-alternate-spacing-large-padding-content-y": "0rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-y": "0rem",
        "components-promo-alternate-spacing-large-padding-image-x": "1.5rem",
        "components-promo-alternate-spacing-large-padding-image-y": "1.5rem",
        "components-promo-alternate-spacing-large-padding-image-top": "5rem",
        "components-promo-alternate-spacing-large-title-margin-bottom": "2rem",
        "components-promo-alternate-spacing-small-padding-x": "0rem",
        "components-promo-alternate-spacing-small-padding-y": "0rem",
        "components-promo-alternate-spacing-small-padding-content-x": "1.5rem",
        "components-promo-alternate-spacing-small-padding-content-y": "2.5rem",
        "components-promo-alternate-spacing-small-padding-image-x": "1rem",
        "components-promo-alternate-spacing-small-padding-image-y": "0rem",
        "components-promo-alternate-spacing-small-padding-image-top": "1rem",
        "components-promo-alternate-spacing-small-title-margin-bottom": "1.5rem",
        "components-share-list-spacing-padding-x": "1rem",
        "components-share-list-spacing-padding-y": "1rem",
        "components-header-copy-margin-bottom": "1rem",
        "components-header-margin-bottom": "1.5rem",
        "components-item-margin-bottom": "1.5rem",
        "components-site-search-padding-x": "2.5rem",
        "components-site-search-padding-y": "5rem",
        "components-site-search-facet-label-space-between": "0.25rem",
        "components-site-search-refine-padding-x": "1.5rem",
        "components-site-search-refine-padding-y": "0rem",
        "components-site-search-hero-search-height": "18.5rem",
        "components-site-search-hero-search-large-padding-x": "5rem",
        "components-site-search-hero-search-large-padding-y": "5rem",
        "components-stats-spacing-stat-text-space-between": "0.5rem",
        "components-stats-spacing-large-inner-padding-x": "5rem",
        "components-stats-spacing-large-inner-padding-y": "5rem",
        "components-stats-spacing-small-inner-padding-x": "1.5rem",
        "components-stats-spacing-small-inner-padding-y": "1.5rem",
        "components-icon-card-listing-spacing-small-top-space-between": "1rem",
        "components-supporting-text-padding-x": "0rem",
        "components-supporting-text-padding-bottom": "0.5rem",
        "components-tab-item-padding-y": "0.75rem",
        "components-tab-item-padding-x": "0.75rem",
        "components-tab-item-spacing-x": "0.75rem",
        "components-tab-input-padding-y": "0.25rem",
        "components-tab-input-padding-x": "1rem",
        "components-tag-spacing-padding-x": "0.5rem",
        "components-tag-spacing-padding-y": "0.5rem",
        "components-tag-spacing-space-between": "0.5rem",
        "components-text-field-input-padding-y": "0.25rem",
        "components-text-field-input-padding-x": "1rem",
        "components-text-field-area-input-padding-y": "1rem",
        "components-text-field-area-input-padding-x": "1rem",
        "components-text-field-supporting-text-padding-x": "0rem",
        "components-text-field-supporting-text-padding-top": "0.5rem",
        "themes-general-surface-spacing-large-padding-x": "4rem",
        "themes-general-surface-spacing-large-padding-y": "4rem",
        "themes-general-surface-spacing-large-title-margin-bottom": "2rem",
        "themes-general-surface-spacing-large-body-margin-right": "4rem",
        "themes-general-surface-spacing-large-body-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-large-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-large-cards-space-between": "1.5rem",
        "themes-general-surface-spacing-large-button-margin-top": "2.5rem",
        "themes-general-surface-spacing-small-padding-x": "1.5rem",
        "themes-general-surface-spacing-small-padding-y": "2.5rem",
        "themes-general-surface-spacing-small-title-margin-bottom": "1rem",
        "themes-general-surface-spacing-small-body-margin-right": "2.5rem",
        "themes-general-surface-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-surface-spacing-small-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-small-cards-space-between": "1rem",
        "themes-general-surface-spacing-small-button-margin-top": "1.5rem",
        "themes-general-card-spacing-large-eyebrow-margin-bottom": "1.5rem",
        "themes-general-card-spacing-large-title-margin-bottom": "1.5rem",
        "themes-general-card-spacing-large-body-margin-bottom": "2rem",
        "themes-general-card-spacing-large-button-space-between": "1rem",
        "themes-general-card-spacing-small-eyebrow-margin-bottom": "1.5rem",
        "themes-general-card-spacing-small-title-margin-bottom": "1rem",
        "themes-general-card-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-small-button-space-between": "1rem",
        "themes-shadow-share-list-spacing-x": "0rem",
        "themes-shadow-share-list-spacing-y": "0.75rem",
        "themes-shadow-share-list-spacing-blur": "1rem",
        "themes-shadow-share-list-spacing-spread": "-0.5rem",
        "themes-shadow-header-menu-spacing-x": "0rem",
        "themes-shadow-header-menu-spacing-y": "0.75rem",
        "themes-shadow-header-menu-spacing-blur": "1rem",
        "themes-shadow-header-menu-spacing-spread": "-0.5rem",
        "themes-typography-rte-large-h1-textsize": "3rem",
        "themes-typography-rte-large-h2-textsize": "2.5rem",
        "themes-typography-rte-large-h3-textsize": "2.25rem",
        "themes-typography-rte-large-h4-textsize": "2rem",
        "themes-typography-rte-large-h5-textsize": "1.5rem",
        "themes-typography-rte-large-h6-textsize": "1.25rem",
        "themes-typography-rte-medium-h1-textsize": "2.5rem",
        "themes-typography-rte-medium-h2-textsize": "2.25rem",
        "themes-typography-rte-medium-h3-textsize": "1.75rem",
        "themes-typography-rte-medium-h4-textsize": "1.5rem",
        "themes-typography-rte-medium-h5-textsize": "1.25rem",
        "themes-typography-rte-medium-h6-textsize": "1.125rem",
        "themes-typography-rte-small-h1-textsize": "2.5rem",
        "themes-typography-rte-small-h2-textsize": "2rem",
        "themes-typography-rte-small-h3-textsize": "1.75rem",
        "themes-typography-rte-small-h4-textsize": "1.5rem",
        "themes-typography-rte-small-h5-textsize": "1.125rem",
        "themes-typography-rte-small-h6-textsize": "1rem",
        "spacing-margin-small-1": "0.125rem",
        "spacing-margin-small-2": "0.25rem",
        "spacing-margin-small-3": "0.5rem",
        "spacing-margin-small-4": "0.75rem",
        "spacing-margin-small-5": "1rem",
        "spacing-margin-small-6": "1.5rem",
        "spacing-margin-small-7": "2.5rem",
        "spacing-margin-medium-2": "0.75rem",
        "spacing-margin-medium-4": "1.5rem",
        "spacing-margin-medium-5": "2rem",
        "spacing-margin-large-1": "0.5rem",
        "spacing-margin-large-2": "1rem",
        "spacing-margin-large-3": "1.5rem",
        "spacing-margin-large-4": "2rem",
        "spacing-margin-large-5": "2.5rem",
        "spacing-margin-large-6": "4rem",
        "spacing-margin-large-7": "5rem",
        "spacing-space-between-small-1": "0.125rem",
        "spacing-space-between-small-2": "0.25rem",
        "spacing-space-between-small-3": "0.5rem",
        "spacing-space-between-small-4": "0.75rem",
        "spacing-space-between-small-5": "1rem",
        "spacing-space-between-small-6": "1.5rem",
        "spacing-space-between-small-7": "2.5rem",
        "spacing-space-between-medium-1": "0.125rem",
        "spacing-space-between-medium-2": "0.25rem",
        "spacing-space-between-medium-3": "0.5rem",
        "spacing-space-between-medium-4": "0.75rem",
        "spacing-space-between-medium-5": "1rem",
        "spacing-space-between-large-1": "0.25rem",
        "spacing-space-between-large-2": "0.5rem",
        "spacing-space-between-large-3": "1rem",
        "spacing-space-between-large-4": "1.5rem",
        "spacing-space-between-large-5": "1.5rem",
        "spacing-space-between-large-6": "2.5rem",
        "spacing-space-between-large-7": "4rem",
        "spacing-padding-small-1": "0.125rem",
        "spacing-padding-small-2": "0.25rem",
        "spacing-padding-small-3": "0.5rem",
        "spacing-padding-small-4": "0.75rem",
        "spacing-padding-small-5": "1rem",
        "spacing-padding-small-6": "1.5rem",
        "spacing-padding-small-7": "2.5rem",
        "spacing-padding-medium-1": "0.25rem",
        "spacing-padding-medium-2": "0.75rem",
        "spacing-padding-medium-3": "1rem",
        "spacing-padding-medium-4": "1.5rem",
        "spacing-padding-medium-5": "2rem",
        "spacing-padding-medium-6": "3rem",
        "spacing-padding-medium-7": "4rem",
        "spacing-padding-large-1": "0.5rem",
        "spacing-padding-large-2": "1rem",
        "spacing-padding-large-3": "1.5rem",
        "spacing-padding-large-4": "2rem",
        "spacing-padding-large-5": "2.5rem",
        "spacing-padding-large-6": "4rem",
        "spacing-padding-large-7": "5rem"
      },
      "string": {
        "components-accordion-section-button": "var(--brand)",
        "components-article-card-listing-button-theme": "var(--brand)",
        "components-article-hero-pagination-buttons": "var(--brand)",
        "components-banner-banner-button": "var(--white)",
        "components-category-card-listing-button-theme": "var(--brand)",
        "components-contact-us-promo-hierarchy-button-theme-1": "var(--filled)",
        "components-contact-us-promo-hierarchy-button-theme-2": "var(--outlined)",
        "components-contact-us-promo-color-button-theme": "var(--brand)",
        "components-contact-us-promo-color-brand-button-theme": "var(--brand)",
        "components-generic-card-button-theme": "var(--brand)",
        "components-global-search-loader": "var(--default)",
        "components-generic-card-listing-decor-top": "var(--raid-1)",
        "components-generic-card-listing-decor-bottom": "var(--raid-2)",
        "components-hero-homepage-pagination-buttons": "var(--brand)",
        "components-hero-homepage-pattern": "var(--pledge)",
        "components-hero-homepage-button-1": "var(--brand)",
        "components-hero-homepage-button-2": "var(--brand)",
        "components-hero-homepage-image-ratio": "var(--4:3)",
        "components-hero-internal-theme-button-one-type": "var(--filled)",
        "components-hero-internal-theme-button-two-type": "var(--outlined)",
        "components-hero-internal-theme-button-color": "var(--white)",
        "components-hero-internal-theme-button-color-with-image": "var(--white)",
        "components-lightbox-button-type": "var(--brand)",
        "components-pdf-link-card-hierarchy-button-theme-1": "var(--filled)",
        "components-pdf-link-card-hierarchy-button-theme-2": "var(--outlined)",
        "components-pdf-link-card-color-button-theme": "var(--brand)",
        "components-product-card-badge-align": "var(--left)",
        "components-product-card-listing-button-color": "var(--white)",
        "components-promo-theme-button-one-theme": "var(--filled)",
        "components-promo-theme-button-two-theme": "var(--outlined)",
        "components-promo-color-default-button-theme": "var(--brand)",
        "components-promo-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-color-brand-button-theme": "var(--brand)",
        "components-pull-quote-border-decor": "var(--duck)",
        "components-promo-alternate-theme-button-one-theme": "var(--filled)",
        "components-promo-alternate-theme-button-two-theme": "var(--outlined)",
        "components-promo-alternate-color-default-button-theme": "var(--brand)",
        "components-promo-alternate-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-alternate-color-brand-button-theme": "var(--brand)",
        "components-share-list-button-color": "var(--brand)",
        "components-site-search-hero-search-hero-pattern": "var(--pledge)",
        "components-stats-decor-top": "var(--raid-1)",
        "components-stats-decor-bottom": "var(--raid-2)",
        "themes-brand-loader": "var(--default)",
        "themes-brand-variants": "var(--pledge)",
        "themes-tab-variant": "var(--default)",
        "themes-label-variants": "var(--default)",
        "themes-logo": "var(--pledge)",
        "themes-category-card": "var(--pledge)",
        "themes-title-decor": "var(--off)",
        "themes-typography-heading-fontfamily": "var(--gotham-ssm)",
        "themes-typography-heading-large-xxlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-xlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-large-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-medium-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-small-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-xsmall-fontweight": "var(--extrabold)",
        "themes-typography-heading-large-xxsmall-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-xxlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-xlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-large-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-medium-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-small-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-xsmall-fontweight": "var(--extrabold)",
        "themes-typography-heading-medium-xxsmall-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-xxlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-xlarge-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-large-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-medium-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-small-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-xsmall-fontweight": "var(--extrabold)",
        "themes-typography-heading-small-xxsmall-fontweight": "var(--extrabold)",
        "themes-typography-rte-fontfamily": "var(--gotham-ssm)",
        "themes-typography-rte-fontweight": "var(--extrabold)"
      },
      "borderRadius": {
        "components-accordion-spacing-large-item-item-radius": "0rem",
        "components-article-card-spacing-large-image-radius-tl": "1rem",
        "components-article-card-spacing-large-image-radius-tr": "1rem",
        "components-article-card-spacing-large-image-radius-bl": "1rem",
        "components-article-card-spacing-large-image-radius-br": "1rem",
        "components-article-card-spacing-large-list-image-radius-br": "1rem",
        "components-article-card-spacing-small-image-radius-br": "0rem",
        "components-article-card-spacing-small-list-image-radius-br": "0rem",
        "components-article-hero-spacing-large-content-radius": "1rem",
        "components-article-hero-spacing-small-content-radius": "1rem",
        "components-button-radius-large": "7.5rem",
        "components-button-group-spacing-border-radius": "7.5rem",
        "components-category-card-radius-border-width": "0.125rem",
        "components-category-card-radius-border-radius": "7.5rem",
        "components-category-card-radius-border-radius-condensed": "0.5rem",
        "components-category-card-radius-media-radius": "0rem",
        "components-content-list-icon-radius": "7.5rem",
        "components-fab-spacing-border-radius": "7.5rem",
        "components-header-spacing-large-search-border-radius-top": "0.25rem",
        "components-header-spacing-large-search-border-radius-bottom": "0.25rem",
        "components-header-spacing-large-language-selector-radius": "0.25rem",
        "components-header-spacing-large-dropdown-radius": "0rem",
        "components-hero-homepage-spacing-large-container-radius": "1rem",
        "components-hero-internal-spacing-large-icon-radius": "7.5rem",
        "components-hero-internal-spacing-small-icon-radius": "7.5rem",
        "components-lightbox-spacing-large-overlay-radius": "1rem",
        "components-pagination-scrollbar-radius": "62.5rem",
        "components-product-card-spacing-image-radius-tl": "0rem",
        "components-product-card-spacing-image-radius-tr": "0rem",
        "components-product-card-spacing-image-radius-bl": "0rem",
        "components-product-card-spacing-image-radius-br": "0rem",
        "components-product-information-spacing-large-content-radius": "1rem",
        "components-product-information-spacing-small-content-radius": "1rem",
        "components-promo-spacing-large-content-radius": "1rem",
        "components-promo-spacing-large-content-radius-tl": "1rem",
        "components-promo-spacing-large-content-radius-tr": "1rem",
        "components-promo-spacing-large-content-radius-bl": "1rem",
        "components-promo-spacing-large-content-radius-br": "1rem",
        "components-promo-spacing-large-image-radius-tl": "1rem",
        "components-promo-spacing-large-image-radius-tr": "1rem",
        "components-promo-spacing-large-image-radius-bl": "1rem",
        "components-promo-spacing-large-image-radius-br": "1rem",
        "components-promo-spacing-small-content-radius": "0.75rem",
        "components-promo-spacing-small-image-radius-tl": "0.75rem",
        "components-promo-spacing-small-image-radius-tr": "0.75rem",
        "components-promo-spacing-small-image-radius-bl": "0.75rem",
        "components-promo-spacing-small-image-radius-br": "0.75rem",
        "components-promo-alternate-spacing-large-content-radius": "1rem",
        "components-promo-alternate-spacing-large-image-radius-tl": "1rem",
        "components-promo-alternate-spacing-large-image-radius-tr": "1rem",
        "components-promo-alternate-spacing-large-image-radius-bl": "1rem",
        "components-promo-alternate-spacing-large-image-radius-br": "1rem",
        "components-promo-alternate-spacing-small-content-radius": "0.75rem",
        "components-promo-alternate-spacing-small-image-radius-tl": "0.75rem",
        "components-promo-alternate-spacing-small-image-radius-tr": "0.75rem",
        "components-promo-alternate-spacing-small-image-radius-bl": "0.75rem",
        "components-promo-alternate-spacing-small-image-radius-br": "0.75rem",
        "components-share-list-spacing-radius": "0.5rem",
        "components-share-list-spacing-link-radius": "0.5rem",
        "components-site-search-filter-radius": "0.5rem",
        "components-site-search-facet-radius": "0rem",
        "components-site-search-refine-radius": "0rem",
        "components-tag-spacing-radius": "0.25rem",
        "components-tip-spacing-radius": "0.75rem",
        "themes-spacing-radius-pagination-bar": "62.5rem",
        "themes-spacing-radius-large-button": "7.5rem",
        "themes-spacing-radius-large-button-icon": "7.5rem",
        "themes-spacing-radius-large-image": "1rem",
        "themes-spacing-radius-large-image-hero": "0rem",
        "themes-spacing-radius-large-image-card": "0.75rem",
        "themes-spacing-radius-large-card": "1rem",
        "themes-spacing-radius-large-icon": "7.5rem",
        "themes-spacing-radius-large-label": "7.5rem",
        "themes-spacing-radius-large-tag": "0.25rem",
        "themes-spacing-radius-large-container-tip": "0.75rem",
        "themes-spacing-radius-large-badge": "0.25rem",
        "themes-spacing-radius-large-item": "0.5rem",
        "themes-spacing-radius-large-form-field-input": "0.25rem",
        "themes-spacing-radius-large-form-area-input": "0.25rem",
        "themes-spacing-radius-large-general": "1rem",
        "themes-spacing-radius-small-button": "7.5rem",
        "themes-spacing-radius-small-button-icon": "7.5rem",
        "themes-spacing-radius-small-image": "0.75rem",
        "themes-spacing-radius-small-image-card": "0.75rem",
        "themes-spacing-radius-small-card": "0.75rem",
        "themes-spacing-radius-small-icon": "7.5rem",
        "themes-spacing-radius-small-label": "7.5rem",
        "themes-spacing-radius-small-tag": "7.5rem",
        "themes-spacing-radius-small-badge": "0rem",
        "themes-spacing-radius-small-item": "0rem",
        "themes-spacing-radius-small-form-field-input": "0.25rem",
        "themes-spacing-radius-small-general": "1rem"
      },
      "borderWidth": {
        "3": "3px",
        "components-article-card-spacing-large-border-width": "0.125rem",
        "components-generic-card-color-border-width": "0rem",
        "components-header-spacing-large-search-border-width-top": "0.125rem",
        "components-header-spacing-large-search-border-width-right": "0.125rem",
        "components-header-spacing-large-search-border-width-bottom": "0.125rem",
        "components-header-spacing-large-search-border-width-left": "0.125rem",
        "components-product-card-spacing-border-width": "0.0625rem"
      },
      "maxHeight": {},
      "maxWidth": {
        "components-article-hero-spacing-large-header-max-width": "66.375rem",
        "components-content-list-spacing-large-item-image-max-width": "33.75rem",
        "components-content-list-spacing-small-item-image-max-width": "33.75rem"
      },
      "minHeight": {
        "components-dropdown-item-min-height": "3rem",
        "components-icon-min-height": "3rem",
        "components-tab-item-min-height": "3rem",
        "components-text-field-input-min-height": "3rem",
        "components-text-field-icon-min-height": "3rem"
      },
      "minWidth": {
        "components-content-list-spacing-small-item-image-min-width": "18.75rem",
        "components-icon-min-width": "3rem",
        "components-text-field-icon-min-width": "3rem"
      },
      "opacity": {
        "components-accessibility-bar-color-bg": 1,
        "components-accessibility-bar-color-text": 1,
        "components-accordion-color-bg": 1,
        "components-accordion-color-title": 1,
        "components-accordion-color-body": 1,
        "components-accordion-color-section-section-bg": 1,
        "components-accordion-color-section-section-title": 1,
        "components-accordion-color-section-section-body": 1,
        "components-accordion-color-item-item-bg": 1,
        "components-accordion-color-item-divider": 1,
        "components-accordion-color-item-icon-icon-default": 1,
        "components-accordion-color-item-icon-icon-hover": 1,
        "components-accordion-color-item-icon-icon-pressed": 1,
        "components-accordion-color-item-icon-icon-active": 1,
        "components-accordion-color-item-title-item-default": 1,
        "components-accordion-color-item-title-item-hover": 1,
        "components-accordion-color-item-title-item-pressed": 1,
        "components-accordion-color-item-title-item-active": 1,
        "components-accordion-color-content-bg": 1,
        "components-accordion-color-content-title": 1,
        "components-accordion-color-content-body": 1,
        "components-article-card-color-bg": 1,
        "components-article-card-color-border": 0.02,
        "components-article-card-color-content-bg": 1,
        "components-article-card-color-title": 1,
        "components-article-card-color-subtitle": 1,
        "components-article-card-color-copy": 1,
        "components-article-card-color-description": 1,
        "components-article-card-listing-color-default-bg": 1,
        "components-article-card-listing-color-default-title": 1,
        "components-article-card-listing-color-default-copy": 1,
        "components-article-hero-color-content-bg": 1,
        "components-article-hero-color-bg": 1,
        "components-article-hero-color-headline": 1,
        "components-article-hero-color-body": 1,
        "components-article-hero-color-detail-text": 1,
        "components-article-hero-color-divider": 1,
        "components-article-hero-color-icon": 1,
        "components-article-hero-color-play-icon": 1,
        "components-article-hero-color-selected-stroke": 1,
        "components-banner-color-bg-default": 1,
        "components-banner-color-icon-default": 1,
        "components-banner-color-title-default": 1,
        "components-banner-color-body-default": 1,
        "components-banner-color-icon-close-default": 1,
        "components-banner-color-bg-alert": 1,
        "components-banner-color-icon-alert": 1,
        "components-banner-color-title-alert": 1,
        "components-banner-color-body-alert": 1,
        "components-banner-color-icon-close-alert": 1,
        "components-breadcrumb-color-bg": 1,
        "components-breadcrumb-color-breadcrumb-link-default": 1,
        "components-breadcrumb-color-breadcrumb-link-hover": 1,
        "components-breadcrumb-color-breadcrumb-link-active": 1,
        "components-breadcrumb-color-icon-divider": 1,
        "components-bug-finder-prompt": 1,
        "components-button-color-filled-brand-default-bg": 1,
        "components-button-color-filled-brand-default-text": 1,
        "components-button-color-filled-brand-default-stroke": 1,
        "components-button-color-filled-brand-default-icon": 1,
        "components-button-color-filled-brand-hover-bg": 1,
        "components-button-color-filled-brand-hover-text": 1,
        "components-button-color-filled-brand-hover-stroke": 1,
        "components-button-color-filled-brand-hover-icon": 1,
        "components-button-color-filled-brand-pressed-bg": 1,
        "components-button-color-filled-brand-pressed-text": 1,
        "components-button-color-filled-brand-pressed-stroke": 1,
        "components-button-color-filled-brand-pressed-icon": 1,
        "components-button-color-filled-brand-disabled-bg": null,
        "components-button-color-filled-brand-disabled-text": 0.02,
        "components-button-color-filled-brand-disabled-stroke": null,
        "components-button-color-filled-brand-disabled-icon": 0.02,
        "components-button-color-filled-brand-focus-bg": 1,
        "components-button-color-filled-brand-focus-text": 1,
        "components-button-color-filled-brand-focus-stroke": 1,
        "components-button-color-filled-brand-focus-icon": 1,
        "components-button-color-filled-brand-focus-outline": 1,
        "components-button-color-filled-tonal-default-bg": 1,
        "components-button-color-filled-tonal-default-text": 1,
        "components-button-color-filled-tonal-default-stroke": 1,
        "components-button-color-filled-tonal-default-icon": 1,
        "components-button-color-filled-tonal-hover-bg": 1,
        "components-button-color-filled-tonal-hover-text": 1,
        "components-button-color-filled-tonal-hover-stroke": 1,
        "components-button-color-filled-tonal-hover-icon": 1,
        "components-button-color-filled-tonal-pressed-bg": 1,
        "components-button-color-filled-tonal-pressed-text": 1,
        "components-button-color-filled-tonal-pressed-stroke": 1,
        "components-button-color-filled-tonal-pressed-icon": 1,
        "components-button-color-filled-tonal-disabled-bg": 0,
        "components-button-color-filled-tonal-disabled-text": 1,
        "components-button-color-filled-tonal-disabled-stroke": 0,
        "components-button-color-filled-tonal-disabled-icon": 1,
        "components-button-color-filled-tonal-focus-bg": 1,
        "components-button-color-filled-tonal-focus-text": 1,
        "components-button-color-filled-tonal-focus-stroke": 1,
        "components-button-color-filled-tonal-focus-icon": 1,
        "components-button-color-filled-tonal-focus-outline": 1,
        "components-button-color-filled-white-default-bg": 1,
        "components-button-color-filled-white-default-text": 1,
        "components-button-color-filled-white-default-stroke": 1,
        "components-button-color-filled-white-default-icon": 1,
        "components-button-color-filled-white-hover-bg": 1,
        "components-button-color-filled-white-hover-text": 1,
        "components-button-color-filled-white-hover-stroke": 1,
        "components-button-color-filled-white-hover-icon": 1,
        "components-button-color-filled-white-pressed-bg": 1,
        "components-button-color-filled-white-pressed-text": 1,
        "components-button-color-filled-white-pressed-stroke": 1,
        "components-button-color-filled-white-pressed-icon": 1,
        "components-button-color-filled-white-disabled-bg": 0.02,
        "components-button-color-filled-white-disabled-text": null,
        "components-button-color-filled-white-disabled-stroke": 0.02,
        "components-button-color-filled-white-disabled-icon": 0.02,
        "components-button-color-filled-white-focus-bg": 1,
        "components-button-color-filled-white-focus-text": 1,
        "components-button-color-filled-white-focus-stroke": 1,
        "components-button-color-filled-white-focus-icon": 1,
        "components-button-color-filled-white-focus-outline": 1,
        "components-button-color-outline-brand-default-bg": 0.02,
        "components-button-color-outline-brand-default-text": 1,
        "components-button-color-outline-brand-default-stroke": 1,
        "components-button-color-outline-brand-default-icon": 1,
        "components-button-color-outline-brand-hover-bg": 1,
        "components-button-color-outline-brand-hover-text": 1,
        "components-button-color-outline-brand-hover-stroke": 1,
        "components-button-color-outline-brand-hover-icon": 1,
        "components-button-color-outline-brand-pressed-bg": 1,
        "components-button-color-outline-brand-pressed-text": 1,
        "components-button-color-outline-brand-pressed-stroke": 1,
        "components-button-color-outline-brand-pressed-icon": 1,
        "components-button-color-outline-brand-disabled-bg": 0.02,
        "components-button-color-outline-brand-disabled-text": null,
        "components-button-color-outline-brand-disabled-stroke": null,
        "components-button-color-outline-brand-disabled-icon": null,
        "components-button-color-outline-brand-focus-bg": 1,
        "components-button-color-outline-brand-focus-text": 1,
        "components-button-color-outline-brand-focus-stroke": 1,
        "components-button-color-outline-brand-focus-icon": 1,
        "components-button-color-outline-brand-focus-outline": 1,
        "components-button-color-outline-tonal-default-bg": 0.02,
        "components-button-color-outline-tonal-default-text": 1,
        "components-button-color-outline-tonal-default-stroke": 1,
        "components-button-color-outline-tonal-default-icon": 1,
        "components-button-color-outline-tonal-hover-bg": 1,
        "components-button-color-outline-tonal-hover-text": 1,
        "components-button-color-outline-tonal-hover-stroke": 1,
        "components-button-color-outline-tonal-hover-icon": 1,
        "components-button-color-outline-tonal-pressed-bg": 1,
        "components-button-color-outline-tonal-pressed-text": 1,
        "components-button-color-outline-tonal-pressed-stroke": 1,
        "components-button-color-outline-tonal-pressed-icon": 1,
        "components-button-color-outline-tonal-disabled-bg": 0.02,
        "components-button-color-outline-tonal-disabled-text": null,
        "components-button-color-outline-tonal-disabled-stroke": null,
        "components-button-color-outline-tonal-disabled-icon": null,
        "components-button-color-outline-tonal-focus-bg": 0.02,
        "components-button-color-outline-tonal-focus-text": 1,
        "components-button-color-outline-tonal-focus-stroke": 1,
        "components-button-color-outline-tonal-focus-icon": 1,
        "components-button-color-outline-tonal-focus-outline": 1,
        "components-button-color-outline-white-default-bg": 0.02,
        "components-button-color-outline-white-default-text": 1,
        "components-button-color-outline-white-default-stroke": 1,
        "components-button-color-outline-white-default-icon": 1,
        "components-button-color-outline-white-hover-bg": 1,
        "components-button-color-outline-white-hover-text": 1,
        "components-button-color-outline-white-hover-stroke": 1,
        "components-button-color-outline-white-hover-icon": 1,
        "components-button-color-outline-white-pressed-bg": 1,
        "components-button-color-outline-white-pressed-text": 1,
        "components-button-color-outline-white-pressed-stroke": 1,
        "components-button-color-outline-white-pressed-icon": 1,
        "components-button-color-outline-white-disabled-bg": 0.02,
        "components-button-color-outline-white-disabled-text": 0.02,
        "components-button-color-outline-white-disabled-stroke": 0.02,
        "components-button-color-outline-white-disabled-icon": 0.02,
        "components-button-color-outline-white-focus-bg": 0.02,
        "components-button-color-outline-white-focus-text": 1,
        "components-button-color-outline-white-focus-stroke": 1,
        "components-button-color-outline-white-focus-icon": 1,
        "components-button-color-outline-white-focus-outline": 1,
        "components-button-color-text-brand-default-bg": 0.02,
        "components-button-color-text-brand-default-text": 1,
        "components-button-color-text-brand-default-stroke": 1,
        "components-button-color-text-brand-default-icon": 1,
        "components-button-color-text-brand-hover-bg": 0.02,
        "components-button-color-text-brand-hover-bg-icon-only": 1,
        "components-button-color-text-brand-hover-text": 1,
        "components-button-color-text-brand-hover-stroke": 1,
        "components-button-color-text-brand-hover-icon": 1,
        "components-button-color-text-brand-pressed-bg": 0.02,
        "components-button-color-text-brand-pressed-bg-icon-only": 1,
        "components-button-color-text-brand-pressed-text": 1,
        "components-button-color-text-brand-pressed-stroke": 1,
        "components-button-color-text-brand-pressed-icon": 1,
        "components-button-color-text-brand-disabled-bg": 0.02,
        "components-button-color-text-brand-disabled-text": null,
        "components-button-color-text-brand-disabled-stroke": null,
        "components-button-color-text-brand-disabled-icon": null,
        "components-button-color-text-brand-focus-bg": 1,
        "components-button-color-text-brand-focus-text": 1,
        "components-button-color-text-brand-focus-stroke": 1,
        "components-button-color-text-brand-focus-icon": 1,
        "components-button-color-text-brand-focus-outline": 1,
        "components-button-color-text-tonal-default-bg": 0.02,
        "components-button-color-text-tonal-default-text": 1,
        "components-button-color-text-tonal-default-stroke": 1,
        "components-button-color-text-tonal-default-icon": 1,
        "components-button-color-text-tonal-hover-bg": 0.02,
        "components-button-color-text-tonal-hover-bg-icon-only": 1,
        "components-button-color-text-tonal-hover-text": 1,
        "components-button-color-text-tonal-hover-stroke": 1,
        "components-button-color-text-tonal-hover-icon": 1,
        "components-button-color-text-tonal-pressed-bg": 0.02,
        "components-button-color-text-tonal-pressed-bg-icon-only": 1,
        "components-button-color-text-tonal-pressed-text": 1,
        "components-button-color-text-tonal-pressed-stroke": 1,
        "components-button-color-text-tonal-pressed-icon": 1,
        "components-button-color-text-tonal-disabled-bg": 0.02,
        "components-button-color-text-tonal-disabled-text": null,
        "components-button-color-text-tonal-disabled-stroke": null,
        "components-button-color-text-tonal-disabled-icon": null,
        "components-button-color-text-tonal-focus-bg": 1,
        "components-button-color-text-tonal-focus-text": 1,
        "components-button-color-text-tonal-focus-stroke": 1,
        "components-button-color-text-tonal-focus-icon": 1,
        "components-button-color-text-tonal-focus-outline": 1,
        "components-button-color-text-white-default-bg": 0.02,
        "components-button-color-text-white-default-text": 1,
        "components-button-color-text-white-default-stroke": 1,
        "components-button-color-text-white-default-icon": 1,
        "components-button-color-text-white-hover-bg": 0.02,
        "components-button-color-text-white-hover-bg-icon-only": 1,
        "components-button-color-text-white-hover-text": 1,
        "components-button-color-text-white-hover-stroke": 1,
        "components-button-color-text-white-hover-icon": 1,
        "components-button-color-text-white-pressed-bg": 0.02,
        "components-button-color-text-white-pressed-bg-icon-only": 1,
        "components-button-color-text-white-pressed-text": 1,
        "components-button-color-text-white-pressed-stroke": 1,
        "components-button-color-text-white-pressed-icon": 1,
        "components-button-color-text-white-disabled-bg": 0.02,
        "components-button-color-text-white-disabled-text": 0.01,
        "components-button-color-text-white-disabled-stroke": 0.01,
        "components-button-color-text-white-disabled-icon": 0.01,
        "components-button-color-text-white-focus-bg": 1,
        "components-button-color-text-white-focus-text": 1,
        "components-button-color-text-white-focus-stroke": 1,
        "components-button-color-text-white-focus-icon": 1,
        "components-button-color-text-white-focus-outline": 1,
        "components-button-group-color-border": 1,
        "components-button-group-color-button-bg-default": 0.02,
        "components-button-group-color-button-fg-default": 1,
        "components-button-group-color-button-bg-hover": null,
        "components-button-group-color-button-fg-hover": 1,
        "components-button-group-color-button-bg-active": 1,
        "components-button-group-color-button-fg-active": 1,
        "components-button-group-color-overlay-left-gradient-fill-1": 1,
        "components-button-group-color-overlay-left-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-1": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-3": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-4": 0.02,
        "components-button-group-color-stroke-hover-gradient-fill-1": null,
        "components-button-group-color-stroke-hover-gradient-fill-2": null,
        "components-button-group-color-stroke-hover-gradient-fill-3": null,
        "components-button-group-color-stroke-hover-gradient-fill-4": null,
        "components-button-group-color-stroke-active-gradient-fill-1": 1,
        "components-button-group-color-stroke-active-gradient-fill-2": 1,
        "components-button-group-color-stroke-active-gradient-fill-3": 1,
        "components-button-group-color-stroke-active-gradient-fill-4": 1,
        "components-category-card-color-bg": 1,
        "components-category-card-color-content-bg": 1,
        "components-category-card-color-category-bg": 1,
        "components-category-card-color-category-bg-2": 1,
        "components-category-card-color-category-bg-3": 1,
        "components-category-card-color-category-bg-4": 1,
        "components-category-card-color-category-bg-5": 1,
        "components-category-card-color-category-bg-6": 1,
        "components-category-card-color-title": 1,
        "components-category-card-color-copy": 1,
        "components-category-card-color-stroke": 1,
        "components-category-card-color-stroke-hover": 1,
        "components-category-card-listing-color-bg": 1,
        "components-category-card-listing-color-title": 1,
        "components-category-card-listing-color-copy": 1,
        "components-card-search-result-color-bg": 1,
        "components-card-search-result-color-label": 1,
        "components-card-search-result-color-title": 1,
        "components-card-search-result-color-body": 1,
        "components-card-search-result-color-divider-line": 1,
        "components-contact-us-promo-color-light-bg": 1,
        "components-contact-us-promo-color-light-title": 1,
        "components-contact-us-promo-color-light-body": 1,
        "components-contact-us-promo-color-default-bg": 1,
        "components-contact-us-promo-color-default-title": 1,
        "components-contact-us-promo-color-default-body": 1,
        "components-contact-us-promo-color-brand-bg": 1,
        "components-contact-us-promo-color-brand-title": 1,
        "components-contact-us-promo-color-brand-body": 1,
        "components-content-list-color-bg": 1,
        "components-content-list-color-title": 1,
        "components-content-list-color-body": 1,
        "components-content-list-color-icon": 1,
        "components-cookies-colors-bg": 1,
        "components-cookies-colors-title": 1,
        "components-cookies-colors-body": 1,
        "components-cookies-colors-icon": 1,
        "components-dropdown-bg": 1,
        "components-dropdown-border-default": 1,
        "components-dropdown-border-hover": 1,
        "components-dropdown-border-focused": 1,
        "components-dropdown-border-destructive": 1,
        "components-dropdown-label-text-default": 1,
        "components-dropdown-item-text-default": 1,
        "components-dropdown-item-bg-default": 1,
        "components-dropdown-item-bg-hover": 1,
        "components-dropdown-input-field-default": 1,
        "components-dropdown-input-field-focused": 1,
        "components-dropdown-input-label-focused": 1,
        "components-dropdown-input-field-destructive": 1,
        "components-external-link-card-bg": 1,
        "components-external-link-card-card-bg": 1,
        "components-external-link-card-card-title": 1,
        "components-external-link-card-card-subtitle": 1,
        "components-external-link-card-card-eyebrow": 1,
        "components-fab-color-border-default": 1,
        "components-footer-brand-color-bg": 1,
        "components-footer-brand-color-logo": 1,
        "components-footer-brand-color-title-text": 1,
        "components-footer-brand-color-description-text": 1,
        "components-footer-brand-color-footer-menu-expanded": 1,
        "components-footer-brand-color-link-default": 1,
        "components-footer-brand-color-link-hover": 1,
        "components-footer-brand-color-link-pressed": 1,
        "components-footer-brand-color-category-link-default": 1,
        "components-footer-brand-color-category-link-hover": 1,
        "components-footer-brand-color-category-link-pressed": 1,
        "components-footer-brand-color-subcategory-link-default": 1,
        "components-footer-brand-color-subcategory-link-hover": 1,
        "components-footer-brand-color-subcategory-link-pressed": 1,
        "components-footer-brand-color-icon-icon-default": 1,
        "components-footer-brand-color-icon-icon-pressed": 1,
        "components-footer-brand-color-social-icon-default": 1,
        "components-footer-brand-color-social-icon-hover": 1,
        "components-footer-brand-color-social-icon-pressed": 1,
        "components-footer-brand-spacing-category-link-hover": 1,
        "components-footer-global-color-bg": 1,
        "components-footer-global-color-title-text": 1,
        "components-footer-global-color-disclaimer-text": 1,
        "components-footer-global-color-link-default": 1,
        "components-footer-global-color-link-hover": 1,
        "components-footer-global-color-link-pressed": 1,
        "components-generic-card-color-bg": 1,
        "components-generic-card-color-content-bg": 1,
        "components-generic-card-color-title": 1,
        "components-generic-card-color-subtitle": 1,
        "components-generic-card-color-copy": 1,
        "components-global-search-color-omnibox-list-bg": 1,
        "components-global-search-color-omnibox-divider": 1,
        "components-global-search-color-omnibox-item-bg-default": 1,
        "components-global-search-color-omnibox-item-bg-hover": 1,
        "components-global-search-color-omnibox-item-text-default": 1,
        "components-global-search-color-omnibox-item-text-hover": 1,
        "components-global-search-color-omnibox-label-text-default": 1,
        "components-global-search-color-omnibox-category-text-default": 1,
        "components-generic-card-listing-color-bg": 1,
        "components-generic-card-listing-color-title": 1,
        "components-generic-card-listing-color-copy": 1,
        "components-header-color-bg": 1,
        "components-header-color-bottom-stroke": 1,
        "components-header-color-search-search-border-default": 1,
        "components-header-color-search-search-text": 1,
        "components-header-color-search-search-icon": 1,
        "components-header-color-search-search-bar": 1,
        "components-header-color-mobile-mobile-expanded-bg": 1,
        "components-header-color-mobile-pressed-bg": 1,
        "components-header-color-language-selector-language": 1,
        "components-header-color-language-selector-country": 1,
        "components-header-color-language-selector-country-hover-bg": 0.02,
        "components-header-color-language-selector-megamenu-language": 1,
        "components-header-color-language-selector-megamenu-country": 1,
        "components-header-color-language-selector-megamenu-country-hover": 1,
        "components-header-color-language-selector-bg": 1,
        "components-header-color-language-selector-stroke": 1,
        "components-header-color-mega-menu-bg": 1,
        "components-header-color-mega-menu-featured-bg": 1,
        "components-header-color-mega-menu-featured-title": 1,
        "components-header-color-mega-menu-category-title": 1,
        "components-header-color-mega-menu-bg-accent": 1,
        "components-header-color-icon-icon-default": 1,
        "components-header-color-icon-icon-hover": 1,
        "components-header-color-icon-icon-pressed": 1,
        "components-header-color-links-nav-main-link-default": 1,
        "components-header-color-links-nav-main-link-hover": 1,
        "components-header-color-links-nav-main-link-pressed": 1,
        "components-header-color-links-nav-main-link-active": 1,
        "components-header-color-links-nav-main-active-bar": 1,
        "components-header-color-links-nav-main-bg-color": 1,
        "components-header-color-links-nav-category-link-category-default": 1,
        "components-header-color-links-nav-category-link-category-hover": 1,
        "components-header-color-links-nav-category-link-category-pressed": 1,
        "components-header-color-links-nav-category-link-category-active": 1,
        "components-header-color-links-nav-menu-link-link-default": 1,
        "components-header-color-links-nav-menu-link-link-hover": 1,
        "components-header-color-links-nav-menu-link-link-pressed": 1,
        "components-header-color-links-nav-menu-link-link-active": 1,
        "components-header-color-links-nav-menu-link-bg": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-default": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-active": 1,
        "components-hero-homepage-color-bg": 1,
        "components-hero-homepage-color-container-bg": 1,
        "components-hero-homepage-color-eyebrow": 1,
        "components-hero-homepage-color-header": 1,
        "components-hero-homepage-color-sub-header": 1,
        "components-hero-homepage-color-body": 1,
        "components-hero-homepage-color-play-pause": 1,
        "components-hero-internal-color-default-title": 1,
        "components-hero-internal-color-default-body": 1,
        "components-hero-internal-color-default-bg": 1,
        "components-hero-internal-color-accent-bg": 1,
        "components-hero-internal-color-with-image-bg": 1,
        "components-hero-internal-color-default-bg-2": 1,
        "components-hero-internal-color-with-image-title": 1,
        "components-hero-internal-color-with-image-body": 1,
        "components-heading-page-title-color-default-bg": 1,
        "components-heading-page-title-color-default-title": 1,
        "components-heading-page-title-color-default-body": 1,
        "components-heading-page-title-color-light-bg": 1,
        "components-heading-page-title-color-light-title": 1,
        "components-heading-page-title-color-light-body": 1,
        "components-heading-page-title-color-color-bg": 1,
        "components-heading-page-title-color-color-title": 1,
        "components-heading-page-title-color-color-body": 1,
        "components-hyperlink-color-dark-default": 1,
        "components-hyperlink-color-dark-hover": 1,
        "components-hyperlink-color-dark-visited": 1,
        "components-hyperlink-color-light-default": 1,
        "components-hyperlink-color-light-hover": 1,
        "components-hyperlink-color-light-visited": 1,
        "components-icon-default": 1,
        "components-icon-focused": 1,
        "components-icon-card-color-bg": 1,
        "components-icon-card-color-icon-bg": 1,
        "components-icon-card-color-icon": 1,
        "components-icon-card-color-title": 1,
        "components-icon-card-color-subtitle": 1,
        "components-icon-card-color-description": 1,
        "components-icon-card-listing-color-bg": 1,
        "components-icon-card-listing-color-inner-bg": 1,
        "components-icon-card-listing-color-brand-bg": 1,
        "components-icon-card-listing-color-title": 1,
        "components-icon-card-listing-color-copy": 1,
        "components-icon-card-listing-color-inner-legal-text": 1,
        "components-jumplink-color-bg-default": 1,
        "components-jumplink-color-link-text-default": 1,
        "components-jumplink-color-link-text-hover": 1,
        "components-jumplink-color-link-border-hover": 1,
        "components-jumplink-color-link-text-active": 1,
        "components-jumplink-color-link-border-active": 1,
        "components-jumplink-color-title-text": 1,
        "components-jumplink-color-description-text": 1,
        "components-layout-bg-default": 1,
        "components-lightbox-color-overlay-bg": 0.02,
        "components-lightbox-color-title": 1,
        "components-lightbox-color-body": 1,
        "components-label-color-on-color-label-label-default": 1,
        "components-label-color-on-color-label-label-hover": 1,
        "components-label-color-on-color-label-label-pressed": 1,
        "components-label-color-on-color-background-bg-default": 0.02,
        "components-label-color-on-color-background-bg-hover": 1,
        "components-label-color-on-color-background-bg-pressed": 1,
        "components-label-color-on-color-stroke-hover": 0.02,
        "components-label-color-on-color-stroke-pressed": 0.02,
        "components-label-color-on-color-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-4": 1,
        "components-label-color-on-white-label-label-default": 1,
        "components-label-color-on-white-label-label-hover": 1,
        "components-label-color-on-white-label-label-pressed": 1,
        "components-label-color-on-white-background-bg-default": 0.02,
        "components-label-color-on-white-background-bg-hover": 1,
        "components-label-color-on-white-background-bg-pressed": 1,
        "components-label-color-on-white-stroke-hover": 1,
        "components-label-color-on-white-stroke-pressed": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-4": 1,
        "components-legal-disclaimer-color-default-bg": 1,
        "components-legal-disclaimer-color-default-title": 1,
        "components-legal-disclaimer-color-default-body": 1,
        "components-legal-disclaimer-color-light-bg": 1,
        "components-legal-disclaimer-color-light-title": 1,
        "components-legal-disclaimer-color-light-body": 1,
        "components-legal-disclaimer-color-brand-bg": 1,
        "components-legal-disclaimer-color-brand-title": 1,
        "components-legal-disclaimer-color-brand-body": 1,
        "components-media-video-thumbnail-overlay-bg": 0.02,
        "components-media-caption-color-bg": 1,
        "components-media-caption-color-text": 1,
        "components-media-gallery-color-bg": 1,
        "components-media-gallery-color-title": 1,
        "components-media-gallery-color-body": 1,
        "components-pagination-on-white-accent-default": 1,
        "components-pagination-on-white-accent-scroll": 1,
        "components-pagination-on-white-disabled": 1,
        "components-pagination-on-white-bg": 1,
        "components-pagination-on-white-stroke": 1,
        "components-pagination-on-white-bg-active": 1,
        "components-pagination-on-white-bg-hover": 1,
        "components-pagination-on-white-text-default": 1,
        "components-pagination-on-white-text-hover": 1,
        "components-pagination-on-white-text-active": 1,
        "components-pagination-on-color-accent-default": 1,
        "components-pagination-on-color-accent-scroll": 1,
        "components-pagination-on-color-disabled": 1,
        "components-pagination-on-color-bg": 0.02,
        "components-pagination-on-color-stroke": 0.02,
        "components-pagination-on-color-bg-active": 1,
        "components-pagination-on-color-bg-hover": 1,
        "components-pagination-on-color-text-default": 1,
        "components-pagination-on-color-text-hover": 1,
        "components-pagination-on-color-text-active": 1,
        "components-pdf-link-card-color-bg": 1,
        "components-pdf-link-card-color-card-bg": 1,
        "components-pdf-link-card-color-card-title": 1,
        "components-pdf-link-card-color-card-subtitle": 1,
        "components-pdf-link-card-color-card-body": 1,
        "components-product-card-color-bg": 1,
        "components-product-card-color-content-bg": 1,
        "components-product-card-color-border": 1,
        "components-product-card-color-title": 1,
        "components-product-card-color-description": 1,
        "components-product-card-color-star-rating": 1,
        "components-product-card-color-badge-bg": 1,
        "components-product-card-color-badge-text": 1,
        "components-product-card-listing-color-bg": 1,
        "components-product-card-listing-color-title": 1,
        "components-product-card-listing-color-copy": 1,
        "components-product-card-listing-color-legal-text": 1,
        "components-product-information-color-bg": 1,
        "components-product-information-color-content-bg": 1,
        "components-product-information-color-headline": 1,
        "components-product-information-color-body": 1,
        "components-product-information-color-detail-text": 1,
        "components-product-information-color-divider": 1,
        "components-product-information-color-icon": 1,
        "components-product-information-color-play-icon": 1,
        "components-product-information-color-selected-stroke": 1,
        "components-product-results-list-with-search-color-bg": 1,
        "components-promo-color-default-bg": 1,
        "components-promo-color-default-image-bg": 1,
        "components-promo-color-default-content-bg": 1,
        "components-promo-color-default-title": 1,
        "components-promo-color-default-body": 1,
        "components-promo-color-default-category": 1,
        "components-promo-color-light-bg": 1,
        "components-promo-color-light-image-bg": 1,
        "components-promo-color-light-content-bg": 1,
        "components-promo-color-light-title": 1,
        "components-promo-color-light-body": 1,
        "components-promo-color-light-category": 1,
        "components-promo-color-brand-bg": 1,
        "components-promo-color-brand-image-bg": 1,
        "components-promo-color-brand-content-bg": 1,
        "components-promo-color-brand-title": 1,
        "components-promo-color-brand-body": 1,
        "components-promo-color-brand-category": 1,
        "components-pull-quote-color-bg": 1,
        "components-pull-quote-color-stroke": 1,
        "components-pull-quote-color-quote-bg": 1,
        "components-pull-quote-color-quote-title": 1,
        "components-pull-quote-color-quote-by-text": 1,
        "components-pull-quote-color-quote-detail": 1,
        "components-pull-quote-color-quote-highlight": 1,
        "components-promo-alternate-color-default-bg": 1,
        "components-promo-alternate-color-default-image-bg": 1,
        "components-promo-alternate-color-default-content-bg": 1,
        "components-promo-alternate-color-default-title": 1,
        "components-promo-alternate-color-default-body": 1,
        "components-promo-alternate-color-default-category": 1,
        "components-promo-alternate-color-light-bg": 1,
        "components-promo-alternate-color-light-image-bg": 1,
        "components-promo-alternate-color-light-content-bg": 1,
        "components-promo-alternate-color-light-title": 1,
        "components-promo-alternate-color-light-body": 1,
        "components-promo-alternate-color-light-category": 1,
        "components-promo-alternate-color-brand-bg": 1,
        "components-promo-alternate-color-brand-image-bg": 1,
        "components-promo-alternate-color-brand-content-bg": 1,
        "components-promo-alternate-color-brand-title": 1,
        "components-promo-alternate-color-brand-body": 1,
        "components-promo-alternate-color-brand-category": 1,
        "components-share-list-color-title": 1,
        "components-share-list-color-bg": 1,
        "components-share-list-color-link-default": 1,
        "components-share-list-color-link-hover": 1,
        "components-share-list-color-bg-link-hover": 1,
        "components-share-list-color-close-icon": 1,
        "components-share-list-color-facebook-icon": 1,
        "components-share-list-color-pinterest-icon": 1,
        "components-share-list-color-twitter-icon": 1,
        "components-share-list-color-basic-icon-default": 1,
        "components-share-list-color-icon-hover": 1,
        "components-share-list-color-border": 1,
        "components-site-search-bg": 1,
        "components-site-search-results_text": 1,
        "components-site-search-filter-filter-selected-bg": 1,
        "components-site-search-facet-group-label-bottom-line": 1,
        "components-site-search-facet-group-label-text": 1,
        "components-site-search-facet-group-label-bg": 1,
        "components-site-search-facet-bg": 1,
        "components-site-search-facet-stroke": 1,
        "components-site-search-refine-bg": 1,
        "components-site-search-refine-right-divider": 1,
        "components-site-search-refine-title": 1,
        "components-site-search-hero-search-bg": 1,
        "components-site-search-hero-search-accent-bg": 1,
        "components-site-search-hero-search-title": 1,
        "components-stats-stat-text": 1,
        "components-stats-stat-text-on-light": 1,
        "components-stats-supporting-text": 1,
        "components-stats-bg-default": 1,
        "components-stats-bg-inner": 1,
        "components-stats-bg-section-inner": 1,
        "components-stats-title": 1,
        "components-stats-copy": 1,
        "components-supporting-text-default": 1,
        "components-supporting-text-destructive": 1,
        "components-tab-bg": 1,
        "components-tab-border-default": 1,
        "components-tab-border-hover": 1,
        "components-tab-border-focused": 1,
        "components-tab-border-destructive": 1,
        "components-tab-label-text-default": 1,
        "components-tab-item-text-default": 1,
        "components-tab-item-bg-default": 1,
        "components-tab-item-bg-hover": 1,
        "components-tab-input-field-default": 1,
        "components-tab-input-field-focused": 1,
        "components-tab-input-label-focused": 1,
        "components-tab-input-field-destructive": 1,
        "components-tab-bg-2": 1,
        "components-tag-color-bg": 0.02,
        "components-tag-color-text": 1,
        "components-tag-color-stroke": 1,
        "components-tag-color-bg-hover": 1,
        "components-tag-color-text-hover": 1,
        "components-tag-color-stroke-hover": 1,
        "components-text-field-bg": 1,
        "components-text-field-bg-focused": 1,
        "components-text-field-border-default": 1,
        "components-text-field-border-hover": 1,
        "components-text-field-border-focused": 1,
        "components-text-field-border-destructive": 1,
        "components-text-field-input-field-default": 1,
        "components-text-field-input-field-focused": 1,
        "components-text-field-input-label-focused": 1,
        "components-text-field-input-field-destructive": 1,
        "components-text-field-icon-default": 1,
        "components-text-field-icon-destructive": 1,
        "components-text-field-supporting-text-default": 1,
        "components-text-field-supporting-text-destructive": 1,
        "components-tip-color-bg": 1,
        "components-tip-color-title": 1,
        "components-tip-color-body": 1,
        "components-tip-color-stroke": 1,
        "components-video-color-bg": 1,
        "themes-general-surface-color-light-bg": 1,
        "themes-general-surface-color-light-title": 1,
        "themes-general-surface-color-light-body": 1,
        "themes-general-surface-color-light-decor-line-break": 1,
        "themes-general-surface-color-light-rule-line": 1,
        "themes-general-surface-color-dark-bg": 1,
        "themes-general-surface-color-dark-title": 1,
        "themes-general-surface-color-dark-body": 1,
        "themes-general-surface-color-dark-decor-line-break": 1,
        "themes-general-card-color-dark-title": 1,
        "themes-general-card-color-dark-subtitle": 1,
        "themes-general-card-color-dark-body": 1,
        "themes-general-card-color-light-title": 1,
        "themes-general-card-color-light-subtitle": 1,
        "themes-general-card-color-light-body": 1,
        "themes-shadow-color": 1,
        "themes-shadow-share-list-color-fill": 0,
        "themes-shadow-header-menu-color-fill": 0,
        "themes-gradient-loader-gradient-fill-1": 1,
        "themes-gradient-loader-gradient-fill-2": null,
        "themes-color-background-white": 1,
        "themes-color-background-light": 1,
        "themes-color-background-dark": 1,
        "themes-color-background-black": 1,
        "themes-color-background-brand1": 1,
        "themes-color-background-brand2": 1,
        "themes-color-background-accent1": 1,
        "themes-color-background-accent2": 1,
        "themes-color-background-body": 1,
        "themes-color-stroke-white": 1,
        "themes-color-stroke-light": 1,
        "themes-color-stroke-dark": 1,
        "themes-color-stroke-black": 1,
        "themes-color-stroke-brand1": 1,
        "themes-color-stroke-brand2": 1,
        "themes-color-stroke-accent1": 1,
        "themes-color-stroke-accent2": 1,
        "themes-color-text-white": 1,
        "themes-color-text-light": 1,
        "themes-color-text-dark": 1,
        "themes-color-text-black": 1,
        "themes-color-text-brand1": 1,
        "themes-color-text-brand2": 1,
        "themes-color-text-accent1": 1,
        "themes-color-text-accent2": 1,
        "colors-grayscale-100": 1,
        "colors-grayscale-200": 1,
        "colors-grayscale-300": 1,
        "colors-grayscale-400": 1,
        "colors-grayscale-500": 1,
        "colors-grayscale-600": 1,
        "colors-grayscale-700": 1,
        "colors-brand-1-100": 1,
        "colors-brand-1-200": 1,
        "colors-brand-1-300": 1,
        "colors-brand-1-400": 1,
        "colors-brand-1-500": 1,
        "colors-brand-1-600": 1,
        "colors-brand-1-700": 1,
        "colors-brand-2-100": 1,
        "colors-brand-2-200": 1,
        "colors-brand-2-300": 1,
        "colors-brand-2-400": 1,
        "colors-brand-2-500": 1,
        "colors-brand-2-600": 1,
        "colors-brand-2-700": 1,
        "colors-accent-1-100": 1,
        "colors-accent-1-200": 1,
        "colors-accent-1-300": 1,
        "colors-accent-1-400": 1,
        "colors-accent-1-500": 1,
        "colors-accent-1-600": 1,
        "colors-accent-1-700": 1,
        "colors-accent-2-100": 1,
        "colors-accent-2-200": 1,
        "colors-accent-2-300": 1,
        "colors-accent-2-400": 1,
        "colors-accent-2-500": 1,
        "colors-accent-2-600": 1,
        "colors-accent-2-700": 1,
        "colors-accent-3-100": 1,
        "colors-accent-3-200": 1,
        "colors-accent-3-300": 1,
        "colors-accent-3-400": 1,
        "colors-accent-3-500": 1,
        "colors-accent-3-600": 1,
        "colors-accent-3-700": 1,
        "colors-accent-4-400_storage": 1,
        "colors-accessible-accent-1-100": 1,
        "colors-accessible-accent-1-200": 1,
        "colors-accessible-accent-1-300": 1,
        "colors-accessible-accent-1-400": 1,
        "colors-accessible-accent-1-500": 1,
        "colors-accessible-accent-1-600": 1,
        "colors-accessible-accent-1-700": 1,
        "colors-accessible-accent-2-100": 1,
        "colors-accessible-accent-2-200": 1,
        "colors-accessible-accent-2-300": 1,
        "colors-accessible-accent-2-400": 1,
        "colors-accessible-accent-2-500": 1,
        "colors-accessible-accent-2-600": 1,
        "colors-accessible-accent-2-700": 1,
        "colors-accessible-brand-2-100": 1,
        "colors-accessible-brand-2-200": 1,
        "colors-accessible-brand-2-300": 1,
        "colors-accessible-brand-2-400": 1,
        "colors-accessible-brand-2-500": 1,
        "colors-accessible-brand-2-600": 1,
        "colors-accessible-brand-2-700": 1
      }
    },
    "Pyrel": {
      "colors": {
        "components-accessibility-bar-color-bg": "#213117",
        "components-accessibility-bar-color-text": "#f3d54f",
        "components-accordion-color-bg": "#ffffff",
        "components-accordion-color-title": "#213117",
        "components-accordion-color-body": "#27282b",
        "components-accordion-color-section-section-bg": "#ffffff",
        "components-accordion-color-section-section-title": "#213117",
        "components-accordion-color-section-section-body": "#27282b",
        "components-accordion-color-item-item-bg": "#ffffff",
        "components-accordion-color-item-divider": "#e8e8ea",
        "components-accordion-color-item-icon-icon-default": "#58813d",
        "components-accordion-color-item-icon-icon-hover": "#ffffff",
        "components-accordion-color-item-icon-icon-pressed": "#ffffff",
        "components-accordion-color-item-icon-icon-active": "#ffffff",
        "components-accordion-color-item-title-item-default": "#213117",
        "components-accordion-color-item-title-item-hover": "#ffffff",
        "components-accordion-color-item-title-item-pressed": "#ffffff",
        "components-accordion-color-item-title-item-active": "#ffffff",
        "components-accordion-color-content-bg": "#ffffff",
        "components-accordion-color-content-title": "#213117",
        "components-accordion-color-content-body": "#27282b",
        "components-article-card-color-bg": "#ffffff",
        "components-article-card-color-border": "#e8e8ea",
        "components-article-card-color-content-bg": "#ffffff",
        "components-article-card-color-title": "#213117",
        "components-article-card-color-subtitle": "#27282b",
        "components-article-card-color-copy": "#27282b",
        "components-article-card-color-description": "#27282b",
        "components-article-card-listing-color-default-bg": "#ffffff",
        "components-article-card-listing-color-default-title": "#213117",
        "components-article-card-listing-color-default-copy": "#27282b",
        "components-article-hero-color-content-bg": "#ffffff",
        "components-article-hero-color-bg": "#ffffff",
        "components-article-hero-color-headline": "#213117",
        "components-article-hero-color-body": "#27282b",
        "components-article-hero-color-detail-text": "#27282b",
        "components-article-hero-color-divider": "#a2a5ab",
        "components-article-hero-color-icon": "#71a850",
        "components-article-hero-color-play-icon": "#ffffff",
        "components-article-hero-color-selected-stroke": "#72757e",
        "components-banner-color-bg-default": "#213117",
        "components-banner-color-icon-default": "#71a850",
        "components-banner-color-title-default": "#ffffff",
        "components-banner-color-body-default": "#ffffff",
        "components-banner-color-icon-close-default": "#ffffff",
        "components-banner-color-bg-alert": "#b32b30",
        "components-banner-color-icon-alert": "#ffffff",
        "components-banner-color-title-alert": "#ffffff",
        "components-banner-color-body-alert": "#ffffff",
        "components-banner-color-icon-close-alert": "#ffffff",
        "components-breadcrumb-color-bg": "#ffffff",
        "components-breadcrumb-color-breadcrumb-link-default": "#72757e",
        "components-breadcrumb-color-breadcrumb-link-hover": "#4e5056",
        "components-breadcrumb-color-breadcrumb-link-active": "#27282b",
        "components-breadcrumb-color-icon-divider": "#72757e",
        "components-bug-finder-prompt": "#ffffff",
        "components-button-color-filled-brand-default-bg": "#213117",
        "components-button-color-filled-brand-default-text": "#ffffff",
        "components-button-color-filled-brand-default-stroke": "#213117",
        "components-button-color-filled-brand-default-icon": "#ffffff",
        "components-button-color-filled-brand-hover-bg": "#000000",
        "components-button-color-filled-brand-hover-text": "#ffffff",
        "components-button-color-filled-brand-hover-stroke": "#000000",
        "components-button-color-filled-brand-hover-icon": "#ffffff",
        "components-button-color-filled-brand-pressed-bg": "#000000",
        "components-button-color-filled-brand-pressed-text": "#ffffff",
        "components-button-color-filled-brand-pressed-stroke": "#000000",
        "components-button-color-filled-brand-pressed-icon": "#ffffff",
        "components-button-color-filled-brand-disabled-bg": "rgba(33, 49, 23, 0.38)",
        "components-button-color-filled-brand-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-disabled-stroke": "rgba(33, 49, 23, 0.38)",
        "components-button-color-filled-brand-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-focus-bg": "#213117",
        "components-button-color-filled-brand-focus-text": "#ffffff",
        "components-button-color-filled-brand-focus-stroke": "#213117",
        "components-button-color-filled-brand-focus-icon": "#ffffff",
        "components-button-color-filled-brand-focus-outline": "#286efa",
        "components-button-color-filled-tonal-default-bg": "#58813d",
        "components-button-color-filled-tonal-default-text": "#ffffff",
        "components-button-color-filled-tonal-default-stroke": "#58813d",
        "components-button-color-filled-tonal-default-icon": "#ffffff",
        "components-button-color-filled-tonal-hover-bg": "#415f2d",
        "components-button-color-filled-tonal-hover-text": "#ffffff",
        "components-button-color-filled-tonal-hover-stroke": "#415f2d",
        "components-button-color-filled-tonal-hover-icon": "#ffffff",
        "components-button-color-filled-tonal-pressed-bg": "#415f2d",
        "components-button-color-filled-tonal-pressed-text": "#ffffff",
        "components-button-color-filled-tonal-pressed-stroke": "#415f2d",
        "components-button-color-filled-tonal-pressed-icon": "#ffffff",
        "components-button-color-filled-tonal-disabled-bg": "rgba(88, 129, 61, 0.38)",
        "components-button-color-filled-tonal-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-tonal-disabled-stroke": "rgba(88, 129, 61, 0.38)",
        "components-button-color-filled-tonal-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-tonal-focus-bg": "#58813d",
        "components-button-color-filled-tonal-focus-text": "#ffffff",
        "components-button-color-filled-tonal-focus-stroke": "#58813d",
        "components-button-color-filled-tonal-focus-icon": "#ffffff",
        "components-button-color-filled-tonal-focus-outline": "#1c4cae",
        "components-button-color-filled-white-default-bg": "#ffffff",
        "components-button-color-filled-white-default-text": "#191b1c",
        "components-button-color-filled-white-default-stroke": "#ffffff",
        "components-button-color-filled-white-default-icon": "#213117",
        "components-button-color-filled-white-hover-bg": "#fcfcfd",
        "components-button-color-filled-white-hover-text": "#191b1c",
        "components-button-color-filled-white-hover-stroke": "#fcfcfd",
        "components-button-color-filled-white-hover-icon": "#213117",
        "components-button-color-filled-white-pressed-bg": "#e8e8ea",
        "components-button-color-filled-white-pressed-text": "#191b1c",
        "components-button-color-filled-white-pressed-stroke": "#e8e8ea",
        "components-button-color-filled-white-pressed-icon": "#213117",
        "components-button-color-filled-white-disabled-bg": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-text": "rgba(25, 27, 28, 0.38)",
        "components-button-color-filled-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-icon": "rgba(33, 49, 23, 0.38)",
        "components-button-color-filled-white-focus-bg": "#ffffff",
        "components-button-color-filled-white-focus-text": "#191b1c",
        "components-button-color-filled-white-focus-stroke": "#ffffff",
        "components-button-color-filled-white-focus-icon": "#213117",
        "components-button-color-filled-white-focus-outline": "#1c4cae",
        "components-button-color-outline-brand-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-brand-default-text": "#213117",
        "components-button-color-outline-brand-default-stroke": "#213117",
        "components-button-color-outline-brand-default-icon": "#213117",
        "components-button-color-outline-brand-hover-bg": "#000000",
        "components-button-color-outline-brand-hover-text": "#ffffff",
        "components-button-color-outline-brand-hover-stroke": "#000000",
        "components-button-color-outline-brand-hover-icon": "#ffffff",
        "components-button-color-outline-brand-pressed-bg": "#000000",
        "components-button-color-outline-brand-pressed-text": "#ffffff",
        "components-button-color-outline-brand-pressed-stroke": "#000000",
        "components-button-color-outline-brand-pressed-icon": "#ffffff",
        "components-button-color-outline-brand-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-brand-disabled-text": "rgba(25, 27, 28, 0.38)",
        "components-button-color-outline-brand-disabled-stroke": "rgba(25, 27, 28, 0.38)",
        "components-button-color-outline-brand-disabled-icon": "rgba(25, 27, 28, 0.38)",
        "components-button-color-outline-brand-focus-bg": "#ffffff",
        "components-button-color-outline-brand-focus-text": "#213117",
        "components-button-color-outline-brand-focus-stroke": "#213117",
        "components-button-color-outline-brand-focus-icon": "#213117",
        "components-button-color-outline-brand-focus-outline": "#286efa",
        "components-button-color-outline-tonal-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-tonal-default-text": "#415f2d",
        "components-button-color-outline-tonal-default-stroke": "#58813d",
        "components-button-color-outline-tonal-default-icon": "#415f2d",
        "components-button-color-outline-tonal-hover-bg": "#415f2d",
        "components-button-color-outline-tonal-hover-text": "#ffffff",
        "components-button-color-outline-tonal-hover-stroke": "#415f2d",
        "components-button-color-outline-tonal-hover-icon": "#ffffff",
        "components-button-color-outline-tonal-pressed-bg": "#415f2d",
        "components-button-color-outline-tonal-pressed-text": "#ffffff",
        "components-button-color-outline-tonal-pressed-stroke": "#415f2d",
        "components-button-color-outline-tonal-pressed-icon": "#ffffff",
        "components-button-color-outline-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-tonal-disabled-text": "rgba(65, 95, 45, 0.38)",
        "components-button-color-outline-tonal-disabled-stroke": "rgba(88, 129, 61, 0.38)",
        "components-button-color-outline-tonal-disabled-icon": "rgba(65, 95, 45, 0.38)",
        "components-button-color-outline-tonal-focus-bg": "rgba(255, 255, 255, 0.01)",
        "components-button-color-outline-tonal-focus-text": "#415f2d",
        "components-button-color-outline-tonal-focus-stroke": "#58813d",
        "components-button-color-outline-tonal-focus-icon": "#415f2d",
        "components-button-color-outline-tonal-focus-outline": "#286efa",
        "components-button-color-outline-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-default-text": "#ffffff",
        "components-button-color-outline-white-default-stroke": "#ffffff",
        "components-button-color-outline-white-default-icon": "#ffffff",
        "components-button-color-outline-white-hover-bg": "#ffffff",
        "components-button-color-outline-white-hover-text": "#191b1c",
        "components-button-color-outline-white-hover-stroke": "#ffffff",
        "components-button-color-outline-white-hover-icon": "#213117",
        "components-button-color-outline-white-pressed-bg": "#ffffff",
        "components-button-color-outline-white-pressed-text": "#191b1c",
        "components-button-color-outline-white-pressed-stroke": "#ffffff",
        "components-button-color-outline-white-pressed-icon": "#213117",
        "components-button-color-outline-white-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-focus-bg": "rgba(255, 255, 255, 0.01)",
        "components-button-color-outline-white-focus-text": "#ffffff",
        "components-button-color-outline-white-focus-stroke": "#ffffff",
        "components-button-color-outline-white-focus-icon": "#ffffff",
        "components-button-color-outline-white-focus-outline": "#286efa",
        "components-button-color-text-brand-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-default-text": "#213117",
        "components-button-color-text-brand-default-stroke": "#213117",
        "components-button-color-text-brand-default-icon": "#213117",
        "components-button-color-text-brand-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-hover-bg-icon-only": "#e8e8ea",
        "components-button-color-text-brand-hover-text": "#213117",
        "components-button-color-text-brand-hover-stroke": "#71a850",
        "components-button-color-text-brand-hover-icon": "#213117",
        "components-button-color-text-brand-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-pressed-bg-icon-only": "#a2a5ab",
        "components-button-color-text-brand-pressed-text": "#000000",
        "components-button-color-text-brand-pressed-stroke": "#000000",
        "components-button-color-text-brand-pressed-icon": "#000000",
        "components-button-color-text-brand-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-disabled-text": "rgba(33, 49, 23, 0.38)",
        "components-button-color-text-brand-disabled-stroke": "rgba(33, 49, 23, 0.38)",
        "components-button-color-text-brand-disabled-icon": "rgba(33, 49, 23, 0.38)",
        "components-button-color-text-brand-focus-bg": "#d5e3ff",
        "components-button-color-text-brand-focus-text": "#213117",
        "components-button-color-text-brand-focus-stroke": "#27282b",
        "components-button-color-text-brand-focus-icon": "#1c4cae",
        "components-button-color-text-brand-focus-outline": "#1c4cae",
        "components-button-color-text-tonal-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-default-text": "#415f2d",
        "components-button-color-text-tonal-default-stroke": "#58813d",
        "components-button-color-text-tonal-default-icon": "#415f2d",
        "components-button-color-text-tonal-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-hover-bg-icon-only": "#fcfcfd",
        "components-button-color-text-tonal-hover-text": "#213117",
        "components-button-color-text-tonal-hover-stroke": "#58813d",
        "components-button-color-text-tonal-hover-icon": "#213117",
        "components-button-color-text-tonal-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-pressed-bg-icon-only": "#e8e8ea",
        "components-button-color-text-tonal-pressed-text": "#213117",
        "components-button-color-text-tonal-pressed-stroke": "#58813d",
        "components-button-color-text-tonal-pressed-icon": "#213117",
        "components-button-color-text-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-disabled-text": "rgba(65, 95, 45, 0.38)",
        "components-button-color-text-tonal-disabled-stroke": "rgba(65, 95, 45, 0.38)",
        "components-button-color-text-tonal-disabled-icon": "rgba(65, 95, 45, 0.38)",
        "components-button-color-text-tonal-focus-bg": "#d5e3ff",
        "components-button-color-text-tonal-focus-text": "#1c4cae",
        "components-button-color-text-tonal-focus-stroke": "#58813d",
        "components-button-color-text-tonal-focus-icon": "#1c4cae",
        "components-button-color-text-tonal-focus-outline": "#1c4cae",
        "components-button-color-text-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-default-text": "#ffffff",
        "components-button-color-text-white-default-stroke": "#ffffff",
        "components-button-color-text-white-default-icon": "#ffffff",
        "components-button-color-text-white-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-hover-bg-icon-only": "#4e5056",
        "components-button-color-text-white-hover-text": "#ffffff",
        "components-button-color-text-white-hover-stroke": "#ffffff",
        "components-button-color-text-white-hover-icon": "#ffffff",
        "components-button-color-text-white-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-pressed-bg-icon-only": "#4e5056",
        "components-button-color-text-white-pressed-text": "#ffffff",
        "components-button-color-text-white-pressed-stroke": "#ffffff",
        "components-button-color-text-white-pressed-icon": "#ffffff",
        "components-button-color-text-white-disabled-bg": "rgba(115, 119, 128, 0.00)",
        "components-button-color-text-white-disabled-text": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-stroke": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-icon": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-focus-bg": "#d5e3ff",
        "components-button-color-text-white-focus-text": "#ffffff",
        "components-button-color-text-white-focus-stroke": "#ffffff",
        "components-button-color-text-white-focus-icon": "#1c4cae",
        "components-button-color-text-white-focus-outline": "#1c4cae",
        "components-button-group-color-border": "#e8e8ea",
        "components-button-group-color-button-bg-default": "rgba(33, 49, 23, 0.00)",
        "components-button-group-color-button-fg-default": "#191b1c",
        "components-button-group-color-button-bg-hover": "rgba(33, 49, 23, 0.38)",
        "components-button-group-color-button-fg-hover": "#191b1c",
        "components-button-group-color-button-bg-active": "#213117",
        "components-button-group-color-button-fg-active": "#ffffff",
        "components-button-group-color-overlay-left-gradient-fill-1": "#ffffff",
        "components-button-group-color-overlay-left-gradient-fill-2": "rgba(255, 255, 255, 0.50)",
        "components-button-group-color-stroke-default-gradient-fill-1": "rgba(33, 49, 23, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-2": "rgba(33, 49, 23, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-3": "rgba(33, 49, 23, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-4": "rgba(33, 49, 23, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-1": "rgba(33, 49, 23, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-2": "rgba(33, 49, 23, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-3": "rgba(33, 49, 23, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-4": "rgba(33, 49, 23, 0.00)",
        "components-button-group-color-stroke-active-gradient-fill-1": "#213117",
        "components-button-group-color-stroke-active-gradient-fill-2": "#213117",
        "components-button-group-color-stroke-active-gradient-fill-3": "#213117",
        "components-button-group-color-stroke-active-gradient-fill-4": "#213117",
        "components-category-card-color-bg": "#ffffff",
        "components-category-card-color-content-bg": "#ffffff",
        "components-category-card-color-category-bg": "#ffffff",
        "components-category-card-color-category-bg-2": "#000000",
        "components-category-card-color-category-bg-3": "#27282b",
        "components-category-card-color-category-bg-4": "#72757e",
        "components-category-card-color-category-bg-5": "#4e5056",
        "components-category-card-color-category-bg-6": "#72757e",
        "components-category-card-color-title": "#213117",
        "components-category-card-color-copy": "#27282b",
        "components-category-card-color-stroke": "#e8e8ea",
        "components-category-card-color-stroke-hover": "#213117",
        "components-category-card-listing-color-bg": "#ffffff",
        "components-category-card-listing-color-title": "#213117",
        "components-category-card-listing-color-copy": "#27282b",
        "components-card-search-result-color-bg": "#ffffff",
        "components-card-search-result-color-label": "#27282b",
        "components-card-search-result-color-title": "#213117",
        "components-card-search-result-color-body": "#27282b",
        "components-card-search-result-color-divider-line": "#e8e8ea",
        "components-contact-us-promo-color-light-bg": "#e8e8ea",
        "components-contact-us-promo-color-light-title": "#213117",
        "components-contact-us-promo-color-light-body": "#27282b",
        "components-contact-us-promo-color-default-bg": "#ffffff",
        "components-contact-us-promo-color-default-title": "#213117",
        "components-contact-us-promo-color-default-body": "#27282b",
        "components-contact-us-promo-color-brand-bg": "#f3d54f",
        "components-contact-us-promo-color-brand-title": "#213117",
        "components-contact-us-promo-color-brand-body": "#27282b",
        "components-content-list-color-bg": "#ffffff",
        "components-content-list-color-title": "#213117",
        "components-content-list-color-body": "#27282b",
        "components-content-list-color-icon": "#58813d",
        "components-cookies-colors-bg": "#fcfcfd",
        "components-cookies-colors-title": "#213117",
        "components-cookies-colors-body": "#27282b",
        "components-cookies-colors-icon": "#71a850",
        "components-dropdown-bg": "#ffffff",
        "components-dropdown-border-default": "#72757e",
        "components-dropdown-border-hover": "#71a850",
        "components-dropdown-border-focused": "#191b1c",
        "components-dropdown-border-destructive": "#b32b30",
        "components-dropdown-label-text-default": "#191b1c",
        "components-dropdown-item-text-default": "#191b1c",
        "components-dropdown-item-bg-default": "#ffffff",
        "components-dropdown-item-bg-hover": "#ebf3e6",
        "components-dropdown-input-field-default": "#72757e",
        "components-dropdown-input-field-focused": "#191b1c",
        "components-dropdown-input-label-focused": "#213117",
        "components-dropdown-input-field-destructive": "#b32b30",
        "components-external-link-card-bg": "#e8e8ea",
        "components-external-link-card-card-bg": "#ffffff",
        "components-external-link-card-card-title": "#27282b",
        "components-external-link-card-card-subtitle": "#27282b",
        "components-external-link-card-card-eyebrow": "#4e5056",
        "components-fab-color-border-default": "#ffffff",
        "components-footer-brand-color-bg": "#213117",
        "components-footer-brand-color-logo": "#ffffff",
        "components-footer-brand-color-title-text": "#ffffff",
        "components-footer-brand-color-description-text": "#ffffff",
        "components-footer-brand-color-footer-menu-expanded": "#213117",
        "components-footer-brand-color-link-default": "#ffffff",
        "components-footer-brand-color-link-hover": "#e8e8ea",
        "components-footer-brand-color-link-pressed": "#e8e8ea",
        "components-footer-brand-color-category-link-default": "#ffffff",
        "components-footer-brand-color-category-link-hover": "#e8e8ea",
        "components-footer-brand-color-category-link-pressed": "#e8e8ea",
        "components-footer-brand-color-subcategory-link-default": "#ffffff",
        "components-footer-brand-color-subcategory-link-hover": "#e8e8ea",
        "components-footer-brand-color-subcategory-link-pressed": "#e8e8ea",
        "components-footer-brand-color-icon-icon-default": "#ffffff",
        "components-footer-brand-color-icon-icon-pressed": "#e8e8ea",
        "components-footer-brand-color-social-icon-default": "#ffffff",
        "components-footer-brand-color-social-icon-hover": "#e8e8ea",
        "components-footer-brand-color-social-icon-pressed": "#e8e8ea",
        "components-footer-brand-spacing-category-link-hover": "#000000",
        "components-footer-global-color-bg": "#ffffff",
        "components-footer-global-color-title-text": "#000000",
        "components-footer-global-color-disclaimer-text": "#000000",
        "components-footer-global-color-link-default": "#000000",
        "components-footer-global-color-link-hover": "#000000",
        "components-footer-global-color-link-pressed": "#000000",
        "components-generic-card-color-bg": "#ffffff",
        "components-generic-card-color-content-bg": "#ffffff",
        "components-generic-card-color-title": "#213117",
        "components-generic-card-color-subtitle": "#27282b",
        "components-generic-card-color-copy": "#27282b",
        "components-global-search-color-omnibox-list-bg": "#ffffff",
        "components-global-search-color-omnibox-divider": "#a2a5ab",
        "components-global-search-color-omnibox-item-bg-default": "#ffffff",
        "components-global-search-color-omnibox-item-bg-hover": "#fcfcfd",
        "components-global-search-color-omnibox-item-text-default": "#72757e",
        "components-global-search-color-omnibox-item-text-hover": "#4e5056",
        "components-global-search-color-omnibox-label-text-default": "#4e5056",
        "components-global-search-color-omnibox-category-text-default": "#4e5056",
        "components-generic-card-listing-color-bg": "#ffffff",
        "components-generic-card-listing-color-title": "#213117",
        "components-generic-card-listing-color-copy": "#27282b",
        "components-header-color-bg": "#ffffff",
        "components-header-color-bottom-stroke": "#e8e8ea",
        "components-header-color-search-search-border-default": "#191b1c",
        "components-header-color-search-search-text": "#4e5056",
        "components-header-color-search-search-icon": "#4e5056",
        "components-header-color-search-search-bar": "#191b1c",
        "components-header-color-mobile-mobile-expanded-bg": "#ffffff",
        "components-header-color-mobile-pressed-bg": "#cde1bf",
        "components-header-color-language-selector-language": "#4e5056",
        "components-header-color-language-selector-country": "#213117",
        "components-header-color-language-selector-country-hover-bg": "#e8e8ea",
        "components-header-color-language-selector-megamenu-language": "#4e5056",
        "components-header-color-language-selector-megamenu-country": "#213117",
        "components-header-color-language-selector-megamenu-country-hover": "#e8e8ea",
        "components-header-color-language-selector-bg": "#ffffff",
        "components-header-color-language-selector-stroke": "#72757e",
        "components-header-color-mega-menu-bg": "#ffffff",
        "components-header-color-mega-menu-featured-bg": "#415f2d",
        "components-header-color-mega-menu-featured-title": "#ffffff",
        "components-header-color-mega-menu-category-title": "#27282b",
        "components-header-color-mega-menu-bg-accent": "#ffffff",
        "components-header-color-icon-icon-default": "#4e5056",
        "components-header-color-icon-icon-hover": "#191b1c",
        "components-header-color-icon-icon-pressed": "#000000",
        "components-header-color-links-nav-main-link-default": "#4e5056",
        "components-header-color-links-nav-main-link-hover": "#191b1c",
        "components-header-color-links-nav-main-link-pressed": "#000000",
        "components-header-color-links-nav-main-link-active": "#000000",
        "components-header-color-links-nav-main-active-bar": "#71a850",
        "components-header-color-links-nav-main-bg-color": "#ffffff",
        "components-header-color-links-nav-category-link-category-default": "#4e5056",
        "components-header-color-links-nav-category-link-category-hover": "#191b1c",
        "components-header-color-links-nav-category-link-category-pressed": "#000000",
        "components-header-color-links-nav-category-link-category-active": "#000000",
        "components-header-color-links-nav-menu-link-link-default": "#4e5056",
        "components-header-color-links-nav-menu-link-link-hover": "#191b1c",
        "components-header-color-links-nav-menu-link-link-pressed": "#000000",
        "components-header-color-links-nav-menu-link-link-active": "#000000",
        "components-header-color-links-nav-menu-link-bg": "#ffffff",
        "components-header-color-links-nav-subcategory-link-subcategory-default": "#4e5056",
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": "#000000",
        "components-header-color-links-nav-subcategory-link-subcategory-active": "#000000",
        "components-hero-homepage-color-bg": "#ffffff",
        "components-hero-homepage-color-container-bg": "#f3d54f",
        "components-hero-homepage-color-eyebrow": "#72757e",
        "components-hero-homepage-color-header": "#213117",
        "components-hero-homepage-color-sub-header": "#213117",
        "components-hero-homepage-color-body": "#27282b",
        "components-hero-homepage-color-play-pause": "#000000",
        "components-hero-internal-color-default-title": "#ffffff",
        "components-hero-internal-color-default-body": "#fcfcfd",
        "components-hero-internal-color-default-bg": "#213117",
        "components-hero-internal-color-accent-bg": "#213117",
        "components-hero-internal-color-with-image-bg": "#213117",
        "components-hero-internal-color-default-bg-2": "#213117",
        "components-hero-internal-color-with-image-title": "#ffffff",
        "components-hero-internal-color-with-image-body": "#fcfcfd",
        "components-heading-page-title-color-default-bg": "#ffffff",
        "components-heading-page-title-color-default-title": "#213117",
        "components-heading-page-title-color-default-body": "#27282b",
        "components-heading-page-title-color-light-bg": "#e8e8ea",
        "components-heading-page-title-color-light-title": "#213117",
        "components-heading-page-title-color-light-body": "#27282b",
        "components-heading-page-title-color-color-bg": "#58813d",
        "components-heading-page-title-color-color-title": "#ffffff",
        "components-heading-page-title-color-color-body": "#fcfcfd",
        "components-hyperlink-color-dark-default": "#72757e",
        "components-hyperlink-color-dark-hover": "#27282b",
        "components-hyperlink-color-dark-visited": "#f3d54f",
        "components-hyperlink-color-light-default": "#e8e8ea",
        "components-hyperlink-color-light-hover": "#a2a5ab",
        "components-hyperlink-color-light-visited": "#fbf0c0",
        "components-icon-default": "#72757e",
        "components-icon-focused": "#72757e",
        "components-icon-card-color-bg": "#ffffff",
        "components-icon-card-color-icon-bg": "#71a850",
        "components-icon-card-color-icon": "#191b1c",
        "components-icon-card-color-title": "#213117",
        "components-icon-card-color-subtitle": "#27282b",
        "components-icon-card-color-description": "#27282b",
        "components-icon-card-listing-color-bg": "#ffffff",
        "components-icon-card-listing-color-inner-bg": "#ffffff",
        "components-icon-card-listing-color-brand-bg": "#f3d54f",
        "components-icon-card-listing-color-title": "#213117",
        "components-icon-card-listing-color-copy": "#27282b",
        "components-icon-card-listing-color-inner-legal-text": "#27282b",
        "components-jumplink-color-bg-default": "#ffffff",
        "components-jumplink-color-link-text-default": "#4e5056",
        "components-jumplink-color-link-text-hover": "#191b1c",
        "components-jumplink-color-link-border-hover": "#191b1c",
        "components-jumplink-color-link-text-active": "#000000",
        "components-jumplink-color-link-border-active": "#71a850",
        "components-jumplink-color-title-text": "#213117",
        "components-jumplink-color-description-text": "#27282b",
        "components-layout-bg-default": "#ffffff",
        "components-lightbox-color-overlay-bg": "rgba(255, 255, 255, 0.95)",
        "components-lightbox-color-title": "#213117",
        "components-lightbox-color-body": "#27282b",
        "components-label-color-on-color-label-label-default": "#ebf3e6",
        "components-label-color-on-color-label-label-hover": "#213117",
        "components-label-color-on-color-label-label-pressed": "#213117",
        "components-label-color-on-color-background-bg-default": "rgba(33, 49, 23, 0.00)",
        "components-label-color-on-color-background-bg-hover": "#ebf3e6",
        "components-label-color-on-color-background-bg-pressed": "#ebf3e6",
        "components-label-color-on-color-stroke-hover": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-color-stroke-pressed": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-color-stroke-default-gradient-fill-1": "#ebf3e6",
        "components-label-color-on-color-stroke-default-gradient-fill-2": "#ebf3e6",
        "components-label-color-on-color-stroke-default-gradient-fill-3": "#ebf3e6",
        "components-label-color-on-color-stroke-default-gradient-fill-4": "#ebf3e6",
        "components-label-color-on-white-label-label-default": "#213117",
        "components-label-color-on-white-label-label-hover": "#fcfcfd",
        "components-label-color-on-white-label-label-pressed": "#fcfcfd",
        "components-label-color-on-white-background-bg-default": "rgba(33, 49, 23, 0.00)",
        "components-label-color-on-white-background-bg-hover": "#213117",
        "components-label-color-on-white-background-bg-pressed": "#213117",
        "components-label-color-on-white-stroke-hover": "#213117",
        "components-label-color-on-white-stroke-pressed": "#213117",
        "components-label-color-on-white-stroke-default-gradient-fill-1": "#213117",
        "components-label-color-on-white-stroke-default-gradient-fill-2": "#213117",
        "components-label-color-on-white-stroke-default-gradient-fill-3": "#213117",
        "components-label-color-on-white-stroke-default-gradient-fill-4": "#213117",
        "components-legal-disclaimer-color-default-bg": "#ffffff",
        "components-legal-disclaimer-color-default-title": "#213117",
        "components-legal-disclaimer-color-default-body": "#27282b",
        "components-legal-disclaimer-color-light-bg": "#e8e8ea",
        "components-legal-disclaimer-color-light-title": "#213117",
        "components-legal-disclaimer-color-light-body": "#27282b",
        "components-legal-disclaimer-color-brand-bg": "#191b1c",
        "components-legal-disclaimer-color-brand-title": "#ffffff",
        "components-legal-disclaimer-color-brand-body": "#fcfcfd",
        "components-media-video-thumbnail-overlay-bg": "rgba(25, 27, 28, 0.80)",
        "components-media-caption-color-bg": "#fcfcfd",
        "components-media-caption-color-text": "#27282b",
        "components-media-gallery-color-bg": "#e8e8ea",
        "components-media-gallery-color-title": "#213117",
        "components-media-gallery-color-body": "#27282b",
        "components-pagination-on-white-accent-default": "#27282b",
        "components-pagination-on-white-accent-scroll": "#27282b",
        "components-pagination-on-white-disabled": "#a2a5ab",
        "components-pagination-on-white-bg": "rgba(39, 40, 43, 0.50)",
        "components-pagination-on-white-stroke": "#e8e8ea",
        "components-pagination-on-white-bg-active": "#213117",
        "components-pagination-on-white-bg-hover": "#e8e8ea",
        "components-pagination-on-white-text-default": "#72757e",
        "components-pagination-on-white-text-hover": "#27282b",
        "components-pagination-on-white-text-active": "#ffffff",
        "components-pagination-on-color-accent-default": "#ffffff",
        "components-pagination-on-color-accent-scroll": "#e8e8ea",
        "components-pagination-on-color-disabled": "#213117",
        "components-pagination-on-color-bg": "rgba(39, 40, 43, 0.80)",
        "components-pagination-on-color-stroke": "rgba(255, 255, 255, 0.38)",
        "components-pagination-on-color-bg-active": "#ffffff",
        "components-pagination-on-color-bg-hover": "#27282b",
        "components-pagination-on-color-text-default": "#ffffff",
        "components-pagination-on-color-text-hover": "#ffffff",
        "components-pagination-on-color-text-active": "#213117",
        "components-pdf-link-card-color-bg": "#ffffff",
        "components-pdf-link-card-color-card-bg": "#ffffff",
        "components-pdf-link-card-color-card-title": "#213117",
        "components-pdf-link-card-color-card-subtitle": "#27282b",
        "components-pdf-link-card-color-card-body": "#27282b",
        "components-product-card-color-bg": "#ffffff",
        "components-product-card-color-content-bg": "#ffffff",
        "components-product-card-color-border": "#e8e8ea",
        "components-product-card-color-title": "#213117",
        "components-product-card-color-description": "#27282b",
        "components-product-card-color-star-rating": "#4e5056",
        "components-product-card-color-badge-bg": "#415f2d",
        "components-product-card-color-badge-text": "#f3d54f",
        "components-product-card-listing-color-bg": "#f3d54f",
        "components-product-card-listing-color-title": "#213117",
        "components-product-card-listing-color-copy": "#27282b",
        "components-product-card-listing-color-legal-text": "#27282b",
        "components-product-information-color-bg": "#ffffff",
        "components-product-information-color-content-bg": "#ffffff",
        "components-product-information-color-headline": "#213117",
        "components-product-information-color-body": "#27282b",
        "components-product-information-color-detail-text": "#27282b",
        "components-product-information-color-divider": "#a2a5ab",
        "components-product-information-color-icon": "#71a850",
        "components-product-information-color-play-icon": "#ffffff",
        "components-product-information-color-selected-stroke": "#58813d",
        "components-product-results-list-with-search-color-bg": "#ffffff",
        "components-promo-color-default-bg": "#ffffff",
        "components-promo-color-default-image-bg": "#ffffff",
        "components-promo-color-default-content-bg": "#ffffff",
        "components-promo-color-default-title": "#213117",
        "components-promo-color-default-body": "#27282b",
        "components-promo-color-default-category": "#213117",
        "components-promo-color-light-bg": "#e8e8ea",
        "components-promo-color-light-image-bg": "#e8e8ea",
        "components-promo-color-light-content-bg": "#e8e8ea",
        "components-promo-color-light-title": "#213117",
        "components-promo-color-light-body": "#27282b",
        "components-promo-color-light-category": "#213117",
        "components-promo-color-brand-bg": "#415f2d",
        "components-promo-color-brand-image-bg": "#415f2d",
        "components-promo-color-brand-content-bg": "#415f2d",
        "components-promo-color-brand-title": "#ffffff",
        "components-promo-color-brand-body": "#fcfcfd",
        "components-promo-color-brand-category": "#ffffff",
        "components-pull-quote-color-bg": "#415f2d",
        "components-pull-quote-color-stroke": "#58813d",
        "components-pull-quote-color-quote-bg": "#ffffff",
        "components-pull-quote-color-quote-title": "#213117",
        "components-pull-quote-color-quote-by-text": "#27282b",
        "components-pull-quote-color-quote-detail": "#415f2d",
        "components-pull-quote-color-quote-highlight": "#71a850",
        "components-promo-alternate-color-default-bg": "#ffffff",
        "components-promo-alternate-color-default-image-bg": "#ffffff",
        "components-promo-alternate-color-default-content-bg": "#ffffff",
        "components-promo-alternate-color-default-title": "#213117",
        "components-promo-alternate-color-default-body": "#27282b",
        "components-promo-alternate-color-default-category": "#213117",
        "components-promo-alternate-color-light-bg": "#e8e8ea",
        "components-promo-alternate-color-light-image-bg": "#e8e8ea",
        "components-promo-alternate-color-light-content-bg": "#e8e8ea",
        "components-promo-alternate-color-light-title": "#213117",
        "components-promo-alternate-color-light-body": "#27282b",
        "components-promo-alternate-color-light-category": "#213117",
        "components-promo-alternate-color-brand-bg": "#415f2d",
        "components-promo-alternate-color-brand-image-bg": "#415f2d",
        "components-promo-alternate-color-brand-content-bg": "#415f2d",
        "components-promo-alternate-color-brand-title": "#ffffff",
        "components-promo-alternate-color-brand-body": "#fcfcfd",
        "components-promo-alternate-color-brand-category": "#ffffff",
        "components-share-list-color-title": "#ffffff",
        "components-share-list-color-bg": "#27282b",
        "components-share-list-color-link-default": "#fcfcfd",
        "components-share-list-color-link-hover": "#71a850",
        "components-share-list-color-bg-link-hover": "#191b1c",
        "components-share-list-color-close-icon": "#ffffff",
        "components-share-list-color-facebook-icon": "#3b5998",
        "components-share-list-color-pinterest-icon": "#e60023",
        "components-share-list-color-twitter-icon": "#ffffff",
        "components-share-list-color-basic-icon-default": "#ffffff",
        "components-share-list-color-icon-hover": "#71a850",
        "components-share-list-color-border": "#191b1c",
        "components-site-search-bg": "#ffffff",
        "components-site-search-results_text": "#27282b",
        "components-site-search-filter-filter-selected-bg": "#ebf3e6",
        "components-site-search-facet-group-label-bottom-line": "#e8e8ea",
        "components-site-search-facet-group-label-text": "#27282b",
        "components-site-search-facet-group-label-bg": "#ffffff",
        "components-site-search-facet-bg": "#ffffff",
        "components-site-search-facet-stroke": "#e8e8ea",
        "components-site-search-refine-bg": "#ffffff",
        "components-site-search-refine-right-divider": "#e8e8ea",
        "components-site-search-refine-title": "#213117",
        "components-site-search-hero-search-bg": "#213117",
        "components-site-search-hero-search-accent-bg": "#213117",
        "components-site-search-hero-search-title": "#ffffff",
        "components-stats-stat-text": "#58813d",
        "components-stats-stat-text-on-light": "#213117",
        "components-stats-supporting-text": "#27282b",
        "components-stats-bg-default": "#ffffff",
        "components-stats-bg-inner": "#ffffff",
        "components-stats-bg-section-inner": "#58813d",
        "components-stats-title": "#213117",
        "components-stats-copy": "#27282b",
        "components-supporting-text-default": "#4e5056",
        "components-supporting-text-destructive": "#b32b30",
        "components-tab-bg": "#ffffff",
        "components-tab-border-default": "#72757e",
        "components-tab-border-hover": "#71a850",
        "components-tab-border-focused": "#191b1c",
        "components-tab-border-destructive": "#b32b30",
        "components-tab-label-text-default": "#191b1c",
        "components-tab-item-text-default": "#191b1c",
        "components-tab-item-bg-default": "#ffffff",
        "components-tab-item-bg-hover": "#ebf3e6",
        "components-tab-input-field-default": "#72757e",
        "components-tab-input-field-focused": "#191b1c",
        "components-tab-input-label-focused": "#213117",
        "components-tab-input-field-destructive": "#b32b30",
        "components-tab-bg-2": "#e8e8ea",
        "components-tag-color-bg": "#ffffff",
        "components-tag-color-text": "#4e5056",
        "components-tag-color-stroke": "#72757e",
        "components-tag-color-bg-hover": "#4e5056",
        "components-tag-color-text-hover": "#ffffff",
        "components-tag-color-stroke-hover": "#4e5056",
        "components-text-field-bg": "#ffffff",
        "components-text-field-bg-focused": "#fcfcfd",
        "components-text-field-border-default": "#191b1c",
        "components-text-field-border-hover": "#72757e",
        "components-text-field-border-focused": "#191b1c",
        "components-text-field-border-destructive": "#b32b30",
        "components-text-field-input-field-default": "#4e5056",
        "components-text-field-input-field-focused": "#191b1c",
        "components-text-field-input-label-focused": "#213117",
        "components-text-field-input-field-destructive": "#b32b30",
        "components-text-field-icon-default": "#4e5056",
        "components-text-field-icon-destructive": "#b32b30",
        "components-text-field-supporting-text-default": "#4e5056",
        "components-text-field-supporting-text-destructive": "#b32b30",
        "components-tip-color-bg": "#e8e8ea",
        "components-tip-color-title": "#213117",
        "components-tip-color-body": "#27282b",
        "components-tip-color-stroke": "#a2a5ab",
        "components-video-color-bg": "#ffffff",
        "themes-general-surface-color-light-bg": "#ffffff",
        "themes-general-surface-color-light-title": "#213117",
        "themes-general-surface-color-light-body": "#27282b",
        "themes-general-surface-color-light-decor-line-break": "#72757e",
        "themes-general-surface-color-light-rule-line": "#a2a5ab",
        "themes-general-surface-color-dark-bg": "#71a850",
        "themes-general-surface-color-dark-title": "#ffffff",
        "themes-general-surface-color-dark-body": "#fcfcfd",
        "themes-general-surface-color-dark-decor-line-break": "#72757e",
        "themes-general-card-color-dark-title": "#213117",
        "themes-general-card-color-dark-subtitle": "#27282b",
        "themes-general-card-color-dark-body": "#27282b",
        "themes-general-card-color-light-title": "#ffffff",
        "themes-general-card-color-light-subtitle": "#fcfcfd",
        "themes-general-card-color-light-body": "#fcfcfd",
        "themes-shadow-color": "#ffffff",
        "themes-shadow-share-list-color-fill": "rgba(0, 0, 0, 0.40)",
        "themes-shadow-header-menu-color-fill": "rgba(0, 0, 0, 0.20)",
        "themes-gradient-loader-gradient-fill-1": "#213117",
        "themes-gradient-loader-gradient-fill-2": "rgba(33, 49, 23, 0.00)",
        "themes-color-background-white": "#ffffff",
        "themes-color-background-light": "#e8e8ea",
        "themes-color-background-dark": "#27282b",
        "themes-color-background-black": "#191b1c",
        "themes-color-background-brand1": "#71a850",
        "themes-color-background-brand2": "#72757e",
        "themes-color-background-accent1": "#005eb5",
        "themes-color-background-accent2": "#f3d54f",
        "themes-color-background-body": "#ffffff",
        "themes-color-stroke-white": "#ffffff",
        "themes-color-stroke-light": "#e8e8ea",
        "themes-color-stroke-dark": "#27282b",
        "themes-color-stroke-black": "#191b1c",
        "themes-color-stroke-brand1": "#71a850",
        "themes-color-stroke-brand2": "#72757e",
        "themes-color-stroke-accent1": "#005eb5",
        "themes-color-stroke-accent2": "#f3d54f",
        "themes-color-text-white": "#ffffff",
        "themes-color-text-light": "#fcfcfd",
        "themes-color-text-dark": "#27282b",
        "themes-color-text-black": "#213117",
        "themes-color-text-brand1": "#71a850",
        "themes-color-text-brand2": "#4e5056",
        "themes-color-text-accent1": "#0a3d70",
        "themes-color-text-accent2": "#564806",
        "colors-grayscale-100": "#fcfcfd",
        "colors-grayscale-200": "#e8e8ea",
        "colors-grayscale-300": "#a2a5ab",
        "colors-grayscale-400": "#72757e",
        "colors-grayscale-500": "#4e5056",
        "colors-grayscale-600": "#27282b",
        "colors-grayscale-700": "#191b1c",
        "colors-brand-1-100": "#ebf3e6",
        "colors-brand-1-200": "#cde1bf",
        "colors-brand-1-300": "#a6ca8e",
        "colors-brand-1-400": "#71a850",
        "colors-brand-1-500": "#58813d",
        "colors-brand-1-600": "#415f2d",
        "colors-brand-1-700": "#213117",
        "colors-brand-2-100": "#fcfcfd",
        "colors-brand-2-200": "#e8e8ea",
        "colors-brand-2-300": "#a2a5ab",
        "colors-brand-2-400": "#72757e",
        "colors-brand-2-500": "#4e5056",
        "colors-brand-2-600": "#27282b",
        "colors-brand-2-700": "#191b1c",
        "colors-accent-1-100": "#f0f7ff",
        "colors-accent-1-200": "#7bc3fe",
        "colors-accent-1-300": "#0a8aed",
        "colors-accent-1-400": "#005eb5",
        "colors-accent-1-500": "#004b9b",
        "colors-accent-1-600": "#0a3d70",
        "colors-accent-1-700": "#07274a",
        "colors-accent-2-100": "#fdf9e6",
        "colors-accent-2-200": "#fbf0c0",
        "colors-accent-2-300": "#f7e48e",
        "colors-accent-2-400": "#f3d54f",
        "colors-accent-2-500": "#e5be10",
        "colors-accent-2-600": "#a98c0c",
        "colors-accent-2-700": "#564806",
        "colors-accent-3-100": "#fcfcfd",
        "colors-accent-3-200": "#e8e8ea",
        "colors-accent-3-300": "#a2a5ab",
        "colors-accent-3-400": "#72757e",
        "colors-accent-3-500": "#4e5056",
        "colors-accent-3-600": "#27282b",
        "colors-accent-3-700": "#191b1c",
        "colors-accent-4-400_storage": "#000000",
        "colors-accessible-accent-1-100": "#f0f7ff",
        "colors-accessible-accent-1-200": "#7bc3fe",
        "colors-accessible-accent-1-300": "#0a8aed",
        "colors-accessible-accent-1-400": "#005eb5",
        "colors-accessible-accent-1-500": "#004b9b",
        "colors-accessible-accent-1-600": "#0a3d70",
        "colors-accessible-accent-1-700": "#07274a",
        "colors-accessible-accent-2-100": "#fdf9e6",
        "colors-accessible-accent-2-200": "#fbf0c0",
        "colors-accessible-accent-2-300": "#f7e48e",
        "colors-accessible-accent-2-400": "#f3d54f",
        "colors-accessible-accent-2-500": "#e5be10",
        "colors-accessible-accent-2-600": "#a98c0c",
        "colors-accessible-accent-2-700": "#564806",
        "colors-accessible-brand-2-100": "#fcfcfd",
        "colors-accessible-brand-2-200": "#e8e8ea",
        "colors-accessible-brand-2-300": "#a2a5ab",
        "colors-accessible-brand-2-400": "#72757e",
        "colors-accessible-brand-2-500": "#4e5056",
        "colors-accessible-brand-2-600": "#27282b",
        "colors-accessible-brand-2-700": "#191b1c"
      },
      "spacing": {
        "components-accordion-spacing-large-padding-x": "5rem",
        "components-accordion-spacing-large-icon-margin-right": "2rem",
        "components-accordion-spacing-large-copy-margin-right": "2.5rem",
        "components-accordion-spacing-large-maxwidth": "59.625rem",
        "components-accordion-spacing-large-section-padding-x": "0rem",
        "components-accordion-spacing-large-section-padding-y": "2.5rem",
        "components-accordion-spacing-large-section-copy-margin-bottom": "2.5rem",
        "components-accordion-spacing-large-section-space-between": "4rem",
        "components-accordion-spacing-large-item-padding-x": "0rem",
        "components-accordion-spacing-large-item-padding-y": "1.5rem",
        "components-accordion-spacing-large-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-large-content-padding-x": "0rem",
        "components-accordion-spacing-large-content-padding-y": "1.5rem",
        "components-accordion-spacing-large-content-title-margin-bottom": "2rem",
        "components-accordion-spacing-large-content-content-space-between": "1.5rem",
        "components-accordion-spacing-large-content-content-margin-left": "1rem",
        "components-accordion-spacing-small-padding-top": "1rem",
        "components-accordion-spacing-small-padding-x": "1.5rem",
        "components-accordion-spacing-small-padding-y": "2.5rem",
        "components-accordion-spacing-small-icon-margin-right": "0.75rem",
        "components-accordion-spacing-small-title-margin-bottom": "0.5rem",
        "components-accordion-spacing-small-copy-margin-right": "2rem",
        "components-accordion-spacing-small-copy-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-padding-x": "0rem",
        "components-accordion-spacing-small-section-padding-y": "0rem",
        "components-accordion-spacing-small-section-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-space-between": "2.5rem",
        "components-accordion-spacing-small-item-padding-x": "0rem",
        "components-accordion-spacing-small-item-padding-y": "1rem",
        "components-accordion-spacing-small-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-small-content-padding-x": "0rem",
        "components-accordion-spacing-small-content-padding-y": "0.75rem",
        "components-accordion-spacing-small-content-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-content-content-space-between": "1rem",
        "components-accordion-spacing-small-content-content-margin-left": "1rem",
        "components-article-card-spacing-large-padding-image-x": "0rem",
        "components-article-card-spacing-large-padding-image-y": "0rem",
        "components-article-card-spacing-large-padding-content-x": "0rem",
        "components-article-card-spacing-large-padding-content-y": "1.5rem",
        "components-article-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-article-card-spacing-large-button-space-between": "1rem",
        "components-article-card-spacing-large-title-margin-bottom": "1rem",
        "components-article-card-spacing-large-list-content-gap": "1rem",
        "components-article-card-spacing-large-list-image-padding-x": "0rem",
        "components-article-card-spacing-large-list-image-padding-y": "0rem",
        "components-article-card-spacing-small-padding-image-x": "0rem",
        "components-article-card-spacing-small-padding-image-y": "0rem",
        "components-article-card-spacing-small-padding-content-x": "1.5rem",
        "components-article-card-spacing-small-padding-content-y": "1.5rem",
        "components-article-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-article-card-listing-spacing-small-padding-x": "1.5rem",
        "components-article-card-listing-spacing-small-padding-y": "2.5rem",
        "components-article-hero-spacing-large-padding-x": "0rem",
        "components-article-hero-spacing-large-padding-y": "0rem",
        "components-article-hero-spacing-large-title-margin-bottom": "2rem",
        "components-article-hero-spacing-large-label-share-margin-bottom": "2rem",
        "components-article-hero-spacing-large-author-name-margin-bottom": "0.75rem",
        "components-article-hero-spacing-large-author-thumbnail-margin-left": "1rem",
        "components-article-hero-spacing-large-details-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-space-between": "1rem",
        "components-article-hero-spacing-large-body-margin-bottom": "2rem",
        "components-article-hero-spacing-large-image-space-between": "1rem",
        "components-article-hero-spacing-large-image-margin-bottom": "1rem",
        "components-article-hero-spacing-large-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-stats-margin-bottom": "2.5rem",
        "components-article-hero-spacing-small-content-margin-top": "2.5rem",
        "components-article-hero-spacing-small-title-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-label-share-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-authorname-margin-bottom": "0.75rem",
        "components-article-hero-spacing-small-authorthumbnail-margin-left": "1rem",
        "components-article-hero-spacing-small-details-margin-bottom": "1rem",
        "components-article-hero-spacing-small-space-between": "0.75rem",
        "components-article-hero-spacing-small-body-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-image-space-between": "0.75rem",
        "components-article-hero-spacing-small-image-margin-bottom": "1rem",
        "components-article-hero-spacing-small-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-stats-margin-bottom": "2rem",
        "components-banner-spacing-large-content-padding-x": "1.5rem",
        "components-banner-spacing-large-content-space-between": "1rem",
        "components-banner-spacing-large-content-width": "69.375rem",
        "components-banner-spacing-icon-dimensions": "1.5rem",
        "components-banner-spacing-icon-margin-left": "0.5rem",
        "components-banner-spacing-small-space-between": "1rem",
        "components-banner-spacing-small-content-space-between": "1rem",
        "components-banner-spacing-small-title-margin-bottom": "0.75rem",
        "components-banner-spacing-small-content-margin-bottom": "1rem",
        "components-breadcrumb-spacing-large-space-between": "0.25rem",
        "components-breadcrumb-spacing-small-space-between": "0.25rem",
        "components-button-spacing-large-icon-large-x": "2.5rem",
        "components-button-spacing-large-icon-large-y": "2.5rem",
        "components-button-spacing-large-default-padding-y": "0.75rem",
        "components-button-spacing-large-default-padding-x": "1.5rem",
        "components-button-spacing-large-default-space-between": "0.25rem",
        "components-button-spacing-large-icon-left-padding-left": "1rem",
        "components-button-spacing-large-icon-right-padding-right": "1rem",
        "components-button-spacing-large-icon-only-padding-x": "0.75rem",
        "components-button-spacing-large-icon-only-padding-y": "0.75rem",
        "components-button-spacing-large-tertiary-content-height": "1.5rem",
        "components-button-spacing-compressed-icon-large-x": "2.5rem",
        "components-button-spacing-compressed-icon-large-y": "2.5rem",
        "components-button-spacing-compressed-default-padding-y": "0.5rem",
        "components-button-spacing-compressed-default-padding-x": "1rem",
        "components-button-spacing-compressed-default-space-between": "0.125rem",
        "components-button-spacing-compressed-icon-left-padding-left": "0.5rem",
        "components-button-spacing-compressed-icon-right-padding-right": "0.5rem",
        "components-button-spacing-compressed-icon-only-padding-x": "0.5rem",
        "components-button-group-spacing-padding-y": "0.125rem",
        "components-button-group-spacing-padding-x": "0.25rem",
        "components-button-group-spacing-space-between": "0.5rem",
        "components-category-card-spacing-large-padding-top": "0rem",
        "components-category-card-spacing-large-padding-x": "0rem",
        "components-category-card-spacing-large-padding-y": "0rem",
        "components-category-card-spacing-large-padding-image-x": "0rem",
        "components-category-card-spacing-large-padding-image-y": "0rem",
        "components-category-card-spacing-large-padding-content-x": "2rem",
        "components-category-card-spacing-large-padding-content-y": "2rem",
        "components-category-card-listing-spacing-large-padding-y": "5rem",
        "components-category-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-category-card-listing-spacing-large-card-margin-bottom": "0rem",
        "components-category-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-card-search-result-small-padding-top": "0rem",
        "components-card-search-result-small-padding-x": "0rem",
        "components-card-search-result-small-padding-y": "0rem",
        "components-card-search-result-small-padding-image-x": "0rem",
        "components-card-search-result-small-padding-image-y": "0rem",
        "components-card-search-result-small-padding-content-x": "1.5rem",
        "components-card-search-result-small-padding-content-y": "1.5rem",
        "components-card-search-result-spacing-large-padding-x": "0rem",
        "components-card-search-result-spacing-large-padding-y": "1.5rem",
        "components-card-search-result-spacing-large-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-image-margin-right": "1.5rem",
        "components-card-search-result-spacing-small-padding-x": "1.5rem",
        "components-card-search-result-spacing-small-padding-y": "1.5rem",
        "components-card-search-result-spacing-small-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-image-margin-right": "0.75rem",
        "components-contact-us-promo-spacing-large-padding-x": "5rem",
        "components-contact-us-promo-spacing-large-padding-y": "5rem",
        "components-contact-us-promo-spacing-large-content-margin-right": "5rem",
        "components-contact-us-promo-spacing-large-margin-y": "2.5rem",
        "components-contact-us-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-contact-us-promo-spacing-small-margin-y": "2.5rem",
        "components-content-list-list-item-container-padding-x": "0.75rem",
        "components-content-list-list-item-container-padding-y": "1rem",
        "components-content-list-list-item-content-space-between": "0.5rem",
        "components-content-list-list-item-content-default-padding-y": "0.25rem",
        "components-content-list-list-item-content-hyperlink-padding-y": "0rem",
        "components-content-list-spacing-large-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-large-item-title-margin-bottom": "1rem",
        "components-content-list-spacing-large-item-copy-margin-bottom": "2.5rem",
        "components-content-list-spacing-large-item-icon-copy-margin-top": "0rem",
        "components-content-list-spacing-large-item-margin-bottom": "2rem",
        "components-content-list-spacing-large-item-image-margin-bottom": "0.5rem",
        "components-content-list-spacing-large-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-y": "2.5rem",
        "components-content-list-spacing-small-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-small-item-title-margin-bottom": "0.5rem",
        "components-content-list-spacing-small-item-copy-margin-bottom": "1rem",
        "components-content-list-spacing-small-item-margin-bottom": "1.5rem",
        "components-content-list-spacing-small-header-title-margin-bottom": "1rem",
        "components-content-list-spacing-small-header-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-title-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-button-space-between": "1rem",
        "components-dropdown-item-padding-y": "0.75rem",
        "components-dropdown-item-padding-x": "0.75rem",
        "components-dropdown-item-spacing-x": "0.75rem",
        "components-dropdown-input-padding-y": "0.25rem",
        "components-dropdown-input-padding-x": "1rem",
        "components-footer-brand-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-large-column-margin-right": "4rem",
        "components-footer-brand-spacing-large-column-margin-bottom": "4rem",
        "components-footer-brand-spacing-large-link-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-padding-y": "2.5rem",
        "components-footer-brand-spacing-small-padding-x": "1rem",
        "components-footer-brand-spacing-small-logo-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-column-margin-right": "0.125rem",
        "components-footer-brand-spacing-small-column-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-small-link-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-title-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-text-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-footer-link-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-link-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-link-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-link-minheight": "3rem",
        "components-footer-brand-spacing-small-footer-category-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-category-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-category-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-category-minheight": "3rem",
        "components-footer-global-spacing-large-padding-y": "2.5rem",
        "components-footer-global-spacing-large-padding-x": "5rem",
        "components-footer-global-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-global-spacing-large-column-margin-right": "2rem",
        "components-footer-global-spacing-large-link-margin-bottom": "2rem",
        "components-footer-global-spacing-small-padding-y": "2.5rem",
        "components-footer-global-spacing-small-padding-x": "1rem",
        "components-footer-global-spacing-small-logo-margin-bottom": "1rem",
        "components-footer-global-spacing-small-column-margin-right": "1rem",
        "components-footer-global-spacing-small-link-margin-bottom": "1rem",
        "components-generic-card-spacing-large-padding-x": "0rem",
        "components-generic-card-spacing-large-padding-y": "0rem",
        "components-generic-card-spacing-large-padding-image-x": "0rem",
        "components-generic-card-spacing-large-padding-image-y": "0rem",
        "components-generic-card-spacing-large-padding-content-x": "1.5rem",
        "components-generic-card-spacing-large-padding-content-y": "1.5rem",
        "components-generic-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-generic-card-spacing-large-title-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-button-space-between": "1rem",
        "components-generic-card-spacing-small-padding-x": "0rem",
        "components-generic-card-spacing-small-padding-y": "0rem",
        "components-generic-card-spacing-small-padding-image-x": "0rem",
        "components-generic-card-spacing-small-padding-image-y": "0rem",
        "components-generic-card-spacing-small-padding-content-x": "0rem",
        "components-generic-card-spacing-small-padding-content-y": "0rem",
        "components-global-search-spacing-omnibox-padding-x": "0.125rem",
        "components-global-search-spacing-omnibox-list-padding-y": "0.5rem",
        "components-global-search-spacing-omnibox-label-padding": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-item-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-height": "3rem",
        "components-generic-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-generic-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-header-shadow-x": "0rem",
        "components-header-spacing-large-padding-y": "1rem",
        "components-header-spacing-large-padding-x": "1.5rem",
        "components-header-spacing-large-links-margin-right": "1.5rem",
        "components-header-spacing-large-icons-space-between": "1.5rem",
        "components-header-spacing-large-logo-margin-right": "2rem",
        "components-header-spacing-large-space-between-search": "0.5rem",
        "components-header-spacing-large-height": "5rem",
        "components-header-spacing-large-language-selector-padding-x": "1rem",
        "components-header-spacing-large-language-selector-padding-y": "0.5rem",
        "components-header-spacing-large-language-selector-space-between": "0.75rem",
        "components-header-spacing-large-language-selector-country-space-between": "0.5rem",
        "components-header-spacing-large-mega-menu-padding-y": "2.5rem",
        "components-header-spacing-large-mega-menu-padding-x": "2.5rem",
        "components-header-spacing-large-mega-menu-column-margin-right": "2.5rem",
        "components-header-spacing-large-mega-menu-category-link-margin-bottom": "1rem",
        "components-header-spacing-large-mega-menu-link-margin-bottom": "0.5rem",
        "components-header-spacing-large-mega-menu-featured-padding-x": "2rem",
        "components-header-spacing-large-mega-menu-featured-padding-y": "2rem",
        "components-header-spacing-large-mega-menu-featured-image-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-title-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-link-space-between": "1.5rem",
        "components-header-spacing-large-mega-menu-title-margin-bottom": "2.5rem",
        "components-header-spacing-large-dropdown-padding-y": "1.5rem",
        "components-header-spacing-large-dropdown-padding-x": "1.5rem",
        "components-header-spacing-large-dropdown-margin-bottom": "0.5rem",
        "components-header-spacing-small-language-selector-padding-x": "1rem",
        "components-header-spacing-small-language-selector-padding-y": "0.5rem",
        "components-header-spacing-small-language-selector-space-between": "0.5rem",
        "components-header-spacing-small-padding-left": "1rem",
        "components-header-spacing-small-padding-right": "1rem",
        "components-header-spacing-small-padding-top": "0.5rem",
        "components-header-spacing-small-padding-bottom": "0.5rem",
        "components-header-spacing-small-nav-main-minheight": "3rem",
        "components-header-spacing-small-nav-main-padding-y": "0.5rem",
        "components-header-spacing-small-nav-main-padding-left": "1.5rem",
        "components-header-spacing-small-nav-main-padding-right": "1rem",
        "components-header-spacing-small-nav-category-minheight": "3rem",
        "components-header-spacing-small-nav-category-padding-left": "1.5rem",
        "components-header-spacing-small-nav-category-padding-right": "0.75rem",
        "components-header-spacing-small-nav-category-padding-y": "0.75rem",
        "components-header-spacing-small-nav-category-space-between": "0rem",
        "components-header-spacing-small-nav-menu-link-minheight": "3rem",
        "components-header-spacing-small-nav-menu-link-padding-right": "1.5rem",
        "components-header-spacing-small-nav-menu-link-padding-left": "2.5rem",
        "components-header-spacing-small-nav-menu-link-padding-y": "0.75rem",
        "components-header-spacing-small-mega-menu-padding-y": "0rem",
        "components-header-spacing-small-mega-menu-padding-x": "0rem",
        "components-header-spacing-small-mega-menu-category-link-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-link-margin-bottom": "0rem",
        "components-header-spacing-small-mega-menu-featured-padding-x": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-padding-y": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-image-margin-bottom": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-title-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-featured-link-space-between": "0.75rem",
        "components-header-spacing-small-mega-menu-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-container-padding": "1rem",
        "components-hero-homepage-spacing-large-padding-x": "4rem",
        "components-hero-homepage-spacing-large-padding-y": "2.5rem",
        "components-hero-homepage-spacing-large-minheight": "34.3125rem",
        "components-hero-homepage-spacing-large-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-margin-bottom": "2rem",
        "components-hero-homepage-spacing-large-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-copy-margin-bottom": "2.5rem",
        "components-hero-homepage-spacing-large-cta-margin-right": "1rem",
        "components-hero-homepage-spacing-large-image-height": "34.3125rem",
        "components-hero-homepage-spacing-large-image-width": "45.75rem",
        "components-hero-homepage-spacing-small-padding-x": "0rem",
        "components-hero-homepage-spacing-small-padding-y": "1.5rem",
        "components-hero-homepage-spacing-small-minheight": "34.3125rem",
        "components-hero-homepage-spacing-small-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-copy-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-image-height": "17.5625rem",
        "components-hero-homepage-spacing-small-image-width": "45.75rem",
        "components-hero-internal-spacing-large-padding-y": "2.5rem",
        "components-hero-internal-spacing-large-padding-right": "10rem",
        "components-hero-internal-spacing-large-title-margin-bottom": "1.5rem",
        "components-hero-internal-spacing-large-button-padding-y": "2rem",
        "components-hero-internal-spacing-large-text-maxwidth": "59.625rem",
        "components-hero-internal-spacing-large-minheight": "18.5rem",
        "components-hero-internal-spacing-small-padding-x": "1.5rem",
        "components-hero-internal-spacing-small-padding-right": "0rem",
        "components-hero-internal-spacing-small-button-padding-y": "1.5rem",
        "components-hero-internal-spacing-small-text-maxwidth": "30rem",
        "components-hero-internal-spacing-small-minheight": "13.5rem",
        "components-icon-card-spacing-large-padding-x": "0rem",
        "components-icon-card-spacing-large-padding-y": "0rem",
        "components-icon-card-spacing-large-icon-margin": "1.5rem",
        "components-icon-card-spacing-large-margin-right": "1.5rem",
        "components-icon-card-spacing-large-title-margin-bottom": "1rem",
        "components-icon-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-icon-card-spacing-large-button-space-between": "1.5rem",
        "components-icon-card-spacing-large-top-space-between": "1rem",
        "components-icon-card-spacing-small-padding-x": "1.5rem",
        "components-icon-card-spacing-small-padding-y": "1.5rem",
        "components-icon-card-spacing-small-icon-margin": "1.5rem",
        "components-icon-card-spacing-small-margin-right": "1.5rem",
        "components-icon-card-spacing-small-title-margin-bottom": "1.5rem",
        "components-icon-card-dimensions-large-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-large-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-large-big-icon-height": "4rem",
        "components-icon-card-dimensions-large-big-icon-width": "4rem",
        "components-icon-card-dimensions-large-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-large-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-large-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-large-tiny-icon-width": "2.5rem",
        "components-icon-card-dimensions-small-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-small-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-small-big-icon-height": "3.5rem",
        "components-icon-card-dimensions-small-big-icon-width": "3.5rem",
        "components-icon-card-dimensions-small-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-small-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-small-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-small-tiny-icon-width": "2.5rem",
        "components-icon-card-listing-spacing-large-padding-inner-x": "4rem",
        "components-icon-card-listing-spacing-large-padding-inner-y": "4rem",
        "components-icon-card-listing-spacing-large-card-space-between": "4rem",
        "components-icon-card-listing-spacing-large-title-margin-bottom": "1.5rem",
        "components-icon-card-listing-spacing-large-card-margin-bottom": "4rem",
        "components-icon-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-x": "1.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-y": "2.5rem",
        "components-icon-card-listing-spacing-small-title-margin-bottom": "1rem",
        "components-icon-card-listing-spacing-small-button-margin-top": "1.5rem",
        "components-jumplink-link-padding-bottom": "0.5rem",
        "components-jumplink-spacing-small-title-area-margin-bottom": "1.5rem",
        "components-jumplink-spacing-small-link-container-padding-x": "0rem",
        "components-lightbox-spacing-large-carousel-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-carousel-space-between": "1.5rem",
        "components-lightbox-spacing-large-featured-image-height": "39.25rem",
        "components-lightbox-spacing-large-featured-image-width": "39.25rem",
        "components-lightbox-spacing-large-image-height": "17.0625rem",
        "components-lightbox-spacing-large-image-width": "17.0625rem",
        "components-lightbox-spacing-small-carousel-margin-bottom": "1rem",
        "components-lightbox-spacing-small-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-small-media-details-margin-top": "1rem",
        "components-lightbox-spacing-small-image-height": "20.4375rem",
        "components-lightbox-spacing-small-image-width": "20.4375rem",
        "components-legal-disclaimer-spacing-small-ruleline-width": "20.4375rem",
        "components-media-spacing-large-padding-y": "0.5rem",
        "components-media-spacing-large-padding-x": "0.75rem",
        "components-media-gallery-large-container-grid-gap-x": "1.5rem",
        "components-media-gallery-large-container-grid-gap-y": "1.5rem",
        "components-media-gallery-small-container-grid-gap-x": "0.5rem",
        "components-media-gallery-small-container-grid-gap-y": "0.5rem",
        "components-media-gallery-spacing-small-padding-x": "1.5rem",
        "components-media-gallery-spacing-small-padding-y": "2.5rem",
        "components-media-gallery-spacing-large-padding-x": "5rem",
        "components-media-gallery-spacing-large-padding-y": "5rem",
        "components-product-card-spacing-list-content-gap": "0rem",
        "components-product-card-spacing-large-padding-image-x": "0rem",
        "components-product-card-spacing-large-padding-image-y": "0rem",
        "components-product-card-spacing-large-padding-content-x": "1.5rem",
        "components-product-card-spacing-large-padding-content-y": "1.5rem",
        "components-product-card-spacing-large-category-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-title-margin-bottom": "1rem",
        "components-product-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-button-space-between": "1rem",
        "components-product-card-spacing-small-padding-image-x": "0rem",
        "components-product-card-spacing-small-padding-image-y": "0rem",
        "components-product-card-spacing-small-padding-content-x": "1.5rem",
        "components-product-card-spacing-small-padding-content-y": "1.5rem",
        "components-product-card-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-large-padding-x": "5rem",
        "components-product-information-spacing-large-padding-y": "2.5rem",
        "components-product-information-spacing-large-title-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-label-margin-bottom": "2.5rem",
        "components-product-information-spacing-large-ratings-margin-bottom": "1rem",
        "components-product-information-spacing-large-details-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-space-between": "1rem",
        "components-product-information-spacing-large-carousel-space-between": "5rem",
        "components-product-information-spacing-large-button-margin-y": "0.5rem",
        "components-product-information-spacing-large-body-margin-top": "1.5rem",
        "components-product-information-spacing-large-image-space-between": "1rem",
        "components-product-information-spacing-large-image-margin-bottom": "1rem",
        "components-product-information-spacing-small-padding-x": "1.5rem",
        "components-product-information-spacing-small-padding-y": "1.5rem",
        "components-product-information-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-small-label-margin-bottom": "1.5rem",
        "components-product-information-spacing-small-ratings-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-details-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-space-between": "0.75rem",
        "components-product-information-spacing-small-carousel-space-between": "2.5rem",
        "components-product-information-spacing-small-button-margin-y": "0.75rem",
        "components-product-information-spacing-small-body-margin-top": "1rem",
        "components-product-information-spacing-small-image-space-between": "0.75rem",
        "components-product-information-spacing-small-image-margin-bottom": "1rem",
        "components-product-results-list-with-search-spacing-large-padding-x": "5rem",
        "components-product-results-list-with-search-spacing-large-padding-y": "5rem",
        "components-product-results-list-with-search-spacing-large-ruleline-margin-top": "2.5rem",
        "components-product-results-list-with-search-spacing-large-filter-space-between": "1rem",
        "components-product-results-list-with-search-spacing-large-card-space-between": "1.5rem",
        "components-product-results-list-with-search-spacing-large-card-container-margin-bottom": "5rem",
        "components-product-results-list-with-search-spacing-small-padding-x": "1.5rem",
        "components-product-results-list-with-search-spacing-small-padding-y": "2.5rem",
        "components-product-results-list-with-search-spacing-small-ruleline-margin-top": "1rem",
        "components-product-results-list-with-search-spacing-small-filter-padding-x": "0.5rem",
        "components-product-results-list-with-search-spacing-small-card-space-between": "1rem",
        "components-product-results-list-with-search-spacing-small-card-margin-bottom": "1.5rem",
        "components-promo-spacing-large-padding-x": "5rem",
        "components-promo-spacing-large-padding-y": "5rem",
        "components-promo-spacing-large-content-space-between": "5rem",
        "components-promo-spacing-large-content-space-between-y": "5rem",
        "components-promo-spacing-large-padding-content-x": "0rem",
        "components-promo-spacing-large-padding-content-condensed-x": "0rem",
        "components-promo-spacing-large-padding-content-y": "0rem",
        "components-promo-spacing-large-padding-content-condensed-y": "0rem",
        "components-promo-spacing-large-padding-image-x": "0rem",
        "components-promo-spacing-large-padding-image-y": "0rem",
        "components-promo-spacing-large-padding-image-top": "5rem",
        "components-promo-spacing-large-title-margin-bottom": "2rem",
        "components-promo-spacing-large-margin-y": "2rem",
        "components-promo-spacing-large-buttons-space-between": "1rem",
        "components-promo-spacing-small-padding-x": "0rem",
        "components-promo-spacing-small-padding-y": "0rem",
        "components-promo-spacing-small-padding-top": "0rem",
        "components-promo-spacing-small-padding-content-x": "1.5rem",
        "components-promo-spacing-small-padding-content-y": "2.5rem",
        "components-promo-spacing-small-padding-image-x": "0rem",
        "components-promo-spacing-small-padding-image-y": "0rem",
        "components-promo-spacing-small-padding-image-top": "0rem",
        "components-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-promo-spacing-small-margin-y": "2.5rem",
        "components-promo-minheight": "30rem",
        "components-pull-quote-spacing-large-padding-quote-x": "4rem",
        "components-pull-quote-spacing-large-padding-quote-y": "4rem",
        "components-pull-quote-spacing-large-title-margin-bottom": "2rem",
        "components-pull-quote-spacing-large-quote-margin-bottom": "4rem",
        "components-pull-quote-spacing-large-quote-space-between": "1.5rem",
        "components-pull-quote-spacing-large-detail-space-between": "0.5rem",
        "components-pull-quote-spacing-large-quote-minheight": "25rem",
        "components-pull-quote-spacing-small-padding-quote-x": "1.5rem",
        "components-pull-quote-spacing-small-padding-quote-y": "2.5rem",
        "components-pull-quote-spacing-small-title-margin-bottom": "1.5rem",
        "components-pull-quote-spacing-small-quote-margin-bottom": "2.5rem",
        "components-pull-quote-spacing-small-quote-space-between": "1rem",
        "components-pull-quote-spacing-small-detail-space-between": "1rem",
        "components-pull-quote-spacing-small-quote-minheight": "15rem",
        "components-promo-alternate-minheight": "30rem",
        "components-promo-alternate-spacing-large-padding-x": "5rem",
        "components-promo-alternate-spacing-large-padding-y": "5rem",
        "components-promo-alternate-spacing-large-content-space-between": "5rem",
        "components-promo-alternate-spacing-large-padding-content-x": "0rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-x": "0rem",
        "components-promo-alternate-spacing-large-padding-content-y": "0rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-y": "0rem",
        "components-promo-alternate-spacing-large-padding-image-x": "0rem",
        "components-promo-alternate-spacing-large-padding-image-y": "0rem",
        "components-promo-alternate-spacing-large-padding-image-top": "5rem",
        "components-promo-alternate-spacing-large-title-margin-bottom": "2rem",
        "components-promo-alternate-spacing-small-padding-x": "0rem",
        "components-promo-alternate-spacing-small-padding-y": "0rem",
        "components-promo-alternate-spacing-small-padding-content-x": "1.5rem",
        "components-promo-alternate-spacing-small-padding-content-y": "2.5rem",
        "components-promo-alternate-spacing-small-padding-image-x": "0rem",
        "components-promo-alternate-spacing-small-padding-image-y": "0rem",
        "components-promo-alternate-spacing-small-padding-image-top": "0rem",
        "components-promo-alternate-spacing-small-title-margin-bottom": "1.5rem",
        "components-share-list-spacing-padding-x": "1rem",
        "components-share-list-spacing-padding-y": "1rem",
        "components-header-copy-margin-bottom": "1rem",
        "components-header-margin-bottom": "1.5rem",
        "components-item-margin-bottom": "1.5rem",
        "components-site-search-padding-x": "2.5rem",
        "components-site-search-padding-y": "5rem",
        "components-site-search-facet-label-space-between": "0.25rem",
        "components-site-search-refine-padding-x": "1.5rem",
        "components-site-search-refine-padding-y": "0rem",
        "components-site-search-hero-search-height": "18.5rem",
        "components-site-search-hero-search-large-padding-x": "5rem",
        "components-site-search-hero-search-large-padding-y": "5rem",
        "components-stats-spacing-stat-text-space-between": "0.5rem",
        "components-stats-spacing-large-inner-padding-x": "5rem",
        "components-stats-spacing-large-inner-padding-y": "5rem",
        "components-stats-spacing-small-inner-padding-x": "1.5rem",
        "components-stats-spacing-small-inner-padding-y": "1.5rem",
        "components-icon-card-listing-spacing-small-top-space-between": "1rem",
        "components-supporting-text-padding-x": "0rem",
        "components-supporting-text-padding-bottom": "0.5rem",
        "components-tab-item-padding-y": "0.75rem",
        "components-tab-item-padding-x": "0.75rem",
        "components-tab-item-spacing-x": "0.75rem",
        "components-tab-input-padding-y": "0.25rem",
        "components-tab-input-padding-x": "1rem",
        "components-tag-spacing-padding-x": "0.5rem",
        "components-tag-spacing-padding-y": "0.5rem",
        "components-tag-spacing-space-between": "0.5rem",
        "components-text-field-input-padding-y": "0.25rem",
        "components-text-field-input-padding-x": "1rem",
        "components-text-field-area-input-padding-y": "1rem",
        "components-text-field-area-input-padding-x": "1rem",
        "components-text-field-supporting-text-padding-x": "0rem",
        "components-text-field-supporting-text-padding-top": "0.5rem",
        "themes-general-surface-spacing-large-padding-x": "5rem",
        "themes-general-surface-spacing-large-padding-y": "5rem",
        "themes-general-surface-spacing-large-title-margin-bottom": "2rem",
        "themes-general-surface-spacing-large-body-margin-right": "4rem",
        "themes-general-surface-spacing-large-body-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-large-margin-bottom": "4rem",
        "themes-general-surface-spacing-large-cards-space-between": "1.5rem",
        "themes-general-surface-spacing-large-button-margin-top": "2.5rem",
        "themes-general-surface-spacing-small-padding-x": "1.5rem",
        "themes-general-surface-spacing-small-padding-y": "2.5rem",
        "themes-general-surface-spacing-small-title-margin-bottom": "1rem",
        "themes-general-surface-spacing-small-body-margin-right": "2.5rem",
        "themes-general-surface-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-surface-spacing-small-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-small-cards-space-between": "1rem",
        "themes-general-surface-spacing-small-button-margin-top": "1.5rem",
        "themes-general-card-spacing-large-eyebrow-margin-bottom": "1.5rem",
        "themes-general-card-spacing-large-title-margin-bottom": "1.5rem",
        "themes-general-card-spacing-large-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-large-button-space-between": "1.5rem",
        "themes-general-card-spacing-small-eyebrow-margin-bottom": "1rem",
        "themes-general-card-spacing-small-title-margin-bottom": "1.5rem",
        "themes-general-card-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-small-button-space-between": "1.5rem",
        "themes-shadow-share-list-spacing-x": "0rem",
        "themes-shadow-share-list-spacing-y": "0.75rem",
        "themes-shadow-share-list-spacing-blur": "1rem",
        "themes-shadow-share-list-spacing-spread": "-0.5rem",
        "themes-shadow-header-menu-spacing-x": "0rem",
        "themes-shadow-header-menu-spacing-y": "0.75rem",
        "themes-shadow-header-menu-spacing-blur": "1rem",
        "themes-shadow-header-menu-spacing-spread": "-0.5rem",
        "themes-typography-rte-large-h1-textsize": "3rem",
        "themes-typography-rte-large-h2-textsize": "2.5rem",
        "themes-typography-rte-large-h3-textsize": "2.25rem",
        "themes-typography-rte-large-h4-textsize": "2rem",
        "themes-typography-rte-large-h5-textsize": "1.5rem",
        "themes-typography-rte-large-h6-textsize": "1.25rem",
        "themes-typography-rte-medium-h1-textsize": "2.5rem",
        "themes-typography-rte-medium-h2-textsize": "2.25rem",
        "themes-typography-rte-medium-h3-textsize": "1.75rem",
        "themes-typography-rte-medium-h4-textsize": "1.5rem",
        "themes-typography-rte-medium-h5-textsize": "1.25rem",
        "themes-typography-rte-medium-h6-textsize": "1.125rem",
        "themes-typography-rte-small-h1-textsize": "2.5rem",
        "themes-typography-rte-small-h2-textsize": "2rem",
        "themes-typography-rte-small-h3-textsize": "1.75rem",
        "themes-typography-rte-small-h4-textsize": "1.5rem",
        "themes-typography-rte-small-h5-textsize": "1.125rem",
        "themes-typography-rte-small-h6-textsize": "1rem",
        "spacing-margin-small-1": "0.125rem",
        "spacing-margin-small-2": "0.25rem",
        "spacing-margin-small-3": "0.5rem",
        "spacing-margin-small-4": "0.75rem",
        "spacing-margin-small-5": "1rem",
        "spacing-margin-small-6": "1.5rem",
        "spacing-margin-small-7": "2.5rem",
        "spacing-margin-medium-2": "0.75rem",
        "spacing-margin-medium-4": "1.5rem",
        "spacing-margin-medium-5": "2rem",
        "spacing-margin-large-1": "0.5rem",
        "spacing-margin-large-2": "1rem",
        "spacing-margin-large-3": "1.5rem",
        "spacing-margin-large-4": "2rem",
        "spacing-margin-large-5": "2.5rem",
        "spacing-margin-large-6": "4rem",
        "spacing-margin-large-7": "5rem",
        "spacing-space-between-small-1": "0.125rem",
        "spacing-space-between-small-2": "0.25rem",
        "spacing-space-between-small-3": "0.5rem",
        "spacing-space-between-small-4": "0.75rem",
        "spacing-space-between-small-5": "1rem",
        "spacing-space-between-small-6": "1.5rem",
        "spacing-space-between-small-7": "2.5rem",
        "spacing-space-between-medium-1": "0.125rem",
        "spacing-space-between-medium-2": "0.25rem",
        "spacing-space-between-medium-3": "0.5rem",
        "spacing-space-between-medium-4": "0.75rem",
        "spacing-space-between-medium-5": "1rem",
        "spacing-space-between-large-1": "0.25rem",
        "spacing-space-between-large-2": "0.5rem",
        "spacing-space-between-large-3": "1rem",
        "spacing-space-between-large-4": "1.5rem",
        "spacing-space-between-large-5": "1.5rem",
        "spacing-space-between-large-6": "2.5rem",
        "spacing-space-between-large-7": "4rem",
        "spacing-padding-small-1": "0.125rem",
        "spacing-padding-small-2": "0.25rem",
        "spacing-padding-small-3": "0.5rem",
        "spacing-padding-small-4": "0.75rem",
        "spacing-padding-small-5": "1rem",
        "spacing-padding-small-6": "1.5rem",
        "spacing-padding-small-7": "2.5rem",
        "spacing-padding-medium-1": "0.25rem",
        "spacing-padding-medium-2": "0.75rem",
        "spacing-padding-medium-3": "1rem",
        "spacing-padding-medium-4": "1.5rem",
        "spacing-padding-medium-5": "2rem",
        "spacing-padding-medium-6": "3rem",
        "spacing-padding-medium-7": "4rem",
        "spacing-padding-large-1": "0.5rem",
        "spacing-padding-large-2": "1rem",
        "spacing-padding-large-3": "1.5rem",
        "spacing-padding-large-4": "2rem",
        "spacing-padding-large-5": "2.5rem",
        "spacing-padding-large-6": "4rem",
        "spacing-padding-large-7": "5rem"
      },
      "string": {
        "components-accordion-section-button": "var(--tonal)",
        "components-article-card-listing-button-theme": "var(--tonal)",
        "components-article-hero-pagination-buttons": "var(--brand)",
        "components-banner-banner-button": "var(--white)",
        "components-category-card-listing-button-theme": "var(--tonal)",
        "components-contact-us-promo-hierarchy-button-theme-1": "var(--filled)",
        "components-contact-us-promo-hierarchy-button-theme-2": "var(--outlined)",
        "components-contact-us-promo-color-button-theme": "var(--brand)",
        "components-contact-us-promo-color-brand-button-theme": "var(--brand)",
        "components-generic-card-button-theme": "var(--brand)",
        "components-global-search-loader": "var(--default)",
        "components-generic-card-listing-decor-top": "var(--raid-1)",
        "components-generic-card-listing-decor-bottom": "var(--raid-2)",
        "components-hero-homepage-pagination-buttons": "var(--tonal)",
        "components-hero-homepage-pattern": "var(--pyrel)",
        "components-hero-homepage-button-1": "var(--brand)",
        "components-hero-homepage-button-2": "var(--brand)",
        "components-hero-homepage-image-ratio": "var(--5:4)",
        "components-hero-internal-theme-button-one-type": "var(--filled)",
        "components-hero-internal-theme-button-two-type": "var(--outlined)",
        "components-hero-internal-theme-button-color": "var(--white)",
        "components-hero-internal-theme-button-color-with-image": "var(--white)",
        "components-lightbox-button-type": "var(--brand)",
        "components-pdf-link-card-hierarchy-button-theme-1": "var(--filled)",
        "components-pdf-link-card-hierarchy-button-theme-2": "var(--outlined)",
        "components-pdf-link-card-color-button-theme": "var(--brand)",
        "components-product-card-badge-align": "var(--left)",
        "components-product-card-listing-button-color": "var(--brand)",
        "components-promo-theme-button-one-theme": "var(--filled)",
        "components-promo-theme-button-two-theme": "var(--outlined)",
        "components-promo-color-default-button-theme": "var(--brand)",
        "components-promo-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-color-brand-button-theme": "var(--white)",
        "components-pull-quote-border-decor": "var(--glade)",
        "components-promo-alternate-theme-button-one-theme": "var(--filled)",
        "components-promo-alternate-theme-button-two-theme": "var(--outlined)",
        "components-promo-alternate-color-default-button-theme": "var(--brand)",
        "components-promo-alternate-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-alternate-color-brand-button-theme": "var(--white)",
        "components-share-list-button-color": "var(--brand)",
        "components-site-search-hero-search-hero-pattern": "var(--none)",
        "components-stats-decor-top": "var(--raid-1)",
        "components-stats-decor-bottom": "var(--raid-2)",
        "themes-brand-loader": "var(--default)",
        "themes-brand-variants": "var(--pyrel)",
        "themes-tab-variant": "var(--default)",
        "themes-label-variants": "var(--default)",
        "themes-logo": "var(--pyrel)",
        "themes-category-card": "var(--pyrel)",
        "themes-title-decor": "var(--raid)",
        "themes-typography-heading-fontfamily": "var(--avenir)",
        "themes-typography-heading-large-xxlarge-fontweight": "var(--black)",
        "themes-typography-heading-large-xlarge-fontweight": "var(--black)",
        "themes-typography-heading-large-large-fontweight": "var(--black)",
        "themes-typography-heading-large-medium-fontweight": "var(--black)",
        "themes-typography-heading-large-small-fontweight": "var(--black)",
        "themes-typography-heading-large-xsmall-fontweight": "var(--black)",
        "themes-typography-heading-large-xxsmall-fontweight": "var(--black)",
        "themes-typography-heading-medium-xxlarge-fontweight": "var(--black)",
        "themes-typography-heading-medium-xlarge-fontweight": "var(--black)",
        "themes-typography-heading-medium-large-fontweight": "var(--black)",
        "themes-typography-heading-medium-medium-fontweight": "var(--black)",
        "themes-typography-heading-medium-small-fontweight": "var(--black)",
        "themes-typography-heading-medium-xsmall-fontweight": "var(--black)",
        "themes-typography-heading-medium-xxsmall-fontweight": "var(--black)",
        "themes-typography-heading-small-xxlarge-fontweight": "var(--black)",
        "themes-typography-heading-small-xlarge-fontweight": "var(--black)",
        "themes-typography-heading-small-large-fontweight": "var(--black)",
        "themes-typography-heading-small-medium-fontweight": "var(--black)",
        "themes-typography-heading-small-small-fontweight": "var(--black)",
        "themes-typography-heading-small-xsmall-fontweight": "var(--black)",
        "themes-typography-heading-small-xxsmall-fontweight": "var(--black)",
        "themes-typography-rte-fontfamily": "var(--avenir)",
        "themes-typography-rte-fontweight": "var(--black)"
      },
      "borderRadius": {
        "components-accordion-spacing-large-item-item-radius": "0rem",
        "components-article-card-spacing-large-image-radius-tl": "0rem",
        "components-article-card-spacing-large-image-radius-tr": "0rem",
        "components-article-card-spacing-large-image-radius-bl": "0rem",
        "components-article-card-spacing-large-image-radius-br": "0rem",
        "components-article-card-spacing-large-list-image-radius-br": "0rem",
        "components-article-card-spacing-small-image-radius-br": "0rem",
        "components-article-card-spacing-small-list-image-radius-br": "0rem",
        "components-article-hero-spacing-large-content-radius": "0rem",
        "components-article-hero-spacing-small-content-radius": "1rem",
        "components-button-radius-large": "7.5rem",
        "components-button-group-spacing-border-radius": "7.5rem",
        "components-category-card-radius-border-width": "0.125rem",
        "components-category-card-radius-border-radius": "7.5rem",
        "components-category-card-radius-border-radius-condensed": "0.5rem",
        "components-category-card-radius-media-radius": "0rem",
        "components-content-list-icon-radius": "7.5rem",
        "components-fab-spacing-border-radius": "7.5rem",
        "components-header-spacing-large-search-border-radius-top": "0.25rem",
        "components-header-spacing-large-search-border-radius-bottom": "0.25rem",
        "components-header-spacing-large-language-selector-radius": "0.25rem",
        "components-header-spacing-large-dropdown-radius": "0rem",
        "components-hero-homepage-spacing-large-container-radius": "1rem",
        "components-hero-internal-spacing-large-icon-radius": "7.5rem",
        "components-hero-internal-spacing-small-icon-radius": "7.5rem",
        "components-lightbox-spacing-large-overlay-radius": "0rem",
        "components-pagination-scrollbar-radius": "62.5rem",
        "components-product-card-spacing-image-radius-tl": "0rem",
        "components-product-card-spacing-image-radius-tr": "0rem",
        "components-product-card-spacing-image-radius-bl": "0rem",
        "components-product-card-spacing-image-radius-br": "0rem",
        "components-product-information-spacing-large-content-radius": "0rem",
        "components-product-information-spacing-small-content-radius": "1rem",
        "components-promo-spacing-large-content-radius": "0rem",
        "components-promo-spacing-large-content-radius-tl": "0rem",
        "components-promo-spacing-large-content-radius-tr": "0rem",
        "components-promo-spacing-large-content-radius-bl": "0rem",
        "components-promo-spacing-large-content-radius-br": "0rem",
        "components-promo-spacing-large-image-radius-tl": "0rem",
        "components-promo-spacing-large-image-radius-tr": "0rem",
        "components-promo-spacing-large-image-radius-bl": "0rem",
        "components-promo-spacing-large-image-radius-br": "0rem",
        "components-promo-spacing-small-content-radius": "0rem",
        "components-promo-spacing-small-image-radius-tl": "0rem",
        "components-promo-spacing-small-image-radius-tr": "0rem",
        "components-promo-spacing-small-image-radius-bl": "0rem",
        "components-promo-spacing-small-image-radius-br": "0rem",
        "components-promo-alternate-spacing-large-content-radius": "0rem",
        "components-promo-alternate-spacing-large-image-radius-tl": "0rem",
        "components-promo-alternate-spacing-large-image-radius-tr": "0rem",
        "components-promo-alternate-spacing-large-image-radius-bl": "0rem",
        "components-promo-alternate-spacing-large-image-radius-br": "0rem",
        "components-promo-alternate-spacing-small-content-radius": "0rem",
        "components-promo-alternate-spacing-small-image-radius-tl": "0rem",
        "components-promo-alternate-spacing-small-image-radius-tr": "0rem",
        "components-promo-alternate-spacing-small-image-radius-bl": "0rem",
        "components-promo-alternate-spacing-small-image-radius-br": "0rem",
        "components-share-list-spacing-radius": "0.5rem",
        "components-share-list-spacing-link-radius": "0.5rem",
        "components-site-search-filter-radius": "0.5rem",
        "components-site-search-facet-radius": "0rem",
        "components-site-search-refine-radius": "0rem",
        "components-tag-spacing-radius": "7.5rem",
        "components-tip-spacing-radius": "0.5rem",
        "themes-spacing-radius-pagination-bar": "62.5rem",
        "themes-spacing-radius-large-button": "7.5rem",
        "themes-spacing-radius-large-button-icon": "7.5rem",
        "themes-spacing-radius-large-image": "0rem",
        "themes-spacing-radius-large-image-hero": "0rem",
        "themes-spacing-radius-large-image-card": "0rem",
        "themes-spacing-radius-large-card": "0rem",
        "themes-spacing-radius-large-icon": "7.5rem",
        "themes-spacing-radius-large-label": "7.5rem",
        "themes-spacing-radius-large-tag": "7.5rem",
        "themes-spacing-radius-large-container-tip": "0.5rem",
        "themes-spacing-radius-large-badge": "0.25rem",
        "themes-spacing-radius-large-item": "0.5rem",
        "themes-spacing-radius-large-form-field-input": "0.25rem",
        "themes-spacing-radius-large-form-area-input": "0.25rem",
        "themes-spacing-radius-large-general": "0rem",
        "themes-spacing-radius-small-button": "7.5rem",
        "themes-spacing-radius-small-button-icon": "7.5rem",
        "themes-spacing-radius-small-image": "0rem",
        "themes-spacing-radius-small-image-card": "0.75rem",
        "themes-spacing-radius-small-card": "0rem",
        "themes-spacing-radius-small-icon": "7.5rem",
        "themes-spacing-radius-small-label": "0.125rem",
        "themes-spacing-radius-small-tag": "0.25rem",
        "themes-spacing-radius-small-badge": "0rem",
        "themes-spacing-radius-small-item": "0rem",
        "themes-spacing-radius-small-form-field-input": "0.5rem",
        "themes-spacing-radius-small-general": "1rem"
      },
      "borderWidth": {
        "3": "3px",
        "components-article-card-spacing-large-border-width": "0rem",
        "components-generic-card-color-border-width": "0.0625rem",
        "components-header-spacing-large-search-border-width-top": "0.125rem",
        "components-header-spacing-large-search-border-width-right": "0.125rem",
        "components-header-spacing-large-search-border-width-bottom": "0.125rem",
        "components-header-spacing-large-search-border-width-left": "0.125rem",
        "components-product-card-spacing-border-width": "0.125rem"
      },
      "maxHeight": {},
      "maxWidth": {
        "components-article-hero-spacing-large-header-max-width": "66.375rem",
        "components-content-list-spacing-large-item-image-max-width": "33.75rem",
        "components-content-list-spacing-small-item-image-max-width": "33.75rem"
      },
      "minHeight": {
        "components-dropdown-item-min-height": "3rem",
        "components-icon-min-height": "3rem",
        "components-tab-item-min-height": "3rem",
        "components-text-field-input-min-height": "3rem",
        "components-text-field-icon-min-height": "3rem"
      },
      "minWidth": {
        "components-content-list-spacing-small-item-image-min-width": "18.75rem",
        "components-icon-min-width": "3rem",
        "components-text-field-icon-min-width": "3rem"
      },
      "opacity": {
        "components-accessibility-bar-color-bg": 1,
        "components-accessibility-bar-color-text": 1,
        "components-accordion-color-bg": 1,
        "components-accordion-color-title": 1,
        "components-accordion-color-body": 1,
        "components-accordion-color-section-section-bg": 1,
        "components-accordion-color-section-section-title": 1,
        "components-accordion-color-section-section-body": 1,
        "components-accordion-color-item-item-bg": 1,
        "components-accordion-color-item-divider": 1,
        "components-accordion-color-item-icon-icon-default": 1,
        "components-accordion-color-item-icon-icon-hover": 1,
        "components-accordion-color-item-icon-icon-pressed": 1,
        "components-accordion-color-item-icon-icon-active": 1,
        "components-accordion-color-item-title-item-default": 1,
        "components-accordion-color-item-title-item-hover": 1,
        "components-accordion-color-item-title-item-pressed": 1,
        "components-accordion-color-item-title-item-active": 1,
        "components-accordion-color-content-bg": 1,
        "components-accordion-color-content-title": 1,
        "components-accordion-color-content-body": 1,
        "components-article-card-color-bg": 1,
        "components-article-card-color-border": 1,
        "components-article-card-color-content-bg": 1,
        "components-article-card-color-title": 1,
        "components-article-card-color-subtitle": 1,
        "components-article-card-color-copy": 1,
        "components-article-card-color-description": 1,
        "components-article-card-listing-color-default-bg": 1,
        "components-article-card-listing-color-default-title": 1,
        "components-article-card-listing-color-default-copy": 1,
        "components-article-hero-color-content-bg": 1,
        "components-article-hero-color-bg": 1,
        "components-article-hero-color-headline": 1,
        "components-article-hero-color-body": 1,
        "components-article-hero-color-detail-text": 1,
        "components-article-hero-color-divider": 1,
        "components-article-hero-color-icon": 1,
        "components-article-hero-color-play-icon": 1,
        "components-article-hero-color-selected-stroke": 1,
        "components-banner-color-bg-default": 1,
        "components-banner-color-icon-default": 1,
        "components-banner-color-title-default": 1,
        "components-banner-color-body-default": 1,
        "components-banner-color-icon-close-default": 1,
        "components-banner-color-bg-alert": 1,
        "components-banner-color-icon-alert": 1,
        "components-banner-color-title-alert": 1,
        "components-banner-color-body-alert": 1,
        "components-banner-color-icon-close-alert": 1,
        "components-breadcrumb-color-bg": 1,
        "components-breadcrumb-color-breadcrumb-link-default": 1,
        "components-breadcrumb-color-breadcrumb-link-hover": 1,
        "components-breadcrumb-color-breadcrumb-link-active": 1,
        "components-breadcrumb-color-icon-divider": 1,
        "components-bug-finder-prompt": 1,
        "components-button-color-filled-brand-default-bg": 1,
        "components-button-color-filled-brand-default-text": 1,
        "components-button-color-filled-brand-default-stroke": 1,
        "components-button-color-filled-brand-default-icon": 1,
        "components-button-color-filled-brand-hover-bg": 1,
        "components-button-color-filled-brand-hover-text": 1,
        "components-button-color-filled-brand-hover-stroke": 1,
        "components-button-color-filled-brand-hover-icon": 1,
        "components-button-color-filled-brand-pressed-bg": 1,
        "components-button-color-filled-brand-pressed-text": 1,
        "components-button-color-filled-brand-pressed-stroke": 1,
        "components-button-color-filled-brand-pressed-icon": 1,
        "components-button-color-filled-brand-disabled-bg": null,
        "components-button-color-filled-brand-disabled-text": 0.02,
        "components-button-color-filled-brand-disabled-stroke": null,
        "components-button-color-filled-brand-disabled-icon": 0.02,
        "components-button-color-filled-brand-focus-bg": 1,
        "components-button-color-filled-brand-focus-text": 1,
        "components-button-color-filled-brand-focus-stroke": 1,
        "components-button-color-filled-brand-focus-icon": 1,
        "components-button-color-filled-brand-focus-outline": 1,
        "components-button-color-filled-tonal-default-bg": 1,
        "components-button-color-filled-tonal-default-text": 1,
        "components-button-color-filled-tonal-default-stroke": 1,
        "components-button-color-filled-tonal-default-icon": 1,
        "components-button-color-filled-tonal-hover-bg": 1,
        "components-button-color-filled-tonal-hover-text": 1,
        "components-button-color-filled-tonal-hover-stroke": 1,
        "components-button-color-filled-tonal-hover-icon": 1,
        "components-button-color-filled-tonal-pressed-bg": 1,
        "components-button-color-filled-tonal-pressed-text": 1,
        "components-button-color-filled-tonal-pressed-stroke": 1,
        "components-button-color-filled-tonal-pressed-icon": 1,
        "components-button-color-filled-tonal-disabled-bg": null,
        "components-button-color-filled-tonal-disabled-text": 0.02,
        "components-button-color-filled-tonal-disabled-stroke": null,
        "components-button-color-filled-tonal-disabled-icon": 0.02,
        "components-button-color-filled-tonal-focus-bg": 1,
        "components-button-color-filled-tonal-focus-text": 1,
        "components-button-color-filled-tonal-focus-stroke": 1,
        "components-button-color-filled-tonal-focus-icon": 1,
        "components-button-color-filled-tonal-focus-outline": 1,
        "components-button-color-filled-white-default-bg": 1,
        "components-button-color-filled-white-default-text": 1,
        "components-button-color-filled-white-default-stroke": 1,
        "components-button-color-filled-white-default-icon": 1,
        "components-button-color-filled-white-hover-bg": 1,
        "components-button-color-filled-white-hover-text": 1,
        "components-button-color-filled-white-hover-stroke": 1,
        "components-button-color-filled-white-hover-icon": 1,
        "components-button-color-filled-white-pressed-bg": 1,
        "components-button-color-filled-white-pressed-text": 1,
        "components-button-color-filled-white-pressed-stroke": 1,
        "components-button-color-filled-white-pressed-icon": 1,
        "components-button-color-filled-white-disabled-bg": 0.02,
        "components-button-color-filled-white-disabled-text": null,
        "components-button-color-filled-white-disabled-stroke": 0.02,
        "components-button-color-filled-white-disabled-icon": null,
        "components-button-color-filled-white-focus-bg": 1,
        "components-button-color-filled-white-focus-text": 1,
        "components-button-color-filled-white-focus-stroke": 1,
        "components-button-color-filled-white-focus-icon": 1,
        "components-button-color-filled-white-focus-outline": 1,
        "components-button-color-outline-brand-default-bg": 0.02,
        "components-button-color-outline-brand-default-text": 1,
        "components-button-color-outline-brand-default-stroke": 1,
        "components-button-color-outline-brand-default-icon": 1,
        "components-button-color-outline-brand-hover-bg": 1,
        "components-button-color-outline-brand-hover-text": 1,
        "components-button-color-outline-brand-hover-stroke": 1,
        "components-button-color-outline-brand-hover-icon": 1,
        "components-button-color-outline-brand-pressed-bg": 1,
        "components-button-color-outline-brand-pressed-text": 1,
        "components-button-color-outline-brand-pressed-stroke": 1,
        "components-button-color-outline-brand-pressed-icon": 1,
        "components-button-color-outline-brand-disabled-bg": 0.02,
        "components-button-color-outline-brand-disabled-text": null,
        "components-button-color-outline-brand-disabled-stroke": null,
        "components-button-color-outline-brand-disabled-icon": null,
        "components-button-color-outline-brand-focus-bg": 1,
        "components-button-color-outline-brand-focus-text": 1,
        "components-button-color-outline-brand-focus-stroke": 1,
        "components-button-color-outline-brand-focus-icon": 1,
        "components-button-color-outline-brand-focus-outline": 1,
        "components-button-color-outline-tonal-default-bg": 0.02,
        "components-button-color-outline-tonal-default-text": 1,
        "components-button-color-outline-tonal-default-stroke": 1,
        "components-button-color-outline-tonal-default-icon": 1,
        "components-button-color-outline-tonal-hover-bg": 1,
        "components-button-color-outline-tonal-hover-text": 1,
        "components-button-color-outline-tonal-hover-stroke": 1,
        "components-button-color-outline-tonal-hover-icon": 1,
        "components-button-color-outline-tonal-pressed-bg": 1,
        "components-button-color-outline-tonal-pressed-text": 1,
        "components-button-color-outline-tonal-pressed-stroke": 1,
        "components-button-color-outline-tonal-pressed-icon": 1,
        "components-button-color-outline-tonal-disabled-bg": 0.02,
        "components-button-color-outline-tonal-disabled-text": null,
        "components-button-color-outline-tonal-disabled-stroke": null,
        "components-button-color-outline-tonal-disabled-icon": null,
        "components-button-color-outline-tonal-focus-bg": 0.02,
        "components-button-color-outline-tonal-focus-text": 1,
        "components-button-color-outline-tonal-focus-stroke": 1,
        "components-button-color-outline-tonal-focus-icon": 1,
        "components-button-color-outline-tonal-focus-outline": 1,
        "components-button-color-outline-white-default-bg": 0.02,
        "components-button-color-outline-white-default-text": 1,
        "components-button-color-outline-white-default-stroke": 1,
        "components-button-color-outline-white-default-icon": 1,
        "components-button-color-outline-white-hover-bg": 1,
        "components-button-color-outline-white-hover-text": 1,
        "components-button-color-outline-white-hover-stroke": 1,
        "components-button-color-outline-white-hover-icon": 1,
        "components-button-color-outline-white-pressed-bg": 1,
        "components-button-color-outline-white-pressed-text": 1,
        "components-button-color-outline-white-pressed-stroke": 1,
        "components-button-color-outline-white-pressed-icon": 1,
        "components-button-color-outline-white-disabled-bg": 0.02,
        "components-button-color-outline-white-disabled-text": 0.02,
        "components-button-color-outline-white-disabled-stroke": 0.02,
        "components-button-color-outline-white-disabled-icon": 0.02,
        "components-button-color-outline-white-focus-bg": 0.02,
        "components-button-color-outline-white-focus-text": 1,
        "components-button-color-outline-white-focus-stroke": 1,
        "components-button-color-outline-white-focus-icon": 1,
        "components-button-color-outline-white-focus-outline": 1,
        "components-button-color-text-brand-default-bg": 0.02,
        "components-button-color-text-brand-default-text": 1,
        "components-button-color-text-brand-default-stroke": 1,
        "components-button-color-text-brand-default-icon": 1,
        "components-button-color-text-brand-hover-bg": 0.02,
        "components-button-color-text-brand-hover-bg-icon-only": 1,
        "components-button-color-text-brand-hover-text": 1,
        "components-button-color-text-brand-hover-stroke": 1,
        "components-button-color-text-brand-hover-icon": 1,
        "components-button-color-text-brand-pressed-bg": 0.02,
        "components-button-color-text-brand-pressed-bg-icon-only": 1,
        "components-button-color-text-brand-pressed-text": 1,
        "components-button-color-text-brand-pressed-stroke": 1,
        "components-button-color-text-brand-pressed-icon": 1,
        "components-button-color-text-brand-disabled-bg": 0.02,
        "components-button-color-text-brand-disabled-text": null,
        "components-button-color-text-brand-disabled-stroke": null,
        "components-button-color-text-brand-disabled-icon": null,
        "components-button-color-text-brand-focus-bg": 1,
        "components-button-color-text-brand-focus-text": 1,
        "components-button-color-text-brand-focus-stroke": 1,
        "components-button-color-text-brand-focus-icon": 1,
        "components-button-color-text-brand-focus-outline": 1,
        "components-button-color-text-tonal-default-bg": 0.02,
        "components-button-color-text-tonal-default-text": 1,
        "components-button-color-text-tonal-default-stroke": 1,
        "components-button-color-text-tonal-default-icon": 1,
        "components-button-color-text-tonal-hover-bg": 0.02,
        "components-button-color-text-tonal-hover-bg-icon-only": 1,
        "components-button-color-text-tonal-hover-text": 1,
        "components-button-color-text-tonal-hover-stroke": 1,
        "components-button-color-text-tonal-hover-icon": 1,
        "components-button-color-text-tonal-pressed-bg": 0.02,
        "components-button-color-text-tonal-pressed-bg-icon-only": 1,
        "components-button-color-text-tonal-pressed-text": 1,
        "components-button-color-text-tonal-pressed-stroke": 1,
        "components-button-color-text-tonal-pressed-icon": 1,
        "components-button-color-text-tonal-disabled-bg": 0.02,
        "components-button-color-text-tonal-disabled-text": null,
        "components-button-color-text-tonal-disabled-stroke": null,
        "components-button-color-text-tonal-disabled-icon": null,
        "components-button-color-text-tonal-focus-bg": 1,
        "components-button-color-text-tonal-focus-text": 1,
        "components-button-color-text-tonal-focus-stroke": 1,
        "components-button-color-text-tonal-focus-icon": 1,
        "components-button-color-text-tonal-focus-outline": 1,
        "components-button-color-text-white-default-bg": 0.02,
        "components-button-color-text-white-default-text": 1,
        "components-button-color-text-white-default-stroke": 1,
        "components-button-color-text-white-default-icon": 1,
        "components-button-color-text-white-hover-bg": 0.02,
        "components-button-color-text-white-hover-bg-icon-only": 1,
        "components-button-color-text-white-hover-text": 1,
        "components-button-color-text-white-hover-stroke": 1,
        "components-button-color-text-white-hover-icon": 1,
        "components-button-color-text-white-pressed-bg": 0.02,
        "components-button-color-text-white-pressed-bg-icon-only": 1,
        "components-button-color-text-white-pressed-text": 1,
        "components-button-color-text-white-pressed-stroke": 1,
        "components-button-color-text-white-pressed-icon": 1,
        "components-button-color-text-white-disabled-bg": 0.02,
        "components-button-color-text-white-disabled-text": 0.01,
        "components-button-color-text-white-disabled-stroke": 0.01,
        "components-button-color-text-white-disabled-icon": 0.01,
        "components-button-color-text-white-focus-bg": 1,
        "components-button-color-text-white-focus-text": 1,
        "components-button-color-text-white-focus-stroke": 1,
        "components-button-color-text-white-focus-icon": 1,
        "components-button-color-text-white-focus-outline": 1,
        "components-button-group-color-border": 1,
        "components-button-group-color-button-bg-default": null,
        "components-button-group-color-button-fg-default": 1,
        "components-button-group-color-button-bg-hover": null,
        "components-button-group-color-button-fg-hover": 1,
        "components-button-group-color-button-bg-active": 1,
        "components-button-group-color-button-fg-active": 1,
        "components-button-group-color-overlay-left-gradient-fill-1": 1,
        "components-button-group-color-overlay-left-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-1": null,
        "components-button-group-color-stroke-default-gradient-fill-2": null,
        "components-button-group-color-stroke-default-gradient-fill-3": null,
        "components-button-group-color-stroke-default-gradient-fill-4": null,
        "components-button-group-color-stroke-hover-gradient-fill-1": null,
        "components-button-group-color-stroke-hover-gradient-fill-2": null,
        "components-button-group-color-stroke-hover-gradient-fill-3": null,
        "components-button-group-color-stroke-hover-gradient-fill-4": null,
        "components-button-group-color-stroke-active-gradient-fill-1": 1,
        "components-button-group-color-stroke-active-gradient-fill-2": 1,
        "components-button-group-color-stroke-active-gradient-fill-3": 1,
        "components-button-group-color-stroke-active-gradient-fill-4": 1,
        "components-category-card-color-bg": 1,
        "components-category-card-color-content-bg": 1,
        "components-category-card-color-category-bg": 1,
        "components-category-card-color-category-bg-2": 1,
        "components-category-card-color-category-bg-3": 1,
        "components-category-card-color-category-bg-4": 1,
        "components-category-card-color-category-bg-5": 1,
        "components-category-card-color-category-bg-6": 1,
        "components-category-card-color-title": 1,
        "components-category-card-color-copy": 1,
        "components-category-card-color-stroke": 1,
        "components-category-card-color-stroke-hover": 1,
        "components-category-card-listing-color-bg": 1,
        "components-category-card-listing-color-title": 1,
        "components-category-card-listing-color-copy": 1,
        "components-card-search-result-color-bg": 1,
        "components-card-search-result-color-label": 1,
        "components-card-search-result-color-title": 1,
        "components-card-search-result-color-body": 1,
        "components-card-search-result-color-divider-line": 1,
        "components-contact-us-promo-color-light-bg": 1,
        "components-contact-us-promo-color-light-title": 1,
        "components-contact-us-promo-color-light-body": 1,
        "components-contact-us-promo-color-default-bg": 1,
        "components-contact-us-promo-color-default-title": 1,
        "components-contact-us-promo-color-default-body": 1,
        "components-contact-us-promo-color-brand-bg": 1,
        "components-contact-us-promo-color-brand-title": 1,
        "components-contact-us-promo-color-brand-body": 1,
        "components-content-list-color-bg": 1,
        "components-content-list-color-title": 1,
        "components-content-list-color-body": 1,
        "components-content-list-color-icon": 1,
        "components-cookies-colors-bg": 1,
        "components-cookies-colors-title": 1,
        "components-cookies-colors-body": 1,
        "components-cookies-colors-icon": 1,
        "components-dropdown-bg": 1,
        "components-dropdown-border-default": 1,
        "components-dropdown-border-hover": 1,
        "components-dropdown-border-focused": 1,
        "components-dropdown-border-destructive": 1,
        "components-dropdown-label-text-default": 1,
        "components-dropdown-item-text-default": 1,
        "components-dropdown-item-bg-default": 1,
        "components-dropdown-item-bg-hover": 1,
        "components-dropdown-input-field-default": 1,
        "components-dropdown-input-field-focused": 1,
        "components-dropdown-input-label-focused": 1,
        "components-dropdown-input-field-destructive": 1,
        "components-external-link-card-bg": 1,
        "components-external-link-card-card-bg": 1,
        "components-external-link-card-card-title": 1,
        "components-external-link-card-card-subtitle": 1,
        "components-external-link-card-card-eyebrow": 1,
        "components-fab-color-border-default": 1,
        "components-footer-brand-color-bg": 1,
        "components-footer-brand-color-logo": 1,
        "components-footer-brand-color-title-text": 1,
        "components-footer-brand-color-description-text": 1,
        "components-footer-brand-color-footer-menu-expanded": 1,
        "components-footer-brand-color-link-default": 1,
        "components-footer-brand-color-link-hover": 1,
        "components-footer-brand-color-link-pressed": 1,
        "components-footer-brand-color-category-link-default": 1,
        "components-footer-brand-color-category-link-hover": 1,
        "components-footer-brand-color-category-link-pressed": 1,
        "components-footer-brand-color-subcategory-link-default": 1,
        "components-footer-brand-color-subcategory-link-hover": 1,
        "components-footer-brand-color-subcategory-link-pressed": 1,
        "components-footer-brand-color-icon-icon-default": 1,
        "components-footer-brand-color-icon-icon-pressed": 1,
        "components-footer-brand-color-social-icon-default": 1,
        "components-footer-brand-color-social-icon-hover": 1,
        "components-footer-brand-color-social-icon-pressed": 1,
        "components-footer-brand-spacing-category-link-hover": 1,
        "components-footer-global-color-bg": 1,
        "components-footer-global-color-title-text": 1,
        "components-footer-global-color-disclaimer-text": 1,
        "components-footer-global-color-link-default": 1,
        "components-footer-global-color-link-hover": 1,
        "components-footer-global-color-link-pressed": 1,
        "components-generic-card-color-bg": 1,
        "components-generic-card-color-content-bg": 1,
        "components-generic-card-color-title": 1,
        "components-generic-card-color-subtitle": 1,
        "components-generic-card-color-copy": 1,
        "components-global-search-color-omnibox-list-bg": 1,
        "components-global-search-color-omnibox-divider": 1,
        "components-global-search-color-omnibox-item-bg-default": 1,
        "components-global-search-color-omnibox-item-bg-hover": 1,
        "components-global-search-color-omnibox-item-text-default": 1,
        "components-global-search-color-omnibox-item-text-hover": 1,
        "components-global-search-color-omnibox-label-text-default": 1,
        "components-global-search-color-omnibox-category-text-default": 1,
        "components-generic-card-listing-color-bg": 1,
        "components-generic-card-listing-color-title": 1,
        "components-generic-card-listing-color-copy": 1,
        "components-header-color-bg": 1,
        "components-header-color-bottom-stroke": 1,
        "components-header-color-search-search-border-default": 1,
        "components-header-color-search-search-text": 1,
        "components-header-color-search-search-icon": 1,
        "components-header-color-search-search-bar": 1,
        "components-header-color-mobile-mobile-expanded-bg": 1,
        "components-header-color-mobile-pressed-bg": 1,
        "components-header-color-language-selector-language": 1,
        "components-header-color-language-selector-country": 1,
        "components-header-color-language-selector-country-hover-bg": 1,
        "components-header-color-language-selector-megamenu-language": 1,
        "components-header-color-language-selector-megamenu-country": 1,
        "components-header-color-language-selector-megamenu-country-hover": 1,
        "components-header-color-language-selector-bg": 1,
        "components-header-color-language-selector-stroke": 1,
        "components-header-color-mega-menu-bg": 1,
        "components-header-color-mega-menu-featured-bg": 1,
        "components-header-color-mega-menu-featured-title": 1,
        "components-header-color-mega-menu-category-title": 1,
        "components-header-color-mega-menu-bg-accent": 1,
        "components-header-color-icon-icon-default": 1,
        "components-header-color-icon-icon-hover": 1,
        "components-header-color-icon-icon-pressed": 1,
        "components-header-color-links-nav-main-link-default": 1,
        "components-header-color-links-nav-main-link-hover": 1,
        "components-header-color-links-nav-main-link-pressed": 1,
        "components-header-color-links-nav-main-link-active": 1,
        "components-header-color-links-nav-main-active-bar": 1,
        "components-header-color-links-nav-main-bg-color": 1,
        "components-header-color-links-nav-category-link-category-default": 1,
        "components-header-color-links-nav-category-link-category-hover": 1,
        "components-header-color-links-nav-category-link-category-pressed": 1,
        "components-header-color-links-nav-category-link-category-active": 1,
        "components-header-color-links-nav-menu-link-link-default": 1,
        "components-header-color-links-nav-menu-link-link-hover": 1,
        "components-header-color-links-nav-menu-link-link-pressed": 1,
        "components-header-color-links-nav-menu-link-link-active": 1,
        "components-header-color-links-nav-menu-link-bg": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-default": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-active": 1,
        "components-hero-homepage-color-bg": 1,
        "components-hero-homepage-color-container-bg": 1,
        "components-hero-homepage-color-eyebrow": 1,
        "components-hero-homepage-color-header": 1,
        "components-hero-homepage-color-sub-header": 1,
        "components-hero-homepage-color-body": 1,
        "components-hero-homepage-color-play-pause": 1,
        "components-hero-internal-color-default-title": 1,
        "components-hero-internal-color-default-body": 1,
        "components-hero-internal-color-default-bg": 1,
        "components-hero-internal-color-accent-bg": 1,
        "components-hero-internal-color-with-image-bg": 1,
        "components-hero-internal-color-default-bg-2": 1,
        "components-hero-internal-color-with-image-title": 1,
        "components-hero-internal-color-with-image-body": 1,
        "components-heading-page-title-color-default-bg": 1,
        "components-heading-page-title-color-default-title": 1,
        "components-heading-page-title-color-default-body": 1,
        "components-heading-page-title-color-light-bg": 1,
        "components-heading-page-title-color-light-title": 1,
        "components-heading-page-title-color-light-body": 1,
        "components-heading-page-title-color-color-bg": 1,
        "components-heading-page-title-color-color-title": 1,
        "components-heading-page-title-color-color-body": 1,
        "components-hyperlink-color-dark-default": 1,
        "components-hyperlink-color-dark-hover": 1,
        "components-hyperlink-color-dark-visited": 1,
        "components-hyperlink-color-light-default": 1,
        "components-hyperlink-color-light-hover": 1,
        "components-hyperlink-color-light-visited": 1,
        "components-icon-default": 1,
        "components-icon-focused": 1,
        "components-icon-card-color-bg": 1,
        "components-icon-card-color-icon-bg": 1,
        "components-icon-card-color-icon": 1,
        "components-icon-card-color-title": 1,
        "components-icon-card-color-subtitle": 1,
        "components-icon-card-color-description": 1,
        "components-icon-card-listing-color-bg": 1,
        "components-icon-card-listing-color-inner-bg": 1,
        "components-icon-card-listing-color-brand-bg": 1,
        "components-icon-card-listing-color-title": 1,
        "components-icon-card-listing-color-copy": 1,
        "components-icon-card-listing-color-inner-legal-text": 1,
        "components-jumplink-color-bg-default": 1,
        "components-jumplink-color-link-text-default": 1,
        "components-jumplink-color-link-text-hover": 1,
        "components-jumplink-color-link-border-hover": 1,
        "components-jumplink-color-link-text-active": 1,
        "components-jumplink-color-link-border-active": 1,
        "components-jumplink-color-title-text": 1,
        "components-jumplink-color-description-text": 1,
        "components-layout-bg-default": 1,
        "components-lightbox-color-overlay-bg": 0.02,
        "components-lightbox-color-title": 1,
        "components-lightbox-color-body": 1,
        "components-label-color-on-color-label-label-default": 1,
        "components-label-color-on-color-label-label-hover": 1,
        "components-label-color-on-color-label-label-pressed": 1,
        "components-label-color-on-color-background-bg-default": null,
        "components-label-color-on-color-background-bg-hover": 1,
        "components-label-color-on-color-background-bg-pressed": 1,
        "components-label-color-on-color-stroke-hover": 0.02,
        "components-label-color-on-color-stroke-pressed": 0.02,
        "components-label-color-on-color-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-4": 1,
        "components-label-color-on-white-label-label-default": 1,
        "components-label-color-on-white-label-label-hover": 1,
        "components-label-color-on-white-label-label-pressed": 1,
        "components-label-color-on-white-background-bg-default": null,
        "components-label-color-on-white-background-bg-hover": 1,
        "components-label-color-on-white-background-bg-pressed": 1,
        "components-label-color-on-white-stroke-hover": 1,
        "components-label-color-on-white-stroke-pressed": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-4": 1,
        "components-legal-disclaimer-color-default-bg": 1,
        "components-legal-disclaimer-color-default-title": 1,
        "components-legal-disclaimer-color-default-body": 1,
        "components-legal-disclaimer-color-light-bg": 1,
        "components-legal-disclaimer-color-light-title": 1,
        "components-legal-disclaimer-color-light-body": 1,
        "components-legal-disclaimer-color-brand-bg": 1,
        "components-legal-disclaimer-color-brand-title": 1,
        "components-legal-disclaimer-color-brand-body": 1,
        "components-media-video-thumbnail-overlay-bg": null,
        "components-media-caption-color-bg": 1,
        "components-media-caption-color-text": 1,
        "components-media-gallery-color-bg": 1,
        "components-media-gallery-color-title": 1,
        "components-media-gallery-color-body": 1,
        "components-pagination-on-white-accent-default": 1,
        "components-pagination-on-white-accent-scroll": 1,
        "components-pagination-on-white-disabled": 1,
        "components-pagination-on-white-bg": null,
        "components-pagination-on-white-stroke": 1,
        "components-pagination-on-white-bg-active": 1,
        "components-pagination-on-white-bg-hover": 1,
        "components-pagination-on-white-text-default": 1,
        "components-pagination-on-white-text-hover": 1,
        "components-pagination-on-white-text-active": 1,
        "components-pagination-on-color-accent-default": 1,
        "components-pagination-on-color-accent-scroll": 1,
        "components-pagination-on-color-disabled": 1,
        "components-pagination-on-color-bg": null,
        "components-pagination-on-color-stroke": 0.02,
        "components-pagination-on-color-bg-active": 1,
        "components-pagination-on-color-bg-hover": 1,
        "components-pagination-on-color-text-default": 1,
        "components-pagination-on-color-text-hover": 1,
        "components-pagination-on-color-text-active": 1,
        "components-pdf-link-card-color-bg": 1,
        "components-pdf-link-card-color-card-bg": 1,
        "components-pdf-link-card-color-card-title": 1,
        "components-pdf-link-card-color-card-subtitle": 1,
        "components-pdf-link-card-color-card-body": 1,
        "components-product-card-color-bg": 1,
        "components-product-card-color-content-bg": 1,
        "components-product-card-color-border": 1,
        "components-product-card-color-title": 1,
        "components-product-card-color-description": 1,
        "components-product-card-color-star-rating": 1,
        "components-product-card-color-badge-bg": 1,
        "components-product-card-color-badge-text": 1,
        "components-product-card-listing-color-bg": 1,
        "components-product-card-listing-color-title": 1,
        "components-product-card-listing-color-copy": 1,
        "components-product-card-listing-color-legal-text": 1,
        "components-product-information-color-bg": 1,
        "components-product-information-color-content-bg": 1,
        "components-product-information-color-headline": 1,
        "components-product-information-color-body": 1,
        "components-product-information-color-detail-text": 1,
        "components-product-information-color-divider": 1,
        "components-product-information-color-icon": 1,
        "components-product-information-color-play-icon": 1,
        "components-product-information-color-selected-stroke": 1,
        "components-product-results-list-with-search-color-bg": 1,
        "components-promo-color-default-bg": 1,
        "components-promo-color-default-image-bg": 1,
        "components-promo-color-default-content-bg": 1,
        "components-promo-color-default-title": 1,
        "components-promo-color-default-body": 1,
        "components-promo-color-default-category": 1,
        "components-promo-color-light-bg": 1,
        "components-promo-color-light-image-bg": 1,
        "components-promo-color-light-content-bg": 1,
        "components-promo-color-light-title": 1,
        "components-promo-color-light-body": 1,
        "components-promo-color-light-category": 1,
        "components-promo-color-brand-bg": 1,
        "components-promo-color-brand-image-bg": 1,
        "components-promo-color-brand-content-bg": 1,
        "components-promo-color-brand-title": 1,
        "components-promo-color-brand-body": 1,
        "components-promo-color-brand-category": 1,
        "components-pull-quote-color-bg": 1,
        "components-pull-quote-color-stroke": 1,
        "components-pull-quote-color-quote-bg": 1,
        "components-pull-quote-color-quote-title": 1,
        "components-pull-quote-color-quote-by-text": 1,
        "components-pull-quote-color-quote-detail": 1,
        "components-pull-quote-color-quote-highlight": 1,
        "components-promo-alternate-color-default-bg": 1,
        "components-promo-alternate-color-default-image-bg": 1,
        "components-promo-alternate-color-default-content-bg": 1,
        "components-promo-alternate-color-default-title": 1,
        "components-promo-alternate-color-default-body": 1,
        "components-promo-alternate-color-default-category": 1,
        "components-promo-alternate-color-light-bg": 1,
        "components-promo-alternate-color-light-image-bg": 1,
        "components-promo-alternate-color-light-content-bg": 1,
        "components-promo-alternate-color-light-title": 1,
        "components-promo-alternate-color-light-body": 1,
        "components-promo-alternate-color-light-category": 1,
        "components-promo-alternate-color-brand-bg": 1,
        "components-promo-alternate-color-brand-image-bg": 1,
        "components-promo-alternate-color-brand-content-bg": 1,
        "components-promo-alternate-color-brand-title": 1,
        "components-promo-alternate-color-brand-body": 1,
        "components-promo-alternate-color-brand-category": 1,
        "components-share-list-color-title": 1,
        "components-share-list-color-bg": 1,
        "components-share-list-color-link-default": 1,
        "components-share-list-color-link-hover": 1,
        "components-share-list-color-bg-link-hover": 1,
        "components-share-list-color-close-icon": 1,
        "components-share-list-color-facebook-icon": 1,
        "components-share-list-color-pinterest-icon": 1,
        "components-share-list-color-twitter-icon": 1,
        "components-share-list-color-basic-icon-default": 1,
        "components-share-list-color-icon-hover": 1,
        "components-share-list-color-border": 1,
        "components-site-search-bg": 1,
        "components-site-search-results_text": 1,
        "components-site-search-filter-filter-selected-bg": 1,
        "components-site-search-facet-group-label-bottom-line": 1,
        "components-site-search-facet-group-label-text": 1,
        "components-site-search-facet-group-label-bg": 1,
        "components-site-search-facet-bg": 1,
        "components-site-search-facet-stroke": 1,
        "components-site-search-refine-bg": 1,
        "components-site-search-refine-right-divider": 1,
        "components-site-search-refine-title": 1,
        "components-site-search-hero-search-bg": 1,
        "components-site-search-hero-search-accent-bg": 1,
        "components-site-search-hero-search-title": 1,
        "components-stats-stat-text": 1,
        "components-stats-stat-text-on-light": 1,
        "components-stats-supporting-text": 1,
        "components-stats-bg-default": 1,
        "components-stats-bg-inner": 1,
        "components-stats-bg-section-inner": 1,
        "components-stats-title": 1,
        "components-stats-copy": 1,
        "components-supporting-text-default": 1,
        "components-supporting-text-destructive": 1,
        "components-tab-bg": 1,
        "components-tab-border-default": 1,
        "components-tab-border-hover": 1,
        "components-tab-border-focused": 1,
        "components-tab-border-destructive": 1,
        "components-tab-label-text-default": 1,
        "components-tab-item-text-default": 1,
        "components-tab-item-bg-default": 1,
        "components-tab-item-bg-hover": 1,
        "components-tab-input-field-default": 1,
        "components-tab-input-field-focused": 1,
        "components-tab-input-label-focused": 1,
        "components-tab-input-field-destructive": 1,
        "components-tab-bg-2": 1,
        "components-tag-color-bg": 1,
        "components-tag-color-text": 1,
        "components-tag-color-stroke": 1,
        "components-tag-color-bg-hover": 1,
        "components-tag-color-text-hover": 1,
        "components-tag-color-stroke-hover": 1,
        "components-text-field-bg": 1,
        "components-text-field-bg-focused": 1,
        "components-text-field-border-default": 1,
        "components-text-field-border-hover": 1,
        "components-text-field-border-focused": 1,
        "components-text-field-border-destructive": 1,
        "components-text-field-input-field-default": 1,
        "components-text-field-input-field-focused": 1,
        "components-text-field-input-label-focused": 1,
        "components-text-field-input-field-destructive": 1,
        "components-text-field-icon-default": 1,
        "components-text-field-icon-destructive": 1,
        "components-text-field-supporting-text-default": 1,
        "components-text-field-supporting-text-destructive": 1,
        "components-tip-color-bg": 1,
        "components-tip-color-title": 1,
        "components-tip-color-body": 1,
        "components-tip-color-stroke": 1,
        "components-video-color-bg": 1,
        "themes-general-surface-color-light-bg": 1,
        "themes-general-surface-color-light-title": 1,
        "themes-general-surface-color-light-body": 1,
        "themes-general-surface-color-light-decor-line-break": 1,
        "themes-general-surface-color-light-rule-line": 1,
        "themes-general-surface-color-dark-bg": 1,
        "themes-general-surface-color-dark-title": 1,
        "themes-general-surface-color-dark-body": 1,
        "themes-general-surface-color-dark-decor-line-break": 1,
        "themes-general-card-color-dark-title": 1,
        "themes-general-card-color-dark-subtitle": 1,
        "themes-general-card-color-dark-body": 1,
        "themes-general-card-color-light-title": 1,
        "themes-general-card-color-light-subtitle": 1,
        "themes-general-card-color-light-body": 1,
        "themes-shadow-color": 1,
        "themes-shadow-share-list-color-fill": 0,
        "themes-shadow-header-menu-color-fill": 0,
        "themes-gradient-loader-gradient-fill-1": 1,
        "themes-gradient-loader-gradient-fill-2": null,
        "themes-color-background-white": 1,
        "themes-color-background-light": 1,
        "themes-color-background-dark": 1,
        "themes-color-background-black": 1,
        "themes-color-background-brand1": 1,
        "themes-color-background-brand2": 1,
        "themes-color-background-accent1": 1,
        "themes-color-background-accent2": 1,
        "themes-color-background-body": 1,
        "themes-color-stroke-white": 1,
        "themes-color-stroke-light": 1,
        "themes-color-stroke-dark": 1,
        "themes-color-stroke-black": 1,
        "themes-color-stroke-brand1": 1,
        "themes-color-stroke-brand2": 1,
        "themes-color-stroke-accent1": 1,
        "themes-color-stroke-accent2": 1,
        "themes-color-text-white": 1,
        "themes-color-text-light": 1,
        "themes-color-text-dark": 1,
        "themes-color-text-black": 1,
        "themes-color-text-brand1": 1,
        "themes-color-text-brand2": 1,
        "themes-color-text-accent1": 1,
        "themes-color-text-accent2": 1,
        "colors-grayscale-100": 1,
        "colors-grayscale-200": 1,
        "colors-grayscale-300": 1,
        "colors-grayscale-400": 1,
        "colors-grayscale-500": 1,
        "colors-grayscale-600": 1,
        "colors-grayscale-700": 1,
        "colors-brand-1-100": 1,
        "colors-brand-1-200": 1,
        "colors-brand-1-300": 1,
        "colors-brand-1-400": 1,
        "colors-brand-1-500": 1,
        "colors-brand-1-600": 1,
        "colors-brand-1-700": 1,
        "colors-brand-2-100": 1,
        "colors-brand-2-200": 1,
        "colors-brand-2-300": 1,
        "colors-brand-2-400": 1,
        "colors-brand-2-500": 1,
        "colors-brand-2-600": 1,
        "colors-brand-2-700": 1,
        "colors-accent-1-100": 1,
        "colors-accent-1-200": 1,
        "colors-accent-1-300": 1,
        "colors-accent-1-400": 1,
        "colors-accent-1-500": 1,
        "colors-accent-1-600": 1,
        "colors-accent-1-700": 1,
        "colors-accent-2-100": 1,
        "colors-accent-2-200": 1,
        "colors-accent-2-300": 1,
        "colors-accent-2-400": 1,
        "colors-accent-2-500": 1,
        "colors-accent-2-600": 1,
        "colors-accent-2-700": 1,
        "colors-accent-3-100": 1,
        "colors-accent-3-200": 1,
        "colors-accent-3-300": 1,
        "colors-accent-3-400": 1,
        "colors-accent-3-500": 1,
        "colors-accent-3-600": 1,
        "colors-accent-3-700": 1,
        "colors-accent-4-400_storage": 1,
        "colors-accessible-accent-1-100": 1,
        "colors-accessible-accent-1-200": 1,
        "colors-accessible-accent-1-300": 1,
        "colors-accessible-accent-1-400": 1,
        "colors-accessible-accent-1-500": 1,
        "colors-accessible-accent-1-600": 1,
        "colors-accessible-accent-1-700": 1,
        "colors-accessible-accent-2-100": 1,
        "colors-accessible-accent-2-200": 1,
        "colors-accessible-accent-2-300": 1,
        "colors-accessible-accent-2-400": 1,
        "colors-accessible-accent-2-500": 1,
        "colors-accessible-accent-2-600": 1,
        "colors-accessible-accent-2-700": 1,
        "colors-accessible-brand-2-100": 1,
        "colors-accessible-brand-2-200": 1,
        "colors-accessible-brand-2-300": 1,
        "colors-accessible-brand-2-400": 1,
        "colors-accessible-brand-2-500": 1,
        "colors-accessible-brand-2-600": 1,
        "colors-accessible-brand-2-700": 1
      }
    },
    "Raid": {
      "colors": {
        "components-accessibility-bar-color-bg": "#fedd00",
        "components-accessibility-bar-color-text": "#191b1c",
        "components-accordion-color-bg": "#ffffff",
        "components-accordion-color-title": "#191b1c",
        "components-accordion-color-body": "#27282b",
        "components-accordion-color-section-section-bg": "#ffffff",
        "components-accordion-color-section-section-title": "#191b1c",
        "components-accordion-color-section-section-body": "#27282b",
        "components-accordion-color-item-item-bg": "#ffffff",
        "components-accordion-color-item-divider": "#e8e8ea",
        "components-accordion-color-item-icon-icon-default": "#191b1c",
        "components-accordion-color-item-icon-icon-hover": "#ffffff",
        "components-accordion-color-item-icon-icon-pressed": "#ffffff",
        "components-accordion-color-item-icon-icon-active": "#ffffff",
        "components-accordion-color-item-title-item-default": "#191b1c",
        "components-accordion-color-item-title-item-hover": "#ffffff",
        "components-accordion-color-item-title-item-pressed": "#ffffff",
        "components-accordion-color-item-title-item-active": "#ffffff",
        "components-accordion-color-content-bg": "#ffffff",
        "components-accordion-color-content-title": "#191b1c",
        "components-accordion-color-content-body": "#27282b",
        "components-article-card-color-bg": "#ffffff",
        "components-article-card-color-border": "#e8e8ea",
        "components-article-card-color-content-bg": "#ffffff",
        "components-article-card-color-title": "#191b1c",
        "components-article-card-color-subtitle": "#27282b",
        "components-article-card-color-copy": "#27282b",
        "components-article-card-color-description": "#27282b",
        "components-article-card-listing-color-default-bg": "#e8e8ea",
        "components-article-card-listing-color-default-title": "#191b1c",
        "components-article-card-listing-color-default-copy": "#27282b",
        "components-article-hero-color-content-bg": "#e8e8ea",
        "components-article-hero-color-bg": "#e8e8ea",
        "components-article-hero-color-headline": "#191b1c",
        "components-article-hero-color-body": "#27282b",
        "components-article-hero-color-detail-text": "#27282b",
        "components-article-hero-color-divider": "#a2a5ab",
        "components-article-hero-color-icon": "#fedd00",
        "components-article-hero-color-play-icon": "#ffffff",
        "components-article-hero-color-selected-stroke": "#005eb5",
        "components-banner-color-bg-default": "#191b1c",
        "components-banner-color-icon-default": "#fedd00",
        "components-banner-color-title-default": "#ffffff",
        "components-banner-color-body-default": "#ffffff",
        "components-banner-color-icon-close-default": "#ffffff",
        "components-banner-color-bg-alert": "#b32b30",
        "components-banner-color-icon-alert": "#ffffff",
        "components-banner-color-title-alert": "#ffffff",
        "components-banner-color-body-alert": "#ffffff",
        "components-banner-color-icon-close-alert": "#ffffff",
        "components-breadcrumb-color-bg": "#ffffff",
        "components-breadcrumb-color-breadcrumb-link-default": "#72757e",
        "components-breadcrumb-color-breadcrumb-link-hover": "#4e5056",
        "components-breadcrumb-color-breadcrumb-link-active": "#27282b",
        "components-breadcrumb-color-icon-divider": "#72757e",
        "components-bug-finder-prompt": "#ffffff",
        "components-button-color-filled-brand-default-bg": "#191b1c",
        "components-button-color-filled-brand-default-text": "#ffffff",
        "components-button-color-filled-brand-default-stroke": "#191b1c",
        "components-button-color-filled-brand-default-icon": "#ffffff",
        "components-button-color-filled-brand-hover-bg": "#000000",
        "components-button-color-filled-brand-hover-text": "#ffffff",
        "components-button-color-filled-brand-hover-stroke": "#000000",
        "components-button-color-filled-brand-hover-icon": "#ffffff",
        "components-button-color-filled-brand-pressed-bg": "#000000",
        "components-button-color-filled-brand-pressed-text": "#ffffff",
        "components-button-color-filled-brand-pressed-stroke": "#000000",
        "components-button-color-filled-brand-pressed-icon": "#ffffff",
        "components-button-color-filled-brand-disabled-bg": "rgba(25, 27, 28, 0.38)",
        "components-button-color-filled-brand-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-disabled-stroke": "rgba(39, 40, 43, 0.38)",
        "components-button-color-filled-brand-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-focus-bg": "#191b1c",
        "components-button-color-filled-brand-focus-text": "#ffffff",
        "components-button-color-filled-brand-focus-stroke": "#191b1c",
        "components-button-color-filled-brand-focus-icon": "#ffffff",
        "components-button-color-filled-brand-focus-outline": "#286efa",
        "components-button-color-filled-tonal-default-bg": "#ffec0d",
        "components-button-color-filled-tonal-default-text": "#191b1c",
        "components-button-color-filled-tonal-default-stroke": "#ffec0d",
        "components-button-color-filled-tonal-default-icon": "#191b1c",
        "components-button-color-filled-tonal-hover-bg": "#fedd00",
        "components-button-color-filled-tonal-hover-text": "#191b1c",
        "components-button-color-filled-tonal-hover-stroke": "#fedd00",
        "components-button-color-filled-tonal-hover-icon": "#191b1c",
        "components-button-color-filled-tonal-pressed-bg": "#fedd00",
        "components-button-color-filled-tonal-pressed-text": "#191b1c",
        "components-button-color-filled-tonal-pressed-stroke": "#fedd00",
        "components-button-color-filled-tonal-pressed-icon": "#191b1c",
        "components-button-color-filled-tonal-disabled-bg": "rgba(255, 236, 13, 0.38)",
        "components-button-color-filled-tonal-disabled-text": "rgba(25, 27, 28, 0.38)",
        "components-button-color-filled-tonal-disabled-stroke": "rgba(255, 236, 13, 0.38)",
        "components-button-color-filled-tonal-disabled-icon": "rgba(25, 27, 28, 0.38)",
        "components-button-color-filled-tonal-focus-bg": "#ffec0d",
        "components-button-color-filled-tonal-focus-text": "#191b1c",
        "components-button-color-filled-tonal-focus-stroke": "#ffec0d",
        "components-button-color-filled-tonal-focus-icon": "#191b1c",
        "components-button-color-filled-tonal-focus-outline": "#1c4cae",
        "components-button-color-filled-white-default-bg": "#ffffff",
        "components-button-color-filled-white-default-text": "#191b1c",
        "components-button-color-filled-white-default-stroke": "#ffffff",
        "components-button-color-filled-white-default-icon": "#191b1c",
        "components-button-color-filled-white-hover-bg": "#fcfcfd",
        "components-button-color-filled-white-hover-text": "#191b1c",
        "components-button-color-filled-white-hover-stroke": "#fcfcfd",
        "components-button-color-filled-white-hover-icon": "#191b1c",
        "components-button-color-filled-white-pressed-bg": "#e8e8ea",
        "components-button-color-filled-white-pressed-text": "#191b1c",
        "components-button-color-filled-white-pressed-stroke": "#e8e8ea",
        "components-button-color-filled-white-pressed-icon": "#191b1c",
        "components-button-color-filled-white-disabled-bg": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-text": "rgba(25, 27, 28, 0.38)",
        "components-button-color-filled-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-icon": "rgba(25, 27, 28, 0.38)",
        "components-button-color-filled-white-focus-bg": "#ffffff",
        "components-button-color-filled-white-focus-text": "#191b1c",
        "components-button-color-filled-white-focus-stroke": "#ffffff",
        "components-button-color-filled-white-focus-icon": "#191b1c",
        "components-button-color-filled-white-focus-outline": "#1c4cae",
        "components-button-color-outline-brand-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-brand-default-text": "#191b1c",
        "components-button-color-outline-brand-default-stroke": "#191b1c",
        "components-button-color-outline-brand-default-icon": "#191b1c",
        "components-button-color-outline-brand-hover-bg": "#000000",
        "components-button-color-outline-brand-hover-text": "#ffffff",
        "components-button-color-outline-brand-hover-stroke": "#000000",
        "components-button-color-outline-brand-hover-icon": "#ffffff",
        "components-button-color-outline-brand-pressed-bg": "#000000",
        "components-button-color-outline-brand-pressed-text": "#ffffff",
        "components-button-color-outline-brand-pressed-stroke": "#000000",
        "components-button-color-outline-brand-pressed-icon": "#ffffff",
        "components-button-color-outline-brand-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-brand-disabled-text": "rgba(25, 27, 28, 0.38)",
        "components-button-color-outline-brand-disabled-stroke": "rgba(25, 27, 28, 0.38)",
        "components-button-color-outline-brand-disabled-icon": "rgba(25, 27, 28, 0.38)",
        "components-button-color-outline-brand-focus-bg": "#ffffff",
        "components-button-color-outline-brand-focus-text": "#191b1c",
        "components-button-color-outline-brand-focus-stroke": "#191b1c",
        "components-button-color-outline-brand-focus-icon": "#191b1c",
        "components-button-color-outline-brand-focus-outline": "#286efa",
        "components-button-color-outline-tonal-default-bg": "rgba(25, 27, 28, 0.00)",
        "components-button-color-outline-tonal-default-text": "#ffec0d",
        "components-button-color-outline-tonal-default-stroke": "#ffec0d",
        "components-button-color-outline-tonal-default-icon": "#ffec0d",
        "components-button-color-outline-tonal-hover-bg": "#fedd00",
        "components-button-color-outline-tonal-hover-text": "#191b1c",
        "components-button-color-outline-tonal-hover-stroke": "#fedd00",
        "components-button-color-outline-tonal-hover-icon": "#191b1c",
        "components-button-color-outline-tonal-pressed-bg": "#fedd00",
        "components-button-color-outline-tonal-pressed-text": "#191b1c",
        "components-button-color-outline-tonal-pressed-stroke": "#fedd00",
        "components-button-color-outline-tonal-pressed-icon": "#191b1c",
        "components-button-color-outline-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-tonal-disabled-text": "rgba(255, 236, 13, 0.38)",
        "components-button-color-outline-tonal-disabled-stroke": "rgba(255, 236, 13, 0.38)",
        "components-button-color-outline-tonal-disabled-icon": "rgba(255, 236, 13, 0.38)",
        "components-button-color-outline-tonal-focus-bg": "rgba(25, 27, 28, 0.01)",
        "components-button-color-outline-tonal-focus-text": "#ffec0d",
        "components-button-color-outline-tonal-focus-stroke": "#ffec0d",
        "components-button-color-outline-tonal-focus-icon": "#ffec0d",
        "components-button-color-outline-tonal-focus-outline": "#286efa",
        "components-button-color-outline-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-default-text": "#ffffff",
        "components-button-color-outline-white-default-stroke": "#ffffff",
        "components-button-color-outline-white-default-icon": "#ffffff",
        "components-button-color-outline-white-hover-bg": "#ffffff",
        "components-button-color-outline-white-hover-text": "#191b1c",
        "components-button-color-outline-white-hover-stroke": "#ffffff",
        "components-button-color-outline-white-hover-icon": "#191b1c",
        "components-button-color-outline-white-pressed-bg": "#ffffff",
        "components-button-color-outline-white-pressed-text": "#191b1c",
        "components-button-color-outline-white-pressed-stroke": "#ffffff",
        "components-button-color-outline-white-pressed-icon": "#191b1c",
        "components-button-color-outline-white-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-focus-bg": "rgba(255, 255, 255, 0.01)",
        "components-button-color-outline-white-focus-text": "#ffffff",
        "components-button-color-outline-white-focus-stroke": "#ffffff",
        "components-button-color-outline-white-focus-icon": "#ffffff",
        "components-button-color-outline-white-focus-outline": "#286efa",
        "components-button-color-text-brand-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-default-text": "#27282b",
        "components-button-color-text-brand-default-stroke": "#27282b",
        "components-button-color-text-brand-default-icon": "#27282b",
        "components-button-color-text-brand-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-hover-bg-icon-only": "#e8e8ea",
        "components-button-color-text-brand-hover-text": "#191b1c",
        "components-button-color-text-brand-hover-stroke": "#27282b",
        "components-button-color-text-brand-hover-icon": "#191b1c",
        "components-button-color-text-brand-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-pressed-bg-icon-only": "#a2a5ab",
        "components-button-color-text-brand-pressed-text": "#000000",
        "components-button-color-text-brand-pressed-stroke": "#000000",
        "components-button-color-text-brand-pressed-icon": "#000000",
        "components-button-color-text-brand-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-disabled-text": "rgba(39, 40, 43, 0.38)",
        "components-button-color-text-brand-disabled-stroke": "rgba(39, 40, 43, 0.38)",
        "components-button-color-text-brand-disabled-icon": "rgba(39, 40, 43, 0.38)",
        "components-button-color-text-brand-focus-bg": "#d5e3ff",
        "components-button-color-text-brand-focus-text": "#191b1c",
        "components-button-color-text-brand-focus-stroke": "#27282b",
        "components-button-color-text-brand-focus-icon": "#1c4cae",
        "components-button-color-text-brand-focus-outline": "#1c4cae",
        "components-button-color-text-tonal-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-default-text": "#ffec0d",
        "components-button-color-text-tonal-default-stroke": "#ffec0d",
        "components-button-color-text-tonal-default-icon": "#ffec0d",
        "components-button-color-text-tonal-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-hover-bg-icon-only": "#27282b",
        "components-button-color-text-tonal-hover-text": "#fedd00",
        "components-button-color-text-tonal-hover-stroke": "#fedd00",
        "components-button-color-text-tonal-hover-icon": "#fedd00",
        "components-button-color-text-tonal-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-pressed-bg-icon-only": "#27282b",
        "components-button-color-text-tonal-pressed-text": "#fedd00",
        "components-button-color-text-tonal-pressed-stroke": "#fedd00",
        "components-button-color-text-tonal-pressed-icon": "#fedd00",
        "components-button-color-text-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-disabled-text": "rgba(255, 236, 13, 0.38)",
        "components-button-color-text-tonal-disabled-stroke": "rgba(255, 236, 13, 0.38)",
        "components-button-color-text-tonal-disabled-icon": "rgba(255, 236, 13, 0.38)",
        "components-button-color-text-tonal-focus-bg": "#d5e3ff",
        "components-button-color-text-tonal-focus-text": "#1c4cae",
        "components-button-color-text-tonal-focus-stroke": "#ffec0d",
        "components-button-color-text-tonal-focus-icon": "#1c4cae",
        "components-button-color-text-tonal-focus-outline": "#1c4cae",
        "components-button-color-text-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-default-text": "#ffffff",
        "components-button-color-text-white-default-stroke": "#ffffff",
        "components-button-color-text-white-default-icon": "#ffffff",
        "components-button-color-text-white-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-hover-bg-icon-only": "#4e5056",
        "components-button-color-text-white-hover-text": "#ffffff",
        "components-button-color-text-white-hover-stroke": "#ffffff",
        "components-button-color-text-white-hover-icon": "#ffffff",
        "components-button-color-text-white-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-pressed-bg-icon-only": "#4e5056",
        "components-button-color-text-white-pressed-text": "#ffffff",
        "components-button-color-text-white-pressed-stroke": "#ffffff",
        "components-button-color-text-white-pressed-icon": "#ffffff",
        "components-button-color-text-white-disabled-bg": "rgba(115, 119, 128, 0.00)",
        "components-button-color-text-white-disabled-text": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-stroke": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-icon": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-focus-bg": "#d5e3ff",
        "components-button-color-text-white-focus-text": "#ffffff",
        "components-button-color-text-white-focus-stroke": "#ffffff",
        "components-button-color-text-white-focus-icon": "#1c4cae",
        "components-button-color-text-white-focus-outline": "#1c4cae",
        "components-button-group-color-border": "#e8e8ea",
        "components-button-group-color-button-bg-default": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-button-fg-default": "#191b1c",
        "components-button-group-color-button-bg-hover": "rgba(25, 27, 28, 0.38)",
        "components-button-group-color-button-fg-hover": "#191b1c",
        "components-button-group-color-button-bg-active": "#191b1c",
        "components-button-group-color-button-fg-active": "#ffffff",
        "components-button-group-color-overlay-left-gradient-fill-1": "#e8e8ea",
        "components-button-group-color-overlay-left-gradient-fill-2": "rgba(232, 232, 234, 0.50)",
        "components-button-group-color-stroke-default-gradient-fill-1": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-2": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-3": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-4": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-1": "rgba(25, 27, 28, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-2": "rgba(25, 27, 28, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-3": "rgba(25, 27, 28, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-4": "rgba(25, 27, 28, 0.00)",
        "components-button-group-color-stroke-active-gradient-fill-1": "#191b1c",
        "components-button-group-color-stroke-active-gradient-fill-2": "#191b1c",
        "components-button-group-color-stroke-active-gradient-fill-3": "#191b1c",
        "components-button-group-color-stroke-active-gradient-fill-4": "#191b1c",
        "components-category-card-color-bg": "#ffffff",
        "components-category-card-color-content-bg": "#ffffff",
        "components-category-card-color-category-bg": "#fedd00",
        "components-category-card-color-category-bg-2": "#74aa50",
        "components-category-card-color-category-bg-3": "#27282b",
        "components-category-card-color-category-bg-4": "#72757e",
        "components-category-card-color-category-bg-5": "#004b9b",
        "components-category-card-color-category-bg-6": "#ff6a14",
        "components-category-card-color-title": "#191b1c",
        "components-category-card-color-copy": "#27282b",
        "components-category-card-color-stroke": "#e8e8ea",
        "components-category-card-color-stroke-hover": "#191b1c",
        "components-category-card-listing-color-bg": "#e8e8ea",
        "components-category-card-listing-color-title": "#191b1c",
        "components-category-card-listing-color-copy": "#27282b",
        "components-card-search-result-color-bg": "#ffffff",
        "components-card-search-result-color-label": "#27282b",
        "components-card-search-result-color-title": "#191b1c",
        "components-card-search-result-color-body": "#27282b",
        "components-card-search-result-color-divider-line": "#e8e8ea",
        "components-contact-us-promo-color-light-bg": "#ffffff",
        "components-contact-us-promo-color-light-title": "#191b1c",
        "components-contact-us-promo-color-light-body": "#27282b",
        "components-contact-us-promo-color-default-bg": "#e8e8ea",
        "components-contact-us-promo-color-default-title": "#191b1c",
        "components-contact-us-promo-color-default-body": "#27282b",
        "components-contact-us-promo-color-brand-bg": "#fedd00",
        "components-contact-us-promo-color-brand-title": "#191b1c",
        "components-contact-us-promo-color-brand-body": "#27282b",
        "components-content-list-color-bg": "#ffffff",
        "components-content-list-color-title": "#191b1c",
        "components-content-list-color-body": "#27282b",
        "components-content-list-color-icon": "#005eb5",
        "components-cookies-colors-bg": "#e8e8ea",
        "components-cookies-colors-title": "#191b1c",
        "components-cookies-colors-body": "#27282b",
        "components-cookies-colors-icon": "#fedd00",
        "components-dropdown-bg": "#ffffff",
        "components-dropdown-border-default": "#72757e",
        "components-dropdown-border-hover": "#fedd00",
        "components-dropdown-border-focused": "#191b1c",
        "components-dropdown-border-destructive": "#b32b30",
        "components-dropdown-label-text-default": "#191b1c",
        "components-dropdown-item-text-default": "#191b1c",
        "components-dropdown-item-bg-default": "#ffffff",
        "components-dropdown-item-bg-hover": "#d5e3ff",
        "components-dropdown-input-field-default": "#72757e",
        "components-dropdown-input-field-focused": "#191b1c",
        "components-dropdown-input-label-focused": "#191b1c",
        "components-dropdown-input-field-destructive": "#b32b30",
        "components-external-link-card-bg": "#e8e8ea",
        "components-external-link-card-card-bg": "#ffffff",
        "components-external-link-card-card-title": "#191b1c",
        "components-external-link-card-card-subtitle": "#27282b",
        "components-external-link-card-card-eyebrow": "#4e5056",
        "components-fab-color-border-default": "#ffffff",
        "components-footer-brand-color-bg": "#000000",
        "components-footer-brand-color-logo": "#ffffff",
        "components-footer-brand-color-title-text": "#ffffff",
        "components-footer-brand-color-description-text": "#fcfcfd",
        "components-footer-brand-color-footer-menu-expanded": "#000000",
        "components-footer-brand-color-link-default": "#ffffff",
        "components-footer-brand-color-link-hover": "#fedd00",
        "components-footer-brand-color-link-pressed": "#fedd00",
        "components-footer-brand-color-category-link-default": "#ffffff",
        "components-footer-brand-color-category-link-hover": "#fedd00",
        "components-footer-brand-color-category-link-pressed": "#fedd00",
        "components-footer-brand-color-subcategory-link-default": "#fcfcfd",
        "components-footer-brand-color-subcategory-link-hover": "#fedd00",
        "components-footer-brand-color-subcategory-link-pressed": "#fedd00",
        "components-footer-brand-color-icon-icon-default": "#ffffff",
        "components-footer-brand-color-icon-icon-pressed": "#fedd00",
        "components-footer-brand-color-social-icon-default": "#ffffff",
        "components-footer-brand-color-social-icon-hover": "#fedd00",
        "components-footer-brand-color-social-icon-pressed": "#fedd00",
        "components-footer-brand-spacing-category-link-hover": "#000000",
        "components-footer-global-color-bg": "#ffffff",
        "components-footer-global-color-title-text": "#000000",
        "components-footer-global-color-disclaimer-text": "#000000",
        "components-footer-global-color-link-default": "#000000",
        "components-footer-global-color-link-hover": "#000000",
        "components-footer-global-color-link-pressed": "#000000",
        "components-generic-card-color-bg": "#ffffff",
        "components-generic-card-color-content-bg": "#ffffff",
        "components-generic-card-color-title": "#191b1c",
        "components-generic-card-color-subtitle": "#27282b",
        "components-generic-card-color-copy": "#27282b",
        "components-global-search-color-omnibox-list-bg": "#ffffff",
        "components-global-search-color-omnibox-divider": "#a2a5ab",
        "components-global-search-color-omnibox-item-bg-default": "#ffffff",
        "components-global-search-color-omnibox-item-bg-hover": "#f0f7ff",
        "components-global-search-color-omnibox-item-text-default": "#005eb5",
        "components-global-search-color-omnibox-item-text-hover": "#004b9b",
        "components-global-search-color-omnibox-label-text-default": "#4e5056",
        "components-global-search-color-omnibox-category-text-default": "#4e5056",
        "components-generic-card-listing-color-bg": "#e8e8ea",
        "components-generic-card-listing-color-title": "#191b1c",
        "components-generic-card-listing-color-copy": "#27282b",
        "components-header-color-bg": "#ffffff",
        "components-header-color-bottom-stroke": "#e8e8ea",
        "components-header-color-search-search-border-default": "#191b1c",
        "components-header-color-search-search-text": "#4e5056",
        "components-header-color-search-search-icon": "#4e5056",
        "components-header-color-search-search-bar": "#191b1c",
        "components-header-color-mobile-mobile-expanded-bg": "#ffffff",
        "components-header-color-mobile-pressed-bg": "#e8e8ea",
        "components-header-color-language-selector-language": "#4e5056",
        "components-header-color-language-selector-country": "#191b1c",
        "components-header-color-language-selector-country-hover-bg": "#e8e8ea",
        "components-header-color-language-selector-megamenu-language": "#4e5056",
        "components-header-color-language-selector-megamenu-country": "#191b1c",
        "components-header-color-language-selector-megamenu-country-hover": "#e8e8ea",
        "components-header-color-language-selector-bg": "#ffffff",
        "components-header-color-language-selector-stroke": "#72757e",
        "components-header-color-mega-menu-bg": "#ffffff",
        "components-header-color-mega-menu-featured-bg": "#191b1c",
        "components-header-color-mega-menu-featured-title": "#fedd00",
        "components-header-color-mega-menu-category-title": "#27282b",
        "components-header-color-mega-menu-bg-accent": "#ffffff",
        "components-header-color-icon-icon-default": "#4e5056",
        "components-header-color-icon-icon-hover": "#191b1c",
        "components-header-color-icon-icon-pressed": "#000000",
        "components-header-color-links-nav-main-link-default": "#4e5056",
        "components-header-color-links-nav-main-link-hover": "#191b1c",
        "components-header-color-links-nav-main-link-pressed": "#000000",
        "components-header-color-links-nav-main-link-active": "#000000",
        "components-header-color-links-nav-main-active-bar": "#fedd00",
        "components-header-color-links-nav-main-bg-color": "#ffffff",
        "components-header-color-links-nav-category-link-category-default": "#4e5056",
        "components-header-color-links-nav-category-link-category-hover": "#191b1c",
        "components-header-color-links-nav-category-link-category-pressed": "#000000",
        "components-header-color-links-nav-category-link-category-active": "#000000",
        "components-header-color-links-nav-menu-link-link-default": "#4e5056",
        "components-header-color-links-nav-menu-link-link-hover": "#191b1c",
        "components-header-color-links-nav-menu-link-link-pressed": "#000000",
        "components-header-color-links-nav-menu-link-link-active": "#000000",
        "components-header-color-links-nav-menu-link-bg": "#ffffff",
        "components-header-color-links-nav-subcategory-link-subcategory-default": "#4e5056",
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": "#000000",
        "components-header-color-links-nav-subcategory-link-subcategory-active": "#000000",
        "components-hero-homepage-color-bg": "#191b1c",
        "components-hero-homepage-color-container-bg": "#191b1c",
        "components-hero-homepage-color-eyebrow": "#fcfcfd",
        "components-hero-homepage-color-header": "#fedd00",
        "components-hero-homepage-color-sub-header": "#fcfcfd",
        "components-hero-homepage-color-body": "#fcfcfd",
        "components-hero-homepage-color-play-pause": "#ffffff",
        "components-hero-internal-color-default-title": "#ffffff",
        "components-hero-internal-color-default-body": "#fcfcfd",
        "components-hero-internal-color-default-bg": "#191b1c",
        "components-hero-internal-color-accent-bg": "#191b1c",
        "components-hero-internal-color-with-image-bg": "#191b1c",
        "components-hero-internal-color-default-bg-2": "#191b1c",
        "components-hero-internal-color-with-image-title": "#fedd00",
        "components-hero-internal-color-with-image-body": "#fcfcfd",
        "components-heading-page-title-color-default-bg": "#191b1c",
        "components-heading-page-title-color-default-title": "#fedd00",
        "components-heading-page-title-color-default-body": "#fcfcfd",
        "components-heading-page-title-color-light-bg": "#ffffff",
        "components-heading-page-title-color-light-title": "#191b1c",
        "components-heading-page-title-color-light-body": "#27282b",
        "components-heading-page-title-color-color-bg": "#fedd00",
        "components-heading-page-title-color-color-title": "#191b1c",
        "components-heading-page-title-color-color-body": "#27282b",
        "components-hyperlink-color-dark-default": "#005eb5",
        "components-hyperlink-color-dark-hover": "#0a3d70",
        "components-hyperlink-color-dark-visited": "#5c068c",
        "components-hyperlink-color-light-default": "#7bc3fe",
        "components-hyperlink-color-light-hover": "#0a8aed",
        "components-hyperlink-color-light-visited": "#a80fff",
        "components-icon-default": "#72757e",
        "components-icon-focused": "#72757e",
        "components-icon-card-color-bg": "#ffffff",
        "components-icon-card-color-icon-bg": "#fedd00",
        "components-icon-card-color-icon": "#191b1c",
        "components-icon-card-color-title": "#191b1c",
        "components-icon-card-color-subtitle": "#27282b",
        "components-icon-card-color-description": "#27282b",
        "components-icon-card-listing-color-bg": "#e8e8ea",
        "components-icon-card-listing-color-inner-bg": "#ffffff",
        "components-icon-card-listing-color-brand-bg": "#191b1c",
        "components-icon-card-listing-color-title": "#191b1c",
        "components-icon-card-listing-color-copy": "#27282b",
        "components-icon-card-listing-color-inner-legal-text": "#fcfcfd",
        "components-jumplink-color-bg-default": "#ffffff",
        "components-jumplink-color-link-text-default": "#4e5056",
        "components-jumplink-color-link-text-hover": "#191b1c",
        "components-jumplink-color-link-border-hover": "#191b1c",
        "components-jumplink-color-link-text-active": "#000000",
        "components-jumplink-color-link-border-active": "#000000",
        "components-jumplink-color-title-text": "#191b1c",
        "components-jumplink-color-description-text": "#27282b",
        "components-layout-bg-default": "#ffffff",
        "components-lightbox-color-overlay-bg": "rgba(232, 232, 234, 0.95)",
        "components-lightbox-color-title": "#191b1c",
        "components-lightbox-color-body": "#27282b",
        "components-label-color-on-color-label-label-default": "#ffffff",
        "components-label-color-on-color-label-label-hover": "#4e5056",
        "components-label-color-on-color-label-label-pressed": "#27282b",
        "components-label-color-on-color-background-bg-default": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-color-background-bg-hover": "#ffffff",
        "components-label-color-on-color-background-bg-pressed": "#e8e8ea",
        "components-label-color-on-color-stroke-hover": "#ffffff",
        "components-label-color-on-color-stroke-pressed": "#e8e8ea",
        "components-label-color-on-color-stroke-default-gradient-fill-1": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-2": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-3": "#ffffff",
        "components-label-color-on-color-stroke-default-gradient-fill-4": "#ffffff",
        "components-label-color-on-white-label-label-default": "#191b1c",
        "components-label-color-on-white-label-label-hover": "#ffffff",
        "components-label-color-on-white-label-label-pressed": "#ffffff",
        "components-label-color-on-white-background-bg-default": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-white-background-bg-hover": "#191b1c",
        "components-label-color-on-white-background-bg-pressed": "#000000",
        "components-label-color-on-white-stroke-hover": "#191b1c",
        "components-label-color-on-white-stroke-pressed": "#000000",
        "components-label-color-on-white-stroke-default-gradient-fill-1": "#191b1c",
        "components-label-color-on-white-stroke-default-gradient-fill-2": "#191b1c",
        "components-label-color-on-white-stroke-default-gradient-fill-3": "#191b1c",
        "components-label-color-on-white-stroke-default-gradient-fill-4": "#191b1c",
        "components-legal-disclaimer-color-default-bg": "#e8e8ea",
        "components-legal-disclaimer-color-default-title": "#191b1c",
        "components-legal-disclaimer-color-default-body": "#27282b",
        "components-legal-disclaimer-color-light-bg": "#e8e8ea",
        "components-legal-disclaimer-color-light-title": "#191b1c",
        "components-legal-disclaimer-color-light-body": "#27282b",
        "components-legal-disclaimer-color-brand-bg": "#191b1c",
        "components-legal-disclaimer-color-brand-title": "#ffffff",
        "components-legal-disclaimer-color-brand-body": "#fcfcfd",
        "components-media-video-thumbnail-overlay-bg": "rgba(25, 27, 28, 0.80)",
        "components-media-caption-color-bg": "#fcfcfd",
        "components-media-caption-color-text": "#27282b",
        "components-media-gallery-color-bg": "#e8e8ea",
        "components-media-gallery-color-title": "#191b1c",
        "components-media-gallery-color-body": "#27282b",
        "components-pagination-on-white-accent-default": "#27282b",
        "components-pagination-on-white-accent-scroll": "#27282b",
        "components-pagination-on-white-disabled": "#a2a5ab",
        "components-pagination-on-white-bg": "#a2a5ab",
        "components-pagination-on-white-stroke": "#e8e8ea",
        "components-pagination-on-white-bg-active": "#191b1c",
        "components-pagination-on-white-bg-hover": "#e8e8ea",
        "components-pagination-on-white-text-default": "#72757e",
        "components-pagination-on-white-text-hover": "#27282b",
        "components-pagination-on-white-text-active": "#ffffff",
        "components-pagination-on-color-accent-default": "#ffffff",
        "components-pagination-on-color-accent-scroll": "#e8e8ea",
        "components-pagination-on-color-disabled": "#442704",
        "components-pagination-on-color-bg": "#4e5056",
        "components-pagination-on-color-stroke": "rgba(255, 255, 255, 0.38)",
        "components-pagination-on-color-bg-active": "#ffffff",
        "components-pagination-on-color-bg-hover": "#27282b",
        "components-pagination-on-color-text-default": "#ffffff",
        "components-pagination-on-color-text-hover": "#ffffff",
        "components-pagination-on-color-text-active": "#191b1c",
        "components-pdf-link-card-color-bg": "#e8e8ea",
        "components-pdf-link-card-color-card-bg": "#ffffff",
        "components-pdf-link-card-color-card-title": "#191b1c",
        "components-pdf-link-card-color-card-subtitle": "#27282b",
        "components-pdf-link-card-color-card-body": "#27282b",
        "components-product-card-color-bg": "#ffffff",
        "components-product-card-color-content-bg": "#ffffff",
        "components-product-card-color-border": "#e8e8ea",
        "components-product-card-color-title": "#191b1c",
        "components-product-card-color-description": "#27282b",
        "components-product-card-color-star-rating": "#72757e",
        "components-product-card-color-badge-bg": "#fedd00",
        "components-product-card-color-badge-text": "#191b1c",
        "components-product-card-listing-color-bg": "#191b1c",
        "components-product-card-listing-color-title": "#fedd00",
        "components-product-card-listing-color-copy": "#fcfcfd",
        "components-product-card-listing-color-legal-text": "#fcfcfd",
        "components-product-information-color-bg": "#ffffff",
        "components-product-information-color-content-bg": "#ffffff",
        "components-product-information-color-headline": "#191b1c",
        "components-product-information-color-body": "#27282b",
        "components-product-information-color-detail-text": "#27282b",
        "components-product-information-color-divider": "#a2a5ab",
        "components-product-information-color-icon": "#fedd00",
        "components-product-information-color-play-icon": "#ffffff",
        "components-product-information-color-selected-stroke": "#005eb5",
        "components-product-results-list-with-search-color-bg": "#e8e8ea",
        "components-promo-color-default-bg": "#e8e8ea",
        "components-promo-color-default-image-bg": "#e8e8ea",
        "components-promo-color-default-content-bg": "#e8e8ea",
        "components-promo-color-default-title": "#191b1c",
        "components-promo-color-default-body": "#27282b",
        "components-promo-color-default-category": "#191b1c",
        "components-promo-color-light-bg": "#ffffff",
        "components-promo-color-light-image-bg": "#ffffff",
        "components-promo-color-light-content-bg": "#ffffff",
        "components-promo-color-light-title": "#191b1c",
        "components-promo-color-light-body": "#27282b",
        "components-promo-color-light-category": "#191b1c",
        "components-promo-color-brand-bg": "#191b1c",
        "components-promo-color-brand-image-bg": "#191b1c",
        "components-promo-color-brand-content-bg": "#191b1c",
        "components-promo-color-brand-title": "#ffffff",
        "components-promo-color-brand-body": "#fcfcfd",
        "components-promo-color-brand-category": "#ffffff",
        "components-pull-quote-color-bg": "#191b1c",
        "components-pull-quote-color-stroke": "#fedd00",
        "components-pull-quote-color-quote-bg": "#191b1c",
        "components-pull-quote-color-quote-title": "#fcfcfd",
        "components-pull-quote-color-quote-by-text": "#fcfcfd",
        "components-pull-quote-color-quote-detail": "#fedd00",
        "components-pull-quote-color-quote-highlight": "#fedd00",
        "components-promo-alternate-color-default-bg": "#e8e8ea",
        "components-promo-alternate-color-default-image-bg": "#e8e8ea",
        "components-promo-alternate-color-default-content-bg": "#e8e8ea",
        "components-promo-alternate-color-default-title": "#191b1c",
        "components-promo-alternate-color-default-body": "#27282b",
        "components-promo-alternate-color-default-category": "#191b1c",
        "components-promo-alternate-color-light-bg": "#ffffff",
        "components-promo-alternate-color-light-image-bg": "#ffffff",
        "components-promo-alternate-color-light-content-bg": "#ffffff",
        "components-promo-alternate-color-light-title": "#191b1c",
        "components-promo-alternate-color-light-body": "#27282b",
        "components-promo-alternate-color-light-category": "#191b1c",
        "components-promo-alternate-color-brand-bg": "#191b1c",
        "components-promo-alternate-color-brand-image-bg": "#191b1c",
        "components-promo-alternate-color-brand-content-bg": "#191b1c",
        "components-promo-alternate-color-brand-title": "#ffffff",
        "components-promo-alternate-color-brand-body": "#fcfcfd",
        "components-promo-alternate-color-brand-category": "#ffffff",
        "components-share-list-color-title": "#ffffff",
        "components-share-list-color-bg": "#27282b",
        "components-share-list-color-link-default": "#fcfcfd",
        "components-share-list-color-link-hover": "#fedd00",
        "components-share-list-color-bg-link-hover": "#191b1c",
        "components-share-list-color-close-icon": "#ffffff",
        "components-share-list-color-facebook-icon": "#3b5998",
        "components-share-list-color-pinterest-icon": "#e60023",
        "components-share-list-color-twitter-icon": "#ffffff",
        "components-share-list-color-basic-icon-default": "#ffffff",
        "components-share-list-color-icon-hover": "#fedd00",
        "components-share-list-color-border": "#191b1c",
        "components-site-search-bg": "#ffffff",
        "components-site-search-results_text": "#27282b",
        "components-site-search-filter-filter-selected-bg": "#feffe7",
        "components-site-search-facet-group-label-bottom-line": "#e8e8ea",
        "components-site-search-facet-group-label-text": "#27282b",
        "components-site-search-facet-group-label-bg": "#ffffff",
        "components-site-search-facet-bg": "#ffffff",
        "components-site-search-facet-stroke": "#e8e8ea",
        "components-site-search-refine-bg": "#ffffff",
        "components-site-search-refine-right-divider": "#e8e8ea",
        "components-site-search-refine-title": "#191b1c",
        "components-site-search-hero-search-bg": "#191b1c",
        "components-site-search-hero-search-accent-bg": "#191b1c",
        "components-site-search-hero-search-title": "#fedd00",
        "components-stats-stat-text": "#fedd00",
        "components-stats-stat-text-on-light": "#191b1c",
        "components-stats-supporting-text": "#fcfcfd",
        "components-stats-bg-default": "#191b1c",
        "components-stats-bg-inner": "#191b1c",
        "components-stats-bg-section-inner": "#191b1c",
        "components-stats-title": "#ffffff",
        "components-stats-copy": "#fcfcfd",
        "components-supporting-text-default": "#4e5056",
        "components-supporting-text-destructive": "#b32b30",
        "components-tab-bg": "#ffffff",
        "components-tab-border-default": "#72757e",
        "components-tab-border-hover": "#fedd00",
        "components-tab-border-focused": "#191b1c",
        "components-tab-border-destructive": "#b32b30",
        "components-tab-label-text-default": "#191b1c",
        "components-tab-item-text-default": "#191b1c",
        "components-tab-item-bg-default": "#ffffff",
        "components-tab-item-bg-hover": "#feffe7",
        "components-tab-input-field-default": "#72757e",
        "components-tab-input-field-focused": "#191b1c",
        "components-tab-input-label-focused": "#191b1c",
        "components-tab-input-field-destructive": "#b32b30",
        "components-tab-bg-2": "#e8e8ea",
        "components-tag-color-bg": "rgba(114, 117, 126, 0.25)",
        "components-tag-color-text": "#191b1c",
        "components-tag-color-stroke": "rgba(114, 117, 126, 0.25)",
        "components-tag-color-bg-hover": "#72757e",
        "components-tag-color-text-hover": "#191b1c",
        "components-tag-color-stroke-hover": "#72757e",
        "components-text-field-bg": "#ffffff",
        "components-text-field-bg-focused": "#f0f7ff",
        "components-text-field-border-default": "#191b1c",
        "components-text-field-border-hover": "#005eb5",
        "components-text-field-border-focused": "#191b1c",
        "components-text-field-border-destructive": "#b32b30",
        "components-text-field-input-field-default": "#4e5056",
        "components-text-field-input-field-focused": "#191b1c",
        "components-text-field-input-label-focused": "#191b1c",
        "components-text-field-input-field-destructive": "#b32b30",
        "components-text-field-icon-default": "#4e5056",
        "components-text-field-icon-destructive": "#b32b30",
        "components-text-field-supporting-text-default": "#4e5056",
        "components-text-field-supporting-text-destructive": "#b32b30",
        "components-tip-color-bg": "#191b1c",
        "components-tip-color-title": "#fedd00",
        "components-tip-color-body": "#fcfcfd",
        "components-tip-color-stroke": "#191b1c",
        "components-video-color-bg": "#ffffff",
        "themes-general-surface-color-light-bg": "#e8e8ea",
        "themes-general-surface-color-light-title": "#191b1c",
        "themes-general-surface-color-light-body": "#27282b",
        "themes-general-surface-color-light-decor-line-break": "#72757e",
        "themes-general-surface-color-light-rule-line": "#72757e",
        "themes-general-surface-color-dark-bg": "#191b1c",
        "themes-general-surface-color-dark-title": "#ffffff",
        "themes-general-surface-color-dark-body": "#fcfcfd",
        "themes-general-surface-color-dark-decor-line-break": "#72757e",
        "themes-general-card-color-dark-title": "#191b1c",
        "themes-general-card-color-dark-subtitle": "#27282b",
        "themes-general-card-color-dark-body": "#27282b",
        "themes-general-card-color-light-title": "#ffffff",
        "themes-general-card-color-light-subtitle": "#fcfcfd",
        "themes-general-card-color-light-body": "#fcfcfd",
        "themes-shadow-color": "#ffffff",
        "themes-shadow-share-list-color-fill": "rgba(0, 0, 0, 0.40)",
        "themes-shadow-header-menu-color-fill": "rgba(0, 0, 0, 0.20)",
        "themes-gradient-loader-gradient-fill-1": "#191b1c",
        "themes-gradient-loader-gradient-fill-2": "rgba(25, 27, 28, 0.00)",
        "themes-color-background-white": "#ffffff",
        "themes-color-background-light": "#e8e8ea",
        "themes-color-background-dark": "#27282b",
        "themes-color-background-black": "#191b1c",
        "themes-color-background-brand1": "#fedd00",
        "themes-color-background-brand2": "#005eb5",
        "themes-color-background-accent1": "#c8102e",
        "themes-color-background-accent2": "#5c068c",
        "themes-color-background-body": "#ffffff",
        "themes-color-stroke-white": "#ffffff",
        "themes-color-stroke-light": "#e8e8ea",
        "themes-color-stroke-dark": "#27282b",
        "themes-color-stroke-black": "#191b1c",
        "themes-color-stroke-brand1": "#fedd00",
        "themes-color-stroke-brand2": "#005eb5",
        "themes-color-stroke-accent1": "#c8102e",
        "themes-color-stroke-accent2": "#5c068c",
        "themes-color-text-white": "#ffffff",
        "themes-color-text-light": "#fcfcfd",
        "themes-color-text-dark": "#27282b",
        "themes-color-text-black": "#191b1c",
        "themes-color-text-brand1": "#fedd00",
        "themes-color-text-brand2": "#004b9b",
        "themes-color-text-accent1": "#6c1028",
        "themes-color-text-accent2": "#2a063f",
        "colors-grayscale-100": "#fcfcfd",
        "colors-grayscale-200": "#e8e8ea",
        "colors-grayscale-300": "#a2a5ab",
        "colors-grayscale-400": "#72757e",
        "colors-grayscale-500": "#4e5056",
        "colors-grayscale-600": "#27282b",
        "colors-grayscale-700": "#191b1c",
        "colors-brand-1-100": "#feffe7",
        "colors-brand-1-200": "#fdff86",
        "colors-brand-1-300": "#ffec0d",
        "colors-brand-1-400": "#fedd00",
        "colors-brand-1-500": "#d1a400",
        "colors-brand-1-600": "#895b0a",
        "colors-brand-1-700": "#442704",
        "colors-brand-2-100": "#f0f7ff",
        "colors-brand-2-200": "#7bc3fe",
        "colors-brand-2-300": "#0a8aed",
        "colors-brand-2-400": "#005eb5",
        "colors-brand-2-500": "#004b9b",
        "colors-brand-2-600": "#0a3d70",
        "colors-brand-2-700": "#07274a",
        "colors-accent-1-100": "#ffe4e4",
        "colors-accent-1-200": "#fea3a7",
        "colors-accent-1-300": "#e41a36",
        "colors-accent-1-400": "#c8102e",
        "colors-accent-1-500": "#9a1833",
        "colors-accent-1-600": "#6c1028",
        "colors-accent-1-700": "#340512",
        "colors-accent-2-100": "#edcdff",
        "colors-accent-2-200": "#a80fff",
        "colors-accent-2-300": "#7d06c3",
        "colors-accent-2-400": "#5c068c",
        "colors-accent-2-500": "#4f057b",
        "colors-accent-2-600": "#43056d",
        "colors-accent-2-700": "#2a063f",
        "colors-accent-3-100": "#fff6ed",
        "colors-accent-3-200": "#ffd3a8",
        "colors-accent-3-300": "#ff8837",
        "colors-accent-3-400": "#ff6a14",
        "colors-accent-3-500": "#f04c06",
        "colors-accent-3-600": "#9e2c0e",
        "colors-accent-3-700": "#451005",
        "colors-accent-4-400_storage": "#74aa50",
        "colors-accessible-accent-1-100": "#ffe4e4",
        "colors-accessible-accent-1-200": "#fea3a7",
        "colors-accessible-accent-1-300": "#e41a36",
        "colors-accessible-accent-1-400": "#c8102e",
        "colors-accessible-accent-1-500": "#9a1833",
        "colors-accessible-accent-1-600": "#6c1028",
        "colors-accessible-accent-1-700": "#340512",
        "colors-accessible-accent-2-100": "#edcdff",
        "colors-accessible-accent-2-200": "#a80fff",
        "colors-accessible-accent-2-300": "#7d06c3",
        "colors-accessible-accent-2-400": "#5c068c",
        "colors-accessible-accent-2-500": "#4f057b",
        "colors-accessible-accent-2-600": "#43056d",
        "colors-accessible-accent-2-700": "#2a063f",
        "colors-accessible-brand-2-100": "#f0f7ff",
        "colors-accessible-brand-2-200": "#7bc3fe",
        "colors-accessible-brand-2-300": "#0a8aed",
        "colors-accessible-brand-2-400": "#005eb5",
        "colors-accessible-brand-2-500": "#004b9b",
        "colors-accessible-brand-2-600": "#0a3d70",
        "colors-accessible-brand-2-700": "#07274a"
      },
      "spacing": {
        "components-accordion-spacing-large-padding-x": "5rem",
        "components-accordion-spacing-large-icon-margin-right": "2rem",
        "components-accordion-spacing-large-copy-margin-right": "2.5rem",
        "components-accordion-spacing-large-maxwidth": "59.625rem",
        "components-accordion-spacing-large-section-padding-x": "0rem",
        "components-accordion-spacing-large-section-padding-y": "2.5rem",
        "components-accordion-spacing-large-section-copy-margin-bottom": "2.5rem",
        "components-accordion-spacing-large-section-space-between": "0rem",
        "components-accordion-spacing-large-item-padding-x": "0rem",
        "components-accordion-spacing-large-item-padding-y": "1.5rem",
        "components-accordion-spacing-large-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-large-content-padding-x": "0rem",
        "components-accordion-spacing-large-content-padding-y": "1.5rem",
        "components-accordion-spacing-large-content-title-margin-bottom": "2rem",
        "components-accordion-spacing-large-content-content-space-between": "0.25rem",
        "components-accordion-spacing-large-content-content-margin-left": "1rem",
        "components-accordion-spacing-small-padding-top": "1rem",
        "components-accordion-spacing-small-padding-x": "1.5rem",
        "components-accordion-spacing-small-padding-y": "2.5rem",
        "components-accordion-spacing-small-icon-margin-right": "0.75rem",
        "components-accordion-spacing-small-title-margin-bottom": "0.5rem",
        "components-accordion-spacing-small-copy-margin-right": "2rem",
        "components-accordion-spacing-small-copy-margin-bottom": "1.5rem",
        "components-accordion-spacing-small-section-padding-x": "0rem",
        "components-accordion-spacing-small-section-padding-y": "0rem",
        "components-accordion-spacing-small-section-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-space-between": "2.5rem",
        "components-accordion-spacing-small-item-padding-x": "0rem",
        "components-accordion-spacing-small-item-padding-y": "1rem",
        "components-accordion-spacing-small-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-small-content-padding-x": "0rem",
        "components-accordion-spacing-small-content-padding-y": "0.75rem",
        "components-accordion-spacing-small-content-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-content-content-space-between": "1rem",
        "components-accordion-spacing-small-content-content-margin-left": "1rem",
        "components-article-card-spacing-large-padding-image-x": "1rem",
        "components-article-card-spacing-large-padding-image-y": "1rem",
        "components-article-card-spacing-large-padding-content-x": "1.5rem",
        "components-article-card-spacing-large-padding-content-y": "1.5rem",
        "components-article-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-article-card-spacing-large-button-space-between": "1rem",
        "components-article-card-spacing-large-title-margin-bottom": "1rem",
        "components-article-card-spacing-large-list-content-gap": "1rem",
        "components-article-card-spacing-large-list-image-padding-x": "1rem",
        "components-article-card-spacing-large-list-image-padding-y": "1rem",
        "components-article-card-spacing-small-padding-image-x": "1rem",
        "components-article-card-spacing-small-padding-image-y": "1rem",
        "components-article-card-spacing-small-padding-content-x": "1.5rem",
        "components-article-card-spacing-small-padding-content-y": "1.5rem",
        "components-article-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-article-card-listing-spacing-small-padding-x": "1.5rem",
        "components-article-card-listing-spacing-small-padding-y": "2.5rem",
        "components-article-hero-spacing-large-padding-x": "0rem",
        "components-article-hero-spacing-large-padding-y": "0rem",
        "components-article-hero-spacing-large-title-margin-bottom": "2rem",
        "components-article-hero-spacing-large-label-share-margin-bottom": "2rem",
        "components-article-hero-spacing-large-author-name-margin-bottom": "0.75rem",
        "components-article-hero-spacing-large-author-thumbnail-margin-left": "1rem",
        "components-article-hero-spacing-large-details-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-space-between": "1rem",
        "components-article-hero-spacing-large-body-margin-bottom": "2rem",
        "components-article-hero-spacing-large-image-space-between": "1rem",
        "components-article-hero-spacing-large-image-margin-bottom": "1rem",
        "components-article-hero-spacing-large-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-stats-margin-bottom": "2.5rem",
        "components-article-hero-spacing-small-content-margin-top": "2.5rem",
        "components-article-hero-spacing-small-title-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-label-share-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-authorname-margin-bottom": "0.75rem",
        "components-article-hero-spacing-small-authorthumbnail-margin-left": "1rem",
        "components-article-hero-spacing-small-details-margin-bottom": "1rem",
        "components-article-hero-spacing-small-space-between": "0.75rem",
        "components-article-hero-spacing-small-body-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-image-space-between": "0.75rem",
        "components-article-hero-spacing-small-image-margin-bottom": "1rem",
        "components-article-hero-spacing-small-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-stats-margin-bottom": "2rem",
        "components-banner-spacing-large-content-padding-x": "1.5rem",
        "components-banner-spacing-large-content-space-between": "1rem",
        "components-banner-spacing-large-content-width": "69.375rem",
        "components-banner-spacing-icon-dimensions": "1.5rem",
        "components-banner-spacing-icon-margin-left": "0.5rem",
        "components-banner-spacing-small-space-between": "1rem",
        "components-banner-spacing-small-content-space-between": "1rem",
        "components-banner-spacing-small-title-margin-bottom": "0.75rem",
        "components-banner-spacing-small-content-margin-bottom": "1rem",
        "components-breadcrumb-spacing-large-space-between": "0.25rem",
        "components-breadcrumb-spacing-small-space-between": "0.25rem",
        "components-button-spacing-large-icon-large-x": "2.5rem",
        "components-button-spacing-large-icon-large-y": "2.5rem",
        "components-button-spacing-large-default-padding-y": "0.75rem",
        "components-button-spacing-large-default-padding-x": "1.5rem",
        "components-button-spacing-large-default-space-between": "0.25rem",
        "components-button-spacing-large-icon-left-padding-left": "1rem",
        "components-button-spacing-large-icon-right-padding-right": "1rem",
        "components-button-spacing-large-icon-only-padding-x": "0.75rem",
        "components-button-spacing-large-icon-only-padding-y": "0.75rem",
        "components-button-spacing-large-tertiary-content-height": "1.5rem",
        "components-button-spacing-compressed-icon-large-x": "2.5rem",
        "components-button-spacing-compressed-icon-large-y": "2.5rem",
        "components-button-spacing-compressed-default-padding-y": "0.5rem",
        "components-button-spacing-compressed-default-padding-x": "1rem",
        "components-button-spacing-compressed-default-space-between": "0.125rem",
        "components-button-spacing-compressed-icon-left-padding-left": "0.5rem",
        "components-button-spacing-compressed-icon-right-padding-right": "0.5rem",
        "components-button-spacing-compressed-icon-only-padding-x": "0.5rem",
        "components-button-group-spacing-padding-y": "0.125rem",
        "components-button-group-spacing-padding-x": "0.25rem",
        "components-button-group-spacing-space-between": "0.5rem",
        "components-category-card-spacing-large-padding-top": "0rem",
        "components-category-card-spacing-large-padding-x": "0rem",
        "components-category-card-spacing-large-padding-y": "0rem",
        "components-category-card-spacing-large-padding-image-x": "0rem",
        "components-category-card-spacing-large-padding-image-y": "0rem",
        "components-category-card-spacing-large-padding-content-x": "2rem",
        "components-category-card-spacing-large-padding-content-y": "2rem",
        "components-category-card-listing-spacing-large-padding-y": "5rem",
        "components-category-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-category-card-listing-spacing-large-card-margin-bottom": "0rem",
        "components-category-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-card-search-result-small-padding-top": "0rem",
        "components-card-search-result-small-padding-x": "0rem",
        "components-card-search-result-small-padding-y": "0rem",
        "components-card-search-result-small-padding-image-x": "0rem",
        "components-card-search-result-small-padding-image-y": "0rem",
        "components-card-search-result-small-padding-content-x": "1.5rem",
        "components-card-search-result-small-padding-content-y": "1.5rem",
        "components-card-search-result-spacing-large-padding-x": "0rem",
        "components-card-search-result-spacing-large-padding-y": "1.5rem",
        "components-card-search-result-spacing-large-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-image-margin-right": "1.5rem",
        "components-card-search-result-spacing-small-padding-x": "1.5rem",
        "components-card-search-result-spacing-small-padding-y": "1.5rem",
        "components-card-search-result-spacing-small-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-image-margin-right": "0.75rem",
        "components-contact-us-promo-spacing-large-padding-x": "5rem",
        "components-contact-us-promo-spacing-large-padding-y": "5rem",
        "components-contact-us-promo-spacing-large-content-margin-right": "5rem",
        "components-contact-us-promo-spacing-large-margin-y": "2.5rem",
        "components-contact-us-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-contact-us-promo-spacing-small-margin-y": "2.5rem",
        "components-content-list-list-item-container-padding-x": "0.75rem",
        "components-content-list-list-item-container-padding-y": "1rem",
        "components-content-list-list-item-content-space-between": "0.5rem",
        "components-content-list-list-item-content-default-padding-y": "0.25rem",
        "components-content-list-list-item-content-hyperlink-padding-y": "0rem",
        "components-content-list-spacing-large-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-large-item-title-margin-bottom": "1rem",
        "components-content-list-spacing-large-item-copy-margin-bottom": "2.5rem",
        "components-content-list-spacing-large-item-icon-copy-margin-top": "0rem",
        "components-content-list-spacing-large-item-margin-bottom": "2rem",
        "components-content-list-spacing-large-item-image-margin-bottom": "0.5rem",
        "components-content-list-spacing-large-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-y": "2.5rem",
        "components-content-list-spacing-small-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-small-item-title-margin-bottom": "0.5rem",
        "components-content-list-spacing-small-item-copy-margin-bottom": "1rem",
        "components-content-list-spacing-small-item-margin-bottom": "1.5rem",
        "components-content-list-spacing-small-header-title-margin-bottom": "1rem",
        "components-content-list-spacing-small-header-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-title-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-button-space-between": "1rem",
        "components-dropdown-item-padding-y": "0.75rem",
        "components-dropdown-item-padding-x": "0.75rem",
        "components-dropdown-item-spacing-x": "0.75rem",
        "components-dropdown-input-padding-y": "0.25rem",
        "components-dropdown-input-padding-x": "1rem",
        "components-footer-brand-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-large-column-margin-right": "4rem",
        "components-footer-brand-spacing-large-column-margin-bottom": "4rem",
        "components-footer-brand-spacing-large-link-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-padding-y": "2.5rem",
        "components-footer-brand-spacing-small-padding-x": "1rem",
        "components-footer-brand-spacing-small-logo-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-column-margin-right": "0.125rem",
        "components-footer-brand-spacing-small-column-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-small-link-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-title-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-text-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-footer-link-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-link-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-link-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-link-minheight": "3rem",
        "components-footer-brand-spacing-small-footer-category-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-category-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-category-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-category-minheight": "3rem",
        "components-footer-global-spacing-large-padding-y": "2.5rem",
        "components-footer-global-spacing-large-padding-x": "5rem",
        "components-footer-global-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-global-spacing-large-column-margin-right": "2rem",
        "components-footer-global-spacing-large-link-margin-bottom": "2rem",
        "components-footer-global-spacing-small-padding-y": "2.5rem",
        "components-footer-global-spacing-small-padding-x": "1rem",
        "components-footer-global-spacing-small-logo-margin-bottom": "1rem",
        "components-footer-global-spacing-small-column-margin-right": "1rem",
        "components-footer-global-spacing-small-link-margin-bottom": "1rem",
        "components-generic-card-spacing-large-padding-x": "0rem",
        "components-generic-card-spacing-large-padding-y": "0rem",
        "components-generic-card-spacing-large-padding-image-x": "0rem",
        "components-generic-card-spacing-large-padding-image-y": "0rem",
        "components-generic-card-spacing-large-padding-content-x": "2rem",
        "components-generic-card-spacing-large-padding-content-y": "2rem",
        "components-generic-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-generic-card-spacing-large-title-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-button-space-between": "1rem",
        "components-generic-card-spacing-small-padding-x": "0rem",
        "components-generic-card-spacing-small-padding-y": "0rem",
        "components-generic-card-spacing-small-padding-image-x": "0rem",
        "components-generic-card-spacing-small-padding-image-y": "0rem",
        "components-generic-card-spacing-small-padding-content-x": "1.5rem",
        "components-generic-card-spacing-small-padding-content-y": "1.5rem",
        "components-global-search-spacing-omnibox-padding-x": "0.125rem",
        "components-global-search-spacing-omnibox-list-padding-y": "0.5rem",
        "components-global-search-spacing-omnibox-label-padding": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-item-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-height": "3rem",
        "components-generic-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-generic-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-header-shadow-x": "0rem",
        "components-header-spacing-large-padding-y": "1rem",
        "components-header-spacing-large-padding-x": "1.5rem",
        "components-header-spacing-large-links-margin-right": "1.5rem",
        "components-header-spacing-large-icons-space-between": "1.5rem",
        "components-header-spacing-large-logo-margin-right": "2rem",
        "components-header-spacing-large-space-between-search": "0.5rem",
        "components-header-spacing-large-height": "5rem",
        "components-header-spacing-large-language-selector-padding-x": "1rem",
        "components-header-spacing-large-language-selector-padding-y": "0.5rem",
        "components-header-spacing-large-language-selector-space-between": "0.75rem",
        "components-header-spacing-large-language-selector-country-space-between": "0.5rem",
        "components-header-spacing-large-mega-menu-padding-y": "2.5rem",
        "components-header-spacing-large-mega-menu-padding-x": "2.5rem",
        "components-header-spacing-large-mega-menu-column-margin-right": "2.5rem",
        "components-header-spacing-large-mega-menu-category-link-margin-bottom": "1rem",
        "components-header-spacing-large-mega-menu-link-margin-bottom": "0.5rem",
        "components-header-spacing-large-mega-menu-featured-padding-x": "2rem",
        "components-header-spacing-large-mega-menu-featured-padding-y": "2rem",
        "components-header-spacing-large-mega-menu-featured-image-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-title-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-link-space-between": "1.5rem",
        "components-header-spacing-large-mega-menu-title-margin-bottom": "2.5rem",
        "components-header-spacing-large-dropdown-padding-y": "1.5rem",
        "components-header-spacing-large-dropdown-padding-x": "1.5rem",
        "components-header-spacing-large-dropdown-margin-bottom": "0.5rem",
        "components-header-spacing-small-language-selector-padding-x": "1rem",
        "components-header-spacing-small-language-selector-padding-y": "0.5rem",
        "components-header-spacing-small-language-selector-space-between": "0.5rem",
        "components-header-spacing-small-padding-left": "1rem",
        "components-header-spacing-small-padding-right": "1rem",
        "components-header-spacing-small-padding-top": "0.5rem",
        "components-header-spacing-small-padding-bottom": "0.5rem",
        "components-header-spacing-small-nav-main-minheight": "3rem",
        "components-header-spacing-small-nav-main-padding-y": "0.5rem",
        "components-header-spacing-small-nav-main-padding-left": "1.5rem",
        "components-header-spacing-small-nav-main-padding-right": "1rem",
        "components-header-spacing-small-nav-category-minheight": "3rem",
        "components-header-spacing-small-nav-category-padding-left": "1.5rem",
        "components-header-spacing-small-nav-category-padding-right": "0.75rem",
        "components-header-spacing-small-nav-category-padding-y": "0.75rem",
        "components-header-spacing-small-nav-category-space-between": "0rem",
        "components-header-spacing-small-nav-menu-link-minheight": "3rem",
        "components-header-spacing-small-nav-menu-link-padding-right": "1.5rem",
        "components-header-spacing-small-nav-menu-link-padding-left": "2.5rem",
        "components-header-spacing-small-nav-menu-link-padding-y": "0.75rem",
        "components-header-spacing-small-mega-menu-padding-y": "0rem",
        "components-header-spacing-small-mega-menu-padding-x": "0rem",
        "components-header-spacing-small-mega-menu-category-link-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-link-margin-bottom": "0rem",
        "components-header-spacing-small-mega-menu-featured-padding-x": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-padding-y": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-image-margin-bottom": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-title-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-featured-link-space-between": "0.75rem",
        "components-header-spacing-small-mega-menu-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-container-padding": "0rem",
        "components-hero-homepage-spacing-large-padding-x": "5rem",
        "components-hero-homepage-spacing-large-padding-y": "2.5rem",
        "components-hero-homepage-spacing-large-minheight": "34.3125rem",
        "components-hero-homepage-spacing-large-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-margin-bottom": "2rem",
        "components-hero-homepage-spacing-large-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-copy-margin-bottom": "2.5rem",
        "components-hero-homepage-spacing-large-cta-margin-right": "1rem",
        "components-hero-homepage-spacing-large-image-height": "34.3125rem",
        "components-hero-homepage-spacing-large-image-width": "45.75rem",
        "components-hero-homepage-spacing-small-padding-x": "0rem",
        "components-hero-homepage-spacing-small-padding-y": "1.5rem",
        "components-hero-homepage-spacing-small-minheight": "34.3125rem",
        "components-hero-homepage-spacing-small-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-copy-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-image-height": "17.5625rem",
        "components-hero-homepage-spacing-small-image-width": "45.75rem",
        "components-hero-internal-spacing-large-padding-y": "2.5rem",
        "components-hero-internal-spacing-large-padding-right": "10rem",
        "components-hero-internal-spacing-large-title-margin-bottom": "1.5rem",
        "components-hero-internal-spacing-large-button-padding-y": "2rem",
        "components-hero-internal-spacing-large-text-maxwidth": "59.625rem",
        "components-hero-internal-spacing-large-minheight": "18.5rem",
        "components-hero-internal-spacing-small-padding-x": "1.5rem",
        "components-hero-internal-spacing-small-padding-right": "0rem",
        "components-hero-internal-spacing-small-button-padding-y": "1.5rem",
        "components-hero-internal-spacing-small-text-maxwidth": "30rem",
        "components-hero-internal-spacing-small-minheight": "13.5rem",
        "components-icon-card-spacing-large-padding-x": "0rem",
        "components-icon-card-spacing-large-padding-y": "0rem",
        "components-icon-card-spacing-large-icon-margin": "1.5rem",
        "components-icon-card-spacing-large-margin-right": "1.5rem",
        "components-icon-card-spacing-large-title-margin-bottom": "1rem",
        "components-icon-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-icon-card-spacing-large-button-space-between": "1.5rem",
        "components-icon-card-spacing-large-top-space-between": "1rem",
        "components-icon-card-spacing-small-padding-x": "1.5rem",
        "components-icon-card-spacing-small-padding-y": "1.5rem",
        "components-icon-card-spacing-small-icon-margin": "1.5rem",
        "components-icon-card-spacing-small-margin-right": "1.5rem",
        "components-icon-card-spacing-small-title-margin-bottom": "1.5rem",
        "components-icon-card-dimensions-large-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-large-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-large-big-icon-height": "4rem",
        "components-icon-card-dimensions-large-big-icon-width": "4rem",
        "components-icon-card-dimensions-large-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-large-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-large-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-large-tiny-icon-width": "2.5rem",
        "components-icon-card-dimensions-small-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-small-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-small-big-icon-height": "3.5rem",
        "components-icon-card-dimensions-small-big-icon-width": "3.5rem",
        "components-icon-card-dimensions-small-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-small-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-small-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-small-tiny-icon-width": "2.5rem",
        "components-icon-card-listing-spacing-large-padding-inner-x": "4rem",
        "components-icon-card-listing-spacing-large-padding-inner-y": "4rem",
        "components-icon-card-listing-spacing-large-card-space-between": "4rem",
        "components-icon-card-listing-spacing-large-title-margin-bottom": "1.5rem",
        "components-icon-card-listing-spacing-large-card-margin-bottom": "4rem",
        "components-icon-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-x": "1.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-y": "2.5rem",
        "components-icon-card-listing-spacing-small-title-margin-bottom": "1rem",
        "components-icon-card-listing-spacing-small-button-margin-top": "1.5rem",
        "components-jumplink-link-padding-bottom": "0.5rem",
        "components-jumplink-spacing-small-title-area-margin-bottom": "1.5rem",
        "components-jumplink-spacing-small-link-container-padding-x": "0rem",
        "components-lightbox-spacing-large-carousel-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-carousel-space-between": "1.5rem",
        "components-lightbox-spacing-large-featured-image-height": "39.25rem",
        "components-lightbox-spacing-large-featured-image-width": "39.25rem",
        "components-lightbox-spacing-large-image-height": "17.0625rem",
        "components-lightbox-spacing-large-image-width": "17.0625rem",
        "components-lightbox-spacing-small-carousel-margin-bottom": "1rem",
        "components-lightbox-spacing-small-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-small-media-details-margin-top": "1rem",
        "components-lightbox-spacing-small-image-height": "20.4375rem",
        "components-lightbox-spacing-small-image-width": "20.4375rem",
        "components-legal-disclaimer-spacing-small-ruleline-width": "20.4375rem",
        "components-media-spacing-large-padding-y": "0.5rem",
        "components-media-spacing-large-padding-x": "0.75rem",
        "components-media-gallery-large-container-grid-gap-x": "1.5rem",
        "components-media-gallery-large-container-grid-gap-y": "1.5rem",
        "components-media-gallery-small-container-grid-gap-x": "0.5rem",
        "components-media-gallery-small-container-grid-gap-y": "0.5rem",
        "components-media-gallery-spacing-small-padding-x": "1.5rem",
        "components-media-gallery-spacing-small-padding-y": "2.5rem",
        "components-media-gallery-spacing-large-padding-x": "5rem",
        "components-media-gallery-spacing-large-padding-y": "5rem",
        "components-product-card-spacing-list-content-gap": "0rem",
        "components-product-card-spacing-large-padding-image-x": "0rem",
        "components-product-card-spacing-large-padding-image-y": "0rem",
        "components-product-card-spacing-large-padding-content-x": "1.5rem",
        "components-product-card-spacing-large-padding-content-y": "1.5rem",
        "components-product-card-spacing-large-category-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-title-margin-bottom": "1rem",
        "components-product-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-button-space-between": "1rem",
        "components-product-card-spacing-small-padding-image-x": "0rem",
        "components-product-card-spacing-small-padding-image-y": "0rem",
        "components-product-card-spacing-small-padding-content-x": "1.5rem",
        "components-product-card-spacing-small-padding-content-y": "1.5rem",
        "components-product-card-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-large-padding-x": "5rem",
        "components-product-information-spacing-large-padding-y": "2.5rem",
        "components-product-information-spacing-large-title-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-label-margin-bottom": "2.5rem",
        "components-product-information-spacing-large-ratings-margin-bottom": "1rem",
        "components-product-information-spacing-large-details-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-space-between": "1rem",
        "components-product-information-spacing-large-carousel-space-between": "5rem",
        "components-product-information-spacing-large-button-margin-y": "0.5rem",
        "components-product-information-spacing-large-body-margin-top": "1.5rem",
        "components-product-information-spacing-large-image-space-between": "1rem",
        "components-product-information-spacing-large-image-margin-bottom": "1rem",
        "components-product-information-spacing-small-padding-x": "1.5rem",
        "components-product-information-spacing-small-padding-y": "1.5rem",
        "components-product-information-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-small-label-margin-bottom": "1.5rem",
        "components-product-information-spacing-small-ratings-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-details-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-space-between": "0.75rem",
        "components-product-information-spacing-small-carousel-space-between": "2.5rem",
        "components-product-information-spacing-small-button-margin-y": "0.75rem",
        "components-product-information-spacing-small-body-margin-top": "1rem",
        "components-product-information-spacing-small-image-space-between": "0.75rem",
        "components-product-information-spacing-small-image-margin-bottom": "1rem",
        "components-product-results-list-with-search-spacing-large-padding-x": "5rem",
        "components-product-results-list-with-search-spacing-large-padding-y": "5rem",
        "components-product-results-list-with-search-spacing-large-ruleline-margin-top": "2.5rem",
        "components-product-results-list-with-search-spacing-large-filter-space-between": "1rem",
        "components-product-results-list-with-search-spacing-large-card-space-between": "1.5rem",
        "components-product-results-list-with-search-spacing-large-card-container-margin-bottom": "5rem",
        "components-product-results-list-with-search-spacing-small-padding-x": "1.5rem",
        "components-product-results-list-with-search-spacing-small-padding-y": "2.5rem",
        "components-product-results-list-with-search-spacing-small-ruleline-margin-top": "1rem",
        "components-product-results-list-with-search-spacing-small-filter-padding-x": "0.5rem",
        "components-product-results-list-with-search-spacing-small-card-space-between": "1rem",
        "components-product-results-list-with-search-spacing-small-card-margin-bottom": "1.5rem",
        "components-promo-spacing-large-padding-x": "5rem",
        "components-promo-spacing-large-padding-y": "5rem",
        "components-promo-spacing-large-content-space-between": "5rem",
        "components-promo-spacing-large-content-space-between-y": "5rem",
        "components-promo-spacing-large-padding-content-x": "0rem",
        "components-promo-spacing-large-padding-content-condensed-x": "0rem",
        "components-promo-spacing-large-padding-content-y": "0rem",
        "components-promo-spacing-large-padding-content-condensed-y": "0rem",
        "components-promo-spacing-large-padding-image-x": "0rem",
        "components-promo-spacing-large-padding-image-y": "0rem",
        "components-promo-spacing-large-padding-image-top": "5rem",
        "components-promo-spacing-large-title-margin-bottom": "2rem",
        "components-promo-spacing-large-margin-y": "2rem",
        "components-promo-spacing-large-buttons-space-between": "1rem",
        "components-promo-spacing-small-padding-x": "0rem",
        "components-promo-spacing-small-padding-y": "0rem",
        "components-promo-spacing-small-padding-top": "0rem",
        "components-promo-spacing-small-padding-content-x": "1.5rem",
        "components-promo-spacing-small-padding-content-y": "2.5rem",
        "components-promo-spacing-small-padding-image-x": "0rem",
        "components-promo-spacing-small-padding-image-y": "0rem",
        "components-promo-spacing-small-padding-image-top": "0rem",
        "components-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-promo-spacing-small-margin-y": "2.5rem",
        "components-promo-minheight": "30rem",
        "components-pull-quote-spacing-large-padding-quote-x": "4rem",
        "components-pull-quote-spacing-large-padding-quote-y": "4rem",
        "components-pull-quote-spacing-large-title-margin-bottom": "2rem",
        "components-pull-quote-spacing-large-quote-margin-bottom": "4rem",
        "components-pull-quote-spacing-large-quote-space-between": "1.5rem",
        "components-pull-quote-spacing-large-detail-space-between": "0.5rem",
        "components-pull-quote-spacing-large-quote-minheight": "25rem",
        "components-pull-quote-spacing-small-padding-quote-x": "1.5rem",
        "components-pull-quote-spacing-small-padding-quote-y": "2.5rem",
        "components-pull-quote-spacing-small-title-margin-bottom": "1.5rem",
        "components-pull-quote-spacing-small-quote-margin-bottom": "2.5rem",
        "components-pull-quote-spacing-small-quote-space-between": "1rem",
        "components-pull-quote-spacing-small-detail-space-between": "1rem",
        "components-pull-quote-spacing-small-quote-minheight": "15rem",
        "components-promo-alternate-minheight": "30rem",
        "components-promo-alternate-spacing-large-padding-x": "5rem",
        "components-promo-alternate-spacing-large-padding-y": "5rem",
        "components-promo-alternate-spacing-large-content-space-between": "5rem",
        "components-promo-alternate-spacing-large-padding-content-x": "0rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-x": "0rem",
        "components-promo-alternate-spacing-large-padding-content-y": "0rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-y": "0rem",
        "components-promo-alternate-spacing-large-padding-image-x": "0rem",
        "components-promo-alternate-spacing-large-padding-image-y": "0rem",
        "components-promo-alternate-spacing-large-padding-image-top": "5rem",
        "components-promo-alternate-spacing-large-title-margin-bottom": "2rem",
        "components-promo-alternate-spacing-small-padding-x": "0rem",
        "components-promo-alternate-spacing-small-padding-y": "0rem",
        "components-promo-alternate-spacing-small-padding-content-x": "1.5rem",
        "components-promo-alternate-spacing-small-padding-content-y": "2.5rem",
        "components-promo-alternate-spacing-small-padding-image-x": "0rem",
        "components-promo-alternate-spacing-small-padding-image-y": "0rem",
        "components-promo-alternate-spacing-small-padding-image-top": "0rem",
        "components-promo-alternate-spacing-small-title-margin-bottom": "1.5rem",
        "components-share-list-spacing-padding-x": "1rem",
        "components-share-list-spacing-padding-y": "1rem",
        "components-header-copy-margin-bottom": "1rem",
        "components-header-margin-bottom": "1.5rem",
        "components-item-margin-bottom": "1.5rem",
        "components-site-search-padding-x": "2.5rem",
        "components-site-search-padding-y": "5rem",
        "components-site-search-facet-label-space-between": "0.25rem",
        "components-site-search-refine-padding-x": "1.5rem",
        "components-site-search-refine-padding-y": "0rem",
        "components-site-search-hero-search-height": "18.5rem",
        "components-site-search-hero-search-large-padding-x": "5rem",
        "components-site-search-hero-search-large-padding-y": "5rem",
        "components-stats-spacing-stat-text-space-between": "0.5rem",
        "components-stats-spacing-large-inner-padding-x": "5rem",
        "components-stats-spacing-large-inner-padding-y": "5rem",
        "components-stats-spacing-small-inner-padding-x": "1.5rem",
        "components-stats-spacing-small-inner-padding-y": "1.5rem",
        "components-icon-card-listing-spacing-small-top-space-between": "1rem",
        "components-supporting-text-padding-x": "0rem",
        "components-supporting-text-padding-bottom": "0.5rem",
        "components-tab-item-padding-y": "0.75rem",
        "components-tab-item-padding-x": "0.75rem",
        "components-tab-item-spacing-x": "0.75rem",
        "components-tab-input-padding-y": "0.25rem",
        "components-tab-input-padding-x": "1rem",
        "components-tag-spacing-padding-x": "0.5rem",
        "components-tag-spacing-padding-y": "0.5rem",
        "components-tag-spacing-space-between": "0.5rem",
        "components-text-field-input-padding-y": "0.25rem",
        "components-text-field-input-padding-x": "1rem",
        "components-text-field-area-input-padding-y": "1rem",
        "components-text-field-area-input-padding-x": "1rem",
        "components-text-field-supporting-text-padding-x": "0rem",
        "components-text-field-supporting-text-padding-top": "0.5rem",
        "themes-general-surface-spacing-large-padding-x": "5rem",
        "themes-general-surface-spacing-large-padding-y": "5rem",
        "themes-general-surface-spacing-large-title-margin-bottom": "2rem",
        "themes-general-surface-spacing-large-body-margin-right": "4rem",
        "themes-general-surface-spacing-large-body-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-large-margin-bottom": "4rem",
        "themes-general-surface-spacing-large-cards-space-between": "1.5rem",
        "themes-general-surface-spacing-large-button-margin-top": "2.5rem",
        "themes-general-surface-spacing-small-padding-x": "1.5rem",
        "themes-general-surface-spacing-small-padding-y": "2.5rem",
        "themes-general-surface-spacing-small-title-margin-bottom": "1rem",
        "themes-general-surface-spacing-small-body-margin-right": "2.5rem",
        "themes-general-surface-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-surface-spacing-small-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-small-cards-space-between": "1rem",
        "themes-general-surface-spacing-small-button-margin-top": "1.5rem",
        "themes-general-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "themes-general-card-spacing-large-title-margin-bottom": "1.5rem",
        "themes-general-card-spacing-large-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-large-button-space-between": "1.5rem",
        "themes-general-card-spacing-small-eyebrow-margin-bottom": "1rem",
        "themes-general-card-spacing-small-title-margin-bottom": "1.5rem",
        "themes-general-card-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-small-button-space-between": "1.5rem",
        "themes-shadow-share-list-spacing-x": "0rem",
        "themes-shadow-share-list-spacing-y": "0.75rem",
        "themes-shadow-share-list-spacing-blur": "1rem",
        "themes-shadow-share-list-spacing-spread": "-0.5rem",
        "themes-shadow-header-menu-spacing-x": "0rem",
        "themes-shadow-header-menu-spacing-y": "0.75rem",
        "themes-shadow-header-menu-spacing-blur": "1rem",
        "themes-shadow-header-menu-spacing-spread": "-0.5rem",
        "themes-typography-rte-large-h1-textsize": "3rem",
        "themes-typography-rte-large-h2-textsize": "2.5rem",
        "themes-typography-rte-large-h3-textsize": "2.25rem",
        "themes-typography-rte-large-h4-textsize": "2rem",
        "themes-typography-rte-large-h5-textsize": "1.5rem",
        "themes-typography-rte-large-h6-textsize": "1.25rem",
        "themes-typography-rte-medium-h1-textsize": "2.5rem",
        "themes-typography-rte-medium-h2-textsize": "2.25rem",
        "themes-typography-rte-medium-h3-textsize": "1.75rem",
        "themes-typography-rte-medium-h4-textsize": "1.5rem",
        "themes-typography-rte-medium-h5-textsize": "1.25rem",
        "themes-typography-rte-medium-h6-textsize": "1.125rem",
        "themes-typography-rte-small-h1-textsize": "2.5rem",
        "themes-typography-rte-small-h2-textsize": "2rem",
        "themes-typography-rte-small-h3-textsize": "1.75rem",
        "themes-typography-rte-small-h4-textsize": "1.5rem",
        "themes-typography-rte-small-h5-textsize": "1.125rem",
        "themes-typography-rte-small-h6-textsize": "1rem",
        "spacing-margin-small-1": "0.125rem",
        "spacing-margin-small-2": "0.25rem",
        "spacing-margin-small-3": "0.5rem",
        "spacing-margin-small-4": "0.75rem",
        "spacing-margin-small-5": "1rem",
        "spacing-margin-small-6": "1.5rem",
        "spacing-margin-small-7": "2.5rem",
        "spacing-margin-medium-2": "0.75rem",
        "spacing-margin-medium-4": "1.5rem",
        "spacing-margin-medium-5": "2rem",
        "spacing-margin-large-1": "0.5rem",
        "spacing-margin-large-2": "1rem",
        "spacing-margin-large-3": "1.5rem",
        "spacing-margin-large-4": "2rem",
        "spacing-margin-large-5": "2.5rem",
        "spacing-margin-large-6": "4rem",
        "spacing-margin-large-7": "5rem",
        "spacing-space-between-small-1": "0.125rem",
        "spacing-space-between-small-2": "0.25rem",
        "spacing-space-between-small-3": "0.5rem",
        "spacing-space-between-small-4": "0.75rem",
        "spacing-space-between-small-5": "1rem",
        "spacing-space-between-small-6": "1.5rem",
        "spacing-space-between-small-7": "2.5rem",
        "spacing-space-between-medium-1": "0.125rem",
        "spacing-space-between-medium-2": "0.25rem",
        "spacing-space-between-medium-3": "0.5rem",
        "spacing-space-between-medium-4": "0.75rem",
        "spacing-space-between-medium-5": "1rem",
        "spacing-space-between-large-1": "0.25rem",
        "spacing-space-between-large-2": "0.5rem",
        "spacing-space-between-large-3": "1rem",
        "spacing-space-between-large-4": "1.5rem",
        "spacing-space-between-large-5": "1.5rem",
        "spacing-space-between-large-6": "2.5rem",
        "spacing-space-between-large-7": "4rem",
        "spacing-padding-small-1": "0.125rem",
        "spacing-padding-small-2": "0.25rem",
        "spacing-padding-small-3": "0.5rem",
        "spacing-padding-small-4": "0.75rem",
        "spacing-padding-small-5": "1rem",
        "spacing-padding-small-6": "1.5rem",
        "spacing-padding-small-7": "2.5rem",
        "spacing-padding-medium-1": "0.25rem",
        "spacing-padding-medium-2": "0.75rem",
        "spacing-padding-medium-3": "1rem",
        "spacing-padding-medium-4": "1.5rem",
        "spacing-padding-medium-5": "2rem",
        "spacing-padding-medium-6": "3rem",
        "spacing-padding-medium-7": "4rem",
        "spacing-padding-large-1": "0.5rem",
        "spacing-padding-large-2": "1rem",
        "spacing-padding-large-3": "1.5rem",
        "spacing-padding-large-4": "2rem",
        "spacing-padding-large-5": "2.5rem",
        "spacing-padding-large-6": "4rem",
        "spacing-padding-large-7": "5rem"
      },
      "string": {
        "components-accordion-section-button": "var(--brand)",
        "components-article-card-listing-button-theme": "var(--brand)",
        "components-article-hero-pagination-buttons": "var(--brand)",
        "components-banner-banner-button": "var(--tonal)",
        "components-category-card-listing-button-theme": "var(--brand)",
        "components-contact-us-promo-hierarchy-button-theme-1": "var(--filled)",
        "components-contact-us-promo-hierarchy-button-theme-2": "var(--outlined)",
        "components-contact-us-promo-color-button-theme": "var(--brand)",
        "components-contact-us-promo-color-brand-button-theme": "var(--brand)",
        "components-generic-card-button-theme": "var(--brand)",
        "components-global-search-loader": "var(--default)",
        "components-generic-card-listing-decor-top": "var(--raid-1)",
        "components-generic-card-listing-decor-bottom": "var(--raid-2)",
        "components-hero-homepage-pagination-buttons": "var(--tonal)",
        "components-hero-homepage-pattern": "var(--raid)",
        "components-hero-homepage-button-1": "var(--tonal)",
        "components-hero-homepage-button-2": "var(--tonal)",
        "components-hero-homepage-image-ratio": "var(--5:4)",
        "components-hero-internal-theme-button-one-type": "var(--filled)",
        "components-hero-internal-theme-button-two-type": "var(--outlined)",
        "components-hero-internal-theme-button-color": "var(--tonal)",
        "components-hero-internal-theme-button-color-with-image": "var(--tonal)",
        "components-lightbox-button-type": "var(--brand)",
        "components-pdf-link-card-hierarchy-button-theme-1": "var(--filled)",
        "components-pdf-link-card-hierarchy-button-theme-2": "var(--outlined)",
        "components-pdf-link-card-color-button-theme": "var(--brand)",
        "components-product-card-badge-align": "var(--left)",
        "components-product-card-listing-button-color": "var(--white)",
        "components-promo-theme-button-one-theme": "var(--filled)",
        "components-promo-theme-button-two-theme": "var(--outlined)",
        "components-promo-color-default-button-theme": "var(--brand)",
        "components-promo-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-color-brand-button-theme": "var(--brand)",
        "components-pull-quote-border-decor": "var(--glade)",
        "components-promo-alternate-theme-button-one-theme": "var(--filled)",
        "components-promo-alternate-theme-button-two-theme": "var(--outlined)",
        "components-promo-alternate-color-default-button-theme": "var(--brand)",
        "components-promo-alternate-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-alternate-color-brand-button-theme": "var(--brand)",
        "components-share-list-button-color": "var(--brand)",
        "components-site-search-hero-search-hero-pattern": "var(--raid)",
        "components-stats-decor-top": "var(--raid-1)",
        "components-stats-decor-bottom": "var(--raid-2)",
        "themes-brand-loader": "var(--default)",
        "themes-brand-variants": "var(--raid)",
        "themes-tab-variant": "var(--default)",
        "themes-label-variants": "var(--default)",
        "themes-logo": "var(--raid)",
        "themes-category-card": "var(--raid)",
        "themes-title-decor": "var(--raid)",
        "themes-typography-heading-fontfamily": "var(--avenir)",
        "themes-typography-heading-large-xxlarge-fontweight": "var(--black)",
        "themes-typography-heading-large-xlarge-fontweight": "var(--black)",
        "themes-typography-heading-large-large-fontweight": "var(--black)",
        "themes-typography-heading-large-medium-fontweight": "var(--black)",
        "themes-typography-heading-large-small-fontweight": "var(--black)",
        "themes-typography-heading-large-xsmall-fontweight": "var(--black)",
        "themes-typography-heading-large-xxsmall-fontweight": "var(--black)",
        "themes-typography-heading-medium-xxlarge-fontweight": "var(--black)",
        "themes-typography-heading-medium-xlarge-fontweight": "var(--black)",
        "themes-typography-heading-medium-large-fontweight": "var(--black)",
        "themes-typography-heading-medium-medium-fontweight": "var(--black)",
        "themes-typography-heading-medium-small-fontweight": "var(--black)",
        "themes-typography-heading-medium-xsmall-fontweight": "var(--black)",
        "themes-typography-heading-medium-xxsmall-fontweight": "var(--black)",
        "themes-typography-heading-small-xxlarge-fontweight": "var(--black)",
        "themes-typography-heading-small-xlarge-fontweight": "var(--black)",
        "themes-typography-heading-small-large-fontweight": "var(--black)",
        "themes-typography-heading-small-medium-fontweight": "var(--black)",
        "themes-typography-heading-small-small-fontweight": "var(--black)",
        "themes-typography-heading-small-xsmall-fontweight": "var(--black)",
        "themes-typography-heading-small-xxsmall-fontweight": "var(--black)",
        "themes-typography-rte-fontfamily": "var(--avenir)",
        "themes-typography-rte-fontweight": "var(--black)"
      },
      "borderRadius": {
        "components-accordion-spacing-large-item-item-radius": "0rem",
        "components-article-card-spacing-large-image-radius-tl": "0rem",
        "components-article-card-spacing-large-image-radius-tr": "0rem",
        "components-article-card-spacing-large-image-radius-bl": "0rem",
        "components-article-card-spacing-large-image-radius-br": "0rem",
        "components-article-card-spacing-large-list-image-radius-br": "0rem",
        "components-article-card-spacing-small-image-radius-br": "0rem",
        "components-article-card-spacing-small-list-image-radius-br": "0rem",
        "components-article-hero-spacing-large-content-radius": "0rem",
        "components-article-hero-spacing-small-content-radius": "0rem",
        "components-button-radius-large": "0rem",
        "components-button-group-spacing-border-radius": "0rem",
        "components-category-card-radius-border-width": "0.0625rem",
        "components-category-card-radius-border-radius": "7.5rem",
        "components-category-card-radius-border-radius-condensed": "0.5rem",
        "components-category-card-radius-media-radius": "0rem",
        "components-content-list-icon-radius": "7.5rem",
        "components-fab-spacing-border-radius": "0rem",
        "components-header-spacing-large-search-border-radius-top": "0rem",
        "components-header-spacing-large-search-border-radius-bottom": "0rem",
        "components-header-spacing-large-language-selector-radius": "0.25rem",
        "components-header-spacing-large-dropdown-radius": "0rem",
        "components-hero-homepage-spacing-large-container-radius": "0rem",
        "components-hero-internal-spacing-large-icon-radius": "7.5rem",
        "components-hero-internal-spacing-small-icon-radius": "7.5rem",
        "components-lightbox-spacing-large-overlay-radius": "0rem",
        "components-pagination-scrollbar-radius": "62.5rem",
        "components-product-card-spacing-image-radius-tl": "0rem",
        "components-product-card-spacing-image-radius-tr": "0rem",
        "components-product-card-spacing-image-radius-bl": "0rem",
        "components-product-card-spacing-image-radius-br": "0rem",
        "components-product-information-spacing-large-content-radius": "0rem",
        "components-product-information-spacing-small-content-radius": "0rem",
        "components-promo-spacing-large-content-radius": "0rem",
        "components-promo-spacing-large-content-radius-tl": "0rem",
        "components-promo-spacing-large-content-radius-tr": "0rem",
        "components-promo-spacing-large-content-radius-bl": "0rem",
        "components-promo-spacing-large-content-radius-br": "0rem",
        "components-promo-spacing-large-image-radius-tl": "0rem",
        "components-promo-spacing-large-image-radius-tr": "0rem",
        "components-promo-spacing-large-image-radius-bl": "0rem",
        "components-promo-spacing-large-image-radius-br": "0rem",
        "components-promo-spacing-small-content-radius": "0rem",
        "components-promo-spacing-small-image-radius-tl": "0rem",
        "components-promo-spacing-small-image-radius-tr": "0rem",
        "components-promo-spacing-small-image-radius-bl": "0rem",
        "components-promo-spacing-small-image-radius-br": "0rem",
        "components-promo-alternate-spacing-large-content-radius": "0rem",
        "components-promo-alternate-spacing-large-image-radius-tl": "0rem",
        "components-promo-alternate-spacing-large-image-radius-tr": "0rem",
        "components-promo-alternate-spacing-large-image-radius-bl": "0rem",
        "components-promo-alternate-spacing-large-image-radius-br": "0rem",
        "components-promo-alternate-spacing-small-content-radius": "0rem",
        "components-promo-alternate-spacing-small-image-radius-tl": "0rem",
        "components-promo-alternate-spacing-small-image-radius-tr": "0rem",
        "components-promo-alternate-spacing-small-image-radius-bl": "0rem",
        "components-promo-alternate-spacing-small-image-radius-br": "0rem",
        "components-share-list-spacing-radius": "0.5rem",
        "components-share-list-spacing-link-radius": "0.5rem",
        "components-site-search-filter-radius": "0.5rem",
        "components-site-search-facet-radius": "0rem",
        "components-site-search-refine-radius": "0rem",
        "components-tag-spacing-radius": "0rem",
        "components-tip-spacing-radius": "0rem",
        "themes-spacing-radius-pagination-bar": "62.5rem",
        "themes-spacing-radius-large-button": "0rem",
        "themes-spacing-radius-large-button-icon": "0rem",
        "themes-spacing-radius-large-image": "0rem",
        "themes-spacing-radius-large-image-hero": "0rem",
        "themes-spacing-radius-large-image-card": "0rem",
        "themes-spacing-radius-large-card": "0rem",
        "themes-spacing-radius-large-icon": "7.5rem",
        "themes-spacing-radius-large-label": "0rem",
        "themes-spacing-radius-large-tag": "0rem",
        "themes-spacing-radius-large-container-tip": "0rem",
        "themes-spacing-radius-large-badge": "0rem",
        "themes-spacing-radius-large-item": "0.5rem",
        "themes-spacing-radius-large-form-field-input": "0rem",
        "themes-spacing-radius-large-form-area-input": "0.25rem",
        "themes-spacing-radius-large-general": "0rem",
        "themes-spacing-radius-small-button": "0rem",
        "themes-spacing-radius-small-button-icon": "0rem",
        "themes-spacing-radius-small-image": "0rem",
        "themes-spacing-radius-small-image-card": "0rem",
        "themes-spacing-radius-small-card": "0rem",
        "themes-spacing-radius-small-icon": "7.5rem",
        "themes-spacing-radius-small-label": "0rem",
        "themes-spacing-radius-small-tag": "0rem",
        "themes-spacing-radius-small-badge": "0rem",
        "themes-spacing-radius-small-item": "0rem",
        "themes-spacing-radius-small-form-field-input": "0rem",
        "themes-spacing-radius-small-general": "0rem"
      },
      "borderWidth": {
        "3": "3px",
        "components-article-card-spacing-large-border-width": "0.0625rem",
        "components-generic-card-color-border-width": "0.0625rem",
        "components-header-spacing-large-search-border-width-top": "0.125rem",
        "components-header-spacing-large-search-border-width-right": "0.125rem",
        "components-header-spacing-large-search-border-width-bottom": "0.125rem",
        "components-header-spacing-large-search-border-width-left": "0.125rem",
        "components-product-card-spacing-border-width": "0.0625rem"
      },
      "maxHeight": {},
      "maxWidth": {
        "components-article-hero-spacing-large-header-max-width": "66.375rem",
        "components-content-list-spacing-large-item-image-max-width": "33.75rem",
        "components-content-list-spacing-small-item-image-max-width": "33.75rem"
      },
      "minHeight": {
        "components-dropdown-item-min-height": "3rem",
        "components-icon-min-height": "3rem",
        "components-tab-item-min-height": "3rem",
        "components-text-field-input-min-height": "3rem",
        "components-text-field-icon-min-height": "3rem"
      },
      "minWidth": {
        "components-content-list-spacing-small-item-image-min-width": "18.75rem",
        "components-icon-min-width": "3rem",
        "components-text-field-icon-min-width": "3rem"
      },
      "opacity": {
        "components-accessibility-bar-color-bg": 1,
        "components-accessibility-bar-color-text": 1,
        "components-accordion-color-bg": 1,
        "components-accordion-color-title": 1,
        "components-accordion-color-body": 1,
        "components-accordion-color-section-section-bg": 1,
        "components-accordion-color-section-section-title": 1,
        "components-accordion-color-section-section-body": 1,
        "components-accordion-color-item-item-bg": 1,
        "components-accordion-color-item-divider": 1,
        "components-accordion-color-item-icon-icon-default": 1,
        "components-accordion-color-item-icon-icon-hover": 1,
        "components-accordion-color-item-icon-icon-pressed": 1,
        "components-accordion-color-item-icon-icon-active": 1,
        "components-accordion-color-item-title-item-default": 1,
        "components-accordion-color-item-title-item-hover": 1,
        "components-accordion-color-item-title-item-pressed": 1,
        "components-accordion-color-item-title-item-active": 1,
        "components-accordion-color-content-bg": 1,
        "components-accordion-color-content-title": 1,
        "components-accordion-color-content-body": 1,
        "components-article-card-color-bg": 1,
        "components-article-card-color-border": 1,
        "components-article-card-color-content-bg": 1,
        "components-article-card-color-title": 1,
        "components-article-card-color-subtitle": 1,
        "components-article-card-color-copy": 1,
        "components-article-card-color-description": 1,
        "components-article-card-listing-color-default-bg": 1,
        "components-article-card-listing-color-default-title": 1,
        "components-article-card-listing-color-default-copy": 1,
        "components-article-hero-color-content-bg": 1,
        "components-article-hero-color-bg": 1,
        "components-article-hero-color-headline": 1,
        "components-article-hero-color-body": 1,
        "components-article-hero-color-detail-text": 1,
        "components-article-hero-color-divider": 1,
        "components-article-hero-color-icon": 1,
        "components-article-hero-color-play-icon": 1,
        "components-article-hero-color-selected-stroke": 1,
        "components-banner-color-bg-default": 1,
        "components-banner-color-icon-default": 1,
        "components-banner-color-title-default": 1,
        "components-banner-color-body-default": 1,
        "components-banner-color-icon-close-default": 1,
        "components-banner-color-bg-alert": 1,
        "components-banner-color-icon-alert": 1,
        "components-banner-color-title-alert": 1,
        "components-banner-color-body-alert": 1,
        "components-banner-color-icon-close-alert": 1,
        "components-breadcrumb-color-bg": 1,
        "components-breadcrumb-color-breadcrumb-link-default": 1,
        "components-breadcrumb-color-breadcrumb-link-hover": 1,
        "components-breadcrumb-color-breadcrumb-link-active": 1,
        "components-breadcrumb-color-icon-divider": 1,
        "components-bug-finder-prompt": 1,
        "components-button-color-filled-brand-default-bg": 1,
        "components-button-color-filled-brand-default-text": 1,
        "components-button-color-filled-brand-default-stroke": 1,
        "components-button-color-filled-brand-default-icon": 1,
        "components-button-color-filled-brand-hover-bg": 1,
        "components-button-color-filled-brand-hover-text": 1,
        "components-button-color-filled-brand-hover-stroke": 1,
        "components-button-color-filled-brand-hover-icon": 1,
        "components-button-color-filled-brand-pressed-bg": 1,
        "components-button-color-filled-brand-pressed-text": 1,
        "components-button-color-filled-brand-pressed-stroke": 1,
        "components-button-color-filled-brand-pressed-icon": 1,
        "components-button-color-filled-brand-disabled-bg": null,
        "components-button-color-filled-brand-disabled-text": 0.02,
        "components-button-color-filled-brand-disabled-stroke": null,
        "components-button-color-filled-brand-disabled-icon": 0.02,
        "components-button-color-filled-brand-focus-bg": 1,
        "components-button-color-filled-brand-focus-text": 1,
        "components-button-color-filled-brand-focus-stroke": 1,
        "components-button-color-filled-brand-focus-icon": 1,
        "components-button-color-filled-brand-focus-outline": 1,
        "components-button-color-filled-tonal-default-bg": 1,
        "components-button-color-filled-tonal-default-text": 1,
        "components-button-color-filled-tonal-default-stroke": 1,
        "components-button-color-filled-tonal-default-icon": 1,
        "components-button-color-filled-tonal-hover-bg": 1,
        "components-button-color-filled-tonal-hover-text": 1,
        "components-button-color-filled-tonal-hover-stroke": 1,
        "components-button-color-filled-tonal-hover-icon": 1,
        "components-button-color-filled-tonal-pressed-bg": 1,
        "components-button-color-filled-tonal-pressed-text": 1,
        "components-button-color-filled-tonal-pressed-stroke": 1,
        "components-button-color-filled-tonal-pressed-icon": 1,
        "components-button-color-filled-tonal-disabled-bg": 0.02,
        "components-button-color-filled-tonal-disabled-text": null,
        "components-button-color-filled-tonal-disabled-stroke": 0.02,
        "components-button-color-filled-tonal-disabled-icon": null,
        "components-button-color-filled-tonal-focus-bg": 1,
        "components-button-color-filled-tonal-focus-text": 1,
        "components-button-color-filled-tonal-focus-stroke": 1,
        "components-button-color-filled-tonal-focus-icon": 1,
        "components-button-color-filled-tonal-focus-outline": 1,
        "components-button-color-filled-white-default-bg": 1,
        "components-button-color-filled-white-default-text": 1,
        "components-button-color-filled-white-default-stroke": 1,
        "components-button-color-filled-white-default-icon": 1,
        "components-button-color-filled-white-hover-bg": 1,
        "components-button-color-filled-white-hover-text": 1,
        "components-button-color-filled-white-hover-stroke": 1,
        "components-button-color-filled-white-hover-icon": 1,
        "components-button-color-filled-white-pressed-bg": 1,
        "components-button-color-filled-white-pressed-text": 1,
        "components-button-color-filled-white-pressed-stroke": 1,
        "components-button-color-filled-white-pressed-icon": 1,
        "components-button-color-filled-white-disabled-bg": 0.02,
        "components-button-color-filled-white-disabled-text": null,
        "components-button-color-filled-white-disabled-stroke": 0.02,
        "components-button-color-filled-white-disabled-icon": null,
        "components-button-color-filled-white-focus-bg": 1,
        "components-button-color-filled-white-focus-text": 1,
        "components-button-color-filled-white-focus-stroke": 1,
        "components-button-color-filled-white-focus-icon": 1,
        "components-button-color-filled-white-focus-outline": 1,
        "components-button-color-outline-brand-default-bg": 0.02,
        "components-button-color-outline-brand-default-text": 1,
        "components-button-color-outline-brand-default-stroke": 1,
        "components-button-color-outline-brand-default-icon": 1,
        "components-button-color-outline-brand-hover-bg": 1,
        "components-button-color-outline-brand-hover-text": 1,
        "components-button-color-outline-brand-hover-stroke": 1,
        "components-button-color-outline-brand-hover-icon": 1,
        "components-button-color-outline-brand-pressed-bg": 1,
        "components-button-color-outline-brand-pressed-text": 1,
        "components-button-color-outline-brand-pressed-stroke": 1,
        "components-button-color-outline-brand-pressed-icon": 1,
        "components-button-color-outline-brand-disabled-bg": 0.02,
        "components-button-color-outline-brand-disabled-text": null,
        "components-button-color-outline-brand-disabled-stroke": null,
        "components-button-color-outline-brand-disabled-icon": null,
        "components-button-color-outline-brand-focus-bg": 1,
        "components-button-color-outline-brand-focus-text": 1,
        "components-button-color-outline-brand-focus-stroke": 1,
        "components-button-color-outline-brand-focus-icon": 1,
        "components-button-color-outline-brand-focus-outline": 1,
        "components-button-color-outline-tonal-default-bg": null,
        "components-button-color-outline-tonal-default-text": 1,
        "components-button-color-outline-tonal-default-stroke": 1,
        "components-button-color-outline-tonal-default-icon": 1,
        "components-button-color-outline-tonal-hover-bg": 1,
        "components-button-color-outline-tonal-hover-text": 1,
        "components-button-color-outline-tonal-hover-stroke": 1,
        "components-button-color-outline-tonal-hover-icon": 1,
        "components-button-color-outline-tonal-pressed-bg": 1,
        "components-button-color-outline-tonal-pressed-text": 1,
        "components-button-color-outline-tonal-pressed-stroke": 1,
        "components-button-color-outline-tonal-pressed-icon": 1,
        "components-button-color-outline-tonal-disabled-bg": 0.02,
        "components-button-color-outline-tonal-disabled-text": 0.02,
        "components-button-color-outline-tonal-disabled-stroke": 0.02,
        "components-button-color-outline-tonal-disabled-icon": 0.02,
        "components-button-color-outline-tonal-focus-bg": null,
        "components-button-color-outline-tonal-focus-text": 1,
        "components-button-color-outline-tonal-focus-stroke": 1,
        "components-button-color-outline-tonal-focus-icon": 1,
        "components-button-color-outline-tonal-focus-outline": 1,
        "components-button-color-outline-white-default-bg": 0.02,
        "components-button-color-outline-white-default-text": 1,
        "components-button-color-outline-white-default-stroke": 1,
        "components-button-color-outline-white-default-icon": 1,
        "components-button-color-outline-white-hover-bg": 1,
        "components-button-color-outline-white-hover-text": 1,
        "components-button-color-outline-white-hover-stroke": 1,
        "components-button-color-outline-white-hover-icon": 1,
        "components-button-color-outline-white-pressed-bg": 1,
        "components-button-color-outline-white-pressed-text": 1,
        "components-button-color-outline-white-pressed-stroke": 1,
        "components-button-color-outline-white-pressed-icon": 1,
        "components-button-color-outline-white-disabled-bg": 0.02,
        "components-button-color-outline-white-disabled-text": 0.02,
        "components-button-color-outline-white-disabled-stroke": 0.02,
        "components-button-color-outline-white-disabled-icon": 0.02,
        "components-button-color-outline-white-focus-bg": 0.02,
        "components-button-color-outline-white-focus-text": 1,
        "components-button-color-outline-white-focus-stroke": 1,
        "components-button-color-outline-white-focus-icon": 1,
        "components-button-color-outline-white-focus-outline": 1,
        "components-button-color-text-brand-default-bg": 0.02,
        "components-button-color-text-brand-default-text": 1,
        "components-button-color-text-brand-default-stroke": 1,
        "components-button-color-text-brand-default-icon": 1,
        "components-button-color-text-brand-hover-bg": 0.02,
        "components-button-color-text-brand-hover-bg-icon-only": 1,
        "components-button-color-text-brand-hover-text": 1,
        "components-button-color-text-brand-hover-stroke": 1,
        "components-button-color-text-brand-hover-icon": 1,
        "components-button-color-text-brand-pressed-bg": 0.02,
        "components-button-color-text-brand-pressed-bg-icon-only": 1,
        "components-button-color-text-brand-pressed-text": 1,
        "components-button-color-text-brand-pressed-stroke": 1,
        "components-button-color-text-brand-pressed-icon": 1,
        "components-button-color-text-brand-disabled-bg": 0.02,
        "components-button-color-text-brand-disabled-text": null,
        "components-button-color-text-brand-disabled-stroke": null,
        "components-button-color-text-brand-disabled-icon": null,
        "components-button-color-text-brand-focus-bg": 1,
        "components-button-color-text-brand-focus-text": 1,
        "components-button-color-text-brand-focus-stroke": 1,
        "components-button-color-text-brand-focus-icon": 1,
        "components-button-color-text-brand-focus-outline": 1,
        "components-button-color-text-tonal-default-bg": 0.02,
        "components-button-color-text-tonal-default-text": 1,
        "components-button-color-text-tonal-default-stroke": 1,
        "components-button-color-text-tonal-default-icon": 1,
        "components-button-color-text-tonal-hover-bg": 0.02,
        "components-button-color-text-tonal-hover-bg-icon-only": 1,
        "components-button-color-text-tonal-hover-text": 1,
        "components-button-color-text-tonal-hover-stroke": 1,
        "components-button-color-text-tonal-hover-icon": 1,
        "components-button-color-text-tonal-pressed-bg": 0.02,
        "components-button-color-text-tonal-pressed-bg-icon-only": 1,
        "components-button-color-text-tonal-pressed-text": 1,
        "components-button-color-text-tonal-pressed-stroke": 1,
        "components-button-color-text-tonal-pressed-icon": 1,
        "components-button-color-text-tonal-disabled-bg": 0.02,
        "components-button-color-text-tonal-disabled-text": 0.02,
        "components-button-color-text-tonal-disabled-stroke": 0.02,
        "components-button-color-text-tonal-disabled-icon": 0.02,
        "components-button-color-text-tonal-focus-bg": 1,
        "components-button-color-text-tonal-focus-text": 1,
        "components-button-color-text-tonal-focus-stroke": 1,
        "components-button-color-text-tonal-focus-icon": 1,
        "components-button-color-text-tonal-focus-outline": 1,
        "components-button-color-text-white-default-bg": 0.02,
        "components-button-color-text-white-default-text": 1,
        "components-button-color-text-white-default-stroke": 1,
        "components-button-color-text-white-default-icon": 1,
        "components-button-color-text-white-hover-bg": 0.02,
        "components-button-color-text-white-hover-bg-icon-only": 1,
        "components-button-color-text-white-hover-text": 1,
        "components-button-color-text-white-hover-stroke": 1,
        "components-button-color-text-white-hover-icon": 1,
        "components-button-color-text-white-pressed-bg": 0.02,
        "components-button-color-text-white-pressed-bg-icon-only": 1,
        "components-button-color-text-white-pressed-text": 1,
        "components-button-color-text-white-pressed-stroke": 1,
        "components-button-color-text-white-pressed-icon": 1,
        "components-button-color-text-white-disabled-bg": 0.02,
        "components-button-color-text-white-disabled-text": 0.01,
        "components-button-color-text-white-disabled-stroke": 0.01,
        "components-button-color-text-white-disabled-icon": 0.01,
        "components-button-color-text-white-focus-bg": 1,
        "components-button-color-text-white-focus-text": 1,
        "components-button-color-text-white-focus-stroke": 1,
        "components-button-color-text-white-focus-icon": 1,
        "components-button-color-text-white-focus-outline": 1,
        "components-button-group-color-border": 1,
        "components-button-group-color-button-bg-default": 0.02,
        "components-button-group-color-button-fg-default": 1,
        "components-button-group-color-button-bg-hover": null,
        "components-button-group-color-button-fg-hover": 1,
        "components-button-group-color-button-bg-active": 1,
        "components-button-group-color-button-fg-active": 1,
        "components-button-group-color-overlay-left-gradient-fill-1": 1,
        "components-button-group-color-overlay-left-gradient-fill-2": 0.01,
        "components-button-group-color-stroke-default-gradient-fill-1": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-3": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-4": 0.02,
        "components-button-group-color-stroke-hover-gradient-fill-1": null,
        "components-button-group-color-stroke-hover-gradient-fill-2": null,
        "components-button-group-color-stroke-hover-gradient-fill-3": null,
        "components-button-group-color-stroke-hover-gradient-fill-4": null,
        "components-button-group-color-stroke-active-gradient-fill-1": 1,
        "components-button-group-color-stroke-active-gradient-fill-2": 1,
        "components-button-group-color-stroke-active-gradient-fill-3": 1,
        "components-button-group-color-stroke-active-gradient-fill-4": 1,
        "components-category-card-color-bg": 1,
        "components-category-card-color-content-bg": 1,
        "components-category-card-color-category-bg": 1,
        "components-category-card-color-category-bg-2": 1,
        "components-category-card-color-category-bg-3": 1,
        "components-category-card-color-category-bg-4": 1,
        "components-category-card-color-category-bg-5": 1,
        "components-category-card-color-category-bg-6": 1,
        "components-category-card-color-title": 1,
        "components-category-card-color-copy": 1,
        "components-category-card-color-stroke": 1,
        "components-category-card-color-stroke-hover": 1,
        "components-category-card-listing-color-bg": 1,
        "components-category-card-listing-color-title": 1,
        "components-category-card-listing-color-copy": 1,
        "components-card-search-result-color-bg": 1,
        "components-card-search-result-color-label": 1,
        "components-card-search-result-color-title": 1,
        "components-card-search-result-color-body": 1,
        "components-card-search-result-color-divider-line": 1,
        "components-contact-us-promo-color-light-bg": 1,
        "components-contact-us-promo-color-light-title": 1,
        "components-contact-us-promo-color-light-body": 1,
        "components-contact-us-promo-color-default-bg": 1,
        "components-contact-us-promo-color-default-title": 1,
        "components-contact-us-promo-color-default-body": 1,
        "components-contact-us-promo-color-brand-bg": 1,
        "components-contact-us-promo-color-brand-title": 1,
        "components-contact-us-promo-color-brand-body": 1,
        "components-content-list-color-bg": 1,
        "components-content-list-color-title": 1,
        "components-content-list-color-body": 1,
        "components-content-list-color-icon": 1,
        "components-cookies-colors-bg": 1,
        "components-cookies-colors-title": 1,
        "components-cookies-colors-body": 1,
        "components-cookies-colors-icon": 1,
        "components-dropdown-bg": 1,
        "components-dropdown-border-default": 1,
        "components-dropdown-border-hover": 1,
        "components-dropdown-border-focused": 1,
        "components-dropdown-border-destructive": 1,
        "components-dropdown-label-text-default": 1,
        "components-dropdown-item-text-default": 1,
        "components-dropdown-item-bg-default": 1,
        "components-dropdown-item-bg-hover": 1,
        "components-dropdown-input-field-default": 1,
        "components-dropdown-input-field-focused": 1,
        "components-dropdown-input-label-focused": 1,
        "components-dropdown-input-field-destructive": 1,
        "components-external-link-card-bg": 1,
        "components-external-link-card-card-bg": 1,
        "components-external-link-card-card-title": 1,
        "components-external-link-card-card-subtitle": 1,
        "components-external-link-card-card-eyebrow": 1,
        "components-fab-color-border-default": 1,
        "components-footer-brand-color-bg": 1,
        "components-footer-brand-color-logo": 1,
        "components-footer-brand-color-title-text": 1,
        "components-footer-brand-color-description-text": 1,
        "components-footer-brand-color-footer-menu-expanded": 1,
        "components-footer-brand-color-link-default": 1,
        "components-footer-brand-color-link-hover": 1,
        "components-footer-brand-color-link-pressed": 1,
        "components-footer-brand-color-category-link-default": 1,
        "components-footer-brand-color-category-link-hover": 1,
        "components-footer-brand-color-category-link-pressed": 1,
        "components-footer-brand-color-subcategory-link-default": 1,
        "components-footer-brand-color-subcategory-link-hover": 1,
        "components-footer-brand-color-subcategory-link-pressed": 1,
        "components-footer-brand-color-icon-icon-default": 1,
        "components-footer-brand-color-icon-icon-pressed": 1,
        "components-footer-brand-color-social-icon-default": 1,
        "components-footer-brand-color-social-icon-hover": 1,
        "components-footer-brand-color-social-icon-pressed": 1,
        "components-footer-brand-spacing-category-link-hover": 1,
        "components-footer-global-color-bg": 1,
        "components-footer-global-color-title-text": 1,
        "components-footer-global-color-disclaimer-text": 1,
        "components-footer-global-color-link-default": 1,
        "components-footer-global-color-link-hover": 1,
        "components-footer-global-color-link-pressed": 1,
        "components-generic-card-color-bg": 1,
        "components-generic-card-color-content-bg": 1,
        "components-generic-card-color-title": 1,
        "components-generic-card-color-subtitle": 1,
        "components-generic-card-color-copy": 1,
        "components-global-search-color-omnibox-list-bg": 1,
        "components-global-search-color-omnibox-divider": 1,
        "components-global-search-color-omnibox-item-bg-default": 1,
        "components-global-search-color-omnibox-item-bg-hover": 1,
        "components-global-search-color-omnibox-item-text-default": 1,
        "components-global-search-color-omnibox-item-text-hover": 1,
        "components-global-search-color-omnibox-label-text-default": 1,
        "components-global-search-color-omnibox-category-text-default": 1,
        "components-generic-card-listing-color-bg": 1,
        "components-generic-card-listing-color-title": 1,
        "components-generic-card-listing-color-copy": 1,
        "components-header-color-bg": 1,
        "components-header-color-bottom-stroke": 1,
        "components-header-color-search-search-border-default": 1,
        "components-header-color-search-search-text": 1,
        "components-header-color-search-search-icon": 1,
        "components-header-color-search-search-bar": 1,
        "components-header-color-mobile-mobile-expanded-bg": 1,
        "components-header-color-mobile-pressed-bg": 1,
        "components-header-color-language-selector-language": 1,
        "components-header-color-language-selector-country": 1,
        "components-header-color-language-selector-country-hover-bg": 1,
        "components-header-color-language-selector-megamenu-language": 1,
        "components-header-color-language-selector-megamenu-country": 1,
        "components-header-color-language-selector-megamenu-country-hover": 1,
        "components-header-color-language-selector-bg": 1,
        "components-header-color-language-selector-stroke": 1,
        "components-header-color-mega-menu-bg": 1,
        "components-header-color-mega-menu-featured-bg": 1,
        "components-header-color-mega-menu-featured-title": 1,
        "components-header-color-mega-menu-category-title": 1,
        "components-header-color-mega-menu-bg-accent": 1,
        "components-header-color-icon-icon-default": 1,
        "components-header-color-icon-icon-hover": 1,
        "components-header-color-icon-icon-pressed": 1,
        "components-header-color-links-nav-main-link-default": 1,
        "components-header-color-links-nav-main-link-hover": 1,
        "components-header-color-links-nav-main-link-pressed": 1,
        "components-header-color-links-nav-main-link-active": 1,
        "components-header-color-links-nav-main-active-bar": 1,
        "components-header-color-links-nav-main-bg-color": 1,
        "components-header-color-links-nav-category-link-category-default": 1,
        "components-header-color-links-nav-category-link-category-hover": 1,
        "components-header-color-links-nav-category-link-category-pressed": 1,
        "components-header-color-links-nav-category-link-category-active": 1,
        "components-header-color-links-nav-menu-link-link-default": 1,
        "components-header-color-links-nav-menu-link-link-hover": 1,
        "components-header-color-links-nav-menu-link-link-pressed": 1,
        "components-header-color-links-nav-menu-link-link-active": 1,
        "components-header-color-links-nav-menu-link-bg": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-default": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-active": 1,
        "components-hero-homepage-color-bg": 1,
        "components-hero-homepage-color-container-bg": 1,
        "components-hero-homepage-color-eyebrow": 1,
        "components-hero-homepage-color-header": 1,
        "components-hero-homepage-color-sub-header": 1,
        "components-hero-homepage-color-body": 1,
        "components-hero-homepage-color-play-pause": 1,
        "components-hero-internal-color-default-title": 1,
        "components-hero-internal-color-default-body": 1,
        "components-hero-internal-color-default-bg": 1,
        "components-hero-internal-color-accent-bg": 1,
        "components-hero-internal-color-with-image-bg": 1,
        "components-hero-internal-color-default-bg-2": 1,
        "components-hero-internal-color-with-image-title": 1,
        "components-hero-internal-color-with-image-body": 1,
        "components-heading-page-title-color-default-bg": 1,
        "components-heading-page-title-color-default-title": 1,
        "components-heading-page-title-color-default-body": 1,
        "components-heading-page-title-color-light-bg": 1,
        "components-heading-page-title-color-light-title": 1,
        "components-heading-page-title-color-light-body": 1,
        "components-heading-page-title-color-color-bg": 1,
        "components-heading-page-title-color-color-title": 1,
        "components-heading-page-title-color-color-body": 1,
        "components-hyperlink-color-dark-default": 1,
        "components-hyperlink-color-dark-hover": 1,
        "components-hyperlink-color-dark-visited": 1,
        "components-hyperlink-color-light-default": 1,
        "components-hyperlink-color-light-hover": 1,
        "components-hyperlink-color-light-visited": 1,
        "components-icon-default": 1,
        "components-icon-focused": 1,
        "components-icon-card-color-bg": 1,
        "components-icon-card-color-icon-bg": 1,
        "components-icon-card-color-icon": 1,
        "components-icon-card-color-title": 1,
        "components-icon-card-color-subtitle": 1,
        "components-icon-card-color-description": 1,
        "components-icon-card-listing-color-bg": 1,
        "components-icon-card-listing-color-inner-bg": 1,
        "components-icon-card-listing-color-brand-bg": 1,
        "components-icon-card-listing-color-title": 1,
        "components-icon-card-listing-color-copy": 1,
        "components-icon-card-listing-color-inner-legal-text": 1,
        "components-jumplink-color-bg-default": 1,
        "components-jumplink-color-link-text-default": 1,
        "components-jumplink-color-link-text-hover": 1,
        "components-jumplink-color-link-border-hover": 1,
        "components-jumplink-color-link-text-active": 1,
        "components-jumplink-color-link-border-active": 1,
        "components-jumplink-color-title-text": 1,
        "components-jumplink-color-description-text": 1,
        "components-layout-bg-default": 1,
        "components-lightbox-color-overlay-bg": 0.01,
        "components-lightbox-color-title": 1,
        "components-lightbox-color-body": 1,
        "components-label-color-on-color-label-label-default": 1,
        "components-label-color-on-color-label-label-hover": 1,
        "components-label-color-on-color-label-label-pressed": 1,
        "components-label-color-on-color-background-bg-default": 0.02,
        "components-label-color-on-color-background-bg-hover": 1,
        "components-label-color-on-color-background-bg-pressed": 1,
        "components-label-color-on-color-stroke-hover": 1,
        "components-label-color-on-color-stroke-pressed": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-4": 1,
        "components-label-color-on-white-label-label-default": 1,
        "components-label-color-on-white-label-label-hover": 1,
        "components-label-color-on-white-label-label-pressed": 1,
        "components-label-color-on-white-background-bg-default": 0.02,
        "components-label-color-on-white-background-bg-hover": 1,
        "components-label-color-on-white-background-bg-pressed": 1,
        "components-label-color-on-white-stroke-hover": 1,
        "components-label-color-on-white-stroke-pressed": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-4": 1,
        "components-legal-disclaimer-color-default-bg": 1,
        "components-legal-disclaimer-color-default-title": 1,
        "components-legal-disclaimer-color-default-body": 1,
        "components-legal-disclaimer-color-light-bg": 1,
        "components-legal-disclaimer-color-light-title": 1,
        "components-legal-disclaimer-color-light-body": 1,
        "components-legal-disclaimer-color-brand-bg": 1,
        "components-legal-disclaimer-color-brand-title": 1,
        "components-legal-disclaimer-color-brand-body": 1,
        "components-media-video-thumbnail-overlay-bg": null,
        "components-media-caption-color-bg": 1,
        "components-media-caption-color-text": 1,
        "components-media-gallery-color-bg": 1,
        "components-media-gallery-color-title": 1,
        "components-media-gallery-color-body": 1,
        "components-pagination-on-white-accent-default": 1,
        "components-pagination-on-white-accent-scroll": 1,
        "components-pagination-on-white-disabled": 1,
        "components-pagination-on-white-bg": 1,
        "components-pagination-on-white-stroke": 1,
        "components-pagination-on-white-bg-active": 1,
        "components-pagination-on-white-bg-hover": 1,
        "components-pagination-on-white-text-default": 1,
        "components-pagination-on-white-text-hover": 1,
        "components-pagination-on-white-text-active": 1,
        "components-pagination-on-color-accent-default": 1,
        "components-pagination-on-color-accent-scroll": 1,
        "components-pagination-on-color-disabled": 1,
        "components-pagination-on-color-bg": 1,
        "components-pagination-on-color-stroke": 0.02,
        "components-pagination-on-color-bg-active": 1,
        "components-pagination-on-color-bg-hover": 1,
        "components-pagination-on-color-text-default": 1,
        "components-pagination-on-color-text-hover": 1,
        "components-pagination-on-color-text-active": 1,
        "components-pdf-link-card-color-bg": 1,
        "components-pdf-link-card-color-card-bg": 1,
        "components-pdf-link-card-color-card-title": 1,
        "components-pdf-link-card-color-card-subtitle": 1,
        "components-pdf-link-card-color-card-body": 1,
        "components-product-card-color-bg": 1,
        "components-product-card-color-content-bg": 1,
        "components-product-card-color-border": 1,
        "components-product-card-color-title": 1,
        "components-product-card-color-description": 1,
        "components-product-card-color-star-rating": 1,
        "components-product-card-color-badge-bg": 1,
        "components-product-card-color-badge-text": 1,
        "components-product-card-listing-color-bg": 1,
        "components-product-card-listing-color-title": 1,
        "components-product-card-listing-color-copy": 1,
        "components-product-card-listing-color-legal-text": 1,
        "components-product-information-color-bg": 1,
        "components-product-information-color-content-bg": 1,
        "components-product-information-color-headline": 1,
        "components-product-information-color-body": 1,
        "components-product-information-color-detail-text": 1,
        "components-product-information-color-divider": 1,
        "components-product-information-color-icon": 1,
        "components-product-information-color-play-icon": 1,
        "components-product-information-color-selected-stroke": 1,
        "components-product-results-list-with-search-color-bg": 1,
        "components-promo-color-default-bg": 1,
        "components-promo-color-default-image-bg": 1,
        "components-promo-color-default-content-bg": 1,
        "components-promo-color-default-title": 1,
        "components-promo-color-default-body": 1,
        "components-promo-color-default-category": 1,
        "components-promo-color-light-bg": 1,
        "components-promo-color-light-image-bg": 1,
        "components-promo-color-light-content-bg": 1,
        "components-promo-color-light-title": 1,
        "components-promo-color-light-body": 1,
        "components-promo-color-light-category": 1,
        "components-promo-color-brand-bg": 1,
        "components-promo-color-brand-image-bg": 1,
        "components-promo-color-brand-content-bg": 1,
        "components-promo-color-brand-title": 1,
        "components-promo-color-brand-body": 1,
        "components-promo-color-brand-category": 1,
        "components-pull-quote-color-bg": 1,
        "components-pull-quote-color-stroke": 1,
        "components-pull-quote-color-quote-bg": 1,
        "components-pull-quote-color-quote-title": 1,
        "components-pull-quote-color-quote-by-text": 1,
        "components-pull-quote-color-quote-detail": 1,
        "components-pull-quote-color-quote-highlight": 1,
        "components-promo-alternate-color-default-bg": 1,
        "components-promo-alternate-color-default-image-bg": 1,
        "components-promo-alternate-color-default-content-bg": 1,
        "components-promo-alternate-color-default-title": 1,
        "components-promo-alternate-color-default-body": 1,
        "components-promo-alternate-color-default-category": 1,
        "components-promo-alternate-color-light-bg": 1,
        "components-promo-alternate-color-light-image-bg": 1,
        "components-promo-alternate-color-light-content-bg": 1,
        "components-promo-alternate-color-light-title": 1,
        "components-promo-alternate-color-light-body": 1,
        "components-promo-alternate-color-light-category": 1,
        "components-promo-alternate-color-brand-bg": 1,
        "components-promo-alternate-color-brand-image-bg": 1,
        "components-promo-alternate-color-brand-content-bg": 1,
        "components-promo-alternate-color-brand-title": 1,
        "components-promo-alternate-color-brand-body": 1,
        "components-promo-alternate-color-brand-category": 1,
        "components-share-list-color-title": 1,
        "components-share-list-color-bg": 1,
        "components-share-list-color-link-default": 1,
        "components-share-list-color-link-hover": 1,
        "components-share-list-color-bg-link-hover": 1,
        "components-share-list-color-close-icon": 1,
        "components-share-list-color-facebook-icon": 1,
        "components-share-list-color-pinterest-icon": 1,
        "components-share-list-color-twitter-icon": 1,
        "components-share-list-color-basic-icon-default": 1,
        "components-share-list-color-icon-hover": 1,
        "components-share-list-color-border": 1,
        "components-site-search-bg": 1,
        "components-site-search-results_text": 1,
        "components-site-search-filter-filter-selected-bg": 1,
        "components-site-search-facet-group-label-bottom-line": 1,
        "components-site-search-facet-group-label-text": 1,
        "components-site-search-facet-group-label-bg": 1,
        "components-site-search-facet-bg": 1,
        "components-site-search-facet-stroke": 1,
        "components-site-search-refine-bg": 1,
        "components-site-search-refine-right-divider": 1,
        "components-site-search-refine-title": 1,
        "components-site-search-hero-search-bg": 1,
        "components-site-search-hero-search-accent-bg": 1,
        "components-site-search-hero-search-title": 1,
        "components-stats-stat-text": 1,
        "components-stats-stat-text-on-light": 1,
        "components-stats-supporting-text": 1,
        "components-stats-bg-default": 1,
        "components-stats-bg-inner": 1,
        "components-stats-bg-section-inner": 1,
        "components-stats-title": 1,
        "components-stats-copy": 1,
        "components-supporting-text-default": 1,
        "components-supporting-text-destructive": 1,
        "components-tab-bg": 1,
        "components-tab-border-default": 1,
        "components-tab-border-hover": 1,
        "components-tab-border-focused": 1,
        "components-tab-border-destructive": 1,
        "components-tab-label-text-default": 1,
        "components-tab-item-text-default": 1,
        "components-tab-item-bg-default": 1,
        "components-tab-item-bg-hover": 1,
        "components-tab-input-field-default": 1,
        "components-tab-input-field-focused": 1,
        "components-tab-input-label-focused": 1,
        "components-tab-input-field-destructive": 1,
        "components-tab-bg-2": 1,
        "components-tag-color-bg": 0.02,
        "components-tag-color-text": 1,
        "components-tag-color-stroke": 0.02,
        "components-tag-color-bg-hover": 1,
        "components-tag-color-text-hover": 1,
        "components-tag-color-stroke-hover": 1,
        "components-text-field-bg": 1,
        "components-text-field-bg-focused": 1,
        "components-text-field-border-default": 1,
        "components-text-field-border-hover": 1,
        "components-text-field-border-focused": 1,
        "components-text-field-border-destructive": 1,
        "components-text-field-input-field-default": 1,
        "components-text-field-input-field-focused": 1,
        "components-text-field-input-label-focused": 1,
        "components-text-field-input-field-destructive": 1,
        "components-text-field-icon-default": 1,
        "components-text-field-icon-destructive": 1,
        "components-text-field-supporting-text-default": 1,
        "components-text-field-supporting-text-destructive": 1,
        "components-tip-color-bg": 1,
        "components-tip-color-title": 1,
        "components-tip-color-body": 1,
        "components-tip-color-stroke": 1,
        "components-video-color-bg": 1,
        "themes-general-surface-color-light-bg": 1,
        "themes-general-surface-color-light-title": 1,
        "themes-general-surface-color-light-body": 1,
        "themes-general-surface-color-light-decor-line-break": 1,
        "themes-general-surface-color-light-rule-line": 1,
        "themes-general-surface-color-dark-bg": 1,
        "themes-general-surface-color-dark-title": 1,
        "themes-general-surface-color-dark-body": 1,
        "themes-general-surface-color-dark-decor-line-break": 1,
        "themes-general-card-color-dark-title": 1,
        "themes-general-card-color-dark-subtitle": 1,
        "themes-general-card-color-dark-body": 1,
        "themes-general-card-color-light-title": 1,
        "themes-general-card-color-light-subtitle": 1,
        "themes-general-card-color-light-body": 1,
        "themes-shadow-color": 1,
        "themes-shadow-share-list-color-fill": 0,
        "themes-shadow-header-menu-color-fill": 0,
        "themes-gradient-loader-gradient-fill-1": 1,
        "themes-gradient-loader-gradient-fill-2": null,
        "themes-color-background-white": 1,
        "themes-color-background-light": 1,
        "themes-color-background-dark": 1,
        "themes-color-background-black": 1,
        "themes-color-background-brand1": 1,
        "themes-color-background-brand2": 1,
        "themes-color-background-accent1": 1,
        "themes-color-background-accent2": 1,
        "themes-color-background-body": 1,
        "themes-color-stroke-white": 1,
        "themes-color-stroke-light": 1,
        "themes-color-stroke-dark": 1,
        "themes-color-stroke-black": 1,
        "themes-color-stroke-brand1": 1,
        "themes-color-stroke-brand2": 1,
        "themes-color-stroke-accent1": 1,
        "themes-color-stroke-accent2": 1,
        "themes-color-text-white": 1,
        "themes-color-text-light": 1,
        "themes-color-text-dark": 1,
        "themes-color-text-black": 1,
        "themes-color-text-brand1": 1,
        "themes-color-text-brand2": 1,
        "themes-color-text-accent1": 1,
        "themes-color-text-accent2": 1,
        "colors-grayscale-100": 1,
        "colors-grayscale-200": 1,
        "colors-grayscale-300": 1,
        "colors-grayscale-400": 1,
        "colors-grayscale-500": 1,
        "colors-grayscale-600": 1,
        "colors-grayscale-700": 1,
        "colors-brand-1-100": 1,
        "colors-brand-1-200": 1,
        "colors-brand-1-300": 1,
        "colors-brand-1-400": 1,
        "colors-brand-1-500": 1,
        "colors-brand-1-600": 1,
        "colors-brand-1-700": 1,
        "colors-brand-2-100": 1,
        "colors-brand-2-200": 1,
        "colors-brand-2-300": 1,
        "colors-brand-2-400": 1,
        "colors-brand-2-500": 1,
        "colors-brand-2-600": 1,
        "colors-brand-2-700": 1,
        "colors-accent-1-100": 1,
        "colors-accent-1-200": 1,
        "colors-accent-1-300": 1,
        "colors-accent-1-400": 1,
        "colors-accent-1-500": 1,
        "colors-accent-1-600": 1,
        "colors-accent-1-700": 1,
        "colors-accent-2-100": 1,
        "colors-accent-2-200": 1,
        "colors-accent-2-300": 1,
        "colors-accent-2-400": 1,
        "colors-accent-2-500": 1,
        "colors-accent-2-600": 1,
        "colors-accent-2-700": 1,
        "colors-accent-3-100": 1,
        "colors-accent-3-200": 1,
        "colors-accent-3-300": 1,
        "colors-accent-3-400": 1,
        "colors-accent-3-500": 1,
        "colors-accent-3-600": 1,
        "colors-accent-3-700": 1,
        "colors-accent-4-400_storage": 1,
        "colors-accessible-accent-1-100": 1,
        "colors-accessible-accent-1-200": 1,
        "colors-accessible-accent-1-300": 1,
        "colors-accessible-accent-1-400": 1,
        "colors-accessible-accent-1-500": 1,
        "colors-accessible-accent-1-600": 1,
        "colors-accessible-accent-1-700": 1,
        "colors-accessible-accent-2-100": 1,
        "colors-accessible-accent-2-200": 1,
        "colors-accessible-accent-2-300": 1,
        "colors-accessible-accent-2-400": 1,
        "colors-accessible-accent-2-500": 1,
        "colors-accessible-accent-2-600": 1,
        "colors-accessible-accent-2-700": 1,
        "colors-accessible-brand-2-100": 1,
        "colors-accessible-brand-2-200": 1,
        "colors-accessible-brand-2-300": 1,
        "colors-accessible-brand-2-400": 1,
        "colors-accessible-brand-2-500": 1,
        "colors-accessible-brand-2-600": 1,
        "colors-accessible-brand-2-700": 1
      }
    },
    "Windex": {
      "colors": {
        "components-accessibility-bar-color-bg": "#001928",
        "components-accessibility-bar-color-text": "#ffffff",
        "components-accordion-color-bg": "#7eddff",
        "components-accordion-color-title": "#0a3e6b",
        "components-accordion-color-body": "#0a3e6b",
        "components-accordion-color-section-section-bg": "#ffffff",
        "components-accordion-color-section-section-title": "#0a3e6b",
        "components-accordion-color-section-section-body": "#0a3e6b",
        "components-accordion-color-item-item-bg": "#ffffff",
        "components-accordion-color-item-divider": "#e8e8ea",
        "components-accordion-color-item-icon-icon-default": "#0a3e6b",
        "components-accordion-color-item-icon-icon-hover": "#ffffff",
        "components-accordion-color-item-icon-icon-pressed": "#ffffff",
        "components-accordion-color-item-icon-icon-active": "#ffffff",
        "components-accordion-color-item-title-item-default": "#0a3e6b",
        "components-accordion-color-item-title-item-hover": "#ffffff",
        "components-accordion-color-item-title-item-pressed": "#ffffff",
        "components-accordion-color-item-title-item-active": "#ffffff",
        "components-accordion-color-content-bg": "#ffffff",
        "components-accordion-color-content-title": "#0a3e6b",
        "components-accordion-color-content-body": "#0a3e6b",
        "components-article-card-color-bg": "#ffffff",
        "components-article-card-color-border": "#e8e8ea",
        "components-article-card-color-content-bg": "#ffffff",
        "components-article-card-color-title": "#0a3e6b",
        "components-article-card-color-subtitle": "#0a3e6b",
        "components-article-card-color-copy": "#0a3e6b",
        "components-article-card-color-description": "#0a3e6b",
        "components-article-card-listing-color-default-bg": "#7eddff",
        "components-article-card-listing-color-default-title": "#0a3e6b",
        "components-article-card-listing-color-default-copy": "#0a3e6b",
        "components-article-hero-color-content-bg": "#ffffff",
        "components-article-hero-color-bg": "#ffffff",
        "components-article-hero-color-headline": "#0a3e6b",
        "components-article-hero-color-body": "#0a3e6b",
        "components-article-hero-color-detail-text": "#0a3e6b",
        "components-article-hero-color-divider": "#a2a5ab",
        "components-article-hero-color-icon": "#0a3e6b",
        "components-article-hero-color-play-icon": "#191b1c",
        "components-article-hero-color-selected-stroke": "#da291c",
        "components-banner-color-bg-default": "#7eddff",
        "components-banner-color-icon-default": "#0a3e6b",
        "components-banner-color-title-default": "#0a3e6b",
        "components-banner-color-body-default": "#0a3e6b",
        "components-banner-color-icon-close-default": "#0a3e6b",
        "components-banner-color-bg-alert": "#b32b30",
        "components-banner-color-icon-alert": "#ffffff",
        "components-banner-color-title-alert": "#ffffff",
        "components-banner-color-body-alert": "#ffffff",
        "components-banner-color-icon-close-alert": "#ffffff",
        "components-breadcrumb-color-bg": "#ebf6ff",
        "components-breadcrumb-color-breadcrumb-link-default": "#72757e",
        "components-breadcrumb-color-breadcrumb-link-hover": "#4e5056",
        "components-breadcrumb-color-breadcrumb-link-active": "#0a3e6b",
        "components-breadcrumb-color-icon-divider": "#72757e",
        "components-bug-finder-prompt": "#ffffff",
        "components-button-color-filled-brand-default-bg": "#0a3e6b",
        "components-button-color-filled-brand-default-text": "#ffffff",
        "components-button-color-filled-brand-default-stroke": "#0a3e6b",
        "components-button-color-filled-brand-default-icon": "#ffffff",
        "components-button-color-filled-brand-hover-bg": "#001928",
        "components-button-color-filled-brand-hover-text": "#ffffff",
        "components-button-color-filled-brand-hover-stroke": "#001928",
        "components-button-color-filled-brand-hover-icon": "#ffffff",
        "components-button-color-filled-brand-pressed-bg": "#001928",
        "components-button-color-filled-brand-pressed-text": "#ffffff",
        "components-button-color-filled-brand-pressed-stroke": "#001928",
        "components-button-color-filled-brand-pressed-icon": "#ffffff",
        "components-button-color-filled-brand-disabled-bg": "rgba(10, 62, 107, 0.38)",
        "components-button-color-filled-brand-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-disabled-stroke": "rgba(10, 62, 107, 0.38)",
        "components-button-color-filled-brand-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-brand-focus-bg": "#0a3e6b",
        "components-button-color-filled-brand-focus-text": "#ffffff",
        "components-button-color-filled-brand-focus-stroke": "#0a3e6b",
        "components-button-color-filled-brand-focus-icon": "#ffffff",
        "components-button-color-filled-brand-focus-outline": "#286efa",
        "components-button-color-filled-tonal-default-bg": "#ebfaff",
        "components-button-color-filled-tonal-default-text": "#0a3e6b",
        "components-button-color-filled-tonal-default-stroke": "#ebfaff",
        "components-button-color-filled-tonal-default-icon": "#0a3e6b",
        "components-button-color-filled-tonal-hover-bg": "#c2efff",
        "components-button-color-filled-tonal-hover-text": "#0a3e6b",
        "components-button-color-filled-tonal-hover-stroke": "#c2efff",
        "components-button-color-filled-tonal-hover-icon": "#0a3e6b",
        "components-button-color-filled-tonal-pressed-bg": "#c2efff",
        "components-button-color-filled-tonal-pressed-text": "#0a3e6b",
        "components-button-color-filled-tonal-pressed-stroke": "#c2efff",
        "components-button-color-filled-tonal-pressed-icon": "#0a3e6b",
        "components-button-color-filled-tonal-disabled-bg": "rgba(235, 250, 255, 0.38)",
        "components-button-color-filled-tonal-disabled-text": "rgba(10, 62, 107, 0.38)",
        "components-button-color-filled-tonal-disabled-stroke": "rgba(235, 250, 255, 0.38)",
        "components-button-color-filled-tonal-disabled-icon": "rgba(10, 62, 107, 0.38)",
        "components-button-color-filled-tonal-focus-bg": "#ebfaff",
        "components-button-color-filled-tonal-focus-text": "#0a3e6b",
        "components-button-color-filled-tonal-focus-stroke": "#ebfaff",
        "components-button-color-filled-tonal-focus-icon": "#0a3e6b",
        "components-button-color-filled-tonal-focus-outline": "#1c4cae",
        "components-button-color-filled-white-default-bg": "#ffffff",
        "components-button-color-filled-white-default-text": "#0a3e6b",
        "components-button-color-filled-white-default-stroke": "#ffffff",
        "components-button-color-filled-white-default-icon": "#0a3e6b",
        "components-button-color-filled-white-hover-bg": "#fcfcfd",
        "components-button-color-filled-white-hover-text": "#0a3e6b",
        "components-button-color-filled-white-hover-stroke": "#fcfcfd",
        "components-button-color-filled-white-hover-icon": "#0a3e6b",
        "components-button-color-filled-white-pressed-bg": "#e8e8ea",
        "components-button-color-filled-white-pressed-text": "#0a3e6b",
        "components-button-color-filled-white-pressed-stroke": "#e8e8ea",
        "components-button-color-filled-white-pressed-icon": "#0a3e6b",
        "components-button-color-filled-white-disabled-bg": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-text": "rgba(10, 62, 107, 0.38)",
        "components-button-color-filled-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-icon": "rgba(10, 62, 107, 0.38)",
        "components-button-color-filled-white-focus-bg": "#ffffff",
        "components-button-color-filled-white-focus-text": "#0a3e6b",
        "components-button-color-filled-white-focus-stroke": "#ffffff",
        "components-button-color-filled-white-focus-icon": "#0a3e6b",
        "components-button-color-filled-white-focus-outline": "#1c4cae",
        "components-button-color-outline-brand-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-brand-default-text": "#0a3e6b",
        "components-button-color-outline-brand-default-stroke": "#0a3e6b",
        "components-button-color-outline-brand-default-icon": "#0a3e6b",
        "components-button-color-outline-brand-hover-bg": "#001928",
        "components-button-color-outline-brand-hover-text": "#ffffff",
        "components-button-color-outline-brand-hover-stroke": "#001928",
        "components-button-color-outline-brand-hover-icon": "#ffffff",
        "components-button-color-outline-brand-pressed-bg": "#001928",
        "components-button-color-outline-brand-pressed-text": "#ffffff",
        "components-button-color-outline-brand-pressed-stroke": "#001928",
        "components-button-color-outline-brand-pressed-icon": "#ffffff",
        "components-button-color-outline-brand-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-brand-disabled-text": "rgba(10, 62, 107, 0.40)",
        "components-button-color-outline-brand-disabled-stroke": "rgba(10, 62, 107, 0.38)",
        "components-button-color-outline-brand-disabled-icon": "rgba(10, 62, 107, 0.38)",
        "components-button-color-outline-brand-focus-bg": "#ffffff",
        "components-button-color-outline-brand-focus-text": "#0a3e6b",
        "components-button-color-outline-brand-focus-stroke": "#0a3e6b",
        "components-button-color-outline-brand-focus-icon": "#0a3e6b",
        "components-button-color-outline-brand-focus-outline": "#286efa",
        "components-button-color-outline-tonal-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-tonal-default-text": "#0a3e6b",
        "components-button-color-outline-tonal-default-stroke": "#c2efff",
        "components-button-color-outline-tonal-default-icon": "#0a3e6b",
        "components-button-color-outline-tonal-hover-bg": "#c2efff",
        "components-button-color-outline-tonal-hover-text": "#0a3e6b",
        "components-button-color-outline-tonal-hover-stroke": "#c2efff",
        "components-button-color-outline-tonal-hover-icon": "#0a3e6b",
        "components-button-color-outline-tonal-pressed-bg": "#c2efff",
        "components-button-color-outline-tonal-pressed-text": "#0a3e6b",
        "components-button-color-outline-tonal-pressed-stroke": "#c2efff",
        "components-button-color-outline-tonal-pressed-icon": "#0a3e6b",
        "components-button-color-outline-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-tonal-disabled-text": "rgba(10, 62, 107, 0.38)",
        "components-button-color-outline-tonal-disabled-stroke": "rgba(194, 239, 255, 0.38)",
        "components-button-color-outline-tonal-disabled-icon": "rgba(10, 62, 107, 0.38)",
        "components-button-color-outline-tonal-focus-bg": "rgba(255, 255, 255, 0.01)",
        "components-button-color-outline-tonal-focus-text": "#0a3e6b",
        "components-button-color-outline-tonal-focus-stroke": "#c2efff",
        "components-button-color-outline-tonal-focus-icon": "#0a3e6b",
        "components-button-color-outline-tonal-focus-outline": "#286efa",
        "components-button-color-outline-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-default-text": "#ffffff",
        "components-button-color-outline-white-default-stroke": "#ffffff",
        "components-button-color-outline-white-default-icon": "#ffffff",
        "components-button-color-outline-white-hover-bg": "#ffffff",
        "components-button-color-outline-white-hover-text": "#0a3e6b",
        "components-button-color-outline-white-hover-stroke": "#ffffff",
        "components-button-color-outline-white-hover-icon": "#0a3e6b",
        "components-button-color-outline-white-pressed-bg": "#ffffff",
        "components-button-color-outline-white-pressed-text": "#0a3e6b",
        "components-button-color-outline-white-pressed-stroke": "#ffffff",
        "components-button-color-outline-white-pressed-icon": "#0a3e6b",
        "components-button-color-outline-white-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-disabled-text": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-disabled-icon": "rgba(255, 255, 255, 0.38)",
        "components-button-color-outline-white-focus-bg": "rgba(255, 255, 255, 0.01)",
        "components-button-color-outline-white-focus-text": "#ffffff",
        "components-button-color-outline-white-focus-stroke": "#ffffff",
        "components-button-color-outline-white-focus-icon": "#ffffff",
        "components-button-color-outline-white-focus-outline": "#286efa",
        "components-button-color-text-brand-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-default-text": "#0a3e6b",
        "components-button-color-text-brand-default-stroke": "#0a3e6b",
        "components-button-color-text-brand-default-icon": "#0a3e6b",
        "components-button-color-text-brand-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-hover-bg-icon-only": "#e8e8ea",
        "components-button-color-text-brand-hover-text": "#001928",
        "components-button-color-text-brand-hover-stroke": "#001928",
        "components-button-color-text-brand-hover-icon": "#001928",
        "components-button-color-text-brand-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-pressed-bg-icon-only": "#a2a5ab",
        "components-button-color-text-brand-pressed-text": "#001928",
        "components-button-color-text-brand-pressed-stroke": "#001928",
        "components-button-color-text-brand-pressed-icon": "#001928",
        "components-button-color-text-brand-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-brand-disabled-text": "rgba(10, 62, 107, 0.38)",
        "components-button-color-text-brand-disabled-stroke": "rgba(10, 62, 107, 0.38)",
        "components-button-color-text-brand-disabled-icon": "rgba(10, 62, 107, 0.38)",
        "components-button-color-text-brand-focus-bg": "#d5e3ff",
        "components-button-color-text-brand-focus-text": "#0a3e6b",
        "components-button-color-text-brand-focus-stroke": "#0a3e6b",
        "components-button-color-text-brand-focus-icon": "#1c4cae",
        "components-button-color-text-brand-focus-outline": "#1c4cae",
        "components-button-color-text-tonal-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-default-text": "#0a3e6b",
        "components-button-color-text-tonal-default-stroke": "#c2efff",
        "components-button-color-text-tonal-default-icon": "#0a3e6b",
        "components-button-color-text-tonal-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-hover-bg-icon-only": "#c2efff",
        "components-button-color-text-tonal-hover-text": "#001928",
        "components-button-color-text-tonal-hover-stroke": "#c2efff",
        "components-button-color-text-tonal-hover-icon": "#001928",
        "components-button-color-text-tonal-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-pressed-bg-icon-only": "#c2efff",
        "components-button-color-text-tonal-pressed-text": "#001928",
        "components-button-color-text-tonal-pressed-stroke": "#c2efff",
        "components-button-color-text-tonal-pressed-icon": "#001928",
        "components-button-color-text-tonal-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-tonal-disabled-text": "rgba(10, 62, 107, 0.38)",
        "components-button-color-text-tonal-disabled-stroke": "rgba(194, 239, 255, 0.38)",
        "components-button-color-text-tonal-disabled-icon": "rgba(10, 62, 107, 0.38)",
        "components-button-color-text-tonal-focus-bg": "#d5e3ff",
        "components-button-color-text-tonal-focus-text": "#0a3e6b",
        "components-button-color-text-tonal-focus-stroke": "#c2efff",
        "components-button-color-text-tonal-focus-icon": "#1c4cae",
        "components-button-color-text-tonal-focus-outline": "#1c4cae",
        "components-button-color-text-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-default-text": "#ffffff",
        "components-button-color-text-white-default-stroke": "#ffffff",
        "components-button-color-text-white-default-icon": "#ffffff",
        "components-button-color-text-white-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-hover-bg-icon-only": "rgba(0, 25, 40, 0.50)",
        "components-button-color-text-white-hover-text": "#ffffff",
        "components-button-color-text-white-hover-stroke": "#ffffff",
        "components-button-color-text-white-hover-icon": "#ffffff",
        "components-button-color-text-white-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-pressed-bg-icon-only": "rgba(0, 25, 40, 0.60)",
        "components-button-color-text-white-pressed-text": "#ffffff",
        "components-button-color-text-white-pressed-stroke": "#ffffff",
        "components-button-color-text-white-pressed-icon": "#ffffff",
        "components-button-color-text-white-disabled-bg": "rgba(115, 119, 128, 0.00)",
        "components-button-color-text-white-disabled-text": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-stroke": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-icon": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-focus-bg": "#d5e3ff",
        "components-button-color-text-white-focus-text": "#ffffff",
        "components-button-color-text-white-focus-stroke": "#ffffff",
        "components-button-color-text-white-focus-icon": "#1c4cae",
        "components-button-color-text-white-focus-outline": "#1c4cae",
        "components-button-group-color-border": "#e8e8ea",
        "components-button-group-color-button-bg-default": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-button-fg-default": "#0a3e6b",
        "components-button-group-color-button-bg-hover": "rgba(10, 62, 107, 0.38)",
        "components-button-group-color-button-fg-hover": "#0a3e6b",
        "components-button-group-color-button-bg-active": "#0a3e6b",
        "components-button-group-color-button-fg-active": "#ffffff",
        "components-button-group-color-overlay-left-gradient-fill-1": "#ffffff",
        "components-button-group-color-overlay-left-gradient-fill-2": "rgba(255, 255, 255, 0.50)",
        "components-button-group-color-stroke-default-gradient-fill-1": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-2": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-3": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-4": "rgba(255, 241, 235, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-1": "rgba(10, 62, 107, 0.38)",
        "components-button-group-color-stroke-hover-gradient-fill-2": "rgba(10, 62, 107, 0.38)",
        "components-button-group-color-stroke-hover-gradient-fill-3": "rgba(10, 62, 107, 0.38)",
        "components-button-group-color-stroke-hover-gradient-fill-4": "rgba(10, 62, 107, 0.38)",
        "components-button-group-color-stroke-active-gradient-fill-1": "#0a3e6b",
        "components-button-group-color-stroke-active-gradient-fill-2": "#0a3e6b",
        "components-button-group-color-stroke-active-gradient-fill-3": "#0a3e6b",
        "components-button-group-color-stroke-active-gradient-fill-4": "#0a3e6b",
        "components-category-card-color-bg": "#ffffff",
        "components-category-card-color-content-bg": "#ffffff",
        "components-category-card-color-category-bg": "#ffffff",
        "components-category-card-color-category-bg-2": "#ffffff",
        "components-category-card-color-category-bg-3": "#ffffff",
        "components-category-card-color-category-bg-4": "#ffffff",
        "components-category-card-color-category-bg-5": "#ffffff",
        "components-category-card-color-category-bg-6": "#ffffff",
        "components-category-card-color-title": "#0a3e6b",
        "components-category-card-color-copy": "#0a3e6b",
        "components-category-card-color-stroke": "#ebf6ff",
        "components-category-card-color-stroke-hover": "#4ac2eb",
        "components-category-card-listing-color-bg": "#7eddff",
        "components-category-card-listing-color-title": "#0a3e6b",
        "components-category-card-listing-color-copy": "#0a3e6b",
        "components-card-search-result-color-bg": "#ffffff",
        "components-card-search-result-color-label": "#0a3e6b",
        "components-card-search-result-color-title": "#0a3e6b",
        "components-card-search-result-color-body": "#0a3e6b",
        "components-card-search-result-color-divider-line": "#e8e8ea",
        "components-contact-us-promo-color-light-bg": "#ebf6ff",
        "components-contact-us-promo-color-light-title": "#0a3e6b",
        "components-contact-us-promo-color-light-body": "#0a3e6b",
        "components-contact-us-promo-color-default-bg": "#ffffff",
        "components-contact-us-promo-color-default-title": "#0a3e6b",
        "components-contact-us-promo-color-default-body": "#0a3e6b",
        "components-contact-us-promo-color-brand-bg": "#7eddff",
        "components-contact-us-promo-color-brand-title": "#0a3e6b",
        "components-contact-us-promo-color-brand-body": "#0a3e6b",
        "components-content-list-color-bg": "#ffffff",
        "components-content-list-color-title": "#0a3e6b",
        "components-content-list-color-body": "#0a3e6b",
        "components-content-list-color-icon": "#da291c",
        "components-cookies-colors-bg": "#ebf6ff",
        "components-cookies-colors-title": "#0a3e6b",
        "components-cookies-colors-body": "#0a3e6b",
        "components-cookies-colors-icon": "#da291c",
        "components-dropdown-bg": "#ffffff",
        "components-dropdown-border-default": "#72757e",
        "components-dropdown-border-hover": "#0a3e6b",
        "components-dropdown-border-focused": "#001928",
        "components-dropdown-border-destructive": "#b32b30",
        "components-dropdown-label-text-default": "#0a3e6b",
        "components-dropdown-item-text-default": "#0a3e6b",
        "components-dropdown-item-bg-default": "#ffffff",
        "components-dropdown-item-bg-hover": "#ebfaff",
        "components-dropdown-input-field-default": "#72757e",
        "components-dropdown-input-field-focused": "#191b1c",
        "components-dropdown-input-label-focused": "#0a3e6b",
        "components-dropdown-input-field-destructive": "#b32b30",
        "components-external-link-card-bg": "#ebf6ff",
        "components-external-link-card-card-bg": "#ffffff",
        "components-external-link-card-card-title": "#0a3e6b",
        "components-external-link-card-card-subtitle": "#0a3e6b",
        "components-external-link-card-card-eyebrow": "#0a3e6b",
        "components-fab-color-border-default": "#ffffff",
        "components-footer-brand-color-bg": "#0a3e6b",
        "components-footer-brand-color-logo": "#ffffff",
        "components-footer-brand-color-title-text": "#ffffff",
        "components-footer-brand-color-description-text": "#ffffff",
        "components-footer-brand-color-footer-menu-expanded": "#0a3e6b",
        "components-footer-brand-color-link-default": "#ffffff",
        "components-footer-brand-color-link-hover": "#e8e8ea",
        "components-footer-brand-color-link-pressed": "#e8e8ea",
        "components-footer-brand-color-category-link-default": "#ffffff",
        "components-footer-brand-color-category-link-hover": "#e8e8ea",
        "components-footer-brand-color-category-link-pressed": "#e8e8ea",
        "components-footer-brand-color-subcategory-link-default": "#ffffff",
        "components-footer-brand-color-subcategory-link-hover": "#e8e8ea",
        "components-footer-brand-color-subcategory-link-pressed": "#e8e8ea",
        "components-footer-brand-color-icon-icon-default": "#ffffff",
        "components-footer-brand-color-icon-icon-pressed": "#e8e8ea",
        "components-footer-brand-color-social-icon-default": "#ffffff",
        "components-footer-brand-color-social-icon-hover": "#e8e8ea",
        "components-footer-brand-color-social-icon-pressed": "#e8e8ea",
        "components-footer-brand-spacing-category-link-hover": "#000000",
        "components-footer-global-color-bg": "#ffffff",
        "components-footer-global-color-title-text": "#000000",
        "components-footer-global-color-disclaimer-text": "#000000",
        "components-footer-global-color-link-default": "#000000",
        "components-footer-global-color-link-hover": "#000000",
        "components-footer-global-color-link-pressed": "#000000",
        "components-generic-card-color-bg": "#ffffff",
        "components-generic-card-color-content-bg": "#ffffff",
        "components-generic-card-color-title": "#0a3e6b",
        "components-generic-card-color-subtitle": "#0a3e6b",
        "components-generic-card-color-copy": "#0a3e6b",
        "components-global-search-color-omnibox-list-bg": "#ffffff",
        "components-global-search-color-omnibox-divider": "#a2a5ab",
        "components-global-search-color-omnibox-item-bg-default": "#ffffff",
        "components-global-search-color-omnibox-item-bg-hover": "#fcf2f2",
        "components-global-search-color-omnibox-item-text-default": "#0a3e6b",
        "components-global-search-color-omnibox-item-text-hover": "#001928",
        "components-global-search-color-omnibox-label-text-default": "#4e5056",
        "components-global-search-color-omnibox-category-text-default": "#4e5056",
        "components-generic-card-listing-color-bg": "#7eddff",
        "components-generic-card-listing-color-title": "#0a3e6b",
        "components-generic-card-listing-color-copy": "#0a3e6b",
        "components-header-color-bg": "#0a3e6b",
        "components-header-color-bottom-stroke": "#001928",
        "components-header-color-search-search-border-default": "#0a3e6b",
        "components-header-color-search-search-text": "#4e5056",
        "components-header-color-search-search-icon": "#0a3e6b",
        "components-header-color-search-search-bar": "#0a3e6b",
        "components-header-color-mobile-mobile-expanded-bg": "#ffffff",
        "components-header-color-mobile-pressed-bg": "#0a3e6b",
        "components-header-color-language-selector-language": "#ebfaff",
        "components-header-color-language-selector-country": "#ffffff",
        "components-header-color-language-selector-country-hover-bg": "rgba(255, 255, 255, 0.10)",
        "components-header-color-language-selector-megamenu-language": "#0a3e6b",
        "components-header-color-language-selector-megamenu-country": "#0a3e6b",
        "components-header-color-language-selector-megamenu-country-hover": "#001928",
        "components-header-color-language-selector-bg": "#ebf6ff",
        "components-header-color-language-selector-stroke": "#0a3e6b",
        "components-header-color-mega-menu-bg": "#ffffff",
        "components-header-color-mega-menu-featured-bg": "#0a3e6b",
        "components-header-color-mega-menu-featured-title": "#ffffff",
        "components-header-color-mega-menu-category-title": "#0a3e6b",
        "components-header-color-mega-menu-bg-accent": "#ebf6ff",
        "components-header-color-icon-icon-default": "#ebfaff",
        "components-header-color-icon-icon-hover": "#c2efff",
        "components-header-color-icon-icon-pressed": "#c2efff",
        "components-header-color-links-nav-main-link-default": "#ebfaff",
        "components-header-color-links-nav-main-link-hover": "#c2efff",
        "components-header-color-links-nav-main-link-pressed": "#c2efff",
        "components-header-color-links-nav-main-link-active": "#ffffff",
        "components-header-color-links-nav-main-active-bar": "#7eddff",
        "components-header-color-links-nav-main-bg-color": "#ffffff",
        "components-header-color-links-nav-category-link-category-default": "#0a3e6b",
        "components-header-color-links-nav-category-link-category-hover": "#001928",
        "components-header-color-links-nav-category-link-category-pressed": "#001928",
        "components-header-color-links-nav-category-link-category-active": "#001928",
        "components-header-color-links-nav-menu-link-link-default": "#0a3e6b",
        "components-header-color-links-nav-menu-link-link-hover": "#001928",
        "components-header-color-links-nav-menu-link-link-pressed": "#001928",
        "components-header-color-links-nav-menu-link-link-active": "#001928",
        "components-header-color-links-nav-menu-link-bg": "#ffffff",
        "components-header-color-links-nav-subcategory-link-subcategory-default": "#0a3e6b",
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": "#001928",
        "components-header-color-links-nav-subcategory-link-subcategory-active": "#001928",
        "components-hero-homepage-color-bg": "#ffffff",
        "components-hero-homepage-color-container-bg": "#ebf6ff",
        "components-hero-homepage-color-eyebrow": "#72757e",
        "components-hero-homepage-color-header": "#0a3e6b",
        "components-hero-homepage-color-sub-header": "#0a3e6b",
        "components-hero-homepage-color-body": "#0a3e6b",
        "components-hero-homepage-color-play-pause": "#000000",
        "components-hero-internal-color-default-title": "#0a3e6b",
        "components-hero-internal-color-default-body": "#0a3e6b",
        "components-hero-internal-color-default-bg": "#ebf6ff",
        "components-hero-internal-color-accent-bg": "#ebf6ff",
        "components-hero-internal-color-with-image-bg": "#ebf6ff",
        "components-hero-internal-color-default-bg-2": "#ebf6ff",
        "components-hero-internal-color-with-image-title": "#0a3e6b",
        "components-hero-internal-color-with-image-body": "#0a3e6b",
        "components-heading-page-title-color-default-bg": "#7eddff",
        "components-heading-page-title-color-default-title": "#0a3e6b",
        "components-heading-page-title-color-default-body": "#0a3e6b",
        "components-heading-page-title-color-light-bg": "#ffffff",
        "components-heading-page-title-color-light-title": "#0a3e6b",
        "components-heading-page-title-color-light-body": "#0a3e6b",
        "components-heading-page-title-color-color-bg": "#ebf6ff",
        "components-heading-page-title-color-color-title": "#0a3e6b",
        "components-heading-page-title-color-color-body": "#0a3e6b",
        "components-hyperlink-color-dark-default": "#06268b",
        "components-hyperlink-color-dark-hover": "#0a1672",
        "components-hyperlink-color-dark-visited": "#0074a3",
        "components-hyperlink-color-light-default": "#7eddff",
        "components-hyperlink-color-light-hover": "#4ac2eb",
        "components-hyperlink-color-light-visited": "#99bfff",
        "components-icon-default": "#72757e",
        "components-icon-focused": "#72757e",
        "components-icon-card-color-bg": "#ffffff",
        "components-icon-card-color-icon-bg": "#001928",
        "components-icon-card-color-icon": "#ffffff",
        "components-icon-card-color-title": "#0a3e6b",
        "components-icon-card-color-subtitle": "#0a3e6b",
        "components-icon-card-color-description": "#0a3e6b",
        "components-icon-card-listing-color-bg": "#ffffff",
        "components-icon-card-listing-color-inner-bg": "#ffffff",
        "components-icon-card-listing-color-brand-bg": "#7eddff",
        "components-icon-card-listing-color-title": "#0a3e6b",
        "components-icon-card-listing-color-copy": "#0a3e6b",
        "components-icon-card-listing-color-inner-legal-text": "#0a3e6b",
        "components-jumplink-color-bg-default": "#ffffff",
        "components-jumplink-color-link-text-default": "#4e5056",
        "components-jumplink-color-link-text-hover": "#0a3e6b",
        "components-jumplink-color-link-border-hover": "#0a3e6b",
        "components-jumplink-color-link-text-active": "#001928",
        "components-jumplink-color-link-border-active": "#001928",
        "components-jumplink-color-title-text": "#0a3e6b",
        "components-jumplink-color-description-text": "#0a3e6b",
        "components-layout-bg-default": "#ffffff",
        "components-lightbox-color-overlay-bg": "rgba(255, 255, 255, 0.95)",
        "components-lightbox-color-title": "#0a3e6b",
        "components-lightbox-color-body": "#0a3e6b",
        "components-label-color-on-color-label-label-default": "#ffffff",
        "components-label-color-on-color-label-label-hover": "#0a3e6b",
        "components-label-color-on-color-label-label-pressed": "#0a3e6b",
        "components-label-color-on-color-background-bg-default": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-color-background-bg-hover": "#ffffff",
        "components-label-color-on-color-background-bg-pressed": "#e8e8ea",
        "components-label-color-on-color-stroke-hover": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-color-stroke-pressed": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-color-stroke-default-gradient-fill-1": "#e8e8ea",
        "components-label-color-on-color-stroke-default-gradient-fill-2": "#e8e8ea",
        "components-label-color-on-color-stroke-default-gradient-fill-3": "#e8e8ea",
        "components-label-color-on-color-stroke-default-gradient-fill-4": "#e8e8ea",
        "components-label-color-on-white-label-label-default": "#0a3e6b",
        "components-label-color-on-white-label-label-hover": "#ffffff",
        "components-label-color-on-white-label-label-pressed": "#ffffff",
        "components-label-color-on-white-background-bg-default": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-white-background-bg-hover": "#0a3e6b",
        "components-label-color-on-white-background-bg-pressed": "#001928",
        "components-label-color-on-white-stroke-hover": "#0a3e6b",
        "components-label-color-on-white-stroke-pressed": "#001928",
        "components-label-color-on-white-stroke-default-gradient-fill-1": "#0a3e6b",
        "components-label-color-on-white-stroke-default-gradient-fill-2": "#0a3e6b",
        "components-label-color-on-white-stroke-default-gradient-fill-3": "#0a3e6b",
        "components-label-color-on-white-stroke-default-gradient-fill-4": "#0a3e6b",
        "components-legal-disclaimer-color-default-bg": "#ffffff",
        "components-legal-disclaimer-color-default-title": "#0a3e6b",
        "components-legal-disclaimer-color-default-body": "#0a3e6b",
        "components-legal-disclaimer-color-light-bg": "#ebf6ff",
        "components-legal-disclaimer-color-light-title": "#0a3e6b",
        "components-legal-disclaimer-color-light-body": "#0a3e6b",
        "components-legal-disclaimer-color-brand-bg": "#7eddff",
        "components-legal-disclaimer-color-brand-title": "#0a3e6b",
        "components-legal-disclaimer-color-brand-body": "#0a3e6b",
        "components-media-video-thumbnail-overlay-bg": "rgba(0, 25, 40, 0.80)",
        "components-media-caption-color-bg": "#fcfcfd",
        "components-media-caption-color-text": "#0a3e6b",
        "components-media-gallery-color-bg": "#ffffff",
        "components-media-gallery-color-title": "#0a3e6b",
        "components-media-gallery-color-body": "#0a3e6b",
        "components-pagination-on-white-accent-default": "#0a3e6b",
        "components-pagination-on-white-accent-scroll": "#0074a3",
        "components-pagination-on-white-disabled": "#a2a5ab",
        "components-pagination-on-white-bg": "#c2efff",
        "components-pagination-on-white-stroke": "#e8e8ea",
        "components-pagination-on-white-bg-active": "#0a3e6b",
        "components-pagination-on-white-bg-hover": "#e8e8ea",
        "components-pagination-on-white-text-default": "#72757e",
        "components-pagination-on-white-text-hover": "#0a3e6b",
        "components-pagination-on-white-text-active": "#ffffff",
        "components-pagination-on-color-accent-default": "#ffffff",
        "components-pagination-on-color-accent-scroll": "#21d0db",
        "components-pagination-on-color-disabled": "#001928",
        "components-pagination-on-color-bg": "rgba(255, 255, 255, 0.20)",
        "components-pagination-on-color-stroke": "rgba(255, 255, 255, 0.38)",
        "components-pagination-on-color-bg-active": "#ffffff",
        "components-pagination-on-color-bg-hover": "#27282b",
        "components-pagination-on-color-text-default": "#ffffff",
        "components-pagination-on-color-text-hover": "#ffffff",
        "components-pagination-on-color-text-active": "#0a3e6b",
        "components-pdf-link-card-color-bg": "#ebf6ff",
        "components-pdf-link-card-color-card-bg": "#ffffff",
        "components-pdf-link-card-color-card-title": "#0a3e6b",
        "components-pdf-link-card-color-card-subtitle": "#0a3e6b",
        "components-pdf-link-card-color-card-body": "#0a3e6b",
        "components-product-card-color-bg": "#ffffff",
        "components-product-card-color-content-bg": "#ffffff",
        "components-product-card-color-border": "#c2efff",
        "components-product-card-color-title": "#0a3e6b",
        "components-product-card-color-description": "#0a3e6b",
        "components-product-card-color-star-rating": "#da291c",
        "components-product-card-color-badge-bg": "#da291c",
        "components-product-card-color-badge-text": "#ffffff",
        "components-product-card-listing-color-bg": "#7eddff",
        "components-product-card-listing-color-title": "#0a3e6b",
        "components-product-card-listing-color-copy": "#0a3e6b",
        "components-product-card-listing-color-legal-text": "#0a3e6b",
        "components-product-information-color-bg": "#ffffff",
        "components-product-information-color-content-bg": "#ffffff",
        "components-product-information-color-headline": "#0a3e6b",
        "components-product-information-color-body": "#0a3e6b",
        "components-product-information-color-detail-text": "#0a3e6b",
        "components-product-information-color-divider": "#a2a5ab",
        "components-product-information-color-icon": "#ffffff",
        "components-product-information-color-play-icon": "#ffffff",
        "components-product-information-color-selected-stroke": "#da291c",
        "components-product-results-list-with-search-color-bg": "#ffffff",
        "components-promo-color-default-bg": "#ffffff",
        "components-promo-color-default-image-bg": "#ffffff",
        "components-promo-color-default-content-bg": "#ffffff",
        "components-promo-color-default-title": "#0a3e6b",
        "components-promo-color-default-body": "#0a3e6b",
        "components-promo-color-default-category": "#0a3e6b",
        "components-promo-color-light-bg": "#ebf6ff",
        "components-promo-color-light-image-bg": "#ebf6ff",
        "components-promo-color-light-content-bg": "#ebf6ff",
        "components-promo-color-light-title": "#0a3e6b",
        "components-promo-color-light-body": "#0a3e6b",
        "components-promo-color-light-category": "#0a3e6b",
        "components-promo-color-brand-bg": "#7eddff",
        "components-promo-color-brand-image-bg": "#7eddff",
        "components-promo-color-brand-content-bg": "#7eddff",
        "components-promo-color-brand-title": "#0a3e6b",
        "components-promo-color-brand-body": "#0a3e6b",
        "components-promo-color-brand-category": "#0a3e6b",
        "components-pull-quote-color-bg": "#ebf6ff",
        "components-pull-quote-color-stroke": "#c2efff",
        "components-pull-quote-color-quote-bg": "#ffffff",
        "components-pull-quote-color-quote-title": "#0a3e6b",
        "components-pull-quote-color-quote-by-text": "#0a3e6b",
        "components-pull-quote-color-quote-detail": "#0a3e6b",
        "components-pull-quote-color-quote-highlight": "#da291c",
        "components-promo-alternate-color-default-bg": "#ffffff",
        "components-promo-alternate-color-default-image-bg": "#ffffff",
        "components-promo-alternate-color-default-content-bg": "#ffffff",
        "components-promo-alternate-color-default-title": "#0a3e6b",
        "components-promo-alternate-color-default-body": "#0a3e6b",
        "components-promo-alternate-color-default-category": "#0a3e6b",
        "components-promo-alternate-color-light-bg": "#ebf6ff",
        "components-promo-alternate-color-light-image-bg": "#ebf6ff",
        "components-promo-alternate-color-light-content-bg": "#ebf6ff",
        "components-promo-alternate-color-light-title": "#0a3e6b",
        "components-promo-alternate-color-light-body": "#0a3e6b",
        "components-promo-alternate-color-light-category": "#0a3e6b",
        "components-promo-alternate-color-brand-bg": "#7eddff",
        "components-promo-alternate-color-brand-image-bg": "#7eddff",
        "components-promo-alternate-color-brand-content-bg": "#7eddff",
        "components-promo-alternate-color-brand-title": "#0a3e6b",
        "components-promo-alternate-color-brand-body": "#0a3e6b",
        "components-promo-alternate-color-brand-category": "#0a3e6b",
        "components-share-list-color-title": "#0a3e6b",
        "components-share-list-color-bg": "#ffffff",
        "components-share-list-color-link-default": "#0a3e6b",
        "components-share-list-color-link-hover": "#0074a3",
        "components-share-list-color-bg-link-hover": "#ebf6ff",
        "components-share-list-color-close-icon": "#0a3e6b",
        "components-share-list-color-facebook-icon": "#3b5998",
        "components-share-list-color-pinterest-icon": "#e60023",
        "components-share-list-color-twitter-icon": "#000000",
        "components-share-list-color-basic-icon-default": "#0a3e6b",
        "components-share-list-color-icon-hover": "#0a3e6b",
        "components-share-list-color-border": "#0074a3",
        "components-site-search-bg": "#ffffff",
        "components-site-search-results_text": "#0a3e6b",
        "components-site-search-filter-filter-selected-bg": "#ebfaff",
        "components-site-search-facet-group-label-bottom-line": "#e8e8ea",
        "components-site-search-facet-group-label-text": "#0a3e6b",
        "components-site-search-facet-group-label-bg": "#ffffff",
        "components-site-search-facet-bg": "#ffffff",
        "components-site-search-facet-stroke": "#e8e8ea",
        "components-site-search-refine-bg": "#ffffff",
        "components-site-search-refine-right-divider": "#ebf6ff",
        "components-site-search-refine-title": "#0a3e6b",
        "components-site-search-hero-search-bg": "#ebf6ff",
        "components-site-search-hero-search-accent-bg": "#ebf6ff",
        "components-site-search-hero-search-title": "#0a3e6b",
        "components-stats-stat-text": "#da291c",
        "components-stats-stat-text-on-light": "#0a3e6b",
        "components-stats-supporting-text": "#0a3e6b",
        "components-stats-bg-default": "#ebf6ff",
        "components-stats-bg-inner": "#ffffff",
        "components-stats-bg-section-inner": "#7eddff",
        "components-stats-title": "#0a3e6b",
        "components-stats-copy": "#0a3e6b",
        "components-supporting-text-default": "#4e5056",
        "components-supporting-text-destructive": "#b32b30",
        "components-tab-bg": "#ffffff",
        "components-tab-border-default": "#72757e",
        "components-tab-border-hover": "#4ac2eb",
        "components-tab-border-focused": "#191b1c",
        "components-tab-border-destructive": "#b32b30",
        "components-tab-label-text-default": "#191b1c",
        "components-tab-item-text-default": "#191b1c",
        "components-tab-item-bg-default": "#ffffff",
        "components-tab-item-bg-hover": "#ebfaff",
        "components-tab-input-field-default": "#72757e",
        "components-tab-input-field-focused": "#191b1c",
        "components-tab-input-label-focused": "#0a3e6b",
        "components-tab-input-field-destructive": "#b32b30",
        "components-tab-bg-2": "#ffffff",
        "components-tag-color-bg": "#ebf6ff",
        "components-tag-color-text": "#0a3e6b",
        "components-tag-color-stroke": "#99bfff",
        "components-tag-color-bg-hover": "#99bfff",
        "components-tag-color-text-hover": "#001928",
        "components-tag-color-stroke-hover": "#99bfff",
        "components-text-field-bg": "#ffffff",
        "components-text-field-bg-focused": "#ebfaff",
        "components-text-field-border-default": "#0a3e6b",
        "components-text-field-border-hover": "#009ca6",
        "components-text-field-border-focused": "#0a3e6b",
        "components-text-field-border-destructive": "#b32b30",
        "components-text-field-input-field-default": "#4e5056",
        "components-text-field-input-field-focused": "#0a3e6b",
        "components-text-field-input-label-focused": "#0a3e6b",
        "components-text-field-input-field-destructive": "#b32b30",
        "components-text-field-icon-default": "#0a3e6b",
        "components-text-field-icon-destructive": "#b32b30",
        "components-text-field-supporting-text-default": "#0074a3",
        "components-text-field-supporting-text-destructive": "#b32b30",
        "components-tip-color-bg": "#ebf6ff",
        "components-tip-color-title": "#0a3e6b",
        "components-tip-color-body": "#0a3e6b",
        "components-tip-color-stroke": "#c2efff",
        "components-video-color-bg": "#7eddff",
        "themes-general-surface-color-light-bg": "#ebf6ff",
        "themes-general-surface-color-light-title": "#0a3e6b",
        "themes-general-surface-color-light-body": "#0a3e6b",
        "themes-general-surface-color-light-decor-line-break": "#a2a5ab",
        "themes-general-surface-color-light-rule-line": "rgba(162, 165, 171, 0.40)",
        "themes-general-surface-color-dark-bg": "#7eddff",
        "themes-general-surface-color-dark-title": "#ffffff",
        "themes-general-surface-color-dark-body": "#fcfcfd",
        "themes-general-surface-color-dark-decor-line-break": "#72757e",
        "themes-general-card-color-dark-title": "#0a3e6b",
        "themes-general-card-color-dark-subtitle": "#0a3e6b",
        "themes-general-card-color-dark-body": "#0a3e6b",
        "themes-general-card-color-light-title": "#ffffff",
        "themes-general-card-color-light-subtitle": "#fcfcfd",
        "themes-general-card-color-light-body": "#fcfcfd",
        "themes-shadow-color": "#ffffff",
        "themes-shadow-share-list-color-fill": "rgba(0, 0, 0, 0.40)",
        "themes-shadow-header-menu-color-fill": "rgba(0, 0, 0, 0.20)",
        "themes-gradient-loader-gradient-fill-1": "#0a3e6b",
        "themes-gradient-loader-gradient-fill-2": "rgba(10, 62, 107, 0.00)",
        "themes-color-background-white": "#ffffff",
        "themes-color-background-light": "#ebf6ff",
        "themes-color-background-dark": "#27282b",
        "themes-color-background-black": "#191b1c",
        "themes-color-background-brand1": "#7eddff",
        "themes-color-background-brand2": "#a92016",
        "themes-color-background-accent1": "#003da5",
        "themes-color-background-accent2": "#009ca6",
        "themes-color-background-body": "#ffffff",
        "themes-color-stroke-white": "#ffffff",
        "themes-color-stroke-light": "#e8e8ea",
        "themes-color-stroke-dark": "#27282b",
        "themes-color-stroke-black": "#191b1c",
        "themes-color-stroke-brand1": "#0a3e6b",
        "themes-color-stroke-brand2": "#da291c",
        "themes-color-stroke-accent1": "#003da5",
        "themes-color-stroke-accent2": "#009ca6",
        "themes-color-text-white": "#ffffff",
        "themes-color-text-light": "#fcfcfd",
        "themes-color-text-dark": "#0a3e6b",
        "themes-color-text-black": "#0a3e6b",
        "themes-color-text-brand1": "#0074a3",
        "themes-color-text-brand2": "#a92016",
        "themes-color-text-accent1": "#0a1672",
        "themes-color-text-accent2": "#002626",
        "colors-grayscale-100": "#fcfcfd",
        "colors-grayscale-200": "#e8e8ea",
        "colors-grayscale-300": "#a2a5ab",
        "colors-grayscale-400": "#72757e",
        "colors-grayscale-500": "#4e5056",
        "colors-grayscale-600": "#27282b",
        "colors-grayscale-700": "#191b1c",
        "colors-brand-1-100": "#ebfaff",
        "colors-brand-1-200": "#c2efff",
        "colors-brand-1-300": "#7eddff",
        "colors-brand-1-400": "#4ac2eb",
        "colors-brand-1-500": "#0074a3",
        "colors-brand-1-600": "#0a3e6b",
        "colors-brand-1-700": "#001928",
        "colors-brand-2-100": "#fcf2f2",
        "colors-brand-2-200": "#fadcdb",
        "colors-brand-2-300": "#f19d99",
        "colors-brand-2-400": "#da291c",
        "colors-brand-2-500": "#a92016",
        "colors-brand-2-600": "#781710",
        "colors-brand-2-700": "#61120d",
        "colors-accent-1-100": "#ebf6ff",
        "colors-accent-1-200": "#99bfff",
        "colors-accent-1-300": "#155fe6",
        "colors-accent-1-400": "#003da5",
        "colors-accent-1-500": "#06268b",
        "colors-accent-1-600": "#0a1672",
        "colors-accent-1-700": "#0c0c5b",
        "colors-accent-2-100": "#e5feff",
        "colors-accent-2-200": "#a3f0f5",
        "colors-accent-2-300": "#21d0db",
        "colors-accent-2-400": "#009ca6",
        "colors-accent-2-500": "#00767b",
        "colors-accent-2-600": "#004f51",
        "colors-accent-2-700": "#002626",
        "colors-accent-3-100": "#fcfcfd",
        "colors-accent-3-200": "#e8e8ea",
        "colors-accent-3-300": "#a2a5ab",
        "colors-accent-3-400": "#72757e",
        "colors-accent-3-500": "#4e5056",
        "colors-accent-3-600": "#27282b",
        "colors-accent-3-700": "#191b1c",
        "colors-accent-4-400_storage": "#000000",
        "colors-accessible-accent-1-100": "#ebf6ff",
        "colors-accessible-accent-1-200": "#99bfff",
        "colors-accessible-accent-1-300": "#155fe6",
        "colors-accessible-accent-1-400": "#003da5",
        "colors-accessible-accent-1-500": "#06268b",
        "colors-accessible-accent-1-600": "#0a1672",
        "colors-accessible-accent-1-700": "#0c0c5b",
        "colors-accessible-accent-2-100": "#e5feff",
        "colors-accessible-accent-2-200": "#a3f0f5",
        "colors-accessible-accent-2-300": "#21d0db",
        "colors-accessible-accent-2-400": "#009ca6",
        "colors-accessible-accent-2-500": "#00767b",
        "colors-accessible-accent-2-600": "#004f51",
        "colors-accessible-accent-2-700": "#002626",
        "colors-accessible-brand-2-100": "#fcf2f2",
        "colors-accessible-brand-2-200": "#fadcdb",
        "colors-accessible-brand-2-300": "#f19d99",
        "colors-accessible-brand-2-400": "#da291c",
        "colors-accessible-brand-2-500": "#a92016",
        "colors-accessible-brand-2-600": "#781710",
        "colors-accessible-brand-2-700": "#61120d"
      },
      "spacing": {
        "components-accordion-spacing-large-padding-x": "5rem",
        "components-accordion-spacing-large-icon-margin-right": "2rem",
        "components-accordion-spacing-large-copy-margin-right": "2.5rem",
        "components-accordion-spacing-large-maxwidth": "64rem",
        "components-accordion-spacing-large-section-padding-x": "2.5rem",
        "components-accordion-spacing-large-section-padding-y": "2.5rem",
        "components-accordion-spacing-large-section-copy-margin-bottom": "2.5rem",
        "components-accordion-spacing-large-section-space-between": "4rem",
        "components-accordion-spacing-large-item-padding-x": "0rem",
        "components-accordion-spacing-large-item-padding-y": "1.5rem",
        "components-accordion-spacing-large-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-large-content-padding-x": "0rem",
        "components-accordion-spacing-large-content-padding-y": "1.5rem",
        "components-accordion-spacing-large-content-title-margin-bottom": "2rem",
        "components-accordion-spacing-large-content-content-space-between": "1.5rem",
        "components-accordion-spacing-large-content-content-margin-left": "1rem",
        "components-accordion-spacing-small-padding-top": "1rem",
        "components-accordion-spacing-small-padding-x": "1.5rem",
        "components-accordion-spacing-small-padding-y": "2.5rem",
        "components-accordion-spacing-small-icon-margin-right": "0.75rem",
        "components-accordion-spacing-small-title-margin-bottom": "0.5rem",
        "components-accordion-spacing-small-copy-margin-right": "2rem",
        "components-accordion-spacing-small-copy-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-padding-x": "1.5rem",
        "components-accordion-spacing-small-section-padding-y": "1.5rem",
        "components-accordion-spacing-small-section-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-space-between": "1rem",
        "components-accordion-spacing-small-item-padding-x": "0rem",
        "components-accordion-spacing-small-item-padding-y": "1rem",
        "components-accordion-spacing-small-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-small-content-padding-x": "1.5rem",
        "components-accordion-spacing-small-content-padding-y": "0.75rem",
        "components-accordion-spacing-small-content-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-content-content-space-between": "1rem",
        "components-accordion-spacing-small-content-content-margin-left": "1rem",
        "components-article-card-spacing-large-padding-image-x": "0rem",
        "components-article-card-spacing-large-padding-image-y": "0rem",
        "components-article-card-spacing-large-padding-content-x": "1.5rem",
        "components-article-card-spacing-large-padding-content-y": "2rem",
        "components-article-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-article-card-spacing-large-button-space-between": "1rem",
        "components-article-card-spacing-large-title-margin-bottom": "1rem",
        "components-article-card-spacing-large-list-content-gap": "1rem",
        "components-article-card-spacing-large-list-image-padding-x": "0rem",
        "components-article-card-spacing-large-list-image-padding-y": "0rem",
        "components-article-card-spacing-small-padding-image-x": "0rem",
        "components-article-card-spacing-small-padding-image-y": "0rem",
        "components-article-card-spacing-small-padding-content-x": "1.5rem",
        "components-article-card-spacing-small-padding-content-y": "1.5rem",
        "components-article-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-article-card-listing-spacing-small-padding-x": "1.5rem",
        "components-article-card-listing-spacing-small-padding-y": "2.5rem",
        "components-article-hero-spacing-large-padding-x": "0rem",
        "components-article-hero-spacing-large-padding-y": "0rem",
        "components-article-hero-spacing-large-title-margin-bottom": "2rem",
        "components-article-hero-spacing-large-label-share-margin-bottom": "2rem",
        "components-article-hero-spacing-large-author-name-margin-bottom": "0.75rem",
        "components-article-hero-spacing-large-author-thumbnail-margin-left": "1rem",
        "components-article-hero-spacing-large-details-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-space-between": "1rem",
        "components-article-hero-spacing-large-body-margin-bottom": "2rem",
        "components-article-hero-spacing-large-image-space-between": "1rem",
        "components-article-hero-spacing-large-image-margin-bottom": "1rem",
        "components-article-hero-spacing-large-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-stats-margin-bottom": "2.5rem",
        "components-article-hero-spacing-small-content-margin-top": "2.5rem",
        "components-article-hero-spacing-small-title-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-label-share-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-authorname-margin-bottom": "0.75rem",
        "components-article-hero-spacing-small-authorthumbnail-margin-left": "1rem",
        "components-article-hero-spacing-small-details-margin-bottom": "1rem",
        "components-article-hero-spacing-small-space-between": "0.75rem",
        "components-article-hero-spacing-small-body-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-image-space-between": "0.75rem",
        "components-article-hero-spacing-small-image-margin-bottom": "1rem",
        "components-article-hero-spacing-small-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-stats-margin-bottom": "2rem",
        "components-banner-spacing-large-content-padding-x": "1.5rem",
        "components-banner-spacing-large-content-space-between": "1rem",
        "components-banner-spacing-large-content-width": "69.375rem",
        "components-banner-spacing-icon-dimensions": "1.5rem",
        "components-banner-spacing-icon-margin-left": "0.5rem",
        "components-banner-spacing-small-space-between": "1rem",
        "components-banner-spacing-small-content-space-between": "1rem",
        "components-banner-spacing-small-title-margin-bottom": "0.75rem",
        "components-banner-spacing-small-content-margin-bottom": "1rem",
        "components-breadcrumb-spacing-large-space-between": "0.25rem",
        "components-breadcrumb-spacing-small-space-between": "0.25rem",
        "components-button-spacing-large-icon-large-x": "2.5rem",
        "components-button-spacing-large-icon-large-y": "2.5rem",
        "components-button-spacing-large-default-padding-y": "0.75rem",
        "components-button-spacing-large-default-padding-x": "1.5rem",
        "components-button-spacing-large-default-space-between": "0.25rem",
        "components-button-spacing-large-icon-left-padding-left": "1rem",
        "components-button-spacing-large-icon-right-padding-right": "1rem",
        "components-button-spacing-large-icon-only-padding-x": "0.75rem",
        "components-button-spacing-large-icon-only-padding-y": "0.75rem",
        "components-button-spacing-large-tertiary-content-height": "1.5rem",
        "components-button-spacing-compressed-icon-large-x": "2.5rem",
        "components-button-spacing-compressed-icon-large-y": "2.5rem",
        "components-button-spacing-compressed-default-padding-y": "0.5rem",
        "components-button-spacing-compressed-default-padding-x": "1rem",
        "components-button-spacing-compressed-default-space-between": "0.125rem",
        "components-button-spacing-compressed-icon-left-padding-left": "0.5rem",
        "components-button-spacing-compressed-icon-right-padding-right": "0.5rem",
        "components-button-spacing-compressed-icon-only-padding-x": "0.5rem",
        "components-button-group-spacing-padding-y": "0.125rem",
        "components-button-group-spacing-padding-x": "0.25rem",
        "components-button-group-spacing-space-between": "0.5rem",
        "components-category-card-spacing-large-padding-top": "0rem",
        "components-category-card-spacing-large-padding-x": "0rem",
        "components-category-card-spacing-large-padding-y": "0rem",
        "components-category-card-spacing-large-padding-image-x": "0rem",
        "components-category-card-spacing-large-padding-image-y": "0rem",
        "components-category-card-spacing-large-padding-content-x": "2rem",
        "components-category-card-spacing-large-padding-content-y": "2rem",
        "components-category-card-listing-spacing-large-padding-y": "5rem",
        "components-category-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-category-card-listing-spacing-large-card-margin-bottom": "0rem",
        "components-category-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-card-search-result-small-padding-top": "0rem",
        "components-card-search-result-small-padding-x": "0rem",
        "components-card-search-result-small-padding-y": "0rem",
        "components-card-search-result-small-padding-image-x": "0rem",
        "components-card-search-result-small-padding-image-y": "0rem",
        "components-card-search-result-small-padding-content-x": "1.5rem",
        "components-card-search-result-small-padding-content-y": "1.5rem",
        "components-card-search-result-spacing-large-padding-x": "0rem",
        "components-card-search-result-spacing-large-padding-y": "1.5rem",
        "components-card-search-result-spacing-large-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-image-margin-right": "1.5rem",
        "components-card-search-result-spacing-small-padding-x": "1.5rem",
        "components-card-search-result-spacing-small-padding-y": "1.5rem",
        "components-card-search-result-spacing-small-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-image-margin-right": "0.75rem",
        "components-contact-us-promo-spacing-large-padding-x": "5rem",
        "components-contact-us-promo-spacing-large-padding-y": "5rem",
        "components-contact-us-promo-spacing-large-content-margin-right": "5rem",
        "components-contact-us-promo-spacing-large-margin-y": "2.5rem",
        "components-contact-us-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-contact-us-promo-spacing-small-margin-y": "2.5rem",
        "components-content-list-list-item-container-padding-x": "0.75rem",
        "components-content-list-list-item-container-padding-y": "1rem",
        "components-content-list-list-item-content-space-between": "0.5rem",
        "components-content-list-list-item-content-default-padding-y": "0.25rem",
        "components-content-list-list-item-content-hyperlink-padding-y": "0rem",
        "components-content-list-spacing-large-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-large-item-title-margin-bottom": "1rem",
        "components-content-list-spacing-large-item-copy-margin-bottom": "2.5rem",
        "components-content-list-spacing-large-item-icon-copy-margin-top": "0rem",
        "components-content-list-spacing-large-item-margin-bottom": "2rem",
        "components-content-list-spacing-large-item-image-margin-bottom": "0.5rem",
        "components-content-list-spacing-large-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-y": "2.5rem",
        "components-content-list-spacing-small-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-small-item-title-margin-bottom": "0.5rem",
        "components-content-list-spacing-small-item-copy-margin-bottom": "1rem",
        "components-content-list-spacing-small-item-margin-bottom": "1.5rem",
        "components-content-list-spacing-small-header-title-margin-bottom": "1rem",
        "components-content-list-spacing-small-header-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-title-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-button-space-between": "1rem",
        "components-dropdown-item-padding-y": "0.75rem",
        "components-dropdown-item-padding-x": "0.75rem",
        "components-dropdown-item-spacing-x": "0.75rem",
        "components-dropdown-input-padding-y": "0.25rem",
        "components-dropdown-input-padding-x": "1rem",
        "components-footer-brand-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-large-column-margin-right": "4rem",
        "components-footer-brand-spacing-large-column-margin-bottom": "4rem",
        "components-footer-brand-spacing-large-link-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-padding-y": "2.5rem",
        "components-footer-brand-spacing-small-padding-x": "1rem",
        "components-footer-brand-spacing-small-logo-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-column-margin-right": "0.125rem",
        "components-footer-brand-spacing-small-column-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-small-link-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-title-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-text-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-footer-link-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-link-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-link-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-link-minheight": "3rem",
        "components-footer-brand-spacing-small-footer-category-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-category-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-category-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-category-minheight": "3rem",
        "components-footer-global-spacing-large-padding-y": "2.5rem",
        "components-footer-global-spacing-large-padding-x": "5rem",
        "components-footer-global-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-global-spacing-large-column-margin-right": "2rem",
        "components-footer-global-spacing-large-link-margin-bottom": "2rem",
        "components-footer-global-spacing-small-padding-y": "2.5rem",
        "components-footer-global-spacing-small-padding-x": "1rem",
        "components-footer-global-spacing-small-logo-margin-bottom": "1rem",
        "components-footer-global-spacing-small-column-margin-right": "1rem",
        "components-footer-global-spacing-small-link-margin-bottom": "1rem",
        "components-generic-card-spacing-large-padding-x": "0rem",
        "components-generic-card-spacing-large-padding-y": "0rem",
        "components-generic-card-spacing-large-padding-image-x": "0rem",
        "components-generic-card-spacing-large-padding-image-y": "0rem",
        "components-generic-card-spacing-large-padding-content-x": "2rem",
        "components-generic-card-spacing-large-padding-content-y": "2rem",
        "components-generic-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-generic-card-spacing-large-title-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-button-space-between": "1rem",
        "components-generic-card-spacing-small-padding-x": "0rem",
        "components-generic-card-spacing-small-padding-y": "0rem",
        "components-generic-card-spacing-small-padding-image-x": "0rem",
        "components-generic-card-spacing-small-padding-image-y": "0rem",
        "components-generic-card-spacing-small-padding-content-x": "1.5rem",
        "components-generic-card-spacing-small-padding-content-y": "1.5rem",
        "components-global-search-spacing-omnibox-padding-x": "0.125rem",
        "components-global-search-spacing-omnibox-list-padding-y": "0.5rem",
        "components-global-search-spacing-omnibox-label-padding": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-item-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-height": "3rem",
        "components-generic-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-generic-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-header-shadow-x": "0rem",
        "components-header-spacing-large-padding-y": "0.5rem",
        "components-header-spacing-large-padding-x": "1.5rem",
        "components-header-spacing-large-links-margin-right": "1.5rem",
        "components-header-spacing-large-icons-space-between": "1.5rem",
        "components-header-spacing-large-logo-margin-right": "2rem",
        "components-header-spacing-large-space-between-search": "0.5rem",
        "components-header-spacing-large-height": "5rem",
        "components-header-spacing-large-language-selector-padding-x": "1rem",
        "components-header-spacing-large-language-selector-padding-y": "0.5rem",
        "components-header-spacing-large-language-selector-space-between": "0.75rem",
        "components-header-spacing-large-language-selector-country-space-between": "0.5rem",
        "components-header-spacing-large-mega-menu-padding-y": "2.5rem",
        "components-header-spacing-large-mega-menu-padding-x": "2.5rem",
        "components-header-spacing-large-mega-menu-column-margin-right": "2.5rem",
        "components-header-spacing-large-mega-menu-category-link-margin-bottom": "1rem",
        "components-header-spacing-large-mega-menu-link-margin-bottom": "0.5rem",
        "components-header-spacing-large-mega-menu-featured-padding-x": "2rem",
        "components-header-spacing-large-mega-menu-featured-padding-y": "2rem",
        "components-header-spacing-large-mega-menu-featured-image-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-title-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-link-space-between": "1.5rem",
        "components-header-spacing-large-mega-menu-title-margin-bottom": "2.5rem",
        "components-header-spacing-large-dropdown-padding-y": "1.5rem",
        "components-header-spacing-large-dropdown-padding-x": "1.5rem",
        "components-header-spacing-large-dropdown-margin-bottom": "0.5rem",
        "components-header-spacing-small-language-selector-padding-x": "1rem",
        "components-header-spacing-small-language-selector-padding-y": "0.5rem",
        "components-header-spacing-small-language-selector-space-between": "0.5rem",
        "components-header-spacing-small-padding-left": "1rem",
        "components-header-spacing-small-padding-right": "1rem",
        "components-header-spacing-small-padding-top": "0rem",
        "components-header-spacing-small-padding-bottom": "0rem",
        "components-header-spacing-small-nav-main-minheight": "3rem",
        "components-header-spacing-small-nav-main-padding-y": "0.5rem",
        "components-header-spacing-small-nav-main-padding-left": "1.5rem",
        "components-header-spacing-small-nav-main-padding-right": "1rem",
        "components-header-spacing-small-nav-category-minheight": "3rem",
        "components-header-spacing-small-nav-category-padding-left": "1.5rem",
        "components-header-spacing-small-nav-category-padding-right": "0.75rem",
        "components-header-spacing-small-nav-category-padding-y": "0.75rem",
        "components-header-spacing-small-nav-category-space-between": "0rem",
        "components-header-spacing-small-nav-menu-link-minheight": "3rem",
        "components-header-spacing-small-nav-menu-link-padding-right": "1.5rem",
        "components-header-spacing-small-nav-menu-link-padding-left": "2.5rem",
        "components-header-spacing-small-nav-menu-link-padding-y": "0.75rem",
        "components-header-spacing-small-mega-menu-padding-y": "0rem",
        "components-header-spacing-small-mega-menu-padding-x": "0rem",
        "components-header-spacing-small-mega-menu-category-link-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-link-margin-bottom": "0rem",
        "components-header-spacing-small-mega-menu-featured-padding-x": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-padding-y": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-image-margin-bottom": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-title-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-featured-link-space-between": "0.75rem",
        "components-header-spacing-small-mega-menu-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-container-padding": "2.5rem",
        "components-hero-homepage-spacing-large-padding-x": "4rem",
        "components-hero-homepage-spacing-large-padding-y": "2.5rem",
        "components-hero-homepage-spacing-large-minheight": "34.3125rem",
        "components-hero-homepage-spacing-large-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-margin-bottom": "2rem",
        "components-hero-homepage-spacing-large-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-copy-margin-bottom": "2.5rem",
        "components-hero-homepage-spacing-large-cta-margin-right": "1rem",
        "components-hero-homepage-spacing-large-image-height": "34.3125rem",
        "components-hero-homepage-spacing-large-image-width": "45.75rem",
        "components-hero-homepage-spacing-small-padding-x": "0rem",
        "components-hero-homepage-spacing-small-padding-y": "1.5rem",
        "components-hero-homepage-spacing-small-minheight": "34.3125rem",
        "components-hero-homepage-spacing-small-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-copy-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-image-height": "17.5625rem",
        "components-hero-homepage-spacing-small-image-width": "45.75rem",
        "components-hero-internal-spacing-large-padding-y": "2.5rem",
        "components-hero-internal-spacing-large-padding-right": "10rem",
        "components-hero-internal-spacing-large-title-margin-bottom": "1.5rem",
        "components-hero-internal-spacing-large-button-padding-y": "2rem",
        "components-hero-internal-spacing-large-text-maxwidth": "59.625rem",
        "components-hero-internal-spacing-large-minheight": "18.5rem",
        "components-hero-internal-spacing-small-padding-x": "1.5rem",
        "components-hero-internal-spacing-small-padding-right": "0rem",
        "components-hero-internal-spacing-small-button-padding-y": "1.5rem",
        "components-hero-internal-spacing-small-text-maxwidth": "30rem",
        "components-hero-internal-spacing-small-minheight": "13.5rem",
        "components-icon-card-spacing-large-padding-x": "0rem",
        "components-icon-card-spacing-large-padding-y": "0rem",
        "components-icon-card-spacing-large-icon-margin": "1.5rem",
        "components-icon-card-spacing-large-margin-right": "1.5rem",
        "components-icon-card-spacing-large-title-margin-bottom": "1rem",
        "components-icon-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-icon-card-spacing-large-button-space-between": "1.5rem",
        "components-icon-card-spacing-large-top-space-between": "1rem",
        "components-icon-card-spacing-small-padding-x": "1.5rem",
        "components-icon-card-spacing-small-padding-y": "1.5rem",
        "components-icon-card-spacing-small-icon-margin": "1.5rem",
        "components-icon-card-spacing-small-margin-right": "1.5rem",
        "components-icon-card-spacing-small-title-margin-bottom": "1.5rem",
        "components-icon-card-dimensions-large-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-large-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-large-big-icon-height": "4rem",
        "components-icon-card-dimensions-large-big-icon-width": "4rem",
        "components-icon-card-dimensions-large-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-large-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-large-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-large-tiny-icon-width": "2.5rem",
        "components-icon-card-dimensions-small-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-small-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-small-big-icon-height": "3.5rem",
        "components-icon-card-dimensions-small-big-icon-width": "3.5rem",
        "components-icon-card-dimensions-small-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-small-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-small-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-small-tiny-icon-width": "2.5rem",
        "components-icon-card-listing-spacing-large-padding-inner-x": "4rem",
        "components-icon-card-listing-spacing-large-padding-inner-y": "4rem",
        "components-icon-card-listing-spacing-large-card-space-between": "4rem",
        "components-icon-card-listing-spacing-large-title-margin-bottom": "1.5rem",
        "components-icon-card-listing-spacing-large-card-margin-bottom": "4rem",
        "components-icon-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-x": "1.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-y": "2.5rem",
        "components-icon-card-listing-spacing-small-title-margin-bottom": "1rem",
        "components-icon-card-listing-spacing-small-button-margin-top": "1.5rem",
        "components-jumplink-link-padding-bottom": "0.5rem",
        "components-jumplink-spacing-small-title-area-margin-bottom": "1.5rem",
        "components-jumplink-spacing-small-link-container-padding-x": "0rem",
        "components-lightbox-spacing-large-carousel-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-carousel-space-between": "1.5rem",
        "components-lightbox-spacing-large-featured-image-height": "39.25rem",
        "components-lightbox-spacing-large-featured-image-width": "39.25rem",
        "components-lightbox-spacing-large-image-height": "17.0625rem",
        "components-lightbox-spacing-large-image-width": "17.0625rem",
        "components-lightbox-spacing-small-carousel-margin-bottom": "1rem",
        "components-lightbox-spacing-small-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-small-media-details-margin-top": "1rem",
        "components-lightbox-spacing-small-image-height": "20.4375rem",
        "components-lightbox-spacing-small-image-width": "20.4375rem",
        "components-legal-disclaimer-spacing-small-ruleline-width": "20.4375rem",
        "components-media-spacing-large-padding-y": "0.5rem",
        "components-media-spacing-large-padding-x": "0.75rem",
        "components-media-gallery-large-container-grid-gap-x": "1.5rem",
        "components-media-gallery-large-container-grid-gap-y": "1.5rem",
        "components-media-gallery-small-container-grid-gap-x": "0.5rem",
        "components-media-gallery-small-container-grid-gap-y": "0.5rem",
        "components-media-gallery-spacing-small-padding-x": "1.5rem",
        "components-media-gallery-spacing-small-padding-y": "2.5rem",
        "components-media-gallery-spacing-large-padding-x": "5rem",
        "components-media-gallery-spacing-large-padding-y": "5rem",
        "components-product-card-spacing-list-content-gap": "0rem",
        "components-product-card-spacing-large-padding-image-x": "0rem",
        "components-product-card-spacing-large-padding-image-y": "0rem",
        "components-product-card-spacing-large-padding-content-x": "1.5rem",
        "components-product-card-spacing-large-padding-content-y": "1.5rem",
        "components-product-card-spacing-large-category-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-title-margin-bottom": "1rem",
        "components-product-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-button-space-between": "1rem",
        "components-product-card-spacing-small-padding-image-x": "0rem",
        "components-product-card-spacing-small-padding-image-y": "0rem",
        "components-product-card-spacing-small-padding-content-x": "1.5rem",
        "components-product-card-spacing-small-padding-content-y": "1.5rem",
        "components-product-card-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-large-padding-x": "5rem",
        "components-product-information-spacing-large-padding-y": "2.5rem",
        "components-product-information-spacing-large-title-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-label-margin-bottom": "2.5rem",
        "components-product-information-spacing-large-ratings-margin-bottom": "1rem",
        "components-product-information-spacing-large-details-margin-bottom": "1rem",
        "components-product-information-spacing-large-space-between": "1rem",
        "components-product-information-spacing-large-carousel-space-between": "5rem",
        "components-product-information-spacing-large-button-margin-y": "0.5rem",
        "components-product-information-spacing-large-body-margin-top": "1.5rem",
        "components-product-information-spacing-large-image-space-between": "1rem",
        "components-product-information-spacing-large-image-margin-bottom": "1rem",
        "components-product-information-spacing-small-padding-x": "1.5rem",
        "components-product-information-spacing-small-padding-y": "1.5rem",
        "components-product-information-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-small-label-margin-bottom": "1.5rem",
        "components-product-information-spacing-small-ratings-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-details-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-space-between": "0.75rem",
        "components-product-information-spacing-small-carousel-space-between": "2.5rem",
        "components-product-information-spacing-small-button-margin-y": "0.75rem",
        "components-product-information-spacing-small-body-margin-top": "1rem",
        "components-product-information-spacing-small-image-space-between": "0.75rem",
        "components-product-information-spacing-small-image-margin-bottom": "1rem",
        "components-product-results-list-with-search-spacing-large-padding-x": "5rem",
        "components-product-results-list-with-search-spacing-large-padding-y": "5rem",
        "components-product-results-list-with-search-spacing-large-ruleline-margin-top": "2.5rem",
        "components-product-results-list-with-search-spacing-large-filter-space-between": "1rem",
        "components-product-results-list-with-search-spacing-large-card-space-between": "1.5rem",
        "components-product-results-list-with-search-spacing-large-card-container-margin-bottom": "5rem",
        "components-product-results-list-with-search-spacing-small-padding-x": "1.5rem",
        "components-product-results-list-with-search-spacing-small-padding-y": "2.5rem",
        "components-product-results-list-with-search-spacing-small-ruleline-margin-top": "1rem",
        "components-product-results-list-with-search-spacing-small-filter-padding-x": "0.5rem",
        "components-product-results-list-with-search-spacing-small-card-space-between": "1rem",
        "components-product-results-list-with-search-spacing-small-card-margin-bottom": "1.5rem",
        "components-promo-spacing-large-padding-x": "4rem",
        "components-promo-spacing-large-padding-y": "4rem",
        "components-promo-spacing-large-content-space-between": "3rem",
        "components-promo-spacing-large-content-space-between-y": "3rem",
        "components-promo-spacing-large-padding-content-x": "0rem",
        "components-promo-spacing-large-padding-content-condensed-x": "0rem",
        "components-promo-spacing-large-padding-content-y": "0rem",
        "components-promo-spacing-large-padding-content-condensed-y": "0rem",
        "components-promo-spacing-large-padding-image-x": "1.5rem",
        "components-promo-spacing-large-padding-image-y": "1.5rem",
        "components-promo-spacing-large-padding-image-top": "5rem",
        "components-promo-spacing-large-title-margin-bottom": "2rem",
        "components-promo-spacing-large-margin-y": "2rem",
        "components-promo-spacing-large-buttons-space-between": "1rem",
        "components-promo-spacing-small-padding-x": "0rem",
        "components-promo-spacing-small-padding-y": "0rem",
        "components-promo-spacing-small-padding-top": "0rem",
        "components-promo-spacing-small-padding-content-x": "1.5rem",
        "components-promo-spacing-small-padding-content-y": "2.5rem",
        "components-promo-spacing-small-padding-image-x": "1rem",
        "components-promo-spacing-small-padding-image-y": "0rem",
        "components-promo-spacing-small-padding-image-top": "1rem",
        "components-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-promo-spacing-small-margin-y": "2.5rem",
        "components-promo-minheight": "30rem",
        "components-pull-quote-spacing-large-padding-quote-x": "4rem",
        "components-pull-quote-spacing-large-padding-quote-y": "4rem",
        "components-pull-quote-spacing-large-title-margin-bottom": "2rem",
        "components-pull-quote-spacing-large-quote-margin-bottom": "4rem",
        "components-pull-quote-spacing-large-quote-space-between": "1.5rem",
        "components-pull-quote-spacing-large-detail-space-between": "0.5rem",
        "components-pull-quote-spacing-large-quote-minheight": "25rem",
        "components-pull-quote-spacing-small-padding-quote-x": "1.5rem",
        "components-pull-quote-spacing-small-padding-quote-y": "2.5rem",
        "components-pull-quote-spacing-small-title-margin-bottom": "1.5rem",
        "components-pull-quote-spacing-small-quote-margin-bottom": "2.5rem",
        "components-pull-quote-spacing-small-quote-space-between": "1rem",
        "components-pull-quote-spacing-small-detail-space-between": "1rem",
        "components-pull-quote-spacing-small-quote-minheight": "15rem",
        "components-promo-alternate-minheight": "30rem",
        "components-promo-alternate-spacing-large-padding-x": "4rem",
        "components-promo-alternate-spacing-large-padding-y": "4rem",
        "components-promo-alternate-spacing-large-content-space-between": "3rem",
        "components-promo-alternate-spacing-large-padding-content-x": "0rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-x": "0rem",
        "components-promo-alternate-spacing-large-padding-content-y": "0rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-y": "0rem",
        "components-promo-alternate-spacing-large-padding-image-x": "1.5rem",
        "components-promo-alternate-spacing-large-padding-image-y": "1.5rem",
        "components-promo-alternate-spacing-large-padding-image-top": "5rem",
        "components-promo-alternate-spacing-large-title-margin-bottom": "2rem",
        "components-promo-alternate-spacing-small-padding-x": "0rem",
        "components-promo-alternate-spacing-small-padding-y": "0rem",
        "components-promo-alternate-spacing-small-padding-content-x": "1.5rem",
        "components-promo-alternate-spacing-small-padding-content-y": "2.5rem",
        "components-promo-alternate-spacing-small-padding-image-x": "1rem",
        "components-promo-alternate-spacing-small-padding-image-y": "0rem",
        "components-promo-alternate-spacing-small-padding-image-top": "1rem",
        "components-promo-alternate-spacing-small-title-margin-bottom": "1.5rem",
        "components-share-list-spacing-padding-x": "1rem",
        "components-share-list-spacing-padding-y": "1rem",
        "components-header-copy-margin-bottom": "1rem",
        "components-header-margin-bottom": "1.5rem",
        "components-item-margin-bottom": "1.5rem",
        "components-site-search-padding-x": "2.5rem",
        "components-site-search-padding-y": "5rem",
        "components-site-search-facet-label-space-between": "0.25rem",
        "components-site-search-refine-padding-x": "1.5rem",
        "components-site-search-refine-padding-y": "0rem",
        "components-site-search-hero-search-height": "18.5rem",
        "components-site-search-hero-search-large-padding-x": "5rem",
        "components-site-search-hero-search-large-padding-y": "5rem",
        "components-stats-spacing-stat-text-space-between": "0.5rem",
        "components-stats-spacing-large-inner-padding-x": "5rem",
        "components-stats-spacing-large-inner-padding-y": "5rem",
        "components-stats-spacing-small-inner-padding-x": "1.5rem",
        "components-stats-spacing-small-inner-padding-y": "1.5rem",
        "components-icon-card-listing-spacing-small-top-space-between": "1rem",
        "components-supporting-text-padding-x": "0rem",
        "components-supporting-text-padding-bottom": "0.5rem",
        "components-tab-item-padding-y": "0.75rem",
        "components-tab-item-padding-x": "0.75rem",
        "components-tab-item-spacing-x": "0.75rem",
        "components-tab-input-padding-y": "0.25rem",
        "components-tab-input-padding-x": "1rem",
        "components-tag-spacing-padding-x": "0.5rem",
        "components-tag-spacing-padding-y": "0.5rem",
        "components-tag-spacing-space-between": "0.5rem",
        "components-text-field-input-padding-y": "0.25rem",
        "components-text-field-input-padding-x": "1rem",
        "components-text-field-area-input-padding-y": "1rem",
        "components-text-field-area-input-padding-x": "1rem",
        "components-text-field-supporting-text-padding-x": "0rem",
        "components-text-field-supporting-text-padding-top": "0.5rem",
        "themes-general-surface-spacing-large-padding-x": "4rem",
        "themes-general-surface-spacing-large-padding-y": "4rem",
        "themes-general-surface-spacing-large-title-margin-bottom": "2rem",
        "themes-general-surface-spacing-large-body-margin-right": "4rem",
        "themes-general-surface-spacing-large-body-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-large-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-large-cards-space-between": "1.5rem",
        "themes-general-surface-spacing-large-button-margin-top": "2.5rem",
        "themes-general-surface-spacing-small-padding-x": "1.5rem",
        "themes-general-surface-spacing-small-padding-y": "2.5rem",
        "themes-general-surface-spacing-small-title-margin-bottom": "1rem",
        "themes-general-surface-spacing-small-body-margin-right": "2.5rem",
        "themes-general-surface-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-surface-spacing-small-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-small-cards-space-between": "1rem",
        "themes-general-surface-spacing-small-button-margin-top": "1.5rem",
        "themes-general-card-spacing-large-eyebrow-margin-bottom": "1.5rem",
        "themes-general-card-spacing-large-title-margin-bottom": "1.5rem",
        "themes-general-card-spacing-large-body-margin-bottom": "2rem",
        "themes-general-card-spacing-large-button-space-between": "1rem",
        "themes-general-card-spacing-small-eyebrow-margin-bottom": "1.5rem",
        "themes-general-card-spacing-small-title-margin-bottom": "1rem",
        "themes-general-card-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-small-button-space-between": "1rem",
        "themes-shadow-share-list-spacing-x": "0rem",
        "themes-shadow-share-list-spacing-y": "0.75rem",
        "themes-shadow-share-list-spacing-blur": "1rem",
        "themes-shadow-share-list-spacing-spread": "-0.5rem",
        "themes-shadow-header-menu-spacing-x": "0rem",
        "themes-shadow-header-menu-spacing-y": "0.75rem",
        "themes-shadow-header-menu-spacing-blur": "1rem",
        "themes-shadow-header-menu-spacing-spread": "-0.5rem",
        "themes-typography-rte-large-h1-textsize": "3rem",
        "themes-typography-rte-large-h2-textsize": "2.5rem",
        "themes-typography-rte-large-h3-textsize": "2.25rem",
        "themes-typography-rte-large-h4-textsize": "2rem",
        "themes-typography-rte-large-h5-textsize": "1.5rem",
        "themes-typography-rte-large-h6-textsize": "1.25rem",
        "themes-typography-rte-medium-h1-textsize": "2.5rem",
        "themes-typography-rte-medium-h2-textsize": "2.25rem",
        "themes-typography-rte-medium-h3-textsize": "1.75rem",
        "themes-typography-rte-medium-h4-textsize": "1.5rem",
        "themes-typography-rte-medium-h5-textsize": "1.25rem",
        "themes-typography-rte-medium-h6-textsize": "1.125rem",
        "themes-typography-rte-small-h1-textsize": "2.5rem",
        "themes-typography-rte-small-h2-textsize": "2rem",
        "themes-typography-rte-small-h3-textsize": "1.75rem",
        "themes-typography-rte-small-h4-textsize": "1.5rem",
        "themes-typography-rte-small-h5-textsize": "1.125rem",
        "themes-typography-rte-small-h6-textsize": "1rem",
        "spacing-margin-small-1": "0.125rem",
        "spacing-margin-small-2": "0.25rem",
        "spacing-margin-small-3": "0.5rem",
        "spacing-margin-small-4": "0.75rem",
        "spacing-margin-small-5": "1rem",
        "spacing-margin-small-6": "1.5rem",
        "spacing-margin-small-7": "2.5rem",
        "spacing-margin-medium-2": "0.75rem",
        "spacing-margin-medium-4": "1.5rem",
        "spacing-margin-medium-5": "2rem",
        "spacing-margin-large-1": "0.5rem",
        "spacing-margin-large-2": "1rem",
        "spacing-margin-large-3": "1.5rem",
        "spacing-margin-large-4": "2rem",
        "spacing-margin-large-5": "2.5rem",
        "spacing-margin-large-6": "4rem",
        "spacing-margin-large-7": "5rem",
        "spacing-space-between-small-1": "0.125rem",
        "spacing-space-between-small-2": "0.25rem",
        "spacing-space-between-small-3": "0.5rem",
        "spacing-space-between-small-4": "0.75rem",
        "spacing-space-between-small-5": "1rem",
        "spacing-space-between-small-6": "1.5rem",
        "spacing-space-between-small-7": "2.5rem",
        "spacing-space-between-medium-1": "0.125rem",
        "spacing-space-between-medium-2": "0.25rem",
        "spacing-space-between-medium-3": "0.5rem",
        "spacing-space-between-medium-4": "0.75rem",
        "spacing-space-between-medium-5": "1rem",
        "spacing-space-between-large-1": "0.25rem",
        "spacing-space-between-large-2": "0.5rem",
        "spacing-space-between-large-3": "1rem",
        "spacing-space-between-large-4": "1.5rem",
        "spacing-space-between-large-5": "1.5rem",
        "spacing-space-between-large-6": "2.5rem",
        "spacing-space-between-large-7": "4rem",
        "spacing-padding-small-1": "0.125rem",
        "spacing-padding-small-2": "0.25rem",
        "spacing-padding-small-3": "0.5rem",
        "spacing-padding-small-4": "0.75rem",
        "spacing-padding-small-5": "1rem",
        "spacing-padding-small-6": "1.5rem",
        "spacing-padding-small-7": "2.5rem",
        "spacing-padding-medium-1": "0.25rem",
        "spacing-padding-medium-2": "0.75rem",
        "spacing-padding-medium-3": "1rem",
        "spacing-padding-medium-4": "1.5rem",
        "spacing-padding-medium-5": "2rem",
        "spacing-padding-medium-6": "3rem",
        "spacing-padding-medium-7": "4rem",
        "spacing-padding-large-1": "0.5rem",
        "spacing-padding-large-2": "1rem",
        "spacing-padding-large-3": "1.5rem",
        "spacing-padding-large-4": "2rem",
        "spacing-padding-large-5": "2.5rem",
        "spacing-padding-large-6": "4rem",
        "spacing-padding-large-7": "5rem"
      },
      "string": {
        "components-accordion-section-button": "var(--brand)",
        "components-article-card-listing-button-theme": "var(--brand)",
        "components-article-hero-pagination-buttons": "var(--brand)",
        "components-banner-banner-button": "var(--white)",
        "components-category-card-listing-button-theme": "var(--brand)",
        "components-contact-us-promo-hierarchy-button-theme-1": "var(--filled)",
        "components-contact-us-promo-hierarchy-button-theme-2": "var(--outlined)",
        "components-contact-us-promo-color-button-theme": "var(--brand)",
        "components-contact-us-promo-color-brand-button-theme": "var(--brand)",
        "components-generic-card-button-theme": "var(--brand)",
        "components-global-search-loader": "var(--ziploc)",
        "components-generic-card-listing-decor-top": "var(--raid-1)",
        "components-generic-card-listing-decor-bottom": "var(--raid-2)",
        "components-hero-homepage-pagination-buttons": "var(--brand)",
        "components-hero-homepage-pattern": "var(--default)",
        "components-hero-homepage-button-1": "var(--brand)",
        "components-hero-homepage-button-2": "var(--brand)",
        "components-hero-homepage-image-ratio": "var(--4:3)",
        "components-hero-internal-theme-button-one-type": "var(--filled)",
        "components-hero-internal-theme-button-two-type": "var(--outlined)",
        "components-hero-internal-theme-button-color": "var(--brand)",
        "components-hero-internal-theme-button-color-with-image": "var(--brand)",
        "components-lightbox-button-type": "var(--brand)",
        "components-pdf-link-card-hierarchy-button-theme-1": "var(--filled)",
        "components-pdf-link-card-hierarchy-button-theme-2": "var(--outlined)",
        "components-pdf-link-card-color-button-theme": "var(--brand)",
        "components-product-card-badge-align": "var(--left)",
        "components-product-card-listing-button-color": "var(--brand)",
        "components-promo-theme-button-one-theme": "var(--filled)",
        "components-promo-theme-button-two-theme": "var(--outlined)",
        "components-promo-color-default-button-theme": "var(--brand)",
        "components-promo-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-color-brand-button-theme": "var(--brand)",
        "components-pull-quote-border-decor": "var(--duck)",
        "components-promo-alternate-theme-button-one-theme": "var(--filled)",
        "components-promo-alternate-theme-button-two-theme": "var(--outlined)",
        "components-promo-alternate-color-default-button-theme": "var(--brand)",
        "components-promo-alternate-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-alternate-color-brand-button-theme": "var(--brand)",
        "components-share-list-button-color": "var(--brand)",
        "components-site-search-hero-search-hero-pattern": "var(--none)",
        "components-stats-decor-top": "var(--raid-1)",
        "components-stats-decor-bottom": "var(--raid-2)",
        "themes-brand-loader": "var(--default)",
        "themes-brand-variants": "var(--windex)",
        "themes-tab-variant": "var(--default)",
        "themes-label-variants": "var(--default)",
        "themes-logo": "var(--windex)",
        "themes-category-card": "var(--muscle)",
        "themes-title-decor": "var(--off)",
        "themes-typography-heading-fontfamily": "var(--gotham)",
        "themes-typography-heading-large-xxlarge-fontweight": "var(--black)",
        "themes-typography-heading-large-xlarge-fontweight": "var(--black)",
        "themes-typography-heading-large-large-fontweight": "var(--black)",
        "themes-typography-heading-large-medium-fontweight": "var(--black)",
        "themes-typography-heading-large-small-fontweight": "var(--black)",
        "themes-typography-heading-large-xsmall-fontweight": "var(--black)",
        "themes-typography-heading-large-xxsmall-fontweight": "var(--black)",
        "themes-typography-heading-medium-xxlarge-fontweight": "var(--black)",
        "themes-typography-heading-medium-xlarge-fontweight": "var(--black)",
        "themes-typography-heading-medium-large-fontweight": "var(--black)",
        "themes-typography-heading-medium-medium-fontweight": "var(--black)",
        "themes-typography-heading-medium-small-fontweight": "var(--black)",
        "themes-typography-heading-medium-xsmall-fontweight": "var(--black)",
        "themes-typography-heading-medium-xxsmall-fontweight": "var(--black)",
        "themes-typography-heading-small-xxlarge-fontweight": "var(--black)",
        "themes-typography-heading-small-xlarge-fontweight": "var(--black)",
        "themes-typography-heading-small-large-fontweight": "var(--black)",
        "themes-typography-heading-small-medium-fontweight": "var(--black)",
        "themes-typography-heading-small-small-fontweight": "var(--black)",
        "themes-typography-heading-small-xsmall-fontweight": "var(--black)",
        "themes-typography-heading-small-xxsmall-fontweight": "var(--black)",
        "themes-typography-rte-fontfamily": "var(--gotham)",
        "themes-typography-rte-fontweight": "var(--black)"
      },
      "borderRadius": {
        "components-accordion-spacing-large-item-item-radius": "0rem",
        "components-article-card-spacing-large-image-radius-tl": "1rem",
        "components-article-card-spacing-large-image-radius-tr": "1rem",
        "components-article-card-spacing-large-image-radius-bl": "0rem",
        "components-article-card-spacing-large-image-radius-br": "0rem",
        "components-article-card-spacing-large-list-image-radius-br": "1rem",
        "components-article-card-spacing-small-image-radius-br": "0rem",
        "components-article-card-spacing-small-list-image-radius-br": "0rem",
        "components-article-hero-spacing-large-content-radius": "1rem",
        "components-article-hero-spacing-small-content-radius": "1rem",
        "components-button-radius-large": "7.5rem",
        "components-button-group-spacing-border-radius": "7.5rem",
        "components-category-card-radius-border-width": "0.125rem",
        "components-category-card-radius-border-radius": "7.5rem",
        "components-category-card-radius-border-radius-condensed": "0.5rem",
        "components-category-card-radius-media-radius": "0rem",
        "components-content-list-icon-radius": "7.5rem",
        "components-fab-spacing-border-radius": "7.5rem",
        "components-header-spacing-large-search-border-radius-top": "0.25rem",
        "components-header-spacing-large-search-border-radius-bottom": "0.25rem",
        "components-header-spacing-large-language-selector-radius": "0.25rem",
        "components-header-spacing-large-dropdown-radius": "0rem",
        "components-hero-homepage-spacing-large-container-radius": "1rem",
        "components-hero-internal-spacing-large-icon-radius": "7.5rem",
        "components-hero-internal-spacing-small-icon-radius": "7.5rem",
        "components-lightbox-spacing-large-overlay-radius": "1rem",
        "components-pagination-scrollbar-radius": "62.5rem",
        "components-product-card-spacing-image-radius-tl": "0rem",
        "components-product-card-spacing-image-radius-tr": "0rem",
        "components-product-card-spacing-image-radius-bl": "0rem",
        "components-product-card-spacing-image-radius-br": "0rem",
        "components-product-information-spacing-large-content-radius": "1rem",
        "components-product-information-spacing-small-content-radius": "1rem",
        "components-promo-spacing-large-content-radius": "1rem",
        "components-promo-spacing-large-content-radius-tl": "1rem",
        "components-promo-spacing-large-content-radius-tr": "1rem",
        "components-promo-spacing-large-content-radius-bl": "1rem",
        "components-promo-spacing-large-content-radius-br": "1rem",
        "components-promo-spacing-large-image-radius-tl": "1rem",
        "components-promo-spacing-large-image-radius-tr": "1rem",
        "components-promo-spacing-large-image-radius-bl": "1rem",
        "components-promo-spacing-large-image-radius-br": "1rem",
        "components-promo-spacing-small-content-radius": "0.75rem",
        "components-promo-spacing-small-image-radius-tl": "0.75rem",
        "components-promo-spacing-small-image-radius-tr": "0.75rem",
        "components-promo-spacing-small-image-radius-bl": "0.75rem",
        "components-promo-spacing-small-image-radius-br": "0.75rem",
        "components-promo-alternate-spacing-large-content-radius": "1rem",
        "components-promo-alternate-spacing-large-image-radius-tl": "1rem",
        "components-promo-alternate-spacing-large-image-radius-tr": "1rem",
        "components-promo-alternate-spacing-large-image-radius-bl": "1rem",
        "components-promo-alternate-spacing-large-image-radius-br": "1rem",
        "components-promo-alternate-spacing-small-content-radius": "0.75rem",
        "components-promo-alternate-spacing-small-image-radius-tl": "0.75rem",
        "components-promo-alternate-spacing-small-image-radius-tr": "0.75rem",
        "components-promo-alternate-spacing-small-image-radius-bl": "0.75rem",
        "components-promo-alternate-spacing-small-image-radius-br": "0.75rem",
        "components-share-list-spacing-radius": "0.5rem",
        "components-share-list-spacing-link-radius": "0.5rem",
        "components-site-search-filter-radius": "0.5rem",
        "components-site-search-facet-radius": "0rem",
        "components-site-search-refine-radius": "0rem",
        "components-tag-spacing-radius": "7.5rem",
        "components-tip-spacing-radius": "0.75rem",
        "themes-spacing-radius-pagination-bar": "62.5rem",
        "themes-spacing-radius-large-button": "7.5rem",
        "themes-spacing-radius-large-button-icon": "7.5rem",
        "themes-spacing-radius-large-image": "1rem",
        "themes-spacing-radius-large-image-hero": "0rem",
        "themes-spacing-radius-large-image-card": "0.75rem",
        "themes-spacing-radius-large-card": "1rem",
        "themes-spacing-radius-large-icon": "7.5rem",
        "themes-spacing-radius-large-label": "7.5rem",
        "themes-spacing-radius-large-tag": "7.5rem",
        "themes-spacing-radius-large-container-tip": "0.75rem",
        "themes-spacing-radius-large-badge": "0.25rem",
        "themes-spacing-radius-large-item": "0.5rem",
        "themes-spacing-radius-large-form-field-input": "0.25rem",
        "themes-spacing-radius-large-form-area-input": "0.25rem",
        "themes-spacing-radius-large-general": "1rem",
        "themes-spacing-radius-small-button": "7.5rem",
        "themes-spacing-radius-small-button-icon": "7.5rem",
        "themes-spacing-radius-small-image": "0.75rem",
        "themes-spacing-radius-small-image-card": "0.75rem",
        "themes-spacing-radius-small-card": "0.75rem",
        "themes-spacing-radius-small-icon": "7.5rem",
        "themes-spacing-radius-small-label": "7.5rem",
        "themes-spacing-radius-small-tag": "7.5rem",
        "themes-spacing-radius-small-badge": "0rem",
        "themes-spacing-radius-small-item": "0rem",
        "themes-spacing-radius-small-form-field-input": "0.25rem",
        "themes-spacing-radius-small-general": "1rem"
      },
      "borderWidth": {
        "3": "3px",
        "components-article-card-spacing-large-border-width": "0.0625rem",
        "components-generic-card-color-border-width": "0rem",
        "components-header-spacing-large-search-border-width-top": "0.125rem",
        "components-header-spacing-large-search-border-width-right": "0.125rem",
        "components-header-spacing-large-search-border-width-bottom": "0.125rem",
        "components-header-spacing-large-search-border-width-left": "0.125rem",
        "components-product-card-spacing-border-width": "0rem"
      },
      "maxHeight": {},
      "maxWidth": {
        "components-article-hero-spacing-large-header-max-width": "66.375rem",
        "components-content-list-spacing-large-item-image-max-width": "33.75rem",
        "components-content-list-spacing-small-item-image-max-width": "33.75rem"
      },
      "minHeight": {
        "components-dropdown-item-min-height": "3rem",
        "components-icon-min-height": "3rem",
        "components-tab-item-min-height": "3rem",
        "components-text-field-input-min-height": "3rem",
        "components-text-field-icon-min-height": "3rem"
      },
      "minWidth": {
        "components-content-list-spacing-small-item-image-min-width": "18.75rem",
        "components-icon-min-width": "3rem",
        "components-text-field-icon-min-width": "3rem"
      },
      "opacity": {
        "components-accessibility-bar-color-bg": 1,
        "components-accessibility-bar-color-text": 1,
        "components-accordion-color-bg": 1,
        "components-accordion-color-title": 1,
        "components-accordion-color-body": 1,
        "components-accordion-color-section-section-bg": 1,
        "components-accordion-color-section-section-title": 1,
        "components-accordion-color-section-section-body": 1,
        "components-accordion-color-item-item-bg": 1,
        "components-accordion-color-item-divider": 1,
        "components-accordion-color-item-icon-icon-default": 1,
        "components-accordion-color-item-icon-icon-hover": 1,
        "components-accordion-color-item-icon-icon-pressed": 1,
        "components-accordion-color-item-icon-icon-active": 1,
        "components-accordion-color-item-title-item-default": 1,
        "components-accordion-color-item-title-item-hover": 1,
        "components-accordion-color-item-title-item-pressed": 1,
        "components-accordion-color-item-title-item-active": 1,
        "components-accordion-color-content-bg": 1,
        "components-accordion-color-content-title": 1,
        "components-accordion-color-content-body": 1,
        "components-article-card-color-bg": 1,
        "components-article-card-color-border": 1,
        "components-article-card-color-content-bg": 1,
        "components-article-card-color-title": 1,
        "components-article-card-color-subtitle": 1,
        "components-article-card-color-copy": 1,
        "components-article-card-color-description": 1,
        "components-article-card-listing-color-default-bg": 1,
        "components-article-card-listing-color-default-title": 1,
        "components-article-card-listing-color-default-copy": 1,
        "components-article-hero-color-content-bg": 1,
        "components-article-hero-color-bg": 1,
        "components-article-hero-color-headline": 1,
        "components-article-hero-color-body": 1,
        "components-article-hero-color-detail-text": 1,
        "components-article-hero-color-divider": 1,
        "components-article-hero-color-icon": 1,
        "components-article-hero-color-play-icon": 1,
        "components-article-hero-color-selected-stroke": 1,
        "components-banner-color-bg-default": 1,
        "components-banner-color-icon-default": 1,
        "components-banner-color-title-default": 1,
        "components-banner-color-body-default": 1,
        "components-banner-color-icon-close-default": 1,
        "components-banner-color-bg-alert": 1,
        "components-banner-color-icon-alert": 1,
        "components-banner-color-title-alert": 1,
        "components-banner-color-body-alert": 1,
        "components-banner-color-icon-close-alert": 1,
        "components-breadcrumb-color-bg": 1,
        "components-breadcrumb-color-breadcrumb-link-default": 1,
        "components-breadcrumb-color-breadcrumb-link-hover": 1,
        "components-breadcrumb-color-breadcrumb-link-active": 1,
        "components-breadcrumb-color-icon-divider": 1,
        "components-bug-finder-prompt": 1,
        "components-button-color-filled-brand-default-bg": 1,
        "components-button-color-filled-brand-default-text": 1,
        "components-button-color-filled-brand-default-stroke": 1,
        "components-button-color-filled-brand-default-icon": 1,
        "components-button-color-filled-brand-hover-bg": 1,
        "components-button-color-filled-brand-hover-text": 1,
        "components-button-color-filled-brand-hover-stroke": 1,
        "components-button-color-filled-brand-hover-icon": 1,
        "components-button-color-filled-brand-pressed-bg": 1,
        "components-button-color-filled-brand-pressed-text": 1,
        "components-button-color-filled-brand-pressed-stroke": 1,
        "components-button-color-filled-brand-pressed-icon": 1,
        "components-button-color-filled-brand-disabled-bg": null,
        "components-button-color-filled-brand-disabled-text": 0.02,
        "components-button-color-filled-brand-disabled-stroke": null,
        "components-button-color-filled-brand-disabled-icon": 0.02,
        "components-button-color-filled-brand-focus-bg": 1,
        "components-button-color-filled-brand-focus-text": 1,
        "components-button-color-filled-brand-focus-stroke": 1,
        "components-button-color-filled-brand-focus-icon": 1,
        "components-button-color-filled-brand-focus-outline": 1,
        "components-button-color-filled-tonal-default-bg": 1,
        "components-button-color-filled-tonal-default-text": 1,
        "components-button-color-filled-tonal-default-stroke": 1,
        "components-button-color-filled-tonal-default-icon": 1,
        "components-button-color-filled-tonal-hover-bg": 1,
        "components-button-color-filled-tonal-hover-text": 1,
        "components-button-color-filled-tonal-hover-stroke": 1,
        "components-button-color-filled-tonal-hover-icon": 1,
        "components-button-color-filled-tonal-pressed-bg": 1,
        "components-button-color-filled-tonal-pressed-text": 1,
        "components-button-color-filled-tonal-pressed-stroke": 1,
        "components-button-color-filled-tonal-pressed-icon": 1,
        "components-button-color-filled-tonal-disabled-bg": 0.02,
        "components-button-color-filled-tonal-disabled-text": null,
        "components-button-color-filled-tonal-disabled-stroke": 0.02,
        "components-button-color-filled-tonal-disabled-icon": null,
        "components-button-color-filled-tonal-focus-bg": 1,
        "components-button-color-filled-tonal-focus-text": 1,
        "components-button-color-filled-tonal-focus-stroke": 1,
        "components-button-color-filled-tonal-focus-icon": 1,
        "components-button-color-filled-tonal-focus-outline": 1,
        "components-button-color-filled-white-default-bg": 1,
        "components-button-color-filled-white-default-text": 1,
        "components-button-color-filled-white-default-stroke": 1,
        "components-button-color-filled-white-default-icon": 1,
        "components-button-color-filled-white-hover-bg": 1,
        "components-button-color-filled-white-hover-text": 1,
        "components-button-color-filled-white-hover-stroke": 1,
        "components-button-color-filled-white-hover-icon": 1,
        "components-button-color-filled-white-pressed-bg": 1,
        "components-button-color-filled-white-pressed-text": 1,
        "components-button-color-filled-white-pressed-stroke": 1,
        "components-button-color-filled-white-pressed-icon": 1,
        "components-button-color-filled-white-disabled-bg": 0.02,
        "components-button-color-filled-white-disabled-text": null,
        "components-button-color-filled-white-disabled-stroke": 0.02,
        "components-button-color-filled-white-disabled-icon": null,
        "components-button-color-filled-white-focus-bg": 1,
        "components-button-color-filled-white-focus-text": 1,
        "components-button-color-filled-white-focus-stroke": 1,
        "components-button-color-filled-white-focus-icon": 1,
        "components-button-color-filled-white-focus-outline": 1,
        "components-button-color-outline-brand-default-bg": 0.02,
        "components-button-color-outline-brand-default-text": 1,
        "components-button-color-outline-brand-default-stroke": 1,
        "components-button-color-outline-brand-default-icon": 1,
        "components-button-color-outline-brand-hover-bg": 1,
        "components-button-color-outline-brand-hover-text": 1,
        "components-button-color-outline-brand-hover-stroke": 1,
        "components-button-color-outline-brand-hover-icon": 1,
        "components-button-color-outline-brand-pressed-bg": 1,
        "components-button-color-outline-brand-pressed-text": 1,
        "components-button-color-outline-brand-pressed-stroke": 1,
        "components-button-color-outline-brand-pressed-icon": 1,
        "components-button-color-outline-brand-disabled-bg": 0.02,
        "components-button-color-outline-brand-disabled-text": null,
        "components-button-color-outline-brand-disabled-stroke": null,
        "components-button-color-outline-brand-disabled-icon": null,
        "components-button-color-outline-brand-focus-bg": 1,
        "components-button-color-outline-brand-focus-text": 1,
        "components-button-color-outline-brand-focus-stroke": 1,
        "components-button-color-outline-brand-focus-icon": 1,
        "components-button-color-outline-brand-focus-outline": 1,
        "components-button-color-outline-tonal-default-bg": 0.02,
        "components-button-color-outline-tonal-default-text": 1,
        "components-button-color-outline-tonal-default-stroke": 1,
        "components-button-color-outline-tonal-default-icon": 1,
        "components-button-color-outline-tonal-hover-bg": 1,
        "components-button-color-outline-tonal-hover-text": 1,
        "components-button-color-outline-tonal-hover-stroke": 1,
        "components-button-color-outline-tonal-hover-icon": 1,
        "components-button-color-outline-tonal-pressed-bg": 1,
        "components-button-color-outline-tonal-pressed-text": 1,
        "components-button-color-outline-tonal-pressed-stroke": 1,
        "components-button-color-outline-tonal-pressed-icon": 1,
        "components-button-color-outline-tonal-disabled-bg": 0.02,
        "components-button-color-outline-tonal-disabled-text": null,
        "components-button-color-outline-tonal-disabled-stroke": 0.02,
        "components-button-color-outline-tonal-disabled-icon": null,
        "components-button-color-outline-tonal-focus-bg": 0.02,
        "components-button-color-outline-tonal-focus-text": 1,
        "components-button-color-outline-tonal-focus-stroke": 1,
        "components-button-color-outline-tonal-focus-icon": 1,
        "components-button-color-outline-tonal-focus-outline": 1,
        "components-button-color-outline-white-default-bg": 0.02,
        "components-button-color-outline-white-default-text": 1,
        "components-button-color-outline-white-default-stroke": 1,
        "components-button-color-outline-white-default-icon": 1,
        "components-button-color-outline-white-hover-bg": 1,
        "components-button-color-outline-white-hover-text": 1,
        "components-button-color-outline-white-hover-stroke": 1,
        "components-button-color-outline-white-hover-icon": 1,
        "components-button-color-outline-white-pressed-bg": 1,
        "components-button-color-outline-white-pressed-text": 1,
        "components-button-color-outline-white-pressed-stroke": 1,
        "components-button-color-outline-white-pressed-icon": 1,
        "components-button-color-outline-white-disabled-bg": 0.02,
        "components-button-color-outline-white-disabled-text": 0.02,
        "components-button-color-outline-white-disabled-stroke": 0.02,
        "components-button-color-outline-white-disabled-icon": 0.02,
        "components-button-color-outline-white-focus-bg": 0.02,
        "components-button-color-outline-white-focus-text": 1,
        "components-button-color-outline-white-focus-stroke": 1,
        "components-button-color-outline-white-focus-icon": 1,
        "components-button-color-outline-white-focus-outline": 1,
        "components-button-color-text-brand-default-bg": 0.02,
        "components-button-color-text-brand-default-text": 1,
        "components-button-color-text-brand-default-stroke": 1,
        "components-button-color-text-brand-default-icon": 1,
        "components-button-color-text-brand-hover-bg": 0.02,
        "components-button-color-text-brand-hover-bg-icon-only": 1,
        "components-button-color-text-brand-hover-text": 1,
        "components-button-color-text-brand-hover-stroke": 1,
        "components-button-color-text-brand-hover-icon": 1,
        "components-button-color-text-brand-pressed-bg": 0.02,
        "components-button-color-text-brand-pressed-bg-icon-only": 1,
        "components-button-color-text-brand-pressed-text": 1,
        "components-button-color-text-brand-pressed-stroke": 1,
        "components-button-color-text-brand-pressed-icon": 1,
        "components-button-color-text-brand-disabled-bg": 0.02,
        "components-button-color-text-brand-disabled-text": null,
        "components-button-color-text-brand-disabled-stroke": null,
        "components-button-color-text-brand-disabled-icon": null,
        "components-button-color-text-brand-focus-bg": 1,
        "components-button-color-text-brand-focus-text": 1,
        "components-button-color-text-brand-focus-stroke": 1,
        "components-button-color-text-brand-focus-icon": 1,
        "components-button-color-text-brand-focus-outline": 1,
        "components-button-color-text-tonal-default-bg": 0.02,
        "components-button-color-text-tonal-default-text": 1,
        "components-button-color-text-tonal-default-stroke": 1,
        "components-button-color-text-tonal-default-icon": 1,
        "components-button-color-text-tonal-hover-bg": 0.02,
        "components-button-color-text-tonal-hover-bg-icon-only": 1,
        "components-button-color-text-tonal-hover-text": 1,
        "components-button-color-text-tonal-hover-stroke": 1,
        "components-button-color-text-tonal-hover-icon": 1,
        "components-button-color-text-tonal-pressed-bg": 0.02,
        "components-button-color-text-tonal-pressed-bg-icon-only": 1,
        "components-button-color-text-tonal-pressed-text": 1,
        "components-button-color-text-tonal-pressed-stroke": 1,
        "components-button-color-text-tonal-pressed-icon": 1,
        "components-button-color-text-tonal-disabled-bg": 0.02,
        "components-button-color-text-tonal-disabled-text": null,
        "components-button-color-text-tonal-disabled-stroke": 0.02,
        "components-button-color-text-tonal-disabled-icon": null,
        "components-button-color-text-tonal-focus-bg": 1,
        "components-button-color-text-tonal-focus-text": 1,
        "components-button-color-text-tonal-focus-stroke": 1,
        "components-button-color-text-tonal-focus-icon": 1,
        "components-button-color-text-tonal-focus-outline": 1,
        "components-button-color-text-white-default-bg": 0.02,
        "components-button-color-text-white-default-text": 1,
        "components-button-color-text-white-default-stroke": 1,
        "components-button-color-text-white-default-icon": 1,
        "components-button-color-text-white-hover-bg": 0.02,
        "components-button-color-text-white-hover-bg-icon-only": 0.01,
        "components-button-color-text-white-hover-text": 1,
        "components-button-color-text-white-hover-stroke": 1,
        "components-button-color-text-white-hover-icon": 1,
        "components-button-color-text-white-pressed-bg": 0.02,
        "components-button-color-text-white-pressed-bg-icon-only": 0.01,
        "components-button-color-text-white-pressed-text": 1,
        "components-button-color-text-white-pressed-stroke": 1,
        "components-button-color-text-white-pressed-icon": 1,
        "components-button-color-text-white-disabled-bg": 0.02,
        "components-button-color-text-white-disabled-text": 0.01,
        "components-button-color-text-white-disabled-stroke": 0.01,
        "components-button-color-text-white-disabled-icon": 0.01,
        "components-button-color-text-white-focus-bg": 1,
        "components-button-color-text-white-focus-text": 1,
        "components-button-color-text-white-focus-stroke": 1,
        "components-button-color-text-white-focus-icon": 1,
        "components-button-color-text-white-focus-outline": 1,
        "components-button-group-color-border": 1,
        "components-button-group-color-button-bg-default": 0.02,
        "components-button-group-color-button-fg-default": 1,
        "components-button-group-color-button-bg-hover": null,
        "components-button-group-color-button-fg-hover": 1,
        "components-button-group-color-button-bg-active": 1,
        "components-button-group-color-button-fg-active": 1,
        "components-button-group-color-overlay-left-gradient-fill-1": 1,
        "components-button-group-color-overlay-left-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-1": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-3": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-4": 0.02,
        "components-button-group-color-stroke-hover-gradient-fill-1": null,
        "components-button-group-color-stroke-hover-gradient-fill-2": null,
        "components-button-group-color-stroke-hover-gradient-fill-3": null,
        "components-button-group-color-stroke-hover-gradient-fill-4": null,
        "components-button-group-color-stroke-active-gradient-fill-1": 1,
        "components-button-group-color-stroke-active-gradient-fill-2": 1,
        "components-button-group-color-stroke-active-gradient-fill-3": 1,
        "components-button-group-color-stroke-active-gradient-fill-4": 1,
        "components-category-card-color-bg": 1,
        "components-category-card-color-content-bg": 1,
        "components-category-card-color-category-bg": 1,
        "components-category-card-color-category-bg-2": 1,
        "components-category-card-color-category-bg-3": 1,
        "components-category-card-color-category-bg-4": 1,
        "components-category-card-color-category-bg-5": 1,
        "components-category-card-color-category-bg-6": 1,
        "components-category-card-color-title": 1,
        "components-category-card-color-copy": 1,
        "components-category-card-color-stroke": 1,
        "components-category-card-color-stroke-hover": 1,
        "components-category-card-listing-color-bg": 1,
        "components-category-card-listing-color-title": 1,
        "components-category-card-listing-color-copy": 1,
        "components-card-search-result-color-bg": 1,
        "components-card-search-result-color-label": 1,
        "components-card-search-result-color-title": 1,
        "components-card-search-result-color-body": 1,
        "components-card-search-result-color-divider-line": 1,
        "components-contact-us-promo-color-light-bg": 1,
        "components-contact-us-promo-color-light-title": 1,
        "components-contact-us-promo-color-light-body": 1,
        "components-contact-us-promo-color-default-bg": 1,
        "components-contact-us-promo-color-default-title": 1,
        "components-contact-us-promo-color-default-body": 1,
        "components-contact-us-promo-color-brand-bg": 1,
        "components-contact-us-promo-color-brand-title": 1,
        "components-contact-us-promo-color-brand-body": 1,
        "components-content-list-color-bg": 1,
        "components-content-list-color-title": 1,
        "components-content-list-color-body": 1,
        "components-content-list-color-icon": 1,
        "components-cookies-colors-bg": 1,
        "components-cookies-colors-title": 1,
        "components-cookies-colors-body": 1,
        "components-cookies-colors-icon": 1,
        "components-dropdown-bg": 1,
        "components-dropdown-border-default": 1,
        "components-dropdown-border-hover": 1,
        "components-dropdown-border-focused": 1,
        "components-dropdown-border-destructive": 1,
        "components-dropdown-label-text-default": 1,
        "components-dropdown-item-text-default": 1,
        "components-dropdown-item-bg-default": 1,
        "components-dropdown-item-bg-hover": 1,
        "components-dropdown-input-field-default": 1,
        "components-dropdown-input-field-focused": 1,
        "components-dropdown-input-label-focused": 1,
        "components-dropdown-input-field-destructive": 1,
        "components-external-link-card-bg": 1,
        "components-external-link-card-card-bg": 1,
        "components-external-link-card-card-title": 1,
        "components-external-link-card-card-subtitle": 1,
        "components-external-link-card-card-eyebrow": 1,
        "components-fab-color-border-default": 1,
        "components-footer-brand-color-bg": 1,
        "components-footer-brand-color-logo": 1,
        "components-footer-brand-color-title-text": 1,
        "components-footer-brand-color-description-text": 1,
        "components-footer-brand-color-footer-menu-expanded": 1,
        "components-footer-brand-color-link-default": 1,
        "components-footer-brand-color-link-hover": 1,
        "components-footer-brand-color-link-pressed": 1,
        "components-footer-brand-color-category-link-default": 1,
        "components-footer-brand-color-category-link-hover": 1,
        "components-footer-brand-color-category-link-pressed": 1,
        "components-footer-brand-color-subcategory-link-default": 1,
        "components-footer-brand-color-subcategory-link-hover": 1,
        "components-footer-brand-color-subcategory-link-pressed": 1,
        "components-footer-brand-color-icon-icon-default": 1,
        "components-footer-brand-color-icon-icon-pressed": 1,
        "components-footer-brand-color-social-icon-default": 1,
        "components-footer-brand-color-social-icon-hover": 1,
        "components-footer-brand-color-social-icon-pressed": 1,
        "components-footer-brand-spacing-category-link-hover": 1,
        "components-footer-global-color-bg": 1,
        "components-footer-global-color-title-text": 1,
        "components-footer-global-color-disclaimer-text": 1,
        "components-footer-global-color-link-default": 1,
        "components-footer-global-color-link-hover": 1,
        "components-footer-global-color-link-pressed": 1,
        "components-generic-card-color-bg": 1,
        "components-generic-card-color-content-bg": 1,
        "components-generic-card-color-title": 1,
        "components-generic-card-color-subtitle": 1,
        "components-generic-card-color-copy": 1,
        "components-global-search-color-omnibox-list-bg": 1,
        "components-global-search-color-omnibox-divider": 1,
        "components-global-search-color-omnibox-item-bg-default": 1,
        "components-global-search-color-omnibox-item-bg-hover": 1,
        "components-global-search-color-omnibox-item-text-default": 1,
        "components-global-search-color-omnibox-item-text-hover": 1,
        "components-global-search-color-omnibox-label-text-default": 1,
        "components-global-search-color-omnibox-category-text-default": 1,
        "components-generic-card-listing-color-bg": 1,
        "components-generic-card-listing-color-title": 1,
        "components-generic-card-listing-color-copy": 1,
        "components-header-color-bg": 1,
        "components-header-color-bottom-stroke": 1,
        "components-header-color-search-search-border-default": 1,
        "components-header-color-search-search-text": 1,
        "components-header-color-search-search-icon": 1,
        "components-header-color-search-search-bar": 1,
        "components-header-color-mobile-mobile-expanded-bg": 1,
        "components-header-color-mobile-pressed-bg": 1,
        "components-header-color-language-selector-language": 1,
        "components-header-color-language-selector-country": 1,
        "components-header-color-language-selector-country-hover-bg": 0.02,
        "components-header-color-language-selector-megamenu-language": 1,
        "components-header-color-language-selector-megamenu-country": 1,
        "components-header-color-language-selector-megamenu-country-hover": 1,
        "components-header-color-language-selector-bg": 1,
        "components-header-color-language-selector-stroke": 1,
        "components-header-color-mega-menu-bg": 1,
        "components-header-color-mega-menu-featured-bg": 1,
        "components-header-color-mega-menu-featured-title": 1,
        "components-header-color-mega-menu-category-title": 1,
        "components-header-color-mega-menu-bg-accent": 1,
        "components-header-color-icon-icon-default": 1,
        "components-header-color-icon-icon-hover": 1,
        "components-header-color-icon-icon-pressed": 1,
        "components-header-color-links-nav-main-link-default": 1,
        "components-header-color-links-nav-main-link-hover": 1,
        "components-header-color-links-nav-main-link-pressed": 1,
        "components-header-color-links-nav-main-link-active": 1,
        "components-header-color-links-nav-main-active-bar": 1,
        "components-header-color-links-nav-main-bg-color": 1,
        "components-header-color-links-nav-category-link-category-default": 1,
        "components-header-color-links-nav-category-link-category-hover": 1,
        "components-header-color-links-nav-category-link-category-pressed": 1,
        "components-header-color-links-nav-category-link-category-active": 1,
        "components-header-color-links-nav-menu-link-link-default": 1,
        "components-header-color-links-nav-menu-link-link-hover": 1,
        "components-header-color-links-nav-menu-link-link-pressed": 1,
        "components-header-color-links-nav-menu-link-link-active": 1,
        "components-header-color-links-nav-menu-link-bg": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-default": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-active": 1,
        "components-hero-homepage-color-bg": 1,
        "components-hero-homepage-color-container-bg": 1,
        "components-hero-homepage-color-eyebrow": 1,
        "components-hero-homepage-color-header": 1,
        "components-hero-homepage-color-sub-header": 1,
        "components-hero-homepage-color-body": 1,
        "components-hero-homepage-color-play-pause": 1,
        "components-hero-internal-color-default-title": 1,
        "components-hero-internal-color-default-body": 1,
        "components-hero-internal-color-default-bg": 1,
        "components-hero-internal-color-accent-bg": 1,
        "components-hero-internal-color-with-image-bg": 1,
        "components-hero-internal-color-default-bg-2": 1,
        "components-hero-internal-color-with-image-title": 1,
        "components-hero-internal-color-with-image-body": 1,
        "components-heading-page-title-color-default-bg": 1,
        "components-heading-page-title-color-default-title": 1,
        "components-heading-page-title-color-default-body": 1,
        "components-heading-page-title-color-light-bg": 1,
        "components-heading-page-title-color-light-title": 1,
        "components-heading-page-title-color-light-body": 1,
        "components-heading-page-title-color-color-bg": 1,
        "components-heading-page-title-color-color-title": 1,
        "components-heading-page-title-color-color-body": 1,
        "components-hyperlink-color-dark-default": 1,
        "components-hyperlink-color-dark-hover": 1,
        "components-hyperlink-color-dark-visited": 1,
        "components-hyperlink-color-light-default": 1,
        "components-hyperlink-color-light-hover": 1,
        "components-hyperlink-color-light-visited": 1,
        "components-icon-default": 1,
        "components-icon-focused": 1,
        "components-icon-card-color-bg": 1,
        "components-icon-card-color-icon-bg": 1,
        "components-icon-card-color-icon": 1,
        "components-icon-card-color-title": 1,
        "components-icon-card-color-subtitle": 1,
        "components-icon-card-color-description": 1,
        "components-icon-card-listing-color-bg": 1,
        "components-icon-card-listing-color-inner-bg": 1,
        "components-icon-card-listing-color-brand-bg": 1,
        "components-icon-card-listing-color-title": 1,
        "components-icon-card-listing-color-copy": 1,
        "components-icon-card-listing-color-inner-legal-text": 1,
        "components-jumplink-color-bg-default": 1,
        "components-jumplink-color-link-text-default": 1,
        "components-jumplink-color-link-text-hover": 1,
        "components-jumplink-color-link-border-hover": 1,
        "components-jumplink-color-link-text-active": 1,
        "components-jumplink-color-link-border-active": 1,
        "components-jumplink-color-title-text": 1,
        "components-jumplink-color-description-text": 1,
        "components-layout-bg-default": 1,
        "components-lightbox-color-overlay-bg": 0.02,
        "components-lightbox-color-title": 1,
        "components-lightbox-color-body": 1,
        "components-label-color-on-color-label-label-default": 1,
        "components-label-color-on-color-label-label-hover": 1,
        "components-label-color-on-color-label-label-pressed": 1,
        "components-label-color-on-color-background-bg-default": 0.02,
        "components-label-color-on-color-background-bg-hover": 1,
        "components-label-color-on-color-background-bg-pressed": 1,
        "components-label-color-on-color-stroke-hover": 0.02,
        "components-label-color-on-color-stroke-pressed": 0.02,
        "components-label-color-on-color-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-4": 1,
        "components-label-color-on-white-label-label-default": 1,
        "components-label-color-on-white-label-label-hover": 1,
        "components-label-color-on-white-label-label-pressed": 1,
        "components-label-color-on-white-background-bg-default": 0.02,
        "components-label-color-on-white-background-bg-hover": 1,
        "components-label-color-on-white-background-bg-pressed": 1,
        "components-label-color-on-white-stroke-hover": 1,
        "components-label-color-on-white-stroke-pressed": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-4": 1,
        "components-legal-disclaimer-color-default-bg": 1,
        "components-legal-disclaimer-color-default-title": 1,
        "components-legal-disclaimer-color-default-body": 1,
        "components-legal-disclaimer-color-light-bg": 1,
        "components-legal-disclaimer-color-light-title": 1,
        "components-legal-disclaimer-color-light-body": 1,
        "components-legal-disclaimer-color-brand-bg": 1,
        "components-legal-disclaimer-color-brand-title": 1,
        "components-legal-disclaimer-color-brand-body": 1,
        "components-media-video-thumbnail-overlay-bg": 0.01,
        "components-media-caption-color-bg": 1,
        "components-media-caption-color-text": 1,
        "components-media-gallery-color-bg": 1,
        "components-media-gallery-color-title": 1,
        "components-media-gallery-color-body": 1,
        "components-pagination-on-white-accent-default": 1,
        "components-pagination-on-white-accent-scroll": 1,
        "components-pagination-on-white-disabled": 1,
        "components-pagination-on-white-bg": 1,
        "components-pagination-on-white-stroke": 1,
        "components-pagination-on-white-bg-active": 1,
        "components-pagination-on-white-bg-hover": 1,
        "components-pagination-on-white-text-default": 1,
        "components-pagination-on-white-text-hover": 1,
        "components-pagination-on-white-text-active": 1,
        "components-pagination-on-color-accent-default": 1,
        "components-pagination-on-color-accent-scroll": 1,
        "components-pagination-on-color-disabled": 1,
        "components-pagination-on-color-bg": 0.02,
        "components-pagination-on-color-stroke": 0.02,
        "components-pagination-on-color-bg-active": 1,
        "components-pagination-on-color-bg-hover": 1,
        "components-pagination-on-color-text-default": 1,
        "components-pagination-on-color-text-hover": 1,
        "components-pagination-on-color-text-active": 1,
        "components-pdf-link-card-color-bg": 1,
        "components-pdf-link-card-color-card-bg": 1,
        "components-pdf-link-card-color-card-title": 1,
        "components-pdf-link-card-color-card-subtitle": 1,
        "components-pdf-link-card-color-card-body": 1,
        "components-product-card-color-bg": 1,
        "components-product-card-color-content-bg": 1,
        "components-product-card-color-border": 1,
        "components-product-card-color-title": 1,
        "components-product-card-color-description": 1,
        "components-product-card-color-star-rating": 1,
        "components-product-card-color-badge-bg": 1,
        "components-product-card-color-badge-text": 1,
        "components-product-card-listing-color-bg": 1,
        "components-product-card-listing-color-title": 1,
        "components-product-card-listing-color-copy": 1,
        "components-product-card-listing-color-legal-text": 1,
        "components-product-information-color-bg": 1,
        "components-product-information-color-content-bg": 1,
        "components-product-information-color-headline": 1,
        "components-product-information-color-body": 1,
        "components-product-information-color-detail-text": 1,
        "components-product-information-color-divider": 1,
        "components-product-information-color-icon": 1,
        "components-product-information-color-play-icon": 1,
        "components-product-information-color-selected-stroke": 1,
        "components-product-results-list-with-search-color-bg": 1,
        "components-promo-color-default-bg": 1,
        "components-promo-color-default-image-bg": 1,
        "components-promo-color-default-content-bg": 1,
        "components-promo-color-default-title": 1,
        "components-promo-color-default-body": 1,
        "components-promo-color-default-category": 1,
        "components-promo-color-light-bg": 1,
        "components-promo-color-light-image-bg": 1,
        "components-promo-color-light-content-bg": 1,
        "components-promo-color-light-title": 1,
        "components-promo-color-light-body": 1,
        "components-promo-color-light-category": 1,
        "components-promo-color-brand-bg": 1,
        "components-promo-color-brand-image-bg": 1,
        "components-promo-color-brand-content-bg": 1,
        "components-promo-color-brand-title": 1,
        "components-promo-color-brand-body": 1,
        "components-promo-color-brand-category": 1,
        "components-pull-quote-color-bg": 1,
        "components-pull-quote-color-stroke": 1,
        "components-pull-quote-color-quote-bg": 1,
        "components-pull-quote-color-quote-title": 1,
        "components-pull-quote-color-quote-by-text": 1,
        "components-pull-quote-color-quote-detail": 1,
        "components-pull-quote-color-quote-highlight": 1,
        "components-promo-alternate-color-default-bg": 1,
        "components-promo-alternate-color-default-image-bg": 1,
        "components-promo-alternate-color-default-content-bg": 1,
        "components-promo-alternate-color-default-title": 1,
        "components-promo-alternate-color-default-body": 1,
        "components-promo-alternate-color-default-category": 1,
        "components-promo-alternate-color-light-bg": 1,
        "components-promo-alternate-color-light-image-bg": 1,
        "components-promo-alternate-color-light-content-bg": 1,
        "components-promo-alternate-color-light-title": 1,
        "components-promo-alternate-color-light-body": 1,
        "components-promo-alternate-color-light-category": 1,
        "components-promo-alternate-color-brand-bg": 1,
        "components-promo-alternate-color-brand-image-bg": 1,
        "components-promo-alternate-color-brand-content-bg": 1,
        "components-promo-alternate-color-brand-title": 1,
        "components-promo-alternate-color-brand-body": 1,
        "components-promo-alternate-color-brand-category": 1,
        "components-share-list-color-title": 1,
        "components-share-list-color-bg": 1,
        "components-share-list-color-link-default": 1,
        "components-share-list-color-link-hover": 1,
        "components-share-list-color-bg-link-hover": 1,
        "components-share-list-color-close-icon": 1,
        "components-share-list-color-facebook-icon": 1,
        "components-share-list-color-pinterest-icon": 1,
        "components-share-list-color-twitter-icon": 1,
        "components-share-list-color-basic-icon-default": 1,
        "components-share-list-color-icon-hover": 1,
        "components-share-list-color-border": 1,
        "components-site-search-bg": 1,
        "components-site-search-results_text": 1,
        "components-site-search-filter-filter-selected-bg": 1,
        "components-site-search-facet-group-label-bottom-line": 1,
        "components-site-search-facet-group-label-text": 1,
        "components-site-search-facet-group-label-bg": 1,
        "components-site-search-facet-bg": 1,
        "components-site-search-facet-stroke": 1,
        "components-site-search-refine-bg": 1,
        "components-site-search-refine-right-divider": 1,
        "components-site-search-refine-title": 1,
        "components-site-search-hero-search-bg": 1,
        "components-site-search-hero-search-accent-bg": 1,
        "components-site-search-hero-search-title": 1,
        "components-stats-stat-text": 1,
        "components-stats-stat-text-on-light": 1,
        "components-stats-supporting-text": 1,
        "components-stats-bg-default": 1,
        "components-stats-bg-inner": 1,
        "components-stats-bg-section-inner": 1,
        "components-stats-title": 1,
        "components-stats-copy": 1,
        "components-supporting-text-default": 1,
        "components-supporting-text-destructive": 1,
        "components-tab-bg": 1,
        "components-tab-border-default": 1,
        "components-tab-border-hover": 1,
        "components-tab-border-focused": 1,
        "components-tab-border-destructive": 1,
        "components-tab-label-text-default": 1,
        "components-tab-item-text-default": 1,
        "components-tab-item-bg-default": 1,
        "components-tab-item-bg-hover": 1,
        "components-tab-input-field-default": 1,
        "components-tab-input-field-focused": 1,
        "components-tab-input-label-focused": 1,
        "components-tab-input-field-destructive": 1,
        "components-tab-bg-2": 1,
        "components-tag-color-bg": 1,
        "components-tag-color-text": 1,
        "components-tag-color-stroke": 1,
        "components-tag-color-bg-hover": 1,
        "components-tag-color-text-hover": 1,
        "components-tag-color-stroke-hover": 1,
        "components-text-field-bg": 1,
        "components-text-field-bg-focused": 1,
        "components-text-field-border-default": 1,
        "components-text-field-border-hover": 1,
        "components-text-field-border-focused": 1,
        "components-text-field-border-destructive": 1,
        "components-text-field-input-field-default": 1,
        "components-text-field-input-field-focused": 1,
        "components-text-field-input-label-focused": 1,
        "components-text-field-input-field-destructive": 1,
        "components-text-field-icon-default": 1,
        "components-text-field-icon-destructive": 1,
        "components-text-field-supporting-text-default": 1,
        "components-text-field-supporting-text-destructive": 1,
        "components-tip-color-bg": 1,
        "components-tip-color-title": 1,
        "components-tip-color-body": 1,
        "components-tip-color-stroke": 1,
        "components-video-color-bg": 1,
        "themes-general-surface-color-light-bg": 1,
        "themes-general-surface-color-light-title": 1,
        "themes-general-surface-color-light-body": 1,
        "themes-general-surface-color-light-decor-line-break": 1,
        "themes-general-surface-color-light-rule-line": 0.01,
        "themes-general-surface-color-dark-bg": 1,
        "themes-general-surface-color-dark-title": 1,
        "themes-general-surface-color-dark-body": 1,
        "themes-general-surface-color-dark-decor-line-break": 1,
        "themes-general-card-color-dark-title": 1,
        "themes-general-card-color-dark-subtitle": 1,
        "themes-general-card-color-dark-body": 1,
        "themes-general-card-color-light-title": 1,
        "themes-general-card-color-light-subtitle": 1,
        "themes-general-card-color-light-body": 1,
        "themes-shadow-color": 1,
        "themes-shadow-share-list-color-fill": 0,
        "themes-shadow-header-menu-color-fill": 0,
        "themes-gradient-loader-gradient-fill-1": 1,
        "themes-gradient-loader-gradient-fill-2": null,
        "themes-color-background-white": 1,
        "themes-color-background-light": 1,
        "themes-color-background-dark": 1,
        "themes-color-background-black": 1,
        "themes-color-background-brand1": 1,
        "themes-color-background-brand2": 1,
        "themes-color-background-accent1": 1,
        "themes-color-background-accent2": 1,
        "themes-color-background-body": 1,
        "themes-color-stroke-white": 1,
        "themes-color-stroke-light": 1,
        "themes-color-stroke-dark": 1,
        "themes-color-stroke-black": 1,
        "themes-color-stroke-brand1": 1,
        "themes-color-stroke-brand2": 1,
        "themes-color-stroke-accent1": 1,
        "themes-color-stroke-accent2": 1,
        "themes-color-text-white": 1,
        "themes-color-text-light": 1,
        "themes-color-text-dark": 1,
        "themes-color-text-black": 1,
        "themes-color-text-brand1": 1,
        "themes-color-text-brand2": 1,
        "themes-color-text-accent1": 1,
        "themes-color-text-accent2": 1,
        "colors-grayscale-100": 1,
        "colors-grayscale-200": 1,
        "colors-grayscale-300": 1,
        "colors-grayscale-400": 1,
        "colors-grayscale-500": 1,
        "colors-grayscale-600": 1,
        "colors-grayscale-700": 1,
        "colors-brand-1-100": 1,
        "colors-brand-1-200": 1,
        "colors-brand-1-300": 1,
        "colors-brand-1-400": 1,
        "colors-brand-1-500": 1,
        "colors-brand-1-600": 1,
        "colors-brand-1-700": 1,
        "colors-brand-2-100": 1,
        "colors-brand-2-200": 1,
        "colors-brand-2-300": 1,
        "colors-brand-2-400": 1,
        "colors-brand-2-500": 1,
        "colors-brand-2-600": 1,
        "colors-brand-2-700": 1,
        "colors-accent-1-100": 1,
        "colors-accent-1-200": 1,
        "colors-accent-1-300": 1,
        "colors-accent-1-400": 1,
        "colors-accent-1-500": 1,
        "colors-accent-1-600": 1,
        "colors-accent-1-700": 1,
        "colors-accent-2-100": 1,
        "colors-accent-2-200": 1,
        "colors-accent-2-300": 1,
        "colors-accent-2-400": 1,
        "colors-accent-2-500": 1,
        "colors-accent-2-600": 1,
        "colors-accent-2-700": 1,
        "colors-accent-3-100": 1,
        "colors-accent-3-200": 1,
        "colors-accent-3-300": 1,
        "colors-accent-3-400": 1,
        "colors-accent-3-500": 1,
        "colors-accent-3-600": 1,
        "colors-accent-3-700": 1,
        "colors-accent-4-400_storage": 1,
        "colors-accessible-accent-1-100": 1,
        "colors-accessible-accent-1-200": 1,
        "colors-accessible-accent-1-300": 1,
        "colors-accessible-accent-1-400": 1,
        "colors-accessible-accent-1-500": 1,
        "colors-accessible-accent-1-600": 1,
        "colors-accessible-accent-1-700": 1,
        "colors-accessible-accent-2-100": 1,
        "colors-accessible-accent-2-200": 1,
        "colors-accessible-accent-2-300": 1,
        "colors-accessible-accent-2-400": 1,
        "colors-accessible-accent-2-500": 1,
        "colors-accessible-accent-2-600": 1,
        "colors-accessible-accent-2-700": 1,
        "colors-accessible-brand-2-100": 1,
        "colors-accessible-brand-2-200": 1,
        "colors-accessible-brand-2-300": 1,
        "colors-accessible-brand-2-400": 1,
        "colors-accessible-brand-2-500": 1,
        "colors-accessible-brand-2-600": 1,
        "colors-accessible-brand-2-700": 1
      }
    },
    "Ziploc": {
      "colors": {
        "components-accessibility-bar-color-bg": "#032c87",
        "components-accessibility-bar-color-text": "#fcfcfd",
        "components-accordion-color-bg": "#ffffff",
        "components-accordion-color-title": "#032c87",
        "components-accordion-color-body": "#27282b",
        "components-accordion-color-section-section-bg": "#ffffff",
        "components-accordion-color-section-section-title": "#032c87",
        "components-accordion-color-section-section-body": "#27282b",
        "components-accordion-color-item-item-bg": "#ffffff",
        "components-accordion-color-item-divider": "#e8e8ea",
        "components-accordion-color-item-icon-icon-default": "#0241cd",
        "components-accordion-color-item-icon-icon-hover": "#ffffff",
        "components-accordion-color-item-icon-icon-pressed": "#ffffff",
        "components-accordion-color-item-icon-icon-active": "#ffffff",
        "components-accordion-color-item-title-item-default": "#032c87",
        "components-accordion-color-item-title-item-hover": "#ffffff",
        "components-accordion-color-item-title-item-pressed": "#ffffff",
        "components-accordion-color-item-title-item-active": "#ffffff",
        "components-accordion-color-content-bg": "#ffffff",
        "components-accordion-color-content-title": "#032c87",
        "components-accordion-color-content-body": "#27282b",
        "components-article-card-color-bg": "#f2f6fd",
        "components-article-card-color-border": "#e8e8ea",
        "components-article-card-color-content-bg": "#f2f6fd",
        "components-article-card-color-title": "#032c87",
        "components-article-card-color-subtitle": "#27282b",
        "components-article-card-color-copy": "#27282b",
        "components-article-card-color-description": "#27282b",
        "components-article-card-listing-color-default-bg": "#ffffff",
        "components-article-card-listing-color-default-title": "#032c87",
        "components-article-card-listing-color-default-copy": "#27282b",
        "components-article-hero-color-content-bg": "#f2f6fd",
        "components-article-hero-color-bg": "#f2f6fd",
        "components-article-hero-color-headline": "#032c87",
        "components-article-hero-color-body": "#27282b",
        "components-article-hero-color-detail-text": "#27282b",
        "components-article-hero-color-divider": "#a2a5ab",
        "components-article-hero-color-icon": "#0241cd",
        "components-article-hero-color-play-icon": "#ffffff",
        "components-article-hero-color-selected-stroke": "#0241cd",
        "components-banner-color-bg-default": "#ebfbff",
        "components-banner-color-icon-default": "#00abd6",
        "components-banner-color-title-default": "#003d4d",
        "components-banner-color-body-default": "#003d4d",
        "components-banner-color-icon-close-default": "#191b1c",
        "components-banner-color-bg-alert": "#b32b30",
        "components-banner-color-icon-alert": "#ffffff",
        "components-banner-color-title-alert": "#fcfcfd",
        "components-banner-color-body-alert": "#fcfcfd",
        "components-banner-color-icon-close-alert": "#fcfcfd",
        "components-breadcrumb-color-bg": "#ffffff",
        "components-breadcrumb-color-breadcrumb-link-default": "#0241cd",
        "components-breadcrumb-color-breadcrumb-link-hover": "#032c87",
        "components-breadcrumb-color-breadcrumb-link-active": "#27282b",
        "components-breadcrumb-color-icon-divider": "#678de1",
        "components-bug-finder-prompt": "#ffffff",
        "components-button-color-filled-brand-default-bg": "#0241cd",
        "components-button-color-filled-brand-default-text": "#fcfcfd",
        "components-button-color-filled-brand-default-stroke": "#0241cd",
        "components-button-color-filled-brand-default-icon": "#fcfcfd",
        "components-button-color-filled-brand-hover-bg": "#2a5fd5",
        "components-button-color-filled-brand-hover-text": "#fcfcfd",
        "components-button-color-filled-brand-hover-stroke": "#2a5fd5",
        "components-button-color-filled-brand-hover-icon": "#fcfcfd",
        "components-button-color-filled-brand-pressed-bg": "#032c87",
        "components-button-color-filled-brand-pressed-text": "#fcfcfd",
        "components-button-color-filled-brand-pressed-stroke": "#032c87",
        "components-button-color-filled-brand-pressed-icon": "#fcfcfd",
        "components-button-color-filled-brand-disabled-bg": "rgba(2, 65, 205, 0.38)",
        "components-button-color-filled-brand-disabled-text": "rgba(252, 252, 253, 0.38)",
        "components-button-color-filled-brand-disabled-stroke": "rgba(2, 65, 205, 0.38)",
        "components-button-color-filled-brand-disabled-icon": "rgba(252, 252, 253, 0.38)",
        "components-button-color-filled-brand-focus-bg": "#0241cd",
        "components-button-color-filled-brand-focus-text": "#fcfcfd",
        "components-button-color-filled-brand-focus-stroke": "#0241cd",
        "components-button-color-filled-brand-focus-icon": "#fcfcfd",
        "components-button-color-filled-brand-focus-outline": "#286efa",
        "components-button-color-filled-tonal-default-bg": "#def8ff",
        "components-button-color-filled-tonal-default-text": "#006c87",
        "components-button-color-filled-tonal-default-stroke": "#def8ff",
        "components-button-color-filled-tonal-default-icon": "#006c87",
        "components-button-color-filled-tonal-hover-bg": "#66e0ff",
        "components-button-color-filled-tonal-hover-text": "#004e61",
        "components-button-color-filled-tonal-hover-stroke": "#66e0ff",
        "components-button-color-filled-tonal-hover-icon": "#004e61",
        "components-button-color-filled-tonal-pressed-bg": "#00bae8",
        "components-button-color-filled-tonal-pressed-text": "#002a35",
        "components-button-color-filled-tonal-pressed-stroke": "#00bae8",
        "components-button-color-filled-tonal-pressed-icon": "#002a35",
        "components-button-color-filled-tonal-disabled-bg": "rgba(222, 248, 255, 0.38)",
        "components-button-color-filled-tonal-disabled-text": "rgba(0, 108, 135, 0.38)",
        "components-button-color-filled-tonal-disabled-stroke": "rgba(222, 248, 255, 0.38)",
        "components-button-color-filled-tonal-disabled-icon": "rgba(0, 108, 135, 0.38)",
        "components-button-color-filled-tonal-focus-bg": "#def8ff",
        "components-button-color-filled-tonal-focus-text": "#006c87",
        "components-button-color-filled-tonal-focus-stroke": "#def8ff",
        "components-button-color-filled-tonal-focus-icon": "#006c87",
        "components-button-color-filled-tonal-focus-outline": "#1c4cae",
        "components-button-color-filled-white-default-bg": "#ffffff",
        "components-button-color-filled-white-default-text": "#032c87",
        "components-button-color-filled-white-default-stroke": "#ffffff",
        "components-button-color-filled-white-default-icon": "#032c87",
        "components-button-color-filled-white-hover-bg": "#e8e8ea",
        "components-button-color-filled-white-hover-text": "#012167",
        "components-button-color-filled-white-hover-stroke": "#e8e8ea",
        "components-button-color-filled-white-hover-icon": "#012167",
        "components-button-color-filled-white-pressed-bg": "#a2a5ab",
        "components-button-color-filled-white-pressed-text": "#012167",
        "components-button-color-filled-white-pressed-stroke": "#e8e8ea",
        "components-button-color-filled-white-pressed-icon": "#012167",
        "components-button-color-filled-white-disabled-bg": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-text": "rgba(3, 44, 135, 0.38)",
        "components-button-color-filled-white-disabled-stroke": "rgba(255, 255, 255, 0.38)",
        "components-button-color-filled-white-disabled-icon": "rgba(3, 44, 135, 0.38)",
        "components-button-color-filled-white-focus-bg": "#ffffff",
        "components-button-color-filled-white-focus-text": "#032c87",
        "components-button-color-filled-white-focus-stroke": "#ffffff",
        "components-button-color-filled-white-focus-icon": "#032c87",
        "components-button-color-filled-white-focus-outline": "#286efa",
        "components-button-color-outline-brand-default-bg": "rgba(242, 246, 253, 0.00)",
        "components-button-color-outline-brand-default-text": "#0241cd",
        "components-button-color-outline-brand-default-stroke": "#0241cd",
        "components-button-color-outline-brand-default-icon": "#0241cd",
        "components-button-color-outline-brand-hover-bg": "#2a5fd5",
        "components-button-color-outline-brand-hover-text": "#fcfcfd",
        "components-button-color-outline-brand-hover-stroke": "#2a5fd5",
        "components-button-color-outline-brand-hover-icon": "#fcfcfd",
        "components-button-color-outline-brand-pressed-bg": "#032c87",
        "components-button-color-outline-brand-pressed-text": "#fcfcfd",
        "components-button-color-outline-brand-pressed-stroke": "#032c87",
        "components-button-color-outline-brand-pressed-icon": "#fcfcfd",
        "components-button-color-outline-brand-disabled-bg": "rgba(242, 246, 253, 0.00)",
        "components-button-color-outline-brand-disabled-text": "rgba(2, 65, 205, 0.38)",
        "components-button-color-outline-brand-disabled-stroke": "rgba(2, 65, 205, 0.38)",
        "components-button-color-outline-brand-disabled-icon": "rgba(2, 65, 205, 0.38)",
        "components-button-color-outline-brand-focus-bg": "#f2f6fd",
        "components-button-color-outline-brand-focus-text": "#0241cd",
        "components-button-color-outline-brand-focus-stroke": "#0241cd",
        "components-button-color-outline-brand-focus-icon": "#0241cd",
        "components-button-color-outline-brand-focus-outline": "#286efa",
        "components-button-color-outline-tonal-default-bg": "rgba(222, 248, 255, 0.00)",
        "components-button-color-outline-tonal-default-text": "#006c87",
        "components-button-color-outline-tonal-default-stroke": "#00bae8",
        "components-button-color-outline-tonal-default-icon": "#006c87",
        "components-button-color-outline-tonal-hover-bg": "#66e0ff",
        "components-button-color-outline-tonal-hover-text": "#004e61",
        "components-button-color-outline-tonal-hover-stroke": "#66e0ff",
        "components-button-color-outline-tonal-hover-icon": "#004e61",
        "components-button-color-outline-tonal-pressed-bg": "#00bae8",
        "components-button-color-outline-tonal-pressed-text": "#002a35",
        "components-button-color-outline-tonal-pressed-stroke": "#00bae8",
        "components-button-color-outline-tonal-pressed-icon": "#002a35",
        "components-button-color-outline-tonal-disabled-bg": "rgba(222, 248, 255, 0.00)",
        "components-button-color-outline-tonal-disabled-text": "rgba(0, 108, 135, 0.38)",
        "components-button-color-outline-tonal-disabled-stroke": "rgba(0, 186, 232, 0.38)",
        "components-button-color-outline-tonal-disabled-icon": "rgba(0, 108, 135, 0.38)",
        "components-button-color-outline-tonal-focus-bg": "#def8ff",
        "components-button-color-outline-tonal-focus-text": "#006c87",
        "components-button-color-outline-tonal-focus-stroke": "#00bae8",
        "components-button-color-outline-tonal-focus-icon": "#006c87",
        "components-button-color-outline-tonal-focus-outline": "#1c4cae",
        "components-button-color-outline-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-default-text": "#fcfcfd",
        "components-button-color-outline-white-default-stroke": "#fcfcfd",
        "components-button-color-outline-white-default-icon": "#fcfcfd",
        "components-button-color-outline-white-hover-bg": "#e8e8ea",
        "components-button-color-outline-white-hover-text": "#012167",
        "components-button-color-outline-white-hover-stroke": "#e8e8ea",
        "components-button-color-outline-white-hover-icon": "#012167",
        "components-button-color-outline-white-pressed-bg": "#a2a5ab",
        "components-button-color-outline-white-pressed-text": "#012167",
        "components-button-color-outline-white-pressed-stroke": "#a2a5ab",
        "components-button-color-outline-white-pressed-icon": "#012167",
        "components-button-color-outline-white-disabled-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-outline-white-disabled-text": "rgba(252, 252, 253, 0.38)",
        "components-button-color-outline-white-disabled-stroke": "rgba(252, 252, 253, 0.38)",
        "components-button-color-outline-white-disabled-icon": "rgba(252, 252, 253, 0.38)",
        "components-button-color-outline-white-focus-bg": "#032c87",
        "components-button-color-outline-white-focus-text": "#fcfcfd",
        "components-button-color-outline-white-focus-stroke": "#fcfcfd",
        "components-button-color-outline-white-focus-icon": "#fcfcfd",
        "components-button-color-outline-white-focus-outline": "#286efa",
        "components-button-color-text-brand-default-bg": "rgba(232, 232, 234, 0.00)",
        "components-button-color-text-brand-default-text": "#0241cd",
        "components-button-color-text-brand-default-stroke": "#0241cd",
        "components-button-color-text-brand-default-icon": "#0241cd",
        "components-button-color-text-brand-hover-bg": "rgba(232, 232, 234, 0.00)",
        "components-button-color-text-brand-hover-bg-icon-only": "#f2f6fd",
        "components-button-color-text-brand-hover-text": "#0241cd",
        "components-button-color-text-brand-hover-stroke": "#0241cd",
        "components-button-color-text-brand-hover-icon": "#0241cd",
        "components-button-color-text-brand-pressed-bg": "rgba(232, 232, 234, 0.00)",
        "components-button-color-text-brand-pressed-bg-icon-only": "#678de1",
        "components-button-color-text-brand-pressed-text": "#0241cd",
        "components-button-color-text-brand-pressed-stroke": "#0241cd",
        "components-button-color-text-brand-pressed-icon": "#0241cd",
        "components-button-color-text-brand-disabled-bg": "rgba(232, 232, 234, 0.00)",
        "components-button-color-text-brand-disabled-text": "rgba(2, 65, 205, 0.38)",
        "components-button-color-text-brand-disabled-stroke": "rgba(2, 65, 205, 0.38)",
        "components-button-color-text-brand-disabled-icon": "rgba(2, 65, 205, 0.38)",
        "components-button-color-text-brand-focus-bg": "#d5e3ff",
        "components-button-color-text-brand-focus-text": "#032c87",
        "components-button-color-text-brand-focus-stroke": "#0239b4",
        "components-button-color-text-brand-focus-icon": "#1c4cae",
        "components-button-color-text-brand-focus-outline": "#1c4cae",
        "components-button-color-text-tonal-default-bg": "rgba(222, 248, 255, 0.00)",
        "components-button-color-text-tonal-default-text": "#fcfcfd",
        "components-button-color-text-tonal-default-stroke": "#00ccff",
        "components-button-color-text-tonal-default-icon": "#00ccff",
        "components-button-color-text-tonal-hover-bg": "rgba(222, 248, 255, 0.00)",
        "components-button-color-text-tonal-hover-bg-icon-only": "#678de1",
        "components-button-color-text-tonal-hover-text": "#fcfcfd",
        "components-button-color-text-tonal-hover-stroke": "#00ccff",
        "components-button-color-text-tonal-hover-icon": "#00ccff",
        "components-button-color-text-tonal-pressed-bg": "rgba(222, 248, 255, 0.00)",
        "components-button-color-text-tonal-pressed-bg-icon-only": "#678de1",
        "components-button-color-text-tonal-pressed-text": "#fcfcfd",
        "components-button-color-text-tonal-pressed-stroke": "#00ccff",
        "components-button-color-text-tonal-pressed-icon": "#00ccff",
        "components-button-color-text-tonal-disabled-bg": "rgba(222, 248, 255, 0.00)",
        "components-button-color-text-tonal-disabled-text": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-tonal-disabled-stroke": "rgba(0, 204, 255, 0.38)",
        "components-button-color-text-tonal-disabled-icon": "rgba(0, 204, 255, 0.38)",
        "components-button-color-text-tonal-focus-bg": "#d5e3ff",
        "components-button-color-text-tonal-focus-text": "#fcfcfd",
        "components-button-color-text-tonal-focus-stroke": "#00ccff",
        "components-button-color-text-tonal-focus-icon": "#1c4cae",
        "components-button-color-text-tonal-focus-outline": "#1c4cae",
        "components-button-color-text-white-default-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-default-text": "#fcfcfd",
        "components-button-color-text-white-default-stroke": "#fcfcfd",
        "components-button-color-text-white-default-icon": "#fcfcfd",
        "components-button-color-text-white-hover-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-hover-bg-icon-only": "#678de1",
        "components-button-color-text-white-hover-text": "#fcfcfd",
        "components-button-color-text-white-hover-stroke": "#fcfcfd",
        "components-button-color-text-white-hover-icon": "#fcfcfd",
        "components-button-color-text-white-pressed-bg": "rgba(255, 255, 255, 0.00)",
        "components-button-color-text-white-pressed-bg-icon-only": "#678de1",
        "components-button-color-text-white-pressed-text": "#fcfcfd",
        "components-button-color-text-white-pressed-stroke": "#fcfcfd",
        "components-button-color-text-white-pressed-icon": "#fcfcfd",
        "components-button-color-text-white-disabled-bg": "rgba(115, 119, 128, 0.00)",
        "components-button-color-text-white-disabled-text": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-stroke": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-disabled-icon": "rgba(252, 252, 253, 0.38)",
        "components-button-color-text-white-focus-bg": "#d5e3ff",
        "components-button-color-text-white-focus-text": "#fcfcfd",
        "components-button-color-text-white-focus-stroke": "#fcfcfd",
        "components-button-color-text-white-focus-icon": "#1c4cae",
        "components-button-color-text-white-focus-outline": "#1c4cae",
        "components-button-group-color-border": "#e8e8ea",
        "components-button-group-color-button-bg-default": "rgba(255, 255, 255, 0.00)",
        "components-button-group-color-button-fg-default": "#0241cd",
        "components-button-group-color-button-bg-hover": "#f2f6fd",
        "components-button-group-color-button-fg-hover": "#0239b4",
        "components-button-group-color-button-bg-active": "#f2f6fd",
        "components-button-group-color-button-fg-active": "#012167",
        "components-button-group-color-overlay-left-gradient-fill-1": "#ffffff",
        "components-button-group-color-overlay-left-gradient-fill-2": "rgba(255, 255, 255, 0.50)",
        "components-button-group-color-stroke-default-gradient-fill-1": "rgba(255, 255, 255, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-2": "rgba(255, 255, 255, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-3": "rgba(255, 255, 255, 0.00)",
        "components-button-group-color-stroke-default-gradient-fill-4": "rgba(255, 255, 255, 0.00)",
        "components-button-group-color-stroke-hover-gradient-fill-1": "#f2f6fd",
        "components-button-group-color-stroke-hover-gradient-fill-2": "#f2f6fd",
        "components-button-group-color-stroke-hover-gradient-fill-3": "#f2f6fd",
        "components-button-group-color-stroke-hover-gradient-fill-4": "#f2f6fd",
        "components-button-group-color-stroke-active-gradient-fill-1": "#0241cd",
        "components-button-group-color-stroke-active-gradient-fill-2": "#f719b8",
        "components-button-group-color-stroke-active-gradient-fill-3": "#00ccff",
        "components-button-group-color-stroke-active-gradient-fill-4": "#48c249",
        "components-category-card-color-bg": "#ffffff",
        "components-category-card-color-content-bg": "#ffffff",
        "components-category-card-color-category-bg": "#00ccff",
        "components-category-card-color-category-bg-2": "#d22630",
        "components-category-card-color-category-bg-3": "#48c249",
        "components-category-card-color-category-bg-4": "#0241cd",
        "components-category-card-color-category-bg-5": "#032c87",
        "components-category-card-color-category-bg-6": "#792f9d",
        "components-category-card-color-title": "#032c87",
        "components-category-card-color-copy": "#27282b",
        "components-category-card-color-stroke": "#e8e8ea",
        "components-category-card-color-stroke-hover": "#0241cd",
        "components-category-card-listing-color-bg": "#ffffff",
        "components-category-card-listing-color-title": "#032c87",
        "components-category-card-listing-color-copy": "#27282b",
        "components-card-search-result-color-bg": "#ffffff",
        "components-card-search-result-color-label": "#27282b",
        "components-card-search-result-color-title": "#032c87",
        "components-card-search-result-color-body": "#27282b",
        "components-card-search-result-color-divider-line": "#e8e8ea",
        "components-contact-us-promo-color-light-bg": "#f2f6fd",
        "components-contact-us-promo-color-light-title": "#032c87",
        "components-contact-us-promo-color-light-body": "#27282b",
        "components-contact-us-promo-color-default-bg": "#ffffff",
        "components-contact-us-promo-color-default-title": "#032c87",
        "components-contact-us-promo-color-default-body": "#27282b",
        "components-contact-us-promo-color-brand-bg": "#0239b4",
        "components-contact-us-promo-color-brand-title": "#fcfcfd",
        "components-contact-us-promo-color-brand-body": "#f2f6fd",
        "components-content-list-color-bg": "#ffffff",
        "components-content-list-color-title": "#032c87",
        "components-content-list-color-body": "#27282b",
        "components-content-list-color-icon": "#72757e",
        "components-cookies-colors-bg": "#ffffff",
        "components-cookies-colors-title": "#032c87",
        "components-cookies-colors-body": "#27282b",
        "components-cookies-colors-icon": "#0241cd",
        "components-dropdown-bg": "#ffffff",
        "components-dropdown-border-default": "#72757e",
        "components-dropdown-border-hover": "#0241cd",
        "components-dropdown-border-focused": "#032c87",
        "components-dropdown-border-destructive": "#b32b30",
        "components-dropdown-label-text-default": "#191b1c",
        "components-dropdown-item-text-default": "#191b1c",
        "components-dropdown-item-bg-default": "#ffffff",
        "components-dropdown-item-bg-hover": "#f2f6fd",
        "components-dropdown-input-field-default": "#72757e",
        "components-dropdown-input-field-focused": "#191b1c",
        "components-dropdown-input-label-focused": "#032c87",
        "components-dropdown-input-field-destructive": "#b32b30",
        "components-external-link-card-bg": "#ffffff",
        "components-external-link-card-card-bg": "#ffffff",
        "components-external-link-card-card-title": "#032c87",
        "components-external-link-card-card-subtitle": "#27282b",
        "components-external-link-card-card-eyebrow": "#4e5056",
        "components-fab-color-border-default": "#fcfcfd",
        "components-footer-brand-color-bg": "#032c87",
        "components-footer-brand-color-logo": "#ffffff",
        "components-footer-brand-color-title-text": "#fcfcfd",
        "components-footer-brand-color-description-text": "#fcfcfd",
        "components-footer-brand-color-footer-menu-expanded": "#032c87",
        "components-footer-brand-color-link-default": "#fcfcfd",
        "components-footer-brand-color-link-hover": "#f2f6fd",
        "components-footer-brand-color-link-pressed": "#678de1",
        "components-footer-brand-color-category-link-default": "#fcfcfd",
        "components-footer-brand-color-category-link-hover": "#f2f6fd",
        "components-footer-brand-color-category-link-pressed": "#007a99",
        "components-footer-brand-color-subcategory-link-default": "#f2f6fd",
        "components-footer-brand-color-subcategory-link-hover": "#f2f6fd",
        "components-footer-brand-color-subcategory-link-pressed": "#2a5fd5",
        "components-footer-brand-color-icon-icon-default": "#f2f6fd",
        "components-footer-brand-color-icon-icon-pressed": "#678de1",
        "components-footer-brand-color-social-icon-default": "#678de1",
        "components-footer-brand-color-social-icon-hover": "#f2f6fd",
        "components-footer-brand-color-social-icon-pressed": "#2a5fd5",
        "components-footer-brand-spacing-category-link-hover": "#000000",
        "components-footer-global-color-bg": "#ffffff",
        "components-footer-global-color-title-text": "#000000",
        "components-footer-global-color-disclaimer-text": "#000000",
        "components-footer-global-color-link-default": "#000000",
        "components-footer-global-color-link-hover": "#000000",
        "components-footer-global-color-link-pressed": "#000000",
        "components-generic-card-color-bg": "#f2f6fd",
        "components-generic-card-color-content-bg": "#f2f6fd",
        "components-generic-card-color-title": "#032c87",
        "components-generic-card-color-subtitle": "#27282b",
        "components-generic-card-color-copy": "#27282b",
        "components-global-search-color-omnibox-list-bg": "#ffffff",
        "components-global-search-color-omnibox-divider": "#a2a5ab",
        "components-global-search-color-omnibox-item-bg-default": "#ffffff",
        "components-global-search-color-omnibox-item-bg-hover": "#f2f6fd",
        "components-global-search-color-omnibox-item-text-default": "#0241cd",
        "components-global-search-color-omnibox-item-text-hover": "#0239b4",
        "components-global-search-color-omnibox-label-text-default": "#191b1c",
        "components-global-search-color-omnibox-category-text-default": "#191b1c",
        "components-generic-card-listing-color-bg": "#ffffff",
        "components-generic-card-listing-color-title": "#032c87",
        "components-generic-card-listing-color-copy": "#27282b",
        "components-header-color-bg": "#ffffff",
        "components-header-color-bottom-stroke": "#e8e8ea",
        "components-header-color-search-search-border-default": "#f719b8",
        "components-header-color-search-search-text": "#0241cd",
        "components-header-color-search-search-icon": "#0241cd",
        "components-header-color-search-search-bar": "#f719b8",
        "components-header-color-mobile-mobile-expanded-bg": "#f2f6fd",
        "components-header-color-mobile-pressed-bg": "#ffffff",
        "components-header-color-language-selector-language": "#4e5056",
        "components-header-color-language-selector-country": "#0241cd",
        "components-header-color-language-selector-country-hover-bg": "#f2f6fd",
        "components-header-color-language-selector-megamenu-language": "#4e5056",
        "components-header-color-language-selector-megamenu-country": "#0241cd",
        "components-header-color-language-selector-megamenu-country-hover": "#f2f6fd",
        "components-header-color-language-selector-bg": "#ffffff",
        "components-header-color-language-selector-stroke": "#72757e",
        "components-header-color-mega-menu-bg": "#f2f6fd",
        "components-header-color-mega-menu-featured-bg": "#032c87",
        "components-header-color-mega-menu-featured-title": "#ffffff",
        "components-header-color-mega-menu-category-title": "#27282b",
        "components-header-color-mega-menu-bg-accent": "#f2f6fd",
        "components-header-color-icon-icon-default": "#0241cd",
        "components-header-color-icon-icon-hover": "#0239b4",
        "components-header-color-icon-icon-pressed": "#032c87",
        "components-header-color-links-nav-main-link-default": "#0241cd",
        "components-header-color-links-nav-main-link-hover": "#012167",
        "components-header-color-links-nav-main-link-pressed": "#012167",
        "components-header-color-links-nav-main-link-active": "#012167",
        "components-header-color-links-nav-main-active-bar": "#0241cd",
        "components-header-color-links-nav-main-bg-color": "#ffffff",
        "components-header-color-links-nav-category-link-category-default": "#032c87",
        "components-header-color-links-nav-category-link-category-hover": "#012167",
        "components-header-color-links-nav-category-link-category-pressed": "#0239b4",
        "components-header-color-links-nav-category-link-category-active": "#012167",
        "components-header-color-links-nav-menu-link-link-default": "#0241cd",
        "components-header-color-links-nav-menu-link-link-hover": "#012167",
        "components-header-color-links-nav-menu-link-link-pressed": "#0239b4",
        "components-header-color-links-nav-menu-link-link-active": "#012167",
        "components-header-color-links-nav-menu-link-bg": "#f2f6fd",
        "components-header-color-links-nav-subcategory-link-subcategory-default": "#032c87",
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": "#0239b4",
        "components-header-color-links-nav-subcategory-link-subcategory-active": "#012167",
        "components-hero-homepage-color-bg": "#0239b4",
        "components-hero-homepage-color-container-bg": "#0239b4",
        "components-hero-homepage-color-eyebrow": "#fcfcfd",
        "components-hero-homepage-color-header": "#fcfcfd",
        "components-hero-homepage-color-sub-header": "#f2f6fd",
        "components-hero-homepage-color-body": "#f2f6fd",
        "components-hero-homepage-color-play-pause": "#ffffff",
        "components-hero-internal-color-default-title": "#fcfcfd",
        "components-hero-internal-color-default-body": "#f2f6fd",
        "components-hero-internal-color-default-bg": "#032c87",
        "components-hero-internal-color-accent-bg": "#0241cd",
        "components-hero-internal-color-with-image-bg": "#0239b4",
        "components-hero-internal-color-default-bg-2": "#032c87",
        "components-hero-internal-color-with-image-title": "#fcfcfd",
        "components-hero-internal-color-with-image-body": "#f2f6fd",
        "components-heading-page-title-color-default-bg": "#ffffff",
        "components-heading-page-title-color-default-title": "#032c87",
        "components-heading-page-title-color-default-body": "#27282b",
        "components-heading-page-title-color-light-bg": "#f2f6fd",
        "components-heading-page-title-color-light-title": "#032c87",
        "components-heading-page-title-color-light-body": "#27282b",
        "components-heading-page-title-color-color-bg": "#032c87",
        "components-heading-page-title-color-color-title": "#f2f6fd",
        "components-heading-page-title-color-color-body": "#fcfcfd",
        "components-hyperlink-color-dark-default": "#2a5fd5",
        "components-hyperlink-color-dark-hover": "#0241cd",
        "components-hyperlink-color-dark-visited": "#032c87",
        "components-hyperlink-color-light-default": "#def8ff",
        "components-hyperlink-color-light-hover": "#2ed5ff",
        "components-hyperlink-color-light-visited": "#009ac1",
        "components-icon-default": "#72757e",
        "components-icon-focused": "#72757e",
        "components-icon-card-color-bg": "#ffffff",
        "components-icon-card-color-icon-bg": "#032c87",
        "components-icon-card-color-icon": "#ffffff",
        "components-icon-card-color-title": "#032c87",
        "components-icon-card-color-subtitle": "#27282b",
        "components-icon-card-color-description": "#27282b",
        "components-icon-card-listing-color-bg": "#ffffff",
        "components-icon-card-listing-color-inner-bg": "#ffffff",
        "components-icon-card-listing-color-brand-bg": "#0241cd",
        "components-icon-card-listing-color-title": "#032c87",
        "components-icon-card-listing-color-copy": "#27282b",
        "components-icon-card-listing-color-inner-legal-text": "#f2f6fd",
        "components-jumplink-color-bg-default": "#ffffff",
        "components-jumplink-color-link-text-default": "#4e5056",
        "components-jumplink-color-link-text-hover": "#032c87",
        "components-jumplink-color-link-border-hover": "#032c87",
        "components-jumplink-color-link-text-active": "#0241cd",
        "components-jumplink-color-link-border-active": "#0241cd",
        "components-jumplink-color-title-text": "#032c87",
        "components-jumplink-color-description-text": "#27282b",
        "components-layout-bg-default": "#ffffff",
        "components-lightbox-color-overlay-bg": "rgba(255, 255, 255, 0.95)",
        "components-lightbox-color-title": "#032c87",
        "components-lightbox-color-body": "#27282b",
        "components-label-color-on-color-label-label-default": "#fcfcfd",
        "components-label-color-on-color-label-label-hover": "#fcfcfd",
        "components-label-color-on-color-label-label-pressed": "#fcfcfd",
        "components-label-color-on-color-background-bg-default": "#0241cd",
        "components-label-color-on-color-background-bg-hover": "#0241cd",
        "components-label-color-on-color-background-bg-pressed": "#032c87",
        "components-label-color-on-color-stroke-hover": "#0241cd",
        "components-label-color-on-color-stroke-pressed": "#032c87",
        "components-label-color-on-color-stroke-default-gradient-fill-1": "#0241cd",
        "components-label-color-on-color-stroke-default-gradient-fill-2": "#f719b8",
        "components-label-color-on-color-stroke-default-gradient-fill-3": "#00ccff",
        "components-label-color-on-color-stroke-default-gradient-fill-4": "#48c249",
        "components-label-color-on-white-label-label-default": "#0241cd",
        "components-label-color-on-white-label-label-hover": "#fcfcfd",
        "components-label-color-on-white-label-label-pressed": "#fcfcfd",
        "components-label-color-on-white-background-bg-default": "rgba(255, 255, 255, 0.00)",
        "components-label-color-on-white-background-bg-hover": "#0241cd",
        "components-label-color-on-white-background-bg-pressed": "#032c87",
        "components-label-color-on-white-stroke-hover": "#0241cd",
        "components-label-color-on-white-stroke-pressed": "#032c87",
        "components-label-color-on-white-stroke-default-gradient-fill-1": "#0241cd",
        "components-label-color-on-white-stroke-default-gradient-fill-2": "#f719b8",
        "components-label-color-on-white-stroke-default-gradient-fill-3": "#00ccff",
        "components-label-color-on-white-stroke-default-gradient-fill-4": "#48c249",
        "components-legal-disclaimer-color-default-bg": "#ffffff",
        "components-legal-disclaimer-color-default-title": "#032c87",
        "components-legal-disclaimer-color-default-body": "#27282b",
        "components-legal-disclaimer-color-light-bg": "#f2f6fd",
        "components-legal-disclaimer-color-light-title": "#032c87",
        "components-legal-disclaimer-color-light-body": "#27282b",
        "components-legal-disclaimer-color-brand-bg": "#032c87",
        "components-legal-disclaimer-color-brand-title": "#fcfcfd",
        "components-legal-disclaimer-color-brand-body": "#f2f6fd",
        "components-media-video-thumbnail-overlay-bg": "rgba(3, 44, 135, 0.80)",
        "components-media-caption-color-bg": "#fcfcfd",
        "components-media-caption-color-text": "#27282b",
        "components-media-gallery-color-bg": "#ffffff",
        "components-media-gallery-color-title": "#032c87",
        "components-media-gallery-color-body": "#27282b",
        "components-pagination-on-white-accent-default": "#0241cd",
        "components-pagination-on-white-accent-scroll": "#0241cd",
        "components-pagination-on-white-disabled": "#a2a5ab",
        "components-pagination-on-white-bg": "#e8e8ea",
        "components-pagination-on-white-stroke": "#e8e8ea",
        "components-pagination-on-white-bg-active": "#ebfbff",
        "components-pagination-on-white-bg-hover": "#f2f6fd",
        "components-pagination-on-white-text-default": "#72757e",
        "components-pagination-on-white-text-hover": "#0241cd",
        "components-pagination-on-white-text-active": "#003d4d",
        "components-pagination-on-color-accent-default": "#00ccff",
        "components-pagination-on-color-accent-scroll": "#00ccff",
        "components-pagination-on-color-disabled": "#012167",
        "components-pagination-on-color-bg": "#012167",
        "components-pagination-on-color-stroke": "#2a5fd5",
        "components-pagination-on-color-bg-active": "#0239b4",
        "components-pagination-on-color-bg-hover": "#ffffff",
        "components-pagination-on-color-text-default": "#ffffff",
        "components-pagination-on-color-text-hover": "#0241cd",
        "components-pagination-on-color-text-active": "#ffffff",
        "components-pdf-link-card-color-bg": "#ffffff",
        "components-pdf-link-card-color-card-bg": "#ffffff",
        "components-pdf-link-card-color-card-title": "#032c87",
        "components-pdf-link-card-color-card-subtitle": "#27282b",
        "components-pdf-link-card-color-card-body": "#27282b",
        "components-product-card-color-bg": "#f2f6fd",
        "components-product-card-color-content-bg": "#f2f6fd",
        "components-product-card-color-border": "#e8e8ea",
        "components-product-card-color-title": "#032c87",
        "components-product-card-color-description": "#27282b",
        "components-product-card-color-star-rating": "#f719b8",
        "components-product-card-color-badge-bg": "#c11490",
        "components-product-card-color-badge-text": "#ffffff",
        "components-product-card-listing-color-bg": "#032c87",
        "components-product-card-listing-color-title": "#fcfcfd",
        "components-product-card-listing-color-copy": "#f2f6fd",
        "components-product-card-listing-color-legal-text": "#27282b",
        "components-product-information-color-bg": "#ffffff",
        "components-product-information-color-content-bg": "#ffffff",
        "components-product-information-color-headline": "#032c87",
        "components-product-information-color-body": "#27282b",
        "components-product-information-color-detail-text": "#27282b",
        "components-product-information-color-divider": "#a2a5ab",
        "components-product-information-color-icon": "#0241cd",
        "components-product-information-color-play-icon": "#ffffff",
        "components-product-information-color-selected-stroke": "#0241cd",
        "components-product-results-list-with-search-color-bg": "#ffffff",
        "components-promo-color-default-bg": "#ffffff",
        "components-promo-color-default-image-bg": "#ffffff",
        "components-promo-color-default-content-bg": "#ffffff",
        "components-promo-color-default-title": "#032c87",
        "components-promo-color-default-body": "#27282b",
        "components-promo-color-default-category": "#032c87",
        "components-promo-color-light-bg": "#f2f6fd",
        "components-promo-color-light-image-bg": "#f2f6fd",
        "components-promo-color-light-content-bg": "#f2f6fd",
        "components-promo-color-light-title": "#032c87",
        "components-promo-color-light-body": "#27282b",
        "components-promo-color-light-category": "#032c87",
        "components-promo-color-brand-bg": "#0239b4",
        "components-promo-color-brand-image-bg": "#0239b4",
        "components-promo-color-brand-content-bg": "#0239b4",
        "components-promo-color-brand-title": "#fcfcfd",
        "components-promo-color-brand-body": "#f2f6fd",
        "components-promo-color-brand-category": "#f2f6fd",
        "components-pull-quote-color-bg": "#032c87",
        "components-pull-quote-color-stroke": "#ffffff",
        "components-pull-quote-color-quote-bg": "#ffffff",
        "components-pull-quote-color-quote-title": "#032c87",
        "components-pull-quote-color-quote-by-text": "#27282b",
        "components-pull-quote-color-quote-detail": "#72757e",
        "components-pull-quote-color-quote-highlight": "#f719b8",
        "components-promo-alternate-color-default-bg": "#ffffff",
        "components-promo-alternate-color-default-image-bg": "#ffffff",
        "components-promo-alternate-color-default-content-bg": "#ffffff",
        "components-promo-alternate-color-default-title": "#032c87",
        "components-promo-alternate-color-default-body": "#27282b",
        "components-promo-alternate-color-default-category": "#032c87",
        "components-promo-alternate-color-light-bg": "#f2f6fd",
        "components-promo-alternate-color-light-image-bg": "#f2f6fd",
        "components-promo-alternate-color-light-content-bg": "#f2f6fd",
        "components-promo-alternate-color-light-title": "#032c87",
        "components-promo-alternate-color-light-body": "#27282b",
        "components-promo-alternate-color-light-category": "#032c87",
        "components-promo-alternate-color-brand-bg": "#0239b4",
        "components-promo-alternate-color-brand-image-bg": "#0239b4",
        "components-promo-alternate-color-brand-content-bg": "#0239b4",
        "components-promo-alternate-color-brand-title": "#fcfcfd",
        "components-promo-alternate-color-brand-body": "#f2f6fd",
        "components-promo-alternate-color-brand-category": "#f2f6fd",
        "components-share-list-color-title": "#27282b",
        "components-share-list-color-bg": "#fcfcfd",
        "components-share-list-color-link-default": "#27282b",
        "components-share-list-color-link-hover": "#0241cd",
        "components-share-list-color-bg-link-hover": "#e8e8ea",
        "components-share-list-color-close-icon": "#27282b",
        "components-share-list-color-facebook-icon": "#3b5998",
        "components-share-list-color-pinterest-icon": "#e60023",
        "components-share-list-color-twitter-icon": "#000000",
        "components-share-list-color-basic-icon-default": "#27282b",
        "components-share-list-color-icon-hover": "#0241cd",
        "components-share-list-color-border": "#e8e8ea",
        "components-site-search-bg": "#ffffff",
        "components-site-search-results_text": "#27282b",
        "components-site-search-filter-filter-selected-bg": "#f2f6fd",
        "components-site-search-facet-group-label-bottom-line": "#e8e8ea",
        "components-site-search-facet-group-label-text": "#27282b",
        "components-site-search-facet-group-label-bg": "#fcfcfd",
        "components-site-search-facet-bg": "#ffffff",
        "components-site-search-facet-stroke": "#e8e8ea",
        "components-site-search-refine-bg": "#ffffff",
        "components-site-search-refine-right-divider": "#f2f6fd",
        "components-site-search-refine-title": "#032c87",
        "components-site-search-hero-search-bg": "#032c87",
        "components-site-search-hero-search-accent-bg": "#0241cd",
        "components-site-search-hero-search-title": "#fcfcfd",
        "components-stats-stat-text": "#032c87",
        "components-stats-stat-text-on-light": "#032c87",
        "components-stats-supporting-text": "#27282b",
        "components-stats-bg-default": "#ffffff",
        "components-stats-bg-inner": "#ffffff",
        "components-stats-bg-section-inner": "#032c87",
        "components-stats-title": "#032c87",
        "components-stats-copy": "#27282b",
        "components-supporting-text-default": "#4e5056",
        "components-supporting-text-destructive": "#b32b30",
        "components-tab-bg": "#ffffff",
        "components-tab-border-default": "#72757e",
        "components-tab-border-hover": "#0241cd",
        "components-tab-border-focused": "#032c87",
        "components-tab-border-destructive": "#b32b30",
        "components-tab-label-text-default": "#191b1c",
        "components-tab-item-text-default": "#191b1c",
        "components-tab-item-bg-default": "#ffffff",
        "components-tab-item-bg-hover": "#f2f6fd",
        "components-tab-input-field-default": "#72757e",
        "components-tab-input-field-focused": "#191b1c",
        "components-tab-input-label-focused": "#032c87",
        "components-tab-input-field-destructive": "#b32b30",
        "components-tab-bg-2": "#ffffff",
        "components-tag-color-bg": "#ffffff",
        "components-tag-color-text": "#032c87",
        "components-tag-color-stroke": "#678de1",
        "components-tag-color-bg-hover": "#0241cd",
        "components-tag-color-text-hover": "#fcfcfd",
        "components-tag-color-stroke-hover": "#0241cd",
        "components-text-field-bg": "#ffffff",
        "components-text-field-bg-focused": "#f2f6fd",
        "components-text-field-border-default": "#72757e",
        "components-text-field-border-hover": "#0241cd",
        "components-text-field-border-focused": "#032c87",
        "components-text-field-border-destructive": "#b32b30",
        "components-text-field-input-field-default": "#72757e",
        "components-text-field-input-field-focused": "#191b1c",
        "components-text-field-input-label-focused": "#032c87",
        "components-text-field-input-field-destructive": "#b32b30",
        "components-text-field-icon-default": "#72757e",
        "components-text-field-icon-destructive": "#b32b30",
        "components-text-field-supporting-text-default": "#4e5056",
        "components-text-field-supporting-text-destructive": "#b32b30",
        "components-tip-color-bg": "#f2f6fd",
        "components-tip-color-title": "#032c87",
        "components-tip-color-body": "#27282b",
        "components-tip-color-stroke": "#678de1",
        "components-video-color-bg": "#ffffff",
        "themes-general-surface-color-light-bg": "#ffffff",
        "themes-general-surface-color-light-title": "#032c87",
        "themes-general-surface-color-light-body": "#27282b",
        "themes-general-surface-color-light-decor-line-break": "#f719b8",
        "themes-general-surface-color-light-rule-line": "#f719b8",
        "themes-general-surface-color-dark-bg": "#0239b4",
        "themes-general-surface-color-dark-title": "#fcfcfd",
        "themes-general-surface-color-dark-body": "#f2f6fd",
        "themes-general-surface-color-dark-decor-line-break": "#f719b8",
        "themes-general-card-color-dark-title": "#032c87",
        "themes-general-card-color-dark-subtitle": "#27282b",
        "themes-general-card-color-dark-body": "#27282b",
        "themes-general-card-color-light-title": "#fcfcfd",
        "themes-general-card-color-light-subtitle": "#f2f6fd",
        "themes-general-card-color-light-body": "#f2f6fd",
        "themes-shadow-color": "#ffffff",
        "themes-shadow-share-list-color-fill": "rgba(0, 0, 0, 0.40)",
        "themes-shadow-header-menu-color-fill": "rgba(0, 0, 0, 0.20)",
        "themes-gradient-loader-gradient-fill-1": "#0241cd",
        "themes-gradient-loader-gradient-fill-2": "rgba(2, 65, 205, 0.00)",
        "themes-color-background-white": "#ffffff",
        "themes-color-background-light": "#f2f6fd",
        "themes-color-background-dark": "#27282b",
        "themes-color-background-black": "#032c87",
        "themes-color-background-brand1": "#0241cd",
        "themes-color-background-brand2": "#00ccff",
        "themes-color-background-accent1": "#f719b8",
        "themes-color-background-accent2": "#48c249",
        "themes-color-background-body": "#ffffff",
        "themes-color-stroke-white": "#fcfcfd",
        "themes-color-stroke-light": "#e8e8ea",
        "themes-color-stroke-dark": "#27282b",
        "themes-color-stroke-black": "#032c87",
        "themes-color-stroke-brand1": "#0241cd",
        "themes-color-stroke-brand2": "#00ccff",
        "themes-color-stroke-accent1": "#f719b8",
        "themes-color-stroke-accent2": "#48c249",
        "themes-color-text-white": "#fcfcfd",
        "themes-color-text-light": "#f2f6fd",
        "themes-color-text-dark": "#27282b",
        "themes-color-text-black": "#032c87",
        "themes-color-text-brand1": "#0241cd",
        "themes-color-text-brand2": "#00ccff",
        "themes-color-text-accent1": "#f719b8",
        "themes-color-text-accent2": "#246125",
        "colors-grayscale-100": "#fcfcfd",
        "colors-grayscale-200": "#e8e8ea",
        "colors-grayscale-300": "#a2a5ab",
        "colors-grayscale-400": "#72757e",
        "colors-grayscale-500": "#4e5056",
        "colors-grayscale-600": "#27282b",
        "colors-grayscale-700": "#191b1c",
        "colors-brand-1-100": "#f2f6fd",
        "colors-brand-1-200": "#678de1",
        "colors-brand-1-300": "#2a5fd5",
        "colors-brand-1-400": "#0241cd",
        "colors-brand-1-500": "#0239b4",
        "colors-brand-1-600": "#032c87",
        "colors-brand-1-700": "#012167",
        "colors-brand-2-100": "#ebfbff",
        "colors-brand-2-200": "#66e0ff",
        "colors-brand-2-300": "#2ed5ff",
        "colors-brand-2-400": "#00ccff",
        "colors-brand-2-500": "#00abd6",
        "colors-brand-2-600": "#007a99",
        "colors-brand-2-700": "#003d4d",
        "colors-accent-1-100": "#fff0fa",
        "colors-accent-1-200": "#fca3e3",
        "colors-accent-1-300": "#fa67d0",
        "colors-accent-1-400": "#f719b8",
        "colors-accent-1-500": "#d916b8",
        "colors-accent-1-600": "#ad1281",
        "colors-accent-1-700": "#7c0d5c",
        "colors-accent-2-100": "#f6fcf6",
        "colors-accent-2-200": "#91da92",
        "colors-accent-2-300": "#65cc66",
        "colors-accent-2-400": "#48c249",
        "colors-accent-2-500": "#3fab40",
        "colors-accent-2-600": "#328833",
        "colors-accent-2-700": "#246125",
        "colors-accent-3-100": "#f9f5fb",
        "colors-accent-3-200": "#b986d1",
        "colors-accent-3-300": "#9d55be",
        "colors-accent-3-400": "#8a35b2",
        "colors-accent-3-500": "#792f9d",
        "colors-accent-3-600": "#61257d",
        "colors-accent-3-700": "#451b59",
        "colors-accent-4-400_storage": "#d22630",
        "colors-accessible-accent-1-100": "#fef0fa",
        "colors-accessible-accent-1-200": "#fcaee6",
        "colors-accessible-accent-1-300": "#fa70d3",
        "colors-accessible-accent-1-400": "#db16a3",
        "colors-accessible-accent-1-500": "#c11490",
        "colors-accessible-accent-1-600": "#9a1073",
        "colors-accessible-accent-1-700": "#710b54",
        "colors-accessible-accent-2-100": "#f1faf1",
        "colors-accessible-accent-2-200": "#4ac34b",
        "colors-accessible-accent-2-300": "#3b9f3c",
        "colors-accessible-accent-2-400": "#328733",
        "colors-accessible-accent-2-500": "#2c772d",
        "colors-accessible-accent-2-600": "#235e23",
        "colors-accessible-accent-2-700": "#19441a",
        "colors-accessible-brand-2-100": "#def8ff",
        "colors-accessible-brand-2-200": "#00bae8",
        "colors-accessible-brand-2-300": "#009ac1",
        "colors-accessible-brand-2-400": "#0081a2",
        "colors-accessible-brand-2-500": "#006c87",
        "colors-accessible-brand-2-600": "#004e61",
        "colors-accessible-brand-2-700": "#002a35"
      },
      "spacing": {
        "components-accordion-spacing-large-padding-x": "5rem",
        "components-accordion-spacing-large-icon-margin-right": "2rem",
        "components-accordion-spacing-large-copy-margin-right": "2.5rem",
        "components-accordion-spacing-large-maxwidth": "59.625rem",
        "components-accordion-spacing-large-section-padding-x": "0rem",
        "components-accordion-spacing-large-section-padding-y": "2.5rem",
        "components-accordion-spacing-large-section-copy-margin-bottom": "2.5rem",
        "components-accordion-spacing-large-section-space-between": "4rem",
        "components-accordion-spacing-large-item-padding-x": "0rem",
        "components-accordion-spacing-large-item-padding-y": "1.5rem",
        "components-accordion-spacing-large-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-large-content-padding-x": "0rem",
        "components-accordion-spacing-large-content-padding-y": "2.5rem",
        "components-accordion-spacing-large-content-title-margin-bottom": "2.5rem",
        "components-accordion-spacing-large-content-content-space-between": "4rem",
        "components-accordion-spacing-large-content-content-margin-left": "1rem",
        "components-accordion-spacing-small-padding-top": "1rem",
        "components-accordion-spacing-small-padding-x": "1.5rem",
        "components-accordion-spacing-small-padding-y": "2.5rem",
        "components-accordion-spacing-small-icon-margin-right": "0.75rem",
        "components-accordion-spacing-small-title-margin-bottom": "0.5rem",
        "components-accordion-spacing-small-copy-margin-right": "2rem",
        "components-accordion-spacing-small-copy-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-padding-x": "0rem",
        "components-accordion-spacing-small-section-padding-y": "0rem",
        "components-accordion-spacing-small-section-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-section-space-between": "2.5rem",
        "components-accordion-spacing-small-item-padding-x": "0rem",
        "components-accordion-spacing-small-item-padding-y": "1rem",
        "components-accordion-spacing-small-item-item-space-between": "-0.125rem",
        "components-accordion-spacing-small-content-padding-x": "0rem",
        "components-accordion-spacing-small-content-padding-y": "0.75rem",
        "components-accordion-spacing-small-content-title-margin-bottom": "1rem",
        "components-accordion-spacing-small-content-content-space-between": "1rem",
        "components-accordion-spacing-small-content-content-margin-left": "1rem",
        "components-article-card-spacing-large-padding-image-x": "0.75rem",
        "components-article-card-spacing-large-padding-image-y": "0.75rem",
        "components-article-card-spacing-large-padding-content-x": "1.5rem",
        "components-article-card-spacing-large-padding-content-y": "1.5rem",
        "components-article-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-article-card-spacing-large-button-space-between": "1rem",
        "components-article-card-spacing-large-title-margin-bottom": "1rem",
        "components-article-card-spacing-large-list-content-gap": "0rem",
        "components-article-card-spacing-large-list-image-padding-x": "1rem",
        "components-article-card-spacing-large-list-image-padding-y": "1rem",
        "components-article-card-spacing-small-padding-image-x": "0.75rem",
        "components-article-card-spacing-small-padding-image-y": "0.75rem",
        "components-article-card-spacing-small-padding-content-x": "1.5rem",
        "components-article-card-spacing-small-padding-content-y": "1.5rem",
        "components-article-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-article-card-listing-spacing-small-padding-x": "1.5rem",
        "components-article-card-listing-spacing-small-padding-y": "2.5rem",
        "components-article-hero-spacing-large-padding-x": "0rem",
        "components-article-hero-spacing-large-padding-y": "0rem",
        "components-article-hero-spacing-large-title-margin-bottom": "2rem",
        "components-article-hero-spacing-large-label-share-margin-bottom": "2rem",
        "components-article-hero-spacing-large-author-name-margin-bottom": "0.75rem",
        "components-article-hero-spacing-large-author-thumbnail-margin-left": "1rem",
        "components-article-hero-spacing-large-details-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-space-between": "1rem",
        "components-article-hero-spacing-large-body-margin-bottom": "2rem",
        "components-article-hero-spacing-large-image-space-between": "1rem",
        "components-article-hero-spacing-large-image-margin-bottom": "1rem",
        "components-article-hero-spacing-large-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-large-stats-margin-bottom": "2.5rem",
        "components-article-hero-spacing-small-content-margin-top": "2.5rem",
        "components-article-hero-spacing-small-title-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-label-share-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-authorname-margin-bottom": "0.75rem",
        "components-article-hero-spacing-small-authorthumbnail-margin-left": "1rem",
        "components-article-hero-spacing-small-details-margin-bottom": "1rem",
        "components-article-hero-spacing-small-space-between": "0.75rem",
        "components-article-hero-spacing-small-body-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-image-space-between": "0.75rem",
        "components-article-hero-spacing-small-image-margin-bottom": "1rem",
        "components-article-hero-spacing-small-gallery-margin-bottom": "1.5rem",
        "components-article-hero-spacing-small-stats-margin-bottom": "2rem",
        "components-banner-spacing-large-content-padding-x": "1.5rem",
        "components-banner-spacing-large-content-space-between": "1rem",
        "components-banner-spacing-large-content-width": "69.375rem",
        "components-banner-spacing-icon-dimensions": "1.5rem",
        "components-banner-spacing-icon-margin-left": "0.5rem",
        "components-banner-spacing-small-space-between": "1rem",
        "components-banner-spacing-small-content-space-between": "1rem",
        "components-banner-spacing-small-title-margin-bottom": "0.75rem",
        "components-banner-spacing-small-content-margin-bottom": "1rem",
        "components-breadcrumb-spacing-large-space-between": "0.25rem",
        "components-breadcrumb-spacing-small-space-between": "0.25rem",
        "components-button-spacing-large-icon-large-x": "2.5rem",
        "components-button-spacing-large-icon-large-y": "2.5rem",
        "components-button-spacing-large-default-padding-y": "0.75rem",
        "components-button-spacing-large-default-padding-x": "1.5rem",
        "components-button-spacing-large-default-space-between": "0.25rem",
        "components-button-spacing-large-icon-left-padding-left": "1rem",
        "components-button-spacing-large-icon-right-padding-right": "1rem",
        "components-button-spacing-large-icon-only-padding-x": "0.75rem",
        "components-button-spacing-large-icon-only-padding-y": "0.75rem",
        "components-button-spacing-large-tertiary-content-height": "1.5rem",
        "components-button-spacing-compressed-icon-large-x": "2.5rem",
        "components-button-spacing-compressed-icon-large-y": "2.5rem",
        "components-button-spacing-compressed-default-padding-y": "0.5rem",
        "components-button-spacing-compressed-default-padding-x": "1rem",
        "components-button-spacing-compressed-default-space-between": "0.125rem",
        "components-button-spacing-compressed-icon-left-padding-left": "0.5rem",
        "components-button-spacing-compressed-icon-right-padding-right": "0.5rem",
        "components-button-spacing-compressed-icon-only-padding-x": "0.5rem",
        "components-button-group-spacing-padding-y": "0.125rem",
        "components-button-group-spacing-padding-x": "0.25rem",
        "components-button-group-spacing-space-between": "0.5rem",
        "components-category-card-spacing-large-padding-top": "0rem",
        "components-category-card-spacing-large-padding-x": "0rem",
        "components-category-card-spacing-large-padding-y": "0rem",
        "components-category-card-spacing-large-padding-image-x": "0rem",
        "components-category-card-spacing-large-padding-image-y": "0rem",
        "components-category-card-spacing-large-padding-content-x": "2rem",
        "components-category-card-spacing-large-padding-content-y": "2rem",
        "components-category-card-listing-spacing-large-padding-y": "5rem",
        "components-category-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-category-card-listing-spacing-large-card-margin-bottom": "0rem",
        "components-category-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-card-search-result-small-padding-top": "0rem",
        "components-card-search-result-small-padding-x": "0rem",
        "components-card-search-result-small-padding-y": "0rem",
        "components-card-search-result-small-padding-image-x": "0rem",
        "components-card-search-result-small-padding-image-y": "0rem",
        "components-card-search-result-small-padding-content-x": "1.5rem",
        "components-card-search-result-small-padding-content-y": "1.5rem",
        "components-card-search-result-spacing-large-padding-x": "0rem",
        "components-card-search-result-spacing-large-padding-y": "1.5rem",
        "components-card-search-result-spacing-large-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-large-image-margin-right": "1.5rem",
        "components-card-search-result-spacing-small-padding-x": "1.5rem",
        "components-card-search-result-spacing-small-padding-y": "1.5rem",
        "components-card-search-result-spacing-small-eyebrow-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-title-margin-bottom": "1.5rem",
        "components-card-search-result-spacing-small-image-margin-right": "0.75rem",
        "components-contact-us-promo-spacing-large-padding-x": "5rem",
        "components-contact-us-promo-spacing-large-padding-y": "5rem",
        "components-contact-us-promo-spacing-large-content-margin-right": "5rem",
        "components-contact-us-promo-spacing-large-margin-y": "2.5rem",
        "components-contact-us-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-contact-us-promo-spacing-small-margin-y": "2.5rem",
        "components-content-list-list-item-container-padding-x": "0.75rem",
        "components-content-list-list-item-container-padding-y": "1rem",
        "components-content-list-list-item-content-space-between": "0.5rem",
        "components-content-list-list-item-content-default-padding-y": "0.25rem",
        "components-content-list-list-item-content-hyperlink-padding-y": "0rem",
        "components-content-list-spacing-large-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-large-item-title-margin-bottom": "1rem",
        "components-content-list-spacing-large-item-copy-margin-bottom": "2.5rem",
        "components-content-list-spacing-large-item-icon-copy-margin-top": "0rem",
        "components-content-list-spacing-large-item-margin-bottom": "2rem",
        "components-content-list-spacing-large-item-image-margin-bottom": "0.5rem",
        "components-content-list-spacing-large-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-x": "1.5rem",
        "components-content-list-spacing-small-padding-y": "2.5rem",
        "components-content-list-spacing-small-item-icon-margin-right": "0.5rem",
        "components-content-list-spacing-small-item-title-margin-bottom": "0.5rem",
        "components-content-list-spacing-small-item-copy-margin-bottom": "1rem",
        "components-content-list-spacing-small-item-margin-bottom": "1.5rem",
        "components-content-list-spacing-small-header-title-margin-bottom": "1rem",
        "components-content-list-spacing-small-header-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-title-margin-bottom": "1.5rem",
        "components-cookies-spacing-small-button-space-between": "1rem",
        "components-dropdown-item-padding-y": "0.75rem",
        "components-dropdown-item-padding-x": "0.75rem",
        "components-dropdown-item-spacing-x": "0.75rem",
        "components-dropdown-input-padding-y": "0.25rem",
        "components-dropdown-input-padding-x": "1rem",
        "components-footer-brand-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-large-column-margin-right": "4rem",
        "components-footer-brand-spacing-large-column-margin-bottom": "4rem",
        "components-footer-brand-spacing-large-link-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-padding-y": "2.5rem",
        "components-footer-brand-spacing-small-padding-x": "1rem",
        "components-footer-brand-spacing-small-logo-margin-bottom": "1.5rem",
        "components-footer-brand-spacing-small-column-margin-right": "0.125rem",
        "components-footer-brand-spacing-small-column-margin-bottom": "2.5rem",
        "components-footer-brand-spacing-small-link-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-title-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-text-margin-bottom": "1rem",
        "components-footer-brand-spacing-small-footer-link-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-link-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-link-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-link-minheight": "3rem",
        "components-footer-brand-spacing-small-footer-category-padding-left": "1.5rem",
        "components-footer-brand-spacing-small-footer-category-padding-right": "0.75rem",
        "components-footer-brand-spacing-small-footer-category-padding-y": "0.5rem",
        "components-footer-brand-spacing-small-footer-category-minheight": "3rem",
        "components-footer-global-spacing-large-padding-y": "2.5rem",
        "components-footer-global-spacing-large-padding-x": "5rem",
        "components-footer-global-spacing-large-logo-margin-bottom": "2.5rem",
        "components-footer-global-spacing-large-column-margin-right": "2rem",
        "components-footer-global-spacing-large-link-margin-bottom": "2rem",
        "components-footer-global-spacing-small-padding-y": "2.5rem",
        "components-footer-global-spacing-small-padding-x": "1rem",
        "components-footer-global-spacing-small-logo-margin-bottom": "1rem",
        "components-footer-global-spacing-small-column-margin-right": "1rem",
        "components-footer-global-spacing-small-link-margin-bottom": "1rem",
        "components-generic-card-spacing-large-padding-x": "0rem",
        "components-generic-card-spacing-large-padding-y": "0rem",
        "components-generic-card-spacing-large-padding-image-x": "0rem",
        "components-generic-card-spacing-large-padding-image-y": "0rem",
        "components-generic-card-spacing-large-padding-content-x": "2rem",
        "components-generic-card-spacing-large-padding-content-y": "2rem",
        "components-generic-card-spacing-large-eyebrow-margin-bottom": "2rem",
        "components-generic-card-spacing-large-title-margin-bottom": "1rem",
        "components-generic-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-generic-card-spacing-large-button-space-between": "1rem",
        "components-generic-card-spacing-small-padding-x": "0rem",
        "components-generic-card-spacing-small-padding-y": "0rem",
        "components-generic-card-spacing-small-padding-image-x": "0rem",
        "components-generic-card-spacing-small-padding-image-y": "0rem",
        "components-generic-card-spacing-small-padding-content-x": "1.5rem",
        "components-generic-card-spacing-small-padding-content-y": "1.5rem",
        "components-global-search-spacing-omnibox-padding-x": "0.125rem",
        "components-global-search-spacing-omnibox-list-padding-y": "0.5rem",
        "components-global-search-spacing-omnibox-label-padding": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-category-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-padding-x": "0.75rem",
        "components-global-search-spacing-omnibox-item-padding-y": "1rem",
        "components-global-search-spacing-omnibox-item-height": "3rem",
        "components-generic-card-listing-spacing-large-card-space-between": "1.5rem",
        "components-generic-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-header-shadow-x": "0rem",
        "components-header-spacing-large-padding-y": "1rem",
        "components-header-spacing-large-padding-x": "1.5rem",
        "components-header-spacing-large-links-margin-right": "1.5rem",
        "components-header-spacing-large-icons-space-between": "1.5rem",
        "components-header-spacing-large-logo-margin-right": "2rem",
        "components-header-spacing-large-space-between-search": "0.5rem",
        "components-header-spacing-large-height": "5rem",
        "components-header-spacing-large-language-selector-padding-x": "1rem",
        "components-header-spacing-large-language-selector-padding-y": "0.5rem",
        "components-header-spacing-large-language-selector-space-between": "0.75rem",
        "components-header-spacing-large-language-selector-country-space-between": "0.5rem",
        "components-header-spacing-large-mega-menu-padding-y": "2.5rem",
        "components-header-spacing-large-mega-menu-padding-x": "2.5rem",
        "components-header-spacing-large-mega-menu-column-margin-right": "5rem",
        "components-header-spacing-large-mega-menu-category-link-margin-bottom": "1rem",
        "components-header-spacing-large-mega-menu-link-margin-bottom": "0.5rem",
        "components-header-spacing-large-mega-menu-featured-padding-x": "2rem",
        "components-header-spacing-large-mega-menu-featured-padding-y": "2rem",
        "components-header-spacing-large-mega-menu-featured-image-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-title-margin-bottom": "2rem",
        "components-header-spacing-large-mega-menu-featured-link-space-between": "1.5rem",
        "components-header-spacing-large-mega-menu-title-margin-bottom": "2.5rem",
        "components-header-spacing-large-dropdown-padding-y": "1.5rem",
        "components-header-spacing-large-dropdown-padding-x": "1.5rem",
        "components-header-spacing-large-dropdown-margin-bottom": "0.5rem",
        "components-header-spacing-small-language-selector-padding-x": "1rem",
        "components-header-spacing-small-language-selector-padding-y": "0.5rem",
        "components-header-spacing-small-language-selector-space-between": "0.5rem",
        "components-header-spacing-small-padding-left": "1rem",
        "components-header-spacing-small-padding-right": "1rem",
        "components-header-spacing-small-padding-top": "0.5rem",
        "components-header-spacing-small-padding-bottom": "0.5rem",
        "components-header-spacing-small-nav-main-minheight": "3rem",
        "components-header-spacing-small-nav-main-padding-y": "0.5rem",
        "components-header-spacing-small-nav-main-padding-left": "1.5rem",
        "components-header-spacing-small-nav-main-padding-right": "1rem",
        "components-header-spacing-small-nav-category-minheight": "3rem",
        "components-header-spacing-small-nav-category-padding-left": "1.5rem",
        "components-header-spacing-small-nav-category-padding-right": "0.75rem",
        "components-header-spacing-small-nav-category-padding-y": "0.75rem",
        "components-header-spacing-small-nav-category-space-between": "0rem",
        "components-header-spacing-small-nav-menu-link-minheight": "3rem",
        "components-header-spacing-small-nav-menu-link-padding-right": "1.5rem",
        "components-header-spacing-small-nav-menu-link-padding-left": "2.5rem",
        "components-header-spacing-small-nav-menu-link-padding-y": "0.75rem",
        "components-header-spacing-small-mega-menu-padding-y": "0rem",
        "components-header-spacing-small-mega-menu-padding-x": "0rem",
        "components-header-spacing-small-mega-menu-category-link-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-link-margin-bottom": "0rem",
        "components-header-spacing-small-mega-menu-featured-padding-x": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-padding-y": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-image-margin-bottom": "1.5rem",
        "components-header-spacing-small-mega-menu-featured-title-margin-bottom": "0.75rem",
        "components-header-spacing-small-mega-menu-featured-link-space-between": "0.75rem",
        "components-header-spacing-small-mega-menu-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-container-padding": "0rem",
        "components-hero-homepage-spacing-large-padding-x": "5rem",
        "components-hero-homepage-spacing-large-padding-y": "2.5rem",
        "components-hero-homepage-spacing-large-minheight": "34.3125rem",
        "components-hero-homepage-spacing-large-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-margin-bottom": "4rem",
        "components-hero-homepage-spacing-large-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-large-content-copy-margin-bottom": "2.5rem",
        "components-hero-homepage-spacing-large-cta-margin-right": "1rem",
        "components-hero-homepage-spacing-large-image-height": "34.3125rem",
        "components-hero-homepage-spacing-large-image-width": "45.75rem",
        "components-hero-homepage-spacing-small-padding-x": "0.5rem",
        "components-hero-homepage-spacing-small-padding-y": "1.5rem",
        "components-hero-homepage-spacing-small-minheight": "34.3125rem",
        "components-hero-homepage-spacing-small-content-title-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-subtitle-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-content-copy-margin-bottom": "1.5rem",
        "components-hero-homepage-spacing-small-image-height": "17.5625rem",
        "components-hero-homepage-spacing-small-image-width": "45.75rem",
        "components-hero-internal-spacing-large-padding-y": "2.5rem",
        "components-hero-internal-spacing-large-padding-right": "10rem",
        "components-hero-internal-spacing-large-title-margin-bottom": "1.5rem",
        "components-hero-internal-spacing-large-button-padding-y": "2rem",
        "components-hero-internal-spacing-large-text-maxwidth": "59.625rem",
        "components-hero-internal-spacing-large-minheight": "18.5rem",
        "components-hero-internal-spacing-small-padding-x": "1.5rem",
        "components-hero-internal-spacing-small-padding-right": "0rem",
        "components-hero-internal-spacing-small-button-padding-y": "1.5rem",
        "components-hero-internal-spacing-small-text-maxwidth": "30rem",
        "components-hero-internal-spacing-small-minheight": "13.5rem",
        "components-icon-card-spacing-large-padding-x": "0rem",
        "components-icon-card-spacing-large-padding-y": "0rem",
        "components-icon-card-spacing-large-icon-margin": "1.5rem",
        "components-icon-card-spacing-large-margin-right": "1.5rem",
        "components-icon-card-spacing-large-title-margin-bottom": "1rem",
        "components-icon-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-icon-card-spacing-large-button-space-between": "0.5rem",
        "components-icon-card-spacing-large-top-space-between": "1rem",
        "components-icon-card-spacing-small-padding-x": "1.5rem",
        "components-icon-card-spacing-small-padding-y": "1.5rem",
        "components-icon-card-spacing-small-icon-margin": "1.5rem",
        "components-icon-card-spacing-small-margin-right": "1.5rem",
        "components-icon-card-spacing-small-title-margin-bottom": "1.5rem",
        "components-icon-card-dimensions-large-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-large-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-large-big-icon-height": "4rem",
        "components-icon-card-dimensions-large-big-icon-width": "4rem",
        "components-icon-card-dimensions-large-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-large-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-large-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-large-tiny-icon-width": "2.5rem",
        "components-icon-card-dimensions-small-big-bg-width": "7.5rem",
        "components-icon-card-dimensions-small-big-bg-height": "7.5rem",
        "components-icon-card-dimensions-small-big-icon-height": "3.5rem",
        "components-icon-card-dimensions-small-big-icon-width": "3.5rem",
        "components-icon-card-dimensions-small-tiny-bg-width": "5rem",
        "components-icon-card-dimensions-small-tiny-bg-height": "5rem",
        "components-icon-card-dimensions-small-tiny-icon-height": "2.5rem",
        "components-icon-card-dimensions-small-tiny-icon-width": "2.5rem",
        "components-icon-card-listing-spacing-large-padding-inner-x": "4rem",
        "components-icon-card-listing-spacing-large-padding-inner-y": "4rem",
        "components-icon-card-listing-spacing-large-card-space-between": "4rem",
        "components-icon-card-listing-spacing-large-title-margin-bottom": "1.5rem",
        "components-icon-card-listing-spacing-large-card-margin-bottom": "4rem",
        "components-icon-card-listing-spacing-large-button-margin-top": "2.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-x": "1.5rem",
        "components-icon-card-listing-spacing-small-padding-inner-y": "2.5rem",
        "components-icon-card-listing-spacing-small-title-margin-bottom": "1rem",
        "components-icon-card-listing-spacing-small-button-margin-top": "1.5rem",
        "components-jumplink-link-padding-bottom": "0.5rem",
        "components-jumplink-spacing-small-title-area-margin-bottom": "1.5rem",
        "components-jumplink-spacing-small-link-container-padding-x": "0rem",
        "components-lightbox-spacing-large-carousel-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-large-carousel-space-between": "1.5rem",
        "components-lightbox-spacing-large-featured-image-height": "39.25rem",
        "components-lightbox-spacing-large-featured-image-width": "39.25rem",
        "components-lightbox-spacing-large-image-height": "17.0625rem",
        "components-lightbox-spacing-large-image-width": "17.0625rem",
        "components-lightbox-spacing-small-carousel-margin-bottom": "1rem",
        "components-lightbox-spacing-small-media-details-margin-bottom": "1.5rem",
        "components-lightbox-spacing-small-media-details-margin-top": "1rem",
        "components-lightbox-spacing-small-image-height": "20.4375rem",
        "components-lightbox-spacing-small-image-width": "20.4375rem",
        "components-legal-disclaimer-spacing-small-ruleline-width": "20.4375rem",
        "components-media-spacing-large-padding-y": "0.5rem",
        "components-media-spacing-large-padding-x": "0.5rem",
        "components-media-gallery-large-container-grid-gap-x": "1.5rem",
        "components-media-gallery-large-container-grid-gap-y": "1.5rem",
        "components-media-gallery-small-container-grid-gap-x": "0.5rem",
        "components-media-gallery-small-container-grid-gap-y": "0.5rem",
        "components-media-gallery-spacing-small-padding-x": "1.5rem",
        "components-media-gallery-spacing-small-padding-y": "2.5rem",
        "components-media-gallery-spacing-large-padding-x": "5rem",
        "components-media-gallery-spacing-large-padding-y": "5rem",
        "components-product-card-spacing-list-content-gap": "0rem",
        "components-product-card-spacing-large-padding-image-x": "0rem",
        "components-product-card-spacing-large-padding-image-y": "0rem",
        "components-product-card-spacing-large-padding-content-x": "1.5rem",
        "components-product-card-spacing-large-padding-content-y": "1.5rem",
        "components-product-card-spacing-large-category-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-title-margin-bottom": "1rem",
        "components-product-card-spacing-large-copy-margin-bottom": "1.5rem",
        "components-product-card-spacing-large-button-space-between": "1rem",
        "components-product-card-spacing-small-padding-image-x": "0rem",
        "components-product-card-spacing-small-padding-image-y": "0rem",
        "components-product-card-spacing-small-padding-content-x": "1.5rem",
        "components-product-card-spacing-small-padding-content-y": "1.5rem",
        "components-product-card-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-large-padding-x": "5rem",
        "components-product-information-spacing-large-padding-y": "1.5rem",
        "components-product-information-spacing-large-title-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-label-margin-bottom": "2.5rem",
        "components-product-information-spacing-large-ratings-margin-bottom": "1rem",
        "components-product-information-spacing-large-details-margin-bottom": "1.5rem",
        "components-product-information-spacing-large-space-between": "1rem",
        "components-product-information-spacing-large-carousel-space-between": "5rem",
        "components-product-information-spacing-large-button-margin-y": "0.5rem",
        "components-product-information-spacing-large-body-margin-top": "1.5rem",
        "components-product-information-spacing-large-image-space-between": "1rem",
        "components-product-information-spacing-large-image-margin-bottom": "1rem",
        "components-product-information-spacing-small-padding-x": "1.5rem",
        "components-product-information-spacing-small-padding-y": "0.5rem",
        "components-product-information-spacing-small-title-margin-bottom": "1rem",
        "components-product-information-spacing-small-label-margin-bottom": "1.5rem",
        "components-product-information-spacing-small-ratings-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-details-margin-bottom": "0.5rem",
        "components-product-information-spacing-small-space-between": "0.75rem",
        "components-product-information-spacing-small-carousel-space-between": "2.5rem",
        "components-product-information-spacing-small-button-margin-y": "0.75rem",
        "components-product-information-spacing-small-body-margin-top": "1rem",
        "components-product-information-spacing-small-image-space-between": "0.75rem",
        "components-product-information-spacing-small-image-margin-bottom": "1rem",
        "components-product-results-list-with-search-spacing-large-padding-x": "5rem",
        "components-product-results-list-with-search-spacing-large-padding-y": "5rem",
        "components-product-results-list-with-search-spacing-large-ruleline-margin-top": "2.5rem",
        "components-product-results-list-with-search-spacing-large-filter-space-between": "1rem",
        "components-product-results-list-with-search-spacing-large-card-space-between": "1.5rem",
        "components-product-results-list-with-search-spacing-large-card-container-margin-bottom": "5rem",
        "components-product-results-list-with-search-spacing-small-padding-x": "1.5rem",
        "components-product-results-list-with-search-spacing-small-padding-y": "2.5rem",
        "components-product-results-list-with-search-spacing-small-ruleline-margin-top": "1rem",
        "components-product-results-list-with-search-spacing-small-filter-padding-x": "0.5rem",
        "components-product-results-list-with-search-spacing-small-card-space-between": "1rem",
        "components-product-results-list-with-search-spacing-small-card-margin-bottom": "1.5rem",
        "components-promo-spacing-large-padding-x": "5rem",
        "components-promo-spacing-large-padding-y": "5rem",
        "components-promo-spacing-large-content-space-between": "5rem",
        "components-promo-spacing-large-content-space-between-y": "5rem",
        "components-promo-spacing-large-padding-content-x": "0rem",
        "components-promo-spacing-large-padding-content-condensed-x": "0rem",
        "components-promo-spacing-large-padding-content-y": "0rem",
        "components-promo-spacing-large-padding-content-condensed-y": "0rem",
        "components-promo-spacing-large-padding-image-x": "0rem",
        "components-promo-spacing-large-padding-image-y": "0rem",
        "components-promo-spacing-large-padding-image-top": "0rem",
        "components-promo-spacing-large-title-margin-bottom": "2rem",
        "components-promo-spacing-large-margin-y": "2.5rem",
        "components-promo-spacing-large-buttons-space-between": "1rem",
        "components-promo-spacing-small-padding-x": "1rem",
        "components-promo-spacing-small-padding-y": "2.5rem",
        "components-promo-spacing-small-padding-top": "0rem",
        "components-promo-spacing-small-padding-content-x": "1.5rem",
        "components-promo-spacing-small-padding-content-y": "2.5rem",
        "components-promo-spacing-small-padding-image-x": "1rem",
        "components-promo-spacing-small-padding-image-y": "0rem",
        "components-promo-spacing-small-padding-image-top": "1rem",
        "components-promo-spacing-small-title-margin-bottom": "1.5rem",
        "components-promo-spacing-small-margin-y": "2.5rem",
        "components-promo-minheight": "30rem",
        "components-pull-quote-spacing-large-padding-quote-x": "4rem",
        "components-pull-quote-spacing-large-padding-quote-y": "4rem",
        "components-pull-quote-spacing-large-title-margin-bottom": "2rem",
        "components-pull-quote-spacing-large-quote-margin-bottom": "4rem",
        "components-pull-quote-spacing-large-quote-space-between": "1.5rem",
        "components-pull-quote-spacing-large-detail-space-between": "0.5rem",
        "components-pull-quote-spacing-large-quote-minheight": "25rem",
        "components-pull-quote-spacing-small-padding-quote-x": "1.5rem",
        "components-pull-quote-spacing-small-padding-quote-y": "2.5rem",
        "components-pull-quote-spacing-small-title-margin-bottom": "1.5rem",
        "components-pull-quote-spacing-small-quote-margin-bottom": "2.5rem",
        "components-pull-quote-spacing-small-quote-space-between": "1rem",
        "components-pull-quote-spacing-small-detail-space-between": "1rem",
        "components-pull-quote-spacing-small-quote-minheight": "15rem",
        "components-promo-alternate-minheight": "30rem",
        "components-promo-alternate-spacing-large-padding-x": "5rem",
        "components-promo-alternate-spacing-large-padding-y": "5rem",
        "components-promo-alternate-spacing-large-content-space-between": "5rem",
        "components-promo-alternate-spacing-large-padding-content-x": "0rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-x": "0rem",
        "components-promo-alternate-spacing-large-padding-content-y": "0rem",
        "components-promo-alternate-spacing-large-padding-content-condensed-y": "0rem",
        "components-promo-alternate-spacing-large-padding-image-x": "0rem",
        "components-promo-alternate-spacing-large-padding-image-y": "0rem",
        "components-promo-alternate-spacing-large-padding-image-top": "0rem",
        "components-promo-alternate-spacing-large-title-margin-bottom": "2rem",
        "components-promo-alternate-spacing-small-padding-x": "1rem",
        "components-promo-alternate-spacing-small-padding-y": "2.5rem",
        "components-promo-alternate-spacing-small-padding-content-x": "1.5rem",
        "components-promo-alternate-spacing-small-padding-content-y": "2.5rem",
        "components-promo-alternate-spacing-small-padding-image-x": "1rem",
        "components-promo-alternate-spacing-small-padding-image-y": "0rem",
        "components-promo-alternate-spacing-small-padding-image-top": "1rem",
        "components-promo-alternate-spacing-small-title-margin-bottom": "1.5rem",
        "components-share-list-spacing-padding-x": "1rem",
        "components-share-list-spacing-padding-y": "1rem",
        "components-header-copy-margin-bottom": "1rem",
        "components-header-margin-bottom": "1.5rem",
        "components-item-margin-bottom": "1.5rem",
        "components-site-search-padding-x": "2.5rem",
        "components-site-search-padding-y": "5rem",
        "components-site-search-facet-label-space-between": "0.25rem",
        "components-site-search-refine-padding-x": "1.5rem",
        "components-site-search-refine-padding-y": "0rem",
        "components-site-search-hero-search-height": "18.5rem",
        "components-site-search-hero-search-large-padding-x": "5rem",
        "components-site-search-hero-search-large-padding-y": "5rem",
        "components-stats-spacing-stat-text-space-between": "0.5rem",
        "components-stats-spacing-large-inner-padding-x": "5rem",
        "components-stats-spacing-large-inner-padding-y": "5rem",
        "components-stats-spacing-small-inner-padding-x": "1.5rem",
        "components-stats-spacing-small-inner-padding-y": "1.5rem",
        "components-icon-card-listing-spacing-small-top-space-between": "1rem",
        "components-supporting-text-padding-x": "0rem",
        "components-supporting-text-padding-bottom": "0.5rem",
        "components-tab-item-padding-y": "0.75rem",
        "components-tab-item-padding-x": "0.75rem",
        "components-tab-item-spacing-x": "0.75rem",
        "components-tab-input-padding-y": "0.25rem",
        "components-tab-input-padding-x": "1rem",
        "components-tag-spacing-padding-x": "0.5rem",
        "components-tag-spacing-padding-y": "0.5rem",
        "components-tag-spacing-space-between": "0.5rem",
        "components-text-field-input-padding-y": "0.25rem",
        "components-text-field-input-padding-x": "1rem",
        "components-text-field-area-input-padding-y": "1rem",
        "components-text-field-area-input-padding-x": "1rem",
        "components-text-field-supporting-text-padding-x": "0rem",
        "components-text-field-supporting-text-padding-top": "0.5rem",
        "themes-general-surface-spacing-large-padding-x": "5rem",
        "themes-general-surface-spacing-large-padding-y": "5rem",
        "themes-general-surface-spacing-large-title-margin-bottom": "2rem",
        "themes-general-surface-spacing-large-body-margin-right": "4rem",
        "themes-general-surface-spacing-large-body-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-large-margin-bottom": "4rem",
        "themes-general-surface-spacing-large-cards-space-between": "1.5rem",
        "themes-general-surface-spacing-large-button-margin-top": "2rem",
        "themes-general-surface-spacing-small-padding-x": "1.5rem",
        "themes-general-surface-spacing-small-padding-y": "2.5rem",
        "themes-general-surface-spacing-small-title-margin-bottom": "1rem",
        "themes-general-surface-spacing-small-body-margin-right": "2.5rem",
        "themes-general-surface-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-surface-spacing-small-margin-bottom": "2.5rem",
        "themes-general-surface-spacing-small-cards-space-between": "1rem",
        "themes-general-surface-spacing-small-button-margin-top": "1.5rem",
        "themes-general-card-spacing-large-eyebrow-margin-bottom": "1.5rem",
        "themes-general-card-spacing-large-title-margin-bottom": "1rem",
        "themes-general-card-spacing-large-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-large-button-space-between": "1.5rem",
        "themes-general-card-spacing-small-eyebrow-margin-bottom": "1rem",
        "themes-general-card-spacing-small-title-margin-bottom": "1rem",
        "themes-general-card-spacing-small-body-margin-bottom": "1.5rem",
        "themes-general-card-spacing-small-button-space-between": "1.5rem",
        "themes-shadow-share-list-spacing-x": "0rem",
        "themes-shadow-share-list-spacing-y": "0.75rem",
        "themes-shadow-share-list-spacing-blur": "1rem",
        "themes-shadow-share-list-spacing-spread": "-0.5rem",
        "themes-shadow-header-menu-spacing-x": "0rem",
        "themes-shadow-header-menu-spacing-y": "0.75rem",
        "themes-shadow-header-menu-spacing-blur": "1rem",
        "themes-shadow-header-menu-spacing-spread": "-0.5rem",
        "themes-typography-rte-large-h1-textsize": "3rem",
        "themes-typography-rte-large-h2-textsize": "2.5rem",
        "themes-typography-rte-large-h3-textsize": "2.25rem",
        "themes-typography-rte-large-h4-textsize": "2rem",
        "themes-typography-rte-large-h5-textsize": "1.5rem",
        "themes-typography-rte-large-h6-textsize": "1.25rem",
        "themes-typography-rte-medium-h1-textsize": "2.5rem",
        "themes-typography-rte-medium-h2-textsize": "2.25rem",
        "themes-typography-rte-medium-h3-textsize": "1.75rem",
        "themes-typography-rte-medium-h4-textsize": "1.5rem",
        "themes-typography-rte-medium-h5-textsize": "1.25rem",
        "themes-typography-rte-medium-h6-textsize": "1.125rem",
        "themes-typography-rte-small-h1-textsize": "2.5rem",
        "themes-typography-rte-small-h2-textsize": "2rem",
        "themes-typography-rte-small-h3-textsize": "1.75rem",
        "themes-typography-rte-small-h4-textsize": "1.5rem",
        "themes-typography-rte-small-h5-textsize": "1.125rem",
        "themes-typography-rte-small-h6-textsize": "1rem",
        "spacing-margin-small-1": "0.125rem",
        "spacing-margin-small-2": "0.25rem",
        "spacing-margin-small-3": "0.5rem",
        "spacing-margin-small-4": "0.75rem",
        "spacing-margin-small-5": "1rem",
        "spacing-margin-small-6": "1.5rem",
        "spacing-margin-small-7": "2.5rem",
        "spacing-margin-medium-2": "0.75rem",
        "spacing-margin-medium-4": "1.5rem",
        "spacing-margin-medium-5": "2rem",
        "spacing-margin-large-1": "0.5rem",
        "spacing-margin-large-2": "1rem",
        "spacing-margin-large-3": "1.5rem",
        "spacing-margin-large-4": "2rem",
        "spacing-margin-large-5": "2.5rem",
        "spacing-margin-large-6": "4rem",
        "spacing-margin-large-7": "5rem",
        "spacing-space-between-small-1": "0.125rem",
        "spacing-space-between-small-2": "0.25rem",
        "spacing-space-between-small-3": "0.5rem",
        "spacing-space-between-small-4": "0.75rem",
        "spacing-space-between-small-5": "1rem",
        "spacing-space-between-small-6": "1.5rem",
        "spacing-space-between-small-7": "2.5rem",
        "spacing-space-between-medium-1": "0.125rem",
        "spacing-space-between-medium-2": "0.25rem",
        "spacing-space-between-medium-3": "0.5rem",
        "spacing-space-between-medium-4": "0.75rem",
        "spacing-space-between-medium-5": "1rem",
        "spacing-space-between-large-1": "0.25rem",
        "spacing-space-between-large-2": "0.5rem",
        "spacing-space-between-large-3": "1rem",
        "spacing-space-between-large-4": "1.5rem",
        "spacing-space-between-large-5": "1.5rem",
        "spacing-space-between-large-6": "2.5rem",
        "spacing-space-between-large-7": "4rem",
        "spacing-padding-small-1": "0.125rem",
        "spacing-padding-small-2": "0.25rem",
        "spacing-padding-small-3": "0.5rem",
        "spacing-padding-small-4": "0.75rem",
        "spacing-padding-small-5": "1rem",
        "spacing-padding-small-6": "1.5rem",
        "spacing-padding-small-7": "2.5rem",
        "spacing-padding-medium-1": "0.25rem",
        "spacing-padding-medium-2": "0.75rem",
        "spacing-padding-medium-3": "1rem",
        "spacing-padding-medium-4": "1.5rem",
        "spacing-padding-medium-5": "2rem",
        "spacing-padding-medium-6": "3rem",
        "spacing-padding-medium-7": "4rem",
        "spacing-padding-large-1": "0.5rem",
        "spacing-padding-large-2": "1rem",
        "spacing-padding-large-3": "1.5rem",
        "spacing-padding-large-4": "2rem",
        "spacing-padding-large-5": "2.5rem",
        "spacing-padding-large-6": "4rem",
        "spacing-padding-large-7": "5rem"
      },
      "string": {
        "components-accordion-section-button": "var(--brand)",
        "components-article-card-listing-button-theme": "var(--brand)",
        "components-article-hero-pagination-buttons": "var(--brand)",
        "components-banner-banner-button": "var(--tonal)",
        "components-category-card-listing-button-theme": "var(--brand)",
        "components-contact-us-promo-hierarchy-button-theme-1": "var(--filled)",
        "components-contact-us-promo-hierarchy-button-theme-2": "var(--outlined)",
        "components-contact-us-promo-color-button-theme": "var(--brand)",
        "components-contact-us-promo-color-brand-button-theme": "var(--white)",
        "components-generic-card-button-theme": "var(--brand)",
        "components-global-search-loader": "var(--default)",
        "components-generic-card-listing-decor-top": "var(--ziploc)",
        "components-generic-card-listing-decor-bottom": "var(--ziploc)",
        "components-hero-homepage-pagination-buttons": "var(--white)",
        "components-hero-homepage-pattern": "var(--ziploc)",
        "components-hero-homepage-button-1": "var(--white)",
        "components-hero-homepage-button-2": "var(--white)",
        "components-hero-homepage-image-ratio": "var(--4:3)",
        "components-hero-internal-theme-button-one-type": "var(--filled)",
        "components-hero-internal-theme-button-two-type": "var(--outlined)",
        "components-hero-internal-theme-button-color": "var(--white)",
        "components-hero-internal-theme-button-color-with-image": "var(--white)",
        "components-lightbox-button-type": "var(--brand)",
        "components-pdf-link-card-hierarchy-button-theme-1": "var(--filled)",
        "components-pdf-link-card-hierarchy-button-theme-2": "var(--outlined)",
        "components-pdf-link-card-color-button-theme": "var(--brand)",
        "components-product-card-badge-align": "var(--center)",
        "components-product-card-listing-button-color": "var(--white)",
        "components-promo-theme-button-one-theme": "var(--outlined)",
        "components-promo-theme-button-two-theme": "var(--text)",
        "components-promo-color-default-button-theme": "var(--brand)",
        "components-promo-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-color-brand-button-theme": "var(--white)",
        "components-pull-quote-border-decor": "var(--ziploc)",
        "components-promo-alternate-theme-button-one-theme": "var(--outlined)",
        "components-promo-alternate-theme-button-two-theme": "var(--text)",
        "components-promo-alternate-color-default-button-theme": "var(--brand)",
        "components-promo-alternate-color-light-brand-light-button-theme": "var(--brand)",
        "components-promo-alternate-color-brand-button-theme": "var(--white)",
        "components-share-list-button-color": "var(--brand)",
        "components-site-search-hero-search-hero-pattern": "var(--ziploc)",
        "components-stats-decor-top": "var(--ziploc)",
        "components-stats-decor-bottom": "var(--ziploc)",
        "themes-brand-loader": "var(--default)",
        "themes-brand-variants": "var(--ziploc)",
        "themes-tab-variant": "var(--ziploc)",
        "themes-label-variants": "var(--ziploc)",
        "themes-logo": "var(--ziploc)",
        "themes-category-card": "var(--ziploc)",
        "themes-title-decor": "var(--ziploc)",
        "themes-typography-heading-fontfamily": "var(--museo-sans)",
        "themes-typography-heading-large-xxlarge-fontweight": "var(--900)",
        "themes-typography-heading-large-xlarge-fontweight": "var(--900)",
        "themes-typography-heading-large-large-fontweight": "var(--900)",
        "themes-typography-heading-large-medium-fontweight": "var(--700)",
        "themes-typography-heading-large-small-fontweight": "var(--700)",
        "themes-typography-heading-large-xsmall-fontweight": "var(--700)",
        "themes-typography-heading-large-xxsmall-fontweight": "var(--700)",
        "themes-typography-heading-medium-xxlarge-fontweight": "var(--900)",
        "themes-typography-heading-medium-xlarge-fontweight": "var(--900)",
        "themes-typography-heading-medium-large-fontweight": "var(--900)",
        "themes-typography-heading-medium-medium-fontweight": "var(--700)",
        "themes-typography-heading-medium-small-fontweight": "var(--700)",
        "themes-typography-heading-medium-xsmall-fontweight": "var(--700)",
        "themes-typography-heading-medium-xxsmall-fontweight": "var(--700)",
        "themes-typography-heading-small-xxlarge-fontweight": "var(--900)",
        "themes-typography-heading-small-xlarge-fontweight": "var(--900)",
        "themes-typography-heading-small-large-fontweight": "var(--900)",
        "themes-typography-heading-small-medium-fontweight": "var(--700)",
        "themes-typography-heading-small-small-fontweight": "var(--700)",
        "themes-typography-heading-small-xsmall-fontweight": "var(--700)",
        "themes-typography-heading-small-xxsmall-fontweight": "var(--700)",
        "themes-typography-rte-fontfamily": "var(--museo-sans)",
        "themes-typography-rte-fontweight": "var(--900)"
      },
      "borderRadius": {
        "components-accordion-spacing-large-item-item-radius": "0rem",
        "components-article-card-spacing-large-image-radius-tl": "1rem",
        "components-article-card-spacing-large-image-radius-tr": "1rem",
        "components-article-card-spacing-large-image-radius-bl": "1rem",
        "components-article-card-spacing-large-image-radius-br": "1rem",
        "components-article-card-spacing-large-list-image-radius-br": "1rem",
        "components-article-card-spacing-small-image-radius-br": "1rem",
        "components-article-card-spacing-small-list-image-radius-br": "1rem",
        "components-article-hero-spacing-large-content-radius": "1.5rem",
        "components-article-hero-spacing-small-content-radius": "1rem",
        "components-button-radius-large": "7.5rem",
        "components-button-group-spacing-border-radius": "7.5rem",
        "components-category-card-radius-border-width": "0.0625rem",
        "components-category-card-radius-border-radius": "7.5rem",
        "components-category-card-radius-border-radius-condensed": "0.5rem",
        "components-category-card-radius-media-radius": "0.75rem",
        "components-content-list-icon-radius": "7.5rem",
        "components-fab-spacing-border-radius": "7.5rem",
        "components-header-spacing-large-search-border-radius-top": "0.25rem",
        "components-header-spacing-large-search-border-radius-bottom": "0rem",
        "components-header-spacing-large-language-selector-radius": "0.25rem",
        "components-header-spacing-large-dropdown-radius": "0rem",
        "components-hero-homepage-spacing-large-container-radius": "0rem",
        "components-hero-internal-spacing-large-icon-radius": "7.5rem",
        "components-hero-internal-spacing-small-icon-radius": "7.5rem",
        "components-lightbox-spacing-large-overlay-radius": "1rem",
        "components-pagination-scrollbar-radius": "62.5rem",
        "components-product-card-spacing-image-radius-tl": "0rem",
        "components-product-card-spacing-image-radius-tr": "0rem",
        "components-product-card-spacing-image-radius-bl": "0rem",
        "components-product-card-spacing-image-radius-br": "0rem",
        "components-product-information-spacing-large-content-radius": "1.5rem",
        "components-product-information-spacing-small-content-radius": "1rem",
        "components-promo-spacing-large-content-radius": "0rem",
        "components-promo-spacing-large-content-radius-tl": "0rem",
        "components-promo-spacing-large-content-radius-tr": "0rem",
        "components-promo-spacing-large-content-radius-bl": "0rem",
        "components-promo-spacing-large-content-radius-br": "0rem",
        "components-promo-spacing-large-image-radius-tl": "1rem",
        "components-promo-spacing-large-image-radius-tr": "1rem",
        "components-promo-spacing-large-image-radius-bl": "1rem",
        "components-promo-spacing-large-image-radius-br": "1rem",
        "components-promo-spacing-small-content-radius": "1rem",
        "components-promo-spacing-small-image-radius-tl": "1rem",
        "components-promo-spacing-small-image-radius-tr": "1rem",
        "components-promo-spacing-small-image-radius-bl": "1rem",
        "components-promo-spacing-small-image-radius-br": "1rem",
        "components-promo-alternate-spacing-large-content-radius": "0rem",
        "components-promo-alternate-spacing-large-image-radius-tl": "1rem",
        "components-promo-alternate-spacing-large-image-radius-tr": "1rem",
        "components-promo-alternate-spacing-large-image-radius-bl": "1rem",
        "components-promo-alternate-spacing-large-image-radius-br": "1rem",
        "components-promo-alternate-spacing-small-content-radius": "1rem",
        "components-promo-alternate-spacing-small-image-radius-tl": "1rem",
        "components-promo-alternate-spacing-small-image-radius-tr": "1rem",
        "components-promo-alternate-spacing-small-image-radius-bl": "1rem",
        "components-promo-alternate-spacing-small-image-radius-br": "1rem",
        "components-share-list-spacing-radius": "0.5rem",
        "components-share-list-spacing-link-radius": "0.5rem",
        "components-site-search-filter-radius": "0.5rem",
        "components-site-search-facet-radius": "0rem",
        "components-site-search-refine-radius": "0rem",
        "components-tag-spacing-radius": "0.25rem",
        "components-tip-spacing-radius": "0.25rem",
        "themes-spacing-radius-pagination-bar": "62.5rem",
        "themes-spacing-radius-large-button": "7.5rem",
        "themes-spacing-radius-large-button-icon": "7.5rem",
        "themes-spacing-radius-large-image": "1rem",
        "themes-spacing-radius-large-image-hero": "0rem",
        "themes-spacing-radius-large-image-card": "0.75rem",
        "themes-spacing-radius-large-card": "1rem",
        "themes-spacing-radius-large-icon": "7.5rem",
        "themes-spacing-radius-large-label": "0.25rem",
        "themes-spacing-radius-large-tag": "0.25rem",
        "themes-spacing-radius-large-container-tip": "0.25rem",
        "themes-spacing-radius-large-badge": "0rem",
        "themes-spacing-radius-large-item": "0.5rem",
        "themes-spacing-radius-large-form-field-input": "0.25rem",
        "themes-spacing-radius-large-form-area-input": "0.25rem",
        "themes-spacing-radius-large-general": "1.5rem",
        "themes-spacing-radius-small-button": "7.5rem",
        "themes-spacing-radius-small-button-icon": "7.5rem",
        "themes-spacing-radius-small-image": "0.75rem",
        "themes-spacing-radius-small-image-card": "0.75rem",
        "themes-spacing-radius-small-card": "0.75rem",
        "themes-spacing-radius-small-icon": "7.5rem",
        "themes-spacing-radius-small-label": "0.125rem",
        "themes-spacing-radius-small-tag": "0.25rem",
        "themes-spacing-radius-small-badge": "0rem",
        "themes-spacing-radius-small-item": "0rem",
        "themes-spacing-radius-small-form-field-input": "0.5rem",
        "themes-spacing-radius-small-general": "1rem"
      },
      "borderWidth": {
        "3": "3px",
        "components-article-card-spacing-large-border-width": "0.0625rem",
        "components-generic-card-color-border-width": "0.0625rem",
        "components-header-spacing-large-search-border-width-top": "0rem",
        "components-header-spacing-large-search-border-width-right": "0rem",
        "components-header-spacing-large-search-border-width-bottom": "0.125rem",
        "components-header-spacing-large-search-border-width-left": "0rem",
        "components-product-card-spacing-border-width": "0.0625rem"
      },
      "maxHeight": {},
      "maxWidth": {
        "components-article-hero-spacing-large-header-max-width": "66.375rem",
        "components-content-list-spacing-large-item-image-max-width": "33.75rem",
        "components-content-list-spacing-small-item-image-max-width": "33.75rem"
      },
      "minHeight": {
        "components-dropdown-item-min-height": "3rem",
        "components-icon-min-height": "3rem",
        "components-tab-item-min-height": "3rem",
        "components-text-field-input-min-height": "3rem",
        "components-text-field-icon-min-height": "3rem"
      },
      "minWidth": {
        "components-content-list-spacing-small-item-image-min-width": "18.75rem",
        "components-icon-min-width": "3rem",
        "components-text-field-icon-min-width": "3rem"
      },
      "opacity": {
        "components-accessibility-bar-color-bg": 1,
        "components-accessibility-bar-color-text": 1,
        "components-accordion-color-bg": 1,
        "components-accordion-color-title": 1,
        "components-accordion-color-body": 1,
        "components-accordion-color-section-section-bg": 1,
        "components-accordion-color-section-section-title": 1,
        "components-accordion-color-section-section-body": 1,
        "components-accordion-color-item-item-bg": 1,
        "components-accordion-color-item-divider": 1,
        "components-accordion-color-item-icon-icon-default": 1,
        "components-accordion-color-item-icon-icon-hover": 1,
        "components-accordion-color-item-icon-icon-pressed": 1,
        "components-accordion-color-item-icon-icon-active": 1,
        "components-accordion-color-item-title-item-default": 1,
        "components-accordion-color-item-title-item-hover": 1,
        "components-accordion-color-item-title-item-pressed": 1,
        "components-accordion-color-item-title-item-active": 1,
        "components-accordion-color-content-bg": 1,
        "components-accordion-color-content-title": 1,
        "components-accordion-color-content-body": 1,
        "components-article-card-color-bg": 1,
        "components-article-card-color-border": 1,
        "components-article-card-color-content-bg": 1,
        "components-article-card-color-title": 1,
        "components-article-card-color-subtitle": 1,
        "components-article-card-color-copy": 1,
        "components-article-card-color-description": 1,
        "components-article-card-listing-color-default-bg": 1,
        "components-article-card-listing-color-default-title": 1,
        "components-article-card-listing-color-default-copy": 1,
        "components-article-hero-color-content-bg": 1,
        "components-article-hero-color-bg": 1,
        "components-article-hero-color-headline": 1,
        "components-article-hero-color-body": 1,
        "components-article-hero-color-detail-text": 1,
        "components-article-hero-color-divider": 1,
        "components-article-hero-color-icon": 1,
        "components-article-hero-color-play-icon": 1,
        "components-article-hero-color-selected-stroke": 1,
        "components-banner-color-bg-default": 1,
        "components-banner-color-icon-default": 1,
        "components-banner-color-title-default": 1,
        "components-banner-color-body-default": 1,
        "components-banner-color-icon-close-default": 1,
        "components-banner-color-bg-alert": 1,
        "components-banner-color-icon-alert": 1,
        "components-banner-color-title-alert": 1,
        "components-banner-color-body-alert": 1,
        "components-banner-color-icon-close-alert": 1,
        "components-breadcrumb-color-bg": 1,
        "components-breadcrumb-color-breadcrumb-link-default": 1,
        "components-breadcrumb-color-breadcrumb-link-hover": 1,
        "components-breadcrumb-color-breadcrumb-link-active": 1,
        "components-breadcrumb-color-icon-divider": 1,
        "components-bug-finder-prompt": 1,
        "components-button-color-filled-brand-default-bg": 1,
        "components-button-color-filled-brand-default-text": 1,
        "components-button-color-filled-brand-default-stroke": 1,
        "components-button-color-filled-brand-default-icon": 1,
        "components-button-color-filled-brand-hover-bg": 1,
        "components-button-color-filled-brand-hover-text": 1,
        "components-button-color-filled-brand-hover-stroke": 1,
        "components-button-color-filled-brand-hover-icon": 1,
        "components-button-color-filled-brand-pressed-bg": 1,
        "components-button-color-filled-brand-pressed-text": 1,
        "components-button-color-filled-brand-pressed-stroke": 1,
        "components-button-color-filled-brand-pressed-icon": 1,
        "components-button-color-filled-brand-disabled-bg": 0.02,
        "components-button-color-filled-brand-disabled-text": 0.01,
        "components-button-color-filled-brand-disabled-stroke": 0.02,
        "components-button-color-filled-brand-disabled-icon": 0.01,
        "components-button-color-filled-brand-focus-bg": 1,
        "components-button-color-filled-brand-focus-text": 1,
        "components-button-color-filled-brand-focus-stroke": 1,
        "components-button-color-filled-brand-focus-icon": 1,
        "components-button-color-filled-brand-focus-outline": 1,
        "components-button-color-filled-tonal-default-bg": 1,
        "components-button-color-filled-tonal-default-text": 1,
        "components-button-color-filled-tonal-default-stroke": 1,
        "components-button-color-filled-tonal-default-icon": 1,
        "components-button-color-filled-tonal-hover-bg": 1,
        "components-button-color-filled-tonal-hover-text": 1,
        "components-button-color-filled-tonal-hover-stroke": 1,
        "components-button-color-filled-tonal-hover-icon": 1,
        "components-button-color-filled-tonal-pressed-bg": 1,
        "components-button-color-filled-tonal-pressed-text": 1,
        "components-button-color-filled-tonal-pressed-stroke": 1,
        "components-button-color-filled-tonal-pressed-icon": 1,
        "components-button-color-filled-tonal-disabled-bg": 0.01,
        "components-button-color-filled-tonal-disabled-text": 0,
        "components-button-color-filled-tonal-disabled-stroke": 0.01,
        "components-button-color-filled-tonal-disabled-icon": 0,
        "components-button-color-filled-tonal-focus-bg": 1,
        "components-button-color-filled-tonal-focus-text": 1,
        "components-button-color-filled-tonal-focus-stroke": 1,
        "components-button-color-filled-tonal-focus-icon": 1,
        "components-button-color-filled-tonal-focus-outline": 1,
        "components-button-color-filled-white-default-bg": 1,
        "components-button-color-filled-white-default-text": 1,
        "components-button-color-filled-white-default-stroke": 1,
        "components-button-color-filled-white-default-icon": 1,
        "components-button-color-filled-white-hover-bg": 1,
        "components-button-color-filled-white-hover-text": 1,
        "components-button-color-filled-white-hover-stroke": 1,
        "components-button-color-filled-white-hover-icon": 1,
        "components-button-color-filled-white-pressed-bg": 1,
        "components-button-color-filled-white-pressed-text": 1,
        "components-button-color-filled-white-pressed-stroke": 1,
        "components-button-color-filled-white-pressed-icon": 1,
        "components-button-color-filled-white-disabled-bg": 0.02,
        "components-button-color-filled-white-disabled-text": 0.02,
        "components-button-color-filled-white-disabled-stroke": 0.02,
        "components-button-color-filled-white-disabled-icon": 0.02,
        "components-button-color-filled-white-focus-bg": 1,
        "components-button-color-filled-white-focus-text": 1,
        "components-button-color-filled-white-focus-stroke": 1,
        "components-button-color-filled-white-focus-icon": 1,
        "components-button-color-filled-white-focus-outline": 1,
        "components-button-color-outline-brand-default-bg": 0.01,
        "components-button-color-outline-brand-default-text": 1,
        "components-button-color-outline-brand-default-stroke": 1,
        "components-button-color-outline-brand-default-icon": 1,
        "components-button-color-outline-brand-hover-bg": 1,
        "components-button-color-outline-brand-hover-text": 1,
        "components-button-color-outline-brand-hover-stroke": 1,
        "components-button-color-outline-brand-hover-icon": 1,
        "components-button-color-outline-brand-pressed-bg": 1,
        "components-button-color-outline-brand-pressed-text": 1,
        "components-button-color-outline-brand-pressed-stroke": 1,
        "components-button-color-outline-brand-pressed-icon": 1,
        "components-button-color-outline-brand-disabled-bg": 0.01,
        "components-button-color-outline-brand-disabled-text": 0.02,
        "components-button-color-outline-brand-disabled-stroke": 0.02,
        "components-button-color-outline-brand-disabled-icon": 0.02,
        "components-button-color-outline-brand-focus-bg": 1,
        "components-button-color-outline-brand-focus-text": 1,
        "components-button-color-outline-brand-focus-stroke": 1,
        "components-button-color-outline-brand-focus-icon": 1,
        "components-button-color-outline-brand-focus-outline": 1,
        "components-button-color-outline-tonal-default-bg": 0.01,
        "components-button-color-outline-tonal-default-text": 1,
        "components-button-color-outline-tonal-default-stroke": 1,
        "components-button-color-outline-tonal-default-icon": 1,
        "components-button-color-outline-tonal-hover-bg": 1,
        "components-button-color-outline-tonal-hover-text": 1,
        "components-button-color-outline-tonal-hover-stroke": 1,
        "components-button-color-outline-tonal-hover-icon": 1,
        "components-button-color-outline-tonal-pressed-bg": 1,
        "components-button-color-outline-tonal-pressed-text": 1,
        "components-button-color-outline-tonal-pressed-stroke": 1,
        "components-button-color-outline-tonal-pressed-icon": 1,
        "components-button-color-outline-tonal-disabled-bg": 0.01,
        "components-button-color-outline-tonal-disabled-text": 0,
        "components-button-color-outline-tonal-disabled-stroke": 0,
        "components-button-color-outline-tonal-disabled-icon": 0,
        "components-button-color-outline-tonal-focus-bg": 1,
        "components-button-color-outline-tonal-focus-text": 1,
        "components-button-color-outline-tonal-focus-stroke": 1,
        "components-button-color-outline-tonal-focus-icon": 1,
        "components-button-color-outline-tonal-focus-outline": 1,
        "components-button-color-outline-white-default-bg": 0.02,
        "components-button-color-outline-white-default-text": 1,
        "components-button-color-outline-white-default-stroke": 1,
        "components-button-color-outline-white-default-icon": 1,
        "components-button-color-outline-white-hover-bg": 1,
        "components-button-color-outline-white-hover-text": 1,
        "components-button-color-outline-white-hover-stroke": 1,
        "components-button-color-outline-white-hover-icon": 1,
        "components-button-color-outline-white-pressed-bg": 1,
        "components-button-color-outline-white-pressed-text": 1,
        "components-button-color-outline-white-pressed-stroke": 1,
        "components-button-color-outline-white-pressed-icon": 1,
        "components-button-color-outline-white-disabled-bg": 0.02,
        "components-button-color-outline-white-disabled-text": 0.01,
        "components-button-color-outline-white-disabled-stroke": 0.01,
        "components-button-color-outline-white-disabled-icon": 0.01,
        "components-button-color-outline-white-focus-bg": 1,
        "components-button-color-outline-white-focus-text": 1,
        "components-button-color-outline-white-focus-stroke": 1,
        "components-button-color-outline-white-focus-icon": 1,
        "components-button-color-outline-white-focus-outline": 1,
        "components-button-color-text-brand-default-bg": 0.01,
        "components-button-color-text-brand-default-text": 1,
        "components-button-color-text-brand-default-stroke": 1,
        "components-button-color-text-brand-default-icon": 1,
        "components-button-color-text-brand-hover-bg": 0.01,
        "components-button-color-text-brand-hover-bg-icon-only": 1,
        "components-button-color-text-brand-hover-text": 1,
        "components-button-color-text-brand-hover-stroke": 1,
        "components-button-color-text-brand-hover-icon": 1,
        "components-button-color-text-brand-pressed-bg": 0.01,
        "components-button-color-text-brand-pressed-bg-icon-only": 1,
        "components-button-color-text-brand-pressed-text": 1,
        "components-button-color-text-brand-pressed-stroke": 1,
        "components-button-color-text-brand-pressed-icon": 1,
        "components-button-color-text-brand-disabled-bg": 0.01,
        "components-button-color-text-brand-disabled-text": 0.02,
        "components-button-color-text-brand-disabled-stroke": 0.02,
        "components-button-color-text-brand-disabled-icon": 0.02,
        "components-button-color-text-brand-focus-bg": 1,
        "components-button-color-text-brand-focus-text": 1,
        "components-button-color-text-brand-focus-stroke": 1,
        "components-button-color-text-brand-focus-icon": 1,
        "components-button-color-text-brand-focus-outline": 1,
        "components-button-color-text-tonal-default-bg": 0.01,
        "components-button-color-text-tonal-default-text": 1,
        "components-button-color-text-tonal-default-stroke": 1,
        "components-button-color-text-tonal-default-icon": 1,
        "components-button-color-text-tonal-hover-bg": 0.01,
        "components-button-color-text-tonal-hover-bg-icon-only": 1,
        "components-button-color-text-tonal-hover-text": 1,
        "components-button-color-text-tonal-hover-stroke": 1,
        "components-button-color-text-tonal-hover-icon": 1,
        "components-button-color-text-tonal-pressed-bg": 0.01,
        "components-button-color-text-tonal-pressed-bg-icon-only": 1,
        "components-button-color-text-tonal-pressed-text": 1,
        "components-button-color-text-tonal-pressed-stroke": 1,
        "components-button-color-text-tonal-pressed-icon": 1,
        "components-button-color-text-tonal-disabled-bg": 0.01,
        "components-button-color-text-tonal-disabled-text": 0.01,
        "components-button-color-text-tonal-disabled-stroke": 0.01,
        "components-button-color-text-tonal-disabled-icon": 0.01,
        "components-button-color-text-tonal-focus-bg": 1,
        "components-button-color-text-tonal-focus-text": 1,
        "components-button-color-text-tonal-focus-stroke": 1,
        "components-button-color-text-tonal-focus-icon": 1,
        "components-button-color-text-tonal-focus-outline": 1,
        "components-button-color-text-white-default-bg": 0.02,
        "components-button-color-text-white-default-text": 1,
        "components-button-color-text-white-default-stroke": 1,
        "components-button-color-text-white-default-icon": 1,
        "components-button-color-text-white-hover-bg": 0.02,
        "components-button-color-text-white-hover-bg-icon-only": 1,
        "components-button-color-text-white-hover-text": 1,
        "components-button-color-text-white-hover-stroke": 1,
        "components-button-color-text-white-hover-icon": 1,
        "components-button-color-text-white-pressed-bg": 0.02,
        "components-button-color-text-white-pressed-bg-icon-only": 1,
        "components-button-color-text-white-pressed-text": 1,
        "components-button-color-text-white-pressed-stroke": 1,
        "components-button-color-text-white-pressed-icon": 1,
        "components-button-color-text-white-disabled-bg": 0.02,
        "components-button-color-text-white-disabled-text": 0.01,
        "components-button-color-text-white-disabled-stroke": 0.01,
        "components-button-color-text-white-disabled-icon": 0.01,
        "components-button-color-text-white-focus-bg": 1,
        "components-button-color-text-white-focus-text": 1,
        "components-button-color-text-white-focus-stroke": 1,
        "components-button-color-text-white-focus-icon": 1,
        "components-button-color-text-white-focus-outline": 1,
        "components-button-group-color-border": 1,
        "components-button-group-color-button-bg-default": 0.02,
        "components-button-group-color-button-fg-default": 1,
        "components-button-group-color-button-bg-hover": 1,
        "components-button-group-color-button-fg-hover": 1,
        "components-button-group-color-button-bg-active": 1,
        "components-button-group-color-button-fg-active": 1,
        "components-button-group-color-overlay-left-gradient-fill-1": 1,
        "components-button-group-color-overlay-left-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-1": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-2": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-3": 0.02,
        "components-button-group-color-stroke-default-gradient-fill-4": 0.02,
        "components-button-group-color-stroke-hover-gradient-fill-1": 1,
        "components-button-group-color-stroke-hover-gradient-fill-2": 1,
        "components-button-group-color-stroke-hover-gradient-fill-3": 1,
        "components-button-group-color-stroke-hover-gradient-fill-4": 1,
        "components-button-group-color-stroke-active-gradient-fill-1": 1,
        "components-button-group-color-stroke-active-gradient-fill-2": 1,
        "components-button-group-color-stroke-active-gradient-fill-3": 1,
        "components-button-group-color-stroke-active-gradient-fill-4": 1,
        "components-category-card-color-bg": 1,
        "components-category-card-color-content-bg": 1,
        "components-category-card-color-category-bg": 1,
        "components-category-card-color-category-bg-2": 1,
        "components-category-card-color-category-bg-3": 1,
        "components-category-card-color-category-bg-4": 1,
        "components-category-card-color-category-bg-5": 1,
        "components-category-card-color-category-bg-6": 1,
        "components-category-card-color-title": 1,
        "components-category-card-color-copy": 1,
        "components-category-card-color-stroke": 1,
        "components-category-card-color-stroke-hover": 1,
        "components-category-card-listing-color-bg": 1,
        "components-category-card-listing-color-title": 1,
        "components-category-card-listing-color-copy": 1,
        "components-card-search-result-color-bg": 1,
        "components-card-search-result-color-label": 1,
        "components-card-search-result-color-title": 1,
        "components-card-search-result-color-body": 1,
        "components-card-search-result-color-divider-line": 1,
        "components-contact-us-promo-color-light-bg": 1,
        "components-contact-us-promo-color-light-title": 1,
        "components-contact-us-promo-color-light-body": 1,
        "components-contact-us-promo-color-default-bg": 1,
        "components-contact-us-promo-color-default-title": 1,
        "components-contact-us-promo-color-default-body": 1,
        "components-contact-us-promo-color-brand-bg": 1,
        "components-contact-us-promo-color-brand-title": 1,
        "components-contact-us-promo-color-brand-body": 1,
        "components-content-list-color-bg": 1,
        "components-content-list-color-title": 1,
        "components-content-list-color-body": 1,
        "components-content-list-color-icon": 1,
        "components-cookies-colors-bg": 1,
        "components-cookies-colors-title": 1,
        "components-cookies-colors-body": 1,
        "components-cookies-colors-icon": 1,
        "components-dropdown-bg": 1,
        "components-dropdown-border-default": 1,
        "components-dropdown-border-hover": 1,
        "components-dropdown-border-focused": 1,
        "components-dropdown-border-destructive": 1,
        "components-dropdown-label-text-default": 1,
        "components-dropdown-item-text-default": 1,
        "components-dropdown-item-bg-default": 1,
        "components-dropdown-item-bg-hover": 1,
        "components-dropdown-input-field-default": 1,
        "components-dropdown-input-field-focused": 1,
        "components-dropdown-input-label-focused": 1,
        "components-dropdown-input-field-destructive": 1,
        "components-external-link-card-bg": 1,
        "components-external-link-card-card-bg": 1,
        "components-external-link-card-card-title": 1,
        "components-external-link-card-card-subtitle": 1,
        "components-external-link-card-card-eyebrow": 1,
        "components-fab-color-border-default": 1,
        "components-footer-brand-color-bg": 1,
        "components-footer-brand-color-logo": 1,
        "components-footer-brand-color-title-text": 1,
        "components-footer-brand-color-description-text": 1,
        "components-footer-brand-color-footer-menu-expanded": 1,
        "components-footer-brand-color-link-default": 1,
        "components-footer-brand-color-link-hover": 1,
        "components-footer-brand-color-link-pressed": 1,
        "components-footer-brand-color-category-link-default": 1,
        "components-footer-brand-color-category-link-hover": 1,
        "components-footer-brand-color-category-link-pressed": 1,
        "components-footer-brand-color-subcategory-link-default": 1,
        "components-footer-brand-color-subcategory-link-hover": 1,
        "components-footer-brand-color-subcategory-link-pressed": 1,
        "components-footer-brand-color-icon-icon-default": 1,
        "components-footer-brand-color-icon-icon-pressed": 1,
        "components-footer-brand-color-social-icon-default": 1,
        "components-footer-brand-color-social-icon-hover": 1,
        "components-footer-brand-color-social-icon-pressed": 1,
        "components-footer-brand-spacing-category-link-hover": 1,
        "components-footer-global-color-bg": 1,
        "components-footer-global-color-title-text": 1,
        "components-footer-global-color-disclaimer-text": 1,
        "components-footer-global-color-link-default": 1,
        "components-footer-global-color-link-hover": 1,
        "components-footer-global-color-link-pressed": 1,
        "components-generic-card-color-bg": 1,
        "components-generic-card-color-content-bg": 1,
        "components-generic-card-color-title": 1,
        "components-generic-card-color-subtitle": 1,
        "components-generic-card-color-copy": 1,
        "components-global-search-color-omnibox-list-bg": 1,
        "components-global-search-color-omnibox-divider": 1,
        "components-global-search-color-omnibox-item-bg-default": 1,
        "components-global-search-color-omnibox-item-bg-hover": 1,
        "components-global-search-color-omnibox-item-text-default": 1,
        "components-global-search-color-omnibox-item-text-hover": 1,
        "components-global-search-color-omnibox-label-text-default": 1,
        "components-global-search-color-omnibox-category-text-default": 1,
        "components-generic-card-listing-color-bg": 1,
        "components-generic-card-listing-color-title": 1,
        "components-generic-card-listing-color-copy": 1,
        "components-header-color-bg": 1,
        "components-header-color-bottom-stroke": 1,
        "components-header-color-search-search-border-default": 1,
        "components-header-color-search-search-text": 1,
        "components-header-color-search-search-icon": 1,
        "components-header-color-search-search-bar": 1,
        "components-header-color-mobile-mobile-expanded-bg": 1,
        "components-header-color-mobile-pressed-bg": 1,
        "components-header-color-language-selector-language": 1,
        "components-header-color-language-selector-country": 1,
        "components-header-color-language-selector-country-hover-bg": 1,
        "components-header-color-language-selector-megamenu-language": 1,
        "components-header-color-language-selector-megamenu-country": 1,
        "components-header-color-language-selector-megamenu-country-hover": 1,
        "components-header-color-language-selector-bg": 1,
        "components-header-color-language-selector-stroke": 1,
        "components-header-color-mega-menu-bg": 1,
        "components-header-color-mega-menu-featured-bg": 1,
        "components-header-color-mega-menu-featured-title": 1,
        "components-header-color-mega-menu-category-title": 1,
        "components-header-color-mega-menu-bg-accent": 1,
        "components-header-color-icon-icon-default": 1,
        "components-header-color-icon-icon-hover": 1,
        "components-header-color-icon-icon-pressed": 1,
        "components-header-color-links-nav-main-link-default": 1,
        "components-header-color-links-nav-main-link-hover": 1,
        "components-header-color-links-nav-main-link-pressed": 1,
        "components-header-color-links-nav-main-link-active": 1,
        "components-header-color-links-nav-main-active-bar": 1,
        "components-header-color-links-nav-main-bg-color": 1,
        "components-header-color-links-nav-category-link-category-default": 1,
        "components-header-color-links-nav-category-link-category-hover": 1,
        "components-header-color-links-nav-category-link-category-pressed": 1,
        "components-header-color-links-nav-category-link-category-active": 1,
        "components-header-color-links-nav-menu-link-link-default": 1,
        "components-header-color-links-nav-menu-link-link-hover": 1,
        "components-header-color-links-nav-menu-link-link-pressed": 1,
        "components-header-color-links-nav-menu-link-link-active": 1,
        "components-header-color-links-nav-menu-link-bg": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-default": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-pressed": 1,
        "components-header-color-links-nav-subcategory-link-subcategory-active": 1,
        "components-hero-homepage-color-bg": 1,
        "components-hero-homepage-color-container-bg": 1,
        "components-hero-homepage-color-eyebrow": 1,
        "components-hero-homepage-color-header": 1,
        "components-hero-homepage-color-sub-header": 1,
        "components-hero-homepage-color-body": 1,
        "components-hero-homepage-color-play-pause": 1,
        "components-hero-internal-color-default-title": 1,
        "components-hero-internal-color-default-body": 1,
        "components-hero-internal-color-default-bg": 1,
        "components-hero-internal-color-accent-bg": 1,
        "components-hero-internal-color-with-image-bg": 1,
        "components-hero-internal-color-default-bg-2": 1,
        "components-hero-internal-color-with-image-title": 1,
        "components-hero-internal-color-with-image-body": 1,
        "components-heading-page-title-color-default-bg": 1,
        "components-heading-page-title-color-default-title": 1,
        "components-heading-page-title-color-default-body": 1,
        "components-heading-page-title-color-light-bg": 1,
        "components-heading-page-title-color-light-title": 1,
        "components-heading-page-title-color-light-body": 1,
        "components-heading-page-title-color-color-bg": 1,
        "components-heading-page-title-color-color-title": 1,
        "components-heading-page-title-color-color-body": 1,
        "components-hyperlink-color-dark-default": 1,
        "components-hyperlink-color-dark-hover": 1,
        "components-hyperlink-color-dark-visited": 1,
        "components-hyperlink-color-light-default": 1,
        "components-hyperlink-color-light-hover": 1,
        "components-hyperlink-color-light-visited": 1,
        "components-icon-default": 1,
        "components-icon-focused": 1,
        "components-icon-card-color-bg": 1,
        "components-icon-card-color-icon-bg": 1,
        "components-icon-card-color-icon": 1,
        "components-icon-card-color-title": 1,
        "components-icon-card-color-subtitle": 1,
        "components-icon-card-color-description": 1,
        "components-icon-card-listing-color-bg": 1,
        "components-icon-card-listing-color-inner-bg": 1,
        "components-icon-card-listing-color-brand-bg": 1,
        "components-icon-card-listing-color-title": 1,
        "components-icon-card-listing-color-copy": 1,
        "components-icon-card-listing-color-inner-legal-text": 1,
        "components-jumplink-color-bg-default": 1,
        "components-jumplink-color-link-text-default": 1,
        "components-jumplink-color-link-text-hover": 1,
        "components-jumplink-color-link-border-hover": 1,
        "components-jumplink-color-link-text-active": 1,
        "components-jumplink-color-link-border-active": 1,
        "components-jumplink-color-title-text": 1,
        "components-jumplink-color-description-text": 1,
        "components-layout-bg-default": 1,
        "components-lightbox-color-overlay-bg": 0.02,
        "components-lightbox-color-title": 1,
        "components-lightbox-color-body": 1,
        "components-label-color-on-color-label-label-default": 1,
        "components-label-color-on-color-label-label-hover": 1,
        "components-label-color-on-color-label-label-pressed": 1,
        "components-label-color-on-color-background-bg-default": 1,
        "components-label-color-on-color-background-bg-hover": 1,
        "components-label-color-on-color-background-bg-pressed": 1,
        "components-label-color-on-color-stroke-hover": 1,
        "components-label-color-on-color-stroke-pressed": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-color-stroke-default-gradient-fill-4": 1,
        "components-label-color-on-white-label-label-default": 1,
        "components-label-color-on-white-label-label-hover": 1,
        "components-label-color-on-white-label-label-pressed": 1,
        "components-label-color-on-white-background-bg-default": 0.02,
        "components-label-color-on-white-background-bg-hover": 1,
        "components-label-color-on-white-background-bg-pressed": 1,
        "components-label-color-on-white-stroke-hover": 1,
        "components-label-color-on-white-stroke-pressed": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-1": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-2": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-3": 1,
        "components-label-color-on-white-stroke-default-gradient-fill-4": 1,
        "components-legal-disclaimer-color-default-bg": 1,
        "components-legal-disclaimer-color-default-title": 1,
        "components-legal-disclaimer-color-default-body": 1,
        "components-legal-disclaimer-color-light-bg": 1,
        "components-legal-disclaimer-color-light-title": 1,
        "components-legal-disclaimer-color-light-body": 1,
        "components-legal-disclaimer-color-brand-bg": 1,
        "components-legal-disclaimer-color-brand-title": 1,
        "components-legal-disclaimer-color-brand-body": 1,
        "components-media-video-thumbnail-overlay-bg": 0.02,
        "components-media-caption-color-bg": 1,
        "components-media-caption-color-text": 1,
        "components-media-gallery-color-bg": 1,
        "components-media-gallery-color-title": 1,
        "components-media-gallery-color-body": 1,
        "components-pagination-on-white-accent-default": 1,
        "components-pagination-on-white-accent-scroll": 1,
        "components-pagination-on-white-disabled": 1,
        "components-pagination-on-white-bg": 1,
        "components-pagination-on-white-stroke": 1,
        "components-pagination-on-white-bg-active": 1,
        "components-pagination-on-white-bg-hover": 1,
        "components-pagination-on-white-text-default": 1,
        "components-pagination-on-white-text-hover": 1,
        "components-pagination-on-white-text-active": 1,
        "components-pagination-on-color-accent-default": 1,
        "components-pagination-on-color-accent-scroll": 1,
        "components-pagination-on-color-disabled": 1,
        "components-pagination-on-color-bg": 1,
        "components-pagination-on-color-stroke": 1,
        "components-pagination-on-color-bg-active": 1,
        "components-pagination-on-color-bg-hover": 1,
        "components-pagination-on-color-text-default": 1,
        "components-pagination-on-color-text-hover": 1,
        "components-pagination-on-color-text-active": 1,
        "components-pdf-link-card-color-bg": 1,
        "components-pdf-link-card-color-card-bg": 1,
        "components-pdf-link-card-color-card-title": 1,
        "components-pdf-link-card-color-card-subtitle": 1,
        "components-pdf-link-card-color-card-body": 1,
        "components-product-card-color-bg": 1,
        "components-product-card-color-content-bg": 1,
        "components-product-card-color-border": 1,
        "components-product-card-color-title": 1,
        "components-product-card-color-description": 1,
        "components-product-card-color-star-rating": 1,
        "components-product-card-color-badge-bg": 1,
        "components-product-card-color-badge-text": 1,
        "components-product-card-listing-color-bg": 1,
        "components-product-card-listing-color-title": 1,
        "components-product-card-listing-color-copy": 1,
        "components-product-card-listing-color-legal-text": 1,
        "components-product-information-color-bg": 1,
        "components-product-information-color-content-bg": 1,
        "components-product-information-color-headline": 1,
        "components-product-information-color-body": 1,
        "components-product-information-color-detail-text": 1,
        "components-product-information-color-divider": 1,
        "components-product-information-color-icon": 1,
        "components-product-information-color-play-icon": 1,
        "components-product-information-color-selected-stroke": 1,
        "components-product-results-list-with-search-color-bg": 1,
        "components-promo-color-default-bg": 1,
        "components-promo-color-default-image-bg": 1,
        "components-promo-color-default-content-bg": 1,
        "components-promo-color-default-title": 1,
        "components-promo-color-default-body": 1,
        "components-promo-color-default-category": 1,
        "components-promo-color-light-bg": 1,
        "components-promo-color-light-image-bg": 1,
        "components-promo-color-light-content-bg": 1,
        "components-promo-color-light-title": 1,
        "components-promo-color-light-body": 1,
        "components-promo-color-light-category": 1,
        "components-promo-color-brand-bg": 1,
        "components-promo-color-brand-image-bg": 1,
        "components-promo-color-brand-content-bg": 1,
        "components-promo-color-brand-title": 1,
        "components-promo-color-brand-body": 1,
        "components-promo-color-brand-category": 1,
        "components-pull-quote-color-bg": 1,
        "components-pull-quote-color-stroke": 1,
        "components-pull-quote-color-quote-bg": 1,
        "components-pull-quote-color-quote-title": 1,
        "components-pull-quote-color-quote-by-text": 1,
        "components-pull-quote-color-quote-detail": 1,
        "components-pull-quote-color-quote-highlight": 1,
        "components-promo-alternate-color-default-bg": 1,
        "components-promo-alternate-color-default-image-bg": 1,
        "components-promo-alternate-color-default-content-bg": 1,
        "components-promo-alternate-color-default-title": 1,
        "components-promo-alternate-color-default-body": 1,
        "components-promo-alternate-color-default-category": 1,
        "components-promo-alternate-color-light-bg": 1,
        "components-promo-alternate-color-light-image-bg": 1,
        "components-promo-alternate-color-light-content-bg": 1,
        "components-promo-alternate-color-light-title": 1,
        "components-promo-alternate-color-light-body": 1,
        "components-promo-alternate-color-light-category": 1,
        "components-promo-alternate-color-brand-bg": 1,
        "components-promo-alternate-color-brand-image-bg": 1,
        "components-promo-alternate-color-brand-content-bg": 1,
        "components-promo-alternate-color-brand-title": 1,
        "components-promo-alternate-color-brand-body": 1,
        "components-promo-alternate-color-brand-category": 1,
        "components-share-list-color-title": 1,
        "components-share-list-color-bg": 1,
        "components-share-list-color-link-default": 1,
        "components-share-list-color-link-hover": 1,
        "components-share-list-color-bg-link-hover": 1,
        "components-share-list-color-close-icon": 1,
        "components-share-list-color-facebook-icon": 1,
        "components-share-list-color-pinterest-icon": 1,
        "components-share-list-color-twitter-icon": 1,
        "components-share-list-color-basic-icon-default": 1,
        "components-share-list-color-icon-hover": 1,
        "components-share-list-color-border": 1,
        "components-site-search-bg": 1,
        "components-site-search-results_text": 1,
        "components-site-search-filter-filter-selected-bg": 1,
        "components-site-search-facet-group-label-bottom-line": 1,
        "components-site-search-facet-group-label-text": 1,
        "components-site-search-facet-group-label-bg": 1,
        "components-site-search-facet-bg": 1,
        "components-site-search-facet-stroke": 1,
        "components-site-search-refine-bg": 1,
        "components-site-search-refine-right-divider": 1,
        "components-site-search-refine-title": 1,
        "components-site-search-hero-search-bg": 1,
        "components-site-search-hero-search-accent-bg": 1,
        "components-site-search-hero-search-title": 1,
        "components-stats-stat-text": 1,
        "components-stats-stat-text-on-light": 1,
        "components-stats-supporting-text": 1,
        "components-stats-bg-default": 1,
        "components-stats-bg-inner": 1,
        "components-stats-bg-section-inner": 1,
        "components-stats-title": 1,
        "components-stats-copy": 1,
        "components-supporting-text-default": 1,
        "components-supporting-text-destructive": 1,
        "components-tab-bg": 1,
        "components-tab-border-default": 1,
        "components-tab-border-hover": 1,
        "components-tab-border-focused": 1,
        "components-tab-border-destructive": 1,
        "components-tab-label-text-default": 1,
        "components-tab-item-text-default": 1,
        "components-tab-item-bg-default": 1,
        "components-tab-item-bg-hover": 1,
        "components-tab-input-field-default": 1,
        "components-tab-input-field-focused": 1,
        "components-tab-input-label-focused": 1,
        "components-tab-input-field-destructive": 1,
        "components-tab-bg-2": 1,
        "components-tag-color-bg": 1,
        "components-tag-color-text": 1,
        "components-tag-color-stroke": 1,
        "components-tag-color-bg-hover": 1,
        "components-tag-color-text-hover": 1,
        "components-tag-color-stroke-hover": 1,
        "components-text-field-bg": 1,
        "components-text-field-bg-focused": 1,
        "components-text-field-border-default": 1,
        "components-text-field-border-hover": 1,
        "components-text-field-border-focused": 1,
        "components-text-field-border-destructive": 1,
        "components-text-field-input-field-default": 1,
        "components-text-field-input-field-focused": 1,
        "components-text-field-input-label-focused": 1,
        "components-text-field-input-field-destructive": 1,
        "components-text-field-icon-default": 1,
        "components-text-field-icon-destructive": 1,
        "components-text-field-supporting-text-default": 1,
        "components-text-field-supporting-text-destructive": 1,
        "components-tip-color-bg": 1,
        "components-tip-color-title": 1,
        "components-tip-color-body": 1,
        "components-tip-color-stroke": 1,
        "components-video-color-bg": 1,
        "themes-general-surface-color-light-bg": 1,
        "themes-general-surface-color-light-title": 1,
        "themes-general-surface-color-light-body": 1,
        "themes-general-surface-color-light-decor-line-break": 1,
        "themes-general-surface-color-light-rule-line": 1,
        "themes-general-surface-color-dark-bg": 1,
        "themes-general-surface-color-dark-title": 1,
        "themes-general-surface-color-dark-body": 1,
        "themes-general-surface-color-dark-decor-line-break": 1,
        "themes-general-card-color-dark-title": 1,
        "themes-general-card-color-dark-subtitle": 1,
        "themes-general-card-color-dark-body": 1,
        "themes-general-card-color-light-title": 1,
        "themes-general-card-color-light-subtitle": 1,
        "themes-general-card-color-light-body": 1,
        "themes-shadow-color": 1,
        "themes-shadow-share-list-color-fill": 0,
        "themes-shadow-header-menu-color-fill": 0,
        "themes-gradient-loader-gradient-fill-1": 1,
        "themes-gradient-loader-gradient-fill-2": 0.02,
        "themes-color-background-white": 1,
        "themes-color-background-light": 1,
        "themes-color-background-dark": 1,
        "themes-color-background-black": 1,
        "themes-color-background-brand1": 1,
        "themes-color-background-brand2": 1,
        "themes-color-background-accent1": 1,
        "themes-color-background-accent2": 1,
        "themes-color-background-body": 1,
        "themes-color-stroke-white": 1,
        "themes-color-stroke-light": 1,
        "themes-color-stroke-dark": 1,
        "themes-color-stroke-black": 1,
        "themes-color-stroke-brand1": 1,
        "themes-color-stroke-brand2": 1,
        "themes-color-stroke-accent1": 1,
        "themes-color-stroke-accent2": 1,
        "themes-color-text-white": 1,
        "themes-color-text-light": 1,
        "themes-color-text-dark": 1,
        "themes-color-text-black": 1,
        "themes-color-text-brand1": 1,
        "themes-color-text-brand2": 1,
        "themes-color-text-accent1": 1,
        "themes-color-text-accent2": 1,
        "colors-grayscale-100": 1,
        "colors-grayscale-200": 1,
        "colors-grayscale-300": 1,
        "colors-grayscale-400": 1,
        "colors-grayscale-500": 1,
        "colors-grayscale-600": 1,
        "colors-grayscale-700": 1,
        "colors-brand-1-100": 1,
        "colors-brand-1-200": 1,
        "colors-brand-1-300": 1,
        "colors-brand-1-400": 1,
        "colors-brand-1-500": 1,
        "colors-brand-1-600": 1,
        "colors-brand-1-700": 1,
        "colors-brand-2-100": 1,
        "colors-brand-2-200": 1,
        "colors-brand-2-300": 1,
        "colors-brand-2-400": 1,
        "colors-brand-2-500": 1,
        "colors-brand-2-600": 1,
        "colors-brand-2-700": 1,
        "colors-accent-1-100": 1,
        "colors-accent-1-200": 1,
        "colors-accent-1-300": 1,
        "colors-accent-1-400": 1,
        "colors-accent-1-500": 1,
        "colors-accent-1-600": 1,
        "colors-accent-1-700": 1,
        "colors-accent-2-100": 1,
        "colors-accent-2-200": 1,
        "colors-accent-2-300": 1,
        "colors-accent-2-400": 1,
        "colors-accent-2-500": 1,
        "colors-accent-2-600": 1,
        "colors-accent-2-700": 1,
        "colors-accent-3-100": 1,
        "colors-accent-3-200": 1,
        "colors-accent-3-300": 1,
        "colors-accent-3-400": 1,
        "colors-accent-3-500": 1,
        "colors-accent-3-600": 1,
        "colors-accent-3-700": 1,
        "colors-accent-4-400_storage": 1,
        "colors-accessible-accent-1-100": 1,
        "colors-accessible-accent-1-200": 1,
        "colors-accessible-accent-1-300": 1,
        "colors-accessible-accent-1-400": 1,
        "colors-accessible-accent-1-500": 1,
        "colors-accessible-accent-1-600": 1,
        "colors-accessible-accent-1-700": 1,
        "colors-accessible-accent-2-100": 1,
        "colors-accessible-accent-2-200": 1,
        "colors-accessible-accent-2-300": 1,
        "colors-accessible-accent-2-400": 1,
        "colors-accessible-accent-2-500": 1,
        "colors-accessible-accent-2-600": 1,
        "colors-accessible-accent-2-700": 1,
        "colors-accessible-brand-2-100": 1,
        "colors-accessible-brand-2-200": 1,
        "colors-accessible-brand-2-300": 1,
        "colors-accessible-brand-2-400": 1,
        "colors-accessible-brand-2-500": 1,
        "colors-accessible-brand-2-600": 1,
        "colors-accessible-brand-2-700": 1
      }
    }
  },
  "componentVariants": {
    "DART": {
      "bannerDefault": {
        "primaryCta": {
          "color": "tonal",
          "type": "outline"
        }
      },
      "genericCardListing": {
        "hasDecor": false
      },
      "heroHomepage": {
        "primaryCta": {
          "color": "white",
          "type": "filled"
        },
        "secondaryCta": {
          "color": "tonal",
          "type": "outline"
        },
        "label": "color",
        "carousel": {
          "pagination": "color",
          "navigation": "white"
        }
      },
      "heroInternal": {
        "hasDecor": false
      },
      "productCardListing": {
        "hasDecor": false,
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "carousel": {
          "pagination": "color",
          "navigation": "brand"
        }
      },
      "articleCardListing": {
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "pullQuote": {
        "carousel": {
          "pagination": "color",
          "navigation": "white"
        }
      },
      "topBar": {
        "primaryCta": {
          "color": "tonal"
        }
      },
      "searchListing": {
        "labelColor": "white"
      },
      "productInformation": {
        "label": "white",
        "primaryCta": {
          "color": "brand",
          "type": "filled"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "heroArticle": {
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      }
    },
    "Ziploc": {
      "bannerDefault": {
        "primaryCta": {
          "color": "tonal",
          "type": "outline"
        }
      },
      "genericCardListing": {
        "hasDecor": false
      },
      "heroHomepage": {
        "primaryCta": {
          "color": "white",
          "type": "filled"
        },
        "secondaryCta": {
          "color": "white",
          "type": "outline"
        },
        "label": "color",
        "carousel": {
          "pagination": "color",
          "navigation": "white"
        }
      },
      "heroInternal": {
        "hasDecor": false
      },
      "productCardListing": {
        "hasDecor": false,
        "primaryCta": {
          "color": "white",
          "type": "outline"
        },
        "carousel": {
          "pagination": "color",
          "navigation": "brand"
        }
      },
      "articleCardListing": {
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "pullQuote": {
        "carousel": {
          "pagination": "color",
          "navigation": "white"
        }
      },
      "topBar": {
        "primaryCta": {
          "color": "tonal"
        }
      },
      "searchListing": {
        "labelColor": "white"
      },
      "productInformation": {
        "label": "white",
        "primaryCta": {
          "color": "brand",
          "type": "filled"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "heroArticle": {
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "multipleBar": {
        "skewX": {
          "value": "40deg",
          "type": "dimension"
        },
        "top": {
          "borderOne": {
            "height": {
              "value": "11px",
              "type": "dimension"
            },
            "fill": {
              "value": "#48C249",
              "type": "color"
            }
          },
          "borderTwo": {
            "height": {
              "value": "11px",
              "type": "dimension"
            },
            "fill": {
              "value": "#00CCFF",
              "type": "color"
            }
          },
          "borderThree": {
            "height": {
              "value": "11px",
              "type": "dimension"
            },
            "fill": {
              "value": "#F719B8",
              "type": "color"
            }
          },
          "borderFour": {
            "height": {
              "value": "11px",
              "type": "dimension"
            },
            "fill": {
              "value": "#0241CD",
              "type": "color"
            }
          }
        },
        "bottom": {
          "borderFive": {
            "height": {
              "value": "11px",
              "type": "dimension"
            },
            "fill": {
              "value": "#48C249",
              "type": "color"
            }
          },
          "borderSix": {
            "height": {
              "value": "11px",
              "type": "dimension"
            },
            "fill": {
              "value": "#00CCFF",
              "type": "color"
            }
          },
          "borderSeven": {
            "height": {
              "value": "11px",
              "type": "dimension"
            },
            "fill": {
              "value": "#F719B8",
              "type": "color"
            }
          },
          "borderEight": {
            "height": {
              "value": "11px",
              "type": "dimension"
            },
            "fill": {
              "value": "#0241CD",
              "type": "color"
            }
          }
        }
      }
    },
    "Off": {
      "bannerDefault": {
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        }
      },
      "genericCardListing": {
        "hasDecor": true
      },
      "heroHomepage": {
        "primaryCta": {
          "color": "brand",
          "type": "filled"
        },
        "secondaryCta": {
          "color": "tonal",
          "type": "outline"
        },
        "label": "white",
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "heroInternal": {
        "hasDecor": true
      },
      "productCardListing": {
        "hasDecor": true,
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "carousel": {
          "pagination": "color",
          "navigation": "brand"
        }
      },
      "articleCardListing": {
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "pullQuote": {
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "topBar": {
        "primaryCta": {
          "color": "brand"
        }
      },
      "searchListing": {
        "labelColor": "color"
      },
      "productInformation": {
        "label": "white",
        "primaryCta": {
          "color": "brand",
          "type": "filled"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "heroArticle": {
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      }
    },
    "Autan": {
      "bannerDefault": {
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        }
      },
      "genericCardListing": {
        "hasDecor": true
      },
      "heroHomepage": {
        "primaryCta": {
          "color": "brand",
          "type": "filled"
        },
        "secondaryCta": {
          "color": "tonal",
          "type": "outline"
        },
        "label": "white",
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "heroInternal": {
        "hasDecor": true
      },
      "productCardListing": {
        "hasDecor": true,
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "carousel": {
          "pagination": "color",
          "navigation": "brand"
        }
      },
      "articleCardListing": {
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "pullQuote": {
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "topBar": {
        "primaryCta": {
          "color": "brand"
        }
      },
      "searchListing": {
        "labelColor": "color"
      },
      "productInformation": {
        "label": "white",
        "primaryCta": {
          "color": "brand",
          "type": "filled"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "heroArticle": {
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      }
    },
    "Corporate": {
      "bannerDefault": {
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        }
      },
      "genericCardListing": {
        "hasDecor": false
      },
      "heroHomepage": {
        "primaryCta": {
          "color": "brand",
          "type": "filled"
        },
        "secondaryCta": {
          "color": "tonal",
          "type": "outline"
        },
        "label": "white",
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "heroInternal": {
        "hasDecor": false
      },
      "productCardListing": {
        "hasDecor": false,
        "primaryCta": {
          "color": "tonal",
          "type": "outline"
        },
        "carousel": {
          "pagination": "color",
          "navigation": "brand"
        }
      },
      "articleCardListing": {
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "pullQuote": {
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "topBar": {
        "primaryCta": {
          "color": "brand"
        }
      },
      "searchListing": {
        "labelColor": "white"
      },
      "productInformation": {
        "label": "white",
        "primaryCta": {
          "color": "brand",
          "type": "filled"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "heroArticle": {
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "multipleBar": {
        "skewX": {
          "value": "0deg",
          "type": "dimension"
        },
        "top": {
          "borderOne": {
            "height": {
              "value": "15px",
              "type": "dimension"
            },
            "fill": {
              "value": "#D92C29",
              "type": "color"
            }
          },
          "borderTwo": {
            "height": {
              "value": "15px",
              "type": "dimension"
            },
            "fill": {
              "value": "#D92C29",
              "type": "color"
            }
          },
          "borderThree": {
            "height": {
              "value": "24px",
              "type": "dimension"
            },
            "fill": {
              "value": "#FFFFFF",
              "type": "color"
            }
          },
          "borderFour": {
            "height": {
              "value": "24px",
              "type": "dimension"
            },
            "fill": {
              "value": "#FFFFFF",
              "type": "color"
            }
          }
        },
        "bottom": {
          "borderFive": {
            "height": {
              "value": "24px",
              "type": "dimension"
            },
            "fill": {
              "value": "#128437",
              "type": "color"
            }
          },
          "borderSix": {
            "height": {
              "value": "24px",
              "type": "dimension"
            },
            "fill": {
              "value": "#128437",
              "type": "color"
            }
          },
          "borderSeven": {
            "height": {
              "value": "15px",
              "type": "dimension"
            },
            "fill": {
              "value": "#FECE2F",
              "type": "color"
            }
          },
          "borderEight": {
            "height": {
              "value": "15px",
              "type": "dimension"
            },
            "fill": {
              "value": "#FECE2F",
              "type": "color"
            }
          }
        }
      }
    },
    "PIDS": {
      "bannerDefault": {
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        }
      },
      "genericCardListing": {
        "hasDecor": false
      },
      "heroHomepage": {
        "primaryCta": {
          "color": "brand",
          "type": "filled"
        },
        "secondaryCta": {
          "color": "tonal",
          "type": "outline"
        },
        "label": "white",
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "heroInternal": {
        "hasDecor": false
      },
      "productCardListing": {
        "hasDecor": false,
        "primaryCta": {
          "color": "tonal",
          "type": "outline"
        },
        "carousel": {
          "pagination": "color",
          "navigation": "brand"
        }
      },
      "articleCardListing": {
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "pullQuote": {
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "topBar": {
        "primaryCta": {
          "color": "brand"
        }
      },
      "searchListing": {
        "labelColor": "white"
      },
      "productInformation": {
        "label": "white",
        "primaryCta": {
          "color": "brand",
          "type": "filled"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "heroArticle": {
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "multipleBar": {
        "skewX": {
          "value": "0deg",
          "type": "dimension"
        },
        "top": {
          "borderOne": {
            "height": {
              "value": "15px",
              "type": "dimension"
            },
            "fill": {
              "value": "#D92C29",
              "type": "color"
            }
          },
          "borderTwo": {
            "height": {
              "value": "15px",
              "type": "dimension"
            },
            "fill": {
              "value": "#D92C29",
              "type": "color"
            }
          },
          "borderThree": {
            "height": {
              "value": "24px",
              "type": "dimension"
            },
            "fill": {
              "value": "#FFFFFF",
              "type": "color"
            }
          },
          "borderFour": {
            "height": {
              "value": "24px",
              "type": "dimension"
            },
            "fill": {
              "value": "#FFFFFF",
              "type": "color"
            }
          }
        },
        "bottom": {
          "borderFive": {
            "height": {
              "value": "24px",
              "type": "dimension"
            },
            "fill": {
              "value": "#128437",
              "type": "color"
            }
          },
          "borderSix": {
            "height": {
              "value": "24px",
              "type": "dimension"
            },
            "fill": {
              "value": "#128437",
              "type": "color"
            }
          },
          "borderSeven": {
            "height": {
              "value": "15px",
              "type": "dimension"
            },
            "fill": {
              "value": "#FECE2F",
              "type": "color"
            }
          },
          "borderEight": {
            "height": {
              "value": "15px",
              "type": "dimension"
            },
            "fill": {
              "value": "#FECE2F",
              "type": "color"
            }
          }
        }
      }
    },
    "AutanDefense": {
      "bannerDefault": {
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        }
      },
      "genericCardListing": {
        "hasDecor": true
      },
      "heroHomepage": {
        "primaryCta": {
          "color": "brand",
          "type": "filled"
        },
        "secondaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "label": "white",
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "heroInternal": {
        "hasDecor": false
      },
      "productCardListing": {
        "hasDecor": false,
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "carousel": {
          "pagination": "color",
          "navigation": "brand"
        }
      },
      "articleCardListing": {
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "pullQuote": {
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "topBar": {
        "primaryCta": {
          "color": "brand"
        }
      },
      "searchListing": {
        "labelColor": "color"
      },
      "productInformation": {
        "label": "white",
        "primaryCta": {
          "color": "brand",
          "type": "filled"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "heroArticle": {
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      }
    },
    "OffDefense": {
      "bannerDefault": {
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        }
      },
      "genericCardListing": {
        "hasDecor": true
      },
      "heroHomepage": {
        "primaryCta": {
          "color": "brand",
          "type": "filled"
        },
        "secondaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "label": "white",
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "heroInternal": {
        "hasDecor": false
      },
      "productCardListing": {
        "hasDecor": false,
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "carousel": {
          "pagination": "color",
          "navigation": "brand"
        }
      },
      "articleCardListing": {
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "pullQuote": {
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "topBar": {
        "primaryCta": {
          "color": "brand"
        }
      },
      "searchListing": {
        "labelColor": "color"
      },
      "productInformation": {
        "label": "white",
        "primaryCta": {
          "color": "brand",
          "type": "filled"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "heroArticle": {
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      }
    },
    "Glade": {
      "bannerDefault": {
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        }
      },
      "genericCardListing": {
        "hasDecor": false
      },
      "heroHomepage": {
        "primaryCta": {
          "color": "brand",
          "type": "filled"
        },
        "secondaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "label": "white",
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "heroInternal": {
        "hasDecor": false
      },
      "productCardListing": {
        "hasDecor": false,
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "carousel": {
          "pagination": "color",
          "navigation": "brand"
        }
      },
      "articleCardListing": {
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "pullQuote": {
        "carousel": {
          "pagination": "white",
          "navigation": "white"
        }
      },
      "topBar": {
        "primaryCta": {
          "color": "brand"
        }
      },
      "searchListing": {
        "labelColor": "color"
      },
      "productInformation": {
        "label": "white",
        "primaryCta": {
          "color": "brand",
          "type": "filled"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "heroArticle": {
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "multipleBar": {
        "skewX": {
          "value": "0deg",
          "type": "dimension"
        },
        "top": {
          "borderOne": {
            "height": {
              "value": "15px",
              "type": "dimension"
            },
            "fill": {
              "value": "#00254D",
              "type": "color"
            }
          },
          "borderTwo": {
            "height": {
              "value": "15px",
              "type": "dimension"
            },
            "fill": {
              "value": "#00254D",
              "type": "color"
            }
          },
          "borderThree": {
            "height": {
              "value": "24px",
              "type": "dimension"
            },
            "fill": {
              "value": "#FFFFFF",
              "type": "color"
            }
          },
          "borderFour": {
            "height": {
              "value": "24px",
              "type": "dimension"
            },
            "fill": {
              "value": "#FFFFFF",
              "type": "color"
            }
          }
        },
        "bottom": {
          "borderFive": {
            "height": {
              "value": "24px",
              "type": "dimension"
            },
            "fill": {
              "value": "#2FCFC8",
              "type": "color"
            }
          },
          "borderSix": {
            "height": {
              "value": "24px",
              "type": "dimension"
            },
            "fill": {
              "value": "#2FCFC8",
              "type": "color"
            }
          },
          "borderSeven": {
            "height": {
              "value": "15px",
              "type": "dimension"
            },
            "fill": {
              "value": "#FEC02F",
              "type": "color"
            }
          },
          "borderEight": {
            "height": {
              "value": "15px",
              "type": "dimension"
            },
            "fill": {
              "value": "#FEC02F",
              "type": "color"
            }
          }
        }
      }
    },
    "Raid": {
      "bannerDefault": {
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        }
      },
      "genericCardListing": {
        "hasDecor": false
      },
      "heroHomepage": {
        "primaryCta": {
          "color": "brand",
          "type": "filled"
        },
        "secondaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "label": "color",
        "carousel": {
          "pagination": "white",
          "navigation": "white"
        }
      },
      "heroInternal": {
        "hasDecor": false
      },
      "productCardListing": {
        "hasDecor": false,
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "white"
        }
      },
      "articleCardListing": {
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "pullQuote": {
        "carousel": {
          "pagination": "white",
          "navigation": "white"
        }
      },
      "topBar": {
        "primaryCta": {
          "color": "brand"
        }
      },
      "searchListing": {
        "labelColor": "color"
      },
      "productInformation": {
        "label": "white",
        "primaryCta": {
          "color": "brand",
          "type": "filled"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "heroArticle": {
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "multipleBar": {
        "skewX": {
          "value": "0deg",
          "type": "dimension"
        },
        "top": {
          "borderOne": {
            "height": {
              "value": "15px",
              "type": "dimension"
            },
            "fill": {
              "value": "#00254D",
              "type": "color"
            }
          },
          "borderTwo": {
            "height": {
              "value": "15px",
              "type": "dimension"
            },
            "fill": {
              "value": "#00254D",
              "type": "color"
            }
          },
          "borderThree": {
            "height": {
              "value": "24px",
              "type": "dimension"
            },
            "fill": {
              "value": "#FFFFFF",
              "type": "color"
            }
          },
          "borderFour": {
            "height": {
              "value": "24px",
              "type": "dimension"
            },
            "fill": {
              "value": "#FFFFFF",
              "type": "color"
            }
          }
        },
        "bottom": {
          "borderFive": {
            "height": {
              "value": "24px",
              "type": "dimension"
            },
            "fill": {
              "value": "#2FCFC8",
              "type": "color"
            }
          },
          "borderSix": {
            "height": {
              "value": "24px",
              "type": "dimension"
            },
            "fill": {
              "value": "#2FCFC8",
              "type": "color"
            }
          },
          "borderSeven": {
            "height": {
              "value": "15px",
              "type": "dimension"
            },
            "fill": {
              "value": "#FEC02F",
              "type": "color"
            }
          },
          "borderEight": {
            "height": {
              "value": "15px",
              "type": "dimension"
            },
            "fill": {
              "value": "#FEC02F",
              "type": "color"
            }
          }
        }
      }
    },
    "Baygon": {
      "bannerDefault": {
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        }
      },
      "genericCardListing": {
        "hasDecor": false
      },
      "heroHomepage": {
        "primaryCta": {
          "color": "brand",
          "type": "filled"
        },
        "secondaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "label": "white",
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "heroInternal": {
        "hasDecor": false
      },
      "productCardListing": {
        "hasDecor": false,
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "articleCardListing": {
        "primaryCta": {
          "color": "tonal",
          "type": "outline"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "pullQuote": {
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "topBar": {
        "primaryCta": {
          "color": "white"
        }
      },
      "searchListing": {
        "labelColor": "color"
      },
      "productInformation": {
        "label": "white",
        "primaryCta": {
          "color": "brand",
          "type": "filled"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "heroArticle": {
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "multipleBar": {
        "skewX": {
          "value": "0deg",
          "type": "dimension"
        },
        "top": {
          "borderOne": {
            "height": {
              "value": "15px",
              "type": "dimension"
            },
            "fill": {
              "value": "#00254D",
              "type": "color"
            }
          },
          "borderTwo": {
            "height": {
              "value": "15px",
              "type": "dimension"
            },
            "fill": {
              "value": "#00254D",
              "type": "color"
            }
          },
          "borderThree": {
            "height": {
              "value": "24px",
              "type": "dimension"
            },
            "fill": {
              "value": "#FFFFFF",
              "type": "color"
            }
          },
          "borderFour": {
            "height": {
              "value": "24px",
              "type": "dimension"
            },
            "fill": {
              "value": "#FFFFFF",
              "type": "color"
            }
          }
        },
        "bottom": {
          "borderFive": {
            "height": {
              "value": "24px",
              "type": "dimension"
            },
            "fill": {
              "value": "#2FCFC8",
              "type": "color"
            }
          },
          "borderSix": {
            "height": {
              "value": "24px",
              "type": "dimension"
            },
            "fill": {
              "value": "#2FCFC8",
              "type": "color"
            }
          },
          "borderSeven": {
            "height": {
              "value": "15px",
              "type": "dimension"
            },
            "fill": {
              "value": "#FEC02F",
              "type": "color"
            }
          },
          "borderEight": {
            "height": {
              "value": "15px",
              "type": "dimension"
            },
            "fill": {
              "value": "#FEC02F",
              "type": "color"
            }
          }
        }
      }
    },
    "Duck": {
      "bannerDefault": {
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        }
      },
      "genericCardListing": {
        "hasDecor": false
      },
      "heroHomepage": {
        "primaryCta": {
          "color": "brand",
          "type": "filled"
        },
        "secondaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "label": "color",
        "carousel": {
          "pagination": "color",
          "navigation": "white"
        }
      },
      "heroInternal": {
        "hasDecor": false
      },
      "productCardListing": {
        "hasDecor": true,
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "carousel": {
          "pagination": "color",
          "navigation": "white"
        }
      },
      "articleCardListing": {
        "primaryCta": {
          "color": "tonal",
          "type": "outline"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "pullQuote": {
        "carousel": {
          "pagination": "color",
          "navigation": "white"
        }
      },
      "topBar": {
        "primaryCta": {
          "color": "white"
        }
      },
      "searchListing": {
        "labelColor": "color"
      },
      "productInformation": {
        "label": "color",
        "primaryCta": {
          "color": "brand",
          "type": "filled"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "white"
        }
      },
      "heroArticle": {
        "carousel": {
          "pagination": "white",
          "navigation": "white"
        }
      },
      "multipleBar": {
        "skewX": {
          "value": "0deg",
          "type": "dimension"
        },
        "top": {
          "borderOne": {
            "height": {
              "value": "15px",
              "type": "dimension"
            },
            "fill": {
              "value": "#00254D",
              "type": "color"
            }
          },
          "borderTwo": {
            "height": {
              "value": "15px",
              "type": "dimension"
            },
            "fill": {
              "value": "#00254D",
              "type": "color"
            }
          },
          "borderThree": {
            "height": {
              "value": "24px",
              "type": "dimension"
            },
            "fill": {
              "value": "#FFFFFF",
              "type": "color"
            }
          },
          "borderFour": {
            "height": {
              "value": "24px",
              "type": "dimension"
            },
            "fill": {
              "value": "#FFFFFF",
              "type": "color"
            }
          }
        },
        "bottom": {
          "borderFive": {
            "height": {
              "value": "24px",
              "type": "dimension"
            },
            "fill": {
              "value": "#2FCFC8",
              "type": "color"
            }
          },
          "borderSix": {
            "height": {
              "value": "24px",
              "type": "dimension"
            },
            "fill": {
              "value": "#2FCFC8",
              "type": "color"
            }
          },
          "borderSeven": {
            "height": {
              "value": "15px",
              "type": "dimension"
            },
            "fill": {
              "value": "#FEC02F",
              "type": "color"
            }
          },
          "borderEight": {
            "height": {
              "value": "15px",
              "type": "dimension"
            },
            "fill": {
              "value": "#FEC02F",
              "type": "color"
            }
          }
        }
      }
    },
    "Pyrel": {
      "bannerDefault": {
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        }
      },
      "genericCardListing": {
        "hasDecor": false
      },
      "heroHomepage": {
        "primaryCta": {
          "color": "brand",
          "type": "filled"
        },
        "secondaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "label": "white",
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "heroInternal": {
        "hasDecor": false
      },
      "productCardListing": {
        "hasDecor": false,
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "articleCardListing": {
        "primaryCta": {
          "color": "tonal",
          "type": "outline"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "pullQuote": {
        "carousel": {
          "pagination": "color",
          "navigation": "white"
        }
      },
      "topBar": {
        "primaryCta": {
          "color": "white"
        }
      },
      "searchListing": {
        "labelColor": "color"
      },
      "productInformation": {
        "label": "white",
        "primaryCta": {
          "color": "brand",
          "type": "filled"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "heroArticle": {
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "multipleBar": {
        "skewX": {
          "value": "0deg",
          "type": "dimension"
        },
        "top": {
          "borderOne": {
            "height": {
              "value": "15px",
              "type": "dimension"
            },
            "fill": {
              "value": "#00254D",
              "type": "color"
            }
          },
          "borderTwo": {
            "height": {
              "value": "15px",
              "type": "dimension"
            },
            "fill": {
              "value": "#00254D",
              "type": "color"
            }
          },
          "borderThree": {
            "height": {
              "value": "24px",
              "type": "dimension"
            },
            "fill": {
              "value": "#FFFFFF",
              "type": "color"
            }
          },
          "borderFour": {
            "height": {
              "value": "24px",
              "type": "dimension"
            },
            "fill": {
              "value": "#FFFFFF",
              "type": "color"
            }
          }
        },
        "bottom": {
          "borderFive": {
            "height": {
              "value": "24px",
              "type": "dimension"
            },
            "fill": {
              "value": "#2FCFC8",
              "type": "color"
            }
          },
          "borderSix": {
            "height": {
              "value": "24px",
              "type": "dimension"
            },
            "fill": {
              "value": "#2FCFC8",
              "type": "color"
            }
          },
          "borderSeven": {
            "height": {
              "value": "15px",
              "type": "dimension"
            },
            "fill": {
              "value": "#FEC02F",
              "type": "color"
            }
          },
          "borderEight": {
            "height": {
              "value": "15px",
              "type": "dimension"
            },
            "fill": {
              "value": "#FEC02F",
              "type": "color"
            }
          }
        }
      }
    },
    "MrMuscle": {
      "bannerDefault": {
        "primaryCta": {
          "color": "tonal",
          "type": "outline"
        }
      },
      "genericCardListing": {
        "hasDecor": false
      },
      "heroHomepage": {
        "primaryCta": {
          "color": "brand",
          "type": "filled"
        },
        "secondaryCta": {
          "color": "white",
          "type": "outline"
        },
        "label": "color",
        "carousel": {
          "pagination": "color",
          "navigation": "white"
        }
      },
      "heroInternal": {
        "hasDecor": false
      },
      "productCardListing": {
        "hasDecor": false,
        "primaryCta": {
          "color": "tonal",
          "type": "outline"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "articleCardListing": {
        "primaryCta": {
          "color": "tonal",
          "type": "outline"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "pullQuote": {
        "carousel": {
          "pagination": "color",
          "navigation": "white"
        }
      },
      "topBar": {
        "primaryCta": {
          "color": "white"
        }
      },
      "searchListing": {
        "labelColor": "white"
      },
      "productInformation": {
        "label": "white",
        "primaryCta": {
          "color": "brand",
          "type": "filled"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "heroArticle": {
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      }
    },
    "Pledge": {
      "bannerDefault": {
        "primaryCta": {
          "color": "tonal",
          "type": "outline"
        }
      },
      "genericCardListing": {
        "hasDecor": false
      },
      "heroHomepage": {
        "primaryCta": {
          "color": "brand",
          "type": "filled"
        },
        "secondaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "label": "white",
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "heroInternal": {
        "hasDecor": false
      },
      "productCardListing": {
        "hasDecor": false,
        "primaryCta": {
          "color": "tonal",
          "type": "outline"
        },
        "carousel": {
          "pagination": "color",
          "navigation": "white"
        }
      },
      "articleCardListing": {
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "pullQuote": {
        "carousel": {
          "pagination": "color",
          "navigation": "white"
        }
      },
      "topBar": {
        "primaryCta": {
          "color": "white"
        }
      },
      "searchListing": {
        "labelColor": "white"
      },
      "productInformation": {
        "label": "white",
        "primaryCta": {
          "color": "brand",
          "type": "filled"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "heroArticle": {
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      }
    },
    "Drano": {
      "bannerDefault": {
        "primaryCta": {
          "color": "tonal",
          "type": "outline"
        }
      },
      "genericCardListing": {
        "hasDecor": false
      },
      "heroHomepage": {
        "primaryCta": {
          "color": "brand",
          "type": "filled"
        },
        "secondaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "label": "white",
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "heroInternal": {
        "hasDecor": false
      },
      "productCardListing": {
        "hasDecor": false,
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "carousel": {
          "pagination": "color",
          "navigation": "brand"
        }
      },
      "articleCardListing": {
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "pullQuote": {
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "topBar": {
        "primaryCta": {
          "color": "tonal"
        }
      },
      "searchListing": {
        "labelColor": "white"
      },
      "productInformation": {
        "label": "white",
        "primaryCta": {
          "color": "brand",
          "type": "filled"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "heroArticle": {
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      }
    },
    "Lysoform": {
      "bannerDefault": {
        "primaryCta": {
          "color": "tonal",
          "type": "outline"
        }
      },
      "genericCardListing": {
        "hasDecor": false
      },
      "heroHomepage": {
        "primaryCta": {
          "color": "white",
          "type": "filled"
        },
        "secondaryCta": {
          "color": "tonal",
          "type": "outline"
        },
        "label": "white",
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "heroInternal": {
        "hasDecor": false
      },
      "productCardListing": {
        "hasDecor": true,
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "articleCardListing": {
        "primaryCta": {
          "color": "white",
          "type": "outline"
        },
        "carousel": {
          "pagination": "color",
          "navigation": "white"
        }
      },
      "pullQuote": {
        "carousel": {
          "pagination": "color",
          "navigation": "white"
        }
      },
      "topBar": {
        "primaryCta": {
          "color": "brand"
        }
      },
      "searchListing": {
        "labelColor": "white"
      },
      "productInformation": {
        "label": "color",
        "primaryCta": {
          "color": "white",
          "type": "filled"
        },
        "carousel": {
          "pagination": "color",
          "navigation": "white"
        }
      },
      "heroArticle": {
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      }
    },
    "Windex": {
      "bannerDefault": {
        "primaryCta": {
          "color": "tonal",
          "type": "outline"
        }
      },
      "genericCardListing": {
        "hasDecor": false
      },
      "heroHomepage": {
        "primaryCta": {
          "color": "white",
          "type": "filled"
        },
        "secondaryCta": {
          "color": "tonal",
          "type": "outline"
        },
        "label": "white",
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "heroInternal": {
        "hasDecor": false
      },
      "productCardListing": {
        "hasDecor": true,
        "primaryCta": {
          "color": "brand",
          "type": "outline"
        },
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      },
      "articleCardListing": {
        "primaryCta": {
          "color": "white",
          "type": "outline"
        },
        "carousel": {
          "pagination": "color",
          "navigation": "white"
        }
      },
      "pullQuote": {
        "carousel": {
          "pagination": "color",
          "navigation": "white"
        }
      },
      "topBar": {
        "primaryCta": {
          "color": "brand"
        }
      },
      "searchListing": {
        "labelColor": "white"
      },
      "productInformation": {
        "label": "color",
        "primaryCta": {
          "color": "white",
          "type": "filled"
        },
        "carousel": {
          "pagination": "color",
          "navigation": "white"
        }
      },
      "heroArticle": {
        "carousel": {
          "pagination": "white",
          "navigation": "brand"
        }
      }
    }
  },
  "global": {
    "colors": {
      "color-system-alert-100": "#ffd8da",
      "color-system-alert-200": "#ff8b8f",
      "color-system-alert-300": "#ff3d45",
      "color-system-alert-400": "#b32b30",
      "color-system-alert-500": "#66181c",
      "color-system-warning-100": "#f6e2d1",
      "color-system-warning-200": "#f4a766",
      "color-system-warning-300": "#d26e1b",
      "color-system-warning-400": "#a64c00",
      "color-system-warning-500": "#5f2b00",
      "color-system-success-100": "#d4ecd6",
      "color-system-success-200": "#7ec584",
      "color-system-success-300": "#289e32",
      "color-system-success-400": "#1c6f23",
      "color-system-success-500": "#103f14",
      "color-system-focus-100": "#d5e3ff",
      "color-system-focus-200": "#80aaff",
      "color-system-focus-300": "#286efa",
      "color-system-focus-400": "#1c4cae",
      "color-system-focus-500": "#112d67",
      "color-system-brands-bg": "#e7e9f0",
      "color-system-brands-bg-hover": "#ffffff",
      "color-grayscale-base-white": "#ffffff",
      "color-grayscale-base-black": "#000000",
      "color-grayscale-warm-100": "#fdfdfc",
      "color-grayscale-warm-200": "#eae8e8",
      "color-grayscale-warm-300": "#aba8a6",
      "color-grayscale-warm-400": "#807a77",
      "color-grayscale-warm-500": "#5b5654",
      "color-grayscale-warm-600": "#373432",
      "color-grayscale-warm-700": "#121111",
      "color-grayscale-cool-100": "#fcfcfd",
      "color-grayscale-cool-200": "#e8e8ea",
      "color-grayscale-cool-300": "#a2a5ab",
      "color-grayscale-cool-400": "#72757e",
      "color-grayscale-cool-500": "#4e5056",
      "color-grayscale-cool-600": "#27282b",
      "color-grayscale-cool-700": "#191b1c",
      "forms-checkbox-color-stroke-hover": "#1c4cae",
      "forms-checkbox-color-stroke-selected": "#1c4cae",
      "forms-checkbox-color-checkbox-default": "#ffffff",
      "forms-checkbox-color-checkbox-hover": "#ffffff",
      "forms-checkbox-color-checkbox-selected": "#1c4cae",
      "forms-checkbox-color-checkmark-hover": "#1c4cae",
      "forms-checkbox-color-checkmark-selected": "#ffffff",
      "forms-radio-color-stroke-hover": "#1c4cae",
      "forms-radio-color-stroke-selected": "#1c4cae",
      "forms-radio-color-checkbox-default": "#ffffff",
      "forms-radio-color-checkbox-hover": "#ffffff",
      "forms-radio-color-checkbox-selected": "#1c4cae",
      "forms-radio-color-checkmark-hover": "#1c4cae",
      "forms-radio-color-checkmark-selected": "#ffffff",
      "forms-toggle-color-track-selected": "#1c4cae"
    },
    "spacing": {
      "spacing-spacing-0": "0rem",
      "spacing-spacing-025": "0.0625rem",
      "spacing-spacing-05": "0.125rem",
      "spacing-spacing-1": "0.25rem",
      "spacing-spacing-2": "0.5rem",
      "spacing-spacing-3": "0.75rem",
      "spacing-spacing-4": "1rem",
      "spacing-spacing-5": "1.5rem",
      "spacing-spacing-6": "2rem",
      "spacing-spacing-7": "2.5rem",
      "spacing-spacing-8": "3rem",
      "spacing-spacing-9": "4rem",
      "spacing-spacing-10": "5rem",
      "spacing-spacing-11": "7.5rem",
      "spacing-spacing-12": "10rem",
      "forms-checkbox-spacing-space-between": "1rem",
      "forms-radio-spacing-space-between": "1rem",
      "forms-text-field-spacing-input-min-height": "3.5rem",
      "forms-toggle-spacing-space-between": "1rem"
    },
    "string": {},
    "borderRadius": {
      "radius-radius-0": "0rem",
      "radius-radius-05": "0.125rem",
      "radius-radius-1": "0.25rem",
      "radius-radius-2": "0.5rem",
      "radius-radius-3": "0.75rem",
      "radius-radius-4": "1rem",
      "radius-radius-5": "1.5rem",
      "radius-radius-6": "2.5rem",
      "radius-radius-7": "4rem",
      "radius-radius-8": "7.5rem",
      "forms-checkbox-spacing-radius": "0.125rem",
      "forms-radio-spacing-radius": "0.125rem",
      "forms-toggle-spacing-radius": "0.125rem"
    },
    "borderWidth": {
      "3": "3px"
    }
  },
  "gradients": {
    "gradient-1": "linear-gradient(259deg, #0241cd 0%, #f719b8 31.78%, #00ccff 66.53%, #48c249 100%)",
    "gradient-2": "linear-gradient(90deg, #0241cd 6.14%, #00ccff 49.42%, #8a35b2 64.24%, #f719b8 79.23%, #ff8528 100%)",
    "gradient-3": "linear-gradient(180deg, #d2ff1a 0%, #d2ff1a 100%)",
    "gradient-4": "linear-gradient(180deg, #D2BFFD, #D2BFFD)",
    "gradient-5": "linear-gradient(90deg, #FFFFFF 29.31%, #FFFFFF80 100%)",
    "gradient-6": "linear-gradient(90deg, #FFFFFF80 0%, #FFFFFF 70.84%)",
    "gradient-7": "linear-gradient(90deg, #191b1c 29.31%, #191b1c80 100%)",
    "gradient-8": "linear-gradient(90deg, #191b1c80 0%, #191b1c 70.84%)",
    "gradient-9": "conic-gradient(#D2FF1A 0deg, #D2FF1A00 360deg);",
    "gradient-10": "conic-gradient(#0241CD 0deg, #0241CD00 360deg);",
    "gradient-11": "linear-gradient(180deg, #72757E, #72757E)",
    "gradient-12": "linear-gradient(180deg, #FFFFFF, #FFFFFF)",
    "gradient-13": "conic-gradient(#FE5000 0deg, #FE500000 360deg);",
    "gradient-14": "conic-gradient(#D7252D 0deg, #D7252D00 360deg);",
    "gradient-15": "linear-gradient(#191B1C , #191B1C);",
    "gradient-16": "conic-gradient(#191B1C 0deg, #191B1C00 360deg);",
    "gradient-17": "linear-gradient(90deg, #F2F2F2 29.31%, #F2F2F2 100%)",
    "gradient-18": "linear-gradient(90deg, #F2F2F2 29.31%, #F2F2F2 100%)",
    "gradient-19": "linear-gradient(90deg, #EBF1F9 29.31%, #EBF1F980 100%)",
    "gradient-20": "linear-gradient(90deg, #EBF1F980 29.31%, #EBF1F9 100%)",
    "gradient-21": "linear-gradient(180deg, #00254D, #00254D)",
    "gradient-22": "conic-gradient(#00254D 0deg, #00254D00 360deg);",
    "gradient-23": "linear-gradient(90deg, #E8E8EA 29.31%, #E8E8EA80 100%)",
    "gradient-24": "linear-gradient(90deg, #E8E8EA80 0%, #E8E8EA 70.84%)",
    "gradient-25": "linear-gradient(#F0F2F7 , #F0F2F7);",
    "gradient-26": "linear-gradient(259deg, #E8E8EA 0%, #A2A5AB 31.78%, #FFFFFF 66.53%, #A2A5AB 100%)",
    "gradient-27": "linear-gradient(259deg, #E8E8EA 0%, #72757E 31.78%, #E8E8EA 66.53%, #72757E 100%)",
    "gradient-28": "conic-gradient(#213117 0deg, #21311700 360deg);",
    "gradient-29": "conic-gradient(#003DA5 0deg, #003DA500 360deg);",
    "gradient-30": "conic-gradient(#001871 0deg, #00187100 360deg);",
    "gradient-31": "linear-gradient(90deg, #EAE9F2 29.31%, #EAE9F280 100%)",
    "gradient-32": "linear-gradient(90deg, #EAE9F280 0%, #EAE9F2 70.84%)",
    "gradient-33": "conic-gradient(#2A2569 0deg, #2A256900 360deg);",
    "gradient-34": "linear-gradient(#2A2569 , #2A2569);",
    "gradient-35": "linear-gradient(#1E395E , #1E395E);",
    "gradient-36": "conic-gradient(#1E395E 0deg, #1E395E00 360deg);",
    "gradient-37": "linear-gradient(180deg, #191B1C, #191B1C)",
    "gradient-38": "linear-gradient(180deg, #E8E8EA, #E8E8EA)",
    "gradient-39": "linear-gradient(180deg, #0A3E6B, #0A3E6B)",
    "gradient-40": "conic-gradient(#0A3E6B 0deg, #0A3E6B00 360deg);",
    "gradient-41": "conic-gradient(#D92C29 0deg, #D92C2900 360deg);",
    "gradient-42": "linear-gradient(180deg, #D92C29, #D92C29)",
    "gradient-43": "linear-gradient(180deg, #000000, #000000)",
    "DART": {
      "components-label-color-oncolor-stroke-stroke-default": "linear-gradient(180deg, #D2BFFD, #D2BFFD)",
      "components-label-color-onwhite-stroke-stroke-default": "linear-gradient(180deg, #d2ff1a 0%, #d2ff1a 100%)",
      "components-lightbox-color-overlay-left-fill-default": "linear-gradient(90deg, #191b1c 29.31%, #191b1c80 100%)",
      "components-lightbox-color-overlay-right-fill-default": "linear-gradient(90deg, #191b1c80 0%, #191b1c 70.84%)",
      "components-omnibox-color-loader-icon-fill-default": "conic-gradient(#D2FF1A 0deg, #D2FF1A00 360deg);",
      "components-button-group-color-button-stroke-active": "#F8FFDB"
    },
    "Ziploc": {
      "components-label-color-oncolor-stroke-stroke-default": "linear-gradient(259deg, #0241cd 0%, #f719b8 31.78%, #00ccff 66.53%, #48c249 100%)",
      "components-label-color-onwhite-stroke-stroke-default": "linear-gradient(259deg, #0241cd 0%, #f719b8 31.78%, #00ccff 66.53%, #48c249 100%)",
      "components-lightbox-color-overlay-left-fill-default": "linear-gradient(90deg, #FFFFFF 29.31%, #FFFFFF80 100%)",
      "components-lightbox-color-overlay-right-fill-default": "linear-gradient(90deg, #FFFFFF80 0%, #FFFFFF 70.84%)",
      "components-omnibox-color-loader-icon-fill-default": "conic-gradient(#0241CD 0deg, #0241CD00 360deg);",
      "components-button-group-color-button-stroke-active": "linear-gradient(259deg, #0241cd 0%, #f719b8 31.78%, #00ccff 66.53%, #48c249 100%)"
    },
    "Off": {
      "components-label-color-oncolor-stroke-stroke-default": "linear-gradient(180deg, #FFFFFF, #FFFFFF)",
      "components-label-color-onwhite-stroke-stroke-default": "linear-gradient(180deg, #72757E, #72757E)",
      "components-lightbox-color-overlay-left-fill-default": "linear-gradient(90deg, #FFFFFF 29.31%, #FFFFFF80 100%)",
      "components-lightbox-color-overlay-right-fill-default": "linear-gradient(90deg, #FFFFFF80 0%, #FFFFFF 70.84%)",
      "components-omnibox-color-loader-icon-fill-default": "conic-gradient(#FE5000 0deg, #FE500000 360deg);",
      "components-button-group-color-button-stroke-active": "linear-gradient(#191B1C , #191B1C);"
    },
    "Autan": {
      "components-label-color-oncolor-stroke-stroke-default": "linear-gradient(180deg, #FFFFFF, #FFFFFF)",
      "components-label-color-onwhite-stroke-stroke-default": "linear-gradient(180deg, #72757E, #72757E)",
      "components-lightbox-color-overlay-left-fill-default": "linear-gradient(90deg, #FFFFFF 29.31%, #FFFFFF80 100%)",
      "components-lightbox-color-overlay-right-fill-default": "linear-gradient(90deg, #FFFFFF80 0%, #FFFFFF 70.84%)",
      "components-omnibox-color-loader-icon-fill-default": "conic-gradient(#D7252D 0deg, #D7252D00 360deg);",
      "components-button-group-color-button-stroke-active": "linear-gradient(#191B1C , #191B1C);"
    },
    "AutanDefense": {
      "components-label-color-oncolor-stroke-stroke-default": "linear-gradient(180deg, #FFFFFF, #FFFFFF)",
      "components-label-color-onwhite-stroke-stroke-default": "linear-gradient(180deg, #72757E, #72757E)",
      "components-lightbox-color-overlay-left-fill-default": "linear-gradient(90deg, #FFFFFF 29.31%, #FFFFFF80 100%)",
      "components-lightbox-color-overlay-right-fill-default": "linear-gradient(90deg, #FFFFFF80 0%, #FFFFFF 70.84%)",
      "components-omnibox-color-loader-icon-fill-default": "conic-gradient(#D7252D 0deg, #D7252D00 360deg);",
      "components-button-group-color-button-stroke-active": "linear-gradient(#191B1C , #191B1C);"
    },
    "OffDefense": {
      "components-label-color-oncolor-stroke-stroke-default": "linear-gradient(180deg, #FFFFFF, #FFFFFF)",
      "components-label-color-onwhite-stroke-stroke-default": "linear-gradient(180deg, #72757E, #72757E)",
      "components-lightbox-color-overlay-left-fill-default": "linear-gradient(90deg, #FFFFFF 29.31%, #FFFFFF80 100%)",
      "components-lightbox-color-overlay-right-fill-default": "linear-gradient(90deg, #FFFFFF80 0%, #FFFFFF 70.84%)",
      "components-omnibox-color-loader-icon-fill-default": "conic-gradient(#D7252D 0deg, #D7252D00 360deg);",
      "components-button-group-color-button-stroke-active": "linear-gradient(#191B1C , #191B1C);"
    },
    "Corporate": {
      "components-label-color-oncolor-stroke-stroke-default": "linear-gradient(180deg, #FFFFFF, #FFFFFF)",
      "components-label-color-onwhite-stroke-stroke-default": "linear-gradient(180deg, #72757E, #72757E)",
      "components-lightbox-color-overlay-left-fill-default": "linear-gradient(90deg, #FFFFFF 29.31%, #FFFFFF80 100%)",
      "components-lightbox-color-overlay-right-fill-default": "linear-gradient(90deg, #FFFFFF80 0%, #FFFFFF 70.84%)",
      "components-omnibox-color-loader-icon-fill-default": "conic-gradient(#D7252D 0deg, #D7252D00 360deg);",
      "components-button-group-color-button-stroke-active": "linear-gradient(#F0F2F7 , #F0F2F7);"
    },
    "Glade": {
      "components-label-color-oncolor-stroke-stroke-default": "linear-gradient(180deg, #FFFFFF, #FFFFFF)",
      "components-label-color-onwhite-stroke-stroke-default": "linear-gradient(180deg, #00254D, #00254D)",
      "components-lightbox-color-overlay-left-fill-default": "linear-gradient(90deg, #EBF1F9 29.31%, #EBF1F980 100%)",
      "components-lightbox-color-overlay-right-fill-default": "linear-gradient(90deg, #EBF1F980 29.31%, #EBF1F9 100%)",
      "components-omnibox-color-loader-icon-fill-default": "conic-gradient(#00254D 0deg, #00254D00 360deg);",
      "components-button-group-color-button-stroke-active": "linear-gradient(#191B1C , #191B1C);"
    },
    "Raid": {
      "components-label-color-oncolor-stroke-stroke-default": "linear-gradient(180deg, #FFFFFF, #FFFFFF)",
      "components-label-color-onwhite-stroke-stroke-default": "linear-gradient(#191B1C , #191B1C);",
      "components-lightbox-color-overlay-left-fill-default": "linear-gradient(90deg, #E8E8EA 29.31%, #E8E8EA80 100%)",
      "components-lightbox-color-overlay-right-fill-default": "linear-gradient(90deg, #E8E8EA80 0%, #E8E8EA 70.84%)",
      "components-omnibox-color-loader-icon-fill-default": "conic-gradient(#191B1C 0deg, #191B1C00 360deg);",
      "components-button-group-color-button-stroke-active": "linear-gradient(#191B1C , #191B1C);"
    },
    "Baygon": {
      "components-label-color-oncolor-stroke-stroke-default": "linear-gradient(180deg, #FFFFFF00, #FFFFFF00)",
      "components-label-color-onwhite-stroke-stroke-default": "linear-gradient(180deg, #FFFFFF00, #FFFFFF00)",
      "components-lightbox-color-overlay-left-fill-default": "linear-gradient(90deg, #FFFFFF 29.31%, #FFFFFF80 100%)",
      "components-lightbox-color-overlay-right-fill-default": "linear-gradient(90deg, #FFFFFF80 0%, #FFFFFF 70.84%)",
      "components-omnibox-color-loader-icon-fill-default": "conic-gradient(#191B1C 0deg, #191B1C00 360deg);",
      "components-button-group-color-button-stroke-active": "linear-gradient(#191B1C , #191B1C);"
    },
    "Pyrel": {
      "components-label-color-oncolor-stroke-stroke-default": "linear-gradient(#EBF3E6 , #EBF3E6)",
      "components-label-color-onwhite-stroke-stroke-default": "linear-gradient(#213117 , #213117)",
      "components-lightbox-color-overlay-left-fill-default": "linear-gradient(90deg, #FFFFFF 29.31%, #FFFFFF80 100%)",
      "components-lightbox-color-overlay-right-fill-default": "linear-gradient(90deg, #FFFFFF80 0%, #FFFFFF 70.84%)",
      "components-omnibox-color-loader-icon-fill-default": "conic-gradient(#213117 0deg, #21311700 360deg);",
      "components-button-group-color-button-stroke-active": "linear-gradient(#213117 , #213117)"
    },
    "Duck": {
      "components-label-color-oncolor-stroke-stroke-default": "linear-gradient(180deg, #FFFFFF, #FFFFFF)",
      "components-label-color-onwhite-stroke-stroke-default": "linear-gradient(180deg, #002F80, #002F80)",
      "components-lightbox-color-overlay-left-fill-default": "linear-gradient(90deg, #FFFFFF 29.31%, #FFFFFF80 100%)",
      "components-lightbox-color-overlay-right-fill-default": "linear-gradient(90deg, #FFFFFF80 0%, #FFFFFF 70.84%)",
      "components-omnibox-color-loader-icon-fill-default": "conic-gradient(#003DA5 0deg, #003DA500 360deg);",
      "components-button-group-color-button-stroke-active": "linear-gradient(180deg, #C32519, #C32519)"
    },
    "MrMuscle": {
      "components-label-color-oncolor-stroke-stroke-default": "linear-gradient(259deg, #E8E8EA 0%, #A2A5AB 31.78%, #FFFFFF 66.53%, #A2A5AB 100%)",
      "components-label-color-onwhite-stroke-stroke-default": "linear-gradient(259deg, #E8E8EA 0%, #72757E 31.78%, #E8E8EA 66.53%, #72757E 100%)",
      "components-lightbox-color-overlay-left-fill-default": "linear-gradient(90deg, #FFFFFF 29.31%, #FFFFFF80 100%)",
      "components-lightbox-color-overlay-right-fill-default": "linear-gradient(90deg, #FFFFFF80 0%, #FFFFFF 70.84%)",
      "components-omnibox-color-loader-icon-fill-default": "conic-gradient(#001871 0deg, #00187100 360deg);",
      "components-button-group-color-button-stroke-active": "linear-gradient(259deg, #E8E8EA 0%, #72757E 31.78%, #E8E8EA 66.53%, #72757E 100%)"
    },
    "Pledge": {
      "components-label-color-oncolor-stroke-stroke-default": "linear-gradient(180deg, #FFFFFF, #FFFFFF)",
      "components-label-color-onwhite-stroke-stroke-default": "linear-gradient(#1E395E , #1E395E);",
      "components-lightbox-color-overlay-left-fill-default": "linear-gradient(90deg, #FFFFFF 29.31%, #FFFFFF80 100%)",
      "components-lightbox-color-overlay-right-fill-default": "linear-gradient(90deg, #FFFFFF80 0%, #FFFFFF 70.84%)",
      "components-omnibox-color-loader-icon-fill-default": "conic-gradient(#1E395E 0deg, #1E395E00 360deg);",
      "components-button-group-color-button-stroke-active": "linear-gradient(#1E395E , #1E395E);"
    },
    "Lysoform": {
      "components-label-color-oncolor-stroke-stroke-default": "linear-gradient(180deg, #FFFFFF, #FFFFFF)",
      "components-label-color-onwhite-stroke-stroke-default": "linear-gradient(#2A2569 , #2A2569);",
      "components-lightbox-color-overlay-left-fill-default": "linear-gradient(90deg, #EAE9F2 29.31%, #EAE9F280 100%)",
      "components-lightbox-color-overlay-right-fill-default": "linear-gradient(90deg, #EAE9F280 0%, #EAE9F2 70.84%)",
      "components-omnibox-color-loader-icon-fill-default": "conic-gradient(#2A2569 0deg, #2A256900 360deg);",
      "components-button-group-color-button-stroke-active": "linear-gradient(#2A2569 , #2A2569);"
    },
    "Drano": {
      "components-label-color-oncolor-stroke-stroke-default": "linear-gradient(180deg, #FFFFFF, #FFFFFF)",
      "components-label-color-onwhite-stroke-stroke-default": "linear-gradient(180deg, #191B1C, #191B1C)",
      "components-lightbox-color-overlay-left-fill-default": "linear-gradient(90deg, #FFFFFF 29.31%, #FFFFFF80 100%)",
      "components-lightbox-color-overlay-right-fill-default": "linear-gradient(90deg, #FFFFFF80 0%, #FFFFFF 70.84%)",
      "components-omnibox-color-loader-icon-fill-default": "conic-gradient(#003DA5 0deg, #003DA500 360deg);",
      "components-button-group-color-button-stroke-active": "linear-gradient(180deg, #D92C29, #D92C29)"
    },
    "Windex": {
      "components-label-color-oncolor-stroke-stroke-default": "linear-gradient(180deg, #E8E8EA, #E8E8EA)",
      "components-label-color-onwhite-stroke-stroke-default": "linear-gradient(180deg, #0A3E6B, #0A3E6B)",
      "components-lightbox-color-overlay-left-fill-default": "linear-gradient(90deg, #FFFFFF 29.31%, #FFFFFF80 100%)",
      "components-lightbox-color-overlay-right-fill-default": "linear-gradient(90deg, #FFFFFF80 0%, #FFFFFF 70.84%)",
      "components-omnibox-color-loader-icon-fill-default": "conic-gradient(#0A3E6B 0deg, #0A3E6B00 360deg);",
      "components-button-group-color-button-stroke-active": "linear-gradient(180deg, #0A3E6B, #0A3E6B)"
    },
    "PIDS": {
      "components-label-color-oncolor-stroke-stroke-default": "linear-gradient(180deg, #FFFFFF, #FFFFFF)",
      "components-label-color-onwhite-stroke-stroke-default": "linear-gradient(180deg, #191B1C, #191B1C)",
      "components-lightbox-color-overlay-left-fill-default": "linear-gradient(90deg, #FFFFFF 29.31%, #FFFFFF80 100%)",
      "components-lightbox-color-overlay-right-fill-default": "linear-gradient(90deg, #FFFFFF80 0%, #FFFFFF 70.84%)",
      "components-omnibox-color-loader-icon-fill-default": "conic-gradient(#D92C29 0deg, #D92C2900 360deg);",
      "components-button-group-color-button-stroke-active": "linear-gradient(180deg, #000000, #000000)"
    }
  },
  "typography": {
    "capsize": {
      "global": {
        "bodySans-xLarge": {
          "capHeight": 370,
          "ascent": 600,
          "descent": 290,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "bodySans-xLarge-underline": {
          "capHeight": 370,
          "ascent": 600,
          "descent": 290,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "bodySans-large": {
          "capHeight": 370,
          "ascent": 600,
          "descent": 290,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "bodySans-large-semibold": {
          "capHeight": 410,
          "ascent": 660,
          "descent": 300,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "bodySans-large-underline": {
          "capHeight": 370,
          "ascent": 600,
          "descent": 290,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "bodySans-large-underline-semibold": {
          "capHeight": 410,
          "ascent": 660,
          "descent": 300,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "bodySans-medium": {
          "capHeight": 370,
          "ascent": 600,
          "descent": 290,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "bodySans-medium-semibold": {
          "capHeight": 410,
          "ascent": 660,
          "descent": 300,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "bodySans-medium-underline": {
          "capHeight": 370,
          "ascent": 600,
          "descent": 290,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "bodySans-medium-underline-semibold": {
          "capHeight": 410,
          "ascent": 660,
          "descent": 300,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "bodySans-small": {
          "capHeight": 370,
          "ascent": 600,
          "descent": 290,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "bodySans-small-semibold": {
          "capHeight": 410,
          "ascent": 660,
          "descent": 300,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "bodySans-small-underline": {
          "capHeight": 370,
          "ascent": 600,
          "descent": 290,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "bodySans-small-underline-semibold": {
          "capHeight": 410,
          "ascent": 660,
          "descent": 300,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "bodySans-xSmall": {
          "capHeight": 370,
          "ascent": 600,
          "descent": 290,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "bodySans-xSmall-semibold": {
          "capHeight": 410,
          "ascent": 660,
          "descent": 300,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "bodySans-xSmall-underline": {
          "capHeight": 370,
          "ascent": 600,
          "descent": 290,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "bodySans-xSmall-underline-semibold": {
          "capHeight": 410,
          "ascent": 660,
          "descent": 300,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "bodySerif-xLarge": {
          "capHeight": 308,
          "ascent": 486,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "bodySerif-xLarge-underline": {
          "capHeight": 308,
          "ascent": 486,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "bodySerif-large": {
          "capHeight": 308,
          "ascent": 486,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "bodySerif-large-underline": {
          "capHeight": 308,
          "ascent": 486,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "bodySerif-medium": {
          "capHeight": 308,
          "ascent": 486,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "bodySerif-medium-underline": {
          "capHeight": 308,
          "ascent": 486,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "bodySerif-small": {
          "capHeight": 308,
          "ascent": 486,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "bodySerif-small-underline": {
          "capHeight": 308,
          "ascent": 486,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "bodySerif-xSmall": {
          "capHeight": 308,
          "ascent": 486,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "bodySerif-xSmall-underline": {
          "capHeight": 308,
          "ascent": 486,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        }
      },
      "DART": {
        "header-large-xxLarge": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xLarge": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-large": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-medium": {
          "capHeight": 534,
          "ascent": 710,
          "descent": 215,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-small": {
          "capHeight": 534,
          "ascent": 710,
          "descent": 215,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall": {
          "capHeight": 534,
          "ascent": 710,
          "descent": 215,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall-underline": {
          "capHeight": 534,
          "ascent": 710,
          "descent": 215,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xxSmall": {
          "capHeight": 534,
          "ascent": 710,
          "descent": 215,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xxLarge": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xLarge": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-large": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-medium": {
          "capHeight": 534,
          "ascent": 710,
          "descent": 215,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-small": {
          "capHeight": 534,
          "ascent": 710,
          "descent": 215,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xSmall": {
          "capHeight": 534,
          "ascent": 710,
          "descent": 215,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xSmall-underline": {
          "capHeight": 534,
          "ascent": 710,
          "descent": 215,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xxSmall": {
          "capHeight": 534,
          "ascent": 710,
          "descent": 215,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xxLarge": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xLarge": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-large": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-medium": {
          "capHeight": 534,
          "ascent": 710,
          "descent": 215,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-small": {
          "capHeight": 534,
          "ascent": 710,
          "descent": 215,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xSmall": {
          "capHeight": 534,
          "ascent": 710,
          "descent": 215,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xSmall-underline": {
          "capHeight": 534,
          "ascent": 710,
          "descent": 215,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xxSmall": {
          "capHeight": 534,
          "ascent": 710,
          "descent": 215,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h1": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h2": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h1": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h2": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h1": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h2": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        }
      },
      "Ziploc": {
        "header-large-xxLarge": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xLarge": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-large": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-medium": {
          "capHeight": 534,
          "ascent": 710,
          "descent": 215,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-small": {
          "capHeight": 534,
          "ascent": 710,
          "descent": 215,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall": {
          "capHeight": 534,
          "ascent": 710,
          "descent": 215,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall-underline": {
          "capHeight": 534,
          "ascent": 710,
          "descent": 215,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xxSmall": {
          "capHeight": 534,
          "ascent": 710,
          "descent": 215,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xxLarge": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xLarge": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-large": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-medium": {
          "capHeight": 534,
          "ascent": 710,
          "descent": 215,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-small": {
          "capHeight": 534,
          "ascent": 710,
          "descent": 215,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xSmall": {
          "capHeight": 534,
          "ascent": 710,
          "descent": 215,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xSmall-underline": {
          "capHeight": 534,
          "ascent": 710,
          "descent": 215,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xxSmall": {
          "capHeight": 534,
          "ascent": 710,
          "descent": 215,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xxLarge": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xLarge": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-large": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-medium": {
          "capHeight": 534,
          "ascent": 710,
          "descent": 215,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-small": {
          "capHeight": 534,
          "ascent": 710,
          "descent": 215,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xSmall": {
          "capHeight": 534,
          "ascent": 710,
          "descent": 215,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xSmall-underline": {
          "capHeight": 534,
          "ascent": 710,
          "descent": 215,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xxSmall": {
          "capHeight": 534,
          "ascent": 710,
          "descent": 215,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h1": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h2": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h3": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h4": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h5": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h6": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h1": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h2": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h3": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h4": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h5": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h6": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h1": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h2": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h3": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h4": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h5": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h6": {
          "capHeight": 535,
          "ascent": 730,
          "descent": 230,
          "lineGap": 0,
          "unitsPerEm": 1000
        }
      },
      "Off": {
        "header-large-xxLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-large": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-medium": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-small": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall-underline": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xxSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xxLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-large": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-medium": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-small": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xSmall": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xSmall-underline": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xxSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xxLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-large": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-medium": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-small": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xSmall-underline": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xxSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h1": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h2": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h3": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h4": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h5": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h6": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h1": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h2": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h3": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h4": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h5": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h6": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h1": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h2": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h3": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h4": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h5": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h6": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        }
      },
      "Autan": {
        "header-large-xxLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-large": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-medium": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-small": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall-underline": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xxSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xxLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-large": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-medium": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-small": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xSmall": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xSmall-underline": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xxSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xxLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-large": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-medium": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-small": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xSmall-underline": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xxSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h1": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h2": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h3": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h4": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h5": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h6": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h1": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h2": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h3": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h4": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h5": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h6": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h1": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h2": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h3": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h4": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h5": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h6": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        }
      },
      "Corporate": {
        "header-large-xxLarge": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xLarge": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-large": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-medium": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-small": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall-underline": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xxSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h1": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h2": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h3": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h4": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h5": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h6": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h1": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h2": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h3": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h4": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h5": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h6": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h1": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h2": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h3": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h4": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h5": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h6": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        }
      },
      "AutanDefense": {
        "header-large-xxLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-large": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-medium": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-small": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall-underline": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xxSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xxLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-large": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-medium": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-small": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xSmall": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xSmall-underline": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xxSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xxLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-large": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-medium": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-small": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xSmall-underline": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xxSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h1": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h2": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h3": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h4": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h5": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h6": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h1": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h2": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h3": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h4": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h5": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h6": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h1": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h2": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h3": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h4": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h5": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h6": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        }
      },
      "OffDefense": {
        "header-large-xxLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-large": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-medium": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-small": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall-underline": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xxSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xxLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-large": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-medium": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-small": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xSmall": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xSmall-underline": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xxSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xxLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-large": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-medium": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-small": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xSmall-underline": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xxSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h1": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h2": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h3": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h4": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h5": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h6": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h1": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h2": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h3": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h4": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h5": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h6": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h1": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h2": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h3": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h4": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h5": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h6": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        }
      },
      "Glade": {
        "header-large-xxLarge": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xLarge": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-large": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-medium": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-small": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall-underline": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xxSmall": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xxLarge": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xLarge": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-large": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-medium": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-small": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xSmall": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xSmall-underline": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xxSmall": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xxLarge": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xLarge": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-large": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-medium": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-small": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xSmall": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xSmall-underline": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xxSmall": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h1": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h2": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h3": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h4": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h5": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h6": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h1": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h2": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h3": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h4": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h5": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h6": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h1": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h2": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h3": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h4": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h5": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h6": {
          "capHeight": 600,
          "ascent": 610,
          "descent": 210,
          "lineGap": 0,
          "unitsPerEm": 1000
        }
      },
      "Raid": {
        "header-large-xxLarge": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xLarge": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-large": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-medium": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-small": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall-underline": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xxSmall": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xxLarge": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xLarge": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-large": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-medium": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-small": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xSmall": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xSmall-underline": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xxSmall": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xxLarge": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xLarge": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-large": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-medium": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-small": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xSmall": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xSmall-underline": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xxSmall": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h1": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h2": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h3": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h4": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h5": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h6": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h1": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h2": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h3": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h4": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h5": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h6": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h1": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h2": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h3": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h4": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h5": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h6": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        }
      },
      "Baygon": {
        "header-large-xxLarge": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xLarge": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-large": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-medium": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-small": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall-underline": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xxSmall": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xxLarge": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xLarge": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-large": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-medium": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-small": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xSmall": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xSmall-underline": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xxSmall": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xxLarge": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xLarge": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-large": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-medium": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-small": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xSmall": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xSmall-underline": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xxSmall": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        }
      },
      "Duck": {
        "header-large-xxLarge": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xLarge": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-large": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-medium": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-small": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall-underline": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xxSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h1": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h2": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h3": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h4": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h5": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h6": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h1": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h2": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h3": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h4": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h5": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h6": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h1": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h2": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h3": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h4": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h5": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h6": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        }
      },
      "Pyrel": {
        "header-large-xxLarge": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xLarge": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-large": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-medium": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-small": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall-underline": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xxSmall": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xxLarge": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xLarge": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-large": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-medium": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-small": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xSmall": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xSmall-underline": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xxSmall": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xxLarge": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xLarge": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-large": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-medium": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-small": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xSmall": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xSmall-underline": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xxSmall": {
          "capHeight": 700,
          "ascent": 580,
          "descent": 180,
          "lineGap": 0,
          "unitsPerEm": 1000
        }
      },
      "MrMuscle": {
        "header-large-xxLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-large": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-medium": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-small": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall-underline": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xxSmall": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xxLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-large": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-medium": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-small": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xSmall": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xSmall-underline": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xxSmall": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xxLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-large": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-medium": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-small": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xSmall": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xSmall-underline": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xxSmall": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h1": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h2": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h3": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h4": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h5": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h6": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h1": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h2": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h3": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h4": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h5": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h6": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h1": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h2": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h3": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h4": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h5": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h6": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        }
      },
      "Pledge": {
        "header-large-xxLarge": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xLarge": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-large": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-medium": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-small": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall-underline": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xxSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h1": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h2": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h3": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h4": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h5": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h6": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h1": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h2": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h3": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h4": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h5": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h6": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h1": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h2": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h3": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h4": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h5": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h6": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        }
      },
      "Drano": {
        "header-large-xxLarge": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xLarge": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-large": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-medium": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-small": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall-underline": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xxSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xxLarge": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xLarge": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-large": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-medium": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-small": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xSmall-underline": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xxSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xxLarge": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xLarge": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-large": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-medium": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-small": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xSmall-underline": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xxSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h1": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h2": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h3": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h4": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h5": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h6": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h1": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h2": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h3": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h4": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h5": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h6": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h1": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h2": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h3": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h4": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h5": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h6": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        }
      },
      "Lysoform": {
        "header-large-xxLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-large": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-medium": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-small": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall-underline": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xxSmall": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xxLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-large": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-medium": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-small": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xSmall": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xSmall-underline": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-medium-xxSmall": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xxLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xLarge": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-large": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-medium": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-small": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xSmall": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xSmall-underline": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-small-xxSmall": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h1": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h2": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h3": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h4": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h5": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h6": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h1": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h2": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h3": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h4": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h5": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h6": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h1": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h2": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h3": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h4": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h5": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h6": {
          "capHeight": 628,
          "ascent": 606,
          "descent": 100,
          "lineGap": 0,
          "unitsPerEm": 1000
        }
      },
      "PIDS": {
        "header-large-xxLarge": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xLarge": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-large": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-medium": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-small": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xSmall-underline": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "header-large-xxSmall": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h1": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h2": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h3": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h4": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h5": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-large-h6": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h1": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h2": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h3": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h4": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h5": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-medium-h6": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h1": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h2": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h3": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h4": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h5": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        },
        "rte-small-h6": {
          "capHeight": 590,
          "ascent": 680,
          "descent": 220,
          "lineGap": 0,
          "unitsPerEm": 1000
        }
      }
    },
    "global": {
      "fontSize": {
        "bodySans-xLarge": "20px",
        "bodySans-xLarge-underline": "20px",
        "bodySans-large": "18px",
        "bodySans-large-semibold": "18px",
        "bodySans-large-underline": "18px",
        "bodySans-large-underline-semibold": "18px",
        "bodySans-medium": "16px",
        "bodySans-medium-semibold": "16px",
        "bodySans-medium-underline": "16px",
        "bodySans-medium-underline-semibold": "16px",
        "bodySans-small": "14px",
        "bodySans-small-semibold": "14px",
        "bodySans-small-underline": "14px",
        "bodySans-small-underline-semibold": "14px",
        "bodySans-xSmall": "12px",
        "bodySans-xSmall-semibold": "12px",
        "bodySans-xSmall-underline": "12px",
        "bodySans-xSmall-underline-semibold": "12px",
        "bodySerif-xLarge": "20px",
        "bodySerif-xLarge-underline": "20px",
        "bodySerif-large": "18px",
        "bodySerif-large-underline": "18px",
        "bodySerif-medium": "16px",
        "bodySerif-medium-underline": "16px",
        "bodySerif-small": "14px",
        "bodySerif-small-underline": "14px",
        "bodySerif-xSmall": "12px",
        "bodySerif-xSmall-underline": "12px"
      },
      "fontFamily": {
        "bodySans-xLarge": "Noto Sans",
        "bodySans-xLarge-underline": "Noto Sans",
        "bodySans-large": "Noto Sans",
        "bodySans-large-semibold": "Noto Sans",
        "bodySans-large-underline": "Noto Sans",
        "bodySans-large-underline-semibold": "Noto Sans",
        "bodySans-medium": "Noto Sans",
        "bodySans-medium-semibold": "Noto Sans",
        "bodySans-medium-underline": "Noto Sans",
        "bodySans-medium-underline-semibold": "Noto Sans",
        "bodySans-small": "Noto Sans",
        "bodySans-small-semibold": "Noto Sans",
        "bodySans-small-underline": "Noto Sans",
        "bodySans-small-underline-semibold": "Noto Sans",
        "bodySans-xSmall": "Noto Sans",
        "bodySans-xSmall-semibold": "Noto Sans",
        "bodySans-xSmall-underline": "Noto Sans",
        "bodySans-xSmall-underline-semibold": "Noto Sans",
        "bodySerif-xLarge": "Noto Serif",
        "bodySerif-xLarge-underline": "Noto Serif",
        "bodySerif-large": "Noto Serif",
        "bodySerif-large-underline": "Noto Serif",
        "bodySerif-medium": "Noto Serif",
        "bodySerif-medium-underline": "Noto Serif",
        "bodySerif-small": "Noto Serif",
        "bodySerif-small-underline": "Noto Serif",
        "bodySerif-xSmall": "Noto Serif",
        "bodySerif-xSmall-underline": "Noto Serif"
      },
      "fontWeight": {
        "bodySans-xLarge": "400",
        "bodySans-xLarge-underline": "400",
        "bodySans-large": "400",
        "bodySans-large-semibold": "600",
        "bodySans-large-underline": "400",
        "bodySans-large-underline-semibold": "600",
        "bodySans-medium": "400",
        "bodySans-medium-semibold": "600",
        "bodySans-medium-underline": "400",
        "bodySans-medium-underline-semibold": "600",
        "bodySans-small": "400",
        "bodySans-small-semibold": "600",
        "bodySans-small-underline": "400",
        "bodySans-small-underline-semibold": "600",
        "bodySans-xSmall": "400",
        "bodySans-xSmall-semibold": "600",
        "bodySans-xSmall-underline": "400",
        "bodySans-xSmall-underline-semibold": "600",
        "bodySerif-xLarge": "400",
        "bodySerif-xLarge-underline": "400",
        "bodySerif-large": "400",
        "bodySerif-large-underline": "400",
        "bodySerif-medium": "400",
        "bodySerif-medium-underline": "400",
        "bodySerif-small": "400",
        "bodySerif-small-underline": "400",
        "bodySerif-xSmall": "400",
        "bodySerif-xSmall-underline": "400",
        "regular": "400",
        "semibold": "600",
        "bold": "700",
        "extrabold": "800",
        "black": "900"
      },
      "lineHeight": {
        "bodySans-xLarge": "140%",
        "bodySans-xLarge-underline": "140%",
        "bodySans-large": "140%",
        "bodySans-large-semibold": "140%",
        "bodySans-large-underline": "140%",
        "bodySans-large-underline-semibold": "140%",
        "bodySans-medium": "140%",
        "bodySans-medium-semibold": "140%",
        "bodySans-medium-underline": "140%",
        "bodySans-medium-underline-semibold": "140%",
        "bodySans-small": "140%",
        "bodySans-small-semibold": "140%",
        "bodySans-small-underline": "140%",
        "bodySans-small-underline-semibold": "140%",
        "bodySans-xSmall": "140%",
        "bodySans-xSmall-semibold": "140%",
        "bodySans-xSmall-underline": "140%",
        "bodySans-xSmall-underline-semibold": "140%",
        "bodySerif-xLarge": "140%",
        "bodySerif-xLarge-underline": "140%",
        "bodySerif-large": "140%",
        "bodySerif-large-underline": "140%",
        "bodySerif-medium": "140%",
        "bodySerif-medium-underline": "140%",
        "bodySerif-small": "140%",
        "bodySerif-small-underline": "140%",
        "bodySerif-xSmall": "140%",
        "bodySerif-xSmall-underline": "140%"
      },
      "letterSpacing": {
        "bodySans-xLarge": "100%",
        "bodySans-xLarge-underline": "100%",
        "bodySans-large": "100%",
        "bodySans-large-semibold": "100%",
        "bodySans-large-underline": "100%",
        "bodySans-large-underline-semibold": "100%",
        "bodySans-medium": "100%",
        "bodySans-medium-semibold": "100%",
        "bodySans-medium-underline": "100%",
        "bodySans-medium-underline-semibold": "100%",
        "bodySans-small": "100%",
        "bodySans-small-semibold": "100%",
        "bodySans-small-underline": "100%",
        "bodySans-small-underline-semibold": "100%",
        "bodySans-xSmall": "100%",
        "bodySans-xSmall-semibold": "100%",
        "bodySans-xSmall-underline": "100%",
        "bodySans-xSmall-underline-semibold": "100%",
        "bodySerif-xLarge": "100%",
        "bodySerif-xLarge-underline": "100%",
        "bodySerif-large": "100%",
        "bodySerif-large-underline": "100%",
        "bodySerif-medium": "100%",
        "bodySerif-medium-underline": "100%",
        "bodySerif-small": "100%",
        "bodySerif-small-underline": "100%",
        "bodySerif-xSmall": "100%",
        "bodySerif-xSmall-underline": "100%"
      },
      "textTransform": {},
      "textDecoration": {
        "bodySans-xLarge": "none",
        "bodySans-xLarge-underline": "underline",
        "bodySans-large": "none",
        "bodySans-large-semibold": "none",
        "bodySans-large-underline": "underline",
        "bodySans-large-underline-semibold": "underline",
        "bodySans-medium": "none",
        "bodySans-medium-semibold": "none",
        "bodySans-medium-underline": "underline",
        "bodySans-medium-underline-semibold": "underline",
        "bodySans-small": "none",
        "bodySans-small-semibold": "none",
        "bodySans-small-underline": "underline",
        "bodySans-small-underline-semibold": "underline",
        "bodySans-xSmall": "none",
        "bodySans-xSmall-semibold": "none",
        "bodySans-xSmall-underline": "underline",
        "bodySans-xSmall-underline-semibold": "underline",
        "bodySerif-xLarge": "none",
        "bodySerif-xLarge-underline": "underline",
        "bodySerif-large": "none",
        "bodySerif-large-underline": "underline",
        "bodySerif-medium": "none",
        "bodySerif-medium-underline": "underline",
        "bodySerif-small": "none",
        "bodySerif-small-underline": "underline",
        "bodySerif-xSmall": "none",
        "bodySerif-xSmall-underline": "underline"
      }
    },
    "DART": {
      "fontSize": {
        "header-large-xxLarge": "64px",
        "header-large-xLarge": "56px",
        "header-large-large": "48px",
        "header-large-medium": "36px",
        "header-large-small": "32px",
        "header-large-xSmall": "24px",
        "header-large-xSmall-underline": "24px",
        "header-large-xxSmall": "20px",
        "header-medium-xxLarge": "48px",
        "header-medium-xLarge": "40px",
        "header-medium-large": "36px",
        "header-medium-medium": "28px",
        "header-medium-small": "24px",
        "header-medium-xSmall": "20px",
        "header-medium-xSmall-underline": "20px",
        "header-medium-xxSmall": "18px",
        "header-small-xxLarge": "48px",
        "header-small-xLarge": "40px",
        "header-small-large": "32px",
        "header-small-medium": "28px",
        "header-small-small": "24px",
        "header-small-xSmall": "18px",
        "header-small-xSmall-underline": "18px",
        "header-small-xxSmall": "16px",
        "rte-large-h1": "48px",
        "rte-large-h2": "40px",
        "rte-large-h3": "36px",
        "rte-large-h4": "32px",
        "rte-large-h5": "24px",
        "rte-large-h6": "20px",
        "rte-medium-h1": "40px",
        "rte-medium-h2": "36px",
        "rte-medium-h3": "28px",
        "rte-medium-h4": "24px",
        "rte-medium-h5": "20px",
        "rte-medium-h6": "18px",
        "rte-small-h1": "40px",
        "rte-small-h2": "32px",
        "rte-small-h3": "28px",
        "rte-small-h4": "24px",
        "rte-small-h5": "18px",
        "rte-small-h6": "16px"
      },
      "fontFamily": {
        "header-large-xxLarge": "MuseoSans900",
        "header-large-xLarge": "MuseoSans900",
        "header-large-large": "MuseoSans900",
        "header-large-medium": "MuseoSans700",
        "header-large-small": "MuseoSans700",
        "header-large-xSmall": "MuseoSans700",
        "header-large-xSmall-underline": "MuseoSans700",
        "header-large-xxSmall": "MuseoSans700",
        "header-medium-xxLarge": "MuseoSans900",
        "header-medium-xLarge": "MuseoSans900",
        "header-medium-large": "MuseoSans900",
        "header-medium-medium": "MuseoSans700",
        "header-medium-small": "MuseoSans700",
        "header-medium-xSmall": "MuseoSans700",
        "header-medium-xSmall-underline": "MuseoSans700",
        "header-medium-xxSmall": "MuseoSans700",
        "header-small-xxLarge": "MuseoSans900",
        "header-small-xLarge": "MuseoSans900",
        "header-small-large": "MuseoSans900",
        "header-small-medium": "MuseoSans700",
        "header-small-small": "MuseoSans700",
        "header-small-xSmall": "MuseoSans700",
        "header-small-xSmall-underline": "MuseoSans700",
        "header-small-xxSmall": "MuseoSans700",
        "rte-large-h1": "MuseoSans900",
        "rte-large-h2": "MuseoSans900",
        "rte-large-h3": "MuseoSans900",
        "rte-large-h4": "MuseoSans900",
        "rte-large-h5": "MuseoSans900",
        "rte-large-h6": "MuseoSans900",
        "rte-medium-h1": "MuseoSans900",
        "rte-medium-h2": "MuseoSans900",
        "rte-medium-h3": "MuseoSans900",
        "rte-medium-h4": "MuseoSans900",
        "rte-medium-h5": "MuseoSans900",
        "rte-medium-h6": "MuseoSans900",
        "rte-small-h1": "MuseoSans900",
        "rte-small-h2": "MuseoSans900",
        "rte-small-h3": "MuseoSans900",
        "rte-small-h4": "MuseoSans900",
        "rte-small-h5": "MuseoSans900",
        "rte-small-h6": "MuseoSans900"
      },
      "fontWeight": {
        "header-large-xxLarge": "900",
        "header-large-xLarge": "900",
        "header-large-large": "900",
        "header-large-medium": "700",
        "header-large-small": "700",
        "header-large-xSmall": "700",
        "header-large-xSmall-underline": "700",
        "header-large-xxSmall": "700",
        "header-medium-xxLarge": "900",
        "header-medium-xLarge": "900",
        "header-medium-large": "900",
        "header-medium-medium": "700",
        "header-medium-small": "700",
        "header-medium-xSmall": "700",
        "header-medium-xSmall-underline": "700",
        "header-medium-xxSmall": "700",
        "header-small-xxLarge": "900",
        "header-small-xLarge": "900",
        "header-small-large": "900",
        "header-small-medium": "700",
        "header-small-small": "700",
        "header-small-xSmall": "700",
        "header-small-xSmall-underline": "700",
        "header-small-xxSmall": "700",
        "rte-large-h1": "900",
        "rte-large-h2": "900",
        "rte-large-h3": "700",
        "rte-large-h4": "700",
        "rte-large-h5": "700",
        "rte-large-h6": "700",
        "rte-medium-h1": "900",
        "rte-medium-h2": "900",
        "rte-medium-h3": "700",
        "rte-medium-h4": "700",
        "rte-medium-h5": "700",
        "rte-medium-h6": "700",
        "rte-small-h1": "900",
        "rte-small-h2": "900",
        "rte-small-h3": "700",
        "rte-small-h4": "700",
        "rte-small-h5": "700",
        "rte-small-h6": "700"
      },
      "lineHeight": {
        "header-large-xxLarge": "120%",
        "header-large-xLarge": "120%",
        "header-large-large": "120%",
        "header-large-medium": "120%",
        "header-large-small": "120%",
        "header-large-xSmall": "120%",
        "header-large-xSmall-underline": "120%",
        "header-large-xxSmall": "120%",
        "header-medium-xxLarge": "120%",
        "header-medium-xLarge": "120%",
        "header-medium-large": "120%",
        "header-medium-medium": "120%",
        "header-medium-small": "120%",
        "header-medium-xSmall": "120%",
        "header-medium-xSmall-underline": "120%",
        "header-medium-xxSmall": "120%",
        "header-small-xxLarge": "120%",
        "header-small-xLarge": "120%",
        "header-small-large": "120%",
        "header-small-medium": "120%",
        "header-small-small": "120%",
        "header-small-xSmall": "120%",
        "header-small-xSmall-underline": "120%",
        "header-small-xxSmall": "120%",
        "rte-large-h1": "120%",
        "rte-large-h2": "120%",
        "rte-large-h3": "120%",
        "rte-large-h4": "120%",
        "rte-large-h5": "120%",
        "rte-large-h6": "120%",
        "rte-medium-h1": "120%",
        "rte-medium-h2": "120%",
        "rte-medium-h3": "120%",
        "rte-medium-h4": "120%",
        "rte-medium-h5": "120%",
        "rte-medium-h6": "120%",
        "rte-small-h1": "120%",
        "rte-small-h2": "120%",
        "rte-small-h3": "120%",
        "rte-small-h4": "120%",
        "rte-small-h5": "120%",
        "rte-small-h6": "120%"
      },
      "letterSpacing": {
        "header-large-xxLarge": "100%",
        "header-large-xLarge": "100%",
        "header-large-large": "100%",
        "header-large-medium": "100%",
        "header-large-small": "100%",
        "header-large-xSmall": "100%",
        "header-large-xSmall-underline": "100%",
        "header-large-xxSmall": "100%",
        "header-medium-xxLarge": "100%",
        "header-medium-xLarge": "100%",
        "header-medium-large": "100%",
        "header-medium-medium": "100%",
        "header-medium-small": "100%",
        "header-medium-xSmall": "100%",
        "header-medium-xSmall-underline": "100%",
        "header-medium-xxSmall": "100%",
        "header-small-xxLarge": "100%",
        "header-small-xLarge": "100%",
        "header-small-large": "100%",
        "header-small-medium": "100%",
        "header-small-small": "100%",
        "header-small-xSmall": "100%",
        "header-small-xSmall-underline": "100%",
        "header-small-xxSmall": "100%",
        "rte-large-h1": "100%",
        "rte-large-h2": "100%",
        "rte-large-h3": "100%",
        "rte-large-h4": "100%",
        "rte-large-h5": "100%",
        "rte-large-h6": "100%",
        "rte-medium-h1": "100%",
        "rte-medium-h2": "100%",
        "rte-medium-h3": "100%",
        "rte-medium-h4": "100%",
        "rte-medium-h5": "100%",
        "rte-medium-h6": "100%",
        "rte-small-h1": "100%",
        "rte-small-h2": "100%",
        "rte-small-h3": "100%",
        "rte-small-h4": "100%",
        "rte-small-h5": "100%",
        "rte-small-h6": "100%"
      },
      "textTransform": {},
      "textDecoration": {
        "header-large-xxLarge": "none",
        "header-large-xLarge": "none",
        "header-large-large": "none",
        "header-large-medium": "none",
        "header-large-small": "none",
        "header-large-xSmall": "none",
        "header-large-xSmall-underline": "underline",
        "header-large-xxSmall": "underline",
        "header-medium-xxLarge": "none",
        "header-medium-xLarge": "none",
        "header-medium-large": "none",
        "header-medium-medium": "none",
        "header-medium-small": "none",
        "header-medium-xSmall": "none",
        "header-medium-xSmall-underline": "underline",
        "header-medium-xxSmall": "underline",
        "header-small-xxLarge": "none",
        "header-small-xLarge": "none",
        "header-small-large": "none",
        "header-small-medium": "none",
        "header-small-small": "none",
        "header-small-xSmall": "none",
        "header-small-xSmall-underline": "underline",
        "header-small-xxSmall": "underline",
        "rte-large-h1": "none",
        "rte-large-h2": "none",
        "rte-large-h3": "none",
        "rte-large-h4": "none",
        "rte-large-h5": "none",
        "rte-large-h6": "underline",
        "rte-medium-h1": "none",
        "rte-medium-h2": "none",
        "rte-medium-h3": "none",
        "rte-medium-h4": "none",
        "rte-medium-h5": "none",
        "rte-medium-h6": "underline",
        "rte-small-h1": "none",
        "rte-small-h2": "none",
        "rte-small-h3": "none",
        "rte-small-h4": "none",
        "rte-small-h5": "none",
        "rte-small-h6": "underline"
      }
    },
    "Ziploc": {
      "fontSize": {
        "header-large-xxLarge": "64px",
        "header-large-xLarge": "56px",
        "header-large-large": "48px",
        "header-large-medium": "36px",
        "header-large-small": "32px",
        "header-large-xSmall": "24px",
        "header-large-xSmall-underline": "24px",
        "header-large-xxSmall": "20px",
        "header-medium-xxLarge": "48px",
        "header-medium-xLarge": "40px",
        "header-medium-large": "36px",
        "header-medium-medium": "28px",
        "header-medium-small": "24px",
        "header-medium-xSmall": "20px",
        "header-medium-xSmall-underline": "20px",
        "header-medium-xxSmall": "18px",
        "header-small-xxLarge": "48px",
        "header-small-xLarge": "40px",
        "header-small-large": "32px",
        "header-small-medium": "28px",
        "header-small-small": "24px",
        "header-small-xSmall": "18px",
        "header-small-xSmall-underline": "18px",
        "header-small-xxSmall": "16px",
        "rte-large-h1": "48px",
        "rte-large-h2": "40px",
        "rte-large-h3": "36px",
        "rte-large-h4": "32px",
        "rte-large-h5": "24px",
        "rte-large-h6": "20px",
        "rte-medium-h1": "40px",
        "rte-medium-h2": "36px",
        "rte-medium-h3": "28px",
        "rte-medium-h4": "24px",
        "rte-medium-h5": "20px",
        "rte-medium-h6": "18px",
        "rte-small-h1": "40px",
        "rte-small-h2": "32px",
        "rte-small-h3": "28px",
        "rte-small-h4": "24px",
        "rte-small-h5": "18px",
        "rte-small-h6": "16px"
      },
      "fontFamily": {
        "header-large-xxLarge": "MuseoSans900",
        "header-large-xLarge": "MuseoSans900",
        "header-large-large": "MuseoSans900",
        "header-large-medium": "MuseoSans700",
        "header-large-small": "MuseoSans700",
        "header-large-xSmall": "MuseoSans700",
        "header-large-xSmall-underline": "MuseoSans700",
        "header-large-xxSmall": "MuseoSans700",
        "header-medium-xxLarge": "MuseoSans900",
        "header-medium-xLarge": "MuseoSans900",
        "header-medium-large": "MuseoSans900",
        "header-medium-medium": "MuseoSans700",
        "header-medium-small": "MuseoSans700",
        "header-medium-xSmall": "MuseoSans700",
        "header-medium-xSmall-underline": "MuseoSans700",
        "header-medium-xxSmall": "MuseoSans700",
        "header-small-xxLarge": "MuseoSans900",
        "header-small-xLarge": "MuseoSans900",
        "header-small-large": "MuseoSans900",
        "header-small-medium": "MuseoSans700",
        "header-small-small": "MuseoSans700",
        "header-small-xSmall": "MuseoSans700",
        "header-small-xSmall-underline": "MuseoSans700",
        "header-small-xxSmall": "MuseoSans700",
        "rte-large-h1": "MuseoSans900",
        "rte-large-h2": "MuseoSans900",
        "rte-large-h3": "MuseoSans900",
        "rte-large-h4": "MuseoSans900",
        "rte-large-h5": "MuseoSans900",
        "rte-large-h6": "MuseoSans900",
        "rte-medium-h1": "MuseoSans900",
        "rte-medium-h2": "MuseoSans900",
        "rte-medium-h3": "MuseoSans900",
        "rte-medium-h4": "MuseoSans900",
        "rte-medium-h5": "MuseoSans900",
        "rte-medium-h6": "MuseoSans900",
        "rte-small-h1": "MuseoSans900",
        "rte-small-h2": "MuseoSans900",
        "rte-small-h3": "MuseoSans900",
        "rte-small-h4": "MuseoSans900",
        "rte-small-h5": "MuseoSans900",
        "rte-small-h6": "MuseoSans900"
      },
      "fontWeight": {
        "header-large-xxLarge": "900",
        "header-large-xLarge": "900",
        "header-large-large": "900",
        "header-large-medium": "700",
        "header-large-small": "700",
        "header-large-xSmall": "700",
        "header-large-xSmall-underline": "700",
        "header-large-xxSmall": "700",
        "header-medium-xxLarge": "900",
        "header-medium-xLarge": "900",
        "header-medium-large": "900",
        "header-medium-medium": "700",
        "header-medium-small": "700",
        "header-medium-xSmall": "700",
        "header-medium-xSmall-underline": "700",
        "header-medium-xxSmall": "700",
        "header-small-xxLarge": "900",
        "header-small-xLarge": "900",
        "header-small-large": "900",
        "header-small-medium": "700",
        "header-small-small": "700",
        "header-small-xSmall": "700",
        "header-small-xSmall-underline": "700",
        "header-small-xxSmall": "700",
        "rte-large-h1": "900",
        "rte-large-h2": "900",
        "rte-large-h3": "900",
        "rte-large-h4": "900",
        "rte-large-h5": "900",
        "rte-large-h6": "900",
        "rte-medium-h1": "900",
        "rte-medium-h2": "900",
        "rte-medium-h3": "900",
        "rte-medium-h4": "900",
        "rte-medium-h5": "900",
        "rte-medium-h6": "900",
        "rte-small-h1": "900",
        "rte-small-h2": "900",
        "rte-small-h3": "900",
        "rte-small-h4": "900",
        "rte-small-h5": "900",
        "rte-small-h6": "900"
      },
      "lineHeight": {
        "header-large-xxLarge": "120%",
        "header-large-xLarge": "120%",
        "header-large-large": "120%",
        "header-large-medium": "120%",
        "header-large-small": "120%",
        "header-large-xSmall": "120%",
        "header-large-xSmall-underline": "120%",
        "header-large-xxSmall": "120%",
        "header-medium-xxLarge": "120%",
        "header-medium-xLarge": "120%",
        "header-medium-large": "120%",
        "header-medium-medium": "120%",
        "header-medium-small": "120%",
        "header-medium-xSmall": "120%",
        "header-medium-xSmall-underline": "120%",
        "header-medium-xxSmall": "120%",
        "header-small-xxLarge": "120%",
        "header-small-xLarge": "120%",
        "header-small-large": "120%",
        "header-small-medium": "120%",
        "header-small-small": "120%",
        "header-small-xSmall": "120%",
        "header-small-xSmall-underline": "120%",
        "header-small-xxSmall": "120%",
        "rte-large-h1": "120%",
        "rte-large-h2": "120%",
        "rte-large-h3": "120%",
        "rte-large-h4": "120%",
        "rte-large-h5": "120%",
        "rte-large-h6": "120%",
        "rte-medium-h1": "120%",
        "rte-medium-h2": "120%",
        "rte-medium-h3": "120%",
        "rte-medium-h4": "120%",
        "rte-medium-h5": "120%",
        "rte-medium-h6": "120%",
        "rte-small-h1": "120%",
        "rte-small-h2": "120%",
        "rte-small-h3": "120%",
        "rte-small-h4": "120%",
        "rte-small-h5": "120%",
        "rte-small-h6": "120%"
      },
      "letterSpacing": {
        "header-large-xxLarge": "100%",
        "header-large-xLarge": "100%",
        "header-large-large": "100%",
        "header-large-medium": "100%",
        "header-large-small": "100%",
        "header-large-xSmall": "100%",
        "header-large-xSmall-underline": "100%",
        "header-large-xxSmall": "100%",
        "header-medium-xxLarge": "100%",
        "header-medium-xLarge": "100%",
        "header-medium-large": "100%",
        "header-medium-medium": "100%",
        "header-medium-small": "100%",
        "header-medium-xSmall": "100%",
        "header-medium-xSmall-underline": "100%",
        "header-medium-xxSmall": "100%",
        "header-small-xxLarge": "100%",
        "header-small-xLarge": "100%",
        "header-small-large": "100%",
        "header-small-medium": "100%",
        "header-small-small": "100%",
        "header-small-xSmall": "100%",
        "header-small-xSmall-underline": "100%",
        "header-small-xxSmall": "100%",
        "rte-large-h1": "100%",
        "rte-large-h2": "100%",
        "rte-large-h3": "100%",
        "rte-large-h4": "100%",
        "rte-large-h5": "100%",
        "rte-large-h6": "100%",
        "rte-medium-h1": "100%",
        "rte-medium-h2": "100%",
        "rte-medium-h3": "100%",
        "rte-medium-h4": "100%",
        "rte-medium-h5": "100%",
        "rte-medium-h6": "100%",
        "rte-small-h1": "100%",
        "rte-small-h2": "100%",
        "rte-small-h3": "100%",
        "rte-small-h4": "100%",
        "rte-small-h5": "100%",
        "rte-small-h6": "100%"
      },
      "textTransform": {},
      "textDecoration": {
        "header-large-xxLarge": "none",
        "header-large-xLarge": "none",
        "header-large-large": "none",
        "header-large-medium": "none",
        "header-large-small": "none",
        "header-large-xSmall": "none",
        "header-large-xSmall-underline": "underline",
        "header-large-xxSmall": "underline",
        "header-medium-xxLarge": "none",
        "header-medium-xLarge": "none",
        "header-medium-large": "none",
        "header-medium-medium": "none",
        "header-medium-small": "none",
        "header-medium-xSmall": "none",
        "header-medium-xSmall-underline": "underline",
        "header-medium-xxSmall": "underline",
        "header-small-xxLarge": "none",
        "header-small-xLarge": "none",
        "header-small-large": "none",
        "header-small-medium": "none",
        "header-small-small": "none",
        "header-small-xSmall": "none",
        "header-small-xSmall-underline": "underline",
        "header-small-xxSmall": "underline",
        "rte-large-h1": "none",
        "rte-large-h2": "none",
        "rte-large-h3": "none",
        "rte-large-h4": "none",
        "rte-large-h5": "none",
        "rte-large-h6": "underline",
        "rte-medium-h1": "none",
        "rte-medium-h2": "none",
        "rte-medium-h3": "none",
        "rte-medium-h4": "none",
        "rte-medium-h5": "none",
        "rte-medium-h6": "underline",
        "rte-small-h1": "none",
        "rte-small-h2": "none",
        "rte-small-h3": "none",
        "rte-small-h4": "none",
        "rte-small-h5": "none",
        "rte-small-h6": "underline"
      }
    },
    "Off": {
      "fontSize": {
        "header-large-xxLarge": "64px",
        "header-large-xLarge": "56px",
        "header-large-large": "48px",
        "header-large-medium": "36px",
        "header-large-small": "32px",
        "header-large-xSmall": "24px",
        "header-large-xSmall-underline": "24px",
        "header-large-xxSmall": "20px",
        "header-medium-xxLarge": "48px",
        "header-medium-xLarge": "40px",
        "header-medium-large": "36px",
        "header-medium-medium": "28px",
        "header-medium-small": "24px",
        "header-medium-xSmall": "20px",
        "header-medium-xSmall-underline": "20px",
        "header-medium-xxSmall": "18px",
        "header-small-xxLarge": "48px",
        "header-small-xLarge": "40px",
        "header-small-large": "32px",
        "header-small-medium": "28px",
        "header-small-small": "24px",
        "header-small-xSmall": "18px",
        "header-small-xSmall-underline": "18px",
        "header-small-xxSmall": "16px",
        "rte-large-h1": "48px",
        "rte-large-h2": "40px",
        "rte-large-h3": "36px",
        "rte-large-h4": "32px",
        "rte-large-h5": "24px",
        "rte-large-h6": "20px",
        "rte-medium-h1": "40px",
        "rte-medium-h2": "36px",
        "rte-medium-h3": "28px",
        "rte-medium-h4": "24px",
        "rte-medium-h5": "20px",
        "rte-medium-h6": "18px",
        "rte-small-h1": "40px",
        "rte-small-h2": "32px",
        "rte-small-h3": "28px",
        "rte-small-h4": "24px",
        "rte-small-h5": "18px",
        "rte-small-h6": "16px"
      },
      "fontFamily": {
        "header-large-xxLarge": "Poppins",
        "header-large-xLarge": "Poppins",
        "header-large-large": "Poppins",
        "header-large-medium": "Poppins",
        "header-large-small": "Poppins",
        "header-large-xSmall": "Poppins",
        "header-large-xSmall-underline": "Poppins",
        "header-large-xxSmall": "Poppins",
        "header-medium-xxLarge": "Poppins",
        "header-medium-xLarge": "Poppins",
        "header-medium-large": "Poppins",
        "header-medium-medium": "Poppins",
        "header-medium-small": "Poppins",
        "header-medium-xSmall": "Poppins",
        "header-medium-xSmall-underline": "Poppins",
        "header-medium-xxSmall": "Poppins",
        "header-small-xxLarge": "Poppins",
        "header-small-xLarge": "Poppins",
        "header-small-large": "Poppins",
        "header-small-medium": "Poppins",
        "header-small-small": "Poppins",
        "header-small-xSmall": "Poppins",
        "header-small-xSmall-underline": "Poppins",
        "header-small-xxSmall": "Poppins",
        "rte-large-h1": "Poppins",
        "rte-large-h2": "Poppins",
        "rte-large-h3": "Poppins",
        "rte-large-h4": "Poppins",
        "rte-large-h5": "Poppins",
        "rte-large-h6": "Poppins",
        "rte-medium-h1": "Poppins",
        "rte-medium-h2": "Poppins",
        "rte-medium-h3": "Poppins",
        "rte-medium-h4": "Poppins",
        "rte-medium-h5": "Poppins",
        "rte-medium-h6": "Poppins",
        "rte-small-h1": "Poppins",
        "rte-small-h2": "Poppins",
        "rte-small-h3": "Poppins",
        "rte-small-h4": "Poppins",
        "rte-small-h5": "Poppins",
        "rte-small-h6": "Poppins"
      },
      "fontWeight": {
        "header-large-xxLarge": "800",
        "header-large-xLarge": "800",
        "header-large-large": "800",
        "header-large-medium": "800",
        "header-large-small": "800",
        "header-large-xSmall": "700",
        "header-large-xSmall-underline": "700",
        "header-large-xxSmall": "600",
        "header-medium-xxLarge": "800",
        "header-medium-xLarge": "800",
        "header-medium-large": "800",
        "header-medium-medium": "800",
        "header-medium-small": "800",
        "header-medium-xSmall": "800",
        "header-medium-xSmall-underline": "800",
        "header-medium-xxSmall": "700",
        "header-small-xxLarge": "800",
        "header-small-xLarge": "800",
        "header-small-large": "800",
        "header-small-medium": "800",
        "header-small-small": "800",
        "header-small-xSmall": "700",
        "header-small-xSmall-underline": "700",
        "header-small-xxSmall": "700",
        "rte-large-h1": "800",
        "rte-large-h2": "800",
        "rte-large-h3": "800",
        "rte-large-h4": "800",
        "rte-large-h5": "800",
        "rte-large-h6": "800",
        "rte-medium-h1": "800",
        "rte-medium-h2": "800",
        "rte-medium-h3": "800",
        "rte-medium-h4": "800",
        "rte-medium-h5": "800",
        "rte-medium-h6": "800",
        "rte-small-h1": "800",
        "rte-small-h2": "800",
        "rte-small-h3": "800",
        "rte-small-h4": "800",
        "rte-small-h5": "800",
        "rte-small-h6": "800"
      },
      "lineHeight": {
        "header-large-xxLarge": "120%",
        "header-large-xLarge": "120%",
        "header-large-large": "120%",
        "header-large-medium": "120%",
        "header-large-small": "120%",
        "header-large-xSmall": "120%",
        "header-large-xSmall-underline": "120%",
        "header-large-xxSmall": "120%",
        "header-medium-xxLarge": "120%",
        "header-medium-xLarge": "120%",
        "header-medium-large": "120%",
        "header-medium-medium": "120%",
        "header-medium-small": "120%",
        "header-medium-xSmall": "120%",
        "header-medium-xSmall-underline": "120%",
        "header-medium-xxSmall": "120%",
        "header-small-xxLarge": "120%",
        "header-small-xLarge": "120%",
        "header-small-large": "120%",
        "header-small-medium": "120%",
        "header-small-small": "120%",
        "header-small-xSmall": "120%",
        "header-small-xSmall-underline": "120%",
        "header-small-xxSmall": "120%",
        "rte-large-h1": "120%",
        "rte-large-h2": "120%",
        "rte-large-h3": "120%",
        "rte-large-h4": "120%",
        "rte-large-h5": "120%",
        "rte-large-h6": "120%",
        "rte-medium-h1": "120%",
        "rte-medium-h2": "120%",
        "rte-medium-h3": "120%",
        "rte-medium-h4": "120%",
        "rte-medium-h5": "120%",
        "rte-medium-h6": "120%",
        "rte-small-h1": "120%",
        "rte-small-h2": "120%",
        "rte-small-h3": "120%",
        "rte-small-h4": "120%",
        "rte-small-h5": "120%",
        "rte-small-h6": "120%"
      },
      "letterSpacing": {
        "header-large-xxLarge": "100%",
        "header-large-xLarge": "100%",
        "header-large-large": "100%",
        "header-large-medium": "100%",
        "header-large-small": "100%",
        "header-large-xSmall": "100%",
        "header-large-xSmall-underline": "100%",
        "header-large-xxSmall": "100%",
        "header-medium-xxLarge": "100%",
        "header-medium-xLarge": "100%",
        "header-medium-large": "100%",
        "header-medium-medium": "100%",
        "header-medium-small": "100%",
        "header-medium-xSmall": "100%",
        "header-medium-xSmall-underline": "100%",
        "header-medium-xxSmall": "100%",
        "header-small-xxLarge": "100%",
        "header-small-xLarge": "100%",
        "header-small-large": "100%",
        "header-small-medium": "100%",
        "header-small-small": "100%",
        "header-small-xSmall": "100%",
        "header-small-xSmall-underline": "100%",
        "header-small-xxSmall": "100%",
        "rte-large-h1": "100%",
        "rte-large-h2": "100%",
        "rte-large-h3": "100%",
        "rte-large-h4": "100%",
        "rte-large-h5": "100%",
        "rte-large-h6": "100%",
        "rte-medium-h1": "100%",
        "rte-medium-h2": "100%",
        "rte-medium-h3": "100%",
        "rte-medium-h4": "100%",
        "rte-medium-h5": "100%",
        "rte-medium-h6": "100%",
        "rte-small-h1": "100%",
        "rte-small-h2": "100%",
        "rte-small-h3": "100%",
        "rte-small-h4": "100%",
        "rte-small-h5": "100%",
        "rte-small-h6": "100%"
      },
      "textTransform": {},
      "textDecoration": {
        "header-large-xxLarge": "none",
        "header-large-xLarge": "none",
        "header-large-large": "none",
        "header-large-medium": "none",
        "header-large-small": "none",
        "header-large-xSmall": "none",
        "header-large-xSmall-underline": "underline",
        "header-large-xxSmall": "none",
        "header-medium-xxLarge": "none",
        "header-medium-xLarge": "none",
        "header-medium-large": "none",
        "header-medium-medium": "none",
        "header-medium-small": "none",
        "header-medium-xSmall": "none",
        "header-medium-xSmall-underline": "underline",
        "header-medium-xxSmall": "none",
        "header-small-xxLarge": "none",
        "header-small-xLarge": "none",
        "header-small-large": "none",
        "header-small-medium": "none",
        "header-small-small": "none",
        "header-small-xSmall": "none",
        "header-small-xSmall-underline": "underline",
        "header-small-xxSmall": "none",
        "rte-large-h1": "none",
        "rte-large-h2": "none",
        "rte-large-h3": "none",
        "rte-large-h4": "none",
        "rte-large-h5": "none",
        "rte-large-h6": "underline",
        "rte-medium-h1": "none",
        "rte-medium-h2": "none",
        "rte-medium-h3": "none",
        "rte-medium-h4": "none",
        "rte-medium-h5": "none",
        "rte-medium-h6": "underline",
        "rte-small-h1": "none",
        "rte-small-h2": "none",
        "rte-small-h3": "none",
        "rte-small-h4": "none",
        "rte-small-h5": "none",
        "rte-small-h6": "underline"
      }
    },
    "Autan": {
      "fontSize": {
        "header-large-xxLarge": "64px",
        "header-large-xLarge": "56px",
        "header-large-large": "48px",
        "header-large-medium": "36px",
        "header-large-small": "32px",
        "header-large-xSmall": "24px",
        "header-large-xSmall-underline": "24px",
        "header-large-xxSmall": "20px",
        "header-medium-xxLarge": "48px",
        "header-medium-xLarge": "40px",
        "header-medium-large": "36px",
        "header-medium-medium": "28px",
        "header-medium-small": "24px",
        "header-medium-xSmall": "20px",
        "header-medium-xSmall-underline": "20px",
        "header-medium-xxSmall": "18px",
        "header-small-xxLarge": "48px",
        "header-small-xLarge": "40px",
        "header-small-large": "32px",
        "header-small-medium": "28px",
        "header-small-small": "24px",
        "header-small-xSmall": "18px",
        "header-small-xSmall-underline": "18px",
        "header-small-xxSmall": "16px",
        "rte-large-h1": "48px",
        "rte-large-h2": "40px",
        "rte-large-h3": "36px",
        "rte-large-h4": "32px",
        "rte-large-h5": "24px",
        "rte-large-h6": "20px",
        "rte-medium-h1": "40px",
        "rte-medium-h2": "36px",
        "rte-medium-h3": "28px",
        "rte-medium-h4": "24px",
        "rte-medium-h5": "20px",
        "rte-medium-h6": "18px",
        "rte-small-h1": "40px",
        "rte-small-h2": "32px",
        "rte-small-h3": "28px",
        "rte-small-h4": "24px",
        "rte-small-h5": "18px",
        "rte-small-h6": "16px"
      },
      "fontFamily": {
        "header-large-xxLarge": "Poppins",
        "header-large-xLarge": "Poppins",
        "header-large-large": "Poppins",
        "header-large-medium": "Poppins",
        "header-large-small": "Poppins",
        "header-large-xSmall": "Poppins",
        "header-large-xSmall-underline": "Poppins",
        "header-large-xxSmall": "Poppins",
        "header-medium-xxLarge": "Poppins",
        "header-medium-xLarge": "Poppins",
        "header-medium-large": "Poppins",
        "header-medium-medium": "Poppins",
        "header-medium-small": "Poppins",
        "header-medium-xSmall": "Poppins",
        "header-medium-xSmall-underline": "Poppins",
        "header-medium-xxSmall": "Poppins",
        "header-small-xxLarge": "Poppins",
        "header-small-xLarge": "Poppins",
        "header-small-large": "Poppins",
        "header-small-medium": "Poppins",
        "header-small-small": "Poppins",
        "header-small-xSmall": "Poppins",
        "header-small-xSmall-underline": "Poppins",
        "header-small-xxSmall": "Poppins",
        "rte-large-h1": "Poppins",
        "rte-large-h2": "Poppins",
        "rte-large-h3": "Poppins",
        "rte-large-h4": "Poppins",
        "rte-large-h5": "Poppins",
        "rte-large-h6": "Poppins",
        "rte-medium-h1": "Poppins",
        "rte-medium-h2": "Poppins",
        "rte-medium-h3": "Poppins",
        "rte-medium-h4": "Poppins",
        "rte-medium-h5": "Poppins",
        "rte-medium-h6": "Poppins",
        "rte-small-h1": "Poppins",
        "rte-small-h2": "Poppins",
        "rte-small-h3": "Poppins",
        "rte-small-h4": "Poppins",
        "rte-small-h5": "Poppins",
        "rte-small-h6": "Poppins"
      },
      "fontWeight": {
        "header-large-xxLarge": "800",
        "header-large-xLarge": "800",
        "header-large-large": "800",
        "header-large-medium": "800",
        "header-large-small": "800",
        "header-large-xSmall": "700",
        "header-large-xSmall-underline": "700",
        "header-large-xxSmall": "600",
        "header-medium-xxLarge": "800",
        "header-medium-xLarge": "800",
        "header-medium-large": "800",
        "header-medium-medium": "800",
        "header-medium-small": "800",
        "header-medium-xSmall": "800",
        "header-medium-xSmall-underline": "800",
        "header-medium-xxSmall": "700",
        "header-small-xxLarge": "800",
        "header-small-xLarge": "800",
        "header-small-large": "800",
        "header-small-medium": "800",
        "header-small-small": "800",
        "header-small-xSmall": "700",
        "header-small-xSmall-underline": "700",
        "header-small-xxSmall": "700",
        "rte-large-h1": "800",
        "rte-large-h2": "800",
        "rte-large-h3": "800",
        "rte-large-h4": "800",
        "rte-large-h5": "800",
        "rte-large-h6": "800",
        "rte-medium-h1": "800",
        "rte-medium-h2": "800",
        "rte-medium-h3": "800",
        "rte-medium-h4": "800",
        "rte-medium-h5": "800",
        "rte-medium-h6": "800",
        "rte-small-h1": "800",
        "rte-small-h2": "800",
        "rte-small-h3": "800",
        "rte-small-h4": "800",
        "rte-small-h5": "800",
        "rte-small-h6": "800"
      },
      "lineHeight": {
        "header-large-xxLarge": "120%",
        "header-large-xLarge": "120%",
        "header-large-large": "120%",
        "header-large-medium": "120%",
        "header-large-small": "120%",
        "header-large-xSmall": "120%",
        "header-large-xSmall-underline": "120%",
        "header-large-xxSmall": "120%",
        "header-medium-xxLarge": "120%",
        "header-medium-xLarge": "120%",
        "header-medium-large": "120%",
        "header-medium-medium": "120%",
        "header-medium-small": "120%",
        "header-medium-xSmall": "120%",
        "header-medium-xSmall-underline": "120%",
        "header-medium-xxSmall": "120%",
        "header-small-xxLarge": "120%",
        "header-small-xLarge": "120%",
        "header-small-large": "120%",
        "header-small-medium": "120%",
        "header-small-small": "120%",
        "header-small-xSmall": "120%",
        "header-small-xSmall-underline": "120%",
        "header-small-xxSmall": "120%",
        "rte-large-h1": "120%",
        "rte-large-h2": "120%",
        "rte-large-h3": "120%",
        "rte-large-h4": "120%",
        "rte-large-h5": "120%",
        "rte-large-h6": "120%",
        "rte-medium-h1": "120%",
        "rte-medium-h2": "120%",
        "rte-medium-h3": "120%",
        "rte-medium-h4": "120%",
        "rte-medium-h5": "120%",
        "rte-medium-h6": "120%",
        "rte-small-h1": "120%",
        "rte-small-h2": "120%",
        "rte-small-h3": "120%",
        "rte-small-h4": "120%",
        "rte-small-h5": "120%",
        "rte-small-h6": "120%"
      },
      "letterSpacing": {
        "header-large-xxLarge": "100%",
        "header-large-xLarge": "100%",
        "header-large-large": "100%",
        "header-large-medium": "100%",
        "header-large-small": "100%",
        "header-large-xSmall": "100%",
        "header-large-xSmall-underline": "100%",
        "header-large-xxSmall": "100%",
        "header-medium-xxLarge": "100%",
        "header-medium-xLarge": "100%",
        "header-medium-large": "100%",
        "header-medium-medium": "100%",
        "header-medium-small": "100%",
        "header-medium-xSmall": "100%",
        "header-medium-xSmall-underline": "100%",
        "header-medium-xxSmall": "100%",
        "header-small-xxLarge": "100%",
        "header-small-xLarge": "100%",
        "header-small-large": "100%",
        "header-small-medium": "100%",
        "header-small-small": "100%",
        "header-small-xSmall": "100%",
        "header-small-xSmall-underline": "100%",
        "header-small-xxSmall": "100%",
        "rte-large-h1": "100%",
        "rte-large-h2": "100%",
        "rte-large-h3": "100%",
        "rte-large-h4": "100%",
        "rte-large-h5": "100%",
        "rte-large-h6": "100%",
        "rte-medium-h1": "100%",
        "rte-medium-h2": "100%",
        "rte-medium-h3": "100%",
        "rte-medium-h4": "100%",
        "rte-medium-h5": "100%",
        "rte-medium-h6": "100%",
        "rte-small-h1": "100%",
        "rte-small-h2": "100%",
        "rte-small-h3": "100%",
        "rte-small-h4": "100%",
        "rte-small-h5": "100%",
        "rte-small-h6": "100%"
      },
      "textTransform": {},
      "textDecoration": {
        "header-large-xxLarge": "none",
        "header-large-xLarge": "none",
        "header-large-large": "none",
        "header-large-medium": "none",
        "header-large-small": "none",
        "header-large-xSmall": "none",
        "header-large-xSmall-underline": "underline",
        "header-large-xxSmall": "none",
        "header-medium-xxLarge": "none",
        "header-medium-xLarge": "none",
        "header-medium-large": "none",
        "header-medium-medium": "none",
        "header-medium-small": "none",
        "header-medium-xSmall": "none",
        "header-medium-xSmall-underline": "underline",
        "header-medium-xxSmall": "none",
        "header-small-xxLarge": "none",
        "header-small-xLarge": "none",
        "header-small-large": "none",
        "header-small-medium": "none",
        "header-small-small": "none",
        "header-small-xSmall": "none",
        "header-small-xSmall-underline": "underline",
        "header-small-xxSmall": "none",
        "rte-large-h1": "none",
        "rte-large-h2": "none",
        "rte-large-h3": "none",
        "rte-large-h4": "none",
        "rte-large-h5": "none",
        "rte-large-h6": "underline",
        "rte-medium-h1": "none",
        "rte-medium-h2": "none",
        "rte-medium-h3": "none",
        "rte-medium-h4": "none",
        "rte-medium-h5": "none",
        "rte-medium-h6": "underline",
        "rte-small-h1": "none",
        "rte-small-h2": "none",
        "rte-small-h3": "none",
        "rte-small-h4": "none",
        "rte-small-h5": "none",
        "rte-small-h6": "underline"
      }
    },
    "Corporate": {
      "fontSize": {
        "header-large-xxLarge": "64px",
        "header-large-xLarge": "56px",
        "header-large-large": "48px",
        "header-large-medium": "36px",
        "header-large-small": "32px",
        "header-large-xSmall": "24px",
        "header-large-xSmall-underline": "24px",
        "header-large-xxSmall": "20px",
        "header-medium-xxLarge": "48px",
        "header-medium-xLarge": "40px",
        "header-medium-large": "36px",
        "header-medium-medium": "28px",
        "header-medium-small": "24px",
        "header-medium-xSmall": "20px",
        "header-medium-xSmall-underline": "20px",
        "header-medium-xxSmall": "18px",
        "header-small-xxLarge": "48px",
        "header-small-xLarge": "40px",
        "header-small-large": "32px",
        "header-small-medium": "28px",
        "header-small-small": "24px",
        "header-small-xSmall": "18px",
        "header-small-xSmall-underline": "18px",
        "header-small-xxSmall": "16px",
        "rte-large-h1": "48px",
        "rte-large-h2": "40px",
        "rte-large-h3": "36px",
        "rte-large-h4": "32px",
        "rte-large-h5": "24px",
        "rte-large-h6": "20px",
        "rte-medium-h1": "40px",
        "rte-medium-h2": "36px",
        "rte-medium-h3": "28px",
        "rte-medium-h4": "24px",
        "rte-medium-h5": "20px",
        "rte-medium-h6": "18px",
        "rte-small-h1": "40px",
        "rte-small-h2": "32px",
        "rte-small-h3": "28px",
        "rte-small-h4": "24px",
        "rte-small-h5": "18px",
        "rte-small-h6": "16px"
      },
      "fontFamily": {
        "header-large-xxLarge": "GothamSSmBold",
        "header-large-xLarge": "GothamSSmBold",
        "header-large-large": "GothamSSmBold",
        "header-large-medium": "GothamSSmBold",
        "header-large-small": "GothamSSmBold",
        "header-large-xSmall": "GothamSSmBold",
        "header-large-xSmall-underline": "GothamSSmBold",
        "header-large-xxSmall": "GothamSSmBold",
        "header-medium-xxLarge": "GothamSSmMedium",
        "header-medium-xLarge": "GothamSSmMedium",
        "header-medium-large": "GothamSSmMedium",
        "header-medium-medium": "GothamSSmMedium",
        "header-medium-small": "GothamSSmMedium",
        "header-medium-xSmall": "GothamSSmMedium",
        "header-medium-xSmall-underline": "GothamSSmMedium",
        "header-medium-xxSmall": "GothamSSmMedium",
        "header-small-xxLarge": "GothamSSmBook",
        "header-small-xLarge": "GothamSSmBook",
        "header-small-large": "GothamSSmBook",
        "header-small-medium": "GothamSSmBook",
        "header-small-small": "GothamSSmBook",
        "header-small-xSmall": "GothamSSmBook",
        "header-small-xSmall-underline": "GothamSSmBook",
        "header-small-xxSmall": "GothamSSmBook",
        "rte-large-h1": "GothamSSmBold",
        "rte-large-h2": "GothamSSmBold",
        "rte-large-h3": "GothamSSmBold",
        "rte-large-h4": "GothamSSmBold",
        "rte-large-h5": "GothamSSmBold",
        "rte-large-h6": "GothamSSmBold",
        "rte-medium-h1": "GothamSSmBold",
        "rte-medium-h2": "GothamSSmBold",
        "rte-medium-h3": "GothamSSmBold",
        "rte-medium-h4": "GothamSSmBold",
        "rte-medium-h5": "GothamSSmBold",
        "rte-medium-h6": "GothamSSmBold",
        "rte-small-h1": "GothamSSmBold",
        "rte-small-h2": "GothamSSmBold",
        "rte-small-h3": "GothamSSmBold",
        "rte-small-h4": "GothamSSmBold",
        "rte-small-h5": "GothamSSmBold",
        "rte-small-h6": "GothamSSmBold"
      },
      "fontWeight": {
        "header-large-xxLarge": "700",
        "header-large-xLarge": "700",
        "header-large-large": "700",
        "header-large-medium": "700",
        "header-large-small": "700",
        "header-large-xSmall": "700",
        "header-large-xSmall-underline": "700",
        "header-large-xxSmall": "700",
        "header-medium-xxLarge": "700",
        "header-medium-xLarge": "700",
        "header-medium-large": "700",
        "header-medium-medium": "700",
        "header-medium-small": "700",
        "header-medium-xSmall": "700",
        "header-medium-xSmall-underline": "700",
        "header-medium-xxSmall": "700",
        "header-small-xxLarge": "700",
        "header-small-xLarge": "700",
        "header-small-large": "700",
        "header-small-medium": "700",
        "header-small-small": "700",
        "header-small-xSmall": "700",
        "header-small-xSmall-underline": "700",
        "header-small-xxSmall": "700",
        "rte-large-h1": "700",
        "rte-large-h2": "700",
        "rte-large-h3": "700",
        "rte-large-h4": "700",
        "rte-large-h5": "700",
        "rte-large-h6": "700",
        "rte-medium-h1": "700",
        "rte-medium-h2": "700",
        "rte-medium-h3": "700",
        "rte-medium-h4": "700",
        "rte-medium-h5": "700",
        "rte-medium-h6": "700",
        "rte-small-h1": "700",
        "rte-small-h2": "700",
        "rte-small-h3": "700",
        "rte-small-h4": "700",
        "rte-small-h5": "700",
        "rte-small-h6": "700"
      },
      "lineHeight": {
        "header-large-xxLarge": "120%",
        "header-large-xLarge": "120%",
        "header-large-large": "120%",
        "header-large-medium": "120%",
        "header-large-small": "120%",
        "header-large-xSmall": "120%",
        "header-large-xSmall-underline": "120%",
        "header-large-xxSmall": "120%",
        "header-medium-xxLarge": "120%",
        "header-medium-xLarge": "120%",
        "header-medium-large": "120%",
        "header-medium-medium": "120%",
        "header-medium-small": "120%",
        "header-medium-xSmall": "120%",
        "header-medium-xSmall-underline": "120%",
        "header-medium-xxSmall": "120%",
        "header-small-xxLarge": "120%",
        "header-small-xLarge": "120%",
        "header-small-large": "120%",
        "header-small-medium": "120%",
        "header-small-small": "120%",
        "header-small-xSmall": "120%",
        "header-small-xSmall-underline": "120%",
        "header-small-xxSmall": "120%",
        "rte-large-h1": "120%",
        "rte-large-h2": "120%",
        "rte-large-h3": "120%",
        "rte-large-h4": "120%",
        "rte-large-h5": "120%",
        "rte-large-h6": "120%",
        "rte-medium-h1": "120%",
        "rte-medium-h2": "120%",
        "rte-medium-h3": "120%",
        "rte-medium-h4": "120%",
        "rte-medium-h5": "120%",
        "rte-medium-h6": "120%",
        "rte-small-h1": "120%",
        "rte-small-h2": "120%",
        "rte-small-h3": "120%",
        "rte-small-h4": "120%",
        "rte-small-h5": "120%",
        "rte-small-h6": "120%"
      },
      "letterSpacing": {
        "header-large-xxLarge": "100%",
        "header-large-xLarge": "100%",
        "header-large-large": "100%",
        "header-large-medium": "100%",
        "header-large-small": "100%",
        "header-large-xSmall": "100%",
        "header-large-xSmall-underline": "100%",
        "header-large-xxSmall": "100%",
        "header-medium-xxLarge": "100%",
        "header-medium-xLarge": "100%",
        "header-medium-large": "100%",
        "header-medium-medium": "100%",
        "header-medium-small": "100%",
        "header-medium-xSmall": "100%",
        "header-medium-xSmall-underline": "100%",
        "header-medium-xxSmall": "100%",
        "header-small-xxLarge": "100%",
        "header-small-xLarge": "100%",
        "header-small-large": "100%",
        "header-small-medium": "100%",
        "header-small-small": "100%",
        "header-small-xSmall": "100%",
        "header-small-xSmall-underline": "100%",
        "header-small-xxSmall": "100%",
        "rte-large-h1": "100%",
        "rte-large-h2": "100%",
        "rte-large-h3": "100%",
        "rte-large-h4": "100%",
        "rte-large-h5": "100%",
        "rte-large-h6": "100%",
        "rte-medium-h1": "100%",
        "rte-medium-h2": "100%",
        "rte-medium-h3": "100%",
        "rte-medium-h4": "100%",
        "rte-medium-h5": "100%",
        "rte-medium-h6": "100%",
        "rte-small-h1": "100%",
        "rte-small-h2": "100%",
        "rte-small-h3": "100%",
        "rte-small-h4": "100%",
        "rte-small-h5": "100%",
        "rte-small-h6": "100%"
      },
      "textTransform": {},
      "textDecoration": {
        "header-large-xxLarge": "none",
        "header-large-xLarge": "none",
        "header-large-large": "none",
        "header-large-medium": "none",
        "header-large-small": "none",
        "header-large-xSmall": "none",
        "header-large-xSmall-underline": "underline",
        "header-large-xxSmall": "none",
        "header-medium-xxLarge": "none",
        "header-medium-xLarge": "none",
        "header-medium-large": "none",
        "header-medium-medium": "none",
        "header-medium-small": "none",
        "header-medium-xSmall": "none",
        "header-medium-xSmall-underline": "underline",
        "header-medium-xxSmall": "none",
        "header-small-xxLarge": "none",
        "header-small-xLarge": "none",
        "header-small-large": "none",
        "header-small-medium": "none",
        "header-small-small": "none",
        "header-small-xSmall": "none",
        "header-small-xSmall-underline": "underline",
        "header-small-xxSmall": "none",
        "rte-large-h1": "none",
        "rte-large-h2": "none",
        "rte-large-h3": "none",
        "rte-large-h4": "none",
        "rte-large-h5": "none",
        "rte-large-h6": "underline",
        "rte-medium-h1": "none",
        "rte-medium-h2": "none",
        "rte-medium-h3": "none",
        "rte-medium-h4": "none",
        "rte-medium-h5": "none",
        "rte-medium-h6": "underline",
        "rte-small-h1": "none",
        "rte-small-h2": "none",
        "rte-small-h3": "none",
        "rte-small-h4": "none",
        "rte-small-h5": "none",
        "rte-small-h6": "underline"
      }
    },
    "AutanDefense": {
      "fontSize": {
        "header-large-xxLarge": "64px",
        "header-large-xLarge": "56px",
        "header-large-large": "48px",
        "header-large-medium": "36px",
        "header-large-small": "32px",
        "header-large-xSmall": "24px",
        "header-large-xSmall-underline": "24px",
        "header-large-xxSmall": "20px",
        "header-medium-xxLarge": "48px",
        "header-medium-xLarge": "40px",
        "header-medium-large": "36px",
        "header-medium-medium": "28px",
        "header-medium-small": "24px",
        "header-medium-xSmall": "20px",
        "header-medium-xSmall-underline": "20px",
        "header-medium-xxSmall": "18px",
        "header-small-xxLarge": "48px",
        "header-small-xLarge": "40px",
        "header-small-large": "32px",
        "header-small-medium": "28px",
        "header-small-small": "24px",
        "header-small-xSmall": "18px",
        "header-small-xSmall-underline": "18px",
        "header-small-xxSmall": "16px",
        "rte-large-h1": "48px",
        "rte-large-h2": "40px",
        "rte-large-h3": "36px",
        "rte-large-h4": "32px",
        "rte-large-h5": "24px",
        "rte-large-h6": "20px",
        "rte-medium-h1": "40px",
        "rte-medium-h2": "36px",
        "rte-medium-h3": "28px",
        "rte-medium-h4": "24px",
        "rte-medium-h5": "20px",
        "rte-medium-h6": "18px",
        "rte-small-h1": "40px",
        "rte-small-h2": "32px",
        "rte-small-h3": "28px",
        "rte-small-h4": "24px",
        "rte-small-h5": "18px",
        "rte-small-h6": "16px"
      },
      "fontFamily": {
        "header-large-xxLarge": "Poppins",
        "header-large-xLarge": "Poppins",
        "header-large-large": "Poppins",
        "header-large-medium": "Poppins",
        "header-large-small": "Poppins",
        "header-large-xSmall": "Poppins",
        "header-large-xSmall-underline": "Poppins",
        "header-large-xxSmall": "Poppins",
        "header-medium-xxLarge": "Poppins",
        "header-medium-xLarge": "Poppins",
        "header-medium-large": "Poppins",
        "header-medium-medium": "Poppins",
        "header-medium-small": "Poppins",
        "header-medium-xSmall": "Poppins",
        "header-medium-xSmall-underline": "Poppins",
        "header-medium-xxSmall": "Poppins",
        "header-small-xxLarge": "Poppins",
        "header-small-xLarge": "Poppins",
        "header-small-large": "Poppins",
        "header-small-medium": "Poppins",
        "header-small-small": "Poppins",
        "header-small-xSmall": "Poppins",
        "header-small-xSmall-underline": "Poppins",
        "header-small-xxSmall": "Poppins",
        "rte-large-h1": "Poppins",
        "rte-large-h2": "Poppins",
        "rte-large-h3": "Poppins",
        "rte-large-h4": "Poppins",
        "rte-large-h5": "Poppins",
        "rte-large-h6": "Poppins",
        "rte-medium-h1": "Poppins",
        "rte-medium-h2": "Poppins",
        "rte-medium-h3": "Poppins",
        "rte-medium-h4": "Poppins",
        "rte-medium-h5": "Poppins",
        "rte-medium-h6": "Poppins",
        "rte-small-h1": "Poppins",
        "rte-small-h2": "Poppins",
        "rte-small-h3": "Poppins",
        "rte-small-h4": "Poppins",
        "rte-small-h5": "Poppins",
        "rte-small-h6": "Poppins"
      },
      "fontWeight": {
        "header-large-xxLarge": "800",
        "header-large-xLarge": "800",
        "header-large-large": "800",
        "header-large-medium": "800",
        "header-large-small": "800",
        "header-large-xSmall": "700",
        "header-large-xSmall-underline": "700",
        "header-large-xxSmall": "600",
        "header-medium-xxLarge": "800",
        "header-medium-xLarge": "800",
        "header-medium-large": "800",
        "header-medium-medium": "800",
        "header-medium-small": "800",
        "header-medium-xSmall": "800",
        "header-medium-xSmall-underline": "800",
        "header-medium-xxSmall": "700",
        "header-small-xxLarge": "800",
        "header-small-xLarge": "800",
        "header-small-large": "800",
        "header-small-medium": "800",
        "header-small-small": "800",
        "header-small-xSmall": "700",
        "header-small-xSmall-underline": "700",
        "header-small-xxSmall": "700",
        "rte-large-h1": "800",
        "rte-large-h2": "800",
        "rte-large-h3": "800",
        "rte-large-h4": "800",
        "rte-large-h5": "800",
        "rte-large-h6": "800",
        "rte-medium-h1": "800",
        "rte-medium-h2": "800",
        "rte-medium-h3": "800",
        "rte-medium-h4": "800",
        "rte-medium-h5": "800",
        "rte-medium-h6": "800",
        "rte-small-h1": "800",
        "rte-small-h2": "800",
        "rte-small-h3": "800",
        "rte-small-h4": "800",
        "rte-small-h5": "800",
        "rte-small-h6": "800"
      },
      "lineHeight": {
        "header-large-xxLarge": "120%",
        "header-large-xLarge": "120%",
        "header-large-large": "120%",
        "header-large-medium": "120%",
        "header-large-small": "120%",
        "header-large-xSmall": "120%",
        "header-large-xSmall-underline": "120%",
        "header-large-xxSmall": "120%",
        "header-medium-xxLarge": "120%",
        "header-medium-xLarge": "120%",
        "header-medium-large": "120%",
        "header-medium-medium": "120%",
        "header-medium-small": "120%",
        "header-medium-xSmall": "120%",
        "header-medium-xSmall-underline": "120%",
        "header-medium-xxSmall": "120%",
        "header-small-xxLarge": "120%",
        "header-small-xLarge": "120%",
        "header-small-large": "120%",
        "header-small-medium": "120%",
        "header-small-small": "120%",
        "header-small-xSmall": "120%",
        "header-small-xSmall-underline": "120%",
        "header-small-xxSmall": "120%",
        "rte-large-h1": "120%",
        "rte-large-h2": "120%",
        "rte-large-h3": "120%",
        "rte-large-h4": "120%",
        "rte-large-h5": "120%",
        "rte-large-h6": "120%",
        "rte-medium-h1": "120%",
        "rte-medium-h2": "120%",
        "rte-medium-h3": "120%",
        "rte-medium-h4": "120%",
        "rte-medium-h5": "120%",
        "rte-medium-h6": "120%",
        "rte-small-h1": "120%",
        "rte-small-h2": "120%",
        "rte-small-h3": "120%",
        "rte-small-h4": "120%",
        "rte-small-h5": "120%",
        "rte-small-h6": "120%"
      },
      "letterSpacing": {
        "header-large-xxLarge": "100%",
        "header-large-xLarge": "100%",
        "header-large-large": "100%",
        "header-large-medium": "100%",
        "header-large-small": "100%",
        "header-large-xSmall": "100%",
        "header-large-xSmall-underline": "100%",
        "header-large-xxSmall": "100%",
        "header-medium-xxLarge": "100%",
        "header-medium-xLarge": "100%",
        "header-medium-large": "100%",
        "header-medium-medium": "100%",
        "header-medium-small": "100%",
        "header-medium-xSmall": "100%",
        "header-medium-xSmall-underline": "100%",
        "header-medium-xxSmall": "100%",
        "header-small-xxLarge": "100%",
        "header-small-xLarge": "100%",
        "header-small-large": "100%",
        "header-small-medium": "100%",
        "header-small-small": "100%",
        "header-small-xSmall": "100%",
        "header-small-xSmall-underline": "100%",
        "header-small-xxSmall": "100%",
        "rte-large-h1": "100%",
        "rte-large-h2": "100%",
        "rte-large-h3": "100%",
        "rte-large-h4": "100%",
        "rte-large-h5": "100%",
        "rte-large-h6": "100%",
        "rte-medium-h1": "100%",
        "rte-medium-h2": "100%",
        "rte-medium-h3": "100%",
        "rte-medium-h4": "100%",
        "rte-medium-h5": "100%",
        "rte-medium-h6": "100%",
        "rte-small-h1": "100%",
        "rte-small-h2": "100%",
        "rte-small-h3": "100%",
        "rte-small-h4": "100%",
        "rte-small-h5": "100%",
        "rte-small-h6": "100%"
      },
      "textTransform": {},
      "textDecoration": {
        "header-large-xxLarge": "none",
        "header-large-xLarge": "none",
        "header-large-large": "none",
        "header-large-medium": "none",
        "header-large-small": "none",
        "header-large-xSmall": "none",
        "header-large-xSmall-underline": "underline",
        "header-large-xxSmall": "none",
        "header-medium-xxLarge": "none",
        "header-medium-xLarge": "none",
        "header-medium-large": "none",
        "header-medium-medium": "none",
        "header-medium-small": "none",
        "header-medium-xSmall": "none",
        "header-medium-xSmall-underline": "underline",
        "header-medium-xxSmall": "none",
        "header-small-xxLarge": "none",
        "header-small-xLarge": "none",
        "header-small-large": "none",
        "header-small-medium": "none",
        "header-small-small": "none",
        "header-small-xSmall": "none",
        "header-small-xSmall-underline": "underline",
        "header-small-xxSmall": "none",
        "rte-large-h1": "none",
        "rte-large-h2": "none",
        "rte-large-h3": "none",
        "rte-large-h4": "none",
        "rte-large-h5": "none",
        "rte-large-h6": "underline",
        "rte-medium-h1": "none",
        "rte-medium-h2": "none",
        "rte-medium-h3": "none",
        "rte-medium-h4": "none",
        "rte-medium-h5": "none",
        "rte-medium-h6": "underline",
        "rte-small-h1": "none",
        "rte-small-h2": "none",
        "rte-small-h3": "none",
        "rte-small-h4": "none",
        "rte-small-h5": "none",
        "rte-small-h6": "underline"
      }
    },
    "OffDefense": {
      "fontSize": {
        "header-large-xxLarge": "64px",
        "header-large-xLarge": "56px",
        "header-large-large": "48px",
        "header-large-medium": "36px",
        "header-large-small": "32px",
        "header-large-xSmall": "24px",
        "header-large-xSmall-underline": "24px",
        "header-large-xxSmall": "20px",
        "header-medium-xxLarge": "48px",
        "header-medium-xLarge": "40px",
        "header-medium-large": "36px",
        "header-medium-medium": "28px",
        "header-medium-small": "24px",
        "header-medium-xSmall": "20px",
        "header-medium-xSmall-underline": "20px",
        "header-medium-xxSmall": "18px",
        "header-small-xxLarge": "48px",
        "header-small-xLarge": "40px",
        "header-small-large": "32px",
        "header-small-medium": "28px",
        "header-small-small": "24px",
        "header-small-xSmall": "18px",
        "header-small-xSmall-underline": "18px",
        "header-small-xxSmall": "16px",
        "rte-large-h1": "48px",
        "rte-large-h2": "40px",
        "rte-large-h3": "36px",
        "rte-large-h4": "32px",
        "rte-large-h5": "24px",
        "rte-large-h6": "20px",
        "rte-medium-h1": "40px",
        "rte-medium-h2": "36px",
        "rte-medium-h3": "28px",
        "rte-medium-h4": "24px",
        "rte-medium-h5": "20px",
        "rte-medium-h6": "18px",
        "rte-small-h1": "40px",
        "rte-small-h2": "32px",
        "rte-small-h3": "28px",
        "rte-small-h4": "24px",
        "rte-small-h5": "18px",
        "rte-small-h6": "16px"
      },
      "fontFamily": {
        "header-large-xxLarge": "Poppins",
        "header-large-xLarge": "Poppins",
        "header-large-large": "Poppins",
        "header-large-medium": "Poppins",
        "header-large-small": "Poppins",
        "header-large-xSmall": "Poppins",
        "header-large-xSmall-underline": "Poppins",
        "header-large-xxSmall": "Poppins",
        "header-medium-xxLarge": "Poppins",
        "header-medium-xLarge": "Poppins",
        "header-medium-large": "Poppins",
        "header-medium-medium": "Poppins",
        "header-medium-small": "Poppins",
        "header-medium-xSmall": "Poppins",
        "header-medium-xSmall-underline": "Poppins",
        "header-medium-xxSmall": "Poppins",
        "header-small-xxLarge": "Poppins",
        "header-small-xLarge": "Poppins",
        "header-small-large": "Poppins",
        "header-small-medium": "Poppins",
        "header-small-small": "Poppins",
        "header-small-xSmall": "Poppins",
        "header-small-xSmall-underline": "Poppins",
        "header-small-xxSmall": "Poppins",
        "rte-large-h1": "Poppins",
        "rte-large-h2": "Poppins",
        "rte-large-h3": "Poppins",
        "rte-large-h4": "Poppins",
        "rte-large-h5": "Poppins",
        "rte-large-h6": "Poppins",
        "rte-medium-h1": "Poppins",
        "rte-medium-h2": "Poppins",
        "rte-medium-h3": "Poppins",
        "rte-medium-h4": "Poppins",
        "rte-medium-h5": "Poppins",
        "rte-medium-h6": "Poppins",
        "rte-small-h1": "Poppins",
        "rte-small-h2": "Poppins",
        "rte-small-h3": "Poppins",
        "rte-small-h4": "Poppins",
        "rte-small-h5": "Poppins",
        "rte-small-h6": "Poppins"
      },
      "fontWeight": {
        "header-large-xxLarge": "800",
        "header-large-xLarge": "800",
        "header-large-large": "800",
        "header-large-medium": "800",
        "header-large-small": "800",
        "header-large-xSmall": "700",
        "header-large-xSmall-underline": "700",
        "header-large-xxSmall": "600",
        "header-medium-xxLarge": "800",
        "header-medium-xLarge": "800",
        "header-medium-large": "800",
        "header-medium-medium": "800",
        "header-medium-small": "800",
        "header-medium-xSmall": "800",
        "header-medium-xSmall-underline": "800",
        "header-medium-xxSmall": "700",
        "header-small-xxLarge": "800",
        "header-small-xLarge": "800",
        "header-small-large": "800",
        "header-small-medium": "800",
        "header-small-small": "800",
        "header-small-xSmall": "700",
        "header-small-xSmall-underline": "700",
        "header-small-xxSmall": "700",
        "rte-large-h1": "800",
        "rte-large-h2": "800",
        "rte-large-h3": "800",
        "rte-large-h4": "800",
        "rte-large-h5": "800",
        "rte-large-h6": "800",
        "rte-medium-h1": "800",
        "rte-medium-h2": "800",
        "rte-medium-h3": "800",
        "rte-medium-h4": "800",
        "rte-medium-h5": "800",
        "rte-medium-h6": "800",
        "rte-small-h1": "800",
        "rte-small-h2": "800",
        "rte-small-h3": "800",
        "rte-small-h4": "800",
        "rte-small-h5": "800",
        "rte-small-h6": "800"
      },
      "lineHeight": {
        "header-large-xxLarge": "120%",
        "header-large-xLarge": "120%",
        "header-large-large": "120%",
        "header-large-medium": "120%",
        "header-large-small": "120%",
        "header-large-xSmall": "120%",
        "header-large-xSmall-underline": "120%",
        "header-large-xxSmall": "120%",
        "header-medium-xxLarge": "120%",
        "header-medium-xLarge": "120%",
        "header-medium-large": "120%",
        "header-medium-medium": "120%",
        "header-medium-small": "120%",
        "header-medium-xSmall": "120%",
        "header-medium-xSmall-underline": "120%",
        "header-medium-xxSmall": "120%",
        "header-small-xxLarge": "120%",
        "header-small-xLarge": "120%",
        "header-small-large": "120%",
        "header-small-medium": "120%",
        "header-small-small": "120%",
        "header-small-xSmall": "120%",
        "header-small-xSmall-underline": "120%",
        "header-small-xxSmall": "120%",
        "rte-large-h1": "120%",
        "rte-large-h2": "120%",
        "rte-large-h3": "120%",
        "rte-large-h4": "120%",
        "rte-large-h5": "120%",
        "rte-large-h6": "120%",
        "rte-medium-h1": "120%",
        "rte-medium-h2": "120%",
        "rte-medium-h3": "120%",
        "rte-medium-h4": "120%",
        "rte-medium-h5": "120%",
        "rte-medium-h6": "120%",
        "rte-small-h1": "120%",
        "rte-small-h2": "120%",
        "rte-small-h3": "120%",
        "rte-small-h4": "120%",
        "rte-small-h5": "120%",
        "rte-small-h6": "120%"
      },
      "letterSpacing": {
        "header-large-xxLarge": "100%",
        "header-large-xLarge": "100%",
        "header-large-large": "100%",
        "header-large-medium": "100%",
        "header-large-small": "100%",
        "header-large-xSmall": "100%",
        "header-large-xSmall-underline": "100%",
        "header-large-xxSmall": "100%",
        "header-medium-xxLarge": "100%",
        "header-medium-xLarge": "100%",
        "header-medium-large": "100%",
        "header-medium-medium": "100%",
        "header-medium-small": "100%",
        "header-medium-xSmall": "100%",
        "header-medium-xSmall-underline": "100%",
        "header-medium-xxSmall": "100%",
        "header-small-xxLarge": "100%",
        "header-small-xLarge": "100%",
        "header-small-large": "100%",
        "header-small-medium": "100%",
        "header-small-small": "100%",
        "header-small-xSmall": "100%",
        "header-small-xSmall-underline": "100%",
        "header-small-xxSmall": "100%",
        "rte-large-h1": "100%",
        "rte-large-h2": "100%",
        "rte-large-h3": "100%",
        "rte-large-h4": "100%",
        "rte-large-h5": "100%",
        "rte-large-h6": "100%",
        "rte-medium-h1": "100%",
        "rte-medium-h2": "100%",
        "rte-medium-h3": "100%",
        "rte-medium-h4": "100%",
        "rte-medium-h5": "100%",
        "rte-medium-h6": "100%",
        "rte-small-h1": "100%",
        "rte-small-h2": "100%",
        "rte-small-h3": "100%",
        "rte-small-h4": "100%",
        "rte-small-h5": "100%",
        "rte-small-h6": "100%"
      },
      "textTransform": {},
      "textDecoration": {
        "header-large-xxLarge": "none",
        "header-large-xLarge": "none",
        "header-large-large": "none",
        "header-large-medium": "none",
        "header-large-small": "none",
        "header-large-xSmall": "none",
        "header-large-xSmall-underline": "underline",
        "header-large-xxSmall": "none",
        "header-medium-xxLarge": "none",
        "header-medium-xLarge": "none",
        "header-medium-large": "none",
        "header-medium-medium": "none",
        "header-medium-small": "none",
        "header-medium-xSmall": "none",
        "header-medium-xSmall-underline": "underline",
        "header-medium-xxSmall": "none",
        "header-small-xxLarge": "none",
        "header-small-xLarge": "none",
        "header-small-large": "none",
        "header-small-medium": "none",
        "header-small-small": "none",
        "header-small-xSmall": "none",
        "header-small-xSmall-underline": "underline",
        "header-small-xxSmall": "none",
        "rte-large-h1": "none",
        "rte-large-h2": "none",
        "rte-large-h3": "none",
        "rte-large-h4": "none",
        "rte-large-h5": "none",
        "rte-large-h6": "underline",
        "rte-medium-h1": "none",
        "rte-medium-h2": "none",
        "rte-medium-h3": "none",
        "rte-medium-h4": "none",
        "rte-medium-h5": "none",
        "rte-medium-h6": "underline",
        "rte-small-h1": "none",
        "rte-small-h2": "none",
        "rte-small-h3": "none",
        "rte-small-h4": "none",
        "rte-small-h5": "none",
        "rte-small-h6": "underline"
      }
    },
    "Glade": {
      "fontSize": {
        "header-large-xxLarge": "64px",
        "header-large-xLarge": "56px",
        "header-large-large": "48px",
        "header-large-medium": "36px",
        "header-large-small": "32px",
        "header-large-xSmall": "24px",
        "header-large-xSmall-underline": "24px",
        "header-large-xxSmall": "20px",
        "header-medium-xxLarge": "48px",
        "header-medium-xLarge": "40px",
        "header-medium-large": "36px",
        "header-medium-medium": "28px",
        "header-medium-small": "24px",
        "header-medium-xSmall": "20px",
        "header-medium-xSmall-underline": "20px",
        "header-medium-xxSmall": "18px",
        "header-small-xxLarge": "48px",
        "header-small-xLarge": "40px",
        "header-small-large": "32px",
        "header-small-medium": "28px",
        "header-small-small": "24px",
        "header-small-xSmall": "18px",
        "header-small-xSmall-underline": "18px",
        "header-small-xxSmall": "16px",
        "rte-large-h1": "48px",
        "rte-large-h2": "40px",
        "rte-large-h3": "36px",
        "rte-large-h4": "32px",
        "rte-large-h5": "24px",
        "rte-large-h6": "20px",
        "rte-medium-h1": "40px",
        "rte-medium-h2": "36px",
        "rte-medium-h3": "28px",
        "rte-medium-h4": "24px",
        "rte-medium-h5": "20px",
        "rte-medium-h6": "18px",
        "rte-small-h1": "40px",
        "rte-small-h2": "32px",
        "rte-small-h3": "28px",
        "rte-small-h4": "24px",
        "rte-small-h5": "18px",
        "rte-small-h6": "16px"
      },
      "fontFamily": {
        "header-large-xxLarge": "ContaxPro75Bold",
        "header-large-xLarge": "ContaxPro75Bold",
        "header-large-large": "ContaxPro75Bold",
        "header-large-medium": "ContaxPro75Bold",
        "header-large-small": "ContaxPro75Bold",
        "header-large-xSmall": "ContaxPro75Bold",
        "header-large-xSmall-underline": "ContaxPro75Bold",
        "header-large-xxSmall": "ContaxPro75Bold",
        "header-medium-xxLarge": "ContaxPro75Bold",
        "header-medium-xLarge": "ContaxPro75Bold",
        "header-medium-large": "ContaxPro75Bold",
        "header-medium-medium": "ContaxPro75Bold",
        "header-medium-small": "ContaxPro75Bold",
        "header-medium-xSmall": "ContaxPro75Bold",
        "header-medium-xSmall-underline": "ContaxPro75Bold",
        "header-medium-xxSmall": "ContaxPro75Bold",
        "header-small-xxLarge": "ContaxPro75Bold",
        "header-small-xLarge": "ContaxPro75Bold",
        "header-small-large": "ContaxPro75Bold",
        "header-small-medium": "ContaxPro75Bold",
        "header-small-small": "ContaxPro75Bold",
        "header-small-xSmall": "ContaxPro75Bold",
        "header-small-xSmall-underline": "ContaxPro75Bold",
        "header-small-xxSmall": "ContaxPro75Bold",
        "rte-large-h1": "ContaxPro75Bold",
        "rte-large-h2": "ContaxPro75Bold",
        "rte-large-h3": "ContaxPro75Bold",
        "rte-large-h4": "ContaxPro75Bold",
        "rte-large-h5": "ContaxPro75Bold",
        "rte-large-h6": "ContaxPro75Bold",
        "rte-medium-h1": "ContaxPro75Bold",
        "rte-medium-h2": "ContaxPro75Bold",
        "rte-medium-h3": "ContaxPro75Bold",
        "rte-medium-h4": "ContaxPro75Bold",
        "rte-medium-h5": "ContaxPro75Bold",
        "rte-medium-h6": "ContaxPro75Bold",
        "rte-small-h1": "ContaxPro75Bold",
        "rte-small-h2": "ContaxPro75Bold",
        "rte-small-h3": "ContaxPro75Bold",
        "rte-small-h4": "ContaxPro75Bold",
        "rte-small-h5": "ContaxPro75Bold",
        "rte-small-h6": "ContaxPro75Bold"
      },
      "fontWeight": {
        "header-large-xxLarge": "700",
        "header-large-xLarge": "700",
        "header-large-large": "700",
        "header-large-medium": "700",
        "header-large-small": "700",
        "header-large-xSmall": "700",
        "header-large-xSmall-underline": "700",
        "header-large-xxSmall": "700",
        "header-medium-xxLarge": "700",
        "header-medium-xLarge": "700",
        "header-medium-large": "700",
        "header-medium-medium": "700",
        "header-medium-small": "700",
        "header-medium-xSmall": "700",
        "header-medium-xSmall-underline": "700",
        "header-medium-xxSmall": "700",
        "header-small-xxLarge": "700",
        "header-small-xLarge": "700",
        "header-small-large": "700",
        "header-small-medium": "700",
        "header-small-small": "700",
        "header-small-xSmall": "700",
        "header-small-xSmall-underline": "700",
        "header-small-xxSmall": "700",
        "rte-large-h1": "700",
        "rte-large-h2": "700",
        "rte-large-h3": "700",
        "rte-large-h4": "700",
        "rte-large-h5": "700",
        "rte-large-h6": "700",
        "rte-medium-h1": "700",
        "rte-medium-h2": "700",
        "rte-medium-h3": "700",
        "rte-medium-h4": "700",
        "rte-medium-h5": "700",
        "rte-medium-h6": "700",
        "rte-small-h1": "700",
        "rte-small-h2": "700",
        "rte-small-h3": "700",
        "rte-small-h4": "700",
        "rte-small-h5": "700",
        "rte-small-h6": "700"
      },
      "lineHeight": {
        "header-large-xxLarge": "120%",
        "header-large-xLarge": "120%",
        "header-large-large": "120%",
        "header-large-medium": "120%",
        "header-large-small": "120%",
        "header-large-xSmall": "120%",
        "header-large-xSmall-underline": "120%",
        "header-large-xxSmall": "120%",
        "header-medium-xxLarge": "120%",
        "header-medium-xLarge": "120%",
        "header-medium-large": "120%",
        "header-medium-medium": "120%",
        "header-medium-small": "120%",
        "header-medium-xSmall": "120%",
        "header-medium-xSmall-underline": "120%",
        "header-medium-xxSmall": "120%",
        "header-small-xxLarge": "120%",
        "header-small-xLarge": "120%",
        "header-small-large": "120%",
        "header-small-medium": "120%",
        "header-small-small": "120%",
        "header-small-xSmall": "120%",
        "header-small-xSmall-underline": "120%",
        "header-small-xxSmall": "120%",
        "rte-large-h1": "120%",
        "rte-large-h2": "120%",
        "rte-large-h3": "120%",
        "rte-large-h4": "120%",
        "rte-large-h5": "120%",
        "rte-large-h6": "120%",
        "rte-medium-h1": "120%",
        "rte-medium-h2": "120%",
        "rte-medium-h3": "120%",
        "rte-medium-h4": "120%",
        "rte-medium-h5": "120%",
        "rte-medium-h6": "120%",
        "rte-small-h1": "120%",
        "rte-small-h2": "120%",
        "rte-small-h3": "120%",
        "rte-small-h4": "120%",
        "rte-small-h5": "120%",
        "rte-small-h6": "120%"
      },
      "letterSpacing": {
        "header-large-xxLarge": "100%",
        "header-large-xLarge": "100%",
        "header-large-large": "100%",
        "header-large-medium": "100%",
        "header-large-small": "100%",
        "header-large-xSmall": "100%",
        "header-large-xSmall-underline": "100%",
        "header-large-xxSmall": "100%",
        "header-medium-xxLarge": "100%",
        "header-medium-xLarge": "100%",
        "header-medium-large": "100%",
        "header-medium-medium": "100%",
        "header-medium-small": "100%",
        "header-medium-xSmall": "100%",
        "header-medium-xSmall-underline": "100%",
        "header-medium-xxSmall": "100%",
        "header-small-xxLarge": "100%",
        "header-small-xLarge": "100%",
        "header-small-large": "100%",
        "header-small-medium": "100%",
        "header-small-small": "100%",
        "header-small-xSmall": "100%",
        "header-small-xSmall-underline": "100%",
        "header-small-xxSmall": "100%",
        "rte-large-h1": "100%",
        "rte-large-h2": "100%",
        "rte-large-h3": "100%",
        "rte-large-h4": "100%",
        "rte-large-h5": "100%",
        "rte-large-h6": "100%",
        "rte-medium-h1": "100%",
        "rte-medium-h2": "100%",
        "rte-medium-h3": "100%",
        "rte-medium-h4": "100%",
        "rte-medium-h5": "100%",
        "rte-medium-h6": "100%",
        "rte-small-h1": "100%",
        "rte-small-h2": "100%",
        "rte-small-h3": "100%",
        "rte-small-h4": "100%",
        "rte-small-h5": "100%",
        "rte-small-h6": "100%"
      },
      "textTransform": {},
      "textDecoration": {
        "header-large-xxLarge": "none",
        "header-large-xLarge": "none",
        "header-large-large": "none",
        "header-large-medium": "none",
        "header-large-small": "none",
        "header-large-xSmall": "none",
        "header-large-xSmall-underline": "underline",
        "header-large-xxSmall": "none",
        "header-medium-xxLarge": "none",
        "header-medium-xLarge": "none",
        "header-medium-large": "none",
        "header-medium-medium": "none",
        "header-medium-small": "none",
        "header-medium-xSmall": "none",
        "header-medium-xSmall-underline": "underline",
        "header-medium-xxSmall": "none",
        "header-small-xxLarge": "none",
        "header-small-xLarge": "none",
        "header-small-large": "none",
        "header-small-medium": "none",
        "header-small-small": "none",
        "header-small-xSmall": "none",
        "header-small-xSmall-underline": "underline",
        "header-small-xxSmall": "none",
        "rte-large-h1": "none",
        "rte-large-h2": "none",
        "rte-large-h3": "none",
        "rte-large-h4": "none",
        "rte-large-h5": "none",
        "rte-large-h6": "underline",
        "rte-medium-h1": "none",
        "rte-medium-h2": "none",
        "rte-medium-h3": "none",
        "rte-medium-h4": "none",
        "rte-medium-h5": "none",
        "rte-medium-h6": "underline",
        "rte-small-h1": "none",
        "rte-small-h2": "none",
        "rte-small-h3": "none",
        "rte-small-h4": "none",
        "rte-small-h5": "none",
        "rte-small-h6": "underline"
      }
    },
    "Raid": {
      "fontSize": {
        "header-large-xxLarge": "64px",
        "header-large-xLarge": "56px",
        "header-large-large": "48px",
        "header-large-medium": "36px",
        "header-large-small": "32px",
        "header-large-xSmall": "24px",
        "header-large-xSmall-underline": "24px",
        "header-large-xxSmall": "20px",
        "header-medium-xxLarge": "48px",
        "header-medium-xLarge": "40px",
        "header-medium-large": "36px",
        "header-medium-medium": "28px",
        "header-medium-small": "24px",
        "header-medium-xSmall": "20px",
        "header-medium-xSmall-underline": "20px",
        "header-medium-xxSmall": "18px",
        "header-small-xxLarge": "48px",
        "header-small-xLarge": "40px",
        "header-small-large": "32px",
        "header-small-medium": "28px",
        "header-small-small": "24px",
        "header-small-xSmall": "18px",
        "header-small-xSmall-underline": "18px",
        "header-small-xxSmall": "16px",
        "rte-large-h1": "48px",
        "rte-large-h2": "40px",
        "rte-large-h3": "36px",
        "rte-large-h4": "32px",
        "rte-large-h5": "24px",
        "rte-large-h6": "20px",
        "rte-medium-h1": "40px",
        "rte-medium-h2": "36px",
        "rte-medium-h3": "28px",
        "rte-medium-h4": "24px",
        "rte-medium-h5": "20px",
        "rte-medium-h6": "18px",
        "rte-small-h1": "40px",
        "rte-small-h2": "32px",
        "rte-small-h3": "28px",
        "rte-small-h4": "24px",
        "rte-small-h5": "18px",
        "rte-small-h6": "16px"
      },
      "fontFamily": {
        "header-large-xxLarge": "Avenir95Black",
        "header-large-xLarge": "Avenir95Black",
        "header-large-large": "Avenir95Black",
        "header-large-medium": "Avenir95Black",
        "header-large-small": "Avenir95Black",
        "header-large-xSmall": "Avenir95Black",
        "header-large-xSmall-underline": "Avenir95Black",
        "header-large-xxSmall": "Avenir95Black",
        "header-medium-xxLarge": "Avenir95Black",
        "header-medium-xLarge": "Avenir95Black",
        "header-medium-large": "Avenir95Black",
        "header-medium-medium": "Avenir95Black",
        "header-medium-small": "Avenir95Black",
        "header-medium-xSmall": "Avenir95Black",
        "header-medium-xSmall-underline": "Avenir95Black",
        "header-medium-xxSmall": "Avenir95Black",
        "header-small-xxLarge": "Avenir95Black",
        "header-small-xLarge": "Avenir95Black",
        "header-small-large": "Avenir95Black",
        "header-small-medium": "Avenir95Black",
        "header-small-small": "Avenir95Black",
        "header-small-xSmall": "Avenir95Black",
        "header-small-xSmall-underline": "Avenir95Black",
        "header-small-xxSmall": "Avenir95Black",
        "rte-large-h1": "Avenir95Black",
        "rte-large-h2": "Avenir95Black",
        "rte-large-h3": "Avenir95Black",
        "rte-large-h4": "Avenir95Black",
        "rte-large-h5": "Avenir95Black",
        "rte-large-h6": "Avenir95Black",
        "rte-medium-h1": "Avenir95Black",
        "rte-medium-h2": "Avenir95Black",
        "rte-medium-h3": "Avenir95Black",
        "rte-medium-h4": "Avenir95Black",
        "rte-medium-h5": "Avenir95Black",
        "rte-medium-h6": "Avenir95Black",
        "rte-small-h1": "Avenir95Black",
        "rte-small-h2": "Avenir95Black",
        "rte-small-h3": "Avenir95Black",
        "rte-small-h4": "Avenir95Black",
        "rte-small-h5": "Avenir95Black",
        "rte-small-h6": "Avenir95Black"
      },
      "fontWeight": {
        "header-large-xxLarge": "900",
        "header-large-xLarge": "900",
        "header-large-large": "900",
        "header-large-medium": "900",
        "header-large-small": "900",
        "header-large-xSmall": "900",
        "header-large-xSmall-underline": "900",
        "header-large-xxSmall": "900",
        "header-medium-xxLarge": "900",
        "header-medium-xLarge": "900",
        "header-medium-large": "900",
        "header-medium-medium": "900",
        "header-medium-small": "900",
        "header-medium-xSmall": "900",
        "header-medium-xSmall-underline": "900",
        "header-medium-xxSmall": "900",
        "header-small-xxLarge": "900",
        "header-small-xLarge": "900",
        "header-small-large": "900",
        "header-small-medium": "900",
        "header-small-small": "900",
        "header-small-xSmall": "900",
        "header-small-xSmall-underline": "900",
        "header-small-xxSmall": "900",
        "rte-large-h1": "900",
        "rte-large-h2": "900",
        "rte-large-h3": "900",
        "rte-large-h4": "900",
        "rte-large-h5": "900",
        "rte-large-h6": "900",
        "rte-medium-h1": "900",
        "rte-medium-h2": "900",
        "rte-medium-h3": "900",
        "rte-medium-h4": "900",
        "rte-medium-h5": "900",
        "rte-medium-h6": "900",
        "rte-small-h1": "900",
        "rte-small-h2": "900",
        "rte-small-h3": "900",
        "rte-small-h4": "900",
        "rte-small-h5": "900",
        "rte-small-h6": "900"
      },
      "lineHeight": {
        "header-large-xxLarge": "120%",
        "header-large-xLarge": "120%",
        "header-large-large": "120%",
        "header-large-medium": "120%",
        "header-large-small": "120%",
        "header-large-xSmall": "120%",
        "header-large-xSmall-underline": "120%",
        "header-large-xxSmall": "120%",
        "header-medium-xxLarge": "120%",
        "header-medium-xLarge": "120%",
        "header-medium-large": "120%",
        "header-medium-medium": "120%",
        "header-medium-small": "120%",
        "header-medium-xSmall": "120%",
        "header-medium-xSmall-underline": "120%",
        "header-medium-xxSmall": "120%",
        "header-small-xxLarge": "120%",
        "header-small-xLarge": "120%",
        "header-small-large": "120%",
        "header-small-medium": "120%",
        "header-small-small": "120%",
        "header-small-xSmall": "120%",
        "header-small-xSmall-underline": "120%",
        "header-small-xxSmall": "120%",
        "rte-large-h1": "120%",
        "rte-large-h2": "120%",
        "rte-large-h3": "120%",
        "rte-large-h4": "120%",
        "rte-large-h5": "120%",
        "rte-large-h6": "120%",
        "rte-medium-h1": "120%",
        "rte-medium-h2": "120%",
        "rte-medium-h3": "120%",
        "rte-medium-h4": "120%",
        "rte-medium-h5": "120%",
        "rte-medium-h6": "120%",
        "rte-small-h1": "120%",
        "rte-small-h2": "120%",
        "rte-small-h3": "120%",
        "rte-small-h4": "120%",
        "rte-small-h5": "120%",
        "rte-small-h6": "120%"
      },
      "letterSpacing": {
        "header-large-xxLarge": "100%",
        "header-large-xLarge": "100%",
        "header-large-large": "100%",
        "header-large-medium": "100%",
        "header-large-small": "100%",
        "header-large-xSmall": "100%",
        "header-large-xSmall-underline": "100%",
        "header-large-xxSmall": "100%",
        "header-medium-xxLarge": "100%",
        "header-medium-xLarge": "100%",
        "header-medium-large": "100%",
        "header-medium-medium": "100%",
        "header-medium-small": "100%",
        "header-medium-xSmall": "100%",
        "header-medium-xSmall-underline": "100%",
        "header-medium-xxSmall": "100%",
        "header-small-xxLarge": "100%",
        "header-small-xLarge": "100%",
        "header-small-large": "100%",
        "header-small-medium": "100%",
        "header-small-small": "100%",
        "header-small-xSmall": "100%",
        "header-small-xSmall-underline": "100%",
        "header-small-xxSmall": "100%",
        "rte-large-h1": "100%",
        "rte-large-h2": "100%",
        "rte-large-h3": "100%",
        "rte-large-h4": "100%",
        "rte-large-h5": "100%",
        "rte-large-h6": "100%",
        "rte-medium-h1": "100%",
        "rte-medium-h2": "100%",
        "rte-medium-h3": "100%",
        "rte-medium-h4": "100%",
        "rte-medium-h5": "100%",
        "rte-medium-h6": "100%",
        "rte-small-h1": "100%",
        "rte-small-h2": "100%",
        "rte-small-h3": "100%",
        "rte-small-h4": "100%",
        "rte-small-h5": "100%",
        "rte-small-h6": "100%"
      },
      "textTransform": {},
      "textDecoration": {
        "header-large-xxLarge": "none",
        "header-large-xLarge": "none",
        "header-large-large": "none",
        "header-large-medium": "none",
        "header-large-small": "none",
        "header-large-xSmall": "none",
        "header-large-xSmall-underline": "underline",
        "header-large-xxSmall": "none",
        "header-medium-xxLarge": "none",
        "header-medium-xLarge": "none",
        "header-medium-large": "none",
        "header-medium-medium": "none",
        "header-medium-small": "none",
        "header-medium-xSmall": "none",
        "header-medium-xSmall-underline": "underline",
        "header-medium-xxSmall": "none",
        "header-small-xxLarge": "none",
        "header-small-xLarge": "none",
        "header-small-large": "none",
        "header-small-medium": "none",
        "header-small-small": "none",
        "header-small-xSmall": "none",
        "header-small-xSmall-underline": "underline",
        "header-small-xxSmall": "none",
        "rte-large-h1": "none",
        "rte-large-h2": "none",
        "rte-large-h3": "none",
        "rte-large-h4": "none",
        "rte-large-h5": "none",
        "rte-large-h6": "underline",
        "rte-medium-h1": "none",
        "rte-medium-h2": "none",
        "rte-medium-h3": "none",
        "rte-medium-h4": "none",
        "rte-medium-h5": "none",
        "rte-medium-h6": "underline",
        "rte-small-h1": "none",
        "rte-small-h2": "none",
        "rte-small-h3": "none",
        "rte-small-h4": "none",
        "rte-small-h5": "none",
        "rte-small-h6": "underline"
      }
    },
    "Baygon": {
      "fontSize": {
        "header-large-xxLarge": "64px",
        "header-large-xLarge": "56px",
        "header-large-large": "48px",
        "header-large-medium": "36px",
        "header-large-small": "32px",
        "header-large-xSmall": "24px",
        "header-large-xSmall-underline": "24px",
        "header-large-xxSmall": "20px",
        "header-medium-xxLarge": "48px",
        "header-medium-xLarge": "40px",
        "header-medium-large": "36px",
        "header-medium-medium": "28px",
        "header-medium-small": "24px",
        "header-medium-xSmall": "20px",
        "header-medium-xSmall-underline": "20px",
        "header-medium-xxSmall": "18px",
        "header-small-xxLarge": "48px",
        "header-small-xLarge": "40px",
        "header-small-large": "32px",
        "header-small-medium": "28px",
        "header-small-small": "24px",
        "header-small-xSmall": "18px",
        "header-small-xSmall-underline": "18px",
        "header-small-xxSmall": "16px",
        "rte-large-h1": "48px",
        "rte-large-h2": "40px",
        "rte-large-h3": "36px",
        "rte-large-h4": "32px",
        "rte-large-h5": "24px",
        "rte-large-h6": "20px",
        "rte-medium-h1": "40px",
        "rte-medium-h2": "36px",
        "rte-medium-h3": "28px",
        "rte-medium-h4": "24px",
        "rte-medium-h5": "20px",
        "rte-medium-h6": "18px",
        "rte-small-h1": "40px",
        "rte-small-h2": "32px",
        "rte-small-h3": "28px",
        "rte-small-h4": "24px",
        "rte-small-h5": "18px",
        "rte-small-h6": "16px"
      },
      "fontFamily": {
        "header-large-xxLarge": "Avenir95Black",
        "header-large-xLarge": "Avenir95Black",
        "header-large-large": "Avenir95Black",
        "header-large-medium": "Avenir95Black",
        "header-large-small": "Avenir95Black",
        "header-large-xSmall": "Avenir95Black",
        "header-large-xSmall-underline": "Avenir95Black",
        "header-large-xxSmall": "Avenir95Black",
        "header-medium-xxLarge": "Avenir95Black",
        "header-medium-xLarge": "Avenir95Black",
        "header-medium-large": "Avenir95Black",
        "header-medium-medium": "Avenir95Black",
        "header-medium-small": "Avenir95Black",
        "header-medium-xSmall": "Avenir95Black",
        "header-medium-xSmall-underline": "Avenir95Black",
        "header-medium-xxSmall": "Avenir95Black",
        "header-small-xxLarge": "Avenir95Black",
        "header-small-xLarge": "Avenir95Black",
        "header-small-large": "Avenir95Black",
        "header-small-medium": "Avenir95Black",
        "header-small-small": "Avenir95Black",
        "header-small-xSmall": "Avenir95Black",
        "header-small-xSmall-underline": "Avenir95Black",
        "header-small-xxSmall": "Avenir95Black",
        "rte-large-h1": "Avenir95Black",
        "rte-large-h2": "Avenir95Black",
        "rte-large-h3": "Avenir95Black",
        "rte-large-h4": "Avenir95Black",
        "rte-large-h5": "Avenir95Black",
        "rte-large-h6": "Avenir95Black",
        "rte-medium-h1": "Avenir95Black",
        "rte-medium-h2": "Avenir95Black",
        "rte-medium-h3": "Avenir95Black",
        "rte-medium-h4": "Avenir95Black",
        "rte-medium-h5": "Avenir95Black",
        "rte-medium-h6": "Avenir95Black",
        "rte-small-h1": "Avenir95Black",
        "rte-small-h2": "Avenir95Black",
        "rte-small-h3": "Avenir95Black",
        "rte-small-h4": "Avenir95Black",
        "rte-small-h5": "Avenir95Black",
        "rte-small-h6": "Avenir95Black"
      },
      "fontWeight": {
        "header-large-xxLarge": "900",
        "header-large-xLarge": "900",
        "header-large-large": "900",
        "header-large-medium": "900",
        "header-large-small": "900",
        "header-large-xSmall": "900",
        "header-large-xSmall-underline": "900",
        "header-large-xxSmall": "900",
        "header-medium-xxLarge": "900",
        "header-medium-xLarge": "900",
        "header-medium-large": "900",
        "header-medium-medium": "900",
        "header-medium-small": "900",
        "header-medium-xSmall": "900",
        "header-medium-xSmall-underline": "900",
        "header-medium-xxSmall": "900",
        "header-small-xxLarge": "900",
        "header-small-xLarge": "900",
        "header-small-large": "900",
        "header-small-medium": "900",
        "header-small-small": "900",
        "header-small-xSmall": "900",
        "header-small-xSmall-underline": "900",
        "header-small-xxSmall": "900",
        "rte-large-h1": "700",
        "rte-large-h2": "700",
        "rte-large-h3": "700",
        "rte-large-h4": "700",
        "rte-large-h5": "700",
        "rte-large-h6": "700",
        "rte-medium-h1": "700",
        "rte-medium-h2": "700",
        "rte-medium-h3": "700",
        "rte-medium-h4": "700",
        "rte-medium-h5": "700",
        "rte-medium-h6": "700",
        "rte-small-h1": "700",
        "rte-small-h2": "700",
        "rte-small-h3": "700",
        "rte-small-h4": "700",
        "rte-small-h5": "700",
        "rte-small-h6": "700"
      },
      "lineHeight": {
        "header-large-xxLarge": "120%",
        "header-large-xLarge": "120%",
        "header-large-large": "120%",
        "header-large-medium": "120%",
        "header-large-small": "120%",
        "header-large-xSmall": "120%",
        "header-large-xSmall-underline": "120%",
        "header-large-xxSmall": "120%",
        "header-medium-xxLarge": "120%",
        "header-medium-xLarge": "120%",
        "header-medium-large": "120%",
        "header-medium-medium": "120%",
        "header-medium-small": "120%",
        "header-medium-xSmall": "120%",
        "header-medium-xSmall-underline": "120%",
        "header-medium-xxSmall": "120%",
        "header-small-xxLarge": "120%",
        "header-small-xLarge": "120%",
        "header-small-large": "120%",
        "header-small-medium": "120%",
        "header-small-small": "120%",
        "header-small-xSmall": "120%",
        "header-small-xSmall-underline": "120%",
        "header-small-xxSmall": "120%",
        "rte-large-h1": "120%",
        "rte-large-h2": "120%",
        "rte-large-h3": "120%",
        "rte-large-h4": "120%",
        "rte-large-h5": "120%",
        "rte-large-h6": "120%",
        "rte-medium-h1": "120%",
        "rte-medium-h2": "120%",
        "rte-medium-h3": "120%",
        "rte-medium-h4": "120%",
        "rte-medium-h5": "120%",
        "rte-medium-h6": "120%",
        "rte-small-h1": "120%",
        "rte-small-h2": "120%",
        "rte-small-h3": "120%",
        "rte-small-h4": "120%",
        "rte-small-h5": "120%",
        "rte-small-h6": "120%"
      },
      "letterSpacing": {
        "header-large-xxLarge": "100%",
        "header-large-xLarge": "100%",
        "header-large-large": "100%",
        "header-large-medium": "100%",
        "header-large-small": "100%",
        "header-large-xSmall": "100%",
        "header-large-xSmall-underline": "100%",
        "header-large-xxSmall": "100%",
        "header-medium-xxLarge": "100%",
        "header-medium-xLarge": "100%",
        "header-medium-large": "100%",
        "header-medium-medium": "100%",
        "header-medium-small": "100%",
        "header-medium-xSmall": "100%",
        "header-medium-xSmall-underline": "100%",
        "header-medium-xxSmall": "100%",
        "header-small-xxLarge": "100%",
        "header-small-xLarge": "100%",
        "header-small-large": "100%",
        "header-small-medium": "100%",
        "header-small-small": "100%",
        "header-small-xSmall": "100%",
        "header-small-xSmall-underline": "100%",
        "header-small-xxSmall": "100%",
        "rte-large-h1": "100%",
        "rte-large-h2": "100%",
        "rte-large-h3": "100%",
        "rte-large-h4": "100%",
        "rte-large-h5": "100%",
        "rte-large-h6": "100%",
        "rte-medium-h1": "100%",
        "rte-medium-h2": "100%",
        "rte-medium-h3": "100%",
        "rte-medium-h4": "100%",
        "rte-medium-h5": "100%",
        "rte-medium-h6": "100%",
        "rte-small-h1": "100%",
        "rte-small-h2": "100%",
        "rte-small-h3": "100%",
        "rte-small-h4": "100%",
        "rte-small-h5": "100%",
        "rte-small-h6": "100%"
      },
      "textTransform": {},
      "textDecoration": {
        "header-large-xxLarge": "none",
        "header-large-xLarge": "none",
        "header-large-large": "none",
        "header-large-medium": "none",
        "header-large-small": "none",
        "header-large-xSmall": "none",
        "header-large-xSmall-underline": "underline",
        "header-large-xxSmall": "none",
        "header-medium-xxLarge": "none",
        "header-medium-xLarge": "none",
        "header-medium-large": "none",
        "header-medium-medium": "none",
        "header-medium-small": "none",
        "header-medium-xSmall": "none",
        "header-medium-xSmall-underline": "underline",
        "header-medium-xxSmall": "none",
        "header-small-xxLarge": "none",
        "header-small-xLarge": "none",
        "header-small-large": "none",
        "header-small-medium": "none",
        "header-small-small": "none",
        "header-small-xSmall": "none",
        "header-small-xSmall-underline": "underline",
        "header-small-xxSmall": "none",
        "rte-large-h1": "none",
        "rte-large-h2": "none",
        "rte-large-h3": "none",
        "rte-large-h4": "none",
        "rte-large-h5": "none",
        "rte-large-h6": "underline",
        "rte-medium-h1": "none",
        "rte-medium-h2": "none",
        "rte-medium-h3": "none",
        "rte-medium-h4": "none",
        "rte-medium-h5": "none",
        "rte-medium-h6": "underline",
        "rte-small-h1": "none",
        "rte-small-h2": "none",
        "rte-small-h3": "none",
        "rte-small-h4": "none",
        "rte-small-h5": "none",
        "rte-small-h6": "underline"
      }
    },
    "Duck": {
      "fontSize": {
        "header-large-xxLarge": "64px",
        "header-large-xLarge": "56px",
        "header-large-large": "48px",
        "header-large-medium": "36px",
        "header-large-small": "32px",
        "header-large-xSmall": "24px",
        "header-large-xSmall-underline": "24px",
        "header-large-xxSmall": "20px",
        "header-medium-xxLarge": "48px",
        "header-medium-xLarge": "40px",
        "header-medium-large": "36px",
        "header-medium-medium": "28px",
        "header-medium-small": "24px",
        "header-medium-xSmall": "20px",
        "header-medium-xSmall-underline": "20px",
        "header-medium-xxSmall": "18px",
        "header-small-xxLarge": "48px",
        "header-small-xLarge": "40px",
        "header-small-large": "32px",
        "header-small-medium": "28px",
        "header-small-small": "24px",
        "header-small-xSmall": "18px",
        "header-small-xSmall-underline": "18px",
        "header-small-xxSmall": "16px",
        "rte-large-h1": "48px",
        "rte-large-h2": "40px",
        "rte-large-h3": "36px",
        "rte-large-h4": "32px",
        "rte-large-h5": "24px",
        "rte-large-h6": "20px",
        "rte-medium-h1": "40px",
        "rte-medium-h2": "36px",
        "rte-medium-h3": "28px",
        "rte-medium-h4": "24px",
        "rte-medium-h5": "20px",
        "rte-medium-h6": "18px",
        "rte-small-h1": "40px",
        "rte-small-h2": "32px",
        "rte-small-h3": "28px",
        "rte-small-h4": "24px",
        "rte-small-h5": "18px",
        "rte-small-h6": "16px"
      },
      "fontFamily": {
        "header-large-xxLarge": "GothamSSmBold",
        "header-large-xLarge": "GothamSSmBold",
        "header-large-large": "GothamSSmBold",
        "header-large-medium": "GothamSSmBold",
        "header-large-small": "GothamSSmBold",
        "header-large-xSmall": "GothamSSmBold",
        "header-large-xSmall-underline": "GothamSSmBold",
        "header-large-xxSmall": "GothamSSmBold",
        "header-medium-xxLarge": "GothamSSmMedium",
        "header-medium-xLarge": "GothamSSmMedium",
        "header-medium-large": "GothamSSmMedium",
        "header-medium-medium": "GothamSSmMedium",
        "header-medium-small": "GothamSSmMedium",
        "header-medium-xSmall": "GothamSSmMedium",
        "header-medium-xSmall-underline": "GothamSSmMedium",
        "header-medium-xxSmall": "GothamSSmMedium",
        "header-small-xxLarge": "GothamSSmBook",
        "header-small-xLarge": "GothamSSmBook",
        "header-small-large": "GothamSSmBook",
        "header-small-medium": "GothamSSmBook",
        "header-small-small": "GothamSSmBook",
        "header-small-xSmall": "GothamSSmBook",
        "header-small-xSmall-underline": "GothamSSmBook",
        "header-small-xxSmall": "GothamSSmBook",
        "rte-large-h1": "GothamSSmBold",
        "rte-large-h2": "GothamSSmBold",
        "rte-large-h3": "GothamSSmBold",
        "rte-large-h4": "GothamSSmBold",
        "rte-large-h5": "GothamSSmBold",
        "rte-large-h6": "GothamSSmBold",
        "rte-medium-h1": "GothamSSmBold",
        "rte-medium-h2": "GothamSSmBold",
        "rte-medium-h3": "GothamSSmBold",
        "rte-medium-h4": "GothamSSmBold",
        "rte-medium-h5": "GothamSSmBold",
        "rte-medium-h6": "GothamSSmBold",
        "rte-small-h1": "GothamSSmBold",
        "rte-small-h2": "GothamSSmBold",
        "rte-small-h3": "GothamSSmBold",
        "rte-small-h4": "GothamSSmBold",
        "rte-small-h5": "GothamSSmBold",
        "rte-small-h6": "GothamSSmBold"
      },
      "fontWeight": {
        "header-large-xxLarge": "700",
        "header-large-xLarge": "700",
        "header-large-large": "700",
        "header-large-medium": "700",
        "header-large-small": "700",
        "header-large-xSmall": "700",
        "header-large-xSmall-underline": "700",
        "header-large-xxSmall": "700",
        "header-medium-xxLarge": "700",
        "header-medium-xLarge": "700",
        "header-medium-large": "700",
        "header-medium-medium": "700",
        "header-medium-small": "700",
        "header-medium-xSmall": "700",
        "header-medium-xSmall-underline": "700",
        "header-medium-xxSmall": "700",
        "header-small-xxLarge": "700",
        "header-small-xLarge": "700",
        "header-small-large": "700",
        "header-small-medium": "700",
        "header-small-small": "700",
        "header-small-xSmall": "700",
        "header-small-xSmall-underline": "700",
        "header-small-xxSmall": "700",
        "rte-large-h1": "700",
        "rte-large-h2": "700",
        "rte-large-h3": "700",
        "rte-large-h4": "700",
        "rte-large-h5": "700",
        "rte-large-h6": "700",
        "rte-medium-h1": "700",
        "rte-medium-h2": "700",
        "rte-medium-h3": "700",
        "rte-medium-h4": "700",
        "rte-medium-h5": "700",
        "rte-medium-h6": "700",
        "rte-small-h1": "700",
        "rte-small-h2": "700",
        "rte-small-h3": "700",
        "rte-small-h4": "700",
        "rte-small-h5": "700",
        "rte-small-h6": "700"
      },
      "lineHeight": {
        "header-large-xxLarge": "120%",
        "header-large-xLarge": "120%",
        "header-large-large": "120%",
        "header-large-medium": "120%",
        "header-large-small": "120%",
        "header-large-xSmall": "120%",
        "header-large-xSmall-underline": "120%",
        "header-large-xxSmall": "120%",
        "header-medium-xxLarge": "120%",
        "header-medium-xLarge": "120%",
        "header-medium-large": "120%",
        "header-medium-medium": "120%",
        "header-medium-small": "120%",
        "header-medium-xSmall": "120%",
        "header-medium-xSmall-underline": "120%",
        "header-medium-xxSmall": "120%",
        "header-small-xxLarge": "120%",
        "header-small-xLarge": "120%",
        "header-small-large": "120%",
        "header-small-medium": "120%",
        "header-small-small": "120%",
        "header-small-xSmall": "120%",
        "header-small-xSmall-underline": "120%",
        "header-small-xxSmall": "120%",
        "rte-large-h1": "120%",
        "rte-large-h2": "120%",
        "rte-large-h3": "120%",
        "rte-large-h4": "120%",
        "rte-large-h5": "120%",
        "rte-large-h6": "120%",
        "rte-medium-h1": "120%",
        "rte-medium-h2": "120%",
        "rte-medium-h3": "120%",
        "rte-medium-h4": "120%",
        "rte-medium-h5": "120%",
        "rte-medium-h6": "120%",
        "rte-small-h1": "120%",
        "rte-small-h2": "120%",
        "rte-small-h3": "120%",
        "rte-small-h4": "120%",
        "rte-small-h5": "120%",
        "rte-small-h6": "120%"
      },
      "letterSpacing": {
        "header-large-xxLarge": "100%",
        "header-large-xLarge": "100%",
        "header-large-large": "100%",
        "header-large-medium": "100%",
        "header-large-small": "100%",
        "header-large-xSmall": "100%",
        "header-large-xSmall-underline": "100%",
        "header-large-xxSmall": "100%",
        "header-medium-xxLarge": "100%",
        "header-medium-xLarge": "100%",
        "header-medium-large": "100%",
        "header-medium-medium": "100%",
        "header-medium-small": "100%",
        "header-medium-xSmall": "100%",
        "header-medium-xSmall-underline": "100%",
        "header-medium-xxSmall": "100%",
        "header-small-xxLarge": "100%",
        "header-small-xLarge": "100%",
        "header-small-large": "100%",
        "header-small-medium": "100%",
        "header-small-small": "100%",
        "header-small-xSmall": "100%",
        "header-small-xSmall-underline": "100%",
        "header-small-xxSmall": "100%",
        "rte-large-h1": "100%",
        "rte-large-h2": "100%",
        "rte-large-h3": "100%",
        "rte-large-h4": "100%",
        "rte-large-h5": "100%",
        "rte-large-h6": "100%",
        "rte-medium-h1": "100%",
        "rte-medium-h2": "100%",
        "rte-medium-h3": "100%",
        "rte-medium-h4": "100%",
        "rte-medium-h5": "100%",
        "rte-medium-h6": "100%",
        "rte-small-h1": "100%",
        "rte-small-h2": "100%",
        "rte-small-h3": "100%",
        "rte-small-h4": "100%",
        "rte-small-h5": "100%",
        "rte-small-h6": "100%"
      },
      "textTransform": {},
      "textDecoration": {
        "header-large-xxLarge": "none",
        "header-large-xLarge": "none",
        "header-large-large": "none",
        "header-large-medium": "none",
        "header-large-small": "none",
        "header-large-xSmall": "none",
        "header-large-xSmall-underline": "underline",
        "header-large-xxSmall": "none",
        "header-medium-xxLarge": "none",
        "header-medium-xLarge": "none",
        "header-medium-large": "none",
        "header-medium-medium": "none",
        "header-medium-small": "none",
        "header-medium-xSmall": "none",
        "header-medium-xSmall-underline": "underline",
        "header-medium-xxSmall": "none",
        "header-small-xxLarge": "none",
        "header-small-xLarge": "none",
        "header-small-large": "none",
        "header-small-medium": "none",
        "header-small-small": "none",
        "header-small-xSmall": "none",
        "header-small-xSmall-underline": "underline",
        "header-small-xxSmall": "none",
        "rte-large-h1": "none",
        "rte-large-h2": "none",
        "rte-large-h3": "none",
        "rte-large-h4": "none",
        "rte-large-h5": "none",
        "rte-large-h6": "underline",
        "rte-medium-h1": "none",
        "rte-medium-h2": "none",
        "rte-medium-h3": "none",
        "rte-medium-h4": "none",
        "rte-medium-h5": "none",
        "rte-medium-h6": "underline",
        "rte-small-h1": "none",
        "rte-small-h2": "none",
        "rte-small-h3": "none",
        "rte-small-h4": "none",
        "rte-small-h5": "none",
        "rte-small-h6": "underline"
      }
    },
    "Pyrel": {
      "fontSize": {
        "header-large-xxLarge": "64px",
        "header-large-xLarge": "56px",
        "header-large-large": "48px",
        "header-large-medium": "36px",
        "header-large-small": "32px",
        "header-large-xSmall": "24px",
        "header-large-xSmall-underline": "24px",
        "header-large-xxSmall": "20px",
        "header-medium-xxLarge": "48px",
        "header-medium-xLarge": "40px",
        "header-medium-large": "36px",
        "header-medium-medium": "28px",
        "header-medium-small": "24px",
        "header-medium-xSmall": "20px",
        "header-medium-xSmall-underline": "20px",
        "header-medium-xxSmall": "18px",
        "header-small-xxLarge": "48px",
        "header-small-xLarge": "40px",
        "header-small-large": "32px",
        "header-small-medium": "28px",
        "header-small-small": "24px",
        "header-small-xSmall": "18px",
        "header-small-xSmall-underline": "18px",
        "header-small-xxSmall": "16px",
        "rte-large-h1": "48px",
        "rte-large-h2": "40px",
        "rte-large-h3": "36px",
        "rte-large-h4": "32px",
        "rte-large-h5": "24px",
        "rte-large-h6": "20px",
        "rte-medium-h1": "40px",
        "rte-medium-h2": "36px",
        "rte-medium-h3": "28px",
        "rte-medium-h4": "24px",
        "rte-medium-h5": "20px",
        "rte-medium-h6": "18px",
        "rte-small-h1": "40px",
        "rte-small-h2": "32px",
        "rte-small-h3": "28px",
        "rte-small-h4": "24px",
        "rte-small-h5": "18px",
        "rte-small-h6": "16px"
      },
      "fontFamily": {
        "header-large-xxLarge": "Avenir95Black",
        "header-large-xLarge": "Avenir95Black",
        "header-large-large": "Avenir95Black",
        "header-large-medium": "Avenir95Black",
        "header-large-small": "Avenir95Black",
        "header-large-xSmall": "Avenir95Black",
        "header-large-xSmall-underline": "Avenir95Black",
        "header-large-xxSmall": "Avenir95Black",
        "header-medium-xxLarge": "Avenir95Black",
        "header-medium-xLarge": "Avenir95Black",
        "header-medium-large": "Avenir95Black",
        "header-medium-medium": "Avenir95Black",
        "header-medium-small": "Avenir95Black",
        "header-medium-xSmall": "Avenir95Black",
        "header-medium-xSmall-underline": "Avenir95Black",
        "header-medium-xxSmall": "Avenir95Black",
        "header-small-xxLarge": "Avenir95Black",
        "header-small-xLarge": "Avenir95Black",
        "header-small-large": "Avenir95Black",
        "header-small-medium": "Avenir95Black",
        "header-small-small": "Avenir95Black",
        "header-small-xSmall": "Avenir95Black",
        "header-small-xSmall-underline": "Avenir95Black",
        "header-small-xxSmall": "Avenir95Black",
        "rte-large-h1": "Avenir95Black",
        "rte-large-h2": "Avenir95Black",
        "rte-large-h3": "Avenir95Black",
        "rte-large-h4": "Avenir95Black",
        "rte-large-h5": "Avenir95Black",
        "rte-large-h6": "Avenir95Black",
        "rte-medium-h1": "Avenir95Black",
        "rte-medium-h2": "Avenir95Black",
        "rte-medium-h3": "Avenir95Black",
        "rte-medium-h4": "Avenir95Black",
        "rte-medium-h5": "Avenir95Black",
        "rte-medium-h6": "Avenir95Black",
        "rte-small-h1": "Avenir95Black",
        "rte-small-h2": "Avenir95Black",
        "rte-small-h3": "Avenir95Black",
        "rte-small-h4": "Avenir95Black",
        "rte-small-h5": "Avenir95Black",
        "rte-small-h6": "Avenir95Black"
      },
      "fontWeight": {
        "header-large-xxLarge": "900",
        "header-large-xLarge": "900",
        "header-large-large": "900",
        "header-large-medium": "900",
        "header-large-small": "900",
        "header-large-xSmall": "900",
        "header-large-xSmall-underline": "900",
        "header-large-xxSmall": "900",
        "header-medium-xxLarge": "900",
        "header-medium-xLarge": "900",
        "header-medium-large": "900",
        "header-medium-medium": "900",
        "header-medium-small": "900",
        "header-medium-xSmall": "900",
        "header-medium-xSmall-underline": "900",
        "header-medium-xxSmall": "900",
        "header-small-xxLarge": "900",
        "header-small-xLarge": "900",
        "header-small-large": "900",
        "header-small-medium": "900",
        "header-small-small": "900",
        "header-small-xSmall": "900",
        "header-small-xSmall-underline": "900",
        "header-small-xxSmall": "900",
        "rte-large-h1": "700",
        "rte-large-h2": "700",
        "rte-large-h3": "700",
        "rte-large-h4": "700",
        "rte-large-h5": "700",
        "rte-large-h6": "700",
        "rte-medium-h1": "700",
        "rte-medium-h2": "700",
        "rte-medium-h3": "700",
        "rte-medium-h4": "700",
        "rte-medium-h5": "700",
        "rte-medium-h6": "700",
        "rte-small-h1": "700",
        "rte-small-h2": "700",
        "rte-small-h3": "700",
        "rte-small-h4": "700",
        "rte-small-h5": "700",
        "rte-small-h6": "700"
      },
      "lineHeight": {
        "header-large-xxLarge": "120%",
        "header-large-xLarge": "120%",
        "header-large-large": "120%",
        "header-large-medium": "120%",
        "header-large-small": "120%",
        "header-large-xSmall": "120%",
        "header-large-xSmall-underline": "120%",
        "header-large-xxSmall": "120%",
        "header-medium-xxLarge": "120%",
        "header-medium-xLarge": "120%",
        "header-medium-large": "120%",
        "header-medium-medium": "120%",
        "header-medium-small": "120%",
        "header-medium-xSmall": "120%",
        "header-medium-xSmall-underline": "120%",
        "header-medium-xxSmall": "120%",
        "header-small-xxLarge": "120%",
        "header-small-xLarge": "120%",
        "header-small-large": "120%",
        "header-small-medium": "120%",
        "header-small-small": "120%",
        "header-small-xSmall": "120%",
        "header-small-xSmall-underline": "120%",
        "header-small-xxSmall": "120%",
        "rte-large-h1": "120%",
        "rte-large-h2": "120%",
        "rte-large-h3": "120%",
        "rte-large-h4": "120%",
        "rte-large-h5": "120%",
        "rte-large-h6": "120%",
        "rte-medium-h1": "120%",
        "rte-medium-h2": "120%",
        "rte-medium-h3": "120%",
        "rte-medium-h4": "120%",
        "rte-medium-h5": "120%",
        "rte-medium-h6": "120%",
        "rte-small-h1": "120%",
        "rte-small-h2": "120%",
        "rte-small-h3": "120%",
        "rte-small-h4": "120%",
        "rte-small-h5": "120%",
        "rte-small-h6": "120%"
      },
      "letterSpacing": {
        "header-large-xxLarge": "100%",
        "header-large-xLarge": "100%",
        "header-large-large": "100%",
        "header-large-medium": "100%",
        "header-large-small": "100%",
        "header-large-xSmall": "100%",
        "header-large-xSmall-underline": "100%",
        "header-large-xxSmall": "100%",
        "header-medium-xxLarge": "100%",
        "header-medium-xLarge": "100%",
        "header-medium-large": "100%",
        "header-medium-medium": "100%",
        "header-medium-small": "100%",
        "header-medium-xSmall": "100%",
        "header-medium-xSmall-underline": "100%",
        "header-medium-xxSmall": "100%",
        "header-small-xxLarge": "100%",
        "header-small-xLarge": "100%",
        "header-small-large": "100%",
        "header-small-medium": "100%",
        "header-small-small": "100%",
        "header-small-xSmall": "100%",
        "header-small-xSmall-underline": "100%",
        "header-small-xxSmall": "100%",
        "rte-large-h1": "100%",
        "rte-large-h2": "100%",
        "rte-large-h3": "100%",
        "rte-large-h4": "100%",
        "rte-large-h5": "100%",
        "rte-large-h6": "100%",
        "rte-medium-h1": "100%",
        "rte-medium-h2": "100%",
        "rte-medium-h3": "100%",
        "rte-medium-h4": "100%",
        "rte-medium-h5": "100%",
        "rte-medium-h6": "100%",
        "rte-small-h1": "100%",
        "rte-small-h2": "100%",
        "rte-small-h3": "100%",
        "rte-small-h4": "100%",
        "rte-small-h5": "100%",
        "rte-small-h6": "100%"
      },
      "textTransform": {},
      "textDecoration": {
        "header-large-xxLarge": "none",
        "header-large-xLarge": "none",
        "header-large-large": "none",
        "header-large-medium": "none",
        "header-large-small": "none",
        "header-large-xSmall": "none",
        "header-large-xSmall-underline": "underline",
        "header-large-xxSmall": "none",
        "header-medium-xxLarge": "none",
        "header-medium-xLarge": "none",
        "header-medium-large": "none",
        "header-medium-medium": "none",
        "header-medium-small": "none",
        "header-medium-xSmall": "none",
        "header-medium-xSmall-underline": "underline",
        "header-medium-xxSmall": "none",
        "header-small-xxLarge": "none",
        "header-small-xLarge": "none",
        "header-small-large": "none",
        "header-small-medium": "none",
        "header-small-small": "none",
        "header-small-xSmall": "none",
        "header-small-xSmall-underline": "underline",
        "header-small-xxSmall": "none",
        "rte-large-h1": "none",
        "rte-large-h2": "none",
        "rte-large-h3": "none",
        "rte-large-h4": "none",
        "rte-large-h5": "none",
        "rte-large-h6": "underline",
        "rte-medium-h1": "none",
        "rte-medium-h2": "none",
        "rte-medium-h3": "none",
        "rte-medium-h4": "none",
        "rte-medium-h5": "none",
        "rte-medium-h6": "underline",
        "rte-small-h1": "none",
        "rte-small-h2": "none",
        "rte-small-h3": "none",
        "rte-small-h4": "none",
        "rte-small-h5": "none",
        "rte-small-h6": "underline"
      }
    },
    "MrMuscle": {
      "fontSize": {
        "header-large-xxLarge": "64px",
        "header-large-xLarge": "56px",
        "header-large-large": "48px",
        "header-large-medium": "36px",
        "header-large-small": "32px",
        "header-large-xSmall": "24px",
        "header-large-xSmall-underline": "24px",
        "header-large-xxSmall": "20px",
        "header-medium-xxLarge": "48px",
        "header-medium-xLarge": "40px",
        "header-medium-large": "36px",
        "header-medium-medium": "28px",
        "header-medium-small": "24px",
        "header-medium-xSmall": "20px",
        "header-medium-xSmall-underline": "20px",
        "header-medium-xxSmall": "18px",
        "header-small-xxLarge": "48px",
        "header-small-xLarge": "40px",
        "header-small-large": "32px",
        "header-small-medium": "28px",
        "header-small-small": "24px",
        "header-small-xSmall": "18px",
        "header-small-xSmall-underline": "18px",
        "header-small-xxSmall": "16px",
        "rte-large-h1": "48px",
        "rte-large-h2": "40px",
        "rte-large-h3": "36px",
        "rte-large-h4": "32px",
        "rte-large-h5": "24px",
        "rte-large-h6": "20px",
        "rte-medium-h1": "40px",
        "rte-medium-h2": "36px",
        "rte-medium-h3": "28px",
        "rte-medium-h4": "24px",
        "rte-medium-h5": "20px",
        "rte-medium-h6": "18px",
        "rte-small-h1": "40px",
        "rte-small-h2": "32px",
        "rte-small-h3": "28px",
        "rte-small-h4": "24px",
        "rte-small-h5": "18px",
        "rte-small-h6": "16px"
      },
      "fontFamily": {
        "header-large-xxLarge": "ProximaNovaExtrabold",
        "header-large-xLarge": "ProximaNovaExtrabold",
        "header-large-large": "ProximaNovaExtrabold",
        "header-large-medium": "ProximaNovaExtrabold",
        "header-large-small": "ProximaNovaExtrabold",
        "header-large-xSmall": "ProximaNovaExtrabold",
        "header-large-xSmall-underline": "ProximaNovaExtrabold",
        "header-large-xxSmall": "ProximaNovaExtrabold",
        "header-medium-xxLarge": "ProximaNovaExtrabold",
        "header-medium-xLarge": "ProximaNovaExtrabold",
        "header-medium-large": "ProximaNovaExtrabold",
        "header-medium-medium": "ProximaNovaExtrabold",
        "header-medium-small": "ProximaNovaExtrabold",
        "header-medium-xSmall": "ProximaNovaExtrabold",
        "header-medium-xSmall-underline": "ProximaNovaExtrabold",
        "header-medium-xxSmall": "ProximaNovaExtrabold",
        "header-small-xxLarge": "ProximaNovaExtrabold",
        "header-small-xLarge": "ProximaNovaExtrabold",
        "header-small-large": "ProximaNovaExtrabold",
        "header-small-medium": "ProximaNovaExtrabold",
        "header-small-small": "ProximaNovaExtrabold",
        "header-small-xSmall": "ProximaNovaExtrabold",
        "header-small-xSmall-underline": "ProximaNovaExtrabold",
        "header-small-xxSmall": "ProximaNovaExtrabold",
        "rte-large-h1": "ProximaNovaExtrabold",
        "rte-large-h2": "ProximaNovaExtrabold",
        "rte-large-h3": "ProximaNovaExtrabold",
        "rte-large-h4": "ProximaNovaExtrabold",
        "rte-large-h5": "ProximaNovaExtrabold",
        "rte-large-h6": "ProximaNovaExtrabold",
        "rte-medium-h1": "ProximaNovaExtrabold",
        "rte-medium-h2": "ProximaNovaExtrabold",
        "rte-medium-h3": "ProximaNovaExtrabold",
        "rte-medium-h4": "ProximaNovaExtrabold",
        "rte-medium-h5": "ProximaNovaExtrabold",
        "rte-medium-h6": "ProximaNovaExtrabold",
        "rte-small-h1": "ProximaNovaExtrabold",
        "rte-small-h2": "ProximaNovaExtrabold",
        "rte-small-h3": "ProximaNovaExtrabold",
        "rte-small-h4": "ProximaNovaExtrabold",
        "rte-small-h5": "ProximaNovaExtrabold",
        "rte-small-h6": "ProximaNovaExtrabold"
      },
      "fontWeight": {
        "header-large-xxLarge": "800",
        "header-large-xLarge": "800",
        "header-large-large": "800",
        "header-large-medium": "800",
        "header-large-small": "800",
        "header-large-xSmall": "800",
        "header-large-xSmall-underline": "800",
        "header-large-xxSmall": "800",
        "header-medium-xxLarge": "800",
        "header-medium-xLarge": "800",
        "header-medium-large": "800",
        "header-medium-medium": "800",
        "header-medium-small": "800",
        "header-medium-xSmall": "800",
        "header-medium-xSmall-underline": "800",
        "header-medium-xxSmall": "800",
        "header-small-xxLarge": "800",
        "header-small-xLarge": "800",
        "header-small-large": "800",
        "header-small-medium": "800",
        "header-small-small": "800",
        "header-small-xSmall": "800",
        "header-small-xSmall-underline": "800",
        "header-small-xxSmall": "800",
        "rte-large-h1": "800",
        "rte-large-h2": "800",
        "rte-large-h3": "800",
        "rte-large-h4": "800",
        "rte-large-h5": "800",
        "rte-large-h6": "800",
        "rte-medium-h1": "800",
        "rte-medium-h2": "800",
        "rte-medium-h3": "800",
        "rte-medium-h4": "800",
        "rte-medium-h5": "800",
        "rte-medium-h6": "800",
        "rte-small-h1": "800",
        "rte-small-h2": "800",
        "rte-small-h3": "800",
        "rte-small-h4": "800",
        "rte-small-h5": "800",
        "rte-small-h6": "800"
      },
      "lineHeight": {
        "header-large-xxLarge": "120%",
        "header-large-xLarge": "120%",
        "header-large-large": "120%",
        "header-large-medium": "120%",
        "header-large-small": "120%",
        "header-large-xSmall": "120%",
        "header-large-xSmall-underline": "120%",
        "header-large-xxSmall": "120%",
        "header-medium-xxLarge": "120%",
        "header-medium-xLarge": "120%",
        "header-medium-large": "120%",
        "header-medium-medium": "120%",
        "header-medium-small": "120%",
        "header-medium-xSmall": "120%",
        "header-medium-xSmall-underline": "120%",
        "header-medium-xxSmall": "120%",
        "header-small-xxLarge": "120%",
        "header-small-xLarge": "120%",
        "header-small-large": "120%",
        "header-small-medium": "120%",
        "header-small-small": "120%",
        "header-small-xSmall": "120%",
        "header-small-xSmall-underline": "120%",
        "header-small-xxSmall": "120%",
        "rte-large-h1": "120%",
        "rte-large-h2": "120%",
        "rte-large-h3": "120%",
        "rte-large-h4": "120%",
        "rte-large-h5": "120%",
        "rte-large-h6": "120%",
        "rte-medium-h1": "120%",
        "rte-medium-h2": "120%",
        "rte-medium-h3": "120%",
        "rte-medium-h4": "120%",
        "rte-medium-h5": "120%",
        "rte-medium-h6": "120%",
        "rte-small-h1": "120%",
        "rte-small-h2": "120%",
        "rte-small-h3": "120%",
        "rte-small-h4": "120%",
        "rte-small-h5": "120%",
        "rte-small-h6": "120%"
      },
      "letterSpacing": {
        "header-large-xxLarge": "100%",
        "header-large-xLarge": "100%",
        "header-large-large": "100%",
        "header-large-medium": "100%",
        "header-large-small": "100%",
        "header-large-xSmall": "100%",
        "header-large-xSmall-underline": "100%",
        "header-large-xxSmall": "100%",
        "header-medium-xxLarge": "100%",
        "header-medium-xLarge": "100%",
        "header-medium-large": "100%",
        "header-medium-medium": "100%",
        "header-medium-small": "100%",
        "header-medium-xSmall": "100%",
        "header-medium-xSmall-underline": "100%",
        "header-medium-xxSmall": "100%",
        "header-small-xxLarge": "100%",
        "header-small-xLarge": "100%",
        "header-small-large": "100%",
        "header-small-medium": "100%",
        "header-small-small": "100%",
        "header-small-xSmall": "100%",
        "header-small-xSmall-underline": "100%",
        "header-small-xxSmall": "100%",
        "rte-large-h1": "100%",
        "rte-large-h2": "100%",
        "rte-large-h3": "100%",
        "rte-large-h4": "100%",
        "rte-large-h5": "100%",
        "rte-large-h6": "100%",
        "rte-medium-h1": "100%",
        "rte-medium-h2": "100%",
        "rte-medium-h3": "100%",
        "rte-medium-h4": "100%",
        "rte-medium-h5": "100%",
        "rte-medium-h6": "100%",
        "rte-small-h1": "100%",
        "rte-small-h2": "100%",
        "rte-small-h3": "100%",
        "rte-small-h4": "100%",
        "rte-small-h5": "100%",
        "rte-small-h6": "100%"
      },
      "textTransform": {},
      "textDecoration": {
        "header-large-xxLarge": "none",
        "header-large-xLarge": "none",
        "header-large-large": "none",
        "header-large-medium": "none",
        "header-large-small": "none",
        "header-large-xSmall": "none",
        "header-large-xSmall-underline": "underline",
        "header-large-xxSmall": "none",
        "header-medium-xxLarge": "none",
        "header-medium-xLarge": "none",
        "header-medium-large": "none",
        "header-medium-medium": "none",
        "header-medium-small": "none",
        "header-medium-xSmall": "none",
        "header-medium-xSmall-underline": "underline",
        "header-medium-xxSmall": "none",
        "header-small-xxLarge": "none",
        "header-small-xLarge": "none",
        "header-small-large": "none",
        "header-small-medium": "none",
        "header-small-small": "none",
        "header-small-xSmall": "none",
        "header-small-xSmall-underline": "underline",
        "header-small-xxSmall": "none",
        "rte-large-h1": "none",
        "rte-large-h2": "none",
        "rte-large-h3": "none",
        "rte-large-h4": "none",
        "rte-large-h5": "none",
        "rte-large-h6": "underline",
        "rte-medium-h1": "none",
        "rte-medium-h2": "none",
        "rte-medium-h3": "none",
        "rte-medium-h4": "none",
        "rte-medium-h5": "none",
        "rte-medium-h6": "underline",
        "rte-small-h1": "none",
        "rte-small-h2": "none",
        "rte-small-h3": "none",
        "rte-small-h4": "none",
        "rte-small-h5": "none",
        "rte-small-h6": "underline"
      }
    },
    "Pledge": {
      "fontSize": {
        "header-large-xxLarge": "64px",
        "header-large-xLarge": "56px",
        "header-large-large": "48px",
        "header-large-medium": "36px",
        "header-large-small": "32px",
        "header-large-xSmall": "24px",
        "header-large-xSmall-underline": "24px",
        "header-large-xxSmall": "20px",
        "header-medium-xxLarge": "48px",
        "header-medium-xLarge": "40px",
        "header-medium-large": "36px",
        "header-medium-medium": "28px",
        "header-medium-small": "24px",
        "header-medium-xSmall": "20px",
        "header-medium-xSmall-underline": "20px",
        "header-medium-xxSmall": "18px",
        "header-small-xxLarge": "48px",
        "header-small-xLarge": "40px",
        "header-small-large": "32px",
        "header-small-medium": "28px",
        "header-small-small": "24px",
        "header-small-xSmall": "18px",
        "header-small-xSmall-underline": "18px",
        "header-small-xxSmall": "16px",
        "rte-large-h1": "48px",
        "rte-large-h2": "40px",
        "rte-large-h3": "36px",
        "rte-large-h4": "32px",
        "rte-large-h5": "24px",
        "rte-large-h6": "20px",
        "rte-medium-h1": "40px",
        "rte-medium-h2": "36px",
        "rte-medium-h3": "28px",
        "rte-medium-h4": "24px",
        "rte-medium-h5": "20px",
        "rte-medium-h6": "18px",
        "rte-small-h1": "40px",
        "rte-small-h2": "32px",
        "rte-small-h3": "28px",
        "rte-small-h4": "24px",
        "rte-small-h5": "18px",
        "rte-small-h6": "16px"
      },
      "fontFamily": {
        "header-large-xxLarge": "GothamSSmBold",
        "header-large-xLarge": "GothamSSmBold",
        "header-large-large": "GothamSSmBold",
        "header-large-medium": "GothamSSmBold",
        "header-large-small": "GothamSSmBold",
        "header-large-xSmall": "GothamSSmBold",
        "header-large-xSmall-underline": "GothamSSmBold",
        "header-large-xxSmall": "GothamSSmBold",
        "header-medium-xxLarge": "GothamSSmMedium",
        "header-medium-xLarge": "GothamSSmMedium",
        "header-medium-large": "GothamSSmMedium",
        "header-medium-medium": "GothamSSmMedium",
        "header-medium-small": "GothamSSmMedium",
        "header-medium-xSmall": "GothamSSmMedium",
        "header-medium-xSmall-underline": "GothamSSmMedium",
        "header-medium-xxSmall": "GothamSSmMedium",
        "header-small-xxLarge": "GothamSSmBook",
        "header-small-xLarge": "GothamSSmBook",
        "header-small-large": "GothamSSmBook",
        "header-small-medium": "GothamSSmBook",
        "header-small-small": "GothamSSmBook",
        "header-small-xSmall": "GothamSSmBook",
        "header-small-xSmall-underline": "GothamSSmBook",
        "header-small-xxSmall": "GothamSSmBook",
        "rte-large-h1": "GothamSSmBold",
        "rte-large-h2": "GothamSSmBold",
        "rte-large-h3": "GothamSSmBold",
        "rte-large-h4": "GothamSSmBold",
        "rte-large-h5": "GothamSSmBold",
        "rte-large-h6": "GothamSSmBold",
        "rte-medium-h1": "GothamSSmBold",
        "rte-medium-h2": "GothamSSmBold",
        "rte-medium-h3": "GothamSSmBold",
        "rte-medium-h4": "GothamSSmBold",
        "rte-medium-h5": "GothamSSmBold",
        "rte-medium-h6": "GothamSSmBold",
        "rte-small-h1": "GothamSSmBold",
        "rte-small-h2": "GothamSSmBold",
        "rte-small-h3": "GothamSSmBold",
        "rte-small-h4": "GothamSSmBold",
        "rte-small-h5": "GothamSSmBold",
        "rte-small-h6": "GothamSSmBold"
      },
      "fontWeight": {
        "header-large-xxLarge": "700",
        "header-large-xLarge": "700",
        "header-large-large": "700",
        "header-large-medium": "700",
        "header-large-small": "700",
        "header-large-xSmall": "700",
        "header-large-xSmall-underline": "700",
        "header-large-xxSmall": "700",
        "header-medium-xxLarge": "700",
        "header-medium-xLarge": "700",
        "header-medium-large": "700",
        "header-medium-medium": "700",
        "header-medium-small": "700",
        "header-medium-xSmall": "700",
        "header-medium-xSmall-underline": "700",
        "header-medium-xxSmall": "700",
        "header-small-xxLarge": "700",
        "header-small-xLarge": "700",
        "header-small-large": "700",
        "header-small-medium": "700",
        "header-small-small": "700",
        "header-small-xSmall": "700",
        "header-small-xSmall-underline": "700",
        "header-small-xxSmall": "700",
        "rte-large-h1": "700",
        "rte-large-h2": "700",
        "rte-large-h3": "700",
        "rte-large-h4": "700",
        "rte-large-h5": "700",
        "rte-large-h6": "700",
        "rte-medium-h1": "700",
        "rte-medium-h2": "700",
        "rte-medium-h3": "700",
        "rte-medium-h4": "700",
        "rte-medium-h5": "700",
        "rte-medium-h6": "700",
        "rte-small-h1": "700",
        "rte-small-h2": "700",
        "rte-small-h3": "700",
        "rte-small-h4": "700",
        "rte-small-h5": "700",
        "rte-small-h6": "700"
      },
      "lineHeight": {
        "header-large-xxLarge": "120%",
        "header-large-xLarge": "120%",
        "header-large-large": "120%",
        "header-large-medium": "120%",
        "header-large-small": "120%",
        "header-large-xSmall": "120%",
        "header-large-xSmall-underline": "120%",
        "header-large-xxSmall": "120%",
        "header-medium-xxLarge": "120%",
        "header-medium-xLarge": "120%",
        "header-medium-large": "120%",
        "header-medium-medium": "120%",
        "header-medium-small": "120%",
        "header-medium-xSmall": "120%",
        "header-medium-xSmall-underline": "120%",
        "header-medium-xxSmall": "120%",
        "header-small-xxLarge": "120%",
        "header-small-xLarge": "120%",
        "header-small-large": "120%",
        "header-small-medium": "120%",
        "header-small-small": "120%",
        "header-small-xSmall": "120%",
        "header-small-xSmall-underline": "120%",
        "header-small-xxSmall": "120%",
        "rte-large-h1": "120%",
        "rte-large-h2": "120%",
        "rte-large-h3": "120%",
        "rte-large-h4": "120%",
        "rte-large-h5": "120%",
        "rte-large-h6": "120%",
        "rte-medium-h1": "120%",
        "rte-medium-h2": "120%",
        "rte-medium-h3": "120%",
        "rte-medium-h4": "120%",
        "rte-medium-h5": "120%",
        "rte-medium-h6": "120%",
        "rte-small-h1": "120%",
        "rte-small-h2": "120%",
        "rte-small-h3": "120%",
        "rte-small-h4": "120%",
        "rte-small-h5": "120%",
        "rte-small-h6": "120%"
      },
      "letterSpacing": {
        "header-large-xxLarge": "100%",
        "header-large-xLarge": "100%",
        "header-large-large": "100%",
        "header-large-medium": "100%",
        "header-large-small": "100%",
        "header-large-xSmall": "100%",
        "header-large-xSmall-underline": "100%",
        "header-large-xxSmall": "100%",
        "header-medium-xxLarge": "100%",
        "header-medium-xLarge": "100%",
        "header-medium-large": "100%",
        "header-medium-medium": "100%",
        "header-medium-small": "100%",
        "header-medium-xSmall": "100%",
        "header-medium-xSmall-underline": "100%",
        "header-medium-xxSmall": "100%",
        "header-small-xxLarge": "100%",
        "header-small-xLarge": "100%",
        "header-small-large": "100%",
        "header-small-medium": "100%",
        "header-small-small": "100%",
        "header-small-xSmall": "100%",
        "header-small-xSmall-underline": "100%",
        "header-small-xxSmall": "100%",
        "rte-large-h1": "100%",
        "rte-large-h2": "100%",
        "rte-large-h3": "100%",
        "rte-large-h4": "100%",
        "rte-large-h5": "100%",
        "rte-large-h6": "100%",
        "rte-medium-h1": "100%",
        "rte-medium-h2": "100%",
        "rte-medium-h3": "100%",
        "rte-medium-h4": "100%",
        "rte-medium-h5": "100%",
        "rte-medium-h6": "100%",
        "rte-small-h1": "100%",
        "rte-small-h2": "100%",
        "rte-small-h3": "100%",
        "rte-small-h4": "100%",
        "rte-small-h5": "100%",
        "rte-small-h6": "100%"
      },
      "textTransform": {},
      "textDecoration": {
        "header-large-xxLarge": "none",
        "header-large-xLarge": "none",
        "header-large-large": "none",
        "header-large-medium": "none",
        "header-large-small": "none",
        "header-large-xSmall": "none",
        "header-large-xSmall-underline": "underline",
        "header-large-xxSmall": "none",
        "header-medium-xxLarge": "none",
        "header-medium-xLarge": "none",
        "header-medium-large": "none",
        "header-medium-medium": "none",
        "header-medium-small": "none",
        "header-medium-xSmall": "none",
        "header-medium-xSmall-underline": "underline",
        "header-medium-xxSmall": "none",
        "header-small-xxLarge": "none",
        "header-small-xLarge": "none",
        "header-small-large": "none",
        "header-small-medium": "none",
        "header-small-small": "none",
        "header-small-xSmall": "none",
        "header-small-xSmall-underline": "underline",
        "header-small-xxSmall": "none",
        "rte-large-h1": "none",
        "rte-large-h2": "none",
        "rte-large-h3": "none",
        "rte-large-h4": "none",
        "rte-large-h5": "none",
        "rte-large-h6": "underline",
        "rte-medium-h1": "none",
        "rte-medium-h2": "none",
        "rte-medium-h3": "none",
        "rte-medium-h4": "none",
        "rte-medium-h5": "none",
        "rte-medium-h6": "underline",
        "rte-small-h1": "none",
        "rte-small-h2": "none",
        "rte-small-h3": "none",
        "rte-small-h4": "none",
        "rte-small-h5": "none",
        "rte-small-h6": "underline"
      }
    },
    "Drano": {
      "fontSize": {
        "header-large-xxLarge": "64px",
        "header-large-xLarge": "56px",
        "header-large-large": "48px",
        "header-large-medium": "36px",
        "header-large-small": "32px",
        "header-large-xSmall": "24px",
        "header-large-xSmall-underline": "24px",
        "header-large-xxSmall": "20px",
        "header-medium-xxLarge": "48px",
        "header-medium-xLarge": "40px",
        "header-medium-large": "36px",
        "header-medium-medium": "28px",
        "header-medium-small": "24px",
        "header-medium-xSmall": "20px",
        "header-medium-xSmall-underline": "20px",
        "header-medium-xxSmall": "18px",
        "header-small-xxLarge": "48px",
        "header-small-xLarge": "40px",
        "header-small-large": "32px",
        "header-small-medium": "28px",
        "header-small-small": "24px",
        "header-small-xSmall": "18px",
        "header-small-xSmall-underline": "18px",
        "header-small-xxSmall": "16px",
        "rte-large-h1": "48px",
        "rte-large-h2": "40px",
        "rte-large-h3": "36px",
        "rte-large-h4": "32px",
        "rte-large-h5": "24px",
        "rte-large-h6": "20px",
        "rte-medium-h1": "40px",
        "rte-medium-h2": "36px",
        "rte-medium-h3": "28px",
        "rte-medium-h4": "24px",
        "rte-medium-h5": "20px",
        "rte-medium-h6": "18px",
        "rte-small-h1": "40px",
        "rte-small-h2": "32px",
        "rte-small-h3": "28px",
        "rte-small-h4": "24px",
        "rte-small-h5": "18px",
        "rte-small-h6": "16px"
      },
      "fontFamily": {
        "header-large-xxLarge": "FuturaLTPro",
        "header-large-xLarge": "FuturaLTPro",
        "header-large-large": "FuturaLTPro",
        "header-large-medium": "FuturaLTPro",
        "header-large-small": "FuturaLTPro",
        "header-large-xSmall": "FuturaLTPro",
        "header-large-xSmall-underline": "FuturaLTPro",
        "header-large-xxSmall": "FuturaLTPro",
        "header-medium-xxLarge": "FuturaLTPro",
        "header-medium-xLarge": "FuturaLTPro",
        "header-medium-large": "FuturaLTPro",
        "header-medium-medium": "FuturaLTPro",
        "header-medium-small": "FuturaLTPro",
        "header-medium-xSmall": "FuturaLTPro",
        "header-medium-xSmall-underline": "FuturaLTPro",
        "header-medium-xxSmall": "FuturaLTPro",
        "header-small-xxLarge": "FuturaLTPro",
        "header-small-xLarge": "FuturaLTPro",
        "header-small-large": "FuturaLTPro",
        "header-small-medium": "FuturaLTPro",
        "header-small-small": "FuturaLTPro",
        "header-small-xSmall": "FuturaLTPro",
        "header-small-xSmall-underline": "FuturaLTPro",
        "header-small-xxSmall": "FuturaLTPro",
        "rte-large-h1": "FuturaLTPro",
        "rte-large-h2": "FuturaLTPro",
        "rte-large-h3": "FuturaLTPro",
        "rte-large-h4": "FuturaLTPro",
        "rte-large-h5": "FuturaLTPro",
        "rte-large-h6": "FuturaLTPro",
        "rte-medium-h1": "FuturaLTPro",
        "rte-medium-h2": "FuturaLTPro",
        "rte-medium-h3": "FuturaLTPro",
        "rte-medium-h4": "FuturaLTPro",
        "rte-medium-h5": "FuturaLTPro",
        "rte-medium-h6": "FuturaLTPro",
        "rte-small-h1": "FuturaLTPro",
        "rte-small-h2": "FuturaLTPro",
        "rte-small-h3": "FuturaLTPro",
        "rte-small-h4": "FuturaLTPro",
        "rte-small-h5": "FuturaLTPro",
        "rte-small-h6": "FuturaLTPro"
      },
      "fontWeight": {
        "header-large-xxLarge": "700",
        "header-large-xLarge": "700",
        "header-large-large": "700",
        "header-large-medium": "700",
        "header-large-small": "700",
        "header-large-xSmall": "700",
        "header-large-xSmall-underline": "700",
        "header-large-xxSmall": "700",
        "header-medium-xxLarge": "700",
        "header-medium-xLarge": "700",
        "header-medium-large": "700",
        "header-medium-medium": "700",
        "header-medium-small": "700",
        "header-medium-xSmall": "700",
        "header-medium-xSmall-underline": "700",
        "header-medium-xxSmall": "700",
        "header-small-xxLarge": "700",
        "header-small-xLarge": "700",
        "header-small-large": "700",
        "header-small-medium": "700",
        "header-small-small": "700",
        "header-small-xSmall": "700",
        "header-small-xSmall-underline": "700",
        "header-small-xxSmall": "700",
        "rte-large-h1": "700",
        "rte-large-h2": "700",
        "rte-large-h3": "700",
        "rte-large-h4": "700",
        "rte-large-h5": "700",
        "rte-large-h6": "700",
        "rte-medium-h1": "700",
        "rte-medium-h2": "700",
        "rte-medium-h3": "700",
        "rte-medium-h4": "700",
        "rte-medium-h5": "700",
        "rte-medium-h6": "700",
        "rte-small-h1": "700",
        "rte-small-h2": "700",
        "rte-small-h3": "700",
        "rte-small-h4": "700",
        "rte-small-h5": "700",
        "rte-small-h6": "700"
      },
      "lineHeight": {
        "header-large-xxLarge": "120%",
        "header-large-xLarge": "120%",
        "header-large-large": "120%",
        "header-large-medium": "120%",
        "header-large-small": "120%",
        "header-large-xSmall": "120%",
        "header-large-xSmall-underline": "120%",
        "header-large-xxSmall": "120%",
        "header-medium-xxLarge": "120%",
        "header-medium-xLarge": "120%",
        "header-medium-large": "120%",
        "header-medium-medium": "120%",
        "header-medium-small": "120%",
        "header-medium-xSmall": "120%",
        "header-medium-xSmall-underline": "120%",
        "header-medium-xxSmall": "120%",
        "header-small-xxLarge": "120%",
        "header-small-xLarge": "120%",
        "header-small-large": "120%",
        "header-small-medium": "120%",
        "header-small-small": "120%",
        "header-small-xSmall": "120%",
        "header-small-xSmall-underline": "120%",
        "header-small-xxSmall": "120%",
        "rte-large-h1": "120%",
        "rte-large-h2": "120%",
        "rte-large-h3": "120%",
        "rte-large-h4": "120%",
        "rte-large-h5": "120%",
        "rte-large-h6": "120%",
        "rte-medium-h1": "120%",
        "rte-medium-h2": "120%",
        "rte-medium-h3": "120%",
        "rte-medium-h4": "120%",
        "rte-medium-h5": "120%",
        "rte-medium-h6": "120%",
        "rte-small-h1": "120%",
        "rte-small-h2": "120%",
        "rte-small-h3": "120%",
        "rte-small-h4": "120%",
        "rte-small-h5": "120%",
        "rte-small-h6": "120%"
      },
      "letterSpacing": {
        "header-large-xxLarge": "100%",
        "header-large-xLarge": "100%",
        "header-large-large": "100%",
        "header-large-medium": "100%",
        "header-large-small": "100%",
        "header-large-xSmall": "100%",
        "header-large-xSmall-underline": "100%",
        "header-large-xxSmall": "100%",
        "header-medium-xxLarge": "100%",
        "header-medium-xLarge": "100%",
        "header-medium-large": "100%",
        "header-medium-medium": "100%",
        "header-medium-small": "100%",
        "header-medium-xSmall": "100%",
        "header-medium-xSmall-underline": "100%",
        "header-medium-xxSmall": "100%",
        "header-small-xxLarge": "100%",
        "header-small-xLarge": "100%",
        "header-small-large": "100%",
        "header-small-medium": "100%",
        "header-small-small": "100%",
        "header-small-xSmall": "100%",
        "header-small-xSmall-underline": "100%",
        "header-small-xxSmall": "100%",
        "rte-large-h1": "100%",
        "rte-large-h2": "100%",
        "rte-large-h3": "100%",
        "rte-large-h4": "100%",
        "rte-large-h5": "100%",
        "rte-large-h6": "100%",
        "rte-medium-h1": "100%",
        "rte-medium-h2": "100%",
        "rte-medium-h3": "100%",
        "rte-medium-h4": "100%",
        "rte-medium-h5": "100%",
        "rte-medium-h6": "100%",
        "rte-small-h1": "100%",
        "rte-small-h2": "100%",
        "rte-small-h3": "100%",
        "rte-small-h4": "100%",
        "rte-small-h5": "100%",
        "rte-small-h6": "100%"
      },
      "textTransform": {},
      "textDecoration": {
        "header-large-xxLarge": "none",
        "header-large-xLarge": "none",
        "header-large-large": "none",
        "header-large-medium": "none",
        "header-large-small": "none",
        "header-large-xSmall": "none",
        "header-large-xSmall-underline": "underline",
        "header-large-xxSmall": "none",
        "header-medium-xxLarge": "none",
        "header-medium-xLarge": "none",
        "header-medium-large": "none",
        "header-medium-medium": "none",
        "header-medium-small": "none",
        "header-medium-xSmall": "none",
        "header-medium-xSmall-underline": "underline",
        "header-medium-xxSmall": "none",
        "header-small-xxLarge": "none",
        "header-small-xLarge": "none",
        "header-small-large": "none",
        "header-small-medium": "none",
        "header-small-small": "none",
        "header-small-xSmall": "none",
        "header-small-xSmall-underline": "underline",
        "header-small-xxSmall": "none",
        "rte-large-h1": "none",
        "rte-large-h2": "none",
        "rte-large-h3": "none",
        "rte-large-h4": "none",
        "rte-large-h5": "none",
        "rte-large-h6": "underline",
        "rte-medium-h1": "none",
        "rte-medium-h2": "none",
        "rte-medium-h3": "none",
        "rte-medium-h4": "none",
        "rte-medium-h5": "none",
        "rte-medium-h6": "underline",
        "rte-small-h1": "none",
        "rte-small-h2": "none",
        "rte-small-h3": "none",
        "rte-small-h4": "none",
        "rte-small-h5": "none",
        "rte-small-h6": "underline"
      }
    },
    "Lysoform": {
      "fontSize": {
        "header-large-xxLarge": "64px",
        "header-large-xLarge": "56px",
        "header-large-large": "48px",
        "header-large-medium": "36px",
        "header-large-small": "32px",
        "header-large-xSmall": "24px",
        "header-large-xSmall-underline": "24px",
        "header-large-xxSmall": "20px",
        "header-medium-xxLarge": "48px",
        "header-medium-xLarge": "40px",
        "header-medium-large": "36px",
        "header-medium-medium": "28px",
        "header-medium-small": "24px",
        "header-medium-xSmall": "20px",
        "header-medium-xSmall-underline": "20px",
        "header-medium-xxSmall": "18px",
        "header-small-xxLarge": "48px",
        "header-small-xLarge": "40px",
        "header-small-large": "32px",
        "header-small-medium": "28px",
        "header-small-small": "24px",
        "header-small-xSmall": "18px",
        "header-small-xSmall-underline": "18px",
        "header-small-xxSmall": "16px",
        "rte-large-h1": "48px",
        "rte-large-h2": "40px",
        "rte-large-h3": "36px",
        "rte-large-h4": "32px",
        "rte-large-h5": "24px",
        "rte-large-h6": "20px",
        "rte-medium-h1": "40px",
        "rte-medium-h2": "36px",
        "rte-medium-h3": "28px",
        "rte-medium-h4": "24px",
        "rte-medium-h5": "20px",
        "rte-medium-h6": "18px",
        "rte-small-h1": "40px",
        "rte-small-h2": "32px",
        "rte-small-h3": "28px",
        "rte-small-h4": "24px",
        "rte-small-h5": "18px",
        "rte-small-h6": "16px"
      },
      "fontFamily": {
        "header-large-xxLarge": "ProximaNovaExtrabold",
        "header-large-xLarge": "ProximaNovaExtrabold",
        "header-large-large": "ProximaNovaExtrabold",
        "header-large-medium": "ProximaNovaExtrabold",
        "header-large-small": "ProximaNovaExtrabold",
        "header-large-xSmall": "ProximaNovaExtrabold",
        "header-large-xSmall-underline": "ProximaNovaExtrabold",
        "header-large-xxSmall": "ProximaNovaExtrabold",
        "header-medium-xxLarge": "ProximaNovaExtrabold",
        "header-medium-xLarge": "ProximaNovaExtrabold",
        "header-medium-large": "ProximaNovaExtrabold",
        "header-medium-medium": "ProximaNovaExtrabold",
        "header-medium-small": "ProximaNovaExtrabold",
        "header-medium-xSmall": "ProximaNovaExtrabold",
        "header-medium-xSmall-underline": "ProximaNovaExtrabold",
        "header-medium-xxSmall": "ProximaNovaExtrabold",
        "header-small-xxLarge": "ProximaNovaExtrabold",
        "header-small-xLarge": "ProximaNovaExtrabold",
        "header-small-large": "ProximaNovaExtrabold",
        "header-small-medium": "ProximaNovaExtrabold",
        "header-small-small": "ProximaNovaExtrabold",
        "header-small-xSmall": "ProximaNovaExtrabold",
        "header-small-xSmall-underline": "ProximaNovaExtrabold",
        "header-small-xxSmall": "ProximaNovaExtrabold",
        "rte-large-h1": "ProximaNovaExtrabold",
        "rte-large-h2": "ProximaNovaExtrabold",
        "rte-large-h3": "ProximaNovaExtrabold",
        "rte-large-h4": "ProximaNovaExtrabold",
        "rte-large-h5": "ProximaNovaExtrabold",
        "rte-large-h6": "ProximaNovaExtrabold",
        "rte-medium-h1": "ProximaNovaExtrabold",
        "rte-medium-h2": "ProximaNovaExtrabold",
        "rte-medium-h3": "ProximaNovaExtrabold",
        "rte-medium-h4": "ProximaNovaExtrabold",
        "rte-medium-h5": "ProximaNovaExtrabold",
        "rte-medium-h6": "ProximaNovaExtrabold",
        "rte-small-h1": "ProximaNovaExtrabold",
        "rte-small-h2": "ProximaNovaExtrabold",
        "rte-small-h3": "ProximaNovaExtrabold",
        "rte-small-h4": "ProximaNovaExtrabold",
        "rte-small-h5": "ProximaNovaExtrabold",
        "rte-small-h6": "ProximaNovaExtrabold"
      },
      "fontWeight": {
        "header-large-xxLarge": "800",
        "header-large-xLarge": "800",
        "header-large-large": "800",
        "header-large-medium": "800",
        "header-large-small": "800",
        "header-large-xSmall": "800",
        "header-large-xSmall-underline": "800",
        "header-large-xxSmall": "800",
        "header-medium-xxLarge": "800",
        "header-medium-xLarge": "800",
        "header-medium-large": "800",
        "header-medium-medium": "800",
        "header-medium-small": "800",
        "header-medium-xSmall": "800",
        "header-medium-xSmall-underline": "800",
        "header-medium-xxSmall": "800",
        "header-small-xxLarge": "800",
        "header-small-xLarge": "800",
        "header-small-large": "800",
        "header-small-medium": "800",
        "header-small-small": "800",
        "header-small-xSmall": "800",
        "header-small-xSmall-underline": "800",
        "header-small-xxSmall": "800",
        "rte-large-h1": "800",
        "rte-large-h2": "800",
        "rte-large-h3": "800",
        "rte-large-h4": "800",
        "rte-large-h5": "800",
        "rte-large-h6": "800",
        "rte-medium-h1": "800",
        "rte-medium-h2": "800",
        "rte-medium-h3": "800",
        "rte-medium-h4": "800",
        "rte-medium-h5": "800",
        "rte-medium-h6": "800",
        "rte-small-h1": "800",
        "rte-small-h2": "800",
        "rte-small-h3": "800",
        "rte-small-h4": "800",
        "rte-small-h5": "800",
        "rte-small-h6": "800"
      },
      "lineHeight": {
        "header-large-xxLarge": "120%",
        "header-large-xLarge": "120%",
        "header-large-large": "120%",
        "header-large-medium": "120%",
        "header-large-small": "120%",
        "header-large-xSmall": "120%",
        "header-large-xSmall-underline": "120%",
        "header-large-xxSmall": "120%",
        "header-medium-xxLarge": "120%",
        "header-medium-xLarge": "120%",
        "header-medium-large": "120%",
        "header-medium-medium": "120%",
        "header-medium-small": "120%",
        "header-medium-xSmall": "120%",
        "header-medium-xSmall-underline": "120%",
        "header-medium-xxSmall": "120%",
        "header-small-xxLarge": "120%",
        "header-small-xLarge": "120%",
        "header-small-large": "120%",
        "header-small-medium": "120%",
        "header-small-small": "120%",
        "header-small-xSmall": "120%",
        "header-small-xSmall-underline": "120%",
        "header-small-xxSmall": "120%",
        "rte-large-h1": "120%",
        "rte-large-h2": "120%",
        "rte-large-h3": "120%",
        "rte-large-h4": "120%",
        "rte-large-h5": "120%",
        "rte-large-h6": "120%",
        "rte-medium-h1": "120%",
        "rte-medium-h2": "120%",
        "rte-medium-h3": "120%",
        "rte-medium-h4": "120%",
        "rte-medium-h5": "120%",
        "rte-medium-h6": "120%",
        "rte-small-h1": "120%",
        "rte-small-h2": "120%",
        "rte-small-h3": "120%",
        "rte-small-h4": "120%",
        "rte-small-h5": "120%",
        "rte-small-h6": "120%"
      },
      "letterSpacing": {
        "header-large-xxLarge": "100%",
        "header-large-xLarge": "100%",
        "header-large-large": "100%",
        "header-large-medium": "100%",
        "header-large-small": "100%",
        "header-large-xSmall": "100%",
        "header-large-xSmall-underline": "100%",
        "header-large-xxSmall": "100%",
        "header-medium-xxLarge": "100%",
        "header-medium-xLarge": "100%",
        "header-medium-large": "100%",
        "header-medium-medium": "100%",
        "header-medium-small": "100%",
        "header-medium-xSmall": "100%",
        "header-medium-xSmall-underline": "100%",
        "header-medium-xxSmall": "100%",
        "header-small-xxLarge": "100%",
        "header-small-xLarge": "100%",
        "header-small-large": "100%",
        "header-small-medium": "100%",
        "header-small-small": "100%",
        "header-small-xSmall": "100%",
        "header-small-xSmall-underline": "100%",
        "header-small-xxSmall": "100%",
        "rte-large-h1": "100%",
        "rte-large-h2": "100%",
        "rte-large-h3": "100%",
        "rte-large-h4": "100%",
        "rte-large-h5": "100%",
        "rte-large-h6": "100%",
        "rte-medium-h1": "100%",
        "rte-medium-h2": "100%",
        "rte-medium-h3": "100%",
        "rte-medium-h4": "100%",
        "rte-medium-h5": "100%",
        "rte-medium-h6": "100%",
        "rte-small-h1": "100%",
        "rte-small-h2": "100%",
        "rte-small-h3": "100%",
        "rte-small-h4": "100%",
        "rte-small-h5": "100%",
        "rte-small-h6": "100%"
      },
      "textTransform": {},
      "textDecoration": {
        "header-large-xxLarge": "none",
        "header-large-xLarge": "none",
        "header-large-large": "none",
        "header-large-medium": "none",
        "header-large-small": "none",
        "header-large-xSmall": "none",
        "header-large-xSmall-underline": "underline",
        "header-large-xxSmall": "none",
        "header-medium-xxLarge": "none",
        "header-medium-xLarge": "none",
        "header-medium-large": "none",
        "header-medium-medium": "none",
        "header-medium-small": "none",
        "header-medium-xSmall": "none",
        "header-medium-xSmall-underline": "underline",
        "header-medium-xxSmall": "none",
        "header-small-xxLarge": "none",
        "header-small-xLarge": "none",
        "header-small-large": "none",
        "header-small-medium": "none",
        "header-small-small": "none",
        "header-small-xSmall": "none",
        "header-small-xSmall-underline": "underline",
        "header-small-xxSmall": "none",
        "rte-large-h1": "none",
        "rte-large-h2": "none",
        "rte-large-h3": "none",
        "rte-large-h4": "none",
        "rte-large-h5": "none",
        "rte-large-h6": "underline",
        "rte-medium-h1": "none",
        "rte-medium-h2": "none",
        "rte-medium-h3": "none",
        "rte-medium-h4": "none",
        "rte-medium-h5": "none",
        "rte-medium-h6": "underline",
        "rte-small-h1": "none",
        "rte-small-h2": "none",
        "rte-small-h3": "none",
        "rte-small-h4": "none",
        "rte-small-h5": "none",
        "rte-small-h6": "underline"
      }
    },
    "PIDS": {
      "fontSize": {
        "header-large-xxLarge": "64px",
        "header-large-xLarge": "56px",
        "header-large-large": "48px",
        "header-large-medium": "36px",
        "header-large-small": "32px",
        "header-large-xSmall": "24px",
        "header-large-xSmall-underline": "24px",
        "header-large-xxSmall": "20px",
        "header-medium-xxLarge": "48px",
        "header-medium-xLarge": "40px",
        "header-medium-large": "36px",
        "header-medium-medium": "28px",
        "header-medium-small": "24px",
        "header-medium-xSmall": "20px",
        "header-medium-xSmall-underline": "20px",
        "header-medium-xxSmall": "18px",
        "header-small-xxLarge": "48px",
        "header-small-xLarge": "40px",
        "header-small-large": "32px",
        "header-small-medium": "28px",
        "header-small-small": "24px",
        "header-small-xSmall": "18px",
        "header-small-xSmall-underline": "18px",
        "header-small-xxSmall": "16px",
        "rte-large-h1": "48px",
        "rte-large-h2": "40px",
        "rte-large-h3": "36px",
        "rte-large-h4": "32px",
        "rte-large-h5": "24px",
        "rte-large-h6": "20px",
        "rte-medium-h1": "40px",
        "rte-medium-h2": "36px",
        "rte-medium-h3": "28px",
        "rte-medium-h4": "24px",
        "rte-medium-h5": "20px",
        "rte-medium-h6": "18px",
        "rte-small-h1": "40px",
        "rte-small-h2": "32px",
        "rte-small-h3": "28px",
        "rte-small-h4": "24px",
        "rte-small-h5": "18px",
        "rte-small-h6": "16px"
      },
      "fontFamily": {
        "header-large-xxLarge": "GothamSSmBold",
        "header-large-xLarge": "GothamSSmBold",
        "header-large-large": "GothamSSmBold",
        "header-large-medium": "GothamSSmBold",
        "header-large-small": "GothamSSmBold",
        "header-large-xSmall": "GothamSSmBold",
        "header-large-xSmall-underline": "GothamSSmBold",
        "header-large-xxSmall": "GothamSSmBold",
        "header-medium-xxLarge": "GothamSSmMedium",
        "header-medium-xLarge": "GothamSSmMedium",
        "header-medium-large": "GothamSSmMedium",
        "header-medium-medium": "GothamSSmMedium",
        "header-medium-small": "GothamSSmMedium",
        "header-medium-xSmall": "GothamSSmMedium",
        "header-medium-xSmall-underline": "GothamSSmMedium",
        "header-medium-xxSmall": "GothamSSmMedium",
        "header-small-xxLarge": "GothamSSmBook",
        "header-small-xLarge": "GothamSSmBook",
        "header-small-large": "GothamSSmBook",
        "header-small-medium": "GothamSSmBook",
        "header-small-small": "GothamSSmBook",
        "header-small-xSmall": "GothamSSmBook",
        "header-small-xSmall-underline": "GothamSSmBook",
        "header-small-xxSmall": "GothamSSmBook",
        "rte-large-h1": "GothamSSmBold",
        "rte-large-h2": "GothamSSmBold",
        "rte-large-h3": "GothamSSmBold",
        "rte-large-h4": "GothamSSmBold",
        "rte-large-h5": "GothamSSmBold",
        "rte-large-h6": "GothamSSmBold",
        "rte-medium-h1": "GothamSSmBold",
        "rte-medium-h2": "GothamSSmBold",
        "rte-medium-h3": "GothamSSmBold",
        "rte-medium-h4": "GothamSSmBold",
        "rte-medium-h5": "GothamSSmBold",
        "rte-medium-h6": "GothamSSmBold",
        "rte-small-h1": "GothamSSmBold",
        "rte-small-h2": "GothamSSmBold",
        "rte-small-h3": "GothamSSmBold",
        "rte-small-h4": "GothamSSmBold",
        "rte-small-h5": "GothamSSmBold",
        "rte-small-h6": "GothamSSmBold"
      },
      "fontWeight": {
        "header-large-xxLarge": "700",
        "header-large-xLarge": "700",
        "header-large-large": "700",
        "header-large-medium": "700",
        "header-large-small": "700",
        "header-large-xSmall": "700",
        "header-large-xSmall-underline": "700",
        "header-large-xxSmall": "700",
        "header-medium-xxLarge": "700",
        "header-medium-xLarge": "700",
        "header-medium-large": "700",
        "header-medium-medium": "700",
        "header-medium-small": "700",
        "header-medium-xSmall": "700",
        "header-medium-xSmall-underline": "700",
        "header-medium-xxSmall": "700",
        "header-small-xxLarge": "700",
        "header-small-xLarge": "700",
        "header-small-large": "700",
        "header-small-medium": "700",
        "header-small-small": "700",
        "header-small-xSmall": "700",
        "header-small-xSmall-underline": "700",
        "header-small-xxSmall": "700",
        "rte-large-h1": "700",
        "rte-large-h2": "700",
        "rte-large-h3": "700",
        "rte-large-h4": "700",
        "rte-large-h5": "700",
        "rte-large-h6": "700",
        "rte-medium-h1": "700",
        "rte-medium-h2": "700",
        "rte-medium-h3": "700",
        "rte-medium-h4": "700",
        "rte-medium-h5": "700",
        "rte-medium-h6": "700",
        "rte-small-h1": "700",
        "rte-small-h2": "700",
        "rte-small-h3": "700",
        "rte-small-h4": "700",
        "rte-small-h5": "700",
        "rte-small-h6": "700"
      },
      "lineHeight": {
        "header-large-xxLarge": "120%",
        "header-large-xLarge": "120%",
        "header-large-large": "120%",
        "header-large-medium": "120%",
        "header-large-small": "120%",
        "header-large-xSmall": "120%",
        "header-large-xSmall-underline": "120%",
        "header-large-xxSmall": "120%",
        "header-medium-xxLarge": "120%",
        "header-medium-xLarge": "120%",
        "header-medium-large": "120%",
        "header-medium-medium": "120%",
        "header-medium-small": "120%",
        "header-medium-xSmall": "120%",
        "header-medium-xSmall-underline": "120%",
        "header-medium-xxSmall": "120%",
        "header-small-xxLarge": "120%",
        "header-small-xLarge": "120%",
        "header-small-large": "120%",
        "header-small-medium": "120%",
        "header-small-small": "120%",
        "header-small-xSmall": "120%",
        "header-small-xSmall-underline": "120%",
        "header-small-xxSmall": "120%",
        "rte-large-h1": "120%",
        "rte-large-h2": "120%",
        "rte-large-h3": "120%",
        "rte-large-h4": "120%",
        "rte-large-h5": "120%",
        "rte-large-h6": "120%",
        "rte-medium-h1": "120%",
        "rte-medium-h2": "120%",
        "rte-medium-h3": "120%",
        "rte-medium-h4": "120%",
        "rte-medium-h5": "120%",
        "rte-medium-h6": "120%",
        "rte-small-h1": "120%",
        "rte-small-h2": "120%",
        "rte-small-h3": "120%",
        "rte-small-h4": "120%",
        "rte-small-h5": "120%",
        "rte-small-h6": "120%"
      },
      "letterSpacing": {
        "header-large-xxLarge": "100%",
        "header-large-xLarge": "100%",
        "header-large-large": "100%",
        "header-large-medium": "100%",
        "header-large-small": "100%",
        "header-large-xSmall": "100%",
        "header-large-xSmall-underline": "100%",
        "header-large-xxSmall": "100%",
        "header-medium-xxLarge": "100%",
        "header-medium-xLarge": "100%",
        "header-medium-large": "100%",
        "header-medium-medium": "100%",
        "header-medium-small": "100%",
        "header-medium-xSmall": "100%",
        "header-medium-xSmall-underline": "100%",
        "header-medium-xxSmall": "100%",
        "header-small-xxLarge": "100%",
        "header-small-xLarge": "100%",
        "header-small-large": "100%",
        "header-small-medium": "100%",
        "header-small-small": "100%",
        "header-small-xSmall": "100%",
        "header-small-xSmall-underline": "100%",
        "header-small-xxSmall": "100%",
        "rte-large-h1": "100%",
        "rte-large-h2": "100%",
        "rte-large-h3": "100%",
        "rte-large-h4": "100%",
        "rte-large-h5": "100%",
        "rte-large-h6": "100%",
        "rte-medium-h1": "100%",
        "rte-medium-h2": "100%",
        "rte-medium-h3": "100%",
        "rte-medium-h4": "100%",
        "rte-medium-h5": "100%",
        "rte-medium-h6": "100%",
        "rte-small-h1": "100%",
        "rte-small-h2": "100%",
        "rte-small-h3": "100%",
        "rte-small-h4": "100%",
        "rte-small-h5": "100%",
        "rte-small-h6": "100%"
      },
      "textTransform": {},
      "textDecoration": {
        "header-large-xxLarge": "none",
        "header-large-xLarge": "none",
        "header-large-large": "none",
        "header-large-medium": "none",
        "header-large-small": "none",
        "header-large-xSmall": "none",
        "header-large-xSmall-underline": "underline",
        "header-large-xxSmall": "none",
        "header-medium-xxLarge": "none",
        "header-medium-xLarge": "none",
        "header-medium-large": "none",
        "header-medium-medium": "none",
        "header-medium-small": "none",
        "header-medium-xSmall": "none",
        "header-medium-xSmall-underline": "underline",
        "header-medium-xxSmall": "none",
        "header-small-xxLarge": "none",
        "header-small-xLarge": "none",
        "header-small-large": "none",
        "header-small-medium": "none",
        "header-small-small": "none",
        "header-small-xSmall": "none",
        "header-small-xSmall-underline": "underline",
        "header-small-xxSmall": "none",
        "rte-large-h1": "none",
        "rte-large-h2": "none",
        "rte-large-h3": "none",
        "rte-large-h4": "none",
        "rte-large-h5": "none",
        "rte-large-h6": "underline",
        "rte-medium-h1": "none",
        "rte-medium-h2": "none",
        "rte-medium-h3": "none",
        "rte-medium-h4": "none",
        "rte-medium-h5": "none",
        "rte-medium-h6": "underline",
        "rte-small-h1": "none",
        "rte-small-h2": "none",
        "rte-small-h3": "none",
        "rte-small-h4": "none",
        "rte-small-h5": "none",
        "rte-small-h6": "underline"
      }
    }
  },
  "boxShadow": {
    "Autan": {
      "components-share-list": "0px 12px 16px -8px rgba(0, 0, 0, 0.40)",
      "components-header-menu": "0px 12px 16px -8px rgba(0, 0, 0, 0.20)"
    },
    "AutanDefense": {
      "components-share-list": "0px 12px 16px -8px rgba(0, 0, 0, 0.40)",
      "components-header-menu": "0px 12px 16px -8px rgba(0, 0, 0, 0.20)"
    },
    "Baygon": {
      "components-share-list": "0px 12px 16px -8px rgba(0, 0, 0, 0.40)",
      "components-header-menu": "0px 12px 16px -8px rgba(0, 0, 0, 0.20)"
    },
    "Corporate": {
      "components-share-list": "0px 12px 16px -8px rgba(0, 0, 0, 0.40)",
      "components-header-menu": "0px 12px 16px -8px rgba(0, 0, 0, 0.20)"
    },
    "DART": {
      "components-share-list": "0px 0px 0px 0px rgba(0, 0, 0, 0.00)",
      "components-header-menu": "0px 12px 16px -8px rgba(0, 0, 0, 0.20)"
    },
    "Drano": {
      "components-share-list": "0px 12px 16px -8px rgba(0, 0, 0, 0.40)",
      "components-header-menu": "0px 12px 16px -8px rgba(0, 0, 0, 0.20)"
    },
    "Duck": {
      "components-share-list": "0px 12px 16px -8px rgba(0, 0, 0, 0.40)",
      "components-header-menu": "0px 12px 16px -8px rgba(0, 0, 0, 0.20)"
    },
    "Exposis": {
      "components-share-list": "0px 12px 16px -8px rgba(0, 0, 0, 0.40)",
      "components-header-menu": "0px 12px 16px -8px rgba(0, 0, 0, 0.20)"
    },
    "Glade": {
      "components-share-list": "0px 12px 16px -8px rgba(0, 0, 0, 0.40)",
      "components-header-menu": "0px 12px 16px -8px rgba(0, 0, 0, 0.20)"
    },
    "Lysoform": {
      "components-share-list": "0px 12px 16px -8px rgba(0, 0, 0, 0.40)",
      "components-header-menu": "0px 12px 16px -8px rgba(0, 0, 0, 0.20)"
    },
    "MrMuscle": {
      "components-share-list": "0px 12px 16px -8px rgba(0, 0, 0, 0.40)",
      "components-header-menu": "0px 12px 16px -8px rgba(0, 0, 0, 0.20)"
    },
    "Off": {
      "components-share-list": "0px 12px 16px -8px rgba(0, 0, 0, 0.40)",
      "components-header-menu": "0px 12px 16px -8px rgba(0, 0, 0, 0.20)"
    },
    "OffDefense": {
      "components-share-list": "0px 12px 16px -8px rgba(0, 0, 0, 0.40)",
      "components-header-menu": "0px 12px 16px -8px rgba(0, 0, 0, 0.20)"
    },
    "PIDS": {
      "components-share-list": "0px 12px 16px -8px rgba(0, 0, 0, 0.40)",
      "components-header-menu": "0px 12px 16px -8px rgba(0, 0, 0, 0.20)"
    },
    "Pledge": {
      "components-share-list": "0px 12px 16px -8px rgba(0, 0, 0, 0.40)",
      "components-header-menu": "0px 12px 16px -8px rgba(0, 0, 0, 0.20)"
    },
    "Pyrel": {
      "components-share-list": "0px 12px 16px -8px rgba(0, 0, 0, 0.40)",
      "components-header-menu": "0px 12px 16px -8px rgba(0, 0, 0, 0.20)"
    },
    "Raid": {
      "components-share-list": "0px 12px 16px -8px rgba(0, 0, 0, 0.40)",
      "components-header-menu": "0px 12px 16px -8px rgba(0, 0, 0, 0.20)"
    },
    "Windex": {
      "components-share-list": "0px 12px 16px -8px rgba(0, 0, 0, 0.40)",
      "components-header-menu": "0px 12px 16px -8px rgba(0, 0, 0, 0.20)"
    },
    "Ziploc": {
      "components-share-list": "0px 12px 16px -8px rgba(0, 0, 0, 0.40)",
      "components-header-menu": "0px 12px 16px -8px rgba(0, 0, 0, 0.20)"
    }
  }
};
