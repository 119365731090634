// Global
import { ImageField, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useContext } from 'react';
import { tv } from 'tailwind-variants';
import { useI18n } from 'next-localization';

// Lib
import { ComponentProps } from 'lib/component-props';
import { ALL_THEMES, useTheme } from 'lib/context/ThemeContext';
import { CardComponents, ProductAndArticleComponent } from 'lib/templates/Feature.Dart.model';
import { Common } from 'lib/templates/Foundation.Dart.model';
import { ItemEx } from 'lib/templates/_.Sitecore.Override';
import { getStaticAssetUrl } from 'lib/utils/public-url-utils';

// Local
import { ProductCardContext } from 'components/authorable/ProductCard/ProductCard';
import Button from 'helpers/Button/Button';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import Label from 'helpers/Label/Label';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { stripHtml } from 'lib/utils/regex';

export type Badge = CardComponents.ProductCard.Badge;

export type Label = Common.Label.Label;

export type ProductInfo = ProductAndArticleComponent.ProductInformation.ProductInformation;

export type ProductCard = ItemEx &
  CardComponents.ProductCard.ProductCardItem &
  Badge & { indexOfItem: number; isBadgeFullWidth: string | undefined };

export type ProductCardProps = ComponentProps & CardComponents.ProductCard.ProductCardsList;

const themeVariants = ALL_THEMES.reduce(
  (acc, curr) => ((acc[curr] = {}), acc),
  {} as Record<string, object>
);

const tailwindVariants = tv({
  slots: {
    badgeBG: [
      'rounded-themes-spacing-radius-large-badge',
      'block',
      'font-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'px-[34px]',
      'py-[8px]',
      'text-center',
      'text-components-product-card-color-badge-text',
    ],
    badgeContainer: ['absolute', 'z-10'],
    buttonClasses: [
      'border-[3px]',
      'border-components-button-color-outline-white-outline-stroke',
      'group',
      'h-8',
      'mr-2',
      'rounded-themes-spacing-radius-large-button',
      'w-8',
      'active:bg-components-button-color-outline-white-pressed-bg',
      'active:border-components-button-color-outline-white-pressed-stroke',
      'disabled:bg-transparent',
      'disabled:border-components-button-color-outline-white-disabled-stroke',
      'disabled:border-components-button-color-outline-white-disabled-stroke',
      'disabled:pointer-events-none',
      'disabled:text-components-button-color-outline-white-disabled-text',
      'hover:bg-components-button-color-outline-white-hover-bg',
      'hover:border-components-button-color-outline-white-hover-stroke',
    ],
    cardContainer: [
      'flex',
      'flex-1',
      'flex-col',
      'gap-themes-general-card-spacing-large-eyebrow-margin-bottom',
    ],
    cardContentWrapper: [
      'flex',
      'flex-col',
      'flex-1',
      'justify-between',
      'py-components-product-card-spacing-large-padding-content-y',
      'px-components-product-card-spacing-large-padding-content-x',
      'gap-themes-general-card-spacing-large-body-margin-bottom',
    ],
    cardImgWrapper: [
      'aspect-card',
      'block',
      'flex',
      'justify-center',
      'relative',
      'w-full',
      'max-h-[330px]',
    ],
    cardLinkWrapper: ['block', 'w-full'],
    cardMedia: ['object-cover', 'min-h-full'],
    cardWrapper: [
      'bg-components-product-card-color-content-bg',
      'flex-col',
      'flex',
      'relative',
      'overflow-hidden',
      'rounded-themes-spacing-radius-large-card',
      'border-components-product-card-spacing-border-width',
      'border-components-product-card-color-border',
      'border-solid',
      'w-full',
    ],
    cta: ['w-full'],
    ctaWrapper: ['[&>a]:w-full'],
    labelBg: ['bg-components-product-card-color-content-bg'],
    productDescription: [
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'line-clamp-2',
      'text-components-product-card-color-description',
      '[&>.richtext-wrapper>div>p]:p-0',
      '[&>.richtext-wrapper>div>p]:pt-[3px]',
      'md:leading-bodySans-medium',
      'md:font-bodySans-medium',
      'md:text-bodySans-medium',
      'mb-[-2px]',
      'pt-[4px]',
      'border-b-4',
      'pb-[5px]',
      'border-transparent',
      'max-h-[50px]',
    ],
    productInfoContainer: [
      'flex',
      'flex-1',
      'flex-col',
      'justify-between',
      'gap-themes-general-card-spacing-large-title-margin-bottom',
      '[&_.pr-snippet.pr-category-snippet]:!mb-0',
      '[&_.pr-snippet-stars.pr-snippet-stars-png]:!mb-0',
    ],
    productInfoTitleRatingsContainer: ['flex', 'flex-col'],
    ratingWrapper: ['mt-themes-general-card-spacing-large-title-margin-bottom'],
    productTitle: [
      'font-header-small-small',
      'leading-header-small-small',
      'text-header-small-small',
      'text-components-product-card-color-title',
      'md:font-header-large-xSmall',
      'md:leading-header-large-xSmall',
      'md:text-header-large-xSmall',
    ],
    rating: ['flex', 'flex-row', 'mb-themes-general-card-spacing-large-title-margin-bottom'],
    starIcon: ['fill-colors-accent-1-400'],
  },
  variants: {
    isBadgeFullWidth: {
      '1': { badgeBG: ['h-[39px]'], badgeContainer: ['w-full'] },
      undefined: {
        badgeContainer: ['top-0', 'left-1/2', 'transform', '-translate-x-1/2'],
      },
    },
    brand: {
      ...themeVariants,
      Pyrel: {
        badgeContainer: ['!top-0', '!left-0', 'translate-x-0'],
        badgeBG: ['!bg-bottom', '!pb-3'],
      },
      Pledge: {
        badgeContainer: ['!top-[24px]', '!left-0', 'translate-x-0'],
        badgeBG: ['!bg-bottom', '!py-[20px]', '!rounded-none'],
      },
      Lysoform: {
        badgeContainer: ['!top-[9.5px]', '!left-0', 'translate-x-0'],
        badgeBG: ['!bg-bottom', '!rounded-none'],
      },
      Baygon: {
        labelBg: [
          '!bg-components-label-color-on-white-background-bg-default',
          'hover:!bg-components-label-color-on-white-background-bg-hover',
        ],
        badgeContainer: ['top-[20px]', 'left-[24px]', 'translate-x-0'],
        badgeBG: ['!bg-center'],
      },
      Duck: {
        badgeContainer: ['!left-0', 'translate-x-0'],
        badgeBG: ['!bg-bottom'],
      },
      Ziploc: {
        badgeBG: ['h-[30px]', 'min-w-[130px]'],
      },
      MrMuscle: {
        badgeContainer: ['top-[20px]', 'left-[24px]', 'translate-x-0'],
        badgeBG: ['!bg-center'],
      },
    },
  },
  compoundVariants: [
    {
      brand: 'Baygon',
      isBadgeFullWidth: '1',
      className: {
        badgeContainer: ['!top-0', '!left-0', 'translate-x-0'],
        badgeBG: ['!bg-center'],
      },
    },
    {
      brand: 'MrMuscle',
      isBadgeFullWidth: '1',
      className: {
        badgeContainer: ['!top-0', '!left-0', 'translate-x-0'],
        badgeBG: ['!bg-center'],
      },
    },
  ],
});

const ProductSingleCard = ({ indexOfItem, ...props }: ProductCard): JSX.Element => {
  const { ctaLink } = props?.fields || {};

  const productCardUid = useContext(ProductCardContext);
  const { themeName } = useTheme();

  const {
    badgeBG,
    badgeContainer,
    cardContainer,
    cardContentWrapper,
    cardImgWrapper,
    cardLinkWrapper,
    cardMedia,
    cardWrapper,
    ctaWrapper,
    labelBg,
    productDescription,
    productInfoContainer,
    productInfoTitleRatingsContainer,
    ratingWrapper,
    productTitle,
  } = tailwindVariants({
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    brand: themeName as string,
    isBadgeFullWidth: props?.isBadgeFullWidth as '1' | undefined,
  });

  const badge = props?.fields?.badge as Badge;
  const badgeSVG = `ProductCard/Breakpoint=Medium,Brand=${themeName}`;

  const productInfo = props?.fields?.productInformation as ProductInfo;
  const pageID = productInfo?.fields?.oldPageId?.value || props?.fields?.productInformation?.id;
  const label = productInfo?.fields?.label as unknown as Label[];
  const svgBG = `${getStaticAssetUrl()}/assets/${badgeSVG}.svg`;
  const ariaLabel = stripHtml(productInfo?.fields?.headline?.value as string);
  const i18n = useI18n();
  const About = i18n.t('AccessibilityAbout') ? i18n.t('AccessibilityAbout') : 'about';

  return (
    <div className={cardWrapper()}>
      {badge?.fields?.badgeTitle?.value && (
        <div className={badgeContainer()} aria-hidden="true">
          <span
            className={badgeBG()}
            style={{
              backgroundImage: `url(${svgBG})`,
              backgroundSize: 'cover',
              backgroundRepeat: props?.isBadgeFullWidth ? 'no-repeat' : 'repeat',
            }}
          >
            {badge?.fields?.badgeTitle?.value}
          </span>
        </div>
      )}
      {ctaLink?.value?.href && (
        <LinkWrapper
          className={cardImgWrapper()}
          showLinkTextWithChildrenPresent={false}
          field={ctaLink}
          aria-label={ariaLabel}
        >
          <ImageWrapper
            field={productInfo?.fields?.primaryImage as ImageField}
            layout="responsive"
            className={cardMedia()}
          />
        </LinkWrapper>
      )}
      <div className={cardContentWrapper()}>
        <div className={cardContainer()}>
          {label?.length && label?.[0]?.fields?.link?.value?.href && (
            <Label
              color={'white'}
              link={label?.[0]?.fields?.link as LinkField}
              backgroundColor={labelBg()}
            />
          )}
          <div className={productInfoContainer()}>
            <div className={productInfoTitleRatingsContainer()}>
              {productInfo?.fields?.headline && (
                <div className={productTitle()}>
                  {props?.fields?.ctaLink?.value?.href && (
                    <LinkWrapper
                      className={cardLinkWrapper()}
                      showLinkTextWithChildrenPresent={false}
                      field={props?.fields?.ctaLink}
                      aria-label={ariaLabel}
                    >
                      <RichTextA11yWrapper field={productInfo?.fields?.headline} />
                    </LinkWrapper>
                  )}
                </div>
              )}
              {productInfo?.fields?.ratingAndReview && (
                <div
                  id={`category-snippet-${productCardUid}-${pageID}-${indexOfItem}`}
                  className={ratingWrapper()}
                ></div>
              )}
            </div>
            {productInfo?.fields?.description && (
              <div className={productDescription()}>
                <RichTextA11yWrapper field={productInfo?.fields?.description} />
              </div>
            )}
          </div>
        </div>
        {ctaLink?.value?.href && (
          <div className={ctaWrapper()}>
            <Button
              srOnlyText={ariaLabel}
              label={ctaLink?.value?.text}
              type="fill"
              title={`${ctaLink?.value?.title}` || `${ctaLink?.value?.text} ${About} ${ariaLabel}`}
              tag="a"
              target={ctaLink?.value?.target}
              href={props?.fields?.ctaLink?.value?.href}
              gtmEvent={{
                event: 'cta_click',
                type: 'primary',
                'gtm.element.dataset.gtmLinkUrl': props?.fields?.ctaLink?.value?.href,
                'gtm.element.dataset.gtmLinkName': props?.fields?.ctaLink?.value?.text,
                'gtm.element.dataset.gtmDatasourceId': props?.fields?.productInformation?.id,
                'gtm.element.dataset.gtmDatasourceName': props?.fields?.productInformation?.name,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductSingleCard;
