// Global
import { tv } from 'tailwind-variants';

export const tailwindVariants = tv({
  slots: {
    base: [
      'px-themes-general-surface-spacing-small-padding-x',
      'md:px-themes-general-surface-spacing-large-padding-x',
      'bg-components-breadcrumb-color-bg',
      'flex-row',
      'flex',
      'gap-components-breadcrumb-spacing-small-space-between',
      'items-center',
      'justify-start',
      'no-scrollbar',
      'py-spacing-spacing-5',
      'w-full',
      'whitespace-nowrap',
    ],
    currentPageContainer: [
      'flex-row',
      'flex',
      'gap-spacing-spacing-1',
      'items-center',
      'justify-start',
      'relative',
      'shrink-0',
    ],
    currentPageText: [
      'flex',
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'items-center',
      'justify-start',
      'relative',
      'text-components-breadcrumb-color-breadcrumb-link-active',
      'text-left',
      'tracking-tighter',
    ],
    link: [
      'flex',
      'items-center',
      'justify-start',
      'relative',
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'text-components-breadcrumb-color-breadcrumb-link-default',
      'text-left',
      'tracking-tighter',
      'hover:text-components-breadcrumb-color-breadcrumb-link-hover',
      'hover:underline',
    ],
    linkContainer: [
      'flex-row',
      'flex',
      'gap-spacing-spacing-1',
      'items-center',
      'justify-start',
      'relative',
      'shrink-0',
    ],
    linkIcon: [
      'fill-components-breadcrumb-color-icon-divider',
      'shrink-0',
      '[&>svg]:w-4',
      '[&>svg]:h-4',
    ],
    wrapper: [
      'flex',
      'flex-1',
      'flex-nowrap',
      'flex-row',
      'gap-components-breadcrumb-spacing-small-space-between',
      'items-center',
      'justify-start',
      'no-scrollbar',
      'overflow-y-clip',
      'overflow-x-auto',
      'relative',
      'whitespace-nowrap',
      'p-1',
    ],
    innerWrapper: ['flex', 'gap-spacing-spacing-1'],
    componentBG: ['bg-components-breadcrumb-color-bg'],
    gradient: ['absolute', 'w-[12px]', 'h-[36px]', 'right-[16px]'],
  },
});
