// GLobal
import {
  FacetPayloadType,
  FilterAnd,
  FilterEqual,
  SearchResultsInitialState,
  SearchResultsStoreState,
  WidgetDataType,
  useSearchResults,
  useSearchResultsSelectedFilters,
  widget,
} from '@sitecore-search/react';
import {
  AccordionFacets,
  CardViewSwitcher,
  FacetItem,
  Pagination,
  Presence,
  SearchResultsAccordionFacets,
  SortSelect,
} from '@sitecore-search/ui';
import { Text, Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { SearchResultsStoreSelectedFacet } from '@sitecore-search/widgets';
import { useEffect, useState, useRef, useCallback } from 'react';
import Link from 'next/link';
import { useI18n } from 'next-localization';
import Parser from 'html-react-parser';
import { sendGTMEvent } from '@next/third-parties/google';

// Lib
import { DartPages } from 'lib/templates/Project.Dart.model';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import Button from 'helpers/Button/Button';
import Label, { ColorTypes } from 'helpers/Label/Label';
import GoogleMaterialSymbol from 'helpers/GoogleMaterialSymbol/GoogleMaterialSymbol';
import styles from './styles.module.css';
import tailwindVarianCard from './tailwindVariant';
import {
  tailwindArticleList,
  tailwindMain,
  tailwindProductTopBar,
  tailwindVariantProductSearch,
  tailwindVariants,
} from './tailwindVariant';
import { focusTrap } from 'src/stories/helpers/CustomeHelpers/FocusTrap';
import { stripHtml } from 'lib/utils/regex';
import SVG from 'helpers/SVG/SVG';
import { useTheme } from 'lib/context/ThemeContext';
import fallback from 'lib/fallback/fallback';
import { ComponentVariants } from 'lib/context/ComponentVariants';

type ProductModel = {
  id: string;
  name: string;
  article_title: string;
  article_author_name: string;
  article_category: string;
  article_subcategory: string;
  article_label: string;
  description: string;
  article_publish_date: string;
  image_url: string;
  url: string;
  source_id?: string;
  target?: string;
  label_url?: string;
};

type SearchResultsProps = {
  defaultSortType?: SearchResultsStoreState['sortType'];
  defaultPage?: SearchResultsStoreState['page'];
  defaultItemsPerPage?: SearchResultsStoreState['itemsPerPage'];
  defaultKeyphrase?: SearchResultsStoreState['keyphrase'];
  resultsPerPage?: Field<number>;
  filterLabel?: Field<string>;
  noSearchResult?: Field<string>;
  sortLabel?: Field<string>;
  filterButtonLabel?: Field<string>;
  sortByLabel?: Field<string>;
  listingPageName?: string;
  primaryCTAColor?: Field<string>;
  primaryCTAType?: Field<string>;
  sortResultBy?: string;
};

type InitialState = SearchResultsInitialState<
  'itemsPerPage' | 'keyphrase' | 'page' | 'sortType' | 'selectedFacets'
>;

export type CategoryListPage = DartPages.CategoryListPage;
type SearchSettings = {
  previewSource: string;
  source: string;
};
const defaultCardView = CardViewSwitcher.CARD_VIEW_GRID;

const buildRangeLabel = (min: number | undefined, max: number | undefined): string => {
  return typeof min === 'undefined'
    ? `< $${max}`
    : typeof max === 'undefined'
    ? ` > $${min}`
    : `$${min} - $${max}`;
};

// Add fallback component variant color
const fallbackComponentVariantColor = fallback?.componentVariants?.value;
const fallbackComponentVariantType = fallback?.componentVariants?.type;

export const ProductListWithSearchComponent = ({
  defaultSortType = 'featured_desc',
  defaultPage = 1,
  defaultKeyphrase = '',
  defaultItemsPerPage = 20,
  filterLabel,
  noSearchResult,
  resultsPerPage,
  sortLabel,
  filterButtonLabel,
  sortByLabel,
  listingPageName,
  primaryCTAColor,
  primaryCTAType,
  sortResultBy,
}: SearchResultsProps) => {
  const { sitecoreContext } = useSitecoreContext();
  const searchSettings = sitecoreContext?.searchDetails as SearchSettings;
  //const searchSourceIds = searchSettings?.source?.split('|') || [];
  const searchSourceIds = process.env.IS_PREVIEW_SITE
    ? searchSettings?.previewSource?.split('|') || []
    : searchSettings?.source?.split('|') || [];
  const categoryPage = sitecoreContext?.route as CategoryListPage;

  defaultSortType = sortResultBy || defaultSortType;

  const preSelectFacetValue =
    categoryPage?.fields?.isCategoryBasedOnSearchResult?.value &&
    categoryPage?.fields?.categoryNameForSearchIndex?.value
      ? {
          facetId:
            categoryPage?.fields?.categoryType?.value == 'Category'
              ? 'article_category'
              : categoryPage?.fields?.categoryType?.value == 'Subcategory'
              ? 'article_subcategory'
              : '',
          facetValueText: categoryPage?.fields?.categoryNameForSearchIndex?.value,
        }
      : null;

  const filterQuery = new FilterAnd([
    new FilterEqual('pagetype', 'Article'),
    new FilterEqual('article_listing_page', listingPageName),
  ]);

  const {
    widgetRef,
    actions: { onPageNumberChange, onRemoveFilter, onSortChange, onFacetClick, onClearFilters },
    state: { sortType, page, itemsPerPage },
    queryResult: {
      isFetching,
      data: {
        total_item: totalItems = 0,
        sort: { choices: sortChoices = [] } = {},
        facet: facets = [],
        content: products = [],
      } = {},
    },
  } = useSearchResults<ProductModel, InitialState>({
    query: (query) => {
      query
        .getRequest()
        .setSources(searchSourceIds)
        .setSearchFilter(filterQuery)
        .setSearchFacetAll(false)
        .setSearchFacetTypes([
          {
            name: 'article_category',
          },
          {
            name: 'article_subcategory',
          },
        ])
        .getSearchFacet();
    },
    config: {
      facets: {
        article_category: {
          type: 'text',
        },
        article_subcategory: {
          type: 'text',
        },
      },
    },
    state: {
      sortType: defaultSortType,
      page: defaultPage,
      itemsPerPage: (resultsPerPage?.value as number) || defaultItemsPerPage,
      keyphrase: defaultKeyphrase,
      selectedFacets: preSelectFacetValue != null ? [preSelectFacetValue] : [],
    },
  });

  const [dir, setDir] = useState(defaultCardView);
  const onToggle = (value = defaultCardView) => setDir(value);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const selectedSortIndex = sortChoices.findIndex((s) => s.name === sortType);
  const selectedOption = sortChoices.find((s) => s.name === sortType);
  const selectedFacetsFromApi = useSearchResultsSelectedFilters();

  type WindowType = 'Desktop' | 'Mobile';
  const [windowSize, setWindowSize] = useState<number>();

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState<string[]>([]);

  const [isPreviousButtonDisabled, setPreviousButtonDisabled] = useState<boolean>(false);
  const [isNextButtonDisabled, setNextButtonDisabled] = useState<boolean>(false);
  const componentVariants = ComponentVariants();

  useEffect(() => {
    const initPreviousButton = (): void => {
      if (page === 1) {
        return setPreviousButtonDisabled(true);
      } else if (page !== 1 && totalPages > 1) {
        return setPreviousButtonDisabled(false);
      } else {
        return setPreviousButtonDisabled(true);
      }
    };

    const initNextButton = (): void => {
      if ((page === 1 && totalPages === 1) || (page !== 1 && page === totalPages)) {
        return setNextButtonDisabled(true);
      } else if ((page === 1 && totalPages > 1) || (page > 1 && page !== totalPages)) {
        return setNextButtonDisabled(false);
      } else {
        return setNextButtonDisabled(true);
      }
    };

    initPreviousButton();
    initNextButton();
  }, [page, totalPages]);

  const refSearchResults = useRef<HTMLDivElement>(null);
  const firstCardFocus = useRef<HTMLDivElement>(null);
  const header = typeof window !== 'undefined' ? window.document.getElementById('header') : null;

  const scrollToSearchResults = (page: number) => {
    onPageNumberChange({ page });
    const { current } = refSearchResults;

    if (firstCardFocus.current) {
      firstCardFocus.current.tabIndex = 0; // Set tabIndex to make it focusable
      firstCardFocus.current.focus();
    }
    if (current !== null) {
      setTimeout(() => {
        const currentScrollPosition = window.scrollY || window.pageYOffset;
        const elementTop = current.getBoundingClientRect().top;
        if (header) {
          const newPosition = currentScrollPosition + elementTop - header?.offsetHeight;
          window.scrollTo({ top: newPosition, behavior: 'smooth' });
        }
      }, 300);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWindowSize(window.innerWidth);
    }
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowSize]);

  const i18n = useI18n();
  let DisplayingCountForSearch = i18n.t('DisplayingCountForSearch');
  let DisplayingMobileCountForSearch = i18n.t('DisplayingMobileCountForSearch');
  const Next = i18n.t('Next');
  const Previous = i18n.t('Previous');
  const ReadMore = i18n.t('ReadMore');
  const All = i18n.t('All');
  const ClearFilters = i18n.t('ClearFilters');
  const { themeName } = useTheme();

  const buildFacetLabel = (
    selectedFacet: SearchResultsStoreSelectedFacet & { type: FacetPayloadType }
  ) => {
    const selectedFacetLabel = selectedFacet?.facetLabel?.toString() ?? '';
    const facetLabel = i18n.t(selectedFacetLabel)
      ? i18n.t(selectedFacetLabel)
      : selectedFacet?.facetLabel;
    if ('min' in selectedFacet || 'max' in selectedFacet) {
      return `${facetLabel}: ${buildRangeLabel(selectedFacet.min, selectedFacet.max)}`;
    }
    return `${facetLabel}: ${selectedFacet.valueLabel}`;
  };

  const {
    card,
    cardWrapper,
    cardHead,
    cardBody,
    labelContainer,
    cardContentGrid,
    cardTitleText,
    cardAuthorNamePublishDateGrid,
    cardDescriptionText,
    cardCtaWrapper,
    cardMedia,
    articleCardListing,
    articleCard,
    articleListContainer,
    labelBg,
  } = tailwindVarianCard({
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    brand: themeName as string,
  });

  const {
    paginationContainer,
    paginationIcon,
    paginationRoot,
    paginationPage,
    paginationPageWrapper,
    paginationDisplayPage,
    paginationGroup,
  } = tailwindVariantProductSearch();

  const {
    base,
    categoryFilterContainer,
    categoryWrapper,
    categoryList,
    categoryLabelBG,
    categoryLabel,
    category,
    filterContainer,
    layoutsButtonContainer,
    viewIcon,
    filterWrapper,
    svgWrapper,
    gradientDesktop,
    gradientMobile,
    categoryItem,
  } = tailwindProductTopBar({
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    brand: themeName as string,
  });

  const {
    articleCardList,
    cardWrapperList,
    cardMediaWrapperList,
    cardContentList,
    cardContentWrapperList,
    cardContainerList,
    cardCtaWrapperList,
    articleCardListButton,
    cardAuthorNamePublishDateList,
    cardBodyList,
    cardDescriptionTextList,
    labelContainerList,
  } = tailwindArticleList();

  const { mainContainer, loaderWrapper, filterBodyBG, searchLoaderAnimation } = tailwindMain();

  const deviceName = windowSize && windowSize >= 996 ? 'Desktop' : 'Mobile';

  const {
    searchResultsLeftWrapper,
    searchResultsLeft,
    refineTitleWrapper,
    refineTitle,
    refineTitleIconWrapper,
    refineTitleIcon,
    sortFilterWrapper,
    sortEyebrowText,
    sortFilter,
    sortFilterIconWrapper,
    sortFilterIcon,
    sortFilterContentWrapper,
    sortFilterContent,
    sortFilterOptionWrapper,
    selectionFilterWrapper,
    selectedFilterWrapper,
    selectedFilter,
    selectedFilterText,
    selectedFilterIcon,
    accordionFacetsWrapper,
    accordionFacetTriggerClass,
    accordionFacetsHeaderWrapper,
    accordionFacetsHeader,
    accordionFacetsHeaderIconWrapper,
    accordionFacetsHeaderIcon,
    accordionFacetsContentWrapper,
    accordionFacetsContentItem,
    accordionFacetsContentItemIconWrapper,
    accordionFacetsContentItemIcon,
    accordionFacetsItemLabelContainer,
    accordionFacetsItemLabelWrapper,
    accordionFacetsItemLabel,
    accordionContent,
  } = tailwindVariants({
    deviceDetect: deviceName as WindowType,
  });

  const refSearchLeftPart = useRef<HTMLDivElement>(null);
  const toggleFilterMenu = useCallback(() => {
    setIsFilterVisible(!isFilterVisible);
    setIsAccordionOpen([]);
  }, [isFilterVisible]);

  useEffect(() => {
    if (isFilterVisible) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [isFilterVisible]);

  useEffect(() => {
    const previouslyFocussed = document?.activeElement;
    isFilterVisible &&
      refSearchLeftPart?.current &&
      focusTrap(refSearchLeftPart.current, toggleFilterMenu);
    return () => {
      previouslyFocussed && (previouslyFocussed as HTMLElement).focus();
    };
  }, [isFilterVisible, toggleFilterMenu]);

  const formatDate = (date: string): string => {
    // Split the input date
    const [month, day, year] = date.split('/');

    // Convert to DD/MM/YYYY format
    const formattedDate = `${month.padStart(2, '0')}/${day.padStart(2, '0')}/${year}`;
    return formattedDate;
  };

  const paginationOf = `${itemsPerPage * (page - 1) + 1} - ${
    itemsPerPage * (page - 1) + products.length
  }`;
  const totalProductPages = totalItems as unknown as string;

  DisplayingCountForSearch = DisplayingCountForSearch.replace('{0}', paginationOf).replace(
    '{1}',
    totalProductPages
  );

  DisplayingMobileCountForSearch = DisplayingMobileCountForSearch.replace('{0}', `${page}`).replace(
    '{1}',
    `${totalPages}`
  );

  // Remove HTML tags and check if it has value or not
  function hasTextContent(htmlString: string): boolean {
    //Remove HTML tags using a regex
    const strippedText = htmlString?.replace(/<\/?[^>]+(>|$)/g, '').trim();

    //Replace common HTML entities (like &nbsp;) with a space
    const cleanedText = strippedText?.replace(/&nbsp;|&#160;/g, ' ').trim();

    // Check if there's any actual text left after cleaning
    return cleanedText?.length > 0;
  }
  return (
    <Container>
      <div ref={widgetRef} className={styles['sitecore-wrapper']}>
        <div className={mainContainer()} ref={refSearchResults}>
          {isFetching && (
            <div className={loaderWrapper()}>
              <Presence present={true}>
                <div className={searchLoaderAnimation()} />
              </Presence>
            </div>
          )}
          {totalItems > 0 && (
            <>
              {isFilterVisible && (
                <>
                  <span onClick={toggleFilterMenu} className={filterBodyBG()}></span>
                  <section
                    className={searchResultsLeftWrapper({ isFilterVisible: isFilterVisible })}
                    ref={refSearchLeftPart}
                  >
                    <div className={searchResultsLeft()}>
                      <div className={refineTitleWrapper()}>
                        <p className={refineTitle()}>{filterLabel?.value}</p>
                        <button className={refineTitleIconWrapper()} onClick={toggleFilterMenu}>
                          <GoogleMaterialSymbol
                            className={refineTitleIcon()}
                            icon={'close'}
                            variant="outlined"
                          />
                        </button>
                      </div>
                      <div className={sortFilterWrapper()}>
                        <SortSelect.Root
                          onValueChange={onSortChange}
                          onOpenChange={() => {
                            setIsDropdownOpen(!isDropdownOpen);
                          }}
                        >
                          <span className={sortEyebrowText()}>{sortLabel?.value}</span>
                          <SortSelect.Trigger className={sortFilter()}>
                            <SortSelect.SelectValue placeholder={sortByLabel?.value}>
                              {selectedSortIndex > -1 ? sortChoices[selectedSortIndex].label : ''}
                            </SortSelect.SelectValue>
                            <div className={sortFilterIconWrapper()}>
                              <GoogleMaterialSymbol
                                className={sortFilterIcon()}
                                icon={isDropdownOpen ? 'arrow_drop_up' : 'arrow_drop_down'}
                                variant="outlined"
                              />
                            </div>
                          </SortSelect.Trigger>
                          <div className={sortFilterContentWrapper()}>
                            <SortSelect.Content className={sortFilterContent()}>
                              <SortSelect.Viewport>
                                {sortChoices.map((option) => (
                                  <SortSelect.Option
                                    value={option}
                                    key={option.name}
                                    className={sortFilterOptionWrapper({
                                      isSelectedOption: selectedOption?.name === option?.name,
                                    })}
                                  >
                                    <SortSelect.OptionText>{option.label}</SortSelect.OptionText>
                                    {selectedOption?.name === option?.name && (
                                      <GoogleMaterialSymbol icon="done" />
                                    )}
                                  </SortSelect.Option>
                                ))}
                              </SortSelect.Viewport>
                            </SortSelect.Content>
                          </div>
                        </SortSelect.Root>
                      </div>
                      <div className={selectionFilterWrapper()}>
                        {!!selectedFacetsFromApi?.length && (
                          <div className={selectedFilterWrapper()}>
                            {selectedFacetsFromApi.map((selectedFacet, index) => (
                              <div
                                key={`${selectedFacet.facetId}${selectedFacet.facetLabel}${selectedFacet.valueLabel}`}
                                className={selectedFilter()}
                                onClick={() => onRemoveFilter(selectedFacet)}
                                onKeyDown={(event) => {
                                  if (event.key === 'Enter') {
                                    onRemoveFilter(selectedFacet);
                                  }
                                }}
                                tabIndex={0}
                                data-index={index}
                              >
                                <span className={selectedFilterText()}>
                                  {Parser(buildFacetLabel(selectedFacet))}
                                </span>
                                <GoogleMaterialSymbol
                                  className={selectedFilterIcon()}
                                  icon={'close'}
                                  variant="outlined"
                                />
                              </div>
                            ))}
                            {selectedFacetsFromApi.length > 0 && (
                              <div
                                onClick={onClearFilters}
                                className={selectedFilter()}
                                onKeyDown={(event) => {
                                  if (event.key === 'Enter') {
                                    onClearFilters();
                                  }
                                }}
                                tabIndex={0}
                              >
                                <span className={selectedFilterText()}>{ClearFilters}</span>
                                <GoogleMaterialSymbol
                                  className={selectedFilterIcon()}
                                  icon={'close'}
                                  variant="outlined"
                                />
                              </div>
                            )}
                          </div>
                        )}
                        <SearchResultsAccordionFacets
                          defaultFacetTypesExpandedList={[]}
                          onFacetValueClick={onFacetClick}
                        >
                          {facets?.map((f) => (
                            <AccordionFacets.Facet
                              facetId={f.name}
                              key={f.name}
                              className={accordionFacetsWrapper({
                                isAccordionOpen: isAccordionOpen.length > 0,
                              })}
                            >
                              <AccordionFacets.Trigger
                                className={accordionFacetTriggerClass()}
                                onClick={() => {
                                  setIsAccordionOpen([...isAccordionOpen, f.name]);
                                  if (isAccordionOpen.includes(f.name)) {
                                    setIsAccordionOpen(
                                      isAccordionOpen.filter((item) => item !== f.name)
                                    );
                                  }
                                }}
                              >
                                <div className={accordionFacetsHeaderWrapper()}>
                                  <AccordionFacets.Header className={accordionFacetsHeader()}>
                                    {i18n.t(Parser(f.label).toString())
                                      ? i18n.t(Parser(f.label).toString())
                                      : Parser(f.label)}
                                  </AccordionFacets.Header>
                                  <div className={accordionFacetsHeaderIconWrapper()}>
                                    <GoogleMaterialSymbol
                                      className={accordionFacetsHeaderIcon()}
                                      icon={
                                        isAccordionOpen?.includes(f.name)
                                          ? 'expand_less'
                                          : 'expand_more'
                                      }
                                      variant="outlined"
                                    />
                                  </div>
                                </div>
                              </AccordionFacets.Trigger>
                              <AccordionFacets.Content className={accordionContent()}>
                                <AccordionFacets.ValueList
                                  className={accordionFacetsContentWrapper()}
                                >
                                  {f.value.map((v, index) => (
                                    <FacetItem
                                      {...{
                                        index,
                                        facetValueId: v.id,
                                      }}
                                      key={v.id}
                                      className={accordionFacetsContentItem()}
                                    >
                                      <AccordionFacets.ItemCheckbox
                                        className={accordionFacetsContentItemIconWrapper()}
                                        tabIndex={0}
                                      >
                                        <AccordionFacets.ItemCheckboxIndicator>
                                          <GoogleMaterialSymbol
                                            className={accordionFacetsContentItemIcon()}
                                            icon={'check_small'}
                                            variant="rounded"
                                          />
                                        </AccordionFacets.ItemCheckboxIndicator>
                                      </AccordionFacets.ItemCheckbox>
                                      <AccordionFacets.ItemLabel
                                        className={accordionFacetsItemLabelContainer()}
                                      >
                                        <div className={accordionFacetsItemLabelWrapper()}>
                                          <span className={accordionFacetsItemLabel()}>
                                            {Parser(v.text)}
                                          </span>
                                          <span className={accordionFacetsItemLabel()}>
                                            {' '}
                                            {v.count && `(${v.count})`}
                                          </span>
                                        </div>
                                      </AccordionFacets.ItemLabel>
                                    </FacetItem>
                                  ))}
                                </AccordionFacets.ValueList>
                              </AccordionFacets.Content>
                            </AccordionFacets.Facet>
                          ))}
                        </SearchResultsAccordionFacets>
                      </div>
                    </div>
                  </section>
                </>
              )}
              <section className={styles['sitecore-right-area']}>
                <div className={base()}>
                  <div className={categoryFilterContainer()}>
                    <div className={categoryWrapper()}>
                      <SearchResultsAccordionFacets
                        defaultFacetTypesExpandedList={['article_category']}
                        onFacetValueClick={onFacetClick}
                        className={styles['sitecore-accordion-facets-root']}
                      >
                        {facets?.map((facet) => {
                          return facet.name === 'article_category' ? (
                            <AccordionFacets.Facet
                              facetId={facet.name}
                              key={facet.name}
                              className={styles['sitecore-accordion-facets-facet']}
                            >
                              <AccordionFacets.Content>
                                <AccordionFacets.ValueList
                                  className={categoryList()}
                                  data-orientation="horizontal"
                                >
                                  {selectedFacetsFromApi.length <= 0 ? (
                                    <button onClick={onClearFilters}>
                                      <Label
                                        text={{ value: All }}
                                        color={
                                          componentVariants?.searchListing?.labelColor as ColorTypes
                                        }
                                        backgroundColor={categoryLabelBG()}
                                        buttonType="rounded"
                                        className={categoryLabel()}
                                      />
                                    </button>
                                  ) : (
                                    <button onClick={onClearFilters} className={category()}>
                                      {All}
                                    </button>
                                  )}
                                  {facet.value.map((facetValue, index) => {
                                    function handleGTMEvent(): void {
                                      sendGTMEvent({
                                        event: 'tab',
                                        type: 'click',
                                        'gtm.element.dataset.gtmLinkName': facetValue.text,
                                      });
                                    }
                                    return (
                                      <FacetItem
                                        {...{
                                          index,
                                          facetValueId: facetValue.id,
                                        }}
                                        key={facetValue.id}
                                        className={categoryItem()}
                                      >
                                        {selectedFacetsFromApi.some(
                                          (item) => item.valueLabel === facetValue.text
                                        ) ? (
                                          <></>
                                        ) : (
                                          <AccordionFacets.ItemCheckbox
                                            className=""
                                            onClick={handleGTMEvent}
                                          >
                                            <AccordionFacets.ItemLabel className={category()}>
                                              <>
                                                <div
                                                  onClick={() => {
                                                    onPageNumberChange({ page: 1 });
                                                  }}
                                                >
                                                  <Text
                                                    encode={false}
                                                    field={{ value: facetValue.text }}
                                                    tag="span"
                                                  />
                                                </div>
                                              </>
                                            </AccordionFacets.ItemLabel>
                                          </AccordionFacets.ItemCheckbox>
                                        )}
                                        {selectedFacetsFromApi.map((selectedFacet) => {
                                          return (
                                            <>
                                              {facetValue.text === selectedFacet.valueLabel && (
                                                <div
                                                  onClick={() => {
                                                    onRemoveFilter(selectedFacet);
                                                    onPageNumberChange({ page: 1 });
                                                  }}
                                                >
                                                  <Label
                                                    text={{ value: selectedFacet.valueLabel }}
                                                    color={
                                                      componentVariants?.searchListing
                                                        ?.labelColor as ColorTypes
                                                    }
                                                    backgroundColor={categoryLabelBG()}
                                                    buttonType="rounded"
                                                    className={categoryLabel()}
                                                  />
                                                </div>
                                              )}
                                            </>
                                          );
                                        })}
                                      </FacetItem>
                                    );
                                  })}
                                </AccordionFacets.ValueList>
                              </AccordionFacets.Content>
                            </AccordionFacets.Facet>
                          ) : (
                            <></>
                          );
                        })}
                      </SearchResultsAccordionFacets>
                    </div>
                    <div className={filterWrapper()}>
                      <div
                        className={gradientDesktop()}
                        style={{
                          background:
                            'linear-gradient(90deg, rgba(255, 255, 255, 0.00) -4.17%, #FFF 104.17%)',
                        }}
                      ></div>
                      <section className={filterContainer()}>
                        <CardViewSwitcher.Root
                          onValueChange={onToggle}
                          defaultValue={defaultCardView}
                          className={layoutsButtonContainer()}
                        >
                          <CardViewSwitcher.Item
                            value="grid"
                            aria-label="Grid View"
                            className={viewIcon({
                              selected: dir === CardViewSwitcher.CARD_VIEW_GRID,
                            })}
                            onClick={() =>
                              sendGTMEvent({ event: 'switcher', type: 'click', value: 'grid' })
                            }
                          >
                            <GoogleMaterialSymbol icon={'grid_view'} />
                          </CardViewSwitcher.Item>
                          <CardViewSwitcher.Item
                            value="list"
                            aria-label="List View"
                            className={viewIcon({
                              selected: dir !== CardViewSwitcher.CARD_VIEW_GRID,
                            })}
                            onClick={() =>
                              sendGTMEvent({ event: 'switcher', type: 'click', value: 'list' })
                            }
                          >
                            <GoogleMaterialSymbol icon={'list'} />
                          </CardViewSwitcher.Item>
                        </CardViewSwitcher.Root>
                        <div className="" onClick={toggleFilterMenu}>
                          <Button
                            label={filterButtonLabel?.value}
                            type="outline"
                            iconLeft="tune"
                            size="compressed"
                          />
                        </div>
                      </section>
                    </div>
                  </div>

                  {facets?.length > 0 && (
                    <div
                      className={gradientMobile()}
                      style={{
                        background:
                          'linear-gradient(90deg, rgba(255, 255, 255, 0.00) -4.17%, #FFF 104.17%)',
                      }}
                    ></div>
                  )}

                  <div className={svgWrapper()}>
                    <SVG svg={`ArticleResultSearch/Brand=${themeName}`} />
                  </div>
                </div>
                <div className={articleListContainer()}>
                  {dir === CardViewSwitcher?.CARD_VIEW_GRID ? (
                    <div
                      className={articleCardListing({
                        articleCardListingView: CardViewSwitcher?.CARD_VIEW_GRID,
                      })}
                      ref={firstCardFocus}
                    >
                      {products?.map((product, index) => (
                        <>
                          <div className={articleCard()} key={index}>
                            <div className={cardWrapper()}>
                              <div className={card()}>
                                <div
                                  className={cardHead({
                                    cardHeadView: CardViewSwitcher?.CARD_VIEW_GRID,
                                  })}
                                >
                                  {product?.image_url && (
                                    <img
                                      src={product?.image_url}
                                      alt={product?.name}
                                      h-auto
                                      max-w-full
                                      className={cardMedia({
                                        /* eslint-disable  @typescript-eslint/ban-ts-comment */
                                        // @ts-ignore
                                        brand: themeName as string,
                                        articleCardListingView: CardViewSwitcher?.CARD_VIEW_GRID,
                                      })}
                                    />
                                  )}
                                </div>
                                <div className={cardBody()}>
                                  {product?.article_label && (
                                    <Label
                                      link={{
                                        value: {
                                          href:
                                            (product?.label_url &&
                                              product.label_url.split('|').at(0)) ||
                                            '',
                                          text:
                                            (product?.article_label &&
                                              product.article_label.split('|').at(0)) ||
                                            '',
                                        },
                                      }}
                                      color="white"
                                      className={labelContainer()}
                                      backgroundColor={labelBg()}
                                      buttonType="default"
                                    />
                                  )}

                                  <div className={cardContentGrid()}>
                                    {product?.article_title && (
                                      <span className={cardTitleText()}>
                                        <Link href={product?.url}>
                                          {Parser(product?.article_title)}
                                        </Link>
                                      </span>
                                    )}
                                    {product?.article_author_name &&
                                      product?.article_publish_date && (
                                        <span className={cardAuthorNamePublishDateGrid()}>
                                          {Parser(product?.article_author_name)}{' '}
                                          {Parser(formatDate(product?.article_publish_date))}
                                        </span>
                                      )}
                                    {product?.description &&
                                      hasTextContent(product?.description) && (
                                        <span className={cardDescriptionText()}>
                                          {Parser(product?.description)}
                                        </span>
                                      )}
                                  </div>
                                </div>
                                <div className={cardCtaWrapper()}>
                                  {product?.url && (
                                    <Button
                                      //The design requires an outline CTA, so for that we have added a fallback as an outline value,
                                      //so if there is no value in sitecore field, it will take the outline value.
                                      srOnlyText={stripHtml(product.article_title)}
                                      fullWidth
                                      target={product?.target}
                                      type={primaryCTAType?.value || fallbackComponentVariantType}
                                      color={
                                        primaryCTAColor?.value || fallbackComponentVariantColor
                                      }
                                      tag="a"
                                      label={ReadMore}
                                      href={product?.url}
                                      gtmEvent={{
                                        event: 'cta_click',
                                        type: 'primary',
                                        'gtm.element.dataset.gtmLinkUrl': product.url,
                                        'gtm.element.dataset.gtmLinkName': product.article_title,
                                        'gtm.element.dataset.gtmDatasourceId': product.id,
                                        'gtm.element.dataset.gtmDatasourceName': product.name,
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  ) : (
                    deviceName !== 'Mobile' && (
                      <div
                        className={articleCardListing({
                          articleCardListingView: CardViewSwitcher?.CARD_VIEW_LIST,
                        })}
                        ref={firstCardFocus}
                      >
                        {products?.map((product, index) => (
                          <div className={articleCardList()} key={index}>
                            <div className={cardWrapperList()} key={product.id}>
                              <div className={cardMediaWrapperList()}>
                                <div
                                  className={cardHead({
                                    cardHeadView: CardViewSwitcher?.CARD_VIEW_LIST,
                                  })}
                                >
                                  {product?.image_url && (
                                    <img
                                      src={product?.image_url}
                                      alt={product?.name}
                                      h-auto
                                      max-w-full
                                      className={cardMedia({
                                        /* eslint-disable  @typescript-eslint/ban-ts-comment */
                                        // @ts-ignore
                                        brand: themeName as string,
                                        articleCardListingView: CardViewSwitcher?.CARD_VIEW_LIST,
                                      })}
                                    />
                                  )}
                                </div>
                              </div>
                              <div className={cardContentWrapperList()}>
                                <div className={cardContainerList()}>
                                  <div className={cardBodyList()}>
                                    {product?.article_label && (
                                      <Label
                                        link={{
                                          value: {
                                            href:
                                              (product?.label_url &&
                                                product.label_url.split('|').at(0)) ||
                                              '',
                                            text:
                                              (product?.article_label &&
                                                product.article_label.split('|').at(0)) ||
                                              '',
                                          },
                                        }}
                                        color="white"
                                        className={labelContainerList()}
                                        backgroundColor={labelBg()}
                                        buttonType="default"
                                      />
                                    )}

                                    <div className={cardContentList()}>
                                      {product?.article_title && (
                                        <span className={cardTitleText()}>
                                          <Link href={product?.url}>
                                            {Parser(product?.article_title)}
                                          </Link>
                                        </span>
                                      )}
                                      {product?.article_author_name &&
                                        product?.article_publish_date && (
                                          <span className={cardAuthorNamePublishDateList()}>
                                            {Parser(product?.article_author_name)}{' '}
                                            {Parser(formatDate(product?.article_publish_date))}
                                          </span>
                                        )}
                                      {product?.description &&
                                        hasTextContent(product?.description) && (
                                          <span className={cardDescriptionTextList()}>
                                            {Parser(product?.description)}
                                          </span>
                                        )}
                                    </div>
                                  </div>
                                  <div className={cardCtaWrapperList()}>
                                    {product?.url && (
                                      <div className={articleCardListButton()}>
                                        <Button
                                          //The design requires an outline CTA, so for that we have added a fallback as an outline value,
                                          //so if there is no value in sitecore field, it will take the outline value.
                                          srOnlyText={stripHtml(product.article_title)}
                                          target={product?.target}
                                          type={
                                            primaryCTAType?.value || fallbackComponentVariantType
                                          }
                                          color={
                                            primaryCTAColor?.value || fallbackComponentVariantColor
                                          }
                                          label={ReadMore}
                                          href={product?.url}
                                          tag="a"
                                          gtmEvent={{
                                            event: 'cta_click',
                                            type: 'primary',
                                            'gtm.element.dataset.gtmLinkUrl': product.url,
                                            'gtm.element.dataset.gtmLinkName':
                                              product.article_title,
                                            'gtm.element.dataset.gtmDatasourceId': product.id,
                                            'gtm.element.dataset.gtmDatasourceName': product.name,
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )
                  )}
                </div>
                <div className={paginationContainer()}>
                  <Pagination.Root
                    currentPage={page}
                    defaultCurrentPage={1}
                    totalPages={totalPages}
                    onPageChange={(pagination) => {
                      scrollToSearchResults(pagination);
                    }}
                    className={paginationRoot()}
                  >
                    <Pagination.PrevPage
                      onClick={(e) => {
                        e.preventDefault();
                        scrollToSearchResults(page - 1);
                      }}
                      className={paginationIcon()}
                      tabIndex={-1}
                    >
                      <Button
                        type="text"
                        iconLeft="chevron_left"
                        label={deviceName === 'Desktop' ? Previous : ''}
                        disabled={isPreviousButtonDisabled}
                        tabIndex={isPreviousButtonDisabled ? -1 : 0}
                      />
                    </Pagination.PrevPage>
                    <div className={paginationGroup()}>
                      <Pagination.Pages className={paginationPageWrapper()}>
                        {(pagination) =>
                          Pagination.paginationLayout(pagination, {
                            boundaryCount: 1,
                            siblingCount: 1,
                          }).map(({ page, type }) =>
                            type === 'page' ? (
                              <Pagination.Page
                                key={page}
                                aria-label={`Page ${page}`}
                                page={page as number}
                                onClick={(e) => e.preventDefault()}
                                className={paginationPage({
                                  currentPage: page == pagination.currentPage,
                                })}
                              >
                                {page}
                              </Pagination.Page>
                            ) : (
                              <span key={type}>...</span>
                            )
                          )
                        }
                      </Pagination.Pages>
                      {totalItems && (
                        <div
                          className={paginationDisplayPage({
                            isMobile: (windowSize && windowSize <= 996) as boolean,
                          })}
                        >
                          {windowSize && windowSize > 996
                            ? DisplayingCountForSearch
                            : DisplayingMobileCountForSearch}
                        </div>
                      )}
                      {totalItems && (
                        <div
                          className={paginationDisplayPage({
                            isMobile: (windowSize && windowSize > 996) as boolean,
                          })}
                        >
                          {DisplayingCountForSearch}
                        </div>
                      )}
                    </div>
                    <Pagination.NextPage
                      onClick={(e) => {
                        e.preventDefault();
                        scrollToSearchResults(page + 1);
                      }}
                      className={paginationIcon()}
                      tabIndex={-1}
                    >
                      {' '}
                      <Button
                        type="text"
                        label={deviceName === 'Desktop' ? Next : ''}
                        iconRight="chevron_right"
                        disabled={isNextButtonDisabled}
                        tabIndex={isNextButtonDisabled ? -1 : 0}
                      />
                    </Pagination.NextPage>
                  </Pagination.Root>
                </div>
              </section>
            </>
          )}
          {totalItems <= 0 && !isFetching && (
            <div className={styles['sitecore-no-results']}>
              <h3>{noSearchResult?.value}</h3>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};
const ArticleListWithSearchWidget = widget(
  ProductListWithSearchComponent,
  WidgetDataType.SEARCH_RESULTS,
  'content'
);

export default ArticleListWithSearchWidget;
