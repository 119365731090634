// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// Global
import { ALL_THEMES } from 'lib/context/ThemeContext';
import { tv } from 'tailwind-variants';

const themeVariants = ALL_THEMES.reduce(
  (acc, curr) => ((acc[curr] = {}), acc),
  {} as Record<string, object>
);

export const tailwindVariants = tv({
  compoundSlots: [
    {
      slots: [
        'flexCommonClass',
        'headerWrapper',
        'headerLeft',
        'primaryNavigation',
        'primaryNavigationListItem',
        'primaryNavigationListItemLinkWrapper',
        'secondaryNavigationListItemTitleLinkWrapper',
        'secondaryNavigationItem',
        'secondaryDropdownItem',
        'currentCountry',
        'countryItemLinkWrapper',
        'countryItemLink',
      ],
      class: ['flex', 'flex-row', 'items-center'],
    },
  ],
  slots: {
    base: ['bg-components-header-color-bg', 'component header', 'hidden', 'w-full', 'md:block'],
    headerWrapper: [
      'header-wrapper',
      'border-b-[length:theme(spacing.spacing-spacing-025)]',
      'border-components-header-color-bottom-stroke',
      'min-h-components-header-spacing-large-height',
      'items-stretch',
      'justify-between',
      'px-components-header-spacing-large-padding-x',
      'relative',
    ],
    headerLeft: [
      'header-left',
      'flex',
      'flex-row',
      'max-lg:gap-[5px]',
      'md:gap-components-header-spacing-large-logo-margin-right',
    ],
    logoWrapper: ['logo-wrapper', 'w-max'],
    logo: ['logo-desktop', 'h-full', 'max-lg:w-full'],
    navigation: ['navigation', 'h-full', 'max-lg:flex-1'],
    navigationInner: ['navigation-inner', 'h-full'],
    primaryNavigation: [
      'primary-navigation',
      'lg:gap-components-header-spacing-large-links-margin-right',
      'gap-[0.5rem]',
      'h-full',
    ],
    primaryNavigationListItemWrapper: ['h-full'],
    primaryNavigationListItem: ['h-full'],
    primaryNavigationListItemLinkWrapper: [
      'cursor-pointer',
      'font-header-medium-xxSmall',
      'leading-header-medium-xxSmall',
      'text-header-medium-xxSmall',
      'group/item',
      'p-spacing-padding-large-1',
      'text-components-header-color-links-nav-main-link-default',
    ],
    primaryNavigationListItemLink: [
      'group-active/item:text-components-header-color-links-nav-main-link-pressed',
      'group-hover/item:text-components-header-color-links-nav-main-link-hover',
      'mr-spacing-space-between-large-1',
    ],
    primaryNavigationListItemArrowDropDown: [
      '[&>svg]:fill-components-header-color-links-nav-main-link-default',
      'group-active/item:[&>svg]:fill-components-header-color-links-nav-main-link-pressed',
      'group-hover/item:[&>svg]:!fill-components-header-color-links-nav-main-link-hover',
    ],
    secondaryNavigationWrapper: [
      'secondary-navigation-wrapper',
      'absolute',
      'bg-components-header-color-mega-menu-bg',
      'border-t',
      'border-t-components-header-color-bottom-stroke',
      'flex',
      'flex-row',
      'flex-wrap',
      '-mt-[1px]',
      'top-full',
      'w-full',
      'h-auto',
      'max-h-[92vh]',
      'fixed',
      'top-0',
      'left-0',
      'right-0',
      'overflow-x-hidden',
      'overflow-y-auto',
      'z-50',
      'shadow-components-header-menu',
    ],
    secondaryNavigation: [
      'secondary-navigation',
      'grid',
      'grid-cols-4',
      'gap-components-header-spacing-large-mega-menu-column-margin-right',
      'px-components-header-spacing-large-mega-menu-padding-x',
      'py-components-header-spacing-large-mega-menu-padding-y',
      'w-[calc(100%-444px)]',
    ],
    secondaryNavigationListItemWrapper: ['flex-1'],
    secondaryNavigationListItemTitleLinkWrapper: [
      'group/secondaryitem',
      'mb-components-header-spacing-large-mega-menu-category-link-margin-bottom',
    ],
    secondaryNavigationListItemTitleLink: [
      'block',
      'font-bodySans-large-semibold',
      'inline-flex',
      'leading-bodySans-large-semibold',
      'p-spacing-padding-large-1',
      'text-bodySans-large-semibold',
      'text-components-header-color-links-nav-category-link-category-default',
      'focus:text-components-header-color-links-nav-category-link-category-pressed',
      'group-active/secondaryitem:text-components-header-color-links-nav-category-link-category-pressed',
      'group-hover/secondaryitem:text-components-header-color-links-nav-category-link-category-hover',
    ],
    secondaryNavigationListItemLinkIconWrapper: ['icon-wrapper', 'h-[24px]', 'w-[24px]'],
    secondaryNavigationListItemLinkIcon: [
      'duration-300',
      'fill-components-header-color-links-nav-main-link-hover',
      'transition-opacity',
      'group-active/secondaryitem:fill-components-header-color-links-nav-main-link-pressed',
      '[&_svg]:opacity-0',
      '[&_svg]:group-hover/secondaryitem:opacity-100',
    ],
    secondaryNavigationItemsWrapper: ['secondary-navigation-items-wrapper', 'flex', 'flex-col'],
    secondaryNavigationItem: [
      'group/secondaryitem',
      'mb-components-header-spacing-large-mega-menu-category-link-margin-bottom',
    ],
    secondaryNavigationItemLink: [
      'font-bodySans-medium',
      'leading-bodySans-medium',
      'text-bodySans-medium',
      'p-spacing-padding-large-1',
      'text-components-header-color-links-nav-menu-link-link-default',
      'focus:text-components-header-color-links-nav-menu-link-link-pressed',
      'group-hover/secondaryitem:text-components-header-color-links-nav-menu-link-link-hover',
    ],
    featuredContentWrapper: [
      'featured-content-wrapper',
      'bg-components-header-color-mega-menu-featured-bg',
      'p-components-header-spacing-large-mega-menu-featured-padding-y',
      'w-[444px]',
      'ml-auto',
    ],
    featuredContentTitleWrapper: ['featured-content-title-wrapper'],
    featuredContentTitle: [
      'text-header-large-xSmall',
      'leading-120',
      'font-header-large-xSmall',
      'font-semibold',
      'text-components-header-color-mega-menu-featured-title',
      'mb-margin-large-4',
    ],
    featuredContentImageWrapper: [
      'featured-content-image-wrapper',
      'object-cover',
      'overflow-hidden',
      'relative',
      'my-components-header-spacing-large-mega-menu-featured-image-margin-bottom',
    ],
    featuredContentImage: ['rounded-themes-spacing-radius-large-image'],
    featuredContentLinksWrapper: ['featured-content-links-wrapper'],
    featuredContentLinks: ['featured-content-links'],
    featuredContentLinkItem: [
      'font-bodySans-medium-semibold',
      'mr-margin-medium-4',
      'inline-block',
    ],
    secondaryDropdownWrapper: [
      'absolute',
      'bg-components-header-color-mega-menu-bg',
      'border-t',
      'border-t-components-header-color-bottom-stroke',
      'flex',
      'flex-row',
      'justify-between',
      'min-w-[270px]',
      'px-components-header-spacing-large-dropdown-padding-x',
      'py-components-header-spacing-large-dropdown-padding-y',
      'secondary-dropdown-wrapper',
      'top-full',
      'w-full',
      'z-50',
      'shadow-components-header-menu',
    ],
    secondaryDropdownListItem: [
      'secondary-dropdown-item-list',
      'flex',
      'flex-col',
      'gap-components-header-spacing-large-dropdown-margin-bottom',
      'p-padding-large-3',
    ],
    secondaryDropdownItem: [
      'gap-spacing-space-between-large-1',
      'group/secondaryitem',
      'p-spacing-padding-large-1',
      '[&:not(:last)]:mb-components-header-spacing-large-dropdown-margin-bottom',
    ],
    secondaryDropdownItemLink: [
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'text-components-header-color-links-nav-menu-link-link-default',
      'focus:text-components-header-color-links-nav-menu-link-link-pressed',
      'group-active/secondaryitem:text-components-header-color-links-nav-menu-link-link-pressed',
      'group-hover/secondaryitem:text-components-header-color-links-nav-menu-link-link-hover',
    ],
    headerRight: [
      'header-right',
      'flex',
      'flex-row',
      'items-center',
      'gap-[0.5rem]', // Adding this gap, will achieve proper logo and navLinks for all the viewpoints
      'lg:gap-components-header-spacing-large-icons-space-between',
    ],
    searchForm: [
      'bg-components-site-search-bg',
      'flex',
      'items-start',
      'justify-between',
      'py-spacing-margin-small-3',
      'w-full',
    ],
    searchFormInput: [
      'bg-components-site-search-bg',
      'font-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'placeholder-components-header-color-search-search-text',
      'text-components-header-color-search-search-text',
    ],
    searchFormButton: [
      'basicFocus',
      'font-medium',
      'h-[30px]',
      'mt-[-3px]',
      'text-white',
      'text-xl',
      'w-[30px]',
    ],
    searchFormButtonIcon: ['fill-components-header-color-search-search-icon'],
    countryWrapper: [
      'country-wrapper',
      'px-components-header-spacing-large-language-selector-padding-y',
    ],
    countryWrapperInner: ['country-wrapper-inner', 'flex', 'flex-row'],
    flagIconWrapper: [
      'flag-icon',
      'w-max',
      'text-components-header-color-language-selector-country', // For Accessibility added font-color as color of country text
    ],
    logoPlaceholder: [
      'logo-placeholder',
      'w-[18px]',
      'h-[13px]',
      'bg-components-header-color-mega-menu-featured-bg',
    ],
    currentCountry: [
      'current-country',
      'gap-components-header-spacing-large-language-selector-space-between',
    ],
    countryInformation: [
      'country-information',
      'flex',
      'flex-col',
      'gap-components-header-spacing-large-language-selector-country-space-between',
    ],
    currentCountryName: [
      'current-country-name',
      'text-components-header-color-language-selector-country',
      'font-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
    ],
    currentCountryLanguage: [
      'current-country-language',
      'text-components-header-color-language-selector-language',
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
    ],
    countryDropDownIcon: ['fill-components-header-color-links-nav-main-link-default'],
    countryListWrapper: [
      'absolute',
      'bg-components-header-color-mega-menu-bg',
      'country-list-wrapper',
      'left-0',
      'max-h-[calc(100vh-80px)]',
      'overflow-auto',
      'top-full',
      'mt-[0.5px]',
      'w-full',
      'z-50',
      'shadow-components-header-menu',
    ],
    countryListInnerWrapper: [
      'country-list-inner-wrapper',
      'p-components-header-spacing-large-mega-menu-padding-y',
    ],
    countryListTop: ['country-list-top'],
    countryListHeading: [
      'country-list-heading',
      'text-components-header-color-mega-menu-category-title',
      'font-header-large-xSmall',
      'text-header-large-xSmall',
      'leading-header-large-xSmall',
      'mb-components-header-spacing-large-mega-menu-title-margin-bottom',
    ],
    countryListBottom: ['country-list-bottom', 'grid', 'grid-cols-4', 'gap-4'],
    countryListCols: ['country-list-cols'],
    countryListCol: ['country-list-col'],
    countryName: [
      'country-name',
      'p-spacing-padding-large-1',
      'font-bodySans-large-semibold',
      'text-bodySans-large-semibold',
      'leading-bodySans-large-semibold',
      'text-components-header-color-links-nav-category-link-category-default',
      'mb-components-header-spacing-large-mega-menu-category-link-margin-bottom',
    ],
    countryList: ['country-list'],
    countryItemLinkWrapper: [
      'group',
      'max-w-[240px]',
      'country-list-item',
      'px-spacing-padding-large-2',
      'py-spacing-padding-large-1',
      'mb-components-header-spacing-large-mega-menu-link-margin-bottom',
      'rounded-components-header-spacing-large-language-selector-radius',
      'border',
      'cursor-pointer',
      'hover:bg-components-header-color-language-selector-bg',
      'hover:border-components-header-color-language-selector-stroke',
      'border-transparent',
    ],
    countryItemLink: ['items-start', 'justify-between', 'w-full'],
    countryListItemLinkleft: ['country-list-item-link-left', 'flex', 'flex-row', 'items-start'],
    countryListItemLinkleftInfo: [
      'country-info',
      'ml-components-header-spacing-large-language-selector-space-between',
      'flex',
      'flex-col',
      'gap-components-header-spacing-large-language-selector-country-space-between',
    ],
    countryListItemLinkleftInfoTitle: [
      'text-components-header-color-language-selector-country',
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
    ],
    countryListItemLinkleftInfoSubTitle: [
      'text-components-header-color-language-selector-language',
      'text-bodySans-small',
      'font-bodySans-small',
      'leading-bodySans-small',
    ],
    countryListRight: ['country-list-right'],
    countrySelection: ['country-selection'],
    countryListRightIcon: [
      'fill-colors-grayscale-400',
      '[&_svg]:group-hover:opacity-100',
      '[&_svg]:opacity-0',
    ],
    flexCommonClass: [],
  },
  variants: {
    hasHref: {
      false: {},
      true: {
        primaryNavigationListItem: ['cursor-pointer'],
      },
    },
    isActive: {
      false: {},
      true: {
        primaryNavigationListItemLink: ['text-components-header-color-links-nav-main-link-active'],
        primaryNavigationListItemArrowDropDown: [
          '[&>svg]:!fill-components-header-color-links-nav-main-link-active',
        ],
        primaryNavigationListItemLinkWrapper: [
          'isActive',
          'relative',
          'before:absolute',
          'before:bg-components-header-color-links-nav-main-active-bar',
          'before:bottom-0.5',
          'before:h-0.5',
          'before:left-0',
          'before:w-full',
        ],
        secondaryDropdownItemLink: [
          'relative',
          'text-components-header-color-links-nav-menu-link-link-active',
          'before:absolute',
          'before:bg-components-header-color-links-nav-main-active-bar',
          'before:bottom-0.5',
          'before:h-0.5',
          'before:left-0',
          'before:w-full',
        ],
        secondaryNavigationListItemLinkIcon: [
          '[&_svg]:opacity-100',
          '[&>svg]:fill-components-header-color-links-nav-main-link-active',
        ],
        secondaryNavigationListItemTitleLink: [
          'relative',
          'text-components-header-color-links-nav-menu-link-link-active',
          'before:absolute',
          'before:bg-components-header-color-links-nav-main-active-bar',
          'before:bottom-0.5',
          'before:h-0.5',
          'before:left-0',
          'before:w-full',
        ],
        secondaryNavigationListItemLinkIconWrapper: ['pt-[3px]'],
        secondaryNavigationItemLink: [
          'relative',
          '!text-components-header-color-links-nav-menu-link-link-active',
          'before:absolute',
          'before:bg-components-header-color-links-nav-main-active-bar',
          'before:bottom-0.5',
          'before:h-0.5',
          'before:left-0',
          'before:w-full',
        ],
      },
    },
    isDropdown: {
      false: {},
      true: {
        primaryNavigationListItemWrapper: ['relative'],
      },
    },
    matchingCountry: {
      false: {},
      true: {
        countryItemLinkWrapper: ['bg-components-header-color-language-selector-bg'],
      },
    },
    navLength: {
      true: {
        currentCountryName: ['md:hidden', 'lg:block'],
        currentCountryLanguage: ['md:hidden', 'lg:block'],
        countryWrapperInner: ['md:items-center', 'lg:items-start', 'md:gap-0', 'lg:gap-2'],
      },
      false: {
        countryWrapperInner: ['gap-2'],
      },
    },
    languageLength: {
      true: {
        countryWrapperInner: ['cursor-pointer'],
      },
      false: {
        countryWrapperInner: ['cursor-default'],
      },
    },
    brand: {
      ...themeVariants,
      Off: {
        navigation: ['-ml-[55px]'],
      },
      Corporate: {
        primaryNavigationListItemLinkWrapper: [
          '!font-header-medium-xxSmall',
          '!leading-header-medium-xxSmall',
          '!text-header-small-xxSmall',
        ],
        logoWrapper: ['max-w-[9vw]'],
      },
      Glade: {
        logoWrapper: ['overflow-hidden', 'relative', 'h-full'],
      },
      Pyrel: {
        logoWrapper: ['!w-[72px]', 'h-[73px]'],
      },
      Duck: {
        countryListWrapper: ['!z-[-10]'],
        logoWrapper: ['!w-[115px]'],
        logo: ['md:!z-999', 'md:mt-9'],
        secondaryNavigationWrapper: ['!z-[-10]'],
      },
      Pledge: {
        countryListItemLinkleftInfoTitle: [
          'text-components-header-color-language-selector-megamenu-country',
        ],
        countryListItemLinkleftInfoSubTitle: [
          'text-components-header-color-language-selector-megamenu-country',
        ],
      },
      Drano: {
        logoWrapper: ['!w-[83px]'],
      },
    },
  },
});
