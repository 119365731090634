// Global
import { ALL_THEMES } from 'lib/context/ThemeContext';
import { tv } from 'tailwind-variants';

const themeVariants = ALL_THEMES.reduce(
  (acc, curr) => ((acc[curr] = {}), acc),
  {} as Record<string, object>
);

export const tailwindconstiants = tv({
  slots: {
    base: [
      'product-information-wrapper',
      'flex-col',
      'flex',
      'gap-components-product-information-spacing-small-carousel-space-between',
      'px-themes-general-surface-spacing-small-padding-x',
      'py-spacing-spacing-6',
      'md:flex-row',
      'md:gap-components-product-information-spacing-large-carousel-space-between',
      'md:px-themes-general-surface-spacing-large-padding-x',
      'md:py-spacing-spacing-7',
    ],
    productInformationBG: ['bg-components-product-information-color-bg'],
    productInformationLeftWrapper: ['product-information-left', 'md:max-w-[50%]'],
    productInformationRightWrapper: [
      'product-information-right',
      'flex',
      'flex-col',
      'justify-center',
      'md:max-w-[50%]',
    ],
    productInformationTitleWrapper: [
      'product-title',
      'font-header-small-large',
      'leading-header-small-large',
      'text-header-small-large',
      'text-components-product-information-color-headline',
      'mb-components-product-information-spacing-small-title-margin-bottom',
      'md:font-header-large-large',
      'md:leading-header-large-large',
      'md:text-header-large-large',
      'md:mb-components-product-information-spacing-large-title-margin-bottom',
    ],
    productInformationTopRightWrapper: [
      'productInformationTopRightWrapper',
      'flex',
      'flex-wrap',
      'justify-start',
      'items-center',
      'mb-components-product-information-spacing-small-label-margin-bottom',
      'md:mb-components-product-information-spacing-large-label-margin-bottom',
      'gap-spacing-spacing-3',
    ],
    productInformationMiddleRightWrapper: [],
    productInformationBottomRightWrapper: [],
    productInformationCTAWrapper: [
      'py-components-product-information-spacing-large-button-margin-y',
    ],
    productInformationDescription: [
      'product-description',
      'font-bodySans-medium',
      'leading-bodySans-medium',
      'text-bodySans-medium',
      'text-components-product-information-color-body',
      'pt-components-product-information-spacing-small-body-margin-top',
      '[&_p]:p-0',
      'md:pt-components-product-information-spacing-large-body-margin-top',
    ],
    productInformationAdditionalDetails: [
      'font-bodySans-medium',
      'leading-bodySans-medium',
      'text-bodySans-medium',
      'mb-components-product-information-spacing-large-details-margin-bottom',
      'text-components-product-information-color-detail-text',
      '[&>strong]:font-bodySans-medium-semibold',
      '[&>strong]:leading-bodySans-medium-semibold',
      '[&>strong]:text-bodySans-medium-semibold',
    ],
    productInformationTopSlider: [
      'mb-components-product-information-spacing-large-image-margin-bottom',
      'w-full',
      'md:max-w-[630px]',
    ],
    productInformationBottomSlider: [
      'mb-spacing-space-between-large-4',
      'thumbnail-slider',
      'w-full',
      'md:max-w-[630px]',
    ],
    productInformationProgressBar: [
      'progress-bar',
      'bg-components-pagination-on-white-bg',
      'h-[4px]',
      'w-[160px]',
    ],
    btn: [
      'group',
      '[&>button]:h-[40px]',
      '[&>button]:w-[40px]',
      '[&>button]:sm:py-[0.1.5rem]',
      '[&>button]:sm:px-[0.1.5rem]',
      '[&>button]:py-[0.7rem]',
      '[&>button]:px-[0.7rem]',
      '[&>button]:!min-h-0',
      'md:[&>button]:w-8',
      'md:[&>button]:h-8',
    ],
    labelColor: ['white'],
    productInformationProgress: ['bg-components-pagination-on-white-accent', 'h-[4px]'],
    productPrimaryImage: ['product-primary-image'],
    productRoundedImages: ['rounded-themes-radius-large-image'],
    productInformationImages: ['!h-full', 'block', 'object-cover'],
    productInformationVideoThumbnail: [
      'absolute',
      'fill-components-product-information-color-play-icon',
      'left-1/2',
      'top-1/2',
      'h-[50px]',
      'transform -translate-x-1/2 -translate-y-1/2',
      'w-[50px]',
      '[&>*]:h-full',
      '[&>*]:w-full',
    ],
    productInformationProgressbarWrapper: [
      'flex',
      'flex-row',
      'gap-6',
      'items-center',
      'justify-center',
    ],
    productInformationSplideControls: ['splide__arrows', 'flex', 'flex-row', 'gap-2'],
    productInformationVideo: [
      'aspect-video',
      'h-full',
      '[&>*]:!h-full',
      '[&>*]:!w-full',
      '[&>*]:!rounded-themes-radius-small-image',
      'rounded-themes-radius-small-image',
      'max-w-[1200px]',
      'my-0',
      'mx-auto',
      'w-full',
      'md:rounded-themes-radius-large-image',
    ],
    productInformationRatingReviewWrap: [
      'flex',
      'flex-col',
      'gap-components-product-information-spacing-small-space-between',
      'items-start',
      'review-rating-wrapper',
      'mb-components-product-information-spacing-small-details-margin-bottom',
      'md:flex-row',
      'md:gap-0',
      'md:items-center',
    ],
    productInformationRatingWrap: [
      'rating-wrapper',
      'flex',
      'flex-row',
      'gap-spacing-space-between-large-2',
      'items-center',
      'mr-components-product-information-spacing-large-space-between',
    ],
    productInformationStarRating: [
      'flex',
      'flex-row',
      'ratings',
      '[&_.pr-snippet-stars.pr-snippet-stars-png]:bg-components-product-information-color-bg',
    ],
    productInformationRatingNumber: [
      'product-rating',
      'rating-number',
      'text-bodySans-medium-semibold',
      'font-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'text-components-product-information-color-detail-text',
      'mt-0.5',
    ],
    productInformationReviews: [
      'review-wrapper',
      'text-components-button-color-outline-brand-default-text',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'font-bodySans-medium-semibold',
      'md:mx-components-product-information-spacing-large-space-between',
    ],
    productInformationSubmitReviews: [
      'submit-wrapper',
      'text-components-button-color-outline-brand-default-text',
      'text-bodySans-medium-semibold',
      'font-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'md:ml-components-product-information-spacing-large-space-between',
    ],
    socialShareAlignment: ['ml-auto'],
    buyNowBtnWrapper: ['flex', 'items-center', '[&>button]:my-0'],
    legalDisclaimerText: [
      'text-bodySans-small',
      'font-bodySans-small',
      'leading-bodySans-small',
      '!text-components-product-information-color-body',
      'pt-spacing-spacing-5',
      'self-start',
    ],
  },
  variants: {
    brand: {
      ...themeVariants,
      Duck: {
        socialShareAlignment: [
          '[&_button>span]:!fill-components-button-color-text-white-default-icon',
        ],
        productInformationStarRating: [
          '[&>div>div>section>div>div>div.pr-snippet>div.pr-snippet-read-and-write>*]:!text-components-button-color-text-white-default-text',
        ],
      },
      Lysoform: {
        socialShareAlignment: [
          '[&_button>span]:!fill-components-button-color-text-white-default-icon',
        ],
        productInformationStarRating: [
          '[&>div>div>section>div>div>div.pr-snippet>div.pr-snippet-read-and-write>*]:!text-components-button-color-text-white-default-text',
        ],
      },
      Baygon: {
        labelColor: [
          '!bg-components-label-color-on-white-background-bg-default',
          'hover:!bg-components-label-color-on-white-background-bg-hover',
        ],
      },
    },
  },
});
