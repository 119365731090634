// Global
import { sendGTMEvent } from '@next/third-parties/google';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import {
  PreviewSearchInitialState,
  WidgetDataType,
  usePreviewSearch,
  widget,
} from '@sitecore-search/react';
import { ArticleCard, Presence, PreviewSearch } from '@sitecore-search/ui';
import { useI18n } from 'next-localization';
import { useRouter } from 'next/navigation';
import { ChangeEvent, useEffect, useRef, useCallback, useState } from 'react';
import { tv } from 'tailwind-variants';

// Lib
import { ALL_THEMES, useTheme } from 'lib/context/ThemeContext';
import { stripHtml } from 'lib/utils/regex';

// Local
import getHighlightText from 'components/utils/getHighlightText';
import GoogleMaterialSymbol from 'helpers/GoogleMaterialSymbol/GoogleMaterialSymbol';

interface SearchProps {
  mainNavigationsLength: number;
  autoSuggestionItems: number;
  resultSuggestionItems: number;
  searchPlaceholderText?: string | '';
  defaultValue: string | '';
}

type SearchSettings = {
  previewSource: string;
  source: string;
  searchPageLink: string;
};

type ProductModel = {
  id: string;
  name: string;
  url: string;
  price: string;
  source_id?: string;
  author?: string;
  highlight?: HighlightModel;
};

type HighlightModel = {
  name?: Array<string>;
};

type InitialState = PreviewSearchInitialState<'itemsPerPage' | 'suggestionsList'>;

const themeVariants = ALL_THEMES.reduce(
  (acc, curr) => ((acc[curr] = {}), acc),
  {} as Record<string, object>
);

const tailwindVariants = tv({
  slots: {
    previewSearchRootWrapper: ['previewSearchRoot-wrapper', 'relative'],
    searchInput: [
      'bg-transparent',
      'box-border',
      'font-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'w-full',
      'focus-visible:!outline-none',
      'focus:!outline-none',
    ],
    searchInputFocusedIcon: ['fill-components-text-field-input-field-focused'],
    searchInputIconWrapper: [
      'basicFocus',
      'font-medium',
      'h-6',
      'text-white',
      'text-xl',
      'w-[24px]',
    ],
    searchInputIcon: ['fill-components-header-color-search-search-icon'],
    searchInputLabel: ['hidden'],
    searchInputWrapper: [
      'border-b-components-header-spacing-large-search-border-width-bottom',
      'border-components-header-color-search-search-border-default',
      'border-l-components-header-spacing-large-search-border-width-left',
      'border-r-components-header-spacing-large-search-border-width-right',
      'border-t-components-header-spacing-large-search-border-width-top',
      'border',
      'flex',
      'items-center',
      'justify-between',
      'bg-components-text-field-bg',
      'px-[calc(theme(spacing.spacing-margin-small-4)-2px)]',
      'py-[calc(theme(spacing.spacing-margin-small-3)-2px)]',
      'rounded-bl-components-header-spacing-large-search-border-radius-bottom',
      'rounded-br-components-header-spacing-large-search-border-radius-bottom',
      'rounded-tl-components-header-spacing-large-search-border-radius-top',
      'rounded-tr-components-header-spacing-large-search-border-radius-top',
    ],
    previewSearchContentMain: [
      'content-wrapper',
      'bg-components-global-search-color-omnibox-list-bg',
      'py-components-global-search-spacing-omnibox-list-padding-y',
      'rounded',
    ],
    previewSearchContentWrapper: [
      '[&>*]:!absolute',
      '[&>*]:!top-full',
      '[&>*]:!min-w-[230px]',
      '[&>*]:!transform-none',
      '[&>*]:!w-full',
      '[&>*]:!-left-[2]',
      '[&>*]:!z-20',
      '[&>*]:!shadow-md',
    ],
    searchContentSuggestionsHeading: [
      'font-bodySans-xSmall',
      'leading-bodySans-xSmall',
      'p-components-global-search-spacing-omnibox-label-padding',
      'text-bodySans-xSmall',
      'text-components-global-search-color-omnibox-label-text-default',
    ],
    searchLoaderAnimation: [
      'animate-spin',
      'bg-components-omnibox-color-loader-icon-fill-default',
      'block',
      'h-20',
      'm-auto',
      'w-20',
      '[clip-path:path("M73.3333_40.0038C73.3333_58.4115_58.411_73.3338_40.0033_73.3338C21.5957_73.3338_6.67334_58.4115_6.67334_40.0038C6.67334_21.5962_21.5957_6.67383_40.0033_6.67383C58.411_6.67383_73.3333_21.5962_73.3333_40.0038ZM13.3393_40.0038C13.3393_54.73_25.2772_66.6678_40.0033_66.6678C54.7295_66.6678_66.6673_54.73_66.6673_40.0038C66.6673_25.2777_54.7295_13.3398_40.0033_13.3398C25.2772_13.3398_13.3393_25.2777_13.3393_40.0038Z")]',
    ],
    searchLoaderContainer: ['flex', 'flex-1', 'items-center'],
    searchResultsItemIcon: ['fill-components-global-search-color-omnibox-item-text-default'],
    searchResultsItemIconWrapper: ['icon-wrapper', 'h-6', 'w-6'],
    searchResultsItemTitle: [
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'text-components-global-search-color-omnibox-item-text-default',
    ],
    searchResultsItemTitleWrapper: ['w-full'],
    searchResultsItemWrapper: [
      'cursor-pointer',
      'flex',
      'gap-[12px]',
      'items-center',
      'justify-between',
      'px-components-global-search-spacing-omnibox-item-padding-x',
      'py-components-global-search-spacing-omnibox-item-padding-y',
    ],
  },
  variants: {
    brand: {
      ...themeVariants,
      Off: {
        searchInputWrapper: [],
      },
      Ziploc: {
        searchInputWrapper: [
          'relative',
          'after:bg-components-header-color-search-search-border-default',
          'after:absolute',
          'after:-bottom-1.5',
          'after:content-[""]',
          'after:h-0.5',
          'after:-left-0',
          'after:w-full',
        ],
      },
      Corporate: {
        previewSearchRootWrapper: ['max-w-[12vw]'],
      },
    },
    isInputFocused: {
      true: {
        searchInput: [
          'placeholder-components-text-field-input-field-focused',
          'text-components-text-field-input-field-focused',
        ],
        searchInputWrapper: [
          'bg-components-text-field-bg-focused',
          '!border-2',
          'border-components-text-field-border-focused',
          '!rounded-themes-spacing-radius-large-form-field-input',
        ],
      },
      false: {
        searchInput: ['placeholder-components-header-color-search-search-text'],
      },
    },
  },
});

export const SearchComponent = (props: SearchProps) => {
  const i18n = useI18n();
  const router = useRouter();
  const { sitecoreContext } = useSitecoreContext();
  const { themeName } = useTheme();

  const [inputValue, setValue] = useState(props.defaultValue || '');
  const [isInputFocused, setInputFocused] = useState(false);

  const refSearchInput = useRef<HTMLInputElement>(null);
  const refSearchDefault = useRef<HTMLDivElement>(null);

  const regex = /(<([^>]+)>)/gi;
  const results = i18n.t('Results');
  const searchSettings = sitecoreContext?.searchDetails as SearchSettings;
  //const searchSourceIds = searchSettings?.source?.split('|') || [];
  const searchSourceIds = process.env.IS_PREVIEW_SITE
    ? searchSettings?.previewSource?.split('|') || []
    : searchSettings?.source?.split('|') || [];
  const suggestions = i18n.t('Suggestions');

  const {
    previewSearchRootWrapper,
    searchInputWrapper,
    searchInputLabel,
    searchInput,
    searchInputIconWrapper,
    searchInputIcon,
    searchInputFocusedIcon,
    previewSearchContentWrapper,
    previewSearchContentMain,
    searchContentSuggestionsHeading,
    searchLoaderAnimation,
    searchLoaderContainer,
    searchResultsItemWrapper,
    searchResultsItemTitleWrapper,
    searchResultsItemTitle,
    searchResultsItemIconWrapper,
    searchResultsItemIcon,
  } = tailwindVariants({
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    brand: themeName,
  });

  const {
    actions: { onItemClick, onKeyphraseChange, onSuggestionClick },
    queryResult,
    queryResult: {
      isFetching,
      isLoading,
      data: { suggestion: { dart_auto_suggester: searchSuggestions = [] } = {} } = {},
    },
  } = usePreviewSearch<ProductModel, InitialState>({
    query: (query) => {
      query
        .getRequest()
        .setSources(searchSourceIds)
        .setSearchFacetAll(false)
        .setSearchQueryHighlightFragmentSize(500)
        .setSearchQueryHighlightFields(['name', 'title'])
        .setSearchQueryHighlightPreTag('<b>')
        .setSearchQueryHighlightPostTag('</b>');
    },
    state: {
      suggestionsList: [
        {
          suggestion: 'dart_auto_suggester',
          max: props.autoSuggestionItems,
        },
      ],
      itemsPerPage: props.resultSuggestionItems,
    },
  });

  const loading = isLoading || isFetching;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => setValue(event.target.value);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Enter') return;
    handleSearch(inputValue);
    setInputFocused(false);
    setValue(props.defaultValue);
    refSearchInput.current?.blur();
    sendGTMEvent({
      event: 'search',
      type: 'query',
      'gtm.element.dataset.gtmLinkName': inputValue,
    });
  };

  const handleSearch = (handleInputValue: string) => {
    const path = window.location.pathname;

    if (handleInputValue) {
      return router.push(`${searchSettings.searchPageLink}?q=${handleInputValue}`);
    } else if (path && path !== '/' && !handleInputValue) {
      return null;
    } else {
      return null;
    }
  };

  const handleSearchButton = () => {
    if (isInputFocused && !props.defaultValue) {
      setValue(props.defaultValue);
      setInputFocused(false);
      refSearchInput?.current?.blur();
    } else if (!isInputFocused) {
      refSearchInput?.current?.focus();
      setInputFocused(true);
      return router.push('/search');
    } else {
      setValue(props.defaultValue);
      setInputFocused(false);
    }

    return;
  };

  const keyphraseHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      onKeyphraseChange({
        keyphrase: target.value,
      });
    },
    [onKeyphraseChange]
  );

  useEffect(() => {
    const handleOutsideClick = (e: Event): void => {
      const targetNode = e.target as Node;
      if (refSearchDefault?.current && !refSearchDefault?.current?.contains(targetNode)) {
        setInputFocused(false);
      }
    };

    const handleTabKey = (event: KeyboardEvent): void => {
      const targetNode = 'target' in event ? (event.target as Node) : null;

      if (
        event.key === 'Tab' &&
        refSearchDefault?.current &&
        !refSearchDefault?.current?.contains(targetNode)
      ) {
        setInputFocused(false);
        setValue(props.defaultValue);
        return;
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('keyup', handleTabKey);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keyup', handleTabKey);
    };
  }, [setInputFocused, setValue, props.defaultValue]);

  return (
    <PreviewSearch.Root>
      <div
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.stopPropagation();
          }
        }}
        className={previewSearchRootWrapper()}
        ref={refSearchDefault}
      >
        <div
          className={searchInputWrapper({
            isInputFocused: isInputFocused,
          })}
        >
          <label htmlFor="header_search" className={searchInputLabel()}>
            {props?.searchPlaceholderText}
          </label>
          <PreviewSearch.Input
            onChange={(e) => {
              setInputFocused(true);
              keyphraseHandler(e);
              handleChange(e);
            }}
            onClick={() => !isInputFocused && setInputFocused(true)}
            ref={refSearchInput}
            autoComplete="off"
            placeholder={props.searchPlaceholderText}
            className={searchInput({ isInputFocused: isInputFocused })}
            value={inputValue ?? ''}
            onKeyUp={handleKeyPress}
            id="header_search"
          />
          <button
            aria-label="search"
            className={searchInputIconWrapper()}
            type="submit"
            onClick={handleSearchButton}
          >
            <GoogleMaterialSymbol
              className={isInputFocused ? searchInputFocusedIcon() : searchInputIcon()}
              icon={isInputFocused ? 'close' : 'search'}
              variant="outlined"
            />
          </button>
        </div>
        {isInputFocused && (
          <div className={previewSearchContentWrapper()}>
            <div data-loading={loading} className={previewSearchContentMain()}>
              <Presence present={loading && isInputFocused}>
                <div className={searchLoaderContainer()}>
                  <div className={searchLoaderAnimation()} />
                </div>
              </Presence>
              <Presence present={!loading && isInputFocused}>
                <>
                  <h2 className={searchContentSuggestionsHeading()}>{results}</h2>
                  <PreviewSearch.Results defaultQueryResult={queryResult}>
                    {({ data: { content: articles = [] } = {} }) => {
                      return (
                        <PreviewSearch.Items>
                          {articles?.map((article: ProductModel, index: number) => {
                            const isPdf = article.url.trim().toLowerCase().endsWith('.pdf');
                            return (
                              <PreviewSearch.Item key={article.id} asChild>
                                <a
                                  href={article.url}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    onItemClick({
                                      id: article.id,
                                      index,
                                      sourceId: article.source_id,
                                    });
                                    const baseUrl = new URL(article.url);
                                    const path = baseUrl.pathname;
                                    setValue(props.defaultValue);
                                    setInputFocused(false);
                                    if (isPdf) {
                                      return window.open(article.url, '_blank');
                                    } else {
                                      return router.push(path);
                                    }
                                  }}
                                  className={searchResultsItemWrapper()}
                                >
                                  <ArticleCard.Root className={searchResultsItemTitleWrapper()}>
                                    <ArticleCard.Title className={searchResultsItemTitle()}>
                                      {inputValue
                                        ? getHighlightText(
                                            article.name.replaceAll(regex, ''),
                                            inputValue
                                          )
                                        : article.name.replaceAll(regex, '')}
                                    </ArticleCard.Title>
                                  </ArticleCard.Root>
                                  <div className={searchResultsItemIconWrapper()}>
                                    <GoogleMaterialSymbol
                                      className={searchResultsItemIcon()}
                                      icon="chevron_right"
                                      variant="outlined"
                                    />
                                  </div>
                                </a>
                              </PreviewSearch.Item>
                            );
                          })}
                        </PreviewSearch.Items>
                      );
                    }}
                  </PreviewSearch.Results>
                  {inputValue && inputValue?.length > 0 && !!searchSuggestions.length && (
                    <PreviewSearch.SuggestionsGroup id="dart_auto_suggester">
                      <h2 className={searchContentSuggestionsHeading()}>{suggestions}</h2>
                      {searchSuggestions.map((item) => {
                        return (
                          <PreviewSearch.SuggestionItem id={item.text} key={item.text} asChild>
                            <a
                              onClick={() => {
                                onSuggestionClick({
                                  name: 'dart_auto_suggester',
                                  title: 'Articles',
                                  value: item.text,
                                  displayName: item.text,
                                });
                                const path = `${searchSettings.searchPageLink}?q=${stripHtml(
                                  item.text
                                )}`;
                                setValue(props.defaultValue);
                                setInputFocused(false);
                                return router.push(path);
                              }}
                              href={`${searchSettings.searchPageLink}?q=${stripHtml(item.text)}`}
                              className={searchResultsItemWrapper()}
                            >
                              <div className={searchResultsItemTitleWrapper()}>
                                <span className={searchResultsItemTitle()}>
                                  {getHighlightText(item.text.replaceAll(regex, ''), inputValue)}
                                </span>
                              </div>
                              <div className={searchResultsItemIconWrapper()}>
                                <GoogleMaterialSymbol
                                  className={searchResultsItemIcon()}
                                  icon="chevron_right"
                                  variant="outlined"
                                />
                              </div>
                            </a>
                          </PreviewSearch.SuggestionItem>
                        );
                      })}
                    </PreviewSearch.SuggestionsGroup>
                  )}
                </>
              </Presence>
            </div>
          </div>
        )}
      </div>
    </PreviewSearch.Root>
  );
};

const SearchWidget = widget(SearchComponent, WidgetDataType.PREVIEW_SEARCH, 'content');

export default SearchWidget;
