// Global
import React from 'react';
import dynamic from 'next/dynamic';
import { SpeedInsights } from '@vercel/speed-insights/next';
import { Placeholder, LayoutServiceData, HTMLLink } from '@sitecore-jss/sitecore-jss-nextjs';
import { Environment, PageController, WidgetsProvider } from '@sitecore-search/react';
import { GoogleTagManager } from '@next/third-parties/google';

// Lib
import { ThemeContext, ThemeName } from 'lib/context/ThemeContext';
import useExperienceEditor from 'lib/use-experience-editor';

// Local
import HrefLang from 'helpers/HeadSection/HrefLang';
import PageHeadSection from 'helpers/HeadSection/PageHeadSection';
import SkipToMain from 'helpers/SkipToMain/SkipToMain';
import jssConfig from 'src/temp/config';
import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import Scripts from './Scripts';

const PowerReviewsScript = dynamic(() => import('helpers/PowerReviews/PowerReviewsScript'), {
  loading: () => <p>Loading...</p>,
});
interface LayoutProps {
  layoutData: LayoutServiceData;
  headLinks: HTMLLink[] | null;
}

function splitLanguageAndCountry(inputValue: string | undefined) {
  // Default country code
  const defaultCountry = 'us';

  // Ensure inputValue is not undefined
  if (!inputValue) {
    inputValue = ''; // Set a default value or handle it according to your needs
  }

  // Split the input string using '-'
  const parts = inputValue.split('-');

  // Extract language and country
  const language = parts[0];
  const country = parts.length > 1 ? parts[1] : defaultCountry;

  return {
    language: language?.toLowerCase(),
    country: country?.toLowerCase(),
  };
}

const Layout = ({ layoutData, headLinks }: LayoutProps): JSX.Element => {
  const { context, route } = layoutData.sitecore;
  const { gtmDetails, language, pageEditing } = context;
  const isExperienceEditor = useExperienceEditor();

  // const appName = (context.site?.name as ThemeName) ?? 'Vanilla';

  let appName = (context.site?.name as ThemeName) ?? 'Vanilla';
  if (isExperienceEditor && context?.sitefallBackTheme?.fallbackAppName) {
    appName = context?.sitefallBackTheme?.fallbackAppName as ThemeName;
  } else {
    appName = !jssConfig.fallbackAppName
      ? (context.site?.name as ThemeName) ?? 'Vanilla'
      : (jssConfig.fallbackAppName as ThemeName);
  }
  const localValues = splitLanguageAndCountry(language);
  const pageHeader = route?.displayName;

  PageController.getContext().setLocaleLanguage(localValues.language);
  PageController.getContext().setLocaleCountry(localValues.country);

  const mainClassPageEditing = pageEditing ? 'editing-mode' : 'prod-mode';

  const headerRef = useRef<HTMLDivElement>(null);

  const router = useRouter();

  useEffect(() => {
    if (headerRef.current) {
      headerRef.current.classList.remove('visually-none');
    }
  }, [router]);

  return (
    <>
      <ThemeContext.Provider value={appName}>
        <Scripts />
        <PageHeadSection headLinks={headLinks} language={language} route={route} />
        <HrefLang />
        {isExperienceEditor || !gtmDetails?.gtmId?.length ? null : (
          <GoogleTagManager gtmId={gtmDetails?.gtmId} />
        )}
        <WidgetsProvider
          apiKey={jssConfig.sitecoreSearchApiKey}
          customerKey={jssConfig.sitecoreSearchCustomerKey}
          env={jssConfig.sitecoreSearchEnv as Environment}
          publicSuffix={true}
        >
          <div className={`${mainClassPageEditing} ${appName} `}>
            <SkipToMain />
            <span className="sr-only">{pageHeader}</span>
            <div id="topbar">
              {route && <Placeholder name="dart-topbanner" rendering={route} />}
            </div>
            <header className="w-full z-[99] top-0 sticky" ref={headerRef} id="header">
              <div id="topbanner">
                {route && <Placeholder name="dart-header" rendering={route} />}
              </div>
            </header>
            <main>
              <div id="content">
                {route && <Placeholder name="dart-breadcrumb" rendering={route} />}
                {route && <Placeholder name="dart-hero" rendering={route} />}
                <div id="main" tabIndex={-1}>
                  {route && <Placeholder name="dart-main" rendering={route} />}
                </div>
              </div>
            </main>
            <footer>
              <div id="footer">{route && <Placeholder name="dart-footer" rendering={route} />}</div>
            </footer>
          </div>
        </WidgetsProvider>
      </ThemeContext.Provider>
      <PowerReviewsScript />
      <SpeedInsights />
    </>
  );
};

export default Layout;
