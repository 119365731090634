// Global
import { tv } from 'tailwind-variants';

export const tailwindVariants = tv({
  slots: {
    base: [
      'z-40',
      'fixed',
      'flex',
      'gap-4',
      'group',
      'items-center',
      'space-between',
      '-order-1',
      'bottom-4',
      'md:bottom-6',
      'hover:cursor-pointer',
    ],
    button: [
      'w-[40px]',
      'md:w-[48px]',
      'h-[40px]',
      'md:h-[48px]',
      'min-h-[initial]',
      'bg-components-button-color-filled-brand-default-bg',
      'hover:!bg-components-button-color-filled-brand-default-bg',
      'md:hover:!bg-components-button-color-filled-brand-hover-bg',
      'border-2',
      'border-components-fab-color-border-default',
      '!px-components-button-spacing-compressed-default-padding-y',
      '!py-components-button-spacing-compressed-default-padding-y',
      'md:px-components-button-spacing-large-icon-only-padding-x',
      'md:py-components-button-spacing-large-icon-only-padding-y',
      'rounded-components-fab-spacing-border-radius',
      'hover:border-components-fab-color-border-default',
    ],
    label: [
      'hidden',
      'bg-light-blue-200',
      'bg-themes-color-background-dark',
      'duration-300',
      'md:flex',
      'font-bodySans-small-semibold',
      'items-center',
      'justify-center',
      'leading-bodySans-small-semibold',
      'min-h-[35px]',
      'mt-0',
      'order-first',
      'overflow-hidden',
      'py-spacing-padding-large-1',
      'rounded',
      'text-bodySans-small-semibold',
      'text-themes-color-text-light',
      'transition-width',
      'w-0',
      'whitespace-nowrap',
      'group-hover:px-spacing-padding-large-1',
      'group-hover:w-auto',
    ],
  },
  variants: {
    alignment: {
      left: {
        base: ['left-4', 'md:left-6'],
      },
      right: {
        base: ['right-4', 'md:right-6'],
      },
    },
    showBackToTopButton: {
      false: {
        base: ['hidden'],
      },
      true: {
        base: ['block'],
      },
    },
  },
});
