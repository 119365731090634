import Button from 'helpers/Button/Button';
import { useI18n } from 'next-localization';
import React from 'react';
import { tv } from 'tailwind-variants';

interface AdmioProps {
  touchpointIDs?: string;
  label?: string;
  isMultiTouchPoint?: boolean;
  type?: string;
  color?: string;
}

const Adimo: React.FC<AdmioProps> = ({ touchpointIDs, isMultiTouchPoint, type, color }) => {
  const i18n = useI18n();
  const mikMakLabel = i18n.t('MikMakLabel');
  const tailwindVariants = tv({
    slots: {
      base: [],
    },
    variants: {
      isMultiTouchPoint: {
        true: {
          base: ['adimo-multi-touchpoint'],
        },
        false: {
          base: ['adimo-site-product-first'],
        },
      },
    },
  });

  const { base } = tailwindVariants({ isMultiTouchPoint: isMultiTouchPoint as boolean });
  return (
    <>
      <Button
        color={color || 'brand'}
        type={(type as string) || 'filled'}
        label={mikMakLabel}
        title={mikMakLabel}
        childClass={base()}
        attributes={{
          'data-type': isMultiTouchPoint ? 'multi-touchpoint-lightbox' : 'site-product-first',
          [isMultiTouchPoint
            ? 'data-static-touchpoint-identifiers'
            : 'data-static-touchpoint-identifier']: String(touchpointIDs),
        }}
        tag="a"
        href="javascript:void(0)"
      >
        {mikMakLabel}
      </Button>
    </>
  );
};

export default Adimo;
