// Global

import { tv } from 'tailwind-variants';

export const tailwindVariants = tv({
  slots: {
    title: [
      'lg:font-header-large-xSmall',
      'lg:leading-header-large-xSmall',
      'lg:text-header-large-xSmall',
      'md:font-header-medium-xSmall',
      'md:leading-header-medium-xSmall',
      'md:text-header-medium-xSmall',
      'font-header-small-xSmall',
      'leading-header-small-xSmall',
      'text-header-small-xSmall',
      'text-components-tip-color-title',
    ],
    description: [
      'md:font-bodySans-large',
      'md:leading-bodySans-large',
      'md:text-bodySans-large',
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'text-components-tip-color-body',
    ],
    wrapper: [
      'bg-components-tip-color-bg',
      'border-components-tip-color-stroke',
      'border-2',
      'flex',
      'flex-col',
      'gap-spacing-spacing-5',
      'py-spacing-spacing-6',
      'px-spacing-spacing-5',
      'rounded-components-tip-spacing-radius',
    ],
  },
});
