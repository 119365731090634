// Global
import { useSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { Options, Splide } from '@splidejs/react-splide';
import React, { useEffect, useRef, useState } from 'react';

// Lib
import { ComponentProps } from 'lib/component-props';
import { ProductAndArticleComponent, CardComponents } from 'lib/templates/Feature.Dart.model';
import { DartPages } from 'lib/templates/Project.Dart.model';
import { Common } from 'lib/templates/Foundation.Dart.model';
import { ItemEx } from 'lib/templates/_.Sitecore.Override';

// Local
import ProductCarousel from 'components/authorable/Carousel/ProductCarousel';
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import Label, { ColorTypes } from 'helpers/Label/Label';
import ProductEan from 'helpers/MikMak/ProductEan';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import ShareButton from 'helpers/ShareButton/ShareButton';
import { useRealPathName } from 'lib/utils/use-real-pathname';
import Adimo from 'helpers/Adimo/Adimo';
import LegalDisclaimer from 'helpers/LegalDisclaimer/LegalDisclaimer';
import { tailwindconstiants } from './ProductInformationTailwind';
import { useTheme } from 'lib/context/ThemeContext';
import { ComponentVariants } from 'lib/context/ComponentVariants';

export type ProductInformationProps = ComponentProps;

export type ProductInformation = ItemEx &
  ProductAndArticleComponent.ProductInformation.ProductInformation;

export type BadgeInformation = ItemEx & CardComponents.ProductCard.Badge;

export type CategoryInfo = ItemEx & DartPages.CategoryListPage;
export type LabelInfo = ItemEx & Common.Label.Label;

export type SecondaryImages = ProductAndArticleComponent.ProductInformation.SecondaryImage &
  ProductAndArticleComponent.ProductInformation.SecondaryVideo;

const ProductInformation = (props: ProductInformationProps): JSX.Element => {
  const path = useRealPathName();
  const { sitecoreContext } = useSitecoreContext();

  const { badge, category, productInformation, subCategory, parallelCategory } =
    sitecoreContext?.route?.fields || {};

  const badgeInfo = badge as BadgeInformation;
  const categoryInfo = category as CategoryInfo[];
  const categories = Array.isArray(categoryInfo)
    ? categoryInfo
        ?.filter((obj) => obj.fields?.categoryType?.value == 'Category')
        ?.map((c) => c.fields?.categoryNameForSearchIndex?.value)
        ?.join('|')
    : '';
  const secondaryCategories = Array.isArray(categoryInfo)
    ? categoryInfo
        ?.filter((obj) => obj.fields?.categoryType?.value == 'Secondary Category')
        ?.map((c) => c.fields?.categoryNameForSearchIndex?.value)
        ?.join('|')
    : '';
  const tertiaryCategories = Array.isArray(categoryInfo)
    ? categoryInfo
        ?.filter((obj) => obj.fields?.categoryType?.value == 'Tertiary Category')
        ?.map((c) => c.fields?.categoryNameForSearchIndex?.value)
        ?.join('|')
    : '';
  const parallelCategories = Array.isArray(parallelCategory)
    ? parallelCategory
        ?.map((c: CategoryInfo) => c.fields?.categoryNameForSearchIndex?.value)
        ?.join('|')
    : '';
  const prodInfo = productInformation as ProductInformation;
  const subCategoryInfo = subCategory as CategoryInfo;
  const isMikMak = sitecoreContext?.integrationMikMak?.isMikMak === '1';
  const adimoMultiTouchPoint = sitecoreContext?.integrationEvidon?.isAdimoMultiTouchpoint === '1';
  const isAdimoPriceFirst = sitecoreContext?.integrationEvidon?.isAdimoPriceFirst === '1';
  const [initialLoad, setInitialLoad] = useState(0);
  const { themeName } = useTheme();
  const componentVariants = ComponentVariants();

  useEffect(() => {
    const randomValue = Math.floor(Math.random() * 100) + 1;

    setInitialLoad(randomValue);
  }, [path]);

  const {
    headline,
    description,
    label,
    additionalDetails,
    socialShare,
    ratingAndReview,
    disclaimerText,
  } = prodInfo?.fields || {};

  const pipeSeparatedLabelTitle = Array.isArray(label)
    ? label?.map((item: LabelInfo) => item?.fields?.link?.value?.text).join('|')
    : '';

  const pipeSeparatedLabelhref = Array.isArray(label)
    ? label?.map((item: LabelInfo) => item?.fields?.link?.value?.href).join('|')
    : '';

  const {
    base,
    productInformationLeftWrapper,
    productInformationRightWrapper,
    productInformationTitleWrapper,
    productInformationTopRightWrapper,
    productInformationMiddleRightWrapper,
    productInformationDescription,
    productInformationAdditionalDetails,
    productInformationRatingReviewWrap,
    productInformationRatingWrap,
    productInformationStarRating,
    productInformationBG,
    socialShareAlignment,
    buyNowBtnWrapper,
    legalDisclaimerText,
    labelColor,
  } = tailwindconstiants({
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    brand: themeName as string,
  });

  const mainSliderRef = useRef<Splide>(null);
  const thumbnailSliderRef = useRef<Splide>(null);
  const mainSlider: Options = {
    type: 'fade',
    rewind: false,
    pagination: false,
    arrows: false,
    autoplay: false,
    resetProgress: false,
  };

  const thumbSlider: Options = {
    arrows: false,
    fixedWidth: 120,
    fixedHeight: 120,
    gap: 16,
    rewind: false,
    isNavigation: true,
    perPage: 3,
    pagination: false,
    breakpoints: {
      600: {
        fixedWidth: 84,
        fixedHeight: 84,
        gap: 10,
      },
    },
  };

  const id = props?.params?.RenderingIdentifier;

  const upcs = [
    ...new Set(prodInfo?.fields?.variantItems?.map((vi) => vi?.fields?.ean?.value)),
  ].join(',');
  // const adimoMultiTouchpointValues = prodInfo?.fields?.wheretoBuy?.value;
  const combinedValues = [
    prodInfo?.fields?.addtoCart?.value,
    prodInfo?.fields?.buyOnline?.value,
    prodInfo?.fields?.wheretoBuy?.value,
  ].join(', ');
  const buyOnline = prodInfo?.fields?.buyOnline?.value;
  return (
    <div
      className={productInformationBG()}
      data-component="authorable/productinformation"
      id={id ? id : undefined}
      tabIndex={id ? -1 : 1}
    >
      <Container>
        <div className={base()}>
          <div className={productInformationLeftWrapper()}>
            <ProductCarousel
              mainSliderRef={mainSliderRef}
              thumbnailSliderRef={thumbnailSliderRef}
              props={prodInfo}
              mainSlider={mainSlider}
              thumbSlider={thumbSlider}
              key={path + initialLoad}
              carouselNavigation={componentVariants?.productInformation?.carousel?.navigation}
              carouselPagination={componentVariants?.productInformation?.carousel?.pagination}
            ></ProductCarousel>
          </div>
          <div className={productInformationRightWrapper()}>
            <div className={productInformationTopRightWrapper()}>
              <>
                {Array.isArray(label) &&
                  label?.map((item: LabelInfo, index: number) => {
                    return (
                      <Label
                        backgroundColor="bg-components-product-information-color-bg"
                        color={
                          (componentVariants?.productInformation?.label as ColorTypes) || 'white'
                        }
                        className={labelColor()}
                        link={item?.fields?.link}
                        key={index}
                      />
                    );
                  })}
                <span className="product-label hidden">
                  <a href={pipeSeparatedLabelhref}>{pipeSeparatedLabelTitle}</a>
                </span>
              </>
              {socialShare?.value && (
                <div className={socialShareAlignment()}>
                  {' '}
                  <ShareButton />
                </div>
              )}
            </div>
            <div className={productInformationMiddleRightWrapper()}>
              {headline?.value && (
                <div className={productInformationTitleWrapper()}>
                  <Text encode={false} field={headline} tag="h1" />
                </div>
              )}
              {ratingAndReview?.value && (
                <div className={productInformationRatingReviewWrap()}>
                  <div className={productInformationRatingWrap()}>
                    <div className={productInformationStarRating()}>
                      <div id="pr-reviewsnippet"></div>
                    </div>
                  </div>
                </div>
              )}
              {additionalDetails?.value && (
                <div className={productInformationAdditionalDetails()}>
                  <RichTextA11yWrapper field={additionalDetails}></RichTextA11yWrapper>
                </div>
              )}
              {isMikMak && (
                <div className={buyNowBtnWrapper()}>
                  <ProductEan
                    eans={upcs}
                    btnType={componentVariants?.productInformation?.primaryCta?.type}
                    btnColor={componentVariants?.productInformation?.primaryCta?.color}
                  />
                </div>
              )}
              {!isMikMak && (
                <>
                  {adimoMultiTouchPoint && (
                    <div className={buyNowBtnWrapper()}>
                      <Adimo
                        touchpointIDs={combinedValues}
                        isMultiTouchPoint={true}
                        type={componentVariants?.productInformation?.primaryCta?.type}
                        color={componentVariants?.productInformation?.primaryCta?.color}
                      />
                    </div>
                  )}
                  {isAdimoPriceFirst && (
                    <div className={buyNowBtnWrapper()}>
                      {' '}
                      <Adimo
                        touchpointIDs={buyOnline}
                        isMultiTouchPoint={false}
                        type={componentVariants?.productInformation?.primaryCta?.type}
                        color={componentVariants?.productInformation?.primaryCta?.color}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
            <div className={productInformationDescription()}>
              {description && <RichTextA11yWrapper field={description} />}
            </div>
            {disclaimerText?.value != '' && (
              <LegalDisclaimer
                disclaimerText={disclaimerText}
                disclaimerClasses={legalDisclaimerText()}
              />
            )}
          </div>
        </div>
        <input
          type="hidden"
          name="hdnProductBadge"
          id="hdnProductBadge"
          value={badgeInfo?.fields?.badgeTitle?.value}
        />
        <input type="hidden" name="hdnProductCategory" id="hdnProductCategory" value={categories} />
        <input
          type="hidden"
          name="hdnProductSecondaryCategory"
          id="hdnProductSecondaryCategory"
          value={secondaryCategories}
        />
        <input
          type="hidden"
          name="hdnProductTertiaryCategory"
          id="hdnProductTertiaryCategory"
          value={tertiaryCategories}
        />
        <input
          type="hidden"
          name="hdnParallelCategory"
          id="hdnParallelCategory"
          value={parallelCategories}
        />
        <input
          type="hidden"
          name="hdnProductSubCategory"
          id="hdnProductSubCategory"
          value={subCategoryInfo?.fields?.categoryNameForSearchIndex?.value}
        />
        <input
          type="hidden"
          name="hdnProductInfoItemId"
          id="hdnProductInfoItemId"
          value={prodInfo?.fields?.oldPageId?.value || prodInfo?.id}
        />
        <input
          type="hidden"
          name="hdnProductPageItemId"
          id="hdnProductPageItemId"
          value={sitecoreContext?.route?.itemId}
        />
        <input
          type="hidden"
          name="hdnAdimoAddtoCart"
          id="hdnAdimoAddtoCart"
          value={prodInfo?.fields?.addtoCart?.value}
        />
        <input
          type="hidden"
          name="hdnAdimoBuyOnline"
          id="hdnAdimoBuyOnline"
          value={prodInfo?.fields?.buyOnline?.value}
        />
        <input
          type="hidden"
          name="hdnAdimoWheretoBuy"
          id="hdnAdimoWheretoBuy"
          value={prodInfo?.fields?.wheretoBuy?.value}
        />
      </Container>
      {/* <>
        <div id="pr-reviewdisplay">
          <h2 className="prReviewHeading" id="prReviewHeading">
            Ratings &amp; Review
          </h2>
        </div>
      </> */}
    </div>
  );
};

export default ProductInformation;
