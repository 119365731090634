// Global
import { tv } from 'tailwind-variants';
import { ALL_THEMES } from 'lib/context/ThemeContext';

const themeVariants = ALL_THEMES.reduce(
  (acc, curr) => ((acc[curr] = {}), acc),
  {} as Record<string, object>
);

export const tailwindVariants = tv({
  slots: {
    base: ['SearchHero', 'component'],
    searchHeroWrapper: [
      'component-content',
      'flex',
      'flex-col',
      'justify-center',
      'relative',
      'h-full',
      'min-h-components-hero-internal-spacing-large-min-height',
      'py-themes-general-surface-spacing-small-padding-y',
      'px-themes-general-surface-spacing-small-padding-x',
      'gap-spacing-spacing-7',
      'md:py-components-site-search-hero-search-large-padding-y',
      'md:px-themes-general-surface-spacing-large-padding-x',
    ],
    searchHeroTitleWrapper: ['field-searchHeroTitle'],
    searchHeroTitle: [
      'font-header-small-large',
      'leading-header-small-large',
      'text-header-small-large',
      'text-components-site-search-hero-search-title',
      'z-10',
      'relative',
      'md:font-header-large-large',
      'md:leading-header-large-large',
      'md:text-header-large-large',
    ],
    searchHeroInputWrapper: ['search-input-wrapper', 'z-10'],
    searchHeroSvg: [
      'absolute',
      'left-0',
      'top-0',
      'w-full',
      'h-full',
      'overflow-hidden',
      'hidden',
      '[&>*]:h-full',
      '[&>*]:top-0',
      '[&>*]:w-full',
      '[&>*]:absolute',
    ],
    svgDecor: ['w-full', 'absolute', 'bottom-0', 'left-0', 'z-10', 'hidden'],
  },
  variants: {
    showPattern: {
      true: {
        searchHeroSvg: ['hidden', 'md:block'],
        svgDecor: ['hidden', 'md:block'],
        searchHeroWrapper: [
          'bg-components-hero-internal-color-accent-bg',
          'md:bg-components-hero-internal-color-default-bg',
        ],
      },
      false: {
        searchHeroWrapper: ['bg-components-hero-internal-color-default-bg'],
      },
    },
    brand: {
      ...themeVariants,
      Glade: {
        searchHeroSvg: ['right-0', '[&>*]:!w-auto', '[&>*]:right-0'],
      },
    },
  },
});
