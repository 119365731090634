// Global
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';

// Lib
import { ComponentProps } from 'lib/component-props';
import { SearchComponents } from 'lib/templates/Feature.Dart.model';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import ProductListWithSearchWidget from 'components/ProductListWithSearch';
import { useRealPathName } from 'lib/utils/use-real-pathname';
import { sendGTMEvent } from '@next/third-parties/google';

export type PIDSResultsListProps = ComponentProps &
  SearchComponents.PidsResultsListWithSearch.PidsResultsListWithSearch;

export const PIDSResultsListwithSearch = (props: PIDSResultsListProps): JSX.Element => {
  const [count, setCount] = useState(0);

  const router = useRouter();
  const path = useRealPathName();

  useEffect(() => {
    setCount((c) => c + 1);
  }, [router]);

  // Add ref for the GTM Event
  const hasFired = useRef(false);

  useEffect(() => {
    // Function to send the GTM event
    sendGTMEvent({ event: 'switcher', type: 'click', value: 'grid' });
    hasFired.current = true;
  }, []); // Empty dependency array ensures it runs only once on mount

  if (!props?.fields) return <></>;

  const pidsResultsData = {
    resultsPerPage: props?.fields?.resultsPerPage,
    noSearchResult: props?.fields?.noSearchResult,
    filterLabel: props?.fields?.filterLabel,
    sortLabel: props?.fields?.sortLabel,
    filterButtonLabel: props?.fields?.filterButtonLabel,
    sortByLabel: props?.fields?.sortByLabel,
    primaryCTAColor: props?.fields?.primaryCTAColor,
    primaryCTAType: props?.fields?.primaryCTAType,
  };

  // Unique id for component
  const id = props?.params?.RenderingIdentifier;

  return (
    <Container>
      <div
        data-component="layout/productresultslistwithsearch"
        id={id ? id : undefined}
        tabIndex={id ? -1 : 1}
      >
        <div>
          <ProductListWithSearchWidget
            rfkId={'rfkid_12'}
            {...pidsResultsData}
            key={path + count}
            isBadgeFullWidth={props?.params?.isBadgeFullWidth}
          />
        </div>
      </div>
    </Container>
  );
};

export default PIDSResultsListwithSearch;
